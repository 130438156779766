import React, { Fragment, useState } from 'react';
import { Button, styled } from '@material-ui/core';
import { FormInput as RAFormInput, TextInput, useLocale, useNotify, useTranslate } from 'react-admin';
import { Route, Redirect, Link } from 'react-router-dom';
import { Form, useFormState } from 'react-final-form'
import { FORM_ERROR } from 'final-form';
import Helmet from 'react-helmet';
import qs from 'qs';

import { apiClient, useLoggedIn } from '../http';
import PasswordInput from '../common/inputs/PasswordInput';

import Layout from './BaseLayout';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const Meta = () => {
  const translate = useTranslate()

  return <>
    <Button type="link" component={Link} to="/login">{translate('ra.auth.login')}</Button>
    <Button type="link" component={Link} to="/register">{translate('ra.action.register')}</Button>
  </>
}

const FormInput = ({ children, ...props }) =>
  <RAFormInput input={children} />

const Error = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  margin: theme.spacing(2, 1),
  fontSize: '0.9rem',
  fontWeight: 400,
}))

const FormError = () => {
  const { submitError } = useFormState();
  return <Error>{submitError}</Error>
}

const getToken = search => {
  const { token } = qs.parse(search.substring(1));
  return token;
}

const validate = (values, translate) => {
  const errors = {};

  if (!values.token) {
    if (!values.username) errors.username = 'ra.validation.required';
  } else if (!values.newPassword) {
    errors.newPassword = 'ra.validation.required'
  } else if (values.newPassword.length < 8) {
    errors.newPassword = translate('ra.validation.minLength', { min: 8 })
  } else if (!values.confirmPassword) {
    errors.confirmPassword = 'ra.validation.required'
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'ra.validation.match'
  }

  return errors;
}

const requestReset = ({ username }, locale) =>
  apiClient('/accounts/request-reset', {
    method: "POST",
    data: {
      username,
      language: locale
    },
    skipAuth: true,
  }).then(res => res.data)

const resetPassword = (token, { newPassword }, locale) =>
  apiClient('/accounts/reset-password', {
    method: "POST",
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: {
      newPassword,
      language: locale
    },
    skipAuth: true,
  }).then(res => res.data)

const ResetPassword = ({ location }) => {
  const locale = useLocale();
  const translate = useTranslate();
  const notify = useNotify();
  const [ loading, setLoading ] = useState(false)

  const token = getToken(location.search)
  const [ complete, setComplete ] = useState(false);

  const isLoggedIn = useLoggedIn();
  if (isLoggedIn) return <Redirect to="/" />

  const handleError = err => {
    const error = err?.response?.data?.error
    const status = err?.response?.status;
    if (error === 'invalid_grant' || status === 401 || status === 403) {
      return { [FORM_ERROR]: translate('components.reset_password.messages.invalid_reset_link') };
    }

    return { [FORM_ERROR]: translate('ra.page.error_try_again_refresh') }
  }

  const onSubmit = values => {
    setLoading(true);
    const request = token ? resetPassword(token, values, locale) : requestReset(values, locale);
    return request
      .then(() => {
        if (token) notify('ra.input.password.change_success', 'info')
        setComplete(true)
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  if (complete) {
    if (token) return <Redirect to="/login" />
    return <Layout>
      <h3>{translate('components.reset_password.messages.check_email')}</h3>
      <p>{translate('components.reset_password.messages.check_email_error')}</p>
    </Layout>
  }

  return <Layout meta={<Meta />}>
    <Helmet title="Reset Password" />
    <Form onSubmit={onSubmit} validate={(values) => validate(values, translate)} initialValues={{ token }} render={({ handleSubmit }) =>
      <form onSubmit={handleSubmit}>
        {!token && <FormInput>
          <TextInput
            source="username"
            label="ra.input.field.email"
            type="email"
            autoFocus
            options={{ InputProps: { autoComplete: "email username" } }}
            {...inputProps}
          />
        </FormInput>}

        {token && <FormInput>
          <PasswordInput source="newPassword" label="ra.input.password.new" autoComplete="new-password" {...inputProps} />
        </FormInput>}

        {token && <FormInput>
          <PasswordInput source="confirmPassword" label="ra.input.password.confirm" autoComplete="new-password" {...inputProps} />
        </FormInput>}

        <FormError />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>{!token ? translate('ra.auth.recovery_email') : translate('ra.input.password.change') }</Button>
      </form>
    } />
  </Layout>
}

export default [
  <Route path="/reset" exact component={ResetPassword} noLayout />
]
