import React from 'react';

import ListCard from '../../../common/cards/ListCard';

import { ScheduleManagerContactGrid } from '../../contacts/ScheduleManagerContactGrid';

export default () =>
  <ListCard
    title="resources.schedules.labels.league_contacts"
    reference="scheduleContacts"
    target="schedules"
    sort={{ field: 'id', order: 'ASC' }}
    perPage={999}
    pagination={null}
  >
    <ScheduleManagerContactGrid showGroup />
  </ListCard>
