import React from 'react';
import { ReferenceField, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';

import { isAuthorized } from '../../common/Authorize';
import FunctionField from '../../common/fields/FunctionField';

import { getVenueCity, getVenueName } from './util';

export const VenueField = ({ variant = 'body2', includeCity = false, ...props }) => {
  const record = useRecordContext(props);
  const showLink = isAuthorized(record, 'venues', 'show') ? 'show' : false;

  return <ReferenceField basePath="/venues" resource="venues" reference="venues" link={showLink} {...props}>
    <FunctionField render={venue => {
      const name = getVenueName(venue);
      const city = getVenueCity(venue);
      if (!includeCity || !city) return <Typography variant={variant} display="inline">{name}</Typography>;
      return <>
        <Typography variant={variant} display="inline">{name}</Typography>
        <Typography variant={variant} display="inline" color="textSecondary"> • {city}</Typography>
      </>
    }} />
  </ReferenceField>
}

VenueField.defaultProps = {
  addLabel: true,
}
