import React from 'react';
import { RecordContextProvider, ReferenceManyField, ResourceContextProvider, useRecordContext, useTranslate } from 'react-admin';
import { Card, CardHeader, CardContent, CardActions, makeStyles, Divider } from '@material-ui/core'

import { MessageGrid } from '../../messages/MessageGrid';
import { MessageForm } from '../../messages/MessageForm';

const useStyles = makeStyles(theme => ({
  actions: {
    padding: 0,
  },
  form: {
    width: '100%',
  }
}))
export default ({ type = 'League', showCreate = false, ...props }) => {
  const game = useRecordContext(props);
  const classes = useStyles();
  const translate = useTranslate();

  const recipients = type === 'League'
    ? translate('resources.games.labels.assignment.team_officials').toLowerCase()
    : translate('resources.games.labels.assignment.assigners').toLowerCase();
  const helperText = translate('resources.gameMessages.helpers.notification_sent_to', { recipients });

  return <Card>
    <CardHeader title={`${translate('resources.gameMessages.name', 2)} (${translate(`resources.gameMessages.values.type.${type}`)})`} titleTypographyProps={{ variant: "subtitle2", gutterBottom: false }} />
    <Divider />
    <CardContent>
      <ReferenceManyField
        reference="gameMessages"
        target="games"
        label={translate('resources.gameMessages.name', 2)}
        filter={{ type }}
        sort={{ field: 'createdAt', order: 'ASC' }}
      >
        <MessageGrid type={type} />
      </ReferenceManyField>
    </CardContent>
    {showCreate && <CardActions className={classes.actions}>
      <ResourceContextProvider value="gameMessages">
        <RecordContextProvider value={null}>
          <MessageForm className={classes.form} initialValues={{ gameId: game?.id, type }} helperText={helperText} />
        </RecordContextProvider>
      </ResourceContextProvider>
    </CardActions>}
  </Card>
}
