import React from 'react';
import { useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { DialogActions, Button, CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Error = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  margin: theme.spacing(0, 1),
  fontSize: '0.9rem',
  fontWeight: 400,
}))

const DialogFormActions = styled(DialogActions)({
  margin: '8px -24px -8px -24px',
})

export const DialogFormToolbar = ({ handleSubmit, submitLabel, submittingLabel = submitLabel, hideSubmit = false, disableInvalidSubmit = false, cancelLabel = "ra.action.cancel", onCancel, deleteButton = null, disableSubmit = false, showSubmitLoading = false, className }) => {
  const { submitError, submitting, invalid } = useFormState();
  const translate = useTranslate();

  const showLoader = submitting && showSubmitLoading;

  return <>
    {submitError && <Error>{submitError}</Error>}
    <DialogFormActions className={className}>
      {deleteButton}
      {onCancel && <Button disabled={submitting} onClick={onCancel}>
        {translate(cancelLabel, { _: cancelLabel, smart_count: 1 })}
      </Button>}
      {!hideSubmit && <Button type="submit" color="primary" disabled={submitting || (disableInvalidSubmit && invalid) || disableSubmit} onClick={handleSubmit}>
        {showLoader && <CircularProgress size={14} style={{ marginRight: 4 }} />}
        {!submitting
          ? translate(submitLabel, { _: submitLabel, smart_count: 1 })
          : translate(submittingLabel, { _: submittingLabel, smart_count: 1 })}
      </Button>}
    </DialogFormActions>
  </>
}
