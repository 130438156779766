import React from 'react';
import { SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { TargetInput } from '../targets/TargetInput';
import { TargetGroupInput } from '../targets/TargetGroupInput';
import { OfficeInput } from '../offices/OfficeInput';

const validate = values => {
  const errors = {};

  if (!values.targetType) errors.targetId = 'resources.assignTargets.validations.missing_type'
  if (!values.targetId) errors.targetId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'assignTargets',
  basePath: '/assignTargets',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const AssignTargetForm = props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <TargetInput source="targetId" disabled={props.initialValues?.targetId} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TargetGroupInput source="groupIds" helperText="ra.message.optional" multiple {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="officeId" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
