import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import RegistrationForm from './RegistrationForm';

const AddRegistrationDialog = props =>
  <CreateResourceDialog label="resources.registrations.actions.add" resource="registrations" basePath="/registrations" {...props}>
    <RegistrationForm />
  </CreateResourceDialog>

const EditRegistrationDialog = props =>
  <EditResourceDialog label="resources.registrations.labels.edit" resource="registrations" basePath="/registrations" {...props}>
    <RegistrationForm />
  </EditResourceDialog>

export const AddRegistrationButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.registrations.actions.add')}
    </Button>
    <AddRegistrationDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const RegistrationDatagrid = props => <DialogDatagrid dialog={EditRegistrationDialog} {...props} />
