import React from 'react';
import { TextField, EditButton, useTranslate, useRecordContext } from 'react-admin'
import { Table, TableBody } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import FunctionField from '../../common/fields/FunctionField'
import Row from '../../common/cards/TableRow';
import { isAuthorized } from '../../common/Authorize';
import InfoCard from '../../common/cards/InfoCard';
import ContentField from '../../common/fields/ContentField';
import DateField from '../../common/fields/DateField';

const allroles = {
  'super-admin': 'Super Admins',
  'admin': 'Admins',
  'registrar': 'Registrars',
  'director': 'League Directors',
  'manager': 'League Managers',
  'organizer': 'Schedule Organizers',
  'assigning-manager': 'Assigning Managers',
  'assigner': 'Assigners',
  'official': 'Officials',
  'coach': 'Team Staff',
  'assistant': 'Team Assistants',
  'association': 'Associations',
  'editor': 'Editors',
  'scorekeeper': 'Scorekeepers',
}

const renderRoles = (article, source) => {
  const roles = article[source];
  return roles.map(role => allroles[role]).join(', ')
}

export const ArticleShowLayout = props => {
  const article = useRecordContext(props);
  const translate = useTranslate();

  const canEditArticle = isAuthorized(article, 'help', 'edit')
  return <InfoCard disableMaxHeight actions={
    canEditArticle && <EditButton label="resources.help.actions.edit" record={article} />
  }>
    {!article?.isPublished && <Alert severity="info">{translate('resources.help.alerts.article_not_published_yet')}</Alert>}
    <Table>
      <TableBody>
        <Row>
          <ContentField source="content" />
        </Row>
        <Row label="resources.help.fields.updatedAt">
          <DateField source="updatedAt" />
        </Row>
        <Row label="resources.help.fields.category">
          <TextField source="category" />
        </Row>
        <Row label="resources.help.fields.roles">
          <FunctionField source="roles" render={renderRoles} />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
