import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AppBar as MuiAppBar, Toolbar, IconButton, makeStyles, useMediaQuery } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { toggleSidebar, HideOnScroll } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { logo } from '../flags';

import { LanguageMenu } from './LanguageMenu';
import { IdentityMenu } from './IdentityMenu';
import { HelpMenu } from './HelpMenu';
import { InternalToolMenu, useCanShowInternalMenu } from './InternalToolMenu';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: theme.spacing(1),
  },
  menuButton: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    height: undefined,
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(.5),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
    '& svg': {
      width: 158,
      height: 28,
    },
  },
  menu: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize,
    minWidth: theme.spacing(4),
    padding: 0,
    marginRight: theme.spacing(2),
  }
}));

const Logo = ({ className }) =>
  <div className={className} dangerouslySetInnerHTML={{ __html: logo }}>
  </div>

const AppBar = ({
  children,
  classes: classesOverride,
  className,
  logo,
  open,
  title,
  userMenu,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const canShowInternalMenu = useCanShowInternalMenu();

  return <HideOnScroll>
    <MuiAppBar className={className} color="secondary" {...rest}>
      <Toolbar disableGutters variant="regular" className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(toggleSidebar())}
          className={classNames(classes.menuButton)}
        >
          <MenuIcon classes={{ root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed }} />
        </IconButton>

        <Link to="/">
          <Logo className={classes.logo} />
        </Link>

        <div className={classes.spacer}>{children}</div>

        {canShowInternalMenu && <InternalToolMenu className={classes.menu} />}
        {!isMobile && <HelpMenu className={classes.menu} />}
        <LanguageMenu className={classes.menu} />
        <IdentityMenu />
      </Toolbar>
    </MuiAppBar>
  </HideOnScroll>
};

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
};

export default AppBar;
