import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { AssignFeesForm } from './AssignFeesForm';

const AddAssignFeesDialog = props =>
  <CreateResourceDialog label="resources.assignFees.actions.add" resource="assignFees" basePath="/assignFees" {...props}>
    <AssignFeesForm />
  </CreateResourceDialog>

const EditAssignFeesDialog = props =>
  <EditResourceDialog undoableDelete={false} label="resources.assignFees.labels.edit" resource="assignFees" basePath="/assignFees" {...props}>
    <AssignFeesForm />
  </EditResourceDialog>

export const AddAssignFeesButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.assignFees.actions.add')}
    </Button>
    <AddAssignFeesDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const AssignFeesDatagrid = props => <DialogDatagrid dialog={EditAssignFeesDialog} {...props} />
