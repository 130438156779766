import React from 'react';
import { useMutation, useRefresh, useNotify, DELETE, useTranslate, RecordContextProvider, useRecordContext, useLocale } from 'react-admin';
import { Divider, Typography, Tooltip, IconButton, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import moment from 'moment-timezone';

import { isAuthorized } from '../../common/Authorize';
import { getDateFormat } from '../../common/fields/DateField';

import { ParticipantField } from '../participants/ParticipantField';

const DeleteButton = ({ className }) => {
  const message = useRecordContext();
  const [ mutate, { loading } ] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();

  const canDelete = isAuthorized(message, `gameMessages${message?.type}`, 'remove');
  if (!canDelete) return null;

  const onClick = event => {
    if (event) event.preventDefault();
    if (loading) return;
    mutate({
      type: DELETE,
      resource: 'gameMessages',
      payload: {
        id: message.id,
        data: message
      }
    }, {
      undoable: true,
      onSuccess() {
        notify('resources.gameMessages.notifications.deleted', 'info', {}, true);
        refresh()
      },
      onFailure() {
        notify('resources.gameMessages.notifications.delete_error', 'error')
      }
    })
  }

  return <Tooltip title={translate('resources.gameMessages.labels.delete')}>
    <IconButton size="small" className={className} disabled={loading} onClick={onClick}>
      <Delete />
    </IconButton>
  </Tooltip>
}

const useStyles = makeStyles(theme => ({
  empty: {
    color: theme.palette.grey[500],
    fontStyle: 'italic',
  },
  participant: {
    fontWeight: 500,
  },
  message: {
    margin: theme.spacing(.5),
  },
  body: {
    margin: theme.spacing(0, 1, 1),
    whiteSpace: 'pre-line',
  },
  buttons: {
    float: 'right',
  }
}))

export const MessageGrid = ({ ids, data, type }) => {
  const locale = useLocale();
  const classes = useStyles();
  const translate = useTranslate();
  if (!data || !ids || !ids.length) return <Typography variant="body2" className={classes.empty}>{translate('resources.gameMessages.messages.no_messages')}</Typography>;

  const dateFormat = getDateFormat(locale)
  const messages = ids.map(id => data[id]).filter(Boolean);
  return messages.map(message => {
    const timestamp = moment(message.createdAt)

    return <RecordContextProvider value={message}>
      <div className={classes.message}>
        <DeleteButton className={classes.buttons} />

        <ParticipantField source="participantId" link={false} className={classes.participant} />

        <Typography variant="overline"> - {
          moment().isSame(timestamp, 'day') ? `${translate('ra.date.lexical.today')}, ${timestamp.format('HH:mm z')}` :
            moment().isSame(timestamp, 'year') ? timestamp.format(`${dateFormat}, HH:mm z`) :
              timestamp.format('ll')
        }</Typography>

        <Typography variant="body2" paragraph className={classes.body}>{message.body}</Typography>

        <Divider />
      </div>
    </RecordContextProvider>
  })
}
