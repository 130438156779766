import React from 'react';
import { useRecordContext } from 'react-admin';

import InfoCard from '../../../common/cards/InfoCard';
import { isAuthorized } from '../../../common/Authorize';

import { ScheduleSettingsField } from '../../scheduleSettings/ScheduleSettingsField';
import { UpdateScheduleSettingsButton } from '../../scheduleSettings/ScheduleSettingsModal';

export default props => {
  const schedule = useRecordContext(props);

  const showSettings = isAuthorized(schedule, 'scheduleSettings', 'show');
  const canEditSettings = isAuthorized(schedule, 'scheduleSettings', 'edit');

  if (!showSettings) return null;
  return <InfoCard title="ra.message.settings" actions={
    canEditSettings && <UpdateScheduleSettingsButton scheduleId={schedule?.id} />
  }>
    <ScheduleSettingsField scheduleId={schedule?.id} />
  </InfoCard>
}
