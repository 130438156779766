import React, { useState } from 'react';
import { Button, useDataProvider, useListContext, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';

import { apiClient } from '../../http';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as MuiButton } from '@material-ui/core';

const recalculateScoresheet = (gameId) => {
  return apiClient(`/games/${gameId}/recalculateScoresheet`, { method: 'POST' });
}

const DeleteEventsDialog = ({ isOpen, onSubmit, onClose, selectedIds = [] }) => {
  const translate = useTranslate()
  return <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.gameEvents.labels.delete_events', selectedIds.length)}</DialogTitle>
    <DialogContent>
      <DialogContentText>{translate('resources.gameEvents.messages.confirm_delete_events', selectedIds.length)}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <MuiButton onClick={onClose}>{translate('ra.action.cancel')}</MuiButton>
      <MuiButton onClick={onSubmit} color="primary">{translate('ra.action.delete')}</MuiButton>
    </DialogActions>
  </Dialog>
}

const DeleteEvents = ({ selectedIds, resource, ...props }) => {
  const [ open, setOpen ] = useState(false);
  const game = useRecordContext()
  const dataProvider = useDataProvider();
  const { onUnselectItems } = useListContext();
  const translate = useTranslate()
  const refresh = useRefresh();
  const notify = useNotify();

  const onSubmit = () => {
    return Promise.all(selectedIds.map(eventId => dataProvider.delete('gameEvents', { id: eventId })))
      .then(res => {
        notify(translate('resources.gameEvents.notifications.deleted', { smart_count: res.length }), 'success')
        recalculateScoresheet(game.id)
        refresh()
        onUnselectItems()
      })
      .catch(err => {
        console.log('err :>>', err); /* eslint-disable-line no-console */
        notify(translate('resources.gameEvents.notifications.delete_error'), 'error')
      })
  }

  return <>
    <Button label="ra.action.delete" onClick={() => setOpen(true)} disabled={!selectedIds?.length} />
    <DeleteEventsDialog isOpen={open} onSubmit={onSubmit} onClose={() => setOpen(false)} selectedIds={selectedIds} />
  </>
}

export const EventBulkActions = props =>
  <DeleteEvents {...props} />
