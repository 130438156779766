import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';

const validate = values => {
  const errors = {}
  if (!values.firstName) errors.firstName = 'ra.validation.required';
  if (!values.lastName) errors.lastName = 'ra.validation.required';

  return errors;
}

const inputProps = {
  resource: 'participants',
  basePath: '/participants',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const ParticipantForm = props => {
  const [ isSynced, toggle ] = useRecordSynced(props);

  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm toolbar={<Toolbar isSynced={isSynced} />} validate={validate} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} sm={6}>
          <TextInput source="firstName" autoFocus disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="lastName" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="HCRId" helperText="ra.message.optional" disabled={isSynced} {...inputProps} />
        </Grid>
      </Grid>
    </SimpleForm>
  </>
}
