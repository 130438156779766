import React from 'react';
import { EditButton, useRecordContext } from 'react-admin'
import { Table, TableBody } from '@material-ui/core';

import Row from '../../common/cards/TableRow';
import { isAuthorized } from '../../common/Authorize';
import InfoCard from '../../common/cards/InfoCard';
import ContentField from '../../common/fields/ContentField';

import { ParticipantField } from '../participants/ParticipantField';
import { TargetField } from '../targets/TargetField'

export const AnnouncementTable = props =>
  <Table>
    <TableBody>
      <Row>
        <ContentField source="content" />
      </Row>
      <Row label="resources.announcements.fields.participantId">
        <ParticipantField source="participantId" filter={{ _scope: 'Tenant' }} />
      </Row>
      <Row label="resources.announcements.fields.targetId">
        <TargetField source="officeId" />
      </Row>
    </TableBody>
  </Table>

export const AnnouncementShowLayout = props => {
  const announcement = useRecordContext(props);
  const canEditAnnouncement = isAuthorized(announcement, 'announcements', 'edit')
  return <InfoCard actions={
    canEditAnnouncement && <EditButton label="resources.announcements.actions.edit" basePath="/announcements" record={announcement} />
  }>
    <AnnouncementTable />
  </InfoCard>
}

export const AnnouncementExpand = props => {
  const announcement = useRecordContext(props);
  const canEditAnnouncement = isAuthorized(announcement, 'announcements', 'edit');
  return <>
    <AnnouncementTable />
    {canEditAnnouncement && <EditButton label="resources.announcements.actions.edit" basePath="/announcements" record={announcement} />}
  </>
}
