import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { ListItemIcon, ListItemText } from '@material-ui/core'
import { UnarchiveOutlined } from '@material-ui/icons'

import { LazyDialog } from '../../../common/LazyDialog';
import { ActionMenuItem } from '../../../common/ActionMenu';

const Uploader = React.lazy(() => import('./Uploader'))

export default ({ isOpen, onClose, ...props }) => {
  return <LazyDialog>
    {isOpen && <Uploader isOpen={isOpen} onClose={onClose} {...props} />}
  </LazyDialog>
}

export const ImportDraftGamesAction = ({ onClick, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  const handleClick = () => {
    onClick();
    setOpen(true);
  }
  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><UnarchiveOutlined fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.draftGames.actions.upload')}</ListItemText>
    </ActionMenuItem>
    <LazyDialog>
      {isOpen && <Uploader isOpen={isOpen} onClose={() => setOpen(false)} {...props} />}
    </LazyDialog>
  </>
}
