import React from 'react';
import { Datagrid, TextField } from 'react-admin';

import { EnumField } from '../../common/inputs/EnumInputs';

export const OfficeGrid = props =>
  <Datagrid size="medium" {...props}>
    <TextField source="name" />
    <EnumField source="type" />
  </Datagrid>

