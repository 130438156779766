import React, {
  Children,
  cloneElement,
  Component,
  isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles, createStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Clear';
import { FormInput } from 'react-admin'
import { translate, ValidationError } from 'ra-core';
import classNames from 'classnames';


const styles = theme =>
  createStyles({
    root: {
      padding: 0,
      margin: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    line: {
      display: 'flex',
      alignItems: 'flex-start',
      listStyleType: 'none',
      paddingBottom: theme.spacing(1.5),
      // borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down('xs')]: { display: 'block' },
    },
    index: {
      width: '3em',
      paddingTop: theme.spacing(2.5),
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    form: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridColumnGap: `${theme.spacing(1)}px`,
      flex: 2,
    },
    action: {
      paddingTop: theme.spacing(1.5),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  });

export class SortedFormIterator extends Component {
  constructor(props) {
    super(props);
    // we need a unique id for each field for a proper enter/exit animation
    // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
    // so we keep an internal map between the field position and an autoincrement id
    this.nextId = props.fields.length
      ? props.fields.length
      : props.initialValues
        ? props.initialValues.length
        : 0;

    // We check whether we have a initialValues (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : [];
  }

  moveUp = index => () => {
    const { fields } = this.props;
    [this.ids[index - 1], this.ids[index]] = [this.ids[index], this.ids[index - 1]]
    fields.move(index, index - 1);
  }

  moveDown = index => () => {
    const { fields } = this.props;
    [this.ids[index + 1], this.ids[index]] = [this.ids[index], this.ids[index + 1]]
    fields.move(index, index + 1);
  }

  removeField = index => () => {
    const { fields } = this.props;
    this.ids.splice(index, 1);
    fields.remove(index);
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determing if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === 'boolean') {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  addField = () => {
    const { fields } = this.props;
    this.ids.push(this.nextId++);
    fields.push({});
  };

  render() {
    const {
      basePath,
      classes = {},
      children,
      fields,
      meta: { error, submitFailed },
      record,
      resource,
      source,
      translate,
      disableAdd,
      disableRemove,
      variant,
      margin,
    } = this.props;

    const records = get(record, source);
    return fields ? (
      <ul className={classes.root}>
        {submitFailed && typeof error !== "object" && error && (
          <FormHelperText error>
            <ValidationError error={error} />
          </FormHelperText>
        )}
        {fields.map((member, index) => (
          <li key={this.ids[index]} className={classes.line}>
            <Typography variant="body2" className={classes.index}>
              {index + 1}
            </Typography>
            <section className={classes.form}>
              {Children.map(children, (input, index2) =>
                isValidElement(input) ? (
                  <FormInput
                    basePath={input.props.basePath || basePath}
                    input={cloneElement(input, {
                      source: input.props.source ? `${member}.${input.props.source}` : member,
                      index: input.props.source ? undefined : index2,
                      label: input.props.label || input.props.source,
                    })}
                    record={(records && records[index]) || {}}
                    resource={resource}
                    variant={variant}
                    margin={margin}
                  />
                ) : null
              )}
            </section>
            <span className={classes.action}>
              <Button
                className={classNames('button-move=up', `button-move=up-${source}-${index}`)}
                size="small"
                onClick={this.moveUp(index)}
                disabled={index === 0}
              >
                <ArrowUpwardIcon className={classes.leftIcon} />
              </Button>
            </span>
            <span className={classes.action}>
              <Button
                className={classNames('button-move=up', `button-move=up-${source}-${index}`)}
                size="small"
                onClick={this.moveDown(index)}
                disabled={index === (fields.length - 1)}
              >
                <ArrowDownwardIcon className={classes.leftIcon} />
              </Button>
            </span>
            {!this.disableRemoveField((records && records[index]) || {}, disableRemove) && (
              <span className={classes.action}>
                <Button
                  className={classNames('button-remove', `button-remove-${source}-${index}`)}
                  size="small"
                  onClick={this.removeField(index)}
                >
                  <CloseIcon className={classes.leftIcon} />
                </Button>
              </span>
            )}
          </li>
        ))}
        {!disableAdd && (
          <li className={classes.line}>
            <span className={classes.action}>
              <Button
                className={classNames('button-add', `button-add-${source}`)}
                size="small"
                onClick={this.addField}
              >
                <AddIcon className={classes.leftIcon} />
                {translate('ra.action.add')}
              </Button>
            </span>
          </li>
        )}
      </ul>
    ) : null;
  }
}

SortedFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

SortedFormIterator.propTypes = {
  initialValues: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default compose(
  translate,
  withStyles(styles)
)(SortedFormIterator);
