import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';
import { AttributeValuesForm } from './AttributeValuesForm';

const AddAttributeValuesDialog = props =>
  <CreateResourceDialog label="resources.attributeValues.actions.add" resource="attributeValues" basePath="/attributeValues" {...props}>
    <AttributeValuesForm />
  </CreateResourceDialog>

const EditAttributeValuesDialog = props =>
  <EditResourceDialog label="resources.attributeValues.labels.edit" resource="attributeValues" basePath="/attributeValues" {...props}>
    <AttributeValuesForm />
  </EditResourceDialog>

export const AddAttributeValuesButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.attributeValues.actions.add')}</Button>
    <AddAttributeValuesDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const AttributeValuesDatagrid = props => <DialogDatagrid dialog={EditAttributeValuesDialog} {...props} />
