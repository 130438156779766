import React, { useEffect, useRef } from 'react';

import { PreviewFailed } from './FileViewer';
import styles from "./FileViewer.module.scss";

const AudioFile = ({ audioSource, fileType, canDownload, downloadFile, onPreviewFail }) => {
  const audioTag = useRef(null);
  useEffect(() => {
    audioTag.current.volume = 0.5;
  }, []);
  return (
    <audio ref={audioTag} controls autoPlay className={styles.Preview}>
      <source src={audioSource} type={fileType} onError={onPreviewFail} />
      {/* Acts as a loadFail */}
      <PreviewFailed type={fileType} canDownload={canDownload} downloadFile={downloadFile} />
    </audio>
  );
};

export default AudioFile;
