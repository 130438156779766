import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { AddressForm } from './AddressForm';

const AddAddressDialog = props =>
  <CreateResourceDialog label="resources.addresses.actions.add" resource="addresses" basePath="/addresses" {...props}>
    <AddressForm />
  </CreateResourceDialog>

const EditAddressDialog = props =>
  <EditResourceDialog label="resources.addresses.labels.edit" resource="addresses" basePath="/addresses" {...props}>
    <AddressForm />
  </EditResourceDialog>

export const AddAddressButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate()
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.addresses.actions.add')}</Button>
    <AddAddressDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const AddressDatagrid = props => <DialogDatagrid dialog={EditAddressDialog} {...props} />
