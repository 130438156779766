import React from 'react';
import { ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core';


import FunctionField from '../../common/fields/FunctionField'

import { AccountField } from '../accounts/AccountField';
import { ParticipantField } from '../participants/ParticipantField';
import { IdentityDatagrid } from './IdentityModal';
import { SMAChip } from '../../common/chips/SMAChip';


const useStyles = makeStyles(theme => ({
  headerRow: {
    display: 'none',
  },
  row: {
    '& .sso': {
      width: theme.spacing(0),
    },
  }
}))

const PrimaryField = props =>
  <FunctionField {...props} render={(identity, source, resource, translate) => {
    if (identity.isPrimary) return translate('resources.participants.fields.isPrimary')
  }} />

const ExternalIdField = props => {
  return <ReferenceField basePath="/accounts" resource="accounts" reference="accounts" link={false} {...props}>
    <FunctionField {...props} render={(account, source, resource, translate) => {
      if (account.externalId) return <SMAChip />
    }} />
  </ReferenceField>
}

export const IdentityGrid = ({ showAccount = true, showParticipant = true, component: Datagrid = IdentityDatagrid, ...props }) => {
  const classes = useStyles()

  return <Datagrid classes={classes} size="medium" {...props}>
    {showAccount && <ExternalIdField cellClassName="sso" source="accountId" />}
    {showAccount && <AccountField source="accountId" allowEmpty label="" />}
    {showParticipant && <ParticipantField source="participantId" allowEmpty label="" />}
    <PrimaryField />
  </Datagrid>
}
