import React, { Fragment, useRef, useState } from 'react';
import { Button, NumberInput, RecordContextProvider, SimpleForm, useLocale, useNotify, useRefresh, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Dialog, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText, makeStyles, styled } from '@material-ui/core';
import { DeviceHub } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import createCalculator from 'final-form-calculate';
import Iframe from 'react-iframe';
import { IntlProvider } from "react-intl";

import { translateApiProperty } from '@hisports/common';

import { apiClient, useHttpClient } from '../../../http';
import { ActionMenuItem } from '../../../common/ActionMenu';
import { EnumInput } from '../../../common/inputs/EnumInputs';
import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import FileViewer from '../../../common/fileViewer/FileViewer';

import ScheduleSequenceAlert from '../../schedulesequences/ScheduleSequenceAlert';
import ScheduleSequenceError from '../../schedulesequences/ScheduleSequenceError';
import { ScheduleGroupInput } from '../../groups/GroupInput';

export const BracketIcon = styled(DeviceHub)({
  transform: 'rotate(-90deg)'
})

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true
}

const useStyles = makeStyles(theme => ({
  details: {
    padding: 0
  },
  frame: {
    width: '100%',
    height: theme.spacing(40),
    border: '1px black solid'
  },
  groupInput: {
    marginTop: theme.spacing(2)
  }
}))

const validate = (values) => {
  const errors = {};
  if (!values.totalTeams) {
    errors.totalTeams = 'ra.validation.required'
  } else if (values.totalTeams <= 0) {
    errors.totalTeams = 'ra.validation.greater_than_zero'
  }
  if (!values.format) errors.format = 'ra.validation.required'
  if (!values.bracketId) errors.bracketId = 'ra.validation.required'
  return errors;
}

const Toolbar = ({ handleSubmit, onClose }) => {
  return <DialogFormToolbar
    onCancel={() => onClose(true)}
    handleSubmit={handleSubmit}
    submitLabel="ra.action.publish"
  />
}

const FormatInput = props => {
  const locale = useLocale()
  return <EnumInput {...props}
    optionText={value => translateApiProperty(value, 'name', locale) }
    radio
  />
}

const BracketInput = ({ brackets = [], ...props }) => {
  const locale = useLocale()
  const translate = useTranslate();
  const { values: { totalTeams, format } } = useFormState();

  const choices = brackets?.filter(bracket => {
    if (totalTeams != bracket.teams) return false;
    if (format !== bracket.type) return false;
    return true;
  })

  const selected = totalTeams && format;
  if (!selected) return null;
  return <EnumInput {...props}
    choices={choices}
    optionText={bracket => translateApiProperty(bracket, 'variant', locale)}
    helperText={selected && !choices?.length ? translate('resources.schedules.labels.brackets_na') : ' '}
    radio
  />
}

const PreviewCard = ({ brackets }) => {
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();
  const [ fileOpen, setFileOpen ] = useState(false);
  const { values: { bracketId } } = useFormState();

  if (!bracketId) return null;
  const bracket = brackets.find(bracket => bracket.id === bracketId)
  const url = translateApiProperty(bracket, 'url', locale);
  if (!url) return null;

  return <>
    <Iframe url={`${url}/svg`} className={classes.frame} />
    <IntlProvider>
      <Button onClick={() => setFileOpen(true)}><span>{translate('resources.schedules.labels.brackets_viewer.open_in_viewer')}</span></Button>
      {fileOpen && <FileViewer
        isOpen={fileOpen}
        close={() => setFileOpen(false)}
        fileName={`${bracketId}`}
        fullPath={`${url}/svg`}
        type="svg"
        openInNewTabURL={`${url}/svg`}
        URLToFetchForDownload={`${url}/pdf`}
      />}
    </IntlProvider>
  </>
}

const BracketForm = ({ scheduleId, onClose }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const { data: { models: brackets, types: formats }, loading, error } = useHttpClient('/schedules/bracketModels', { models: [], types: [] })

  const refresh = useRefresh();

  const handleSave = values => {
    return apiClient(`/schedules/${scheduleId}/importBrackets`, {
      method: 'POST',
      data: {
        bracketId: values.bracketId,
        groupId: values.groupId
      }
    })
      .then(() => {
        refresh();
        onClose();
        notify('resources.schedules.notifications.bracket_games_created')
      })
      .catch(e => {
        // page error
        if (!e.isAxiosError) {
          notify('ra.page.error_try_again_refresh')
          return;
        }

        // validation error
        const { error } = e.response.data;
        if ((error?.status === 422 || error?.statusCode === 422) && error?.message) {
          const errorMsg = translate(`resources.schedules.errors.brackets.${error.message}`)

          if (error.message === 'missing_sequence') {
            notify(<ScheduleSequenceError errorMsg={errorMsg} scheduleId={scheduleId} />, 'error')
          } else {
            notify(errorMsg)
          }
          return;
        }

        // api error
        return notify('ra.page.error_try_again')
      })
  }

  // decorators must be wrapped in useRef otherwise SimpleForm prop will register duplicates
  // this bug seems to be triggered by use of hooks (useSelector)
  const decorators = useRef([createCalculator({
    field: 'format',
    updates: {
      bracketId: () => null,
    },
  }, {
    field: 'totalTeams',
    updates: {
      bracketId: () => null,
    },
  })])

  if (error) return <p><Alert severity="error">{translate('ra.page.error_try_again_refresh')}</Alert></p>
  if (loading) return <p>{translate('ra.page.loading')}</p>

  return <SimpleForm
    component={Fragment}
    validate={validate}
    initialValues={{ totalTeams: 10, format: 'DE' }}
    toolbar={<Toolbar onClose={onClose} />}
    decorators={decorators.current}
    save={handleSave}
  >
    <NumberInput source="totalTeams" helperText="" label="resources.schedules.fields.brackets.totalTeams" {...inputProps} />
    <ScheduleGroupInput source="groupId" className={classes.groupInput} scheduleId={scheduleId} label="resources.schedules.fields.brackets.groupId" showNone={translate('ra.message.no_group')} {...inputProps} />
    <FormatInput source="format" helperText="" label="resources.schedules.fields.brackets.format" choices={formats} {...inputProps} />
    <BracketInput source="bracketId" helperText="" label="resources.schedules.fields.brackets.bracketId" brackets={brackets} {...inputProps} />
    <PreviewCard brackets={brackets} />
  </SimpleForm>
}

export const BracketDialog = ({ scheduleId, isOpen, onClose }) => {
  const translate = useTranslate();

  return <RecordContextProvider value={null}>
    <Dialog open={isOpen} onClose={() => onClose(true)} maxWidth="md" fullWidth>
      <DialogTitle>{translate('resources.schedules.labels.create_bracket')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{translate('resources.schedules.labels.brackets_info')}</DialogContentText>
        <ScheduleSequenceAlert scheduleId={scheduleId} style={{ marginBlock: 16 }} />
        <BracketForm scheduleId={scheduleId} onClose={onClose} />
      </DialogContent>
    </Dialog>
  </RecordContextProvider>
}

export const AddBracketButton = ({ scheduleId, onClick, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  const handleClick = () => {
    onClick();
    setOpen(true);
  }
  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><BracketIcon fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.schedules.labels.bracket_schedule')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <BracketDialog scheduleId={scheduleId} isOpen={isOpen} onClose={() => setOpen(false)} {...props} />}
  </>
}
