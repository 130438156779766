import React, { useRef } from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux';
import createCalculator from 'final-form-calculate';
import { kebabCase } from 'lodash';

import Toolbar from '../../common/ra/Toolbar';
import { InfractionSeverityInput } from '../../common/inputs/EnumInputs';

import { RuleInput } from './RuleInput';
import { InfractionTypeInput } from './InfractionTypeInput';
import { InfractionInput } from './InfractionInput';

const validate = values => {
  const errors = {};

  if (!values.ruleId) errors.ruleId = 'ra.validation.required'
  if (!values.typeId) errors.typeId = 'ra.validation.required'
  if (!values.severity) errors.severity = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.legacyName) errors.legacyName = 'ra.validation.required'
  if (!values.legacyDuration) errors.legacyDuration = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'infractions',
  basePath: '/infractions',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const ParentInfractionInput = ({ office, ...props }) => {
  // hide infractions of current office
  const hasParentOffice = !!office?.parentId
  const getOptionDisabled = infraction => {
    if (infraction?.officeId === office?.id) return true
    return false
  }

  return <InfractionInput
    source="parentId"
    filter={{ effectiveOffices: office?.id, includeParents: true, _scope: 'Parents' }}
    helperText="resources.infractions.helpers.parentId"
    includeOffice
    disabled={!hasParentOffice}
    getOptionDisabled={getOptionDisabled}
    hideDisabled
    {...props}
  />
}


export default ({ office, ...props }) => {
  // must use ref for roles to get current value, otherwise value in calculator is stale
  const rules = useSelector(store => store.admin.resources.rules.data);
  const rulesRef = useRef({});
  rulesRef.current = rules;

  const types = useSelector(store => store.admin.resources.infractionTypes.data);
  const typesRef = useRef({});
  typesRef.current = types;

  // decorators must be wrapped in useRef otherwise SimpleForm prop will register duplicates
  // this bug seems to be triggered by use of hooks (useSelector)
  const decorators = useRef([createCalculator({
    field: 'ruleId',
    updates: {
      legacyName: (ruleId, values, prevValues) => {
        if (!ruleId || values.legacyName) return values.legacyName;
        const rule = rulesRef.current[ruleId];
        if (!rule) return values.legacyName;
        return kebabCase(rule.name).replace('-the', '');
      }
    }
  }, {
    field: 'typeId',
    updates: {
      legacyDuration: (typeId, values, prevValues) => {
        if (!typeId || values.legacyDuration) return values.legacyDuration;
        const type = typesRef.current[typeId];
        if (!type) return values.legacyDuration;
        return kebabCase(type.name);
      }
    }
  })])

  return <SimpleForm toolbar={<Toolbar />} decorators={decorators.current} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <RuleInput source="ruleId" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput source="code" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InfractionTypeInput source="typeId" filter={{ officeId: office?.id }} helperText="" select {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <InfractionSeverityInput source="severity" helperText="" radio {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput source="legacyName" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput source="legacyDuration" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ParentInfractionInput source="parentId" office={office} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
