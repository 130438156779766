import React from 'react';
import { List as RAList, Pagination, useResourceContext } from 'react-admin';

import { isAuthorized } from '../Authorize';

import ListActions from './ListActions';

export function List({ actions, customActions, listActionsClass, rowsPerPageOptions = [ 25, 50, 100 ], exporter = false, resetFilters, ...props }) {
  const resource = useResourceContext(props);
  return <RAList
    perPage={25}
    exporter={exporter}
    bulkActionButtons={false}
    empty={false}
    pagination={<Pagination rowsPerPageOptions={rowsPerPageOptions} />}
    actions={actions || <ListActions actions={customActions} className={listActionsClass} resetFilters={resetFilters} />}
    {...props}
    hasCreate={!props.hasCreate ? false : isAuthorized(props.permissions, resource, 'create')}
  />
}
