import { byParticipantId, byTeamId } from '../util/index.js'

export const getThrowsInnings = (state, { teamId, participantId } = {}) => {
  if (!state) return [];
  const throwsInnings = state.throwsInnings || [];
  if (teamId) {
    return throwsInnings.filter(byTeamId(teamId))
  }
  if (participantId) {
    return throwsInnings.find(byParticipantId(participantId))
  }
  return throwsInnings;
}
