import React, { useRef, useLayoutEffect } from 'react';
import { useTranslate } from 'react-admin';
import { ListItem, ListItemText, ListItemIcon, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import {
  CheckCircleOutline as AssignedIcon,
  Announcement as RequestedIcon,
  RemoveCircleOutline as RemovedIcon,
  ReportProblemOutlined as ExpiredIcon,
  Block as EligibilityIcon,
  Group as ConflictIcon,
  Schedule as ScheduleIcon,
  DirectionsRun as TravelIcon,
  Today as AssignedSameDayIcon,
  EventBusy as UnavailableIcon,
  Voicemail as AccountIcon,
  HomeOutlined as LocalIcon,
} from '@material-ui/icons';
import { FixedSizeList as List } from 'react-window';

import { hasAnyFlag, isIneligible, filterFlags, isExpired, getMeta } from '../util';
import { useAssignmentContext } from '../AssignmentContext';

const useOfficialStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(4),
  },
  inset: {
    paddingLeft: theme.spacing(4),
  },
  ineligible: {
    color: theme.palette.grey[400],
  }
}))

const OfficialIcon = ({ flags, meta }) => {
  const translate = useTranslate()
  const theme = useTheme()

  const isUnavailable = hasAnyFlag(flags, ['Unavailable', 'Availability', 'Office', 'Arena'])
  const isAssigned = hasAnyFlag(flags, ['Assigned'])
  const isRequested = hasAnyFlag(flags, ['Requested'])
  const isRemoved = hasAnyFlag(flags, ['Removed'])
  const isExpired = hasAnyFlag(flags, ['Expired'])
  const isIneligible = hasAnyFlag(flags, ['Scorekeeper Level', 'Timekeeper Level', 'Official Level', 'Referee Level', 'Linesperson Level', 'Referee Grade', 'Linesperson Grade'])
  const isConflict = hasAnyFlag(flags, ['Team Conflict', 'Roster'])
  const isSchedule = hasAnyFlag(flags, ['Roster Conflict', 'Game Conflict', 'Game Overlap'])
  const isTravel = hasAnyFlag(flags, ['Travel', 'At Arena'])
  const isAssignedSameDay = hasAnyFlag(flags, ['Assigned Today'])
  const isAccount = hasAnyFlag(flags, ['Not Verified', 'Invited', 'Not Registered'])
  const isLocal = hasAnyFlag(flags, ['Local'])
  const hasRequestedConflict = getMeta(meta, 'Requested', 'conflict')

  const types = [];
  if (isAssigned) types.push(translate('resources.games.labels.assignment.status.assigned'))
  if (isRequested && !hasRequestedConflict) types.push(translate('resources.games.labels.assignment.status.requested'))
  if (isRemoved) types.push(translate('resources.games.labels.assignment.status.removed'))
  if (isExpired) types.push(translate('resources.games.labels.assignment.status.expired'))
  if (isIneligible) types.push(translate('resources.games.labels.assignment.status.ineligible'))
  if (isConflict) types.push(translate('resources.games.labels.assignment.status.conflicts'))
  if (isSchedule) types.push(translate('resources.games.labels.assignment.status.schedule'))
  if (isTravel) types.push(translate('resources.games.labels.assignment.status.travel'))
  if (!isTravel && !isSchedule && isAssignedSameDay) types.push(translate('resources.games.labels.assignment.status.assigned_today'))
  if (isUnavailable) types.push(translate('resources.games.labels.assignment.status.unavailable'))
  if (isAccount) types.push(translate('resources.games.labels.assignment.status.account'))
  if (isLocal) types.push(translate('resources.games.labels.assignment.status.local'))

  let icon;
  if (isAssigned) {
    icon = <AssignedIcon fontSize="small" htmlColor={theme.palette.success.main} />
  } else if (isRequested && !hasRequestedConflict) {
    icon = <RequestedIcon fontSize="small" />
  } else if (isRemoved) {
    icon = <RemovedIcon fontSize="small" />
  } else if (isExpired) {
    icon = <ExpiredIcon fontSize="small" />
  } else if (isIneligible) {
    icon = <EligibilityIcon fontSize="small" />
  } else if (isConflict) {
    icon = <ConflictIcon fontSize="small" />
  } else if (isSchedule) {
    icon = <ScheduleIcon fontSize="small" />
  } else if (isTravel) {
    icon = <TravelIcon fontSize="small" />
  } else if (!isTravel && !isSchedule && isAssignedSameDay) {
    icon = <AssignedSameDayIcon fontSize="small" />
  } else if (isUnavailable) {
    icon = <UnavailableIcon fontSize="small" />
  } else if (isAccount) {
    icon = <AccountIcon fontSize="small" />
  } else if (isLocal) {
    icon = <LocalIcon fontSize="small" />
  }

  if (!icon) return null;
  return <Tooltip title={types.join(', ')}>{icon}</Tooltip>
}

export const OfficialListItem = React.memo(({ filters, assignment, official, current, selected, onSelect, style }) => {
  const classes = useOfficialStyles();

  const flags = filterFlags(official.flags, assignment.position, filters);

  const ineligible = isIneligible(flags) || isExpired(flags);
  const isNoShow = assignment.status === 'no-show'

  let icon = <OfficialIcon flags={flags} meta={official.meta} />
  if (icon) icon = <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>

  const handleClick = () => {
    onSelect(official.participantId)
  }

  return <ListItem dense button
    selected={selected}
    disabled={isNoShow && !current}
    onClick={handleClick}
    style={style}
  >
    {icon}
    <ListItemText
      primary={official.participant.fullName?.toUpperCase()}
      primaryTypographyProps={{
        classes: {
          root: ineligible ? classes.ineligible : null,
        },
      }}
      classes={{
        inset: classes.inset,
      }}
      inset={!icon}
    />
  </ListItem>
})

const OfficialRow = ({ index, style, data }) => {
  const { officials, assignment, selectedId, onSelect, filters } = data;

  const official = officials[index];
  const isSelected = official && official.participantId === selectedId
  const isCurrent = assignment && assignment.participantId === official.participantId

  return <OfficialListItem key={index} style={style} assignment={assignment} official={official} current={isCurrent} selected={isSelected} onSelect={onSelect} filters={filters} />
}

const useListStyles = makeStyles(theme => ({
  empty: {
    margin: theme.spacing(.5, 5),
    fontStyle: 'italic',
  }
}))

export const OfficialList = ({ officials, onSelect }) => {
  const { assignment, selectedId, filters, allOfficials } = useAssignmentContext()
  const classes = useListStyles();
  const translate = useTranslate();
  const ref = useRef()

  useLayoutEffect(() => {
    if (!selectedId || !ref || !ref.current) return;
    const index = officials.findIndex(official => official.participantId === selectedId);
    ref.current.scrollToItem(index, 'smart')
  }, [ officials, selectedId, ref ])

  if (!officials.length) return <div className={classes.empty}>
    {translate('ra.message.no_results_filtered', allOfficials.length)}
  </div>;

  return <List
    ref={ref}
    height={8 * (42 - 7)}
    width={8 * 38}
    itemSize={36}
    itemCount={officials.length}
    itemData={{ officials, assignment, selectedId, onSelect, filters }}
  >
    {OfficialRow}
  </List>
}
