import React from 'react';
import { Datagrid, TextField, NumberField } from 'react-admin';
import { OfficeField } from '../offices/OfficeField';
import { SectionField } from '../sections/SectionField';

export const PageGrid = props =>
  <Datagrid size="medium" {...props}>
    <TextField source="title" />
    <SectionField source="sectionId" />
    <NumberField source="order" />
    <OfficeField source="officeId" />
  </Datagrid>
