import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import XLSX from 'xlsx';
import { downloadCSV, useDataProvider, useListContext, useRecordContext, useTranslate } from 'react-admin';

import { appName } from '../flags';
import { apiClient } from '../http';

const OPTIONS = {
  officialTransactions: ['details_csv', 'details_xlsx', 'summary_csv', 'summary_xlsx'],
  gamepenalties: ['export_csv', 'export_xlsx']
}

const getData = async (resource, dataProvider, filterValues = {}, sort, include, target, targetId) => {
  const filters = { ...filterValues }

  if (target && targetId) {
    filters[target] = targetId;
  }

  if (resource === 'gamepenalties' && target === 'officeId' && targetId) {
    // remove includeMeta to skip observer
    delete filters._options.includeMeta;

    return await apiClient(`/gamepenalties/export`, {
      params: { filter: { where: filters, include } }
    })
      .then(res => res.data)
  }

  return await dataProvider.getList(resource, {
    filter: { _include: include, ...filters },
    sort,
    pagination: { page: 1, perPage: 9999 }
  }, { enabled: !!resource })
    .then(res => res.data);
}

export const exportFile = (csv, fileName = appName, fileType = 'csv') => {
  switch (fileType) {
    case 'xlsx': {
      const wb = XLSX.read(csv, { type: 'string', raw: true });
      return XLSX.writeFile(wb, `${fileName}.xlsx`, { blankrows: false });
    }

    case 'csv':
    default:
      return downloadCSV(csv, fileName);
  }
}

export const makeExporter = (exportData, include) => ({ target, showSummary = false, ...props }) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const { sort, resource, filterValues, filter } = useListContext();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const displayedOptions = OPTIONS[resource].filter(option => showSummary || !option.includes('summary'))

  const exportReport = (report, fileType) => {
    const filters = { ...filterValues, ...filter }
    getData(resource, dataProvider, filters, sort, include, target, record.id)
      .then(data => exportData(data, translate, fileType, report, props))
  };

  const handleClick = (index) => {
    const option = OPTIONS[resource][index];

    switch (option) {
      case 'export_csv':
        exportReport(null, 'csv');
        break;
      case 'export_xlsx':
        exportReport(null, 'xlsx');
        break;
      case 'summary_csv':
        exportReport('summary', 'csv');
        break;
      case 'summary_xlsx':
        exportReport('summary', 'xlsx');
        break;
      case 'details_csv':
        exportReport('details', 'csv');
        break
      case 'details_xlsx':
      default:
        exportReport('details', 'xlsx');
        break;
    }

    setOpen(false);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;

    setOpen(false);
  };

  return <>
    <ButtonGroup variant="text" color="primary" ref={anchorRef}>
      <Button
        color="primary"
        size="small"
        onClick={handleToggle}
        endIcon={<ArrowDropDown />}
      >
        {translate('ra.action.export')}
      </Button>
    </ButtonGroup>
    <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" transition style={{ zIndex: '2' }}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {displayedOptions.map((option, index) => (
                  <MenuItem
                    key={option}
                    onClick={() => handleClick(index)}
                  >
                    {translate(`components.exporter.actions.${option}`)}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
}
