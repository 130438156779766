import React, { Fragment, useState, useRef, useLayoutEffect } from 'react';
import { RecordContextProvider, SimpleForm, useDataProvider, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin'
import { Dialog, DialogTitle, DialogContent, IconButton, Tooltip, makeStyles, LinearProgress, Stepper, StepLabel, Step, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import { Close } from '@material-ui/icons';
import createCalculator from 'final-form-calculate';
import moment from 'moment-timezone';

import { FF_DRAFT_DEFAULT_ROUNDS, FF_DRAFT_DEFAULT_SLOTS, FF_DRAFT_LIMIT_BYES } from '@hisports/common/featureFlags';
import { createRange } from '@hisports/common';

import { DialogFormToolbar } from '../../../../common/dialogs/DialogForm';
import { useFlag } from '../../../../http';

import { GeneratorContextProvider, useGeneratorContext } from './GeneratorContext';
import { Preview } from './Preview';
import { Scheduling } from './Scheduling';
import { Matchups } from './Matchups';
import { Matrix } from './Matrix';
import ScheduleSequenceAlert from '../../../schedulesequences/ScheduleSequenceAlert';

export const METHODS = {
  GAMES_PER_TEAM: 'Games per Team',
  TOTAL_ROUNDS: 'Total Rounds'
}

export const TYPES = {
  SINGLE_ROTATION: 'Single Rotation',
  DOUBLE_ROTATION: 'Double Rotation',
  MATRIX: 'Matrix'
}

export const ROUND_TYPES = {
  HOME_SLOTS: 'Home Slots',
  WEEKDAYS: 'Weekdays',
  DATES: 'Dates'
}

export const WEEKDAYS_TYPES = {
  ROTATING: 'Rotating',
  CONSECUTIVE: 'Consecutive'
}

export const STEPS = {
  MATCHUPS: 0,
  MATRIX: 1,
  SCHEDULING: 2,
  PREVIEW: 3,
}

const isEven = n => n % 2 === 0;

const useStyles = makeStyles(theme => ({
  buttons: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: 'white',
    position: 'sticky',
    bottom: theme.spacing(-1),
    zIndex: 10,
    borderTop: `1px solid ${theme.palette.divider}`,
  }
}))

const formOptions = {
  destroyOnUnmount: true,
  enableReinitialize: false,
  forceUnregisterOnUnmount: true,
}

const validate = (values = {}, activeStep, schedule, scheduleTeams = [], translate) => {
  const errors = {};
  const { method, roundType, weekdays, breaks, type } = values

  // matchup step
  if (activeStep === STEPS.MATCHUPS) {
    if (method === METHODS.GAMES_PER_TEAM) {
      if (values.gamesPerTeam <= 0 || values.gamesPerTeam > 999) {
        errors.gamesPerTeam = 'ra.validation.invalid_number'
      }
      if (values.gamesPerTeam == null) {
        errors.gamesPerTeam = 'ra.validation.required'
      }
      if (type === TYPES.MATRIX && !isEven(values.gamesPerTeam) && !isEven(scheduleTeams.length)) {
        errors.gamesPerTeam = 'resources.draftGames.validations.manual_odd_numbers'
      }
    }
    if (method === METHODS.TOTAL_ROUNDS) {
      if (values.totalRounds <= 0 || values.totalRounds > 999) {
        errors.totalRounds = 'ra.validation.invalid_number'
      }
      if (values.totalRounds == null) {
        errors.totalRounds = 'ra.validation.required'
      }
    }

    if (values.startRound != null && values.startRound <= 0) {
      errors.startRound = 'ra.validation.invalid_number'
    }
  }

  // scheduling step
  if (activeStep === STEPS.SCHEDULING) {
    if (roundType === ROUND_TYPES.WEEKDAYS) {
      if (!weekdays?.length) errors.weekdays = 'ra.validation.required'
    }

    if ([ROUND_TYPES.HOME_SLOTS, ROUND_TYPES.WEEKDAYS].includes(roundType)) {
      if (!values.startDate) errors.startDate = 'ra.validation.required'
      let day, startDate;
      if (startDate) {
        startDate = moment.utc(values.startDate, 'YYYY-MM-DD')
        day = startDate.format('dddd');
      }

      const earliestDate = startDate && moment.utc(startDate, 'YYYY-MM-DD').add(1, 'week');

      if (breaks) {
        errors.breaks = breaks?.map((brk, index, breaks) => {
          if (!brk) return {};
          const error = {}

          const brkStart = moment.utc(brk.startDate, 'YYYY-MM-DD')
          if (!brk.startDate || !brkStart.isValid()) {
            error.startDate = 'ra.validation.required';
          } else {
            if (day && brkStart.format('dddd') !== day) error.startDate = translate('resources.draftGames.validations.must_start_on_day', { day });
            if (earliestDate && brkStart.isBefore(earliestDate, 'day')) error.startDate = translate('resources.draftGames.validations.must_be_after', { date: moment(earliestDate).subtract(1, 'day').format('YYYY-MM-DD') });
          }

          if (!brk.totalWeeks) {
            error.totalWeeks = 'ra.validation.required'
          } else if (brk.totalWeeks <= 0) {
            error.totalWeeks = 'ra.validation.invalid_number';
          }

          if (brk.startDate && brk.totalWeeks > 0 && !error.startDate) {
            const brkEnd = moment.utc(brkStart).add(brk.totalWeeks + 1, 'weeks')

            if (schedule?.endDate) {
              const scheduleEndDate = moment.utc(schedule.endDate, 'YYYY-MM-DD')
              if (brkEnd.isAfter(scheduleEndDate, 'day')) error.startDate = translate('resources.draftGames.validations.must_be_before_schedule_end_date', { date: moment(scheduleEndDate).format('YYYY-MM-DD') })
            }
            if (index > 0) {
              const prev = breaks[index - 1];
              const brkRange = createRange(brkStart, brkEnd);
              if (prev?.startDate && prev?.totalWeeks) {
                const prevBrkStart = moment.utc(prev.startDate, 'YYYY-MM-DD');
                const prevBrkEnd = moment.utc(prev.startDate, 'YYYY-MM-DD').add(prev.totalWeeks + 1, 'weeks')
                const prevBrkRange = createRange(prevBrkStart, prevBrkEnd)
                if (brkRange.overlaps(prevBrkRange)) error.startDate = translate('resources.draftGames.validations.must_be_after', { date: prevBrkEnd.subtract(1, 'day').format('YYYY-MM-DD') })
              }
            }
          }

          return error;
        })
      }
    }
  }

  return errors;
}

const GeneratorAlert = props => {
  const { alert, activeStep, loading, loaded } = useGeneratorContext()
  const classes = useStyles();

  if (!alert?.message || loading || !loaded || (alert?.step != null && alert.step !== activeStep)) return null

  return <Alert severity={alert.severity} className={classes.alert} {...props}>
    {alert.message}
  </Alert>
}

const GeneratorToolbar = props => {
  const { alert, loaded } = useGeneratorContext()

  return <DialogFormToolbar {...props} disableSubmit={alert?.severity === 'error' || !loaded} />
}

const GeneratorStepper = ({ showMatrix }) => {
  const translate = useTranslate();
  const { activeStep } = useGeneratorContext()

  const steps = [
    translate('resources.draftGames.labels.steps.matchups'),
    showMatrix && translate('resources.draftGames.labels.steps.matrix'),
    !showMatrix && translate('resources.draftGames.labels.steps.scheduling'),
    translate('resources.draftGames.labels.steps.preview'),
  ].filter(Boolean)

  let displayStep = activeStep

  if (activeStep === STEPS.MATRIX || activeStep === STEPS.SCHEDULING) {
    displayStep = 1
  } else if (activeStep === STEPS.PREVIEW) {
    displayStep = 2
  }

  return <Stepper activeStep={displayStep}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
}

const GeneratorStep = ({ showMatrix, dialogContentHeight, ...props }) => {
  const { activeStep, loading, loaded } = useGeneratorContext()

  if (loading || !loaded) return <LinearProgress />

  switch (activeStep) {
    case STEPS.MATCHUPS:
      return <Matchups {...props} />
    case STEPS.MATRIX:
      return <Matrix {...props} source="matrixTable" maxHeight={dialogContentHeight} />
    case STEPS.SCHEDULING:
      return <Scheduling {...props} />
    case STEPS.PREVIEW:
      return <Preview {...props} />;
  }
  return null;
}

const GeneratorForm = ({ schedule, ...props }) => {
  const translate = useTranslate()
  const { scheduleTeams, activeStep } = useGeneratorContext()

  return <SimpleForm
    validate={(values) => validate(values, activeStep, schedule, scheduleTeams, translate)}
    {...props}
  />
}

export default ({ schedule, isOpen, onClose }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const isEnabled = useFlag();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const dialogContentRef = useRef();
  const [dialogContentHeight, setDialogContentHeight] = useState(null); // we need this height for the matrix's maxHeight to have stickies working accordignly

  schedule = schedule || record

  const initialFormValues = {
    groupId: null,
    method: isEnabled(FF_DRAFT_DEFAULT_ROUNDS) ? METHODS.TOTAL_ROUNDS : METHODS.GAMES_PER_TEAM,
    type: TYPES.SINGLE_ROTATION,
    limitByes: isEnabled(FF_DRAFT_LIMIT_BYES) || undefined,
    roundType: isEnabled(FF_DRAFT_DEFAULT_SLOTS) ? ROUND_TYPES.HOME_SLOTS : ROUND_TYPES.WEEKDAYS,
    games: []
  }

  const [ showMatrix, setShowMatrix ] = useState(initialFormValues.type === TYPES.MATRIX);
  const [ activeStep, setActiveStep ] = useState(0);

  // necessary to use step/showMatrix in the callback
  const stepRef = useRef();
  const showMatrixRef = useRef();
  stepRef.current = activeStep;
  showMatrixRef.current = showMatrix;

  useLayoutEffect(() => {
    if (dialogContentRef.current) {
      setDialogContentHeight(dialogContentRef.current.offsetHeight)
    }
  }, [ dialogContentRef.current?.offsetHeight ]);

  const handleNext = () => {
    setActiveStep(activeStep => {
      const skipStep = (activeStep === STEPS.MATCHUPS && !showMatrixRef.current) || activeStep === STEPS.MATRIX
      return activeStep + (skipStep ? 2 : 1)
    });
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onClose(true)
      return
    }
    setActiveStep(activeStep => {
      const skipStep = (activeStep === STEPS.PREVIEW && showMatrixRef.current) || activeStep === STEPS.SCHEDULING
      return activeStep - (skipStep ? 2 : 1)
    });
  }

  const decorators = useRef([createCalculator({
    field: 'method',
    updates: {
      gamesPerTeam: (method, values, prevValues) => {
        if (method !== METHODS.GAMES_PER_TEAM) return
        return values?.gamesPerTeam
      },
      totalRounds: (method, values, prevValues) => {
        if (method !== METHODS.TOTAL_ROUNDS) return
        return values?.totalRounds
      },
      type: (method, values, prevValues) => {
        if (method !== METHODS.GAMES_PER_TEAM && values?.type === TYPES.MATRIX) return TYPES.SINGLE_ROTATION
        return values?.type
      },
    }
  }, {
    field: 'type',
    updates: {
      matrixTable: (type, values, prevValues) => {
        if (type !== TYPES.MANUAL) return
        return values?.matrixTable
      },
      crossScheduleTeams: (type, values, prevValues) => {
        if (type !== TYPES.MANUAL) return
        return values?.crossScheduleTeams
      }
    }
  }, {
    field: 'gamesPerTeam',
    updates: {
      matrixTable: (gamesPerTeam, values, prevValues) => {
        if (gamesPerTeam !== prevValues?.gamesPerTeam) return
        return values?.matrixTable
      },
      crossScheduleTeams: (gamesPerTeam, values, prevValues) => {
        if (gamesPerTeam !== prevValues?.gamesPerTeam) return
        return values?.crossScheduleTeams
      }
    }
  }, {
    field: 'groupId',
    updates: {
      matrixTable: (groupId, values, prevValues) => {
        if (groupId !== prevValues?.groupId) return
        return values?.matrixTable
      },
      crossScheduleTeams: (groupId, values, prevValues) => {
        if (groupId !== prevValues?.groupId) return
        return values?.crossScheduleTeams
      }
    }
  }, {
    field: 'roundType',
    updates: {
      weekdays: (roundType, values, prevValues) => {
        const { weekdays } = values
        if (weekdays?.length && roundType !== ROUND_TYPES.WEEKDAYS) return
        return weekdays
      }
    }
  })])

  const onSubmit = async values => {
    if (stepRef.current !== STEPS.PREVIEW) return handleNext()
    const { games = [] } = values;

    if (!games.length) return;

    const draftGames = games.map(({ id, ...game }) => game)

    return dataProvider.createMany("draftGames", { data: draftGames })
      .then(res => res.data)
      .then(data => {
        const total = data.length;
        const message = translate(`resources.draftGames.notifications.added`, total);

        onClose();
        notify(message, 'info');
        refresh();
      })
      .catch(() => {
        onClose();
        notify(`resources.draftGames.notifications.not_added`, 'error');
      })
  }

  const cancelLabel = activeStep === 0 ? "ra.action.cancel" : "ra.action.back"
  const submitLabel = activeStep === Math.max(...Object.values(STEPS)) ? "ra.action.generate" : "ra.action.next"

  return <Dialog
    maxWidth={activeStep === STEPS.MATRIX ? 'xl' : 'md'}
    PaperProps={activeStep === STEPS.MATRIX ? { style: { minHeight: '90%' } } : undefined}
    fullWidth
    scroll="paper"
    open={isOpen}
    onClose={onClose}
  >
    <DialogTitle>
      {translate('resources.schedules.labels.generate')}
      {schedule && <Typography variant="subtitle1" color="textSecondary">{schedule.name}</Typography>}
    </DialogTitle>
    <div className={classes.buttons}>
      <Tooltip title={translate('ra.action.close')}>
        <IconButton onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
    <ScheduleSequenceAlert scheduleId={schedule?.id} style={{ marginInline: 16 }} />
    <GeneratorContextProvider schedule={schedule} activeStep={activeStep} setShowMatrix={setShowMatrix} showMatrix={showMatrix}>
      <GeneratorStepper showMatrix={showMatrix} />
      <DialogContent ref={dialogContentRef}>
        <div>
          <RecordContextProvider value={null}>
            <GeneratorAlert />
            <GeneratorForm
              component={Fragment}
              {...formOptions}
              decorators={decorators.current}
              save={onSubmit}
              initialValues={initialFormValues}
              toolbar={<GeneratorToolbar
                className={classes.toolbar}
                handleSubmit={onSubmit}
                onCancel={handleBack}
                cancelLabel={cancelLabel}
                submitLabel={submitLabel}
              />}
            >
              <GeneratorStep showMatrix={showMatrix} dialogContentHeight={dialogContentHeight} />
            </GeneratorForm>
          </RecordContextProvider>
        </div>
      </DialogContent>
    </GeneratorContextProvider>
  </Dialog>
}
