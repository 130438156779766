import React, { Fragment, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Tooltip } from '@material-ui/core'
import { DoneAll } from '@material-ui/icons';

import { apiClient } from '../../../../../../http';
import { useAssignmentsContext } from '../../AssignmentsContext';
import { GameDetailsField } from '../../../../../games/GameDetailsField';

const accept = (gameId, notes, isAvailable) =>
  apiClient(`/games/${gameId}/respondAssignment`, {
    method: 'POST',
    data: { accepted: true, notes, isAvailable },
  })

const getIds = (official, flag) => {
  const meta = official.meta[flag];
  if (!meta) return [];
  return meta.ids || [];
}

const ConfirmDialog = ({ assignment, official, open, onClose }) => {
  const translate = useTranslate();
  const { settings } = useAssignmentsContext()
  const [ notes, setNotes ] = useState('')
  const handleCancel = () => onClose(false);
  const handleConfirm = () => onClose(true, notes);
  const handleNotes = event => setNotes(event.target.value);

  const conflicts = getIds(official, 'Game Conflict'); // accepted games, require manual decline first
  const overlaps = getIds(official, 'Game Overlap'); // unconfirmed games
  const consecutives = getIds(official, 'Consecutive');
  const hasConflicts = conflicts.length > 0;
  const hasOverlaps = overlaps.length > 0;
  const hasConsecutives = consecutives.length > 0 && settings.enforceConsecutive;

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.games.labels.assignment.confirm')}</DialogTitle>
    <DialogContent>
      {!hasConflicts && <DialogContentText>
        {translate(`resources.games.messages.assignment.are_you_sure_accept_assignment${settings?.declineConfirmedDisabled ? '_disabled' : ''}`)}
      </DialogContentText>}
      {!hasConflicts && hasOverlaps && <DialogContentText>
        {translate('resources.games.messages.assignment.accepting_assignment')}
        <ul>
          {overlaps.map(id => <li><GameDetailsField record={{ id }} source="id" /></li>)}
        </ul>
      </DialogContentText>}
      {hasConflicts && <DialogContentText>
        {translate('resources.games.messages.assignment.you_cannot_accept_assignment')}
        <ul>
          {conflicts.map(id => <li><GameDetailsField record={{ id }} source="id" /></li>)}
        </ul>
      </DialogContentText>}
      {!hasConflicts && hasConsecutives && <DialogContentText>
        {translate('resources.games.messages.assignment.accept_consecutive_assignments')}
        <ul>
          {consecutives.map(id => <li><GameDetailsField record={{ id }} source="id" /></li>)}
        </ul>
      </DialogContentText>}

      <TextField
        margin="dense"
        fullWidth
        multiline
        rows={2}
        label={translate('resources.games.labels.notes', 1)}
        helperText={translate("ra.message.optional")}
        value={notes}
        onChange={handleNotes}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary" disabled={hasConflicts}>{translate('ra.action.accept')}</Button>
    </DialogActions>
  </Dialog>
}

export default ({ game, assignment, official, disabled, onClose }) => {
  const [ accepted, setAccepted ] = useState(false);
  const [ showConfirm, setConfirm ] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();

  if (!game || !assignment || !official) return null;

  const isUnavailable = official.flags.includes('Unavailable') || official.flags.includes('Availability');

  const handleConfirm = (confirmed, notes) => {
    setConfirm(false);
    if (!confirmed) return;

    const isAvailable = isUnavailable ? true : undefined;
    setAccepted(true);
    accept(game.id, notes, isAvailable)
      .then(res => {
        onClose(assignment, official.participantId)
        notify(translate('resources.games.notifications.assignment.accepted_game', { number: game.number }), 'success')
      }).catch(err => {
        setAccepted(false);
        const errorName = err?.response?.data?.error?.name;
        const errorMessage = errorName && translate(`resources.games.notifications.assignment.errors.${errorName}`);
        notify(errorMessage || 'ra.page.error_try_again', 'error')
      })
  }

  return <>
    <Tooltip title={translate('resources.games.messages.assignment.accept_game_assignment')}>
      <Button
        color="primary"
        startIcon={<DoneAll fontSize="small" />}
        disabled={disabled || accepted}
        onClick={() => setConfirm(true)}
      >
        {translate('ra.action.accept')}
      </Button>
    </Tooltip>
    <ConfirmDialog assignment={assignment} official={official} open={showConfirm} onClose={handleConfirm} />
  </>
}
