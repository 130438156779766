import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { matchPath, generatePath } from 'react-router';
import { useNotify, useDataProvider } from 'react-admin';
import qs from 'qs';

import { LazyBoundary } from '../../common/LazyBoundary';

const Scheduler = React.lazy(() => import('./Scheduler'))

const NewDraft = props => {
  const { location, history } = props;

  const { type } = qs.parse(location.search.substring(1))
  const { scheduleId } = history.location.state || {};

  return <LazyBoundary>
    <Scheduler type={type} scheduleId={scheduleId} {...props} />
  </LazyBoundary>
}

const Draft = props => {
  const { match, history } = props;
  const { id } = match.params;

  const [ loading, setLoading ] = useState(true);
  const [ draft, setDraft ] = useState(null)
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    if (!id) return;
    setLoading(true)
    dataProvider.getOne('drafts', { id })
      .then(res => res.data)
      .then(draft => setDraft(draft))
      .catch(() => {
        notify('resources.drafts.notifications.unable_to_load_draft');
        history.push('/profile');
      })
      .finally(() => setLoading(false))
  }, [ id ]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || !draft) return null;

  const limitedView = draft?._scopes?.includes('scheduling:drafts')
    && !draft?._scopes?.includes('scheduling:manage')
    && !draft?._scopes?.includes('scheduling:games')

  if (limitedView) {
    const stepMatch = matchPath(history.location.pathname, { path: `${props.match.path}/:step` })
    if (stepMatch?.params?.step !== 'games') {
      const path = generatePath("/drafts/:id/:step", { id: draft.id, step: 'games' })
      return <Redirect to={path} />
    }
  }

  const { type } = draft;
  return <LazyBoundary>
    <Scheduler type={type} draft={draft} limitedView={limitedView} {...props} />
  </LazyBoundary>
}

export const DraftRoutes = [
  <Route
    path="/drafts/:id"
    render={props => {
      const { id } = props.match.params;
      if (id === 'create') return <NewDraft {...props} />
      return <Draft {...props} />
    }}
  />
]
