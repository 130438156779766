import React from 'react';
import { TextField } from 'react-admin';
import { AttributeTypeField } from '../attributeTypes/AttributeTypeField';
import { OfficeField } from '../offices/OfficeField';
import { AttributeValuesDatagrid } from './AttributeValuesModal';


export const AttributeValuesGrid = props =>
  <AttributeValuesDatagrid size="medium" {...props}>
    <AttributeTypeField source="attributeId" />
    <TextField source="value" />
    <OfficeField source="officeId" />
  </AttributeValuesDatagrid>
