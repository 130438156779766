import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { IdentityForm } from './IdentityForm';

const AddIdentityDialog = props =>
  <CreateResourceDialog label="resources.identities.actions.add" resource="identities" basePath="/identities" {...props}>
    <IdentityForm />
  </CreateResourceDialog>

const EditIdentityDialog = props =>
  <EditResourceDialog label="resources.identities.labels.edit" resource="identities" basePath="/identities" {...props}>
    <IdentityForm />
  </EditResourceDialog>

export const AddIdentityButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.identities.actions.add')}</Button>
    <AddIdentityDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const IdentityDatagrid = props => <DialogDatagrid dialog={EditIdentityDialog} {...props} />
