import React from 'react';
import { useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { ListItemIcon, ListItemText, ListSubheader, useTheme } from "@material-ui/core"
import { Sync } from "@material-ui/icons"

import { ActionMenuItem } from "../../common/ActionMenu"
import { apiClient, usePermissions } from '../../http';

const Recalculate = ({ scheduleId, method, handleClose }) => {
  const theme = useTheme();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const purple = theme.palette.tertiary.main;

  const handleClick = () => {
    handleClose();
    notify(translate('resources.schedules.notifications.recalculating'), { autoHideDuration: 3 * 1000 }) // 3s
    apiClient(`/schedules/${scheduleId}/${method}`, {
      method: 'POST',
    })
      .then(() => refresh())
  }

  return <ActionMenuItem size="small" onClick={handleClick}>
    <ListItemIcon><Sync htmlColor={purple} /></ListItemIcon>
    <ListItemText>{translate(`resources.schedules.actions.${method}`)}</ListItemText>
  </ActionMenuItem>
}

export default ({ handleClose }) => {
  const translate = useTranslate();
  const schedule = useRecordContext();
  if (!schedule) return null;

  const permissions = usePermissions()
  const isSystem = permissions.some(p => p.roleType === 'System')

  if (!isSystem) return null
  return <>
    <ListSubheader>{translate('resources.schedules.actions.internal')}</ListSubheader>
    <Recalculate scheduleId={schedule.id} method="recalculateStats" handleClose={handleClose} />
  </>
}
