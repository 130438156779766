import React, { useState } from 'react';
import { TextInput, useTranslate } from 'react-admin'
import { InputAdornment, IconButton } from '@material-ui/core'
import { VisibilityOutlined, Visibility } from '@material-ui/icons'

export default ({ autoComplete, ...props }) => {
  const [ showPassword, setVisibility ] = useState(false);
  const onClick = () => setVisibility(visible => !visible);
  const onMouseDown = e => e.preventDefault();
  const translate = useTranslate();

  const type = !showPassword ? 'password' : 'text';
  const endAdornment = <InputAdornment position="end">
    <IconButton
      aria-label={translate('ra.input.password.toggle_visibility')}
      onClick={onClick}
      onMouseDown={onMouseDown}
      tabIndex="-1"
    >
      {showPassword ? <Visibility /> : <VisibilityOutlined />}
    </IconButton>
  </InputAdornment>

  const InputProps = {
    endAdornment,
    autoComplete,
  }
  return <TextInput type={type} InputProps={InputProps} {...props} />
}
