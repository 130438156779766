import React from 'react';
import { ReferenceInput, ReferenceArrayInput, RecordContextProvider, useLocale } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { translateApiProperty } from '@hisports/common';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'

import { RuleSectionField } from './RuleSectionField';
import { OfficeField } from '../offices/OfficeField';

const useStyles = makeStyles(theme => ({
  secondary: {
    display: 'flex',
    flexDirection: 'column',
  }
}))

const filterRule = name => ({ name })

export const RuleSuggestion = ({ suggestion: rule, hideSecondary = false, includeOffice, ...props }) => {
  const classes = useStyles();
  const locale = useLocale();
  return <RecordContextProvider value={rule}>
    <AutocompleteSuggestion
      primary={translateApiProperty(rule, 'name', locale)}
      secondary={<div className={classes.secondary}>
        {!hideSecondary && <RuleSectionField source="sectionId" />}
        {includeOffice && <OfficeField source="officeId" variant="inherit" />}
      </div>}
    />
  </RecordContextProvider>
}

export const RuleInput = ({ helperText, multiple, groupBy, alwaysGroup = false, includeOffice = false, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;


  return <Input
    reference="rules"
    filterToQuery={filterRule}
    sort={{ field: ['order', 'name'], order: ['ASC', 'ASC'] }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText="name"
      suggestionComponent={RuleSuggestion}
      options={{ suggestionComponentProps: { hideSecondary: !!groupBy, includeOffice } }}
      filterKeys={['name', 'code']}
      disableSort
      multiple={multiple}
      helperText={helperText}
      groupBy={groupBy}
      alwaysGroup={alwaysGroup}
    />
  </Input>
}
