import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { UnarchiveOutlined, StarBorder, PlaylistAdd } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { FF_BRACKETS, FF_DRAFT_GAMES, FF_LEGACY_DRAFTS_DISABLED } from '@hisports/common/featureFlags';

import { useFlag } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';
import LinksCard from '../../../common/cards/LinksCard';

import { BracketDialog, BracketIcon } from '../dialogs/BracketDialog';

export default props => {
  const isEnabled = useFlag();
  const schedule = useRecordContext(props);
  const translate = useTranslate();
  const history = useHistory();

  const [ bracketsOpen, setBracketsOpen ] = useState(false);

  const onBracketsClose = (cancelled = false) => {
    setBracketsOpen(false);
    if (!cancelled) history.push(`/schedules/${scheduleId}/show/games`)
  }

  const canAddDrafts = isAuthorized(schedule, 'drafts', 'create');
  if (!canAddDrafts) return null;

  const scheduleId = schedule?.id;
  const showBrackets = isEnabled(FF_BRACKETS) && ['Playoffs', 'Tournament', 'Cup'].includes(schedule?.type)

  const showLegacyDrafts = !isEnabled(FF_LEGACY_DRAFTS_DISABLED);
  if (!showLegacyDrafts) return null;

  return <>
    <LinksCard links={[
      {
        primary: translate(`resources.schedules.labels.generate${isEnabled(FF_DRAFT_GAMES) ?'_legacy' : ''}`),
        icon: <StarBorder />,
        to: {
          pathname: "/drafts/create",
          search: '?type=Generated',
          state: { scheduleId, type: 'Generated' }
        }
      },
      {
        primary: translate(`resources.schedules.labels.upload${isEnabled(FF_DRAFT_GAMES) ?'_legacy' : ''}`),
        icon: <UnarchiveOutlined />,
        to: {
          pathname: "/drafts/create",
          search: '?type=Upload',
          state: { scheduleId, type: 'Upload' }
        }
      },
      {
        primary: translate(`resources.schedules.labels.draft${isEnabled(FF_DRAFT_GAMES) ?'_legacy' : ''}`),
        icon: <PlaylistAdd />,
        to: {
          pathname: "/drafts/create",
          search: '?type=Manual',
          state: { scheduleId, type: 'Manual' }
        }
      },
      showBrackets && {
        primary: translate(`resources.schedules.labels.bracket_schedule`),
        icon: <BracketIcon />,
        onClick: () => setBracketsOpen(true),
      },
    ].filter(Boolean)} />
    <BracketDialog scheduleId={schedule.id} isOpen={bracketsOpen} onClose={onBracketsClose} />
  </>
}
