import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import moment from 'moment'
import { useInput, FieldTitle, InputHelperText, useResourceContext } from 'react-admin';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

export const datePlaceholder = moment().format('YYYY-MM-DD');
export const dateTimePlaceholder = moment().format('YYYY-MM-DD HH:mm');

const defaultOptions = {
  variant: 'inline',
  showTodayButton: true,
  autoOk: true,
  format: 'YYYY-MM-DD',
  placeholder: datePlaceholder,
  minutesStep: 5,
  hideTabs: true,
}
const getOptions = options => Object.assign({}, defaultOptions, options);

const makePicker = (PickerComponent, dateOnly = false) => props => {
  const [ focused, setFocused ] = useState(false);
  const { values: { timezone } } = useFormState();

  const {
    id,
    input: { name, value, onChange, onFocus, onBlur, ...input },
    meta: { touched, error, submitError },
    isRequired
  } = useInput(props);

  const {
    options,
    label,
    source,
    helperText,
    className,
    variant,
    margin = 'dense',
  } = props;
  const resource = useResourceContext(props);

  const onDateChange = value => {
    // remove any timezone and truncate time
    const parseFormat = dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
    const date = moment(value).utc(true).format(parseFormat);
    // adjust to selected timezone
    const dateISO = moment.tz(date, parseFormat, timezone || 'UTC').toISOString();

    return onChange(dateISO);
  }

  const onDateFocus = e => {
    setFocused(true);
    return onFocus(e);
  }

  const onDateBlur = e => {
    setFocused(false);
    onDateChange(e.target.value);
    return onBlur(e);
  }

  const allOptions = getOptions(options);
  const inputValue = moment(value).isValid() ? moment.tz(value, timezone).format(allOptions.format || 'YYYY-MM-DD HH:mm') : null;

  return <PickerComponent
    {...props}
    id={id}
    name={name}
    label={<FieldTitle
      label={label}
      source={source}
      resource={resource}
      isRequired={isRequired}
    />}
    inputVariant={variant}
    margin={margin}
    error={!!(touched && (error || submitError))}
    helperText={(touched && (error || submitError)) || helperText ? (
      <InputHelperText
        touched={touched}
        error={error || submitError}
        helperText={helperText}
      />
    ) : null
    }
    className={className}
    value={value || null} // null avoids an error in rifm (no longer necessary in v4)
    inputValue={inputValue}
    onChange={onDateChange}
    onFocus={onDateFocus}
    onBlur={onDateBlur}
    {...input}
    InputLabelProps={{
      shrink: focused || !!value,
    }}
    {...allOptions}
  />
}

export const DateInput = makePicker(KeyboardDatePicker, true);
export const InlineDateInput = makePicker(KeyboardDatePicker, true);
export const TimeInput = makePicker(KeyboardTimePicker);
export const InlineTimeInput = makePicker(KeyboardTimePicker);
export const DateTimeInput = makePicker(KeyboardDateTimePicker);
export const InlineDateTimeInput = makePicker(KeyboardDateTimePicker);
