import React from 'react';
import { ReferenceInput, useTranslate } from 'react-admin';
import moment from 'moment-timezone';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion';

const draftText = (record, translate) => {
  if (!record) return null;
  const draft = record.draft;
  const startDate = moment.utc(draft.options.startDate).format('YYYY-MM-DD');

  const summary = [];
  const breaks = draft.options?.breaks?.reduce((total, brk) => total + brk.totalWeeks, 0) || 0;

  const totalPlay = draft.options.totalRounds;
  const gamesPerTeam = draft.options.gamesPerTeam;
  if (totalPlay) summary.push(translate('resources.drafts.messages.number_of_play', totalPlay))
  if (gamesPerTeam) summary.push(translate('resources.drafts.messages.number_of_games_per_team', gamesPerTeam))
  if (breaks) summary.push(translate('resources.drafts.messages.number_of_break', breaks))

  return `${startDate} - ${draft.options.type} (${summary.join(', ')})`
}

const draftSecondary = (draft, translate) => {
  const { isPublished, publishedAt, publishedBy, updatedAt } = draft;
  if (!isPublished) return moment.utc(updatedAt).calendar();

  const date = moment.utc(publishedAt).calendar();
  return `${translate('resources.drafts.fields.isPublished')}: ${date} ${translate('ra.function.by')} ${publishedBy}`;
}

const DraftSuggestion = ({ suggestion: draft }) => {
  const translate = useTranslate();
  return <AutocompleteSuggestion primary={draftText(draft, translate)} secondary={draftSecondary(draft, translate)} />
}

export default ({ helperText, ...props }) => {
  const translate = useTranslate();
  return <ReferenceInput
    reference="drafts"
    sort={{
      field: 'id',
      order: 'ASC'
    }}
    perPage={999}
    filterToQuery={() => null}
    {...props}
  >
    <AutocompleteInput
      optionText={draft => draftText(draft, translate)}
      suggestionComponent={DraftSuggestion}
      helperText={helperText}
      {...props}
    />
  </ReferenceInput>
}
