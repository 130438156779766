import React, { useEffect, useContext } from 'react';
import { TranslationContext } from 'react-admin';
import { Button } from '@material-ui/core';

import { useLocale, locales } from '../locale';
import { useTenant } from '../http';

export const LanguageMenu = ({ className }) => {
  const [ locale, setLocale ] = useLocale();
  const { setLocale: setAdminLocale, i18nProvider } = useContext(TranslationContext);
  const { sport } = useTenant() || {}

  const languages = Object.keys(locales)
  const currentLanguage = languages.indexOf(locale)
  const nextLanguage = languages[(currentLanguage + 1) % languages.length]

  useEffect(() => {
    i18nProvider.changeLocale(locale, sport)
    setAdminLocale(locale)
  }, [ i18nProvider, locale, sport, setAdminLocale ])

  return <Button type="link" onClick={() => setLocale(nextLanguage)} className={className}>
    {nextLanguage}
  </Button>
}
