import React, { useState } from 'react';
import { ReferenceInput, ReferenceArrayInput, useLocale, useTranslate, BooleanInput } from 'react-admin';
import { useSelector } from 'react-redux'
import { useForm, useFormState } from 'react-final-form';
import { get } from 'lodash';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { ilike } from '../../http/restClient/filters';

import { translateApiProperty } from '@hisports/common';

const filterCategory = (name, locale) => {
  if (!name) return;
  const inputs = name.split(' ');
  return { or: inputs.flatMap(input => ([
    { name: ilike(input) },
    { [`i18n.${locale}.name`]: ilike(input) },
    { 'division.name': ilike(input) },
    { 'gender': ilike(input) },
    { 'class.name': ilike(input) },
  ])) }
}

const getValues = (result, property) => {
  const values = [];
  if (result?.[property]) values.push(result[property]);
  if (!result.i18n) return values;
  Object.keys(result.i18n).forEach(locale => {
    if (result.i18n[locale][property]) values.push(result.i18n[locale][property])
  })
  return values;
}

const matches = (left, right) => `${left}`.trim().toLowerCase().includes(`${right}`.trim().toLowerCase());
const equals = (left, right) => `${left}`.trim().toLowerCase() === `${right}`.trim().toLowerCase()
const matchCategory = (translate) => (input, result) => {
  if (!input || !result) return false;
  return input.split(' ')
    .every(input =>
      (getValues(result, 'name').some(value => matches(value, input))) ||
      (equals(result.gender, input) || equals(translate(`resources.categories.values.gender.${result.gender}`), input)) ||
      (getValues(result.division, 'name').some(value => matches(value, input))) ||
      (getValues(result.class, 'name').some(value => matches(value, input)))
    )
}

export const CategorySuggestion = ({ suggestion: category, query, isHighlighted, ...props }) => {
  const locale = useLocale();
  const translate = useTranslate()
  return <AutocompleteSuggestion primary={translateApiProperty(category, 'name', locale)} secondary={translate(`resources.categories.values.gender.${category.gender}`)} />
}

export const CategoryInput = ({ helperText, multiple, options, scheduleCategoryId, ...props }) => {
  const locale = useLocale();
  const translate = useTranslate();
  const { values } = useFormState();
  const form = useForm();
  const [disabled, setDisabled] = useState(false);
  const categories = useSelector(state => state.admin.resources.categories.data);

  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  const categoryId = !multiple && get(values, props.source);
  const gender = categoryId && categories?.[categoryId]?.gender;

  const handleChange = (event) => {
    setDisabled(!disabled);

    if (!event) {
      form.change(props.source);
      return;
    }

    form.change(props.source, scheduleCategoryId);
  }

  return <>
    <Input
      label="resources.categories.name"
      reference="categories"
      filterToQuery={name => filterCategory(name, locale)}
      sort={{ field: ['order', 'name'], order: ['ASC', 'ASC'] }}
      perPage={999}
      {...props}
    >
      <AutocompleteInput
        optionText={category => (multiple && category) ? `${translateApiProperty(category, 'name', locale)} ${translate(`resources.categories.values.gender.${category.gender}`)}` : translateApiProperty(category, 'name', locale)}
        suggestionComponent={CategorySuggestion}
        disableSort
        groupBy={category => translateApiProperty(category.division, 'name', locale)}
        inputValueMatcher={matchCategory(translate)}
        emptyOptionsText="ra.input.autocomplete.type_to_search"
        multiple={multiple}
        helperText={gender ? translate(`resources.categories.values.gender.${gender}`) : helperText}
        options={options}
        disabled={disabled}
      />
    </Input>
    {scheduleCategoryId && <BooleanInput source="scheduleCategoryId" onChange={handleChange} label="resources.categories.actions.scheduleCategory" />}
  </>
}
