import React from 'react';
import { RecordContextProvider, ReferenceManyField, Pagination } from 'react-admin';

import { ContactGrid } from '../../../../../contacts/ContactGrid';

import { useAssignmentContext } from '../AssignmentContext';

export default () => {
  const { official } = useAssignmentContext();

  if (!official) return null;
  return <RecordContextProvider value={official}>
    <ReferenceManyField
      basePath="/contacts"
      resource="contacts"
      reference="contacts"
      target="participants"
      pagination={<Pagination rowsPerPageOptions={[]} />}
    >
      <ContactGrid />
    </ReferenceManyField>
  </RecordContextProvider>
}
