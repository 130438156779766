import React, { useMemo } from 'react';
import { useListContext, useResourceContext } from 'react-admin';

import Calendar from '../../common/calendar';
import { useSchedulingContext } from '../../common/calendar/SchedulingContext';
import { useCalendarContext } from '../../common/calendar/CalendarContext';
import { CALENDAR_VIEWS } from './EventViewSettings';

export const gameResource = type => {
  switch (type) {
    case 'Draft Game':
      return 'draftGames'
    case 'Draft':
      return 'drafts'
    case 'Game':
    default:
      return 'games'
  }
}

export default ({ rowClick }) => {
  const { resource: schedulingResource, availability } = useSchedulingContext();
  const resource = useResourceContext();
  const { dates, type } = useCalendarContext()
  const { ids = [], data } = useListContext();

  const events = useMemo(() => {
    if (type === CALENDAR_VIEWS.SEASON) return [];

    let tempEvents = ids.map(id => data[id])
      .filter(e => e && e.startTime && e.endTime)
      .map(event => ({
        ...event,
        resource: event.resource || schedulingResource || resource
      }));

    if (availability) {
      const { games = [], practices = [], slots = [], availabilities = [] } = availability;
      const availabilityEvents = [
        ...games.map(game => ({ ...game, isEditable: false, resource: gameResource(game.type) })),
        ...practices.map(practice => ({ ...practice, isEditable: false, resource: 'practices', type: 'Practice' })),
        ...slots.map(slot => ({ ...slot, isEditable: false, resource: 'arenaslots', type: 'Slot' })),
        ...availabilities.map(availability => ({ ...availability, isEditable: false, resource: 'availabilities', type: 'Availability' })),
      ];

      tempEvents = [...tempEvents, ...availabilityEvents.filter(event => !tempEvents.some(e => e.id === event.id && e.resource === event.resource))];
    }

    return dates?.length ? tempEvents.filter(event => dates.includes(event.date)) : tempEvents;
  }, [availability, data, dates, ids, resource, schedulingResource, type]);

  return <Calendar events={events} rowClick={rowClick} />
}

