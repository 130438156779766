import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { getFaultyPlayerReason, getFlagColor, getInitialSuspension } from '@hisports/scoresheet/src/util';
import { TEAM_FLAGS } from '@hisports/scoresheet/src/constants';
import { getThrowsInnings } from '@hisports/scoresheet/src/selectors';

import { useScoresheet } from '../ScoresheetContext';
import Flag from '../certification/Flag'

import { useMemberValidationContext } from './MemberValidationContext';
import { SuspensionInfo, SuspensionTitle } from './SuspensionInfo';

const suspendedFlags = [TEAM_FLAGS.SERVING, TEAM_FLAGS.NOT_SERVING];
const detailedFlags = [TEAM_FLAGS.EXTRA, TEAM_FLAGS.TRANSFERRED, TEAM_FLAGS.SECONDARY_TEAM, TEAM_FLAGS.MISSING_SUSPENDED_MEMBER, TEAM_FLAGS.UNVALIDATED_MEMBERS];

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(0.5)
  },
  modalFlagIcon: {
    margin: 'auto',
  },
  flagCount: {
    position: "absolute",
    zIndex: 1,
    userSelect: 'none',
    pointerEvents: 'none',
    aspectRatio: "1/1",
    height: '1.5em',
    fontSize: '.6rem',
    top: theme.spacing(.75),
    right: props => props.edge ? theme.spacing(-.25) : theme.spacing(.75),
    color: "white",
    backgroundColor: '#707070', // same color as the default flag color
    border: '1px solid white',
    borderRadius: "50%",
    display: 'grid',
    placeContent: 'center'
  }
}))

const FlagItem = ({ flagName, member }) => {
  const classes = useStyles();
  const translate = useTranslate()
  const { memberValidations } = useMemberValidationContext();
  const memberValidation = memberValidations.find(({ participantId }) => participantId === member.participantId);
  const memberThrowsInnings = useScoresheet(scoresheet => getThrowsInnings(scoresheet, { teamId: member.teamId, participantId: member.participantId }))

  let secondary;

  if (suspendedFlags.includes(flagName)) {
    const initialSuspention = getInitialSuspension(memberValidation, member.teamId)
    const validationSuspension = memberValidation.suspensions.find(suspension => suspension.id === initialSuspention.id)
    if (validationSuspension) {
      secondary = <Typography variant="body1"><SuspensionInfo suspension={validationSuspension} /></Typography>
    }
  } else if (detailedFlags.includes(flagName)) {
    secondary = translate(`resources.games.labels.flagsDetails.${flagName}`)
  } else if (flagName === TEAM_FLAGS.FAULTY_PLAYERS) {
    const faultyReason = getFaultyPlayerReason(memberValidation.throwsInningsStatus, memberThrowsInnings);
    secondary = translate(`resources.games.labels.flagsDetails.${flagName}.${faultyReason}`)
  }
  const { suspension } = member;

  const primary = suspendedFlags.includes(flagName) ? <SuspensionTitle selected suspension={suspension} memberValidation={memberValidation} /> : translate(`resources.games.labels.flags.${flagName}`, 1)

  return <ListItem alignItems="flex-start">
    <ListItemIcon>
      <Flag color={getFlagColor(flagName)} className={classes.modalFlagIcon} />
    </ListItemIcon>
    <ListItemText
      primary={primary}
      secondary={secondary}
    />
  </ListItem>;
}

const FlagCount = ({ count, edge }) => {
  const classes = useStyles({ edge });
  return <span className={classes.flagCount}>{count}</span>
}

export const MemberFlagsList = ({ member, flagNames }) => {
  return <List>
    {flagNames.map(flagName => (
      <FlagItem key={flagName} flagName={flagName} member={member} />
    ))}
  </List>
}

export const MemberFlagsButton = ({ flagNames, flagColor, member, edge }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const translate = useTranslate();
  const { memberValidations } = useMemberValidationContext();
  const memberValidation = memberValidations.find(({ participantId }) => participantId === member.participantId);
  const { suspension } = member;

  const flagTooltip = flagNames.map((flagName, key) => {
    const flagLabel = suspendedFlags.includes(flagName) ? <SuspensionTitle selected suspension={suspension} memberValidation={memberValidation} /> : translate(`resources.games.labels.flags.${flagName}`, 1)
    return (
      <>
        {key > 0 && <br />}
        {flagLabel}
      </>
    );
  })

  return (
    <>
      <Box position="relative">
        {flagNames.length > 1 && <FlagCount count={flagNames.length} edge={edge} />}
        <Flag onClick={() => setOpen(true)} color={flagColor} tooltip={flagTooltip} edge={edge} />
      </Box>
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitle}>{member.participant.fullName}</DialogTitle>
        <MemberFlagsList member={member} flagNames={flagNames} />
      </Dialog>
    </>
  )
}

