import React from 'react';
import { ReferenceInput } from 'react-admin';
import { Group as ListIcon } from '@material-ui/icons'

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'

const filterList = name => ({ name })

const ListSuggestion = ({ suggestion: list, query, isHighlighted, ...props }) =>
  <AutocompleteSuggestion
    icon={<ListIcon />}
    primary={list.name}
  />

export const ListInput = ({ helperText, ...props }) =>
  <ReferenceInput
    reference="lists"
    filterToQuery={filterList}
    sort={{
      field: ['name'],
      order: ['ASC'],
    }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText="name"
      suggestionComponent={ListSuggestion}
      helperText={helperText}
    />
  </ReferenceInput>
