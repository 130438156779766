import React, { useState } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../../common/dialogs/DialogDatagrid';
import { ResourceDialog } from '../../../common/dialogs/ResourceDialog';

import { VarianceForm } from './VarianceForm';

const AddVarianceDialog = props =>
  <RecordContextProvider value={null}>
    <ResourceDialog label="resources.rulebooks.actions.add_variance" resource="rulebooks" basePath="/rulebooks" maxWidth="md" {...props}>
      <VarianceForm {...props} />
    </ResourceDialog>
  </RecordContextProvider>

const EditVarianceDialog = ({ save, ...props }) =>
  <ResourceDialog label="resources.rulebooks.labels.edit_variance" resource="rulebooks" basePath="/rulebooks" maxWidth="md" handleSubmit={save} {...props}>
    <VarianceForm {...props} />
  </ResourceDialog>

export const AddVarianceButton = ({ save, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  const handleSubmit = infraction => {
    save(infraction);
    setOpen(false);
  }
  return <>
    <Button color="primary" startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.rulebooks.actions.add_variance')}
    </Button>
    <AddVarianceDialog isOpen={isOpen} handleClose={() => setOpen(false)} handleSubmit={handleSubmit} {...props} />
  </>
}

export const VarianceDatagrid = props => <DialogDatagrid dialog={EditVarianceDialog} {...props} />
