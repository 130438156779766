import React from 'react';
import { NullableBooleanInput, TextInput, useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { InlineDateInput } from '../../../common/inputs/DateInput';

import { EventView } from '../../events/EventView';
import { EventReferenceManyField } from '../../events/EventReferenceField';
import { AddPracticeButton } from '../../practices/PracticeModal';
import { AddActivityButton } from '../../activities/ActivityModal';
import { CALENDAR_VIEWS, EventViewSettingsMenu, useEventSort, useShowCalendar } from '../../events/EventViewSettings';
import { rowClick } from '../../events/EventGrid';

export default props => {
  const team = useRecordContext(props);
  const [ showCalendar ] = useShowCalendar();
  const sort = useEventSort();

  const canAddPractices = isAuthorized(team, 'practices', 'create')
  const canAddActivities = isAuthorized(team, 'activities', 'create')

  const addButton = canAddPractices && <AddPracticeButton initialValues={{ teamIds: team ? [team.id] : [] }} />
  const actions = [
    canAddActivities && <AddActivityButton size="small" initialValues={{ teamId: team?.id }} />,
    <EventViewSettingsMenu alwaysOn showTeamEvents showAvailabilties disableGroupArenas />,
  ].filter(Boolean)

  const filters = [
    <InlineDateInput source="startTime" label="resources.games.filters.startTime" variant="outlined" alwaysOn />,
    <InlineDateInput source="endTime" label="resources.games.filters.endTime" variant="outlined" disabled={showCalendar} alwaysOn />,
    <TextInput source="number" variant="outlined" />,
    <NullableBooleanInput source="isApproved" label="resources.games.filters.isApproved" variant="outlined" />,
  ]

  return <ListCard
    title="resources.schedules.name"
    resource="games"
    target="teams"
    base={EventReferenceManyField}
    baseProps={{
      includeGames: true,
      includeDraftGames: false,
      includePractices: true,
      includeActivities: true,
      includeSlots: false,
      includeAvailabilities: showCalendar
    }}
    addButton={addButton}
    actions={actions}
    filters={filters}
    sort={sort}
    noResultsText={!showCalendar}
    rowsPerPageOptions={showCalendar ? [] : [25, 50, 100]}
    perPage={showCalendar ? 99999 : 25}
  >
    <EventView team={team} rowClick={rowClick} strictView={CALENDAR_VIEWS.WEEK} disableScheduling />
  </ListCard>
}
