import React from 'react';
import { Grid } from '@material-ui/core'

import ActivityCard from './cards/ActivityCard';

export const ActivityShowLayout = props =>
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <ActivityCard />
    </Grid>
  </Grid>
