import React from 'react';
import { Divider, ListItemIcon, ListItemText, ListSubheader, useTheme } from "@material-ui/core"
import { Sync } from "@material-ui/icons"
import moment from 'moment-timezone';

import { ActionMenuItem } from "../../common/ActionMenu"
import { useNotify, useRecordContext, useTranslate } from 'react-admin';
import { apiClient } from '../../http';
import { useSeason } from '../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../common/Authorize';

const SyncButton = ({ officeId, method, handleClose }) => {
  const theme = useTheme()
  const notify = useNotify();
  const seasonId = useSeason();
  const translate = useTranslate();
  const purple = theme.palette.tertiary.main;

  const handleClick = () => {
    handleClose();
    notify('Syncing...', { autoHideDuration: 3 * 1000 }) // 3s
    apiClient(`/offices/${officeId}/${method}`, {
      method: 'POST',
      params: {
        seasonId,
        date: moment().format('YYYY-MM-DD'),
      }
    })
  }
  return <ActionMenuItem size="small" onClick={handleClick}>
    <ListItemIcon><Sync htmlColor={purple} /></ListItemIcon>
    <ListItemText>{translate(`resources.offices.actions.${method}`)}</ListItemText>
  </ActionMenuItem>
}
export default ({ handleClose }) => {
  const translate = useTranslate();
  const office = useRecordContext();
  if (!office || office.externalId == null) return null;
  if (!isAuthorized(office, 'offices', 'internal', true)) return null;

  return <>
    <Divider />
    <ListSubheader>{translate('resources.offices.actions.internal')}</ListSubheader>
    <SyncButton officeId={office.id} method="syncOffices" handleClose={handleClose} />
    <SyncButton officeId={office.id} method="syncTeams" handleClose={handleClose} />
    <SyncButton officeId={office.id} method="syncOfficials" handleClose={handleClose} />
    <SyncButton officeId={office.id} method="syncRegistrations" handleClose={handleClose} />
  </>
}
