import React, { Fragment, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { apiClient } from '../../http';
import ListCard from '../../common/cards/ListCard';
import { Form } from 'react-final-form';
import ReportFilters from '../../routes/Reports/ReportFilters';
import ReportFrame from '../../routes/Reports/ReportFrame';
import { TrendingUp } from '@material-ui/icons';

const useStyles = makeStyles({
  cardContent: {
    padding: '8px',
  }
})

const validate = values => {
  const errors = {};

  if (!values.reportId) errors.reportId = 'ra.validation.required'

  return errors;
}

export default ({ collapsable = true, targetType, officeId, participantId, teamId, ...props }) => {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ reports, setReports ] = useState([]);
  const [ groupFilters, setGroupFilters ] = useState(false)
  const translate = useTranslate();
  const classes = useStyles()

  useEffect(() => {
    setLoading(true);
    apiClient(`/reports`, { params: { filter: {
      where: {
        targetType,
      } } } })
      .then(res => res.data)
      .catch(err => {
        setError(err?.response?.status || -1)
        return []
      })
      .then(setReports)
      .finally(() => {
        setLoading(false);
      })
  }, [ targetType ])

  let message;
  if (loading) {
    message = translate('ra.page.loading')
  } else if (error && error !== 403) {
    message = translate('resources.reports.messages.error', { error })
  } else if (!reports.length) {
    message = translate('resources.reports.messages.empty')
  }

  return <Form
    initialValues={{ officeId, participantId, teamId }}
    onSubmit={() => {}}
    validate={validate}
    render={({ handleSubmit, ...props }) => {
      return <ListCard
        title={translate('resources.reports.name', 2)}
        icon={<TrendingUp />}
        reference="reports"
        collapsable={collapsable}
        expandable
        onExpand={() => setGroupFilters(!groupFilters)}
        dialogProps={{ fullHeight: true }}
        base={Fragment}
      >
        <div className={classes.cardContent}>
          {message}
          {reports?.length > 0 &&
          <form onSubmit={handleSubmit}>
            <ReportFilters
              reports={reports}
              component={Fragment}
              componentContent={Fragment}
              groupFilters={groupFilters}
              hideOffice={targetType === 'Office'}
              hideParticipant={targetType === 'Participant'}
              hideTeam={targetType === 'Team'}
            />
            <ReportFrame reports={reports} />
          </form>
          }
        </div>
      </ListCard>
    }}
  />
}
