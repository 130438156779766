import React from 'react';
import { Grid } from '@material-ui/core'
import { SimpleForm } from 'react-admin';

import { SeasonInput } from '../../common/inputs/EnumInputs';
import Toolbar from '../../common/ra/Toolbar';

import { TargetInput } from '../targets/TargetInput';
import { ParticipantInput } from '../participants/ParticipantInput';

const validate = values => {
  const errors = {};

  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.participantId) errors.participantId = 'ra.validation.required'
  if (!values.targetType) errors.targetType = 'ra.validation.required'
  if (!values.targetId) errors.targetId = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'officialConflicts',
  basePath: '/officialConflicts',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const OfficialConflictForm = props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <ParticipantInput source="participantId" disabled {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <SeasonInput source="seasonId" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TargetInput source="targetId" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
