import React from 'react';
import { useRecordContext, ReferenceManyField, RecordContextProvider, ResourceContextProvider, useResourceContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { useTeamEffectiveSettings } from '../../teamSettings';
import ScheduleTeamsGrid from '../../scheduleteams/ScheduleTeamsGrid';
import { ScheduleTeamContactGrid } from '../../contacts/ScheduleTeamContactGrid';
import { ScheduleManagerContactGrid } from '../../contacts/ScheduleManagerContactGrid';

const ScheduleContacts = ({ basePath, ...props }) => {
  const team = useRecordContext(props);
  const resource = useResourceContext(props);

  return <RecordContextProvider value={team}>
    <ResourceContextProvider value={resource}>
      <ReferenceManyField
        label="resources.contacts.labels.card.title"
        basePath={basePath}
        reference="teamContacts"
        target="schedules"
        source="scheduleId"
        filter={{ groupId: team?.groupId }}
        sort={{
          field: ['schedule.startTime', 'schedule.name'],
          order: ['ASC', 'ASC'],
        }}
        perPage={9999}
        pagination={null}
      >
        <ScheduleTeamContactGrid />
      </ReferenceManyField>
      <ReferenceManyField
        label={null}
        basePath={basePath}
        reference="scheduleContacts"
        target="schedules"
        source="scheduleId"
        filter={{ groupId: team?.groupId }}
        perPage={9999}
        pagination={null}
      >
        <ScheduleManagerContactGrid />
      </ReferenceManyField>
    </ResourceContextProvider>
  </RecordContextProvider>
}

export default props => {
  const team = useRecordContext(props);
  const settings = useTeamEffectiveSettings(team?.id);

  const showOffice = isAuthorized(team, 'offices', 'show')
  const showContacts = settings?.contactsVisibility === 'teams';

  const title = showContacts ? 'resources.scheduleteams.labels.card.title' : 'resources.schedules.labels.card.title'
  return <ListCard
    title={title}
    collapsable
    reference="scheduleteams"
    target="teams"
    perPage={999}
    rowsPerPageOptions={[]}
    sort={{ field: 'schedule.startDate', order: 'ASC' }}
  >
    <ScheduleTeamsGrid
      showOffice={showOffice}
      expand={showContacts && <ScheduleContacts />}
    />
  </ListCard>
}
