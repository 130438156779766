import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import AlertDialog from '../../../../common/dialogs/AlertDialog';
import { isAuthorized } from '../../../../common/Authorize';

import { StartDialog } from '../StartModal';

const StartButton = props => {
  const translate = useTranslate();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true)
  }

  return <>
    <Button color="primary" variant="contained" size="small" onClick={() => handleClick()} {...props}>{translate('ra.action.start')}</Button>
    {modalOpen && <StartDialog open={modalOpen} setModalOpen={setModalOpen} />}
    <AlertDialog {...alert} />
  </>
}

export const StartAlert = ({ canApprove, ...props }) => {
  const translate = useTranslate();
  const game = useRecordContext();

  const showButton = canApprove || isAuthorized(game, 'scoresheets', 'full')

  return <Alert severity="info" action={showButton && <StartButton {...props} />}>
    {translate('resources.scoresheets.alerts.start')}
  </Alert>
}
