import React from 'react';
import { Filter, NullableBooleanInput } from 'react-admin';

import { ResetFiltersButton } from '../../common/ResetFiltersButton';
import { FilterCard } from '../../common/cards/FilterCard';
import { SavedSearches } from '../../common/SavedSearches';
import { DisciplineStatusInput, SuspensionStatusInput } from '../../common/inputs/EnumInputs';

import { GameInput } from '../games/GameInput';
import { ParticipantInput } from '../participants/ParticipantInput';
import { TeamInput } from '../teams/TeamInput';
import { ScheduleInput } from '../schedules/ScheduleInput';

const multipleProps = {
  multiple: true,
  alwaysOn: true,
  fullWidth: true,
  allowEmpty: false,
}

const SuspensionFilters = props => {
  return <Filter variant="outlined" margin="dense" fullWidth {...props}>
    <ParticipantInput source="participantId" {...multipleProps} />
    <TeamInput source="teamId" {...multipleProps} />
    <GameInput source="gameId" {...multipleProps} />
    <ScheduleInput source="game.scheduleId" label="resources.games.fields.scheduleId" {...multipleProps} multiple={false} />
    <SuspensionStatusInput source="status" {...multipleProps} />
    <DisciplineStatusInput source="disciplineStatus" {...multipleProps} />
    <NullableBooleanInput source="isAutomatic" alwaysOn fullWidth />
  </Filter>
}

export const SuspensionFilterList = () => {
  return <FilterCard>
    <SuspensionFilters />
    <ResetFiltersButton />
    <SavedSearches ignoredFilters={['seasonId']} />
  </FilterCard>
}
