import { downloadCSV } from 'react-admin';
import moment from 'moment-timezone';
import Papa from 'papaparse'

import { appName } from '../../flags';
import { getSurfaceName } from '../surfaces/util';

const practicesExporter = data => {
  const practices = data.map(practice => {
    const teamNames = practice?.teams.map(team => team.name);
    const teamHCRIds = practice?.teams.map(team => team.HCRId);
    const surface = practice.surface;
    const timezone = practice.timezone;

    return [
      practice.teamIds,
      teamNames,
      teamHCRIds,
      practice.name,
      getSurfaceName(surface),
      moment.utc(practice.date, 'YYYY-MM-DD').format("YYYY-MM-DD"),
      moment.tz(practice.startTime, timezone).format("HH:mm"),
      moment.tz(practice.endTime, timezone).format("HH:mm"),
      timezone,
      practice.status,
      practice.comments
    ]
  });

  const csv = Papa.unparse({
    fields: ["Team IDs", "Team Names", "Team Registry IDs", "Name", "Venue", "Date", "Start Time", "End Time", "Timezone", "Status", "Comments"],
    data: practices
  });
  downloadCSV(csv, `${appName} - Practices - ${moment().format('YYYYMMDDHHmmss')}`);
};

practicesExporter._include = [
  'teams',
  'surface',
]

export default practicesExporter;
