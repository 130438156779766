import React from 'react';
import { useResourceContext } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

import { useGroupArenas, useGroupDates, useGroupRounds, useShowCalendar } from './EventViewSettings';
import EventCalendar from './EventCalendar';
import EventList from './EventList';
import EventGroupedGrid from './EventGroupedGrid';
import { EventGrid } from './EventGrid';
import { DragProvider } from '../../common/calendar/DragContext';
import { SchedulingProvider } from '../../common/calendar/SchedulingContext';
import { CalendarProvider } from '../../common/calendar/CalendarContext';

export const BaseEventView = ({ isRowSelectable, disableCalendar, ...props }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const [ showCalendar ] = useShowCalendar();
  const [ groupDates ] = useGroupDates();
  const [ groupRounds ] = useGroupRounds();
  const [ groupArenas ] = useGroupArenas();

  // combine event view settings and prop, allow prop disabling with false
  const groupByRound = props.groupByRound !== false && (props.groupByRound || groupRounds);
  const groupByDate = props.groupByDate !== false && (props.groupByDate || groupDates);
  const groupByArena = props.groupByArena !== false && (props.groupByArena || groupArenas);

  if (isMobile) return <EventList {...props} />
  if (showCalendar && !disableCalendar) return <EventCalendar {...props} />
  if (groupByRound || groupByDate || groupByArena) return <EventGroupedGrid isRowSelectable={isRowSelectable} {...props} />
  return <EventGrid isRowSelectable={isRowSelectable} {...props} />
}

export const EventView = ({ initialStart, hideArena, includeVenue, strictView, disableScheduling, ...props }) => {
  const resource = useResourceContext();

  return <SchedulingProvider resource={resource} disableScheduling={disableScheduling}>
    <DragProvider>
      <CalendarProvider initialStart={initialStart} hideArena={hideArena} includeVenue={includeVenue} strictView={strictView}>
        <BaseEventView hideArena={hideArena} includeVenue={includeVenue} {...props} />
      </CalendarProvider>
    </DragProvider>
  </SchedulingProvider>
}
