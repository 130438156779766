import { downloadCSV } from 'react-admin';
import moment from 'moment-timezone';
import Papa from 'papaparse'

import { getSeasonDisplayName } from '@hisports/common/src/seasons';

import { appName } from '../../flags';

const schedulesExporter = (data, sport) => {
  const schedules = data.map(schedule => {
    const office = schedule.office;
    const season = getSeasonDisplayName(schedule.seasonId, sport);

    return [
      schedule.id,
      schedule.name,
      season,
      schedule.type,
      schedule.category.name,
      moment.utc(schedule.startDate, 'YYYY-MM-DD').format("YYYY-MM-DD"),
      moment.utc(schedule.endDate, 'YYYY-MM-DD').format("YYYY-MM-DD"),
      schedule.officeId,
      office?.name
    ]
  });

  const csv = Papa.unparse({
    fields: ["Schedule ID", "Schedule Name", "Season", "Type", "Category", "Start Date", "End Date", "Office ID", "Office Name"],
    data: schedules
  });
  downloadCSV(csv, `${appName} - Schedules - ${moment().format('YYYYMMDDHHmmss')}`);
};

schedulesExporter._include = [
  'office',
  'category',
]

export default schedulesExporter;
