import React, { useState } from "react";
import { useTranslate, Datagrid } from "react-admin";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { CreateResourceDialog, EditResourceDialog } from "../../../common/dialogs/ResourceDialog";
import { DialogDatagrid } from "../../../common/dialogs/DialogDatagrid";

import { SuspensionForm } from "./SuspensionForm";

export const AddSuspensionDialog = props =>
  <CreateResourceDialog label="resources.suspensions.actions.add" resource="suspensions" basePath="/suspensions" {...props}>
    <SuspensionForm />
  </CreateResourceDialog>

const EditSuspensionDialog = props =>
  <EditResourceDialog label="resources.suspensions.labels.edit" resource="suspensions" basePath="/suspensions" {...props}>
    <SuspensionForm />
  </EditResourceDialog>


export const AddSuspensionButton = ({ size, variant, icon = <Add />, label = true, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={icon} onClick={() => setOpen(true)}>{label && translate('resources.suspensions.actions.add')}</Button>
    <AddSuspensionDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const SuspensionDialogDatagrid = props => <DialogDatagrid dialog={EditSuspensionDialog} {...props} />
export const SuspensionDatagrid = props => <Datagrid size="medium" rowClick="show" {...props} />
