import React, { useRef } from 'react';
import { TextInput, SimpleForm, useTranslate, useLocale } from 'react-admin';
import { useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';
import { Grid, Typography } from '@material-ui/core'
import { Check, Warning } from '@material-ui/icons';
import { Alert, AlertTitle } from "@material-ui/lab"

import { ContactTypeEnumInput, LanguageTypeEnum } from '../../common/inputs/EnumInputs';
import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput';
import { isAuthorized } from '../../common/Authorize';

// simple email regex to require at least: a@a.a
const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/i;
const phoneRegex = /^[\d-()\s]*$/

const validate = values => {
  const errors = {};

  if (!values.type) errors.type = 'ra.validation.required'
  if (!values.value) errors.value = 'ra.validation.required'
  if (!values.language) errors.language = 'ra.validation.required'

  if (values.value && values.type === 'Email' && !emailRegex.test(values.value)) {
    errors.value = 'ra.validation.invalid_email_format';
  }
  if (values.value && values.type !== 'Email' && !phoneRegex.test(values.value)) {
    errors.value = 'ra.validation.invalid_number_format';
  }
  return errors;
}

const inputProps = {
  resource: 'contacts',
  basePath: '/contacts',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const ValueInput = props => {
  const { initialValues: { value: initialValue, isVerified }, values: { type, value } } = useFormState();
  if (type !== 'Email' || !value?.length) return <TextInput {...props} />

  const changed = initialValue !== value;
  const helperText = changed
    ? 'resources.contacts.helpers.value'
    : isVerified ? 'ra.message.verified_email' : 'ra.message.unverified_email'

  return <TextInput {...props} helperText={helperText} InputProps={{
    endAdornment: !changed && isVerified && <Check />
  }} />
}

const FormContent = props => {
  const translate = useTranslate();
  const { values, initialValues } = useFormState();
  const { type, isVerified } = values || {};

  const showAccountAlert = values.type === 'Email' && isAuthorized([], 'participants', 'invite', true);

  return <>
    <Grid container spacing={1} fullWidth>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">{translate('resources.contacts.messages.contacts')}</Typography>
      </Grid>
      {showAccountAlert && <Grid item xs={12}>
        <Alert severity="error" icon={<Warning />} variant="outlined">
          <AlertTitle>{translate('resources.contacts.alerts.contact_accounts')}</AlertTitle>
          {translate(!initialValues?.id ? 'resources.contacts.alerts.create_via_invite_button' : 'resources.contacts.alerts.change_login') }
        </Alert>
      </Grid>}
      <Grid item xs={12}>
        <ContactTypeEnumInput source="type" helperText="" disabled={isVerified} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ValueInput source="value" autoFocus disabled={isVerified} {...inputProps} />
      </Grid>
    </Grid>
    <br />
    <Typography variant="subtitle2">{translate('resources.contacts.labels.information')}</Typography>
    <Grid container spacing={1} fullWidth>
      <Grid item xs={12}>
        <SwitchInput source="isPrimary" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <SwitchInput source="isEmergency" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="notes" helperText="ra.message.optional" multiline rows="2" {...inputProps} />
      </Grid>
    </Grid>

    {type === 'Email' && <>
      <br />
      <Typography variant="subtitle2">{translate('resources.contacts.labels.notifications')}</Typography>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12}>
          <SwitchInput source="isNotification" helperText="" {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <LanguageTypeEnum source="language" label="resources.contacts.fields.language" helperText="" {...inputProps} />
        </Grid>
      </Grid>
    </>}
  </>
}

export const ContactForm = ({ initialValues = {}, ...props }) => {
  const locale = useLocale();

  const decorators = useRef([createCalculator({
    field: 'type',
    updates: {
      isNotification: (type, values, prevValues) => {
        if (type !== 'Email') return false;
        if (prevValues.type !== 'Email') return initialValues.isNotification ?? true;

        return values.isNotification;
      }
    }
  })])

  return <SimpleForm
    toolbar={<Toolbar />}
    validate={validate}
    decorators={decorators.current}
    initialValues={{ type: 'Email', isPrimary: false, language: locale, isNotification: true, ...initialValues }}
    {...props}
  >
    <FormContent {...props} />
  </SimpleForm>
}
