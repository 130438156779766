import React, { Fragment, useEffect, useState } from 'react';
import { SimpleForm, useNotify, useTranslate, useList, ListContextProvider, Pagination, Datagrid, TextField, useDataProvider } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, DialogContentText, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import { Restore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { isEmpty } from '@hisports/parsers';

import { apiClient } from '../../http';
import { AssignRuleTypeEnumInput } from '../../common/inputs/EnumInputs';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { ActionMenuItem } from '../../common/ActionMenu';
import { InlineDateInput } from '../../common/inputs/DateInput';
import TimeRangeField from '../../common/fields/TimeRangeField';
import DateField from '../../common/fields/DateField';
import { useSeason } from '../../common/inputs/SeasonSelector';

import { CategoryInput } from '../categories/CategoryInput';
import { CategoryField } from '../categories/CategoryField';
import { TeamField } from '../teams/TeamField';
import { SurfaceField } from '../surfaces/SurfaceField';

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const AssignRulesInput = props => {
  const translate = useTranslate();
  const { values } = useFormState();
  const types = ['systemId', 'feesId', "minReferee", "minLinesperson", "minOfficial", "minScorekeeper", "minTimekeeper", "minRefereeGrade", "minLinespersonGrade", "minAge", "status"]

  if (isEmpty(values?.gameIds)) return null
  return <>
    <br />
    <AssignRuleTypeEnumInput source="rules" types={types} label={translate("resources.assignRules.labels.applied_settings", 2)} helperText="resources.assignRules.helpers.applied_settings" multiple {...props} />
  </>
}

const ResetRulesForm = props => {
  const translate = useTranslate();
  return <>
    <Alert severity="info" fullWidth>
      {translate('resources.assignRules.alerts.existing_games')}
    </Alert>
    <br />
    <SimpleForm {...props} {...inputProps} >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <InlineDateInput
            source="startDate"
            label="resources.games.filters.startTime"
            {...inputProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InlineDateInput
            source="endDate"
            label="resources.games.filters.endTime"
            {...inputProps}
          />
        </Grid>
        <Grid item xs={12}>
          <CategoryInput source="categoryIds" label="resources.games.fields.categoryId" multiple {...inputProps} />
        </Grid>
      </Grid>
      <br />
      <GameList {...props} />
      <AssignRulesInput {...inputProps} />
    </SimpleForm>
  </>
}

const GameGrid = props => {
  return <Datagrid size="medium" {...props}>
    <TextField source="number" label="resources.games.fields.number" />
    <DateField source="date" />
    <TimeRangeField startSource="startTime" endSource="endTime" label="resources.games.labels.time" />
    <TeamField source="homeTeamId" link={false} label="resources.games.fields.homeTeamId" />
    <TeamField source="awayTeamId" link={false} label="resources.games.fields.awayTeamId" />
    <SurfaceField source="arenaId" link={false} label="resources.games.fields.arenaId" />
    <CategoryField source="categoryId" label="resources.games.fields.categoryId" />
  </Datagrid>
}

const GameList = ({ type }) => {
  const dataProvider = useDataProvider();
  const { values } = useFormState();
  const { id: officeId, startDate, endDate, categoryIds } = values;
  const seasonId = useSeason();

  const { change } = useForm();
  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ games, setGames ] = useState([])

  const listContext = useList({
    data: games,
    loaded,
    loading,
    page: 1,
    perPage: 50
  })

  useEffect(() => {
    change('gameIds', listContext?.selectedIds)
  }, [ listContext?.selectedIds, change ])

  useEffect(() => {
    setLoading(true);

    if (!startDate && !endDate && !categoryIds?.length) {
      setGames([]);
      setLoaded(true);
      setLoading(false);
      return
    }

    dataProvider.getList('games', {
      filter: {
        startTime: startDate,
        endTime: endDate,
        categoryId: categoryIds,
        [type]: officeId,
        seasonId: seasonId,
      },
      sort: { field: 'date', order: 'ASC' },
      pagination: { page: 1, perPage: 500 }
    })
      .then(res => {
        setGames(res.data);
        setLoaded(true);
        setLoading(false);
      })
      .catch(() => {
        setGames([]);
        setLoaded(true);
        setLoading(false);
      })
  }, [type, officeId, startDate, endDate, categoryIds, dataProvider, seasonId])

  return <ListContextProvider value={listContext}>
    <GameGrid
      {...listContext}
      hasBulkActions
      rowClick="toggleSelection"
      isRowSelectable={() => true}
      isLoading={loading}
    />
    <Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />
  </ListContextProvider>
}

const AssignRulesResetModal = ({ type, officeId, isOpen, handleClose, ...props }) => {
  const translate = useTranslate();
  const notify = useNotify();

  const resetAssigner = type === 'scheduleOffices';

  const onSubmit = async values => {
    const gameIds = values?.gameIds || []
    if (!gameIds.length) {
      handleClose();
      return;
    }

    await apiClient(`/offices/${officeId}/applyAssignRules`, {
      method: 'POST',
      data: { gameIds, rules: values?.rules, resetAssigner }
    }).then(res => {
      notify(translate('resources.assignRules.notifications.apply_rules_success', { smart_count: res.data.length }));
      handleClose();
    })
  }

  return <Dialog open={isOpen} onClose={handleClose} maxWidth="medium">
    <DialogTitle>{translate(`resources.assignRules.labels.${type}`)}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate(`resources.assignRules.alerts.${type}`)}
      </DialogContentText>
      <ResetRulesForm type={type} save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.apply" cancelLabel="ra.action.close" onCancel={handleClose} />
      } />
    </DialogContent>
  </Dialog>
}

export default ({ type, onClick, handleClose, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  const handleClick = () => {
    onClick();
    setOpen(true);
  }
  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><Restore fontSize="small" /></ListItemIcon>
      <ListItemText>{translate(`resources.assignRules.labels.${type}`)}</ListItemText>
    </ActionMenuItem>
    {isOpen && <AssignRulesResetModal type={type} isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />}
  </>
}
