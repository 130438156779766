import React from 'react';
import { ReferenceInput } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { AccountIcon } from '../icons';

const filterAccount = email => ({ email })

const AccountSuggestion = ({ suggestion: account, query, isHighlighted, ...props }) =>
  <AutocompleteSuggestion icon={<AccountIcon />} primary={account.email} />

export const AccountInput = ({ helperText, ...props }) =>
  <ReferenceInput
    reference="accounts"
    filterToQuery={filterAccount}
    sort={{
      field: 'email',
      order: 'ASC'
    }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText="email"
      suggestionComponent={AccountSuggestion}
      helperText={helperText}
      emptyOptionsText="resources.accounts.messages.enter_email"
    />
  </ReferenceInput>
