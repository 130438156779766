import React from "react";
import { useDataProvider, useNotify } from "react-admin";

import ScheduleForm from '../ScheduleForm'

import { useScheduleContext } from "./WizardContext.js";

export default ({ toolbar, handleNext }) => {
  const dataProvider = useDataProvider();
  const [ schedule, setSchedule ] = useScheduleContext();
  const notify = useNotify();

  const onSubmit = values => {
    if (!values?.id) {
      return dataProvider.create("schedules", {
        data: values,
      }, {
        onSuccess({ data }) {
          setSchedule(data);
          notify('resources.schedules.notifications.created')
          return handleNext();
        },
        onFailure(error) {
          notify(error.message, 'error')
        },
        returnPromise: true,
      })
    } else {
      return dataProvider.update("schedules", {
        id: values.id,
        data: values,
      }, {
        onSuccess({ data }) {
          setSchedule(data);
          notify('resources.schedules.notifications.updated')
          return handleNext();
        },
        onFailure(error) {
          notify(error.message, 'error')
        },
        returnPromise: true,
      })
    }
  }

  return <ScheduleForm toolbar={toolbar} save={onSubmit} initialValues={schedule} />
}
