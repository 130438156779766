import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { OfficialClaimDocumentForm } from './OfficialClaimDocumentForm';

const AddOfficialClaimDocumentDialog = props =>
  <CreateResourceDialog label="resources.gameDocuments.actions.add" maxWidth="md" resource="officialClaimDocuments" basePath="/officialClaimDocuments" {...props}>
    <OfficialClaimDocumentForm />
  </CreateResourceDialog>

const EditOfficialClaimDocumentDialog = props =>
  <EditResourceDialog label="resources.gameDocuments.actions.edit" maxWidth="md" resource="officialClaimDocuments" basePath="/officialClaimDocuments" {...props}>
    <OfficialClaimDocumentForm />
  </EditResourceDialog>

export const AddOfficialClaimDocumentButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.gameDocuments.actions.add')}</Button>
    <AddOfficialClaimDocumentDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const OfficialClaimDocumentDatagrid = props => <DialogDatagrid dialog={EditOfficialClaimDocumentDialog} {...props} />
