import React from 'react';
import { FunctionField, GET_LIST, TextField, useQuery, useRecordContext } from 'react-admin';

import { TextLocaleField } from '../../common/fields/TextLocaleField';

import { InfractionAccumulationDatagrid } from './InfractionAccumulationModal';
import { InfractionField } from './InfractionField';

const useInfractionAccumulations = officeId => useQuery({
  type: GET_LIST,
  resource: 'infractionAccumulations',
  payload: {
    filter: {
      officeId,
      _include: [
        {
          relation: 'members',
          scope: {
            include: [
              { relation: 'infraction' },
            ],
          },
        },
      ] }
  },
}, {
  enabled: officeId != null,
})

export const InfractionAccumulationGrid = props => {
  const office = useRecordContext();
  const { data: infractionAccumulations } = useInfractionAccumulations(office?.id);

  if (!infractionAccumulations) return null

  const renderCodes = record => {
    const infractionAccumulationMembers = infractionAccumulations.find(({ id }) => id === record.id)?.members;
    if (!infractionAccumulationMembers) return null;

    return infractionAccumulationMembers.map(({ infraction } = {}) => infraction?.code).join(', ');
  }

  return <InfractionAccumulationDatagrid size="medium" {...props}>
    <TextLocaleField source="name" />
    <FunctionField source="rule" label="resources.infractionAccumulations.fields.members" render={renderCodes} />
    <TextField source="count" />
    <InfractionField source="infractionId" />
  </InfractionAccumulationDatagrid>;
}
