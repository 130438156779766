import React, { Fragment, useState } from 'react';
import { SimpleForm, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';

import { apiClient } from '../../../http';
import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { ScheduleGroupInput } from '../../groups/GroupInput';
import { ActionMenuItem } from '../../../common/ActionMenu';
import { BracketIcon } from './BracketDialog';

const useStyles = makeStyles((theme) => ({
  group: {
    marginTop: theme.spacing(2)
  },
}))

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true
}

const validate = (values) => {
  const errors = {};

  return errors;
}

const Toolbar = ({ handleSubmit, onClose }) => {
  return <DialogFormToolbar
    onCancel={() => onClose(true)}
    handleSubmit={handleSubmit}
    submitLabel="ra.action.confirm"
  />
}

const BracketReseedForm = ({ scheduleId, onClose }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const handleSave = values => {
    return apiClient(`/schedules/${scheduleId}/reseedBracketGames`, {
      method: 'POST',
      data: {
        groupId: values.groupId
      }
    })
      .then(() => {
        onClose()
        refresh()
        notify('resources.schedules.notifications.bracket_games_reseeded')
      })
      .catch(e => {
        // page error
        if (!e.isAxiosError) {
          notify('ra.page.error_try_again_refresh')
          return;
        }

        // validation error
        const { error } = e.response.data;
        if ((error?.status === 422 || error?.statusCode === 422) && error?.message) {
          notify(`resources.schedules.errors.brackets.${error.message}`)
          return;
        }

        // api error
        return notify('ra.page.error_try_again')
      })
  }

  return <SimpleForm
    component={Fragment}
    validate={validate}
    toolbar={<Toolbar onClose={onClose} />}
    save={handleSave}
  >
    <ScheduleGroupInput className={classes.group} source="groupId" scheduleId={scheduleId} showNone={translate('ra.message.no_group')} label={translate('resources.schedules.labels.group_bracket_reseed')} {...inputProps} />
  </SimpleForm>
}

export const BracketReseedMenuItem = ({ onClick, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const handleClick = () => {
    onClick();
    setOpen(true);
  }

  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><BracketIcon fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.schedules.labels.bracket_reseed')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <BracketReseedDialog isOpen={isOpen} onClose={() => setOpen(false)} {...props} />}
  </>
}

export const BracketReseedButton = ({ size, variant, scheduleId, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  if (!scheduleId) return null;
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<BracketIcon />} onClick={() => setOpen(true)}>{translate('resources.schedules.labels.bracket_reseed')}</Button>
    <BracketReseedDialog scheduleId={scheduleId} isOpen={isOpen} onClose={() => setOpen(false)} {...props} />
  </>
}


export const BracketReseedDialog = ({ scheduleId, isOpen, onClose }) => {
  const translate = useTranslate();

  return <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>{translate('resources.schedules.labels.reseed_bracket')}</DialogTitle>
    <DialogContent>
      <DialogContentText>{translate('resources.schedules.labels.bracket_reseed_info')}</DialogContentText>
      <BracketReseedForm scheduleId={scheduleId} onClose={onClose} />
    </DialogContent>
  </Dialog>
}
