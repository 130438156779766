import React from 'react';
import { useTranslate } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

import { List, Show, Create, Edit } from '../../common/ra';
import { useSeason } from '../../common/inputs/SeasonSelector';
import ResourceTitle from '../../common/ResourceTitle';

import { EventView } from '../events/EventView';
import { useEventSort } from '../events/EventViewSettings';

import { ActivityFilters } from './ActivityFilters';
import { ActivityFilterList } from './ActivityFilterList';
import { ActivityShowLayout } from './ActivityShow';
import { ActivityForm } from './ActivityForm';
import ActivitiesExporter from './ActivitiesExporter';

export const ActivityList = props => {
  const seasonId = useSeason();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const sort = useEventSort();

  return <List {...props}
    exporter={ActivitiesExporter}
    aside={!isSmall && <ActivityFilterList />}
    filters={isSmall && <ActivityFilters />}
    filterDefaultValues={{ seasonId }}
    sort={sort}
    resetFilters={isSmall}
  >
    <EventView rowClick="show" disableCalendar />
  </List>
}

const getTitle = (activity, translate) => {
  const type = translate(`resources.activities.values.type.${activity.type}`, { _: activity.type })
  if (!activity.name?.length) return type;
  return `${type}: ${activity.name}`
}

export const ActivityShow = props => {
  const translate = useTranslate();
  return <Show {...props}
    title={<ResourceTitle title={record => getTitle(record, translate)} />}
  >
    <ActivityShowLayout />
  </Show>
}

export const ActivityCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={() => translate('resources.activities.labels.add')} />}
    {...props}
  >
    <ActivityForm redirect="show" />
  </Create>
}

export const ActivityEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: getTitle(record, translate) })} />}
    {...props}
  >
    <ActivityForm redirect="show" />
  </Edit>
}
