import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';

import { AddressGrid } from '../../../resources/addresses/AddressGrid';
import { AddAddressButton } from '../../../resources/addresses/AddressModal';

export default props => {
  const account = useRecordContext();
  if (!account?.participantId) return null;

  return <ListCard
    title="resources.addresses.labels.card.title"
    reference="addresses"
    target="participants"
    sort={{ field: 'isPrimary', order: 'DESC' }}
    rowsPerPageOptions={[]}
    addButton={<AddAddressButton initialValues={{ participantId: account?.participantId }} />}
  >
    <AddressGrid />
  </ListCard>
}
