import React from 'react';
import { useListContext } from 'react-admin';

import FunctionField from '../../common/fields/FunctionField'

import { SurfaceField } from '../surfaces/SurfaceField';
import { OfficeField } from '../offices/OfficeField';
import { OfficesField } from '../offices/OfficesField';
import { DivisionsField } from '../categories/DivisionsField';

import { ArenaOfficeDatagrid } from './ArenaOfficeModal';

const has = (ids, data, property) =>
  ids?.some(id => data?.[id]?.[property]?.length > 0)

const TypeField = props =>
  <FunctionField {...props} render={(relation, source, resource, translate) => {
    if (relation.isPrimary) return translate('resources.arenaoffices.fields.isPrimary')
    return null;
  }} />

export const ArenaOfficesGrid = ({ showArena = true, showOffice = true, ...props }) => {
  const { ids = [], data } = useListContext(props);
  const showLeagues = has(ids, data, 'leagueIds')
  const showDivisions = has(ids, data, 'divisionIds')
  return <ArenaOfficeDatagrid size="medium" {...props}>
    {showArena && <SurfaceField source="arenaId" sortable={false} includeCity="full" />}
    {showOffice && <OfficeField source="officeId" sortable={false} />}
    {showDivisions && <DivisionsField source="divisionIds" sortable={false} link={false} emptyText="ra.message.all" />}
    {showLeagues && <OfficesField source="leagueIds" sortable={false} link={false} />}
    <TypeField source="isPrimary" />
  </ArenaOfficeDatagrid>
}
