import React from 'react';
import { ReferenceArrayField, useRecordContext } from 'react-admin';

import { FF_TEAM_SHORT_NAMES } from '@hisports/common/featureFlags';

import { useFlag } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { SingleFieldList } from '../../common/ra/SingleFieldList';

import { InnerTeamField } from './TeamField';

export const TeamsField = ({ authMethod = "show", includeId = false, includeSeason = false, includeCategory = false, link = 'show', variant = 'body2', ...props }) => {
  const record = useRecordContext(props);
  const showLink = isAuthorized(record, 'teams', authMethod) ? link : false;
  const isEnabled = useFlag();

  return <ReferenceArrayField basePath="/teams" resource="teams" reference="teams" allowEmpty {...props} >
    <SingleFieldList link={showLink}>
      <InnerTeamField source="name" includeId={includeId} includeSeason={includeSeason} includeCategory={includeCategory} showShortName={isEnabled(FF_TEAM_SHORT_NAMES)} />
    </SingleFieldList>
  </ReferenceArrayField>
}

TeamsField.defaultProps = {
  addLabel: true,
}
