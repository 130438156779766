import React from 'react';
import { useLocation } from 'react-router-dom';

import { ScheduleWizardProvider } from "./WizardContext"
import { ScheduleWizard } from "./ScheduleWizard"

export default props => {
  const location = useLocation();

  return <ScheduleWizardProvider initialValues={location?.state?.initialValues} {...props}>
    <ScheduleWizard />
  </ScheduleWizardProvider>
}
