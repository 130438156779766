import React from 'react';
import { Datagrid, useListContext } from 'react-admin';
import moment from 'moment-timezone';

import FunctionField from '../../common/fields/FunctionField';

import { GroupField } from '../groups/GroupField';

const metaRender = ({ type, draft, isShared }, source, resource, translate) => {
  if (!draft) return type;
  const meta = [
    `${draft.games.length} ${translate('resources.games.name', draft.games.length)}`,
    `${draft.teams.length} ${translate('resources.teams.name', draft.teams.length)}`,
    isShared && translate('resources.drafts.labels.shared'),
  ].filter(Boolean).join(', ')
  return `${translate(`resources.drafts.values.type.${type}`, { _: type })} (${meta})`
}

const updatedAtRender = ({ updatedAt }, source, resource, translate) => {
  if (!updatedAt) return;
  return moment.tz(updatedAt, moment.tz.guess()).calendar();
}

const publishedAtRender = ({ publishedAt, publishedBy }, source, resource, translate) => {
  if (!publishedAt) return;

  const date = moment(publishedAt);
  return `${date.calendar()}${publishedBy ? ` ${translate('ra.function.by')} ${publishedBy}` : ''}`;
}

export default props => {
  const { ids = [], data } = useListContext(props);

  const showGroup = ids?.some(id => data?.[id]?.groupId != null)
  const showPublished = ids?.some(id => data?.[id]?.isPublished)

  return <Datagrid size="medium" {...props}>
    <FunctionField render={metaRender} />
    {showGroup && <GroupField source="groupId" />}
    <FunctionField source="updatedAt" render={updatedAtRender} />
    {showPublished && <FunctionField label="resources.drafts.fields.publishedAt" render={publishedAtRender} />}
  </Datagrid>
}
