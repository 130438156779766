import React from 'react';
import { ReferenceField, TextField, ReferenceInput } from 'react-admin';
import AutocompleteInput from '../../common/inputs/AutocompleteInput';

export const AssignSystemField = props =>
  <ReferenceField basePath="/assignSystem" resource="assignSystems" reference="assignSystems" link={false} {...props}>
    <TextField source="name" />
  </ReferenceField>

export const AssignSystemInput = props => {
  return <ReferenceInput
    reference="assignSystems"
    perPage={999}
    sort={{ field: 'name', order: 'ASC' }}
    filterToQuery={() => null}
    {...props}
  >
    <AutocompleteInput optionText="name" {...props} />
  </ReferenceInput>
}
