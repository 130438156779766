import React, { useEffect } from 'react';
import { RecordContextProvider, useRecordContext, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Typography, List, ListItem, ListItemIcon, ListItemText, ListSubheader, makeStyles, Badge, styled, Grid, Tooltip, LinearProgress } from '@material-ui/core';
import { ExpandMore, Check as AvailableIcon, Clear as UnavailableIcon, PeopleOutline, PlaceOutlined, LockOutlined, ReportProblemOutlined, Schedule, Check, ErrorOutline as Warning } from '@material-ui/icons';
import { useForm, useFormState } from 'react-final-form';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';

import { FF_DRAFT_GAMES } from '@hisports/common/featureFlags';
import { createRange, isSameTime } from '@hisports/common';
import { isEmpty } from '@hisports/parsers';

import { apiClient, useFlag } from '../../http';
import { GameDetailsPopover, PracticeDetailsPopover, SlotDetailsPopover } from '../../common/calendar/EventDetailsPopover';
import { isDraft, isDraftGameType, isGame, isPractice } from '../../common/calendar/EventDetails';
import TimeRangeField from '../../common/fields/TimeRangeField';
import { useCalendarContext } from '../../common/calendar/CalendarContext';

import { CALENDAR_VIEWS } from '../events/EventViewSettings';
import { TargetField } from '../targets/TargetField';
import { OfficeField } from '../offices/OfficeField';
import { TargetTypeField } from '../targets/TargetTypeField';
import { SurfaceField } from '../surfaces/SurfaceField';
import { TeamField } from '../teams/TeamField';
import { TeamsField } from '../teams/TeamsField';
import { useScheduleSettingsWithStore } from '../scheduleSettings';
import { useSlotsInUse } from '../arenaslots/useSlotsInUse';

import { useGameAvailabilityContext } from './GameAvailabilityContext';

const CONFLICT_TYPES = {
  surface: 'Surface',
  team: 'Team',
}

const isSurfaceConflict = ({ conflictTypes }) => {
  if (!conflictTypes?.length) return false;
  return conflictTypes.includes(CONFLICT_TYPES.surface);
}

const isTeamConflict = ({ conflictTypes }) => {
  if (!conflictTypes?.length) return false;
  return conflictTypes.includes(CONFLICT_TYPES.team);
}

export const isTournamentConflict = ({ schedule }) => {
  if (!schedule) return false;
  return schedule.type == 'Tournament';
}

export const draftLabel = (isEnabled, translate, gameType) => {
  if (!isEnabled(FF_DRAFT_GAMES)) return translate('resources.drafts.name');
  return translate(`resources.drafts.${gameType === 'Draft' ? 'name_legacy' : 'name'}`, 1)
}

export const isSlotInUse = (slotsInUse, slotId, record) => {
  const slotOverlaps = slotsInUse?.[slotId];
  if (!slotOverlaps?.length) return false;

  // ignore own record overlap
  return slotOverlaps.some(overlap => {
    if (!record) return true;
    if (isDraft(overlap) && isDraft(record) && overlap.id === record.id) return false;
    if (isGame(overlap) && isGame(record) && overlap.id === record.id) return false;
    if (isPractice(overlap) && isPractice(record) && overlap.id === record.id) return false;
    return true;
  });
}

const useAvailabilityContext = (game, draftId, enabled) => {
  const { batch, change } = useForm();
  const { type } = useCalendarContext();
  const {
    availabilityInfo: [ gameAvailabilities, setAvailabilities ],
    settings: [ settings, setSettings ],
    slot: [ selectedSlot, setSelectedSlot ],
    loading: [ isLoading, setLoading ]
  } = useGameAvailabilityContext();

  const { date, startTime, endTime, timezone, categoryId, homeTeamId, awayTeamId, arenaId, officeId, id: gameId, scheduleId, number, updatedGameId } = game;
  const { data: scheduleSettings, loading: scheduleSettingsLoading } = useScheduleSettingsWithStore(scheduleId, enabled)

  useEffect(() => {
    if (!enabled) return;

    setLoading(true);
    apiClient('/games/availabilities', {
      method: 'GET',
      params: {
        date,
        startTime,
        endTime,
        timezone,
        categoryId,
        homeTeamId,
        awayTeamId,
        arenaId,
        officeId,
        gameId,
        scheduleId,
        draftId,
        number,
        updatedGameId,
        homeSlotsOnly: [CALENDAR_VIEWS.SEASON, CALENDAR_VIEWS.MONTH].includes(type),
      }
    })
      .then(res => res.data)
      .then(data => setAvailabilities(data))
      .finally(() => setLoading(false))
  }, [enabled, date, startTime, endTime, timezone, categoryId, homeTeamId, awayTeamId, arenaId, officeId, gameId, scheduleId, draftId, number, setAvailabilities, setLoading, updatedGameId, type])

  useEffect(() => {
    if (!enabled || !scheduleId || !scheduleSettings || scheduleSettingsLoading) return;

    setSettings(scheduleSettings)
  }, [ enabled, scheduleSettings, scheduleSettingsLoading, setSettings, scheduleId ])

  useEffect(() => {
    if (!gameAvailabilities?.slots?.length) return setSelectedSlot(null);

    const overlap = gameAvailabilities.slots.find(slot => moment.tz(startTime, timezone).isBetween(moment.tz(slot.startTime, slot.timezone), moment.tz(slot.endTime, slot.timezone), 'minutes', '[)') && arenaId == slot.arenaId)
    if (overlap) {
      batch(() => {
        change('startTime', overlap.startTime)
        change('endTime', overlap.endTime)
        change('timezone', overlap.timezone)
      })
      return setSelectedSlot(overlap)
    }

    const selected = gameAvailabilities.slots.find(slot => isSameTime(slot, game))
    if (!selected) {
      return setSelectedSlot(null)
    }
    setSelectedSlot(selected)
  }, [batch, change, gameAvailabilities.slots, setSelectedSlot, game, startTime, endTime, timezone, arenaId])

  return gameAvailabilities;
}

export const useAvailabilities = game => {
  const { availabilityInfo: [ gameAvailabilities ], settings: [ settings ] } = useGameAvailabilityContext();

  const availabilities = gameAvailabilities?.availabilities || [];
  const conflicts = gameAvailabilities?.conflicts || [];
  const constraints = gameAvailabilities?.constraints || [];
  const slots = gameAvailabilities?.slots || [];

  let timeAvailabilities = []
  const eventRange = createRange(moment.tz(game.startTime, game.timezone), moment.tz(game.endTime, game.timezone))
  if (availabilities) {
    timeAvailabilities = availabilities.filter(availability => {
      const availabilityRange = createRange(moment.tz(availability.startTime, availability.timezone), moment.tz(availability.endTime, availability.timezone));
      return availabilityRange.overlaps(eventRange)
    })

    timeAvailabilities = sortBy(timeAvailabilities, ({ targetType }) => {
      if (targetType === 'Surface') return 1;
      if (targetType === 'Team') return 2;
      if (targetType === 'Office') return 3;
      return 3;
    })
  }

  if (constraints) {
    constraints.sort((a, b) => moment(a.date) - moment(b.date));
  }

  return {
    availabilities: timeAvailabilities,
    conflicts,
    constraints,
    slots,
    settings,
  };
}

export const GameAvailabilityInfo = ({ draftId, ...props }) => {
  const { values: game, errors } = useFormState();
  const hasTimeslotError = Object.keys(errors).includes('timeslot');
  const hasErrors = Object.keys(errors).some(key => key !== 'status') && !hasTimeslotError;
  const enabled = game.date != null && !hasErrors;

  const data = useAvailabilityContext(game, draftId, enabled);
  const { availabilities, conflicts, constraints, slots } = useAvailabilities(game);
  if (isEmpty(data)) return null;

  if (!enabled || (!availabilities.length && !conflicts.length && !constraints.length && !slots.length)) return null;

  return <Grid item xs={12}>
    <AvailabilityDetails availabilities={availabilities} conflicts={conflicts} constraints={constraints} slots={slots} />
  </Grid>
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  conflicted: {
    border: `${theme.spacing(.25)}px solid ${theme.palette.error.dark}`,
  },
  conflicted_info: {
    border: `${theme.spacing(.25)}px solid ${theme.palette.secondary.light}`,
  },
  details: {
    overflowX: 'auto', // allow horizontal scroll in small dialogs
    padding: theme.spacing(0, 3, 2, 3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  }
}))

export const AvailabilityDetails = ({ availabilities = [], conflicts = [], constraints = [], slots = [] }) => {
  const { values } = useFormState();
  const schedules = useSelector(store => store.admin.resources.schedules.data);
  const schedule = schedules[values.scheduleId];
  const classes = useStyles();
  const translate = useTranslate();

  const available = availabilities.filter(availability => availability.isAvailable);
  const unavailable = availabilities.filter(availability => !availability.isAvailable);

  const allAvailable = availabilities.every(availability => availability.isAvailable);
  const onlyAvailable = allAvailable && !conflicts.length && !constraints.length;
  const onlySlots = slots.length && !conflicts.length && !constraints.length;

  const teamConflicts = conflicts.filter(conflict => isTeamConflict(conflict));
  const surfaceConflicts = conflicts.filter(conflict => isSurfaceConflict(conflict));
  const conflictTotal = [...teamConflicts, ...surfaceConflicts, ...constraints].length;
  const hasConflicts = unavailable.length > 0 || conflictTotal > 0;

  const isTournamentSchedule = schedule && schedule.type == 'Tournament';
  const tournamentConflicts = conflicts.filter(conflict => isTournamentConflict(conflict));
  const warningConflict = isTournamentSchedule && isEmpty(tournamentConflicts);
  const conflictAccordionStyle = warningConflict ? classes.conflicted_info : classes.conflicted;

  let Icon = Schedule
  if (!onlySlots && onlyAvailable) Icon = AvailableIcon
  if (!onlySlots && !onlyAvailable) Icon = warningConflict ? Warning : UnavailableIcon

  const title = []
  if (availabilities.length) title.push(translate(`resources.availabilities.labels.${onlyAvailable ? 'available' : 'unavailable'}`))
  if (slots.length) title.push(translate('resources.availabilities.labels.slots'))
  if (conflictTotal > 0) title.push(translate('resources.availabilities.labels.conflicts', { smart_count: conflictTotal }));

  return <Accordion classes={{ root: hasConflicts && conflictAccordionStyle }}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Icon fontSize="small" className={classes.icon} />
      <Typography variant="subtitle2">{title.join(', ')}</Typography>
    </AccordionSummary>
    <AccordionDetails classes={{ root: classes.details }}>
      <AvailabilityList unavailabilities={unavailable} availabilities={available} />
      <SlotsList slots={slots} />
      <ConflictList teamConflicts={teamConflicts} surfaceConflicts={surfaceConflicts} />
      <ConstraintList constraints={constraints} />
    </AccordionDetails>
  </Accordion>
}

export const AvailabilityList = ({ unavailabilities, availabilities }) => {
  const translate = useTranslate();
  if (!unavailabilities.length && !availabilities.length) return null;
  return <List dense disablePadding>
    {unavailabilities.length > 0 && <ListSubheader disableGutters>{translate('resources.availabilities.labels.unavailable')}</ListSubheader>}
    {unavailabilities.map(unavailability => <RecordContextProvider value={unavailability} key={unavailability.id}>
      <AvailabilityItem />
    </RecordContextProvider>)}
    {availabilities.length > 0 && <ListSubheader disableGutters>{translate('resources.availabilities.labels.available')}</ListSubheader>}
    {availabilities.map(availability => <RecordContextProvider value={availability} key={availability.id}>
      <AvailabilityItem />
    </RecordContextProvider>)}
  </List>
}

const SlotsList = ({ slots = [] }) => {
  const translate = useTranslate();
  const classes = useItemStyles();
  const { batch, change } = useForm();
  const { slot } = useGameAvailabilityContext() || {};
  const [ selectedSlot, setSelectedSlot ] = slot || [];
  const record = useRecordContext();
  const { data: slotsInUse, loading, error } = useSlotsInUse(slots.map(slot => slot.id))

  const handleChange = slot => {
    if (!slot) return;

    if (
      record?.startTime == slot.startTime &&
      record?.endTime == slot.endTime &&
      record?.timezone == slot.timezone &&
      record?.arenaId == slot.arenaId
    ) {
      // clear if selecting same slot
      setSelectedSlot(null);
      batch(() => {
        change('arenaId', null)
        change('startTime', null)
        change('endTime', null)
        change('timezone', null)
      })
    } else {
      setSelectedSlot(slot);
      batch(() => {
        const { startTime, endTime, timezone, arenaId } = slot;
        change('arenaId', arenaId)
        change('startTime', startTime)
        change('endTime', endTime)
        change('timezone', timezone)
      })
    }
  }

  if (!slots?.length) return null;
  return <List dense disablePadding>
    <ListSubheader disableGutters>{translate('resources.availabilities.labels.slots')}</ListSubheader>
    {loading ?
      <LinearProgress className={classes.loading} />
      :
      slots.map(slot => {
        return <RecordContextProvider value={slot} key={slot.id}>
          <SlotItem isSelected={selectedSlot?.id === slot?.id} handleChange={handleChange} isInUse={isSlotInUse(slotsInUse, slot.id, record)} />
        </RecordContextProvider>
      })
    }
  </List>
}

const ConflictList = ({ teamConflicts, surfaceConflicts }) => {
  const translate = useTranslate();
  if (!teamConflicts.length && !surfaceConflicts.length) return null;
  return <List dense disablePadding>
    {surfaceConflicts.length > 0 && <ListSubheader disableGutters>{translate('resources.availabilities.labels.surface_conflicts')}</ListSubheader>}
    {surfaceConflicts.map(conflict => <RecordContextProvider value={conflict} key={conflict.id}>
      <ConflictItem type="arena" />
    </RecordContextProvider>)}
    {teamConflicts.length > 0 && <ListSubheader disableGutters>{translate('resources.availabilities.labels.team_conflicts')}</ListSubheader>}
    {teamConflicts.map(conflict => <RecordContextProvider value={conflict} key={conflict.id}>
      <ConflictItem type="team" />
    </RecordContextProvider>)}
  </List>
}

const ConstraintList = ({ constraints }) => {
  const translate = useTranslate();
  if (!constraints.length) return null;
  return <List dense disablePadding>
    {constraints.length > 0 && <ListSubheader disableGutters>{translate('resources.availabilities.labels.constraints')}</ListSubheader>}
    {constraints.map(constraint => <RecordContextProvider value={constraint} key={constraint.id}>
      <ConstraintItem />
    </RecordContextProvider>)}
  </List>
}

const useItemStyles = makeStyles(theme => ({
  icon: {
    alignSelf: 'start',
    paddingTop: theme.spacing(1.5),
  },
  notes: {
    whiteSpace: 'pre',
    padding: theme.spacing(.5, 1),
  },
  badge: {
    top: 'initial',
    left: 'initial',
    right: 'initial',
    transform: 'scale(1) translate(250%, 250%)',
    transition: 'none'
  },
  selectedBadge: {
    top: 'initial',
    left: 'initial',
    right: 'initial',
    transform: 'scale(1) translate(50%, 50%)',
    transition: 'none'
  },
  conflictItem: {
    cursor: 'pointer',
  },
  slotItem: {
    cursor: props => !props?.readOnly ? 'pointer' : null,
    '&:hover': {
      backgroundColor: props => !props?.readOnly ? theme.palette.action.hover : null,
      borderRadius: 4
    },
    '&:active': {
      backgroundColor: props => !props?.readOnly ? theme.palette.action.selected : null,
      borderRadius: 4,
    }
  },
  loading: {
    minWidth: theme.spacing(24)
  }
}))

const styleIcon = Icon => styled(Icon)({
  fontSize: '1.5em',
  marginRight: '-1em',
  verticalAlign: 'middle',
})

const WarningIcon = styled(ReportProblemOutlined)({
  position: 'relative',
  top: 3,
  left: 4,
  fontSize: '1rem',
})

const TeamIcon = styleIcon(PeopleOutline)
const ArenaIcon = styleIcon(PlaceOutlined)
const ConstraintIcon = styleIcon(LockOutlined)
const SlotIcon = styleIcon(Schedule)
const SelectedIcon = styleIcon(Check)

const AvailabilityItem = () => {
  const classes = useItemStyles();
  const translate = useTranslate();
  const { startTime, endTime, timezone, isAvailable, targetType, notes, isPrimary } = useRecordContext();
  const start = moment.tz(startTime, timezone).format('HH:mm')
  const end = moment.tz(endTime, timezone).format('HH:mm z')
  const color = isAvailable ? "primary" : "error";

  const secondary = [
    `${start} - ${end}`,
  ]
  if (targetType === 'Surface') {
    secondary.unshift(<>
      <OfficeField source="officeId" link={false} />
      {isPrimary && <> ({translate('resources.arenaoffices.fields.isPrimary')})</>}
    </>)
  }
  if (notes) secondary.push(<div className={classes.notes}>{notes}</div>)

  return <ListItem dense>
    <ListItemIcon classes={{ root: classes.icon }}>
      <Badge color={color} badgeContent=" " variant="dot" classes={{ badge: classes.badge }}>
        <TargetTypeField source="targetType" fontSize="large" />
      </Badge>
    </ListItemIcon>
    <ListItemText
      primary={<TargetField source="targetId" includeSeason={false} link={false} />}
      secondary={secondary}
    />
  </ListItem>
}

export const SlotItem = ({ isSelected, handleChange, readOnly = false, isInUse = false }) => {
  const translate = useTranslate();
  const classes = useItemStyles({ readOnly });
  const slot = useRecordContext();
  const isRestricted = slot?.restrictions?.length;
  const color = isSelected ? 'default' : (isRestricted || isInUse) ? 'error' : 'primary';

  const secondary = [<TimeRangeField startSource="startTime" endSource="endTime" label="ra.date.time" />];

  if (isRestricted) {
    secondary.push(<br />, <Typography variant="p">{`${translate('resources.arenaslots.labels.restricted')}: `}</Typography>);
    const restrictions = slot.restrictions.map(restriction => translate(`resources.arenaslots.labels.${restriction}`));
    secondary.push(<Typography variant="p">{restrictions.join(', ')}</Typography>);
  }

  return <ListItem dense onClick={!readOnly ? () => handleChange(slot) : null} className={classes.slotItem}>
    <ListItemIcon classes={{ root: classes.icon }}>
      <Badge
        color={color}
        badgeContent={isSelected ? <SelectedIcon color={(isRestricted || isInUse) ? 'error' : 'primary'} fontSize="large" /> : " "}
        variant={isSelected ? 'standard' : 'dot'}
        classes={{ badge: isSelected ? classes.selectedBadge : classes.badge }}
      >
        <SlotIcon fontSize="large" />
      </Badge>
    </ListItemIcon>
    <ListItemText
      primary={<SurfaceField source="arenaId" link={false} />}
      secondary={secondary}
    />
  </ListItem>
}

const ConflictItem = ({ type }) => {
  const translate = useTranslate();
  const classes = useItemStyles();
  const isEnabled = useFlag();
  const { id, startTime, endTime, timezone, number, name, resource: conflictResource, teamIds, homeTeamId, awayTeamId, type: gameType, schedule } = useRecordContext();

  const start = moment.tz(startTime, timezone).format('HH:mm')
  const end = moment.tz(endTime, timezone).format('HH:mm z')

  const icon = type === "arena" ? <ArenaIcon fontSize="large" /> : <TeamIcon fontSize="large" />
  let PopoverComponent = null;
  let title;
  switch (conflictResource) {
    case 'practices':
      title = `${translate('resources.practices.name', 1)}${name ? `: ${name}` : ''}`;
      PopoverComponent = PracticeDetailsPopover;
      break;
    case 'arenaslots':
      title = `${translate('resources.arenaslots.name', 1)}${name ? `: ${name}` : ''}`;
      PopoverComponent = SlotDetailsPopover;
      break;
    case 'games':
    default:
      title = `${number != null ? number : id}${isDraftGameType(gameType) ? ` (${draftLabel(isEnabled, translate, gameType)})` : ''}`;
      PopoverComponent = GameDetailsPopover;
      break;
  }

  const secondary = [
    `${start} - ${end}`,
  ]
  if (type === "arena") secondary.unshift(<SurfaceField source="arenaId" link={false} />, <br />)
  if (type === "team") {
    if (teamIds) {
      secondary.unshift(<TeamsField source="teamIds" link={false} />, <br />)
    } else {
      if (homeTeamId) {
        secondary.unshift(<TeamField source="homeTeamId" link={false} />, <br />)
      }
      if (awayTeamId) {
        secondary.unshift(<TeamField source="awayTeamId" link={false} />, <br />)
      }
    }
  }
  if (schedule) {
    secondary.unshift(translate(`resources.schedules.values.type.${schedule.type}`), <br />)
  }

  return <PopoverComponent isEditable={false} disableLaunch popRight>
    {(onClick) => {
      return <ListItem onClick={onClick} className={classes.conflictItem} dense>
        <ListItemIcon classes={{ root: classes.icon }}>
          <Badge color="error" badgeContent=" " variant="dot" classes={{ badge: classes.badge }} >
            {icon}
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={<Typography variant="body2" display="inline">{title}</Typography>}
          secondary={secondary}
        />
      </ListItem>
    }}
  </PopoverComponent>
}

const ConstraintItem = () => {
  const classes = useItemStyles();
  const translate = useTranslate();
  const isEnabled = useFlag();
  const { date, number, id, constraint: { limit, period }, type } = useRecordContext();

  const title = `${number != null ? number : id}${isDraftGameType(type) ? ` (${draftLabel(isEnabled, translate, type)})` : ''}`
  const info = `${translate('resources.schedulingConstraints.helpers.limit', { smart_count: limit })} ${translate('resources.schedulingConstraints.helpers.period', { smart_count: period })}`
  const primary = [
    <Typography variant="body2" display="inline">{title}</Typography>,
    <Tooltip title={<Typography variant="body2" display="inline">{info}</Typography>}>
      <WarningIcon />
    </Tooltip>]

  const secondary = [
    <TeamField source="constraint.teamId" link={false} />,
    <Typography variant="body2">{date}</Typography>
  ]

  return <ListItem dense>
    <ListItemIcon classes={{ root: classes.icon }}>
      <Badge color="error" badgeContent=" " variant="dot" classes={{ badge: classes.badge }} >
        <ConstraintIcon fontSize="large" />
      </Badge>
    </ListItemIcon>
    <ListItemText
      primary={primary}
      secondary={secondary}
    />
  </ListItem>
}
