import React, { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, RadioGroup, FormControlLabel, Radio, Tooltip, Typography, makeStyles } from '@material-ui/core'
import { Cached as SwapIcon } from '@material-ui/icons';

import { isDelegated } from '@hisports/assigning';

import { apiClient } from '../../../../../../http';

import { useAssignmentsContext } from '../../AssignmentsContext';

const useStyles = makeStyles(theme => ({
  choiceSubtitle: {
    paddingLeft: '31px',
    marginTop: '-10px'
  },
  bottomText: {
    marginTop: theme.spacing(1.5)
  }
}))

const updatePosition = (gameId, participantId, position) =>
  apiClient(`/games/${gameId}/switchAssignment`, {
    method: 'POST',
    data: { participantId, position },
  }).then(res => res.data);

const SwitchDialog = ({ assignment, assignments, official, open, onClose }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [ selected, setSelected ] = useState()
  const handleCancel = () => onClose();
  const handleChange = e => setSelected(e.target.value)
  const handleConfirm = () => onClose(selected);

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.games.labels.assignment.swap_assignment')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.games.messages.assignment.select_new_assignment', { name: official.participant.fullName })}:
      </DialogContentText>
      <RadioGroup aria-label="assignment" name="assignment" value={selected} onChange={handleChange}>
        {assignments.map(choice => {
          const isCurrentPosition = choice.position === assignment.position
          const isSameAssignOffice = isDelegated(assignment) ? assignment.officeId === choice?.officeId : !choice?.officeId
          const label = translate(`resources.assignFees.fields.${choice.position}`, { _: choice.position })
          let subLabel;

          if (isCurrentPosition) {
            subLabel = translate('resources.games.messages.assignment.swap_current_position')
          } else if (!isSameAssignOffice) {
            subLabel = translate('resources.games.messages.assignment.swap_delegated')
          } else if (choice?.participant?.fullName) {
            subLabel = translate('resources.games.messages.assignment.swap_choice', { name: choice.participant.fullName, position: assignment.position })
          }

          return <>
            <FormControlLabel key={choice.id} label={label} value={choice.id} disabled={isCurrentPosition || !isSameAssignOffice} control={<Radio />} />
            {subLabel && <Typography variant="body2" color="textSecondary" className={classes.choiceSubtitle}>{subLabel}</Typography> }
          </>
        })}
        <DialogContentText className={classes.bottomText}>
          {translate('resources.games.messages.assignment.swap_settings_help')}
        </DialogContentText>
      </RadioGroup>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary" disabled={!selected}>{translate('ra.action.swap')}</Button>
    </DialogActions>
  </Dialog>
}

export default ({ game, assignment, official, disabled, onClose }) => {
  const [ showConfirm, setConfirm ] = useState(false);
  const { assignments, refetch, setOpenAssignmentIndexes } = useAssignmentsContext();
  const notify = useNotify();
  const translate = useTranslate();

  if (!game || !assignment || !official) return null;
  if (!official.participantId) return null;

  const handleConfirm = assignmentId => {
    setConfirm(false);

    const withAssignment = assignments.find(assignment => assignment.id === assignmentId)

    if (!withAssignment) return;

    Promise.all([
      assignment.participantId && updatePosition(game.id, assignment.participantId, withAssignment.position),
      withAssignment.participantId && updatePosition(game.id, withAssignment.participantId, assignment.position)
    ]).then(res => {
      setOpenAssignmentIndexes([]);
    }).catch(err => {
      notify('ra.page.error_try_again', 'error')
    }).finally(() => {
      refetch();
    })
  }

  return <>
    <Tooltip title={translate('resources.games.labels.assignment.swap_assignment_official')}>
      <Button
        color="secondary"
        startIcon={<SwapIcon fontSize="small" />}
        disabled={disabled}
        onClick={() => setConfirm(true)}
      >
        {translate('ra.action.swap')}
      </Button>
    </Tooltip>
    <SwitchDialog assignment={assignment} assignments={assignments} official={official} open={showConfirm} onClose={handleConfirm} />
  </>
}
