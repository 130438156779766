export const dedupe = values => Array.from(new Set(values)).filter(Boolean);

export const dedupeBy = (key, values = []) =>
  values.filter(Boolean).filter((value, index, self) =>
    index === self.findIndex((v) =>
      Array.isArray(key) ? key.every(k => v[k] === value[k]) : v[key] === value[key]
    )
  )

export const sanitize = (value, casing = 'upper') => {
  if (!value) return;
  const trimmed = String(value).trim();
  switch (casing) {
    case 'upper': return trimmed.toUpperCase();
    case 'lower': return trimmed.toLowerCase();
  }
  return trimmed;
}

export const isEmpty = value => {
  if (!value) return true;
  if (Array.isArray(value)) return !value.length;
  if (typeof value === 'object' && Object.keys(value).length <= 0) return true;
  return false;
}

export const hasValue = value =>
  !isEmpty(value)

export const isNumber = value => {
  if (!value) return null;
  return !isNaN(Number(value));
}

const UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
export const isUUID = input => UUID_REGEX.test(input);

export const sum = (arr = []) => arr.reduce((total, row) => {
  return total + (Array.isArray(row) ? sum(row) : row || 0)
}, 0);
