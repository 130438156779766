import React, { useState } from 'react';
import { EditButton, TextField, useRecordContext, useTranslate } from 'react-admin';
import { Table, TableBody, Button, Chip as MuiChip, styled } from '@material-ui/core';
import { PersonAdd, Star, SupervisorAccountOutlined, VerifiedUserOutlined } from '@material-ui/icons';

import { FF_SSO } from '@hisports/common/featureFlags';

import InfoCard from '../../../common/cards/InfoCard';
import Row from '../../../common/cards/TableRow';
import { isAuthorized } from '../../../common/Authorize';
import RegistryLink from '../../../common/RegistryLink';
import { SMAChip } from '../../../common/chips/SMAChip';
import { useFlag, usePermissions } from '../../../http';
import { ParticipantIcon, TeamIcon } from '../../icons';

import { useParticipantStatus } from '../ParticipantStatusContext';
import { InviteDialog } from '../InviteDialog';
import { SsoInviteDialog } from '../SsoInviteDialog';
import { useParticipantAccount } from '../ParticipantAccountContext';

const Chip = styled(MuiChip)(({ theme }) => ({
  margin: theme.spacing(.5),
  padding: theme.spacing(.5),
}))

export const InviteButton = ({ participant, size = 'medium' }) => {
  const [ open, setOpen ] = useState(false);
  const translate = useTranslate();

  if (!participant) return null;
  return <>
    <Button color="primary" size={size} startIcon={<PersonAdd />} onClick={() => setOpen(true)}>{translate('ra.action.invite')}</Button>
    <InviteDialog participantId={participant.id} open={open} onClose={() => setOpen(false)} />
  </>
}

export const SsoInviteButton = ({ participant }) => {
  const [ open, setOpen ] = useState(false);
  const translate = useTranslate()

  if (!participant) return null;
  return <>
    <Button color="primary" size="small" startIcon={<SupervisorAccountOutlined />} onClick={() => setOpen(true)}>
      {translate('ra.action.invite')}
    </Button>
    <SsoInviteDialog participantId={participant.id} open={open} onClose={() => setOpen(false)} />
  </>
}

const getIcon = type => {
  switch (type) {
    case 'Spordle':
      return <Star fontSize="small" />
    case 'Admin':
    case 'League':
    case 'Assigner':
      return <VerifiedUserOutlined fontSize="small" />
    case 'Staff':
    case 'Player':
      return <TeamIcon fontSize="small" />
    case 'Official':
    case 'Coach':
    case 'Scorekeeper':
    case 'Monitor':
      return <ParticipantIcon fontSize="small" />
    default:
      return null;
  }
}

const TypeChip = ({ type }) => {
  const translate = useTranslate();
  const label = translate(`resources.participants.labels.status.types.${type}`, { _: type });
  return <Chip variant="outlined" size="small" label={label} icon={getIcon(type)} />
}

export default props => {
  const participant = useRecordContext(props);
  const status = useParticipantStatus();
  const permissions = usePermissions();
  const isEnabled = useFlag();
  const accounts = useParticipantAccount();

  const canEditParticipant = isAuthorized(participant, 'participants', 'edit');
  const canInvite = isAuthorized(participant, 'participants', 'invite') && !status.accounts && status.types.length > 0;
  const canSsoInvite = isEnabled(FF_SSO) && (!status.accounts || status.accounts === 'Invited') && status.types.length > 0;
  const Invite = (canInvite && !canSsoInvite)
    ? <InviteButton participant={participant} />
    : (canInvite && canSsoInvite ? <SsoInviteButton participant={participant} /> : null);

  return <InfoCard
    title="resources.participants.labels.card.title"
    actions={[
      canEditParticipant && <EditButton label="resources.participants.actions.edit" record={participant} />,
      Invite,
      <RegistryLink />,
    ]}
  >
    <Table>
      <TableBody>
        <Row label="resources.participants.fields.fullName">
          <TextField source="fullName" />
        </Row>
        <Row label="resources.participants.fields.HCRId" hidden={!participant?.HCRId}>
          <TextField source="HCRId" />
        </Row>
        <Row label="resources.participants.labels.status.roles" hidden={!status.types?.length}>
          {status.types.map(type => <TypeChip type={type} />)}
        </Row>
        <Row label="resources.participants.fields.accountEmail" hidden={!accounts?.email}>
          {accounts?.email}{accounts?.externalId && <SMAChip />}
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
