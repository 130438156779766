import React from 'react';
import { TextField, EditButton, useTranslate, useRecordContext, FunctionField } from 'react-admin';
import { Table, TableBody, Button } from '@material-ui/core';
import { Directions } from '@material-ui/icons';

import Row from '../../../common/cards/TableRow';
import InfoCard from '../../../common/cards/InfoCard';
import { isAuthorized } from '../../../common/Authorize';
import { VenueField } from '../../venues/VenueField';

import { getSurfaceCity } from '../util';
import { SurfaceField } from '../SurfaceField';
import { EnumField } from '../../../common/inputs/EnumInputs';

const DirectionsButton = ({ surface }) => {
  const translate = useTranslate()

  const address = getSurfaceCity(surface, true, true, true)
  if (!address) return null;
  const href = `https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=${address}`;
  return <Button color="primary" size="small" startIcon={<Directions />} href={href} target="_blank">
    {translate('ra.action.directions')}
  </Button>
}

export default props => {
  const surface = useRecordContext(props);
  const canEditSurface = isAuthorized(surface, 'surfaces', 'edit');

  const city = getSurfaceCity(surface, true, true, false)
  return <InfoCard title="resources.surfaces.name" actions={[
    canEditSurface && <EditButton label="resources.surfaces.actions.edit" record={surface} />,
    <DirectionsButton surface={surface} />
  ]}>
    <Table>
      <TableBody>
        <Row label="resources.surfaces.fields.venueId">
          <VenueField source="venueId" />
        </Row>
        {surface?.parentId && <Row label="resources.surfaces.fields.parentId">
          <SurfaceField source="parentId" includeAttributes="full" />
        </Row>}
        <Row label="resources.surfaces.fields.name">
          <TextField source="name" />
        </Row>
        <Row label="resources.surfaces.fields.address">
          {surface.venue?.address && <TextField source="venue.address" />}
          {surface.venue?.address && city && <br />}
          {city && <FunctionField source="city" render={() => city} />}
        </Row>
        <Row label="resources.surfaces.fields.type" hidden={!surface?.type}>
          <EnumField source="type" />
        </Row>
        <Row label="resources.surfaces.fields.size" hidden={!surface?.size}>
          <EnumField source="size" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
