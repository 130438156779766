import React, { useState } from "react";
import { useTranslate } from "react-admin";
import Iframe from "react-iframe";
import { IntlProvider } from "react-intl";

import { Button, makeStyles, Typography } from "@material-ui/core";
import FileViewer from "../../common/fileViewer/FileViewer";

const useStyles = makeStyles(theme => ({
  frame: {
    width: '100%',
    height: theme.spacing(60),
    border: '1px black solid'
  },
}))

export default ({ url, scheduleId, showIframe = false }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ fileOpen, setFileOpen ] = useState(false);

  return <>
    {showIframe && <Iframe url={`${url}/svg`} className={classes.frame} />}
    <IntlProvider>
      <Button color="primary" size="small" onClick={() => setFileOpen(true)}>
        <Typography variant="inherit">{translate('resources.schedules.labels.brackets_viewer.open_in_viewer')}</Typography>
      </Button>
      {fileOpen && <FileViewer
        isOpen={fileOpen}
        close={() => setFileOpen(false)}
        fileName={scheduleId}
        fullPath={`${url}/svg`}
        type="svg"
        openInNewTabURL={`${url}/svg`}
        URLToFetchForDownload={`${url}/pdf`} />}
    </IntlProvider></>
}
