import React, { useEffect, useState } from 'react';
import { DatagridRow, RecordContextProvider, useListContext } from 'react-admin'
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';


import GroupedView from '../../common/GroupedView';
import DateField from '../../common/fields/DateField';

import { OfficeField } from '../offices/OfficeField';
import { GameField } from '../games/GameField';
import { ParticipantField } from '../participants/ParticipantField';
import { PayPeriodField } from '../payPeriods/PayPeriodField';

import { useGroupGames, useGroupParticipants } from './TransactionViewSettings';
import { AddTransactionButton, EditTransactionDialog } from './TransactionModal';
import { AmountAndDescriptionField, TypeAndNoteField } from './TransactionsGrid';

const useStyles = makeStyles(theme => ({
  root: {
    '& .column-date': {
      width: theme.spacing(12),
    },
    '& .column-type': {
      width: theme.spacing(25),
    },
    '& .column-amount': {
      width: theme.spacing(12),
    },
  }
}))


const isSelectable = (transactions, payPeriods = {}) => id => {
  const transaction = transactions?.[id]
  const payPeriod = payPeriods[transaction?.payPeriodId];
  if (!payPeriod) return true;
  return payPeriod.isClosed !== true;
}

const OfficialTransactionTitle = ({ group: id }) => {
  const [ groupGames ] = useGroupGames();

  return <RecordContextProvider value={{ id }}>
    {groupGames ? <GameField source="id" includeDate="inline" includeTime includeSurface="inline" /> : <ParticipantField source="id" variant="subtitle2" includeId="inline" />}
  </RecordContextProvider>
}

const AddTransactionFromGroupButton = ({ group: id, initialValues = {}, canAdd = true, ...props }) => {
  const [ groupGames ] = useGroupGames();

  if (!canAdd) return null;

  return <AddTransactionButton
    initialValues={{
      ...initialValues,
      ...(groupGames ? { gameId: id } : { participantId: id })
    }}
    target="officeId"
    size="small"
    {...props}
  />
}

const GroupedTransactionList = ({ records = [], basePath, resource, classes: classesOverrides, hideOffice = false, hidePayPeriod = false, isRowSelectable, ...props }) => {
  const [ groupParticipants ] = useGroupParticipants();
  const [ groupGames ] = useGroupGames();
  const { onToggleItem, selectedIds } = useListContext()
  const [ isOpen, setIsOpen ] = useState(false);
  const [ rowClickId, setRowClickId ] = useState();
  const classes = useStyles({ classes: classesOverrides });

  const handleRowClick = (id) => {
    setRowClickId(id)
    setIsOpen(true);
  }
  const handleClose = () => {
    setIsOpen(false);
  }

  if (!records?.length) return null;

  return <>
    {records.map((record, rowIndex) => {
      const selected = selectedIds.includes(record?.id);
      const rowClick = record.type !== 'Base' ? handleRowClick : null;
      const selectable = isRowSelectable(record.id);

      const style = record.type !== 'Base' && selectable ? { cursor: 'pointer' } : undefined;

      return <DatagridRow selectable={selectable} onToggleItem={onToggleItem} rowClick={selectable ? rowClick : null} selected={selected} key={record.id} basePath={basePath} resource={resource} record={record} id={record.id} style={style} className={classes.root} {...props}>
        {!groupParticipants && <ParticipantField source="participantId" link="show" includeId="inline" />}
        {!groupGames && <GameField source="gameId" link="scoresheet" includeDate="inline" includeTime includeSurface="full" />}
        {!hideOffice && <OfficeField source="officeId" link={false} />}
        <DateField source="date" />
        <TypeAndNoteField source="type" />
        <AmountAndDescriptionField source="amount" />
        {!hidePayPeriod && <PayPeriodField source="payPeriodId" />}
      </DatagridRow>
    })}
    {isOpen && <EditTransactionDialog id={rowClickId} handleClose={handleClose} isOpen={isOpen} {...props} />}
  </>
}

export const GroupedTransactionView = ({ office, ...props }) => {
  const [ groupGames ] = useGroupGames();
  const { data: transactions, selectedIds, onToggleItem } = useListContext();
  const payPeriods = useSelector(state => state?.admin?.resources?.payPeriods?.data)

  const groupingKey = groupGames ? 'gameId' : 'participantId'
  const isRowSelectable = isSelectable(transactions, payPeriods);

  useEffect(() => {
    selectedIds.forEach(id => {
      if (!isRowSelectable(id)) {
        onToggleItem(id)
      }
    })
  }, [ isRowSelectable, onToggleItem, selectedIds ])

  return <GroupedView
    grouping={transaction => transaction[groupingKey]}
    titleComponent={OfficialTransactionTitle}
    isRowSelectable={isRowSelectable}
    actionButton={AddTransactionFromGroupButton}
    {...props}
  >
    <GroupedTransactionList isRowSelectable={isRowSelectable} {...props} />
  </GroupedView>
}
