import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { RegistrationGrid } from '../../registrations/RegistrationGrid';
import { AddRegistrationButton } from '../../registrations/RegistrationModal';

export default props => {
  const participant = useRecordContext(props);
  const seasonId = useSeason();

  const showRegistrations = isAuthorized(participant, 'registrations', 'list')
  const canAddRegistration = isAuthorized(participant, 'registrations', 'create')

  if (!showRegistrations) return null;
  return <ListCard
    title="resources.registrations.labels.card.title"
    reference="registrations"
    target="participants"
    filter={{ seasonId }}
    addButton={canAddRegistration && <AddRegistrationButton initialValues={{ participantId: participant?.id }} />}
  >
    <RegistrationGrid />
  </ListCard>
}
