import React, { Fragment, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button, SimpleForm, useListContext, useNotify, useRefresh, useTranslate } from 'react-admin';

import { isAuthorized } from '../../common/Authorize';
import { apiClient, useScopes } from '../../http';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { ArenaSlotTypeEnumInput } from '../../common/inputs/EnumInputs';
import CheckboxRowInput from '../../common/inputs/CheckboxRowInput';

import { CategoryInput } from '../categories/CategoryInput';
import { OfficeInput } from '../offices/OfficeInput';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const ArenaSlotRestrictionDialog = ({ isOpen, onSubmit, onClose, arenaSlotsIds = [] }) => {
  const translate = useTranslate();
  return <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.arenaslots.labels.slots_restrictions')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.arenaslots.alerts.bulk_slots_restrictions')}
      </DialogContentText>
      <ArenaSlotRestrictionForm save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.update" onCancel={onClose} />
      } />
    </DialogContent>
  </Dialog>
}

const ArenaSlotRestrictionForm = props => {
  return <SimpleForm {...props}>
    <CheckboxRowInput resource="arenaslots" source="types" multiple helperText="resources.arenaslots.helpers.types" {...inputProps}>
      <ArenaSlotTypeEnumInput />
    </CheckboxRowInput>
    <CheckboxRowInput resource="arenaslots" source="categoryIds" multiple helperText="resources.arenaslots.helpers.categoryIds" {...inputProps}>
      <CategoryInput />
    </CheckboxRowInput>
    <CheckboxRowInput resource="arenaslots" source="leagueIds" multiple helperText="resources.arenaslots.helpers.leagueIds" filter={{ type: ['League', 'Tournament', 'Cup'] }} {...inputProps}>
      <OfficeInput />
    </CheckboxRowInput>
  </SimpleForm>
}

const ArenaSlotsRestrictions = ({ selectedIds }) => {
  const [ open, setOpen ] = useState(false);
  const { onUnselectItems } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onSubmit = (values) => {
    const {
      typesIsChecked, types,
      categoryIdsIsChecked, categoryIds,
      leagueIdsIsChecked, leagueIds,
    } = values;

    apiClient(`/arenaslots/bulkRestrictions`, {
      method: 'POST',
      data: {
        arenaSlotsIds: selectedIds,
        restrictions: JSON.parse(JSON.stringify({
          types: typesIsChecked ? (types || []) : undefined,
          categoryIds: categoryIdsIsChecked ? (categoryIds || []) : undefined,
          leagueIds: leagueIdsIsChecked ? (leagueIds || []) : undefined,
        }))
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        refresh();
        onUnselectItems();
        notify(translate('resources.arenaslots.notifications.update_restrictions_success', { smart_count: count }), 'success')
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate('resources.arenaslots.notifications.update_restrictions_error', { smart_count: selectedIds.length }), 'error')
      })

    setOpen(false);
  }

  return <>
    <Button label={translate('resources.arenaslots.labels.set_slots_restrictions')} onClick={() => setOpen(true)} disabled={!selectedIds.length} />
    <ArenaSlotRestrictionDialog isOpen={open} onSubmit={onSubmit} onClose={() => setOpen(false)} arenaSlotsIds={selectedIds} />
  </>
}

const DeleteDialog = ({ open, onSubmit, onClose, arenaSlotsIds = [] }) => {
  const translate = useTranslate();

  return <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.arenaslots.labels.delete_slots', { smart_count: arenaSlotsIds.length })}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.arenaslots.messages.confirm_delete', { smart_count: arenaSlotsIds.length })}
      </DialogContentText>
      <SimpleForm
        save={onSubmit}
        component={Fragment}
        toolbar={<DialogFormToolbar submitLabel="ra.action.delete" cancelLabel="ra.action.cancel" onCancel={onClose} hideSubmit={!arenaSlotsIds?.length} />}
      />
    </DialogContent>
  </Dialog>
}

const Delete = ({ selectedIds }) => {
  const [ open, setOpen ] = useState(false);
  const { onUnselectItems } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onSubmit = async () => {
    apiClient(`/arenaslots/bulkDelete`, {
      method: 'DELETE',
      data: {
        arenaSlotsIds: selectedIds,
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        refresh();
        onUnselectItems();
        notify(translate('resources.arenaslots.notifications.delete_slots_success', { smart_count: count }), 'success')
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate('resources.arenaslots.notifications.delete_slots_error', { smart_count: selectedIds.length }), 'error')
      })

    setOpen(false);
  }

  return <>
    <Button label={translate('ra.action.delete')} onClick={() => setOpen(true)} disabled={!selectedIds.length} />
    <DeleteDialog open={open} onSubmit={onSubmit} onClose={() => setOpen(false)} arenaSlotsIds={selectedIds} />
  </>
}

export const ArenaSlotBulkActions = props => {
  const scopes = useScopes();
  const canChangeRestriction = isAuthorized(scopes, 'arenaslots', 'edit');
  const canDeleteArenaSlots = isAuthorized(scopes, 'arenaslots', 'remove');

  return <>
    {canChangeRestriction && <ArenaSlotsRestrictions {...props} />}
    {canDeleteArenaSlots && <Delete {...props} />}
  </>
}
