import React from 'react';
import { useTranslate } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

import { List, Show, Create, Edit } from '../../common/ra';
import { useSeason } from '../../common/inputs/SeasonSelector';
import ResourceTitle from '../../common/ResourceTitle';

import { EventView } from '../events/EventView';
import { useEventSort } from '../events/EventViewSettings';

import { PracticeFilters } from './PracticeFilters';
import { PracticeFilterList } from './PracticeFilterList';
import { PracticeShowLayout } from './PracticeShow';
import { PracticeForm } from './PracticeForm';
import PracticesExporter from './PracticesExporter';
import { PracticeBulkActions } from './PracticeBulkActions';
import { isAuthorized } from '../../common/Authorize';

export const PracticeList = props => {
  const seasonId = useSeason();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const sort = useEventSort();
  const showBulkActions = isAuthorized(props.permissions, 'practices', 'bulkActions')

  return <List {...props}
    exporter={PracticesExporter}
    aside={!isSmall && <PracticeFilterList />}
    filters={isSmall && <PracticeFilters />}
    filterDefaultValues={{ seasonId }}
    bulkActionButtons={showBulkActions ? <PracticeBulkActions /> : false}
    sort={sort}
    resetFilters={isSmall}
  >
    <EventView rowClick="show" disableCalendar />
  </List>
}

const getTitle = (practice, translate) => {
  const type = translate('resources.practices.name', 1)
  if (!practice.name?.length) return type;
  return `${type}: ${practice.name}`
}

export const PracticeShow = props => {
  const translate = useTranslate();
  return <Show {...props}
    title={<ResourceTitle title={record => getTitle(record, translate)} />}
  >
    <PracticeShowLayout />
  </Show>
}

export const PracticeCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={() => translate('resources.practices.labels.add')} />}
    {...props}
  >
    <PracticeForm redirect="show" />
  </Create>
}

export const PracticeEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('resources.practices.labels.edit')} />}
    {...props}
  >
    <PracticeForm redirect="show" />
  </Edit>
}
