import React from 'react';
import { useRecordContext } from 'react-admin';

import InfoCard from '../../../common/cards/InfoCard';
import { isAuthorized } from '../../../common/Authorize';

import { ProfileField } from '../../profiles/ProfileField';
import { EditProfileButton } from '../../profiles/ProfileModal';

export default props => {
  const participant = useRecordContext(props);

  const canShowProfile = isAuthorized(participant, "profiles", "show");
  const canEditProfile = isAuthorized(participant, "profiles", "edit");
  if (!canShowProfile) return null;

  return <InfoCard title="resources.profiles.labels.card.title" actions={
    canEditProfile && <EditProfileButton />
  }>
    <ProfileField source="id" />
  </InfoCard>
}
