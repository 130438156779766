import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import SanctionForm from './SanctionForm';

const AddSanctionDialog = ({ office, ...props }) =>
  <CreateResourceDialog label="resources.sanctions.actions.add" resource="sanctions" basePath="/sanctions" {...props}>
    <SanctionForm office={office} />
  </CreateResourceDialog>


const EditSanctionDialog = ({ office, ...props }) =>
  <EditResourceDialog label="resources.sanctions.labels.edit" resource="sanctions" basePath="/sanctions" {...props}>
    <SanctionForm office={office} />
  </EditResourceDialog>

export const AddSanctionButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.sanctions.actions.add')}
    </Button>
    <AddSanctionDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const SanctionDatagrid = props => <DialogDatagrid dialog={EditSanctionDialog} {...props} />
