import React, { Suspense } from 'react';
import { useInput } from 'react-admin';

import { LazyBoundary } from '../../LazyBoundary';

const Editor = React.lazy(() => import('./Editor'))

const defaultValue = [
  { type: 'paragraph', children: [{ text: '' }] },
]

export default props => {
  const {
    input: { value, onChange },
  } = useInput({ ...props, defaultValue });

  return <Suspense fallback={() => null}>
    <LazyBoundary>
      <Editor value={value} onChange={onChange} />
    </LazyBoundary>
  </Suspense>
}
