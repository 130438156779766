import React, { useState } from 'react';
import { useTranslate, useNotify, useRefresh, useRecordContext } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Replay } from '@material-ui/icons';

import { undoCertifyScoresheet } from '@hisports/scoresheet/src/actions';

import { isAuthorized } from '../../../../common/Authorize';
import AlertDialog from '../../../../common/dialogs/AlertDialog';

import { useScoresheetDispatch } from '../../ScoresheetContext';

const useStyles = makeStyles((theme) => ({
  alert: {
    '& .MuiAlert-action': {
      paddingTop: theme.spacing(0.25),
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    }
  },
  flags: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(-2),
  }
}));

export const ExpandButton = ({ open, setOpen }) => {
  const translate = useTranslate();
  return <Button color="inherit" size="small" onClick={() => setOpen(open => !open)}>{translate(`resources.scoresheets.labels.${open ? 'less_info' : 'more_info'}`)}</Button>
}

export const UndoApprovalButton = ({ gameId }) => {
  const translate = useTranslate();
  const dispatch = useScoresheetDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const [ alert, setAlert ] = useState({ isOpen: false });

  const onClick = async () => {
    const result = await new Promise(resolve => {
      setAlert({
        title: translate('resources.scoresheets.messages.undo_certify.title'),
        message: translate('resources.scoresheets.messages.undo_certify.message'),
        reject: 'ra.action.cancel',
        accept: 'ra.action.continue',
        isOpen: true,
        onClose: result => {
          resolve(result)
          setAlert(alert => ({ ...alert, isOpen: false }))
        }
      })
    })
    if (!result) return;

    try {
      await dispatch(undoCertifyScoresheet(gameId))

      notify('resources.scoresheets.messages.successful_game_undo_certified', 'info')

      setTimeout(() => refresh(), 500);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      notify('ra.message.error', 'error')
    }
  }

  return <>
    <Button color="primary" size="small" startIcon={<Replay />} onClick={onClick}>
      {translate('resources.scoresheets.labels.undo_certify')}
    </Button>
    <AlertDialog {...alert} />
  </>
}

export const CertifiedAlert = props => {
  const translate = useTranslate();
  const classes = useStyles({ open });
  const game = useRecordContext(props);

  const canUndoApproval = isAuthorized(game, 'scoresheets', 'full')

  return <Alert className={classes.alert} severity="success" action={[
    canUndoApproval && <UndoApprovalButton gameId={game.id} />,
  ]}>
    {translate('resources.scoresheets.alerts.certified')}
  </Alert>
}
