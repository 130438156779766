import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';

import { ContactGrid } from '../../../resources/contacts/ContactGrid';
import { AddContactButton } from '../../../resources/contacts/ContactModal';

export default props => {
  const account = useRecordContext();
  if (!account?.participantId) return null;

  return <ListCard
    title="resources.contacts.labels.card.title"
    reference="contacts"
    target="participants"
    sort={{ field: 'isPrimary', order: 'DESC' }}
    rowsPerPageOptions={[]}
    addButton={<AddContactButton initialValues={{ participantId: account?.participantId }} />}
  >
    <ContactGrid />
  </ListCard>
}
