import React from 'react';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { VenueView } from './VenueView';
import { VenueFilters } from './VenueFilters';
import { VenueShowLayout } from './VenueShow';

import { getVenueName } from './util';
import { VenueForm } from './VenueForm';
import { useTranslate } from 'react-admin';

export const VenueList = props =>
  <List {...props}
    component="div"
    filters={<VenueFilters />}
    filterDefaultValues={{ _scope: 'Tenant' }}
    title="Venues"
    sort={{
      field: ['country', 'region', 'city', 'name'],
      order: ['ASC', 'ASC', 'ASC', 'ASC'],
    }}
  >
    <VenueView rowClick="show" />
  </List>

export const VenueShow = props =>
  <Show {...props}
    title={<ResourceTitle title={getVenueName} />}
  >
    <VenueShowLayout />
  </Show>

export const VenueCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.venues.actions.add')} />}
    {...props}
  >
    <VenueForm redirect="show" />
  </Create>
}

export const VenueEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: getVenueName(record) })} />}
    {...props}
  >
    <VenueForm redirect="show" />
  </Edit>
}
