import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Gavel } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { SuspensionStatusInput } from '../../../common/inputs/EnumInputs';
import { SuspensionGrid } from '../../scoresheet/suspension/SuspensionGrid';
import { TeamInput } from '../../teams/TeamInput';
import { CategoryInput } from '../../categories/CategoryInput';

export default props => {
  const translate = useTranslate();
  const participant = useRecordContext(props);
  const participantId = participant?.id;

  const filters = [
    <TeamInput source="teamId" variant="outlined" />,
    <CategoryInput source="game.categoryId" label="resources.games.fields.categoryId" variant="outlined" />,
    <SuspensionStatusInput source="status" variant="outlined" multiple />,
  ]

  return <ListCard
    title={translate("resources.suspensions.name", 2)}
    icon={<Gavel />}
    basePath="/suspensions"
    resource="suspensions"
    filter={{ participantId }}
    sort={{ field: ['game.date', 'participant.lastName', 'participant.firstName'], order: ['DESC', 'ASC', 'ASC'] }}
    filters={filters}
    {...props}
  >
    <SuspensionGrid multipleGames hideParticipant rowOptions={{ isRowSelectable: () => false }} />
  </ListCard>
}
