import React from 'react';
import { Card, CardContent } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles';

const createTheme = theme => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiCard: {
      root: {
        order: -1,
        width: '20em',
        paddingTop: '16px',
        paddingBottom: '16px',
        marginTop: '-56px',
        marginRight: '1em',
        minHeight: '75vh',
        minWidth: 288,
        position: 'relative',
        zIndex: 1,
      }
    },
    RaAutocompleteArrayInput: {
      chipContainerOutlined: {
        margin: 8,
      },
    },
    RaFilterForm: {
      form: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      }
    },
    RaFilterFormInput: {
      body: {
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        width: '100%',
      },
      spacer: {
        width: 0,
        height: 0,
      },
      hideButton: {
        order: 2,
        '& svg': {
          width: '0.85em',
          height: '0.85em',
        }
      }
    },
    RaNullableBooleanInput: {
      input: {
        width: '100%',
      }
    },
    MuiChip: {
      root: {
        height: 'auto',
      },
      label: {
        whiteSpace: 'break-spaces',
        padding: '4px 8px',
      }
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 0,
      },
    },
    MuiAutocomplete: {
      hasClearIcon: {
        width: '100%'
      },
      hasPopupIcon: {
        '&:not(.Mui-focused) label': {
          right: 48,
          whiteSpace: 'pre',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      }
    },
    HiAutocompleteInput: {
      input: {
        minWidth: '0px'
      }
    }
  }
})

export const FilterCard = ({ children }) => {
  return <ThemeProvider theme={createTheme}>
    <Card>
      <CardContent>
        { children }
      </CardContent>
    </Card>
  </ThemeProvider>
}
