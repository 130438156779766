import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core'
import { UnarchiveOutlined } from '@material-ui/icons'

import { LazyDialog } from '../../../../common/LazyDialog';

const Uploader = React.lazy(() => import('./Uploader'))

export default ({ size, variant }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<UnarchiveOutlined />} onClick={() => setOpen(true)}>
      {translate('ra.action.import')}
    </Button>
    <LazyDialog>
      {isOpen && <Uploader isOpen={isOpen} onClose={() => setOpen(false)} />}
    </LazyDialog>
  </>
}
