import React from 'react';
import { useRecordContext } from 'react-admin'

import { FF_DRAFT_GAMES } from '@hisports/common/featureFlags';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { useFlag } from '../../../http';

import DraftGrid from '../../drafts/DraftGrid';
import { AddDraftButton } from '../../drafts/dialogs/DraftDialog';

export default props => {
  const schedule = useRecordContext(props);
  const isEnabled = useFlag()

  const canCreateDrafts = isAuthorized(schedule, 'drafts', 'create');
  const canManageDrafts = isAuthorized(schedule, 'drafts', 'edit');
  if (!canManageDrafts) return null;

  const isSharedDrafts = isAuthorized(schedule, 'drafts', 'shared')
  return <ListCard
    title={`resources.drafts.labels.card.title${isEnabled(FF_DRAFT_GAMES) ? '_legacy' : ''}`}
    collapsable={!isSharedDrafts}
    target="schedules"
    reference="drafts"
    filter={{ isPublished: false }}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    rowsPerPageOptions={[]}
    addButton={canCreateDrafts && <AddDraftButton /> }
    {...props}
  >
    <DraftGrid rowClick={draftId => `/drafts/${draftId}/review`} />
  </ListCard>
}
