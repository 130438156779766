import React, { useState, useEffect } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { Table, TableHead, TableBody, TableRow, TableCell, Link, Tooltip, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { Flag } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment-timezone';

import { FF_OFFICIALS, FF_SCOREKEEPERS, FF_TIMEKEEPERS, FF_ADDITIONAL_LINESPERSONS } from '@hisports/common/featureFlags';
import { getSeasonDisplayName } from '@hisports/common/src/seasons';

import { apiClient, useFlag, useSport } from '../../../../../../http';
import LoadingAlert from '../../../../../../common/LoadingAlert';
import { TeamField } from '../../../../../teams/TeamField';
import { SurfaceField } from '../../../../../surfaces/SurfaceField';

import { AssignmentPosition } from '../AssignmentStatus'
import { useAssignmentsContext } from '../../AssignmentsContext';
import { useAssignmentContext } from '../AssignmentContext';

const useStyles = makeStyles(theme => ({
  titleRow: {
    paddingTop: theme.spacing(1.5),
  },
}))

const getSlotBackground = ({ assignmentGame, panelGame }) => {
  if (!assignmentGame || !panelGame) return 'inherit';
  if (assignmentGame.id !== panelGame.id) return 'inherit';
  return `repeating-linear-gradient(
    -55deg,
    rgba(97, 97, 97, .025),
    rgba(97, 97, 97, .025) 8px,
    rgba(97, 97, 97, .05) 8px,
    rgba(97, 97, 97, .05) 16px
  )`
}

const useAssignmentStyles = makeStyles(theme => ({
  row: {
    // borderLeftWidth: theme.spacing(.5),
    // borderLeftStyle: 'solid',
    // borderLeftColor: props => getSlotColor(props),
    background: props => getSlotBackground(props),
  },
  cell: {
    border: 0,
  },
  fixedCell: {
    whiteSpace: 'pre',
    border: 0,
  },
  date: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
  },
  flag: {
    fontSize: '.75rem',
    marginLeft: 4,
  }
}))

const GameFlag = ({ game, panelGame, className }) => {
  const translate = useTranslate();

  if (game.id !== panelGame.id) return null;
  return <span>
    <Tooltip title={translate('resources.games.labels.assignment.this_game')}>
      <Flag className={className} />
    </Tooltip>
  </span>
}

const TeamFlag = ({ teamId, game, panelGame, title, className }) => {
  const translate = useTranslate();

  if (game.id === panelGame.id) return null;

  const teamIds = [panelGame.homeTeamId, panelGame.awayTeamId];
  if (!teamIds.includes(teamId)) return null;

  return <span>
    <Tooltip title={translate('ra.action.repeat')}>
      <Flag className={className} />
    </Tooltip>
  </span>
}

const Assignment = ({ game: panelGame, assignment, showDate }) => {
  const { game } = assignment;
  const classes = useAssignmentStyles({ assignment, assignmentGame: game, panelGame });
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'))

  if (!game) return null;

  const date = moment.tz(game.startTime, game.timezone).format('LL')

  let details;
  if (small) {
    details = <TableCell colSpan={3}>
      <p>
        <Link component={RouterLink} to={`/games/${game.id}/show/officials`}>
          {game.number}
        </Link>
        <GameFlag game={game} panelGame={panelGame} className={classes.flag} />
      </p>
      <p>
        H: <TeamField source="game.homeTeamId" />
        <TeamFlag teamId={game.homeTeamId} game={game} panelGame={panelGame} className={classes.flag} />
      </p>
      <p>
        A: <TeamField source="game.awayTeamId" />
        <TeamFlag teamId={game.awayTeamId} game={game} panelGame={panelGame} className={classes.flag} />
      </p>
      <p>V: <SurfaceField source="game.arenaId" /></p>
      <p><AssignmentPosition assignment={assignment} /></p>
    </TableCell>
  } else {
    details = <>
      <TableCell className={classes.fixedCell}>
        <Link component={RouterLink} to={`/games/${game.id}/show/officials`}>
          {game.number}
        </Link>
        <GameFlag game={game} panelGame={panelGame} className={classes.flag} />
      </TableCell>
      <TableCell className={classes.cell}>
        <TeamField source="game.homeTeamId" />
        <TeamFlag teamId={game.homeTeamId} game={game} panelGame={panelGame} className={classes.flag} title="Home team" />
        <br />
        <TeamField source="game.awayTeamId" />
        <TeamFlag teamId={game.awayTeamId} game={game} panelGame={panelGame} className={classes.flag} title="Away team" />
      </TableCell>
      <TableCell className={classes.cell}>
        <SurfaceField source="game.arenaId" />
      </TableCell>
      <TableCell className={classes.fixedCell} align="right">
        <AssignmentPosition assignment={assignment} />
      </TableCell>
    </>
  }
  return <>
    {showDate && <TableRow className={classes.date}>
      <TableCell colSpan={4}>
        {date}
      </TableCell>
    </TableRow>}
    <TableRow className={classes.row}>
      {details}
    </TableRow>
  </>
}

const countAssignments = (date, assignments, timezone) => {
  const yesterday = moment.tz(date, timezone).subtract(1, 'day');
  const week = [
    moment.tz(date, timezone).startOf('week').add(1, 'day'),
    moment.tz(date, timezone).endOf('week').add(1, 'day'),
  ]
  const lastWeek = [
    moment(date).subtract(1, 'week').startOf('week').add(1, 'day'),
    moment(date).subtract(1, 'week').endOf('week').add(1, 'day'),
  ]
  const lastMonth = moment.tz(date, timezone).subtract(1, 'month');

  return assignments.reduce((history, assignment) => {
    const gameTime = moment.tz(assignment.game.startTime, timezone)

    if (gameTime.isSame(date, 'day')) history.day++;
    if (gameTime.isSame(yesterday, 'day')) history.lastDay++;

    if (gameTime.isBetween(...week, 'day', '[]')) history.week++;
    if (gameTime.isBetween(...lastWeek, 'day', '[]')) history.lastWeek++;

    if (gameTime.isSame(date, 'month')) history.month++;
    if (gameTime.isSame(lastMonth, 'month')) history.lastMonth++;

    return history;
  }, {
    'day': 0, 'week': 0, 'month': 0,
    'lastDay': 0, 'lastWeek': 0, 'lastMonth': 0,
    'season': assignments.length,
  })
}

const HistoryRow = ({ history, id, title }) => {
  const translate = useTranslate()
  const classes = useStyles();

  if (!id && title) {
    return <TableRow>
      <TableCell variant="head" component="th" scope="row" className={classes.titleRow}>{translate(title, { _: title })}</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
    </TableRow>
  }

  const total = history[id];
  if (!total) return null
  return <TableRow>
    <TableCell component="th" scope="row">{translate(title, { _: title })}</TableCell>
    <TableCell align="right">{total.day} <Typography display="inline" variant="body2" color="textSecondary">({total.lastDay})</Typography></TableCell>
    <TableCell align="right">{total.week} <Typography display="inline" variant="body2" color="textSecondary">({total.lastWeek})</Typography></TableCell>
    <TableCell align="right">{total.month} <Typography display="inline" variant="body2" color="textSecondary">({total.lastMonth})</Typography></TableCell>
    <TableCell align="right">{total.season}</TableCell>
  </TableRow>
}

const DateRangeTitle = ({ current, last, title }) =>
  <Tooltip title={<>
    <Typography variant="body1">{current}</Typography>
    {last && <Typography variant="body2">({last})</Typography>}
  </>}>
    <span>
      {title}
    </span>
  </Tooltip>

const useHistoryStyles = makeStyles(theme => ({
  root: {
    overflowY: 'auto',
  },
  value: {
    minWidth: theme.spacing(5),
  }
}))

const History = ({ history, game, tableTitle, tableRows = [] }) => {
  const translate = useTranslate()
  const sport = useSport()
  const classes = useHistoryStyles()

  if (!tableRows.length) return null;

  const date = moment.tz(game.startTime, game.timezone);

  const day = date.format('YYYY-MM-DD');
  const lastDay = `${moment(date).subtract(1, 'day').format('YYYY-MM-DD')}`

  const week = [
    moment(date).startOf('week').add(1, 'day').format('YYYY-MM-DD'),
    moment(date).endOf('week').add(1, 'day').format('YYYY-MM-DD'),
  ].join(' - ')
  const lastWeek = [
    moment(date).subtract(1, 'week').startOf('week').add(1, 'day').format('YYYY-MM-DD'),
    moment(date).subtract(1, 'week').endOf('week').add(1, 'day').format('YYYY-MM-DD'),
  ].join(' - ')

  const month = [
    moment(date).startOf('month').format('YYYY-MM-DD'),
    moment(date).endOf('month').format('YYYY-MM-DD')
  ].join(' - ')
  const lastMonth = [
    moment(date).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    moment(date).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
  ].join(' - ')

  return <div className={classes.root}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{translate(tableTitle, { _: tableTitle })}</TableCell>
          <TableCell align="right" className={classes.value}>
            <DateRangeTitle title={translate('ra.date.day', 1)} current={day} last={lastDay} />
          </TableCell>
          <TableCell align="right" className={classes.value}>
            <DateRangeTitle title={translate('ra.date.week', 1)} current={week} last={lastWeek} />
          </TableCell>
          <TableCell align="right" className={classes.value}>
            <DateRangeTitle title={translate('ra.date.month', 1)} current={month} last={lastMonth} />
          </TableCell>
          <TableCell align="right" className={classes.value}>
            <DateRangeTitle title={translate('resources.qualifications.fields.seasonId')} current={getSeasonDisplayName(game.seasonId, sport)} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows.map(({ id, title }) => <HistoryRow history={history} id={id} title={title} />)}
      </TableBody>
    </Table>
  </div>
}

const AssignmentHistory = ({ game, official }) => {
  const isEnabled = useFlag()
  const translate = useTranslate();
  const [ assignments, setAssignments ] = useState([]);
  const [ history, setHistory ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);

  useEffect(() => {
    setError(false)
    setLoading(true)

    apiClient(`/participants/${official.participantId}/assignHistory`, {
      params: {
        seasonId: game.seasonId,
      },
    })
      .then(res => res.data)
      .then(assignments => {
        setError(false)
        setAssignments(assignments)

        const date = moment.tz(game.startTime, game.timezone);
        setHistory({
          'Referee': countAssignments(date, assignments.filter(assignment => assignment.position === 'Referee'), game.timezone),
          'Linesperson': countAssignments(date, assignments.filter(assignment => assignment.position === 'Linesperson'), game.timezone),
          ...(isEnabled(FF_ADDITIONAL_LINESPERSONS) ? { 'LinespersonTwo': countAssignments(date, assignments.filter(assignment => assignment.position === 'LinespersonTwo'), game.timezone) } : {}),
          ...(isEnabled(FF_ADDITIONAL_LINESPERSONS) ? { 'LinespersonThree': countAssignments(date, assignments.filter(assignment => assignment.position === 'LinespersonThree'), game.timezone) } : {}),
          ...(isEnabled(FF_OFFICIALS) ? { 'Official': countAssignments(date, assignments.filter(assignment => assignment.position === 'Official'), game.timezone) } : {}),
          ...(isEnabled(FF_SCOREKEEPERS) ? { 'Scorekeeper': countAssignments(date, assignments.filter(assignment => assignment.position === 'Scorekeeper'), game.timezone) } : {}),
          ...(isEnabled(FF_TIMEKEEPERS) ? { 'Timekeeper': countAssignments(date, assignments.filter(assignment => assignment.position === 'Timekeeper'), game.timezone) } : {}),
          'Supervisor': countAssignments(date, assignments.filter(assignment => assignment.position === 'Supervisor'), game.timezone),
          'total': countAssignments(date, assignments, game.timezone),
          [game.homeTeamId]: countAssignments(date, assignments.filter(assignment => [assignment.game.homeTeamId, assignment.game.awayTeamId].includes(game.homeTeamId)), game.timezone),
          [game.awayTeamId]: countAssignments(date, assignments.filter(assignment => [assignment.game.homeTeamId, assignment.game.awayTeamId].includes(game.awayTeamId)), game.timezone)
        })
      })
      .catch(e => setError(true))
      .finally(() => setLoading(false))
  }, [ game.startTime, game.seasonId, game.timezone, game.homeTeamId, game.awayTeamId, official.participantId, isEnabled ])

  if (loading) {
    return <LoadingAlert>{translate('resources.games.alerts.loading.assignment_history')}</LoadingAlert>
  }

  if (error) {
    return <Alert severity="error">{translate('resources.games.alerts.assignment.official_history_error')}</Alert>
  }

  if (!loading && !assignments.length) {
    return <Alert severity="info">{translate('resources.games.alerts.assignment.no_assignment')}</Alert>
  }

  return <>
    <History
      game={game}
      history={history}
      tableTitle="resources.officials.labels.position"
      tableRows={[
        { id: 'Referee', title: 'resources.games.values.assignment.position.Referee' },
        { id: 'Linesperson', title: 'resources.games.values.assignment.position.Linesperson' },
        { id: 'LinespersonTwo', title: 'resources.games.values.assignment.position.LinespersonTwo' },
        { id: 'LinespersonThree', title: 'resources.games.values.assignment.position.LinespersonThree' },
        { id: 'Official', title: 'resources.games.values.assignment.position.Official' },
        { id: 'Scorekeeper', title: 'resources.games.values.assignment.position.Scorekeeper' },
        { id: 'Timekeeper', title: 'resources.games.values.assignment.position.Timekeeper' },
        { id: 'Supervisor', title: 'resources.games.values.assignment.position.Supervisor' },
        { id: 'total', title: 'ra.message.total' },

        ...(game.homeTeamId || game.awayTeamId ? [{ title: 'resources.officials.labels.team' }] : []),
        ...(game.homeTeamId ? [{ id: game.homeTeamId, title: <TeamField source="homeTeamId" variant="inherit" /> }] : []),
        ...(game.awayTeamId ? [{ id: game.awayTeamId, title: <TeamField source="awayTeamId" variant="inherit" /> }] : [])
      ]}
    />
    <br />
    <Table>
      <TableBody>
        {assignments.map((assignment, index) => {
          let showDate = false;
          const prevAssignment = assignments[index - 1];
          if (!prevAssignment) {
            showDate = true;
          } else {
            const prevGame = prevAssignment.game;
            const thisGame = assignment.game;

            if (prevGame && thisGame) {
              const thisDate = moment.tz(thisGame.startTime, thisGame.timezone).format('YYYY-MM-DD')
              const prevDate = moment.tz(prevGame.startTime, prevGame.timezone).format('YYYY-MM-DD')
              showDate = thisDate !== prevDate;
            }
          }
          return <RecordContextProvider value={assignment} key={assignment.gameId}>
            <Assignment key={assignment.gameId} game={game} assignment={assignment} showDate={showDate} />
          </RecordContextProvider>
        })}
      </TableBody>
    </Table>
  </>
}

export default () => {
  const { game } = useAssignmentsContext();
  const { official } = useAssignmentContext();

  if (!game || !official) return null;
  return <AssignmentHistory game={game} official={official} />
}

