import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { GET_LIST, useQueryWithStore, useTranslate } from 'react-admin';

import { STEPS } from './Generator';

const hasOrderGap = (scheduleTeams = []) => !sortBy(scheduleTeams, 'order').every((scheduleTeam, index) => scheduleTeam.order === index + 1)

const GeneratorContext = React.createContext(null);

export const useGeneratorContext = () => useContext(GeneratorContext) || {};

export const GeneratorContextProvider = ({ schedule = {}, activeStep, setShowMatrix, children }) => {
  const translate = useTranslate();
  const [ groupId, setGroupId ] = useState(null)
  const [ alert, setAlert ] = useState(null)

  // loading schedule teams
  const { data: scheduleTeamsUnfiltered = [], loading: scheduleTeamsLoading, loaded: scheduleTeamsLoaded } = useQueryWithStore({
    type: GET_LIST,
    resource: 'scheduleteams',
    payload: {
      filter: { scheduleId: schedule.id },
      pagination: { page: 1, perPage: 999 },
    }
  }, { enabled: !!schedule.id, action: 'CUSTOM_QUERY' })

  // loading latest published draft game
  const { data: drafts, loading: draftsLoading, loaded: draftsLoaded } = useQueryWithStore({
    type: GET_LIST,
    resource: 'draftGames',
    payload: {
      filter: { scheduleId: schedule.id },
      sort: { field: 'round', order: 'DESC' },
      pagination: { page: 1, perPage: 1 },
    }
  }, { enabled: !!schedule.id, action: 'CUSTOM_QUERY' })

  const loading = scheduleTeamsLoading || draftsLoading
  const loaded = scheduleTeamsLoaded || draftsLoaded

  const scheduleTeams = useMemo(() => {
    return sortBy(scheduleTeamsUnfiltered.filter(scheduleTeam => scheduleTeam.groupId === groupId), 'order')
  }, [scheduleTeamsUnfiltered, groupId])

  useEffect(() => {
    if (!scheduleTeamsLoaded) return
    if (!scheduleTeams.length) {
      return setAlert({ step: STEPS.MATCHUPS, severity: 'error', message: translate(`resources.draftGames.messages.no_teams${groupId ? '_group' : ''}_alert`) })
    } else if (scheduleTeams.length === 1) {
      return setAlert({ step: STEPS.MATCHUPS, severity: 'error', message: translate(`resources.draftGames.messages.one_team${groupId ? '_group' : ''}_alert`) })
    } else if (hasOrderGap(scheduleTeams)) {
      return setAlert({ step: STEPS.MATCHUPS, severity: 'warning', message: translate(`resources.draftGames.messages.gaps${groupId ? '_group' : ''}_alert`) })
    }
    return setAlert(null)
  }, [scheduleTeamsLoaded, scheduleTeams, groupId, setAlert, translate])

  const value = useMemo(() => {
    const [ lastPublishedDraft ] = drafts || []
    const lastPublishedDraftRound = lastPublishedDraft?.round

    return {
      schedule,
      groupId,
      activeStep,
      lastPublishedDraftRound,
      scheduleTeams,
      loading,
      loaded,
      alert,
      setAlert,
      setGroupId,
      setShowMatrix
    }
  }, [ setGroupId, alert, setAlert, setShowMatrix, scheduleTeams, drafts, loading, loaded, schedule, activeStep, groupId ]);

  return <GeneratorContext.Provider value={value}>
    {children}
  </GeneratorContext.Provider>
}
