import React from 'react';
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { ArenaOfficesGrid } from '../../arenaoffices/ArenaOfficesGrid';
import { AddArenaOfficeButton } from '../../arenaoffices/ArenaOfficeModal';

const useStyles = makeStyles({
  headerRow: {
    display: 'none',
  }
})

export default props => {
  const surface = useRecordContext(props);
  const canAddOffices = isAuthorized(surface, 'arenaoffices', 'create') && !surface.parentId;
  const classes = useStyles()

  return <ListCard
    title="resources.offices.labels.card.title"
    reference="arenaoffices"
    target="arenaId"
    sort={{
      field: ['isPrimary', 'office.name'],
      order: ['DESC', 'ASC'],
    }}
    filter={{ effectiveSurfaces: true }}
    addButton={canAddOffices && <AddArenaOfficeButton initialValues={{ arenaId: surface?.id }} target="arenaId" />}
    collapsable
  >
    <ArenaOfficesGrid showArena={false} classes={classes} />
  </ListCard>
}
