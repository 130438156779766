/*
Soccer: Home vs Away
Baseball: Away @ Home
Hockey: Away @ Home
*/
export const isHomeFirst = sport => {
  switch (sport) {
    case 'Soccer': return true
    case 'Baseball':
    case 'Hockey': return false
    default: return true
  }
};
