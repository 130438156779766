import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslate } from "react-admin";
import { createPortal } from 'react-dom';
import { Button } from "reactstrap";
import { FormattedNumber } from "react-intl";
import { CloseOutlined, Description, GetApp, Image, OpenInNew, PictureAsPdf, ZoomIn, ZoomOut } from "@material-ui/icons";
import { UAParser } from "ua-parser-js";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import styles from "./FileViewer.module.scss";
import PDFFile from "./PDFFile";
import FileViewerPicker from "./FileViewerPicker";
import PortalLifeCycle from "./PortalLifeCycle";

// /////////////////////////////////////////////////////////////////////
// This is an imported component from the SID project
// Some Page specific changes had to be done :
// openInNewTabURL & URLToFetchForDownload props -> required for the schedule brackets
// downloadFile & getFileForDownload functions -> different logic to fetch the download file instead of downloading the loaded one
// { cache: 'no-cache' } in useEffect -> causes bug with image fetches getting CORS errors
// SVG class in scss module -> adds a white background to the SVG so the brackets are readable
// added ", a" to the Btns class -> handles the openInNewTab button that acts like an anchor tag
// /////////////////////////////////////////////////////////////////////

/**
 * @param {object} props
 * @param {string} props.type
 * @param {string|(() => Promise.<any>)|File} props.fullPath
 * @param {string} props.fileName
 * @param {string} props.creationDate
 * @param {boolean} props.isOpen
 * @param {Function} props.close
 * @param {Record.<string, string>} [props.createdBy]
 * @param {Record.<string, string>} [props.defaultImage]
 * @returns
 */
const FileViewer = ({ type, fullPath, fileName, createdBy, creationDate, isOpen, close, openInNewTabURL, URLToFetchForDownload }) => {
  const translate = useTranslate();
  const [ image, setImage ] = useState('');
  const [ fetchedType, setFetchedType ] = useState(type);
  const [ pageCount, setPageNumber ] = useState(1);
  const [ shownControls, setShownControls ] = useState(true);
  const containerRef = useRef(null);
  const mouseCooldown = useRef(null);
  const [ previewInfo, setPreviewInfo ] = useState({ isVisible: true, naturalWidth: 0, naturalHeight: 0 });
  const canDownload = !(fullPath instanceof File) && image;
  const isPhone = useRef(new UAParser().getDevice().type === 'mobile');
  const isLoading = useRef(false);
  const [ isPdf, setIsPdf ] = useState(type === 'application/pdf' || type === 'pdf');
  const isTextFile = Array.isArray(image);
  const isVideoFile = image?.type === 'video';
  const isAudioFile = image?.type === 'audio';

  useEffect(() => {
    setIsPdf(type === 'application/pdf' || type === 'pdf');
  }, [ type ]);

  function hideControls() {
    setShownControls(false);
    clearTimeout(mouseCooldown.current);
  }

  function showControls(cooldown = 2000) {
    setShownControls(true);
    clearTimeout(mouseCooldown.current);
    mouseCooldown.current = setTimeout(hideControls, cooldown);
  }

  function getInitialScale() {
    return isPhone.current ? 0.6 : 1;
  }

  /**
     * @param {number} initialScale
     * @returns {number[]}
     */
  function buildScalePoints(initialScale = 1) {
    const jumpStep = 1.5;// 50%
    const points = [ initialScale ];
    let lowerPoint = initialScale, higherPoint = initialScale;
    for (let index = 0; index < 4; index++) { // 4 points lower scale and 4 points higher scale
      points.push([ lowerPoint /= jumpStep, higherPoint *= jumpStep ]);
    }
    return points.sort();
  }

  const scalePoints = useRef(buildScalePoints(getInitialScale()));

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(numPages);
    setPreviewInfo({ isVisible: true });
  }

  function getFileForDownload() {
    // very stiff logic that only works for downloading PDF files
    // short on time
    // look at how we handle different file types in the useEffect
    if (URLToFetchForDownload) {
      return fetch(URLToFetchForDownload)
        .then((r) => r.blob().then(URL.createObjectURL));
    }
    return Promise.resolve(image);
  }

  function downloadFile() {
    // this function is different than the SID one
    // because we needed a logic to download a different file
    // from the one loaded
    // we load an SVG, user needs to download a PDF
    getFileForDownload()
      .then((file) => {
        const a = document.createElement('a');
        a.style = 'display: none;';
        a.download = fileName;
        a.href = Array.isArray(file) ? `data:text/plain;charset=utf-8,${encodeURIComponent(file.join(''))}` : file;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  }

  function onPreviewFail() {
    setPreviewInfo({ isVisible: false });
  }

  // string when image and pdf
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const canZoomInOut = useCallback(() => image && previewInfo.isVisible && typeof image === 'string', [ image, previewInfo.isVisible, typeof image ]);

  function hasScroll() {
    if (!containerRef.current) return false;
    return containerRef.current.clientHeight < containerRef.current.scrollHeight || containerRef.current.clientWidth < containerRef.current.scrollWidth;
  }

  function getVideoImageValue(content) {
    return {
      type: 'video',
      src: URL.createObjectURL(content),
    };
  }
  function getAudioImageValue(content) {
    return {
      type: 'audio',
      src: URL.createObjectURL(content),
    };
  }

  useEffect(() => {
    if (isOpen) {
      if (isLoading.current) return;

      showControls(2000);

      if (image) {
        // Do nothing -> Image has already been loaded
      } else if (typeof fullPath === "string") {
        isLoading.current = true;
        fetch(fullPath, { cache: 'no-cache' }) // cache causes images to get a CORS error for some reason
          .then((r) => {
            if (/text\/*/.test(type)) {
              return r.text().then((text) => text.split('\n'));
            } else if (/video\/*/.test(type)) {
              return r.blob().then(getVideoImageValue);
            } else if (/audio\/*/.test(type)) {
              return r.blob().then(getAudioImageValue);
            }
            return r.blob().then(URL.createObjectURL);
          })
          .then(setImage)
          .catch(() => {
            // Needed to show the "No preview" state of the FileViewer
            // Will trigger the loading of the ressource to fail thus, showing no preview available
            setImage('something_that_will_fail_the_download');
          })
          .finally(() => {
            isLoading.current = false;
          });
      } else if (typeof fullPath === 'function') {
        isLoading.current = true;
        fullPath()
          .then((data) => {
            setFetchedType(data.original_file_mime_type);
            if (data.preview_full_path) setIsPdf(true);
            return fetch(data.preview_full_path || data.full_path)
              .then((r) => {
                if (/text\/*/.test(data.original_file_mime_type)) {
                  return r.text().then((text) => text.split('\n'));
                } else if (/video\/*/.test(data.original_file_mime_type)) {
                  return r.blob().then(getVideoImageValue);
                } else if (/audio\/*/.test(data.original_file_mime_type)) {
                  return r.blob().then(getAudioImageValue);
                }
                return r.blob().then(URL.createObjectURL);
              });
          })
          .then(setImage)
        // .then(() => setImage('something_that_will_fail_the_download'))
          .catch(() => {
            // Needed to show the "No preview" state of the FileViewer
            // Will trigger the loading of the ressource to fail thus, showing no preview available
            setImage('something_that_will_fail_the_download');
          })
          .finally(() => {
            isLoading.current = false;
          });
      } else if (/text\/*/.test(fullPath.type)) { // Is text file
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setImage(reader.result.split('\n'));
        });
        reader.addEventListener('error', onPreviewFail);
        reader.readAsText(fullPath);
      } else if (/video\/*/.test(fullPath.type)) {
        setImage(getVideoImageValue(fullPath));
      } else if (/audio\/*/.test(fullPath.type)) {
        setImage(getAudioImageValue(fullPath));
      } else {
        setImage(URL.createObjectURL(fullPath));
      }
    } else {
      setImage('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isOpen ]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        close();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isOpen && createPortal(
    <TransformWrapper
      disabled={!image || !previewInfo.isVisible || isTextFile || isVideoFile || isAudioFile}
      minScale={scalePoints.current[0][0]}
      maxScale={scalePoints.current[0][1]}
      initialScale={1}
      limitToBounds
    >
      {(reactPinchPanZoom) => (
        <aside
          ref={containerRef}
          className={styles.Container}
          onMouseMove={() => {
            showControls();
          }}
          role="alertdialog"
          aria-describedby="FileViewerHeaderInfo"
          tabIndex={0}// Makes the onKeyDown listener works
          onKeyDown={(e) => {
            if (e.ctrlKey) {
              // CTRL + e.code
              switch (e.code) {
                case 'Equal':
                case 'NumpadEqual':
                case 'NumpadAdd':
                  if (canZoomInOut()) reactPinchPanZoom.zoomIn();
                  e.preventDefault();
                  break;
                case 'Minus':
                case 'NumpadSubtract':
                  if (canZoomInOut()) reactPinchPanZoom.zoomOut();
                  e.preventDefault();
                  break;
                case 'Semicolon':
                case 'IntlRo':
                case 'IntlYen':
                  e.preventDefault();
                  break;
                default:
                  break;
              }
            }
          }}
        >
          <PortalLifeCycle
            container={containerRef}
            resetScalePoints={() => {
              scalePoints.current = buildScalePoints(1);
            }}
          />
          <header className={`${styles.Bar} ${styles.BarTop} ${shownControls ? styles.IsVisible : ''}`}>
            <div className={`${styles.Bootstrap_MR2} ${styles.Bootstrap_LineHeight1} ${styles.Bootstrap_TextMuted}`}>
              {getIcon(fetchedType)}
            </div>
            <div className={styles.BarTopFileInfo} id="FileViewerHeaderInfo">
              <div role="heading" className={styles.BarTopFileName}>
                {fileName}
              </div>
              {createdBy &&
                <div role="note" className="small">{createdBy.name} {createdBy.family_name}</div>
              }
              <div role="note" className="small">
                {creationDate}
              </div>
            </div>
            <div className={styles.BarTopBtns}>
              {openInNewTabURL &&
                <Button
                  className={`${styles.Button} ${styles.ButtonSecondary} ${styles.Bootstrap_MR2}`}
                  type="button"
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                  }}// Prevents zoom on double click
                  title={translate('resources.schedules.labels.brackets_viewer.open_new_window')}
                  onBlur={() => {
                    containerRef.current.focus();
                  }}
                  tag="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={openInNewTabURL}
                >
                  <OpenInNew />
                </Button>
              }
              {canDownload &&
                <Button
                  className={`${styles.Button} ${styles.ButtonSecondary} ${styles.Bootstrap_MR2}`}
                  type="button"
                  onClick={downloadFile}
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                  }}// Prevents zoom on double click
                  title={translate('resources.schedules.labels.brackets_viewer.download')}
                  onBlur={() => {
                    containerRef.current.focus();
                  }}
                >
                  <GetApp />
                </Button>
              }
              <Button
                className={`${styles.Button} ${styles.ButtonDark}`}
                type="button"
                onClick={(e) => {
                  e.stopPropagation(); close();
                }}
                onDoubleClick={(e) => {
                  e.stopPropagation();
                }}// Prevents zoom on double click
                title={translate('resources.schedules.labels.brackets_viewer.close')}
                onBlur={() => {
                  containerRef.current.focus();
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          </header>
          <TransformComponent
            wrapperClass={`${canZoomInOut() ? styles.PreviewContainerZoom : styles.PreviewContainer} ${isTextFile ? styles.WithText : ''}
            ${!image || !previewInfo.isVisible ? styles.NoPreview : ''}`}
          >
            {isPdf ?
              image ?
                <PDFFile
                  image={image}
                  fileLoading={<FileLoading type={fetchedType} fileName={fileName} />}
                  errorView={<PreviewFailed canDownload={canDownload} downloadFile={downloadFile} type="pdf" />}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  onPreviewFail={onPreviewFail}
                  pageCount={pageCount}
                  containerRef={containerRef}
                  reactPinchPanZoom={reactPinchPanZoom}
                  resetScalePoints={(initialScale) => {
                    scalePoints.current = buildScalePoints(initialScale);
                  }}
                />
                :
                <FileLoading type={fetchedType} fileName={fileName} />
              :
              !image ?
                <FileLoading type={fetchedType} fileName={fileName} />
                : previewInfo.isVisible ?
                  <FileViewerPicker
                    fileData={image}
                    fileName={fileName}
                    onPreviewFail={onPreviewFail}
                    setPreviewInfo={setPreviewInfo}
                    onLoad={(scaleValue) => {
                      scalePoints.current = buildScalePoints(scaleValue);
                      reactPinchPanZoom.centerView(scaleValue, 0);
                    }}
                    containerRef={containerRef}
                    canDownload={canDownload}
                    downloadFile={downloadFile}
                    fileType={fetchedType}
                    hasScroll={hasScroll}
                    isMobile={isPhone.current}
                  />
                  :
                  <PreviewFailed type={fetchedType || type} canDownload={canDownload} downloadFile={downloadFile} />
            }
          </TransformComponent>
          {canZoomInOut() &&
            <footer className={`${styles.Bar} ${styles.BarBottom} ${shownControls ? styles.IsVisible : ''}`}>
              <Button
                className={`${styles.Button} ${styles.ButtonDark} ${styles.Bootstrap_MR1}`}
                type="button"
                onClick={() => {
                  reactPinchPanZoom.zoomOut();
                }}
                title={translate('resources.schedules.labels.brackets_viewer.zoom_out')}
                aria-controls="FileViewerZoomLevel"
                onDoubleClick={(e) => {
                  e.stopPropagation();
                }}// Prevents zoom on double click
                onBlur={() => {
                  containerRef.current.focus();
                }}
              >
                <ZoomOut />
              </Button>
              <Button
                className={`${styles.Button} ${styles.ButtonDark} ${styles.Bootstrap_ML1}`}
                type="button"
                onClick={() => {
                  reactPinchPanZoom.zoomIn();
                }}
                title={translate('resources.schedules.labels.brackets_viewer.zoom_in')}
                aria-controls="FileViewerZoomLevel"
                onDoubleClick={(e) => {
                  e.stopPropagation();
                }}// Prevents zoom on double click
                onBlur={() => {
                  containerRef.current.focus();
                }}
              >
                <ZoomIn />
              </Button>
              <div className={`${styles.ZoomLevel}`} id="FileViewerZoomLevel"><FormattedNumber value={reactPinchPanZoom.state.scale} style="percent" /></div>
            </footer>
          }
        </aside>
      )}
    </TransformWrapper>
    , document.body
  );
};

const FileLoading = ({ type, fileName }) => {
  const translate = useTranslate();

  return (
    <>
      {getIcon(type)}
      <i
        className={`${styles.NoPreview} ${styles.Loading}`}
        role="status"
        aria-label={translate('ra.page.loading', { fileName })}
      />
    </>
  );
};

export const PreviewFailed = ({ canDownload, downloadFile, type }) => {
  const translate = useTranslate();

  return (
    <div role="status">
      <div className={styles.Icon}>
        {getIcon(type)}
      </div>
      <div className="font-bold h1 text-body"></div>
      {translate('resources.schedules.labels.brackets_viewer.no_preview.text')}
      {canDownload &&
        <div>
          <div className={`${styles.Bootstrap_MB3}`}></div>
          <Button className={`${styles.Button} ${styles.ButtonSecondary} ${styles.Bootstrap_MR1}`} type="button" onClick={downloadFile}>
            <GetApp className={`${styles.NoPreviewIcon}`} />
            <span className={`${styles.NoPreviewText}`}> {translate('resources.schedules.labels.brackets_viewer.download')} </span>
          </Button>
        </div>
      }
    </div>
  );
};

const getIcon = (type) => {
  switch (type) {
    case 'application/pdf':
    case 'pdf':
      return <PictureAsPdf style={{ fontSize: "50px" }} />;
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <Image style={{ fontSize: "50px" }} />
    default:
      return <Description style={{ fontSize: "50px" }} />;
  }
};

export default FileViewer;
