import { cloneDeep } from '@hisports/common/src/lodash.js';

import {
  SETTINGS_UPDATED,
  GAME_STARTED,
  GAME_ENDED,
  NOTES_UPDATED,
  SCORESHEET_APPROVED,
  SCORESHEET_DISAPPROVED,
  GAME_FORFEITED,
  SCORESHEET_CERTIFIED,
  SCORESHEET_DECERTIFIED,
  SCORESHEET_RESET,
} from '../actions/index.js'
import { DEFAULT_SETTINGS, SCHEDULED, STARTED, ENDED, APPROVED, CERTIFIED } from '../constants.js';

export const startTime = (state = null, action) => {
  if (action.type === SCORESHEET_RESET) return null;
  if (action.type !== GAME_STARTED) return state;
  return action.payload.timestamp;
}

export const endTime = (state = null, action) => {
  if (action.type === SCORESHEET_RESET) return null;
  if (action.type !== GAME_ENDED) return state;
  return action.payload.timestamp;
}

export const status = (state = SCHEDULED, action) => {
  switch (action.type) {
    case GAME_STARTED:
    case SCORESHEET_DISAPPROVED:
      return STARTED;

    case GAME_ENDED:
      return ENDED;

    case GAME_FORFEITED:
    case SCORESHEET_APPROVED:
    case SCORESHEET_DECERTIFIED:
      return APPROVED;

    case SCORESHEET_CERTIFIED:
      return CERTIFIED;

    case SCORESHEET_RESET:
      return SCHEDULED;

    default:
      return state
  }
}

export const forfeit = (state = null, action) => {
  switch (action.type) {
    case GAME_FORFEITED:
      return action.payload.event.teamId;

    case SCORESHEET_RESET:
    case SCORESHEET_DISAPPROVED:
      return null;

    default:
      return state;
  }
}

export const notes = (state = '', action) => {
  if (action.type === SCORESHEET_RESET) return '';

  if (action.type === NOTES_UPDATED && typeof action.payload.event.notes === 'string') {
    return action.payload.event.notes;
  }

  if (action.type === GAME_FORFEITED) {
    const { notes } = action.payload.event;
    // if no note specified, noop
    if (!notes || !notes.length) return state;

    // if no existing state, use forfeit note
    if (!state || !state.length) return notes;

    // otherwise append to existing state
    return `${state}\n\n${notes}`;
  }

  return state;
}

export const adminNotes = (state = '', action) => {
  if (action.type === SCORESHEET_RESET) return '';

  if (action.type === NOTES_UPDATED && typeof action.payload.event.adminNotes === 'string') {
    return action.payload.event.adminNotes;
  }

  if (action.type === GAME_FORFEITED) {
    const { adminNotes } = action.payload.event;
    // if no note specified, noop
    if (!adminNotes || !adminNotes.length) return state;

    // if no existing state, use forfeit admin note
    if (!state || !state.length) return adminNotes;

    // otherwise append to existing state
    return `${state}\n\n${adminNotes}`;
  }

  return state;
}

export const unreviewed = (state = null, action) => {
  switch (action.type) {
    case SCORESHEET_APPROVED:
      return action.payload.event.unreviewed || null;
    case SCORESHEET_DISAPPROVED:
      return null;
    case SCORESHEET_RESET:
      return null;
    default:
      return state;
  }
}

export const settings = (state = cloneDeep(DEFAULT_SETTINGS), action) => {
  switch (action.type) {
    case GAME_STARTED:
      if (!action.payload.event.periods) return state;
      return {
        ...state,
        periods: action.payload.event.periods
      }
    case SETTINGS_UPDATED:
      return {
        ...state,
        ...action.payload.event
      };
    case SCORESHEET_RESET:
      return cloneDeep(DEFAULT_SETTINGS);
    default:
      return state;
  }
}
