import { apiClient, deviceId } from "../../../http";

const setEventMeta = action => {
  return deviceId.then(deviceId => {
    const meta = action.payload.meta || {}
    action.payload.meta = {
      ...meta,
      deviceId,
    }
    return action;
  })
}

export default store => next => action => {
  if (action.meta?.offline?.effect?.type !== 'event') return next(action);
  const { gameId, eventType } = action.payload;

  if (!gameId) return next(action);
  return setEventMeta(action)
    .then(action => apiClient(`/games/${gameId}/events`, {
      method: 'POST',
      data: action.payload,
    }))
    .then(res => res.data)
    .then(event => {
      if (!event || !event.id || event.eventType !== eventType || event.gameId != gameId) { // eslint-disable-line eqeqeq
        console.log(action.payload) // eslint-disable-line no-console
        const error = new Error('Invalid event response')
        error.code = 'EINVALIDRES'
        error.status = 500;
        return Promise.reject(error)
      }
      const { meta, ...payload } = event;
      action.payload = payload;
      return next(action)
    })
}
