import React from 'react';

import { SeasonField } from '../seasons/SeasonField';
import { TargetField } from '../targets/TargetField';

import { OfficialConflictDatagrid } from './OfficialConflictModal';

export const OfficialConflictGrid = props =>
  <OfficialConflictDatagrid size="medium" {...props}>
    <SeasonField source="seasonId" />
    <TargetField source="targetId" />
  </OfficialConflictDatagrid>
