import React, { useEffect, useState } from 'react';
import { NumberInput, useResourceContext, useTranslate } from 'react-admin';
import moment from 'moment-timezone';
import { Accordion, AccordionDetails, AccordionSummary, Grid, InputAdornment, Typography, styled } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, DateRange as Schedule } from '@material-ui/icons'
import { useFormState } from 'react-final-form';

const ScheduleIcon = styled(Schedule)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: theme.spacing(5),
}))

const ListItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(.5, 0),
}))

const DurationInput = props => {
  const translate = useTranslate();
  return <NumberInput {...props} InputProps={{
    endAdornment: <InputAdornment position="end">
      {translate('ra.input.adornments.minutes')}
    </InputAdornment>
  }}
  />
}

const SlotSplitSchedule = () => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const { values } = useFormState();
  const { startTime, endTime, slotDuration, breakDuration, timezone } = values;
  const [ splits, setSplits ] = useState([])

  const date = moment.tz(startTime, timezone).format('MMM Do');
  const firstSlotTime = moment.tz(startTime, timezone).format('HH:mm A');
  const lastSlotTime = moment.tz(endTime, timezone).format('HH:mm A');

  useEffect(() => {
    const timeslotMinutes = moment(endTime).diff(startTime, 'minutes');
    const splits = [];

    if (timeslotMinutes % (slotDuration + breakDuration) === 0) {
      const numOfSplits = timeslotMinutes / (slotDuration + breakDuration);
      const currentStartTime = moment.tz(startTime, timezone);

      for (let i = 0; i < numOfSplits; i++) {
        const duration = slotDuration + breakDuration;
        const splitStart = moment(currentStartTime)
        const splitEnd = moment(currentStartTime).add(slotDuration, 'minutes')

        splits.push({ startTime: splitStart, endTime: splitEnd });
        currentStartTime.add(duration, 'minutes');
      }
    }

    setSplits(splits)
  }, [startTime, endTime, timezone, slotDuration, breakDuration])

  if (!splits?.length) return null;

  const total = splits.length;
  const first = splits.slice(0, 5);
  const last = splits[total - 1];

  return <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <ScheduleIcon />
      <Typography color="textSecondary">{translate(`resources.${resource}.messages.slot_splits`, { smart_count: total, startTime: firstSlotTime, endTime: lastSlotTime, date })}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <List>
        {first.map(({ startTime, endTime }) => <ListItem>{`${startTime.format('HH:mm A')} - ${endTime.format('HH:mm A')}`}</ListItem>)}
        {total > 6 && <ListItem>{translate(`resources.${resource}.messages.additional_instances`, { smart_count: total - 6 })}</ListItem>}
        {total > 5 && <ListItem>{`${last.startTime.format('HH:mm A')} - ${last.endTime.format('HH:mm A')}`}</ListItem>}
      </List>
    </AccordionDetails>
  </Accordion>
}

export const SlotSplitField = (inputProps) => {
  const translate = useTranslate();
  const { values } = useFormState();
  const { startTime, endTime, slotDuration, breakDuration } = values;
  const hasSplits = startTime && endTime && slotDuration && breakDuration !== null && breakDuration >= 0;

  return <>
    <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.arenaslots.labels.slot_splits')}</Typography>
    </Grid>
    <Grid item xs={6}>
      <DurationInput source="slotDuration" label="resources.arenaslots.labels.slot_duration" helperText={!breakDuration ? 'ra.message.optional' : ''} {...inputProps} />
    </Grid>
    <Grid item xs={6}>
      <DurationInput source="breakDuration" label="resources.arenaslots.labels.break_duration" helperText={!slotDuration ? 'ra.message.optional' : ''} {...inputProps} />
    </Grid>
    {hasSplits && <Grid item xs={12}>
      <SlotSplitSchedule />
    </Grid> }
  </>
}
