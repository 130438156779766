import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core';
import { EmailOutlined } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { AnnouncementForm } from './AnnouncementForm';

const AddAnnouncementDialog = props =>
  <CreateResourceDialog maxWidth="md" label="resources.announcements.labels.add" resource="announcements" basePath="/announcements" {...props}>
    <AnnouncementForm />
  </CreateResourceDialog>


const EditAnnouncementDialog = props =>
  <EditResourceDialog label="resources.announcements.labels.edit" resource="announcements" basePath="/announcements" {...props}>
    <AnnouncementForm />
  </EditResourceDialog>

export const AddAnnouncementButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Tooltip title={translate('resources.announcements.labels.add')}>
      <Button color="primary" size={size} variant={variant} startIcon={<EmailOutlined />} onClick={() => setOpen(true)}>
        {translate('ra.action.new')}
      </Button>
    </Tooltip>
    <AddAnnouncementDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const AnnouncementDatagrid = props => <DialogDatagrid dialog={EditAnnouncementDialog} {...props} />
