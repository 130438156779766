import React, { forwardRef, useEffect, useState } from 'react';
import { useNotify, useRecordContext, useTranslate, RecordContextProvider } from 'react-admin';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, TextField, makeStyles, Tooltip, Paper, ListSubheader } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { GroupAddOutlined } from '@material-ui/icons'
import classnames from 'classnames';

import { isDelegated } from '@hisports/assigning';
import { OFFICIAL_STATUS_CONFIRMED, OFFICIAL_STATUS_ASSIGNED, OFFICIAL_STATUS_DECLINED, OFFICIAL_STATUS_NO_SHOW, OFFICIAL_STATUS_UNASSIGNED } from '@hisports/scoresheet/src/constants';

import { apiClient, useParticipant, usePermissions } from '../../http';
import { useRichTranslate } from '../../common/useRichTranslate';

import { AssignmentStatusIcon } from '../games/cards/OfficialsCard/Assignment/AssignmentStatus';
import { assign } from '../games/cards/OfficialsCard/Assignment/AssignToolbar/AssignButton';
import { QuickAssignmentDialog } from '../gameStatus/AssignmentsDialog';
import { OfficialsContextProvider, useOfficialsContext } from '../games/cards/OfficialsCard/Assignment/OfficialsContext';
import { OfficialListItem } from '../games/cards/OfficialsCard/Assignment/OfficialList/OfficialList';
import { OfficeField } from '../offices/OfficeField';
import { useOfficialList } from './EventViewSettings';

const useStyles = makeStyles(theme => ({
  assignment: {
    marginRight: theme.spacing(3),
    fontSize: '.95em',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      verticalAlign: 'middle',
    }
  },
  position: {
    fontWeight: 'bold',
    marginRight: theme.spacing(.5),
  },
  name: {
    display: 'flex',
    alignItems: 'center',

    // we simulate the mui outlined input on hover
    paddingBlock: 10.5,
    paddingInline: 4, // this is smaller to fit the clear btn
    borderRadius: 4,
    border: '1px solid transparent',
    fontFamily: 'inherit',
    backgroundColor: 'transparent',
  },
  buttonHover: {
    // add the name hover only if the user can assign
    '&:hover': {
      borderColor: 'black',
      backgroundColor: 'white',
      cursor: 'pointer',
    }
  },
  input: {
    marginBlock: 0,
    minWidth: theme.spacing(35),

    '& [class*="MuiFormControl"]': {
      marginBlock: 0,
    }
  },
  option: {
    padding: 0,
  },
  unassigned: {
    fontStyle: 'italic',
    color: theme.palette.grey[500],
  },
  declined: {
    textDecoration: 'line-through',
  },
  status: {
    marginLeft: theme.spacing(.5),
    verticalAlign: 'middle',
  },
  self: {
    backgroundColor: 'yellow',
    borderBottom: '2px solid black',
    padding: 2,
  },
  listSubheader: {
    paddingTop: theme.spacing(2),
    lineHeight: theme.spacing(0.25),
  }
}))

const hasPermission = (permissions, officeId) => {
  if (!officeId) return false;
  const scopes = permissions.filter(p => p.roleType === 'System' || (p.roleType === 'Office' && p.officeIds.includes(officeId))).flatMap(p => p.scopes);
  return scopes.includes('assigning:assign')
}

const removeAssignmentApi = (gameId, participantId, isNoShow = false, officialId) => {
  return apiClient(`/games/${gameId}/removeAssignment`, {
    method: 'POST',
    data: {
      participantId,
      isNoShow,
      officialId,
    },
  })
}

const OfficialsDropdown = forwardRef(function CustomPaper(props, ref) {
  const classes = useStyles();
  const [officialList] = useOfficialList();

  return (
    <Paper ref={ref} {...props}>
      {officialList && <ListSubheader className={classes.listSubheader}>
        {officialList?.name}
      </ListSubheader>}
      {props.children}
    </Paper>
  );
});

const OfficialInput = ({ toggleNameView, onOfficialSelection, assignment, mainAssignOfficeId }) => {
  const { officials, isLoading, error: officialsError } = useOfficialsContext(assignment, mainAssignOfficeId);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const classes = useStyles();

  // when a dropdown item is clicked
  const handleAutocompleteChange = (event, newValue) => {
    setInputValue(newValue);
    onOfficialSelection(newValue)
    toggleNameView();
  }

  // when the user type something in the input
  const handleInputChange = (event) => {
    setError(false);
    setInputValue(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const matchingOfficial = officials.find(official => official.participant.fullName.toLowerCase() === inputValue.toLowerCase());

    if (matchingOfficial) {
      onOfficialSelection(matchingOfficial);
      toggleNameView();
    } else {
      setError(true);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Autocomplete
        open={autocompleteOpen}
        onOpen={() => setAutocompleteOpen(true)}
        onClose={() => setAutocompleteOpen(false)}
        options={officials}
        loading={isLoading && !officialsError}
        getOptionLabel={official => official?.participant?.fullName}
        className={classes.input}
        onChange={handleAutocompleteChange}
        PaperComponent={OfficialsDropdown}
        renderOption={option => <OfficialListItem official={option} assignment={assignment} onSelect={() => {}} />}
        renderInput={(params) => (
          <TextField
            autoFocus
            error={error}
            onFocus={() => setAutocompleteOpen(true)}
            onBlur={toggleNameView}
            margin="dense"
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            {...params} />
        )}
        classes={{ option: classes.option }}
      />
    </form>
  )
}

const ConfirmRemoveDialog = ({ open, setOpen, handleConfirm, assignment }) => {
  const translate = useTranslate();
  const participantId = useParticipant();
  const game = useRecordContext();
  const [markAsNoShow, setMarkAsNoShow] = useState(false);

  const onClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  }

  const showNoShow = game?.isApproved && assignment?.status !== OFFICIAL_STATUS_NO_SHOW;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{translate('ra.action.confirm_removal')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {assignment.participantId !== participantId
            ? translate('resources.games.messages.assignment.removed_from_game', { name: assignment?.participant?.fullName })
            : translate('resources.games.messages.assignment.request_removed')}
        </DialogContentText>
        {showNoShow && <DialogContentText>
          <FormControlLabel
            label={translate('resources.games.messages.assignment.mark_as_no_show', { name: assignment?.participant?.fullName })}
            control={<Checkbox color="primary" checked={markAsNoShow} onChange={() => setMarkAsNoShow(prevState => !prevState)} />}
          />
        </DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>{translate('ra.action.cancel')}</Button>
        <Button onClick={handleConfirm} color="primary">{translate('ra.action.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const ClearAssignmentButton = ({ assignment, canAssign, onClear }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const game = useRecordContext();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleConfirm = () => {
    removeAssignmentApi(game.id, assignment.participantId, false, assignment.id)
      .then(res => {
        const message = canAssign
          ? translate('resources.games.notifications.assignment.remove_official_from_game', { number: game.number })
          : translate('resources.games.notifications.assignment.request_removed_from_game', { number: game.number })
        setConfirmModalOpen(false);
        notify(message, 'success')
        onClear(assignment);
      })
      .catch(err => {
        const message = err?.response?.data?.error?.message
        notify(message || 'ra.page.error_try_again', 'error')
      });
  }

  return <>
    <IconButton aria-label="Clear" size="small" onClick={() => setConfirmModalOpen(true)}>
      <CloseIcon fontSize="small" />
    </IconButton>
    <ConfirmRemoveDialog open={confirmModalOpen} setOpen={setConfirmModalOpen} handleConfirm={handleConfirm} assignment={assignment} />
  </>
}

// quick component used to conditionally render a button vs span when we can vs cannot assign
const OfficialName = ({ canAssign, onClick: handleClick, className, children }) => {
  if (!canAssign) return <span className={className}>{children}</span>;
  return <button className={className} onClick={handleClick}>{children}</button>
}

export const EventAssignment = ({ assignment, mainAssignOfficeId, onOfficialSelection, onClear }) => {
  const translate = useTranslate();
  const richTranslate = useRichTranslate();
  const [showInput, setShowInput] = useState(false);
  const classes = useStyles();
  const participantId = useParticipant();
  const game = useRecordContext();
  const permissions = usePermissions();

  const isAssigned = !!assignment.participant;
  const isDeclined = assignment.status === OFFICIAL_STATUS_DECLINED;
  const isSelf = participantId && assignment.participantId === participantId;

  const delegatedOffice = isDelegated(assignment) && <RecordContextProvider value={{ assignment }}>
    <OfficeField source="assignment.officeId" link={false} variant="inherit" />
  </RecordContextProvider>

  const payOfficeId = assignment?.payOfficeId || mainAssignOfficeId
  const delegatedPayOffice = <RecordContextProvider value={{ officeId: payOfficeId }}>
    <OfficeField source="officeId" link={false} variant="inherit" />
  </RecordContextProvider>

  const canAssign = hasPermission(permissions, assignment.officeId || mainAssignOfficeId) && !game.isCertified

  const position = assignment.position;
  const name = isAssigned ? assignment.participant?.fullName : `(${translate('resources.games.labels.assignment.unassigned')})`;

  const handleOfficialSelection = (official = null) => {
    setShowInput(false);

    if (!!official) {
      onOfficialSelection({
        selectedAssignment: assignment,
        selectedOfficial: official,
      });
    } else {
      onClear(assignment);
    }
  }

  const handleNameClick = () => {
    setShowInput(true);
  }

  let official;
  if (showInput) {
    official = <OfficialInput
      toggleNameView={() => setShowInput(false)}
      onOfficialSelection={handleOfficialSelection}
      assignment={assignment}
      mainAssignOfficeId={mainAssignOfficeId}
    />
  } else {
    official = <OfficialName
      canAssign={canAssign}
      className={classnames(classes.name, {
        [classes.unassigned]: !isAssigned,
        [classes.declined]: isDeclined,
        [classes.self]: isSelf,
      }, classes.buttonHover)}
      onClick={handleNameClick}
    >
      {name}
      <span className={classes.status}>
        <AssignmentStatusIcon assignment={assignment} showUnassigned />
        {isDelegated(assignment) && <Tooltip
          title={<>
            {richTranslate("resources.games.helpers.assignment.delegated_tooltip", { office: delegatedOffice })}
            <br />
            {richTranslate("resources.games.helpers.assignment.pay_delegated_tooltip", { office: delegatedPayOffice })}
          </>}
        >
          <GroupAddOutlined />
        </Tooltip>}
      </span>
    </OfficialName>

    if (isAssigned && canAssign) {
      official = <>
        {official}
        <ClearAssignmentButton assignment={assignment} canAssign={canAssign} onClear={onClear} />
      </>
    }
  }

  return <span className={classes.assignment}>
    <span className={classes.position}>{translate(`resources.games.values.assignment.position_short.${position}`)}:</span>
    {official}
  </span>
};

export const EventAssignmentList = ({ assignments, mainAssignOfficeId, assignSettingStatus }) => {
  const game = useRecordContext();
  const notify = useNotify();
  const translate = useTranslate();
  const [listId] = useOfficialList();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [assignmentData, setAssignmentData] = useState({
    selectedAssignment: null,
    selectedOfficial: {},
    displayedAssignments: assignments, // updated when we post data
  });

  // update displayedAssignments if assignments are refetched
  useEffect(() => {
    setAssignmentData(prevAssignmentData => ({
      ...prevAssignmentData,
      displayedAssignments: assignments
    }))
  }, [assignments, setAssignmentData])

  const handleOfficialSelection = ({ selectedAssignment, selectedOfficial = null }) => {
    if (!selectedAssignment) throw new Error('Cannot update official without providing an Assignment');

    setAssignmentData(prevAssignmentData => ({
      ...prevAssignmentData,
      selectedAssignment,
      selectedOfficial,
    }));

    if (selectedOfficial) {
      setDialogOpen(true)
    }

    if (assignSettingStatus == 'Draft') {
      assign(selectedAssignment.id, game.id, selectedOfficial.participantId, selectedAssignment.position, selectedAssignment.participantId)
        .then(() => {
          notify(translate('resources.games.notifications.assignment.official_assigned_to_game', { number: game.number }), 'success')
        })
        .catch(err => {
          const message = err?.response?.data?.error?.message
          notify(message || 'ra.page.error_try_again', 'error')
        })

      setAssignmentData(prevAssignmentData => ({
        selectedAssignment: null,
        selectedOfficial: {},
        displayedAssignments: prevAssignmentData.displayedAssignments.map(assignment => ({
          ...assignment,
          participantId: prevAssignmentData.selectedAssignment.id === assignment.id ? selectedOfficial.participantId : assignment.participantId,
          participant: prevAssignmentData.selectedAssignment.id === assignment.id ? selectedOfficial.participant : assignment.participant,
          status: prevAssignmentData.selectedAssignment.id === assignment.id ? (game.isApproved ? OFFICIAL_STATUS_CONFIRMED : OFFICIAL_STATUS_ASSIGNED) : assignment.status,
        })),
      }))
    }
  };

  const handleClear = (clearedAssignment) => {
    if (!clearedAssignment) throw new Error('Cannot remove official without providing an Assignment');

    setAssignmentData(prevAssignmentData => ({
      ...prevAssignmentData,
      selectedAssignment: null,
      selectedOfficial: {},
      displayedAssignments: prevAssignmentData.displayedAssignments.map(prevAssignment => ({
        ...prevAssignment,
        participantId: prevAssignment.id === clearedAssignment.id ? null : prevAssignment.participantId,
        participant: prevAssignment.id === clearedAssignment.id ? null : prevAssignment.participant,
        status: prevAssignment.id === clearedAssignment.id ? OFFICIAL_STATUS_UNASSIGNED : prevAssignment.status,
      })),
    }));
  }

  const handleOnAssign = (official) => {
    setAssignmentData(prevAssignmentData => ({
      selectedAssignment: null,
      selectedOfficial: {},
      displayedAssignments: prevAssignmentData.displayedAssignments.map(assignment => ({
        ...assignment,
        participantId: prevAssignmentData.selectedAssignment.id === assignment.id ? official.participantId : assignment.participantId,
        participant: prevAssignmentData.selectedAssignment.id === assignment.id ? official.participant : assignment.participant,
        status: prevAssignmentData.selectedAssignment.id === assignment.id ? OFFICIAL_STATUS_ASSIGNED : assignment.status,
      })),
    }))
    setDialogOpen(false)
  }

  const { displayedAssignments, selectedAssignment, selectedOfficial } = assignmentData;

  // From this point, the Assignment var is the assignment state that we manually update when posting data (not the actual assignment fetched)
  return <div>
    <OfficialsContextProvider gameId={game.id} assignments={displayedAssignments} listId={listId}>
      {displayedAssignments.map(displayedAssignment =>
        <EventAssignment
          key={displayedAssignment.id}
          assignment={displayedAssignment}
          mainAssignOfficeId={mainAssignOfficeId}
          onOfficialSelection={handleOfficialSelection}
          onClear={handleClear} />
      )}
    </OfficialsContextProvider>
    <QuickAssignmentDialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      selectedAssignment={selectedAssignment}
      selectedOfficial={selectedOfficial}
      displayedAssignment={displayedAssignments.find(assignment => assignment?.id === selectedAssignment?.id)}
      onAssign={handleOnAssign}
    />
  </div>
}
