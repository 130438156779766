import React from 'react';
import { useListContext, useTranslate } from 'react-admin';
import { styled, Table, TableHead, TableRow, TableCell, TableBody, CardContent, Typography } from '@material-ui/core';

const GroupTableCell = styled(TableCell)({
  fontWeight: 'bold',
})

const ContactTableCell = styled(TableCell)({
  whiteSpace: 'pre',
  verticalAlign: 'top',
})

const ContactList = styled('ul')({
  margin: 0,
  marginTop: 4,
  paddingLeft: 24,
})

const ContactListItem = styled('li')({
  maxWidth: 200,
  whiteSpace: 'normal',
  overflowWrap: 'anywhere',
})

const Contact = ({ member }) => {
  if (!member) return <ContactTableCell>--</ContactTableCell>;

  const contacts = member.contacts.map(contact => {
    let value = contact.value;
    if (contact.type !== 'Email') {
      value = `${contact.value} (${contact.type})`
    } else if (contact.notes) {
      value = `${contact.value} (${contact.notes})`
    }
    return <ContactListItem key={contact.id} title={contact.notes}>{value}</ContactListItem>
  });

  return <ContactTableCell>
    {member.name}
    {contacts.length ? <ContactList>{contacts}</ContactList> : null}
  </ContactTableCell>
}

export const ScheduleTeamContactGrid = ({ showGroup = false, ...props }) => {
  const translate = useTranslate();
  const { ids = [], data, loaded } = useListContext(props)

  if (!loaded) return <CardContent>{translate('ra.page.loading')}</CardContent>;
  if (!ids?.length) return null;

  const teams = ids.map(id => data?.[id]).filter(Boolean);

  const hasGroups = showGroup && teams.some(team => team.group);
  const hasPrimaryTeamArena = teams.some(team => team.primaryTeamArena);
  return <Table stickyHeader size="small">
    <TableHead>
      <TableRow>
        <TableCell>{translate('resources.scheduleteams.fields.teamId')}</TableCell>
        <TableCell>{translate('resources.scheduleteams.labels.coach')}</TableCell>
        <TableCell>{translate('resources.scheduleteams.labels.manager')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {teams.map((team, index) => {
        const manager = team.members.find(member => member.positions.includes('Manager'))
        let coach = team.members.find(member => member.positions.includes('Head Coach'))
        coach = coach || team.members.find(member => member.positions.some(position => position.includes('Coach')))

        const prevTeam = index > 0 && teams[index - 1];
        const firstGroup = index === 0 || (prevTeam && prevTeam.group !== team.group);
        const group = hasGroups && firstGroup && team.group;
        return <>
          {group && <TableRow><GroupTableCell colSpan={3}>{group || 'No group'}</GroupTableCell></TableRow>}
          <TableRow key={team._id} hover>
            <ContactTableCell>
              {team.name}
              {hasPrimaryTeamArena && <Typography variant="inherit" display="block" color="textSecondary">{team.primaryTeamArena || '--'}</Typography>}
            </ContactTableCell>
            <Contact member={coach} />
            <Contact member={manager} />
          </TableRow>
        </>
      })}
    </TableBody>
  </Table>
}
