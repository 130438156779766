import React, { useEffect } from 'react'
import { BooleanInput, NumberInput, SimpleForm, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Grid, InputAdornment, makeStyles, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons';

import Toolbar from '../../common/ra/Toolbar';
import { DurationTypeInput } from '../../common/inputs/EnumInputs';
import { useSport } from '../../http';

import { SanctionInput } from './SanctionInput';

const validate = (values, translate) => {
  const errors = {};

  if (!values.offense) errors.offense = 'ra.validation.required'
  if (!values.sanctionId) errors.sanctionId = 'ra.validation.required'
  if (values.durationType === 'Definite') {
    if (values.games == undefined) {
      errors.games = 'ra.validation.required';
    } else if (values.games < 1) {
      errors.games = translate('ra.validation.minValue', { min: 1 });
    }
  }

  return errors;
}

const inputProps = {
  resource: 'sanctionOffenses',
  basePath: '/sanctionOffenses',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const useStyles = makeStyles(theme => ({
  tooltip: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  }
}))

const ToolTipNumberInput = ({ tooltipText, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return <NumberInput
    InputProps={{
      endAdornment: <InputAdornment className={classes.tooltip}>
        <Tooltip title={translate(tooltipText, { _: tooltipText })}>
          <InfoOutlined color="primary" />
        </Tooltip>
      </InputAdornment>
    }}
    {...props}
  />
}

const SanctionOffenseForm = props => {
  const translate = useTranslate();
  const { values } = useFormState();
  const { change, batch } = useForm();
  const sport = useSport();

  const isIndefinite = values.durationType === 'Indefinite';

  useEffect(() => {
    if (isIndefinite) {
      batch(() => {
        change('games', null);
        change('lastMinutesGames', null);
        change('injuryGames', null);
        change('injuryDays', null);
        change('days', null);
        change('lastMinutesDays', null);
      })
    }
  }, [isIndefinite, change, batch]);

  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12} md={4}>
      <NumberInput source="offense" helperText="" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={8}>
      <SanctionInput source="sanctionId" disabled helperText="" {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <DurationTypeInput source="durationType" radio helperText="" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={6}>
      <ToolTipNumberInput source="games" tooltipText="resources.sanctionOffenses.helpers.games" disabled={isIndefinite} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={6}>
      <ToolTipNumberInput source="days" tooltipText="resources.sanctionOffenses.helpers.days" helperText="ra.message.unsupported" disabled {...inputProps} />
    </Grid>
    <Grid item xs={12} md={6}>
      <ToolTipNumberInput source="lastMinutesGames" tooltipText={translate("resources.sanctionOffenses.helpers.lastMinutesGames", { minutes: 10 })} helperText={sport === 'Hockey' ? "ra.message.optional" : "ra.message.unsupported"} disabled={isIndefinite || sport !== 'Hockey'} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={6}>
      <ToolTipNumberInput source="lastMinutesDays" tooltipText={translate("resources.sanctionOffenses.helpers.lastMinutesDays", { minutes: 10 })} helperText="ra.message.unsupported" disabled {...inputProps} />
    </Grid>
    <Grid item xs={12} md={6}>
      <ToolTipNumberInput source="injuryGames" tooltipText="resources.sanctionOffenses.helpers.injuryGames" helperText="ra.message.optional" disabled={isIndefinite} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={6}>
      <ToolTipNumberInput source="injuryDays" tooltipText="resources.sanctionOffenses.helpers.injuryDays" helperText="ra.message.unsupported" disabled {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <BooleanInput source="requireDisciplineCommittee" tooltipText="resources.sanctionOffenses.helpers.requireDisciplineCommittee" {...inputProps} />
    </Grid>
  </Grid>
}

export default props => {
  const translate = useTranslate();
  return <SimpleForm toolbar={<Toolbar />} validate={values => validate(values, translate)} {...props}>
    <SanctionOffenseForm {...props} />
  </SimpleForm>
}
