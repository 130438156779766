import React, { useContext, useMemo, useState } from 'react';

const ArenaSlotContext = React.createContext();

export const useArenaSlotContext = () => useContext(ArenaSlotContext);

export const ArenaSlotProvider = ({ children }) => {
  const overlaps = useState([]);

  const context = useMemo(() => ({
    overlaps
  }), [ overlaps ])

  return <ArenaSlotContext.Provider value={context}>
    {children}
  </ArenaSlotContext.Provider>
}
