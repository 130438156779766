import React from 'react';
import { TextInput, NumberInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { OfficeInput } from '../offices/OfficeInput';

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  return errors;
}

const inputProps = {
  resource: 'sections',
  basePath: '/sections',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const SectionForm = props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} lg={6}>
        <TextInput source="name" autoFocus {...inputProps} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <NumberInput source="order" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="officeId" filter={{ type: { neq: 'Historical' } }} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
