import React, { Fragment, useState } from 'react';
import { RecordContextProvider, SimpleForm, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Button, ListItemIcon, ListItemText } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';
import { ActionMenuItem } from '../../common/ActionMenu';
import { EventChanges } from '../../common/EventChanges';

import { DraftGameForm } from './DraftGameForm';
import { RequestCreatedByField, RequestReasonField } from './DraftGameApprovalModal';
import { isAuthorized } from '../../common/Authorize';
import { useAccount } from '../../http';

const EventChangesDialog = ({ draftGameId, previousEvent, ...props }) => {
  const translate = useTranslate();
  const draftGame = useSelector(state => state.admin.resources.draftGames.data[draftGameId]);
  const account = useAccount();

  const canDelete = isAuthorized(draftGame, 'draftGames', 'remove') || account?.id === draftGame?.createdBy;

  return <RecordContextProvider value={draftGame}>
    <EditResourceDialog label={translate('resources.draftGames.labels.draft_request', { number: draftGameId })} canDelete={canDelete} maxWidth="sm" resource="draftGames" basePath="/draftgames" hideSubmit {...props}>
      <SimpleForm component={Fragment} {...props}>
        <EventChanges previousEvent={previousEvent} alertLabel={translate("resources.draftGameApprovals.alerts.request_changes")} />
        <RequestReasonField />
        <RequestCreatedByField />
      </SimpleForm>
    </EditResourceDialog>
  </RecordContextProvider>
}

export const AddDraftGameDialog = ({ hideRounds, requestChanges, ...props }) =>
  <CreateResourceDialog label={`resources.draftGames.actions.${requestChanges ? 'request' : 'add'}`} maxWidth="md" resource="draftGames" basePath="/draftgames" {...props}>
    <DraftGameForm hideRounds={hideRounds} requestChanges={requestChanges} />
  </CreateResourceDialog>

export const EditDraftGameDialog = ({ hideRounds, gameToUpdate, ...props }) => {
  const translate = useTranslate();
  const approvals = useSelector(state => state.admin.resources.draftGameApprovals.data);

  const draftGameId = props?.id;
  const hasApprovals = draftGameId && Object.keys(approvals).some(approvalId => approvals[approvalId]?.draftGameId === draftGameId);

  if (hasApprovals) return <EventChangesDialog draftGameId={draftGameId} previousEvent={gameToUpdate} {...props} />

  return <EditResourceDialog label={translate('resources.draftGames.labels.edit_number', { number: props.id })} maxWidth={hasApprovals ? 'sm' : 'md'} resource="draftGames" basePath="/draftgames" {...props}>
    <DraftGameForm hideRounds={hideRounds} />
  </EditResourceDialog>
}

export const AddDraftGameButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.draftGames.actions.add')}</Button>
    <AddDraftGameDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const AddDraftGameAction = ({ onClick, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  const handleClick = () => {
    onClick();
    setOpen(true);
  }
  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><Add fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.draftGames.actions.add')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <AddDraftGameDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />}
  </>
}
