import React from 'react';
import { useRecordContext, NumberInput } from 'react-admin';

import { FF_DISABLE_ADD_QUALIFICATIONS } from '@hisports/common/featureFlags';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';
import { useDefaultSeason, useFlag, useScopes } from '../../../http';
import { ParticipantIcon } from '../../icons';

import { QualificationGrid } from '../../qualifications/QualificationGrid';
import { AddQualificationButton } from '../../qualifications/QualificationModal';
import { QualificationCategoryInput } from '../../qualifications/QualificationForm';

const expiry = new Date().toISOString();

export default props => {
  const isEnabled = useFlag();
  const scopes = useScopes();
  const participant = useRecordContext(props);
  const seasonId = useSeason();
  const defaultSeason = useDefaultSeason();

  const showQualifications = isAuthorized(participant, 'qualifications', 'list')
  const canAddQualification = scopes.includes('internal') || (isAuthorized(participant, 'qualifications', 'create') && !isEnabled(FF_DISABLE_ADD_QUALIFICATIONS))

  if (!showQualifications) return null;

  const filters = [
    <NumberInput source="level" variant="outlined" alwaysOn />,
    <QualificationCategoryInput source="qualificationCategoryId" filter={{ types: { inq: ['Official', 'Scorekeeper'] } }} variant="outlined" alwaysOn />,
    // <QualificationCategoryTypeInput source="qualificationCategory.types" variant="outlined" alwaysOn />,
  ]

  const isPast = seasonId !== defaultSeason;
  const filter = isPast
    ? { seasonId,
      qualificationCategory: {
        types: { inq: ['Official', 'Scorekeeper'] } } }
    : { qualificationCategory: {
      types: { inq: ['Official', 'Scorekeeper'] } },
    or: [
      { seasonId },
      { expiry: { gte: expiry } },
      { expiry: { eq: null } }
    ]
    }

  return <ListCard
    title="resources.qualifications.labels.card.title"
    icon={<ParticipantIcon />}
    reference="qualifications"
    target="participants"
    filter={filter}
    filters={filters}
    sort={{ field: 'seasonId', order: 'DESC' }}
    addButton={canAddQualification && <AddQualificationButton initialValues={{ participantId: participant?.id }} />}
  >
    <QualificationGrid />
  </ListCard>
}
