import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { RssFeed } from '@material-ui/icons';

import { useParticipant, useFilteredScopes } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';
import ListCard from '../../../common/cards/ListCard';

import { EventReferenceManyField } from '../../../resources/events/EventReferenceField';
import { EventView } from '../../../resources/events/EventView';
import { useEventSort } from '../../../resources/events/EventViewSettings';

import CalendarDialog from '../dialogs/CalendarDialog';

const FeedButton = ({ color, size, variant }) => {
  const scopes = useFilteredScopes(['Participant']);
  const translate = useTranslate()
  const participantId = useParticipant()
  const [ isOpen, setOpen ] = useState(false);

  if (!isAuthorized(scopes, 'profile', 'feed')) return null;
  return <>
    <Button color={color} size={size} variant={variant} startIcon={<RssFeed />} onClick={() => setOpen(true)}>
      {translate('resources.games.messages.calendar.subscribe')}
    </Button>
    <CalendarDialog participantId={participantId} isOpen={isOpen} onClose={() => setOpen(false)} />
  </>
}

export default ({ title, ...props }) => {
  const account = useRecordContext(props);
  const scopes = useFilteredScopes(['Team', 'Participant']);
  const sort = useEventSort();

  if (!account?.id) return null;
  if (!isAuthorized(scopes, 'profile', 'games')) return null;

  const actions = [
    <FeedButton size="small" />,
  ]

  return <ListCard
    title={title}
    resource="games"
    target="participants"
    sort={sort}
    base={EventReferenceManyField}
    baseProps={{
      includeGames: true,
      includeDraftGames: false,
      includePractices: true,
      includeActivities: true,
      includeSlots: false,
      includeAvailabilities: false,
    }}
    actions={actions}
    rowsPerPageOptions={[10, 25, 50, 100]}
    {...props}
  >
    <EventView disableCalendar />
  </ListCard>
}
