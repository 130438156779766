import React from 'react';
import { useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';

import { OfficeInput } from '../offices/OfficeInput';
import { ScheduleInput } from '../schedules/ScheduleInput';
import { TeamInput } from '../teams/TeamInput';
import { ParticipantInput } from '../participants/ParticipantInput';
import { VenueInput } from '../venues/VenueInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';
import { ListInput } from '../lists/ListInput';
import { GameInput } from '../games/GameInput';

export const TargetInput = props => {
  const translate = useTranslate();
  const { values } = useFormState();
  const { targetType } = values;

  switch (targetType) {
    case 'Office':
      return <OfficeInput label={translate('resources.offices.name', 1)} filter={{ type: { neq: 'Historical' } }} {...props} />
    case 'Schedule':
      return <ScheduleInput label={translate('resources.schedules.name', 1)} {...props} />
    case 'Team':
      return <TeamInput label={translate('resources.teams.name', 1)} {...props} />
    case 'Venue':
      return <VenueInput label={translate('resources.venues.name', 1)} {...props} />
    case 'Surface':
    case 'Arena':
      return <SurfaceInput label={translate('resources.surfaces.name', 1)} {...props} />
    case 'Participant':
      return <ParticipantInput label={translate('resources.participants.name', 1)} {...props} />
    case 'List':
      return <ListInput label={translate('resources.lists.name', 1)} {...props} />
    case 'Game':
      return <GameInput label={translate('resources.games.name', 1)} {...props} />
    default:
      return null;
  }
}
