import React from 'react';
import { useLocale, useRecordContext, useTranslate } from 'react-admin';
import { SportsOutlined } from '@material-ui/icons';

import { isLegacyRulebookSeason } from '@hisports/scoresheet/src/util';

import { useFlag, useSport } from '../../../http';
import ListCard from '../../../common/cards/ListCard';

import { GamePenaltyGrid } from '../../gamepenalties/GamePenaltyGrid';
import { ParticipantInput } from '../../participants/ParticipantInput';
import { TeamInput } from '../../teams/TeamInput';
import { CategoryInput } from '../../categories/CategoryInput';
import PenaltiesExporter from '../../gamepenalties/PenaltiesExporter';

export default props => {
  const schedule = useRecordContext(props);
  const translate = useTranslate();
  const sport = useSport();
  const isEnabled = useFlag();
  const locale = useLocale();

  const isLegacy = isLegacyRulebookSeason(schedule?.seasonId);

  const filters = [
    <ParticipantInput source="participantId" variant="outlined" />,
    <TeamInput source="teamId" variant="outlined" />,
    <CategoryInput source="game.categoryId" label="resources.games.fields.categoryId" variant="outlined" />,
  ]

  const actions = [
    <PenaltiesExporter target="game.scheduleId" sport={sport} isEnabled={isEnabled} locale={locale} />
  ]

  return <ListCard
    title={translate('resources.gamepenalties.name', 2)}
    icon={<SportsOutlined />}
    basePath="/gamepenalties"
    resource="gamepenalties"
    actions={actions}
    filter={{ _options: { includeMeta: isLegacy }, 'game.scheduleId': schedule?.id }}
    sort={{ field: ['game.date', 'participant.lastName', 'participant.firstName'], order: ['DESC', 'ASC', 'ASC'] }}
    filters={filters}
    {...props}
  >
    <GamePenaltyGrid seasonId={schedule?.seasonId} multipleGames rowOptions={{ isRowSelectable: () => false }} />
  </ListCard>
}
