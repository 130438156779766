import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { Link } from '@material-ui/core';

import { usePermissions } from '../http';

import Layout from './BaseLayout';
import Unauthorized from './Unauthorized';

export default () => {
  const translate = useTranslate();
  const permissions = usePermissions();
  const volunteer = permissions.find(p => p.roleType === 'Game' && p.scopes.includes('scoresheets:manage'));

  const url = `/scoresheets/${volunteer?.gameId}/pregame`;
  useEffect(() => {
    if (!volunteer) return;
    window.location.href = url;
  }, [volunteer, url])

  if (!volunteer) return <Unauthorized />
  return <Layout>
    <h3>{translate('ra.message.redirecting')}...</h3>
    <p>{translate('components.login.messages.redirect')}
      <Link href="#">{translate('ra.action.click_here').toLowerCase()}</Link>.
    </p>
  </Layout>
}
