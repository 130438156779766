import React, { useEffect, useMemo, useRef } from 'react';
import { SimpleForm, useTranslate, TextInput } from 'react-admin';
import moment from 'moment-timezone';
import { Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';

import { changeTimezone, isEmpty } from '@hisports/parsers';
import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { ArenaSlotProvider, useArenaSlotContext } from './ArenaSlotContext';
import { apiClient } from '../../http';

import { InlineDateTimeInput, dateTimePlaceholder } from '../../common/inputs/DateInput';
import { ArenaSlotTypeEnumInput, TimezoneEnumInput } from '../../common/inputs/EnumInputs';
import { EndTimeInput } from '../../common/inputs/EndTimeInput';
import { RecurrenceField } from '../../common/fields/RecurrenceField';
import { getRule } from '../../common/fields/RecurrenceSchedule';
import Toolbar from '../../common/ra/Toolbar';

import { CategoryInput } from '../categories/CategoryInput';
import { OfficeInput } from '../offices/OfficeInput';
import { ArenaOfficeInput } from '../arenaoffices/ArenaOfficeInput';

import { SlotSplitField } from './SlotSplits';
import SwitchInput from '../../common/inputs/SwitchInput';

const inputProps = {
  resource: 'arenaslots',
  basePath: '/arenaslots',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const validate = (values, overlaps) => {
  const { startTime, endTime, officeId, arenaId, timezone, frequency, endDate, slotDuration, breakDuration } = values;
  const errors = {};

  if (overlaps?.length > 0) errors.startTime = 'resources.arenaslots.validations.overlaps';
  if (!startTime) errors.startTime = 'ra.validation.required';
  if (!endTime) errors.endTime = 'ra.validation.required';
  if (!arenaId) errors.arenaId = 'ra.validation.required';
  if (!timezone) errors.timezone = 'ra.validation.required';

  if (startTime && endTime) {
    if (!moment(startTime).isValid()) {
      errors.startTime = 'resources.games.validations.invalid_time'
    } else if (!moment(endTime).isValid()) {
      errors.endTime = 'resources.games.validations.invalid_time'
    } else if (moment(endTime).isBefore(startTime, 'minute')) {
      errors.endTime = 'resources.games.validations.end_before_start'
    } else if (!moment(endTime).isSame(startTime, 'day')) {
      errors.endTime = 'resources.games.validations.invalid_must_be_same_day'
    }

    if (slotDuration || breakDuration) {
      const timeslotMinutes = moment.tz(endTime, timezone).diff(startTime, 'minutes');

      if (slotDuration && breakDuration == null) errors.breakDuration = 'ra.validation.required';
      if (!slotDuration && breakDuration) errors.slotDuration = 'ra.validation.required';

      if (slotDuration > timeslotMinutes) errors.slotDuration = 'resources.arenaslots.validations.duration_exceeds_range';
      if (breakDuration > timeslotMinutes) errors.breakDuration = 'resources.arenaslots.validations.duration_exceeds_range';

      if (slotDuration && breakDuration != null) {
        if (timeslotMinutes % (slotDuration + breakDuration) !== 0) {
          errors.slotDuration = 'resources.arenaslots.validations.total_duration';
          errors.breakDuration = 'resources.arenaslots.validations.total_duration';
        }
      }
    }
  }

  if (frequency != null && frequency !== 'Once') {
    if (!endDate) {
      errors.endDate = 'ra.validation.required'
    } else if (startTime && moment.utc(endDate).isBefore(startTime, 'day')) {
      errors.endDate = 'resources.games.validations.after_start_time';
    } else if (startTime && moment.utc(endDate).diff(startTime, 'day') > 365) {
      errors.endDate = 'resources.games.validations.invalid_date'
    }
  }

  if (!officeId) errors.officeId = 'ra.validation.required';

  return errors;
}

const useSlotOverlaps = slot => {
  const { overlaps: [ overlaps, setOverlaps ] } = useArenaSlotContext();
  const { id, startTime, endTime, arenaId, frequency, interval, weekdays, endDate, timezone } = slot;

  const rule = useMemo(() => {
    return getRule({ frequency, interval, weekdays, endDate, timezone })
  }, [frequency, interval, weekdays, endDate, timezone])

  const recurrence = rule?.toString().replace('RRULE:', '') || null;

  useEffect(() => {
    if (!startTime || !endTime || !arenaId) return;

    apiClient(`/surfaces/${arenaId}/overlaps`, { method: 'POST', data: [{ startTime, endTime, recurrence, timezone }] })
      .then(res => res.data)
      .then(data => setOverlaps(data.flatMap(slot => slot.overlaps).filter(overlap => overlap.id !== id)))
  }, [startTime, endTime, arenaId, recurrence, timezone, setOverlaps, id])

  return overlaps;
}

const ArenaSlotFormBody = () => {
  const translate = useTranslate();
  const { initialValues, values } = useFormState();
  const overlaps = useSlotOverlaps(values);
  const isEditing = initialValues.id != null;

  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12} md={6}>
      <TextInput source="name" helperText="ra.message.optional" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={6}>
      <OfficeInput source="officeId" filter={{ type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] } }} {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <ArenaOfficeInput source="arenaId" officeId={initialValues?.officeId} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <InlineDateTimeInput
        source="startTime"
        options={{
          label: translate('resources.arenaslots.fields.startTime'),
          format: 'YYYY-MM-DD HH:mm',
          placeholder: dateTimePlaceholder,
          ampm: false,
        }}
        {...inputProps}
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <EndTimeInput
        source="endTime"
        maxHours={12}
        options={{
          label: translate('resources.arenaslots.fields.endTime'),
          format: 'YYYY-MM-DD HH:mm',
          placeholder: dateTimePlaceholder,
          ampm: false,
        }}
        {...inputProps}
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <TimezoneEnumInput source="timezone" disabled {...inputProps} />
    </Grid>
    {!!overlaps?.length && <Grid item xs={12}>
      <Alert severity="error">
        <AlertTitle>
          {translate('resources.arenaslots.alerts.overlaps_title')}
        </AlertTitle>
        {translate('resources.arenaslots.alerts.overlaps')}
      </Alert>
    </Grid>}
    <Grid item xs={12} lg={12}>
      <TextInput source="comments" multiline rows="3" helperText="ra.message.optional" {...inputProps} />
    </Grid>
    {!isEditing && <SlotSplitField {...inputProps} />}
    <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.arenaslots.labels.restrictions')}</Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <ArenaSlotTypeEnumInput source="types" multiple helperText="resources.arenaslots.helpers.types" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={8}>
      <CategoryInput source="categoryIds" multiple helperText="resources.arenaslots.helpers.categoryIds" {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <OfficeInput source="leagueIds" multiple helperText="resources.arenaslots.helpers.leagueIds" filter={{ type: ['League', 'Tournament', 'Cup'] }} {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <SwitchInput source="isShared" {...inputProps} />
    </Grid>
    {!isEditing && <RecurrenceField {...inputProps} />}
  </Grid>
}

const InnerArenaSlotForm = ({ save, ...props }) => {
  const { overlaps: [ overlaps ] } = useArenaSlotContext();
  const surfaces = useSelector(store => store.admin.resources.surfaces.data);
  const surfacesRef = useRef({});
  surfacesRef.current = surfaces;

  const decorators = useRef([createCalculator({
    field: 'officeId',
    updates: {
      leagueIds: (officeId, values, prevValues) => {
        if (!officeId || prevValues.officeId != officeId) {
          return [];
        }
        return values.leagueIds;
      } }
  }, {
    field: 'arenaId',
    updates: {
      timezone: (arenaId, values, prevValues) => {
        const surface = surfacesRef.current[arenaId];
        if (!surface) return values.timezone;
        return surface.timezone;
      }
    }
  }, {
    field: 'timezone',
    updates: {
      startTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.startTime || isEmpty(prevValues) || timezone === prevTimezone) return values.startTime;
        return changeTimezone(values.startTime, prevTimezone, timezone)
      },
      endTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.endTime || isEmpty(prevValues) || timezone === prevTimezone) return values.endTime;
        return changeTimezone(values.endTime, prevTimezone, timezone)
      },
    }
  }, {
    field: 'startTime',
    updates: {
      date: (startTime, values) => {
        if (!startTime) return values.date;
        if (!values.timezone) return startTime;
        return moment.tz(startTime, values.timezone).format('YYYY-MM-DD');
      }
    }
  })])

  const onSave = async ({ frequency, interval, weekdays, endDate, recurrence, splits = [], ...timeslot }) => {
    const rule = getRule({ frequency, interval, weekdays, endDate, timezone: timeslot.timezone });
    timeslot.recurrence = rule?.toString().replace('RRULE:', '') || null;

    return save(timeslot, props.redirect);
  }

  return (
    <SimpleForm
      toolbar={<Toolbar />}
      decorators={decorators.current}
      validate={(values) => validate(values, overlaps)}
      save={onSave}
      {...props}>
      <ArenaSlotFormBody />
    </SimpleForm>
  );
}

export const ArenaSlotForm = props =>
  <ArenaSlotProvider>
    <InnerArenaSlotForm {...props} />
  </ArenaSlotProvider>
