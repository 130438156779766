import React from 'react';
import { useRecordContext } from 'react-admin';

import { isAuthorized } from '../../../common/Authorize';
import ListCard from '../../../common/cards/ListCard';

import { GameEventGrid } from '../../gameEvents/GameEventGrid';
import { EventBulkActions } from '../../events/EventBulkActions';

export default props => {
  const game = useRecordContext();
  const canDelete = isAuthorized(game, 'gameEvents', 'remove');

  return <ListCard
    title="resources.gameEvents.labels.card.title"
    reference="gameEvents"
    target="gameId"
    sort={{ field: 'timestamp', order: 'ASC' }}
    rowsPerPageOptions={[]}
    perPage={9999}
    bulkActionButtons={canDelete && <EventBulkActions />}
  >
    <GameEventGrid />
  </ListCard>
}
