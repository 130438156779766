import React from 'react';

import FunctionField from '../../common/fields/FunctionField';
import TextArrayField from '../../common/fields/TextArrayField';

import { ConstraintDatagrid } from './ConstraintsModal';
import { getConstraintSummary } from './ConstraintsForm';

import { CategoriesField } from '../categories/CategoriesField';

const renderSummary = (values, source, resource, translate) => {
  return getConstraintSummary(values, translate)
}

export const ConstraintsGrid = props => {
  return <ConstraintDatagrid size="medium" {...props}>
    <FunctionField source="limit" {...props} render={renderSummary} />
    <CategoriesField source="categoryIds" emptyText="ra.message.all" />
    <TextArrayField source="scheduleTypes" emptyText="ra.message.all" />
  </ConstraintDatagrid>
}
