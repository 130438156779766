import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { RulebookForm } from './RulebookForm';

const AddRulebookDialog = props =>
  <CreateResourceDialog label="resources.rulebooks.labels.add_limits" resource="rulebooks" basePath="/rulebooks" maxWidth="md" {...props}>
    <RulebookForm />
  </CreateResourceDialog>

const EditRulebookDialog = props =>
  <EditResourceDialog label="resources.rulebooks.labels.edit_limits" resource="rulebooks" basePath="/rulebooks" {...props}>
    <RulebookForm />
  </EditResourceDialog>

export const AddRulebookButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.rulebooks.actions.add_limits')}
    </Button>
    <AddRulebookDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const RulebookDatagrid = props => <DialogDatagrid dialog={EditRulebookDialog} {...props} />
