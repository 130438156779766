import React, { Fragment } from 'react';
import { Datagrid, DeleteButton, useTranslate, RecordContextProvider, useRecordContext } from 'react-admin';
import { Link, makeStyles } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { startCase } from 'lodash';

import { dedupe } from '@hisports/parsers';
import { displayTime, isGeneralFlag, isTeamFlag } from '@hisports/scoresheet/src/util';
import { FF_BATTERS } from '@hisports/common/featureFlags';

import { useFlag, useSport } from '../../http';
import { isAuthorized } from '../../common/Authorize';

import { TeamField } from '../teams/TeamField';
import { ParticipantField } from '../participants/ParticipantField';
import { AccountField } from '../accounts/AccountField';
import { OfficeField } from '../offices/OfficeField';
import { AssignSystemField } from '../assignSystems';
import { AssignFeesField } from '../assignFees';
import { SurfaceField } from '../surfaces/SurfaceField';
import { ScheduleField } from '../schedules/ScheduleField';
import { GroupField } from '../groups/GroupField';
import { CategoryField } from '../categories/CategoryField';
import { InfractionField } from '../infractions/InfractionField';

const useStyles = makeStyles(theme => ({
  text: {
    whiteSpace: 'pre-line'
  },
  verticalAlignTop: {
    verticalAlign: 'top'
  }
}))

const Timestamp = ({ timestamp, timezone = moment.tz.guess() }) =>
  <span title={moment.tz(timestamp, timezone).format('YYYY-MM-DD HH:mm:ss.SSS')}>
    {moment.tz(timestamp, timezone).format('YYYY-MM-DD hh:mm A z')}
  </span>

const TimestampDate = ({ timestamp, timezone = moment.tz.guess() }) =>
  <span title={moment.tz(timestamp, timezone).format('LL')}>
    {moment.tz(timestamp, timezone).format('LL')}
  </span>

const Participant = ({ participant }) => {
  if (!participant) return null;
  const name = participant.HCRId ? `${participant.fullName} (${participant.HCRId})` : participant.fullName;
  if (!participant.id) return name;
  return <Link component={RouterLink} to={`/participants/${participant.id}/show`}>{name}</Link>
}

const toSuspension = suspension => !suspension ? '-' :
  `✅${suspension.game ? ` (${suspension.game}/${suspension.total || '∞'})` : ''}`

const getValue = value => {
  if (React.isValidElement(value)) return value;
  if (typeof value === 'undefined') return '-';
  if (value === true) return '✅';
  if (value === false) return '❌';
  if (typeof value === 'string') return value;
  if (Array.isArray(value) && !value.length) return 'N/A';
  if (Array.isArray(value) && value.some(React.isValidElement)) return value.map(getValue)
  if (Array.isArray(value)) return value.map(getValue).join(', ')
  return JSON.stringify(value, null, 2);
}

const Value = ({ value }) => {
  return <td>{getValue(value)}</td>
}

const scrollTo = id => () => {
  const row = document.getElementById(`id-${id}`);
  if (!row) return;
  row.scrollIntoView();
}

const Row = ({ title, value, addLink }) =>
  <tr>
    <td style={{ fontWeight: 500, minWidth: 100, borderSpacing: 0 }}>{title}</td>
    <td onClick={addLink && scrollTo(value)}>{value}</td>
  </tr>

const RowValue = ({ title, value, addLink }) =>
  <Row title={title} value={getValue(value)} addLink={addLink} />

const Signature = ({ signature }) => {
  if (!signature) return JSON.stringify(signature, null, 2) || null;
  return <img style={{ border: '1px solid #efefef', maxWidth: 500 }} src={signature} alt="Signature" />
}

const Member = ({ member }) => {
  const isEnabled = useFlag();

  return <tr>
    <Value value={member.number != null ? member.number : ''} />
    <td><Participant participant={member.participant} /></td>
    {isEnabled(FF_BATTERS) && <Value value={member.battingOrder != null ? member.battingOrder : '-'} />}
    <Value value={member.positions} />
    <Value value={member.isCaptain} />
    <Value value={member.isAlternate} />
    <Value value={member.isAffiliate} />
    <Value value={member.isOverage} />
    <Value value={member.isUnderage} />
    <Value value={!!member.isStarter} />
    <Value value={toSuspension(member.suspension)} />
  </tr>
}

const ThrowsInnings = ({ member }) =>
  <tr>
    <td><ParticipantField record={member} reference="participants" source="participantId" /></td>
    <Value value={member.throws != null ? member.throws : '-'} />
    <Value value={member.inningsPitched != null ? member.inningsPitched : '-'} />
  </tr>

const EventTypeField = () => {
  const translate = useTranslate();
  const { eventType } = useRecordContext();
  return translate(`resources.gameEvents.values.eventType.${eventType}`);
}

const EventField = ({ game, ...props }) => {
  const isEnabled = useFlag()
  const translate = useTranslate();
  const sport = useSport();
  const { eventType, event } = useRecordContext(props);
  const classes = useStyles();

  switch (eventType) {
    case 'gameCreated':
    case 'gameRescheduled':
    case 'gameUpdated': {
      return <table>
        <tbody>
          {event.number && <RowValue title={translate('resources.games.fields.number')} value={event.number} />}
          {event.date && <RowValue title={translate('resources.games.fields.date')} value={<TimestampDate timestamp={event.startTime} timezone={event.timezone} />} />}
          {event.startTime && <Row title={translate('resources.games.fields.startTime')} value={<Timestamp timestamp={event.startTime} timezone={event.timezone} />} />}
          {event.endTime && <Row title={translate('resources.games.fields.endTime')} value={<Timestamp timestamp={event.endTime} timezone={event.timezone} />} />}
          {event.arenaId && <RowValue title={translate('resources.games.fields.arenaId')} value={<SurfaceField source="event.arenaId" addLabel={false} allowEmpty />} />}
          {event.homeTeamId && <RowValue title={translate('resources.games.fields.homeTeamId')} value={<TeamField source="event.homeTeamId" addLabel={false} allowEmpty />} />}
          {event.awayTeamId && <RowValue title={translate('resources.games.fields.awayTeamId')} value={<TeamField source="event.awayTeamId" addLabel={false} allowEmpty />} />}
          {event.scheduleId && <RowValue title={translate('resources.games.fields.scheduleId')} value={<ScheduleField source="event.scheduleId" addLabel={false} allowEmpty />} />}
          {event.categoryId && <RowValue title={translate('resources.games.fields.categoryId')} value={<CategoryField source="event.categoryId" addLabel={false} allowEmpty />} />}
          {event.groupId && <RowValue title={translate('resources.games.fields.groupId')} value={<GroupField source="event.groupId" addLabel={false} allowEmpty />} />}
          {event.status && <RowValue title={translate('resources.games.fields.status')} value={event.status} />}
          {event.comments && <RowValue title={translate('resources.games.fields.comments')} value={event.comments} />}
        </tbody>
      </table>
    }

    case 'assignSettingsUpdated': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.offices.name', 1)} value={<OfficeField source="event.officeId" addLabel={false} allowEmpty />} />
          <RowValue title={translate('resources.gameAssignSettings.fields.systemId')} value={<AssignSystemField source="event.systemId" addLabel={false} allowEmpty />} />
          <RowValue title={translate('resources.gameAssignSettings.labels.referee')} value={event.minReferee} />
          <RowValue title={translate('resources.gameAssignSettings.labels.linesperson')} value={event.minLinesperson} />
          <RowValue title={translate('resources.gameAssignSettings.labels.official')} value={event.minOfficial} />
          <RowValue title={translate('resources.gameAssignSettings.labels.scorekeeper')} value={event.minScorekeeper} />
          <RowValue title={translate('resources.gameAssignSettings.labels.timekeeper')} value={event.minTimekeeper} />
          <RowValue title={translate('resources.gameAssignSettings.labels.grade')} value={event.minGrade} />
          <RowValue title={translate('resources.gameAssignSettings.labels.referee_grade')} value={event.minRefereeGrade} />
          <RowValue title={translate('resources.gameAssignSettings.labels.linesperson_grade')} value={event.minLinespersonGrade} />
          <RowValue title={translate('resources.gameAssignSettings.fields.minAge')} value={event.minAge} />
          <RowValue title={translate('resources.gameAssignSettings.fields.feesId')} value={<AssignFeesField source="event.feesId" addLabel={false} allowEmpty />} />
          <RowValue title={translate('resources.gameAssignSettings.fields.status')} value={event.status} />
        </tbody>
      </table>
    }

    case 'lineupExtraAdded': {
      return <>
        <TeamField source="teamId" addLabel={false} allowEmpty />
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>{translate('resources.members.fields.participantId')}</th>
              {isEnabled(FF_BATTERS) && <th>{translate('resources.members.fields.batter')}</th>}
              <th>{translate('resources.members.fields.positions')}</th>
              <th title={translate('resources.members.fields.isCaptain')}>{translate('resources.members.fields.abbreviations.isCaptain')}</th>
              <th title={translate('resources.members.fields.isAlternate')}>{translate('resources.members.fields.abbreviations.isAlternate')}</th>
              <th title={translate('resources.members.fields.isAffiliate')}>{translate('resources.members.fields.abbreviations.isAffiliate')}</th>
              <th title={translate('resources.members.fields.isOverage')}>{translate('resources.members.fields.abbreviations.isOverage')}</th>
              <th title={translate('resources.members.fields.isUnderage')}>{translate('resources.members.fields.abbreviations.isUnderage')}</th>
              <th title={translate('resources.members.fields.isStarter')}>{translate('resources.members.fields.abbreviations.isStarter')}</th>
              <th title={translate('resources.members.labels.suspension')}>{translate('resources.members.labels.suspension_abbreviation')}</th>
            </tr>
          </thead>
          <tbody>
            <Member member={event.member} />
          </tbody>
        </table>
      </>
    }

    case 'lineupExtraRemoved': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.teams.name', 1)} value={<TeamField source="event.teamId" addLabel={false} allowEmpty />} />
          <RowValue title={translate('resources.participants.name', 1)} value={<ParticipantField source="event.participantId" addLabel={false} allowEmpty />} />
        </tbody>
      </table>
    }

    case 'lineupUpdated': {
      const members = event.members.map(member => <Member member={member} key={member.id} />)

      return <>
        <TeamField source="event.teamId" addLabel={false} allowEmpty />
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>{translate('resources.members.fields.participantId')}</th>
              {isEnabled(FF_BATTERS) && <th>{translate('resources.members.fields.batter')}</th>}
              <th>{translate('resources.members.fields.positions')}</th>
              <th title={translate('resources.members.fields.isCaptain')}>{translate('resources.members.fields.abbreviations.isCaptain')}</th>
              <th title={translate('resources.members.fields.isAlternate')}>{translate('resources.members.fields.abbreviations.isAlternate')}</th>
              <th title={translate('resources.members.fields.isAffiliate')}>{translate('resources.members.fields.abbreviations.isAffiliate')}</th>
              <th title={translate('resources.members.fields.isOverage')}>{translate('resources.members.fields.abbreviations.isOverage')}</th>
              <th title={translate('resources.members.fields.isUnderage')}>{translate('resources.members.fields.abbreviations.isUnderage')}</th>
              <th title={translate('resources.members.fields.isStarter')}>{translate('resources.members.fields.abbreviations.isStarter')}</th>
              <th title={translate('resources.members.labels.suspension')}>{translate('resources.members.labels.suspension_abbreviation')}</th>
            </tr>
          </thead>
          <tbody>
            {members}
          </tbody>
        </table>
      </>
    }

    case 'lineupApproved': {
      return <>
        <table>
          <tbody>
            <RowValue title={translate('resources.teams.name', 1)} value={<TeamField source="event.teamId" addLabel={false} allowEmpty />} />
            <RowValue title={translate('resources.gameEvents.labels.signatory')} value={<ParticipantField source="event.participantId" addLabel={false} allowEmpty />} />
          </tbody>
        </table>
        <Signature signature={event.signature} />
      </>
    }

    case 'keepersUpdated': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.gameEvents.labels.scorekeeper')} value={event.scorekeeper} />
          <RowValue title={translate('resources.gameEvents.labels.timekeeper')} value={event.timekeeper} />
        </tbody>
      </table>
    }

    case 'officialRequested':
    case 'officialAssigned':
    case 'officialAdded': {
      const position = event.position || (event.positions && event.positions[0])
      return <table>
        <tbody>
          <RowValue title="ID" value={event.official.id} />
          <RowValue title={translate('resources.participants.name', 1)} value={<Participant participant={event.official.participant} />} />
          <RowValue title={translate('resources.gameEvents.labels.position', 1)} value={translate(`resources.games.values.assignment.position.${position}`, { _: position })} />
          <RowValue title={translate('resources.gameEvents.labels.notes')} value={event.notes} />
        </tbody>
      </table>
    }

    case 'officialAccepted':
    case 'officialDeclined': {
      return <table>
        <tbody>
          {event.participantId && <RowValue title={translate('resources.participants.name', 1)} value={<ParticipantField source="event.participantId" addLabel={false} allowEmpty />} />}
          <RowValue title={translate('resources.gameEvents.labels.notes')} value={event.notes} />
        </tbody>
      </table>
    }

    case 'officialDelegated': {
      const position = event.position || (event.positions && event.positions[0])
      return <table>
        <tbody>
          {event.participantId && <RowValue title={translate('resources.participants.name', 1)} value={<ParticipantField source="event.participantId" addLabel={false} allowEmpty />} />}
          {event.officialId && <RowValue title={translate('resources.officials.labels.official_id')} value={event.officialId} />}
          {event.officeId && <RowValue title={translate('resources.officials.fields.officeId')} value={<OfficeField source="event.officeId" addLabel={false} allowEmpty />} />}
          {event.payOfficeId && <RowValue title={translate('resources.officials.fields.payOfficeId')} value={<OfficeField source="event.payOfficeId" addLabel={false} allowEmpty />} />}
          <RowValue title={translate('resources.gameEvents.labels.position', 1)} value={translate(`resources.games.values.assignment.position.${position}`, { _: position })} />
        </tbody>
      </table>
    }

    case 'officialUpdated': {
      const position = event.position || (event.positions && event.positions[0])
      return <table>
        <tbody>
          {event.participantId && <RowValue title={translate('resources.participants.name', 1)} value={<ParticipantField source="event.participantId" addLabel={false} allowEmpty />} />}
          {event.officialId && <RowValue title={translate('resources.officials.labels.official_id')} value={event.officialId} />}
          <RowValue title={translate('resources.gameEvents.labels.position', 1)} value={translate(`resources.games.values.assignment.position.${position}`, { _: position })} />
          {event.status && <RowValue title={translate('resources.games.fields.status')} value={translate(`resources.games.labels.assignment.status.${event.status}`, { _: event.status })} />}
        </tbody>
      </table>
    }

    case 'officialRemoved': {
      return <table>
        <tbody>
          {event.participantId && <RowValue title={translate('resources.participants.name', 1)} value={<ParticipantField source="event.participantId" addLabel={false} allowEmpty />} />}
          {event.officialId && <RowValue title={translate('resources.officials.labels.official_id')} value={event.officialId} />}
          {event.isNoShow && <RowValue title={translate('resources.games.fields.status')} value={translate(`resources.games.labels.assignment.status.no_show`)} />}
        </tbody>
      </table>
    }

    case 'settingsUpdated': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.gameEvents.labels.periods.1')} value={displayTime(event.periods?.[0], sport, false, !event.periods?.[0])} />
          <RowValue title={translate('resources.gameEvents.labels.periods.2')} value={displayTime(event.periods?.[1], sport, false, !event.periods?.[1])} />
          <RowValue title={translate('resources.gameEvents.labels.periods.3')} value={displayTime(event.periods?.[2], sport, false, !event.periods?.[2])} />
          <RowValue title={translate('resources.gameEvents.labels.periods.OT')} value={displayTime(event.periods?.[3], sport, false, !event.periods?.[2])} />
        </tbody>
      </table>
    }

    case 'certificationFlagsUpdated': {
      const { flags = [] } = event;
      const teamIds = dedupe(flags.map(flag => flag.teamId));
      const generalFlags = flags.filter(isGeneralFlag);
      const firstTeamFlags = teamIds?.[0] && flags.filter(flag => isTeamFlag(flag) && flag.teamId === teamIds?.[0])
      const secondTeamFlags = teamIds?.[1] && flags.filter(flag => isTeamFlag(flag) && flag.teamId === teamIds?.[1])

      return <table>
        <tbody>
          {!!generalFlags?.length && <RowValue title={translate('resources.games.labels.general')} value={generalFlags.map(flag => translate(`resources.games.labels.flags.${flag.name}`, 2))} />}
          {!!firstTeamFlags?.length && <RowValue
            title={<TeamField source="id" record={{ id: teamIds?.[0] }} addLabel={false} allowEmpty link={false} variant="inherit" />}
            value={firstTeamFlags.map(flag => translate(`resources.games.labels.flags.${flag.name}`, 2))}
          />}
          {!!secondTeamFlags?.length && <RowValue
            title={<TeamField source="id" record={{ id: teamIds?.[1] }} addLabel={false} allowEmpty link={false} variant="inherit" />}
            value={secondTeamFlags.map(flag => translate(`resources.games.labels.flags.${flag.name}`, 2))}
          />}
        </tbody>
      </table>
    }

    case 'gameStarted':
    case 'scoresheetDisapproved':
    case 'scoresheetCertified':
    case 'scoresheetDecertified':
    case 'scoresheetReset':
      return null;

    case 'goalScored':
    case 'goalEdited': {
      const types = [
        event.isPowerplay && translate('resources.gameEvents.labels.goal.powerplay'),
        event.isShorthanded && translate('resources.gameEvents.labels.goal.shorthanded'),
        event.isEmptyNet && translate('resources.gameEvents.labels.goal.empty_net'),
        event.isPenaltyShot && translate('resources.gameEvents.labels.goal.penalty_shot'),
        event.isOwnGoal && translate('resources.gameEvents.labels.goal.own_goal')
      ].filter(Boolean)
      return <table>
        <tbody>
          { event.goalId && <RowValue title={translate('resources.gameEvents.labels.goal.id')} value={event.goalId} addLink />}
          <RowValue title={translate('resources.gameEvents.labels.game_time')} value={displayTime(event.gameTime, sport, true, false)} />
          <RowValue title={translate('resources.teams.name', 1)} value={<TeamField source="event.teamId" addLabel={false} allowEmpty />} />
          <RowValue title={translate('resources.gameEvents.labels.goal.scorer')} value={<ParticipantField source="event.participantId" addLabel={false} allowEmpty />} />
          { event.assistIds.length
            ? <RowValue title={translate('resources.gameEvents.labels.goal.assists')} value={event.assistIds.map((_, index) =>
              <ParticipantField source={`event.assistIds[${index}]`} addLabel={false} allowEmpty />
            )} />
            : null}
          { !!types.length && <RowValue title={translate('resources.gameEvents.labels.goal.type')} value={types.join(' / ')} />}
        </tbody>
      </table>
    }

    case 'goalDeleted': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.gameEvents.labels.goal.id')} value={event.goalId} addLink />
        </tbody>
      </table>
    }

    case 'penaltyStarted':
    case 'penaltyEdited': {
      return <table>
        <tbody>
          { event.penaltyId && <RowValue title={translate('resources.gameEvents.labels.penalty.id')} value={event.penaltyId} addLink /> }
          <RowValue title={translate('resources.gameEvents.labels.game_time')} value={displayTime(event.gameTime, sport, true, false)} />
          <RowValue title={translate('resources.teams.name', 1)} value={<TeamField source="event.teamId" addLabel={false} allowEmpty />} />
          <RowValue title={translate('resources.participants.name', 1)} value={<ParticipantField source="event.participantId" addLabel={false} allowEmpty />} />
          <RowValue
            title={translate('resources.gameEvents.labels.penalty.infraction')}
            value={event?.infractionId ? <InfractionField source="event.infractionId" /> : `${startCase(event.infraction)} - ${startCase(event.duration)}`}
          />
          { event.servedById && <RowValue title={translate('resources.gameEvents.labels.penalty.served_by')} value={<ParticipantField source="event.servedById" addLabel={false} allowEmpty />} />}
          { event.startTime && <RowValue title={translate('resources.gameEvents.labels.start_time')} value={displayTime(event.startTime, sport, true, false)} />}
          { event.endTime && <RowValue title={translate('resources.gameEvents.labels.end_time')} value={displayTime(event.endTime, sport, true, false)} />}
        </tbody>
      </table>
    }

    case 'penaltyEnded': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.gameEvents.labels.penalty.id')} value={event.penaltyId} addLink />
          <RowValue title={translate('resources.gameEvents.labels.end_time')} value={displayTime(event.endTime, sport, true, false)} />
        </tbody>
      </table>
    }

    case 'penaltyDeleted': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.gameEvents.labels.penalty.id')} value={event.penaltyId} addLink />
        </tbody>
      </table>
    }

    case 'goalieChanged':
    case 'goalieChangeEdited': {
      return <table>
        <tbody>
          { event.goalieChangeId && <RowValue title={translate('resources.gameEvents.labels.goalie_change.id')} value={event.goalieChangeId} addLink /> }
          <RowValue title={translate('resources.teams.name', 1)} value={<TeamField source="event.teamId" addLabel={false} allowEmpty />} />
          <RowValue title={translate('resources.gameEvents.labels.game_time')} value={displayTime(event.gameTime, sport, true, false)} />
          <RowValue title={translate('resources.gameEvents.fields.eventType')} value={event.onParticipantId ? translate('resources.gameEvents.labels.goalie_change.new_goalie') : translate('resources.gameEvents.labels.goal.empty_net')} />
          {event.onParticipantId && <RowValue title={translate('resources.gameEvents.labels.goalie_change.goalie')} value={<ParticipantField source="event.onParticipantId" addLabel={false} allowEmpty />} />}
        </tbody>
      </table>
    }

    case 'goalieChangeDeleted': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.gameEvents.labels.goalie_change.id')} value={event.goalieChangeId} addLink />
        </tbody>
      </table>
    }

    case 'goalieShotsUpdated': {
      return <table>
        <thead>
          <tr>
            <th>{translate('resources.gameEvents.labels.goalie_change.goalie')}</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>OT</th>
          </tr>
        </thead>
        <tbody>
          {event.goalies.map(goalie => <RecordContextProvider value={goalie}>
            <tr>
              <td><ParticipantField source="participantId" addLabel={false} allowEmpty /></td>
              <td>{goalie.totals[0] || '-'}</td>
              <td>{goalie.totals[1] || '-'}</td>
              <td>{goalie.totals[2] || '-'}</td>
              <td>{goalie.totals[3] || '-'}</td>
            </tr>
          </RecordContextProvider>)}
        </tbody>
      </table>
    }

    case 'notesUpdated': {
      return <table>
        <tbody className={classes.verticalAlignTop}>
          {event.notes && <RowValue
            title={<span className={classes.verticalAlignTop}>{translate('resources.scoresheets.labels.scoresheet_notes')}</span>}
            value={<span className={classes.text}>{event.notes}</span>}
          />}
          {event.adminNotes && <RowValue
            title={translate('resources.scoresheets.labels.admin_notes')}
            value={<span className={classes.text}>{event.adminNotes}</span>}
          />}
        </tbody>
      </table>
    }

    case 'scoreUpdated': {
      return <table>
        <tbody>
          {Object.keys(event.score || {}).map(key =>
            <RowValue
              title={<TeamField source="id" record={{ id: key }} addLabel={false} allowEmpty link={false} variant="inherit" />}
              value={event.score[key]}
            />
          )}
        </tbody>
      </table>
    }

    case 'gameEnded': {
      return <table>
        <tbody>
          <RowValue title={translate('resources.gameEvents.labels.end_time')} value={displayTime(event.gameTime, sport, true, false)} />
        </tbody>
      </table>
    }

    case 'scoresheetApproved': {
      return <>
        {event?.emails && <table>
          <tbody>
            <RowValue title={translate('resources.gameEvents.labels.emails')} value={event.emails} />
          </tbody>
        </table>}
        {(event?.scorekeeper || event?.timekeeper || event?.officials?.length) && <table>
          <thead>
            <tr>
              <th>{translate('resources.participants.name', 1)}</th>
              <th>{translate('resources.gameEvents.labels.signature')}</th>
            </tr>
          </thead>
          <tbody>
            {event?.scorekeeper && <tr>
              <Value value={`${event.scorekeeper.name} (${translate('resources.gameEvents.labels.scorekeeper')})`} />
              <td><Signature signature={event.scorekeeper.signature} /></td>
            </tr>}
            {event?.timekeeper && <tr>
              <Value value={`${event.timekeeper.name} (${translate('resources.gameEvents.labels.timekeeper')})`} />
              <td><Signature signature={event.timekeeper.signature} /></td>
            </tr>}
            {event.officials?.map((official, index) => <tr>
              <Value value={official.participantId
                ? <ParticipantField source={`event.officials[${index}].participantId`} addLabel={false} allowEmpty />
                : official.officialId
              } />
              <td><Signature signature={official.signature} /></td>
            </tr>)}
          </tbody>
        </table>}
      </>
    }

    case 'gameForfeited': {
      const { homeTeamId, awayTeamId } = game || {}
      return <table>
        <tbody>
          {event?.teamId == -1
            ? <RowValue title={translate('resources.teams.name', 1)} value={translate('resources.games.values.status.DoubleForfeit')} />
            : <RowValue title={translate('resources.teams.name', 1)} value={<TeamField source="event.teamId" addLabel={false} allowEmpty />} />
          }
          <RowValue title={translate('resources.gameEvents.labels.homeScore')} value={event.score?.[homeTeamId]} />
          <RowValue title={translate('resources.gameEvents.labels.awayScore')} value={event.score?.[awayTeamId]} />
          <p style={{ whiteSpace: 'pre-line', maxWidth: 700 }}>{event.notes}</p>
        </tbody>
      </table>
    }
    case 'throwsInningsUpdated': {
      const members = event.throwsInnings.map(member => <ThrowsInnings member={member} key={member.participantId} />)

      return <>
        <TeamField source="event.teamId" addLabel={false} allowEmpty />
        <table>
          <thead>
            <tr>
              <th>{translate('resources.members.fields.participantId')}</th>
              <th>{translate('components.throws_innings.labels.throws')}</th>
              <th>{translate('components.throws_innings.labels.innings')}</th>
            </tr>
          </thead>
          <tbody>
            {members}
          </tbody>
        </table>
      </>
    }

    default:
      return <pre>
        {JSON.stringify(event, null, '  ')}
      </pre>
  }
}

const systemEvents = ['gameCreated', 'gameRescheduled', 'gameUpdated', 'gameDeleted', 'assignSettingsUpdated', 'gameForfeited']
const User = () => {
  const event = useRecordContext();
  const translate = useTranslate();
  if (!event.meta.userId) {
    if (systemEvents.includes(event.eventType)) return translate('resources.gameEvents.labels.system');
    return translate('resources.gameEvents.labels.scorekeeper');
  }
  return <AccountField source="meta.userId" addLabel={false} />
}

const MetaField = (props) => {
  const translate = useTranslate()
  const event = useRecordContext(props)
  const { timezone } = props.game;
  const { timestamp, deviceId, transactionId } = event.meta;

  const offset = moment(timestamp).diff(event.timestamp);
  const delayed = offset > 30 * 1000; // took >30s to upload
  const skewed = offset < 60 * 1000 * -5; // time is >5m in the future?
  const duration = moment.duration(offset);

  const canDelete = isAuthorized(event, 'gameEvents', 'remove');
  return <>
    <table id={`id-${event.id}`}>
      <tbody>
        <RowValue title={translate('resources.gameEvents.labels.event_id')} value={event.id} />
        <Row title={translate('resources.gameEvents.labels.account')} value={<User />} />
        <RowValue title={translate('resources.gameEvents.labels.device')} value={deviceId} />
        {transactionId && <RowValue title={translate('resources.gameEvents.labels.transaction')} value={transactionId} />}
        <Row title={translate('resources.gameEvents.labels.created')} value={<Timestamp timestamp={event.timestamp} timezone={timezone} />} />
        {(delayed || skewed) && <Row title={translate('resources.gameEvents.labels.received')} value={<Timestamp timestamp={timestamp} timezone={timezone} />} />}
        {delayed && <tr style={{ fontWeight: 'bold', color: 'red' }}><td colSpan={2} title={duration.toISOString()}>{translate('resources.gameEvents.messages.offline_for_duration', { duration: duration.humanize() })}</td></tr>}
        {skewed && <tr style={{ fontWeight: 'bold', color: 'red' }}><td colSpan={2} title={duration.toISOString()}>{translate('resources.gameEvents.messages.time_skew_duration', { duration: duration.humanize() })}</td></tr>}
      </tbody>
    </table>
    {canDelete && <DeleteButton resource="gameEvents" redirect={false} />}
  </>
}

export const GameEventGrid = props => {
  const game = useRecordContext();
  return <Datagrid {...props}>
    <EventTypeField source="eventType" sortable={false} />
    <EventField source="event" sortable={false} game={game} />
    <MetaField source="meta" sortable={false} game={game} />
  </Datagrid>;
}
