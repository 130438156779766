/* eslint-disable import/namespace */
import * as FeaturesFlag from "./featureFlags.js";

/** @typedef {typeof import('./featureFlags')} FeatureFlagType */

export const FF_CATEGORIES = (() => {
  /** @type {{ [category: string]: Array<FeatureFlagType[keyof FeatureFlagType] | { id:FeatureFlagType[keyof FeatureFlagType]; description: string; }>}} */
  const out = {
    application: [
      {
        id: FeaturesFlag.FF_VERSION_MISMATCH,
        description: 'Banner when version between front-end and back-end is not matching.',
      },
      {
        id: FeaturesFlag.FF_FORETHOUGHT,
        description: 'AI agent for customer support (Spordle Assistant).',
      }
    ],
    account_management: [
      {
        id: FeaturesFlag.FF_SSO,
        description: 'Single Sign On with MyAccount.',
      },
      {
        id: FeaturesFlag.FF_ACCOUNTS,
        description: 'Display of "Accounts" tab on the left panel menu.',
      },
    ],
    scheduling: [
      {
        id: FeaturesFlag.FF_DRAFT_GAMES,
        description: 'Ability to use the Scheduler and see Draft Games.',
      },
      {
        id: FeaturesFlag.FF_LEGACY_DRAFTS_DISABLED,
        description: 'Disable ability to use the deprecated Scheduler and see the old drafts.',
      },
      {
        id: FeaturesFlag.FF_TOURNAMENTS,
        description: 'Display of "Tournaments" tab on the left panel menu.',
      },
      {
        id: FeaturesFlag.FF_CUPS,
        description: 'Display of "Cups" tab on the left panel menu.',
      },
      {
        id: FeaturesFlag.FF_SCHEDULE_MENU,
        description: 'Display of "Schedules" tab on the left panel menu.',
      },
      {
        id: FeaturesFlag.FF_BRACKETS,
        description: 'Ability to add brackets to a schedule of type Playoffs, Tournament or Cup.',
      },
      {
        id: FeaturesFlag.FF_ENFORCE_SCHEDULE_OFFICE,
        description: 'Enfore the creation of schedules only in schedule offices (League, Tournament and Cup).',
      },
      {
        id: FeaturesFlag.FF_DRAFT_LIMIT_BYES,
        description: 'Ability to limit the amount of bye rounds when generating games using "Single Rotation".',
      },
      {
        id: FeaturesFlag.FF_DRAFT_DEFAULT_ROUNDS,
        description: 'Defaults to "Total Rounds" instead of "Games Per Team" when generating games.',
      },
      {
        id: FeaturesFlag.FF_DRAFT_DEFAULT_SLOTS,
        description: 'Defaults to using "Home Slots" instead of "Weekdays" when generating games.',
      },
      {
        id: FeaturesFlag.FF_SEQUENCES,
        description: 'Ability to use sequences in schedules.',
      },
      {
        id: FeaturesFlag.FF_SCHEDULE_TEAMS_ORDER,
        description: 'Ability to edit the order of teams in schedules. Order is used when generating games and have no impact on stats.',
      },
      {
        id: FeaturesFlag.FF_SCHEDULE_TEAMS_DISABLE_ADD_SYNCED,
        description: 'Disable the ability to add teams in a schedule when a team is synced from SID. Internal users will still have access to add teams.',
      },
      {
        id: FeaturesFlag.FF_CROSS_SCHEDULING,
        description: 'Allow cross scheduling between groups and schedules.',
      },
      {
        id: FeaturesFlag.FF_TEAM_SHORT_NAMES,
        description: 'Allow the display of team short names in the scheduling matrix and scheduler calendar. Short name will only display if the team has a short name available.',
      },
    ],
    officiating: [
      {
        id: FeaturesFlag.FF_SCOREKEEPERS,
        description: 'Allow the official position "Scorekeeper".',
      },
      {
        id: FeaturesFlag.FF_TIMEKEEPERS,
        description: 'Allow the official position "Timekeeper".',
      },
      {
        id: FeaturesFlag.FF_OFFICIALS,
        description: 'Allow the official position "Official" or "4th Referee" in Soccer.',
      },
      {
        id: FeaturesFlag.FF_ADDITIONAL_LINESPERSONS,
        description: 'Allow the official position "Video Judge" & "Other Off-Ice Official" (Hockey), "Assistants 2" & "Assistants 3" (Soccer), or "2nd Base Umpire" & "3rd Base Umpire" (Baseball).',
      },
      {
        id: FeaturesFlag.FF_GRADES,
        description: 'Allow the use of "Grades" for official qualifications.',
      },
      {
        id: FeaturesFlag.FF_OFFICIAL_HIDE_ASSIGNERS,
        description: 'Hide the "My Assigners" in the availability settings of an official. The "My Assigners" is used for officials to be able to make themself available to officiate games for a specific association.',
      },
      {
        id: FeaturesFlag.FF_DISABLE_ADD_QUALIFICATIONS,
        description: 'Disable the ability to add qualifications to a participant when they are synced from SID. Internal users will still have access to add qualifications.',
      }
    ],
    scorekeeping: [
      {
        id: FeaturesFlag.FF_SCOREKEEPING_APP,
        description: 'Ability to go to the Scorekeeping Application from a game.',
      },
      {
        id: FeaturesFlag.FF_PERIODS,
        description: 'Office setting to set default period length for newly created schedules. This feature is currently only supported for Hockey.',
      },
      {
        id: FeaturesFlag.FF_PENALTY_SERVED_BY,
        description: 'Allow the entry of "served by" on a penalty.',
      },
      {
        id: FeaturesFlag.FF_PENALTY_START_END,
        description: 'Allow the entry of a start time on a penalty.',
      },
      {
        id: FeaturesFlag.FF_PENALTY_FILL_START,
        description: 'Automatically fill the start time when entering a game time on a penalty.',
      },
      {
        id: FeaturesFlag.FF_DOUBLE_FORFEIT,
        description: 'Allow forfeiting from both teams. Double forfeiting results in a loss for both teams.',
      },
      {
        id: FeaturesFlag.FF_PHOTOS,
        description: 'Allow access to the players photo carousel slider on a lineup ("Photos" button).',
      },
      {
        id: FeaturesFlag.FF_PRINT_LINEUP,
        description: 'Ability to print the lineup sheet. This feature is currently only supported for Soccer.',
      },
      {
        id: FeaturesFlag.FF_BATTERS,
        description: 'Ability to mark a player as a "Batter" and adding a batting order in a lineup.',
      },
      {
        id: FeaturesFlag.FF_PITCHERS,
        description: 'Allow the entry of throws and innings pitched and applying pitching limits.',
      },
      {
        id: FeaturesFlag.FF_GOALIES,
        description: 'Allow the entry of goalie shots against.',
      },
      {
        id: FeaturesFlag.FF_REQUIRE_LINEUPS,
        description: 'Both lineups need to be approved before starting to scorekeep a game.',
      },
      {
        id: FeaturesFlag.FF_REQUIRE_GAME_START,
        description: 'Scorekeeper must click start game in order to start scorekeeping.',
      },
      {
        id: FeaturesFlag.FF_REQUIRE_TEAMS,
        description: 'Only teams are required on a game in order to scorekeep. The lineups does not need to be approved and the game does not need to be manually started.',
      },
      {
        id: FeaturesFlag.FF_APPROVAL_SIGNATURE,
        description: 'Require official signature when approving a game.',
      },
      {
        id: FeaturesFlag.FF_HIDE_INFRACTION_NAME,
        description: 'Show only the rule name without the infraction name when displaying a penalty. Ex. used in Soccer to avoid showing "Yellow Card - Yellow Card".',
      },
      {
        id: FeaturesFlag.FF_SPORTSMANSHIP,
        description: 'Allow calculation of sportsmanship points when calculating stats. This feature only supports specific leagues: "PCAHA" and "VIAHA".',
      },
      {
        id: FeaturesFlag.FF_RESTRICT_LINEUP_ENTRY,
        description: 'Lineup cannot be entered until the prior game is completed.',
      },
    ],
    sanction_management: [
      {
        id: FeaturesFlag.FF_SUSPENSIONS_MENU,
        description: 'Display of "Suspensions" tab on the left panel menu.',
      },
    ],
    legacy_public_sites: [
      {
        id: FeaturesFlag.FF_CMS,
        description: 'Display of "Posts", "Events", and "Pages" tab on the left panel menu. This is the legacy CMS that is used for the legacy hisports sites. Ex. an event is a publication on the legacy site.',
      },
    ],
  };
  !out.unclassified && void (out.unclassified = []);
  const allFeaturesIncludedOnCategories = Object.keys(out).flatMap(key => out[key]).map(e => e.id || e);
  out.unclassified = Object.keys(FeaturesFlag)
    .filter(key => !allFeaturesIncludedOnCategories.includes(FeaturesFlag[key]) && !allFeaturesIncludedOnCategories.includes(FeaturesFlag[key].id))
    .map(key => FeaturesFlag[key]);
  if (!out.unclassified.length) {
    delete out.unclassified;
  }
  return out;
})();
