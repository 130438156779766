import React from 'react';
import { SimpleForm, SaveButton, useTranslate } from 'react-admin';
import { Grid, Typography } from '@material-ui/core';

import { FF_PITCHERS } from '@hisports/common/featureFlags';

import { useFlag } from '../../../http';
import Toolbar from "../../../common/ra/Toolbar";
import { CategoryInput } from '../../categories/CategoryInput';
import { LimitsInput } from '../LimitsInput';
import { PitcherLimits } from '../pitcherLimits';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const validate = (values, translate) => {
  const errors = {};

  if (!values.categoryIds?.length > 0) {
    errors.categoryIds = 'ra.validation.required'
  }

  return errors;
}

export const VarianceForm = props => {
  const translate = useTranslate();
  const isEnabled = useFlag();

  const toolbar = <Toolbar>
    <SaveButton />
  </Toolbar>

  return <SimpleForm toolbar={toolbar} validate={(values) => validate(values, translate)} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <CategoryInput source="categoryIds" multiple {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <LimitsInput source="lineupLimits" />
      </Grid>

      {isEnabled(FF_PITCHERS) && <Grid item xs={12}>
        <Typography variant="subtitle2">{translate('resources.rulebooks.labels.pitcher_limits')}</Typography>
        <PitcherLimits source="pitcherLimits" />
      </Grid>}
    </Grid>
  </SimpleForm>
}
