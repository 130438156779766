import moment from 'moment';
import React from 'react';
import { useField, useFormState } from 'react-final-form';

import { InlineDateTimeInput, DateInput } from './DateInput';

export const EndTimeInput = ({ maxHours, ...props }) => {
  const { name } = useField();
  const { values } = useFormState();

  const { startTime, timezone } = values;
  const localTimezone = moment.tz.guess();
  const start = moment.tz(startTime, timezone || localTimezone).format('YYYY-MM-DD HH:mm');

  const maxDate = (maxHours && start) ? moment.tz(start, timezone || localTimezone).add(maxHours, 'hours').format('YYYY-MM-DD HH:mm') : undefined;
  const openTo = (start && !values[name]) ? 'hours' : 'date';

  return <InlineDateTimeInput
    initialFocusedDate={start}
    minDate={start}
    maxDate={maxDate}
    disabled={!start}
    openTo={openTo}
    {...props}
  />
}

export const EndDateInput = ({ maxHours, ...props }) => {
  const { values: { startDate, startTime } } = useFormState();
  const start = startDate || startTime
  const maxDate = (maxHours && start) ? moment(start).add(maxHours, 'hours').toISOString(true) : undefined;

  return <DateInput
    initialFocusedDate={start}
    minDate={start}
    maxDate={maxDate}
    disabled={!start}
    {...props}
  />
}
