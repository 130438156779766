import React from 'react';
import { useTranslate } from 'react-admin';
import { useMediaQuery } from '@material-ui/core'
import { useSelector } from 'react-redux';

import { List, Show, Create, Edit, Pagination } from '../../common/ra';
import { isAuthorized } from '../../common/Authorize';
import ResourceTitle from '../../common/ResourceTitle';
import { useSeason } from '../../common/inputs/SeasonSelector';

import { useEventSort } from '../events/EventViewSettings';
import { EventView } from '../events/EventView';

import { GameFilters } from './GameFilters';
import { GameFilterList } from './GameFilterList';
import { GameActions } from './GameActions';
import { GameShowLayout } from './GameShow';
import { GameForm } from './GameForm';
import { GameBulkActions, canManageAssignments, canCertify, canChangeStatus } from './GameBulkActions';
import GamesExporter from './GamesExporter';

const isSelectable = (statuses, games) => id => {
  const gameStatus = statuses[id];
  const game = games[id];
  return canManageAssignments(gameStatus) || canCertify(gameStatus) || canChangeStatus(game);
}

export const GameList = props => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const statuses = useSelector(state => state.admin.resources.gameStatuses.data)
  const games = useSelector(state => state.admin.resources.games.data)
  const sort = useEventSort({ groupByDate: true });
  const seasonId = useSeason();

  const isRowSelectable = isSelectable(statuses, games);
  const showBulkActions = isAuthorized(props.permissions, 'games', 'bulkActions') && Object.keys(statuses).some(isRowSelectable)

  return <List {...props}
    exporter={GamesExporter}
    aside={!isSmall && <GameFilterList disableCalendar />}
    component={!isMobile ? "div" : undefined}
    filters={isSmall && <GameFilters />}
    filterDefaultValues={{ seasonId }}
    pagination={<Pagination isMobile={isMobile} rowsPerPageOptions={[25, 50, 100]} />}
    sort={sort}
    bulkActionButtons={showBulkActions ? <GameBulkActions /> : false}
    resetFilters={isSmall}
  >
    <EventView
      groupByDate
      isRowSelectable={isRowSelectable}
      disableCalendar
    />
  </List>
}

export const GameShow = props => {
  const translate = useTranslate();
  return <Show {...props}
    actions={<GameActions />}
    title={<ResourceTitle title={record => translate('resources.games.labels.game_number', { number: record.number || '' })} />}
  >
    <GameShowLayout />
  </Show>
}

export const GameCreate = props => {
  const translate = useTranslate();
  return <Create
    actions={<GameActions />}
    title={<ResourceTitle title={record => translate('resources.games.labels.add')} />}
    {...props}
  >
    <GameForm redirect="show" />
  </Create>
}

export const GameEdit = props => {
  const translate = useTranslate();
  return <Edit
    actions={<GameActions />}
    title={<ResourceTitle title={record => translate('resources.games.labels.editing_game_number', { number: record.number || '' })} />}
    {...props}
  >
    <GameForm redirect="show" />
  </Edit>
}
