import React, { Fragment, useEffect, useState } from 'react';
import { RecordContextProvider, Datagrid, BulkDeleteButton, useDataProvider, SimpleForm, useList, ListContextProvider, NumberField, useTranslate, Pagination, ReferenceField, useListContext, RadioButtonGroupInput, useRecordContext } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, useMediaQuery, makeStyles } from '@material-ui/core';
import { useFormState, useForm } from 'react-final-form';
import { orderBy } from 'lodash';

import { dedupeBy } from '@hisports/parsers';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';

import { ScheduleInput } from '../ScheduleInput';
import { ScheduleGroupInput } from '../../groups/GroupInput';
import { OfficeInput } from '../../offices/OfficeInput';
import { CategoryField } from '../../categories/CategoryField';
import { TeamField } from '../../teams/TeamField';
import { GroupField } from '../../groups/GroupField';
import { CategoryInput } from '../../categories/CategoryInput';

const useStyles = makeStyles(theme => ({
  categoryInput: {
    marginTop: theme.spacing(1)
  },
  addGroupInput: {
    marginTop: theme.spacing(1.5)
  },
  MuiDialogContent: {
    paddingTop: 0,
  },
  filterGroupInput: {
    marginTop: theme.spacing(3)
  }
}))

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
  helperText: false,
}

const validate = values => {
  const errors = {}

  return errors;
}

const getSchedule = (dataProvider, scheduleId) => {
  if (!scheduleId) return Promise.resolve([]);
  return dataProvider.getOne('schedules', { id: scheduleId })
    .then(res => res.data)
}

const getOfficeTeams = (dataProvider, officeId, seasonId, categoryId) => {
  if (!officeId) return Promise.resolve([])
  return dataProvider.getList('teams', {
    filter: { effectiveOffices: officeId, seasonId, categoryId, _scope: 'Tenant' },
    sort: { field: ['category.order', 'name'], order: ['ASC', 'ASC'] },
    pagination: { page: 1, perPage: 99999 },
  }).then(res => res.data)
}

const getScheduleTeams = (dataProvider, scheduleId, groupId) => {
  if (!scheduleId) return Promise.resolve([]);

  const filter = { scheduleId }

  if (groupId) {
    filter.groupId = groupId
  }

  return dataProvider.getList('scheduleteams', {
    filter,
    sort: { field: ['group.name', 'team.name'], order: ['ASC', 'ASC'] },
    pagination: { page: 1, perPage: 99999 },
  }).then(res => res.data)
    .then(teams => dedupeBy('teamId', teams));
}

const getTeamScheduleStats = (dataProvider, scheduleId, teamIds = []) => {
  if (!scheduleId || !teamIds.length) return Promise.resolve([]);
  return dataProvider.getManyReference('teamstats', {
    target: 'schedules',
    id: scheduleId,
    filter: { teamId: { inq: teamIds } },
    sort: { field: 'ranking', order: 'DESC' },
    pagination: { page: 1, perPage: 99999 },
  }).then(res => res.data)
    .then(teams => dedupeBy('id', teams));
}

const TeamCategoryField = props => {
  return <ReferenceField basePath="/teams" resource="teams" reference="teams" link={false} {...props}>
    <CategoryField {...props} source="categoryId" />
  </ReferenceField>
}

const TeamGrid = props => {
  const { ids = [], data } = useListContext()

  const showRank = !ids?.every(id => {
    const rank = data?.[id]?.rank;
    return !rank || rank === 1;
  });
  const showGroup = ids?.some(id => data?.[id]?.groupId != null);

  return <Datagrid size="medium" {...props}>
    {showRank && <NumberField source="rank" label="resources.scheduleteams.labels.rank" />}
    <TeamField source="id" link={false} includeId="full" label="resources.scheduleteams.fields.teamId" />
    <TeamCategoryField source="id" label="resources.scheduleteams.fields.categoryId" />
    {showGroup && <GroupField source="groupId" />}
  </Datagrid>
}

const TeamList = ({ initialValues, scheduleIdForGroupInput }) => {
  const dataProvider = useDataProvider();
  const { values: { source, scheduleId, officeId, categoryId, filterGroupId } } = useFormState();

  const { change } = useForm();
  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ teams, setTeams ] = useState([]);

  const listContext = useList({
    data: teams,
    ids: teams.map(team => parseInt(team.id)),
    loaded,
    loading,
    page: 1,
    perPage: 100,
  })

  useEffect(() => {
    change('teamIds', listContext?.selectedIds)
  }, [ listContext?.selectedIds, change ])

  useEffect(() => {
    change('filterGroupId', null)
  }, [ scheduleId, change ])

  useEffect(() => {
    async function fetchTeamList() {
      setLoading(true);
      listContext.setPage(1);

      let teamList = []
      if (source === 'Schedule' && scheduleId) {
        // if schedule selected, fetch teams from schedule
        teamList = await getScheduleTeams(dataProvider, scheduleId, filterGroupId);
        teamList = teamList.map(team => ({ id: team.teamId, groupId: team.groupId }))

        // get team schedule stats to sort on ranking
        const teamschedulestats = await getTeamScheduleStats(dataProvider, scheduleId, teamList.map(team => team.id)).catch(e => {});
        if (teamschedulestats?.length) {
          teamList = teamList.map(team => {
            const stats = teamschedulestats.find(stats => stats.teamId === team.id && team.groupId == stats.groupId)
            team.rank = stats?.ranking;
            return team;
          })
        }
      } else if (source === 'Office' && officeId) {
        // if office selected, fetch teams from selected office
        let schedule;
        if (initialValues?.scheduleId || scheduleIdForGroupInput) {
          schedule = await getSchedule(dataProvider, initialValues?.scheduleId || scheduleIdForGroupInput)
        }
        teamList = await getOfficeTeams(dataProvider, officeId || schedule?.officeId, schedule?.seasonId, categoryId)
      }

      const selectedTeams = (listContext?.selectedIds || []).map(selectedId => teams.find(team => team.id === selectedId)).filter(Boolean);

      // combine the selected teams and import teams
      teamList = dedupeBy('id', [...selectedTeams, ...teamList])
      // order by rank
      teamList = orderBy(teamList, ['rank'], ['asc']);

      // set new list of teams
      setTeams(teamList)
      setLoaded(true)
      setLoading(false)
    }

    fetchTeamList()
  }, [ source, scheduleId, officeId, categoryId, dataProvider, filterGroupId ]) // eslint-disable-line react-hooks/exhaustive-deps

  return <ListContextProvider value={listContext}>
    <TeamGrid
      {...listContext}
      hasBulkActions={<BulkDeleteButton />}
      rowClick="toggleSelection"
      isRowSelectable={() => true}
    />
    <Pagination rowsPerPageOptions={[]} />
  </ListContextProvider>
}

const OfficeOrScheduleInput = props => {
  const { values: { source } } = useFormState();
  return <>
    {source === 'Office' && <OfficeInput source="officeId" label="resources.offices.name" filter={{ _scope: 'Tenant' }} {...props} />}
    {source === 'Schedule' && <ScheduleInput source="scheduleId" label="resources.schedules.name" {...props} />}
  </>
}

const FormBody = ({ initialValues, scheduleIdForGroupInput, ...props }) => {
  const translate = useTranslate();
  const { values: { source, scheduleId } } = useFormState();
  const classes = useStyles()

  return <>
    {scheduleIdForGroupInput && <ScheduleGroupInput source="groupId" className={classes.addGroupInput} scheduleId={scheduleIdForGroupInput} showNone={translate('ra.message.no_group')} label="resources.schedules.labels.add_to_group" {...inputProps} />}
    <RadioButtonGroupInput source="source" label="ra.action.search_in" choices={[
      { id: 'Office', name: translate('resources.offices.name', 1) },
      { id: 'Schedule', name: translate('resources.schedules.name', 1) },
    ]} />
    <OfficeOrScheduleInput {...inputProps} />
    {source === 'Schedule' && scheduleId && <ScheduleGroupInput source="filterGroupId" defaultValue={null} className={classes.filterGroupInput} scheduleId={scheduleId} showNone={translate('resources.groups.messages.all_groups')} label="ra.message.filter_by_group" {...inputProps} />}
    {source === 'Office' && <div className={classes.categoryInput}>
      <CategoryInput source="categoryId" label="resources.schedules.fields.categoryId" {...inputProps} />
    </div>}
    <TeamList initialValues={initialValues} scheduleIdForGroupInput={scheduleIdForGroupInput} />
  </>
}

const TeamImportForm = ({ onSubmit, initialValues, scheduleIdForGroupInput, ...props }) => {
  return <SimpleForm save={onSubmit} initialValues={initialValues} {...props} {...inputProps}>
    <FormBody initialValues={initialValues} scheduleIdForGroupInput={scheduleIdForGroupInput} />
  </SimpleForm>
}

export const TeamImportDialog = ({ title, isOpen, onSubmit, onClose, initialValues, scheduleIdForGroupInput, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { officeId } = useRecordContext();
  const classes = useStyles();

  return <Dialog open={isOpen} maxWidth="md" fullWidth fullScreen={fullScreen}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent className={classes.MuiDialogContent}>
      <RecordContextProvider value={{}}>
        <TeamImportForm
          validate={validate}
          onSubmit={onSubmit}
          onClose={onClose}
          component={Fragment}
          toolbar={<DialogFormToolbar submitLabel="ra.action.add" cancelLabel="ra.action.cancel" onCancel={onClose} />}
          initialValues={{ officeId, source: 'Office', ...initialValues }}
          scheduleIdForGroupInput={scheduleIdForGroupInput}
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}
