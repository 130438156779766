import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import { Error as ErrorIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { captureException, lastEventId, showReportDialog } from '@sentry/browser'

import { Title, TitlePropType, useTranslate } from 'react-admin';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '1em',
      },
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.5,
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: '2em',
      height: '2em',
      marginRight: '0.5em',
    },
    panel: {
      marginTop: '1em',
    },
    panelDetails: {
      whiteSpace: 'pre-wrap',
    },
    toolbar: {
      marginTop: '2em',
    },
  }),
  { name: 'RaError' }
);

const Error = ({
  error,
  errorInfo,
  classes: classesOverride,
  className,
  title,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const translate = useTranslate();

  useEffect(() => {
    const exception = captureException(error, { extra: errorInfo });
    console.error(error); /* eslint-disable-line no-console */
    console.log(`Captured exception ${exception}`) /* eslint-disable-line no-console */
  }, [ error, errorInfo ])

  const eventId = lastEventId();
  return (
    <>
      <Title defaultTitle={title} />
      <div className={classnames(classes.container, className)} {...rest}>
        <h1 className={classes.title} role="alert">
          <ErrorIcon className={classes.icon} />
          {translate('ra.page.error')}
        </h1>
        <div>{translate('ra.message.error')}</div>

        {process.env.NODE_ENV !== 'production' && (
          <Accordion className={classes.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {translate('ra.message.details')}
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetails}>
              <div>
                <h3>{translate(error.toString())}</h3>
                {errorInfo.componentStack}
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        <div className={classes.toolbar}>
          {eventId && <Button
            variant="contained"
            onClick={() => showReportDialog()}
          >
            {translate('ra.message.report_details')}
          </Button>}
        </div>

        <pre>{eventId}</pre>
      </div>
    </>
  );
};

Error.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.object.isRequired,
  errorInfo: PropTypes.object,
  title: TitlePropType,
};

export default Error;
