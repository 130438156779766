import React from 'react';
import { LinearProgress, RecordContextProvider, useTranslate } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import Row from '../../common/cards/TableRow';
import FunctionField from '../../common/fields/FunctionField';

import { useBranchSettings } from '../branchOfficeSettings';

const renderCertifiedOfficialChanges = (settings, source, resource, translate) => {
  const { certifiedOfficialChanges } = settings;

  return certifiedOfficialChanges
    ? translate('resources.branchOfficeSettings.fields.certifiedOfficialChanges_on')
    : translate('resources.branchOfficeSettings.fields.certifiedOfficialChanges_off');
}

export const BranchOfficeSettingsField = ({ officeId }) => {
  const translate = useTranslate();
  const { data: branchOfficeSettings, loading, error } = useBranchSettings(officeId);

  if (loading) return <LinearProgress />;
  if (error) return translate('resources.branchOfficeSettings.messages.load_error');

  return (
    <RecordContextProvider value={branchOfficeSettings}>
      <Table>
        <TableBody>
          <Row label={translate("resources.branchOfficeSettings.fields.certifiedOfficialChanges")}>
            <FunctionField source="certifiedOfficialChanges" render={renderCertifiedOfficialChanges} />
          </Row>
        </TableBody>
      </Table>
    </RecordContextProvider>
  );
};
