import React from 'react';
import { Redirect } from 'react-router';

import { DraftRoutes } from '../resources/drafts';

import RegisterRoutes from './Register'
import MyProfileRoutes from './MyProfile';
import ResetPasswordRoutes from './ResetPassword';
import ReportRoutes from './Reports';

const ArenaRoutes = [
  <Redirect from="/arenas/:id/edit" to="/surfaces/:id/edit" />,
  <Redirect from="/arenas/:id/show" to="/surfaces/:id/show" />,
  <Redirect from="/arenas/:id" to="/surfaces/:id" />,
  <Redirect from="/arenas" to="/surfaces" />,
]

export const customRoutes = [
  ...RegisterRoutes,
  ...ResetPasswordRoutes,
  ...MyProfileRoutes,
  ...DraftRoutes,
  ...ReportRoutes,
  ...ArenaRoutes,
]
