import React, { Fragment, useState } from 'react';
import { ArrayInput, SimpleForm, useDataProvider, useQueryWithStore, useRefresh, useNotify, useRecordContext, GET_LIST, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, ListItemIcon, ListItemText } from '@material-ui/core';
import { useFieldArray } from 'react-final-form-arrays';

import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import SimpleFormIterator, { TransitionProps } from '../../common/ra/SimpleFormIterator';
import { isAuthorized } from '../../common/Authorize';
import { ActionMenuItem } from '../../common/ActionMenu';

import { OfficeIcon } from '../icons';
import { OfficeInput } from '../offices/OfficeInput'
import { OfficeField } from '../offices/OfficeField'

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const Office = props => {
  const [ parent, source ] = props.source.split('.')
  const { fields } = useFieldArray(parent)

  if (fields.value && fields.value.id) {
    return <OfficeField {...props} record={fields.value} source={source} basePath="/offices" />
  }
  return <OfficeInput {...props} filter={{ _scope: 'Tenant', type: { nin: ['League', 'Tournament', 'Cup', 'Zone'] } }} />
}

const MembersForm = ({ editable, ...props }) =>
  <SimpleForm resource="offices" {...props} {...inputProps}>
    <ArrayInput source="members" label="" {...inputProps}>
      <SimpleFormIterator disableReordering disableAdd={!editable} disableRemove={!editable} TransitionProps={TransitionProps} {...inputProps}>
        <Office source="officeId" label="resources.offices.name" options={{ label: null }} {...inputProps} />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>

const OfficeMembersModal = ({ isOpen, handleClose, ...props }) => {
  const office = useRecordContext(props);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const canEditMembers = isAuthorized(office, 'officemembers', 'create')

  const { data: members = [], loaded } = useQueryWithStore({
    type: GET_LIST,
    resource: 'officemembers',
    payload: {
      filter: { parentId: office.id },
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'office.name', order: 'ASC' },
    }
  })

  const onSubmit = ({ members: offices }) => {
    const officeIds = offices
      .map(member => member && member.officeId)
      .filter(Boolean)

    const added = officeIds.filter(officeId => {
      return !members.some(member => member.officeId === officeId);
    })
    const additions = added.map(officeId => {
      return dataProvider.create("officemembers", { data: {
        officeId,
        parentId: office.id,
      } })
    })

    const removed = members.filter(member => {
      return !officeIds.includes(member.officeId)
    })
    const removals = removed.map(member => {
      return dataProvider.delete("officemembers", { id: member.id })
    })

    const changes = [...additions, ...removals];
    return Promise.all(changes)
      .then(() => {
        handleClose()
        refresh()
      }).catch(error => {
        notify(error.message, 'error');
      })
  }

  const initialValues = {
    members,
  }
  return <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.offices.labels.members')}</DialogTitle>
    <DialogContent>
      {loaded && <MembersForm initialValues={initialValues} save={onSubmit} component={Fragment} editable={canEditMembers} toolbar={
        <DialogFormToolbar submitLabel="ra.action.save" cancelLabel="ra.action.close" onCancel={handleClose} hideSubmit={!canEditMembers} />
      } />}
    </DialogContent>
  </Dialog>
}

export default ({ onClick, handleClose, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  const handleClick = () => {
    onClick();
    setOpen(true);
  }
  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><OfficeIcon fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.offices.labels.members')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <OfficeMembersModal isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />}
  </>
}
