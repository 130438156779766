import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core'
import { UnarchiveOutlined } from '@material-ui/icons'

import { LazyDialog } from '../../../../common/LazyDialog';

const Uploader = React.lazy(() => import('./Uploader'))

export default ({ officeId, size, variant }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<UnarchiveOutlined />} onClick={() => setOpen(true)}>
      {translate('resources.qualifications.labels.import_grades')}
    </Button>
    <LazyDialog>
      {isOpen && <Uploader officeId={officeId} isOpen={isOpen} onClose={() => setOpen(false)} />}
    </LazyDialog>
  </>
}
