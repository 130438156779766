import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { ContactForm } from './ContactForm';

const AddContactDialog = props =>
  <CreateResourceDialog label="resources.contacts.actions.add" resource="contacts" basePath="/contacts" {...props}>
    <ContactForm />
  </CreateResourceDialog>

const EditContactDialog = props =>
  <EditResourceDialog label="resources.contacts.labels.edit" resource="contacts" basePath="/contacts" {...props}>
    <ContactForm />
  </EditResourceDialog>

export const AddContactButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate()
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.contacts.actions.add')}</Button>
    <AddContactDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const ContactDatagrid = props => <DialogDatagrid dialog={EditContactDialog} {...props} />
