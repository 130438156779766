import React from 'react';
import { FunctionField, ReferenceField, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';

import { isAuthorized } from '../../common/Authorize';
import TimeRangeField from '../../common/fields/TimeRangeField';
import DateField from '../../common/fields/DateField';

import { CategoryField } from '../categories/CategoryField';
import { SurfaceField } from '../surfaces/SurfaceField';
import { SeasonField } from "../seasons/SeasonField";

const GameDateField = ({ showTime, ...props }) => {
  if (!showTime) return <DateField source="date" showLocal {...props} />
  return <TimeRangeField startSource="startTime" endSource="endTime" showDate />
}

export const GameField = ({ includeSeason = false, includeDate = false, includeTime = false, includeCategory = false, includeSurface = false, link = 'show', variant = 'body2', className, ...props }) => {
  const record = useRecordContext(props);

  if (isAuthorized(record, 'games', 'show')) {
    if (link === 'scoresheet') {
      link = (record, reference) => `/${reference}/${record[props.source]}/show/scoresheet`
    }
  } else {
    link = false;
  }

  return <ReferenceField basePath="/games" resource="games" reference="games" link={link} {...props}>
    <FunctionField source="name" render={game => {
      const inline = [
        includeSeason === 'inline' && <SeasonField source="seasonId" variant="inherit" />,
        includeDate === 'inline' && <GameDateField showTime={includeTime} />,
        includeCategory === 'inline' && <CategoryField source="categoryId" variant="inherit" />,
        includeSurface === 'inline' && <SurfaceField source="arenaId" variant="inherit" link={false} />,
      ].filter(Boolean).flatMap(e => [e, ' • ']).slice(0, -1)
      const full = [
        includeSeason === 'full' && <SeasonField source="seasonId" variant="inherit" />,
        includeDate === 'full' && <GameDateField showTime={includeTime} />,
        includeCategory === 'full' && <CategoryField source="categoryId" variant="inherit" />,
        includeSurface === 'full' && <SurfaceField source="arenaId" variant="inherit" link={false} />,
      ].filter(Boolean).flatMap(e => [e, ' • ']).slice(0, -1)
      return <>
        <Typography variant={variant} display="inline">{game.number}</Typography>
        {inline.length > 0 && <span><Typography variant={variant} display="inline" color="textSecondary"> {inline}</Typography></span>}
        {full.length > 0 && <div><Typography variant={variant} display="inline" color="textSecondary">{full}</Typography></div>}
      </>
    }} variant={variant} className={className} />
  </ReferenceField>
}

GameField.defaultProps = {
  addLabel: true,
}
