import React from 'react';
import { ReferenceInput, ReferenceArrayInput, useTranslate } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'

import { LeagueIcon, OfficeIcon } from '../icons';

const filterOffice = name => ({ name })

export const OfficeSuggestion = ({ suggestion: office, query, isHighlighted, ...props }) => {
  const translate = useTranslate();
  const type = translate(`resources.offices.values.type.${office.type}`, { _: office.type, smart_count: 1 })
  return <AutocompleteSuggestion
    icon={['League', 'Tournament', 'Cup'].includes(office.type) ? <LeagueIcon /> : <OfficeIcon />}
    primary={office.name}
    secondary={!office.HCRId ? type : `${type} - HCR ID: ${office.HCRId}`}
  />
}

export const OfficeInput = ({ helperText, multiple, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  const translate = useTranslate();
  return <Input
    reference="offices"
    filterToQuery={filterOffice}
    sort={{
      field: ['type', 'name'],
      order: ['DESC', 'ASC'],
    }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText="name"
      groupBy={({ type }) => translate(`resources.offices.values.type.${type}`)}
      suggestionComponent={OfficeSuggestion}
      filterKeys={['name', 'HCRId']}
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
