import React from 'react';
import { useTranslate } from 'react-admin';
import {
  List,
  ListItem,
  Grid,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

import { getPeriods, getSummary } from '@hisports/scoresheet/src/selectors';
import { displayTime } from '@hisports/scoresheet/src/util';
import { FF_PERIODS } from '@hisports/common/featureFlags';

import { useFlag, useSport } from '../../../http';

import { SummaryItem } from './SummaryItem';
import { useMeta, useScoresheet } from '../ScoresheetContext';


const useStyles = makeStyles(theme => ({
  list: {
    maxHeight: theme.spacing(75),
    overflow: 'auto',
  },
  divider: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  }
}))

const PeriodDivider = ({ gameTime }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const sport = useSport();
  const periodSettings = useScoresheet(scoresheet => getPeriods(scoresheet)) || [];
  const periodSetting = periodSettings.find(setting => setting.period === gameTime.period);
  const periodLength = displayTime(periodSetting, sport, false, false);

  return <ListItem dense>
    <Grid container alignItems="center" spacing={3} className={classes.divider}>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          {translate(`resources.gameEvents.labels.periods.${gameTime.period}`)} {`(${periodLength})`}
        </Typography>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  </ListItem>
}

export const SummaryList = props => {
  const isEnabled = useFlag();
  const classes = useStyles();
  const { meta, game } = useMeta();
  const { scoringMode, penaltyMode } = meta?.policies || {};

  const sport = useSport();

  const events = useScoresheet(scoresheet => getSummary(scoresheet, meta.infractions, meta.types, sport, game.seasonId));

  return <List className={classes.list} dense>
    {events
      .filter(event => scoringMode !== 'Detailed' ? event.eventType !== 'goal' : true)
      .filter(event => penaltyMode !== 'Detailed' ? event.eventType !== 'penalty' : true)
      .map((event, i) => {
        const lastEvent = events?.[i-1];
        const addPeriodDivider = isEnabled(FF_PERIODS) && event?.gameTime?.period !== lastEvent?.gameTime?.period;

        return <>
          {addPeriodDivider && <PeriodDivider gameTime={event.gameTime} />}
          <SummaryItem
            key={event?.id}
            event={event}
            {...props}
          />
        </>
      })}
  </List>
}
