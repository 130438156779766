import React, { Fragment, useState } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../../common/dialogs/DialogDatagrid';
import { ResourceDialog } from '../../../common/dialogs/ResourceDialog';

import { DurationForm } from './DurationForm';

const AddDurationDialog = props =>
  <RecordContextProvider value={null}>
    <ResourceDialog label="resources.rulebooks.actions.add_duration" resource="rulebooks" basePath="/rulebooks" maxWidth="sm" {...props}>
      <DurationForm {...props} />
    </ResourceDialog>
  </RecordContextProvider>

const EditDurationDialog = ({ save, ...props }) =>
  <ResourceDialog label="resources.rulebooks.labels.edit_duration" resource="rulebooks" basePath="/rulebooks" maxWidth="sm" handleSubmit={save} {...props}>
    <DurationForm {...props} />
  </ResourceDialog>

export const AddDurationButton = ({ save, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  const handleSubmit = infraction => {
    save(infraction);
    setOpen(false);
  }
  return <>
    <Button color="primary" startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.rulebooks.actions.add_duration')}
    </Button>
    <AddDurationDialog isOpen={isOpen} handleClose={() => setOpen(false)} handleSubmit={handleSubmit} {...props} />
  </>
}

export const DurationDatagrid = props => <DialogDatagrid dialog={EditDurationDialog} {...props} />
