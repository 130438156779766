import React from "react"

import { History } from '@material-ui/icons';

import ListCard from "../../common/cards/ListCard";
import { SuspensionGrid } from "../scoresheet/suspension/SuspensionGrid";

export const ParticipantSuspensionsCard = ({ hideAddButton = true, rowOptions, ...props }) => {
  return <ListCard
    title="resources.suspensions.labels.participant_history"
    icon={<History />}
    reference="suspensions"
    target="participantId"
    sort={{ field: ['game.date'], order: ['DESC'] }}
    {...props}
  >
    <SuspensionGrid multipleGames hideParticipant hover={false} />
  </ListCard>
}
