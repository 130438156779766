import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';
import { useTranslate } from "react-admin";
import { SupervisorAccountOutlined } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  ssoChip: {
    color: theme.palette.lightBlue[500],
    borderColor: theme.palette.lightBlue[500],
    backgroundColor: theme.palette.lightBlue[50],
    marginLeft: theme.spacing(1)
  },
  ssoIcon: {
    color: theme.palette.lightBlue[500],
    marginLeft: '7px !important'
  }
}))

export const SMAChip = () => {
  const translate = useTranslate();
  const classes = useStyles();

  return <Chip
    className={classes.ssoChip}
    size="small"
    variant="outlined"
    label={translate('components.login.chip.sso')}
    icon={<SupervisorAccountOutlined className={classes.ssoIcon} />}
  />
}
