import React from 'react';
import { useRecordContext } from 'react-admin';
import { FontDownloadOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { InfractionAccumulationGrid } from '../../infractions/InfractionAccumulationGrid';
import { AddInfractionAccumulationButton } from '../../infractions/InfractionAccumulationModal';

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const canAddType = isAuthorized(office, 'infractionAccumulations', 'create');

  return <ListCard
    title="resources.infractionAccumulations.labels.card.title"
    subtitle="resources.infractionAccumulations.labels.card.subtitle"
    icon={<FontDownloadOutlined />}
    collapsable
    reference="infractionAccumulations"
    target="officeId"
    filter={{ seasonId }}
    sort={{
      field: ['name'],
      order: ['ASC'],
    }}
    addButton={canAddType && <AddInfractionAccumulationButton initialValues={{ officeId: office?.id, seasonId }} office={office} />}
  >
    <InfractionAccumulationGrid rowOptions={{ office }} office={office} />
  </ListCard>
}
