import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import SanctionMemberForm from './SanctionMemberForm';

const AddSanctionMemberDialog = ({ office, ...props }) =>
  <CreateResourceDialog label="resources.sanctionMembers.actions.add" resource="sanctionMembers" basePath="/sanctionMembers" {...props}>
    <SanctionMemberForm office={office} />
  </CreateResourceDialog>

const EditSanctionMemberDialog = ({ sanctionMembers, initialValues, office, ...props }) => {
  const sanctionMember = sanctionMembers.find(({ id }) => id === props.id);
  const ruleId = sanctionMember?.option?.rule?.id || sanctionMember?.infraction?.rule?.id;

  return <EditResourceDialog label="resources.sanctionMembers.labels.edit" resource="sanctionMembers" basePath="/sanctionMembers" initialValues={{ ...initialValues, ruleId }} {...props} >
    <SanctionMemberForm office={office} />
  </EditResourceDialog>
}


export const AddSanctionMemberButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.sanctionMembers.actions.add')}
    </Button>
    <AddSanctionMemberDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const SanctionMemberDatagrid = props => <DialogDatagrid dialog={EditSanctionMemberDialog} {...props} />
