import React from 'react';
import { SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import { SeasonInput } from '../../common/inputs/EnumInputs';
import Toolbar from '../../common/ra/Toolbar';

import { ParticipantInput } from '../participants/ParticipantInput';
import { OfficeInput } from '../offices/OfficeInput';
import { DivisionInput } from '../categories/DivisionInput';

const validate = values => {
  const errors = {};

  if (!values.participantId) errors.participantId = 'ra.validation.required'
  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.divisionId) errors.divisionId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'registrations',
  basePath: '/registrations',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export default props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <ParticipantInput source="participantId" filter={{ _scope: 'Tenant' }} disabled={props.initialValues?.id || props.initialValues?.participantId} {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SeasonInput source="seasonId" {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <DivisionInput source="divisionId" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="officeId" filter={{ type: 'Association' }} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
