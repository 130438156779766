import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import { Lock, LockOpen } from '@material-ui/icons';
import moment from 'moment-timezone';

import { TransactionViewSettingsProvider } from '../officialTransactions/TransactionViewSettings';
import PayPeriodCard from './cards/PayPeriodCard';
import OfficialTransactionsCard from './cards/TransactionsCard';
import ClosePeriodModal from './ClosePeriodModal';

const PayPeriodStatus = () => {
  const translate = useTranslate();
  const { isClosed, closedAt } = useRecordContext();

  const title = translate(`resources.payPeriods.alerts.${isClosed ? 'period_closed' : 'period_active'}`);
  const message = translate(`resources.payPeriods.alerts.${isClosed ? 'period_closed_message' : 'period_active_message'}`, { date: moment.utc(closedAt, 'YYYY-MM-DD').format('LL') });

  return <Grid item xs={12}>
    <Alert icon={isClosed ? <Lock /> : <LockOpen />} color={isClosed ? "warning" : "info"} action={!isClosed && <ClosePeriodModal />}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  </Grid>
}
export const PayPeriodShowLayout = props => {
  return <Grid container spacing={3}>
    <Grid item xs={12}>
      <PayPeriodCard />
    </Grid>
    <PayPeriodStatus />
    <Grid item xs={12}>
      <TransactionViewSettingsProvider>
        <OfficialTransactionsCard />
      </TransactionViewSettingsProvider>
    </Grid>
  </Grid>;
}
