import moment from 'moment-timezone';
import Papa from 'papaparse'

import { displayTime } from '@hisports/scoresheet/src/util';
import { FF_PENALTY_SERVED_BY, FF_PENALTY_START_END } from "@hisports/common/featureFlags";
import { translateApiProperty } from '@hisports/common';

import { appName } from '../../../flags';

export default (penalties, translate, sport, isEnabled, locale) => {
  const data = penalties.map(penalty => {
    const { team, game, participant, servedBy, gameTime = null, startTime = null, endTime = null, infraction } = penalty;

    const gameDate = game?.date ? moment.utc(game.date, 'YYYY-MM-DD').format('YYYY-MM-DD') : '-';
    let infractionName = translateApiProperty(infraction?.rule, 'name', locale)

    if (infraction?.code) {
      infractionName += ` - ${infraction?.code}`
    }

    return [
      participant?.firstName,
      participant?.lastName,
      participant?.HCRId,
      team?.name,
      team?.HCRId,
      game?.schedule?.name,
      game?.number,
      gameDate,
      infractionName,
      displayTime(gameTime, sport, true),
      isEnabled(FF_PENALTY_START_END) && displayTime(startTime, sport, true),
      isEnabled(FF_PENALTY_START_END) && displayTime(endTime, sport, true),
      isEnabled(FF_PENALTY_SERVED_BY) && servedBy?.fullName,
      isEnabled(FF_PENALTY_SERVED_BY) && servedBy?.HCRId,
    ].filter(field => field !== false)
  })

  const csv = Papa.unparse({
    fields: [
      translate('components.exporter.penalties_exporter.reports.summary.columns.first_name'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.last_name'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.member_id'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.team_name'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.team_id'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.schedule_name'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.game_number'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.game_date'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.infraction'),
      translate('components.exporter.penalties_exporter.reports.summary.columns.game_time'),
      isEnabled(FF_PENALTY_START_END) && translate('components.exporter.penalties_exporter.reports.summary.columns.start_time'),
      isEnabled(FF_PENALTY_START_END) && translate('components.exporter.penalties_exporter.reports.summary.columns.end_time'),
      isEnabled(FF_PENALTY_SERVED_BY) && translate('components.exporter.penalties_exporter.reports.summary.columns.served_by_name'),
      isEnabled(FF_PENALTY_SERVED_BY) && translate('components.exporter.penalties_exporter.reports.summary.columns.served_by_id'),
    ].filter(Boolean),
    data,
  })
  const fileName = `${appName} - ${translate('components.exporter.penalties_exporter.reports.summary.title')} - ${moment().format('YYYY-MM-DD HH:mm')}`;
  return { csv, fileName };
}
