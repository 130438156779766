import React from 'react';
import { sanitizeFieldRestProps, useLocale, useRecordContext } from "react-admin";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

export const TextLocaleField = props => {
  const { className, source, emptyText, ...rest } = props;

  const locale = useLocale();
  const record = useRecordContext(props);

  const defaultValue = get(record, source);
  const localeValue = get(record?.i18n?.[locale], source);
  const value = localeValue || defaultValue;

  return <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
    {value != null && typeof value !== 'string' ? JSON.stringify(value) : value || emptyText}
  </Typography>
}
