import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { ConstraintsForm } from './ConstraintsForm';

const AddConstraintDialog = ({ office, ...props }) => {
  return <CreateResourceDialog label="resources.schedulingConstraints.actions.add_constraint" resource="schedulingConstraints" basePath="/schedulingConstraints" {...props}>
    <ConstraintsForm office={office} />
  </CreateResourceDialog>
}

const EditConstraintDialog = ({ office, ...props }) =>
  <EditResourceDialog label="resources.schedulingConstraints.actions.edit_constraint" resource="schedulingConstraints" basePath="/schedulingConstraints" {...props}>
    <ConstraintsForm office={office} />
  </EditResourceDialog>

export const AddConstraintButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate("resources.schedulingConstraints.actions.add_constraint")}</Button>
    <AddConstraintDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const ConstraintDatagrid = props => <DialogDatagrid dialog={EditConstraintDialog} {...props} />
