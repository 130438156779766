import { useState, useEffect } from 'react';
import { createInstance } from 'localforage';

const storage = createInstance({
  name: 'hisports',
  storeName: 'preferences',
})

export default (key, initialState) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ state, setState ] = useState(initialState)

  useEffect(() => {
    storage.getItem(key).then(saved => {
      if (saved != null) {
        setState(saved)
      }
      setLoaded(true);
    }).catch(e => {
      setLoaded(true)
    })
  }, [ key ])

  useEffect(() => {
    if (!loaded) return;
    storage.setItem(key, state).catch(e => {});
  }, [ key, state, loaded ])

  return [ state, setState, loaded ];
}
