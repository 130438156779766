import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import { apiClient } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { useRichTranslate } from '../../common/useRichTranslate';

export default ({ isOpen = false, contact, onClose }) => {
  const notify = useNotify();
  const translate = useRichTranslate();
  const [ sending, setSending ] = useState(false);
  if (!isOpen || !contact) return null;

  const canSendVerification = isAuthorized(contact, 'contacts', 'verify');
  const onClick = () => {
    if (!canSendVerification) return;

    setSending(true)
    apiClient(`/contacts/${contact.id}/sendVerification`, { method: 'POST' })
      .then(() => {
        onClose(true)
        notify('ra.message.verification_email');
      })
      .catch(err => {
        const errorMessage = translate(`resources.contacts.notifications.${err?.response?.data?.error?.name}`, { _: err?.response?.data?.error?.message || 'ra.page.error' })
        notify(errorMessage, 'error');
      })
      .finally(() => {
        setSending(false)
      })
  }

  const action = canSendVerification
    ? translate('resources.contacts.messages.already_received_email')
    : translate('resources.contacts.messages.only_participant_verify')

  return <Dialog
    open={isOpen}
    onClose={() => onClose()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    disableBackdropClick={canSendVerification}
  >
    <DialogTitle id="alert-dialog-title">{translate('ra.message.unverified')}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {translate('resources.contacts.messages.has_not_been_verified', { contact: <strong>{contact.value}</strong> })}
        <p>{translate('resources.contacts.messages.unverified_explain')}</p>

        <p>{action}</p>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)}>
        {translate('ra.action.close')}
      </Button>
      {canSendVerification && <Button onClick={onClick} color="primary" autoFocus disabled={sending}>
        {translate('ra.action.send_verification')}
      </Button>}
    </DialogActions>
  </Dialog>
}
