import React, { useRef } from 'react';
import { TextInput, BooleanInput, SimpleForm, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core'
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import createCalculator from 'final-form-calculate';

import { changeTimezone, isEmpty } from '@hisports/parsers';

import Toolbar from '../../common/ra/Toolbar';
import { InlineDateInput, InlineDateTimeInput, datePlaceholder, dateTimePlaceholder } from '../../common/inputs/DateInput';
import EditorInput from '../../common/inputs/EditorInput'

import { OfficeInput } from '../offices/OfficeInput';
import { TimezoneEnumInput } from '../../common/inputs/EnumInputs';

const validate = values => {
  const errors = {}

  if (!values.title) {
    errors.title = 'ra.validation.required';
  } else if (values.title.length > 80) {
    errors.title = 'ra.validation.title_too_long';
  }
  if (!values.content) errors.content = 'ra.validation.required';
  if (!values.startTime) errors.startTime = 'ra.validation.required';
  if (!values.officeId) errors.officeId = 'ra.validation.required';
  if (!values.timezone) errors.timezone = 'ra.validation.requred';

  return errors;
}

const inputProps = {
  resource: 'webevents',
  basePath: '/webevents',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const EventDateInput = ({ options, ...props }) => {
  const { values } = useFormState();
  const { allDay } = values;
  const Input = allDay ? InlineDateInput : InlineDateTimeInput;
  const dateOptions = {
    format: allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm',
    placeholder: allDay ? datePlaceholder : dateTimePlaceholder,
    ampm: false,
    ...options,
  }
  return <Input options={dateOptions} {...props} />
}

export const WebEventForm = props => {
  const translate = useTranslate()

  const offices = useSelector(store => store.admin.resources.offices.data);
  const officesRef = useRef({});
  officesRef.current = offices;

  const decorators = useRef([createCalculator({
    field: 'officeId',
    updates: {
      timezone: (officeId, values, prevValues) => {
        const office = officesRef.current[officeId];
        if (!office) return values.timezone;
        return office.timezone;
      },
      startTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.startTime || isEmpty(prevValues) || timezone === prevTimezone) return values.startTime;
        return changeTimezone(values.startTime, prevTimezone, timezone)
      },
      endTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.endTime || isEmpty(prevValues) || timezone === prevTimezone) return values.endTime;
        return changeTimezone(values.endTime, prevTimezone, timezone)
      },
    }
  })])

  return <SimpleForm toolbar={<Toolbar />} decorators={decorators.current} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <TextInput source="title" autoFocus {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <EventDateInput
          source="startTime"
          options={{ label: translate('resources.webevents.fields.startTime') }}
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <EventDateInput
          source="endTime"
          options={{ label: translate('resources.webevents.fields.endTime') }}
          helperText="ra.message.optional"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <BooleanInput source="allDay" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="location" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <EditorInput source="content" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <OfficeInput source="officeId" filter={{ type: { neq: 'Historical' } }} {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <TimezoneEnumInput source="timezone" disabled {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
