import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';

export const ImportStepper = ({ steps, activeStep }) =>
  <Stepper activeStep={activeStep}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
