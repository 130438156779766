import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";

import styles from "./FileViewer.module.scss";
import { useTranslate } from 'react-admin';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFFile = ({ image, pageCount, fileLoading, errorView, onDocumentLoadSuccess, onPreviewFail, containerRef, reactPinchPanZoom, resetScalePoints }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reactPinchPanZoom.centerView(undefined, 0), []);

  return (
    <Document
      file={image}
      onLoadSuccess={onDocumentLoadSuccess}
      renderMode="canvas"// Performant but somewhat blurrier than svg -> User can download to have full quality
      externalLinkTarget="_blank"// Links in pdf must not bail out of our website
      loading={fileLoading}
      onLoadError={() => {
        reactPinchPanZoom.setTransform(0, 0, reactPinchPanZoom.state.scale, 0);
        onPreviewFail();
      }}
      error={errorView}
      noData={<NoPdfPreview close={close} reactPinchPanZoom={reactPinchPanZoom} />}
    >
      {Array.from({ length: pageCount }, (_, index) => index + 1)
        .map((pageNumber) => (
          <Page
            key={pageNumber}
            pageNumber={pageNumber}
            className={`${pageNumber === 1 ? styles.PdfFirstPage : ''} ${styles.PdfPage}`}
            onLoadSuccess={pageNumber === 1 ? (e) => {
              const scaleValue = Math.min(1, containerRef.current.clientWidth / e.originalWidth);
              resetScalePoints(scaleValue);
              reactPinchPanZoom.setTransform(Math.max((containerRef.current.clientWidth - e.originalWidth) / 2, 0), Math.max((containerRef.current.clientHeight - e.originalHeight) / 2, 0), scaleValue, 0);
              reactPinchPanZoom.centerView();
            } : undefined}
            renderAnnotationLayer={false}
          />
        ))
      }
    </Document>
  );
};

const NoPdfPreview = ({ close, reactPinchPanZoom }) => {
  const translate = useTranslate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reactPinchPanZoom.centerView(undefined, 0), []);

  return (
    <div className={styles.NoPreview} role="status">
      <div className={"mdi mdi-file-hidden" `${styles.Icon}`} />
      <div className="font-bold h1 text-body">
        {translate('resources.schedules.labels.brackets_viewer.no_preview.no_data')}
      </div>
      <p className="mb-3">
        {translate('resources.schedules.labels.brackets_viewer.no_preview.text')}
      </p>
      <Button onClick={(e) => {
        e.stopPropagation(); close();
      }} color="dark" type="button">
        <span>{translate('resources.schedules.labels.brackets_viewer.close')}</span>
      </Button>
    </div>
  );
};

export default PDFFile;
