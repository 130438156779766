import React from 'react';
import { useTranslate } from 'react-admin';
import { TableRow, TableCell, styled } from '@material-ui/core';

const StyledTableRow = styled(TableRow)(({ theme, clickable }) => ({
  ...(clickable && {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
}));

export default ({ label, children, hidden = false, cellClass, onClick = null }) => {
  if (hidden) return null;
  const translate = useTranslate();

  const handleClick = (e) => {
    if (!onClick) return;
    onClick(e);
  }

  return <StyledTableRow onClick={onClick ? handleClick : undefined} clickable={!!onClick}>
    {label &&
    <TableCell component="th" scope="row" className={cellClass}>
      {translate(label, { _: label, smart_count: 1 })}
    </TableCell>}
    <TableCell colSpan={label ? 1 : 2} className={cellClass}>{children}</TableCell>
  </StyledTableRow>
}
