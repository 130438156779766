import React from 'react';
import { ReferenceInput, ReferenceArrayInput } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { SchemaIcon } from '../icons';

const filterSchemas = () => ({})

const getProperties = schema => {
  const properties = Object.keys(schema.schema.properties)
    .map(id => schema.schema.properties[id].displayOptions)

  properties.sort((a, b) => a.order - b.order);
  return properties.map(property => property.description).join(' / ')
}

const SchemaSuggestion = ({ suggestion: schema, query, isHighlighted, ...props }) => {
  if (!schema?.id) return null;
  return <AutocompleteSuggestion
    icon={<SchemaIcon />}
    primary={`${schema.name} (${schema.owner} v${schema.version})`}
    secondary={getProperties(schema)}
  />
}

export const SchemaInput = ({ allowEmpty, multiple, helperText, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="schemas"
    filterToQuery={filterSchemas}
    sort={{
      field: ['type', 'scope', 'owner'],
      order: ['ASC', 'ASC', 'ASC'],
    }}
    perPage={100}
    {...props}
  >
    <AutocompleteInput
      optionText={schema => `${schema.name} (${schema.owner})`}
      groupBy={schema => `${schema.type} ${schema.scope}`}
      suggestionComponent={SchemaSuggestion}
      multiple={multiple}
      helperText={helperText}
      resettable
      allowEmpty
    />
  </Input>
}
