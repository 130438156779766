import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { AddressGrid } from '../../addresses/AddressGrid';
import { AddAddressButton } from '../../addresses/AddressModal';

export default props => {
  const participant = useRecordContext(props);

  const showAddresses = isAuthorized(participant, 'addresses', 'list')
  const canAddAddress = isAuthorized(participant, 'addresses', 'create')

  if (!showAddresses) return null;
  return <ListCard
    title="resources.addresses.labels.card.title"
    reference="addresses"
    target="participants"
    sort={{
      field: ['isPrimary', 'type', 'label'],
      order: ['DESC', 'ASC', 'ASC'],
    }}
    addButton={canAddAddress && <AddAddressButton initialValues={{ participantId: participant?.id }} />}
  >
    <AddressGrid />
  </ListCard>
}
