import { Typography } from '@material-ui/core';
import React from 'react';
import { ReferenceField, useRecordContext, useTranslate } from 'react-admin';

import { FF_TEAM_SHORT_NAMES } from '@hisports/common/featureFlags';

import { isAuthorized } from '../../common/Authorize';
import FunctionField from '../../common/fields/FunctionField'
import { CategoryField } from '../categories/CategoryField';
import { SeasonField } from '../seasons/SeasonField';
import { useFlag } from '../../http';
import { OfficeField } from '../offices/OfficeField';

const isTBA = team => team?.HCRId?.startsWith('TBA')

export const InnerTeamField = ({ includeId = false, includeCategory = false, includeSeason = 'inline', includeOffice = false, variant = 'body2', showShortName = false, ...props }) =>
  <FunctionField render={team => {
    const isSpecial = team?.HCRId?.startsWith('TBA') || team?.HCRId?.startsWith('NDT')
    const inline = [
      includeSeason === 'inline' && <SeasonField source="seasonId" />,
      includeCategory === 'inline' && <CategoryField source="categoryId" variant="inherit" />,
      includeOffice === 'inline' && <OfficeField source="officeId" variant="inherit" />,
      includeId === 'inline' && team.HCRId,
    ].filter(Boolean).flatMap(e => [e, ' • ']).slice(0, -1)
    const full = [
      includeSeason === 'full' && <SeasonField source="seasonId" />,
      includeCategory === 'full' && <CategoryField source="categoryId" variant="inherit" />,
      includeOffice === 'full' && <OfficeField source="officeId" variant="inherit" />,
      includeId === 'full' && team.HCRId,
    ].filter(Boolean).flatMap(e => [e, ' • ']).slice(0, -1)
    return <>
      <Typography variant={variant} display="inline">{showShortName && team.shortName ? team.shortName : team.name}</Typography>
      {inline.length > 0 && !isSpecial && <span><Typography variant={variant} display="inline" color="textSecondary"> ({inline})</Typography></span>}
      {full.length > 0 && !isSpecial && <div><Typography variant={variant} display="inline" color="textSecondary">{full}</Typography></div>}
    </>
  }} variant={variant} {...props} />

export const TeamField = ({ className, authMethod = "show", includeId = false, includeCategory = false, includeSeason = false, link = 'show', variant = 'body2', showShortName = false, noneLabel = false, ...props }) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  const showLink = isAuthorized(record, 'teams', authMethod) ? link : false;
  const isEnabled = useFlag();

  return <ReferenceField basePath="/teams" resource="teams" reference="teams" emptyText={translate(noneLabel ? 'ra.message.none' : 'resources.games.labels.tbd')} link={showLink} {...props} >
    <InnerTeamField source="name" variant={variant} className={className} includeId={includeId} includeCategory={includeCategory} includeSeason={includeSeason} showShortName={isEnabled(FF_TEAM_SHORT_NAMES) && showShortName} />
  </ReferenceField>
}

TeamField.defaultProps = {
  addLabel: true,
}
