import React from 'react';
import { useRecordContext, useLocale, useTranslate } from 'react-admin';
import { Card, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Sports, AssignmentOutlined, Edit, People, Nfc } from '@material-ui/icons'

import { FF_GOALIES, FF_SCOREKEEPING_APP } from '@hisports/common/featureFlags';

import { useFlag } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';
import { ListItemLink } from '../../../common/cards/LinksCard';

const LinkItem = ({ icon, primary, to, href, onClick }) =>
  <ListItem button component="a" to={to} href={href} onClick={onClick}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={primary} />
  </ListItem>

export default ({ status, ...props }) => {
  const isEnabled = useFlag();
  const game = useRecordContext(props);
  const translate = useTranslate();
  const locale = useLocale();

  if (!game) return null;

  const { scorekeeping = false, assigning = false, scoringMode = 'Detailed', penaltyMode = 'Detailed' } = status || {};
  const reportUrl = `${process.env.PDF_URL}/game/${game.id}?locale=${locale}`
  const isGameComplete = game.isApproved;
  const hasTeam = game.homeTeamId || game.awayTeamId;

  const canEditScoresheet = scorekeeping && (scoringMode === 'Detailed' && penaltyMode === 'Detailed') && isEnabled(FF_SCOREKEEPING_APP) && isAuthorized(game, 'scoresheets', 'full');
  const canManageLineups = scorekeeping && hasTeam && isAuthorized(game, 'scoresheets', 'lineup');
  const canAssignOfficials = assigning && isAuthorized(game, 'games', 'assign');
  const canRequestAssignment = assigning && isAuthorized(game, 'games', 'request');
  const showOfficials = canAssignOfficials || canRequestAssignment
  const canEditShots = scorekeeping && isEnabled(FF_GOALIES) && isGameComplete && isAuthorized(game, 'games', 'shots');

  let officialLabel = translate('resources.games.labels.assignment.game_officials');
  if (canAssignOfficials) {
    officialLabel = translate('resources.games.labels.assignment.assign_officials');
  }

  const actions = [
    isGameComplete && <LinkItem href={reportUrl} icon={<AssignmentOutlined />} primary={translate('resources.games.labels.report')} target="_blank" />,
    canEditScoresheet && <LinkItem href={`/scoresheets/${game.id}/pregame`} icon={<Edit />} primary={translate('resources.games.labels.scorekeeping')} />,
    showOfficials && <ListItemLink to={`/games/${game.id}/show/officials`} icon={<Sports />} primary={officialLabel} />,
    canManageLineups && <ListItemLink to={`/games/${game.id}/show/scoresheet`} icon={<People />} primary={translate('resources.games.labels.team_lineups')} />,
    canEditShots && <ListItemLink to={`/games/${game.id}/show/scoresheet`} icon={<Nfc />} primary={translate('components.goalie_shots_against.name')} />,
  ].filter(Boolean);

  if (!canAssignOfficials && !canEditScoresheet && !canManageLineups && !isGameComplete) return null;
  return <Card>
    <List>
      {actions}
    </List>
  </Card>
}
