import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { v4 as uuid } from 'uuid';

import { VarianceGrid } from './VarianceGrid';
import { AddVarianceButton } from './VarianceModal';

import { ArrayGrid } from '../ArrayGrid';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const Variances = ({ source }) => {
  const { values: { variances } } = useFormState();
  const { mutators } = useForm();

  const onSave = variance => {
    if (!variance.id) {
      variance.id = uuid();
    }

    const index = variances?.findIndex(value => value.id === variance.id);
    if (index >= 0) {
      const { id, ...changes } = variance;
      const newVariance = Object.assign({}, variances[index], changes);
      mutators.update('variances', index, newVariance)
    } else {
      mutators.push('variances', variance);
    }
  }

  const onDelete = variance => {
    const index = variances?.findIndex(value => value.id === variance.id);
    mutators.remove('variances', index);
  }

  return <>
    <ArrayGrid source={source}>
      <VarianceGrid
        save={onSave}
        canDelete
        onDelete={onDelete}
        {...inputProps}
      />
    </ArrayGrid>
    <AddVarianceButton save={onSave} />
  </>
}
