import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Table, TableHead, TableCell, TableBody, TableRow, Grid, Button } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { styled } from '@material-ui/styles';

import { getGoaliesShots } from '@hisports/scoresheet/src/selectors';

import { useIsHomeFirst } from '../../../resources/events/EventViewSettings';
import { isAuthorized } from '../../../common/Authorize';
import InfoCard from '../../../common/cards/InfoCard';

import { useScoresheet } from '../ScoresheetContext';

import { GoalieShotsModal } from './GameShotsModal';
import { useSport } from '../../../http';

const Goalie = styled(TableCell)(({ theme }) => ({
  minWidth: theme.spacing(12),
  padding: theme.spacing(1),
}))

const Period = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: theme.spacing(10),
  padding: theme.spacing(1),
  '&:last-child': {
    paddingRight: theme.spacing(1),
  }
}))

const GoalieShots = ({ teamId }) => {
  const translate = useTranslate();
  const sport = useSport();
  const game = useRecordContext();
  const goalies = useScoresheet(scoresheet => getGoaliesShots(scoresheet, game, sport))

  return <Table selectable={false} fullWidth>
    <TableHead displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow>
        <Goalie>{translate('components.goalie_shots_against.labels.goalie')}</Goalie>
        <Period>{translate('components.goalie_shots_against.labels.periods.1')}</Period>
        <Period>{translate('components.goalie_shots_against.labels.periods.2')}</Period>
        <Period>{translate('components.goalie_shots_against.labels.periods.3')}</Period>
        <Period>{translate('components.goalie_shots_against.labels.periods.OT')}</Period>
      </TableRow>
    </TableHead>
    <TableBody displayRowCheckbox={false}>
      {goalies
        .filter(goalie => goalie.teamId === teamId)
        .map(goalie => {
          return <TableRow key={goalie.participantId}>
            <Goalie>{goalie.participant.fullName}</Goalie>
            <Period>
              {goalie?.totals?.[0] || '—'}
            </Period>
            <Period>
              {goalie?.totals?.[1] || '—'}
            </Period>
            <Period>
              {goalie?.totals?.[2] || '—'}
            </Period>
            <Period>
              {goalie?.totals?.[3] || '—'}
            </Period>
          </TableRow>
        }
        )}
    </TableBody>
  </Table>
}

const EditShotsButton = ({ game }) => {
  const translate = useTranslate();
  const [shotsOpen, setShotsOpen] = useState(false);

  return <>
    <Button onClick={() => setShotsOpen(true)} startIcon={<Create fontSize="small" />}>{translate('components.goalie_shots_against.labels.edit_shots')}</Button>
    <GoalieShotsModal game={game} open={shotsOpen} onClose={() => setShotsOpen(false)} />
  </>
}

export const GoalieShotsCard = ({ status }) => {
  const game = useRecordContext();
  const isHomeFirst = useIsHomeFirst()
  const canEditShots = status.scorekeeping && !game?.isCertified && isAuthorized(game, 'games', 'shots');

  return <InfoCard title="components.goalie_shots_against.name" actions={canEditShots && <EditShotsButton game={game} />}>
    <Grid container spacing={3} xs={12}>
      <Grid item xs={12} md={6}>
        <GoalieShots teamId={isHomeFirst ? game.homeTeamId : game.awayTeamId} />
      </Grid>
      <Grid item xs={12} md={6}>
        <GoalieShots teamId={isHomeFirst ? game.awayTeamId : game.homeTeamId} />
      </Grid>
    </Grid>
  </InfoCard>
}
