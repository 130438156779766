import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { TeamArenasGrid } from '../../teamArenas/TeamArenaGrid';
import { EditTeamArenasButton } from '../../teamArenas/TeamArenaModal';
import { SurfaceIcon } from '../../icons';

export default props => {
  const team = useRecordContext(props);

  const showSettings = isAuthorized(team, 'teamArenas', 'show');
  const canEditSettings = isAuthorized(team, 'teamArenas', 'edit');

  const addButton = canEditSettings && <EditTeamArenasButton />;

  if (!showSettings) return null;
  return <ListCard
    {...props}
    title="resources.teamArenas.labels.card.title"
    subtitle="resources.teamArenas.labels.card.subtitle"
    icon={<SurfaceIcon />}
    collapsable
    reference="teamArenas"
    target="teams"
    source="id"
    sort={{ field: 'order', order: 'ASC' }}
    addButton={addButton}
  >
    <TeamArenasGrid />
  </ListCard>
}
