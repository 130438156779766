import React from 'react';
import { useLocale, useRecordContext, useTranslate } from 'react-admin';
import { SportsOutlined } from '@material-ui/icons';

import { isLegacyRulebookSeason } from '@hisports/scoresheet/src/util';

import { useFlag, useSport } from '../../../http';
import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { GamePenaltyGrid } from '../../gamepenalties/GamePenaltyGrid';
import { ParticipantInput } from '../../participants/ParticipantInput';
import { TeamInput } from '../../teams/TeamInput';
import { CategoryInput } from '../../categories/CategoryInput';
import PenaltiesExporter from '../../gamepenalties/PenaltiesExporter';

export default ({ collapsale, ...props }) => {
  const translate = useTranslate();
  const office = useRecordContext(props);
  const seasonId = useSeason();
  const sport = useSport();
  const isEnabled = useFlag();
  const locale = useLocale();

  const isLegacy = isLegacyRulebookSeason(seasonId);

  const filters = [
    <ParticipantInput source="participantId" variant="outlined" />,
    <TeamInput source="teamId" variant="outlined" />,
    <CategoryInput source="game.categoryId" label="resources.games.fields.categoryId" variant="outlined" />,
  ]

  const actions = [
    <PenaltiesExporter target="officeId" sport={sport} isEnabled={isEnabled} locale={locale} />
  ]

  return <ListCard
    title={translate('resources.gamepenalties.name', 2)}
    icon={<SportsOutlined />}
    basePath="/gamepenalties"
    resource="gamepenalties"
    actions={actions}
    filter={{ _options: { includeMeta: isLegacy }, effectiveOffices: office?.id, 'game.seasonId': seasonId }}
    sort={{ field: ['game.date', 'participant.lastName', 'participant.firstName'], order: ['DESC', 'ASC', 'ASC'] }}
    filters={filters}
    {...props}
  >
    <GamePenaltyGrid seasonId={seasonId} multipleGames rowOptions={{ isRowSelectable: () => false }} />
  </ListCard>
}
