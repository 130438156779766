import React, { useState, useEffect, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { approveLineup, saveLineup } from '@hisports/scoresheet/src/actions';
import { getLineup, getLineupCounts, getMembers, getSummary, hasUnselectedSuspendedPlayers } from '@hisports/scoresheet/src/selectors';
import { isPlayer } from '@hisports/scoresheet/src/util';

import { useSport } from '../../../http';

import { useMeta, useScoresheet, useScoresheetDispatch, useScoresheetStore } from '../ScoresheetContext';
import { SignLineup } from './SignLineup';
import { useMemberValidationContext } from './MemberValidationContext';

const isWithinLimits = (limits, count) => {
  if (limits.min > 0 && count < limits.min) return false;
  if (limits.max > 0 && count > limits.max) return false;
  return true;
}

const isLineupValid = (lineup, counts, limits) => {
  if (!isWithinLimits(limits.players, counts.totalPlayers)) return false;
  if (!isWithinLimits(limits.skaters, counts.totalSkater)) return false;
  if (!isWithinLimits(limits.goalies, counts.G)) return false;
  if (!isWithinLimits(limits.staff, counts.totalStaff)) return false;

  const members = lineup?.members || [];
  const players = members.filter(member => isPlayer(member) && isEmpty(member?.suspension));

  if (players.some(member => !member.number)) return false;

  return true;
}

export const hasDuplicateBattingOrder = (lineup) => {
  const members = lineup?.members || [];
  const players = members.filter(isPlayer);
  const battings = players.map(player => player.battingOrder ? player.battingOrder : []);

  return battings.some((val, i) => battings.indexOf(val) !== i);
}

export const hasUnselectedMembersWithRelations = (scoresheet, team, gameSuspensions, infractions, types, sport, seasonId) => {
  const lineup = getLineup(scoresheet, { teamId: team.id })
  const events = getSummary(scoresheet, infractions, types, sport, seasonId)
  const members = getMembers(lineup, team, sport)

  return members?.some(member => {
    const hasEvents = events.some(event => event.participantId === member.participantId);
    const hasNewSuspension = gameSuspensions.some(event => event.participantId === member.participantId);
    const isInLineup = lineup?.members?.find(lineupMember => lineupMember.participantId === member.participantId);

    return (hasEvents || hasNewSuspension) && !isInLineup
  })
}

export const SaveLineup = ({ teamId, disabled }) => {
  const translate = useTranslate();
  const { game, meta } = useMeta();
  const { memberValidations, loading } = useMemberValidationContext();
  const ref = useRef();
  const history = useHistory();
  const dispatch = useScoresheetDispatch();
  const sport = useSport();
  const scoresheetStore = useScoresheetStore();
  const lineup = useScoresheet(scoresheet => getLineup(scoresheet, { teamId }))
  const counts = useScoresheet(scoresheet => getLineupCounts(scoresheet, { teamId, sport }))
  const gameSuspensions = useSelector(state => Object.values(state.admin.resources.suspensions.data).filter(suspension => suspension.gameId === game.id))
  const team = meta.teams[teamId];
  const isValid = isLineupValid(lineup, counts, meta.policies.lineupLimits);
  const invalidBattingOrder = hasDuplicateBattingOrder(lineup);
  const invalidLineupSelected = hasUnselectedSuspendedPlayers(scoresheetStore.getState(), team, memberValidations, sport);
  const invalidMemberUnselected = hasUnselectedMembersWithRelations(scoresheetStore.getState(), team, gameSuspensions, meta.infractions, meta.types, sport, game.seasonId)
  const version = useSelector(store => store.admin.ui.viewVersion)
  const [refreshed, setRefreshed] = useState(false)
  const [ changes, setChanges ] = useState(-1);

  useEffect(() => {
    if (version === 0) return;
    setRefreshed(true);
  }, [ version ])

  useEffect(() => {
    if (refreshed) {
      setRefreshed(false);
      setChanges(0);
      return;
    }

    setChanges(changes => ++changes);
  }, [ lineup ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const unblock = ref.current;
    if (changes === 0) {
      if (unblock) unblock();
      return;
    }
    ref.current = history.block(translate('ra.message.discard_changes'))
    return () => {
      if (unblock) unblock();
    }
  }, [ changes, history, translate ])

  const handleSave = (approval) => {
    if (changes) dispatch(saveLineup(game.id, teamId, lineup.members))
    if (approval) {
      dispatch(approveLineup(game.id, teamId, approval.participantId, approval.signature))
    }
    setChanges(-1)
  }

  return <>
    <SignLineup teamId={teamId} disabled={!isValid || loading} onSave={handleSave} />
    <Button color="primary" variant="outlined" disabled={!changes || invalidBattingOrder || invalidLineupSelected || invalidMemberUnselected || loading} onClick={() => handleSave()}>
      {translate('ra.action.save')}
    </Button>
  </>
}
