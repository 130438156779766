import React, { useState } from 'react';
import { InputHelperText } from 'react-admin';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment'

import { datePlaceholder } from '../DateInput';

const defaultOptions = {
  variant: 'outlined',
  showTodayButton: true,
  autoOk: true,
  format: 'YYYY-MM-DD',
  placeholder: datePlaceholder,
  minutesStep: 5,
  hideTabs: true,
}
const getOptions = options => Object.assign({}, defaultOptions, options);

const DatePicker = (props) => {
  const [ focused, setFocused ] = useState(false);
  const [ value, setValue ] = useState(null);

  const {
    name,
    label,
    error,
    helperText,
    className,
    variant = 'outlined',
    margin = 'dense',
    options,
    onChange
  } = props;

  const onDateChange = date => {
    // remove any timezone and truncate time
    const dateStr = moment(date).utc(true).format('YYYY-MM-DD');
    // adjust to selected timezone
    const dateISO = moment.tz(dateStr, 'YYYY-MM-DD', 'UTC').toISOString();

    setValue(dateISO);
    onChange(dateISO);
  }

  const onDateFocus = () => {
    setFocused(true);
  }

  const onDateBlur = (e) => {
    if (e.target.value == null && !value) {
      onDateChange(e.target.value);
    }
    setFocused(false);
  }

  const allOptions = getOptions(options);
  const inputValue = moment(value).isValid() ? moment.tz(value, 'UTC').format(allOptions.format || 'YYYY-MM-DD HH:mm') : null;

  return <KeyboardDatePicker
    {...props}
    name={name}
    label={label}
    inputVariant={variant}
    margin={margin}
    error={!!error}
    helperText={error || helperText ? (
      <InputHelperText
        touched={focused}
        error={error}
        helperText={error || helperText}
      />
    ) : null
    }
    className={className}
    value={value || null} // null avoids an error in rifm (no longer necessary in v4)
    inputValue={inputValue}
    onChange={onDateChange}
    onFocus={onDateFocus}
    onBlur={onDateBlur}
    InputLabelProps={{
      shrink: focused || !!value,
    }}
    {...allOptions}
  />
}

export default DatePicker;
