import { getPeriods } from './index.js';
import { byTeamId, byParticipantId, calculateEndTime, getInfractionType, getSeverity, isTimed, requiresIncidentReport, sortPenalties } from '../util/index.js';

/**
 * Get all penalties for a game with optional filters
 * @param {object} state
 * @param {Array} infractions
 * @param {Array|object} types
 * @param {string} sport
 * @param {string} seasonId
 * @param {object} [filters]
 * @param {number|string} [filters.teamId]
 * @param {number|string} [filters.participantId]
 * @param {string[]} [filters.infractionIds]
 * @param {string[]} [filters.optionIds]
 * @param {string[]} [filters.accumulationIds]
 * @param {boolean} [filters.hideEnded]
 * @param {boolean} [filters.hideNonTimed]
 * @param {boolean} [filters.requireIncidentReport]
 */
export const getPenalties = (state, infractions, types, sport, seasonId, {
  teamId = null,
  participantId = null,
  infractionIds = null,
  optionIds = null,
  accumulationIds = null,
  severities = null,
  hideEnded = false,
  hideNonTimed = false,
  requireIncidentReport = false
} = {}) => {
  if (!state || !state.penalties) return [];
  let penalties = [...state.penalties];

  penalties.forEach(penalty => {
    // calculate penalty end time if missing
    if (penalty.startTime && !penalty.endTime && types) {
      const infractionType = getInfractionType(penalty, infractions, types, seasonId)
      if (infractionType) {
        penalty.endTime = calculateEndTime(penalty.startTime, infractionType, penalty.period)
      }
    }

    // set the duration id as the infraction id if its missing
    if (!penalty.duration) {
      penalty.duration = penalty.infraction;
    }
  })

  // apply optional filters
  if (teamId) {
    penalties = penalties.filter(byTeamId(teamId));
  }
  if (participantId) {
    penalties = penalties.filter(byParticipantId(participantId));
  }
  if (infractionIds) {
    penalties = penalties.filter(penalty => infractionIds.includes(penalty.infractionId))
  }
  if (optionIds) {
    penalties = penalties.filter(penalty => optionIds.includes(penalty.optionId))
  }
  if (accumulationIds) {
    penalties = penalties.filter(penalty => optionIds.includes(penalty.accumulationId))
  }
  if (requireIncidentReport) {
    penalties = penalties.filter(penalty => {
      const infractionType = getInfractionType(penalty, infractions, types, seasonId);
      const severity = getSeverity(penalty, infractions, types, seasonId)
      return requiresIncidentReport(infractionType, severity)
    });
  }
  if (severities) {
    penalties = penalties.filter(penalty => {
      const severity = getSeverity(penalty, infractions, types, seasonId)
      return severities.includes(severity)
    });
  }
  if (hideEnded) {
    penalties = penalties.filter(penalty => !penalty.isEnded);
  }
  if (hideNonTimed) {
    penalties = penalties.filter(penalty => {
      const infractionType = getInfractionType(penalty, infractions, types, seasonId);
      return isTimed(infractionType)
    });
  }

  return sortPenalties(penalties, sport)
}

export const getPenaltyById = (state, infractions, types, sport, seasonId, { penaltyId }) => {
  const penalties = getPenalties(state, infractions, types, sport, seasonId);
  return penalties.find(penalty => penalty.id == penaltyId);
}

export const getRecalculatedPenalty = (state, infractions, types, sport, seasonId, { penaltyId, startTime }) => {
  const penalty = getPenaltyById(state, infractions, types, sport, seasonId, { penaltyId })
  if (!penalty) return;

  const periods = getPeriods(state);
  const infractionType = getInfractionType(penalty, infractions, types, seasonId)
  const endTime = calculateEndTime(startTime, infractionType, periods);
  return {
    ...penalty,
    startTime,
    endTime,
  }
}
