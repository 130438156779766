import React from 'react';
import { useRecordContext } from 'react-admin';
import { LayersOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { GroupGrid } from '../../groups/GroupGrid';
import { AddGroupButton } from '../../groups/GroupModal';

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const showGroups = isAuthorized(office, 'groups', 'list')
  const canAddGroups = isAuthorized(office, 'groups', 'create')

  if (!showGroups) return null;
  return <ListCard
    title="resources.groups.labels.card.title"
    subtitle="resources.groups.labels.card.subtitle"
    icon={<LayersOutlined />}
    collapsable
    reference="groups"
    target="offices"
    source="id"
    filter={{ seasonId }}
    sort={{ field: 'name', order: 'ASC' }}
    addButton={canAddGroups && <AddGroupButton initialValues={{ officeId: office?.id }} officeId={office?.id} />}
  >
    <GroupGrid />
  </ListCard>
}
