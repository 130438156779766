import React from 'react';


import { PitcherLimitsDatagrid } from './PitcherLimitsModal';
import FunctionField from '../../../common/fields/FunctionField';
import { isEmpty } from '@hisports/parsers';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  ul: {
    '& .column-categoryIds': {
      width: '25%',
    }
  }
})

const renderScheduleTypes = ({ scheduleTypes }, source, resource, translate) => {
  const output = [];

  scheduleTypes.forEach(value => {
    output.push(<div>{translate(`resources.rulebooks.values.pitcherLimits.scheduleTypes.${value}`)}</div>)
  })

  return output
}

const renderThrowsLimits = ({ throwsLimits }, source, resource, translate) => {
  if (isEmpty(throwsLimits)) return '-'
  const output = [];

  throwsLimits.forEach(value => {
    output.push(<div>
      {translate('resources.rulebooks.values.pitcherLimits.throwsLimits.between', { min: value.min, max: value.max, restingDays: value.restingDays })}
    </div>)
  })

  return output
}

const renderInningsPitchedLimits = ({ inningsPitchedLimits }, source, resource, translate) => {
  if (isEmpty(inningsPitchedLimits)) return '-'
  const output = [];

  inningsPitchedLimits.forEach(value => {
    if (isEmpty(value.startDate) && isEmpty(value.endDate)) {
      output.push(<div>
        {translate('resources.rulebooks.values.pitcherLimits.inningsPitchedLimits.always',
          { limitType: translate(`resources.rulebooks.values.pitcherLimits.limitType.${value.limitType}`), limit: value.limit })}
      </div>)
    } else if (!isEmpty(value.startDate) && isEmpty(value.endDate)) {
      output.push(<div>
        {translate('resources.rulebooks.values.pitcherLimits.inningsPitchedLimits.from',
          { limitType: translate(`resources.rulebooks.values.pitcherLimits.limitType.${value.limitType}`), limit: value.limit, startDate: moment.utc(value.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') })}
      </div>)
    } else if (!isEmpty(value.startDate) && !isEmpty(value.endDate)) {
      output.push(<div>
        {translate('resources.rulebooks.values.pitcherLimits.inningsPitchedLimits.between',
          { limitType: translate(`resources.rulebooks.values.pitcherLimits.limitType.${value.limitType}`), limit: value.limit, startDate: moment.utc(value.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), endDate: moment.utc(value.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') })}
      </div>)
    } else if (isEmpty(value.startDate) && !isEmpty(value.endDate)) {
      output.push(<div>
        {translate('resources.rulebooks.values.pitcherLimits.inningsPitchedLimits.until',
          { limitType: translate(`resources.rulebooks.values.pitcherLimits.limitType.${value.limitType}`), limit: value.limit, endDate: moment.utc(value.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') })}
      </div>)
    }
  })

  return output
}

const renderInningsPitchedRests = ({ inningsPitchedRests }, source, resource, translate) => {
  if (isEmpty(inningsPitchedRests)) return '-'
  const output = [];

  inningsPitchedRests.forEach(value => {
    output.push(<div>
      {translate('resources.rulebooks.values.pitcherLimits.inningsPitchedRests.between', { min: value.min, max: value.max, restingDays: value.restingDays })}
    </div>)
  })

  return output
}

export const PitcherLimitsGrid = props => {
  const classes = useStyles();

  return <PitcherLimitsDatagrid size="medium" classes={classes} {...props}>
    <FunctionField source="scheduleTypes" render={renderScheduleTypes} />
    <FunctionField source="throwsLimits" render={renderThrowsLimits} />
    <FunctionField source="inningsPitchedLimits" render={renderInningsPitchedLimits} />
    <FunctionField source="inningsPitchedRests" render={renderInningsPitchedRests} />
  </PitcherLimitsDatagrid>
}
