import { applyMiddleware } from 'redux'

import { createStore as createScoresheet } from '@hisports/scoresheet'
import requests from './requests';
import validate from './validate';
import addMeta from './addMeta';

export const createStore = (sport, seasonId, scoresheet) => {
  // middleware is run in ascending order of arguments
  const enhancer = applyMiddleware(
    addMeta(sport, seasonId),
    validate(sport),
    requests
  )
  return createScoresheet(sport, scoresheet, enhancer)
}
