import {
  PLAYER_POSITIONS,
  COACH_POSITIONS,
  STAFF_POSITIONS,
  GOALIE,
  REFEREE,
  LINESPERSON,
  LINESPERSON_TWO,
  LINESPERSON_THREE,
  OFFICIAL,
  SCOREKEEPER,
  TIMEKEEPER,
  SUPERVISOR,
} from '../constants.js';

export const hasPosition = position => member =>
  member && member.positions && member.positions.includes(position);

export const isPlayerPosition = position =>
  PLAYER_POSITIONS.includes(position);

export const isCoachPosition = position =>
  COACH_POSITIONS.includes(position);

export const isStaffPosition = position =>
  STAFF_POSITIONS.includes(position);

export const isPlayer = member =>
  member && member.positions && member.positions.some(isPlayerPosition);

export const isGoalie = hasPosition(GOALIE);

export const isStarterGoalie = member =>
  member && hasPosition(GOALIE)(member) && member.isStarter

export const isCoach = member =>
  member && member.positions && member.positions.some(isCoachPosition);

export const isStaff = member =>
  member && member.positions && member.positions.some(isStaffPosition);

export const isSkater = member =>
  isPlayer(member) && !isGoalie(member)

export const isReferee = official =>
  official && official.position === REFEREE

export const isLinesperson = official =>
  official && [LINESPERSON, LINESPERSON_TWO, LINESPERSON_THREE].includes(official.position)

export const isExtraOfficial = official =>
  official && official.position === OFFICIAL

export const isSupervisor = official =>
  official && official.position === SUPERVISOR

export const isScorekeeper = official =>
  official && official.position === SCOREKEEPER

export const isTimekeeper = official =>
  official && official.position === TIMEKEEPER

export const isKeeper = official =>
  isScorekeeper(official) || isTimekeeper(official)

export const isOfficial = official =>
  isReferee(official) || isLinesperson(official) || isSupervisor(official) || isExtraOfficial(official)

export const isAffiliate = member => member.isAffiliate

export const isSuspended = member => member.suspension != null;
export const isNotSuspended = member => member.suspension == null;

export const getMember = (members, participantId) => {
  if (!members || !participantId) return null;
  return Array.isArray(members) ? members.find(member => member.participantId === participantId) : members[participantId];
}

/**
* @param {object} i18n
* @param {string} i18n.bench
* @param {string} i18n.coach
*/
export const getMemberType = (member, i18n) => {
  if (isPlayer(member)) return member.number;
  if (isCoach(member)) return i18n.coach;
  return i18n.bench;
}

/**
* @param {object} i18n
* @param {string} i18n.bench
* @param {string} i18n.coach
*/
export const getMemberLabel = (members, participantId, i18n) => {
  const member = getMember(members, participantId);
  if (!member) return '';
  return `${member.participant.fullName} (${getMemberType(member, i18n)})`;
}

export const getStatus = (member, short = true) => {
  const status = [];
  if (!member) return [];
  if (member.isCaptain) status.push(short ? 'C' : 'Captain');
  if (member.isAlternate) status.push(short ? 'A' : 'Alternate');
  if (member.isAffiliate) status.push(short ? 'AP' : 'Affiliate');
  if (member.isOverage) status.push(short ? 'OA' : 'Over-age');
  if (member.isUnderage) status.push(short ? 'UA' : 'Under-age');
  if (member.isStarter) status.push(short ? 'S' : 'Starter');
  if (member.suspension) status.push(short ? 'SUS' : `Suspended${member.suspension.game ? ` (${member.suspension.game}/${member.suspension.total || '∞'})` : ''}`);
  if (member.isReleased) status.push(short ? 'Rls' : 'Released');
  return status.join(' / ');
}

export const getMemberPositionGroup = (members, participantId) => {
  const member = getMember(members, participantId);
  if (!member || !member.positions || !member.positions.length) return 'Player';
  return member.positions.some(isPlayerPosition) ? 'Player' : 'Staff'
}
