import React from 'react';
import { useRecordContext } from 'react-admin';
import { Grid } from '@material-ui/core';

import { useFlag, useScopes } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { TabbedShowLayout, Tab } from '../../common/ra/TabbedShowLayout';

import AvailabilitySettings from '../officials/AvailabilitySettings';
import AvailabilityCard from '../availabilities/AvailabilityCard';
import ReportsCard from '../reports/ReportsCard';

import ParticipantCard from './cards/ParticipantCard';
import ProfileCard from './cards/ProfileCard';
import ContactsCard from './cards/ContactsCard';
import AddressesCard from './cards/AddressesCard';
import NotificationsCard from './cards/NotificationsCard';
import TeamsCard from './cards/TeamsCard';
import EventsCard from './cards/EventsCard';
import QualificationsCard from './cards/QualificationsCard';
import ListsCard from './cards/ListsCard';
import ConflictsCard from './cards/ConflictsCard';
import RegistrationsCard from './cards/RegistrationsCard';
import RolesCard from './cards/RolesCard';
import AccountsCard from './cards/AccountsCard';
import AttributeValuesCard from './cards/AttributeValuesCard';
import SuspensionsCard from './cards/SuspensionsCard';
import { ParticipantStatusAlert, ParticipantStatusProvider } from './ParticipantStatusContext';
import { ParticipantAccountProvider } from './ParticipantAccountContext';
import TransactionsCard from './cards/TransactionsCard';
import GamePenaltiesCard from './cards/GamePenaltiesCard';
import ClaimsCard from './cards/ClaimsCard';

const SummaryTab = props => {
  const participant = useRecordContext();
  const scopes = useScopes();

  const showReports = isAuthorized(scopes, 'reports', 'list');

  return <Tab {...props}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ParticipantStatusAlert />
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <ParticipantCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <ContactsCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <TeamsCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <QualificationsCard />
      </Grid>
      <Grid item xs={12}>
        <NotificationsCard />
      </Grid>
      <Grid item xs={12}>
        <RolesCard />
      </Grid>
      <Grid item xs={12}>
        <AccountsCard />
      </Grid>
      {showReports && <Grid item xs={12}>
        <ReportsCard targetType="Participant" participantId={participant.id} />
      </Grid>}
    </Grid>
  </Tab>
}

const ProfileTab = props => {
  const participant = useRecordContext(props);
  if (!isAuthorized(participant, 'participants', 'profileTab')) return null;

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <ContactsCard />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AddressesCard />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProfileCard />
      </Grid>
    </Grid>
  </Tab>
}

const ScheduleTab = props => {
  const participant = useRecordContext(props);
  if (!isAuthorized(participant, 'games', 'list')) return null;

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TeamsCard />
      </Grid>
      <Grid item xs={12}>
        <EventsCard />
      </Grid>
    </Grid>
  </Tab>
}

const RegistrationsTab = props => {
  const participant = useRecordContext(props);
  const isEnabled = useFlag();

  if (!isAuthorized(participant, 'participants', 'registrationsTab')) return null;

  const showSuspensions = isAuthorized(participant, 'suspensions', 'show')
  const showGamePenalties = isAuthorized(participant, 'gamepenalties', 'show')

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RegistrationsCard />
      </Grid>
      {showGamePenalties && <Grid item xs={12}>
        <GamePenaltiesCard />
      </Grid>}
      {showSuspensions && <Grid item xs={12}>
        <SuspensionsCard />
      </Grid>}
    </Grid>
  </Tab>
}

const OfficiatingTab = props => {
  const participant = useRecordContext(props);
  if (!isAuthorized(participant, 'participants', 'officiatingTab')) return null;

  const canEditAvailabilities = isAuthorized(participant, 'officialAvailabilities', 'edit');
  const canViewTransactions = isAuthorized(participant, 'officialTransactions', 'list');
  const canViewClaims = isAuthorized(participant, 'officialClaims', 'list')

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <QualificationsCard />
      </Grid>
      <Grid item xs={12}>
        <AvailabilityCard settings={AvailabilitySettings} editable={canEditAvailabilities} />
      </Grid>
      {canViewTransactions && <Grid item xs={12}>
        <TransactionsCard />
      </Grid>}
      {canViewClaims && <Grid item xs={12}>
        <ClaimsCard />
      </Grid>}
      <Grid item xs={12}>
        <AttributeValuesCard type="Officiating" targetType="Participant" />
      </Grid>
      <Grid item xs={12} md={6}>
        <ListsCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <ConflictsCard />
      </Grid>
    </Grid>
  </Tab>
}

export const ParticipantShowLayout = props =>
  <ParticipantStatusProvider>
    <ParticipantAccountProvider>
      <TabbedShowLayout>
        <SummaryTab label="resources.participants.labels.tabs.summary" />
        <ProfileTab label="resources.participants.labels.tabs.profile" path="profile" />
        <ScheduleTab label="resources.participants.labels.tabs.schedule" path="games" />
        <RegistrationsTab label="resources.participants.labels.tabs.registrations" path="registrations" />
        <OfficiatingTab label="resources.participants.labels.tabs.officiating" path="officiating" />
      </TabbedShowLayout>
    </ParticipantAccountProvider>
  </ParticipantStatusProvider>
