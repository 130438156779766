import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { OfficeGrid } from '../../offices/OfficeGrid';
import { AddLeagueButton } from '../../leagues/LeagueModal';

export default props => {
  const office = useRecordContext(props);
  const canAddOffice = isAuthorized(office, 'offices', 'create');

  return <ListCard
    title="resources.leagues.labels.card.title"
    reference="leagues"
    target="parentId"
    filter={{ type: 'League' }}
    sort={{ field: 'name', order: 'ASC' }}
    addButton={canAddOffice && <AddLeagueButton initialValues={{ type: 'League', parentId: office?.id, timezone: office?.timezone }} />}
    perPage={10}
    rowsPerPageOptions={[10, 25, 50, 100]}
  >
    <OfficeGrid rowClick="show" />
  </ListCard>
}
