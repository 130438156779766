import { GET_ONE, useQueryWithStore } from "react-admin";
import { useSelector } from 'react-redux';

export const useGameWithStore = (gameId, enabled = true) => {
  const gameInStore = useSelector(state => state?.admin?.resources?.games?.data?.[gameId])

  const { data, loading, error } = useQueryWithStore({
    type: GET_ONE,
    resource: 'games',
    payload: { id: gameId },
  }, {
    enabled: !gameInStore && !!gameId && !!enabled
  })
  return { data: gameInStore || data, loading, error };
}
