import React from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput';

import { OfficeInput } from '../offices/OfficeInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';

import { DivisionInput } from '../categories/DivisionInput';

const validate = values => {
  const errors = {};

  if (!values.arenaId) errors.arenaId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'arenaoffices',
  basePath: '/arenaoffices',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const ArenaOfficeForm = ({ initialValues = {}, target, ...props }) => {
  const translate = useTranslate();
  const disableArenaInput = initialValues?.arenaId != null
  const disableOfficeInput = initialValues?.officeId != null

  return <SimpleForm toolbar={<Toolbar />} validate={validate} initialValues={{ isPrimary: false, ...initialValues }} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <SurfaceInput source="arenaId" filter={{ parentId: null }} disabled={disableArenaInput} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="officeId" filter={{ type: { nin: ['League', 'Tournament', 'Cup'] } }} disabled={disableOfficeInput} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <SwitchInput source="isPrimary" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{translate('resources.arenaoffices.labels.restrictions')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <DivisionInput source="divisionIds" multiple helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="leagueIds" multiple helperText="ra.message.optional" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
