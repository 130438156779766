import { useState } from 'react';
import { useQueryWithStore, useMutation, GET_LIST, CREATE, UPDATE, DELETE } from 'react-admin';

import { isEmpty } from '@hisports/parsers';

export default (type, initialValue) => {
  const [ preference, _setPreference ] = useState({ value: initialValue })
  const [ save, { loading: saveLoading } ] = useMutation();
  const { loading, error } = useQueryWithStore({
    type: GET_LIST,
    resource: 'preferences',
  }, {
    onSuccess(response) {
      const preference = response.data.find(preference => preference.type === type)
      if (preference) _setPreference(preference);
    }
  })

  const setPreference = value => {
    if (typeof value === 'function') return setPreference(value(preference.value));
    _setPreference(preference => ({ ...preference, value }));
    save({
      type: preference.id ? (!isEmpty(value) ? UPDATE : DELETE) : CREATE,
      resource: 'preferences',
      payload: {
        id: preference.id,
        data: { type, value },
      }
    }, {
      onSuccess(response) {
        const data = !isEmpty(response.data) ? response.data : { value: initialValue }
        _setPreference(data)
      }
    })
  }

  return [ preference.value, setPreference, loading || saveLoading, error ];
}
