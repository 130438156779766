import React, { Fragment } from 'react';
import { useNotify, SimpleForm, FormInput, NumberInput, useTranslate, useRecordContext } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Table, TableHead, TableCell, TableBody, TableRow, useMediaQuery } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import { getGoaliesShots } from '@hisports/scoresheet/src/selectors';
import { updateGoalieShots } from '@hisports/scoresheet/src/actions';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { usePermissions, useSport } from '../../../http';

import { useScoresheet, useScoresheetDispatch } from '../ScoresheetContext';

const filterGoalies = (game, goalies, permissions) => {
  // filter goalies for coaches only
  const teamIds = [game.homeTeamId, game.awayTeamId].filter(teamId => {
    return permissions.some(p => p.roleType === 'Team' && p.teamIds.includes(teamId))
  })
  if (!teamIds.length) return goalies;

  return goalies.filter(goalie => teamIds.includes(goalie.teamId))
}

const Heading = styled('h4')(() => ({
  margin: '-12px 0 16px 0'
}))

const Goalie = styled(TableCell)(({ theme }) => ({
  minWidth: theme.spacing(12),
  padding: theme.spacing(1),
}))

const Period = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: theme.spacing(10),
  padding: theme.spacing(1),
  '&:last-child': {
    paddingRight: theme.spacing(1),
  }
}))

const PeriodFormInput = styled(FormInput)(({ theme }) => ({
  input: {
    width: theme.spacing(10),
    margin: 0,
  }
}))

const validateShots = value => {
  if (!value) return;
  if (value < 0) return 'ra.validation.positive';
  if (!Number.isInteger(value)) return 'ra.validation.whole';
}

const PeriodInput = ({ index, period }) =>
  <PeriodFormInput input={
    <NumberInput
      source={`goalies[${index}].totals[${period}]`}
      validate={validateShots}
      step={1}
      label="components.goalie_shots_against.labels.shots"
      variant="outlined"
    />
  } />

const GameShotsForm = ({ goalies, ...props }) => {
  const translate = useTranslate();
  return <SimpleForm {...props}>
    <Table selectable={false} fullWidth>
      <TableHead displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <Goalie>{translate('components.goalie_shots_against.labels.goalie')}</Goalie>
          <Period>{translate('components.goalie_shots_against.labels.periods.1')}</Period>
          <Period>{translate('components.goalie_shots_against.labels.periods.2')}</Period>
          <Period>{translate('components.goalie_shots_against.labels.periods.3')}</Period>
          <Period>{translate('components.goalie_shots_against.labels.periods.OT')}</Period>
        </TableRow>
      </TableHead>
      <TableBody displayRowCheckbox={false}>
        {goalies.map((goalie, index) =>
          <TableRow key={goalie.participantId}>
            <Goalie>{goalie.participant.fullName}</Goalie>
            <Period>
              <PeriodInput index={index} period={0} />
            </Period>
            <Period>
              <PeriodInput index={index} period={1} />
            </Period>
            <Period>
              <PeriodInput index={index} period={2} />
            </Period>
            <Period>
              <PeriodInput index={index} period={3} />
            </Period>
          </TableRow>)}
      </TableBody>
    </Table>
  </SimpleForm>
}

const GameShotsModal = ({ isOpen, onClose, ...props }) => {
  const game = useRecordContext(props);
  const sport = useSport();
  const notify = useNotify();
  const translate = useTranslate();
  const dispatch = useScoresheetDispatch();
  const permissions = usePermissions();
  let goalies = useScoresheet(scoresheet => getGoaliesShots(scoresheet, game, sport))

  goalies = filterGoalies(game, goalies, permissions)

  const onSubmit = async values => {
    const { goalies } = values
    try {
      await dispatch(updateGoalieShots(game.id, goalies))
      notify('components.goalie_shots_against.notifications.updated', 'info')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      notify('ra.message.error', 'error')
    }
    onClose()
  }

  return <>
    <DialogTitle>{translate('components.goalie_shots_against.name')}</DialogTitle>
    <DialogContent>
      <Heading>{translate('resources.games.labels.game_number', { number: game.number })}</Heading>
      <div>{translate('components.goalie_shots_against.messages.enter_shots')}</div>
      <p>{translate('components.goalie_shots_against.messages.blank')}</p>
      <GameShotsForm game={game} initialValues={{ goalies }} goalies={goalies} save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.save" onCancel={onClose} />
      } />
    </DialogContent>
  </>
}

export const GoalieShotsModal = ({ open, onClose, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
    <GameShotsModal open={open} onClose={onClose} {...props} />
  </Dialog>
}
