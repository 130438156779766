import React from 'react';
import { NullableBooleanInput, TextInput } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { InlineDateInput } from '../../../common/inputs/DateInput';

import { EventReferenceManyField } from '../../events/EventReferenceField';
import { EventView } from '../../events/EventView';
import { EventViewSettingsMenu, useEventSort, useShowCalendar } from '../../events/EventViewSettings';
import { rowClick } from '../../events/EventGrid';

export default props => {
  const seasonId = useSeason()
  const [ showCalendar ] = useShowCalendar();
  const sort = useEventSort();

  const actions = [
    <EventViewSettingsMenu disableAssignments disableGroupArenas />
  ]

  const filters = [
    <InlineDateInput source="startTime" label="resources.games.filters.startTime" variant="outlined" alwaysOn />,
    <InlineDateInput source="endTime" label="resources.games.filters.endTime" variant="outlined" disabled={showCalendar} alwaysOn />,
    <TextInput source="number" variant="outlined" />,
    <NullableBooleanInput source="isApproved" label="resources.games.filters.isApproved" variant="outlined" />,
  ]

  return <ListCard
    title="resources.events.labels.card.title"
    resource="games"
    target="participants"
    filter={{ seasonId, pastEvents: true }}
    filters={filters}
    sort={sort}
    base={EventReferenceManyField}
    baseProps={{
      includeGames: true,
      includeDraftGames: false,
      includePractices: true,
      includeActivities: true,
      includeSlots: false,
      includeAvailabilities: false,
    }}
    actions={actions}
    noResultsText={!showCalendar}
    rowsPerPageOptions={showCalendar ? [] : [25, 50, 100]}
    perPage={showCalendar ? 99999 : 25}
  >
    <EventView rowClick={rowClick} />
  </ListCard>
}
