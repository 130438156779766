import React from 'react';
import { useRecordContext } from 'react-admin';

import InfoCard from '../../../common/cards/InfoCard';
import { isAuthorized } from '../../../common/Authorize';

import { UpdateOfficeSettingsButton } from '../../officeSettings/OfficeSettingsModal';
import { OfficeSettingsField } from '../../officeSettings/OfficeSettingsField';

export default props => {
  const office = useRecordContext(props);
  const showSettings = isAuthorized(office, 'officeSettings', 'show');
  const canEditSettings = isAuthorized(office, 'officeSettings', 'edit');

  if (!showSettings) return null;
  return <InfoCard title="ra.message.settings" actions={
    canEditSettings && <UpdateOfficeSettingsButton officeId={office?.id} />
  }>
    <OfficeSettingsField officeId={office?.id} />
  </InfoCard>
}

