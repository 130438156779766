import React from 'react';
import { useRecordContext } from 'react-admin';
import { EmojiPeople } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { ClaimStatusEnumInput, ClaimTypeEnumInput } from '../../../common/inputs/EnumInputs';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { ClaimsGrid } from '../../officialClaims/ClaimsGrid';
import { AddClaimButton } from '../../officialClaims/ClaimModal';
import { ClaimBulkAction } from '../../officialClaims/ClaimBulkActions';
import { ParticipantInput } from '../../participants/ParticipantInput';
import { useOfficeAssignSettings } from '../../officeAssignSettings';

export default ({ ...props }) => {
  const office = useRecordContext(props);
  const seasonId = useSeason();
  const { data: assignSettings } = useOfficeAssignSettings(office?.id);

  const filters = [
    <ClaimStatusEnumInput source="status" variant="outlined" multiple alwaysOn />,
    <ParticipantInput source="participantId" filter={{ 'officialClaims.officeId': office?.id }} variant="outlined" />,
    <ClaimTypeEnumInput source="type" settings={assignSettings} hideAmount multiple variant="outlined" />
  ]

  return <ListCard
    title="resources.officialClaims.labels.card.title"
    icon={<EmojiPeople />}
    reference="officialClaims"
    target="officeId"
    filters={filters}
    filter={{ 'game.seasonId': seasonId }}
    sort={{ field: ['status', 'participant.lastName', 'participant.firstName', 'game.startTime'], order: ['ASC', 'ASC', 'ASC', 'ASC'] }}
    bulkActionButtons={<ClaimBulkAction />}
    addButton={<AddClaimButton
      target="officeId"
      initialValues={{
        officeId: office?.id,
      }}
    />}
    collapsable
  >
    <ClaimsGrid hideOffice />
  </ListCard>
}
