import { v4 as uuid, validate as isUUID } from 'uuid';
import { uniqBy } from '@hisports/common/src/lodash.js';

export * from './boxScore.js';
export * from './gameFlags.js';
export * from './gameTime.js';
export * from './members.js';
export * from './participants.js';
export * from './penalties.js';
export * from './legacyPenalties.js';
export * from './officials.js';
export * from './teams.js';
export * from './summaryEvents.js';
export * from './suspensions.js';

export function append(state, entity, property = 'id') {
  if (!entity) return state;
  if (entity[property] && state.some(e => e[property] == entity[property])) return state;
  return [...state, entity];
}

export function appendMany(state, entities, property = 'id') {
  return uniqBy([...state, ...entities], property);
}

export function update(state, id, payload, property = 'id') {
  return [...state].map(entity => {
    if (entity[property] != id) return entity;

    return Object.assign({}, entity, payload);
  });
}

export function replace(state, id, newEntity, property = 'id') {
  let replaced = false;
  const newState = [...state].reduce((entities, entity) => {
    if (entity[property] != id) {
      entities.push(entity);
      return entities;
    }
    // skip if already replaced (duplicate)
    if (replaced) return entities;

    replaced = true;
    if (typeof newEntity === 'function') {
      entities.push(newEntity(entity));
    } else {
      entities.push(newEntity);
    }
    return entities;
  }, [])
  if (replaced) return newState;
  if (typeof newEntity === 'function') return append(state, newEntity(), property);
  return append(state, newEntity, property);
}

export function removeById(state, id, property = 'id') {
  return state.filter(entity => entity[property] != id);
}

export function removeByIds(state, ids, property = 'id') {
  return state.filter(entity => !ids.includes(entity[property]));
}

export function generateId() {
  return uuid();
}

export function stripTemp(value) {
  return String(value).substring(5);
}

export function isTempId(value) {
  if (!String(value).startsWith("temp_")) return false;
  return isUUID(stripTemp(value));
}

export const byParticipantId = participantId =>
  entity => entity.participantId == participantId

export const byTeamId = teamId =>
  entity => entity.teamId == teamId

export const byAssists = participantId =>
  event => ((event && event.assistIds) || []).some(assistId => assistId == participantId)
