import React, { Fragment } from 'react';
import { DeleteButton, useCreate, useUpdate, useGetOne, useRefresh, useNotify, useTranslate, CRUD_GET_ONE, useResourceContext, useRecordContext, RecordContextProvider, ResourceContextProvider } from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent, makeStyles, alpha } from '@material-ui/core';

import { isAuthorized } from '../Authorize';

import { DialogFormToolbar } from './DialogForm';
import { useScopes } from '../../http';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    marginRight: 'auto',
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  }
}))

export const ResourceDialog = ({
  children,

  basePath,

  label,
  isOpen,
  maxWidth = "sm",

  loading,
  initialValues,
  hideSubmit = false,
  handleSubmit,
  handleClose,

  disableDelete = false,
  canDelete,
  onDelete,
  undoableDelete = true,
  ...props
}) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const scopes = useScopes()

  if (canDelete == null) {
    canDelete = scopes.includes('internal') || (!disableDelete && record && record.id && !record?.externalId && isAuthorized(record, resource, 'remove'))
  }
  let deleteButton;
  if (onDelete) {
    const handleDelete = () => {
      onDelete(record)
      handleClose()
    }
    deleteButton = <Button className={classes.deleteButton} onClick={handleDelete}>{translate('ra.action.delete')}</Button>
  } else {
    deleteButton = <DeleteButton
      basePath={basePath}
      record={record}
      resource={resource}
      redirect={false}
      style={{ marginRight: 'auto' }}
      undoable={undoableDelete}
      onFailure={(error) => {
        const message = (error.name && error.name !== 'ra.validation.invalid') ? translate(error.name) : translate('ra.validation.unable_to_delete')
        notify(message, 'error');
        refresh();
      }}
    />
  }

  return <Dialog
    fullWidth
    maxWidth={maxWidth}
    open={isOpen}
    onClose={handleClose}
    aria-label={label && translate(label, { _: label, smart_count: 1 })}
    disableBackdropClick
  >
    {isOpen && <>
      <DialogTitle>{label && translate(label, { _: label, smart_count: 1 })}</DialogTitle>
      <DialogContent>
        { React.cloneElement(React.Children.only(children), {
          initialValues,
          record,
          resource,
          handleSubmit,
          onSubmit: handleSubmit,
          save: handleSubmit,
          saving: loading,
          undoable: false,
          destroyOnUnmount: true,
          component: Fragment,
          toolbar: <DialogFormToolbar
            hideSubmit={hideSubmit}
            handleSubmit={handleSubmit}
            submitLabel={translate('ra.action.save')}
            cancelLabel={translate('ra.action.cancel')}
            onCancel={handleClose}
            deleteButton={canDelete && deleteButton}
          />
        })}
      </DialogContent>
    </>}
  </Dialog>
}

export const CreateResourceDialog = ({ onSuccess, onFailure, handleClose, skipRefresh = false, ...props }) => {
  const resource = useResourceContext(props);
  const [ create ] = useCreate(resource);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleSubmit = async values => {
    try {
      return await create({ payload: { data: values } }, {
        onSuccess({ data }) {
          if (!skipRefresh) refresh()
          if (typeof onSuccess === 'function') onSuccess(data)
          handleClose()
        },
        onFailure({ error }) {
          if (typeof onFailure === 'function') onFailure(error)
          if (error?.message) notify(error.message, 'error')
        },
        returnPromise: true,
      })
    } catch (e) {
      return e;
    }
  }

  return <ResourceContextProvider value={resource}>
    <RecordContextProvider value={null}>
      <ResourceDialog
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        {...props}
        record={null}
      />
    </RecordContextProvider>
  </ResourceContextProvider>
}

export const EditResourceDialog = ({ id, onSuccess, onFailure, customHandleSubmit, handleClose, skipRefresh = false, ...props }) => {
  const resource = useResourceContext(props);
  const [ update ] = useUpdate(resource, id);
  const refresh = useRefresh();
  const notify = useNotify();

  const { data: record, loaded } = useGetOne(resource, id, {
    action: CRUD_GET_ONE,
    enabled: props.isOpen,
    onFailure() {
      notify('ra.notification.item_doesnt_exist', 'warning');
    }
  })

  const handleSubmit = async values => {
    try {
      return await update({ payload: { data: values } }, {
        onSuccess({ data }) {
          if (!skipRefresh) refresh()
          if (typeof onSuccess === 'function') onSuccess(data)
          handleClose()
        },
        onFailure(error) {
          if (typeof onFailure === 'function') onFailure(error)
          if (error?.message) notify(error.message, 'error')
        },
        returnPromise: true,
      })
    } catch (e) {
      return e;
    }
  }

  if (!loaded) return null;
  return <RecordContextProvider value={record}>
    <ResourceContextProvider value={resource}>
      <ResourceDialog
        record={record}
        initialValues={record}
        handleSubmit={customHandleSubmit || handleSubmit}
        handleClose={handleClose}
        {...props}
      />
    </ResourceContextProvider>
  </RecordContextProvider>
}

