import React, { Fragment, useMemo } from 'react';
import { useNotify, SimpleForm, useRefresh, RecordContextProvider, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, useMediaQuery, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isEmpty, keyBy } from 'lodash';

import { getPeriods, getPenalties, getLineup, getMembers } from '@hisports/scoresheet/src/selectors';
import { editGoal, editPenalty, startPenalty, deletePenalty } from '@hisports/scoresheet/src/actions';
import { isTimeBefore, isTimeValid, LegacyPenaltyManager, LegacyHockeyPenaltyManager, LegacySoccerPenaltyManager, findAdditionalPenalties, findChoice, getInfractionRule, isLegacyRulebookSeason, PenaltyManager, getLinkedPenalties } from '@hisports/scoresheet/src/util';

import { useSport } from '../../../http';
import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { TeamField } from '../../teams/TeamField';

import { useMeta, useScoresheetDispatch, useScoresheetStore } from "../ScoresheetContext"
import { GameEventInput } from './SummaryBulkAddModal';

const inputProps = {
  variant: 'outlined',
  size: 'small',
  margin: 'dense',
  fullWidth: true,
}

const GOAL_TYPE_MAP = {
  isEmptyNet: 'empty_net',
  isPowerplay: 'powerplay',
  isShorthanded: 'shorthanded',
  isOwnGoal: 'own_goal',
  isPenaltyShot: 'penalty_shot',
}

const useStyles = makeStyles(theme => ({
  team: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1)
  }
}))

export const validateEvent = (event, scoresheet, sport) => {
  const validateGameTime = (gameTime = {}) => {
    const errors = {};
    const { period, clock, half } = gameTime

    if (sport === 'Hockey') {
      const { minutes, seconds } = clock || {};
      if (!period) {
        errors.period = ' '
      }
      if (period != 'SO') {
        if (minutes == undefined || seconds == undefined) {
          errors.clock = 'ra.validation.required'
        } else if (isNaN(minutes) || isNaN(seconds)) {
          errors.clock = 'ra.validation.invalid_number'
        } else if (period && !isTimeValid({ period, minutes, seconds }, getPeriods(scoresheet), 'Hockey')) {
          errors.clock = 'resources.scoresheets.validations.out_of_range'
        }
      }
    }

    if (sport === 'Soccer') {
      const { minutes, extra } = clock || {};
      if (!minutes) {
        errors.clock = 'ra.validation.required'
      } else if (isNaN(minutes)) {
        errors.clock = 'ra.validation.invalid_number'
      } else if (minutes < 1) {
        errors.clock = 'ra.validation.greater_than_zero'
      } else if (extra && isNaN(extra)) {
        errors.clock = 'resources.scoresheets.validations.invalid_extra'
      }
    }

    if (sport === 'Baseball') {
      if (!period) {
        errors.period = 'ra.validation.required'
      } else if (isNaN(period)) {
        errors.period = 'ra.validation.invalid_number'
      } else if (period < 1) {
        errors.period = 'ra.validation.greater_than_zero'
      }
      if (!half) errors.half = 'ra.validation.required'
    }

    return errors
  }

  const validateStartTime = event => {
    let errors = {}
    if (!event.startTime) return errors;
    errors = { ...validateGameTime(event.startTime) }
    if (event.gameTime && event.gameTime.clock && event.gameTime.period && event.startTime && event.startTime.clock && event.startTime.period) {
      const gameTime = { ...event.gameTime.clock, period: event.gameTime.period };
      const startTime = { ...event.startTime.clock, period: event.startTime.period };
      if (isTimeBefore(gameTime, startTime, 'Hockey')) {
        errors.clock = 'resources.scoresheets.validations.start_time_before_game_time';
      }
    }
    return errors
  }

  const validatePenalty = event => {
    const errors = {};
    if (isEmpty(event)) return errors;

    const gameTimeErrors = validateGameTime(event.gameTime);
    const startTimeErrors = validateStartTime(event);

    if (!isEmpty(gameTimeErrors)) errors.gameTime = gameTimeErrors;
    if (!isEmpty(startTimeErrors)) errors.startTime = startTimeErrors;
    if (!event.participantId) errors.participantId = 'ra.validation.required';
    if (!event.optionId) errors.optionId = 'ra.validation.required';
    return errors;
  }

  const validateGoal = event => {
    const errors = {};
    if (isEmpty(event)) return errors;

    const gameTimeErrors = validateGameTime(event.gameTime);

    if (!isEmpty(gameTimeErrors)) errors.gameTime = gameTimeErrors;
    if (event && !event?.goalTypes?.includes('own_goal') && !event.participantId) errors.participantId = 'ra.validation.required';
    if (event?.assistIds?.length > 2) errors.assistIds = 'resources.scoresheets.validations.too_many_assists'
    return errors;
  }

  const validate = ({ eventType, ...event }) => {
    if (eventType === 'goal') return validateGoal(event);
    if (eventType === 'penalty') return validatePenalty(event);
    return {}
  }

  return {
    event: validate(event)
  }
}

export const parsePenaltyEvent = (event = {}, scoresheet = {}, meta = {}, sport, seasonId) => {
  const periods = getPeriods(scoresheet)
  const team = meta.teams[event.teamId];
  const lineup = getLineup(scoresheet, { teamId: team.id })
  const members = getMembers(lineup, team, sport)
  const teamPenalties = getPenalties(scoresheet, meta.infractions, meta.types, sport, seasonId, { teamId: event.teamId })

  const penaltyEvent = {
    ...event,
    gameTime: { period: event.gameTime.period, ...event.gameTime.clock, half: event.gameTime.half },
    startTime: { period: event.gameTime.period, ...event.gameTime.clock },
    endTime: undefined, // force recalculation of endTime
  };

  if (event.startTime) {
    penaltyEvent.startTime = { period: event.startTime.period, ...event.startTime.clock }
  }

  let manager;

  if (isLegacyRulebookSeason(seasonId)) {
    switch (sport) {
      case 'Hockey':
        manager = new LegacyHockeyPenaltyManager(meta, event.teamId, periods, keyBy(members, 'participantId'));
        break;
      case 'Soccer':
        manager = new LegacySoccerPenaltyManager(meta, event.teamId);
        break;
      default:
        manager = new LegacyPenaltyManager(meta, event.teamId);
    }

    const rule = meta.rules?.find(({ choices }) => choices?.find(({ id }) => id === event.optionId));
    const choice = rule?.choices?.find(({ id }) => id === event.optionId);

    penaltyEvent.infraction = rule.id,

    manager.setPenalty(penaltyEvent, choice, meta.rules);
  } else {
    manager = new PenaltyManager(meta, event.teamId, periods);

    manager.setPenalty(penaltyEvent, teamPenalties);
  }

  return manager.getResultingPenalties(teamPenalties);
}

export const parseGoalEvent = (event = {}) => ({
  ...event,
  ...Object.keys(GOAL_TYPE_MAP).reduce((attributes, attribute) => ({
    ...attributes,
    [attribute]: (event?.goalTypes || []).includes(GOAL_TYPE_MAP[attribute])
  }), {}),
  assistIds: [...(event.assistIds || [])],
  gameTime: { ...event.gameTime, ...event.gameTime.clock }
})

// form requires minutes/seconds/extra to be nested into 'clock' since its a single input
const parseEventTimeToFormFormat = gameTime => ({
  ...(gameTime?.period != null ? { period: gameTime.period } : {}),
  ...(gameTime?.half != null ? { half: gameTime.half } : {}),
  ...(gameTime ? { clock: {
    ...(gameTime.minutes != null ? { minutes: gameTime.minutes } : {}),
    ...(gameTime.seconds != null ? { seconds: gameTime.seconds } : {}),
    ...(gameTime.extra != null ? { extra: gameTime.extra } : {}),
  } }: {})
})

// form requires a few things to be nested for the inputs to work correctly
const parseEventToFormFormat = (event = {}, originalOptionId) => {
  const eventCopy = { ...event }
  Object.keys(GOAL_TYPE_MAP).forEach(goalType => delete eventCopy[goalType])
  return {
    ...eventCopy,
    ...(originalOptionId ? { optionId: originalOptionId } : {}),
    goalTypes: Object.entries(event)
      .map(([type, value]) => value && GOAL_TYPE_MAP[type])
      .filter(Boolean),
    ...(event.gameTime ? { gameTime: { ...parseEventTimeToFormFormat(event.gameTime) } } : {}),
    ...(event.startTime ? { startTime: { ...parseEventTimeToFormFormat(event.startTime) } } : {}),
    ...(event.endTime ? { endTime: { ...parseEventTimeToFormFormat(event.endTime) } } : {}),
  }
}

const SummaryForm = ({ ...props }) => {
  const classes = useStyles()

  return <SimpleForm {...props} {...inputProps}>
    <div className={classes.team} >
      <RecordContextProvider value={{ teamId: props.teamId }}>
        <TeamField source="teamId" link={false} variant="subtitle2" />
      </RecordContextProvider>
    </div>
    <GameEventInput source="event" {...props} />
  </SimpleForm>
}

export const SummaryEditModal = ({ title, event, open, setModalOpen, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const translate = useTranslate();
  const dispatch = useScoresheetDispatch();
  const store = useScoresheetStore();
  const notify = useNotify();
  const refresh = useRefresh();
  const { meta, game } = useMeta();
  const sport = useSport();
  const scoresheet = store.getState();

  const linkedPenalties = useMemo(() => {
    if (event.eventType !== 'penalty') return []
    const scoresheet = store.getState();
    const teamPenalties = getPenalties(scoresheet, meta.infractions, meta.types, sport, game.seasonId, { teamId: event.teamId })

    if (isLegacyRulebookSeason(game.seasonId)) {
      return findAdditionalPenalties(event, teamPenalties, meta.rules, sport, true)
    }
    return getLinkedPenalties(event, teamPenalties, sport)
  }, [store, event, meta.infractions, meta.types, meta.rules, sport, game.seasonId])

  const subsequentPenalties = useMemo(() => {
    return linkedPenalties.slice(1);
  }, [ linkedPenalties ])

  const isSubsequentPenalty = useMemo(() => {
    return subsequentPenalties.some(linkedPenalty => linkedPenalty.id === event.id);
  }, [ subsequentPenalties, event ])

  const originalOptionId = useMemo(() => {
    if (event.eventType !== 'penalty') return

    if (isLegacyRulebookSeason(game.seasonId)) {
      const additionalPenalties = linkedPenalties.filter(linkedPenalty => linkedPenalty.id !== event.id)
      const rule = getInfractionRule(event, meta.infractions, meta.rules, game.seasonId)
      const choice = findChoice(event, rule, additionalPenalties)
      return choice?.id
    } else {
      return event.optionId
    }
  }, [event, meta.infractions, linkedPenalties, meta.rules, game.seasonId])

  const onSubmit = async ({ event }) => {
    const { eventType } = event;
    let numberOfFulfilled = 0;
    let dispatches = [];

    if (eventType === 'penalty') {
      if (isSubsequentPenalty) {
        // subsequent penalty is being modified so edit only selected penalty
        dispatches.push(editPenalty(game.id, event.id, event));
      } else {
        // first penalty is being modified so edit all linked penalties
        const newPenalties = parsePenaltyEvent(event, store.getState(), meta, sport, game.seasonId)

        // modify existing penalties (or create if new accumulation)
        newPenalties.forEach((newPenalty, i) => {
          if (linkedPenalties[i]) {
            dispatches.push(editPenalty(game.id, linkedPenalties[i].id, newPenalty));
          } else {
            dispatches.push(startPenalty(game.id, newPenalty))
          }
        })
        // remove extra old penalties
        linkedPenalties.forEach((linkedPenalty, i) => {
          if (!newPenalties[i]) {
            dispatches.push(deletePenalty(game.id, linkedPenalty.id))
          }
        })
      }
    }

    if (eventType === 'goal') {
      const goal = parseGoalEvent(event)
      dispatches = [goal].map(event => editGoal(game.id, event.id, event))
    }

    const results = await Promise.allSettled(dispatches.map(event => {
      try {
        return dispatch(event)
      } catch (e) {
        // catch validation errors caused by the dispatches
        return Promise.reject(e)
      }
    }))

    numberOfFulfilled += (results || []).filter(result => result.status === 'fulfilled').length;

    const isSuccessful = numberOfFulfilled
    const message = isSuccessful ? `resources.scoresheets.messages.${eventType}.updated` : `resources.scoresheets.messages.${eventType}.not_updated`;
    const notificationType = isSuccessful ? 'info' : 'error';

    notify(message, notificationType, numberOfFulfilled)
    setModalOpen(false);
    setTimeout(() => refresh(), 500);
  }
  const onClose = () => {
    setModalOpen(false);
  }

  if (!open) return null;
  return <Dialog open={open} maxWidth="lg" fullWidth fullScreen={fullScreen}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {!isSubsequentPenalty && !!subsequentPenalties.length && <Alert severity="info">{translate('resources.scoresheets.messages.penalty.subsequent_penalties')}</Alert>}
      {isSubsequentPenalty && <Alert severity="info">{translate('resources.scoresheets.messages.penalty.subsequent_penalties_edit')}</Alert>}
      <RecordContextProvider value={null}>
        <SummaryForm
          eventType={event.eventType}
          teamId={event.teamId}
          initialValues={{ event: parseEventToFormFormat(event, originalOptionId) }}
          validate={({ event }) => validateEvent(event, scoresheet, sport)}
          save={onSubmit} component={Fragment} toolbar={
            <DialogFormToolbar submitLabel="ra.action.save" cancelLabel="ra.action.cancel" onCancel={onClose} />
          } />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}
