import React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import { Schedule } from '@material-ui/icons';

import { isAuthorized } from '../../../common/Authorize';
import { InlineDateInput } from '../../../common/inputs/DateInput';
import { ArenaSlotTypeEnumInput } from '../../../common/inputs/EnumInputs';
import ListCard from '../../../common/cards/ListCard';

import { ArenaSlotsGrid } from '../../arenaslots/ArenaSlotsGrid';
import { AddArenaSlotButton } from '../../arenaslots/ArenaSlotModal';
import { ArenaSlotBulkActions } from '../../arenaslots/ArenaSlotBulkActions';
import { ArenaOfficeInput } from '../../arenaoffices/ArenaOfficeInput';

const useStyles = makeStyles(theme => ({
  filter: {
    minWidth: theme.spacing(22),
  },
  hideHelperText: {
    '& .MuiFormHelperText-root:empty': {
      display: 'none',
    }
  },
}))

export default ({ filter, title, initialValues, ...props }) => {
  const office = useRecordContext(props);
  const classes = useStyles()

  const showSlots = isAuthorized(office, 'arenaslots', 'list');
  const canAddSlots = isAuthorized(office, 'arenaslots', 'create');
  const showBulkActions = isAuthorized(office, 'arenaslots', 'bulkActions')
  if (!showSlots) return null;

  const addButton = canAddSlots && <AddArenaSlotButton initialValues={{ ...initialValues, officeId: office?.id }} />

  const filters = [
    <InlineDateInput source="startTime" key="startTime" label="resources.arenaslots.fields.startTime" variant="outlined" alwaysOn />,
    <InlineDateInput source="endTime" key="endTime" label="resources.arenaslots.fields.endTime" variant="outlined" alwaysOn />,
    <ArenaSlotTypeEnumInput source="types" key="types" label="resources.arenaslots.fields.types" variant="outlined" alwaysOn multiple className={classes.filter} {...props} />,
    <ArenaOfficeInput source="arenaId" key="arenaId" label="resources.surfaces.name" variant="outlined" alwaysOn officeId={office?.id} {...props} />,
  ];

  return <ListCard
    {...props}
    title={title || "resources.arenaslots.labels.card.title"}
    subtitle="resources.arenaslots.labels.card.subtitle"
    icon={<Schedule />}
    reference="arenaslots"
    target="officeId"
    filter={filter}
    sort={{
      field: ['startTime', 'endTime'],
      order: ['DESC', 'DESC'],
    }}
    addButton={addButton}
    filters={filters}
    bulkActionButtons={showBulkActions ? <ArenaSlotBulkActions /> : false}
    collapsable
  >
    <ArenaSlotsGrid showOffice={false} showSurface />
  </ListCard>
}
