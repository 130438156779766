import React, { useState } from 'react';
import { TextInput, useInput } from 'react-admin';

import TextMask from 'react-text-mask';

const MaskedInput = ({ inputRef, ...props }) =>
  <TextMask
    {...props}
    ref={inputRef}
  />

export default ({ maskOptions, InputProps, ...props }) => {
  const {
    input: { value, onFocus, onBlur },
  } = useInput(props);

  const [ focused, setFocused ] = useState(false);

  const onInputFocus = e => {
    setFocused(true)
    return onFocus(e)
  }

  const onInputBlur = e => {
    setFocused(false);
    return onBlur(e)
  }

  return <TextInput
    {...props}
    onFocus={onInputFocus}
    onBlur={onInputBlur}
    options={{
      ...props.options,
      InputProps: {
        inputComponent: MaskedInput,
        inputProps: maskOptions,
        defaultValue: value,
        ...InputProps
      },
      InputLabelProps: {
        shrink: focused || !!value,
      }
    }}
  />
}
