import React from 'react';
import { SimpleForm, TextInput, useRecordContext } from 'react-admin';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';

import Toolbar from '../../common/ra/Toolbar';
import { OfficeInput } from '../offices/OfficeInput';
import { DateInput } from '../../common/inputs/DateInput';


const validate = values => {
  const errors = {}

  if (!values.officeId) errors.officeId = 'ra.validation.required';
  if (!values.name) errors.name = 'ra.validation.required';
  if (!values.startDate) errors.startDate = 'ra.validation.required';
  if (!values.endDate) errors.endDate = 'ra.validation.required';

  if (values.startDate && values.endDate) {
    if (moment(values.endDate).isBefore(values.startDate, 'day')) {
      errors.endDate = 'ra.validation.end_after_start'
    }
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const PayPeriodForm = ({ initialValues, ...props }) => {
  const payPeriod = useRecordContext();

  const isClosed = payPeriod?.isClosed === true;

  return <SimpleForm toolbar={<Toolbar hideDelete />} validate={validate} initialValues={initialValues} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <TextInput source="name" disabled={isClosed} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="officeId" disabled={isClosed || !!initialValues?.officeId} {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput source="startDate" disabled={isClosed} {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput source="endDate" disabled={isClosed} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
