import React from 'react';
import { TextField, EditButton, useRecordContext } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import InfoCard from '../../../common/cards/InfoCard';
import Row from '../../../common/cards/TableRow';
import { isAuthorized } from '../../../common/Authorize';
import DateRangeField from '../../../common/fields/DateRangeField';

import { OfficeField } from '../../offices/OfficeField';

export default props => {
  const payPeriod = useRecordContext(props);

  const canEditPayPeriod = isAuthorized(payPeriod, 'payPeriods', 'edit');
  const isClosed = payPeriod?.isClosed === true

  return <InfoCard
    title="resources.payPeriods.name"
    actions={[
      !isClosed && canEditPayPeriod && <EditButton label="ra.action.edit" record={payPeriod} />,
    ]}
  >
    <Table>
      <TableBody>
        <Row label="resources.payPeriods.fields.name">
          <TextField source="name" />
        </Row>
        <Row label="resources.payPeriods.labels.dates">
          <DateRangeField startSource="startDate" endSource="endDate" />
        </Row>
        <Row label="resources.payPeriods.fields.officeId">
          <OfficeField source="officeId" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
