import React, { useEffect } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Link, useHistory } from 'react-router-dom';
import { Dialog, DialogTitle, DialogActions, IconButton, Tooltip, makeStyles, useMediaQuery } from '@material-ui/core';
import { Launch, Close } from '@material-ui/icons';

import LoadingAlert from '../../common/LoadingAlert';
import { LazyBoundary } from '../../common/LazyBoundary';
import { useHttpClient } from '../../http';

import { AssignmentsProvider, useAssignmentsContext } from '../games/cards/OfficialsCard/AssignmentsContext';
import { AssignmentProvider } from '../games/cards/OfficialsCard/Assignment/AssignmentContext';
import OfficialDetails from '../games/cards/OfficialsCard/Assignment/OfficialDetails';
import AssignToolbar from '../games/cards/OfficialsCard/Assignment/AssignToolbar';

const Assignments = React.lazy(() => import('../games/cards/OfficialsCard'))

const useStyles = makeStyles(theme => ({
  buttons: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  quickAssignToolbar: {
    borderTop: `1px solid ${theme.palette.divider}`,
    textAlign: 'right',
    padding: 8,
  }
}))

const useOfficialStatus = (gameId, participantId, enabled) =>
  useHttpClient(`/games/${gameId}/availableOfficials/${participantId}`, null, enabled)

const AssignmentDialogHeader = ({ gameId, onClose, title }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return <>
    <DialogTitle>{title}</DialogTitle>
    <div className={classes.buttons}>
      <Tooltip title={translate('ra.action.open_full_page')}>
        <IconButton component={Link} to={`/games/${gameId}/show/officials`} onClick={onClose}>
          <Launch fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={translate('ra.action.close')}>
        <IconButton onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  </>
}

const QuickAssignmentBody = ({ game, selectedAssignment, selectedOfficial, onClose, onAssign }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { assignments = [] } = useAssignmentsContext();
  const {
    data: selectedOfficialWithFlags,
    loaded
  } = useOfficialStatus(game.id, selectedOfficial.participantId, !!game.id && !!selectedOfficial.participantId);

  const assignment = assignments.find(officialAssignment => officialAssignment?.id === selectedAssignment?.id) || selectedAssignment;
  const official = selectedOfficialWithFlags || selectedOfficial;

  const title = translate('resources.games.labels.quick_assign_dialog_title', {
    participantName: official?.participant?.fullName,
    position: translate(`resources.games.values.assignment.position.${assignment?.position}`),
    gameNumber: game.number,
  });

  if (!loaded) return <LoadingAlert square>{translate('resources.games.alerts.loading.assignments')}</LoadingAlert>

  return <>
    <AssignmentDialogHeader gameId={game.id} onClose={onClose} title={title} />
    <AssignmentProvider assignment={assignment} selectedOfficial={official}>
      <OfficialDetails />
      <AssignToolbar className={classes.quickAssignToolbar} onAssign={onAssign} quickAssign />
    </AssignmentProvider>
  </>
}

export const QuickAssignmentDialog = ({ open, onClose, selectedAssignment, selectedOfficial, onAssign }) => {
  const game = useRecordContext();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const onClick = e => e.stopPropagation();

  if (!game || !selectedAssignment) return null;

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={isMobile}
      maxWidth={isMobile ? "xs" : "md"}
      onClick={onClick}
      onClose={onClose}
    >
      <AssignmentsProvider gameId={game.id}>
        <QuickAssignmentBody
          game={game}
          selectedAssignment={selectedAssignment}
          selectedOfficial={selectedOfficial}
          onClose={onClose}
          onAssign={onAssign}
        />
      </AssignmentsProvider>
    </Dialog>
  )
}

export default ({ open, onClose, ...props }) => {
  const translate = useTranslate();
  const game = useRecordContext(props)
  const history = useHistory();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const title = translate('resources.games.labels.game_number_officials', { number: game.number })

  useEffect(() => {
    return history.listen(() => {
      if (!open) return;
      onClose();
    })
  }, [history, onClose, open])

  if (!game) return null;

  const onClick = e => e.stopPropagation();
  return <Dialog
    open={open}
    fullWidth
    fullScreen={isMobile}
    maxWidth={isMobile ? "xs" : "md"}
    onClick={onClick}
    onClose={onClose}
  >
    <AssignmentDialogHeader gameId={game.id} onClose={onClose} title={title} />
    <LazyBoundary>
      <Assignments gameId={game.id} />
    </LazyBoundary>
    <DialogActions />
  </Dialog>
}
