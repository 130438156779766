import React from 'react';
import { ReferenceField, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';

import FunctionField from '../../common/fields/FunctionField';
import { TextLocaleField } from '../../common/fields/TextLocaleField';

export const CategoryField = ({ variant = 'body2', includeGender = "inline", ...props }) => {
  const translate = useTranslate()
  return <ReferenceField basePath="/categories" resource="categories" reference="categories" {...props} link={false}>
    <FunctionField source="name" render={category => {
      const inline = [
        includeGender === 'inline' && translate(`resources.categories.values.gender.${category.gender}`),
      ].filter(Boolean).flatMap(e => [e, ' • ']).slice(0, -1)
      const full = [
        includeGender === 'full' && translate(`resources.categories.values.gender.${category.gender}`),
      ].filter(Boolean).flatMap(e => [e, ' • ']).slice(0, -1)
      return <>
        <TextLocaleField source="name" variant={variant} />
        {inline.length > 0 && <span><Typography variant={variant} display="inline" color="textSecondary"> {inline}</Typography></span>}
        {full.length > 0 && <div><Typography variant={variant} display="inline" color="textSecondary">{full}</Typography></div>}
      </>
    }} variant={variant} />
  </ReferenceField>
}

CategoryField.defaultProps = {
  addLabel: true,
}
