import React from 'react';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

import { isAuthorized } from '../../common/Authorize';
import TimeRangeField from '../../common/fields/TimeRangeField';
import DateField from '../../common/fields/DateField';

import { SurfaceField } from '../surfaces/SurfaceField';

const GameDetails = ({ hideSurface, hideGameTime, ...props }) => {
  const date = hideGameTime ? <DateField source="date" showLocal /> : <TimeRangeField startSource="startTime" endSource="endTime" showDate />

  return <>
    <TextField source="number" />: {date}{!hideSurface && <>@ <SurfaceField source="arenaId" link={false} /></>}
  </>
}

export const GameDetailsField = ({ includeCity = false, hideSurface = false, hideGameTime = false, link = 'show', ...props }) => {
  const game = useRecordContext(props)

  return <ReferenceField
    basePath="/games"
    resource="games"
    reference="games"
    link={isAuthorized(game, 'games', 'show') ? link : false}
    {...props}
  >
    <GameDetails hideSurface={hideSurface} hideGameTime={hideGameTime} />
  </ReferenceField>
}

GameDetailsField.defaultProps = {
  addLabel: true,
}
