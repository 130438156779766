import React from 'react';
import { useRecordContext } from 'react-admin';
import { NotificationsOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { NotificationGrid } from '../../notifications/NotificationGrid';
import { AddNotificationButton } from '../../notifications/NotificationModal';

export default props => {
  const office = useRecordContext(props);

  const showNotifications = isAuthorized(office, 'notifications', 'list')
  const canAddNotifications = isAuthorized(office, 'notifications', 'create')

  if (!showNotifications) return null;
  return <ListCard
    title="resources.notifications.labels.card.title"
    subtitle="resources.notifications.labels.card.subtitles.office"
    icon={<NotificationsOutlined />}
    collapsable
    reference="notifications"
    target="offices"
    sort={{ field: 'email', order: 'ASC' }}
    addButton={canAddNotifications && <AddNotificationButton initialValues={{ targetType: 'Office', targetId: office?.id }} />}
  >
    <NotificationGrid />
  </ListCard>
}
