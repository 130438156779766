import React from 'react';
import { DELETE, Datagrid, useMutation, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { Delete } from '@material-ui/icons';

import { isAuthorized } from '../../common/Authorize';
import FunctionField from '../../common/fields/FunctionField';

const useStyles = makeStyles(theme => ({
  headerRow: {
    display: 'none',
  },
  row: {
    '& .column-createdAt': {
      maxWidth: theme.spacing(4),
    },
  },
  buttons: {
    float: 'right',
  }
}))

const DeleteButton = ({ className }) => {
  const message = useRecordContext();
  const [ mutate, { loading } ] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();

  const canDelete = isAuthorized(message, 'gameDocuments', 'remove');
  if (!canDelete) return null;

  const onClick = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (loading) return;
    mutate({
      type: DELETE,
      resource: 'gameDocuments',
      payload: {
        id: message.id,
        data: message
      }
    }, {
      undoable: true,
      onSuccess() {
        notify('resources.gameDocuments.notifications.deleted', 'info', {}, true);
        refresh()
      },
      onFailure() {
        notify('resources.gameDocuments.notifications.delete_error', 'error')
      }
    })
  }

  return <Tooltip title={translate('resources.gameDocuments.labels.delete')}>
    <IconButton size="small" className={className} disabled={loading} onClick={onClick}>
      <Delete />
    </IconButton>
  </Tooltip>
}

const renderMeta = (document, source, resource, translate) => {
  const { createdAt, author } = document;
  const date = moment(createdAt).calendar()
  if (!author) return date;
  return `${date} ${translate('ra.function.by')} ${author}`;
}

export const GameDocumentGrid = ({ canManageGameDocuments, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const rowClick = (id, basePath, document) => {
    const url = document?.file?.url;
    if (!url) return;
    window.open(url, '_blank')
  }

  return <Datagrid size="medium" classes={classes} rowClick={rowClick}>
    <FunctionField source="name" render={document => `${translate(`resources.gameDocuments.values.type.${document.type}`)}: ${document.name}`} />
    <FunctionField source="createdAt" render={renderMeta} />
    {canManageGameDocuments && <DeleteButton className={classes.buttons} />}
  </Datagrid>
}
