import React from 'react'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Flag as FlagIcon } from '@material-ui/icons';

import { FLAG_COLORS } from '@hisports/scoresheet/src/constants';

const useStyles = makeStyles(theme => ({
  flagIconContainer: {
    display: 'grid',

    // forces all child to overlap eachother
    '& > *': {
      gridColumn: '1/-1',
      gridRow: '1/-1',
    }
  },
  colorError: {
    color: theme.palette.error.main,
  },
  colorWarning: {
    color: theme.palette.warning.main,
  },
  splitedFlagIcon: {
    clipPath: 'polygon(0 0, 0% 70%, 100% 20%);' // top left triangle ajusted for the MUI flag icon
  },
}))

const ConditionnalyRenderTooltip = ({ tooltip, children }) => tooltip ? (
  <Tooltip title={tooltip} placement="top" arrow>
    {/* need span to foward tooltip ref. inlineblock to keep children's dimentions */}
    <span style={{ display: 'inline-block' }}>{children}</span>
  </Tooltip>
): children

const ConditionnalyRenderButton = ({ onClick, edge, children }) => (onClick ? (
  <IconButton edge={edge} onClick={onClick}>{children}</IconButton>
): children)


const Flag = ({ className = '', color, tooltip, onClick, edge }) => {
  const classes = useStyles();

  const colorClassName = (() => {
    switch (color) {
      case FLAG_COLORS.RED:
        return classes.colorError;

      case FLAG_COLORS.ORANGE:
      case FLAG_COLORS.RED_ORANGE:
        return classes.colorWarning;

      case FLAG_COLORS.DEFAULT:
      default:
        return '';
    }
  })();

  return (
    <span className={className}>
      <ConditionnalyRenderTooltip tooltip={tooltip}>
        <ConditionnalyRenderButton onClick={onClick} edge={edge}>
          <span className={classes.flagIconContainer}>
            {color === FLAG_COLORS.RED_ORANGE && <FlagIcon className={`${classes.colorError} ${classes.splitedFlagIcon}`} /> }
            <FlagIcon className={colorClassName} />
          </span>
        </ConditionnalyRenderButton>
      </ConditionnalyRenderTooltip>
    </span>
  )
};

export default Flag;
