import React from 'react';
import { NumberField, ImageField, EditButton, useRecordContext } from 'react-admin'
import { Table, TableBody } from '@material-ui/core';

import Row from '../../common/cards/TableRow';
import { isAuthorized } from '../../common/Authorize';
import InfoCard from '../../common/cards/InfoCard';
import ContentField from '../../common/fields/ContentField';

import { SectionField } from '../sections/SectionField'
import { OfficeField } from '../offices/OfficeField';

export const PageShowLayout = props => {
  const office = useRecordContext(props);
  const canEditPage = isAuthorized(office, 'pages', 'edit')
  return <InfoCard actions={
    canEditPage && <EditButton label="resources.pages.actions.edit" record={office} />
  }>
    <Table>
      <TableBody>
        <Row>
          <ImageField source="cover.url" />
          <ContentField source="content" />
        </Row>
        <Row label="resources.pages.fields.sectionId">
          <SectionField source="sectionId" />
        </Row>
        <Row label="resources.pages.fields.order" hidden={office?.order == null}>
          <NumberField source="order" />
        </Row>
        <Row label="resources.pages.fields.officeId">
          <OfficeField source="officeId" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
