import React, { Suspense } from 'react';
import { useLocale, useRecordContext } from 'react-admin';
import { Grid } from '@material-ui/core';

import { FF_DRAFT_GAMES } from '@hisports/common/featureFlags';

import { useFlag, usePermissions, useSport } from '../../http';
import { hasAnyScope, isAuthorized } from '../../common/Authorize'
import { LazyBoundary } from '../../common/LazyBoundary';
import { TabbedShowLayout, Tab } from '../../common/ra/TabbedShowLayout';
import { useGameStatus } from '../gameStatus/useGameStatus';
import InfoCard from '../../common/cards/InfoCard';

import Scoresheet from '../scoresheet';
import { useScheduleSeeds } from '../gameBrackets/useSeeds';

import GameCard from './cards/GameCard';
import GameLinksCard from './cards/GameLinksCard';
import ConflictsCard from './cards/ConflictsCard';
import MessagesCard from './cards/MessagesCard';
import EventsCard from './cards/EventsCard';
import TransactionsCard from './cards/TransactionsCard';
import ClaimsCard from './cards/ClaimsCard';
import DraftGamesCard from './cards/DraftGamesCard';
import ExternalProvidersCard from '../externalProviders/ExternalProvidersCard';
import BracketsCard from '../gameBrackets/cards/BracketsCard';

const Assignments = React.lazy(() => import('./cards/OfficialsCard'));

const SummaryTab = ({ status, ...props }) => {
  const game = useRecordContext(props);
  const isEnabled = useFlag();

  const canViewConflicts = isAuthorized(game, 'games', 'edit') && game?.status === 'Conflict';
  const canViewDraftGames = isEnabled(FF_DRAFT_GAMES) && (isAuthorized(game, 'games', 'edit') || (status?.rescheduleRequests && isAuthorized(game, 'draftGames', 'approve', false, false)));
  const canViewMessages = isAuthorized(game, 'gameMessagesLeague', 'list');
  const canCreateMessages = isAuthorized(game, 'gameMessagesLeague', 'create');

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={9} lg={8}>
        <GameCard status={status} />
      </Grid>
      <Grid item xs={12} md={3} lg={4}>
        <GameLinksCard status={status} />
        <br />
        <ExternalProvidersCard targetId={game?.id} />
      </Grid>
      {canViewDraftGames && <Grid item xs={12}>
        <DraftGamesCard />
      </Grid>}
      {canViewConflicts && <Grid item xs={12}>
        <ConflictsCard />
      </Grid>}
      {canViewMessages && <Grid item xs={12}>
        <MessagesCard type="League" showCreate={canCreateMessages} />
      </Grid>}
    </Grid>
  </Tab>
}

const OfficialsTab = ({ status, ...props }) => {
  const game = useRecordContext(props);
  const permissions = usePermissions();

  if (!status?.scorekeeping && !status?.assigning) return <div />;
  if (!isAuthorized(game, "games", "officials", false, false)) return <div />;

  const isAssigner = hasAnyScope(permissions.map(permission => permission.scopes).flat(), ['assigning:manage', 'assigning:assign']);

  const canViewTransactions = isAuthorized(game, 'officialTransactions', 'list', false, false) && (isAssigner || ['assigned', 'confirmed', 'declined', 'no-show'].includes(status.officials?.self));
  const canViewClaims = isAuthorized(game, 'officialClaims', 'list', false, false) && (isAssigner || ['assigned', 'confirmed', 'declined', 'no-show'].includes(status.officials?.self));
  const canViewMessages = isAuthorized(game, 'gameMessagesOfficiating', 'list', false, false) || ['assigned', 'confirmed', 'declined', 'no-show'].includes(status.officials?.self);
  const canCreateMessages = isAuthorized(game, 'gameMessagesOfficiating', 'create', false, false) || ['assigned', 'confirmed'].includes(status.officials?.self);

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Suspense fallback={() => null}>
          <InfoCard title="resources.officials.labels.card.title">
            <LazyBoundary>
              <Assignments gameId={game?.id} gameOfficeId={game?.officeId} hideMessages />
            </LazyBoundary>
          </InfoCard>
        </Suspense>
      </Grid>

      {canViewTransactions && <Grid item xs={12}>
        <TransactionsCard />
      </Grid>}

      {canViewClaims && <Grid item xs={12}>
        <ClaimsCard />
      </Grid>}

      {canViewMessages && <Grid item xs={12}>
        <MessagesCard type="Officiating" showCreate={canCreateMessages} />
      </Grid>}
    </Grid>
  </Tab>
}

const ScoresheetTab = ({ status, ...props }) => {
  const game = useRecordContext(props);

  if (!game || (!game.homeTeamId && !game.awayTeamId)) return <div />;
  if (!status?.scorekeeping) return null;
  if (!(isAuthorized(game, "scoresheets", "lineup") || status?.officials?.self)) return null;

  return <Tab {...props}>
    <Scoresheet status={status} />
  </Tab>
}

const BracketsTab = props => {
  const game = useRecordContext(props);

  /* Used by the bracket system */
  const sport = useSport();
  const locale = useLocale();
  const BRACKET_URL = process.env.BRACKET_URL;
  const isSeeded = useScheduleSeeds(game?.scheduleId)

  if (!isSeeded || !BRACKET_URL) return <div />;

  const bracketUrl = `${BRACKET_URL}/${game?.scheduleId}/${sport}/${locale}`

  return <Tab {...props}>
    <BracketsCard url={bracketUrl} scheduleId={game?.scheduleId} />
  </Tab>
}


const EventsTab = props => {
  const game = useRecordContext(props);

  if (!isAuthorized(game, "gameEvents", "list")) return <div />;
  return <Tab {...props}>
    <EventsCard />
  </Tab>
}

export const GameShowLayout = props => {
  const game = useRecordContext(props);

  const { data: status, loading, error } = useGameStatus(game?.id);

  return <TabbedShowLayout>
    <SummaryTab label="resources.games.labels.tabs.summary" status={status} />
    <OfficialsTab label="resources.games.labels.tabs.officials" path="officials" status={status} />
    <ScoresheetTab label="resources.games.labels.tabs.scoresheet" path="scoresheet" status={status} />
    <BracketsTab label="resources.games.labels.tabs.bracket" path="bracket" />
    <EventsTab label="resources.games.labels.tabs.events" path="events" status={status} />
  </TabbedShowLayout>
}
