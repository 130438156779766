import React, { useState } from 'react';
import { useTranslate, useRecordContext } from 'react-admin';
import { ListItemIcon, ListItemText } from '@material-ui/core'
import { StarBorder } from '@material-ui/icons'

import { ActionMenuItem } from '../../../../common/ActionMenu';
import { LazyDialog } from '../../../../common/LazyDialog';

const Generator = React.lazy(() => import('./Generator'))

export default ({ schedule, isOpen, onClose, ...props }) => {
  return <LazyDialog>
    {isOpen && <Generator schedule={schedule} isOpen={isOpen} onClose={onClose} {...props} />}
  </LazyDialog>
}

export const GenerateDraftGamesAction = ({ onClick, ...props }) => {
  const schedule = useRecordContext();
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  const handleClick = () => {
    onClick();
    setOpen(true);
  }
  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><StarBorder fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.draftGames.actions.generate')}</ListItemText>
    </ActionMenuItem>
    <LazyDialog>
      {isOpen && <Generator schedule={schedule} isOpen={isOpen} onClose={() => setOpen(false)} {...props} />}
    </LazyDialog>
  </>
}
