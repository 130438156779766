import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { SurfaceGrid } from '../../surfaces/SurfaceGrid';
import { AddSurfaceButton } from '../../surfaces/SurfaceModal';
import { SurfaceIcon } from '../../icons';

export default props => {
  const surface = useRecordContext();

  const canAddSurface = isAuthorized(surface, 'surfaces', 'create');
  const addButton = canAddSurface && <AddSurfaceButton initialValues={{ venueId: surface?.venue.id, parentId: surface?.id }} />

  return <ListCard
    title="resources.surfaces.labels.card.title"
    icon={<SurfaceIcon />}
    {...props}
    reference="surfaces"
    target="arenaId"
    addButton={addButton}
    filter={{ _scope: 'Tenant', effectiveSurfaces: true, includeParents: false }}
    sort={{
      field: 'name',
      order: 'ASC',
    }}
  >
    <SurfaceGrid includeVenue={false} rowClick="show" />
  </ListCard>
}
