import React from 'react';
import { useRecordContext } from 'react-admin';
import { Web } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { SectionGrid } from '../../sections/SectionGrid';
import { AddSectionButton } from '../../sections/SectionModal';

export default props => {
  const office = useRecordContext(props);

  const canAddSection = isAuthorized(office, 'sections', 'create');

  return <ListCard
    title="resources.sections.labels.card.title"
    subtitle="resources.sections.labels.card.subtitle"
    icon={<Web />}
    collapsable
    reference="sections"
    target="officeId"
    sort={{ field: 'name', order: 'ASC' }}
    addButton={canAddSection && <AddSectionButton initialValues={{ officeId: office?.id }} />}
  >
    <SectionGrid rowClick="show" />
  </ListCard>
}
