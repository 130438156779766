import React from 'react';
import { Datagrid } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { ListField } from '../lists/ListField';

const useStyles = makeStyles({
  headerRow: {
    display: 'none',
  }
})

export const ListMemberGrid = ({ showOffice = true, ...props }) => {
  const classes = useStyles();
  return <Datagrid size="medium" classes={classes} {...props}>
    <ListField source="listId" />
  </Datagrid>
}
