import React, { Fragment, useState } from "react";
import { useTranslate } from "react-admin";
import { Button } from "@material-ui/core";
import { CreateResourceDialog, EditResourceDialog } from "../../../common/dialogs/ResourceDialog";
import { DialogDatagrid } from "../../../common/dialogs/DialogDatagrid";
import { Add } from "@material-ui/icons";
import { IncidentReportForm } from "./IncidentReportForm";

export const AddIncidentReportDialog = props =>
  <CreateResourceDialog label="resources.gameIncidents.actions.add" resource="gameIncidents" basePath="/gameIncidents" {...props}>
    <IncidentReportForm event={props?.event} />
  </CreateResourceDialog>

const EditIncidentReportDialog = props =>
  <EditResourceDialog label="resources.gameIncidents.labels.edit" resource="gameIncidents" basePath="/gameIncidents" {...props}>
    <IncidentReportForm />
  </EditResourceDialog>


export const AddIncidentReportButton = ({ size, variant, icon = <Add />, label = true, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={icon} onClick={() => setOpen(true)}>{label && translate('resources.gameIncidents.actions.add')}</Button>
    <AddIncidentReportDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const IncidentReportDatagrid = props => <DialogDatagrid dialog={EditIncidentReportDialog} {...props} />
