import React from 'react';
import { TextField, NumberField, Datagrid } from 'react-admin';

import { FF_OFFICIALS, FF_SCOREKEEPERS, FF_TIMEKEEPERS, FF_ADDITIONAL_LINESPERSONS } from '@hisports/common/featureFlags';

import { useFlag } from '../../http';
import Toolbar from '../../common/ra/Toolbar';

export const AssignSystemGrid = props => {
  const isEnabled = useFlag();

  return <Datagrid size="medium" toolbar={<Toolbar />} {...props}>
    <TextField source="name" />
    <NumberField source="referees" />
    <NumberField source="linespersons" />
    {isEnabled(FF_ADDITIONAL_LINESPERSONS) && <NumberField source="linespersonsTwo" />}
    {isEnabled(FF_ADDITIONAL_LINESPERSONS) && <NumberField source="linespersonsThree" />}
    {isEnabled(FF_OFFICIALS) && <NumberField source="officials" />}
    {isEnabled(FF_SCOREKEEPERS) && <NumberField source="scorekeepers" />}
    {isEnabled(FF_TIMEKEEPERS) && <NumberField source="timekeepers" />}
    <NumberField source="supervisors" />
  </Datagrid>
}
