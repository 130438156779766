import { cloneDeep } from '@hisports/common/src/lodash.js';

import { isEmpty } from '@hisports/parsers';

import {
  DEFAULT_SETTINGS,
  EVENT_TYPE_GOAL,
  EVENT_TYPE_PENALTY,
  EVENT_TYPE_GOALIE,
  THIRD_PERIOD,
  OVERTIME_PERIOD,
  SHOOTOUT_PERIOD,
} from '../constants.js';
import { getGoals } from './goals.js';
import { getPenalties } from './penalties.js'
import { getGoalieChanges } from './goalies.js';
import { compareTime } from '../util/index.js';

export * from './meta.js';
export * from './pregame.js';
export * from './lineups.js';
export * from './goals.js';
export * from './penalties.js';
export * from './suspensions.js';
export * from './goalies.js';
export * from './flags.js';
export * from './purges.js';
export * from './baseball.js';

const setEventType = eventType => event => Object.assign({}, event, { eventType });

export const getSummary = (state, infractions, types, sport, seasonId) => {
  if (!state) return [];
  const goals = getGoals(state, sport);
  const penalties = getPenalties(state, infractions, types, sport, seasonId);
  const goalieChanges = getGoalieChanges(state);
  const summary = [
    ...goals.map(setEventType(EVENT_TYPE_GOAL)),
    ...penalties.map(setEventType(EVENT_TYPE_PENALTY)),
    ...goalieChanges.map(setEventType(EVENT_TYPE_GOALIE))
  ]

  summary.sort((a, b) => compareTime(a.gameTime, b.gameTime, sport))

  return summary;
}

export const getSettings = (state) => {
  if (!state || !state.settings) return cloneDeep(DEFAULT_SETTINGS);
  return state.settings;
}

export const getPeriods = (state) => {
  const settings = getSettings(state);
  return settings.periods;
}

export const getPeriod = (state, period) => {
  const periods = getPeriods(state);
  return periods[period - 1];
}

export const isInLastMinutes = (state, gameTime, sport, minutes = 10) => {
  if (sport !== 'Hockey') return false;
  if (isEmpty(gameTime)) return false;
  if ([OVERTIME_PERIOD, SHOOTOUT_PERIOD].includes(gameTime.period)) return true;

  const lastPeriod = getPeriod(state, THIRD_PERIOD);
  if (!lastPeriod) return true; // if no period 3, assume in last minutes

  return gameTime.minutes >= lastPeriod.minutes - minutes;
}
