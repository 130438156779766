import React, { useState, useEffect } from 'react';
import { Card, CardContent, makeStyles } from '@material-ui/core'
import { useVersion } from 'react-admin';
import Iframe from 'react-iframe';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { apiClient } from '../../http';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  frame: {
    overflow: 'hidden',
    position: 'inherit',
    display: 'block',
    height: '95vh',
    width: '100%',
    border: 0,
  }
}))

const ReportFrame = ({ reports }) => {
  const classes = useStyles();
  const version = useVersion();
  const [ reportUrl, setReportUrl ] = useState(null);
  const [ frameUrl, setFrameUrl ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  const { values = {} } = useFormState();
  const { reportId, officeId, seasonId, participantId, teamId, scheduleType, scheduleId, categoryIds } = values;

  const office = useSelector(state => officeId && state.admin?.resources?.offices?.data[officeId])

  useEffect(() => {
    const getUrl = () => {
      if (!reportId) return null;
      const report = reports.find(report => report.id === reportId);
      if (!report) return null;

      const params = [];
      if (seasonId) params.push(`seasonId=${seasonId}`)
      if (scheduleType) params.push(`scheduleType=${scheduleType}`)
      if (scheduleId) params.push(`scheduleId=${scheduleId}`)

      if (report.targetType === 'Office') {
        if (categoryIds?.length) params.push(...categoryIds.map(categoryId => `categoryIds[]=${categoryId}`))
        if (!officeId) return null;
        params.push(`targetId=${officeId}`)
      } else if (report.targetType === 'Team') {
        if (!teamId) return null;
        params.push(`targetId=${teamId}`)
      } else if (report.targetType === 'Participant') {
        if (!participantId) return null;
        params.push(`targetId=${participantId}`)
      } else {
        return null;
      }

      return `/reports/${reportId}/generateReport?${params.join('&')}`
    }
    setReportUrl(getUrl())
  }, [ reports, reportId, officeId, seasonId, participantId, teamId, scheduleType, scheduleId, categoryIds ])

  useEffect(() => {
    setFrameUrl(null);

    if (!reportUrl) return
    setLoading(true)
    apiClient(reportUrl, { method: 'POST' })
      .then(res => res ? res.data : null)
      .then(url => {
        setFrameUrl(url);
        setError(null);
      })
      .catch(res => {
        setFrameUrl(null);
        const status = res?.response?.status;
        setError(status || true)
      })
      .finally(() => {
        setLoading(false);
      })
  }, [ reportUrl, version ])

  let message;
  if (loading) {
    message = 'Generating report...'
  } else if (error === 403) {
    const report = reports.find(r => r.id === reportId);
    if (report) {
      message = 'This report is not available for this '
      switch (report.targetType) {
        case 'Team':
          message += 'team.';
          break;
        case 'Participant':
          message += "player's association.";
          break;
        case 'Office':
        default: {
          let type = office?.type?.toLowerCase() || 'office';
          if (type === 'Administrative') {
            type = 'office';
          }
          message += `${type}.`;
          break;
        }
      }
    } else {
      message = 'This report is not available.'
    }
  } else if (error) {
    const code = error !== true ? ` (${error})` : '';
    message = `Something went wrong while loading this report${code}. You may need to try again later.`
  }

  if (message) {
    return <Card className={classes.root}>
      <CardContent>
        {message}
      </CardContent>
    </Card>
  }

  if (!frameUrl) return null;
  return <Card className={classes.root}>
    <Iframe url={`${frameUrl}#bordered=false&titled=false`} className={classes.frame} />
  </Card>
}

export default ReportFrame;
