import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { LeagueForm } from './LeagueForm';

const AddLeagueDialog = props =>
  <CreateResourceDialog label="resources.leagues.labels.add" resource="leagues" basePath="/leagues" {...props}>
    <LeagueForm />
  </CreateResourceDialog>

const EditLeagueDialog = props =>
  <EditResourceDialog label="resources.leagues.labels.edit" resource="leagues" basePath="/leagues" {...props}>
    <LeagueForm />
  </EditResourceDialog>

export const AddLeagueButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.leagues.actions.add')}</Button>
    <AddLeagueDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const LeagueDatagrid = props => <DialogDatagrid dialog={EditLeagueDialog} {...props} />
