import React, { Fragment, useState } from 'react';
import { ArrayInput, SimpleForm, useDataProvider, useRefresh, useNotify, useTranslate, RecordContextProvider } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, Button, makeStyles, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import SimpleFormIterator, { TransitionProps } from '../../common/ra/SimpleFormIterator';
import { useSeason } from '../../common/inputs/SeasonSelector';

import { TeamInput } from '../teams/TeamInput'
import { ScheduleGroupInput } from '../groups/GroupInput';
import { CategoryInput } from '../categories/CategoryInput';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const useStyles = makeStyles(theme => ({
  inputChip: {
    marginBottom: theme.spacing(2),
  }
}))

const FormBody = ({ scheduleCategoryId }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const seasonId = useSeason();
  const { values: { categoryId } } = useFormState();

  return <>
    <ScheduleGroupInput className={classes.inputChip} source="groupId" showNone={translate('ra.message.no_group')} {...inputProps} />
    <Typography variant="body2" color="textSecondary">{translate('ra.message.filter_by_category')}</Typography>
    <CategoryInput source="categoryId" scheduleCategoryId={scheduleCategoryId} {...inputProps} />
    <ArrayInput source="teamIds" label="resources.scheduleteams.fields.teamIds" {...inputProps}>
      <SimpleFormIterator disableReordering TransitionProps={TransitionProps}>
        <TeamInput source="id" filter={{ seasonId, _scope: 'Tenant', categoryId }} ignoreSchedule options={{ label: null }} {...inputProps} />
      </SimpleFormIterator>
    </ArrayInput>
  </>
}

const AddTeamsForm = ({ scheduleCategoryId, ...props }) => {
  return <RecordContextProvider>
    <SimpleForm {...props} {...inputProps}>
      <FormBody scheduleCategoryId={scheduleCategoryId} />
    </SimpleForm>
  </RecordContextProvider>
}

export const AddTeamsButton = ({ initialValues, scheduleCategoryId, size, variant }) => {
  const [ isOpen, setOpen ] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onSubmit = ({ scheduleId, groupId, teamIds }) => {
    if (!teamIds || !teamIds.length) {
      setOpen(false);
      return;
    }

    const teams = teamIds
      .map(team => team && team.id)
      .filter(Boolean)
      .map(teamId => ({ scheduleId, groupId, teamId }))

    return Promise.all(teams.map(
      data => dataProvider.create("scheduleteams", { data })
    )).then(() => {
      setOpen(false)
      refresh()
    }).catch(error => {
      notify(error.message, 'error');
    })
  }

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.scheduleteams.actions.add')}
    </Button>
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>{translate('resources.scheduleteams.actions.add')}</DialogTitle>
      <DialogContent>
        <AddTeamsForm initialValues={initialValues} scheduleCategoryId={scheduleCategoryId} save={onSubmit} component={Fragment} toolbar={
          <DialogFormToolbar submitLabel="ra.action.save" />
        } />
      </DialogContent>
    </Dialog>
  </>
}
