import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText as MuiDialogContentText, DialogTitle, styled } from '@material-ui/core'
import { useTranslate } from 'react-admin';

const DialogContentText = styled(MuiDialogContentText)({
  whiteSpace: 'pre-line'
})

export default ({
  isOpen = false,
  title,
  message,
  reject,
  accept,
  extraButton,
  onExtraButtonClose = () => {},
  onClose = () => {}
}) => {
  const translate = useTranslate()
  return <Dialog
    open={isOpen}
    onClose={() => onClose()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    {message && <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>}
    <DialogActions>
      {reject && <Button onClick={() => onClose(false)}>
        {translate(reject, { _: reject })}
      </Button>}
      {accept && <Button onClick={() => onClose(true)} color="primary">
        {translate(accept, { _: accept })}
      </Button>}
      {extraButton && <Button onClick={() => onExtraButtonClose(true)} color="primary" autoFocus>
        {translate(extraButton, { _: extraButton })}
      </Button>}
    </DialogActions>
  </Dialog>
}
