import React from 'react';
import { ArrayInput, GET_MANY_REFERENCE, NumberInput, SimpleForm, TextInput, useQuery, useRecordContext } from 'react-admin';
import { Grid } from '@material-ui/core'
import { useFormState } from 'react-final-form';

import Toolbar from '../../common/ra/Toolbar';
import HorizontalFormIterator, { TransitionProps } from '../../common/ra/HorizontalFormIterator';
import { ArrayI18nInput } from '../../common/inputs/I18nInput';

import { RuleInput } from './RuleInput';
import { InfractionInput } from './InfractionInput';

const validate = values => {
  const errors = {};

  if (!values.ruleId) errors.ruleId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.name) errors.name = 'ra.validation.required'
  if (values.order == null) errors.order = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'ruleOptions',
  basePath: '/ruleOptions',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const arrayInputProps = {
  resource: 'ruleOptions',
  basePath: '/ruleOptions',
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const RuleInfractionInput = props => {
  const { values } = useFormState();
  return <InfractionInput {...props} filter={{ ruleId: values?.ruleId }} />
}

export default ({ initialValues, ...props }) => {
  const option = useRecordContext();

  const enabled = option != null;
  const { data: members = [], loaded } = useQuery({
    type: GET_MANY_REFERENCE,
    resource: 'ruleOptionMembers',
    payload: {
      target: 'ruleOptions',
      id: option?.id,
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'order', order: 'ASC' },
      filter: {}
    },
  }, { enabled })

  if (enabled && !loaded) return null;

  const values = { ...initialValues, members }
  return <SimpleForm initialValues={values} toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={8}>
        <RuleInput source="ruleId" disabled {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberInput source="order" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="name" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ArrayI18nInput {...inputProps} />
      </Grid>
      <ArrayInput source="members" label="resources.rulebooks.fields.infractions" {...inputProps}>
        <HorizontalFormIterator TransitionProps={TransitionProps}>
          <RuleInfractionInput source="infractionId" label="resources.infractions.name" {...arrayInputProps} />
        </HorizontalFormIterator>
      </ArrayInput>
      {/* parent option */}
    </Grid>
  </SimpleForm>;
}

