import React from 'react';
import { useRecordContext } from 'react-admin';

import { ScheduleField } from '../schedules/ScheduleField';
import { OfficeField } from '../offices/OfficeField';
import { TeamField } from '../teams/TeamField';
import { VenueField } from '../venues/VenueField';
import { SurfaceField } from '../surfaces/SurfaceField';
import { ListField } from '../lists/ListField';
import { GameField } from '../games/GameField';

export const TargetField = ({ includeSeason = true, ...props }) => {
  const record = useRecordContext(props);
  if (!record) return null;

  switch (record.targetType) {
    case 'Office':
      return <OfficeField {...props} source="targetId" />
    case 'Schedule':
      return <ScheduleField {...props} source="targetId" includeSeason={includeSeason} />
    case 'Team':
      return <TeamField {...props} source="targetId" includeSeason={includeSeason} />
    case 'Venue':
      return <VenueField {...props} source="targetId" />
    case 'Surface':
    case 'Arena':
      return <SurfaceField {...props} source="targetId" />
    case 'List':
      return <ListField {...props} source="targetId" />
    case 'Game':
      return <GameField {...props} source="targetId" includeDate="inline" includeTime includeSurface="full" />
    default:
      return null;
  }
}
