import React, { useState } from 'react';
import { RecordContextProvider, SimpleForm, TextInput, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, LinearProgress, List, ListItem, ListItemIcon, ListItemText, makeStyles, styled, Typography, useMediaQuery, withTheme } from '@material-ui/core';
import { Cancel, CheckCircle, PeopleOutline, Business, Inbox } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { apiClient, usePermissions } from '../../http';

import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { EventChanges } from '../../common/EventChanges';
import { useRichTranslate } from '../../common/useRichTranslate';
import FunctionField from '../../common/fields/FunctionField';

import { useDraftGameApprovals } from './DraftGameActions';
import { UpdateGameAlert } from './DraftGameForm';
import { TeamField } from '../teams/TeamField';
import { OfficeField } from '../offices/OfficeField';
import { GameField } from '../games/GameField';

const APPROVED = 'Approved';
const DECLINED = 'Declined';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    '& button[type="submit"]': {
      color: theme.palette.error.main,
    },
  },
  actions: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  reasonTitle: {
    fontWeight: 500,
  },
  reasonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginInline: theme.spacing(2),
  }
}))

const hasRespondPermission = (permissions, approval) => {
  if (permissions.some(p => p?.roleType === 'System')) return true;

  const { targetType, targetId } = approval;
  const approvePermissions = permissions.filter(p => p.scopes.includes('scheduling:approve'));

  if (targetType === 'Office') {
    return approvePermissions.some(p => p.roleType === 'Office' && p.officeIds?.includes(targetId));
  } else if (targetType === 'Team') {
    return approvePermissions.some(p => p.roleType === 'Team' && p?.teamIds?.includes(targetId));
  }

  return false;
}

const ApproveIcon = styled(withTheme(CheckCircle))(props => ({
  color: props.theme.palette.success.main,
}))

const StatusIcon = ({ status }) => {
  switch (status) {
    case APPROVED:
      return <ApproveIcon fontSize="small" />
    case DECLINED:
      return <Cancel color="error" fontSize="small" />
    default:
      return null;
  }
}

export const RequestReasonField = () => {
  const translate = useTranslate();
  const classes = useStyles();

  const renderReason = request => {
    if (!request?.requestReason) return null;
    return <div className={classes.reasonContainer}>
      <Typography className={classes.reasonTitle}>{translate("resources.draftGames.fields.requestReason")}</Typography>
      <Typography variant="body1" color="textSecondary">{request.requestReason}</Typography>
    </div>
  }

  return <FunctionField source="requestReason" label="" render={renderReason} />
}

export const RequestCreatedByField = () => {
  const translate = useTranslate();
  const classes = useStyles();

  const renderCreatedByAuthor = request => {
    if (!request?.createdByAuthor || !request?.createdAt) return null;
    return <div className={classes.reasonContainer}>
      <Typography className={classes.reasonTitle}>{translate("resources.draftGames.fields.requested_by")}</Typography>
      <Typography variant="body1" color="textSecondary">{request.createdByAuthor} -  {moment.tz(request.createdAt, request.timezone).format('LLLL')}</Typography>
    </div>
  }

  return <FunctionField source="createdByAuthor" label="" render={renderCreatedByAuthor} />
}

const TargetLabel = ({ targetId, targetType, link = false }) => {
  const TargetField = targetType === 'Team' ? TeamField : OfficeField;
  return <RecordContextProvider value={{ targetId }}>
    <TargetField source="targetId" link={link} />
  </RecordContextProvider>
}

const ApprovalRow = ({ approval, handleClick, loading }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const permissions = usePermissions();

  const { targetType, targetId, status, comments } = approval;

  const approvalStatus = translate(`resources.draftGameApprovals.values.${status}`);
  const secondary = [ approvalStatus, comments ].filter(Boolean).join(' - ');
  const canRespond = hasRespondPermission(permissions, approval);

  const onClick = responseType => handleClick({ ...approval, responseType });

  return <>
    <ListItem>
      <ListItemIcon>
        <Badge badgeContent={<StatusIcon status={status} />} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          {targetType === 'Team' ? <PeopleOutline /> : <Business />}
        </Badge>
      </ListItemIcon>
      <ListItemText
        primary={<TargetLabel targetId={targetId} targetType={targetType} link={canRespond} />}
        secondary={<span>{secondary}</span>}
      />
      {canRespond && <div className={classes.actions}>
        <Button onClick={() => onClick(DECLINED)} disabled={status === DECLINED || loading}>{translate('ra.action.decline')}</Button>
        <Button onClick={() => onClick(APPROVED)} color="primary" disabled={status === APPROVED || loading}>{translate('ra.action.approve')}</Button>
      </div>}
    </ListItem>
    <Divider />
  </>
}

const ConfirmModel = ({ approval, willPublish, willCancel, gameToUpdate, handleClose, refetch, setShouldRefresh }) => {
  const translate = useRichTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const handleClick = (e) => e.stopPropagation(); // needed to prevent edit dialog from opening when clicking inside the dialog

  const handleRespond = async (values) => {
    const { id, responseType, comments } = values;

    apiClient(`/draftGameApprovals/${id}/respond`, {
      method: 'POST',
      data: {
        status: responseType,
        comments: comments || '',
        scheduleId: gameToUpdate?.scheduleId,
      }
    })
      .then(({ data }) => {
        const { isPublished, isCancelled, isActive } = data;
        if (isPublished) {
          notify(translate(`resources.draftGameApprovals.notifications.approved_and_published`), 'info');
          refresh();
        } else if (isCancelled) {
          notify(translate(`resources.draftGameApprovals.notifications.declined_and_cancelled`), 'info');
          refetch();
          setShouldRefresh(true);
        } else {
          const action = responseType.toLowerCase();
          notify(translate(`resources.draftGameApprovals.notifications.request_${action}`), 'info');
          refetch();
          isActive && setShouldRefresh(true);
        }
      })
      .catch(() => {
        notify(translate(`resources.draftGameApprovals.notifications.respond_error`), 'error');
      })

    handleClose();
  }

  const declined = approval?.responseType === DECLINED;
  const action = declined ? 'decline' : 'approve';

  const showGameAlert = willPublish || willCancel;
  const alertLabel = declined
    ? translate('resources.draftGameApprovals.alerts.cancel_draft')
    : translate('resources.draftGameApprovals.alerts.publish_game', { game: <GameField source="updatedGameId" record={{ updatedGameId: gameToUpdate?.id }} includeDate="full" includeTime="full" includeSurface="full" /> })

  // if cancelled and will publish, show Active status on EventChanges component (will change to Active after approval is submitted)
  if (gameToUpdate?.status === 'Cancelled' && willPublish) {
    gameToUpdate.status = 'Active';
  }

  return <Dialog open={approval} onClose={handleClose} fullWidth maxWidth="sm" onClick={handleClick}>
    <DialogTitle>{translate(`resources.draftGameApprovals.labels.${action}_request`)}</DialogTitle>
    <DialogContent>
      {showGameAlert
        ? <div className={classes.alert}>
          <UpdateGameAlert updatedGameId={gameToUpdate?.id} alertLabel={alertLabel} severity={declined ? 'warning' : 'info'} />
        </div>
        : <DialogContentText>{translate(`resources.draftGameApprovals.messages.confirm_${action}_request`)}</DialogContentText>
      }
      <EventChanges previousEvent={gameToUpdate} indicateChangeRemoval={declined} hideAlert />
      <RecordContextProvider value={approval}>
        <SimpleForm
          save={handleRespond}
          initialValues={{ comments: '' }}
          toolbar={<DialogFormToolbar className={declined ? classes.deleteButton : undefined} submitLabel={`ra.action.${action}`} onCancel={handleClose} />}
        >
          {!willPublish && <TextInput source="comments" helperText="resources.draftGameApprovals.helpers.approval_comments" multiline minRows="3" fullWidth variant="outlined" />}
        </SimpleForm>
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

const DraftGameApprovalDialog = ({ isOpen, setOpen, gameToUpdate }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const draftGame = useRecordContext();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [approval, setApproval] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { data: approvals, loading, refetch } = useDraftGameApprovals(draftGame?.id);

  const willPublish = approval && approvals?.filter(a => a.id !== approval?.id).every(a => a.status === APPROVED) && approval.responseType === APPROVED;
  const willCancel = approval && approvals?.filter(a => a.id !== approval?.id).every(a => a.status !== DECLINED) && approval.responseType === DECLINED;

  const handleClick = (e) => e.stopPropagation(); // needed to prevent edit dialog from opening when clicking inside the dialog
  const handleClose = () => {
    if (shouldRefresh) refresh();
    setOpen(false);
  };

  return <>
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="sm" onClick={handleClick}>
      <DialogTitle>{translate('resources.draftGameApprovals.labels.approvals')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{translate('resources.draftGameApprovals.messages.request_reschedule')}</DialogContentText>
        <List>
          {loading && <LinearProgress color="primary" variant="indeterminate" />}
          <Divider />
          {(approvals || []).map(approval => <ApprovalRow key={approval.id} approval={approval} handleClick={setApproval} loading={loading} />)}
        </List>
        <RequestReasonField />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">{translate('ra.action.cancel')}</Button>
      </DialogActions>
    </Dialog>
    {approval && <ConfirmModel
      approval={approval}
      willPublish={willPublish}
      willCancel={willCancel}
      gameToUpdate={gameToUpdate}
      refetch={refetch}
      setShouldRefresh={setShouldRefresh}
      handleClose={() => setApproval(null)}
    />}
  </>
}

export const DraftGameApprovalAction = ({ size = "small", hideLabel = false, ...props }) => {
  const translate = useTranslate();
  const draftGame = useRecordContext();
  const [ isOpen, setOpen ] = useState(false);
  const games = useSelector(store => store.admin.resources.games.data);

  const gameToUpdate = games?.[draftGame?.updatedGameId];

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  }

  if (!gameToUpdate) return null;
  return <>
    <Button color="primary" size={size} startIcon={<Inbox />} onClick={handleClick}>{!hideLabel && translate('resources.draftGameApprovals.labels.approvals')}</Button>
    {isOpen && <DraftGameApprovalDialog isOpen={isOpen} setOpen={setOpen} gameToUpdate={gameToUpdate} {...props} />}
  </>
}
