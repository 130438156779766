import React from 'react';
import { FormInput, NumberInput, useTranslate } from 'react-admin';
import { Table, TableHead, TableBody, TableRow, TableCell, styled } from '@material-ui/core'
import { useSport } from '../../http';

const PositionCell = styled(TableCell)(({ theme }) => ({
  minWidth: theme.spacing(12),
  padding: theme.spacing(1),
}))

const LimitCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: theme.spacing(10),
  padding: theme.spacing(1),
  '&:last-child': {
    paddingRight: theme.spacing(1),
  }
}))

const LimitFormInput = styled(FormInput)(({ theme }) => ({
  input: {
    width: theme.spacing(10),
    margin: 0,
  }
}))

const LimitInput = ({ source, label }) =>
  <LimitFormInput input={
    <NumberInput source={source} step={1} label={label} variant="outlined" />
  } />

export const LimitsInput = ({ source }) => {
  const sport = useSport();
  const translate = useTranslate();
  return <Table selectable={false} fullWidth>
    <TableHead displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow>
        <PositionCell>{translate('resources.rulebooks.labels.position')}</PositionCell>
        <LimitCell>{translate('ra.validation.min')}</LimitCell>
        <LimitCell>{translate('ra.validation.max')}</LimitCell>
      </TableRow>
    </TableHead>
    <TableBody displayRowCheckbox={false}>
      <TableRow>
        <PositionCell>{translate('resources.rulebooks.labels.players')}</PositionCell>
        <LimitCell>
          <LimitInput source={`${source}.players.min`} label={translate('ra.validation.min')} />
        </LimitCell>
        <LimitCell>
          <LimitInput source={`${source}.players.max`} label={translate('ra.validation.max')} />
        </LimitCell>
      </TableRow>
      {sport === 'Hockey' && <TableRow>
        <PositionCell>{translate('resources.rulebooks.labels.skaters')}</PositionCell>
        <LimitCell>
          <LimitInput source={`${source}.skaters.min`} label={translate('ra.validation.min')} />
        </LimitCell>
        <LimitCell>
          <LimitInput source={`${source}.skaters.max`} label={translate('ra.validation.max')} />
        </LimitCell>
      </TableRow>}
      {sport === 'Hockey' && <TableRow>
        <PositionCell>{translate('resources.rulebooks.labels.goalies')}</PositionCell>
        <LimitCell>
          <LimitInput source={`${source}.goalies.min`} label={translate('ra.validation.min')} />
        </LimitCell>
        <LimitCell>
          <LimitInput source={`${source}.goalies.max`} label={translate('ra.validation.max')} />
        </LimitCell>
      </TableRow>}
      <TableRow>
        <PositionCell>{translate('resources.rulebooks.labels.staff')}</PositionCell>
        <LimitCell>
          <LimitInput source={`${source}.staff.min`} label={translate('ra.validation.min')} />
        </LimitCell>
        <LimitCell>
          <LimitInput source={`${source}.staff.max`} label={translate('ra.validation.max')} />
        </LimitCell>
      </TableRow>
    </TableBody>
  </Table>;
}
