import React, { useState } from 'react';
import { Datagrid, DatagridBody, DatagridRow, useRecordContext, useResourceContext } from 'react-admin';

import { isAuthorized } from '../Authorize';

const DialogDatagridRow = ({ dialog, save, onDelete, isRowSelectable, ...props }) => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);

  const canEdit = isAuthorized(record, resource, 'edit');
  const selectable = isRowSelectable ? isRowSelectable(record.id) : true;
  const canClickRow = canEdit && selectable;

  const [ isOpen, setOpen ] = useState(false);

  const openModal = () => setOpen(true);
  const rowClick = canClickRow ? openModal : null

  const handleClose = () => {
    setOpen(false);
  }

  let handleSave;
  if (save) {
    handleSave = (...values) => {
      save(...values)
      handleClose()
    }
  }

  let handleDelete;
  if (onDelete) {
    handleDelete = (...values) => {
      onDelete(...values);
      handleClose()
    }
  }

  const style = canClickRow ? { cursor: 'pointer' } : undefined;
  return <>
    <DatagridRow {...props} rowClick={rowClick} style={style} />
    { canClickRow && dialog && isOpen && React.createElement(dialog, { ...props, record, resource, initialValues: record, isOpen, save: handleSave, onDelete: handleDelete, handleClose })}
  </>
}

const DialogDatagridBody = ({ dialog, save, canDelete, onDelete, rowOptions = {}, ...props }) => <DatagridBody {...props} row={<DialogDatagridRow dialog={dialog} save={save} canDelete={canDelete} onDelete={onDelete} {...rowOptions} />} />
export const DialogDatagrid = ({ dialog, save, canDelete, onDelete, bodyOptions = {}, rowOptions = {}, ...props }) => <Datagrid {...props} body={<DialogDatagridBody dialog={dialog} save={save} canDelete={canDelete} onDelete={onDelete} rowOptions={rowOptions} {...bodyOptions} />} />
