import React from 'react';
import { EditButton, useTranslate, useRecordContext, FunctionField, TextField } from 'react-admin';
import { Table, TableBody, Button } from '@material-ui/core';
import { Directions } from '@material-ui/icons';

import Row from '../../../common/cards/TableRow';
import InfoCard from '../../../common/cards/InfoCard';
import { isAuthorized } from '../../../common/Authorize';

import { getVenueCity, getVenueName } from '../util';

const DirectionsButton = ({ venue }) => {
  const translate = useTranslate()

  if (!venue?.address) return null;
  const href = `https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=${getVenueCity(venue)}`;
  return <Button color="primary" size="small" startIcon={<Directions />} href={href} target="_blank">
    {translate('ra.action.directions')}
  </Button>
}

export default props => {
  const venue = useRecordContext(props);
  const canEditVenue = isAuthorized(venue, 'venues', 'edit');

  const city = getVenueCity(venue, true, true, false)
  return <InfoCard title="resources.venues.name" actions={[
    canEditVenue && <EditButton label="resources.venues.actions.edit" record={venue} />,
    <DirectionsButton venue={venue} />
  ]}>
    <Table>
      <TableBody>
        <Row label="resources.venues.fields.name">
          <FunctionField source="name" render={getVenueName} />
        </Row>

        <Row label="resources.venues.fields.address">
          {venue.address && <TextField source="address" />}
          {venue.address && city && <br />}
          {city && <FunctionField source="city" render={() => city} />}
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
