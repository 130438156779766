import { GAME_FORFEITED, SCORESHEET_RESET, SCORE_UPDATED } from "../actions/index.js";

export default (state = null, action) => {
  switch (action.type) {
    case SCORE_UPDATED: {
      if (action.payload.event.score == null) return state;
      return action.payload.event.score;
    }
    case GAME_FORFEITED: {
      if (action.payload.event.score == null) return state;
      return action.payload.event.score;
    }
    case SCORESHEET_RESET: {
      return null;
    }

    default:
      return state;
  }
}
