import React, { Fragment, useState } from 'react';
import { SimpleForm, TextInput, Button, useNotify, useRefresh, useTranslate, useUnselectAll } from "react-admin";
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { apiClient, useScopes } from '../../http';

import { isAuthorized } from "../../common/Authorize";
import { GameStatusEnumInput } from '../../common/inputs/EnumInputs';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const validateStatus = values => {
  const errors = {};
  if (!values.status) errors.status = 'ra.validation.required';
  return errors;
}

const PracticeStatusForm = props => {
  return <SimpleForm {...props} {...inputProps} validate={validateStatus} >
    <GameStatusEnumInput source="status" defaultValue="Active" {...inputProps} />
    <TextInput source="comments" multiline minRows="3" {...inputProps} />
  </SimpleForm>
}

const PracticeStatusDialog = ({ isOpen, onSubmit, onClose }) => {
  const translate = useTranslate();
  return <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.practices.labels.practice_status')}</DialogTitle>
    <DialogContent>
      <PracticeStatusForm initialValues={{ status: 'Active', comments: '' }} save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.update" onCancel={onClose} />
      } />
    </DialogContent>
  </Dialog>
}

const PracticeStatus = ({ selectedIds, resource }) => {
  const [ open, setOpen ] = useState(false);
  const unselectAll = useUnselectAll(resource);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onClose = () => {
    setOpen(false);
    unselectAll();
  }

  const onSubmit = (values) => {
    apiClient(`/practices/bulkStatus`, {
      method: 'POST',
      data: {
        practiceIds: selectedIds,
        status: values.status,
        comments: values.comments
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        onClose();
        refresh();
        notify(translate('resources.practices.notifications.change_practice_status', {
          smart_count: count,
          status: translate(`resources.games.values.status.${values.status}`, { _: values.status }),
        }))
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify('resources.practices.notifications.change_practice_status_error', selectedIds.length)
      })
  }

  return <>
    <Button label={translate('resources.practices.labels.set_practice_status')} onClick={() => setOpen(true)} disabled={!selectedIds.length} />
    <PracticeStatusDialog
      isOpen={open}
      selectedIds={selectedIds}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  </>
}

export const PracticeBulkActions = props => {
  const scopes = useScopes();
  const canChangeStatuses = isAuthorized(scopes, 'practices', 'changeStatus');

  return canChangeStatuses && <PracticeStatus {...props} />
}
