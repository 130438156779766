import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button, Dialog, List, ListItem, ListItemText, ListItemIcon, DialogActions } from '@material-ui/core';
import { Add, StarBorder, UnarchiveOutlined, PlaylistAdd } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';

import { FF_BRACKETS } from '@hisports/common/featureFlags';

import { useFlag } from '../../../http';
import { BracketDialog, BracketIcon } from '../../schedules/dialogs/BracketDialog';

export const AddDraftDialog = ({ schedule, isOpen, handleClose, showSchedule, showBrackets, showDraftGamesGenerator }) => {
  const isEnabled = useFlag()
  const translate = useTranslate();
  const history = useHistory()
  const [ bracketsOpen, setBracketsOpen ] = useState(false);
  const scheduleId = schedule?.id

  const onBracketsClose = (cancelled = false) => {
    setBracketsOpen(false)
    if (!cancelled) history.push(`/schedules/${scheduleId}/show/games`)
  }

  return <Dialog open={isOpen} onClose={handleClose}>
    <div>
      <List>
        <ListItem button component={Link} to={{ pathname: '/drafts/create', search: '?type=Generated', state: { scheduleId, type: 'Generated' } }}>
          <ListItemIcon><StarBorder /></ListItemIcon>
          <ListItemText
            primary={translate(`resources.drafts.labels.generate_automatically`)}
            secondary={translate('resources.drafts.messages.generate_single_double')} />
        </ListItem>
        <ListItem button component={Link} to={{ pathname: '/drafts/create', search: '?type=Upload', state: { scheduleId, type: 'Upload' } }}>
          <ListItemIcon><UnarchiveOutlined /></ListItemIcon>
          <ListItemText
            primary={translate('ra.input.file.upload')}
            secondary={translate('ra.input.file.upload_csv')} />
        </ListItem>
        {showBrackets && isEnabled(FF_BRACKETS) && <ListItem button onClick={() => setBracketsOpen(true)}>
          <ListItemIcon><BracketIcon /></ListItemIcon>
          <ListItemText
            primary={translate('resources.schedules.labels.bracket_schedule')}
            secondary={translate('resources.schedules.labels.bracket_info')}
          />
        </ListItem>}
        <ListItem button component={Link} to={{ pathname: '/drafts/create', search: '?type=Manual', state: { scheduleId, type: 'Manual' } }}>
          <ListItemIcon><PlaylistAdd /></ListItemIcon>
          <ListItemText
            primary={translate('resources.drafts.labels.manual_entry')}
            secondary={translate('resources.drafts.messages.draft_multiple_games')} />
        </ListItem>
      </List>
    </div>
    {showBrackets && isEnabled(FF_BRACKETS) && <BracketDialog scheduleId={scheduleId} isOpen={bracketsOpen} onClose={onBracketsClose} />}
    {showSchedule && <DialogActions>
      <Button component={Link} color="primary" to={`/schedules/${scheduleId}/show`}>{translate('ra.action.skip')}</Button>
    </DialogActions>}

  </Dialog>
}

export const AddDraftButton = ({ size, variant }) => {
  const schedule = useRecordContext();
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.drafts.actions.add')}</Button>
    <AddDraftDialog schedule={schedule} isOpen={isOpen} handleClose={() => setOpen(false)} />
  </>
}
