import { getVenueCity } from "../venues/util";

export const isSystemSurface = surface => ['TBA', 'TBD', 'NDA'].includes(surface?.alias)

export const getSurfaceName = (surface, includeVenue = true) => {
  if (!surface) return;
  let name = surface.name;

  if (includeVenue && surface?.venue?.name && surface.venue.name !== surface.name) {
    name = `${surface.venue.name} - ${name}`;
  }
  if (surface?.fraction) {
    name = `${name} (${surface?.fraction})`
  }

  return name;
}

export const getSurfaceCity = (surface, includeCountry = true, includeRegion = true, includeAddress = true) => {
  if (!surface) return;
  if (isSystemSurface(surface)) return;
  return getVenueCity(surface.venue, includeCountry, includeRegion, includeAddress);
}

export const getSurfaceAttributes = (surface, translate) => {
  if (!surface) return;
  if (isSystemSurface(surface)) return;

  const attributes = [];
  if (surface.type) attributes.push(getSurfaceType(surface, translate))
  if (surface.size) attributes.push(getSurfaceSize(surface, translate))

  return attributes.join(' ');
}

export const getSurfaceSize = (surface, translate) => {
  if (!surface?.size || !translate) return;
  if (isSystemSurface(surface)) return;
  return translate(`resources.surfaces.values.size.${surface?.size}`, { _: surface?.size });
}

export const getSurfaceType = (surface, translate) => {
  if (!surface?.type || !translate) return;
  if (isSystemSurface(surface)) return;
  return translate(`resources.surfaces.values.type.${surface.type}`, { _: surface.type })
}
