import React from 'react';
import { TextField, NumberField } from 'react-admin';

import { SectionDatagrid } from './SectionModal';

export const SectionGrid = props =>
  <SectionDatagrid size="medium" {...props}>
    <TextField source="name" />
    <NumberField source="order" />
  </SectionDatagrid>
