import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { InlineDateInput } from '../../common/inputs/DateInput';
import EditorInput from '../../common/inputs/EditorInput'
import ImageInput from '../../common/inputs/ImageInput'

import { OfficeInput } from '../offices/OfficeInput';

const validate = values => {
  const errors = {}

  if (!values.title) {
    errors.title = 'ra.validation.required';
  } else if (values.title.length > 80) {
    errors.title = 'ra.validation.title_too_long';
  }
  if (!values.date) errors.date = 'ra.validation.required';
  if (!values.content) errors.content = 'ra.validation.required';
  if (!values.officeId) errors.officeId = 'ra.validation.required';

  return errors;
}

const inputProps = {
  resource: 'posts',
  basePath: '/posts',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const PostForm = props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} sm={6} md={8}>
        <TextInput source="title" autoFocus {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <InlineDateInput source="date" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <EditorInput source="content" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="officeId" filter={{ type: { neq: 'Historical' } }} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="coverId" helperText="ra.message.optional" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
