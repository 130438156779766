import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { FileTypeEnumInput } from '../../common/inputs/EnumInputs';


import DocumentInput from './GameDocumentInput';
import { GameInput } from '../games/GameInput';

const validate = values => {
  const errors = {}

  if (!values.file) errors.file = 'ra.validation.required'
  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.type) errors.type = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'gameDocuments',
  basePath: '/gameDocuments',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const GameDocumentForm = props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <GameInput source="gameId" disabled {...inputProps} />
      </Grid>
      <Grid item xs={12} md={3}>
        <FileTypeEnumInput source="type" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={9}>
        <TextInput source="name" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <DocumentInput source="file" helperText="resources.gameDocuments.messages.max_size" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
