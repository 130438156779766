import React, { Fragment, useRef } from 'react'
import { SimpleForm, useTranslate } from "react-admin";
import createCalculator from 'final-form-calculate';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { isEmpty } from '@hisports/parsers';

import { DialogFormToolbar } from '../../../../common/dialogs/DialogForm';
import { ScheduleInput } from '../../ScheduleInput';
import { ScheduleGroupInput } from '../../../groups/GroupInput';
import { TeamInput } from '../../../teams/TeamInput';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const validate = ({ scheduleId: crossScheduleId, groupId: crossGroupId, teamId: crossTeamId }, scheduleId, groupId, matrixTeams = []) => {
  const errors = {};

  if (!crossScheduleId) errors.scheduleId = 'ra.validation.required'
  if (matrixTeams.some(team => team.teamId === crossTeamId)) errors.teamId = 'resources.draftGames.validations.matrix_team_duplicate';
  if (crossScheduleId === scheduleId && crossGroupId === groupId) errors.teamId = 'resources.draftGames.validations.same_group';
  if (!crossTeamId) errors.teamId = 'ra.validation.required';

  return errors
}

const AddCrossTeamForm = ({ onSubmit, schedule, ...props }) => {
  const translate = useTranslate();
  const seasonId = schedule.seasonId

  const decorators = useRef([createCalculator({
    field: 'scheduleId',
    updates: {
      groupId: (scheduleId, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.scheduleId !== scheduleId) return
        return values?.groupId
      },
      teamId: (scheduleId, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.scheduleId !== scheduleId) return
        return values?.teamId
      }
    }
  }, {
    field: 'groupId',
    updates: {
      teamId: (groupId, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.groupId !== groupId) return
        return values?.teamId
      }
    }
  })])

  return <SimpleForm save={onSubmit} decorators={decorators.current} component={Fragment}
    toolbar={<DialogFormToolbar submitLabel="ra.action.add" />}
    {...props}>
    <ScheduleInput source="scheduleId" label={translate('resources.draftGames.labels.cross_schedule')} {...inputProps} />
    <ScheduleGroupInput source="groupId" showNone={translate('ra.message.no_group')} defaultValue={null} {...inputProps} />
    <TeamInput source="teamId" label={translate('resources.draftGames.labels.cross_team')} filter={{ seasonId, _scope: 'Tenant' }} {...inputProps} />
  </SimpleForm>
}

export const AddCrossTeamDialog = ({ isOpen, setOpen, schedule, groupId, onSubmit, teams, ...props }) => {
  const translate = useTranslate();
  const scheduleId = schedule?.id

  return <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={() => setOpen(false)} {...props}>
    <DialogTitle>{translate('resources.draftGames.labels.add_cross_team')}</DialogTitle>
    <DialogContent>
      <AddCrossTeamForm onSubmit={onSubmit} schedule={schedule} validate={values => validate(values, scheduleId, groupId, teams)} initialValues={{ scheduleId }} />
    </DialogContent>
  </Dialog>
}
