import React from 'react';
import { Datagrid, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import FunctionField from '../../common/fields/FunctionField';

import { SurfaceField } from '../surfaces/SurfaceField';

const useStyles = makeStyles({
  headerRow: {
    display: 'none',
  },
  order: {
    width: '10%',
  }
})

const renderOrder = (teamarena = {}, source, resource, translate) => {
  const { order } = teamarena;
  return translate(`resources.teamArenas.values.order.${order || 1}`, { _: `${order}` })
}


const renderSlot = (teamarena = {}, source, resource, translate) => {
  const { day, startTime, endTime } = teamarena
  if (!day || !startTime || !endTime) return translate('resources.teamArenas.labels.not_set');
  return `${translate(`ra.date.days.long.${day}`, { _: day })} ${startTime} - ${endTime}`
}

export const TeamArenasGrid = ({ ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return <Datagrid size="medium" classes={classes} {...props}>
    <FunctionField source="order" label={translate('resources.teamArenas.fields.order')} render={renderOrder} cellClassName={classes.order} />
    <SurfaceField source="arenaId" label={translate('resources.teamArenas.fields.arenaId')} includeAttributes="full" includeCity="full" />
    <FunctionField label={translate('resources.teamArenas.fields.slot')} render={renderSlot} />
  </Datagrid>
}
