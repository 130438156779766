import React, { Fragment } from 'react';
import { RecordContextProvider, useRecordContext, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import moment from 'moment-timezone';

import { validateGameNumber } from '@hisports/scheduler';
import { getGameNumberValidationError } from '@hisports/scheduler/src/sequences/sequences';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { useSchedulingContext } from '../../../common/calendar/SchedulingContext';
import { GameForm } from '../../games/GameForm';
import { draftLabel } from '../../games/GameAvailabilityInfo';
import { useFlag } from '../../../http';

export const validate = (values, schedule, draft, limitedView, translate) => {
  const errors = {};

  if (values.arenaId || values.startTime || values.endTime) {
    if (!values.arenaId) errors.arenaId = 'ra.validation.required'
    if (!values.startTime) errors.startTime = 'ra.validation.required'
    if (!values.endTime) errors.endTime = 'ra.validation.required'
  }

  if (draft?.type === 'Generated') {
    if (values.round == undefined) {
      errors.round = 'ra.validation.required';
    } else if (values.round < 1) {
      errors.round = translate('ra.validation.minValue', { min: 1 });
    }
  }

  if (!values.homeTeamId && values.isApproved) errors.homeTeamId = 'ra.validation.required'
  if (!values.awayTeamId && values.isApproved) errors.awayTeamId = 'ra.validation.required'

  if (values.startTime && values.endTime) {
    if (!moment.tz(values.startTime, values.timezone).isValid()) {
      errors.startTime = 'resources.games.validations.invalid_time'
    } else if (!moment.tz(values.endTime, values.timezone).isValid()) {
      errors.endTime = 'resources.games.validations.invalid_time'
    } else if (moment.tz(values.endTime, values.timezone).isBefore(values.startTime, 'minute')) {
      errors.endTime = 'resources.games.validations.end_before_start'
    } else if (moment.tz(values.endTime, values.timezone).diff(values.startTime, 'hours') > 6) {
      errors.endTime = 'resources.games.validations.invalid_time_check_am_pm'
    }

    if (limitedView) {
      const previousGame = draft?.games?.find(game => game.id === values.id);
      if (previousGame) {
        if (!moment.tz(values.startTime, values.timezone).isSame(previousGame.date, 'day') || !moment.tz(values.date, values.timezone).isSame(previousGame.date, 'day')) {
          errors.startTime = 'resources.games.validations.invalid_date'
        }
      }
    }
  }

  if (values.date && schedule?.startDate && moment.utc(schedule.startDate).isAfter(values.date)) {
    errors.startTime = 'resources.games.validations.before_schedule_date'
  } else if (values.date && schedule?.endDate && moment.utc(schedule.endDate).add(1, 'day').isBefore(values.date)) {
    errors.startTime = 'resources.games.validations.after_schedule_date'
  }

  if (!values.status) errors.status = 'ra.validation.required'

  if (!validateGameNumber(String(values.number), schedule.seasonId)) {
    const gameNumberError = getGameNumberValidationError(String(values.number), schedule.seasonId);
    errors.number = `resources.schedulesequences.validations.${gameNumberError}`
  }

  if (draft?.games.find(game => game.number === values?.number && game.id !== values?.id)) {
    errors.number = 'resources.drafts.validations.same_game_number'
  }

  return errors;
}

export const GameTitle = ({ game, isDraft }) => {
  const translate = useTranslate();
  const isEnabled = useFlag()

  let title = translate('resources.games.actions.add');

  if (game?.number != null || game?.id) {
    title = translate('resources.games.labels.game_number', { number: game?.number != null ? game.number : game.id })
    if (isDraft) {
      title = `${title} (${draftLabel(isEnabled, translate, game?.type)})`
    }
    if (game.date) {
      title = `${moment(game.date).format('ll')} / ${title}`
    }
    if (game.round) {
      title = `${translate('resources.drafts.labels.round', 1)} ${game.round} / ${title}`
    }
  }

  return <>{title}</> // eslint-disable-line react/jsx-no-useless-fragment
}

export default ({ game, schedule, isOpen, hideNumber, hideAvailabilities, deleteButton, onClose, onSave }) => {
  const translate = useTranslate();
  const draft = useRecordContext();
  const { limitedView, deferDraftConflict } = useSchedulingContext();
  let teamIds = [];

  if (draft && draft.teams) {
    teamIds = draft.teams.map(team => team.teamId)
  }
  const teamFilter = { id: { inq: teamIds } }
  const timeOptions = {};
  if (game?.date && !(game?.startTime || game?.endTime)) {
    timeOptions.openTo = 'hours'
    timeOptions.initialFocusedDate = game.date
  }

  return <Dialog
    open={isOpen}
    maxWidth="md"
    onClose={onClose}
    disableBackdropClick
  >
    {isOpen && <>
      <DialogTitle><GameTitle game={game} /></DialogTitle>
      <DialogContent>
        <RecordContextProvider value={game}>
          <GameForm
            validate={values => validate(values, schedule, draft, limitedView, translate)}
            draft={draft}
            destroyOnUnmount
            save={onSave}
            hideSchedule
            hideNumber={hideNumber}
            hideAvailabilities={hideAvailabilities}
            skipStatusValidation={deferDraftConflict}
            teamProps={{ filter: teamFilter, forceSelect: true, ignoreSchedule: true }}
            resetDate={false}
            timeOptions={timeOptions}
            component={Fragment}
            toolbar={
              <DialogFormToolbar submitLabel="ra.action.save" deleteButton={deleteButton} onCancel={onClose} />
            }
          />
        </RecordContextProvider>
      </DialogContent>
    </>}
  </Dialog>
}
