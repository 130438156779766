import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { SportsOutlined } from '@material-ui/icons';

import { isLegacyRulebookSeason } from '@hisports/scoresheet/src/util';

import ListCard from '../../../common/cards/ListCard';

import { GamePenaltyGrid } from '../../gamepenalties/GamePenaltyGrid';
import { ParticipantInput } from '../../participants/ParticipantInput';
import { CategoryInput } from '../../categories/CategoryInput';

export default props => {
  const team = useRecordContext(props);
  const translate = useTranslate();

  const isLegacy = isLegacyRulebookSeason(team?.seasonId);

  const filters = [
    <ParticipantInput source="participantId" variant="outlined" />,
    <CategoryInput source="game.categoryId" label="resources.games.fields.categoryId" variant="outlined" />,
  ]

  return <ListCard
    title={translate('resources.gamepenalties.name', 2)}
    icon={<SportsOutlined />}
    basePath="/gamepenalties"
    reference="gamepenalties"
    target="teamId"
    filter={{ _options: { includeMeta: isLegacy } }}
    sort={{ field: ['game.date', 'participant.lastName', 'participant.firstName'], order: ['DESC', 'ASC', 'ASC'] }}
    filters={filters}
    {...props}
  >
    <GamePenaltyGrid seasonId={team?.seasonId} multipleGames hideTeam rowOptions={{ isRowSelectable: () => false }} />
  </ListCard>
}
