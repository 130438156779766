import React from 'react';
import { EditButton, ShowButton, useEditContext, useResourceContext } from 'react-admin';
import { Divider } from '@material-ui/core';

import { ActionMenu } from '../../common/ActionMenu';
import { isAuthorized } from '../../common/Authorize';
import { TopToolbar } from '../../common/cards/Card';

import ChangesCard from './cards/ChangesCard';
import ResetAssignRulesModal from './ResetAssignRulesModal';
import ResetScoresheetModal from '../games/ResetScoresheetModal'
import InternalScoresheetActions from '../scoresheet/actions/InternalScoresheetActions';

export const GameActions = ({ hasEdit, hasShow, ...props }) => {
  const resource = useResourceContext(props)
  const { basePath, record } = useEditContext(props);
  const canEditResource = isAuthorized(record, resource, 'edit');
  const canShowResource = isAuthorized(record, resource, 'show');
  const canResetAssignRules = isAuthorized(record, resource, 'resetAssignRules');
  const canResetScoresheet = isAuthorized(record, resource, 'resetScoresheet');
  const canUseInternalActions = isAuthorized(record, resource, 'internal');

  return <TopToolbar>
    <ChangesCard />
    {hasEdit && canEditResource && <EditButton basePath={basePath} resource={resource} record={record} />}
    {hasShow && canShowResource && <ShowButton basePath={basePath} resource={resource} record={record} />}
    <ActionMenu>
      {canResetAssignRules && <ResetAssignRulesModal />}
      {canResetScoresheet && <ResetScoresheetModal />}
      {(canResetAssignRules || canResetScoresheet) && canUseInternalActions && <Divider />}
      {canUseInternalActions && <InternalScoresheetActions />}
    </ActionMenu>
  </TopToolbar>
}
