import React, { Fragment } from 'react';
import { FunctionField, GET_LIST, useLocale, useQuery, useRecordContext, useTranslate } from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';
import { BookOutlined } from '@material-ui/icons';

import { translateApiProperty } from '@hisports/common';
import { getInfractionOptionCodes } from '@hisports/scoresheet/src/util';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { AddRuleSectionButton, RuleSectionDatagrid } from '../../infractions/RuleSectionModal';
import { AddRuleButton, RuleDatagrid } from '../../infractions/RuleModal';
import { AddRuleOptionButton, RuleOptionDatagrid } from '../../infractions/RuleOptionModal';
import { AddInfractionButton } from '../../infractions/InfractionModal';
import { InfractionGrid } from '../../infractions/InfractionGrid';

const useStyles = makeStyles({
  thead: {
    display: 'none',
  }
})

const useRuleOptions = ruleId => useQuery({
  type: GET_LIST,
  resource: 'ruleOptions',
  payload: {
    filter: {
      ruleId,
      _include: ['infractions', 'members']
    }
  },
}, {
  enabled: ruleId != null,
})

const NameField = props => {
  const locale = useLocale();
  return <FunctionField {...props} render={record => [record.code, translateApiProperty(record, 'name', locale)].filter(Boolean).join(' ')} />;
}

const RuleSectionGrid = props => {
  const classes = useStyles();
  return <RuleSectionDatagrid classes={classes} {...props}>
    <NameField source="name" />
  </RuleSectionDatagrid>;
}

const RuleGrid = props => {
  const classes = useStyles();
  return <RuleDatagrid classes={classes} {...props}>
    <NameField source="name" />
  </RuleDatagrid>;
}

const RuleOptionGrid = props => {
  const rule = useRecordContext();
  const { data: ruleOptions } = useRuleOptions(rule?.id);

  if (!ruleOptions?.length) return null;

  const renderCodes = record => {
    const infractions = ruleOptions.find(({ id }) => id === record.id)?.infractions;
    const members = ruleOptions.find(({ id }) => id === record.id)?.members;
    if (!infractions || !members) return null;

    return getInfractionOptionCodes({ ...record, members }, infractions).join(' + ');
  }

  return <RuleOptionDatagrid {...props}>
    <FunctionField source="code" label="resources.sanctionMembers.labels.code" render={renderCodes} />
    <NameField source="name" />
  </RuleOptionDatagrid>;
}

const RuleOptionList = ({ office, ...props }) => {
  const rule = useRecordContext(props);
  const seasonId = useSeason();

  const canAddRuleOption = isAuthorized(office, 'ruleOptions', 'create')

  return <ListCard {...props}
    reference="ruleOptions"
    resource="ruleOptions"
    target="ruleId"
    sort={{
      field: ['order'],
      order: ['ASC'],
    }}
    perPage={25}
    rowsPerPageOptions={[]}
    addButton={canAddRuleOption && <AddRuleOptionButton initialValues={{ ruleId: rule?.id, officeId: office?.id, seasonId }} />}
    addPosition="footer"
    component={Fragment}
  >
    <RuleOptionGrid />
  </ListCard>
}

const InfractionList = ({ office, ...props }) => {
  const rule = useRecordContext(props);
  const seasonId = useSeason();

  const canAddInfraction = isAuthorized(rule, 'infractions', 'create');

  return <ListCard
    reference="infractions"
    resource="infractions"
    target="ruleId"
    sort={{ field: ['rule.order', 'type.order', 'code'], order: ['ASC', 'ASC', 'ASC'] }}
    perPage={25}
    rowsPerPageOptions={[]}
    addButton={canAddInfraction && <AddInfractionButton initialValues={{ ruleId: rule?.id, officeId: office?.id, seasonId }} office={office} />}
    addPosition="footer"
    component={Fragment}
  >
    <InfractionGrid rowOptions={{ office }} office={office} />
  </ListCard>
}

const RuleDetails = ({ office, ...props }) => {
  const translate = useTranslate()
  return <>
    <Typography variant="subtitle2">{translate('resources.infractions.labels.card.title')}</Typography>
    <Typography variant="body2" color="textSecondary">{translate('resources.infractions.labels.card.subtitle')}</Typography>
    <InfractionList office={office} {...props} />

    <Typography variant="subtitle2">{translate('resources.ruleOptions.labels.card.title')}</Typography>
    <Typography variant="body2" color="textSecondary">{translate('resources.ruleOptions.labels.card.subtitle')}</Typography>
    <RuleOptionList office={office} {...props} />
  </>
}

const RuleList = ({ office, ...props }) => {
  const section = useRecordContext(props);
  const seasonId = useSeason();

  const canAddRule = isAuthorized(office, 'ruleSections', 'create')

  return <ListCard {...props}
    reference="rules"
    resource="rules"
    target="sectionId"
    sort={{
      field: ['order', 'code', 'name'],
      order: ['ASC', 'ASC', 'ASC'],
    }}
    perPage={25}
    rowsPerPageOptions={[]}
    addButton={canAddRule && <AddRuleButton initialValues={{ sectionId: section?.id, officeId: office?.id, seasonId }} />}
    addPosition="footer"
    component={Fragment}
  >
    <RuleGrid expand={<RuleDetails office={office} />} />
  </ListCard>
}

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const canAddSection = isAuthorized(office, 'ruleSections', 'create');

  return <ListCard
    title="resources.ruleSections.labels.card.title"
    subtitle="resources.ruleSections.labels.card.subtitle"
    icon={<BookOutlined />}
    reference="ruleSections"
    target="officeId"
    filter={{ seasonId }}
    sort={{
      field: ['order', 'code', 'name'],
      order: ['ASC', 'ASC', 'ASC'],
    }}
    addPosition="footer"
    addButton={canAddSection && <AddRuleSectionButton initialValues={{ officeId: office?.id, seasonId }} />}
    rowsPerPageOptions={[]}
    perPage={99999}
  >
    <RuleSectionGrid expand={<RuleList office={office} />} />
  </ListCard>
}
