import React from 'react';
import { useRecordContext } from 'react-admin';
import { SupervisorAccount } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { AssignTargetGrid } from '../../assignTargets/AssignTargetGrid';
import { AddAssignTargetButton } from '../../assignTargets/AssignTargetModal';

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason()

  const canAddOverride = isAuthorized(office, 'assignTargets', 'create')
  return <ListCard
    title="resources.assignTargets.labels.card.title"
    subtitle="resources.assignTargets.labels.card.subtitle"
    icon={<SupervisorAccount />}
    collapsable
    resource="assignTargets"
    basePath="/assignTargets"
    addButton={canAddOverride && <AddAssignTargetButton initialValues={{ targetType: 'Office', targetId: office?.id }} />}
    filter={{
      or: [
        { and: [
          { targetType: 'Office' },
          { or: [
            { targetId: office.id },
            { officeId: office.id }
          ] }
        ] },
        { and: [
          { targetType: { inq: ['Schedule', 'Team'] } },
          { 'target.seasonId': seasonId },
          { or: [
            { 'target.officeId': office.id },
            { officeId: office.id }
          ] }
        ] }
      ]
    }}
    sort={{ field: 'id', order: 'ASC' }}
  >
    <AssignTargetGrid rowClick={false} />
  </ListCard>
}
