import { generateId } from "../util/index.js";

export const GAME_CREATED = "GAME_CREATED";
export const GAME_RESCHEDULED = "GAME_RESCHEDULED";
export const GAME_UPDATED = "GAME_UPDATED";
export const GAME_DELETED = "GAME_DELETED";

export const GAME_STARTED = "GAME_STARTED";
export const GAME_ENDED = "GAME_ENDED";
export const GAME_FORFEITED = "GAME_FORFEITED";

export const LINEUP_UPDATED = "LINEUP_UPDATED";
export const LINEUP_APPROVED = "LINEUP_APPROVED";
export const LINEUP_EXTRA_ADDED = "LINEUP_EXTRA_ADDED";
export const LINEUP_EXTRA_REMOVED = "LINEUP_EXTRA_REMOVED";
export const THROWS_INNINGS_UPDATED = "THROWS_INNINGS_UPDATED";

export const OFFICIAL_DELEGATED = "OFFICIAL_DELEGATED";
export const OFFICIAL_REQUESTED = "OFFICIAL_REQUESTED";
export const OFFICIAL_ASSIGNED = "OFFICIAL_ASSIGNED";
export const OFFICIAL_ACCEPTED = "OFFICIAL_ACCEPTED";
export const OFFICIAL_DECLINED = "OFFICIAL_DECLINED";

export const OFFICIAL_ADDED = "OFFICIAL_ADDED";
export const OFFICIAL_UPDATED = "OFFICIAL_UPDATED";
export const OFFICIAL_REMOVED = "OFFICIAL_REMOVED";
export const KEEPERS_UPDATED = "KEEPERS_UPDATED";

export const GOAL_SCORED = "GOAL_SCORED";
export const GOAL_EDITED = "GOAL_EDITED";
export const GOAL_DELETED = "GOAL_DELETED";

export const PENALTY_STARTED = "PENALTY_STARTED";
export const PENALTY_ENDED = "PENALTY_ENDED";
export const PENALTY_EDITED = "PENALTY_EDITED";
export const PENALTY_DELETED = "PENALTY_DELETED";

export const GOALIE_CHANGED = "GOALIE_CHANGED";
export const GOALIE_CHANGE_EDITED = "GOALIE_CHANGE_EDITED";
export const GOALIE_CHANGE_DELETED = "GOALIE_CHANGE_DELETED";
export const GOALIE_SHOTS_UPDATED = "GOALIE_SHOTS_UPDATED";

export const SETTINGS_UPDATED = "SETTINGS_UPDATED";
export const NOTES_UPDATED = "NOTES_UPDATED";
export const CERTIFICATION_FLAGS_UPDATED = "CERTIFICATION_FLAGS_UPDATED";
export const SCORE_UPDATED = "SCORE_UPDATED";

export const SCORESHEET_APPROVED = "SCORESHEET_APPROVED";
export const SCORESHEET_DISAPPROVED = "SCORESHEET_DISAPPROVED";
export const SCORESHEET_CERTIFIED = "SCORESHEET_CERTIFIED";
export const SCORESHEET_DECERTIFIED = "SCORESHEET_DECERTIFIED";
export const SCORESHEET_RESET = "SCORESHEET_RESET";

const commit = (type) => ({
  type: `${type}_COMMITTED`,
  // payload: server response will be merged in by redux-offline
});

const rollback = (type, payload) => ({
  type: `${type}_ROLLBACK`,
  // payload: server response will be merged in by redux-offline
  payload: {
    action: payload
  }
});

const event = (type, payload, meta = {}) => {
  const { seasonId = null, sport = null } = meta;
  const action = {
    type,
    payload,
    meta: {
      sport,
      seasonId,
      offline: {
        effect: {
          type: "event"
        },
        commit: commit(type),
        rollback: rollback(type, payload)
      }
    }
  };

  return action;
};

export function gameCreated(payload, meta) {
  return event(GAME_CREATED, payload, meta);
}
export function gameRescheduled(payload, meta) {
  return event(GAME_RESCHEDULED, payload, meta);
}
export function gameUpdated(payload, meta) {
  return event(GAME_UPDATED, payload, meta);
}
export function gameDeleted(payload, meta) {
  return event(GAME_DELETED, payload, meta);
}

export function gameStarted(payload, meta) {
  return event(GAME_STARTED, payload, meta);
}
export function gameEnded(payload, meta) {
  return event(GAME_ENDED, payload, meta);
}
export function gameForfeited(payload, meta) {
  return event(GAME_FORFEITED, payload, meta);
}


export function lineupUpdated(payload, meta) {
  return event(LINEUP_UPDATED, payload, meta);
}
export function lineupApproved(payload, meta) {
  return event(LINEUP_APPROVED, payload, meta);
}
export function lineupExtraAdded(payload, meta) {
  return event(LINEUP_EXTRA_ADDED, payload, meta);
}
export function lineupExtraRemoved(payload, meta) {
  return event(LINEUP_EXTRA_REMOVED, payload, meta);
}
export function throwsInningsUpdated(payload, meta) {
  return event(THROWS_INNINGS_UPDATED, payload, meta);
}

export function officialDelegated(payload, meta) {
  return event(OFFICIAL_DELEGATED, payload, meta);
}
export function officialRequested(payload, meta) {
  return event(OFFICIAL_REQUESTED, payload, meta);
}
export function officialAssigned(payload, meta) {
  return event(OFFICIAL_ASSIGNED, payload, meta);
}
export function officialAccepted(payload, meta) {
  return event(OFFICIAL_ACCEPTED, payload, meta);
}
export function officialDeclined(payload, meta) {
  return event(OFFICIAL_DECLINED, payload, meta);
}

export function officialAdded(payload, meta) {
  return event(OFFICIAL_ADDED, payload, meta);
}
export function officialUpdated(payload, meta) {
  return event(OFFICIAL_UPDATED, payload, meta);
}
export function officialRemoved(payload, meta) {
  return event(OFFICIAL_REMOVED, payload, meta);
}

export function keepersUpdated(payload, meta) {
  return event(KEEPERS_UPDATED, payload, meta);
}

export function goalScored(payload, meta) {
  return event(GOAL_SCORED, payload, meta);
}
export function goalEdited(payload, meta) {
  return event(GOAL_EDITED, payload, meta);
}
export function goalDeleted(payload, meta) {
  return event(GOAL_DELETED, payload, meta);
}

export function penaltyStarted(payload, meta) {
  return event(PENALTY_STARTED, payload, meta);
}
export function penaltyEnded(payload, meta) {
  return event(PENALTY_ENDED, payload, meta);
}
export function penaltyEdited(payload, meta) {
  return event(PENALTY_EDITED, payload, meta);
}
export function penaltyDeleted(payload, meta) {
  return event(PENALTY_DELETED, payload, meta);
}

export function goalieChanged(payload, meta) {
  return event(GOALIE_CHANGED, payload, meta);
}
export function goalieChangeEdited(payload, meta) {
  return event(GOALIE_CHANGE_EDITED, payload, meta);
}
export function goalieChangeDeleted(payload, meta) {
  return event(GOALIE_CHANGE_DELETED, payload, meta);
}
export function goalieShotsUpdated(payload, meta) {
  return event(GOALIE_SHOTS_UPDATED, payload, meta);
}


export function settingsUpdated(payload, meta) {
  return event(SETTINGS_UPDATED, payload, meta);
}
export function notesUpdated(payload, meta) {
  return event(NOTES_UPDATED, payload, meta);
}
export function certificationFlagsUpdated(payload, meta) {
  return event(CERTIFICATION_FLAGS_UPDATED, payload, meta);
}
export function scoreUpdated(payload, meta) {
  return event(SCORE_UPDATED, payload, meta);
}

export function scoresheetApproved(payload, meta) {
  return event(SCORESHEET_APPROVED, payload, meta);
}
export function scoresheetDisapproved(payload, meta) {
  return event(SCORESHEET_DISAPPROVED, payload, meta);
}
export function scoresheetCertified(payload, meta) {
  return event(SCORESHEET_CERTIFIED, payload, meta);
}
export function scoresheetDecertified(payload, meta) {
  return event(SCORESHEET_DECERTIFIED, payload, meta);
}
export function scoresheetReset(payload, meta) {
  return event(SCORESHEET_RESET, payload, meta);
}

const createEvent = (eventType, gameId, event = {}) => ({
  id: generateId(),
  gameId,
  timestamp: new Date().toISOString(),
  eventType,
  event
})

export const updateSettings = (gameId, settings, meta) => {
  const event = createEvent('settingsUpdated', gameId, settings);
  return settingsUpdated(event, meta);
}

export const addOfficial = (gameId, official, position, meta) => {
  const event = createEvent('officialAdded', gameId, {
    official,
    position,
  })

  return officialAdded(event, meta);
}

export const updateOfficial = (gameId, official, position, status, meta) => {
  const payload = { position, status };
  const { participantId, id } = official;
  if (participantId) {
    payload.participantId = participantId;
  } else {
    payload.officialId = id;
  }

  const event = createEvent('officialUpdated', gameId, payload)
  return officialUpdated(event, meta);
}

export const removeOfficial = (gameId, official, isNoShow, meta) => {
  const payload = {};
  const { participantId, id } = official;

  if (id) {
    payload.officialId = id;
  }
  if (participantId) {
    payload.participantId = participantId
  }
  if (isNoShow) {
    payload.isNoShow = true;
  }

  const event = createEvent('officialRemoved', gameId, payload)
  return officialRemoved(event, meta);
}

export const addLineupExtra = (gameId, teamId, participant, positions, number, isAffiliate, meta) => {
  const event = createEvent('lineupExtraAdded', gameId, {
    teamId,
    member: {
      participant,
      participantId: participant.id,
      positions,
      number,
      isAffiliate,
    }
  })
  return lineupExtraAdded(event, meta);
}

export const removeLineupExtra = (gameId, teamId, participantId, meta) => {
  const event = createEvent('lineupExtraRemoved', gameId, {
    teamId,
    participantId,
  })
  return lineupExtraRemoved(event, meta);
}

export const saveLineup = (gameId, teamId, members, meta) => {
  const event = createEvent('lineupUpdated', gameId, {
    teamId,
    members,
  });
  return lineupUpdated(event, meta);
}

export const approveLineup = (gameId, teamId, participantId, signature, meta) => {
  const event = createEvent('lineupApproved', gameId, {
    teamId,
    participantId,
    signature
  })

  return lineupApproved(event, meta);
}

export const updateThrowsInnings = (gameId, teamId, throwsInnings, meta) => {
  const event = createEvent('throwsInningsUpdated', gameId, {
    teamId,
    throwsInnings
  })

  return throwsInningsUpdated(event, meta);
}

export const updateKeepers = (gameId, payload, meta) => {
  const event = createEvent('keepersUpdated', gameId, payload);
  return keepersUpdated(event, meta);
}

export const startGame = (gameId, periods, meta) => {
  const event = createEvent('gameStarted', gameId, { periods });
  return gameStarted(event, meta)
}

export const addGoal = (gameId, goal, meta) => {
  const event = createEvent('goalScored', gameId, goal);
  return goalScored(event, meta);
}

export const editGoal = (gameId, goalId, goal, meta) => {
  const event = createEvent('goalEdited', gameId, {
    ...goal,
    goalId
  });

  return goalEdited(event, meta);
}

export const deleteGoal = (gameId, goalId, meta) => {
  const event = createEvent('goalDeleted', gameId, { goalId });
  return goalDeleted(event, meta);
}

export const startPenalty = (gameId, penalty, meta) => {
  const event = createEvent('penaltyStarted', gameId, penalty);
  return penaltyStarted(event, meta);
}

export const endPenalty = (gameId, penaltyId, endTime, meta) => {
  const event = createEvent('penaltyEnded', gameId, {
    penaltyId,
    endTime
  })

  return penaltyEnded(event, meta);
}

export const editPenalty = (gameId, penaltyId, penalty, meta) => {
  const event = createEvent('penaltyEdited', gameId, {
    ...penalty,
    penaltyId
  });

  return penaltyEdited(event, meta);
}

export const deletePenalty = (gameId, penaltyId, meta) => {
  const event = createEvent('penaltyDeleted', gameId, { penaltyId });
  return penaltyDeleted(event, meta);
}

export const changeGoalie = (gameId, goalieChange, meta) => {
  const event = createEvent('goalieChanged', gameId, goalieChange);
  return goalieChanged(event, meta);
}

export const editGoalieChange = (gameId, goalieChangeId, goalieChange, meta) => {
  const event = createEvent('goalieChangeEdited', gameId, {
    ...goalieChange,
    goalieChangeId
  })

  return goalieChangeEdited(event, meta);
}

export const deleteGoalieChange = (gameId, goalieChangeId, meta) => {
  const event = createEvent('goalieChangeDeleted', gameId, { goalieChangeId });
  return goalieChangeDeleted(event, meta);
}

export const updateGoalieShots = (gameId, goalies, meta) => {
  const event = createEvent('goalieShotsUpdated', gameId, { goalies })
  return goalieShotsUpdated(event, meta);
}

export const updateNotes = (gameId, notes, adminNotes, meta) => {
  const event = createEvent('notesUpdated', gameId, {
    notes,
    adminNotes,
  })

  return notesUpdated(event, meta);
}

export const saveCertificationFlags = (gameId, flags, meta) => {
  const event = createEvent('certificationFlagsUpdated', gameId, {
    flags
  });
  return certificationFlagsUpdated(event, meta);
}

export const saveScore = (gameId, score, meta) => {
  const event = createEvent('scoreUpdated', gameId, {
    score,
  });
  return scoreUpdated(event, meta);
}

export const endGame = (gameId, gameTime, meta) => {
  const event = createEvent('gameEnded', gameId, {
    gameTime
  })

  return gameEnded(event, meta);
}

export const approveScoresheet = (gameId, approvals, meta) => {
  const event = createEvent('scoresheetApproved', gameId, approvals);
  return scoresheetApproved(event, meta);
}

export const undoApprovalScoresheet = (gameId, meta) => {
  const event = createEvent('scoresheetDisapproved', gameId);
  return scoresheetDisapproved(event, meta);
}

export const certifyScoresheet = (gameId, meta) => {
  const event = createEvent('scoresheetCertified', gameId);
  return scoresheetCertified(event, meta);
}

export const undoCertifyScoresheet = (gameId, meta) => {
  const event = createEvent('scoresheetDecertified', gameId);
  return scoresheetDecertified(event, meta);
}

export const resetScoresheet = (gameId, meta) => {
  const event = createEvent('scoresheetReset', gameId);
  return scoresheetReset(event, meta);
}
