import React from 'react';
import { useTranslate } from 'react-admin';
import { SportsOutlined } from '@material-ui/icons';

import { isLegacyRulebookSeason } from '@hisports/scoresheet/src/util';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { GamePenaltyGrid } from '../../gamepenalties/GamePenaltyGrid';
import { TeamInput } from '../../teams/TeamInput';
import { CategoryInput } from '../../categories/CategoryInput';

export default props => {
  const translate = useTranslate()
  const seasonId = useSeason();

  const isLegacy = isLegacyRulebookSeason(seasonId);

  const filters = [
    <TeamInput source="teamId" variant="outlined" />,
    <CategoryInput source="game.categoryId" label="resources.games.fields.categoryId" variant="outlined" />,
  ]

  return <ListCard
    title={translate('resources.gamepenalties.name', 2)}
    icon={<SportsOutlined />}
    basePath="/gamepenalties"
    reference="gamepenalties"
    target="participantId"
    filter={{ 'game.seasonId': seasonId, _options: { includeMeta: isLegacy } }}
    sort={{ field: ['game.date', 'participant.lastName', 'participant.firstName'], order: ['DESC', 'ASC', 'ASC'] }}
    filters={filters}
    {...props}
  >
    <GamePenaltyGrid seasonId={seasonId} multipleGames hideParticipant rowOptions={{ isRowSelectable: () => false }} />
  </ListCard>
}
