import React, { useRef } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core'
import useComponentSize from '@rehooks/component-size'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  }
}))

export default ({ icon, primary, secondary }) => {
  const ref = useRef(null)
  const { width } = useComponentSize(ref)
  const classes = useStyles();

  return <Grid container alignItems="center" ref={ref}>
    {icon && width > 250 && <Grid item>
      {React.cloneElement(icon, { className: classes.icon })}
    </Grid>}
    <Grid item xs>
      {primary}

      {secondary && <Typography variant="body2" color="textSecondary">
        {secondary}
      </Typography>}
    </Grid>
  </Grid>
}
