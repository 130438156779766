import React, { Fragment, useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { Route } from 'react-router-dom';
import { ResourceContextProvider, useTranslate } from 'react-admin';
import { Card, CardContent, CardActions, Typography, styled } from '@material-ui/core'
import { TrendingUp } from '@material-ui/icons'

import { apiClient } from '../../http';
import { useSeason } from '../../common/inputs/SeasonSelector';

import ReportFilters from './ReportFilters';
import ReportFrame from './ReportFrame';


const ReportsIcon = styled(TrendingUp)({
  marginTop: -3,
  marginRight: 8,
  verticalAlign: 'middle',
})

const validate = reports => values => {
  const errors = {};

  if (!values.reportId) errors.reportId = 'ra.validation.required'

  const report = reports.find(report => report.id === values.reportId)
  if (report && report.targetType === 'Office' && !values.officeId) errors.officeId = 'ra.validation.required'
  if (report && report.targetType === 'Participant' && !values.participantId) errors.participantId = 'ra.validation.required';
  if (report && report.targetType === 'Team' && !values.teamId) errors.teamId = 'ra.validation.required'

  return errors;
}

const Report = () => {
  const seasonId = useSeason();
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ reports, setReports ] = useState([]);
  const translate = useTranslate();

  useEffect(() => {
    setLoading(true);
    apiClient(`/reports`)
      .then(res => {
        if (!res || !res.data) return [];
        const reports = res.data;
        return reports.sort((a, b) => {
          const type = a.type.localeCompare(b.type);
          if (type !== 0) return type;

          const targetType = a.targetType.localeCompare(b.targetType);
          if (targetType !== 0) return targetType;

          return a.name.localeCompare(b.name);
        })
      })
      .catch(err => {
        setError(err?.response?.status || -1)
        return []
      })
      .then(setReports)
      .finally(() => {
        setLoading(false);
      })
  }, [])

  const header = <CardActions>
    <Typography variant="h6" color="inherit"><ReportsIcon />{translate('resources.reports.name', 2)}</Typography>
  </CardActions>

  let message;
  if (loading) {
    message = translate('ra.page.loading')
  } else if (error && error !== 403) {
    message = translate('resources.reports.messages.error', { error })
  } else if (!reports.length) {
    message = translate('resources.reports.messages.empty')
  }

  if (message) return <>
    {header}
    <Card>
      <CardContent>{message}</CardContent>
    </Card>
  </>

  return <ResourceContextProvider value="reports" >
    <Form
      initialValues={{ seasonId }}
      onSubmit={() => ({})}
      validate={validate(reports)}
      render={({ handleSubmit }) =>
        <form onSubmit={handleSubmit}>
          {header}
          <ReportFilters reports={reports} />
          <ReportFrame reports={reports} />
        </form>
      } />
  </ResourceContextProvider>
}

export default [
  <Route path="/reports" exact component={Report} />
]
