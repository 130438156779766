import React, { useContext, useEffect, useMemo } from 'react';
import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useForm, Field, useFormState } from 'react-final-form';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { getSeasonDisplayName, SEASONS } from '@hisports/common/src/seasons';

import usePreferences from '../usePreferences';
import { useDefaultSeason, useSport } from '../../http';

export const SeasonContext = React.createContext([null, () => {}]);
export const SeasonProvider = props => {
  const defaultSeason = useDefaultSeason();
  const season = usePreferences('seasonId', defaultSeason);

  useEffect(() => {
    const [ current, setSeason ] = season;
    if (current != null) return;
    setSeason(defaultSeason)
  }, [ season, defaultSeason ])

  const value = useMemo(() => ({
    season,
    defaultSeason,
  }), [ season, defaultSeason ])

  return <div><SeasonContext.Provider value={value} {...props} /></div>
}

export const useSeason = () => {
  const { season: [ seasonId ] } = useContext(SeasonContext);
  return seasonId;
}

export const useFormSeason = () => {
  const appSeasonId = useSeason();
  const { values } = useFormState();
  const matches = useRouteMatch("/:resource/:id")
  const params = (matches && matches.params) || {};

  const resources = useSelector(state => state.admin.resources);
  const { schedules, groups, teams, games } = resources || {};

  if (values.seasonId) return values.seasonId;

  if (values.scheduleId) {
    const schedule = schedules.data[values.scheduleId];
    if (schedule) return schedule.seasonId;
  }

  if (values.groupId) {
    const group = groups.data[values.groupId];
    if (group) return group.seasonId;
  }

  if (values.groupIds && values.groupIds.length) {
    const groupId = values.groupIds[0];
    const group = groups.data[groupId];
    if (group) return group.seasonId;
  }

  if (values.teamId) {
    const team = teams.data[values.teamId];
    if (team) return team.seasonId;
  }

  if (params.resource === "schedules") {
    const scheduleId = parseInt(params.id, 10);
    const schedule = schedules.data[scheduleId];
    if (schedule) return schedule.seasonId;
  }

  if (params.resource === "teams") {
    const teamId = parseInt(params.id, 10);
    const team = teams.data[teamId];
    if (team) return team.seasonId;
  }

  if (params.resource === "games") {
    const gameId = parseInt(params.id, 10);
    const game = games.data[gameId];
    if (game) return game.seasonId;
  }

  return appSeasonId;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'flex-end',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: theme.spacing(15),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputHistory: {
    fontWeight: 'bold',
  },
}))

const BaseSeasonFilter = ({ source, meta }) => {
  const seasonId = useSeason()
  const { change } = useForm();

  useEffect(() => {
    change(source, seasonId)
  }, [change, seasonId, source])
  return null;
}

export const SeasonFilter = props =>
  <Field name={props.source} {...props} component={BaseSeasonFilter} />

export default () => {
  const { season: [ seasonId, setSeason ] } = useContext(SeasonContext)
  const defaultSeason = useDefaultSeason()
  const classes = useStyles();
  const sport = useSport();
  const history = useHistory()

  const isDefault = seasonId === defaultSeason;
  return <form className={classes.root} autoComplete="off">
    <FormControl className={classes.formControl}>
      <Select
        value={seasonId}
        onChange={e => {
          setSeason(e.target.value);
          history.push(`/`);
        }}
        className={isDefault ? classes.input : classes.inputHistory}
        inputProps={{
          name: 'season',
          id: 'season-input',
        }}
      >
        {SEASONS.map(season =>
          <MenuItem key={season} value={season}>{getSeasonDisplayName(season, sport)}</MenuItem>
        )}
      </Select>
    </FormControl>
  </form>
}
