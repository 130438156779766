import React from 'react';
import { ReferenceInput, ReferenceArrayInput, RecordContextProvider } from 'react-admin';
import moment from 'moment-timezone';

import { useFormSeason } from '../../common/inputs/SeasonSelector';
import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { GameIcon } from '../icons';
import { CategoryField } from '../categories/CategoryField';
import { SurfaceField } from '../surfaces/SurfaceField';

const filterGames = filter => number => ({ ...filter, number })

const GameSuggestions = ({ suggestion: game, query, isHighlighted, ...props }) => {
  if (!game?.id) return null;

  const primary = `${game.number} - ${moment.tz(game.date, game.timezone).format('LL')}`

  const secondaryComponent = <RecordContextProvider value={game}>
    <CategoryField source="categoryId" link={false} />
    {' - '}
    <SurfaceField source="arenaId" includeVenue link={false} />
  </RecordContextProvider>

  return <AutocompleteSuggestion icon={<GameIcon />} primary={primary} secondary={secondaryComponent} />
}

export const GameInput = ({ allowEmpty, multiple, helperText, ...props }) => {
  const seasonId = useFormSeason();
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="games"
    filterToQuery={filterGames({ seasonId, pastEvents: true })}
    sort={{
      field: ['number'],
      order: ['ASC'],
    }}
    perPage={100}
    {...props}
  >
    <AutocompleteInput
      optionText="number"
      suggestionComponent={GameSuggestions}
      multiple={multiple}
      helperText={helperText}
      resettable
      allowEmpty
    />
  </Input>
}
