import React from 'react';
import { useListContext, useLocale, useTranslate } from 'react-admin';
import { styled, Table, TableHead, TableRow, TableCell, TableBody, CardContent, Typography } from '@material-ui/core';

import { translateApiProperty } from '@hisports/common';

const ContactTableCell = styled(TableCell)({
  whiteSpace: 'pre',
})

const ContactList = styled('ul')({
  padding: 0,
  margin: 0,
  listStyleType: 'none',
})

const Roles = ({ member }) => {
  const locale = useLocale()
  if (!member || !member.roles.length) return <Typography variant="inherit" display="block" color="textSecondary">--</Typography>
  const roles = member.roles.reduce((roles, r) => {
    const role = translateApiProperty(r, 'role', locale)
    if (!r.groups || !r.groups.length) {
      roles.push(role);
    } else {
      roles.push(...r.groups.map(group => `${role} - ${group.name}`))
    }
    return roles;
  }, [])

  if (roles.length === 1) return <Typography variant="inherit" display="block" color="textSecondary">{roles[0]}</Typography>
  return <Typography variant="inherit" display="block" color="textSecondary">
    <ContactList>
      {roles.map((role, index) => <li>{role}</li>)}
    </ContactList>
  </Typography>
}

const Contacts = ({ member }) => {
  if (!member) return <ContactTableCell>--</ContactTableCell>

  const contacts = member.contacts.map(contact => {
    let value = contact.value;
    if (contact.type !== 'Email') {
      value = `${contact.value} (${contact.type})`
    } else if (contact.notes) {
      value = `${contact.value} (${contact.notes})`
    }
    return <li key={contact.id} title={contact.notes}>{value}</li>
  });

  return <ContactTableCell>{!contacts.length ? '--' : <ContactList>{contacts}</ContactList>}</ContactTableCell>
}

export const ScheduleManagerContactGrid = (props) => {
  const translate = useTranslate();
  const { ids = [], data, loaded } = useListContext(props)

  if (!loaded) return <CardContent>{translate('ra.page.loading')}</CardContent>;
  if (!ids?.length) return null;

  const members = ids.map(id => data?.[id]).filter(Boolean);

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell>{translate('ra.input.field.name')}</TableCell>
        <TableCell>{translate('resources.contacts.name', 2)}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {members.map(member => {
        return <TableRow key={member.id} hover>
          <ContactTableCell>
            {member.name}
            <Roles member={member} />
          </ContactTableCell>
          <Contacts member={member} />
        </TableRow>
      })}
    </TableBody>
  </Table>
}
