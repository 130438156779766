import React, { useContext, useMemo, useState } from 'react';

const GameAvailabilityContext = React.createContext();

export const useGameAvailabilityContext = () => useContext(GameAvailabilityContext);

export const GameAvailabilityContextProvider = ({ children }) => {
  const availabilityInfo = useState({});
  const settings = useState({});
  const slot = useState(null);
  const loading = useState(false);

  const context = useMemo(() => ({
    availabilityInfo,
    settings,
    slot,
    loading
  }), [ availabilityInfo, settings, slot, loading ])

  return <GameAvailabilityContext.Provider value={context}>
    {children}
  </GameAvailabilityContext.Provider>
}
