import React, { useCallback } from 'react';
import { get } from 'lodash';
import { FileInput, useNotify, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';

const MAX_SIZE = 10000000 // 10mb

const OfficialClaimDocumentInput = ({ placeholder, source, ...props }) => {
  const { values } = useFormState();
  const filename = get(values, source)?.rawFile?.name;
  const notify = useNotify()
  const translate = useTranslate()

  const handleDropRejected = useCallback((fileRejections) => {
    if (fileRejections?.[0]?.size && fileRejections[0].size > MAX_SIZE) {
      notify(translate('ra.input.file.error_too_large'), 'error');
    }
  }, [notify, translate]);

  return <FileInput
    accept="image/*, application/pdf"
    multiple={false}
    source={source}
    placeholder={filename || placeholder}
    maxSize={MAX_SIZE}
    options={{ onDropRejected: handleDropRejected }}
    {...props}
  >
    <div></div>
  </FileInput>
}

export default OfficialClaimDocumentInput;
