import React from 'react';
import { useTranslate } from 'react-admin';

import { List, Show, Create, Edit, Pagination } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { AnnouncementGrid } from './AnnouncementGrid';
import { AnnouncementFilters } from './AnnouncementFilters';
import { AnnouncementShowLayout } from './AnnouncementShow';
import { AnnouncementForm } from './AnnouncementForm';

export const AnnouncementList = props =>
  <List {...props}
    filters={<AnnouncementFilters />}
    sort={{
      field: 'createdAt',
      order: 'DESC'
    }}
    perPage={10}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <AnnouncementGrid rowClick="show" />
  </List>

export const AnnouncementShow = props =>
  <Show {...props}
    title={<ResourceTitle title={record => record.title} />}
  >
    <AnnouncementShowLayout />
  </Show>

export const AnnouncementCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={record => translate('resources.announcements.labels.add')} />}
    {...props}
  >
    <AnnouncementForm redirect="show" />
  </Create>
}

export const AnnouncementEdit = props => {
  const translate = useTranslate()
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: record.title })} />}
    {...props}
  >
    <AnnouncementForm redirect="show" />
  </Edit>
}
