
import { exportFile, makeExporter } from '../../common/Exporter';
import details from './reports/details';
import summary from './reports/summary';

const transactionsExporter = (transactions, translate, fileType = 'csv', reportName = 'details', { hidePayPeriod = false, ...props }) => {
  let report;

  switch (reportName) {
    case 'summary':
      report = summary(transactions, translate);
      break;
    case 'details':
    default:
      report = details(transactions, translate, hidePayPeriod);
      break;
  }

  if (!report) return;

  return exportFile(report.csv, report.fileName, fileType);
};

const include = [
  'payPeriod',
  { relation: 'participant', scope: { scope: 'Tenant' } },
  { relation: 'game', scope: {
    scope: 'Tenant',
    include: [{
      relation: 'schedule',
      scope: {
        scope: 'Tenant',
        include: {
          relation: 'office',
          scope: { scope: 'Tenant' },
        }
      }
    },
    { relation: 'surface', scope: { scope: 'Tenant' } }]
  } },
  { relation: 'office', scope: { scope: 'Tenant' } },
]

export default makeExporter(transactionsExporter, include);
