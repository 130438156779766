import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useTranslate, GET_ONE, GET_MANY_REFERENCE, useDataProvider } from 'react-admin';
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { v4 as uuid } from 'uuid';

import { dedupe, isEmpty } from '@hisports/parsers';

import { useAuthContext, apiClient, useHttpClient, useParticipant } from '../../../../http';
import LoadingAlert from '../../../../common/LoadingAlert';
import { isAuthorized } from '../../../../common/Authorize';
import { useBranchSettings } from '../../../branchOfficeSettings';
import { useOfficeAssignSettings } from '../../../officeAssignSettings';

import { getAssignmentIndex } from './Assignment/util';

const AssignmentsContext = createContext(null);

const useGame = (gameId) => useQuery({
  type: GET_ONE,
  resource: 'games',
  payload: { id: gameId }
}, {
  enabled: gameId != null,
})

const useAssignments = (gameId) => useQuery({
  type: GET_MANY_REFERENCE,
  resource: 'officialAssignments',
  payload: {
    target: 'games',
    id: gameId,
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'gameId', order: 'ASC' },
    filter: {},
  }
}, {
  enabled: gameId != null,
})

const useSettings = (gameId) => useQuery({
  type: GET_ONE,
  resource: 'gameAssignSettings',
  payload: { id: gameId }
}, {
  enabled: gameId != null,
})

const useCurrentOfficial = (gameId, participantId, enabled) =>
  useHttpClient(`/games/${gameId}/availableOfficials/${participantId}`, null, enabled)

const useAllOfficials = (game, officeIds = [], enabled) => {
  const [ data, setData ] = useState()

  useEffect(() => {
    if (!enabled || !game?.id) return;

    officeIds.forEach(officeId => {
      if (data?.[officeId]) return;

      setData((data = {}) => ({ ...data, [officeId]: { loading: true } }))

      apiClient(`/games/${game.id}/availableOfficials`, {
        method: 'GET',
        params: { officeId }
      }).then(({ data: officials }) => {
        setData((data = {}) => ({ ...data, [officeId]: { loading: false, data: officials } }))
      }).catch(e => {
        setData((data = {}) => ({ ...data, [officeId]: { loading: false, error: e } }))
      })
    })
  }, [data, officeIds, game, enabled])

  return { data, refetch: () => setData() }
}

const useOfficialLists = (officeIds = [], enabled, effectiveOfficialsList = false) => {
  const [ data, setData ] = useState()
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (!enabled) return;

    officeIds.forEach(officeId => {
      if (data?.[officeId]) return;

      const listFilter = effectiveOfficialsList ? { effectiveOffices: officeId, includeParents: false, _scope: 'Parents' } : { officeId, effectiveOffices: false }

      setData((data = {}) => ({ ...data, [officeId]: { loading: true } }))

      dataProvider.getList('lists', {
        filter: listFilter,
        pagination: { page: 0, perPage: 9999 },
        sort: { field: 'name', order: 'ASC' },
      }).then(({ data: officialList }) => {
        setData((data = {}) => ({ ...data, [officeId]: { loading: false, data: officialList } }))
      }).catch(e => {
        setData((data = {}) => ({ ...data, [officeId]: { loading: false, error: e } }))
      })
    })
  }, [data, effectiveOfficialsList, officeIds, enabled, dataProvider])

  return { data }
}

const useOfficialAttributeTypes = (officeIds = [], enabled) => {
  const [ data, setData ] = useState()
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (!enabled) return;

    officeIds.forEach(officeId => {
      if (data?.[officeId]) return;

      setData((data = {}) => ({ ...data, [officeId]: { loading: true } }))

      dataProvider.getList('attributetypes', {
        filter: { type: 'Officiating', targetType: 'Participant', effectiveOffices: officeId, includeParents: true, _scope: 'Parents' },
        pagination: { page: 0, perPage: 9999 },
        sort: { field: 'name', order: 'ASC' },
      }).then(({ data: officialAttributeTypes }) => {
        setData((data = {}) => ({ ...data, [officeId]: { loading: false, data: officialAttributeTypes } }))
      }).catch(e => {
        setData((data = {}) => ({ ...data, [officeId]: { loading: false, error: e } }))
      })
    })
  }, [data, officeIds, enabled, dataProvider])

  return { data }
}

const updateAssignments = (gameId) => {
  return apiClient(`/games/${gameId}/officialAssignments`, {
    method: 'GET',
  })
}

const updateOfficials = (gameId, officeId) => {
  return apiClient(`/games/${gameId}/availableOfficials`, {
    method: 'GET',
    params: { officeId }
  })
}

const updateOfficial = (gameId, participantId) => {
  return apiClient(`/games/${gameId}/availableOfficials/${participantId}`)
}

export function AssignmentsProvider({ gameId, gameOfficeId, children }) {
  const translate = useTranslate();
  const participantId = useParticipant();
  const { permissions } = useAuthContext();

  const [ assignments, setAssignments ] = useState([])
  const [ allOfficials, setAllOfficials ] = useState({});
  const [ currentOfficial, setCurrentOfficial ] = useState({})
  const [ openAssignmentIndexes, setOpenAssignmentIndexes ] = useState([])

  // base game/assignment data
  const { data: game, loading: gameLoading, error: gameError } = useGame(gameId)
  const { data: settings, loading: settingsLoading, error: settingsError, refetch: refetchSettings } = useSettings(gameId)
  const { data: branchSettings, loading: branchSettingsLoading, error: branchSettingsError, refetch: refetchBranchSettings } = useBranchSettings(gameOfficeId)
  const { data: fetchedAssignments, loading: assignmentsLoading, error: assignmentsError, refetch: refetchAssignments } = useAssignments(gameId)

  const settingsFailure = settingsError && !(settingsError?.response?.status === 404)
  const branchSettingsFailure = branchSettingsError && !(branchSettingsError?.response?.status === 404)
  const loading = gameLoading || assignmentsLoading || (settingsLoading && !settingsError) || (branchSettingsLoading && !branchSettingsError)
  const error = gameError || assignmentsError || settingsFailure || branchSettingsFailure;

  // if not assigner, load own availability, and load full official list for assigners
  const canAssign = isAuthorized(game, 'games', 'assign', false, false);
  const officialEnabled = game != null && participantId != null && !canAssign
  const officeAssignSettings = useOfficeAssignSettings(settings?.officeId, canAssign)

  const officeIds = dedupe((assignments || [])
    .filter(assignment => openAssignmentIndexes.includes(getAssignmentIndex(assignments, assignment?.id)))
    .map(assignment => assignment.officeId || settings?.officeId))

  // panel data
  const { data: lists } = useOfficialLists(officeIds, gameId != null && canAssign, officeAssignSettings?.data?.effectiveOfficialsList)
  const { data: attributeTypes } = useOfficialAttributeTypes(officeIds, gameId != null && canAssign)
  const { data: fetchedOfficials, refetch: refetchOfficials } = useAllOfficials(game, officeIds, gameId != null && canAssign)
  const { data: fetchedCurrentOfficial, loading: currentOfficialLoading, loaded: currentOfficialLoaded, error: currentOfficialError, refetch: refetchCurrentOfficial } = useCurrentOfficial(gameId, participantId, officialEnabled)

  useEffect(() => {
    if (!fetchedAssignments?.length) return;
    setAssignments(fetchedAssignments)
  }, [ fetchedAssignments ])

  useEffect(() => {
    if (isEmpty(fetchedOfficials)) return;
    setAllOfficials(fetchedOfficials)
  }, [ fetchedOfficials ])

  useEffect(() => {
    if (isEmpty(fetchedCurrentOfficial) && !currentOfficialLoading) return;
    setCurrentOfficial({ data: fetchedCurrentOfficial, loading: currentOfficialLoading, loaded: currentOfficialLoaded, error: currentOfficialError })
  }, [ fetchedCurrentOfficial, currentOfficialLoading, currentOfficialLoaded, currentOfficialError ])

  const canManage = useMemo(() => {
    const isSystemUser = permissions.some(p => p.roleType === 'System');
    const permittedOfficeIds = permissions.filter(p => p.roleType === 'Office' && p.scopes.includes('assigning:assign')).flatMap(p => p.officeIds).filter(Boolean)
    const permittedScheduleIds = permissions.filter(p => p.roleType === 'Schedule' && p.scopes.includes('assigning:assign')).flatMap(p => p.scheduleIds).filter(Boolean)

    return isAuthorized(game, 'games', 'assignSettings', false, false) && (isSystemUser || permittedOfficeIds.includes(settings?.officeId) || permittedScheduleIds.includes(game?.scheduleId))
  }, [ game, settings, permissions ])

  const refetch = useMemo(() => () => {
    refetchAssignments();
    refetchSettings();
    refetchBranchSettings();
    refetchOfficials();
    refetchCurrentOfficial();
  }, [ refetchAssignments, refetchSettings, refetchBranchSettings, refetchOfficials, refetchCurrentOfficial ]);

  const value = useMemo(() => {
    const refresh = async (assignment, participantId = null) => {
      const { id, position, officeId: assignmentOfficeId, _scopes } = assignment;
      const officeId = assignmentOfficeId || settings.officeId;

      participantId && await updateOfficial(game.id, participantId)
        .then(official => setCurrentOfficial(official))

      !participantId && await updateOfficials(game.id, officeId)
        .then(({ data: officials }) => setAllOfficials((data = {}) => ({ ...data, [officeId]: { loading: false, data: officials } })))

      await updateAssignments(game.id)
        .then(({ data }) =>
          setAssignments(prev => {
            const newAssignments = [...prev];
            const updatedAssignment = data.find(assignment => assignment.id === id);
            const assignmentIndex = newAssignments.findIndex(assignment => assignment.id === id);

            if (!updatedAssignment) {
              newAssignments[assignmentIndex] = { id: uuid(), position, status: "unassigned", _scopes }
              return newAssignments;
            }

            newAssignments[assignmentIndex] = updatedAssignment;
            return newAssignments;
          }))
    }

    return {
      game,
      assignments,
      settings,
      branchSettings,
      lists,
      attributeTypes,
      allOfficials,
      currentOfficial,
      openAssignmentIndexes,
      setOpenAssignmentIndexes,
      officialEnabled,
      canManage,
      refetch,
      refresh,
    }
  }, [ game, assignments, settings, branchSettings, lists, attributeTypes, allOfficials, currentOfficial, openAssignmentIndexes, officialEnabled, canManage, refetch ])

  const retryAction = <Button color="inherit" disabled={loading && !error} onClick={refetch}>{translate('ra.action.retry')}</Button>

  if (error) {
    return <Alert severity="error" action={retryAction}>{translate('resources.games.alerts.assignment.load_assignment_panel_error')}</Alert>
  }

  if (loading) {
    return <LoadingAlert square>{translate('resources.games.alerts.loading.assignments')}</LoadingAlert>
  }

  if (!game || !assignments) {
    return <Alert severity="error" action={retryAction}>{translate('resources.games.alerts.assignment.load_assignments_error')}</Alert>
  }

  return <AssignmentsContext.Provider value={value}>
    {children}
  </AssignmentsContext.Provider>
}

export const useAssignmentsContext = () => useContext(AssignmentsContext)
