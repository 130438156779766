import React, { Suspense } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { LazyBoundary } from './LazyBoundary';

const LazyFallbackDialog = () => {
  const translate = useTranslate();
  return <Dialog maxWidth="sm" fullWidth open>
    <DialogTitle>{translate('ra.page.loading')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <LinearProgress colour="primary" variant="indeterminate" />
      </DialogContentText>
    </DialogContent>
  </Dialog>
}

export const LazyDialog = ({ children }) => {
  return <Suspense fallback={<LazyFallbackDialog />}>
    <LazyBoundary dialog>
      {children}
    </LazyBoundary>
  </Suspense>
}
