import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';
import { useSeason } from '../../common/inputs/SeasonSelector';

import { OfficeActions } from '../offices/OfficeActions';
import { OfficeShowHeader } from '../offices';

import { LeagueFilters } from './LeagueFilters';
import { LeagueView } from './LeagueView';
import { LeagueShowLayout } from './LeagueShow';
import { LeagueForm } from './LeagueForm';

export const LeagueList = props => {
  const seasonId = useSeason();

  return <List {...props}
    resource="offices"
    component="div"
    filters={<LeagueFilters />}
    filter={{ type: 'League' }}
    filterDefaultValues={{ seasonId }}
    sort={{
      field: ['parent.name', 'name'],
      order: ['ASC', 'ASC'],
    }}
  >
    <LeagueView rowClick="show" />
  </List>
}

export const LeagueShow = props => {
  const location = useLocation();
  const officeTab = useMemo(() => {
    return location?.pathname?.substring(location.pathname.lastIndexOf('/') + 1)
  }, [ location.pathname ])

  return <Show {...props}
    title={<OfficeShowHeader />}
    style={{ maxWidth: officeTab === 'scheduler' ? 'none' : undefined }}
    actions={<OfficeActions />}
  >
    <LeagueShowLayout />
  </Show>
}

export const LeagueCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.leagues.labels.add')} />}
    {...props}
  >
    <LeagueForm redirect="show" initialValues={{ type: 'League' }} hideType />
  </Create>
}

export const LeagueEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('resources.leagues.labels.editing', { name: record.name })} />}
    {...props}
  >
    <LeagueForm redirect="show" />
  </Edit>
}
