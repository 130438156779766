import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill'

import React from 'react';
import ReactDOM from 'react-dom';
import { init as initSentry } from '@sentry/browser';
// import { Integrations as TracingIntegrations } from "@sentry/tracing";

import './index.css';
import App from './App';

initSentry({
  dsn: process.env.SENTRY_DSN,
  release: process.env.SENTRY_RELEASE,
  environment: process.env.SENTRY_ENVIRONMENT,
  enabled: process.env.SENTRY_RELEASE != false,
  // integrations: [
  //   new TracingIntegrations.BrowserTracing(),
  // ],
  sampleRate: 1,
  tracesSampleRate: 0,
  ignoreErrors: [
    'ChunkLoadError',
    'SyntaxError',
    'No available storage method found.',
    /^ResizeObserver loop/,
  ],
})

ReactDOM.render(<App />, document.getElementById('root'));
