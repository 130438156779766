import React, { useState } from 'react';
import { RecordContextProvider, useLocale, useRecordContext, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent as MuiDialogContent, DialogActions, Button, List, ListSubheader as MuiListSubheader, ListItem, ListItemText, ListItemIcon as MuiListItemIcon, Table, TableBody, TableRow, TableCell, Tooltip, styled, useTheme } from '@material-ui/core';
import { History, AddCircleOutline, DateRange, Edit, Delete, AssignmentTurnedIn, DoneAll, Assignment, Inbox, Remove, Feedback, Flag, Settings, Restore, Replay } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment-timezone';
import { startCase, camelCase, orderBy, groupBy } from 'lodash';

import { useHttpClient } from '../../../http';
import { formatCurrency } from '../../../locale';

import { ParticipantField } from '../../participants/ParticipantField';
import { SurfaceField } from '../../surfaces/SurfaceField';
import { TeamField } from '../../teams/TeamField';
import { ScheduleField } from '../../schedules/ScheduleField';
import { CategoryField } from '../../categories/CategoryField';
import { GroupField } from '../../groups/GroupField';
import { OfficeField } from '../../offices/OfficeField';
import { SeasonField } from '../../seasons/SeasonField';
import { AssignSystemField } from '../../assignSystems';
import { AssignFeesField } from '../../assignFees';

const DialogContent = styled(MuiDialogContent)({
  padding: 0,
})

const ListSubheader = styled(MuiListSubheader)({
  backgroundColor: '#fff',
})

const LabelCell = styled(TableCell)({
  width: 96,
  maxWidth: 96,
  padding: 4,
})

const ListItemIcon = styled(MuiListItemIcon)({
  justifyContent: 'center',
  alignSelf: 'start',
  paddingTop: 4,
})

const getPosition = event => {
  if (event.positions) return event.positions.map(position => {
    if (position === 'Linesman') return 'Linesperson'
    return position;
  }).join(', ');
  return event.position;
}

const ChangeIcon = ({ source }) => {
  const change = useRecordContext();
  const theme = useTheme();
  switch (change[source]) {
    case 'gameCreated': return <AddCircleOutline />
    case 'gameRescheduled': return <DateRange />
    case 'gameUpdated': return <Edit />
    case 'gameDeleted': return <Delete htmlColor={theme.palette.error.main} />
    case 'gameForfeited': return <Flag />

    case 'assignSettingsUpdated': return <Settings />

    case 'officialRequested': return <Feedback />
    case 'officialAssigned': return <Inbox />
    case 'officialAccepted': return <DoneAll htmlColor={theme.palette.success.main} />
    case 'officialDeclined': return <Remove htmlColor={theme.palette.error.main} />

    case 'officialAdded': return <DoneAll htmlColor={theme.palette.success.main} />
    case 'officialUpdated': return <Assignment htmlColor={theme.palette.success.main} />
    case 'officialRemoved': return <Remove />

    case 'scoresheetApproved': return <AssignmentTurnedIn htmlColor={theme.palette.success.main} />
    case 'scoresheetDisapproved': return <Replay />
    case 'scoresheetCertified': return <DoneAll htmlColor={theme.palette.success.main} />
    case 'scoresheetDecertified': return <Replay />
    case 'scoresheetReset': return <Restore />

    default:
      return null;
  }
}

const AttributeRow = ({ source }) => {
  const change = useRecordContext()
  const translate = useTranslate();
  const locale = useLocale();

  const value = change.event[source]
  switch (source) {
    case 'startTime': {
      const date = moment.tz(value, change.event.timezone || moment.tz.guess()).format('LL HH:mm z');
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.startTime')}
        </LabelCell>
        <TableCell>
          <strong>{date}</strong>
        </TableCell>
      </TableRow>
    }

    case 'endTime': {
      const date = moment.tz(value, change.event.timezone || moment.tz.guess()).format('LL HH:mm z');
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.endTime')}
        </LabelCell>
        <TableCell>
          <strong>{date}</strong>
        </TableCell>
      </TableRow>
    }

    case 'timezone': {
      if (Object.keys(change.event).some(key => ['startTime', 'endTime'].includes(key))) return null;

      return <TableRow>
        <LabelCell>{translate('resources.games.fields.timezone')}</LabelCell>
        <TableCell>
          {value}
        </TableCell>
      </TableRow>
    }

    case 'arenaId':
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.arenaId')}
        </LabelCell>
        <TableCell>
          <SurfaceField source="event.arenaId" includeAttributes="full" includeCity="full" />
        </TableCell>
      </TableRow>

    case 'homeTeamId':
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.homeTeamId')}
        </LabelCell>
        <TableCell>
          <TeamField source="event.homeTeamId" includeCategory="full" includeId="full" />
        </TableCell>
      </TableRow>

    case 'awayTeamId':
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.awayTeamId')}
        </LabelCell>
        <TableCell>
          <TeamField source="event.awayTeamId" includeCategory="full" includeId="full" />
        </TableCell>
      </TableRow>

    case 'scheduleId':
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.scheduleId')}
        </LabelCell>
        <TableCell>
          <ScheduleField source="event.scheduleId" includeCategory="full" includeType="ful" />
        </TableCell>
      </TableRow>

    case 'categoryId':
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.categoryId')}
        </LabelCell>
        <TableCell>
          <CategoryField source="event.categoryId" />
        </TableCell>
      </TableRow>

    case 'groupId':
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.groupId')}
        </LabelCell>
        <TableCell>
          <GroupField source="event.groupId" />
        </TableCell>
      </TableRow>

    case 'officeId':
      return <TableRow>
        <LabelCell>{translate('resources.games.fields.officeId')}</LabelCell>
        <TableCell>
          <OfficeField source="event.officeId" />
        </TableCell>
      </TableRow>

    case 'seasonId':
      return <TableRow>
        <LabelCell>{translate('resources.games.fields.seasonId')}</LabelCell>
        <TableCell>
          <SeasonField source="event.seasonId" />
        </TableCell>
      </TableRow>

    case 'systemId':
      return <TableRow>
        <LabelCell>{translate('resources.gameAssignSettings.fields.systemId')}</LabelCell>
        <TableCell>
          <AssignSystemField source="event.systemId" />
        </TableCell>
      </TableRow>

    case 'minReferee':
    case 'minLinesperson':
    case 'minOfficial':
    case 'minScorekeeper':
    case 'minTimekeeper':
    case 'minGrade':
    case 'minRefereeGrade':
    case 'minLinespersonGrade':
    case 'minAge':
      return <TableRow>
        <LabelCell>{translate(`resources.gameAssignSettings.fields.${source}`)}</LabelCell>
        <TableCell>
          {value}
        </TableCell>
      </TableRow>

    case 'feesId':
      return <TableRow>
        <LabelCell>{translate('resources.assignFees.fields.fees')}</LabelCell>
        <TableCell>
          <AssignFeesField source="event.feesId" />
        </TableCell>
      </TableRow>

    case 'fees':
      return <TableRow>
        <LabelCell>{translate('resources.assignFees.fields.fees')}</LabelCell>
        <TableCell>
          {translate('resources.assignFees.fields.Referee')}: {formatCurrency(value.Referee, locale)}<br />
          {translate('resources.assignFees.fields.Linesperson')}: {formatCurrency(value.Linesperson, locale)}<br />
          {translate('resources.assignFees.fields.LinespersonTwo')}: {formatCurrency(value.LinespersonTwo, locale)}<br />
          {translate('resources.assignFees.fields.LinespersonThree')}: {formatCurrency(value.LinespersonThree, locale)}<br />
          {translate('resources.assignFees.fields.Supervisor')}: {formatCurrency(value.Supervisor, locale)}
        </TableCell>
      </TableRow>

    case 'status':
      return <TableRow>
        <LabelCell>{translate('resources.games.fields.status')}</LabelCell>
        <TableCell>
          {translate(`resources.games.values.status.${value}`, { _: value })}
        </TableCell>
      </TableRow>

    case 'number':
      return <TableRow>
        <LabelCell>
          {translate('resources.games.fields.number')}
        </LabelCell>
        <TableCell>
          {value}
        </TableCell>
      </TableRow>

    default:
      return <TableRow>
        <LabelCell>
          {startCase(source)}
        </LabelCell>
        <TableCell>
          {value || <em>{translate('resources.games.labels.assignment.status.removed')}</em>}
        </TableCell>
      </TableRow>
  }
}

const order = [
  'startTime',
  'endTime',
  'arenaId',
  'homeTeamId',
  'awayTeamId',
  'scheduleId',
  'groupId',
  'status',
  'comments',

  'number',
  'division',
  'gender',
  'category',
  'categoryId',
  'isApproved',

  'officeId',
  'systemId',
  'minReferee',
  'minLinesperson',
  'minOfficial',
  'minScorekeeper',
  'minTimekeeper',
  'minGrade',
  'minRefereeGrade',
  'minLinespersonGrade',
  'feesId',
  'fees',
  'status',
]

const getAttributes = (game, ignored = []) => {
  const changes = Object.keys(game).filter(key => !ignored.includes(key))

  return changes.sort((a, b) => {
    const aIndex = order.findIndex(attribute => a === attribute);
    if (aIndex < 0) return 1;
    const bIndex = order.findIndex(attribute => b === attribute);
    if (bIndex < 0) return -1;
    return aIndex - bIndex;
  })
}
const AttributeTable = ({ ignored }) => {
  const change = useRecordContext();
  return <Table size="small">
    <TableBody>
      {getAttributes(change.event, ignored).map(source => {
        return <AttributeRow key={source} source={source} />
      })}
    </TableBody>
  </Table>
}

const ChangeCell = () => {
  const change = useRecordContext();
  const translate = useTranslate();

  switch (change.eventType) {
    case 'gameCreated': {
      const ignored = ['division', 'gender', 'category', 'seasonId', 'isApproved']
      return <>
        {translate('resources.gameEvents.values.eventType.gameCreated')}
        <AttributeTable ignored={ignored} />
      </>
    }

    case 'gameRescheduled':
      return <>
        {change.event.status !== 'Cancelled'
          ? translate('resources.gameEvents.values.eventType.gameRescheduled')
          : translate('resources.gameEvents.messages.game_cancelled')
        }
        <AttributeTable />
      </>

    case 'gameUpdated':
      return <>
        {translate('resources.gameEvents.values.eventType.gameUpdated')}
        <AttributeTable ignored={['category']} />
      </>

    case 'gameDeleted':
      return translate('resources.gameEvents.messages.game_deleted');

    case 'gameForfeited':
      return <>
        {change.event.teamId == -1
          ? translate('resources.games.values.status.DoubleForfeit')
          : <>{translate('resources.gameEvents.values.eventType.gameForfeited')} {translate('ra.function.by')} <TeamField source="event.teamId" /></>
        }
        {change.event.notes && <p>{change.event.notes}</p>}
      </>

    case 'scoresheetApproved':
      return translate('resources.gameEvents.messages.game_completed');

    case 'scoresheetDisapproved':
      return translate('resources.gameEvents.messages.game_disapproved');

    case 'scoresheetCertified':
      return translate('resources.gameEvents.messages.game_certified')

    case 'scoresheetDecertified':
      return translate('resources.gameEvents.messages.game_decertified')

    case 'scoresheetReset':
      return translate('resources.gameEvents.messages.game_reset')

    case 'assignSettingsUpdated':
      return <>
        {translate('resources.gameEvents.values.eventType.assignSettingsUpdated')}
        <AttributeTable />
      </>

    case 'officialRequested':
      return <>
        <ParticipantField source="event.official.participantId" allowEmpty /> {translate('resources.gameEvents.messages.requested', { position: getPosition(change.event) })}
      </>

    case 'officialAssigned':
      return <>
        <ParticipantField source="event.official.participantId" allowEmpty /> {translate('resources.gameEvents.messages.assigned_as', { position: getPosition(change.event) })}
      </>

    case 'officialAccepted':
      return <>
        <ParticipantField source="event.participantId" allowEmpty /> {translate('resources.gameEvents.messages.accepted_assignment')}
      </>

    case 'officialDeclined':
      return <>
        <ParticipantField source="event.participantId" allowEmpty /> {translate('resources.gameEvents.messages.declined_assignment')}
      </>


    case 'officialAdded':
      return <>
        <ParticipantField source="event.official.participantId" allowEmpty /> {translate('resources.gameEvents.messages.added_as', { position: getPosition(change.event) })}
      </>

    case 'officialUpdated':
      return <>
        <ParticipantField source="event.participantId" allowEmpty /> {translate('resources.gameEvents.messages.changed_to', { position: getPosition(change.event) })}
      </>

    case 'officialRemoved':
      return <>
        <ParticipantField source="event.participantId" allowEmpty /> {translate('resources.gameEvents.messages.removed')}
      </>

    default:
      return null;
  }
}

const nameCase = name => {
  if (name.includes('@')) return name;
  return startCase(camelCase(name));
}

const ChangeItem = () => {
  const change = useRecordContext()
  const translate = useTranslate();
  const time = moment(change.meta.timestamp || change.timestamp);

  return <ListItem dense>
    <ListItemIcon>
      <ChangeIcon source="eventType" />
    </ListItemIcon>
    <ListItemText
      primary={<ChangeCell />}
      secondary={<>
        <Tooltip title={time.format('YYYY-MM-DD HH:mm:ss')}><span>{time.format('h:mm A')}</span></Tooltip> {translate('ra.function.by')} {nameCase(change.meta.actorName)}
      </>}
    />
  </ListItem>
}

const ChangesTable = ({ changes }) => {
  const sortedChanges = orderBy(changes, change => moment(change.meta.timestamp || change.timestamp).valueOf(), 'desc');
  const dates = groupBy(sortedChanges, change => moment(change.meta.timestamp || change.timestamp).format('LL'))

  return <List>
    {Object.keys(dates).map(date => {
      const changes = dates[date];
      return <>
        <ListSubheader>{date}</ListSubheader>
        {changes.map(change => <RecordContextProvider value={change} key={change.id}>
          <ChangeItem />
        </RecordContextProvider>)}
      </>
    })}
  </List>
}

const ChangesCard = ({ gameId }) => {
  const translate = useTranslate();
  const { data: changes, loading, error } = useHttpClient(gameId && `/games/${gameId}/changes`)

  if (loading) return <Alert severity="info" variant="outlined">{translate('ra.page.loading')}</Alert>
  if (error) return <Alert severity="error">{translate('resources.games.alerts.loading_game_error')}</Alert>
  if (!changes || !changes.length) return <Alert severity="success">{translate('resources.games.alerts.no_changes_made')}</Alert>;

  return <ChangesTable changes={changes} />
}

export default props => {
  const game = useRecordContext(props)
  const translate = useTranslate();
  const [ open, setOpen ] = useState(false);
  const onClose = () => {
    setOpen(false);
  }

  if (!game?.id) return null;
  return <>
    <Button size="small" startIcon={<History />} onClick={() => setOpen(true)}>{translate('resources.gameEvents.labels.history')}</Button>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <DialogTitle>{translate('resources.games.labels.game_number_history', { number: game.number })}</DialogTitle>
      <DialogContent dividers>
        <ChangesCard gameId={game.id} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('ra.action.close')}</Button>
      </DialogActions>
    </Dialog>
  </>
}
