import React, { useContext, useState } from 'react';

const ScheduleContext = React.createContext();

export const useScheduleContext = () => useContext(ScheduleContext);

export const ScheduleWizardProvider = ({ initialValues, children }) => {
  const context = useState(initialValues);

  return <ScheduleContext.Provider value={context} >
    {children}
  </ScheduleContext.Provider>
}
