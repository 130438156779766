import React from 'react';
import { TextInput, SimpleForm, ArrayInput, useRecordContext, useQuery, GET_MANY_REFERENCE } from 'react-admin';
import { Grid } from '@material-ui/core';
import { useFieldArray } from 'react-final-form-arrays';
import { useFormState } from 'react-final-form';

import Toolbar from '../../common/ra/Toolbar';

import { OfficeInput } from '../offices/OfficeInput';
import SimpleFormIterator, { TransitionProps } from '../../common/ra/SimpleFormIterator';

import { ParticipantInput } from '../participants/ParticipantInput';
import { ParticipantField } from '../participants/ParticipantField';

const validate = values => {
  const errors = {};

  if (!values.name) errors.name = 'ra.validation.required'
  errors.members = values.members?.map((member, index) => {
    const error = {}
    if (member?.participantId) {
      const existing = values.members.findIndex(m => m.participantId == member.participantId);
      if (existing >= 0 && existing < index) error.participantId = 'resources.lists.validation.already_exists'
    } else {
      error.participantId = 'ra.validation.required'
    }
    return error;
  })

  return errors;
}

const inputProps = {
  resource: 'lists',
  basePath: '/lists',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const Participant = props => {
  const lists = useRecordContext();
  const [ parent, source ] = props.source.split('.')
  const { fields } = useFieldArray(parent)
  const { values } = useFormState();

  const officeId = lists?.officeId || values?.officeId;

  if (fields.value && fields.value.id) {
    return <ParticipantField {...props} record={fields.value} source={source} basePath="/participants" />
  }
  return <ParticipantInput {...props} filter={{ isOfficial: true, _scope: 'Tenant', 'officialOffices.officeId': officeId }} />
}

export const ListForm = ({ initialValues, ...props }) => {
  const list = useRecordContext();

  const enabled = list != null;
  const { data: members = [], loaded } = useQuery({
    type: GET_MANY_REFERENCE,
    resource: 'listMembers',
    payload: {
      target: 'lists',
      id: list?.id,
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'participant.lastName', order: 'ASC' },
      filter: {}
    },
  }, { enabled })

  if (enabled && !loaded) return null;

  const values = { ...initialValues, members }
  return <SimpleForm initialValues={values} toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <TextInput source="name" autoFocus helperText="" {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <OfficeInput source="officeId" disabled helperText="" {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <ArrayInput source="members" label="resources.lists.fields.members" {...inputProps}>
          <SimpleFormIterator disableReordering TransitionProps={TransitionProps}>
            <Participant source="participantId" label="resources.participants.name" options={{ label: null }} {...inputProps} />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  </SimpleForm>
}
