import React, { useEffect, useRef, useState } from 'react';
import { TextInput, NumberInput, ArrayInput, useQueryWithStore, GET_ONE, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { useStore, useSelector } from 'react-redux';
import { Card, CardContent, Grid } from '@material-ui/core';
import moment from 'moment-timezone';

import { createRange } from '@hisports/common';
import { FF_DRAFT_DEFAULT_ROUNDS, FF_DRAFT_DEFAULT_SLOTS, FF_DRAFT_LIMIT_BYES } from '@hisports/common/featureFlags';
import { validateGameNumber } from '@hisports/scheduler';
import { getGameNumberValidationError } from '@hisports/scheduler/src/sequences/sequences';

import { useFlag } from '../../../http';
import { useWizard } from '../../../common/Wizard';
import { DateInput, InlineDateInput } from '../../../common/inputs/DateInput';
import { DraftTypeEnumInput, DayEnumInput, DraftRoundTypeEnumInput, DraftMethodEnumInput } from '../../../common/inputs/EnumInputs';
import HorizontalFormIterator, { TransitionProps } from '../../../common/ra/HorizontalFormIterator';
import AlertDialog from '../../../common/dialogs/AlertDialog';
import SwitchInput from '../../../common/inputs/SwitchInput';
import CardHeader from '../common/CardHeader';

import { hasGroups, ScheduleGroupInput } from '../../groups/GroupInput';
import { ScheduleInput } from '../../schedules/ScheduleInput';
import DraftInput from '../DraftInput';

export const ROUND_TYPES = {
  HOME_SLOTS: 'Home Slots',
  WEEKDAYS: 'Weekdays',
  DATES: 'Dates'
}

const validate = (values, schedule, translate) => {
  const errors = {
    options: {}
  };

  if (!values.scheduleId) errors.scheduleId = 'ra.validation.required';

  const hasOption = property => values.options && values.options[property] != null;
  if (!hasOption('type')) errors.options.type = 'ra.validation.required';
  if (values.options.method === 'totalRounds' && !hasOption('totalRounds')) errors.options.totalRounds = 'ra.validation.required'
  if (values.options.method === 'gamesPerTeam' && !hasOption('gamesPerTeam')) errors.options.gamesPerTeam = 'ra.validation.required'
  if (!hasOption('startNumber')) {
    errors.options.startNumber = 'ra.validation.required'
  } else if (!validateGameNumber(values.options.startNumber, schedule?.seasonId)) {
    const gameNumberError = getGameNumberValidationError(values.options.startNumber, schedule?.seasonId);
    errors.options.startNumber = `resources.schedulesequences.validations.${gameNumberError}`
  }

  const { roundType, weekdays, breaks, type } = values?.options || {}

  if (roundType === ROUND_TYPES.WEEKDAYS) {
    if (!weekdays?.length) errors.options.weekdays = 'ra.validation.required'
    if (type === 'Double Rotation' && weekdays?.length > 2) errors.options.weekdays = translate('resources.drafts.validations.max_weekdays', 2)
  }

  if (roundType === ROUND_TYPES.HOME_SLOTS || roundType === ROUND_TYPES.WEEKDAYS) {
    if (!hasOption('startDate')) errors.options.startDate = 'ra.validation.required'
    let day, startDate;
    if (hasOption('startDate')) {
      startDate = moment.utc(values.options.startDate, 'YYYY-MM-DD')
      day = startDate.format('dddd');
    }

    const earliestDate = startDate && moment.utc(startDate, 'YYYY-MM-DD').add(1, 'week');

    if (hasOption('breaks')) {
      errors.options.breaks = breaks?.map((brk, index, breaks) => {
        if (!brk) return {};
        const error = {}

        const brkStart = moment.utc(brk.startDate, 'YYYY-MM-DD')
        if (!brk.startDate || !brkStart.isValid()) {
          error.startDate = 'ra.validation.required';
        } else {
          if (day && brkStart.format('dddd') !== day) error.startDate = translate('resources.drafts.validations.must_start_on_day', { day });
          if (earliestDate && brkStart.isBefore(earliestDate, 'day')) error.startDate = translate('resources.drafts.validations.must_be_after', { date: moment(earliestDate).subtract(1, 'day').format('YYYY-MM-DD') });
        }

        if (!brk.totalWeeks) {
          error.totalWeeks = 'ra.validation.required'
        } else if (brk.totalWeeks <= 0) {
          error.totalWeeks = 'ra.validation.invalid_number';
        }

        if (brk.startDate && brk.totalWeeks > 0 && !error.startDate) {
          const brkEnd = moment.utc(brkStart).add(brk.totalWeeks + 1, 'weeks')

          if (schedule?.endDate) {
            const scheduleEndDate = moment.utc(schedule.endDate, 'YYYY-MM-DD')
            if (brkEnd.isAfter(scheduleEndDate, 'day')) error.startDate = translate('resources.drafts.validations.must_be_before_schedule_end_date', { date: moment(scheduleEndDate).format('YYYY-MM-DD') })
          }
          if (index > 0) {
            const prev = breaks[index - 1];
            const brkRange = createRange(brkStart, brkEnd);
            if (prev?.startDate && prev?.totalWeeks) {
              const prevBrkStart = moment.utc(prev.startDate, 'YYYY-MM-DD');
              const prevBrkEnd = moment.utc(prev.startDate, 'YYYY-MM-DD').add(prev.totalWeeks + 1, 'weeks')
              const prevBrkRange = createRange(prevBrkStart, prevBrkEnd)
              if (brkRange.overlaps(prevBrkRange)) error.startDate = translate('resources.drafts.validations.must_be_after', { date: prevBrkEnd.subtract(1, 'day').format('YYYY-MM-DD') })
            }
          }
        }

        return error;
      })
    }
  }

  return errors;
}

const inputProps = {
  resource: 'drafts',
  basePath: '/drafts',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export default ({ draft = {}, schedule, preloadTeams, save, onNext, existingTeams, existingPreviousDraftTeams, loading, classes }) => {
  const isEnabled = useFlag();
  const translate = useTranslate()
  const store = useStore()
  const form = useForm();
  const [ alert, setAlert ] = useState({ isOpen: false })
  const existingTeamsRef = useRef(existingTeams);
  const existingPreviousDraftTeamsRef = useRef(existingPreviousDraftTeams);
  const scheduleRef = useRef(schedule)
  const groupsData = useSelector(state => state.admin.resources.groups.data);
  const { options = {}, groupId, scheduleId } = draft;
  const { roundType, type, previousDraftId, startRound, startNumber, method, totalRounds, gamesPerTeam, limitByes, crossGroup, weekdays } = options;

  const scheduleHasGroups = hasGroups(groupsData, schedule?.officeId, schedule?.type)

  const showCrossGroup = scheduleHasGroups && groupId === null // "No Groups" selected
  const showLimitByes = type === 'Single Rotation' && (isEnabled(FF_DRAFT_LIMIT_BYES) || limitByes != null)

  useEffect(() => {
    scheduleRef.current = schedule
    existingTeamsRef.current = existingTeams;
    existingPreviousDraftTeamsRef.current = existingPreviousDraftTeams;
  }, [ schedule, existingTeams, existingPreviousDraftTeams ])

  useWizard((values) => validate(values, scheduleRef.current, translate), async draft => {
    if (!await confirmPrevious(draft)) return
    if (!await confirmCrossGroup(draft)) return
    return confirmTeamListOverwrite(draft)
      .then(draft => save(draft))
      .then(draft => preloadTeams(draft))
      .then(draft => onNext(draft))
  }, { disableBack: true })

  useEffect(() => {
    if (method === 'gamesPerTeam' && type === 'Double Rotation') {
      form.change('options.method', 'totalRounds')
    }
    if (type === 'Double Rotation') {
      form.change('options.weekdaysType', 'Consecutive')
    } else {
      form.change('options.weekdaysType', 'Rotating')
    }
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (weekdays?.length && roundType !== ROUND_TYPES.WEEKDAYS) {
      form.change('options.weekdays', [])
    }
  }, [roundType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type !== 'Single Rotation' && crossGroup) {
      form.change('options.type', 'Single Rotation')
    }
  }, [crossGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!crossGroup && scheduleHasGroups && groupId == null) { // default to cross-group if no group selected
      form.change('options.crossGroup', true)
    }
    if (crossGroup && groupId != null) { // reset cross-group if no group not selected
      form.change('options.crossGroup')
    }
  }, [groupId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (method === 'totalRounds' && gamesPerTeam != null) {
      form.batch(() => {
        form.change('options.gamesPerTeam')
        form.change('options.totalRounds', gamesPerTeam)
      })
    }
    if (method === 'gamesPerTeam' && totalRounds != null) {
      form.batch(() => {
        form.change('options.totalRounds')
        form.change('options.gamesPerTeam', totalRounds)
      })
    }
  }, [method]) // eslint-disable-line react-hooks/exhaustive-deps

  const { data: previousDraft } = useQueryWithStore({
    type: GET_ONE,
    resource: 'drafts',
    payload: {
      id: previousDraftId,
    }
  })

  const confirmPrevious = async (draft) => {
    if (draft?.options?.previousDraftId) return true
    const state = store.getState();
    const drafts = state?.admin?.resources?.drafts?.data || {};
    const hasPreviousDraft = Object.values(drafts)
      .filter(previous => draft.scheduleId == previous.scheduleId && draft.groupId == previous.groupId && (!draft.id || draft.id !== previous.id))
      .some(draft => draft.isPublished)

    if (!hasPreviousDraft) return true;
    return new Promise(resolve => {
      setAlert({
        title: translate('resources.drafts.labels.previous_draft_alert'),
        message: translate('resources.drafts.messages.previous_draft_alert'),
        reject: 'ra.action.cancel',
        accept: 'ra.action.ok',
        isOpen: true,
        onClose: result => {
          resolve(result)
          setAlert(alert => ({ ...alert, isOpen: false }))
        }
      })
    })
  }

  const confirmCrossGroup = async (draft) => {
    const schedule = scheduleRef.current;
    const state = store.getState();
    const groups = state?.admin?.resources?.groups?.data || {};
    const scheduleHasGroups = hasGroups(groups, schedule?.officeId, schedule?.type)

    if (!scheduleHasGroups || draft?.groupId != null || draft?.options?.crossGroup !== undefined) return true
    return new Promise(resolve => {
      setAlert({
        title: translate('resources.drafts.labels.cross_group_alert'),
        message: translate('resources.drafts.messages.cross_group_alert'),
        reject: 'ra.action.cancel',
        accept: 'ra.action.no',
        extraButton: 'ra.action.yes',
        isOpen: true,
        onExtraButtonClose: result => {
          draft.options.crossGroup = true
          resolve(result)
          setAlert(alert => ({ ...alert, isOpen: false }))
        },
        onClose: result => {
          resolve(result)
          setAlert(alert => ({ ...alert, isOpen: false }))
        }
      })
    })
  }

  const confirmTeamListOverwrite = async (draft) => {
    const existingTeams = existingTeamsRef.current.filter(team => !draft?.groupId || team.groupId === draft?.groupId)
    const existingPreviousDraftTeams = existingPreviousDraftTeamsRef.current;
    const newTeams = existingPreviousDraftTeams?.length ? existingPreviousDraftTeams : existingTeams;
    const currentTeams = (draft?.teams || []).map(({ teamId, groupId }) => ({ teamId, groupId }))

    if (!newTeams?.length) return draft;

    const isEqualTeamList = JSON.stringify([...currentTeams].sort()) === JSON.stringify([...newTeams].sort());

    if (isEqualTeamList) return draft;

    if (currentTeams?.length) {
      const confirm = await new Promise(resolve => {
        setAlert({
          title: translate('resources.drafts.labels.replace_teams_alert'),
          message: translate('resources.drafts.messages.replace_teams_alert'),
          reject: 'ra.action.keep',
          accept: 'ra.action.replace',
          isOpen: true,
          onClose: result => {
            resolve(result)
            setAlert(alert => ({ ...alert, isOpen: false }))
          }
        })
      })

      if (!confirm) return draft
    }

    return { ...draft, teams: newTeams.map(({ teamId, groupId }) => ({ teamId, groupId })) }
  }

  const previousDraftHelperText = (() => {
    if (!startRound) return 'resources.drafts.helpers.previousDraftId_optional';
    return translate('resources.drafts.helpers.previousDraftId', { round: startRound+1 })
  })()

  const startNumberHelperText = (() => {
    if (!previousDraft?.draft) return false;

    const gameNumbers = previousDraft.draft?.games.map(game => game.number);
    gameNumbers.sort();

    if (startNumber) {
      const exists = gameNumbers.includes(startNumber);
      if (exists) return translate('resources.drafts.helpers.startNumber', { number: startNumber })
    }

    const previousDraftStartNumber = previousDraft.draft?.options?.startNumber;
    const endNumber = gameNumbers[gameNumbers.length - 1];
    return `${translate('resources.drafts.labels.previous_schedule')}: ${previousDraftStartNumber} - ${endNumber}`
  })()

  const weekdaysHelperText = (() => {
    if (type === 'Double Rotation') return 'resources.drafts.helpers.weekdays_double';
    return 'resources.drafts.helpers.weekdays'
  })()

  const startDateHelperText = (() => {
    let draftStart, previousDraftStart;

    const { startDate, method, totalRounds, breaks = [] } = draft?.options || {};
    if (startDate) {
      draftStart = moment.utc(startDate, 'YYYY-MM-DD').format('dddd');
    }

    const previousStartDate = previousDraft?.draft?.options?.startDate;
    if (previousStartDate) {
      previousDraftStart = moment.utc(previousStartDate).format('dddd');
    }

    if (!startDate && !previousStartDate) return false;

    const helperText = [];
    if (draftStart) {
      let message = translate('resources.drafts.helpers.startDate', { date: draftStart });

      if (method === 'gamesPerTeam') return message

      if (startDate && totalRounds) {
        const draftStart = moment.utc(startDate, 'YYYY-MM-DD');
        const endDay = (breaks || []).filter(Boolean).reduce((endDate, brk) => {
          if (brk.startDate && moment.utc(brk.startDate, 'YYYY-MM-DD').isAfter(endDate, 'day')) return endDate;
          return endDate.add(brk?.totalWeeks || 0, 'weeks');
        }, moment.utc(startDate, 'YYYY-MM-DD').add(totalRounds, 'weeks'))
        const range = translate('ra.date.range', { date1: draftStart.format('YYYY-MM-DD'), date2: endDay.subtract(1, 'day').format('YYYY-MM-DD') })
        const rangeTotalWeeks = translate('ra.date.phrase.week', totalRounds)
        message += ` (${rangeTotalWeeks}, ${range})`;
      }
      helperText.push(message)
    }
    if (previousDraftStart) helperText.push(translate('resources.drafts.helpers.startDate_previous', { date: previousDraftStart }))
    return helperText.join('\n')
  })()

  return <>
    <Grid container spacing={2} direction="column">
      <Grid item xs={12}>
        <Card>
          <CardHeader title="resources.drafts.labels.general" titleTypographyProps={{ variant: 'subtitle2', gutterBottom: false }} />
          <CardContent>
            <Grid container spacing={2} fullWidth>
              <Grid item xs={12}>
                <ScheduleInput
                  source="scheduleId"
                  {...inputProps}
                />
                <ScheduleGroupInput
                  source="groupId"
                  showNone={`${translate('resources.drafts.fields.options.crossGroup')} / ${translate('ra.message.no_group')}`}
                  {...inputProps}
                />
              </Grid>

              {scheduleId && <Grid item xs={12}>
                <DraftInput
                  source="options.previousDraftId"
                  helperText={previousDraftHelperText}
                  filter={{
                    isPublished: true,
                    type: 'Generated',
                    scheduleId,
                    groupId
                  }}
                  {...inputProps}
                />
              </Grid>}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title="resources.drafts.labels.matchups" titleTypographyProps={{ variant: 'subtitle2', gutterBottom: false }} />
          <CardContent>
            <Grid container spacing={2} fullWidth>
              <Grid item xs={12}>
                <DraftTypeEnumInput
                  source="options.type"
                  initialValue="Single Rotation"
                  getOptionDisabled={option => crossGroup && option.id === 'Double Rotation'}
                  {...inputProps} />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <DraftMethodEnumInput
                  source="options.method"
                  initialValue={isEnabled(FF_DRAFT_DEFAULT_ROUNDS) ? 'totalRounds' : 'gamesPerTeam'}
                  helperText=""
                  {...inputProps} />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <>
                  {method === 'totalRounds' && <NumberInput
                    source="options.totalRounds"
                    helperText=""
                    {...inputProps}
                  />}
                  {method === 'gamesPerTeam' && <NumberInput
                    source="options.gamesPerTeam"
                    helperText=""
                    {...inputProps}
                  />}
                </>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  source="options.startNumber"
                  helperText={startNumberHelperText}
                  placeholder="XY001"
                  {...inputProps}
                />
              </Grid>

              {showLimitByes && <Grid item xs={12}>
                <SwitchInput
                  source="options.limitByes"
                  label="resources.drafts.fields.options.limitByes"
                  helperText="resources.drafts.helpers.limitByes"
                  defaultValue={isEnabled(FF_DRAFT_LIMIT_BYES) || undefined}
                  disabled={crossGroup}
                />
              </Grid>}

              {showCrossGroup && <Grid item xs={12}>
                <SwitchInput
                  source="options.crossGroup"
                  label="resources.drafts.fields.options.crossGroup"
                  helperText="resources.drafts.helpers.crossGroup"
                  disabled={type === 'Double Rotation'}
                />
              </Grid>}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title="resources.drafts.labels.scheduling" titleTypographyProps={{ variant: 'subtitle2', gutterBottom: false }} />
          <CardContent>
            <Grid container spacing={2} fullWidth>
              <Grid item xs={12}>
                <DraftRoundTypeEnumInput
                  source="options.roundType"
                  initialValue={isEnabled(FF_DRAFT_DEFAULT_SLOTS) ? ROUND_TYPES.HOME_SLOTS : ROUND_TYPES.WEEKDAYS }
                  helperText="ra.message.optional"
                  {...inputProps}
                />
              </Grid>
              {roundType === ROUND_TYPES.WEEKDAYS && <Grid item xs={12}>
                <DayEnumInput
                  source="options.weekdays"
                  helperText={weekdaysHelperText}
                  select
                  multiple
                  abbreviation={false}
                  sortValues={false}
                  {...inputProps}
                />
              </Grid>}

              {(roundType === ROUND_TYPES.HOME_SLOTS || roundType === ROUND_TYPES.WEEKDAYS) && <>
                <Grid item xs={12}>
                  <DateInput
                    source="options.startDate"
                    helperText={startDateHelperText}
                    initialValue={schedule?.startDate}
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArrayInput source="options.breaks" {...inputProps}>
                    <HorizontalFormIterator hideIndex TransitionProps={TransitionProps}>
                      <InlineDateInput source="startDate" {...inputProps} />
                      <NumberInput source="totalWeeks" {...inputProps} />
                    </HorizontalFormIterator>
                  </ArrayInput>
                </Grid>
              </>}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <AlertDialog {...alert} />
  </>
}
