import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';

import { FF_DRAFT_GAMES } from '@hisports/common/featureFlags';

import { useFlag } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';
import ListCard from '../../../common/cards/ListCard';

import { EventView } from '../../events/EventView';
import { EditDraftGameDialog } from '../../draftgames/DraftGameModal';

export default props => {
  const game = useRecordContext(props);
  const [ draftGameId, setDraftGameId ] = useState()
  const isEnabled = useFlag();
  const canEdit = isEnabled(FF_DRAFT_GAMES) && isAuthorized(game, 'games', 'edit');
  const canApprove = isEnabled(FF_DRAFT_GAMES) && isAuthorized(game, 'games', 'approve', false, false);
  const showOnlyRequest = (!canEdit && canApprove);
  const requestsFilter = showOnlyRequest ? { draftGameId: { neq: null } } : null;

  const handleRowSelect = (id, basePath, draftGame) => {
    return canEdit || canApprove ? setDraftGameId(id) : null;
  }

  return <>
    <ListCard
      title="resources.games.labels.draftUpdates.card.title"
      subtitle="resources.games.labels.draftUpdates.card.subtitle"
      reference="draftGames"
      target="updatedGameId"
      rowsPerPageOptions={[]}
      filter={{ _include: 'requests', isPublished: false, requests: requestsFilter }}
      sort={{ field: 'startTime', order: 'ASC' }}
    >
      <EventView rowClick={handleRowSelect} hideGroup hideStatus disableCalendar showDraftActions />
    </ListCard>
    <EditDraftGameDialog isOpen={!!draftGameId} id={draftGameId} gameToUpdate={game} hideRounds handleClose={() => setDraftGameId()} />
  </>
}
