import React, { Fragment, useState } from 'react';
import { useTranslate, useRecordContext } from 'react-admin';
import { Button } from '@material-ui/core';

import { GameIcon } from '../icons';
import { AddDraftGameDialog } from '../draftgames/DraftGameModal';

export default ({ requestChanges, size, variant, ...props }) => {
  const game = useRecordContext(props)
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const { id: updatedGameId, ...rest } = game
  const initialValues = { updatedGameId, isPublished: false, ...rest }

  // Needed for api to determine if this is a request for changes. Gets removed before saving.
  if (requestChanges) {
    initialValues.requestChanges = true;
  }

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<GameIcon />} onClick={() => setOpen(true)}>
      {translate(`resources.games.labels.draftUpdates.${requestChanges ? 'request' : 'create'}`)}
    </Button>
    <AddDraftGameDialog initialValues={initialValues} isOpen={isOpen} component={Fragment} handleClose={() => setOpen(false)} hideRounds requestChanges={requestChanges} />
  </>
}
