import React from 'react';

import ResourceTitle from '../../common/ResourceTitle';
import { Create, Edit, Show } from "../../common/ra";

import { PayPeriodShowLayout } from './PayPeriodShow';
import { useTranslate } from 'react-admin';
import { PayPeriodForm } from './PayPeriodForm';

const getTitle = payPeriod => payPeriod.name

export const PayPeriodShow = props => {
  return <Show {...props}
    title={<ResourceTitle title={getTitle} />}
  >
    <PayPeriodShowLayout />
  </Show>
}

export const PayPeriodCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={() => translate('resources.payPeriods.labels.add')} />}
    {...props}
  >
    <PayPeriodForm redirect="show" />
  </Create>
}

export const PayPeriodEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: record?.name })} />}
    {...props}
  >
    <PayPeriodForm redirect="show" />
  </Edit>
}
