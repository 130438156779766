import React from 'react';
import { useResourceContext } from 'react-admin';
import { dedupe } from '@hisports/parsers';

import FunctionField from '../fields/FunctionField'

const TextArrayField = ({ emptyText, test, ...props }) => {
  const resource = useResourceContext(props);
  return <FunctionField render={(entity, source, _, translate) => {
    const values = dedupe(entity[source] || [])
    if (!values.length && emptyText) return translate(emptyText);
    return values
      .map(element => translate(test ? test(element) : `resources.${resource}.values.${source}.${element}`))
      .join(', ')
  }} {...props} />
}

TextArrayField.defaultProps = {
  addLabel: true
}

export default TextArrayField;
