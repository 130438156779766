import React from "react";

import ImageFile from "./ImageFile";
import TextFiles from './TextFiles';
import AudioFile from "./AudioFile";
import VideoFile from "./VideoFile";

const FileViewerPicker = ({ fileData, setPreviewInfo, fileName, onLoad, onPreviewFail, fileType, canDownload, downloadFile, containerRef, hasScroll, isMobile }) => {
  return (
    Array.isArray(fileData) ?// text files will be read line by line represented by an array
      <TextFiles fileData={fileData} />
      : fileData.type === 'audio' ?
        <AudioFile audioSource={fileData.src} fileType={fileType} canDownload={canDownload} downloadFile={downloadFile} onPreviewFail={onPreviewFail} />
        : fileData.type === 'video' ?
          <VideoFile videoSrc={fileData.src} fileType={fileType} canDownload={canDownload} downloadFile={downloadFile} onPreviewFail={onPreviewFail} />
          :
          <ImageFile
            fileData={fileData}
            fileType={fileType}
            fileName={fileName}
            onPreviewFail={onPreviewFail}
            setPreviewInfo={setPreviewInfo}
            containerRef={containerRef}
            hasScroll={hasScroll}
            onLoad={onLoad}
            isMobile={isMobile}
          />
  );
};

export default FileViewerPicker;
