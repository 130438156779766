import React from 'react';
import { useFormState } from 'react-final-form';

import { OfficeGroupInput, ScheduleGroupInput } from '../groups/GroupInput';

export const TargetGroupInput = props => {
  const { values } = useFormState();
  const { targetType, targetId } = values;

  switch (targetType) {
    case 'Office':
      return <OfficeGroupInput officeId={targetId} {...props} />
    case 'Schedule':
      return <ScheduleGroupInput scheduleId={targetId} {...props} />
    default:
      return null;
  }
}
