import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { PracticeForm } from './PracticeForm';

const AddPracticeDialog = props =>
  <CreateResourceDialog label="resources.practices.actions.add" maxWidth="md" resource="practices" basePath="/practices" {...props}>
    <PracticeForm />
  </CreateResourceDialog>

const EditPracticeDialog = props =>
  <EditResourceDialog label="resources.practices.labels.edit" maxWidth="md" resource="practices" basePath="/practices" {...props}>
    <PracticeForm />
  </EditResourceDialog>

export const AddPracticeButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.practices.actions.add')}</Button>
    <AddPracticeDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const PracticeDatagrid = props => <DialogDatagrid dialog={EditPracticeDialog} {...props} />
