import { useState } from 'react';
import { useQuery, GET_LIST, GET_MANY_REFERENCE } from 'ra-core';

export function useVenues() {
  const { data: venues, ...props } = useQuery({
    type: GET_LIST,
    resource: 'venues',
    payload: {
      filter: { _scope: 'Tenant' },
      pagination: { page: 0, perPage: 9999 },
      sort: {
        field: [ 'country', 'region', 'city', 'name' ],
        order: [ 'ASC', 'ASC', 'ASC', 'ASC' ],
      }
    }
  });

  return { venues, ...props };
}


export function useVenueSelection(participantId, venues = []) {
  const [ selection, setSelection ] = useState([]);
  const { data, ...props } = useQuery({
    type: GET_MANY_REFERENCE,
    resource: 'officialVenues',
    payload: {
      target: 'participants',
      id: participantId,
      pagination: { page: 0, perPage: 9999 },
      filter: {},
    }
  }, {
    enabled: participantId !== null && venues.length > 0,
    onSuccess({ data }) {
      if (!data?.length) return;
      const ids = data.map(officialVenue => officialVenue.venueId);
      setSelection(ids)
    }
  })

  return { selection, ...props }
}
