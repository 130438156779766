import React, { useState } from 'react';
import { RecordContextProvider, useListContext, useRefresh, useRecordContext, useTranslate } from 'react-admin';
import { Button, Dialog, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { apiClient } from '../../http';

import TeamArenaForm from './TeamArenaForm';

const EditTeamArenasDialog = ({ title, isOpen, closeModal, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const refresh = useRefresh();
  const translate = useTranslate();
  const record = useRecordContext();
  const { data } = useListContext();
  const teamArenas = Object.values(data);

  const onSubmit = async (values = {}) => {
    const arenas = (values?.teamArenas || []).map((teamArena, index) => ({
      ...teamArena,
      id: teamArena.id || undefined,
      teamId: record.id,
      order: index + 1
    }))

    if (record.id) {
      await apiClient(`/teams/${record.id}/teamArenas`, {
        method: 'PATCH',
        data: { arenas },
      })
      refresh()
    }

    closeModal()
  }

  const onCancel = () => {
    closeModal();
  }

  return <Dialog open={isOpen} maxWidth="md" fullWidth fullScreen={fullScreen}>
    <DialogTitle>{translate('resources.teamArenas.actions.edit')}</DialogTitle>
    <DialogContent>
      <RecordContextProvider value={{ teamArenas }}>
        <TeamArenaForm source="teamArenas" onSubmit={onSubmit} onCancel={onCancel} />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

export const EditTeamArenasButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" startIcon={<Edit />} size={size} variant={variant} onClick={() => setOpen(true)}>
      {translate('resources.teamArenas.actions.edit')}
    </Button>
    <EditTeamArenasDialog isOpen={isOpen} closeModal={() => setOpen(false)} {...props} />
  </>
}
