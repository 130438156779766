import React from 'react';
import { Box, CircularProgress } from "@material-ui/core";

import { isEmpty } from '@hisports/parsers';

const byPosition = position => flag => {
  if (!position) return true;
  if (flag === 'Scorekeeper Level' && position !== 'Scorekeeper') return false;
  if (flag === 'Timekeeper Level' && position !== 'Timekeeper') return false;
  if (flag === 'Official Level' && position !== 'Official') return false;
  if (flag === 'Referee Level' && position !== 'Referee') return false;
  if (flag === 'Referee Grade' && position !== 'Referee') return false;
  if (flag === 'Linesperson Level' && !['Linesperson', 'LinespersonTwo', 'LinespersonThree'].includes(position)) return false;
  if (flag === 'Linesperson Grade' && !['Linesperson', 'LinespersonTwo', 'LinespersonThree'].includes(position)) return false;
  return true;
}

const byFilters = filters => flag => {
  if (!filters) return true;
  if (flag === 'Arena' && filters.ignoreArena) return false;
  return true;
}

export const filterFlags = (flags = [], position, filters) =>
  flags
    .filter(byPosition(position))
    .filter(byFilters(filters))

export const getAssignmentIndex = (assignments, assignmentId) => assignments.findIndex(assignment => assignment.id === assignmentId) + 1;

export const ineligibleFlags = [
  'Scorekeeper Level',
  'Timekeeper Level',
  'Official Level',
  'Referee Level',
  'Linesperson Level',
  'Referee Grade',
  'Linesperson Grade',
  'Underage',
  'Team',
  'Roster',
  'Schedule',
  'Group',
  'Game Conflict',
  'Daily Limit',
]

export const unavailableFlags = [
  'Game Overlap',
  'Game Participant',
  'Unavailable',
  'Availability',
  'Office',
  'Arena',
  'Travel',
]

const unregisteredFlags = [
  'Not Registered',
  'Invited',
]

const metaFlags = [
  'Local',
  'New',
]

export const hasFlag = (flags, flag) => flags.includes(flag);
export const hasAnyFlag = (officialFlags, flags) => flags.some(flag => officialFlags.includes(flag));

export const isIneligible = flags => hasAnyFlag(flags, ineligibleFlags);
export const isUnavailable = flags => hasAnyFlag(flags, unavailableFlags);
export const isUnregistered = flags => hasAnyFlag(flags, unregisteredFlags);
export const isExpired = flags => hasFlag(flags, 'Expired')
export const isConflict = flags => hasFlag(flags, 'Game Conflict');
export const isAtArena = flags => hasFlag(flags, 'At Arena');
export const isAssigned = flags => hasFlag(flags, 'Assigned');
export const isAssignedSameDay = flags => hasFlag(flags, 'Assigned Today')
export const isRequested = flags => hasFlag(flags, 'Requested');
export const isUnconfirmed = flags => hasFlag(flags, 'Unconfirmed');
export const isDeclined = flags => hasFlag(flags, 'Declined');
export const isNoShow = flags => hasFlag(flags, 'No Show');
export const isNew = flags => hasFlag(flags, 'New');
export const isLocal = flags => hasFlag(flags, 'Local');
export const isRemoved = flags => hasFlag(flags, 'Removed');
export const isConsecutive = flags => hasFlag(flags, 'Consecutive');

export const isReady = flags =>
  flags.filter(flag => !metaFlags.includes(flag)).length === 0

export const getMeta = (meta, flag, attribute) => {
  if (!meta || !flag || !meta[flag]) return;
  if (!attribute) return meta[flag];
  return meta[flag][attribute]
}

export const hasAttributeValuesFilter = (filters, attributeTypes) => {
  return attributeTypes.some(attributeType => !isEmpty(filters[`attributeValues[${attributeType.id}]`]))
}

export const hasMatchingAttributeValues = (filters, officialAttributeValues, attributeTypes) => {
  if (isEmpty(officialAttributeValues)) return false;

  const results = [];
  attributeTypes.forEach((attributeType, index) => {
    const { id, valueType } = attributeType;
    if (isEmpty(filters[`attributeValues[${id}]`])) return;
    results[index] = false;

    officialAttributeValues.forEach(officialAttributeValue => {
      const { attributeId, value } = officialAttributeValue;
      if (attributeId !== id) return;

      switch (valueType) {
        case 'Dropdown':
          if (filters[`attributeValues[${id}]`].includes(value)) {
            results[index] = true;
          }
          break;

        case 'Text':
          if (value.toUpperCase().includes(filters[`attributeValues[${id}]`].toUpperCase())) {
            results[index] = true;
          }
          break;

        case 'Number':
          if (value == filters[`attributeValues[${id}]`]) {
            results[index] = true;
          }
          break;

        default:
          results[index] = false;
      }
    })
  })

  const method = filters.matchAllAttributes ? 'every' : 'some';
  return results[method](matching => matching)
}

export const LoadingSpinner = props =>
  <Box display="flex" alignItems="center" justifyContent="center" flex={1} {...props}>
    <CircularProgress />
  </Box>
