import React, { useRef } from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import createCalculator from 'final-form-calculate';

import Toolbar from '../../common/ra/Toolbar';
import { InlineDateInput } from '../../common/inputs/DateInput';
import SwitchInput from '../../common/inputs/SwitchInput';

import { RoleInput } from '../roles/RoleInput';
import { TargetInput } from '../targets/TargetInput';
import { TargetGroupInput } from '../targets/TargetGroupInput';
import { AccountInput } from '../accounts/AccountInput';
import { ParticipantInput } from '../participants/ParticipantInput';
import { CategoryInput } from '../categories/CategoryInput';
import { useBranchSettings } from '../branchOfficeSettings';

const validate = (values, canUseRole) => {
  const errors = {};

  if (!values.principalId) errors.principalId = 'ra.validation.required'
  if (!values.principalType) errors.principalType = 'ra.validation.required'
  if (!values.roleId) errors.roleId = 'ra.validation.required'
  if (!values.targetType) errors.targetType = 'ra.validation.required'
  if (!values.targetId) errors.targetId = 'ra.validation.required'

  if (values.roleId && values.targetId && !canUseRole) {
    errors.roleId = 'resources.permissions.validations.role_not_activated';
  }

  return errors;
}

const PrincipalInput = ({ principalType, ...props }) => {
  const translate = useTranslate();
  if (principalType === 'Account') return <AccountInput label={translate('resources.accounts.name', 1)} {...props} />
  if (principalType === 'Participant') return <ParticipantInput label="resources.accounts.fields.participantId" filter={{ _scope: 'Tenant' }} {...props} />
  return null;
}

const OfficeAttributeGrid = props => {
  const { values } = useFormState();
  const { targetType, targetId } = values;

  if (targetType !== 'Office' || !targetId) return null;
  return <Grid {...props} />
}

const ScopeAttributeGrid = ({ scope, ...props }) => {
  const { values } = useFormState();
  const { roleId } = values;

  const role = useSelector(state => state.admin.resources.roles.data?.[roleId])
  if (!role?.scopes.includes(scope)) return null;
  return <Grid {...props} />
}

const inputProps = {
  resource: 'permissions',
  basePath: '/permissions',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const isRoleEnabled = (selectedRole, roleName, branchSettings) => {
  return selectedRole?.name !== roleName || (selectedRole?.name === roleName && branchSettings);
};

export const RoleForm = props => {
  const translate = useTranslate();
  // must use ref for roles to get current value, otherwise value in calculator is stale
  const roles = useSelector(store => store.admin.resources.roles.data)
  const rolesRef = useRef({});
  rolesRef.current = roles;

  const officeIdRef = useRef();
  const selectedRoleRef = useRef();

  const { data: branchSettings } = useBranchSettings(officeIdRef.current);
  const canUseRole = isRoleEnabled(selectedRoleRef.current, 'discipline-committee', branchSettings?.ff_suspension_disciplines);

  // decorators must be wrapped in useRef otherwise SimpleForm prop will register duplicates
  // this bug seems to be triggered by use of hooks (useSelector)
  const decorators = useRef([createCalculator({
    field: 'roleId',
    updates: {
      targetType: (roleId, values, prevValues) => {
        const role = rolesRef.current[roleId];
        if (!role) return;
        selectedRoleRef.current = role;
        return role.targetType;
      }
    }
  }, {
    field: 'targetType',
    isEqual(next, prev) {
      if (typeof prev === 'undefined') return true; // ignored
      return next === prev;
    },
    updates: {
      targetId: () => {},
      categoryIds: () => {},
      groupIds: () => {},
    }
  }, {
    field: 'targetId',
    isEqual(next, prev) {
      officeIdRef.current = next;
      if (typeof prev === 'undefined') return true; // ignored
      return next === prev;
    },
    updates: {
      groupIds: () => {},
    }
  })])

  const { id, principalType, principalId, targetType, targetId } = props.initialValues || {};
  const principalDisabled = id || principalId;
  const targetDisabled = id || targetId;
  const legacy = id && principalType !== 'Participant';

  return <SimpleForm toolbar={<Toolbar />} decorators={decorators.current} validate={(v) => validate(v, canUseRole)} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <PrincipalInput
          source="principalId"
          principalType={principalType}
          disabled={principalDisabled}
          helperText={legacy ? translate('resources.permissions.helpers.principalId') : ''}
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12}>
        <RoleInput source="roleId" filter={{ targetType }} {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <TargetInput source="targetId" disabled={targetDisabled} allowEmpty {...inputProps} />
      </Grid>

      <OfficeAttributeGrid item xs={12}>
        <CategoryInput source="categoryIds" select multiple allowEmpty emptyLabel="All" helperText="resources.permissions.helpers.categoryIds" {...inputProps} />
      </OfficeAttributeGrid>

      <Grid item xs={12}>
        <TargetGroupInput source="groupIds" allowEmpty multiple filterByCategories={false} helperText="resources.permissions.helpers.groupIds" {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <InlineDateInput source="expiry" helperText="resources.permissions.helpers.expiry" {...inputProps} />
      </Grid>

      <ScopeAttributeGrid item xs={12} scope="stats:reports">
        <SwitchInput source="reportsDisabled" allowEmpty helperText="" options={{ color: 'secondary' }} {...inputProps} />
      </ScopeAttributeGrid>

      <ScopeAttributeGrid item xs={12} scope="scoresheets:manage">
        <SwitchInput source="scorekeepingDisabled" allowEmpty helperText="" options={{ color: 'secondary' }} {...inputProps} />
      </ScopeAttributeGrid>
    </Grid>
  </SimpleForm>
}
