import React from 'react';
import { ReferenceArrayField, useRecordContext, useTranslate } from 'react-admin';

import FunctionField from '../../common/fields/FunctionField'
import { useSeason } from '../../common/inputs/SeasonSelector';
import { SingleFieldList } from '../../common/ra/SingleFieldList';
import { useDefaultSeason } from '../../http';

export const GroupsField = ({ showType = false, emptyText, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  const appSeason = useSeason();
  const defaultSeason = useDefaultSeason();

  const values = record?.[props.source]
  if (!values?.length && emptyText) return translate(emptyText)

  const getGroupName = (group, showType) => {
    const { name, type, seasonId } = group;
    const meta = [];
    if (seasonId != appSeason || seasonId !== defaultSeason) meta.push(seasonId);
    if (showType) meta.push(translate(`resources.schedules.values.type.${type}`));
    if (meta.length) return `${name} (${meta.join(' ')})`
    return name;
  }

  return <ReferenceArrayField basePath="/groups" resource="groups" reference="groups" allowEmpty {...props} >
    <SingleFieldList link={false}>
      <FunctionField render={group => getGroupName(group, showType)} />
    </SingleFieldList>
  </ReferenceArrayField>
}

GroupsField.defaultProps = {
  addLabel: true,
}
