import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { ListMemberGrid } from '../../listMembers/ListMemberGrid';

export default props => {
  const participant = useRecordContext(props);
  const showLists = isAuthorized(participant, 'listMembers', 'list')

  if (!showLists) return null;
  return <ListCard
    title="resources.lists.labels.card.title"
    reference="listMembers"
    target="participants"
  >
    <ListMemberGrid />
  </ListCard>
}
