import React, { useState } from 'react';
import { styled, Typography, makeStyles, Box, Tooltip, Chip } from '@material-ui/core'
import { useListContext, useTranslate } from 'react-admin';
import moment from 'moment-timezone';

import { CALENDAR_VIEWS } from '../../../resources/events/EventViewSettings';
import DayGrid from './DayGrid';
import MonthGrid from './MonthGrid';
import { useCalendarContext } from '../CalendarContext';
import { useSchedulingContext } from '../SchedulingContext';
import SeasonGrid from './SeasonGrid';
import { CalendarNavigation } from '../CalendarNavigation';
import DatePicker from '../../inputs/MuiInputs/DatePicker';

const useStyle = makeStyles((theme) => ({
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
  dateInputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  weekdayChips: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
}))

const useScheduledGamesStyle = makeStyles((theme) => ({
  scheduledGames: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    cursor: 'default',
  },
  progress: {
    display: 'flex',
    width: theme.spacing(20),
    gap: theme.spacing(.5),
    borderRadius: theme.spacing(.5),
    overflow: 'hidden',
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1.5,
    height: '1.5em',
  },
  progressCompleted: {
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
  },
  progressNotCompleted: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[300],
  },

}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  height: '41px',
}))

const TotalScheduledGames = ({ games }) => {
  const classes = useScheduledGamesStyle();
  const translate = useTranslate();

  if (!games) return null

  const totalGames = games.length;
  const totalScheduledGames = games.filter(game => game?.date).length
  const totalMissingGames = totalGames - totalScheduledGames
  const percentCompleted = (totalScheduledGames / totalGames).toFixed(2)

  const completedTooltip = translate('components.calendar.tooltips.scheduled_games', { count: totalScheduledGames, totalCount: 5 });
  const notCompletedTooltip = translate('components.calendar.tooltips.not_scheduled_games', { count: totalMissingGames });


  const completedStyles = {
    width: `${percentCompleted * 100}%`,
    flexShrink: 1 - percentCompleted, /* make sure that it skrinks equally on both sides of the gap */
  }

  const notCompletedStyles = {
    width: `${(1 - percentCompleted) * 100}%`,
    flexShrink: percentCompleted, /* make sure that it skrinks equally on both sides of the gap */
  }

  return (
    <div className={classes.scheduledGames}>
      <div className={classes.progress}>
        <Tooltip title={completedTooltip}>
          <div className={classes.progressCompleted} style={completedStyles}></div>
        </Tooltip>
        <Tooltip title={notCompletedTooltip}>
          <div className={classes.progressNotCompleted} style={notCompletedStyles}></div>
        </Tooltip>
      </div>
      {totalGames !== 0 && (
        <Tooltip title={`${totalScheduledGames} / ${totalGames}`}>
          <div className={classes.percent}>{percentCompleted * 100}%</div>
        </Tooltip>
      )}
    </div>
  )
}

export const WeekdaySelect = () => {
  const translate = useTranslate();
  const classes = useStyle();
  const { dateFilter, setDateFilter } = useSchedulingContext();
  const weekdays = dateFilter?.weekdays || [];

  const handleToggle = (value) => () => {
    if (value === null) {
      setDateFilter(prev => ({ ...prev, weekdays: [] }));
      return;
    }

    const currentIndex = weekdays.indexOf(value);
    const newWeekdays = [...weekdays];

    if (currentIndex === -1) {
      newWeekdays.push(value);
    } else {
      newWeekdays.splice(currentIndex, 1);
    }

    setDateFilter(prev => ({ ...prev, weekdays: newWeekdays }));
  };

  return (
    <div className={classes.weekdayChips}>
      <Tooltip title={translate('ra.date.days.long.All')}>
        <Chip variant={weekdays.length === 0 ? 'default' : 'outlined'} onClick={handleToggle(null)} label={translate('ra.date.days.short.All')} />
      </Tooltip>
      {[ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ].map((value) => {
        return (
          <Tooltip key={value} title={translate(`ra.date.days.long.${value}`)}>
            <Chip
              variant={weekdays.includes(value) ? 'default' : 'outlined'}
              onClick={handleToggle(value)}
              label={translate(`ra.date.days.short.${value}`)?.charAt(0)}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}

const DateInputs = () => {
  const classes = useStyle();
  const translate = useTranslate();
  const { schedule, dateFilter, setDateFilter } = useSchedulingContext();
  const [pendingDates, setPendingDates] = useState(null); // used to store the dates after violation
  const [error, setError] = useState(false);

  const handleChange = (property) => (value) => {
    if (!property) return;
    setError(false);

    const newDate = moment.utc(value).format('YYYY-MM-DD');

    if (property === 'startDate' && moment.utc(value).isAfter(pendingDates?.endDate || dateFilter?.endDate, 'day')) {
      setPendingDates((prev) => ({ ...prev, startDate: newDate }))
      return setError({ startDate: translate('components.calendar.validations.start_after_end') });
    } else if (property === 'endDate' && moment.utc(value).isBefore(pendingDates?.startDate || dateFilter?.startDate, 'day')) {
      setPendingDates((prev) => ({ ...prev, endDate: newDate }))
      return setError({ endDate: translate('components.calendar.validations.end_before_start') });
    }

    if (!value) {
      const scheduleStartDate = moment.utc(schedule.startDate);
      const scheduleEndDate = moment.utc(schedule.endDate);
      const oneMonthFromStartDate = scheduleStartDate.clone().add(1, 'month');

      const endDate = oneMonthFromStartDate.isAfter(scheduleEndDate, 'day')
        ? scheduleEndDate
        : oneMonthFromStartDate;

      return setDateFilter(prev => ({
        ...prev,
        startDate: property === 'startDate' ? scheduleStartDate.format('YYYY-MM-DD') : prev.startDate,
        endDate: property === 'endDate' ? endDate.format('YYYY-MM-DD') : prev.endDate,
      }));
    }

    setDateFilter(prev => ({ ...prev, ...pendingDates, [property]: newDate }));
    setPendingDates(null);
  }

  return <div className={classes.dateInputContainer}>
    <DatePicker label={translate('resources.schedules.fields.startDate')} onChange={handleChange('startDate')} error={error?.startDate} />
    <DatePicker label={translate('resources.schedules.fields.endDate')} onChange={handleChange('endDate')} error={error?.endDate} />
  </div>
}

const CalendarHeader = ({ header }) => {
  const classes = useStyle();
  const { data } = useListContext();
  const { type } = useCalendarContext();

  const games = Object.values(data)

  return <>
    <Header>
      <Box sx={{ paddingBlock: 8 }}><Typography variant="h6">{header}</Typography></Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 8 }}>
        {type === CALENDAR_VIEWS.SEASON && <TotalScheduledGames games={games} />}
        <span><CalendarNavigation /></span>
      </Box>
    </Header>
    {type === CALENDAR_VIEWS.SEASON && <Box className={classes.filtersContainer}>
      <WeekdaySelect />
      <DateInputs />
    </Box>}
  </>
}

export default ({ offsetHours, children }) => {
  const { type, disabled, header, alert } = useCalendarContext();
  const classes = useStyle();

  return <div style={{ position: 'relative' }}>
    {alert}
    <div className={disabled ? classes.disabled : ''}>
      <CalendarHeader header={header} />
      {[CALENDAR_VIEWS.DAY, CALENDAR_VIEWS.WEEK].includes(type) && <DayGrid offsetHours={offsetHours} >
        {children}
      </DayGrid>}
      {type === CALENDAR_VIEWS.MONTH && <MonthGrid>
        {children}
      </MonthGrid>}
      {type === CALENDAR_VIEWS.SEASON && <SeasonGrid>
        {children}
      </SeasonGrid>}
    </div>
  </div>
}
