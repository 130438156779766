import React from 'react';
import { TextInput } from 'react-admin';

const filterOfficialParticipants = name => ({ name })

export const OfficialParticipantInput = ({ allowEmpty, helperText, ...props }) => {
  return <TextInput
    reference="participants"
    filterToQuery={filterOfficialParticipants}
    sort={{
      field: 'lastName',
      order: 'ASC'
    }}
    perPage={25}
    {...props}
  />
}
