import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab'


import { isAuthorized } from '../../../../common/Authorize';

import { CertificationModal } from '../CertificationModal';

const useStyles = makeStyles(theme => ({
  alert: {
    '& .MuiAlert-action': {
      paddingTop: theme.spacing(0.25),
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    }
  },
  flags: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(-2),
  }
}))

const CertifyButton = ({ ...props }) => {
  const translate = useTranslate();
  const [ modalOpen, setModalOpen ] = useState(false);

  return <>
    <Button color="primary" variant="contained" size="small" onClick={() => setModalOpen(true)}>
      {translate('ra.action.certify')}
    </Button>
    {modalOpen && <CertificationModal open={modalOpen} setModalOpen={setModalOpen} {...props} />}
  </>
}

export const CertifyAlert = props => {
  const classes = useStyles();
  const game = useRecordContext(props);
  const translate = useTranslate();

  const showButton = isAuthorized(game, 'scoresheets', 'full');

  return <Alert className={classes.alert} severity="warning" action={[
    showButton && <CertifyButton {...props} />,
  ]}>
    {translate('resources.scoresheets.alerts.not_certified')}
  </Alert>
}
