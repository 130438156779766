import React, { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import { isEmpty, sortBy } from 'lodash';

import { createRange } from '@hisports/common';

import { apiClient } from '../../http';

import { usePracticeAvailabilityContext } from './PracticeAvailabilityContext';
import { AvailabilityDetails } from '../games/GameAvailabilityInfo';
import { useOfficeSettingsWithStore } from '../officeSettings';

const useAvailabilityContext = (practice, enabled) => {
  const {
    availabilityInfo: [ practiceAvailabilities, setAvailabilities ],
    settings: [ settings, setSettings ],
  } = usePracticeAvailabilityContext();

  const { id, date, startTime, endTime, timezone, teamIds, arenaId, officeId } = practice;
  const { data: officeSettings, loading: officeSettingsLoading } = useOfficeSettingsWithStore(officeId, enabled)

  useEffect(() => {
    if (!enabled) return;

    apiClient('/practices/availabilities', {
      method: 'GET',
      params: {
        id,
        date,
        startTime,
        endTime,
        timezone,
        teamIds,
        arenaId,
        officeId,
      }
    })
      .then(res => res.data)
      .then(setAvailabilities)
  }, [ id, date, startTime, endTime, timezone, teamIds, arenaId, officeId, enabled, setAvailabilities ])

  useEffect(() => {
    if (!enabled || !officeId || !officeSettings || officeSettingsLoading) return;

    setSettings(officeSettings)
  }, [ enabled, officeSettings, officeSettingsLoading, setSettings, officeId ])


  return practiceAvailabilities;
}

export const useAvailabilities = practice => {
  const { availabilityInfo: [ practiceAvailabilities ], settings: [ settings ] } = usePracticeAvailabilityContext();

  const availabilities = practiceAvailabilities?.availabilities || [];
  const conflicts = practiceAvailabilities?.conflicts || [];

  let timeAvailabilities = []
  const eventRange = createRange(moment.tz(practice.startTime, practice.timezone), moment.tz(practice.endTime, practice.timezone))
  if (availabilities) {
    timeAvailabilities = availabilities.filter(availability => {
      const availabilityRange = createRange(moment.tz(availability.startTime, availability.timezone), moment.tz(availability.endTime, availability.timezone));
      return availabilityRange.overlaps(eventRange)
    })

    timeAvailabilities = sortBy(timeAvailabilities, ({ targetType }) => {
      if (targetType === 'Surface') return 1;
      if (targetType === 'Team') return 2;
      if (targetType === 'Office') return 3;
      return 3;
    })
  }

  return {
    availabilities: timeAvailabilities,
    conflicts,
    settings,
  };
}

export const PracticeAvailabilityInfo = (props) => {
  const { values: practice, errors } = useFormState();
  const hasErrors = Object.keys(errors).some(key => key !== 'status');
  const enabled = practice.date != null && !hasErrors;

  const data = useAvailabilityContext(practice, enabled);
  const { availabilities, conflicts } = useAvailabilities(practice);

  if (isEmpty(data)) return null;
  if (!enabled || (!availabilities.length && !conflicts.length)) return null;

  return <Grid item xs={12}>
    <AvailabilityDetails availabilities={availabilities} conflicts={conflicts} />
  </Grid>
}

