import React, { useState, useEffect, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core'
import { FileCopyOutlined } from '@material-ui/icons';

import { apiClient } from '../../../http';

const getFeedUrl = participantId => {
  return apiClient(`/participants/${participantId}/calendarFeed`)
    .then(res => res.data)
}

const generateFeedUrl = participantId => {
  return apiClient(`/participants/${participantId}/calendarFeed`, { method: 'POST' })
    .then(res => res.data)
}

export default ({ participantId, isOpen = false, onClose }) => {
  const translate = useTranslate()
  const inputRef = useRef()
  const [ loading, setLoading ] = useState(true);
  const [ feedUrl, setFeedUrl ] = useState(null);

  const hasFeed = feedUrl != null;
  const canCopy = document.queryCommandSupported('copy');

  useEffect(() => {
    if (!isOpen || hasFeed) return;
    setLoading(true);
    getFeedUrl(participantId)
      .then(setFeedUrl)
      .finally(() => setLoading(false))
  }, [ isOpen, hasFeed, participantId ]);

  const onCreate = () => {
    setLoading(true)
    generateFeedUrl(participantId)
      .then(setFeedUrl)
      .finally(() => setLoading(false))
  }

  const onCopy = () => {
    inputRef.current.select();
    document.execCommand('copy')
  }

  return <Dialog
    open={isOpen}
    onClose={() => onClose()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{translate('resources.games.messages.calendar.subscribe')}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {translate('resources.games.messages.calendar.subscribe_description')}
        <p dangerouslySetInnerHTML={{ __html: translate('resources.games.messages.calendar.subscribe_description_extra') }}></p>
      </DialogContentText>
      {hasFeed && <TextField
        label={translate('resources.profiles.labels.personal_calendar_feed')}
        variant="outlined"
        fullWidth
        readonly
        value={loading ? translate('ra.page.loading') : feedUrl}
        InputProps={{
          inputRef,
          endAdornment: canCopy && <InputAdornment position="end">
            <Tooltip title={translate('ra.action.copy_to_clipboard')}>
              <IconButton edge="end" onClick={onCopy}>
                <FileCopyOutlined />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }}
      />}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)}>{translate('ra.action.close')}</Button>
      {!hasFeed && <Button color="primary" disabled={loading} onClick={onCreate}>{translate('ra.action.subscribe')}</Button>}
    </DialogActions>
  </Dialog>
}
