import React, { useRef, forwardRef, cloneElement, Children, useMemo } from 'react';
import { Table, TableBody, TableRow, TableCell, styled } from '@material-ui/core'
import moment from 'moment-timezone';

import { dedupe } from '@hisports/parsers';

import { CALENDAR_VIEWS } from '../../../resources/events/EventViewSettings';
import { useElementSize } from '../../useElementSize';
import { useCalendarContext } from '../CalendarContext';

const ScheduleView = styled('div')({
  maxHeight: '100vh',
  overflowY: 'auto',
  position: 'relative',
  '& .react-grid-item.react-grid-placeholder': {
    background: 'grey',
  }
})

const HeaderTable = styled(Table)({
  tableLayout: 'fixed',
})

const DayCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
}))

const HourCell = styled(TableCell)(({ theme }) => ({
  height: theme.spacing(4),
  borderRight: `1px solid ${theme.palette.divider}`,
  position: 'relative',
}))

const HourInfoCell = styled(TableCell)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(4),
  borderRight: `1px solid ${theme.palette.divider}`,
  borderBottom: 0,
  textAlign: 'center',
}))

const TimezoneCell = styled(TableCell)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(4),
  fontSize: '0.75rem',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  verticalAlign: 'bottom',
}))

const HourInfo = styled('span')(({ theme }) => ({
  fontSize: '12px',
  position: 'relative',
  top: theme.spacing(-3.5),
  color: theme.palette.grey[500],
}))

const getOffsetDifference = (timezoneA, timezoneB) => {
  const now = moment.utc();

  const offsetA = moment.tz.zone(timezoneA).utcOffset(now);
  const offsetB = moment.tz.zone(timezoneB).utcOffset(now);
  return Math.floor(offsetA - offsetB) / 60
}

const Row = ({ index, offset }) => {
  const { columns, getColumnKey, timezones } = useCalendarContext();
  const primaryTimezone = timezones[0];

  return <TableRow>
    {dedupe(timezones).map(timezone => {
      const offsetFromPrimaryTimezone = getOffsetDifference(primaryTimezone, timezone)
      const hour = index + offset + offsetFromPrimaryTimezone
      return <HourInfoCell>
        <HourInfo>{index !== 0 && moment().hours(hour).minutes(0).format('h A')}</HourInfo>
      </HourInfoCell>
    })}
    {columns.map(column => <HourCell key={getColumnKey(column)} />)}
  </TableRow>
}

// Time grid
const ScheduleTable = forwardRef(({ hours, offset }, ref) => {
  return <Table>
    <TableBody ref={ref}>
      {hours.map((_, index) => {
        return <Row index={index} offset={offset} />
      })
      }
    </TableBody>
  </Table>
})

const ColumnHeaders = props => {
  const { getColumnKey, getColumnHeading, columns, start, timezones, type } = useCalendarContext();

  return <HeaderTable>
    <TableBody>
      <TableRow>
        {timezones.map(timezone => <TimezoneCell>{moment.tz(start, timezone).format('z')}</TimezoneCell>)}
        {columns?.map(value => <DayCell key={getColumnKey(value)}>
          {getColumnHeading(value)}
        </DayCell>)}
      </TableRow>
    </TableBody>
  </HeaderTable>
}

export default ({ offsetHours, children }) => {
  const { start, timezones = [], type, columns } = useCalendarContext();
  const bodyRef = useRef(null);

  // the top row is shorter than usual (TODO: why?) and the first columns (depending on timezones) are the time column
  const cellRef = useRef(null);
  cellRef.current = bodyRef?.current?.querySelector(`tr:nth-child(${timezones.length + 1}) td:nth-child(${timezones.length + 1})`)

  const timeColumnRef = useRef(null);
  timeColumnRef.current = bodyRef?.current?.querySelector('td')

  const size = useElementSize(cellRef.current);
  const timeColumnSize = useElementSize(timeColumnRef.current);
  const isMeasured = size.height > 0 && size.width > 0

  const offset = useMemo(() => ({
    width: timeColumnSize.width * timezones.length,
    height: 0,
  }), [timeColumnSize, timezones])

  const startDate = moment(start);
  const totalHours = 24 - offsetHours;
  const hours = totalHours > 0 ? Array(totalHours).fill(null) : [];

  if (type === CALENDAR_VIEWS.WEEK && columns?.length !== 7) return null;

  return <>
    <ColumnHeaders />
    <ScheduleView>
      <ScheduleTable hours={hours} offset={offsetHours} ref={bodyRef} />
      {isMeasured && Children.map(children, child => {
        if (!child) return null;
        return cloneElement(child, { ...child.props, size, offset, startDate, offsetHours });
      })}
    </ScheduleView>
  </>
}
