import React from 'react';
import { TextField, useTranslate } from "react-admin";
import { Tooltip, makeStyles } from '@material-ui/core';
import { Archive, SportsHockey } from '@material-ui/icons';
import { useFormState } from 'react-final-form';

import { getChoiceLabel } from '@hisports/scoresheet/src/util';

import FunctionField from '../../../common/fields/FunctionField'
import { InfractionDatagrid } from './InfractionModal';

const useStyles = makeStyles({
  list: {
    paddingInlineStart: '12px',
  }
})

const Stick = () => {
  const translate = useTranslate();
  return <Tooltip title={translate('resources.rulebooks.fields.isStick')}>
    <SportsHockey />
  </Tooltip>
}

const Archived = () => {
  const translate = useTranslate();
  return <Tooltip title={translate('resources.rulebooks.fields.isArchived')}>
    <Archive />
  </Tooltip>
}

const ChoiceList = ({ record = {}, className }) => {
  const { values } = useFormState();
  const { durations } = values;
  const { choices } = record;
  return <ul className={className}>
    {(choices || []).map(choice =>
      <li key={choice.id} >{getChoiceLabel(choice, durations, '+')}</li>
    )}
  </ul>
}

export const InfractionGrid = props => {
  const classes = useStyles();
  return <InfractionDatagrid size="medium" {...props}>
    <TextField source="name" />
    <ChoiceList source="choices" className={classes.list} {...props} />
    <TextField source="category" sortable />
    <FunctionField render={infraction => {
      return <>
        {infraction.isStick && <Stick />}
        {infraction.isArchived && <Archived />}
      </>
    }} />
  </InfractionDatagrid>
}
