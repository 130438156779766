import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import OfficeSettingsForm from './OfficeSettingsForm';

const EditOfficeSettingsDialog = props => {
  const office = useRecordContext();
  return <EditResourceDialog label="resources.officeSettings.actions.edit" maxWidth="md" disableDelete resource="officeSettings" basePath="/officeSettings" {...props}>
    <OfficeSettingsForm office={office} />
  </EditResourceDialog>
}

export const UpdateOfficeSettingsButton = ({ officeId, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  return <>
    <Button color="primary" size="small" startIcon={<Edit />} onClick={() => setOpen(true)}>
      {translate('resources.officeSettings.actions.edit')}
    </Button>
    <EditOfficeSettingsDialog id={officeId} isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}
