import { useCallback, useEffect, useRef } from 'react';
import { indexById, removeEmpty, useSafeSetState, usePaginationState, useSortState, useSelectionState, useResourceContext } from 'react-admin';
import applyFilter from 'loopback-filters';
import { isEqual } from 'lodash'

import { getWhere, getOrder } from '../../http/restClient/filters';

/**
 * Handle filtering, sorting and pagination on local data.
 *
 * Returns the data and callbacks expected by <ListContext>.
 *
 * @example
 * const data = [
 *     { id: 1, name: 'Arnold' },
 *     { id: 2, name: 'Sylvester' },
 *     { id: 3, name: 'Jean-Claude' },
 * ]
 * const ids = [1, 2, 3];
 *
 * const MyComponent = () => {
 *     const listContext = useList({
 *         data,
 *         ids,
 *         basePath: '/resource';
 *         resource: 'resource';
 *     });
 *     return (
 *         <ListContextProvider value={listContext}>
 *             <Datagrid>
 *                 <TextField source="id" />
 *                 <TextField source="name" />
 *             </Datagrid>
 *         </ListContextProvider>
 *     );
 * };
 *
 * @param {UseListOptions} props
 * @param {Record[]} props.data An array of records
 * @param {Identifier[]} props.ids An array of the record identifiers
 * @param {Boolean} props.loaded: A boolean indicating whether the data has been loaded at least once
 * @param {Boolean} props.loading: A boolean indicating whether the data is being loaded
 * @param {Error | String} props.error: Optional. The error if any occurred while loading the data
 * @param {Object} props.filter: Optional. An object containing the filters applied on the data
 * @param {Number} props.page: Optional. The initial page index
 * @param {Number} props.perPage: Optional. The initial page size
 * @param {SortPayload} props.sort: Optional. The initial sort (field and order)
 */
export const useList = (props) => {
  const resource = useResourceContext(props);
  const {
    data,
    error,
    filter = defaultFilter,
    ids,
    loaded,
    loading,
    page: initialPage = 1,
    perPage: initialPerPage = 1000,
    sort: initialSort = defaultSort,
    initialSelection: initialSelection = defaultInitialSelection,
  } = props;

  const [finalItems, setFinalItems] = useSafeSetState(() => ({
    data: Array.isArray(data) ? indexById(data) : {},
    ids: Array.isArray(ids) ? ids : [],
    total: Array.isArray(ids) ? ids.length : 0,
  }));

  // pagination logic
  const { page, setPage, perPage, setPerPage } = usePaginationState({
    page: initialPage,
    perPage: initialPerPage,
  });

  // sort logic
  const { sort, setSort: setSortObject } = useSortState(initialSort);
  const setSort = useCallback((field, order = 'ASC') => {
    setSortObject({ field, order });
    setPage(1);
  }, [setPage, setSortObject]);

  // selection logic
  const {
    selectedIds,
    onSelect,
    onToggleItem,
    onUnselectItems,
  } = useSelectionState(initialSelection);

  // filter logic
  const filterRef = useRef(filter);
  const [displayedFilters, setDisplayedFilters] = useSafeSetState({});
  const [filterValues, setFilterValues] = useSafeSetState(filter);

  const hideFilter = useCallback((filterName) => {
    setDisplayedFilters(previousState => {
      const { [filterName]: _, ...newState } = previousState;
      return newState;
    });
    setFilterValues(previousState => {
      const { [filterName]: _, ...newState } = previousState;
      return newState;
    });
  }, [setDisplayedFilters, setFilterValues]);

  const showFilter = useCallback((filterName, defaultValue) => {
    setDisplayedFilters(previousState => ({
      ...previousState,
      [filterName]: true,
    }));
    setFilterValues(previousState =>
      removeEmpty({
        ...previousState,
        [filterName]: defaultValue,
      })
    );
  }, [setDisplayedFilters, setFilterValues]);

  const setFilters = useCallback((filters, displayedFilters) => {
    setFilterValues(removeEmpty(filters));
    if (displayedFilters) {
      setDisplayedFilters(displayedFilters);
    }
    setPage(1);
  }, [setDisplayedFilters, setFilterValues, setPage]);

  // handle filter prop change
  useEffect(() => {
    if (isEqual(filter, filterRef.current)) return;
    filterRef.current = filter;
    setFilterValues(filter);
    setPage(1);
  });

  // We do all the data processing (filtering, sorting, paginating) client-side
  useEffect(() => {
    if (!loaded) return;

    const lbFilter = {
      where: getWhere(resource, { filter: filterValues, clientFiltering: true }),
      order: getOrder({ field: sort.field, order: sort.order }),
    }

    let tempData = applyFilter(data, lbFilter);
    const filteredLength = tempData.length;

    // paginate
    tempData = tempData.slice((page - 1) * perPage, page * perPage);
    const finalData = indexById(tempData);
    const finalIds = tempData
      .filter(data => typeof data !== 'undefined')
      .map(data => data.id);

    setFinalItems({
      data: finalData,
      ids: finalIds,
      total: filteredLength,
    });
  }, [
    data,
    resource,
    filterValues,
    loaded,
    page,
    perPage,
    setFinalItems,
    sort.field,
    sort.order,
  ]);

  return {
    currentSort: sort,
    data: finalItems.data,
    error,
    displayedFilters,
    filterValues,
    hideFilter,
    ids: finalItems.ids,
    loaded,
    loading,
    onSelect,
    onToggleItem,
    onUnselectItems,
    page,
    perPage,
    selectedIds,
    setFilters,
    setPage,
    setPerPage,
    setSort,
    showFilter,
    total: finalItems.total,
  };
};

const defaultFilter = {};
const defaultSort = { field: null, order: null };
const defaultInitialSelection = []
