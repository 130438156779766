import React from 'react';
import { ReferenceArrayField, useListContext, useLocale, useTranslate } from 'react-admin';
import { groupBy, orderBy } from 'lodash';

import { dedupeBy } from '@hisports/parsers';
import { translateApiProperty } from '@hisports/common';

import { formatList } from '../../locale';

const format = (locale, categories, source, translate) => {
  const values = categories.map(category => {
    let value = translateApiProperty(category, source, locale);
    if (category?.gender) {
      value += ` ${translate(`resources.categories.values.gender.${category.gender}`)}`
    }
    return value
  })

  return formatList(values, locale);
}

const CategoriesList = ({ source, emptyText }) => {
  const translate = useTranslate()
  const locale = useLocale();
  const { ids = [], data } = useListContext();

  const categories = orderBy(dedupeBy('id', ids.map(id => data?.[id])), 'order')
  if (categories.length <= 5) return format(locale, categories, source, translate) || translate(emptyText, { _: emptyText }) || null;

  const divisions = groupBy(categories, 'divisionId')
  return Object.keys(divisions).map(divisionId => {
    const categories = divisions[divisionId]
    const value = format(locale, categories, source, translate) || translate(emptyText, { _: emptyText })
    if (!value) return null;
    return <div>{value}</div>;
  })
}

export const CategoriesField = ({ authMethod = "show", showId = false, showSeason = false, link = 'show', variant = 'body2', emptyText, ...props }) => {
  return <ReferenceArrayField basePath="/categories" resource="categories" reference="categories" allowEmpty {...props} >
    <CategoriesList source="name" emptyText={emptyText} />
  </ReferenceArrayField>
}

CategoriesField.defaultProps = {
  addLabel: true,
}
