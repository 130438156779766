import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import InfractionAccumulationForm from './InfractionAccumulationForm';

const AddInfractionAccumulationDialog = ({ office, ...props }) =>
  <CreateResourceDialog label="resources.infractionAccumulations.actions.add" resource="infractionAccumulations" basePath="/infractionAccumulations" {...props}>
    <InfractionAccumulationForm office={office} />
  </CreateResourceDialog>


const EditInfractionAccumulationDialog = ({ office, ...props }) =>
  <EditResourceDialog label="resources.infractionAccumulations.labels.edit" resource="infractionAccumulations" basePath="/infractionAccumulations" {...props}>
    <InfractionAccumulationForm office={office} />
  </EditResourceDialog>

export const AddInfractionAccumulationButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.infractionAccumulations.actions.add')}
    </Button>
    <AddInfractionAccumulationDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const InfractionAccumulationDatagrid = props => <DialogDatagrid dialog={EditInfractionAccumulationDialog} {...props} />
