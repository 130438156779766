import React from 'react';
import { Filter, TextInput, useTranslate } from 'react-admin';

export const ParticipantFilters = props => {
  const translate = useTranslate();

  return <Filter variant="outlined" {...props}>
    <TextInput source="name" alwaysOn />
    <TextInput source="HCRId" alwaysOn />
    <TextInput source="email" label={translate('resources.participants.fields.accountEmail')} alwaysOn />
  </Filter>
}
