import React from 'react';
import { CardActions, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardAction: {
    zIndex: 2,
    display: 'flex',
    padding: theme.spacing(2, 1),
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }
}))

export const TopToolbar = ({ children, ...props }) => {
  const classes = useStyles();
  return <CardActions className={classes.cardAction} {...props}>
    <Typography
      variant="h6"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />
    {children}
  </CardActions>
}
