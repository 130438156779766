import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';

import { EnumField } from '../../common/fields/EnumField';
import { OfficeField } from '../offices/OfficeField';

import { InfractionDatagrid } from './InfractionModal';
import { RuleField } from './RuleField';
import { InfractionTypeField } from './InfractionTypeField';

const ParentInfractionCode = props =>
  <ReferenceField basePath="/infractions" resource="infractions" reference="infractions" {...props} link={false}>
    <TextField source="code" />
  </ReferenceField>

const ParentInfractionOffice = props =>
  <ReferenceField basePath="/infractions" resource="infractions" reference="infractions" {...props} link={false}>
    <OfficeField source="officeId" />
  </ReferenceField>


export const InfractionGrid = ({ office, ...props }) => {
  const translate = useTranslate()
  const hasParentOffice = !!office?.parentId

  return <InfractionDatagrid size="medium" {...props}>
    <TextField source="code" />
    <RuleField source="ruleId" />
    <InfractionTypeField source="typeId" />
    <EnumField source="severity" />
    {hasParentOffice && <ParentInfractionCode source="parentId" label={translate('resources.infractions.labels.parent_code')} />}
    {hasParentOffice && <ParentInfractionOffice source="parentId" label={translate('resources.infractions.labels.parent_office')} />}
  </InfractionDatagrid>
}
