import React from 'react';
import { useTranslate } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

import ResourceTitle from '../../common/ResourceTitle';
import { Create, Edit, List, Show } from "../../common/ra";
import { useSeason } from '../../common/inputs/SeasonSelector';

import { SuspensionForm } from '../scoresheet/suspension/SuspensionForm';
import { SuspensionGrid } from '../scoresheet/suspension/SuspensionGrid';
import { SuspensionShowLayout } from './SuspensionShow';
import { SuspensionFilterList } from './SuspensionFilterList';
import { SuspensionFilters } from './SuspensionFilters';

export const SuspensionList = props => {
  const seasonId = useSeason();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return <List {...props}
    aside={!isSmall && <SuspensionFilterList />}
    filters={isSmall && <SuspensionFilters />}
    filter={{ seasonId }}
    sort={{
      field: 'game.date',
      order: 'DESC'
    }}
    resetFilters={isSmall}
  >
    <SuspensionGrid multipleGames rowClick="show" canExpand={false} />
  </List>
}

export const SuspensionShow = props => {
  const translate = useTranslate()
  return <Show {...props}
    title={<ResourceTitle title={() => translate('resources.suspensions.name', 1)} />}
  >
    <SuspensionShowLayout />
  </Show>
}

export const SuspensionCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={() => translate('resources.suspensions.labels.add')} />}
    {...props}
  >
    <SuspensionForm redirect="show" />
  </Create>
}

export const SuspensionEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: translate('resources.suspensions.name', 1) })} />}
    {...props}
  >
    <SuspensionForm redirect="show" />
  </Edit>
}
