import React from 'react';
import { SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import Toolbar from '../../common/ra/Toolbar';

import { OfficeInput } from '../offices/OfficeInput';
import { ParticipantInput } from '../participants/ParticipantInput';

const validate = values => {
  const errors = {};

  if (!values.participantId) errors.participantId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'officialOffices',
  basePath: '/officialoffices',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const OfficialForm = props => {
  return <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <ParticipantInput source="participantId" filter={{ _scope: 'Tenant', isOfficial: true }} disabled={props.initialValues?.id || props.initialValues?.participantId} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="officeId" disabled filter={{ type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] } }} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
