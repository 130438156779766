import React from 'react';

import ListCard from '../../../common/cards/ListCard';
import { InlineDateInput } from '../../../common/inputs/DateInput';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { EventView } from '../../events/EventView';
import { CALENDAR_VIEWS, EventViewSettingsMenu, useEventSort, useShowCalendar } from '../../events/EventViewSettings';
import { EventReferenceManyField } from '../../events/EventReferenceField';
import { rowClick } from '../../events/EventGrid';

export default props => {
  const [ showCalendar ] = useShowCalendar();
  const sort = useEventSort();
  const seasonId = useSeason();

  const actions = [
    <EventViewSettingsMenu alwaysOn showTeamEvents showSlots showAvailabilties disableGroupArenas />,
  ].filter(Boolean);

  const filters = [
    <InlineDateInput source="startTime" label="resources.games.filters.startTime" variant="outlined" alwaysOn />,
    <InlineDateInput source="endTime" label="resources.games.filters.endTime" variant="outlined" alwaysOn />,
  ]

  return <ListCard
    title="resources.schedules.name"
    resource="games"
    target="arenaId"
    base={EventReferenceManyField}
    baseProps={{
      includeGames: true,
      includeDraftGames: showCalendar,
      includePractices: true,
      includeActivities: false,
      includeSlots: showCalendar,
      includeAvailabilities: showCalendar,
    }}
    actions={actions}
    filters={filters}
    filter={{ effectiveSurfaces: true, seasonId }}
    sort={sort}
    noResultsText={!showCalendar}
    rowsPerPageOptions={showCalendar ? [] : [25, 50, 100]}
    perPage={showCalendar ? 99999 : 25}
  >
    <EventView rowClick={rowClick} includeVenue={false} strictView={CALENDAR_VIEWS.WEEK} disableScheduling />
  </ListCard>
}
