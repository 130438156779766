import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

import FunctionField from '../../common/fields/FunctionField'

export const GroupField = ({ showType = false, ...props }) =>
  <ReferenceField basePath="/groups" resource="groups" reference="groups" link={false} allowEmpty {...props} >
    { showType
      ? <FunctionField render={({ name, type }) => `${name} (${type})`} />
      : <TextField source="name" />
    }
  </ReferenceField>

GroupField.defaultProps = {
  addLabel: true,
}
