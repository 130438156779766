import React from 'react';

import { getStatus } from '@hisports/scoresheet/src/util'

import FunctionField from '../../common/fields/FunctionField'

export const MemberStatusField = props =>
  <FunctionField render={member => getStatus(member)} {...props} />

MemberStatusField.defaultProps = {
  addLabel: true
}
