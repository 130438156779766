import React from 'react';
import { EditButton, ShowButton, useEditContext, useResourceContext } from 'react-admin';

import { FF_BRACKETS } from '@hisports/common/featureFlags'

import { useFlag, usePermissions } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { TopToolbar } from '../../common/cards/Card';
import { ActionMenu } from '../../common/ActionMenu';

import { AnalyzeButton } from './AnalyzeDialog';
import { BracketReseedMenuItem } from './dialogs/BracketReseedDialog';
import ScheduleInternalActions from './ScheduleInternalActions';

export const ScheduleActions = ({ hasEdit, hasShow, ...props }) => {
  const resource = useResourceContext(props);
  const { basePath, record: schedule } = useEditContext(props);
  const isEnabled = useFlag()
  const permissions = usePermissions()

  if (!schedule) return null;
  const { id: scheduleId, officeId, type } = schedule;
  const isSystem = permissions.some(p => p.roleType === 'System')

  const canEditResource = isAuthorized(schedule, resource, 'edit');
  const canShowResource = isAuthorized(schedule, resource, 'show');
  const showBracketReseed = isEnabled(FF_BRACKETS) && canEditResource && ['Playoffs', 'Tournament', 'Cup'].includes(type);
  const showInternalActions = isSystem

  const showActionMenu = showBracketReseed || showInternalActions

  return <TopToolbar>
    {hasEdit && canEditResource && <EditButton basePath={basePath} resource={resource} record={schedule} />}
    {hasShow && canShowResource && <ShowButton basePath={basePath} resource={resource} record={schedule} />}
    <AnalyzeButton scheduleId={scheduleId} officeId={officeId} />
    {showActionMenu && <ActionMenu>
      {showBracketReseed && <BracketReseedMenuItem scheduleId={scheduleId} />}
      {showInternalActions && <ScheduleInternalActions />}
    </ActionMenu>}
  </TopToolbar>
}
