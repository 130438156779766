import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';

import InfoCard from '../../../common/cards/InfoCard';
import { isAuthorized } from '../../../common/Authorize';

import { useOfficeAssignSettings } from '../../officeAssignSettings';
import { OfficeAssignSettingsField } from '../../officeAssignSettings/OfficeAssignSettingsField';
import { UpdateOfficeAssignSettingsButton } from '../../officeAssignSettings/OfficeAssignSettingsModal';

const OfficeAssignSettingsCard = ({ officeId, showEdit }) => {
  const translate = useTranslate();
  const settings = useOfficeAssignSettings(officeId, true);

  if (!settings.data) return null;
  if (!settings.data.enabled) return <Alert severity="warning">{translate('resources.officeAssignSettings.alerts.assigning_not_enabled')}</Alert>

  return <InfoCard title="resources.officeAssignSettings.labels.card.title" actions={[
    showEdit && <UpdateOfficeAssignSettingsButton officeId={officeId} />
  ]}>
    <OfficeAssignSettingsField officeId={officeId} {...settings} />
  </InfoCard>
}

export default props => {
  const office = useRecordContext(props);
  const showSettings = isAuthorized(office, 'officeAssignSettings', 'show')
  const canEditSettings = isAuthorized(office, 'officeAssignSettings', 'edit')

  if (!showSettings) return null;
  return <OfficeAssignSettingsCard officeId={office?.id} showEdit={canEditSettings} />
}
