import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { Table, TableBody, TableRow, TableCell, IconButton, makeStyles, styled, useMediaQuery } from "@material-ui/core"
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab'
import moment from 'moment-timezone';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import { apiClient } from '../../../../../../http';
import LoadingAlert from '../../../../../../common/LoadingAlert';
import { TeamField } from '../../../../../teams/TeamField';
import { SurfaceField } from '../../../../../surfaces/SurfaceField';

import { AssignmentPosition } from '../AssignmentStatus';
import { useAssignmentContext } from '../AssignmentContext';
import { useAssignmentsContext } from '../../AssignmentsContext';

const useAssignmentStyles = makeStyles(theme => ({
  cell: {
    border: 0,
  },
  timeCell: {
    width: theme.spacing(14),
    maxWidth: theme.spacing(14),
    border: 0,
  },
  fixedCell: {
    whiteSpace: 'pre',
    border: 0,
  },
  small: {
    width: '100%',
    border: 0,
  }
}))

const Assignment = ({ assignment }) => {
  const classes = useAssignmentStyles();
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const { game } = assignment;
  if (!game) return <TableCell colSpan={4} className={classes.cell} />

  const start = moment.tz(game.startTime, game.timezone);
  const end = moment.tz(game.endTime, game.timezone);
  const timeRange = `${start.format('HH:mm')} - ${end.format('HH:mm')}`

  let details;
  if (small) {
    details = <TableCell colSpan={4} className={classes.small}>
      <p>{game.number}</p>
      <p>H: <TeamField source="game.homeTeamId" /></p>
      <p>A: <TeamField source="game.awayTeamId" /></p>
      <p>V: <SurfaceField source="game.arenaId" /></p>
      <p>
        <AssignmentPosition assignment={assignment} />
      </p>
    </TableCell>
  } else {
    details = <>
      <TableCell className={classes.fixedCell}>
        {game.number}
      </TableCell>
      <TableCell className={classes.cell}>
        <TeamField source="game.homeTeamId" />
        <br />
        <TeamField source="game.awayTeamId" />
      </TableCell>
      <TableCell className={classes.cell}>
        <SurfaceField source="game.arenaId" />
      </TableCell>
      <TableCell className={classes.fixedCell} align="right">
        <AssignmentPosition assignment={assignment} />
      </TableCell>
    </>
  }

  return <>
    <TableCell className={classes.timeCell}>
      {timeRange}
    </TableCell>
    {details}
  </>
}

const getAvailabilityMessage = (slot, duration, translate, timezone) => {
  let availability = (slot.isAvailable === true && translate('ra.message.available'))
                  || (slot.isAvailable === false && translate('ra.message.unavailable'))
                  || translate('ra.message.unknown')

  if (duration === 1439) { // 23h59m
    availability += ` ${translate('ra.date.lexical.all_day')}`
    return availability;
  }

  const startOfDay = moment.tz(slot.startTime, timezone).hour() === 0
  if (startOfDay) {
    const endTime = moment.tz(slot.endTime, timezone).format('HH:mm');
    availability += ` ${translate('ra.date.lexical.until_time', { time: endTime })}`
    return availability;
  }

  const endOfDay = moment.tz(slot.endTime, timezone).minutes() === 59
  if (endOfDay) { // end of day slot
    const startTime = moment.tz(slot.startTime, timezone).format('HH:mm');
    availability += ` ${translate('ra.date.lexical.after_time', { time: startTime })}`
    return availability;
  }

  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  availability += ` ${translate('ra.function.for')}`
  if (hours > 0) {
    availability += ` ${translate('ra.date.phrase.hour', hours)}`
  }
  if (hours >= 1 && minutes > 0) {
    availability += ` ${translate('ra.function.and')}`
  }
  if (minutes > 0) {
    availability += ` ${translate('ra.date.phrase.minute', minutes)}`
  }

  return availability;
}

const Note = styled('p')(({ theme }) => ({
  margin: theme.spacing(1),
  fontStyle: 'italic',
  whiteSpace: 'pre-line',
}))

const Notes = ({ slot }) => {
  if (!slot.notes) return null;
  return <Note>{slot.notes}</Note>
}

const EmptySlot = ({ slot, duration, game }) => {
  const translate = useTranslate();
  const classes = useAssignmentStyles();

  const availability = getAvailabilityMessage(slot, duration, translate, game.timezone);
  return <TableCell className={classes.cell} colSpan={4}>
    {availability}
    <Notes slot={slot} />
  </TableCell>
}

const getSlotColor = ({ slot }) => {
  if (!slot) return 'transparent'
  if (slot.isAvailable == null) return grey[500];
  return slot.isAvailable ? green[500] : red[500];
}

const stripes = (r, g, b) => `repeating-linear-gradient(
  -55deg,
  rgba(${r}, ${g}, ${b}, .025),
  rgba(${r}, ${g}, ${b}, .025) 8px,
  rgba(${r}, ${g}, ${b}, .05) 8px,
  rgba(${r}, ${g}, ${b}, .05) 16px
)`

const getSlotBackground = ({ slot, duration }) => {
  if (slot.assignments.length) return 'inherit';
  if (slot.isAvailable == null) return stripes(158, 158, 158); // grey, unknown
  if (!slot.isAvailable) return stripes(244, 67, 54); // red, unavailable
  if (duration < 60) return stripes(255, 152, 0); // orange, warning <1h
  return stripes(76, 175, 80) // green, available
}

const useSlotStyles = makeStyles(theme => ({
  slot: {
    borderLeftWidth: theme.spacing(1),
    borderLeftStyle: 'solid',
    borderLeftColor: props => getSlotColor(props),
    height: props => theme.spacing(props.size),
    background: props => getSlotBackground(props),
    margin: theme.spacing(0, 1),
  },
  timeCell: {
    width: theme.spacing(12),
    minWidth: theme.spacing(7),
    border: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(12),
    }
  }
}))

const AvailabilitySlot = ({ game, slot }) => {
  const start = moment.tz(slot.startTime, game.timezone);
  const end = moment.tz(slot.endTime, game.timezone);

  const duration = end.diff(start, 'minutes');
  const size = Math.max(4, Math.min(duration / 15, 16));

  const classes = useSlotStyles({ duration, size, slot });
  const timeRange = `${start.format('HH:mm')} - ${end.format('HH:mm')}`

  if (!slot.assignments.length) {
    return <TableRow className={classes.slot}>
      <TableCell className={classes.timeCell}>
        {timeRange}
      </TableCell>
      <EmptySlot slot={slot} duration={duration} game={game} />
    </TableRow>
  }

  return slot.assignments.map((assignment, index) =>
    <RecordContextProvider value={assignment} key={assignment.gameId}>
      <TableRow className={classes.slot}>
        <Assignment assignment={assignment} />
      </TableRow>
    </RecordContextProvider>
  )
}

const AlertCell = styled(TableCell)({ border: 0 })
const Availabilities = ({ game, official, date }) => {
  const translate = useTranslate();
  const [ availabilities, setAvailabilities ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiClient(`/participants/${official.participantId}/assignAvailability`, { params: { date, timezone: game.timezone } })
      .then(res => res.data)
      .then(data => setAvailabilities(data))
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [ date, game.startTime, game.timezone, official.participantId ])

  if (loading) {
    return <TableRow>
      <AlertCell colSpan={5}>
        <LoadingAlert>{translate('resources.games.alerts.loading.availability')}</LoadingAlert>
      </AlertCell>
    </TableRow>
  }

  if (error) {
    return <TableRow>
      <AlertCell colSpan={5}>
        <Alert severity="error">{translate('resources.games.alerts.assignment.load_official_schedule_error')}</Alert>
      </AlertCell>
    </TableRow>
  }

  if (!loading && !availabilities.length) {
    return <TableRow>
      <AlertCell colSpan={5}>
        <Alert severity="info">{translate('resources.games.alerts.assignment.no_game_and_availability')}</Alert>
      </AlertCell>
    </TableRow>
  }

  return availabilities.map(slot => <AvailabilitySlot key={slot.startTime} game={game} slot={slot} />)
}

const useDateStyles = makeStyles(theme => ({
  row: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    flex: 1,
  },
  diff: {
    color: theme.palette.secondary.light
  },
  footnote: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.common.white,
  }
}))

const DateRow = ({ game, official, date, onNext, onPrev, onInit }) => {
  const classes = useDateStyles();
  const translate = useTranslate();

  const ref = useRef(null);
  useEffect(() => {
    onInit(date, ref.current)
  }, [ ref, date, onInit ])

  const header = moment.tz(date, 'YYYY-MM-DD', game.timezone).format('dddd, LL')
  const diff = moment.tz(game.startTime, game.timezone).startOf('day').diff(date, 'days') * -1

  let diffText;

  if (diff !== 0) {
    diffText = ` (${diff > 0 ? `+${diff}` : diff} ${translate('ra.date.day', diff).toLowerCase()})`
  }

  return <>
    <span ref={ref} id={date}></span>
    <TableRow className={classes.row}>
      <TableCell colSpan={5}>
        <div className={classes.content}>
          <div className={classes.date}>{header}<span className={classes.diff}>{diffText}</span></div>
          <IconButton size="small" onClick={() => onPrev(date, game)}>
            <ExpandLess />
          </IconButton>
          <IconButton size="small" onClick={() => onNext(date, game)}>
            <ExpandMore />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
    <Availabilities game={game} official={official} date={date} />
  </>
}

const FooterRow = ({ game }) => {
  const classes = useDateStyles();
  const translate = useTranslate();

  const timezoneAbbr = moment.tz(game.startTime, game.timezone).zoneAbbr();
  return <TableRow className={classes.footnote}>
    <TableCell colSpan={5}>
      <div dangerouslySetInnerHTML={{ __html: translate('resources.games.messages.assignment.time_display', { timezone: timezoneAbbr }) }} />
    </TableCell>
  </TableRow>
}

const Spacer = styled('div')({ height: 160 })
export default ({ panelRef }) => {
  const { game } = useAssignmentsContext();
  const { official } = useAssignmentContext();

  const refs = useRef({})
  const initialDate = moment.tz(game.startTime, game.timezone).format('YYYY-MM-DD')
  const [ dates, setDates ] = useState([ initialDate ])
  const [ selection, setSelection ] = useState(null);

  useLayoutEffect(() => {
    if (selection == null) return;

    const ref = refs.current[selection];
    if (panelRef && ref) {
      panelRef.current.scroll({ top: ref.offsetTop, behaviour: 'smooth' })
    }

    setSelection(null)
  }, [ selection, panelRef ])

  if (!official) return null;

  const handleInit = (date, ref) => {
    refs.current[date] = ref;
    setSelection(date);
  }

  const handleNext = (date, game) => {
    const next = moment.tz(date, 'YYYY-MM-DD', game.timezone).add(1, 'day').format('YYYY-MM-DD')
    if (!dates.includes(next)) {
      setDates([...dates, next])
    } else {
      setSelection(next)
    }
  }

  const handlePrev = (date, game) => {
    const prev = moment.tz(date, 'YYYY-MM-DD', game.timezone).subtract(1, 'day').format('YYYY-MM-DD')
    if (!dates.includes(prev)) {
      setDates([prev, ...dates])
    } else {
      setSelection(prev)
    }
  }

  return <>
    <Table>
      <TableBody>{
        dates.map(date =>
          <DateRow key={date} game={game} official={official} date={date} onNext={handleNext} onPrev={handlePrev} onInit={handleInit} />
        )}
      <FooterRow game={game} />
      </TableBody>
    </Table>
    <Spacer />
  </>
}

