import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

export const SectionField = props =>
  <ReferenceField basePath="/sections" resource="sections" reference="sections" link={false} allowEmpty {...props} >
    <TextField source="name" />
  </ReferenceField>

SectionField.defaultProps = {
  addLabel: true,
}
