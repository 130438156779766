import React, { cloneElement } from 'react';
import { RecordContextProvider, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  grayText: {
    color: theme.palette.text.secondary,
  },
  decline: {
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  }
}))

const handleHasChanged = (previousEvent, currentEvent, property) => {
  return previousEvent[property] !== currentEvent[property]
}

/**
 * if the previousEvent is different from the record from useRecordContext(),
 * dupplicate the children using a RecordContextProvider set as previousEvent.
 * we also adds that -> between both childrens
 *
 * @param {ReactNode} props.children - The children elements, mostlikely a Field component (DateField, TimeField or SurfaceField)
 * @param {Object} props.previousEvent - The previous record.
 * @param {string} props.property - The property of the record to compare.
 * @param {function} props.hasChanged - Optionnal function that returns true/false to manually handle the UI.
 * @return {ReactNode} renders the children or the dupplicated children using a previous value on RecordContextProvider.
 */
const DifferenceField = ({ children, previousEvent, property, indicateChangeRemoval = false, hasChanged = handleHasChanged }) => {
  const event = useRecordContext();
  const classes = useStyles();

  const previousValue = previousEvent[property];
  const currentValue = event[property];
  const hasEventChanged = hasChanged(previousEvent, event, property);
  const hasChangeRemoval = indicateChangeRemoval && hasEventChanged;

  return <>
    {previousValue && hasEventChanged &&
      <>
        <RecordContextProvider value={previousEvent}>
          {cloneElement(children, { className: currentValue ? classes.grayText : undefined, link: false })}
        </RecordContextProvider>
        {currentValue && <span> → </span>}
      </>
    }
    {cloneElement(children, { className: hasChangeRemoval ? classes.decline : undefined, link: false })}
  </>
}

export default DifferenceField;
