import React from 'react';
import { useTranslate } from 'react-admin';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { QuestionAnswerOutlined } from '@material-ui/icons';

import { getAdminNotes, getNotes } from '@hisports/scoresheet/src/selectors';

import { useScoresheet } from '../../ScoresheetContext';

const useStyles = makeStyles(theme => ({
  alert: {
    margin: theme.spacing(1),
    whiteSpace: 'pre-line'
  },
}))

export const NotesAlert = ({ type = 'scoresheet', ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const selector = type === 'scoresheet' ? getNotes : getAdminNotes;
  const notes = useScoresheet(scoresheet => selector(scoresheet));

  if (!notes) return null;

  const noteTitle = translate(`resources.scoresheets.labels.${type === 'scoresheet' ? 'scoresheet_notes' : 'admin_notes'}`)

  return <Alert icon={<QuestionAnswerOutlined />} severity={type === 'scoresheet' ? 'info' : 'warning'} className={classes.alert}>
    <AlertTitle>{noteTitle}</AlertTitle>
    <p>{notes}</p>
  </Alert>
}
