import React, { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Replay } from '@material-ui/icons';

import { useRichTranslate } from '../../../../../../common/useRichTranslate';
import { useAssignmentsContext } from '../../AssignmentsContext';
import { ParticipantField } from '../../../../../participants/ParticipantField';

import { assign } from './AssignButton';

const UndoDialog = ({ official, open, onClose, handleUndo }) => {
  const translate = useRichTranslate()
  const officialName = <ParticipantField record={official} source="participantId" variant="inherit" link={false} />

  return <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>{translate('resources.games.labels.assignment.undo_no_show')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.games.messages.assignment.undo_no_show', { name: officialName })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleUndo} color="primary">{translate('ra.action.confirm')}</Button>
    </DialogActions>
  </Dialog>
}

export default ({ game, assignment, official }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { refresh, refetch } = useAssignmentsContext();
  const [ open, setOpen ] = useState();

  const onClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClick = async () => {
    const { id, participantId, position } = assignment;
    assign(id, game.id, participantId, position, participantId)
      .then(() => {
        notify(translate('resources.games.notifications.assignment.official_assigned_to_game', { number: game.number }), 'success')
        refresh(assignment);
      })
      .catch(err => {
        const message = err?.response?.data?.error?.message
        notify(message || 'ra.page.error_try_again', 'error')
        refetch()
      })
  }

  return <>
    <Button startIcon={<Replay fontSize="small" />} size="small" color="inherit" onClick={onClick}>
      {translate('ra.action.undo')}
    </Button>
    <UndoDialog open={open} onClose={handleClose} handleUndo={handleClick} assignment={assignment} official={official} />
  </>
}
