import React from 'react';
import { useRecordContext } from 'react-admin';
import { useSelector } from 'react-redux';
import { FormatListNumberedRtl } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { RulebookGrid } from '../../rulebooks/RulebookGrid';
import { AddRulebookButton } from '../../rulebooks/RulebookModal';
import { useSport } from '../../../http';

export default props => {
  const office = useRecordContext(props);
  const sport = useSport();
  const seasonId = useSeason();

  const rulebooks = useSelector(store => store.admin.resources.rulebooks.data)
  const hasRulebook = Object.keys(rulebooks)
    .map(id => rulebooks[id])
    .some(rulebook => rulebook.officeId === office?.id)

  const showRulebooks = isAuthorized(office, 'rulebooks', 'list')
  const canAddRulebooks = isAuthorized(office, 'rulebooks', 'create') && !hasRulebook

  const defaultRulebookName = `${office?.name} LIMITS (${seasonId})`

  if (!showRulebooks) return null;
  return <ListCard
    title={sport === 'Baseball' ? "resources.rulebooks.labels.card.baseball_title" : "resources.rulebooks.labels.card.title"}
    subtitle={sport === 'Baseball' ? "resources.rulebooks.labels.card.baseball_subtitle" : "resources.rulebooks.labels.card.subtitle"}
    icon={<FormatListNumberedRtl />}
    collapsable
    reference="rulebooks"
    target="offices"
    filter={{ seasonId }}
    sort={{ field: 'name', order: 'ASC' }}
    rowsPerPageOptions={[]}
    addButton={canAddRulebooks && <AddRulebookButton initialValues={{ officeId: office?.id, name: defaultRulebookName }} />}
  >
    <RulebookGrid />
  </ListCard>
}
