import React from 'react';
import { ReferenceInput, ReferenceArrayInput, useLocale, RecordContextProvider } from 'react-admin';

import { translateApiProperty } from '@hisports/common';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { OfficeField } from '../offices/OfficeField';

const filterSanction = name => ({ name })

export const SanctionSuggestion = ({ suggestion: sanction, query, isHighlighted, includeOffice = false, ...props }) => {
  const locale = useLocale();
  const primary = `${sanction?.code ? `${sanction.code} - ` : ''}${translateApiProperty(sanction, 'name', locale)}`;
  const secondary = includeOffice ? <OfficeField source="officeId" variant="inherit" /> : null

  return <RecordContextProvider value={sanction}>
    <AutocompleteSuggestion primary={primary} secondary={secondary} />
  </RecordContextProvider>
}

export const SanctionInput = ({ helperText, multiple, includeOffice = false, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;

  const getOptionText = sanction => {
    return [ sanction.code, sanction.name ].filter(Boolean).join(' - ')
  }

  return <Input
    reference="sanctions"
    filterToQuery={filterSanction}
    sort={{ field: ['name'], order: ['ASC'] }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText={getOptionText}
      suggestionComponent={SanctionSuggestion}
      options={{ suggestionComponentProps: { includeOffice } }}
      filterKeys={['name', 'code']}
      disableSort
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
