import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { ListForm } from './ListForm';

const AddListDialog = props =>
  <CreateResourceDialog label="resources.lists.actions.add" resource="lists" basePath="/lists" {...props}>
    <ListForm />
  </CreateResourceDialog>

const EditListDialog = props =>
  <EditResourceDialog label="resources.lists.actions.edit" resource="lists" basePath="/lists" {...props}>
    <ListForm />
  </EditResourceDialog>

export const AddListButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.lists.actions.add')}</Button>
    <AddListDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const ListDatagrid = props => <DialogDatagrid dialog={EditListDialog} {...props} />
