import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { TabbedShowLayout as RaTabbedShowLayout, TabbedShowLayoutTabs } from 'react-admin';

export const TabbedShowLayout = props => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const tabs = isMobile ? <TabbedShowLayoutTabs variant="scrollable" scrollButtons="on" /> : <TabbedShowLayoutTabs />
  return <RaTabbedShowLayout
    tabs={tabs}
    {...props}
  />
}

export { Tab } from 'react-admin';
