import React from 'react';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

export const AttributeTypeField = ({ variant = 'body2', ...props }) => {
  const attributeType = useRecordContext(props);

  return <ReferenceField basePath="/attributeTypes" resource="attributeTypes" reference="attributeTypes" link={false} {...props}>
    <TextField source="name" variant={variant} />
  </ReferenceField>
}

AttributeTypeField.defaultProps = {
  addLabel: true,
}
