import React, { isValidElement, Fragment } from 'react';
import { useTranslate } from 'react-admin';

// https://github.com/airbnb/polyglot.js/blob/master/index.js#L148
// Could probably be written as /(%\{(?:[a-zA-Z_]+)\})/
const REGEX_VARIABLE = /(%\{(?:.+?)\})/;

const groupOptions = (options) => (
  Object.keys(options).reduce((acc, key) => {
    const value = options[key];
    const type = isValidElement(value) ? 'jsx' : 'regular';
    acc[type][key] = value;
    return acc;
  }, { jsx: {}, regular: {} })
);

const smartT = (simpleT, id, options) => {
  // If there is no key there is nothing to work with. Matching Polyglot behavior.
  if (!id) return;

  // Options could be either a number or missing
  if (typeof options !== 'object') return simpleT(id, options);

  const { jsx, regular } = groupOptions(options);
  const content = simpleT(id, regular);

  if (!Object.keys(jsx).length) return content;

  const renderFragment = (shard, index) => {
    // `shard` is either a variable WITH syntax '%{variableName}' or a part of the string
    // We could use a regex to extract `variableName` but this is 100x faster
    // Syntax is preserved to avoid false positive replacements when part of the string
    // is matching a substitution variable
    const node = options[shard.slice(2, -1)];
    return node ? <Fragment key={`${shard}:${index}`}>{node}</Fragment> : shard;
  };

  // returns an array of mixed strings/Fragment nodes
  return content.split(REGEX_VARIABLE).map(renderFragment);
};

export const useRichTranslate = () => {
  const translate = useTranslate();
  return (id, options) => smartT(translate, id, options);
}
