import React from 'react';
import { Grid, Typography } from "@material-ui/core"
import { NumberInput, useResourceContext, useTranslate } from "react-admin"
import { useFormState } from 'react-final-form';

import { EndDateInput } from "../inputs/EndTimeInput"
import { DayEnumInput, FrequencyEnumInput } from "../inputs/EnumInputs"

import { RecurrenceSchedule } from './RecurrenceSchedule';

export const RecurrenceField = (inputProps) => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const { values: { frequency }, initialValues } = useFormState();

  const isRecurring = frequency != null && frequency !== 'Once'
  const frequencyHelper = !isRecurring && (initialValues.frequency && initialValues.frequency !== 'Once') ? `resources.${resource}.helpers.frequency` : ''

  return <>
    <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.arenaslots.labels.recurrence')}</Typography>
    </Grid>

    <Grid item xs={12} lg={3}>
      <FrequencyEnumInput source="frequency" label={translate(`resources.${resource}.labels.frequency`)} helperText={frequencyHelper} {...inputProps} />
    </Grid>

    <Grid item xs={12} lg={3}>
      <NumberInput source="interval" label={translate(`resources.${resource}.labels.interval`)} helperText={isRecurring ? "ra.message.optional" : 'ra.message.not_applicable'} disabled={!isRecurring} {...inputProps} />
    </Grid>

    <Grid item xs={12} lg={3}>
      <DayEnumInput source="weekdays" label={translate(`resources.${resource}.labels.weekdays`)} helperText={isRecurring ? "ra.message.optional" : 'ra.message.not_applicable'} multiple disabled={!isRecurring} {...inputProps} />
    </Grid>

    <Grid item xs={12} lg={3}>
      <EndDateInput source="endDate" label={translate(`resources.${resource}.labels.end_date`)} helperText={isRecurring ? '' : 'ra.message.not_applicable'} disabled={!isRecurring} {...inputProps} />
    </Grid>

    {isRecurring && <Grid item xs={12}>
      <RecurrenceSchedule />
    </Grid>}
  </>
}
