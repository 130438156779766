import React from 'react';
import { TextField } from 'react-admin';
import { Lock } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import moment from 'moment-timezone';

import FunctionField from '../../common/fields/FunctionField'
import DateRangeField from '../../common/fields/DateRangeField';

import { OfficeField } from '../offices/OfficeField';
import { PayPeriodDatagrid } from './PayPeriodModal';

export const CloseStatusChip = ({ isClosed, closedAt, ...props }) => {
  const date = moment.utc(closedAt).format('YYYY-MM-DD');
  const label = moment(date, 'YYYY-MM-DD', true).isValid() ? date : '-'

  if (!isClosed) return null;
  return <Chip icon={<Lock />} size="small" variant="outlined" label={label} />
}

const renderStatus = (payPeriod, source, resource, translate) => {
  if (!payPeriod.isClosed) return translate('resources.payPeriods.labels.active')
  return translate('resources.payPeriods.labels.closed', { date: moment.utc(payPeriod.closedAt, 'YYYY-MM-DD').format('LL') })
}

export const PayPeriodGrid = ({ hideOffice = false, ...props }) =>
  <PayPeriodDatagrid size="medium" {...props}>
    {!hideOffice && <OfficeField source="officeId" />}
    <TextField source="name" />

    <DateRangeField source="startDate" startSource="startDate" endSource="endDate" />
    <FunctionField source="isClosed" label="resources.payPeriods.labels.status" render={renderStatus} />
  </PayPeriodDatagrid>
