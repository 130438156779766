import React from 'react'
import { useTranslate } from 'react-admin'
import { Box } from '@material-ui/core'
import { Flag } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'

import { getCertificationFlags } from '@hisports/scoresheet/src/selectors'
import { GENERAL_FLAGS } from '@hisports/scoresheet/src/constants'

import { useScoresheet } from '../ScoresheetContext'

const SUMMARY_FLAGS = [GENERAL_FLAGS.UNFINISHED, GENERAL_FLAGS.COACH_ENTERED_RESULT]

const SummaryFlags = () => {
  const translate = useTranslate();
  const flags = useScoresheet(scoresheet => getCertificationFlags(scoresheet));

  const summaryFlags = flags.filter(flag => SUMMARY_FLAGS.includes(flag.name) && flag.name !== GENERAL_FLAGS.NOTES ? flag : null);

  return (
    <>
      {summaryFlags.map(flag => (
        <Box m={1} key={flag.name}>
          <Alert icon={<Flag />} severity="warning">
            {translate(`resources.games.labels.flags.${flag.name}`, 2)}
          </Alert>
        </Box>
      ))}
    </>
  )
}

export default SummaryFlags
