import React from 'react';
import { useRecordContext } from 'react-admin';
import { NotificationsOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';

import { NotificationGrid } from '../../notifications/NotificationGrid';
import { AddNotificationButton } from '../../notifications/NotificationModal';

export default props => {
  const schedule = useRecordContext(props);

  return <ListCard
    title="resources.notifications.labels.card.title"
    subtitle="resources.notifications.labels.card.subtitles.schedule"
    icon={<NotificationsOutlined />}
    collapsable
    reference="notifications"
    target="schedules"
    sort={{ field: 'email', order: 'ASC' }}
    addButton={<AddNotificationButton initialValues={{ targetType: 'Schedule', targetId: schedule?.id }} />}
  >
    <NotificationGrid />
  </ListCard>
}
