import { downloadCSV } from 'react-admin';
import moment from 'moment-timezone';
import Papa from 'papaparse'

import { appName } from '../../flags';

const activitiesExporter = data => {
  const activities = data.map(activity => {
    const timezone = activity.timezone;
    const team = activity.team;

    return [
      activity.teamId,
      team?.name,
      team?.HCRId,
      activity.name,
      activity.type,
      activity.location,
      moment.utc(activity.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      moment.tz(activity.startTime, timezone).format("HH:mm"),
      moment.tz(activity.endTime, timezone).format("HH:mm"),
      timezone,
      activity.status,
      activity.comments
    ]
  });

  const csv = Papa.unparse({
    fields: ["Team ID", "Team Name", "Team Registry ID", "Name", "Type", "Location", "Date", "Start Time", "End Time", "Timezone", "Status", "Comments"],
    data: activities
  });
  downloadCSV(csv, `${appName} - Activities - ${moment().format('YYYYMMDDHHmmss')}`);
};

activitiesExporter._include = [
  'team',
]

export default activitiesExporter;
