import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { ListItem, ListItemIcon, ListItemText, List, makeStyles, Typography, Divider as MuiDivider, Grid, Checkbox } from '@material-ui/core';
import { Flag as FlagIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';

import { addCertificationFlag, removeCertificationFlag } from '@hisports/scoresheet/src/actions';
import { getCertificationFlag, getCertificationFlags } from '@hisports/scoresheet/src/selectors';
import { displayTime, getPenaltyDetails, getGeneralFlagNames, getTeamFlagNames, getFlagData, isGeneralFlag, isTeamFlag } from '@hisports/scoresheet/src/util';

import { useLocale } from '../../../locale';
import { useSport } from '../../../http';
import LoadingAlert from '../../../common/LoadingAlert';
import { isAuthorized } from '../../../common/Authorize';
import { useGameMemberValidationContext } from '../lineup/GameMembersValidationContext';

import { useMeta, useScoresheetDispatch, useScoresheetStore } from '../ScoresheetContext';
import { useMemberValidationContext } from '../lineup/MemberValidationContext';

const useStyles = makeStyles(theme => ({
  divider: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  checkbox: {
    padding: 0
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemText: {
    marginRight: theme.spacing(3)
  },
  list: {
    backgroundColor: 'inherit',
    paddingTop: 0,
  }
}))

const getFlagName = flag => flag?.name;

export const Divider = ({ text }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return <ListItem dense>
    <Grid container alignItems="center" spacing={3} className={classes.divider}>
      <Grid item xs>
        <MuiDivider />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          {translate(text, { _: text })}
        </Typography>
      </Grid>
      <Grid item xs>
        <MuiDivider />
      </Grid>
    </Grid>
  </ListItem>
}

const FlagDetails =({ flag, flagData, penalties, members }) => {
  const translate = useTranslate();
  const sport = useSport();
  const classes = useStyles();
  const [ locale ] = useLocale();
  const { meta, game } = useMeta();

  const limitListSize = ['Low Severity Penalties', 'High Severity Penalties', 'Yellow', 'Indirect Red', 'Direct Red', 'Extra', 'Secondary Team'].includes(flag)

  let details = flagData.map(data => {
    let label = [];
    if (data?.participant && !data?.penalty) {
      label.push(data?.participant?.fullName)
    }
    if (data?.penalty) {
      label.push(getPenaltyDetails(data.penalty, meta.infractions, meta.rules, meta.types, penalties, members, {}, sport, game.seasonId, locale, { hideStartTime: true, hideServedBy: true }))
      label.unshift(displayTime(data.penalty.gameTime, sport, true))
    }
    if (data?.suspension) {
      label.push(`${translate('resources.members.labels.suspended')}${data.suspension.game ? ` (${data.suspension.game}/${data.suspension.total || '∞'})` : ''}`)
    }
    if (data?.throwsInningsStatus) {
      const faultyPlayerLabel = []
      if (data?.throwsInningsStatus?.hasInningsRest || data?.throwsInningsStatus?.hasThrowsRest) {
        faultyPlayerLabel.push(translate('components.throws_innings.labels.resting'))
      }
      if (data?.throwsInningsStatus?.consecutiveDays) {
        faultyPlayerLabel.push(translate('components.throws_innings.labels.consecutive_days'))
      }
      if ((data?.throwsInningsStatus?.dailyInningsRemaining !== null && data?.throwsInnings?.inningsPitched > data?.throwsInningsStatus?.dailyInningsRemaining) ||
        (data?.throwsInningsStatus?.weeklyInningsRemaining !== null && data?.throwsInnings?.inningsPitched > data?.throwsInningsStatus?.weeklyInningsRemaining)) {
        faultyPlayerLabel.push(`${translate('components.throws_innings.labels.over_innings_limit')} ${data.throwsInnings.inningsPitched - data.throwsInningsStatus.dailyInningsRemaining}`)
      }
      if (!isEmpty(faultyPlayerLabel)) {
        label.push(faultyPlayerLabel.join(' & '))
      }
    }
    label = label.join(' - ')
    return <ListItem className={classes.listItem} dense>
      <ListItemText className={classes.listItemText} dense inset primary={<Typography variant="body2" color="textSecondary">{label}</Typography>} />
    </ListItem>
  })

  if (details.length > 3 && limitListSize) {
    const hiddenCount = details.length - 3
    details = details.slice(0, 3)
    details.push(<ListItem className={classes.listItem} dense>
      <ListItemText className={classes.listItemText} dense inset primary={<Typography variant="body2" color="textSecondary">{translate('resources.games.messages.and_more', hiddenCount)}</Typography>} />
    </ListItem>)
  }

  return details
}

const FlagItemList = ({ flag, teamId }) => {
  const classes = useStyles();
  const { meta } = useMeta();
  const { memberValidations } = useMemberValidationContext();
  const store = useScoresheetStore();
  const scoresheet = store.getState();

  if (!flag || !teamId) return null;

  const members = scoresheet?.lineups[teamId]?.members || [];
  const penalties = scoresheet?.penalties || [];

  const flagData = getFlagData({ name: flag, teamId }, scoresheet, meta, memberValidations);
  if (!flagData?.length) return null;

  return <List className={classes.list} disablePadding dense>
    <FlagDetails flag={flag} flagData={flagData} members={members} penalties={penalties} />
  </List>
}

const Flag = ({ name, teamId, checkable = true, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { game } = useMeta();
  const dispatch = useScoresheetDispatch();
  const store = useScoresheetStore();
  const scoresheet = store.getState();
  const [ checked, setChecked ] = useState(false);

  const canManageScoresheet = isAuthorized(game, 'scoresheets', 'full')
  const scoresheetFlag = getCertificationFlag(scoresheet, name, teamId)

  useEffect(() => {
    setChecked(!!scoresheetFlag);
  }, [ scoresheetFlag ])

  const handleSelect = () => {
    if (checked && scoresheetFlag) {
      const event = removeCertificationFlag(game.id, name, teamId)
      dispatch(event);
    } else if (!checked && !scoresheetFlag) {
      const event = addCertificationFlag(game.id, name, teamId);
      dispatch(event);
    }

    setChecked(!checked)
  }

  const disabled = !["Unfinished", 'Not Rostered'].includes(name) && !canManageScoresheet

  return <>
    <ListItem>
      <ListItemIcon><FlagIcon /></ListItemIcon>
      <ListItemText primary={translate(`resources.games.labels.flags.${name}`, 2)} />
      {checkable && <Checkbox color="primary" className={classes.checkbox} onChange={handleSelect} checked={checked} disabled={disabled} />}
    </ListItem>
    <FlagItemList flag={name} teamId={teamId} />
  </>
}

export const CondensedFlagList = ({ severity = 'warning' }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const sport = useSport();
  const { game, meta } = useMeta();
  const store = useScoresheetStore();
  const scoresheet = store.getState();
  const { memberValidations, loading } = useGameMemberValidationContext();

  const homeTeamMembers = scoresheet?.lineups[game.homeTeamId]?.members || [];
  const awayTeamMembers = scoresheet?.lineups[game.awayTeamId]?.members || [];

  const members = [...homeTeamMembers, ...awayTeamMembers]
  const penalties = scoresheet?.penalties || [];

  const flags = getCertificationFlags(scoresheet);
  const generalFlags = flags.filter(flag => isGeneralFlag(flag, sport));
  const teamFlags = flags.filter(flag => isTeamFlag(flag, sport));

  const flagDetails = teamFlags.reduce((flags, flag) => {
    const { name, teamId } = flag;
    const flagData = getFlagData(flag, scoresheet, meta, memberValidations[teamId])

    if (!flags[name]) {
      flags[name] = flagData;
    } else {
      flags[name] = [...flags[name], ...flagData];
    }

    return flags
  }, {})

  if (loading) return <LoadingAlert severity={severity}>{translate('resources.scoresheets.messages.loading_flags')}</LoadingAlert>
  return <List className={classes.list} disablePadding dense>
    {generalFlags.map(flag =>
      <ListItem>
        <ListItemIcon><FlagIcon /></ListItemIcon>
        <ListItemText primary={translate(`resources.games.labels.flags.${flag.name}`, 2)} />
      </ListItem>
    )}
    {Object.entries(flagDetails).map(([flagName, flagDetails]) => {
      return <>
        <ListItem>
          <ListItemIcon><FlagIcon /></ListItemIcon>
          <ListItemText primary={translate(`resources.games.labels.flags.${flagName}`, 2)} />
        </ListItem>
        <FlagDetails flag={flagName} flagData={flagDetails} members={members} penalties={penalties} />
      </>
    })}
  </List>
}

export const FlagList = ({ isAlert = false, showAll, checkable = true, ...props }) => {
  const store = useScoresheetStore();
  const scoresheet = store.getState();
  const { game } = useMeta();
  const sport = useSport();
  const classes = useStyles();

  const scoresheetFlags = getCertificationFlags(scoresheet);
  const generalFlags = showAll ? getGeneralFlagNames(sport) : scoresheetFlags.filter(flag => isGeneralFlag(flag, sport)).map(getFlagName);

  const homeFlags = showAll ? getTeamFlagNames(sport) : scoresheetFlags.filter(flag => isTeamFlag(flag, sport) && flag.teamId === game.homeTeamId).map(getFlagName);
  const awayFlags = showAll ? getTeamFlagNames(sport) : scoresheetFlags.filter(flag => isTeamFlag(flag, sport) && flag.teamId === game.awayTeamId).map(getFlagName);

  return <List dense className={classes.list}>
    {!!generalFlags.length && <>
      <Divider text="resources.games.labels.general" />
      {generalFlags.map(flagName => <Flag name={flagName} checkable={checkable} />)}
    </>}
    {!!homeFlags.length && <>
      <Divider text="resources.teams.values.types.home" />
      {homeFlags.map(flagName => <Flag name={flagName} teamId={game?.homeTeamId} checkable={checkable} />)}
    </>}
    {!!awayFlags.length && <>
      <Divider text="resources.teams.values.types.away" />
      {awayFlags.map(flagName => <Flag name={flagName} teamId={game?.awayTeamId} checkable={checkable} />)}
    </>}
  </List>
}
