import React from 'react';
import { useRecordContext } from 'react-admin';
import { AssignmentOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { AssignRuleGrid } from '../../assignRules/AssignRuleGrid';
import { AddAssignRuleButton } from '../../assignRules/AssignRuleModal';

export default ({ title = "resources.assignRules.labels.default_rules", types, typesToExclude, ...props }) => {
  const office = useRecordContext(props);
  const seasonId = useSeason();
  const officeId = office?.id;
  const addButton = <AddAssignRuleButton types={types} initialValues={{ officeId, seasonId }} />;

  return <ListCard
    title={title}
    icon={<AssignmentOutlined />}
    collapsable
    addButton={addButton}
    reference="assignRules"
    target="officeId"
    filter={{
      seasonId,
      ...(types ? { type: { inq: types } } : {}),
      effectiveOffices: true,
      includeParents: true,
      _scope: 'Parents'
    }}
    sort={{
      field: ['type', 'value', 'order'],
      order: ['ASC', 'ASC', 'ASC'],
    }}
    {...props}
  >
    <AssignRuleGrid showRule showAttributes showCriteria={false} showConditions={false} />
  </ListCard>
}
