import React from 'react';
import { Pagination, useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { TeamIcon } from '../../icons';
import { AddTeamButton } from '../../teams/TeamModal';
import { TeamGrid } from '../../teams/TeamGrid';
import { CategoryInput } from '../../categories/CategoryInput';

export default ({ collapsable, ...props }) => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const canAddTeam = isAuthorized(office, 'teams', 'create');
  const filters = [
    <CategoryInput source="categoryId" multiple variant="outlined" alwaysOn />,
  ]

  return <ListCard
    title="resources.teams.labels.card.title"
    icon={<TeamIcon />}
    reference="teams"
    target="officeId"
    filter={{ seasonId, effectiveOffices: true }}
    filters={filters}
    sort={{
      field: ['office.name', 'category.order', 'name'],
      order: ['ASC', 'ASC', 'ASC'],
    }}
    pagination={<Pagination />}
    addLabel={false}
    addButton={canAddTeam && <AddTeamButton initialValues={{ seasonId, officeId: office?.id }} />}
    collapsable={collapsable}
  >
    <TeamGrid rowClick="show" showOffice />
  </ListCard>
}
