import React from 'react';
import { NumberField, ResourceContextProvider } from 'react-admin';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import Toolbar from '../../common/ra/Toolbar';
import FunctionField from '../../common/fields/FunctionField'

import { OfficeField } from '../offices/OfficeField';
import { AssignSystemField } from '../assignSystems';
import { AssignFeesField } from '../assignFees';
import { CategoriesField } from '../categories/CategoriesField';
import { GroupsField } from '../groups/GroupsField';

import { AssignRuleDatagrid } from './AssignRuleModal';

const renderRule = (rule, source, resource, translate) => {
  const { value, type } = rule;

  switch (type) {
    case 'systemId':
      return <AssignSystemField source="value" />
    case 'officeId':
      return <>
        {translate('resources.assignRules.labels.rules.officeId')} <OfficeField source="value" />
      </>
    case 'feesId':
      return <>
        {translate('resources.assignRules.labels.rules.feesId')}: <AssignFeesField source="value" />
      </>
    case 'minReferee':
    case 'minLinesperson':
    case 'minOfficial':
    case 'minScorekeeper':
    case 'minTimekeeper':
    case 'minGrade':
    case 'minRefereeGrade':
    case 'minLinespersonGrade':
    case 'minAge':
      return translate(`resources.assignRules.labels.rules.${type}`, { value })
    case 'assignType':
    case 'status':
      return translate(`resources.assignRules.labels.rules.${type}`, { value: translate(`resources.assignRules.options.${value}`) })
    default:
      return null
  }
}

const renderAttributes = (rule, source, resource, translate) => {
  if (!rule) return null;

  const attributes = [];
  const { categoryIds, types, groupIds } = rule;
  const { duration, days, time, effectiveDate } = rule.attributes || {};

  if (types && types.length) {
    attributes.push(<Typography variant="subtitle2">{translate('resources.assignRules.fields.types')}</Typography>)
    attributes.push(<Typography variant="body2">{
      types.map(type => translate(`resources.assignRules.values.types.${type}`)).join(', ')
    }</Typography>)
  }

  if (groupIds && groupIds.length) {
    attributes.push(<Typography variant="subtitle2">{translate('resources.assignRules.fields.groupIds')}</Typography>)
    attributes.push(<GroupsField source="groupIds" showType allowEmpty />)
  }

  if (duration) {
    attributes.push(<Typography variant="body2">{
      translate('resources.assignRules.labels.duration', { duration })
    }</Typography>)
  }

  if (days && Array.isArray(days) && days.length) {
    attributes.push(<Typography variant="body2">{
      translate('resources.assignRules.labels.days', { days: days.join(', ') })
    }</Typography>)
  }

  if (time) {
    attributes.push(<Typography variant="body2">{
      translate('resources.assignRules.labels.time_before', { time })
    }</Typography>)
  }

  if (categoryIds?.length) {
    attributes.push(<Typography variant="subtitle2">{translate('resources.assignRules.fields.categoryIds')}</Typography>)
    attributes.push(<CategoriesField source="categoryIds" showType allowEmpty />)
  }

  if (effectiveDate) {
    attributes.push(<Typography variant="body2">{
      translate('resources.assignRules.labels.effective', { date: moment.utc(effectiveDate).format('LL') })
    }</Typography>)
  }

  return attributes;
}

const AssignOfficeField = ({ source, ...props }) =>
  <ResourceContextProvider value="offices">
    <OfficeField source={source} />
  </ResourceContextProvider>

export const AssignRuleGrid = props =>
  <AssignRuleDatagrid size="medium" toolbar={<Toolbar />} {...props}>
    <FunctionField label="resources.assignRules.labels.rule" render={renderRule} />
    <FunctionField label="resources.assignRules.labels.criteria" render={renderAttributes} />
    <NumberField source="order" />
    <AssignOfficeField source="officeId" />
  </AssignRuleDatagrid>

export const AssignRuleAltGrid = props =>
  <AssignRuleDatagrid size="medium" toolbar={<Toolbar />} {...props}>
    <FunctionField label="resources.assignRules.labels.criteria" render={renderAttributes} />
    <NumberField source="order" />
    <AssignOfficeField source="officeId" />
  </AssignRuleDatagrid>

