import React, { useState } from 'react';
import { useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import AlertDialog from '../../../../common/dialogs/AlertDialog';
import { isAuthorized } from '../../../../common/Authorize';

import { ApprovalDialog } from '../ApprovalModal';

const ApprovalButton = ({ isMissingIncidents, ...props }) => {
  const translate = useTranslate();
  const [modalOpen, setModalOpen] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false })
  const refresh = useRefresh()

  const handleClick = () => {
    if (!isMissingIncidents) {
      setModalOpen(true)
    } else {
      setAlert({
        title: translate('resources.scoresheets.alerts.missingIncidents.title'),
        message: translate('resources.scoresheets.alerts.missingIncidents.message'),
        reject: 'ra.action.ok',
        isOpen: true,
        onClose: () => {
          setAlert(alert => ({ ...alert, isOpen: false }));
          refresh()
        }
      })
    }
  }

  return <>
    <Button color="primary" variant="contained" size="small" onClick={() => handleClick()} {...props}>{translate('resources.scoresheets.labels.complete')}</Button>
    {modalOpen && <ApprovalDialog open={modalOpen} setModalOpen={setModalOpen} />}
    <AlertDialog {...alert} />
  </>
}

export const ApproveAlert = ({ canApprove, ...props }) => {
  const translate = useTranslate();
  const game = useRecordContext();

  const showButton = canApprove || isAuthorized(game, 'scoresheets', 'full')

  return <Alert severity="info" action={showButton && <ApprovalButton {...props} />}>
    {translate('resources.scoresheets.alerts.approve')}
  </Alert>
}
