import React, { useEffect } from 'react';
import { NumberInput, useTranslate } from 'react-admin';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useFormState } from 'react-final-form';

import { FF_DRAFT_LIMIT_BYES } from '@hisports/common/featureFlags';

import { useFlag } from '../../../../http';
import { DraftGameMethodInput, DraftGameTypeInput } from '../../../../common/inputs/EnumInputs';
import SwitchInput from '../../../../common/inputs/SwitchInput';
import { ScheduleGroupInput } from '../../../groups/GroupInput';

import { METHODS, TYPES } from './Generator';
import { useGeneratorContext } from './GeneratorContext';

const useStyles = makeStyles(theme => ({
  hide: {
    display: 'none'
  },
}))

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const Matchups = props => {
  const translate = useTranslate();
  const isEnabled = useFlag();
  const { values } = useFormState();
  const classes = useStyles();
  const { schedule, lastPublishedDraftRound, setGroupId, setShowMatrix, scheduleTeams } = useGeneratorContext()
  const { groupId, type, method, limitByes }= values;

  const startRoundHelperText = (() => {
    let helperText = translate('resources.draftGames.helpers.start_round')
    if (lastPublishedDraftRound) {
      helperText += `\n${translate('resources.draftGames.helpers.latest_round', lastPublishedDraftRound)}`
    }
    return helperText
  })()

  useEffect(() => {
    setGroupId(groupId || null)
  }, [groupId, setGroupId])

  useEffect(() => {
    setShowMatrix(type === TYPES.MATRIX)
  }, [type, setShowMatrix])

  const showLimitByes = type === TYPES.SINGLE_ROTATION && (isEnabled(FF_DRAFT_LIMIT_BYES) || limitByes != null)
  const showStartRound = method !== METHODS.GAMES_PER_TEAM
  const showMatrixOption = method === METHODS.GAMES_PER_TEAM

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <ScheduleGroupInput source="groupId" scheduleId={schedule.id} showNone={translate('ra.message.no_group')} />
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body1"> {translate('resources.draftGames.labels.number_of_teams')}: {scheduleTeams.length} </Typography>
    </Grid>
    <Grid item xs={12}>
      <DraftGameMethodInput
        source="method"
        label="resources.draftGames.labels.method"
        helperText={false}
        radio
        row={false}
        {...inputProps}
      />
    </Grid>
    <Grid item xs={12}>
      <NumberInput source="gamesPerTeam" label="resources.draftGames.labels.games_per_team" min={0} max={999} helperText={false} className={values?.method !== METHODS.GAMES_PER_TEAM ? classes.hide : null} {...inputProps} />
      <NumberInput source="totalRounds" label="resources.draftGames.labels.total_rounds" min={0} max={999} helperText={false} className={values?.method !== METHODS.TOTAL_ROUNDS ? classes.hide : null} {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <DraftGameTypeInput
        source="type"
        label="resources.draftGames.labels.type"
        showMatrixOption={showMatrixOption}
        helperText={false}
        radio
        row={false}
        {...inputProps}
      />
    </Grid>
    {showLimitByes && <Grid item xs={12}>
      <SwitchInput
        source="limitByes"
        label="resources.draftGames.labels.limit_byes"
        helperText="resources.draftGames.helpers.limit_byes"
      />
    </Grid>}
    {showStartRound && <Grid item xs={12}>
      <NumberInput source="startRound" label="resources.draftGames.labels.start_round" helperText={startRoundHelperText} {...inputProps} />
    </Grid>}
  </Grid>
}
