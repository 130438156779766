import React, { Fragment, useState } from 'react'
import { SimpleForm, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { Dialog, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText } from '@material-ui/core';
import { Restore } from '@material-ui/icons';

import { apiClient } from '../../http';

import { ActionMenuItem } from '../../common/ActionMenu';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import CheckboxInput from '../../common/inputs/CheckboxInput';
import { SquareAlert } from '../../common/SquareAlert';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const validate = values => {
  const errors = {};
  if (!values.confirm) errors.confirm ='ra.validation.required';
  return errors;
}

const ResetForm = (props) => {
  return <SimpleForm validate={validate} {...props} {...inputProps}>
    <CheckboxInput
      source="confirm"
      label="resources.scoresheets.validations.confirm"
      helperText=""
      {...props}
      {...inputProps}
    />
  </SimpleForm>
}

const ResetScoresheetModal = ({ isOpen, handleClose, ...props }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const game = useRecordContext();
  const isApproved = game?.isApproved;

  const onSubmit = async () => {
    await apiClient(`/games/${game.id}/resetScoresheet`, {
      method: 'POST',
    }).then(() => {
      handleClose();
      refresh();
      notify('resources.scoresheets.notifications.reset');
    }).catch(err => {
      handleClose();
      const errorName = err?.response?.data?.error?.name;
      const errorMessage = errorName && `resources.scoresheets.notifications.errors.${errorName}`;
      notify(errorMessage || 'ra.page.error_try_again', 'error')
    })
  }

  return <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.scoresheets.labels.reset_scoresheet')}</DialogTitle>
    {isApproved && <SquareAlert severity="warning">
      {translate('resources.scoresheets.alerts.cannot_reset')}
    </SquareAlert>}
    <DialogContent>
      <DialogContentText color={isApproved ? "textSecondary" : ""}>
        {translate('resources.scoresheets.messages.reset_scoresheet')}
      </DialogContentText>
      <ResetForm disabled={isApproved} save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.reset" onCancel={handleClose} disableInvalidSubmit />
      } />
    </DialogContent>
  </Dialog>
}

export default ({ handleClose }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const handleClick = () => {
    handleClose();
    setOpen(true);
  }
  const handleSubmit = () => {
    setOpen(false);
  }

  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><Restore fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.scoresheets.labels.reset_scoresheet')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <ResetScoresheetModal isOpen={isOpen} handleClose={handleSubmit} />}
  </>
}
