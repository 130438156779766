
import { exportFile, makeExporter } from '../../common/Exporter';

import summary from './reports/summary';

const penaltiesExporter = (penalties, translate, fileType = 'csv', reportName, { sport, isEnabled, locale, ...props }) => {
  const report = summary(penalties, translate, sport, isEnabled, locale);
  if (!report) return;

  return exportFile(report.csv, report.fileName, fileType);
};

const include = [
  { relation: 'team', scope: { scope: 'Tenant' } },
  { relation: 'participant', scope: { scope: 'Tenant' } },
  { relation: 'infraction', scope: {
    scope: 'Tenant',
    include: [{
      relation: 'rule',
      scope: {
        scope: 'Tenant'
      }
    }]
  } },
  { relation: 'servedBy', scope: { scope: 'Tenant' } },
  { relation: 'game', scope: {
    scope: 'Tenant',
    include: [{
      relation: 'schedule',
      scope: {
        scope: 'Tenant',
      }
    }]
  } },
]

export default makeExporter(penaltiesExporter, include);
