import React, { Children, cloneElement, useState } from 'react';
import { Menu, MenuItem as MuiMenuItem, IconButton, styled } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

export const ActionMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  minWidth: theme.spacing(28),
}))

export const ActionMenu = ({ children }) => {
  const [ anchor, setAnchor ] = useState(null);

  const handleOpen = e => {
    setAnchor(e.currentTarget);
  }
  const handleClose = () => {
    setAnchor(null);
  }

  if (Children.map(children, child => React.isValidElement(child)).filter(Boolean).length <= 0) return null
  return <>
    <IconButton size="small" onClick={handleOpen}><MoreVert /></IconButton>
    <Menu
      open={Boolean(anchor)}
      keepMounted
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
    >
      {Children.map(children, action => action && cloneElement(action, { handleClose, onClick: handleClose, ...action.props }))}
    </Menu>
  </>
}
