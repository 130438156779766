import React from 'react';
import { ReferenceArrayField, TextField, useRecordContext, useTranslate } from 'react-admin';

import { SingleFieldList } from '../../common/ra/SingleFieldList';
import { isAuthorized } from '../../common/Authorize';

export const OfficesField = ({ emptyText, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext(props);

  const values = record?.[props.source];
  if (!values?.length && emptyText) return translate(emptyText)

  const showLink = isAuthorized(record, 'offices', 'show') ? 'show' : false;
  return <ReferenceArrayField basePath="/offices" resource="offices" reference="offices" {...props}>
    <SingleFieldList link={showLink} {...props}>
      <TextField source="name" />
    </SingleFieldList>
  </ReferenceArrayField>
}
