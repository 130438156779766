import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { OfficeTypeEnumInput, TimezoneEnumInput } from '../../common/inputs/EnumInputs';
import LogoInput from '../../common/inputs/LogoInput'
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';

import { OfficeInput } from './OfficeInput';

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = 'ra.validation.required';
  if (!values.type) errors.type = 'ra.validation.required';
  if (!values.timezone) errors.timezone = 'ra.validation.required';
  if (values.type !== 'Organization' && !values.parentId) errors.parentId = 'ra.validation.required';
  if (values.id && (values.id === values.parentId)) errors.parentId = 'resources.offices.validations.same_office';

  return errors;
}

const HCRIdInput = props => {
  const { values } = useFormState();

  if (values && ['League', 'Tournament', 'Cup', 'Zone'].includes(values.type)) return null;
  return <TextInput {...props} />
}

const inputProps = {
  resource: 'offices',
  basePath: '/offices',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const NameInput = props => {
  const { values } = useFormState()
  const { name } = values;

  const hasDivision = name && name.match(/U\d+|Initiation|Novice|Atom|PeeWee|Bantam|Midget|Juvenile/i)
  const helperText = hasDivision ? "resources.offices.helpers.name" : null;

  return <TextInput helperText={helperText} {...props} />
}

const TypeInput = props => {
  const { values } = useFormState()

  return <OfficeTypeEnumInput hideOrganization={values.type !== 'Organization'} {...props} />
}

export const OfficeForm = props => {
  const [ isSynced, toggle ] = useRecordSynced(props);

  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm toolbar={<Toolbar isSynced={isSynced} />} validate={validate} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} lg={6}>
          <NameInput source="name" autoFocus disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TypeInput source="type" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <HCRIdInput source="HCRId" helperText="ra.message.optional" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <OfficeInput source="parentId" filter={{ type: { nin: ['League', 'Tournament', 'Cup', 'Zone'] }, _scope: 'Tenant' }} disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TimezoneEnumInput source="timezone" {...inputProps} />
        </Grid>
        {!isSynced && <Grid item xs={12}>
          <LogoInput source="logo" label="Logo" {...inputProps} />
        </Grid>}
      </Grid>
    </SimpleForm>
  </>
}
