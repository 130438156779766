import React from 'react';
import { useTranslate } from 'react-admin';
import { ButtonGroup, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 2),
  },
  spacer: {
    flex: 1,
  }
}))

const SelectionSummary = ({ bulkSelect, toggleBulkSelect, selection, clearSelection }) => {
  const translate = useTranslate();
  return <ButtonGroup size="small" disableElevation>
    <Button variant={!bulkSelect && 'contained'} onClick={() => toggleBulkSelect()}>{translate('ra.date.day', 1)}</Button>
    <Button variant={bulkSelect && 'contained'} onClick={() => toggleBulkSelect()}>{translate('resources.participants.labels.availability.multiple')}</Button>
    <Button disabled={!selection.length} onClick={clearSelection}>{translate('ra.action.clear')}</Button>
  </ButtonGroup>
}

export default ({ bulkSelect, toggleBulkSelect, selection, clearSelection, settings: Settings }) => {
  const classes = useStyles()
  return <div className={classes.root}>
    <SelectionSummary bulkSelect={bulkSelect} toggleBulkSelect={toggleBulkSelect} selection={selection} clearSelection={clearSelection} />
    <div className={classes.spacer}></div>
    {Settings && <Settings />}
  </div>
}
