import React from 'react';
import { TextField, EditButton, useTranslate, useRecordContext } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import InfoCard from '../../../common/cards/InfoCard';
import Row from '../../../common/cards/TableRow';
import TimeRangeField from '../../../common/fields/TimeRangeField';
import { isAuthorized } from '../../../common/Authorize';
import UserTextField from '../../../common/fields/UserTextField';
import { EnumField } from '../../../common/fields/EnumField';

import { TeamField } from '../../teams/TeamField';

export default props => {
  const activity = useRecordContext(props);
  const translate = useTranslate();

  const canEditActivity = isAuthorized(activity, 'activities', 'edit');
  const type = translate(`resources.activities.values.type.${activity?.type}`, { _: activity?.type }) || 'resources.activities.labels.card.name'

  return <InfoCard
    title={type}
    actions={[
      canEditActivity && <EditButton label={translate('ra.page.edit', { name: type })} record={activity} />
    ]}
  >
    <Table>
      <TableBody>
        <Row label="resources.activities.fields.teamId">
          <TeamField source="teamId" includeId="full" includeCategory="full" />
        </Row>
        <Row label="ra.date.name">
          <TimeRangeField startSource="startTime" endSource="endTime" showDate showLocal />
        </Row>
        <Row label="resources.activities.fields.location">
          <TextField {...props} source="location" />
        </Row>
        <Row label="resources.activities.fields.status">
          <EnumField source="status" />
        </Row>
        <Row hidden={!activity?.comments}>
          <UserTextField source="comments" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
