import React from 'react';
import { EditButton, ShowButton, useEditContext, useResourceContext } from 'react-admin';
import { ActionMenu } from '../../common/ActionMenu';

import { isAuthorized } from '../../common/Authorize';
import { TopToolbar } from '../../common/cards/Card';

import OfficeMembersModal from '../officemembers/OfficeMembersModal';
import AssignRuleApplyModal from '../assignRules/AssignRuleApplyModal';
import OfficeInternalActions from './OfficeInternalActions';
import { AnalyzeMenuItem } from '../schedules/AnalyzeDialog';

export const OfficeActions = ({ hasEdit, hasShow, ...props }) => {
  const resource = useResourceContext(props)
  const { basePath, record: office } = useEditContext(props);

  const canEditResource = isAuthorized(office, resource, 'edit');
  const canShowResource = isAuthorized(office, resource, 'show');
  const canShowMembers = isAuthorized(office, 'officemembers', 'list') && ['League', 'Tournament', 'Cup', 'Zone'].includes(office.type)
  const canResetRules = isAuthorized(office, 'assignRules', 'edit')
  const showAnalyze = ['League', 'Tournament', 'Cup', 'Association'].includes(office?.type);

  return <TopToolbar>
    {hasEdit && canEditResource && <EditButton basePath={basePath} resource={resource} record={office} />}
    {hasShow && canShowResource && <ShowButton basePath={basePath} resource={resource} record={office} />}
    <ActionMenu>
      {canShowMembers && <OfficeMembersModal />}
      {showAnalyze && <AnalyzeMenuItem officeId={office?.id} />}
      {canResetRules && <AssignRuleApplyModal type="assignOffices" officeId={office?.id} />}
      {canResetRules && <AssignRuleApplyModal type="scheduleOffices" officeId={office?.id} />}
      <OfficeInternalActions />
    </ActionMenu>
  </TopToolbar>
}
