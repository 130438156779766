import React from 'react';
import { ReferenceInput } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'

import { SectionIcon } from '../icons';

const filterSection = name => ({ name })

const SectionSuggestion = ({ suggestion: section, query, isHighlighted, ...props }) =>
  <AutocompleteSuggestion
    icon={<SectionIcon />}
    primary={section.name}
  />

export const SectionInput = ({ helperText, ...props }) =>
  <ReferenceInput
    reference="sections"
    filterToQuery={filterSection}
    sort={{
      field: ['order', 'name'],
      order: ['ASC', 'ASC'],
    }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText="name"
      suggestionComponent={SectionSuggestion}
      helperText={helperText}
    />
  </ReferenceInput>
