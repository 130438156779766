import { authService } from '../http';
import requirements from '../permissions';

export const hasAnyScope = (scopes = [], requiredScopes = []) => {
  if (!scopes || !requiredScopes) return false;
  return requiredScopes.some(scope => scopes.includes(scope))
}

export const isAuthorized = (recordOrScopes, resource, method, includeAll, filteredScopes = true) => {
  if (!resource || !method) {
    console.error('isAuthorized: resource/method missing') /* eslint-disable-line no-console */
    return false;
  }
  if (requirements?.[resource]?.[method] == null) {
    console.log(`isAuthorized: Requirements missing for resource ${resource} / method ${method}`) /* eslint-disable-line no-console */
    return false;
  }
  if (!recordOrScopes) return false;

  let scopes = [];
  if (Array.isArray(recordOrScopes)) {
    scopes = recordOrScopes;
  } else if (filteredScopes && recordOrScopes?._filteredScopes != null) {
    scopes = recordOrScopes._filteredScopes;
  } else if (recordOrScopes?._scopes != null) {
    scopes = recordOrScopes._scopes;
  } else {
    scopes = authService.getScopes();
  }

  if (includeAll) {
    scopes = [...scopes, ...authService.getScopes()]
  }

  const requiredScopes = requirements[resource][method];
  return requiredScopes && hasAnyScope(scopes, requiredScopes);
}
