import React from 'react';
import { ReferenceField, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';

import { isAuthorized } from '../../common/Authorize';
import FunctionField from '../../common/fields/FunctionField'

export const ParticipantField = ({ includeId = false, link = 'show', className, variant = 'body2', ...props }) => {
  const record = useRecordContext(props);

  const showLink = isAuthorized(record, 'participants', 'show') ? link : false;
  return <ReferenceField basePath="/participants" resource="participants" reference="participants" link={showLink} {...props}>
    <FunctionField render={participant => {
      const id = participant?.HCRId;
      return <>
        <Typography variant={variant} display="inline">{participant?.fullName?.toUpperCase()}</Typography>
        {id && includeId === 'inline' && <span><Typography variant={variant} display="inline" color="textSecondary"> ({id})</Typography></span>}
        {id && includeId === 'full' && <div><Typography variant={variant} display="inline" color="textSecondary"> {id}</Typography></div>}
      </>
    }} variant={variant} className={className} />
  </ReferenceField>
}

ParticipantField.defaultProps = {
  addLabel: true,
}
