import React, { useState } from 'react';
import { Button, Avatar, makeStyles, Menu, MenuItem, Divider, ListItemText, ListSubheader, useMediaQuery, IconButton, Tooltip } from '@material-ui/core';
import { AccountCircle, ArrowDropDown, HowToRegOutlined, NavigateNext } from '@material-ui/icons';
import { useLogout, useTranslate } from 'react-admin';
import { truncate } from 'lodash';
import cn from 'classnames';

import { useAccount, useIdentities, useIdentity, authService } from '../http';

const useStyles = makeStyles(theme => ({
  menu: {
    minWidth: theme.spacing(32),
  },
  button: {
    textTransform: 'initial',
  },
  avatar: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    height: theme.spacing(4),
    width: theme.spacing(4),
    fontSize: '1rem !important',
  },
  avatarAccount: {
    backgroundColor: 'inherit',
    marginRight: theme.spacing(-1),
  }
}))

const capitalizeName = name => name?.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
const toName = value => truncate(capitalizeName(value), { length: 32 })

const IdentityButton = ({ identity, account, onClick, classes }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  let name = <AccountCircle />
  const fullName = identity?.participant?.fullName
  if (fullName) {
    name = fullName.split(' ')[0][0].toUpperCase();
  }

  const avatarClass = cn(classes.avatar, {
    [classes.avatarAccount]: !fullName,
  })

  if (isMobile) {
    return <IconButton onClick={onClick}>
      <Avatar variant="rounded" className={avatarClass}>{name}</Avatar>
    </IconButton>
  }

  const tooltip = [account?.username, identity?.tenant?.name].filter(Boolean).join(' · ')
  return <Tooltip title={tooltip}>
    <Button
      aria-haspopup
      color="inherit"
      className={classes.button}
      startIcon={<Avatar variant="rounded" className={avatarClass}>{name}</Avatar>}
      endIcon={<ArrowDropDown />}
      onClick={onClick}
    >
      {toName(identity?.participant?.firstName)}
    </Button>
  </Tooltip>
}

const IdentitiesList = ({ identity: current, identities, onClick }) => {
  const selectIdentity = (identity) => {
    authService.setIdentity(identity)
    onClick();
    setTimeout(() => {
      // using setTimeout allows the new identity to persist for the reload to be effective
      window.location = '/profile'
    }, 0)
  }

  return <>
    {identities.map(identity => {
      const name = toName(identity.participant.fullName)
      const isCurrent = identity.id === current.id
      return <MenuItem key={identity.id} disabled={isCurrent} onClick={() => selectIdentity(identity)}>
        <ListItemText primary={name} secondary={identity.tenant.name} />
        {isCurrent ? <HowToRegOutlined fontSize="small" /> : <NavigateNext fontSize="small" />}
      </MenuItem>
    })}
    <Divider />
  </>
}

export const IdentityMenu = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const account = useAccount();
  const identity = useIdentity()
  const identities = useIdentities();
  const logout = useLogout();

  const [ anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    handleClose()
    logout()
  }

  return <>
    <IdentityButton identity={identity} account={account} onClick={handleMenu} classes={classes} />
    <Menu
      classes={{
        paper: classes.menu,
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      dense
    >
      <IdentitiesList identity={identity} identities={identities} onClick={handleClose} />
      <ListSubheader>{account.username}</ListSubheader>
      <MenuItem onClick={handleLogout}>{translate('ra.auth.logout')}</MenuItem>
    </Menu>
  </>
}
