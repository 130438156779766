export const findMatch = (fields, options) => {
  const expression = new RegExp(options.join('|'), 'gi')
  return fields.find(field => expression.test(field))
}

export const matchFields = (fields, matchers) => {
  const mappings = {};
  for (const [ key, names ] of Object.entries(matchers)) {
    mappings[key] = findMatch(fields, names);
  }
  return mappings;
}

export const hasInvalid = (data, column, test) => {
  if (!data || !column) return;
  return data
    .map(value => value[column])
    .some(value => test(value) === false)
}

export const check = (values, column, regex) => {
  if (!column) return;
  const profiles = values.data;
  return profiles
    .map(value => value[column])
    .some(value => !regex.test(value))
}

export const getColumn = (values, property) => values.columns && values.columns[property];
export const getData = (values, property) => values.data && values.data.some(data => data[property]);
