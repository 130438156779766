import * as React from 'react';
import { useCallback } from 'react';
import { useInput, InputHelperText, sanitizeInputRestProps, FieldTitle } from 'react-admin';
import { FormHelperText, FormGroup, Checkbox, FormControlLabel } from '@material-ui/core';

const CheckboxInput = (props) => {
  const {
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    options,
    disabled,
    parse,
    resource,
    source,
    validate,
    hideHelper = false,
    ...rest
  } = props;
  const {
    id,
    input: { onChange: finalFormOnChange, type, value, ...inputProps },
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    ...rest,
  });

  const handleChange = useCallback(
    (event, value) => {
      finalFormOnChange(value);
    },
    [finalFormOnChange]
  );

  return (
    <FormGroup {...sanitizeInputRestProps(rest)}>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            color="primary"
            onChange={handleChange}
            {...inputProps}
            {...options}
            disabled={disabled}
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
      />
      {!hideHelper && <FormHelperText error={!!(error || submitError)}>
        <InputHelperText
          touched={touched}
          error={error || submitError}
          helperText={helperText}
        />
      </FormHelperText>}
    </FormGroup>
  );
};

CheckboxInput.defaultProps = {
  options: {},
};

export default CheckboxInput;
