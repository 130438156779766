import React, { useState } from 'react';
import { Datagrid, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { PayPeriodForm } from './PayPeriodForm';

const AddPayPeriodDialog = props =>
  <CreateResourceDialog label="resources.payPeriods.actions.add" resource="payPeriods" basePath="/payPeriods" {...props}>
    <PayPeriodForm />
  </CreateResourceDialog>

const EditPayPeriodDialog = props => {
  const isPeriodClosed = props?.record?.isClosed === true;
  return <EditResourceDialog disableDelete hideSubmit={isPeriodClosed} label="resources.payPeriods.actions.edit" resource="payPeriods" basePath="/payPeriods" {...props}>
    <PayPeriodForm />
  </EditResourceDialog>
}

export const AddPayPeriodButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate()
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.payPeriods.actions.add')}</Button>
    <AddPayPeriodDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const PayPeriodDatagrid = props => <Datagrid size="medium" rowClick="show" {...props} />
