import moment from 'moment-timezone';

export const parseDate = (value, timezone, includeTime = false) => {
  const format = includeTime ? 'YYYY-MM-DD hh:mm' : 'YYYY-MM-DD';
  return moment.tz(value, format, timezone).toISOString(true)
}

export const changeDate = (timestamp, newDate, timezone = 'utc') => {
  const date = moment.tz(newDate, 'YYYY-MM-DD', timezone)
  const time = moment.tz(timestamp, timezone)
  return date.hour(time.hour()).minute(time.minute()).toISOString();
}

export const changeTimezone = (timestamp, currentTimezone, newTimezone) => {
  const format = 'YYYY-MM-DD HH:mm'
  const raw = moment.tz(timestamp, currentTimezone).format(format)
  return moment.tz(raw, format, newTimezone).toISOString();
}

export const isLocalTimezone = (timestamp, timezone, localTimezone) => {
  if (localTimezone) return moment.tz(timestamp, timezone).utcOffset() === moment.tz(timestamp, localTimezone).utcOffset();
  return moment.tz(timestamp, timezone).utcOffset() === moment(timestamp).utcOffset();
}

export const displayTimeRange = (startTime, endTime, timezone = moment.tz.guess()) => {
  if (!startTime || !endTime) return;

  const start = moment.tz(startTime, timezone);
  const end = moment.tz(endTime, timezone);

  return `${start.format('HH:mm')} - ${end.format('HH:mm z')}`;
}
