import React from 'react';
import { useLocale, useRecordContext } from 'react-admin';
import moment from 'moment-timezone';
import { Typography } from '@material-ui/core';

const getDateRange = (startTime, endTime, allDay, locale, timezone) => {
  const start = moment.tz(startTime, timezone);
  const end = moment.tz(endTime, timezone);

  if (locale.startsWith('en')) {
    if (allDay) {
      if (!endTime) return start.format('MMM D');
      if (!start.isSame(end, 'month')) return `${start.format('MMM D')} - ${end.format('MMM D')}`
      if (!start.isSame(end, 'day')) return `${start.format('MMM D')} - ${end.format('D')}`
      return start.format('MMM D');
    }

    if (!endTime) return start.format('MMM D HH:mm z');
    if (!start.isSame(end, 'year')) return `${start.format('MMM D HH:mm')} - ${end.format('MMM D, YYYY HH:mm z')}`
    if (!start.isSame(end, 'day')) return `${start.format('MMM D HH:mm')} - ${end.format('MMM D HH:mm z')}`
    return `${start.format('MMM D HH:mm')} - ${end.format('HH:mm z')}`
  } else {
    if (allDay) {
      if (!endTime) return start.format('D MMM');
      if (!start.isSame(end, 'month')) return `${start.format('D MMM')} - ${end.format('D MMM')}`
      if (!start.isSame(end, 'day')) return `${start.format('D')} - ${end.format('D MMM')}`
      return start.format('D MMM');
    }

    if (!endTime) return start.format('D MMM HH:mm z');
    if (!start.isSame(end, 'year')) return `${start.format('D MMM HH:mm')} - ${end.format('D MMM, YYYY HH:mm z')}`
    if (!start.isSame(end, 'day')) return `${start.format('D MMM HH:mm')} - ${end.format('D MMM HH:mm z')}`
    return `${start.format('D MMM HH:mm')} - ${end.format('HH:mm z')}`
  }
}

const EventDateField = ({ className, ...props }) => {
  const locale = useLocale();
  const record = useRecordContext(props)
  if (!record) return null;
  const dateRange = getDateRange(record.startTime, record.endTime, record.allDay, locale, record.timezone);

  return <Typography
    component="span"
    variant="body2"
    noWrap
    className={className}
  >
    {dateRange}
  </Typography>
}

EventDateField.defaultProps = {
  label: 'Dates',
  addLabel: true,
}

export default EventDateField;
