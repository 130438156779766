import React from 'react';
import { ReferenceInput, ReferenceArrayInput } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'

import { ParticipantIcon } from '../icons'

const filterParticipants = name => ({ name })

const ParticipantSuggestion = ({ suggestion: participant, query, isHighlighted, ...props }) =>
  <AutocompleteSuggestion icon={<ParticipantIcon />} primary={participant.fullName} secondary={participant.HCRId} />

export const ParticipantInput = ({ allowEmpty, helperText, multiple, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="participants"
    filterToQuery={filterParticipants}
    sort={{
      field: 'lastName',
      order: 'ASC'
    }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText="fullName"
      groupBy={p => p.lastName[0]}
      suggestionComponent={ParticipantSuggestion}
      filterKeys={['lastName', 'firstName', 'fullName', 'HCRId']}
      multiple={multiple}
      helperText={helperText}
      emptyOptionsText="resources.participants.messages.search_by_name"
      resettable
      allowEmpty
    />
  </Input>
}
