import React from 'react';
import { ListContextProvider, useList, useRecordContext } from 'react-admin';
import { GroupOutlined } from '@material-ui/icons';

import InfoCard from '../../common/cards/InfoCard.js';
import { useHttpClient } from '../../http';

import { CommitteeRoleGrid } from "../roles/RoleGrid.js";

const useDisciplineCommittee = (suspensionId) => {
  return useHttpClient(`/suspensions/${suspensionId}/committee`, null, !!suspensionId);
}

export default props => {
  const suspension = useRecordContext();
  const { data: committee, loading, loaded, error } = useDisciplineCommittee(suspension?.id);

  const listContext = useList({
    data: committee,
    ids: committee ? committee.map(c => c.id) : [],
    loading: loading,
    loaded: loaded,
    error: error,
    sort: {
      field: 'targetId',
    },
  });

  if (!committee) return null;

  return <ListContextProvider value={listContext}>
    <InfoCard
      title="resources.suspensionDisciplines.labels.discipline_committee"
      icon={<GroupOutlined />}
      collapsable
    >
      <CommitteeRoleGrid />
    </InfoCard>
  </ListContextProvider>
}
