import React, { useEffect } from 'react';
import { ArrayInput, NumberInput, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';

import { DayEnumInput, DraftGameRoundTypeEnumInput, DraftGameWeekdaysTypeEnumInput } from '../../../../common/inputs/EnumInputs';
import { DateInput, InlineDateInput } from '../../../../common/inputs/DateInput';
import HorizontalFormIterator, { TransitionProps } from '../../../../common/ra/HorizontalFormIterator';

import { METHODS, ROUND_TYPES, STEPS, WEEKDAYS_TYPES } from './Generator';
import { useGeneratorContext } from './GeneratorContext';

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const isEven = n => n % 2 === 0;

export const Scheduling = props => {
  const translate = useTranslate();
  const { values = {} } = useFormState();
  const { schedule, scheduleTeams = [], setAlert } = useGeneratorContext()
  const { type, roundType, startDate, method, totalRounds, weekdays, weekdaysType, breaks } = values

  const weekdaysTypeHelperText = (() => {
    return `resources.draftGames.helpers.weekdays_type.${weekdaysType}`
  })()

  const startDateHelperText = (() => {
    let date;

    if (startDate) {
      date = moment.utc(startDate, 'YYYY-MM-DD').format('dddd');
    }

    if (!startDate) return false;

    const helperText = [];
    if (date) {
      let message = translate('resources.draftGames.helpers.start_date', { date });

      if (method === METHODS.GAMES_PER_TEAM) return message

      if (startDate && totalRounds) {
        const draftStart = moment.utc(startDate, 'YYYY-MM-DD');
        const endDay = (breaks || []).filter(Boolean).reduce((endDate, brk) => {
          if (brk.startDate && moment.utc(brk.startDate, 'YYYY-MM-DD').isAfter(endDate, 'day')) return endDate;
          return endDate.add(brk?.totalWeeks || 0, 'weeks');
        }, moment.utc(startDate, 'YYYY-MM-DD').add(totalRounds, 'weeks'))
        const range = translate('ra.date.range', { date1: draftStart.format('YYYY-MM-DD'), date2: endDay.subtract(1, 'day').format('YYYY-MM-DD') })
        const rangeTotalWeeks = translate('ra.date.phrase.week', totalRounds)
        message += ` (${rangeTotalWeeks}, ${range})`;
      }
      helperText.push(message)
    }
    return helperText.join('\n')
  })()

  useEffect(() => {
    if (type === 'Double Rotation' && weekdays?.length > 1 && scheduleTeams && !isEven(scheduleTeams.length)) {
      setAlert({ step: STEPS.SCHEDULING, severity: 'info', message: translate('resources.draftGames.messages.unslotted_matchups_alert') })
    } else {
      setAlert()
    }
  }, [type, roundType, weekdays, scheduleTeams, setAlert, translate])

  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12}>
      <DraftGameRoundTypeEnumInput
        source="roundType"
        label="resources.draftGames.labels.round_type"
        helperText="ra.message.optional"
        {...inputProps}
      />
    </Grid>
    {roundType === ROUND_TYPES.WEEKDAYS && <Grid item xs={12}>
      <DayEnumInput
        source="weekdays"
        label="resources.draftGames.labels.weekdays"
        helperText={false}
        select
        multiple
        abbreviation={false}
        sortValues={false}
        {...inputProps}
      />
    </Grid>}
    {roundType === ROUND_TYPES.WEEKDAYS && <Grid item xs={12}>
      <DraftGameWeekdaysTypeEnumInput
        source="weekdaysType"
        label="resources.draftGames.labels.weekdays_type"
        helperText={weekdaysTypeHelperText}
        initialValue={WEEKDAYS_TYPES.CONSECUTIVE}
        radio
        {...inputProps}
      />
    </Grid>}

    {(roundType === ROUND_TYPES.HOME_SLOTS || roundType === ROUND_TYPES.WEEKDAYS) && <>
      <Grid item xs={12}>
        <DateInput
          source="startDate"
          label="resources.draftGames.labels.start_date"
          helperText={startDateHelperText}
          initialValue={schedule?.startDate}
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source="breaks" label="resources.draftGames.labels.breaks" {...inputProps}>
          <HorizontalFormIterator hideIndex TransitionProps={TransitionProps}>
            <InlineDateInput source="startDate" label="resources.draftGames.labels.start_date" {...inputProps} />
            <NumberInput source="totalWeeks" label="resources.draftGames.labels.total_weeks" {...inputProps} />
          </HorizontalFormIterator>
        </ArrayInput>
      </Grid>
    </>}
  </Grid>
}
