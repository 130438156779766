import React, { useEffect } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, IconButton, Tooltip, makeStyles, useMediaQuery } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { ScoresheetProvider } from '../scoresheet/ScoresheetContext';
import { LazyBoundary } from '../../common/LazyBoundary';

const LineupCardBody = React.lazy(() => import('../scoresheet/lineup/LineupCard'))

const useStyles = makeStyles(theme => ({
  buttons: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  }
}))

const DialogHeader = ({ game, onClose }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return <>
    <DialogTitle>
      {translate('resources.games.labels.game_number_lineup', { number: game.number })}
    </DialogTitle>
    <div className={classes.buttons}>
      <Tooltip title={translate('ra.action.close')}>
        <IconButton onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  </>
}

export default ({ open, teamId, onClose }) => {
  const game = useRecordContext()
  const history = useHistory();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    return history.listen(() => {
      if (!open) return;
      onClose();
    })
  }, [history, onClose, open])

  if (!game) return null;

  const onClick = e => e.stopPropagation();
  return <Dialog
    open={open}
    fullWidth
    fullScreen={fullScreen}
    maxWidth="sm"
    onClick={onClick}
    onClose={onClose}
    disableBackdropClick
  >
    <DialogHeader game={game} onClose={onClose} />
    <LazyBoundary>
      <ScoresheetProvider gameId={game.id}>
        <LineupCardBody teamId={teamId} showCompleted />
      </ScoresheetProvider>
    </LazyBoundary>
  </Dialog>
}
