import React from 'react';
import { ReferenceField } from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';

import FunctionField from '../../common/fields/FunctionField';

import { InfractionTypeField } from './InfractionTypeField'
import { RuleField } from './RuleField'

const useStyles = makeStyles((theme) => ({
  type: {
    paddingLeft: theme.spacing(0.5)
  }
}))

export const InfractionField = ({ variant = 'body2', ...props }) => {
  const classes = useStyles();

  return <ReferenceField basePath="/infractions" resource="infractions" reference="infractions" {...props} link={false}>
    <FunctionField render={infraction => <>
      <Typography variant={variant} display="inline">{infraction.code}</Typography>
      <InfractionTypeField source="typeId" className={classes.type} />
      <Typography variant={variant} color="textSecondary">
        <RuleField source="ruleId" />
      </Typography>
    </>} />
  </ReferenceField>
}

InfractionField.defaultProps = {
  addLabel: true,
}
