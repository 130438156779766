import React, { useState, useEffect } from 'react'
import { useLocale, useResourceContext, useTranslate } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core'
import { Add, Check, Clear, Edit } from '@material-ui/icons'
import moment from 'moment-timezone';
import cn from 'classnames';

import { isLocalTimezone } from '@hisports/parsers/src/dateTime';

import { getDateFormat } from '../../../common/fields/DateField';

import SlotModal from './SlotModal';
import { formatTime, updateSlots, availabilitiesToSlot } from './util'

const getSize = slot => {
  if (!slot) return;
  const diff = moment(slot.endTime).diff(slot.startTime);
  const hours = moment.duration(diff).asHours();

  const minutes = hours * 60;
  return Math.max(6, Math.min(minutes / 15, 16)) * 8
}

const stripes = (r, g, b) => `repeating-linear-gradient(
  -55deg,
  rgba(${r}, ${g}, ${b}, .125),
  rgba(${r}, ${g}, ${b}, .125) 8px,
  rgba(${r}, ${g}, ${b}, .15) 8px,
  rgba(${r}, ${g}, ${b}, .15) 16px
)`

const useSlotStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
  },
  placeholder: {
    padding: theme.spacing(0, 1),
    height: '100%',
    textAlign: 'center',
    background: stripes(222, 222, 222),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },
  edit: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.text.disabled,
  },
  empty: {
    padding: theme.spacing(3),
    background: stripes(189, 189, 189),
    textAlign: 'center',
  },
  time: {
    marginTop: theme.spacing(1),
    minHeight: '4em',
    position: 'relative',
    height: props => getSize(props.slot),
    cursor: props => props.editable ? 'pointer' : 'default',
  },
  available: {
    backgroundColor: 'rgba(76, 175, 80, .25)',
  },
  unavailable: {
    backgroundColor: 'rgba(211, 47, 47, .15)',
  },
  notes: {
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    textAlign: 'center',
  },
  updatedat: {
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center',
    fontSize: "60%"
  }
}))

const Placeholder = ({ loading }) => {
  const translate = useTranslate();
  const classes = useSlotStyles({});
  const __html = translate(loading ? 'ra.page.loading' : 'resources.participants.messages.availability.placeholder')
  return <div
    className={cn(classes.root, classes.placeholder)}
    dangerouslySetInnerHTML={{ __html }}
  >
  </div>
}

const EmptySlot = ({ onClick }) => {
  const translate = useTranslate();
  const classes = useSlotStyles({});
  return <div className={cn(classes.root, classes.empty)} onClick={onClick}>
    {translate('resources.participants.messages.availability.empty_slot')}
  </div>
}

const Slot = ({ slot, onClick, editable }) => {
  const classes = useSlotStyles({ slot, editable });
  const availableClass = slot.isAvailable ? classes.available : classes.unavailable;
  const translate = useTranslate();

  return <div className={cn(classes.root, classes.time, availableClass)} onClick={editable ? () => onClick(slot) : null}>
    {editable && <div className={classes.edit}>
      <Edit fontSize="small" />
    </div>}
    {slot.isAvailable ? <Check /> : <Clear />}
    { formatTime(slot.startTime, slot.timezone) } - { formatTime(slot.endTime, slot.timezone)}
    { slot.notes && <div className={classes.notes}>{slot.notes}</div>}
    { slot.updatedat && <div className={classes.updatedat}>{translate('ra.message.updated')}: {moment.tz(slot.updatedat, slot.timezone).format('LLLL')}</div>}
  </div>
}

const usePanelStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    height: `calc(100% - ${theme.spacing(2)}px)`,
  },
  title: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
  spacer: {
    flex: 1,
    minHeight: theme.spacing(3),
  },
  timezone: {
    fontSize: '0.875rem',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }
}))

const SaveButton = ({ selection, dirty, saving, ...props }) => {
  const locale = useLocale();
  const translate = useTranslate();

  let saveLabel = translate('resources.participants.labels.availability.saved');
  if (saving) {
    saveLabel = translate('resources.participants.labels.availability.saving')
  } else if (dirty) {
    saveLabel = translate('resources.participants.messages.availability.save_for');
    if (selection.length === 1) {
      const dateFormat = getDateFormat(locale)
      saveLabel += moment.utc(selection[0]).format(dateFormat)
    } else {
      saveLabel += translate('resources.participants.messages.availability.number_dates', { number: selection.length })
    }
  }

  const variant = dirty ? 'contained' : 'text';
  return <Button
    color="primary"
    startIcon={<Check />}
    variant={variant}
    disabled={!dirty || saving}
    {...props}
  >
    {saveLabel}
  </Button>
}

export default ({ availabilities = [], selection = [], clearSelection, timezone: defaultTimezone = moment.tz.guess(), onSave, saving, loading, editable }) => {
  const translate = useTranslate();
  const classes = usePanelStyles();
  const resource = useResourceContext();

  const [ timezone, setTimezone ] = useState(defaultTimezone);
  const [ date, setDate ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ selected, setSelected ] = useState(null);
  const [ slots, setSlots ] = useState([]);
  const [ dirty, setDirty ] = useState(false);

  useEffect(() => {
    if (!selection.length) {
      setDate(null);
      setSlots([]);
      setDirty(false);
      setTimezone(defaultTimezone)
      return;
    }
    const selectionDate = selection[0];
    if (selectionDate === date) {
      if (selection.length > 1) setDirty(true)
      return;
    };

    setDate(selectionDate);
    const slots = availabilitiesToSlot(availabilities, selectionDate);
    setSlots(slots);
    if (slots.length) {
      setTimezone(slots[0].timezone)
    } else {
      setTimezone(defaultTimezone)
    }
  }, [ date, selection, availabilities, defaultTimezone ])

  const openSlot = slot => {
    setSelected(slot);
    setOpen(true);
  }

  const onAdd = slot => {
    setOpen(false);
    if (!slot) return;
    setDirty(true)
    setSlots(existing => updateSlots(existing, slot))
  }

  if (loading || !selection.length) return <Placeholder loading={loading} />

  const displayedTimezone = isLocalTimezone(undefined, timezone)
    ? 'resources.games.messages.assignment.time_display_local'
    : 'resources.games.messages.assignment.time_display_other'
  return <div className={classes.root}>
    <div className={classes.title}>
      {translate(`resources.${resource}.labels.availability.title`)}
    </div>

    {!slots.length && <EmptySlot onClick={() => editable && openSlot(null)} />}
    {slots.map(slot => <Slot key={slot.id} slot={slot} onClick={openSlot} editable={editable} />)}

    {editable && <Button color="primary" startIcon={<Add />} onClick={() => openSlot(null)}>
      {translate('resources.participants.labels.availability.add_time')}
    </Button>}

    <div className={classes.spacer}></div>
    <div className={classes.timezone}>{translate(displayedTimezone, { timezone: moment.tz(timezone).zoneAbbr() })}</div>
    {editable && <SaveButton selection={selection} dirty={dirty} saving={saving} onClick={() => onSave(slots, timezone)} />}

    {open && <SlotModal slot={selected} timezone={timezone} onClose={onAdd} />}
  </div>
}
