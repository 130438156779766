import React, { useState } from 'react';
import { useQueryWithStore, useRefresh, useNotify, GET_MANY_REFERENCE, useRecordContext, useResourceContext, useGetOne } from 'react-admin';
import { Grid, makeStyles } from '@material-ui/core';
import { EventAvailable } from '@material-ui/icons';
import moment from 'moment-timezone';

import { dedupe } from '@hisports/parsers';

import OfficeSelector, { useOffice } from '../../../common/inputs/OfficeSelector';
import InfoCard from '../../../common/cards/InfoCard';

import AvailabilityPanel from './AvailabilityPanel';
import AvailabilityCalendar from './AvailabilityCalendar';
import AvailabilityToolbar from './AvailabilityToolbar';
import { saveAvailability } from './util';

const getDates = (startDate, timezone) => {
  const calendarStart = moment.tz(startDate, timezone).startOf('month').day(0).startOf('day')
  const calendarEnd = moment.tz(startDate, timezone).endOf('month').day(6).endOf('day')

  const date = moment.tz(calendarStart, timezone);
  const dates = [];
  do {
    dates.push(date.format('YYYY-MM-DD'))
    date.add(1, 'day');
  } while (date.isBefore(calendarEnd));
  return dates;
}

const useAvailabilities = (target, dates, id, officeId, enabled) => {
  const { data = [], loading, error } = useQueryWithStore({
    type: GET_MANY_REFERENCE,
    resource: 'availabilities',
    payload: {
      target,
      id,
      pagination: { page: 0, perPage: 9999 },
      sort: { field: 'startTime', order: 'ASC' },
      filter: {
        officeId,
        effectiveOffices: false,
        date: { inq: dates }
      },
    }
  }, { enabled, action: 'CUSTOM_QUERY' })

  return [ data, loading, error ]
}

const useTimezone = (resource, officeId, recordId) => {
  const tzResource = resource === 'surfaces' ? 'surfaces' : 'offices';
  const id = resource === 'surfaces' ? recordId : officeId;
  const { data } = useGetOne(tzResource, id, { enabled: id != null })
  return data?.timezone || moment.tz.guess();
}

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
  }
}))

const AvailabilityCard = ({ settings, editable, officeIds, ...props }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const selectedOfficeId = useOffice();

  let officeId = null, requiresOffice = false, availabilityResource = resource;
  switch (resource) {
    case 'participants': break;

    case 'offices':
    case 'leagues': {
      officeId = record.id;
      availabilityResource = 'offices';
      break;
    }
    case 'teams': {
      officeId = record.officeId;
      break;
    }
    case 'surfaces':
    default: {
      requiresOffice = true;
      officeId = selectedOfficeId;
      break;
    }
  }

  const timezone = useTimezone(resource, officeId, record?.id);

  const [ saving, setSaving ] = useState(false);
  const [ date, setDate ] = useState(moment.tz(timezone).toISOString());
  const dates = getDates(date, timezone);
  const setMonth = months => {
    setDate(moment.tz(date, timezone).add(months, 'months').toISOString())
  }

  const missingOffice = editable && requiresOffice && officeId == null
  const [ availabilities, loading, error ] = useAvailabilities(availabilityResource, dates, record.id, officeId, !missingOffice);
  const [ selection, setSelection ] = useState([]);
  const [ bulkSelect, setBulkSelect ] = useState(false);
  const toggleBulkSelect = () => {
    setBulkSelect(!bulkSelect);
    if (bulkSelect && selection.length > 1) {
      setSelection([selection[0]])
    }
  }

  const disableCalendar = loading || error || missingOffice;

  const toggleSelection = (date, selectMultiple, selectWeekday) => setSelection(selection => {
    if (selectMultiple && selection.length) {
      const shiftSelection = [];
      const lastSelected = selection[selection.length - 1];
      const startDate = lastSelected < date ? moment(lastSelected) : moment(date);
      const endDate = lastSelected < date ? moment(date) : moment(lastSelected);

      while (startDate <= endDate) {
        if (!selectWeekday || moment(lastSelected).format('ddd') === startDate.format('ddd')) {
          shiftSelection.push(startDate.format('YYYY-MM-DD'));
        }
        startDate.add(1, 'days');
      }
      return dedupe([...selection, ...shiftSelection]);
    }
    if (selection.includes(date)) {
      if (!bulkSelect) return [];
      return selection.filter(select => select !== date);
    }
    if (!bulkSelect) return [date];
    return [...selection, date];
  })
  const clearSelection = () => setSelection([])

  const onSave = (slots, timezone) => {
    setSaving(true)
    saveAvailability(availabilityResource, record.id, selection, slots, timezone, officeId)
      .then(() => {
        notify(`resources.${resource}.labels.availability.updated`, 'info');
        clearSelection();
        refresh();
      }).catch(() => {
        notify('ra.page.error_try_again', 'error');
      }).finally(() => {
        setSaving(false)
      })
  }

  const officeFilter = officeIds?.length > 0 ? { id: { inq: officeIds } } : {}
  return <Grid container className={classes.root}>
    {requiresOffice &&
      <Grid item xs={12} sm={12} md={9}>
        <OfficeSelector filter={{ ...officeFilter, effectiveSurfaces: true }} disabled={officeIds && !officeIds?.length} />
      </Grid>
    }
    <Grid item xs={12} sm={12} md={9} className={disableCalendar && classes.disabled}>
      <AvailabilityCalendar
        date={date}
        dates={dates}
        availabilities={!disableCalendar ? availabilities : []}
        timezone={timezone}
        selection={selection}
        toggleSelection={toggleSelection}
        setMonth={setMonth}
      />
      {editable &&
        <AvailabilityToolbar
          bulkSelect={bulkSelect}
          toggleBulkSelect={toggleBulkSelect}
          selection={selection}
          clearSelection={clearSelection}
          settings={settings}
        />
      }
    </Grid>
    <Grid item xs={12} sm={12} md={3} className={disableCalendar && classes.disabled}>
      <AvailabilityPanel
        availabilities={availabilities}
        selection={selection}
        clearSelection={clearSelection}
        timezone={timezone}
        onSave={onSave}
        saving={saving}
        loading={loading}
        editable={editable}
      />
    </Grid>
  </Grid>
}

export default ({ icon, collapsable, editable, officeIds, ...props }) => {
  const resource = useResourceContext();
  return <InfoCard
    title={`resources.${resource}.labels.availability.card.title`}
    subtitle={`resources.${resource}.labels.availability.card.subtitle`}
    icon={<EventAvailable />}
    collapsable={collapsable}
  >
    <AvailabilityCard editable={editable} officeIds={officeIds} {...props} />
  </InfoCard>
}
