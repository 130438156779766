import React from 'react';
import { useTranslate } from 'react-admin';
import { styled, Tooltip, useTheme } from "@material-ui/core"
import { DoneAll, Inbox, Feedback, Clear, SportsHockey, LocationOff, GroupAddOutlined, ReportProblemOutlined } from '@material-ui/icons';

import { isDelegated } from '@hisports/assigning';

import { useRichTranslate } from '../../../../../common/useRichTranslate';
import { useAssignmentsContext } from '../AssignmentsContext';
import { AssigningOfficeField } from './OfficialDetails/SummaryTab';

const DelegatedIcon = styled(GroupAddOutlined)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.grey[600],
}))

const DelegatedStatus = ({ assignment }) => {
  const translate = useRichTranslate();
  const { settings } = useAssignmentsContext()

  const payOfficeId = assignment?.payOfficeId || settings?.officeId

  const assigningOffice = <AssigningOfficeField officeId={assignment?.officeId} />
  const payOffice = <AssigningOfficeField officeId={payOfficeId} />

  if (!isDelegated(assignment) || !payOfficeId) return null;

  return <Tooltip
    title={<>
      {translate("resources.games.helpers.assignment.delegated_tooltip", { office: assigningOffice }) }
      <br />
      {translate("resources.games.helpers.assignment.pay_delegated_tooltip", { office: payOffice }) }
    </>}
  >
    <DelegatedIcon />
  </Tooltip>
}

// displayedStatus used when we assign a new official, we update the status in the front-end instead of re-fetching status
export const AssignmentStatusIcon = ({ assignment, fontSize = "small", style = {}, showUnassigned = false }) => {
  const theme = useTheme();
  const translate = useTranslate();

  if (assignment.position === 'Participant') {
    return <Tooltip title={translate('resources.participants.name', 1)}>
      <SportsHockey fontSize={fontSize} />
    </Tooltip>
  }

  const status = assignment.status

  switch (status) {
    case 'confirmed':
      return <Tooltip title={translate('resources.games.labels.assignment.status.confirmed')}>
        <DoneAll fontSize={fontSize} style={style} htmlColor={theme.palette.success.main} />
      </Tooltip>
    case 'assigned':
      return <Tooltip title={translate('resources.games.labels.assignment.status.pending')}>
        <Inbox fontSize={fontSize} style={style} />
      </Tooltip>
    case 'declined':
      return <Tooltip title={translate('resources.games.labels.assignment.status.declined')}>
        <Clear color="error" fontSize={fontSize} style={style} />
      </Tooltip>
    case 'requested':
      return <Tooltip title={translate('resources.games.labels.assignment.status.requested')}>
        <Feedback fontSize={fontSize} style={style} />
      </Tooltip>
    case 'no-show':
      return <Tooltip title={translate('resources.games.labels.assignment.status.no_show')}>
        <LocationOff fontSize={fontSize} style={style} />
      </Tooltip>
    case 'unassigned': {
      if (!showUnassigned) return null
      return <Tooltip title={translate('resources.games.labels.assignment.unassigned')}>
        <ReportProblemOutlined fontSize={fontSize} style={style} htmlColor={theme.palette.warning.main} />
      </Tooltip>
    }
    default:
      return null;
  }
}

export const AssignmentStatus = ({ assignment, showDelegated }) => {
  const translate = useTranslate();
  let status;
  if (assignment.status === 'confirmed') status = <strong>{translate('resources.games.labels.assignment.status.confirmed')}</strong>;
  if (assignment.status === 'assigned') status = translate('resources.games.labels.assignment.status.pending')
  if (assignment.status === 'declined') status = <strong>{translate('resources.games.labels.assignment.status.declined')}</strong>
  if (assignment.status === 'no-show') status = translate('resources.games.labels.assignment.status.no_show')

  return <div style={{ display: 'inline-flex', alignItems: 'center' }}>
    {status}
    <AssignmentStatusIcon assignment={assignment} fontSize="default" style={{ marginLeft: 2 }} />
    {showDelegated && <DelegatedStatus assignment={assignment} />}
  </div>
}

export const AssignmentPosition = ({ assignment, showUnassigned = false }) => {
  const translate = useTranslate();
  return <div style={{ display: 'inline-flex', alignItems: 'center' }}>
    {translate(`resources.games.values.assignment.position.${assignment.position}`)}
    <AssignmentStatusIcon assignment={assignment} fontSize="default" style={{ marginLeft: 4 }} showUnassigned={showUnassigned} />
  </div>
}
