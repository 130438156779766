import React from 'react';
import { ReferenceField } from 'react-admin';

import { TextLocaleField } from '../../common/fields/TextLocaleField';

export const RuleField = ({ variant = 'body2', ...props }) => {
  return <ReferenceField basePath="/rules" resource="rules" reference="rules" {...props} link={false}>
    <TextLocaleField source="name" variant={variant} />
  </ReferenceField>
}

RuleField.defaultProps = {
  addLabel: true,
}
