import { uniqWith, isEqual } from '@hisports/common/src/lodash.js';
import { CERTIFICATION_FLAGS_UPDATED, ADD_CERTIFICATION_FLAG, REMOVE_CERTIFICATION_FLAG, SCORESHEET_DISAPPROVED, SCORESHEET_RESET } from "../actions/index.js";


export default (state = [], action) => {
  switch (action.type) {
    case CERTIFICATION_FLAGS_UPDATED: {
      const flags = action.payload.event.flags;

      return uniqWith(flags, isEqual);
    }

    case ADD_CERTIFICATION_FLAG: {
      const { name, teamId } = action.payload;
      const flag = { name, teamId };

      const flagExists = state.some(f => f.name === flag.name && f.teamId == flag.teamId);
      if (flagExists) return state;

      return [...state, flag];
    }

    case REMOVE_CERTIFICATION_FLAG: {
      const { name, teamId } = action.payload;

      return state.filter(f => f.name != name || teamId != f.teamId)
    }

    case SCORESHEET_RESET:
    case SCORESHEET_DISAPPROVED: {
      return [];
    }

    default:
      return state;
  }
}
