import React from 'react';
import { ReferenceInput, ReferenceArrayInput, useLocale } from 'react-admin';

import { translateApiProperty } from '@hisports/common';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { ilike } from '../../http/restClient/filters';

const filterDivision = (name, locale) => name ? ({ or: [
  { name: ilike(name) },
  { [`i18n.${locale}.name`]: ilike(name) },
] }) : undefined

export const DivisionSuggestion = ({ suggestion: division, query, isHighlighted, ...props }) => {
  const locale = useLocale();
  return <AutocompleteSuggestion primary={translateApiProperty(division, 'name', locale)} />
}

export const DivisionInput = ({ helperText, multiple, ...props }) => {
  const locale = useLocale();
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="divisions"
    filterToQuery={name => filterDivision(name, locale)}
    sort={{ field: ['order', 'name'], order: ['ASC', 'ASC'] }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText={division => translateApiProperty(division, 'name', locale)}
      suggestionComponent={DivisionSuggestion}
      filterKeys={['name', 'shortName', `i18n.${locale}.name`, `i18n.${locale}.shortName`]}
      disableSort
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
