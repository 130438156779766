import React, { Fragment, useMemo, useState } from 'react';
import { Menu, MenuItem, IconButton, makeStyles, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, Typography, Grid } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Build as BuildIcon, Flag as FlagIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { camelCase, startCase } from 'lodash';

import { FF_CATEGORIES } from '@hisports/common/feature-flags-categories';

import { useFlags, usePermissions, useTenant } from '../http';

const useStyleToolButton = makeStyles(theme => ({
  button: {
    color: [theme.palette.tertiary.light, '!important'],
    opacity: 0.8,
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
  }
}));

const useStyleDropdownlButton = makeStyles(theme => ({
  button: {
    color: [theme.palette.tertiary.main, '!important'],
  }
}));

const InternalToolButton = ({ className, onClick }) => {
  const classes = useStyleToolButton();

  return <IconButton
    onClick={onClick}
    aria-haspopup
    className={` ${classes.button} ${className}`}
  >
    <BuildIcon fontSize="small" />
  </IconButton>
}

const FeatureFlagDialog = ({ open, onClose }) => {
  const flags = useFlags();
  const tenant = useTenant();
  const initialValues = useMemo(() => flags.reduce((initialValues, flag) => {
    initialValues[flag] = true;
    return initialValues;
  }, {}), [flags]);

  const translate = useTranslate();

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
    <DialogTitle>
      <div style={{ marginBottom: '1rem' }}>{`${translate('components.feature_flags.title')} - ${tenant?.name}`}</div>
      <Alert severity="info">
        {translate('components.feature_flags.alerts.branch_level')}
      </Alert>
    </DialogTitle>

    <DialogContent>
      {Object.entries(FF_CATEGORIES).map(([category, flags]) => <>
        <Typography variant="subtitle2" title={category}>{startCase(camelCase(category))}</Typography>
        <br />
        <Grid container spacing={3}>
          {flags.map(flag => <Grid item xs={12} md={4}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{ width: '1.1rem', height: '1.1rem', background: initialValues[flag.id || flag] ? '#4caf50' : '#ff1744', borderRadius: '50%', marginTop: 'auto' }}
                  title={initialValues[flag.id || flag] ? 'enabled' : 'disabled'}
                ></div>
                <div style={{ marginLeft: '1rem', fontWeight: 'bold', flex: 1, fontSize: '.9rem' }} title={flag.id || flag}>{(flag.id || flag).toUpperCase()}</div>
              </div>
              {flag.description && <div style={{ color: 'grey', fontSize: '.8rem', marginTop: '.5rem' }}>
                {flag.description}
              </div>}
            </div>
          </Grid>
          )}
        </Grid>
        <br />
        <br />
      </>)}
    </DialogContent>
  </Dialog>
};

export const useCanShowInternalMenu = () => {
  const permissions = usePermissions();
  const canShowInternalMenu = permissions.some(p => p.roleType === 'System');
  return canShowInternalMenu;
};

export const InternalToolMenu = ({ className }) => {
  const translate = useTranslate();
  const classes = useStyleDropdownlButton();
  const [FFOpen, setFFOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return <>
    <InternalToolButton onClick={handleMenu} className={className} />
    {FFOpen && <FeatureFlagDialog open={FFOpen} onClose={() => setFFOpen(false)} />}
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      dense
    >
      <MenuItem onClick={() => setFFOpen(true)}>
        <ListItemIcon>
          <FlagIcon fontSize="small" className={classes.button} />
        </ListItemIcon>
        <ListItemText primary={translate('components.feature_flags.title')} />
      </MenuItem>
    </Menu>
  </>
}
