import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { EmojiEventsOutlined } from '@material-ui/icons';

import { EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { SeedsForm } from './SeedsForm';

const EditSeedsDialog = props =>
  <EditResourceDialog label="resources.gameBrackets.labels.edit" resource="gameBrackets" basePath="/gameBrackets" {...props}>
    <SeedsForm />
  </EditResourceDialog>

export const EditSeedsButton = ({ size, variant, seeds, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  if (!seeds) return null;
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<EmojiEventsOutlined />} onClick={() => setOpen(true)}>{translate('resources.gameBrackets.actions.edit')}</Button>
    <EditSeedsDialog id={seeds?.id} isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}
