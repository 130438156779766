import React, { Fragment } from 'react';
import { RecordContextProvider, useTranslate, useQueryWithStore, GET_MANY } from 'react-admin';
import {
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons'
import moment from 'moment-timezone';

import { dedupe } from '@hisports/parsers';

import { TeamField } from '../../teams/TeamField';
import { OfficeField } from '../../offices/OfficeField';
import { GroupField } from '../../groups/GroupField';

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
  overflowX: 'auto',
  overflowY: 'hidden',
})

const Heading = props => <Typography variant="body2" gutterBottom {...props} />

const getAllGames = (draftGames = [], existingGames = []) => {
  return [...draftGames, ...existingGames].reduce((games, game) => {
    if (games.some(g => game.number == g.number)) return games;
    games.push(game);
    return games;
  }, []).sort((a = {}, b = {}) => new Date(a.startTime || a.date) - new Date(b.startTime || b.date))
}

const hasRounds = (draft) => {
  return draft?.games?.some(game => game.round != null)
}

const getByes = (draft, games) => {
  const draftHasRounds = hasRounds(draft);

  let rotations = [];
  if (draftHasRounds) {
    const rounds = games.map(game => game.round).filter(Boolean)
    const min = rounds.reduce((a, b) => Math.min(a, b))
    const max = rounds.reduce((a, b) => Math.max(a, b))
    rotations = Array.from({ length: max - min + 1 }, (_, i) => i + min)
  } else {
    const startDate = draft.options?.startDate
      ? moment.utc(draft.options.startDate, 'YYYY-MM-DD')
      : moment.tz(games[0].startTime, games[0].timezone).format('YYYY-MM-DD');

    const end = moment.tz(games[games.length - 1].startTime, games[games.length - 1].timezone);
    const totalWeeks = end.diff(startDate, 'weeks') + 1;

    for (let weekNumber = 0; weekNumber < totalWeeks; weekNumber++) {
      rotations.push({
        start: moment.utc(startDate, 'YYYY-MM-DD').add(weekNumber * 7, 'days').format(),
        end: moment.utc(startDate, 'YYYY-MM-DD').add(weekNumber * 7 + 6, 'days').format(),
      });
    }
  }

  const byes = rotations.map(round => {
    const teamIds = [];
    games.forEach(game => {
      const areTeamsPlaying = draftHasRounds
        ? game.round === round
        : moment.tz(moment.tz(game.startTime, game.timezone).format('YYYY-MM-DD'), game.timezone).isBetween(round.start, round.end, 'day', '[]')

      if (!areTeamsPlaying) return;
      teamIds.push(game.homeTeamId, game.awayTeamId);
    })

    return dedupe(teamIds);
  });

  return byes.map(bye => draft.teams.filter(team => !bye.includes(team.teamId)))
}

const Summary = ({ draft, existingGames }) => {
  const translate = useTranslate()

  const allGames = getAllGames(draft.games, existingGames)
  const byes = getByes(draft, allGames);

  const teams = draft.teams.map(team => {
    const { teamId } = team;
    const home = allGames.filter(game => game.homeTeamId === teamId)
    const away = allGames.filter(game => game.awayTeamId === teamId)
    const conflicts = allGames.filter(game => (game.homeTeamId === teamId || game.awayTeamId === teamId) && game.status === 'Conflict')
    const byesCount = byes.reduce((total, bye) => {
      if (bye.map(team => team.teamId).includes(teamId)) total++;
      return total;
    }, 0)
    const opponents = allGames.reduce((teams, game) => {
      if (game.homeTeamId === teamId) teams.add(game.awayTeamId);
      if (game.awayTeamId === teamId) teams.add(game.homeTeamId);
      return teams;
    }, new Set())

    return {
      teamId: team.teamId,
      day: team.day,
      opponents: opponents.size,
      conflicts: conflicts.length,
      byes: byesCount,
      home: home.length,
      away: away.length,
      total: home.length + away.length,
    }
  })

  const showDay = teams.some(t => t.day);

  return <Table padding="dense">
    <TableHead>
      <TableRow>
        <TableCell>{translate('resources.drafts.labels.total')}</TableCell>
        {showDay && <TableCell>{translate('ra.date.day', 1)}</TableCell>}
        <TableCell numeric>{translate('resources.drafts.labels.opponents')}</TableCell>
        <TableCell numeric>{translate('resources.drafts.labels.conflicts')}</TableCell>
        <TableCell numeric>{translate('resources.drafts.labels.byes')}</TableCell>
        <TableCell numeric>{translate('resources.drafts.labels.home')}</TableCell>
        <TableCell numeric>{translate('resources.drafts.labels.away')}</TableCell>
        <TableCell numeric>{translate('resources.drafts.labels.total')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {teams.map(team =>
        <RecordContextProvider value={team}>
          <TableRow key={team.teamId}>
            <TableCell component="th" scope="row">
              <TeamField source="teamId" includeId="inline" />
            </TableCell>
            {showDay && <TableCell>{team.day}</TableCell>}
            <TableCell numeric>{team.opponents}</TableCell>
            <TableCell numeric>{team.conflicts}</TableCell>
            <TableCell numeric>{team.byes}</TableCell>
            <TableCell numeric>{team.home}</TableCell>
            <TableCell numeric>{team.away}</TableCell>
            <TableCell numeric>{team.total}</TableCell>
          </TableRow>
        </RecordContextProvider>
      )}
    </TableBody>
  </Table>
}

const Matchups = ({ draft, games }) => {
  const translate = useTranslate()

  const teams = draft.teams.map((team, index) => {
    const { teamId, groupId } = team;
    const opponents = draft.teams
      .reduce((opponents, opponent, index) => {
        if (opponent.teamId === teamId) {
          opponents[index] = { home: 0, away: 0 }
          return opponents;
        }

        const home = games
          .filter(game => game.homeTeamId === teamId && game.awayTeamId === opponent.teamId)
          .length;
        const away = games
          .filter(game => game.homeTeamId === opponent.teamId && game.awayTeamId === teamId)
          .length;

        opponents[index] = { home, away }
        return opponents;
      }, [])
    return {
      id: index + 1,
      teamId,
      groupId,
      day: team.day,
      opponents,
    }
  })

  const showGroups = draft?.options?.crossGroup && teams.some(team => team.groupId);

  return <Table padding="dense">
    <TableHead>
      <TableRow>
        <TableCell numeric />
        {showGroups && <TableCell>{translate('resources.drafts.labels.team')}</TableCell>}
        <TableCell>{translate('resources.drafts.labels.group')}</TableCell>
        {teams.map(team => <TableCell numeric>{team.id}</TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {teams.map(team =>
        <RecordContextProvider value={team} key={team.id}>
          <TableRow hover>
            <TableCell component="th" scope="row" numeric>{team.id}</TableCell>
            <TableCell><TeamField source="teamId" /></TableCell>
            {showGroups && <TableCell><GroupField source="groupId" /></TableCell>}
            {team.opponents.map(({ home, away }) => <TableCell numeric>
              {(home + away === 0) ? null : `${home}/${away}`}
            </TableCell>)}
          </TableRow>
        </RecordContextProvider>
      )}
    </TableBody>
  </Table>
}

const useStyles = makeStyles(theme => ({
  status: {
    width: '100%',
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  table: {
    // add margin to minimize scrolling
    marginRight: theme.spacing(15),
  },
  officeHeader: {
    width: theme.spacing(3),
    height: theme.spacing(15),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  rotate: {
    transform: 'translate(25px, 58px) rotate(315deg)',
    width: theme.spacing(1.5),
  },
  rotateInner: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(.5),
  },
  officeCell: {
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  link: {
    display: 'inline-block',
    width: theme.spacing(21),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const OfficeMatchups = ({ draft, existingGames }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const games = getAllGames(draft.games, existingGames)
  const teamIds = dedupe(games.flatMap(game => [game.homeTeamId, game.awayTeamId]))

  const { data: teams = [], loading, error } = useQueryWithStore({
    type: GET_MANY,
    resource: 'teams',
    payload: {
      ids: teamIds,
      _scope: 'Tenant',
    }
  });

  if (error) return <div className={classes.status}>
    {translate('ra.page.error_try_again_refresh')}
  </div>
  if (loading) return <div className={classes.status}>
    <CircularProgress color="primary" variant="indeterminate" />
  </div>

  const teamOfficeMatchups = teams.map(team => {
    const officeMatchups = games.reduce((matchups, game) => {
      if (![game.homeTeamId, game.awayTeamId].includes(team.id)) return matchups;

      if (game.homeTeamId === team.id) {
        const awayTeam = teams.find(team => team.id === game.awayTeamId);
        if (awayTeam) {
          matchups[awayTeam.officeId] = (matchups[awayTeam.officeId] || 0) + 1;
        }
      }

      if (game.awayTeamId === team.id) {
        const homeTeam = teams.find(team => team.id === game.homeTeamId);
        if (homeTeam) {
          matchups[homeTeam.officeId] = (matchups[homeTeam.officeId] || 0) + 1;
        }
      }

      return matchups;
    }, {})

    return {
      teamId: team.id,
      officeMatchups,
    }
  })

  const officeIds = dedupe(teams.map(team => team.officeId))
  return <Table padding="dense" className={classes.table}>
    <TableHead>
      <TableRow>
        <TableCell />
        {officeIds.map(officeId => <TableCell className={classes.officeHeader}>
          <div className={classes.rotate}>
            <span className={classes.rotateInner}>
              <RecordContextProvider value={{ officeId }}>
                <OfficeField source="officeId" classes={{ link: classes.link }} />
              </RecordContextProvider>
            </span>
          </div>
        </TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {teamOfficeMatchups.map(team =>
        <TableRow hover>
          <TableCell component="th" scope="row">
            <RecordContextProvider value={team}>
              <TeamField source="teamId" />
            </RecordContextProvider>
          </TableCell>
          {officeIds.map(officeId => <TableCell numeric className={classes.officeCell}>
            {team?.officeMatchups?.[officeId] || 0}
          </TableCell>)}
        </TableRow>
      )}
    </TableBody>
  </Table>
}

const ByesList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyleType: 'none',
})

const Byes = ({ draft, existingGames }) => {
  const translate = useTranslate();

  const games = getAllGames(draft.games, existingGames)
  const byes = getByes(draft, games)
  const byeLabel = hasRounds(draft) ? translate('resources.drafts.labels.round', 1) : translate('resources.drafts.labels.week')
  const rounds = games?.map(game => game.round)?.filter(Boolean);
  const minRound = (rounds.length ? rounds : [1]).reduce((a, b) => Math.min(a, b));

  if (!byes.some(teams => teams.length > 0)) return <MuiAccordionDetails>{translate('resources.drafts.messages.no_byes')}</MuiAccordionDetails>
  return <Table padding="dense">
    <TableHead>
      <TableRow>
        <TableCell numeric align="right">{byeLabel}</TableCell>
        <TableCell>{translate('resources.drafts.labels.team')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {byes.map((teams, index) =>
        <TableRow>
          <TableCell component="th" scope="row" numeric align="right">{index + minRound}</TableCell>
          <TableCell>{!teams.length
            ? 'None'
            : <ByesList>
              {teams.map(team =>
                <RecordContextProvider value={team} key={team.id}>
                  <li>
                    <TeamField source="teamId" />
                  </li>
                </RecordContextProvider>
              )}
            </ByesList>}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
}

export default ({ draft, existingGames, isOpen, onClose }) => {
  const translate = useTranslate()
  return <Dialog
    scroll="body"
    maxWidth="md"
    open={isOpen}
    onClose={onClose}
  >
    {isOpen && <>
      <DialogTitle>{translate('resources.drafts.labels.analysis')}</DialogTitle>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Heading>{translate('ra.action.summary')}</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Summary draft={draft} existingGames={existingGames} />
        </AccordionDetails>
      </Accordion>

      {existingGames.length ? <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Heading>{translate('resources.drafts.labels.current_matchups')}</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Matchups draft={draft} games={existingGames} />
        </AccordionDetails>
      </Accordion> : null}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Heading>{translate('resources.drafts.labels.new_matchups')}</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Matchups draft={draft} games={draft.games} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Heading>{translate('resources.drafts.labels.office_matchups')}</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <OfficeMatchups draft={draft} existingGames={existingGames} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Heading>{translate('resources.drafts.labels.byes')}</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Byes draft={draft} existingGames={existingGames} />
        </AccordionDetails>
      </Accordion>

      <DialogActions>
        <Button color="primary" onClick={onClose}>{translate('ra.action.close')}</Button>
      </DialogActions>
    </>}
  </Dialog>
}
