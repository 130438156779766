import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { GroupForm } from './GroupForm';

const AddGroupDialog = props =>
  <CreateResourceDialog label="resources.groups.actions.add" resource="groups" basePath="/groups" {...props}>
    <GroupForm />
  </CreateResourceDialog>

const EditGroupDialog = props =>
  <EditResourceDialog label="resources.groups.labels.edit" resource="groups" basePath="/groups" {...props}>
    <GroupForm />
  </EditResourceDialog>

export const AddGroupButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.groups.actions.add')}</Button>
    <AddGroupDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const GroupDatagrid = props => <DialogDatagrid dialog={EditGroupDialog} {...props} />
