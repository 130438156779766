import React, { Fragment, useState } from 'react'
import { Button, FormWithRedirect, useListContext, useRecordContext } from 'react-admin';
import { Dialog, makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import { changeDate } from '@hisports/parsers';

import { InlineDateInput } from '../../../common/inputs/DateInput';

const useStyles = makeStyles(theme => ({
  date: {
    borderRadius: 0
  }
}))

const SetDateButton = ({ selectedIds, ...props }) => {
  const draft = useRecordContext();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onClick = () => {
    if (props.handleClick) props.handleClick();
    setOpen(true)
  };

  const onChange = date => {
    if (props.handleChange) props.handleChange(date);
    setOpen(false);
  };

  const startDate = draft?.options?.startDate;
  const initialValue = startDate && moment.utc(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')

  return <>
    <Button label="resources.drafts.labels.set_date" onClick={onClick} {...props} />
    <Dialog
      open={open}
      maxWidth="lg"
      onEscapeKeyDown={() => setOpen(false)}
      onBackdropClick={() => setOpen(false)}
      PaperProps={{ className: classes.date }}
    >
      <InlineDateInput
        source="date"
        initialValue={initialValue}
        options={{ variant: 'static' }}
        orientation="landscape"
        openTo="date"
        variant="outlined"
        alwaysOn
        onChange={onChange}
      />
    </Dialog>
  </>
}

export default ({ updateGames }) => {
  const draft = useRecordContext();
  const { selectedIds = [], data: games } = useListContext();

  const showSwap = selectedIds.length === 2;
  const selectedGames = selectedIds.map(id => games?.[id]).filter(Boolean);
  const showClearDate = selectedGames.some(game => game && game.date);
  const showMarkActive = selectedGames.some(game => game && game.status === 'Conflict');
  const showMarkConflict = selectedGames.some(game => game && game.status !== 'Conflict');

  const handleSetDateChange = date => {
    const dateSelected = moment.utc(date).format('YYYY-MM-DD')
    const updatedGames = (draft?.games || []).map(game => {
      const gameSelected = selectedIds.find(selectedId => selectedId === game.id)
      return {
        ...game,
        date: gameSelected ? dateSelected : game?.date,
        startTime: gameSelected && game?.startTime ? changeDate(game.startTime, dateSelected, game?.timezone) : game?.startTime,
        endTime: gameSelected && game?.endTime ? changeDate(game.endTime, dateSelected, game?.timezone) : game?.endTime,
      }
    })

    updateGames(updatedGames);
  }

  const handleClearDate = () => {
    const updatedGames = (draft?.games || []).map(game => {
      const gameSelected = selectedIds.find(selectedId => selectedId === game.id)
      return {
        ...game,
        date: gameSelected ? undefined : game?.date,
        startTime: gameSelected ? undefined : game?.startTime,
        endTime: gameSelected ? undefined : game?.endTime,
        arenaId: gameSelected ? undefined : game?.arenaId
      }
    })

    updateGames(updatedGames);
  }

  const handleSwap = () => {
    const game1 = { ...draft.games.find(game => game.id === selectedIds[0]) };
    const game2 = { ...draft.games.find(game => game.id === selectedIds[1]) };

    const updatedGames = draft.games
      .map(game => {
        const updatedGame = { ...game }
        if (game.id === selectedIds[0]) {
          updatedGame.homeTeamId = game2.homeTeamId;
          updatedGame.awayTeamId = game2.awayTeamId;
        } else if (game.id === selectedIds[1]) {
          updatedGame.homeTeamId = game1.homeTeamId;
          updatedGame.awayTeamId = game1.awayTeamId;
        }
        return updatedGame
      })

    updateGames(updatedGames)
  }

  const handleMarkConflicts = () => {
    updateGames(draft.games.map(game => {
      if (!selectedIds.includes(game.id)) return game;
      return { ...game, status: 'Conflict' }
    }))
  }

  const handleMarkActive = () => {
    updateGames(draft.games.map(game => {
      if (!selectedIds.includes(game.id)) return game;
      return { ...game, status: 'Active' }
    }))
  }

  return <FormWithRedirect render={props => <Fragment {...props} />}> {/* eslint-disable-line react/jsx-no-useless-fragment */}
    <SetDateButton color="primary" handleChange={handleSetDateChange} />
    {showClearDate && <Button color="primary" onClick={handleClearDate} label="resources.drafts.labels.clear_date" />}
    {showSwap && <Button color="primary" onClick={handleSwap} label="resources.drafts.labels.swap_matchups" />}
    {showMarkActive && <Button color="primary" onClick={handleMarkActive} label="resources.drafts.labels.set_active" />}
    {showMarkConflict && <Button color="primary" onClick={handleMarkConflicts} label="resources.drafts.labels.set_conflict" />}
  </FormWithRedirect>
}
