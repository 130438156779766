import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useLocale } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { UAParser } from 'ua-parser-js';
import { useSport, useTenant } from '../../http';

const ForeThoughtMobile = React.lazy(() => import('./ForeThoughtMobile'));
const ForeThoughtDesktop = React.lazy(() => import('./ForeThoughtDesktop'));

export const hideForeThought = () => {
  document.body.classList.add("hide-forethought");
};

export const showForeThought = () => {
  document.body.classList.remove("hide-forethought");
};

const useStyles = makeStyles(theme => ({
  block: {
    display: 'block',
  }
}));

const ForeThought = ({ authenticated, fullName }) => {
  const [canShow, setCanShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const timeout = useRef(false);
  const isMobile = useMemo(() => ['mobile', 'console', 'smarttv', 'wearable'].includes(new UAParser()?.getDevice?.()?.type), []);
  const classes = useStyles();
  const locale = useLocale();

  const onForethoughtMessage = useCallback((event) => {
    if (!event.isTrusted) {
      return;
    }

    if (event.origin !== "https://solve-widget.forethought.ai") {
      return;
    }

    if (!event.data) {
      return;
    }

    if (event.data.event === 'forethoughtWidgetError') {
      console.error(event.data.error);
      return;
    }

    // The widget needs to be loaded before we try to open it.
    if (event.data?.event === "forethoughtWidgetLoaded") {
      hideForeThought();
      setCanShow(true);
      return;
    }

    // Makes sure the assistant is open before showing the iframe.
    // Avoids glitchy apparition of the forethought bubble.
    if (event.data?.event === "forethoughtWidgetOpened") {
      document.getElementById("forethought-chat")?.classList.add(classes.block);
      return;
    }

    // The close button is inside the iFrame.
    // We need to listen to the messages to check if the assistant window has been closed.
    if (event.data?.event === "forethoughtWidgetClosed") {
      hideForeThought();
      document.getElementById("forethought-chat")?.classList.remove(classes.block);
      setIsOpen(false);
    }
  }, [classes.block]);

  const tenant = useTenant();
  const sport = useSport();

  const mountScript = () => {
    unmountScript();
    setIsOpen(false);
    setCanShow(false);
    window.addEventListener("message", onForethoughtMessage);

    timeout.current = setTimeout(() => {
      const head = document.head || document.getElementsByTagName("head")[0];
      const forethoughtScript = document.createElement("script");

      forethoughtScript.setAttribute("id", "forethought-script");
      forethoughtScript.setAttribute("src", "https://solve-widget.forethought.ai/embed.js");
      forethoughtScript.setAttribute("type", "application/javascript");
      forethoughtScript.setAttribute("data-api-key", "b912010e-9ccf-4f3c-a49a-241c7622ddb4");
      forethoughtScript.setAttribute("data-ft-Spordle-Client", tenant?.name || "none");
      forethoughtScript.setAttribute("data-ft-Spordle-Sport", sport?.substring(0, 3).toUpperCase() || "none");

      forethoughtScript.setAttribute("data-ft-authenticated", !!authenticated);
      forethoughtScript.setAttribute("data-ft-Embed-Script-Language", locale);
      forethoughtScript.setAttribute("data-ft-Full-Name", fullName);
      forethoughtScript.setAttribute("data-ft-Provided-URL", window.location.href);
      forethoughtScript.setAttribute("data-ft-userType", "parent");
      forethoughtScript.setAttribute("data-ft-workflow-tag", "parent");
      head.appendChild(forethoughtScript);
    }, 10);
  };

  const unmountScript = () => {
    clearTimeout(timeout.current);

    if (window.Forethought) {
      window.Forethought('widget', 'close');
    }

    document.getElementById("forethought-script")?.remove();
    document.getElementById("forethought-chat")?.remove();

    window.removeEventListener("message", onForethoughtMessage);
  };

  const toggleChat = () => {
    if (!window.Forethought) {
      return;
    }
    showForeThought();
    window.Forethought('widget', 'open');
    setIsOpen(true);
  };

  useEffect(() => {
    mountScript();

    return unmountScript;
  }, [locale, authenticated, fullName]); // eslint-disable-line react-hooks/exhaustive-deps

  // The bubble will only be shown when forethought has loaded.
  if (!canShow) {
    return null;
  }

  if (isMobile) {
    return (
      <ForeThoughtMobile isOpen={isOpen} toggleChat={toggleChat} />
    );
  }

  return <ForeThoughtDesktop isOpen={isOpen} toggleChat={toggleChat} />;
};

export default ForeThought;
