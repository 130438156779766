import React from 'react';
import { Datagrid, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  headerRow: {
    display: 'none',
  }
})

export const RulebookGrid = ({ showTarget = false, showParticipant = true, ...props }) => {
  const classes = useStyles();
  return <Datagrid size="medium" rowClick="edit" classes={classes} {...props}>
    <TextField source="name" />
  </Datagrid>
}
