import React from 'react';
import { TextField, ImageField, EditButton, useRecordContext } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import Row from '../../../common/cards/TableRow';
import { isAuthorized } from '../../../common/Authorize';
import InfoCard from '../../../common/cards/InfoCard';
import RegistryLink from '../../../common/RegistryLink';

import { CategoryField } from '../../categories/CategoryField';
import { OfficeField } from '../../offices/OfficeField';
import { SeasonField } from '../../seasons/SeasonField';

export default props => {
  const team = useRecordContext(props);

  const canEditTeam = isAuthorized(team, 'teams', 'edit')
  return <InfoCard
    title="resources.teams.name"
    actions={[
      canEditTeam && <EditButton label="resources.teams.actions.edit" record={team} />,
      <RegistryLink />
    ]}
  >
    <Table>
      <TableBody>
        <Row hidden={!team?.logo}>
          <ImageField source="logo.url" label="Logo" />
        </Row>
        <Row label="resources.teams.fields.HCRId" hidden={!team?.HCRId}>
          <TextField source="HCRId" />
        </Row>
        <Row label="resources.teams.fields.seasonId">
          <SeasonField source="seasonId" />
        </Row>
        <Row label="resources.teams.fields.categoryId">
          <CategoryField source="categoryId" includeGender="full" />
        </Row>
        {isAuthorized(team, "offices", "show") &&
          <Row label="resources.teams.fields.officeId">
            <OfficeField source="officeId" includeType="full" />
          </Row>}
      </TableBody>
    </Table>
  </InfoCard>
}
