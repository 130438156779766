import React, { Fragment } from 'react';
import { RecordContextProvider, SimpleForm, TextInput, useNotify, useRecordContext, useTranslate } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from "@material-ui/core";

import { updateNotes } from '@hisports/scoresheet/src/actions';
import { getAdminNotes, getNotes } from '@hisports/scoresheet/src/selectors';

import { isAuthorized } from '../../../common/Authorize';
import { DialogFormToolbar } from "../../../common/dialogs/DialogForm";
import { useScoresheet, useScoresheetDispatch } from '../ScoresheetContext';

const inputProps = {
  variant: 'outlined',
  size: 'small',
  margin: 'dense',
  fullWidth: true,
}

const dispatchNotes = async (gameId, scoresheetNotes, adminNotes, dispatch, notify) => {
  if (!gameId) return;

  const event = updateNotes(gameId, scoresheetNotes, adminNotes);

  try {
    await dispatch(event);
    notify('resources.scoresheets.messages.successful_save_notes', 'success');
  } catch (e) {
    // catch validation errors caused by the dispatch
    // eslint-disable-next-line no-console
    console.error(e)
    notify('ra.message.error', 'error')
  }
}

const NotesForm = ({ ...props }) => {
  const record = useRecordContext();

  const canEditAdminNotes = isAuthorized(record, 'scoresheets', 'adminNotes')

  return <SimpleForm {...props}>
    <TextInput
      source="scoresheetNotes"
      label="resources.scoresheets.labels.scoresheet_notes"
      helperText="resources.scoresheets.helpers.scoresheet_notes"
      parse={value => value} // empty input should be an empty string
      multiline
      rows={10}
      {...inputProps}
    />
    {canEditAdminNotes && <TextInput
      source="adminNotes"
      label="resources.scoresheets.labels.admin_notes"
      helperText="resources.scoresheets.helpers.admin_notes"
      parse={value => value}
      multiline
      rows={10}
      {...inputProps}
    />}
  </SimpleForm>
}

export const NotesModal = ({ open, setOpen, ...props }) => {
  const game = useRecordContext()
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const dispatch = useScoresheetDispatch();
  const notify = useNotify();
  const notes = useScoresheet(scoresheet => getNotes(scoresheet));
  const adminNotes = useScoresheet(scoresheet => getAdminNotes(scoresheet));

  const hasNotes = notes || adminNotes;

  const onSubmit = async values => {
    await dispatchNotes(game?.id, values.scoresheetNotes, values.adminNotes, dispatch, notify)
    setOpen(false)
  }

  const onCancel = () => setOpen(false);

  return <Dialog open={open} maxWidth="md" fullWidth fullScreen={fullScreen}>
    <DialogTitle>{translate(`resources.scoresheets.labels.${hasNotes ? 'edit_notes_title' : 'add_notes_title'}`)}</DialogTitle>
    <DialogContent>
      <RecordContextProvider value={{ scoresheetNotes: notes, adminNotes }}>
        <NotesForm
          save={onSubmit}
          component={Fragment}
          toolbar={
            <DialogFormToolbar
              submitLabel="ra.action.save"
              cancelLabel="ra.action.cancel"
              onCancel={onCancel}
            />
          }
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}
