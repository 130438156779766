import React, { useContext, useEffect } from 'react';
import moment from 'moment-timezone';

import 'moment/locale/fr';

import usePreferences from '../common/usePreferences';

const LocaleContext = React.createContext([]);

export const getGenericLocale = (locale) => {
  switch (locale) {
    case 'fr':
    case 'fr-ca':
    case 'fr-fr':
      return 'fr';

    case 'en':
    case 'en-ca':
    case 'en-us':
    default:
      return 'en';
  }
}

const getDefaultLocale = () => {
  return window.navigator?.language?.toLowerCase()
    || window.navigator?.userLanguage?.toLowerCase()
    || 'en-ca';
}

export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider = (props) => {
  const state = usePreferences('locale', getGenericLocale(getDefaultLocale()));
  const [ locale, , ready ] = state;

  useEffect(() => {
    moment.locale(locale);
  }, [ locale ])

  if (!ready) return null; // wait for preference to finish loading to avoid moment issues

  moment.locale(locale, {
    week: {
      dow: 0, // first day of week is Sunday
    }
  });

  return <LocaleContext.Provider value={state} {...props} />
}
