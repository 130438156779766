import React from 'react';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab'
import moment from 'moment-timezone';

export default ({ games = [] }) => {
  const count = games
    .filter(game => moment().isAfter(game.startTime, 'day'))
    .length

  if (count <= 0) return null;

  const translate = useTranslate()
  const alert = translate('resources.drafts.alerts.dated_in_past', count);
  return <Alert severity="warning">{alert}</Alert>
}
