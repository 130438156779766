import React from 'react';
import { Datagrid, ReferenceField, useListContext } from 'react-admin';

import DateRangeField from '../../common/fields/DateRangeField';

import { ScheduleField } from '../schedules/ScheduleField';
import { GroupField } from '../groups/GroupField';
import { OfficeField } from '../offices/OfficeField';

export default ({ showOffice = true, ...props }) => {
  const { ids = [], data } = useListContext(props);
  const showGroup = ids?.some(id => data?.[id]?.groupId != null)

  return <Datagrid size="medium" {...props}>
    <ScheduleField source="scheduleId" label="resources.scheduleteams.fields.scheduleId" sortable={false} includeCategory="inline" includeType="inline" />
    {showGroup && <GroupField source="groupId" sortable={false} />}
    <ReferenceField source="scheduleId" reference="schedules" label="resources.schedules.labels.dates" link={false} sortable={false} allowEmpty>
      <DateRangeField source="startDate" startSource="startDate" endSource="endDate" />
    </ReferenceField>
    {showOffice && <OfficeField source="officeId" sortable={false} includeType="inline" />}
  </Datagrid>
}
