import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { kebabCase } from 'lodash';

import { DurationGrid } from './DurationGrid';
import { AddDurationButton } from './DurationModal';

import { ArrayGrid } from '../ArrayGrid';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const Durations = ({ source }) => {
  const { values: { durations, infractions } } = useFormState();
  const { change, mutators } = useForm();

  const onSave = duration => {
    if (!duration.id) {
      duration.id = kebabCase(duration.name);
    }

    change(source, {
      ...durations,
      [duration.id]: { ...durations[duration.id], ...duration }
    })
  }

  const onDelete = duration => {
    // remove duration
    delete durations[duration.id];
    change(source, { ...durations })

    // remove infraction choices that contains the duration
    infractions.forEach((infraction, index) => {
      const initialLength = infraction?.choices?.length || 0;
      infraction.choices = (infraction?.choices || []).filter(choice => !(choice?.durations || []).includes(duration.id))

      if (infraction.choices.length !== initialLength) {
        mutators.update('infractions', index, infraction)
      }
    })
  }

  return <>
    <ArrayGrid source={source}>
      <DurationGrid
        save={onSave}
        canDelete
        onDelete={onDelete}
        {...inputProps}
      />
    </ArrayGrid>
    <AddDurationButton save={onSave} />
  </>
}
