import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { OfficialConflictGrid } from '../../officialConflicts/OfficialConflictGrid';
import { AddOfficialConflictButton } from '../../officialConflicts/OfficialConflictModal';

export default props => {
  const participant = useRecordContext(props);
  const seasonId = useSeason();

  const showOfficials = isAuthorized(participant, 'officialConflicts', 'list')
  const canAddOfficial = isAuthorized(participant, 'officialConflicts', 'create')

  if (!showOfficials) return null;
  return <ListCard
    title="resources.officialConflicts.labels.card.title"
    reference="officialConflicts"
    target="participants"
    filter={{ seasonId }}
    addButton={canAddOfficial && <AddOfficialConflictButton initialValues={{ participantId: participant?.id, targetType: 'Team' }} />}
  >
    <OfficialConflictGrid />
  </ListCard>
}
