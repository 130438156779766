import React, { Fragment, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Tooltip, Checkbox, FormControlLabel } from '@material-ui/core'
import { Clear } from '@material-ui/icons';

import { apiClient, useParticipant } from '../../../../../../http';
import { useRichTranslate } from '../../../../../../common/useRichTranslate';
import { isAuthorized } from '../../../../../../common/Authorize';
import { ParticipantField } from '../../../../../participants/ParticipantField';

import { useAssignmentContext } from '../AssignmentContext';

const remove = (gameId, participantId, isNoShow = false, officialId) =>
  apiClient(`/games/${gameId}/removeAssignment`, {
    method: 'POST',
    data: {
      participantId,
      isNoShow,
      officialId,
    },
  })

const ConfirmDialog = ({ assignment, official, assigner, open, onClose, game }) => {
  const [ checked, setChecked ] = useState(false);
  const translate = useRichTranslate()
  const participantId = useParticipant()
  const { meta } = useAssignmentContext();
  const { isAdhoc } = meta || {};

  const handleCancel = () => onClose(false);
  const handleConfirm = () => onClose(true, checked);
  const handleSelect = () => setChecked(!checked);

  const showNoShow = game?.isApproved && !isAdhoc && assignment?.status !== 'no-show';
  const officialName = <ParticipantField record={official} source="participantId" variant="inherit" link={false} />

  const message = official.participantId !== participantId
    ? translate('resources.games.messages.assignment.removed_from_game', { name: official.participant.fullName })
    : translate('resources.games.messages.assignment.request_removed')
  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="xs">
    <DialogTitle>{translate('ra.action.confirm_removal')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {message}
      </DialogContentText>
      {showNoShow && <DialogContentText>
        <FormControlLabel
          label={translate('resources.games.messages.assignment.mark_as_no_show', { name: officialName })}
          control={<Checkbox color="primary" checked={checked} onChange={handleSelect} />}
        />
      </DialogContentText>}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary">{translate('ra.action.remove')}</Button>
    </DialogActions>
  </Dialog>
}

export default ({ game, assignment, official, disabled, onClose }) => {
  const [ removed, setRemoved ] = useState(false);
  const [ showConfirm, setConfirm ] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const participantId = useParticipant();
  const { meta } = useAssignmentContext();
  const { isUnassigned } = meta || {};
  const isSelf = official?.participantId === participantId;

  if (!game || !assignment || !official) return null;
  if (!official.participantId) return null;

  const canAssign = isAuthorized(game, 'games', 'assign');

  const handleConfirm = (confirmed, isNoShow) => {
    setConfirm(false);
    if (!confirmed) return;

    setRemoved(true)
    remove(game.id, official.participantId, isNoShow, !isUnassigned ? assignment.id : null).then(res => {
      onClose(assignment, isSelf && !canAssign ? participantId : null)
      const message = canAssign
        ? translate('resources.games.notifications.assignment.remove_official_from_game', { number: game.number })
        : translate('resources.games.notifications.assignment.request_removed_from_game', { number: game.number })
      notify(message, 'success')
    }).catch(err => {
      setRemoved(false);
      const message = err?.response?.data?.error?.message
      notify(message || 'ra.page.error_try_again', 'error')
    })
  }

  const tooltip = canAssign
    ? translate('resources.games.messages.assignment.remove_official_from_assignment')
    : translate('resources.games.messages.assignment.remove_request_from_game')
  return <>
    <Tooltip title={tooltip}>
      <Button
        color="secondary"
        startIcon={<Clear fontSize="small" />}
        disabled={disabled || removed}
        onClick={() => setConfirm(true)}
      >
        {translate('ra.action.remove')}
      </Button>
    </Tooltip>
    <ConfirmDialog assignment={assignment} official={official} assigner={canAssign} open={showConfirm} onClose={handleConfirm} game={game} />
  </>
}
