import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { filterOfficialsByPositionType } from '@hisports/scoresheet/src/util';

import { apiClient } from '../../../../../http';

const OfficialsContext = createContext();

export const OfficialsContextProvider = ({ gameId, assignments, listId, children }) => {
  const [officials, setOfficials] = useState({}); // { [listId]: { [officeId]: { loading: bool, data: [official], error: '' } } }

  const value = useMemo(() => {
    const listKey = listId || 'noListId';

    const fetchOfficials = (abortControllerSignal, officeId) => {
      if (!abortControllerSignal || !officeId) throw new Error('missing abortControllerSignal or officeId');

      if (officials?.[listKey]?.[officeId]) return; // data already fetched
      setOfficials((data = {}) => ({ ...data, [listKey]: { ...data[listKey], [officeId]: { loading: true } } }))

      apiClient(`/games/${gameId}/availableOfficials`, {
        method: 'GET',
        params: { officeId, listId, skipFlags: false },
        signal: abortControllerSignal
      }).then(({ data: officials }) => {
        setOfficials((data = {}) => ({ ...data, [listKey]: { ...data[listKey], [officeId]: { loading: false, data: officials, error: '' } } }))
      }).catch(e => {
        setOfficials((data = {}) => ({ ...data, [listKey]: { ...data[listKey], [officeId]: { loading: false, data: [], error: e } } }))
      })
    }

    return {
      officials: officials[listKey],
      fetchOfficials,
      assignments,
    };
  }, [ gameId, officials, assignments, listId ])

  return (
    <OfficialsContext.Provider value={value}>
      {children}
    </OfficialsContext.Provider>
  );
};

export const useOfficialsContext = (assignment, mainAssignOfficeId) => {
  const contextValues = useContext(OfficialsContext)
  if (!contextValues) throw new Error('useOfficialsContext must be used within an OfficialsContextProvider');

  const { officials, fetchOfficials, assignments = [] } = contextValues;
  const officeId = assignment?.officeId || mainAssignOfficeId
  if (!officeId) throw new Error('useOfficialsContext needs officeId');

  useEffect(() => {
    const abortController = new AbortController();

    if (!officials?.[officeId]) {
      fetchOfficials(abortController.signal, officeId);
    }

    return () => {
      if (!officials?.[officeId] || officials[officeId].loading) return;
      abortController.abort();
    }
  }, [officials, fetchOfficials, officeId]);

  const participantIds = assignments.map(assignment => assignment.participantId).filter(Boolean)
  const scopedOfficials = officials?.[officeId] || null;
  const data = filterOfficialsByPositionType(scopedOfficials?.data, assignment)
    .filter(official => !participantIds.includes(official.participantId))

  return {
    officials: data || [],
    isLoading: scopedOfficials?.loading,
    error: scopedOfficials?.error,
  }
};
