import React from 'react';
import { TextInput, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  arrayContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
}));

export const I18nInput = props => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values: { i18n } } = useFormState();

  const locale = props?.source.split('.')[1];

  return <div className={classes.inputContainer}>
    <Typography>{translate(`ra.input.i18n.values.${locale}`)}</Typography>
    <TextInput
      {...props}
      label="ra.input.i18n.label"
      helperText=""
      format={() => i18n?.[locale]?.name}
      parse={(v) => ({ name: v })}
    />
  </div>
};

export const ArrayI18nInput = props => {
  const classes = useStyles();
  return <div className={classes.arrayContainer}>
    <I18nInput source="i18n.en" {...props} />
    <I18nInput source="i18n.fr" {...props} />
  </div>
}


