import React from 'react';
import { makeStyles, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Checkbox, Chip, alpha } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { isPlayer } from '@hisports/scoresheet/src/util';
import { FLAG_COLORS } from '@hisports/scoresheet/src/constants';
import { FF_PITCHERS } from '@hisports/common/featureFlags';

import { useFlag } from '../../../http';

import { EditMemberButton } from './EditMember';
import { MemberFlagsButton } from './MemberFlags';
import { useMemberItem, MemberItemContextProvider } from './MemberItemContext';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('md')]: {
      marginLeft: theme.spacing(0),
    }
  },
  twoActions: {
    paddingRight: theme.spacing(12)
  },
  listItemActions: {
    display: 'flex',
  },
  flaggedBgWarning: {
    backgroundColor: alpha(theme.palette.warning[50], .4),
    borderTop: '1px solid white',
  },
  flaggedBgError: {
    backgroundColor: alpha(theme.palette.error[50], .4),
    borderTop: '1px solid white',
  },
}));

const getMemberStatus = (member, translate, hideSuspension = false) => {
  const status = [];
  if (!member) return [];
  if (member.isCaptain) status.push(translate('resources.members.fields.isCaptain'));
  if (member.isAlternate) status.push(translate('resources.members.fields.isAlternate'));
  if (member.isAffiliate) status.push(translate('resources.members.fields.isAffiliate'));
  if (member.isOverage) status.push(translate('resources.members.fields.isOverage'));
  if (member.isUnderage) status.push(translate('resources.members.fields.isUnderage'));
  if (member.isStarter) status.push(translate('resources.members.fields.isStarter'));
  if (member.suspension && !hideSuspension) status.push(`${translate('resources.members.labels.suspended')}${member.suspension.game && member.suspension.total ? ` (${member.suspension.game}/${member.suspension.total})` : ''}`);
  if (member.isReleased) status.push(translate('resources.members.fields.isReleased'));
  return status.join(' / ');
}

export const getMemberDescription = (member, translate, hideSuspension = false) => [
  member.positions.map(position => translate(`resources.members.values.positions.${position}`)).join(', '),
  getMemberStatus(member, translate, hideSuspension),
  member.battingOrder && `${translate(`resources.members.fields.batter`)} #${member.battingOrder}`
].filter(Boolean).join(' / ');

export const ThrowsInningsStatus = ({ status, isPlayer, ...props }) => {
  const translate = useTranslate();
  if (!isPlayer || !status) return []

  let label = null

  if (status.hasInningsRest || status.hasThrowsRest) {
    label = `${translate('components.throws_innings.labels.resting')}`
  } else if (status.consecutiveDays >= 2 || (status.dailyInningsRemaining != null && status.dailyInningsRemaining <= 0) || (status.weeklyInningsRemaining != null && status.weeklyInningsRemaining <= 0)) {
    label = `${translate('components.throws_innings.labels.cannot_throw')}`
  } else if (!status.weeklyInningsRemaining && status.dailyInningsRemaining) {
    label = `${translate('components.throws_innings.labels.innings_remaining', { count: status.dailyInningsRemaining })}`
  } else if (status.weeklyInningsRemaining && !status.dailyInningsRemaining) {
    label = `${translate('components.throws_innings.labels.innings_remaining', { count: status.weeklyInningsRemaining })}`
  } else if (status.weeklyInningsRemaining && status.dailyInningsRemaining) {
    if (status.dailyInningsRemaining < status.weeklyInningsRemaining) {
      label = `${translate('components.throws_innings.labels.innings_remaining', { count: status.dailyInningsRemaining })}`
    } else {
      label = `${translate('components.throws_innings.labels.innings_remaining', { count: status.weeklyInningsRemaining })}`
    }
  }
  if (!label) return null

  return <Chip size="small" label={label} title={label} variant="outlined" color="primary" {...props} />
}

export const getThrowsInningsCount = (gameDate, throwsInnings, translate) => {
  if (!throwsInnings?.length) return []
  const throwsInningsCount = []

  const throwsCount = {
    today: throwsInnings.filter(throwsInning => throwsInning.gameDate === gameDate).map(throwsInning => throwsInning.throws).reduce((a = 0, b = 0) => a + b, 0),
    weekly: throwsInnings.map(throwsInning => throwsInning.throws).reduce((a, b) => a + b, 0)
  }

  const inningsCount = {
    today: throwsInnings.filter(throwsInning => throwsInning.gameDate === gameDate).map(throwsInning => throwsInning.inningsPitched).reduce((a = 0, b = 0) => a + b, 0),
    weekly: throwsInnings.map(throwsInning => throwsInning.inningsPitched).reduce((a = 0, b = 0) => a + b, 0)
  }

  if (throwsCount.today) throwsInningsCount.push(`${translate('components.throws_innings.labels.throws_today')}: ${throwsCount.today}`)
  if (throwsCount.weekly) throwsInningsCount.push(`${translate('components.throws_innings.labels.throws_weekly')}: ${throwsCount.weekly}`)
  if (inningsCount.today) throwsInningsCount.push(`${translate('components.throws_innings.labels.innings_today')}: ${inningsCount.today}`)
  if (inningsCount.weekly) throwsInningsCount.push(`${translate('components.throws_innings.labels.innings_weekly')}: ${inningsCount.weekly}`)
  if (!throwsInningsCount?.length) return []

  return throwsInningsCount.join(' / ')
}

const MemberListItem = () => {
  const translate = useTranslate();
  const isEnabled = useFlag();
  const classes = useStyles();

  const { game, member, teamId, memberValidation, flagNames, flagColor, handleSelect, selected, hasSuspension, isExtra, disabled, loading, isScorekeeper, nonSelectable } = useMemberItem();

  const primary = member.number ? `#${member.number} ${member.participant.fullName}` : member.participant.fullName;
  const secondary = getMemberDescription(member, translate, true)
  const throwsInningsCount = getThrowsInningsCount(game.date, memberValidation?.throwsInnings, translate);

  const missingNumber = isPlayer(member) && !member.number;
  const incomplete = hasSuspension || missingNumber;

  const isClickable = !disabled && !loading && (!nonSelectable || selected)
  const showEditButton = selected && !disabled && (!nonSelectable || selected)
  const showFlag = !!flagNames.length

  let className;
  if ([FLAG_COLORS.RED, FLAG_COLORS.RED_ORANGE].includes(flagColor)) {
    className = classes.flaggedBgError
  } else if (flagColor === FLAG_COLORS.ORANGE) {
    className = classes.flaggedBgWarning;
  }

  return <ListItem dense button={isClickable} onClick={isClickable && handleSelect} className={className}>
    {!disabled && <ListItemIcon>
      <Checkbox
        color={!incomplete ? "primary" : 'default'}
        checked={selected}
        tabIndex={-1}
        disableRipple
        disabled={disabled || loading || (nonSelectable && !selected)}
      />
    </ListItemIcon>}
    <ListItemText
      primary={primary}
      secondary={<>
        {secondary}
        <br />
        {isEnabled(FF_PITCHERS) && <>
          {throwsInningsCount}
          <ThrowsInningsStatus isPlayer={isPlayer(member)} status={memberValidation?.throwsInningsStatus} classes={{ root: classes.chip }} />
        </>}
      </>
      }
    />
    <ListItemSecondaryAction className={classes.listItemActions}>
      {showFlag && <MemberFlagsButton flagNames={flagNames} flagColor={flagColor} member={member} edge={!showEditButton && "end"} />}
      {showEditButton && <EditMemberButton teamId={teamId} member={member} isExtra={isExtra} isScorekeeper={isScorekeeper} disabled={loading} />}
    </ListItemSecondaryAction>
  </ListItem>
}

export const MemberItem = ({ member, teamId, disabled, isScorekeeper, type }) =>
  <MemberItemContextProvider member={member} teamId={teamId} disabled={disabled} isScorekeeper={isScorekeeper} type={type}>
    <MemberListItem />
  </MemberItemContextProvider>
