import React from 'react';
import { useRecordContext } from 'react-admin';
import { FilterList } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { ListGrid } from '../../lists/ListGrid';
import { AddListButton } from '../../lists/ListModal';
import ImportLists from '../../lists/dialogs/officials';
import { useOfficeAssignSettings } from '../../officeAssignSettings';

export default props => {
  const office = useRecordContext(props);

  const showLists = isAuthorized(office, 'lists', 'list')
  const canAddLists = isAuthorized(office, 'lists', 'create')
  const canImportMembers = isAuthorized(office, 'lists', 'import')

  const officeId = office?.id
  const officeAssignSettings = useOfficeAssignSettings(officeId, true)

  const listFilters = officeAssignSettings?.data?.effectiveOfficialsList ? { effectiveOffices: true, includeParents: false, _scope: 'Parents' } : { officeId }
  const addButton = canAddLists && <AddListButton initialValues={{ officeId }} officeId={officeId} />
  const actions = [
    canImportMembers && <ImportLists officeId={officeId} size="small" />,
  ].filter(Boolean)

  if (!showLists) return null;
  return <ListCard
    title="resources.lists.labels.official"
    subtitle="resources.lists.labels.card.subtitle"
    icon={<FilterList />}
    collapsable
    reference="lists"
    target="officeId"
    filter={listFilters}
    sort={{ field: 'name', order: 'ASC' }}
    addButton={addButton}
    actions={actions}
  >
    <ListGrid rowClick={false} showOffice />
  </ListCard>
}
