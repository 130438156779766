import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { NumberInput, RecordContextProvider, SimpleForm, useNotify, useRecordContext, useTranslate } from 'react-admin';

import { getScore } from '@hisports/scoresheet/src/selectors';
import { saveScore } from '@hisports/scoresheet/src/actions';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { useSport } from '../../../http';

import { useIsHomeFirst } from '../../events/EventViewSettings';

import { useMeta, useScoresheet, useScoresheetDispatch } from '../ScoresheetContext';

import { TeamField } from '../../teams/TeamField';

const useStyles = makeStyles(theme => ({
  teams: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  label: {
    paddingLeft: theme.spacing(0.5),
  },
  input: {
    marginTop: theme.spacing(1),
  }
}))

const inputProps = {
  variant: 'outlined',
  size: 'small',
  margin: 'dense',
  fullWidth: true,
}

const validate = values => {
  const errors = {};

  if (values.homeScore === null || values.homeScore === undefined) errors.homeScore = 'ra.validation.required';
  if (values.awayScore === null || values.awayScore === undefined) errors.awayScore = 'ra.validation.required';

  if (values.homeScore && !Number.isInteger(values.homeScore)) errors.homeScore = 'ra.validation.whole';
  if (values.awayScore && !Number.isInteger(values.awayScore)) errors.awayScore = 'ra.validation.whole';

  return errors;
}

const dispatchScore = async (game, homeScore = 0, awayScore = 0, dispatch, notify) => {
  const dispatches = [
    saveScore(game.id, { [game.homeTeamId]: homeScore, [game.awayTeamId]: awayScore })
  ]
  const results = await Promise.allSettled(dispatches.map(event => {
    try {
      return dispatch(event);
    } catch (e) {
      // catch validation errors caused by the dispatches
      // eslint-disable-next-line no-console
      console.error(e)
      return Promise.reject(e)
    }
  }))

  const isSuccessful = (results || []).every(result => result.status === 'fulfilled')
  const message = isSuccessful ? 'resources.scoresheets.messages.successful_save_score' : 'ra.message.error';
  const notificationType = isSuccessful ? 'success' : 'error';

  notify(message, notificationType)
}

const ScoreEditForm = ({ game, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const isHomeFirst = useIsHomeFirst();

  return <SimpleForm {...props} {...inputProps} validate={validate}>
    <RecordContextProvider value={game}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12}>
          <Grid container spacing={2} fullWidth>
            <Grid item xs={8} sm={9}>
              <div className={classes.teams}>
                <TeamField source={isHomeFirst ? "homeTeamId" : "awayTeamId"} link={false} />
                <Typography className={classes.label} variant="body2" color="textSecondary" display="inline">{`(${translate(`resources.games.labels.${isHomeFirst ? 'home' : 'away'}`)})`}</Typography>
              </div>
            </Grid>
            <Grid item xs={4} sm={3}>
              <NumberInput source={isHomeFirst ? "homeScore" : "awayScore"} label="resources.games.labels.score" className={classes.input} min={0} max={999} {...inputProps} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} fullWidth>
            <Grid item xs={8} sm={9}>
              <div className={classes.teams}>
                <TeamField source={isHomeFirst ? "awayTeamId" : "homeTeamId"} link={false} />
                <Typography className={classes.label} variant="body2" color="textSecondary" display="inline">{`(${translate(`resources.games.labels.${isHomeFirst ? 'away' : 'home'}`)})`}</Typography>
              </div>
            </Grid>
            <Grid item xs={4} sm={3}>
              <NumberInput source={isHomeFirst ? "awayScore" : "homeScore"} label="resources.games.labels.score" className={classes.input} min={0} max={999} {...inputProps} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RecordContextProvider>
  </SimpleForm>
}

export const ScoreEditModal = ({ open, setOpen, ...props }) => {
  const translate = useTranslate();
  const game = useRecordContext();
  const dispatch = useScoresheetDispatch();
  const notify = useNotify();
  const sport = useSport();
  const { meta } = useMeta();
  const { maxScoreDiff, scoringMode } = meta?.policies || {};
  const homeScore = useScoresheet(scoresheet => getScore(scoresheet, sport, { teamId: game.homeTeamId, maxScoreDiff, scoringMode }));
  const awayScore = useScoresheet(scoresheet => getScore(scoresheet, sport, { teamId: game.awayTeamId, maxScoreDiff, scoringMode }));

  const onSubmit = async (values) => {
    await dispatchScore(game, values.homeScore, values.awayScore, dispatch, notify);

    setOpen(false)
  }

  const onCancel = () => setOpen(false);

  if (!open || !game) return null;

  return <Dialog open={open} maxWidth="xs">
    <DialogTitle>{translate('resources.scoresheets.labels.edit_score')}</DialogTitle>
    <DialogContent>
      <RecordContextProvider value={{ homeScore, awayScore }}>
        <ScoreEditForm
          game={game}
          validate={validate}
          save={onSubmit}
          component={Fragment}
          toolbar={
            <DialogFormToolbar
              submitLabel="ra.action.save"
              cancelLabel="ra.action.cancel"
              onCancel={onCancel}
            />
          }
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}
