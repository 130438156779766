import React from 'react';
import { ReferenceField } from 'react-admin';

import { TextLocaleField } from '../../common/fields/TextLocaleField';

export const RuleSectionField = ({ variant = 'body2', ...props }) => {
  return <ReferenceField basePath="/ruleSections" resource="ruleSections" reference="ruleSections" {...props} link={false}>
    <TextLocaleField source="name" variant={variant} />
  </ReferenceField>
}

RuleSectionField.defaultProps = {
  addLabel: true,
}
