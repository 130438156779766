import React from 'react';
import { useTranslate } from 'react-admin';
import { sortBy } from 'lodash';

import { getLineupMembers, getLineupPlayers } from '@hisports/scoresheet/src/selectors';

import { EnumInput } from '../../../common/inputs/EnumInputs';
import { useSport } from '../../../http';

import { useScoresheet } from '../ScoresheetContext';
import { getMemberDescription } from './MemberItem';

export const LineupInput = ({ teamId, exclude = [], excludeStaff = false, ...props }) => {
  const translate = useTranslate();
  const sport = useSport();
  const lineup = useScoresheet(scoresheet => excludeStaff ? getLineupPlayers(scoresheet, { teamId, sport }) : getLineupMembers(scoresheet, { teamId, sport }));

  const choices = sortBy(lineup, ['number', 'participant.fullName'])
    .filter(member => Array.isArray(exclude) ? !exclude.includes(member.participant.id) : member.participant.id !== exclude)
    .map(member => {
      const { id } = member.participant;
      const name = member.number ? `#${member.number} ${member.participant.fullName}` : member.participant.fullName;
      const description = getMemberDescription(member, translate);
      return { id, name, description };
    })

  return <EnumInput {...props} choices={choices} optionDescription="description" />
}
