import React from 'react';
import { makeStyles } from '@material-ui/styles';

import FunctionField from '../../common/fields/FunctionField'

import { AddressDatagrid } from './AddressModal';

const useStyles = makeStyles({
  headerRow: {
    display: 'none',
  },
  addressField: {
    whiteSpace: 'pre-line',
  }
})

const AddressField = props =>
  <FunctionField {...props} render={address => {
    if (!address.address) return;
    return [
      address.label,
      address.address,
      address.address2,
      [address.city, address.province].filter(Boolean).join(', '),
      address.postal
    ].filter(Boolean).join('\n')
  }} />

const TypeField = props =>
  <FunctionField {...props} render={address => {
    if (!address.isPrimary) return address.type;
    return `${address.type} (Primary)`
  }} />

export const AddressGrid = props => {
  const classes = useStyles();

  return <AddressDatagrid size="medium" classes={classes} {...props}>
    <TypeField source="isPrimary" />
    <AddressField source="address" className={classes.addressField} />
  </AddressDatagrid>
}
