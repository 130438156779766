import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { OfficeGrid } from '../../offices/OfficeGrid';
import { AddOfficeButton } from '../../offices/OfficeModal';

export default props => {
  const office = useRecordContext(props);
  const canAddOffice = isAuthorized(office, 'offices', 'create');

  return <ListCard
    title="resources.offices.labels.card.title"
    reference="offices"
    target="parentId"
    filter={{ type: { nin: ['League', 'Tournament', 'Cup'] } }}
    sort={{ field: 'name', order: 'ASC' }}
    addButton={canAddOffice && <AddOfficeButton initialValues={{ parentId: office?.id }} />}
    perPage={10}
    rowsPerPageOptions={[10, 25, 50, 100]}
  >
    <OfficeGrid rowClick="show" />
  </ListCard>
}
