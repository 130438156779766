import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';

export default ({
  open,
  setOpen,
  handleConfirm,
  title,
  text,
}) => {
  const translate = useTranslate();

  const onClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  }

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    {title && <DialogTitle>{title}</DialogTitle>}
    {text && <DialogContent>
      <DialogContentText>
        {text}
      </DialogContentText>
    </DialogContent>}
    <DialogActions>
      <Button onClick={onClose} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary">{translate('ra.action.confirm')}</Button>
    </DialogActions>
  </Dialog>
}
