import React from 'react';
import { TextInput, NumberInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import { InlineDateInput } from '../../common/inputs/DateInput';
import { ProfileDexterityEnumInput } from '../../common/inputs/EnumInputs';
import MaskedTextInput from '../../common/inputs/MaskedTextInput';
// import AvatarInput from '../../common/inputs/AvatarInput';
import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput';

import { ParticipantInput } from '../participants/ParticipantInput';

const heightMask = value => {
  if (/\d' 1[0-1]"/.test(value)) return [/\d/, '\'', ' ', /[1]/, /[0-1]/, '"'];
  return [/\d/, '\'', ' ', /\d/, '"'];
}

const inputProps = {
  resource: 'profiles',
  basePath: '/profiles',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const ProfileForm = props =>
  <SimpleForm toolbar={<Toolbar />} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <ParticipantInput source="id" label="resources.participants.fields.name" disabled {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InlineDateInput source="birthdate" autoFocus {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput source="hometown" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <MaskedTextInput source="height" maskOptions={{ mask: heightMask }} {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberInput source="weight" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <ProfileDexterityEnumInput source="dexterity" allowEmpty {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <SwitchInput source="isPublic" helperText="resources.profiles.helpers.isPublic" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>

// <Grid item xs={12}>
//   <AvatarInput {...props} source="picture" label="Upload a new picture" {...inputProps} />
// </Grid>
