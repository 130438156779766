import React from 'react';
import { EditButton, useTranslate, useRecordContext } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import InfoCard from '../../../common/cards/InfoCard';
import Row from '../../../common/cards/TableRow';
import TimeRangeField from '../../../common/fields/TimeRangeField';
import { isAuthorized } from '../../../common/Authorize';
import UserTextField from '../../../common/fields/UserTextField';
import { EnumField } from '../../../common/fields/EnumField';

import { SurfaceField } from '../../surfaces/SurfaceField';
import { TeamsField } from '../../teams/TeamsField';
import { OfficeField } from '../../offices/OfficeField';

import PracticeStatusModal from '../PracticeStatusModal';

export default props => {
  const practice = useRecordContext(props);
  const translate = useTranslate();

  const canEditPractice = isAuthorized(practice, 'practices', 'edit');
  const canChangeStatus = isAuthorized(practice, 'practices', 'changeStatus')
  const type = translate(`resources.practices.values.type.${practice?.type}`, { _: practice?.type }) || 'resources.practices.labels.card.name'

  return <InfoCard
    title={type}
    actions={[
      canEditPractice && <EditButton label="resources.practices.actions.edit" record={practice} />,
      canChangeStatus && <PracticeStatusModal />
    ]}
  >
    <Table>
      <TableBody>
        <Row label="resources.practices.fields.teamIds">
          {practice?.teamIds?.length ? <TeamsField source="teamIds" includeCategory="inline" /> : translate('resources.practices.labels.tbd')}
        </Row>
        <Row label="resources.practices.fields.officeId">
          <OfficeField source="officeId" />
        </Row>
        <Row label="ra.date.name">
          <TimeRangeField startSource="startTime" endSource="endTime" showDate showLocal />
        </Row>
        <Row label="resources.practices.fields.location">
          <SurfaceField source="arenaId" includeAttributes="full" includeCity="full" />
        </Row>
        <Row label="resources.practices.fields.status">
          <EnumField source="status" />
        </Row>
        <Row hidden={!practice?.comments}>
          <UserTextField source="comments" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
