import React from 'react';
import { ReferenceInput, ReferenceArrayInput, useLocale } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { translateApiProperty } from '@hisports/common';

const filterType = name => ({ name })

export const InfractionTypeSuggestion = ({ suggestion: type, query, isHighlighted, ...props }) => {
  const locale = useLocale();

  const primary = translateApiProperty(type, 'name', locale);
  const secondary = [
    translateApiProperty(type, 'abbreviation', locale),
    translateApiProperty(type, 'description', locale)
  ].filter(Boolean).join(' - ')
  return <AutocompleteSuggestion primary={primary} secondary={secondary} />
}

export const InfractionTypeInput = ({ helperText, multiple, ...props }) => {
  const locale = useLocale();

  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="infractionTypes"
    filterToQuery={filterType}
    sort={{ field: ['order', 'name'], order: ['ASC', 'ASC'] }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText={section => translateApiProperty(section, 'name', locale)}
      optionDescription="description"
      suggestionComponent={InfractionTypeSuggestion}
      filterKeys={['name', 'abbrevation', 'description']}
      disableSort
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
