import React from 'react';
import { TextField } from 'react-admin';

import Toolbar from '../../common/ra/Toolbar';

import { OfficeField } from '../offices/OfficeField';
import { ListDatagrid } from './ListModal';

export const ListGrid = ({ showOffice = false, ...props }) => {
  return <ListDatagrid size="medium" toolbar={<Toolbar />} {...props}>
    <TextField source="name" />
    {showOffice && <OfficeField source="officeId" allowEmpty />}
  </ListDatagrid>
}
