import React, { useState } from 'react';
import { ArrayInput, useRecordContext, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Card, CardContent, CardActions, Button } from '@material-ui/core';
import { Add, PlaylistAdd, Shuffle } from '@material-ui/icons';
import { shuffle, isEmpty, isEqual } from 'lodash';

import SortedFormIterator from '../../../common/ra/SortedFormIterator';
import { useWizard } from '../../../common/Wizard';

import { TeamInput } from '../../teams/TeamInput';

import { TeamImportDialog } from '../../schedules/dialogs/TeamImportDialog';
import CardHeader from '../common/CardHeader';

const validate = values => {
  const errors = {}
  errors.teams = values.teams.map((team, index) => {
    const error = {};
    if (!team.teamId) {
      error.teamId = 'ra.validation.required'
    } else if (values.teams.slice(0, index).some(({ teamId }) => teamId === team.teamId)) {
      error.teamId = 'ra.validation.duplicate'
    }
    return error;
  })
  return errors;
}

const inputProps = {
  resource: 'drafts',
  basePath: '/drafts',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const TeamImportButton = ({ label, officeId, scheduleIdForGroupInput, ...props }) => {
  const draft = useRecordContext();
  const { mutators } = useForm();
  const { values: { scheduleId, groupId } } = useFormState();
  const [modalOpen, setModalOpen] = useState(false);

  const onSubmit = (values = {}) => {
    const { teamIds = [], groupId } = values;
    const teams = [...(draft?.teams || [])]

    teamIds.forEach(teamId => {
      if (teams.some(team => team.teamId === teamId)) return;
      const emptyTeamSlotIndex = teams.findIndex(team => isEmpty(team))
      if (emptyTeamSlotIndex >= 0) {
        teams[emptyTeamSlotIndex] = { teamId, groupId }
        mutators.update('teams', emptyTeamSlotIndex, { teamId, groupId })
      } else {
        teams.push({ teamId, groupId })
        mutators.push('teams', { teamId, groupId })
      }
    })

    setModalOpen(false);
  }

  return <>
    <Button onClick={() => setModalOpen(true)} color="primary" startIcon={<PlaylistAdd />} {...props}>{label}</Button>
    <TeamImportDialog
      title={label}
      isOpen={modalOpen}
      onSubmit={onSubmit}
      onClose={() => setModalOpen(false)}
      initialValues={{ officeId, scheduleId, groupId }}
      scheduleIdForGroupInput={scheduleIdForGroupInput}
    />
  </>
}

export default ({ draft, schedule, preloadTeams, save, loading, onBack, onNext }) => {
  const translate = useTranslate();
  const { change, mutators } = useForm();

  useWizard(validate, draft => {
    return save(draft)
      .then(onNext)
  }, { hideDelete: true })

  const shuffleTeams = () => {
    // requires atleast 2 teams to shuffle
    if (draft?.teams?.filter(team => !isEmpty(team)).length < 2) return;

    const teams = shuffle(draft.teams)

    // sort empty team slots last
    teams.sort((a, b) => {
      if (a.teamId && !b.teamId) return -1
    });

    if (!isEqual(teams, draft.teams)) {
      change('teams', teams);
    } else {
      shuffleTeams();
    }
  }

  return <Card>
    <CardHeader title="resources.teams.labels.card.title" titleTypographyProps={{ variant: 'subtitle2', gutterBottom: false }} />
    <CardContent>
      <ArrayInput source="teams" label={null} {...inputProps}>
        <SortedFormIterator disableAdd {...inputProps}>
          <TeamInput source="teamId" filter={{ _scope: 'Tenant' }} label="resources.teams.name" ignoreSchedule {...inputProps} />
        </SortedFormIterator>
      </ArrayInput>
    </CardContent>
    <CardActions>
      <Button color="primary" startIcon={<Add />} onClick={() => mutators.push('teams', {})}>{translate('resources.drafts.labels.add_team')}</Button>
      <TeamImportButton label={translate('resources.drafts.labels.import_teams')} officeId={schedule?.officeId} />
      <Button color="primary" startIcon={<Shuffle />} onClick={() => shuffleTeams()}>{translate('resources.drafts.labels.shuffle')}</Button>
    </CardActions>
  </Card>
}
