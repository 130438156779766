import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';

const noop = () => {}
export const WizardContext = createContext({
  validate: null,
  onSubmit: null,
  setValidate: noop,
  setSubmit: noop,
  options: {}
})

export const WizardProvider = props => {
  const translate = useTranslate();
  const [ wizard, setWizard ] = useState({});
  const { validate, handleSubmit, options = {} } = wizard;

  const onValidate = (...props) => {
    if (typeof validate !== 'function') return {};
    return validate(...props, translate);
  }

  const onSubmit = (...props) => {
    if (typeof handleSubmit !== 'function') return;
    return handleSubmit(...props);
  }

  return <WizardContext.Provider
    value={{ validate: onValidate, onSubmit, options, setWizard }}
    {...props}
  />
}

export const useWizard = (validate, handleSubmit, options = {}) => {
  const context = useContext(WizardContext);
  useEffect(() => {
    if (!validate && !handleSubmit) return;
    context.setWizard({ validate, handleSubmit, options })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return context;
}

export const withWizard = Component => props =>
  <WizardProvider>
    <Component {...props} />
  </WizardProvider>
