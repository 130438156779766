import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';
import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';

import { TransactionForm } from './TransactionForm';

const AddTransactionDialog = ({ officeIds, ...props }) =>
  <CreateResourceDialog label="resources.officialTransactions.actions.add" maxWidth="md" resource="officialTransactions" basePath="/officialTransactions" {...props}>
    <TransactionForm officeIds={officeIds} />
  </CreateResourceDialog>

export const AddTransactionButton = ({ size, variant, className, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();

  return <>
    <Button color="primary" size={size} variant={variant} className={className} startIcon={<Add />} onClick={() => setOpen(true)}>{translate("resources.officialTransactions.actions.add")}</Button>
    <AddTransactionDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const EditTransactionDialog = props =>
  <EditResourceDialog label="resources.officialTransactions.actions.edit" maxWidth="md" resource="officialTransactions" basePath="/officialTransactions" {...props}>
    <TransactionForm />
  </EditResourceDialog>

export const TransactionDatagrid = props =>
  <DialogDatagrid dialog={EditTransactionDialog} {...props} />
