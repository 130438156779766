import React from 'react';
import { SimpleList, useListContext } from 'react-admin'

import TimeRangeField from '../../common/fields/TimeRangeField';

import { GroupField } from '../groups/GroupField';
import { GameStatusIcon } from '../games/GameNumberField';
import { DraftGameActions } from '../draftgames/DraftGameActions';

import { LocationField, EventTeamsField, StatusesField, rowClick, EventCommentsField, EventNameField } from './EventGrid';

export default ({ hideGroup = false, hideStatus = false, hideNumber = false, team, ...props }) => {
  const { ids, data } = useListContext(props);
  if (!ids || !data) return null;

  return <SimpleList
    primaryText={record => record && <>
      {!hideNumber && <strong>{record.number || record.type} </strong>}
      <GameStatusIcon game={record} />
      <TimeRangeField source="time" startSource="startTime" endSource="endTime" showDate />
      {!hideGroup && <> <GroupField source="groupId" /></>}
    </>}
    secondaryText={record => <>
      <LocationField link={false} /><br />
      <EventTeamsField source="homeTeamId" link={false} team={team} />
      <EventNameField />
      <EventCommentsField />
    </>}
    rightIcon={record => {
      if (record?.updatedGameId) return <DraftGameActions hideLabel />
      if (hideStatus) return null;
      return <StatusesField {...props} />
    }}
    linkType={(record, id) => {
      if (record?.updatedGameId) return null;
      return rowClick(id, null, record)
    }}
  />
}
