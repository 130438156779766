import React from 'react';
import { NumberInput, SimpleForm, TextInput, ReferenceArrayInput, ReferenceInput, useLocale } from 'react-admin';
import { Grid } from '@material-ui/core'

import { FF_GRADES } from '@hisports/common/featureFlags';
import { translateApiProperty } from '@hisports/common';

import { useFlag } from '../../http';
import { ilike } from '../../http/restClient/filters';
import { SeasonInput } from '../../common/inputs/EnumInputs';
import Toolbar from '../../common/ra/Toolbar';
import { DateInput } from '../../common/inputs/DateInput';
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';

import { OfficeInput } from '../offices/OfficeInput';
import { ParticipantInput } from '../participants/ParticipantInput';
import AutocompleteInput from '../../common/inputs/AutocompleteInput';


const validateGrade = (grades, position) => {
  if (!grades) return;
  const grade = grades[position];
  if (grade == null) return;
  if (isNaN(grade)) return 'ra.validation.invalid';
  if (grade < 0) return 'ra.validation.greater_than_or_equal_zero';
  if (grade % 1 > 0) return 'ra.validation.invalid';
}

const validate = values => {
  const errors = { grades: {} };

  if (values.level == null) {
    errors.level = 'ra.validation.required'
  } else if (values.level < 0) {
    errors.level = 'resources.qualifications.validations.level'
  } else if (values.level % 1 > 0) {
    errors.level = 'ra.validation.invalid'
  }

  errors.grades.Referee = validateGrade(values.grades, 'Referee')
  errors.grades.Linesperson = validateGrade(values.grades, 'Linesperson')

  if (!values.participantId) errors.participantId = 'ra.validation.required'
  if (!values.qualificationCategoryId) errors.qualificationCategoryId = 'ra.validation.required'
  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'qualifications',
  basePath: '/qualifications',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const filterQualificationCategory = (name, locale) => name ? ({ or: [
  { name: ilike(name) },
  { [`i18n.${locale}.name`]: ilike(name) },
] }) : undefined

export const QualificationCategoryInput = ({ multiple, filter, ...props }) => {
  const locale = useLocale();
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;

  return <Input
    source="qualificationCategoryId"
    reference="qualificationCategories"
    filterToQuery={name => filterQualificationCategory(name, locale)}
    filter={filter}
    sort={{ field: [`i18n.${locale}.name`, 'name'], order: ['ASC', 'ASC'] }}
    {...props}
  >
    <AutocompleteInput
      optionText={qualificationcategory => translateApiProperty(qualificationcategory, 'name', locale)}
    />
  </Input>
}

export const QualificationForm = props => {
  const [ isSynced, toggle ] = useRecordSynced(props);
  const isEnabled = useFlag();

  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm toolbar={<Toolbar isSynced={isSynced} />} validate={validate} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12}>
          <ParticipantInput source="participantId" filter={{ _scope: 'Tenant' }} label="resources.qualifications.fields.participantId" disabled={props.initialValues?.id || props.initialValues?.participantId || isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <QualificationCategoryInput source="qualificationCategoryId" filter={{ _scope: 'Tenant' }} label="resources.qualifications.labels.qualification_category" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={isEnabled(FF_GRADES) ? 4 : 12}>
          <NumberInput source="level" min={0} step={1} label="resources.qualifications.labels.official_level" helperText="" disabled={isSynced} {...inputProps} />
        </Grid>
        {isEnabled(FF_GRADES) && <Grid item xs={12} md={4}>
          <NumberInput source="grades.Referee" min={0} step={1} label="resources.qualifications.fields.grades.Referee" helperText="ra.message.optional" {...inputProps} />
        </Grid>}
        {isEnabled(FF_GRADES) && <Grid item xs={12} md={4}>
          <NumberInput source="grades.Linesperson" min={0} step={1} label="resources.qualifications.fields.grades.Linesperson" helperText="ra.message.optional" {...inputProps} />
        </Grid>}
        <Grid item xs={12} md={6}>
          <SeasonInput source="seasonId" label="resources.qualifications.fields.seasonId" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput source="expiry" label="resources.qualifications.fields.expiry" helperText="ra.message.optional" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <OfficeInput source="officeId" filter={{ type: { nin: ['League', 'Tournament', 'Cup', 'Zone', 'Historical'] } }} label="resources.qualifications.fields.officeId" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="notes" helperText="ra.message.optional" multiline rows="2" disabled={isSynced} {...inputProps} />
        </Grid>
      </Grid>
    </SimpleForm>
  </>
}
