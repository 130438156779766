import React from 'react';
import { EditButton, ShowButton, useEditContext, useResourceContext } from 'react-admin';

import { TopToolbar } from '../cards/Card';
import { isAuthorized } from '../Authorize';

export const ResourceActions = ({ hasEdit, hasShow, ...props }) => {
  const resource = useResourceContext(props)
  const { basePath, record } = useEditContext(props);
  const canEditResource = isAuthorized(record, resource, 'edit');
  const canShowResource = isAuthorized(record, resource, 'show');

  return <TopToolbar>
    {hasEdit && canEditResource && <EditButton basePath={basePath} resource={resource} record={record} />}
    {hasShow && canShowResource && <ShowButton basePath={basePath} resource={resource} record={record} />}
  </TopToolbar>
}
