import React from 'react';
import { styled, Tooltip } from '@material-ui/core';
import { ReportProblemOutlined, Schedule, LocationOnOutlined, Shuffle } from '@material-ui/icons';

import FunctionField from '../../common/fields/FunctionField'

const Info = styled('div')({
  display: 'inline-block',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap'
})

const icons = {
  'Cancelled': <ReportProblemOutlined fontSize="small" color="error" style={{ verticalAlign: 'middle' }} />,
  'Postponed': <ReportProblemOutlined fontSize="small" color="error" style={{ verticalAlign: 'middle' }} />,
  'Conflict': <Shuffle fontSize="small" color="error" style={{ verticalAlign: 'middle' }} />,
  'Rescheduled': <Schedule fontSize="small" style={{ verticalAlign: 'middle' }} />,
  'Rink Changed': <LocationOnOutlined fontSize="small" style={{ verticalAlign: 'middle' }} />,
}

export const GameStatusIcon = ({ game }) => {
  if (!game) return null;
  const icon = icons[game.status]
  if (!icon) return null;
  return <Tooltip title={game.status}>
    {icon}
  </Tooltip>
}

export const GameNumberField = props =>
  <FunctionField {...props} render={game => {
    if (!game) return null;
    if (game.isApproved) return game.number;

    const icon = icons[game.status];
    if (icon) {
      return <Info>
        {game.number}
        <GameStatusIcon game={game} />
      </Info>
    }

    return game.number;
  }} />
