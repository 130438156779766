import React, { useRef } from 'react';
import { TextInput, SimpleForm, useTranslate, useRecordContext } from 'react-admin';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import createCalculator from 'final-form-calculate';

import { changeTimezone, isEmpty } from '@hisports/parsers';

import { InlineDateTimeInput, dateTimePlaceholder } from '../../common/inputs/DateInput';
import { GameStatusEnumInput, ActivityTypeEnumInput, TimezoneEnumInput } from '../../common/inputs/EnumInputs';
import { EndTimeInput } from '../../common/inputs/EndTimeInput';
import { getRule, useRecurrence } from '../../common/fields/RecurrenceSchedule';
import { RecurrenceField } from '../../common/fields/RecurrenceField';
import Toolbar from '../../common/ra/Toolbar';

import { TeamInput } from '../teams/TeamInput';

const validate = values => {
  const errors = {};

  if (!values.teamId) errors.teamId = 'ra.validation.required'
  if (!values.type) {
    errors.type = 'ra.validation.required'
  }
  if (!values.location) {
    errors.location = 'ra.validation.required'
  }

  if (!values.startTime) errors.startTime = 'ra.validation.required'
  if (!values.endTime) errors.endTime = 'ra.validation.required'

  if (values.startTime && values.endTime) {
    if (!moment(values.startTime).isValid()) {
      errors.startTime = 'resources.games.validations.invalid_time'
    } else if (!moment(values.endTime).isValid()) {
      errors.endTime = 'resources.games.validations.invalid_time'
    } else if (moment(values.endTime).isBefore(values.startTime, 'minute')) {
      errors.endTime = 'resources.games.validations.end_before_start'
    } else if (moment(values.endTime).diff(values.startTime, 'hours') > 12 && values.type !== 'Accomodation') {
      errors.endTime = 'resources.games.validations.invalid_time_check_am_pm'
    }
  }

  if (values.frequency != null && values.frequency !== 'Once') {
    if (!values.endDate) {
      errors.endDate = 'ra.validation.required'
    } else if (values.startTime && moment(values.endDate).isBefore(values.startTime, 'day')) {
      errors.endDate = 'resources.games.validations.after_start_time';
    } else if (values.startTime && moment(values.endDate).diff(values.startTime, 'days') > 365) {
      errors.endDate = 'resources.games.validations.invalid_date'
    }
  }

  if (!values.status) errors.status = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'activities',
  basePath: '/activities',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const ActivityFormBody = () => {
  const { initialValues } = useFormState();
  const translate = useTranslate();

  const isEditing = initialValues.id
  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12} >
      <Alert severity="info">{translate('resources.activities.alerts.activity_explanation')}</Alert>
    </Grid>
    <Grid item xs={12} md={9}>
      <TextInput source="name" helperText="ra.message.optional" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={3}>
      <ActivityTypeEnumInput source="type" {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <TeamInput source="teamId" {...inputProps} disabled={initialValues?.teamId} />
    </Grid>
    <Grid item xs={12} md={4}>
      <TextInput source="location" helperText={false} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <InlineDateTimeInput
        source="startTime"
        options={{
          label: translate('resources.activities.fields.startTime'),
          format: 'YYYY-MM-DD HH:mm',
          placeholder: dateTimePlaceholder,
          ampm: false,
        }}
        {...inputProps}
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <EndTimeInput
        source="endTime"
        options={{
          label: translate('resources.activities.fields.endTime'),
          format: 'YYYY-MM-DD HH:mm',
          placeholder: dateTimePlaceholder,
          ampm: false,
        }}
        {...inputProps}
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <GameStatusEnumInput source="status" defaultValue="Active" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <TimezoneEnumInput source="timezone" {...inputProps} />
    </Grid>
    <Grid item xs={0} md={4} />

    {!isEditing && <RecurrenceField {...inputProps} />}

    <Grid item xs={12} lg={12}>
      <TextInput source="comments" multiline rows="3" helperText="ra.message.optional" {...inputProps} />
    </Grid>
  </Grid>
}

export const ActivityForm = ({ initialValues: propInitialValues, save, ...props }) => {
  const record = useRecordContext(props);
  const recurrenceValues = useRecurrence(record?.recurrence)
  const initialValues = { status: 'Active', type: 'Training', frequency: 'Once', timezone: moment.tz.guess(), ...recurrenceValues, ...propInitialValues }

  const teams = useSelector(store => store.admin.resources.teams.data);
  const teamsRef = useRef({});
  teamsRef.current = teams;

  const decorators = useRef([createCalculator({
    field: 'timezone',
    updates: {
      startTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.startTime || isEmpty(prevValues) || timezone === prevTimezone) return values.startTime;
        return changeTimezone(values.startTime, prevTimezone, timezone)
      },
      endTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.endTime || isEmpty(prevValues) || timezone === prevTimezone) return values.endTime;
        return changeTimezone(values.endTime, prevTimezone, timezone)
      },
    }
  }, {
    field: 'startTime',
    updates: {
      date: (startTime, values) => {
        if (!startTime || !values.timezone) return startTime;
        return moment.tz(startTime, values.timezone).format('YYYY-MM-DD');
      },
    }
  })])

  const onSave = async ({ frequency, interval, weekdays, endDate, recurrence, ...activity }) => {
    const rule = getRule({ frequency, interval, weekdays, endDate, timezone: activity.timezone });
    activity.recurrence = rule?.toString().replace('RRULE:', '') || null;
    return save(activity, props.redirect);
  }

  /* eslint-disable react/jsx-no-useless-fragment */
  return <>
    <SimpleForm
      initialValues={initialValues}
      validate={validate}
      save={onSave}
      decorators={decorators.current}
      toolbar={<Toolbar />}
      {...props}
    >
      <ActivityFormBody />
    </SimpleForm>
  </>
}
