import React from 'react';

import { ParticipantField } from '../participants/ParticipantField';
import { OfficeField } from '../offices/OfficeField';

import { OfficialDatagrid } from './OfficialModal';

export const OfficialGrid = props =>
  <OfficialDatagrid size="medium" {...props}>
    <ParticipantField source="participantId" includeId="inline" />
    <OfficeField source="officeId" />
  </OfficialDatagrid>
