import React from 'react';
import { Datagrid, TextField } from 'react-admin';

import { OfficeField } from '../offices/OfficeField';
import EventDateField from './EventDateField';

export const WebEventGrid = props =>
  <Datagrid size="medium" {...props}>
    <TextField source="title" />
    <EventDateField source="startTime" label="resources.webevents.labels.dates" />
    <OfficeField source="officeId" />
  </Datagrid>
