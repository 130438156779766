import React, { useMemo } from 'react';
import { useRecordContext, useTranslate, sanitizeFieldRestProps, useResourceContext } from 'react-admin';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { useSport } from '../../http';

/**
 * Field using a render function
 *
 * @example
 * <FunctionField
 *     source="last_name" // used for sorting
 *     label="Name"
 *     render={record => record && `${record.first_name} ${record.last_name}`}
 * />
 */
const FunctionField = props => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const sport = useSport();
  const translate = useTranslate();

  const { className, source = '', render, ...rest } = props;
  return useMemo(() => {
    if (!record) return null;
    return <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {render(record, source, resource, translate, sport)}
    </Typography>
  }, [className, record, source, resource, render, rest, translate, sport]);
};

FunctionField.defaultProps = {
  addLabel: true,
};

FunctionField.propTypes = {
  ...Typography.propTypes,
  render: PropTypes.func.isRequired,
};

export default FunctionField;
