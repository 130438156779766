import React, { Fragment } from 'react';

import ListCard from '../../../common/cards/ListCard';

import { GamePurgeGrid } from './GamePurgeGrid';

export const GamePurgeList = ({ ...props }) => {
  return <ListCard {...props}
    reference="gamePurges"
    resource="gamePurges"
    target="suspensionId"
    sort={{
      field: ['game.date'],
      order: ['DESC'],
    }}
    perPage={25}
    rowsPerPageOptions={[]}
    component={Fragment}
    noResultsText="resources.gamePurges.messages.no_results"
  >
    <GamePurgeGrid rowClick={false} />
  </ListCard>
};
