import React, { Fragment } from 'react';
import { TextInput, SimpleForm, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core'
import { useFormState } from 'react-final-form';

import Toolbar from '../../common/ra/Toolbar';
import EditorInput from '../../common/inputs/EditorInput';
import { AnnouncementTypeEnumInput, AnnouncementTargetTypeEnumInput, SeasonInput } from '../../common/inputs/EnumInputs'

import { TargetInput } from '../targets/TargetInput';

const validate = values => {
  const errors = {}

  if (!values.title) {
    errors.title = 'ra.validation.required'
  } else if (values.title.length > 80) {
    errors.title = 'ra.validation.title_too_long'
  }
  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.type) errors.type = 'ra.validation.required'
  if (!values.content) errors.content = 'ra.validation.required'
  if (!values.targetType) errors.targetType = 'ra.validation.required'
  if (!values.targetId) errors.targetId = 'ra.validation.required'

  return errors;
}

const recipients = {
  'Office': {
    'League': 'resources.announcements.messages.recipients.all_team_staff',
    'Officiating': 'resources.announcements.messages.recipients.all_available_officials',
    'General': 'resources.announcements.messages.recipients.all_team_staff_all_available_game_officials',
  },
  'List': {
    'League': 'resources.announcements.messages.recipients.all_list_members',
    'Officiating': 'resources.announcements.messages.recipients.all_list_members',
    'General': 'resources.announcements.messages.recipients.all_list_members',
  }
};

const DestinationInputs = () => {
  const translate = useTranslate();
  const { values } = useFormState();
  const { id, type, targetType } = values;

  const helperText = !id && targetType && type
    ? translate('resources.announcements.helpers.destination_sent', { recipient: translate(recipients[targetType][type]) })
    : 'resources.announcements.helpers.destination_not_sent'

  const filter = targetType === 'List' ? { _scope: 'Parents' } : undefined;
  return <>
    <Grid item xs={12} md={3}>
      <AnnouncementTypeEnumInput source="type" disabled {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={4} md={3}>
      <AnnouncementTargetTypeEnumInput source="targetType" {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={8} md={6}>
      <TargetInput source="targetId" label="resources.announcements.fields.officeId" helperText={helperText} filter={filter} {...inputProps} />
    </Grid>
  </>
}

const inputProps = {
  resource: 'announcements',
  basePath: '/announcements',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const AnnouncementForm = ({ initialValues = {}, ...props }) =>
  <SimpleForm toolbar={<Toolbar />} initialValues={{ type: 'Officiating', targetType: 'Office', ...initialValues }} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} md={9}>
        <TextInput source="title" autoFocus {...inputProps} />
      </Grid>
      <Grid item xs={12} md={3}>
        <SeasonInput source="seasonId" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <EditorInput source="content" {...inputProps} />
      </Grid>
      <DestinationInputs />
    </Grid>
  </SimpleForm>
