import React from 'react';
import { ArrayInput, NumberInput, SimpleFormIterator, minValue } from 'react-admin';
import { Grid } from '@material-ui/core'
import { LimitTypeEnumInput, PitcherLimitsScheduleTypeEnumInput } from '../../../common/inputs/EnumInputs';
import HorizontalFormIterator, { TransitionProps } from '../../../common/ra/HorizontalFormIterator';
import { DateInput } from '../../../common/inputs/DateInput';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const arrayInputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const required = (value) => {
  if (!value && value != 0) return { message: 'ra.validation.required' };
}

const ThrowsLimitsInput = () => {
  return <ArrayInput source="throwsLimits" label="resources.rulebooks.labels.throws_limits" >
    <HorizontalFormIterator disableReordering TransitionProps={TransitionProps} >
      <NumberInput validate={[required, minValue(1)]} source="min" label="ra.validation.min" min={1} step={1} helperText="" {...arrayInputProps} />
      <NumberInput validate={[required, minValue(1)]} source="max" label="ra.validation.max" min={1} step={1} helperText="" {...arrayInputProps} />
      <NumberInput validate={[required, minValue(0)]} source="restingDays" label="resources.rulebooks.fields.restingDays" min={0} step={1} {...arrayInputProps} />
    </HorizontalFormIterator>
  </ArrayInput>
}

const InningsPitchedLimitsInput = () => {
  return <ArrayInput source="inningsPitchedLimits" label="resources.rulebooks.labels.innings_pitched_limits" >
    <SimpleFormIterator getItemLabel={index => `#${index + 1}`} disableReordering TransitionProps={TransitionProps} >
      <LimitTypeEnumInput validate={[required]} source="limitType" label="resources.rulebooks.fields.limitType" {...arrayInputProps} />
      <NumberInput validate={[required, minValue(1)]} source="limit" label="ra.validation.max" min={1} step={1} helperText="" {...arrayInputProps} />
      <DateInput source="startDate" label="resources.rulebooks.fields.startDate" helperText="ra.message.optional" {...arrayInputProps} />
      <DateInput source="endDate" label="resources.rulebooks.fields.endDate" helperText="ra.message.optional" {...arrayInputProps} />
    </SimpleFormIterator>
  </ArrayInput>
}

const InningsPitchedRestsInput = () => {
  return <ArrayInput source="inningsPitchedRests" label="resources.rulebooks.labels.innings_pitched_rests" >
    <HorizontalFormIterator disableReordering TransitionProps={TransitionProps} >
      <NumberInput validate={[required, minValue(1)]} source="min" label="ra.validation.min" min={1} step={1} helperText="" {...arrayInputProps} />
      <NumberInput validate={[required, minValue(1)]} source="max" label="ra.validation.max" min={1} step={1} helperText="" {...arrayInputProps} />
      <NumberInput validate={[required, minValue(1)]} source="restingDays" label="resources.rulebooks.fields.restingDays" min={1} step={1} {...arrayInputProps} />
    </HorizontalFormIterator>
  </ArrayInput>
}

export const PitcherLimitsInput = ({ source }) => {
  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12}>
      <PitcherLimitsScheduleTypeEnumInput multiple source="scheduleTypes" label="resources.rulebooks.fields.scheduleTypes" {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <ThrowsLimitsInput {...inputProps} />
    </Grid>
    <Grid item xs={12}>
      <InningsPitchedLimitsInput />
    </Grid>
    <Grid item xs={12}>
      <InningsPitchedRestsInput />
    </Grid>
  </Grid>;
}
