import React, { useState } from 'react';
import { BooleanInput, RecordContextProvider, SimpleForm, useNotify, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, Grid, makeStyles, alpha } from '@material-ui/core'

import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { DialogFormToolbar } from '../../../../../../common/dialogs/DialogForm';
import { OfficeInput } from '../../../../../offices/OfficeInput';
import { AssignFeesInput } from '../../../../../assignFees';
import { apiClient } from '../../../../../../http';
import { useOfficeWithStore } from '../../../../../offices/useOffice';
import { useAssignmentsContext } from '../../AssignmentsContext';
import { useAssignmentContext } from '../AssignmentContext';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    marginRight: 'auto',
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  }
}))

const validate = values => {
  const errors = {};

  if (!values.officeId) errors.officeId = 'ra.validation.required';

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const delegateAssigning = async (gameId, assignmentId, position, officeId, payOfficeId, feesId) =>
  apiClient(`/games/${gameId}/delegateAssignment`, {
    method: 'POST',
    data: { assignmentId, position, officeId, payOfficeId, feesId },
  }).then(res => res.data);

const FeesInput = ({ game, ...props }) => {
  const { values } = useFormState();
  const { officeId, isDelegatedPay } = values;
  const seasonId = game?.seasonId;

  if (!isDelegatedPay) return null;
  return <AssignFeesInput {...props} filter={{ seasonId, effectiveOffices: officeId, includeParents: true, _scope: 'Parents' }} />
}

const DelegateFormBody = ({ game }) => {
  const { values } = useFormState();
  const { meta } = useAssignmentContext();
  const { settings } = useAssignmentsContext();
  const { isDelegated } = meta || {};
  const translate = useTranslate();

  const delegatedPayOfficeId = values.isDelegatedPay ? values.officeId : settings?.officeId
  const { data: delegatedPayOffice } = useOfficeWithStore(delegatedPayOfficeId)

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <OfficeInput source="officeId" filter={{ type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] }, isAssigner: true, _scope: 'Tenant' }} {...inputProps} />
    </Grid>
    {values.officeId && <Grid item xs={12}>
      <BooleanInput
        source="isDelegatedPay"
        label="resources.games.labels.assignment.delegate_pay"
        helperText={delegatedPayOffice && translate('resources.games.helpers.assignment.delegated_pay_office', { office: delegatedPayOffice.name })}
      />
    </Grid>}
    {isDelegated && !!values?.isDelegatedPay && <Grid item xs={12}>
      <FeesInput source="feesId" allowEmpty label="resources.gameAssignSettings.fields.feesId" helperText="ra.message.optional" game={game} {...inputProps} />
    </Grid>}
  </Grid>
}

const DelegateForm = ({ handleSubmit, handleCancel, handleDelete, assignment, game, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const deleteButton = <Button color="secondary" onClick={handleDelete} className={classes.deleteButton}>{translate('ra.action.undelegate')}</Button>
  const toolbar = <DialogFormToolbar handleSubmit={handleSubmit} onCancel={handleCancel} submitLabel="ra.action.delegate" deleteButton={deleteButton} />

  return <SimpleForm toolbar={toolbar} validate={validate} save={handleSubmit} {...props}>
    <DelegateFormBody game={game} />
  </SimpleForm>
}

const DelegateDialog = ({ assignment, game, open, onClose, closeModal }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { refetch } = useAssignmentsContext();

  const { officeId, feesId, payOfficeId } = assignment

  const handleCancel = () => closeModal();
  const handleSubmit = values => {
    const { officeId, isDelegatedPay, feesId } = values;
    const { id, position } = assignment;
    const payOfficeId = isDelegatedPay ? officeId : null

    delegateAssigning(game.id, id, position, officeId, payOfficeId, feesId).then(res => {
      closeModal()
    }).catch(err => {
      const message = err?.response?.data?.error?.message
      notify(message || 'ra.page.error_try_again', 'error')
    }).finally(() => {
      setTimeout(refetch, 500)
    })
  }
  const handleDelete = () => handleSubmit({})

  return <Dialog open={open} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.games.labels.assignment.delegate')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.games.messages.assignment.delegate')}
      </DialogContentText>
      <RecordContextProvider value={null}>
        <DelegateForm
          initialValues={{ officeId, feesId, isDelegatedPay: (!!payOfficeId || !officeId) }}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
          assignment={assignment}
          game={game} />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

export default ({ game, assignment, disabled, onClose, ...props }) => {
  const [ showDelegateDialog, setShowDelegateDialog ] = useState(false);
  const translate = useTranslate();
  const { meta } = useAssignmentContext();

  if (!game || !assignment) return null;

  return <>
    <Button
      disabled={disabled}
      onClick={() => setShowDelegateDialog(true)}
      {...props}
    >
      {!meta.isDelegated ? translate('ra.action.delegate') : translate('ra.message.settings')}
    </Button>
    <DelegateDialog assignment={assignment} game={game} open={showDelegateDialog} onClose={onClose} closeModal={() => setShowDelegateDialog(false)} />
  </>
}
