import React from 'react';
import { ReferenceField, useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux'

import { isAuthorized } from '../../common/Authorize';
import FunctionField from '../../common/fields/FunctionField'

import { getSurfaceAttributes, getSurfaceCity, getSurfaceName, isSystemSurface } from './util'

export const SurfaceField = ({ className, includeCity = false, includeVenue = true, includeAttributes = false, variant='body2', ...props }) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  const surface = useSelector(state => state.admin.resources.surfaces.data[record?.[props.source]])
  const showLink = isAuthorized(record, 'surfaces', 'show') && !isSystemSurface(surface) ? 'show' : false;

  return <ReferenceField basePath="/surfaces" resource="surfaces" reference="surfaces" link={showLink} variant={variant} {...props}>
    <FunctionField className={className} render={surface => {
      const name = getSurfaceName(surface, includeVenue);
      const attributes = getSurfaceAttributes(surface, translate)
      const city = getSurfaceCity(surface, false);
      const inline = [
        includeAttributes === 'inline' && attributes,
        includeCity === 'inline' && city,
      ].filter(Boolean).join(' • ')
      const full = [
        includeAttributes === 'full' && attributes,
        includeCity === 'full' && city,
      ].filter(Boolean).join(' • ')
      return <>
        <Typography variant={variant} display="inline">{name}</Typography>
        {inline && <Typography variant={variant} display="inline" color="textSecondary"> • {inline}</Typography>}
        {full && <div><Typography variant={variant} display="inline" color="textSecondary">{full}</Typography></div>}
      </>
    }} />
  </ReferenceField>
}

SurfaceField.defaultProps = {
  addLabel: true,
}
