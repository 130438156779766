import React, { useMemo, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Sync } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { useScopes } from '../http';

const useStyles = makeStyles(theme => ({
  alertRoot: {
    marginBottom: theme.spacing(1),
    '&:hover .MuiAlert-action': {
      visibility: 'visible',
    }
  },
  alertAction: {
    visibility: 'hidden'
  },
  syncIcon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(0.5),
  }
}))

export const useRecordSynced = props => {
  const record = useRecordContext(props);
  const [ disabled, setDisabled ] = useState(record?.externalId != null);
  const scopes = useScopes();

  return useMemo(() => {
    const toggle = scopes.includes('internal') ? () => setDisabled(disabled => !disabled) : null;
    return [ disabled, toggle ];
  }, [ scopes, disabled, setDisabled ])
}

export const SyncAlert = ({ isSynced, toggle }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!isSynced) return null;
  return <Alert
    icon={<Sync />}
    severity="info"
    classes={{ root: classes.alertRoot, action: classes.alertAction }}
    onClose={toggle}
  >
    {translate('ra.message.managed_by_id')}
  </Alert>
}

export const SyncAlertCustomMessage = ({ isSynced, toggle, message }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!isSynced) return null;
  return <Alert
    icon={<Sync />}
    severity="info"
    classes={{ root: classes.alertRoot, action: classes.alertAction }}
    onClose={toggle}
  >
    {translate(message)}
  </Alert>
}

export const SyncedIcon = props => {
  const translate = useTranslate();
  return <Tooltip title={translate('ra.message.synced_with_id')}>
    <Sync {...props} />
  </Tooltip>
}

export const SyncedIndicator = ({ record }) => {
  const classes = useStyles();
  if (!record?.externalId) return null;
  return <SyncedIcon className={classes.syncIcon} fontSize="inherit" />;
}
