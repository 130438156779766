import React, { useRef } from "react";
import { NumberInput, SimpleForm, TextInput } from "react-admin";
import { Grid } from "@material-ui/core";
import createCalculator from 'final-form-calculate';

import { FieldDependency } from "../../common/FieldDependency";
import { DisciplineStatusInput, DurationTypeInput } from "../../common/inputs/EnumInputs";

const validate = values => {
  const errors = {};

  if (values.durationType === 'Definite' && values.additionalGames == null) {
    errors.additionalGames = 'ra.validation.required';
  } else if (values.durationType === 'Indefinite' && values.additionalGames >= 0) {
    errors.additionalGames = 'resources.suspensionDisciplines.validations.must_be_empty';
  }

  if (!values.durationType) {
    errors.durationType = 'ra.validation.required';
  }

  if (!values.status) {
    errors.status = 'ra.validation.required';
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const SuspensionDisciplineForm = props => {
  const decorators = useRef([createCalculator({
    field: 'durationType',
    updates: {
      additionalGames: (durationType, values, prevValues) => {
        if (durationType === 'Indefinite') {
          return;
        }
        return values.additionalGames;
      }
    }
  })]);

  return <SimpleForm decorators={decorators.current} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <FieldDependency fieldSource="durationType" test={durationType => durationType !== 'Indefinite'} disabled >
          <NumberInput source="additionalGames" label="resources.suspensionDisciplines.fields.additionalGames" helperText="" {...inputProps} />
        </FieldDependency>
      </Grid>
      <Grid item xs={12}>
        <DurationTypeInput source="durationType" label="resources.suspensionDisciplines.fields.durationType" radio helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="comments" label="resources.suspensionDisciplines.fields.comments" multiline minRows="3" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <DisciplineStatusInput source="status" label="resources.suspensionDisciplines.fields.status" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
