import React from 'react';
import { useTranslate } from 'react-admin';

import { Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { RulebookForm } from './RulebookForm';

export const RulebookCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={record => translate('resources.rulebooks.labels.add')} />}
    {...props}
  >
    <RulebookForm redirect="show" />
  </Create>
}

export const RulebookEdit = props => {
  const translate = useTranslate()
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: record.name })} />}
    {...props}
  >
    <RulebookForm redirect="show" />
  </Edit>
}
