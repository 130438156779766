import React from 'react';
import { DatagridRow, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import FunctionField from '../../common/fields/FunctionField'
import GroupedView from '../../common/GroupedView';
import { getVenueCity, getVenueName } from './util';

const useStyles = makeStyles(theme => ({
  row: {
    '& .column-name': {
      width: theme.spacing(50),
    },
    '& .column-address': {
      width: theme.spacing(50),
    },
  }
}))

const VenuesList = ({ records, basePath, resource, ...props }) => {
  const classes = useStyles(props);

  return records.map(record =>
    <DatagridRow key={record.id} basePath={basePath} resource={resource} record={record} id={record.id} rowClick="show" className={classes.row}>
      <FunctionField source="name" render={getVenueName} />
      <TextField source="address" />
    </DatagridRow>
  )
}

export const VenueView = () =>
  <GroupedView grouping={venue => getVenueCity(venue, true, true, false, false)}>
    <VenuesList />
  </GroupedView>
