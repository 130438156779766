import React from 'react';
import { ReferenceInput, ReferenceArrayInput } from 'react-admin';

import { getSeasonDisplayName } from '@hisports/common/src/seasons';

import { useFormSeason } from '../../common/inputs/SeasonSelector';
import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { useSport } from '../../http';

import { ScheduleIcon } from '../icons';

const filterSchedules = filter => name => ({ ...filter, name })

const ScheduleSuggestion = ({ suggestion: schedule, query, isHighlighted, ...props }) => {
  const sport = useSport();
  if (!schedule?.id) return null;
  const season = getSeasonDisplayName(schedule.seasonId, sport);
  return <AutocompleteSuggestion icon={<ScheduleIcon />} primary={schedule.name} secondary={`${schedule.type} (${season})`} />
}

export const ScheduleInput = ({ allowEmpty, multiple, helperText, ...props }) => {
  const seasonId = useFormSeason();
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="schedules"
    filterToQuery={filterSchedules({ seasonId, pastEvents: true })}
    sort={{
      field: ['type', 'name'],
      order: ['ASC', 'ASC'],
    }}
    perPage={100}
    {...props}
  >
    <AutocompleteInput
      optionText="name"
      groupBy={schedule => schedule.type}
      suggestionComponent={ScheduleSuggestion}
      multiple={multiple}
      helperText={helperText}
      resettable
      allowEmpty
    />
  </Input>
}
