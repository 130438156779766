import React, { Fragment, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Tooltip, TextField } from '@material-ui/core'
import { Feedback } from '@material-ui/icons';

import { apiClient } from '../../../../../../http';

const request = (gameId, position, notes) =>
  apiClient(`/games/${gameId}/requestAssignment`, {
    method: 'POST',
    data: { position, notes },
  })

const ConfirmDialog = ({ assignment, official, open, onClose }) => {
  const translate = useTranslate();
  const [ notes, setNotes ] = useState('');
  const handleCancel = () => onClose(false);
  const handleConfirm = () => onClose(true, notes);
  const handleNotes = event => setNotes(event.target.value);

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.games.labels.assignment.confirm_request')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.games.messages.assignment.assigners_will_be_notified')}
      </DialogContentText>
      <TextField
        margin="dense"
        fullWidth
        multiline
        rows={2}
        label={translate('resources.games.labels.notes', 1)}
        helperText={translate('ra.message.optional')}
        value={notes}
        onChange={handleNotes}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary">{translate('ra.action.request')}</Button>
    </DialogActions>
  </Dialog>
}

export default ({ game, assignment, official, disabled, onClose }) => {
  const [ requested, setRequested ] = useState(false);
  const [ showConfirm, setConfirm ] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();

  if (!game || !assignment || !official) return null;

  const handleConfirm = (confirmed, notes) => {
    setConfirm(false);
    if (!confirmed) return;

    setRequested(true)
    request(game.id, assignment.position, notes).then(res => {
      onClose(assignment, official.participantId)
      notify(translate('resources.games.notifications.assignment.requested_game', { number: game.number }), 'success')
    }).catch(err => {
      setRequested(false)
      const message = err?.response?.data?.error?.message
      notify(message || 'ra.page.error_try_again', 'error')
    })
  }

  return <>
    <Tooltip title={translate('resources.games.labels.assignment.request_game')}>
      <Button
        color="primary"
        startIcon={<Feedback fontSize="small" />}
        disabled={disabled || requested}
        onClick={() => setConfirm(true)}
      >
        {translate('ra.action.request')}
      </Button>
    </Tooltip>
    <ConfirmDialog assignment={assignment} official={official} open={showConfirm} onClose={handleConfirm} />
  </>
}
