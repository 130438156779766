import React, { useContext, useMemo, useState } from 'react';

const PracticeAvailabilityContext = React.createContext();

export const usePracticeAvailabilityContext = () => useContext(PracticeAvailabilityContext);

export const PracticeAvailabilityContextProvider = ({ children }) => {
  const availabilityInfo = useState({});
  const settings = useState({});

  const context = useMemo(() => ({
    availabilityInfo,
    settings,
  }), [ availabilityInfo, settings ])

  return <PracticeAvailabilityContext.Provider value={context}>
    {children}
  </PracticeAvailabilityContext.Provider>
}
