import React from 'react';

import ListCard from '../../../common/cards/ListCard';

import { EventView } from '../../events/EventView';

export default props =>
  <ListCard
    title="resources.officialConflicts.labels.card.title"
    subtitle="resources.officialConflicts.labels.card.subtitle"
    reference="games"
    target="conflicts"
    rowsPerPageOptions={[]}
    sort={{ field: 'startTime', order: 'ASC' }}
  >
    <EventView rowClick={false} hideGroup hideStatus showSchedule disableCalendar />
  </ListCard>
