import React from 'react';
import { ImageField, TextField, LinearProgress, useReference, useTranslate, useRecordContext, RecordContextProvider } from 'react-admin';
import { Table, TableBody, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { VisibilityOff, Public } from '@material-ui/icons';

import Row from '../../common/cards/TableRow'
import DateField from '../../common/fields/DateField';

import { ParticipantField } from '../participants/ParticipantField';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    borderRadius: '50%',
  }
}))

const ProfileImage = props => {
  const classes = useStyles(props);
  return <div className={classes.root}>
    <ImageField source="picture.url" label="resources.profiles.labels.picture" classes={classes} />
  </div>
}

const ProfileTable = ({ loaded, ...props }) => {
  const profile = useRecordContext(props);
  const translate = useTranslate();
  return <Table>
    <TableBody>
      <Row hidden={!profile.picture}>
        <ProfileImage />
      </Row>
      <Row hidden={!loaded || !profile.isPublic}>
        <Alert variant="outlined" severity="info" icon={<Public />}>
          {translate('resources.profiles.alerts.public_profile')}
        </Alert>
      </Row>
      <Row hidden={!loaded || profile.isPublic}>
        <Alert variant="outlined" severity="success" icon={<VisibilityOff />}>
          {translate('resources.profiles.alerts.private_profile')}
        </Alert>
      </Row>
      <Row label="resources.participants.fields.name">
        <ParticipantField source="id" link={false} />
      </Row>
      <Row hidden={loaded}>
        <LinearProgress />
      </Row>
      <Row label="resources.profiles.fields.birthdate" hidden={!profile.birthdate}>
        <DateField source="birthdate" yearFormat="LL" />
      </Row>
      <Row label="resources.profiles.fields.height" hidden={!profile.height}>
        <TextField source="height" />
      </Row>
      <Row label="resources.profiles.fields.weight" hidden={!profile.weight}>
        <TextField source="weight" />
      </Row>
      <Row label="resources.profiles.fields.hometown" hidden={!profile.hometown}>
        <TextField source="hometown" />
      </Row>
      <Row label="resources.profiles.fields.dexterity" hidden={!profile.dexterity}>
        <TextField source="dexterity" />
      </Row>
    </TableBody>
  </Table>
}

export const ProfileField = ({ source = 'id', ...props }) => {
  const participant = useRecordContext(props);
  const id = participant?.[source];

  const { referenceRecord, loaded } = useReference({ reference: 'profiles', id })
  const profile = referenceRecord || { id }

  return <RecordContextProvider value={profile}>
    <ProfileTable loaded={loaded} />
  </RecordContextProvider>
}
