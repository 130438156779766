import React from 'react';
import { GET_LIST, useQuery, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { isAuthorized } from '../../common/Authorize';

import { PublishDraftGameAction } from './DraftGamePublishModal';
import { DraftGameApprovalAction } from './DraftGameApprovalModal';

const useFieldStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      flexDirection: 'column',
    }
  }
}))

export const useDraftGameApprovals = draftGameId => useQuery({
  type: GET_LIST,
  resource: 'draftGameApprovals',
  payload: {
    filter: { draftGameId },
    sort: { field: ['targetType', 'targetId'], order: ['ASC', 'ASC'] }
  }
}, { enabled: draftGameId != null })

const isDraftValid = (draftGame) => {
  const { isPublished, date, startTime, endTime, timezone, arenaId } = draftGame || {}
  return isPublished === false && date && startTime && endTime && timezone && arenaId;
}

export const DraftGameActions = ({ hideLabel }) => {
  const draftGame = useRecordContext()
  const classes = useFieldStyles();
  const { data: approvals, loading } = useDraftGameApprovals(draftGame?.id);

  const canPublish = isAuthorized(draftGame, 'draftGames', 'publish') && isDraftValid(draftGame);
  const canApprove = isAuthorized(draftGame, 'draftGames', 'approve', false, false) && approvals?.length > 0;

  if (!draftGame || (!canPublish && !canApprove) || loading) return null;

  return <span className={classes.root}>
    {canApprove
      ? <DraftGameApprovalAction hideLabel={hideLabel} />
      : canPublish && <PublishDraftGameAction hideLabel={hideLabel} />
    }
  </span>
}
