import React from 'react'
import { Button, useListContext, useTranslate } from 'react-admin';
import { ClearAll } from '@material-ui/icons';
import { isEmpty } from 'lodash';

import { useSeason } from './inputs/SeasonSelector'

export const ResetFiltersButton = () => {
  const translate = useTranslate();
  const seasonId = useSeason();
  const { filterValues, setFilters } = useListContext();

  const { seasonId: initialSeasonId, ...rest } = filterValues;

  const handleClick = () => setFilters({ seasonId })

  if (isEmpty(rest)) return null;
  return <div>
    <Button label={translate("ra.action.clear_filters")} onClick={handleClick}>
      <ClearAll />
    </Button>
  </div>
}
