import React from 'react';
import { useRecordContext } from 'react-admin';

import InfoCard from '../../../common/cards/InfoCard';
import { isAuthorized } from '../../../common/Authorize';

import { UpdateBranchOfficeSettingsButton } from '../../branchOfficeSettings/BranchOfficeSettingsModal';
import { BranchOfficeSettingsField } from '../../branchOfficeSettings/BranchOfficeSettingsField';

export default props => {
  const office = useRecordContext(props);
  const showSettings = isAuthorized(office, 'branchOfficeSettings', 'show');
  const canEditSettings = isAuthorized(office, 'branchOfficeSettings', 'edit');

  if (!showSettings) return null;

  return <InfoCard title="resources.branchOfficeSettings.labels.card.title" actions={
    canEditSettings && <UpdateBranchOfficeSettingsButton officeId={office?.id} />
  }>
    <BranchOfficeSettingsField officeId={office?.id} />
  </InfoCard>
}
