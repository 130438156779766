import { isEqual, startCase } from '@hisports/common/src/lodash.js';
import { isTimeValid, calculateGameTime, calculateEndTime, isTimeEqual, isTimeAfter, compareTime, isTimeBefore } from './gameTime.js';
import { isSkater } from './members.js';
import {
  MINOR,
  DOUBLE_MINOR,
  HEAD_CONTACT,
  GAME_EJECTION,
  YELLOW,
  YELLOW_RED,
} from '../constants.js';
import { getInfractionTypeDuration } from './penalties.js';

// util function to remove duplicates
const dedupe = values => Array.from(new Set(values))

export const getLegacyDuration = (duration, durations = {}) => {
  if (!duration) return;
  if (typeof duration === 'object') {
    return durations[duration.durationId];
  }

  return durations[duration];
};

export const getLegacyInfraction = (infraction, infractions = []) => {
  if (!infraction) return;
  if (typeof infraction === 'object') {
    return infraction
  }
  const result = infractions.find(({ id }) => id === infraction);

  // return dummy infraction if infraction not found in the rulebook
  if (!result) return ({
    id: infraction,
    name: startCase(infraction),
    choices: []
  });

  return result;
}

export const getChoiceDurationLabel = (choiceDuration, durations = {}, includeDescription = false) => {
  const { durationId, code } = choiceDuration;
  const duration = getLegacyDuration(durationId, durations) || {};
  const name = duration.name ? duration.name : startCase(durationId);
  return `${code ? `${code} - ` : ''}${name}${includeDescription && duration.description ? ` (${duration.description})` : ''}`
}

export const getChoiceLabel = (choice, durations = {}, joinSymbol = '/') => {
  return choice.durations.map(duration => getChoiceDurationLabel(duration, durations)).join(` ${joinSymbol} `);
}

// if there are additional duplicate penalties, add total denominator to duration ex. (2/4) or (4/4)
export const getDuplicateDurationFractionLabel = (duration = {}, penalty = {}, penalties = [], infractions = [], sport) => {
  const minutes = getInfractionTypeDuration(duration, null, true)
  const additionalDupes = findAdditionalPenalties(penalty, penalties, infractions, sport)
    .filter(additionalPenalty => additionalPenalty.duration === penalty.duration);

  if (!additionalDupes.length) return;
  const dupes = [penalty, ...additionalDupes];
  let order = 0;

  for (const p of penalties) {
    // increment order for every additional penalty found
    if (dupes.some(dupe => dupe.id === p.id)) {
      order += 1;
    }
    if (p.id === penalty.id) break;
  }

  return `${order * minutes}/${dupes.length * minutes}`
}

export const isStickInfraction = infraction => {
  if (!infraction || typeof infraction !== 'object') return false;
  return infraction.isStick === true;
}

// find penalties that are withing the same choice
export const findAdditionalPenalties = (penalty, penalties = [], infractions, sport, includeCurrentPenalty = false) => {
  if (!penalty || !penalties.length || !infractions) return [];

  // get the infraction of the penalty
  const infraction = getLegacyInfraction(penalty.infraction, infractions);
  const choices = infraction && infraction.choices || [];

  // find potential possible code combinations out of the infractions choices
  const possibleCodes = dedupe(choices.reduce((values, choice) => {
    const codes = (choice.durations || []).map(({ code }) => code);
    if (codes.length > 1 && codes.includes(penalty.code)) {
      return [...values, ...codes];
    }
    return [...values];
  }, []));

  // find potential possible choice duration permutations
  const possibleChoiceDurationPermutations = [...choices]
    .sort((a, b) => b.durations.length - a.durations.length)
    .map(choice => choice.durations.map(duration => duration.durationId))

  // find all linked penalties based on same infraction / participant / game time / code
  const linkedPenalties = [...penalties].filter(({ participantId, gameTime, code, infraction }) => {
    const isSameInfraction = infraction && penalty.infraction && infraction === penalty.infraction;
    const isSameParticipant = participantId && penalty.participantId && participantId === penalty.participantId;
    const isSameGameTime = gameTime && penalty.gameTime && isTimeEqual(gameTime, penalty.gameTime, sport);
    const isCodeFromPossibleChoices = !code || (possibleCodes.length && possibleCodes.includes(code));

    return isSameInfraction
    && isSameParticipant // it is the same participant
    && isSameGameTime // it is at the same game time
    && isCodeFromPossibleChoices; // both have no codes or it is a code from the possible choices available from the infraction
  }).sort((a, b) => compareTime(a.startTime, b.startTime, sport));

  // find all penalty combinations from all the linked penalties
  const penaltyCombinations = []
  for (let index = 0; index < linkedPenalties.length; index++) {
    for (const permutation of possibleChoiceDurationPermutations) {
      const combination = linkedPenalties.slice(index, index + permutation.length)
      // compare to see if both contain same durations (ignoring order)
      const isSame = JSON.stringify(combination.map(({ duration }) => duration).sort()) === JSON.stringify(permutation.sort())
      if (isSame) {
        penaltyCombinations.push(combination)
        index = index + permutation.length - 1
        break;
      }
    }
  }

  // find the penalty combination that the original penalty is in
  const penaltyCombination = (penaltyCombinations || []).find(combination => {
    return combination.find(({ id }) => id === penalty.id)
  }) || linkedPenalties;

  let additionalPenalties = (penaltyCombination || [])

  // remove the original penalty from the additionalPenalties
  if (!includeCurrentPenalty) {
    additionalPenalties = additionalPenalties.filter(({ id }) => id !== penalty.id)
  }

  return additionalPenalties
}

export const findChoice = (penalty, infraction, additionalPenalties = []) => {
  if (!penalty || !infraction || !infraction.choices || !infraction.choices.length) return;

  // find choice using additional penalties
  if (additionalPenalties.length) {
    const durations = [penalty, ...additionalPenalties].map(({ duration }) => duration);

    return infraction.choices.find(choice => isEqual(choice.durations.map(({ durationId }) => durationId).sort(), durations.sort()))
  }

  // find choice using code of the penalty
  if (penalty.code) {
    return infraction.choices.find(({ durations }) => durations.find(({ code }) => code === penalty.code))
  }

  // sort by choices with least amount of duration first
  const choices = [...infraction.choices].sort((a, b) => (a.durations || []).length - (b.durations || []).length);

  // pick first choice from sorted list
  return choices.find(choice => choice.durations.findIndex(({ durationId }) => durationId === penalty.duration) >= 0);
}

export const sortPenaltiesBasedOnChoice = (penalties = [], choice, sport) => {
  if (!choice) return penalties;
  const choiceDurationIds = choice.durations.map(({ durationId }) => durationId);
  return [...penalties].sort((a, b) => {
    if (choiceDurationIds.indexOf(a.duration) < choiceDurationIds.indexOf(b.duration)) {
      return -1;
    }
    if (choiceDurationIds.indexOf(a.duration) > choiceDurationIds.indexOf(b.duration)) {
      return 1;
    }
    if (isTimeAfter(a.endTime, b.endTime, sport)) {
      return -1;
    }
    if (isTimeAfter(b.endTime, a.endTime, sport)) {
      return 1;
    }
    return 0;
  })
}

export class LegacyPenaltyManager {
  constructor(meta, teamId, periods = [], members = {}) {
    this.meta = meta;
    this.periods = periods;
    this.members = members;

    this.penalty = {
      teamId,
      participantId: null,
      infraction: null,
      choice: null,
    }
  }

  getResultingPenalties(teamPenalties) {
    const penalties = [];
    const addPenalty = (infraction, duration, offset) =>
      penalties.push(this.createPenalty(infraction, duration, offset));

    this.penalty.choice.durations.forEach(duration => {
      addPenalty(this.penalty.infraction.id, duration)
    })

    return penalties;
  }

  setPenalty(penalty, choice, infractions) {
    this.penalty.id = penalty.id;
    this.penalty.teamId = penalty.teamId;
    this.penalty.participantId = penalty.participantId;
    this.penalty.gameTime = penalty.gameTime;

    this.penalty.infraction = getLegacyInfraction(penalty.infraction, infractions);
    this.penalty.choice = choice;
  }

  selectInfraction(infraction) {
    this.resetChoices();

    // unselect
    if (this.penalty.infraction && this.penalty.infraction.id == infraction.id) {
      this.penalty.infraction = null;
      return;
    }

    this.penalty.infraction = infraction;

    // automatically select first duration
    const { choices } = this.penalty.infraction;
    if (choices) {
      this.selectChoice(choices[0]);
    }
  }

  selectChoice(choice) {
    if (this.isChoiceSelected(choice)) {
      // unselect
      this.penalty.choice = null;
      return;
    }

    this.penalty.choice = choice;
  }

  resetChoices() {
    this.penalty.choice = null;
  }

  isChoiceSelected(choice) {
    return this.penalty && this.penalty.choice && isEqual(this.penalty.choice.durations, choice.durations)
  }

  isValidPlayers() {
    return this.penalty && this.penalty.participantId != null;
  }

  isValidInfraction() {
    const { infraction, choice } = this.penalty;
    if (!infraction || !choice) return false;
    return true;
  }

  createPenalty(infraction, { durationId, code }) {
    return {
      teamId: this.penalty.teamId,
      participantId: this.penalty.participantId,
      gameTime: this.penalty.gameTime,
      startTime: this.penalty.gameTime,
      endTime: this.penalty.endTime,
      infraction,
      duration: durationId,
      code
    }
  }
}

export class LegacyHockeyPenaltyManager extends LegacyPenaltyManager {
  constructor(meta, teamId, periods = [], members = {}) {
    super(meta, teamId, periods, members);

    this.stickInfractions = this.meta.rules.filter(isStickInfraction).map(infraction => infraction.id);

    this.penalty = {
      ...this.penalty,
      servedById: null
    }
  }

  getResultingPenalties(teamPenalties) {
    const penalties = [];
    const addPenalty = (infraction, duration, offset) =>
      penalties.push(this.createPenalty(infraction, duration, offset));

    this.penalty.choice.durations.forEach(duration => {
      let offset;

      const timedDurationIds = Object.entries(this.meta.types)
        .filter(([id, duration]) => duration.timed)
        .map(([id]) => id);

      // if timed penalty, calculate total length of prior penalties to offset the current one
      if (timedDurationIds.includes(duration.durationId)) {
        offset = penalties.reduce((total, penalty) => {
          const duration = getLegacyDuration(penalty.duration, this.meta.types);
          if (!duration.timed || !duration.length) return total;
          return total + duration.length;
        }, 0);
      }

      addPenalty(this.penalty.infraction.id, duration, offset)
    })

    switch (this.meta.policies.ejections) {
      case 'Contact': {
        // add a game ejection when adding a 3rd stick penalty
        if (this.requiresStickEjection(penalties, teamPenalties)) {
          addPenalty(GAME_EJECTION, { durationId: GAME_EJECTION });
        }

        // add a game ejection when adding a 3rd head contact penalty
        if (this.requiresContactEjection(penalties, teamPenalties)) {
          addPenalty(GAME_EJECTION, { durationId: GAME_EJECTION });
        }
        break;
      }
      case 'All': {
        // add a game ejection when adding a 3rd penalty (minor or otherwise)
        if (this.requiresAnyEjection(penalties, teamPenalties)) {
          addPenalty(GAME_EJECTION, { durationId: GAME_EJECTION });
        }
        break;
      }
      case 'None':
      default: {
        break;
      }
    }

    return penalties;
  }

  setPenalty(penalty, choice, infractions) {
    this.penalty.id = penalty.id;
    this.penalty.teamId = penalty.teamId;
    this.penalty.participantId = penalty.participantId;
    this.penalty.servedById = penalty.servedById;
    this.penalty.gameTime = penalty.gameTime;
    this.penalty.startTime = penalty.startTime;

    this.penalty.infraction = getLegacyInfraction(penalty.infraction, infractions);
    this.penalty.choice = choice;

    if (penalty.isEnded) {
      this.penalty.endTime = penalty.endTime;
    }
  }

  requiresAnyEjection(newPenalties, teamPenalties) {
    const existingCount = this.getParticipantTotal(teamPenalties, false);
    return existingCount >= 2;
  }

  requiresStickEjection(newPenalties, teamPenalties) {
    const stickPenalties = teamPenalties.filter(penalty => this.stickInfractions.includes(penalty.infraction))
    const existingCount = this.getParticipantTotal(stickPenalties);
    if (existingCount < 2) return false;
    return this.hasStickInfraction(newPenalties)
  }

  requiresContactEjection(newPenalties, teamPenalties) {
    const contactPenalties = teamPenalties.filter(penalty => penalty.infraction === HEAD_CONTACT);
    const existingCount = this.getParticipantTotal(contactPenalties);
    if (existingCount < 2) return false;
    return this.hasContactInfraction(newPenalties);
  }

  hasStickInfraction(penalties) {
    const isMinor = duration => duration === MINOR || duration === DOUBLE_MINOR;
    return penalties.some(
      penalty => {
        const infraction = getLegacyInfraction(penalty.infraction, this.meta.rules)
        return isMinor(penalty.duration) && isStickInfraction(infraction)
      }
    )
  }

  hasContactInfraction(penalties) {
    const isMinor = duration => duration === MINOR || duration === DOUBLE_MINOR;
    return penalties.some(
      penalty => isMinor(penalty.duration) && penalty.infraction === HEAD_CONTACT
    )
  }

  getParticipantTotal(penalties = [], doubleAsSingle = true) {
    const { participantId, id } = this.penalty;
    if (!participantId || !penalties.length) return 0;

    let filtered = penalties
      .filter(penalty => penalty.participantId == participantId && (!id || penalty.id != id))

    if (doubleAsSingle) {
      filtered = filtered.reduce((penalties, penalty) => {
        // account for double minors (two minor penalties with same goalTime)
        const double = penalties.find(existing =>
          existing.infraction === penalty.infraction && // same infraction
          isTimeEqual(existing.gameTime, penalty.gameTime, 'Hockey') // at same gameTime
        )
        if (!double) penalties.push(penalty);
        return penalties;
      }, []);
    }

    return filtered.length;
  }

  isSkater(participantId) {
    if (!participantId) return false;
    const member = this.members[participantId];
    if (!member) return false;
    return isSkater(member);
  }

  getServedBy() {
    const { participantId, servedById } = this.penalty;

    if (servedById) return servedById;
    if (!this.isSkater(participantId)) return null

    return participantId;
  }

  createPenalty(infraction, { durationId, code }, offset) {
    const penalty = {
      teamId: this.penalty.teamId,
      participantId: this.penalty.participantId,
      gameTime: this.penalty.gameTime,
      startTime: this.penalty.startTime,
      infraction,
      duration: durationId,
      code,
    }
    const duration = getLegacyDuration(durationId, this.meta.types);
    if (offset) {
      penalty.startTime = calculateGameTime(penalty.startTime, offset, this.periods);
    }
    if (duration && duration.length) {
      // this.penalty.endTime is not defined when creating a penalty; only when editing
      // allows for user to edit the end time if necessary
      if (!this.penalty.endTime) {
        penalty.endTime = calculateEndTime(penalty.startTime, duration, this.periods);
      } else if (offset && offset > 0) {
        penalty.endTime = calculateEndTime(this.penalty.endTime, offset, this.periods);
      } else {
        penalty.endTime = this.penalty.endTime;
      }

      // timed penalties and the serving member is a player
      if (duration.timed && this.isSkater(this.penalty.servedById)) {
        penalty.servedById = this.penalty.servedById;
      }
    }
    return penalty;
  }

  isValidTime() {
    if (!this.penalty || !this.penalty.gameTime) return false;
    return isTimeValid(this.penalty.gameTime, this.periods, 'Hockey');
  }

  isValidStartTime() {
    if (!this.penalty || !this.penalty.startTime) return false;
    if (isTimeBefore(this.penalty.gameTime, this.penalty.startTime, 'Hockey')) return false;
    return isTimeValid(this.penalty.startTime, this.periods, this.meta.sport);
  }
}

export class LegacySoccerPenaltyManager extends LegacyPenaltyManager {
  constructor(meta, teamId) {
    super(meta, teamId);
  }

  setPenalty(penalty, choice, infractions) {
    this.penalty.id = penalty.id;
    this.penalty.teamId = penalty.teamId;
    this.penalty.participantId = penalty.participantId;
    this.penalty.gameTime = penalty.gameTime;

    this.penalty.infraction = getLegacyInfraction(penalty.infraction, infractions);
    this.penalty.choice = choice;
  }

  getResultingPenalties(teamPenalties) {
    const penalties = [];
    const addPenalty = (infraction, duration, offset) =>
      penalties.push(this.createPenalty(infraction, duration, offset));

    this.penalty.choice.durations.forEach(duration => {
      const _duration = { ...duration }

      if (this.requiresAnyEjection(teamPenalties)) {
        _duration.durationId = YELLOW_RED
      }

      addPenalty(this.penalty.infraction.id, _duration)
    })

    return penalties;
  }

  // x2 yellow -> 1 indirect red
  requiresAnyEjection(teamPenalties) {
    const isYellow = this.penalty.choice.durations.map(({ durationId }) => durationId).includes(YELLOW)
    if (!isYellow) return false

    const existingCount = this.getParticipantTotalPreviousYellow(teamPenalties);
    return existingCount >= 1;
  }

  getParticipantTotalPreviousYellow(penalties = []) {
    const { participantId, id } = this.penalty;
    if (!participantId || !penalties.length) return 0;


    return penalties
      .filter(penalty => {
        const isCurrentPenalty = id && penalty.id === id
        const isCurrentParticipant = penalty.participantId === participantId
        const isPreviousPenalty = isTimeBefore(this.penalty.gameTime, penalty.gameTime, 'Soccer')
        const isYellow = penalty.duration === YELLOW;

        return !isCurrentPenalty && isCurrentParticipant && isPreviousPenalty && isYellow
      }).length
  }

  isValidTime() {
    if (!this.penalty || !this.penalty.gameTime) return false;
    return isTimeValid(this.penalty.gameTime, this.periods, 'Soccer');
  }
}
