import React from 'react';
import { NumberInput, SimpleForm, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { ArrayI18nInput } from '../../common/inputs/I18nInput';

import { RuleSectionInput } from './RuleSectionInput';

const validate = values => {
  const errors = {};

  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.order) errors.order = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (values.order == null) errors.order = 'ra.validation.required';

  return errors;
}

const inputProps = {
  resource: 'rules',
  basePath: '/rules',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export default props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} md={8}>
        <TextInput source="name" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput source="code" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ArrayI18nInput {...inputProps} />
      </Grid>
      <Grid item xs={12} md={8}>
        <RuleSectionInput source="sectionId" disabled {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberInput source="order" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
