import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { OfficeActions } from '../offices/OfficeActions';
import { OfficeShowHeader } from '../offices';

import { CupFilters } from './CupFilters';
import { CupView } from './CupView';
import { CupShowLayout } from './CupShow';
import { CupForm } from './CupForm';

export const CupList = props =>
  <List {...props}
    resource="offices"
    component="div"
    filters={<CupFilters />}
    filter={{ type: 'Cup' }}
    sort={{
      field: ['parent.name', 'name'],
      order: ['ASC', 'ASC'],
    }}
  >
    <CupView rowClick="show" />
  </List>

export const CupShow = props => {
  const location = useLocation();
  const officeTab = useMemo(() => {
    return location?.pathname?.substring(location.pathname.lastIndexOf('/') + 1)
  }, [ location.pathname ])

  return <Show {...props}
    title={<OfficeShowHeader />}
    style={{ maxWidth: officeTab === 'scheduler' ? 'none' : undefined }}
    actions={<OfficeActions />}
  >
    <CupShowLayout />
  </Show>
}

export const CupCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.cups.labels.add')} />}
    {...props}
  >
    <CupForm redirect="show" initialValues={{ type: 'Cup' }} hideType />
  </Create>
}

export const CupEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('resources.cups.labels.editing', { name: record.name })} />}
    {...props}
  >
    <CupForm redirect="show" />
  </Edit>
}
