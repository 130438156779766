import React, { Component, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Dialog } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { SyncProblem } from '@material-ui/icons';

const LazyBoundaryAlert = props => {
  const translate = useTranslate();
  return <Alert severity="error" icon={<SyncProblem />}>
    <AlertTitle>{translate('ra.page.error')}</AlertTitle>
    {translate('ra.message.version_error')}
  </Alert>
}

const LazyBoundaryDialog = props => {
  const [ open, setOpen ] = useState(true);
  const handleClose = () => setOpen(false);
  return <Dialog open={open} onClose={handleClose}>
    <LazyBoundaryAlert />
  </Dialog>
}

export class LazyBoundary extends Component {
  state = { error: false };

  static getDerivedStateFromError(error) {
    // eslint-disable-next-line no-console
    console.error('error', error);
    return {
      error,
    };
  }

  render() {
    const { error } = this.state;
    if (!error) return this.props.children;
    if (error.name !== 'ChunkLoadError') throw error;
    if (this.props.dialog) return <LazyBoundaryDialog />
    return <LazyBoundaryAlert />
  }
}
