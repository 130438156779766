import React from 'react';
import { get } from 'lodash';
import { FileInput } from 'react-admin';
import { useFormState } from 'react-final-form';

const GameDocumentInput = ({ placeholder, source, ...props }) => {
  const { values } = useFormState();
  const filename = get(values, source)?.rawFile?.name;

  return <FileInput
    accept="image/*, application/pdf"
    multiple={false}
    source={source}
    placeholder={filename || placeholder}
    maxSize={10000000}
    {...props}
  >
    <div></div>
  </FileInput>
}

export default GameDocumentInput;
