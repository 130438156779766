import React from 'react';
import { useRecordContext } from 'react-admin';
import { VerifiedUserOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { RoleGrid } from '../../roles/RoleGrid';
import { AddRoleButton } from '../../roles/RoleModal';

export default props => {
  const participant = useRecordContext(props);
  const showRoles = isAuthorized(participant, 'permissions', 'list')
  if (!showRoles) return null;

  return <ListCard
    title="resources.permissions.labels.administrative"
    icon={<VerifiedUserOutlined fontSize="small" />}
    reference="permissions"
    target="participants"
    label="Roles"
    sort={{ field: 'roleId', order: 'ASC' }}
    addButton={<AddRoleButton initialValues={{ principalId: participant?.id, principalType: 'Participant' }} />}
    collapsable
  >
    <RoleGrid showAccount={false} />
  </ListCard>
}
