import React, { useState } from 'react';
import { useRecordContext, useTranslate, useNotify, useRefresh, useLocale } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Replay } from '@material-ui/icons';

import { undoApprovalScoresheet } from '@hisports/scoresheet/src/actions';
import { isGameCertified } from '@hisports/scoresheet/src/selectors';

import { isAuthorized } from '../../../../common/Authorize';
import AlertDialog from '../../../../common/dialogs/AlertDialog';
import { SquareAlert } from '../../../../common/SquareAlert';

import { useScoresheet, useScoresheetDispatch } from '../../ScoresheetContext';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alert: {
    '& .MuiAlert-action': {
      paddingTop: theme.spacing(0.25),
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    }
  },
  flags: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(-2),
  }
}));

export const UndoApprovalButton = ({ gameId }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dispatch = useScoresheetDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const [ alert, setAlert ] = useState({ isOpen: false });

  const isCertified = useScoresheet(scoresheet => isGameCertified(scoresheet));

  const onClick = async () => {
    const result = await new Promise(resolve => {
      setAlert({
        title: translate('resources.scoresheets.messages.undo_approval.title'),
        message: translate('resources.scoresheets.messages.undo_approval.message'),
        reject: 'ra.action.cancel',
        accept: 'ra.action.continue',
        isOpen: true,
        onClose: result => {
          resolve(result)
          setAlert(alert => ({ ...alert, isOpen: false }))
        }
      })
    })
    if (!result) return;

    try {
      await dispatch(undoApprovalScoresheet(gameId))

      notify('resources.scoresheets.messages.successful_game_undo_approval', 'info')

      setTimeout(() => refresh(), 500);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      notify('ra.message.error', 'error')
    }
  }

  if (isCertified) return null;
  return <>
    <Button color="primary" className={classes.margin} size="small" startIcon={<Replay />} onClick={onClick}>
      {translate('resources.scoresheets.labels.undo_approval')}
    </Button>
    <AlertDialog {...alert} />
  </>
}

export const CompletedAlert = ({ showUndoApproval = false, canUndoApproval = false, square, ...props }) => {
  const game = useRecordContext(props);
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();

  const hasFullScoresheets = isAuthorized(game, 'scoresheets', 'full')
  const showUndoApprovalButton = showUndoApproval && (canUndoApproval || hasFullScoresheets)

  const viewHref = `${process.env.PDF_URL}/game/${game.id}?locale=${locale}`;

  const AlertComponent = square ? SquareAlert : Alert;

  return <AlertComponent severity="success" className={classes.alert} action={[
    showUndoApprovalButton && <UndoApprovalButton gameId={game.id} />,
    <Button color="primary" variant="contained" size="small" href={viewHref} target="_blank" onClick={e => e.stopPropagation()}>
      {translate('ra.action.view')}
    </Button>
  ]}>
    {translate('resources.scoresheets.alerts.completed')}
  </AlertComponent>
}
