import React from 'react';
import moment from 'moment-timezone';
import { Chip, Tooltip } from '@material-ui/core';
import { Star, Create, Update } from '@material-ui/icons';
import { DatagridRow, TextField } from 'react-admin';

import GroupedView from '../../common/GroupedView';
import FunctionField from '../../common/fields/FunctionField'

const diff = time => moment.duration(moment(time).diff()).humanize(true)

const Status = ({ label, tooltip, ...props }) =>
  <Tooltip title={tooltip}>
    <Chip size="small" variant="outlined" label={label} {...props} />
  </Tooltip>

const renderStatus = (article, source, resource, translate) => {
  if (!article) return null;
  if (!article.isPublished) return <Status label={translate('ra.message.draft')} tooltip={translate('ra.message.not_published')} icon={<Create />} />

  const isNew = moment().subtract(1, 'week').isBefore(article.createdAt);
  if (isNew) return <Status color="primary" variant="default" label={translate('ra.action.new')} tooltip={`${translate('ra.message.created')} ${diff(article.createdAt)}`} icon={<Star />} />

  const isRecent = moment().subtract(3, 'days').isBefore(article.updatedAt);
  if (isRecent) return <Status color="primary" label={translate('ra.message.updated')} tooltip={`${translate('ra.message.updated')} ${diff(article.updatedAt)}`} icon={<Update />} />

  return null;
}

const ArticlesList = ({ records, basePath, resource, classes }) => {
  return records.map((record, rowIndex) =>
    <DatagridRow key={record.id} basePath={basePath} resource={resource} record={record} id={record.id} rowClick="show" className={classes.row}>
      <TextField source="title" />
      <FunctionField source="updatedAt" render={renderStatus} textAlign="right" />
    </DatagridRow>
  )
}

export const ArticleView = () =>
  <GroupedView grouping={article => article.category}>
    <ArticlesList />
  </GroupedView>
