import React, { Fragment, useState } from 'react';
import { SimpleForm, useNotify, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { FORM_ERROR } from 'final-form'

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import PasswordInput from '../../../common/inputs/PasswordInput';
import { apiClient } from '../../../http';

const validate = (values, translate) => {
  const errors = {};

  if (!values.oldPassword) errors.oldPassword = 'ra.validation.required'
  if (!values.newPassword) {
    errors.newPassword = 'ra.validation.required'
  } else if (values.newPassword.length < 8) {
    errors.newPassword = translate('ra.validation.minLength', { min: 8 })
  } else if (values.oldPassword === values.newPassword) {
    errors.newPassword = 'ra.validation.different'
  } else if (!values.confirmPassword) {
    errors.confirmPassword = 'ra.validation.required'
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'ra.validation.match'
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const ChangePasswordForm = props => {
  const translate = useTranslate();

  return <SimpleForm component={Fragment} {...props} validate={(values) => validate(values, translate)}>
    <PasswordInput source="oldPassword" label="ra.input.password.old" autoComplete="current-password" autoFocus {...inputProps} />
    <PasswordInput source="newPassword" label="ra.input.password.new" autoComplete="new-password" {...inputProps} />
    <PasswordInput source="confirmPassword" label="ra.input.password.confirm" autoComplete="new-password" {...inputProps} />
  </SimpleForm>
}

export default props => {
  const [ isOpen, setOpen ] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();

  const handleError = (field, message) => ({
    [field]: message,
    [FORM_ERROR]: message,
  })

  const onSubmit = (values) => {
    return apiClient(`/accounts/change-password`, { method: 'POST', data: values })
      .then(res => res.data)
      .then(() => {
        setOpen(false)
        notify('ra.input.password.change_success', 'info')
      })
      .catch(err => {
        const code = err?.response?.data?.error?.code;
        switch (code) {
          case 'INVALID_PASSWORD':
            return handleError('oldPassword', translate('ra.input.password.invalid'))

          case 'PASSWORD_TOO_LONG':
            return handleError('newPassword', translate('ra.input.password.too_long'))

          default: {
            return handleError('confirmPassword', translate('ra.page.error_try_again'))
          }
        }
      })
  }

  return <>
    <Button color="primary" startIcon={<Lock />} onClick={() => setOpen(true)}>
      {translate('ra.input.password.change')}
    </Button>
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={() => setOpen(false)}>
      <DialogTitle>{translate('ra.input.password.change')}</DialogTitle>
      <DialogContent>
        <ChangePasswordForm save={onSubmit} toolbar={
          <DialogFormToolbar submitLabel="ra.action.change" />
        } />
      </DialogContent>
    </Dialog>
  </>
}
