import React from 'react';

import { isGameCertified, isGameCompleted } from '@hisports/scoresheet/src/selectors';

import { useMeta, useScoresheet } from '../../ScoresheetContext';

import { CertifiedAlert } from './CertifiedAlert';
import { CertifyAlert } from './CertifyAlert';

export const CertificationAlerts = ({ isAssignedOfficial, isScorekeeper, ...props }) => {
  const { meta } = useMeta();

  const isCertified = useScoresheet(scoresheet => isGameCertified(scoresheet))
  const isCompleted = useScoresheet(scoresheet => isGameCompleted(scoresheet))

  const showCertifyAlerts = isCompleted && meta?.policies?.requireCertification === true

  if (!showCertifyAlerts) return null;

  return <>
    {isCertified && <CertifiedAlert />}
    {!isCertified && <CertifyAlert />}
  </>
}
