import React from 'react';
import { ReferenceField } from 'react-admin';

import { TextLocaleField } from '../../common/fields/TextLocaleField';

export const InfractionTypeField = ({ variant = 'body2', className, ...props }) => {
  return <ReferenceField basePath="/infractionTypes" resource="infractionTypes" reference="infractionTypes" {...props} link={false}>
    <TextLocaleField source="name" variant={variant} className={className} />
  </ReferenceField>
}

InfractionTypeField.defaultProps = {
  addLabel: true,
}
