import React from 'react';
import { Button, useListContext, useNotify, useRefresh, useUnselectAll } from 'react-admin';

import { apiClient } from '../../http';


const RemovePayPeriod = ({ selectedIds = [], resource, ...props }) => {
  const { data: officialTransactions } = useListContext() || {};
  const unselectAll = useUnselectAll(resource);
  const notify = useNotify();
  const refresh = useRefresh();

  const removeableIds = selectedIds.filter(id => officialTransactions?.[id]?.payPeriodId);

  const onClick = async () => {
    apiClient('/officialTransactions/assignPayPeriod', {
      method: 'POST',
      data: {
        transactionIds: removeableIds,
      }
    })
      .then(({ data }) => {
        unselectAll()
        refresh()
        notify('resources.officialTransactions.notifications.pay_period_removed')
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        notify('resources.officialTransactions.notifications.remove_pay_period_error')
      })
  }

  return <Button label="resources.officialTransactions.actions.remove_pay_period" onClick={onClick} disabled={!removeableIds?.length} {...props} />
}

export const TransactionBulkActions = props =>
  <RemovePayPeriod {...props} />
