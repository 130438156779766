import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button, makeStyles, styled } from '@material-ui/core'
import { Announcement, CompareArrows, DirectionsRun, EmojiObjectsOutlined, HomeOutlined, PlaylistAddCheck } from '@material-ui/icons';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab'

import { useAssignmentsContext } from '../../AssignmentsContext';
import { useAssignmentContext } from '../AssignmentContext';
import { isRequested, isAtArena, isLocal, isReady, isConsecutive, getMeta } from '../util';
import { AssignerAlert as UnstyledAssigningAlert } from './SummaryTab';

const AssignerAlert = styled(UnstyledAssigningAlert)({
  margin: '8px 16px',
})

const Alert = styled(MuiAlert)({
  margin: '8px 16px',
})

const hasRequestedConflict = meta => getMeta(meta, 'Requested', 'conflict');

const OfficialList = ({ officials, selectOfficial }) => {
  const translate = useTranslate();
  const [ limited, setLimited ] = useState(officials.length > 5);
  const list = limited ? officials.slice(0, 5) : officials;
  return <>
    {list.map(official => {
      const icon = isRequested(official.flags) ? <Announcement />
        : isConsecutive(official.flags) ? <CompareArrows />
          : isAtArena(official.flags) ? <DirectionsRun />
            : isLocal(official.flags) ? <HomeOutlined />
              : <PlaylistAddCheck />;
      return <div key={official.participantId}>
        <Button size="small" startIcon={icon} onClick={() => selectOfficial(official.participantId)}>
          {official.participant.fullName.toUpperCase()}
        </Button>
      </div>
    })}
    {limited && <Button size="small" onClick={() => setLimited(false)}>{translate('ra.message.show_more', { number: officials.length - 5 })}</Button>}
  </>
}

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    flexGrow: 1,
    maxHeight: theme.spacing(42),
    width: '100%',
  },
  info: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    },
  },
  alert: {
    margin: theme.spacing(1, 0),
  }
}))

export default () => {
  const { settings } = useAssignmentsContext();
  const { meta, allOfficials, selectOfficial } = useAssignmentContext();
  const classes = useStyles();
  const translate = useTranslate();

  const { isUnassigned } = meta;

  const info = [
    isUnassigned
      ? translate('resources.games.messages.assignment.position_unassigned')
      : translate('resources.games.messages.assignment.official_already_assigned'),
    translate('resources.games.messages.assignment.select_official_to_view_availability')
  ]

  const suggestions = allOfficials.filter(official => (isRequested(official.flags) || isAtArena(official.flags) || isConsecutive(official.flags)) && !hasRequestedConflict(official.meta))
  const ready = allOfficials.filter(official => isReady(official.flags))
  const officials = [...suggestions, ...ready];

  let text;
  if (settings.requestsEnabled) {
    text = !officials.length
      ? translate('resources.games.alerts.assignment.suggestions.no_requests_or_ready_officials')
      : suggestions.length > 0
        ? translate('resources.games.alerts.assignment.suggestions.officials_requested_this_game_already')
        : translate('resources.games.alerts.assignment.suggestions.no_request_officials_ready')
  } else {
    text = !officials.length
      ? translate('resources.games.alerts.assignment.suggestions.no_ready_officials')
      : suggestions.length > 0
        ? translate('resources.games.alerts.assignment.suggestions.officials_already_arena')
        : translate('resources.games.alerts.assignment.suggestions.no_officials_this_arena')
  }
  const title = officials.length
    ? translate('resources.games.alerts.assignment.suggestions.title')
    : translate('resources.games.alerts.assignment.suggestions.title_alt')
  const severity = officials.length ? 'success' : 'warning'

  return <div className={classes.root}>
    <p className={classes.info}>{info.join(' ')}</p>

    <Alert variant="outlined" severity={severity} icon={<EmojiObjectsOutlined />}>
      <AlertTitle>{title}</AlertTitle>
      <p className={classes.alert}>{text}</p>
      <OfficialList officials={officials} selectOfficial={selectOfficial} />
    </Alert>
    <AssignerAlert />
  </div>
}
