import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, makeStyles, ListItemIcon, ListItemText } from '@material-ui/core';
import { useLocale, useTranslate } from 'react-admin';
import { HelpOutline as HelpIcon, MenuBook, LiveHelp } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.contrastText,
  }
}))

const HelpButton = ({ className, onClick }) => {
  const classes = useStyles();

  return <IconButton
    onClick={onClick}
    aria-haspopup
    className={`${className} ${classes.button}`}
  >
    <HelpIcon fontSize="small" />
  </IconButton>
}

export const HelpMenu = ({ className }) => {
  const translate = useTranslate();
  const locale = useLocale();

  const [ anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  let commonUrls = {};
  try {
    commonUrls = process.env.COMMON_URLS && JSON.parse(process.env.COMMON_URLS || '{}');
  // eslint-disable-next-line no-empty
  } catch (error) {}

  const documentationUrl = commonUrls?.[locale]?.documentation
  const supportUrl = commonUrls?.[locale]?.support

  if (!documentationUrl && !supportUrl) return null;

  return <>
    <HelpButton onClick={handleMenu} className={className} />
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      dense
    >
      {!!documentationUrl && <MenuItem component="a" href={documentationUrl} target="_blank">
        <ListItemIcon>
          <MenuBook fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={translate('resources.help.labels.documentation')} />
      </MenuItem>}
      {!!supportUrl && <MenuItem component="a" href={supportUrl} target="_blank">
        <ListItemIcon>
          <LiveHelp fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={translate('resources.help.labels.help_center')} />
      </MenuItem>}
    </Menu>
  </>
}
