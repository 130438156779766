import React from 'react';
import { LinearProgress, RecordContextProvider, useTranslate } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import Row from '../../common/cards/TableRow';
import FunctionField from '../../common/fields/FunctionField'

import { useScheduleSettings } from './index.js';

const renderGameLength = (record, source, resource, translate) => translate('ra.date.phrase.minute', record.gameLength);

const renderRegistrations = (settings, source, resource, translate) => {
  if (!settings?.includeRegistrations) return translate('resources.scheduleSettings.values.includeRegistrations');
  return translate('resources.scheduleSettings.messages.included')
}

const renderExtras = (settings, source, resource, translate) => {
  if (!settings?.allowExtras) return translate('resources.scheduleSettings.values.allowExtras');
  return translate('resources.scheduleSettings.messages.allow_extras_default')
}

const renderPoints = (settings, source, resource, translate) => {
  const { win, loss, tie } = settings.points.Team
  let result = translate('resources.scheduleSettings.fields.points.preview', { win, loss, tie })

  if (settings?.disableSportsmanshipCarry) {
    result += `. ${translate('resources.scheduleSettings.values.disableSportsmanshipCarry')}`
  }
  return result;
}

export const ScheduleSettingsField = ({ scheduleId }) => {
  const translate = useTranslate();
  const { data: scheduleSettings, loading, error } = useScheduleSettings(scheduleId);

  if (loading) return <LinearProgress />
  if (error) return translate('resources.scheduleSettings.messages.load_error');

  return <RecordContextProvider value={scheduleSettings}>
    <Table>
      <TableBody>
        <Row label="resources.scheduleSettings.labels.game_length" hidden={!Number.isInteger(scheduleSettings?.gameLength)}>
          <FunctionField source="gameLength" render={renderGameLength} />
        </Row>
        <Row label="resources.scheduleSettings.labels.include_registrations">
          <FunctionField source="includeRegistrations" allowEmpty render={renderRegistrations} />
        </Row>
        <Row label="resources.scheduleSettings.labels.allow_extras">
          <FunctionField source="allowExtras" allowEmpty render={renderExtras} />
        </Row>
        <Row label="resources.scheduleSettings.labels.points_system">
          <FunctionField source="points" allowEmpty render={renderPoints} />
        </Row>
      </TableBody>
    </Table>
  </RecordContextProvider>
}
