import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { kebabCase } from 'lodash';

import { InfractionGrid } from './InfractionGrid';
import { AddInfractionButton } from './InfractionModal';

import { ArrayGrid } from '../ArrayGrid';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const Infractions = ({ source }) => {
  const { values: { durations, infractions } } = useFormState();
  const { mutators } = useForm();

  const onSave = infraction => {
    if (!infraction.id) {
      infraction.id = kebabCase(infraction.name);
    }
    if (!infraction.isStick) {
      infraction.isStick = undefined;
    }
    if (!infraction.isArchived) {
      infraction.isArchived = undefined;
    }
    infraction.choices = infraction.choices.filter(Boolean);
    const index = infractions?.findIndex(value => value.id === infraction.id);
    if (index >= 0) {
      const { id, name, ...changes } = infraction;
      const newInfraction = Object.assign({}, infractions[index], changes);
      mutators.update(source, index, newInfraction)
    } else {
      mutators.push(source, infraction);
    }
  }

  const onDelete = infraction => {
    const index = infractions?.findIndex(value => value.id === infraction.id);
    mutators.remove(source, index);
  }

  return <>
    <ArrayGrid source={source}>
      <InfractionGrid
        save={onSave}
        canDelete
        onDelete={onDelete}
        rowOptions={{ durations }}
        {...inputProps}
      />
    </ArrayGrid>
    <AddInfractionButton save={onSave} durations={durations} />
  </>
}
