import { SimpleFormIterator } from 'react-admin';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(1),
  },
  line: {
    alignItems: 'center',
    borderBottom: 0,
  },
  form: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: `${theme.spacing(1)}px`,
  },
  index: {
    display: props => !props.hideIndex ? 'none' : 'block',
    padding: '1em 1em 0 0',
  },
})

export const TransitionProps = {
  in: true,
  appear: true,
  enter: false,
  exit: false,
}

export default withStyles(styles)(SimpleFormIterator);
