import React from 'react';
import { RecordContextProvider } from 'react-admin';

import InfoCard from '../../../common/cards/InfoCard';
import { useParticipant } from '../../../http';

import { ProfileField } from '../../../resources/profiles/ProfileField';
import { EditProfileButton } from '../../../resources/profiles/ProfileModal';

export default props => {
  const participantId = useParticipant();
  const profile = { id: participantId, participantId }

  return <RecordContextProvider value={profile}>
    <InfoCard actions={
      <EditProfileButton source="participantId" />
    }>
      <ProfileField source="participantId" />
    </InfoCard>
  </RecordContextProvider>
}
