import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button, ListItemIcon, ListItemText } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';
import { ActionMenuItem } from '../../common/ActionMenu';

import { GameForm } from './GameForm';

const AddGameDialog = props =>
  <CreateResourceDialog label="resources.games.actions.add" maxWidth="md" resource="games" basePath="/games" {...props}>
    <GameForm />
  </CreateResourceDialog>

export const EditGameDialog = props =>
  <EditResourceDialog label="resources.games.labels.edit" maxWidth="md" resource="games" basePath="/games" {...props}>
    <GameForm />
  </EditResourceDialog>

export const AddGameButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.games.actions.add')}</Button>
    <AddGameDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const AddGameAction = ({ onClick, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  const handleClick = () => {
    onClick();
    setOpen(true);
  }
  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><Add fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.games.actions.add')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <AddGameDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />}
  </>
}

export const GameDatagrid = props => <DialogDatagrid dialog={EditGameDialog} {...props} />
