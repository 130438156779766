import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { SectionForm } from './SectionForm';

const AddSectionDialog = props =>
  <CreateResourceDialog label="resources.sections.actions.add" resource="sections" basePath="/sections" {...props}>
    <SectionForm />
  </CreateResourceDialog>

const EditSectionDialog = props =>
  <EditResourceDialog label="resources.sections.labels.edit" resource="sections" basePath="/sections" {...props}>
    <SectionForm />
  </EditResourceDialog>

export const AddSectionButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.sections.actions.add')}
    </Button>
    <AddSectionDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const SectionDatagrid = props => <DialogDatagrid dialog={EditSectionDialog} {...props} />
