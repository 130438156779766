import React from 'react';
import { useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { ListItemIcon, ListItemText, ListSubheader, useTheme } from "@material-ui/core"
import { Sync } from "@material-ui/icons"

import { ActionMenuItem } from "../../../common/ActionMenu"
import { apiClient } from '../../../http';

const Recalculate = ({ gameId, method, handleClose }) => {
  const theme = useTheme();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const purple = theme.palette.tertiary.main;

  const handleClick = () => {
    handleClose();
    notify(translate('resources.games.notifications.recalculating'), { autoHideDuration: 3 * 1000 }) // 3s
    apiClient(`/games/${gameId}/${method}`, {
      method: 'POST',
    })
      .then(() => refresh())
  }
  return <ActionMenuItem size="small" onClick={handleClick}>
    <ListItemIcon><Sync htmlColor={purple} /></ListItemIcon>
    <ListItemText>{translate(`resources.games.actions.${method}`)}</ListItemText>
  </ActionMenuItem>
}

export default ({ handleClose }) => {
  const translate = useTranslate();
  const game = useRecordContext();
  if (!game) return null;

  return <>
    <ListSubheader>{translate('resources.games.actions.internal')}</ListSubheader>
    {game.isApproved && <Recalculate gameId={game.id} method="recalculateStats" handleClose={handleClose} />}
    <Recalculate gameId={game.id} method="recalculateScoresheet" handleClose={handleClose} />
    <Recalculate gameId={game.id} method="recalculateTransactions" handleClose={handleClose} />
  </>
}
