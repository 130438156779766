import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { getLineup, getLineupCounts, hasUnselectedSuspendedPlayers } from '@hisports/scoresheet/src/selectors';
import { isPlayer } from '@hisports/scoresheet/src/util';

import { useSport } from '../../../http';

import { useMeta, useScoresheet, useScoresheetStore } from '../ScoresheetContext';
import { hasDuplicateBattingOrder, hasUnselectedMembersWithRelations } from './SaveLineup';
import { useMemberValidationContext } from './MemberValidationContext';

const useStyles = makeStyles(theme => ({
  action: {
    alignItems: 'flex-start',
  },
  ul: {
    paddingLeft: theme.spacing(2),
  },
  liText: {
    display: 'inline',
    verticalAlign: 'middle'
  }
}))

export const LineupRequirements = ({ teamId, disabled }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const sport = useSport();
  const [ isOpen, setOpen ] = useState(true);
  const { meta, game } = useMeta();
  const { memberValidations } = useMemberValidationContext();
  const scoresheetStore = useScoresheetStore();
  const gameSuspensions = useSelector(state => Object.values(state.admin.resources.suspensions.data).filter(suspension => suspension.gameId === game.id))

  const { lineupLimits: limits } = meta.policies;

  const requirements = [];
  const lineup = useScoresheet(scoresheet => getLineup(scoresheet, { teamId }))
  const count = useScoresheet(scoresheet => getLineupCounts(scoresheet, { teamId, sport }))
  const team = meta.teams[teamId];

  if (disabled) return null;

  if (hasUnselectedSuspendedPlayers(scoresheetStore.getState(), team, memberValidations, sport)) {
    requirements.push({
      message: translate('resources.scoresheets.alerts.unselected_suspended_players'),
      isHard: true
    })
  }

  if (hasDuplicateBattingOrder(lineup)) {
    requirements.push({
      message: translate('resources.scoresheets.alerts.duplicate_batting_order'),
      isHard: true
    })
  }

  if (hasUnselectedMembersWithRelations(scoresheetStore.getState(), team, gameSuspensions, meta.infractions, meta.types, sport, game.seasonId)) {
    requirements.push({
      message: translate('resources.scoresheets.alerts.cannot_remove_members'),
      isHard: true
    })
  }

  if (limits.players.min > 0 && count.totalPlayer < limits.players.min) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.players.min', { min: limits.players.min }) })
  } else if (limits.players.max > 0 && count.totalPlayer > limits.players.max) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.players.max', { max: limits.players.max }) })
  }

  if (limits.skaters.min > 0 && count.totalSkater < limits.skaters.min) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.skaters.min', { min: limits.skaters.min }) })
  } else if (limits.skaters.max > 0 && count.totalSkater > limits.skaters.max) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.skaters.max', { max: limits.skaters.max }) })
  }

  if (limits.goalies.min > 0 && count.G < limits.goalies.min) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.goalies.min', { min: limits.goalies.min }) })
  } else if (limits.goalies.max > 0 && count.G > limits.goalies.max) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.goalies.max', { max: limits.goalies.max }) })
  }

  if (limits.staff.min > 0 && count.totalStaff < limits.staff.min) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.staff.min', { min: limits.staff.min }) })
  } else if (limits.staff.max > 0 && count.totalStaff > limits.staff.max) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.staff.max', { max: limits.staff.max }) })
  }

  const players = (lineup?.members || []).filter(member => isPlayer(member) && isEmpty(member?.suspension));
  if (players.some(member => !member.number)) {
    requirements.push({ message: translate('resources.scoresheets.alerts.requirements.players.missing_jersey_number') })
  }

  if (!requirements.length) return null;

  const toggle = () => setOpen(open => !open)
  const toggleAction = <IconButton onClick={toggle}>
    {isOpen ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
  </IconButton>

  return <Box m={1}>
    <Alert
      severity={requirements.some(requirement => requirement.isHard) ? "error" : "warning"}
      action={toggleAction}
      classes={classes}>
      <AlertTitle onClick={toggle}>{translate('resources.scoresheets.alerts.requirements.title')}</AlertTitle>
      {isOpen && <ul className={classes.ul}>
        {requirements.map(requirement => <li key={requirement}><Typography variant="body1" className={classes.liText}>{requirement.message}</Typography></li>)}
      </ul>}
    </Alert>
  </Box>
}
