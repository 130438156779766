import React, { Fragment } from 'react';
import { ArrayInput, SimpleForm } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';

import { DayEnumInput } from '../../common/inputs/EnumInputs';
import TimeInput from '../../common/inputs/BasicTimeInput';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import SortedFormIterator from '../../common/ra/SortedFormIterator';

import { SurfaceInput } from '../surfaces/SurfaceInput';

const validate = values => {
  const errors = {};

  errors.teamArenas = values?.teamArenas?.map(teamArena => {
    const errors = {}
    const { day, startTime, endTime, arenaId } = teamArena || {}

    if (!arenaId) errors.arenaId = 'ra.validation.required';

    // if any are set all are required
    if (day || startTime || endTime) {
      if (!day) errors.day = 'ra.validation.required';
      if (!startTime) errors.startTime = 'ra.validation.required';
      if (!endTime) errors.endTime = 'ra.validation.required';
    }

    // ensure start and end times are valid range
    if (startTime && endTime) {
      const start = moment.utc(startTime, 'HH:mm')
      const end = moment.utc(endTime, 'HH:mm')

      if (end.isSameOrBefore(start, 'minutes')) {
        errors.endTime = 'resources.games.validations.end_before_start';
      } else if (end.diff(start, 'hours') > 6) {
        errors.endTime = 'resources.games.validations.invalid_time_check_am_pm'
      }
    }

    return errors;
  })

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const useIteratorStyles = makeStyles(theme => ({
  form: {
    gridTemplateColumns: `auto repeat(3, ${theme.spacing(16)}px)`,
  },
  action: {
    width: theme.spacing(6)
  }
}))

const useStyles = makeStyles(theme => ({
  surface: {
    width: '100%',
  },
  input: {
    width: '100%',
    maxWidth: theme.spacing(16),
    minWidth: theme.spacing(16),
  }
}))

const TeamArenasInput = ({ source, ...props }) => {
  const classes = useStyles();
  const iteratorClasses = useIteratorStyles();
  return <ArrayInput source={source} label="" {...inputProps}>
    <SortedFormIterator {...inputProps} classes={iteratorClasses}>
      <SurfaceInput source="arenaId" label="resources.teamArenas.fields.arenaId" {...inputProps} classes={classes.surface} />
      <DayEnumInput source="day" label="resources.teamArenas.fields.day" allowEmpty className={classes.input} />
      <TimeInput source="startTime" label="resources.teamArenas.fields.startTime" className={classes.input} />
      <TimeInput source="endTime" label="resources.teamArenas.fields.endTime" className={classes.input} />
    </SortedFormIterator>
  </ArrayInput>;
}

export default ({ onSubmit, onCancel, ...props }) =>
  <SimpleForm component={Fragment} toolbar={<DialogFormToolbar submitLabel="ra.action.save" handleSubmit={onSubmit} onCancel={onCancel} />} save={onSubmit} validate={validate} >
    <TeamArenasInput {...props} />
  </SimpleForm>


