import React from 'react';
import { useListContext } from 'react-admin';

import { FF_GRADES } from '@hisports/common/featureFlags';

import { useFlag } from '../../http';
import DateField from '../../common/fields/DateField';
import FunctionField from '../../common/fields/FunctionField'

import { ParticipantField } from '../participants/ParticipantField';
import { OfficeField } from '../offices/OfficeField';
import { SeasonField } from '../seasons/SeasonField';

import { QualificationDatagrid } from './QualificationModal';
import { QualificationCategoryField } from './QualificationCategoryField';

const renderLevel = (record, source, resource, translate) => {
  const level = record[source];
  if (level == null) return;
  if (level === 0) return translate('resources.qualifications.values.level.zero')
  return level;
}

const renderGrades = (record, source, resource, translate) => {
  if (!record.grades) return null;
  const { Referee, Linesperson } = record.grades;
  const R = translate(`resources.games.values.assignment.position_short.Referee`)
  const L = translate(`resources.games.values.assignment.position_short.Linesperson`)
  if (!Referee && !Linesperson) return null;
  if (Referee && !Linesperson) return `${Referee}${R}`
  if (!Referee && Linesperson) return `${Referee}${L}`
  if (Referee == Linesperson) return `${Referee}`
  return `${Referee}${R} / ${Linesperson}${L}`
}

export const QualificationGrid = ({ showParticipant = false, showOffice = true, ...props }) => {
  const { ids = [], data } = useListContext(props);
  const isEnabled = useFlag()

  const qualifications = ids?.map(id => data?.[id]);
  const showGrades = isEnabled(FF_GRADES) && qualifications.some(qualification => qualification?.grades != null)
  const showExpiry = qualifications.some(qualification => qualification?.expiry != null)

  return <QualificationDatagrid size="medium" {...props}>
    <SeasonField source="seasonId" />
    {showParticipant && <ParticipantField source="participantId" includeId="inline" />}
    <QualificationCategoryField source="qualificationCategoryId" />
    <FunctionField source="level" render={renderLevel} textAlign="right" />
    {showGrades && <FunctionField source="grades" render={renderGrades} />}
    {showOffice && <OfficeField source="officeId" />}
    {showExpiry && <DateField source="expiry" format="LL" yearFormat="LL" />}
  </QualificationDatagrid>;
}
