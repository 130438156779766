import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { dedupe } from '@hisports/parsers';
import { isAdhoc, isDelegated, isDelegatedPay } from '@hisports/assigning';
import { filterOfficialsByPositionType } from '@hisports/scoresheet/src/util';

import { useParticipant, useAuthContext } from '../../../../../http';
import { isAuthorized } from '../../../../../common/Authorize';
import usePreferences from '../../../../../common/usePreferences';

import { useAssignmentsContext } from '../AssignmentsContext';
import { getAssignmentIndex } from './util';

const AssignmentContext = createContext(null);

const useAllOfficials = assignment => {
  const { settings, allOfficials } = useAssignmentsContext();
  const officeId = assignment?.officeId || settings?.officeId;

  const state = { ...allOfficials?.[officeId] }

  if (state?.data?.length) {
    state.data = filterOfficialsByPositionType(state.data, assignment);
  }

  return state
}

const useOfficialLists = assignment => {
  const { settings, lists } = useAssignmentsContext();
  const officeId = assignment?.officeId || settings?.officeId;

  return lists?.[officeId] || {}
}

const useOfficialAttributeTypes = assignment => {
  const { settings, attributeTypes } = useAssignmentsContext();
  const officeId = assignment?.officeId || settings?.officeId;

  return attributeTypes?.[officeId] || {}
}

const useCurrentOfficial = () => {
  const { currentOfficial } = useAssignmentsContext();
  return currentOfficial || {};
}

// selectedOfficial prop used when we open the quickAssign panel to avoid loading all officials and use only the selected 1
export function AssignmentProvider({ assignment, children, selectedOfficial }) {
  const { game, settings, branchSettings, openAssignmentIndexes, setOpenAssignmentIndexes, officialEnabled, assignments } = useAssignmentsContext();
  const { permissions } = useAuthContext();
  const participantId = useParticipant();
  const assignmentIndex = getAssignmentIndex(assignments, assignment?.id);

  const { data: allOfficials = [], loading: officialsLoading, error: officialsError } = useAllOfficials(assignment);
  const { data: lists = [], loading: listsLoading, error: listsError } = useOfficialLists(assignment);
  const { data: attributeTypes = [], loading: attributeTypesLoading, error: attributeTypesError } = useOfficialAttributeTypes(assignment);
  const { data: currentOfficial, loading: officialLoading, error: officialError } = useCurrentOfficial();

  const isPanelLoading = (officialEnabled ? officialLoading : officialsLoading) ||
                        (isAuthorized(game, 'games', 'assign', false, false) && listsLoading) ||
                        (isAuthorized(game, 'games', 'assign', false, false) && attributeTypesLoading)

  const [ isPanelOpen, setPanelOpen ] = useState(openAssignmentIndexes.includes(assignmentIndex) || assignment?.participantId === participantId);
  const [ selectedId, setSelected ] = useState(selectedOfficial?.id || currentOfficial?.id || assignment?.participantId); // current user if official || assigner's selected official
  const [ official, setOfficial ] = useState(selectedOfficial || currentOfficial); // gets updated when the assigner selects a new official

  useEffect(() => {
    setOpenAssignmentIndexes(indexes => {
      if (isPanelOpen) return dedupe([...indexes, assignmentIndex])
      return indexes.filter(index => index !== assignmentIndex)
    })
  }, [ isPanelOpen, assignmentIndex, setOpenAssignmentIndexes, assignment, settings ])

  useEffect(() => {
    const foundOfficial = allOfficials?.find(official => official.participantId === selectedId);
    const official = currentOfficial || selectedOfficial || foundOfficial; // if the user is an official, he can only assign itself
    setOfficial(official)
  }, [ currentOfficial, allOfficials, selectedId, setOfficial, selectedOfficial ])

  const [ isMenuOpen, setMenuOpen ] = useState(false)
  const [ isFilterMenuOpen, setFilterMenuOpen ] = useState(false);
  const [ filters, setFilters ] = usePreferences('OfficialFilters', {
    unavailable: true,
    ineligible: true,
    expired: false,
    unregistered: false,
    onlyLocal: false,
    ignoreArena: false,
    matchAllAttributes: false,
    listId: null,
  });

  const meta = useMemo(() => {
    const meta = {};

    // assignment can be null when the quick assign modal is initiated before opened
    if (!assignment) return meta;

    const isSystemUser = permissions.some(p => p.roleType === 'System');
    const permittedAssignOfficeIds = permissions.filter(p => p.roleType === 'Office' && p.scopes.includes('assigning:assign')).flatMap(p => p.officeIds).filter(Boolean)
    const permittedOfficialOfficeIds = permissions.filter(p => p.roleType === 'Participant' && p.scopes.includes('assigning:official')).flatMap(p => p.officeIds).filter(Boolean)
    const permittedAssignScheduleIds = permissions.filter(p => p.roleType === 'Schedule' && p.scopes.includes('assigning:assign')).flatMap(p => p.scheduleIds).filter(Boolean)

    const isPermittedAssignOffice = permittedAssignOfficeIds.includes(settings?.officeId) || permittedAssignOfficeIds.includes(assignment?.officeId)
    const isPermittedOfficialOffice = permittedOfficialOfficeIds.includes(settings?.officeId) || permittedOfficialOfficeIds.includes(assignment?.officeId)
    const isPermittedAssignSchedule = permittedAssignScheduleIds.includes(game.scheduleId)

    meta.isAdhoc = isAdhoc(assignment) && assignment.status === 'confirmed';
    meta.enabled = settings?.enabled && settings?.mode !== 'Integration'
    meta.certifiedOfficialChanges = branchSettings?.certifiedOfficialChanges
    meta.isDelegated = isDelegated(assignment)
    meta.isDelegatedPay = isDelegatedPay(assignment)

    meta.isPastGame = moment().isAfter(game.startTime, 'day');
    meta.isConflict = ['Conflict', 'Cancelled', 'Postponed'].includes(game.status);

    meta.isAssignedGame = assignments.some(assignment => assignment.participantId === participantId)
    meta.isUnassigned = assignment.status === 'unassigned';
    meta.isAssignedSelf = participantId && assignment.participantId === participantId;
    meta.isSupervisor = assignment.position === 'Supervisor';

    meta.isOfficial = isAuthorized(game, 'games', 'request', false, false);

    meta.canAssign = isAuthorized(game, 'games', 'assign', false, false) && (isPermittedAssignOffice || isPermittedAssignSchedule || isSystemUser)
    meta.canRequest = meta.isOfficial && ((isPermittedOfficialOffice && !meta.isSupervisor) || isSystemUser) && meta.isUnassigned && settings?.requestsEnabled && !meta.isConflict && !meta.isPastGame;
    meta.canRespond = meta.isOfficial && meta.isAssignedSelf;

    meta.isOfficialSelf = official && official.participantId === participantId
    meta.hideSupervisor = meta.isSupervisor && !meta.canAssign && !meta.isAssignedSelf && !settings.supervisorVisible
    meta.showPanel = meta.enabled && (meta.canAssign || (!game?.isApproved && (meta.canRequest || meta.canRespond))) && !isPanelLoading

    const fees = meta.isDelegatedPay ? assignment?.fees : settings?.fees
    meta.fee = fees?.[assignment.position];
    meta.isCompleted = game.isApproved || game.isCertified
    meta.isCertified = game.isCertified

    return meta;
  }, [ game, assignment, settings, branchSettings, official, participantId, permissions, isPanelLoading, assignments ])

  const value = useMemo(() => {
    const selectOfficial = participantId => {
      setSelected(selectedId => {
        if (selectedId === participantId) return null;
        return participantId;
      })
    }

    const toggleMenu = () => {
      setMenuOpen(open => !open);
    }

    const closeMenu = () => {
      setMenuOpen(false);
    }

    const togglePanel = () => {
      if (!meta.showPanel) return;
      setPanelOpen(open => !open)
    }

    const closePanel = () => {
      setPanelOpen(false)
    }

    const toggleFilterMenu = () => {
      setFilterMenuOpen(isOpen => !isOpen);
    }

    const toggleFilter = (filter, value, menuOpen = false) => {
      setFilters(filters => ({
        ...filters,
        [filter]: typeof value === 'undefined' ? !filters[filter] : value,
      }))
      setFilterMenuOpen(menuOpen)
    }

    return {
      assignment,
      meta,
      official,
      allOfficials,
      lists,
      attributeTypes,
      selectedId,
      selectOfficial,
      isMenuOpen, toggleMenu, closeMenu,
      filters, toggleFilter,
      isFilterMenuOpen, toggleFilterMenu,
      isPanelOpen, isPanelLoading, togglePanel, closePanel,
    }
  }, [ assignment, meta, official, allOfficials, lists, attributeTypes, selectedId, setSelected, isMenuOpen, setMenuOpen, filters, setFilters, isFilterMenuOpen, setFilterMenuOpen, isPanelOpen, isPanelLoading, setPanelOpen ])

  if (!game || !assignment || !meta || !filters) return null;
  return <AssignmentContext.Provider value={value}>
    {children}
  </AssignmentContext.Provider>
}

export const useAssignmentContext = () => useContext(AssignmentContext);
