import { withStyles } from '@material-ui/core';
import { SimpleFormIterator } from 'react-admin';

const styles = {
  index: {
    display: 'none',
  },
  line: {
    alignItems: 'center',
    minHeight: 40,
  }
}

export const TransitionProps = {
  in: true,
  appear: true,
  enter: false,
  exit: false,
}

export default withStyles(styles)(SimpleFormIterator);
