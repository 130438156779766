import React from 'react';
import { BooleanInput, useRecordContext } from 'react-admin';
import { PaymentOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { AssignFeesGrid } from '../../assignFees/AssignFeesGrid';
import { AssignRuleList } from '../../assignRules/AssignRuleList';
import { AddAssignFeesButton } from '../../assignFees/AssignFeesModal';

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const canAddFees = isAuthorized(office, 'assignFees', 'create')

  const filters = [
    <BooleanInput source="isArchived" defaultValue={false} />
  ]

  return <ListCard
    title="resources.assignFees.labels.card.title"
    subtitle="resources.assignFees.labels.card.subtitle"
    icon={<PaymentOutlined />}
    collapsable
    reference="assignFees"
    target="officeId"
    filter={{ seasonId, effectiveOffices: true, includeParents: true, _scope: 'Parents' }}
    filters={filters}
    addButton={canAddFees && <AddAssignFeesButton initialValues={{ officeId: office?.id, seasonId }} />}
    sort={{ field: ['isArchived', 'name'], order: ['ASC', 'ASC'] }}
  >
    <AssignFeesGrid
      rowClick="expand"
      expand={<AssignRuleList type="feesId" officeId={office?.id} />}
    />
  </ListCard>
}
