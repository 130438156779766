import React, { useCallback } from 'react';
import { Create as RACreate, useCreate, useSaveModifiers, useNotify, useRedirect, CRUD_CREATE } from 'react-admin';
import { ResourceActions } from './ResourceActions';

const Form = ({
  resource,
  basePath,
  onSuccess,
  onFailure,
  transform,
  successMessage,
  children,
  ...props
}) => {
  // save based on useCreateController
  const {
    onSuccessRef,
    setOnSuccess,
    onFailureRef,
    setOnFailure,
    transformRef,
    setTransform,
  } = useSaveModifiers({ onSuccess, onFailure, transform });
  const notify = useNotify()
  const redirect = useRedirect()

  const [ create ] = useCreate(resource)
  const save = useCallback(async (values, redirectTo, {
    onSuccess: onSuccessFromSave,
    onFailure: onFailureFromSave,
    transform: transformFromSave,
  } = {}) => {
    try {
      const data = await Promise.resolve(
        transformFromSave ? transformFromSave(values) :
          transformRef.current ? transformRef.current(values)
            : values
      )

      return await create(
        { payload: { data } },
        {
          action: CRUD_CREATE,
          onSuccess: onSuccessFromSave || onSuccessRef.current || function({ data }) {
            notify(successMessage || 'ra.notification.created', 'info', { smart_count: 1 })
            redirect(redirectTo, basePath, data.id, data)
          },
          onFailure: onFailureFromSave || onFailureRef.current || function(error) {
            const errorMessage = typeof error === 'string' ? error : error.message;
            notify(errorMessage || 'ra.notification.http_error', 'warning', { _: errorMessage })
          },
          // added for final-form to recognize the error response
          returnPromise: true,
        }
      )
    } catch (e) {
      // passed to final-form
      return e;
    }
  }, [
    transformRef,
    create,
    onSuccessRef,
    onFailureRef,
    notify,
    successMessage,
    redirect,
    basePath,
  ])

  return React.cloneElement(children, {
    ...props,
    ...children.props,
    resource,
    basePath,
    save,
    setOnSuccess,
    setOnFailure,
    setTransform,
  })
}

export function Create({ children, ...props }) {
  return <RACreate
    actions={<ResourceActions />}
    {...props}
  >
    <Form>
      {children}
    </Form>
  </RACreate>
}
