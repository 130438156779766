export const getVenueName = venue => {
  if (!venue) return;
  return venue.name;
}

export const getVenueCity = (venue, includeCountry = true, includeRegion = true, includeAddress = true, includePostalCode = true) => {
  if (!venue) return;

  let city = venue.city;
  if (includeRegion && venue.region && venue.city != venue.region) {
    city = `${city}, ${venue.region}`;
  }
  if (includePostalCode && venue.postalCode) {
    city = `${city}, ${venue.postalCode}`;
  }
  if (includeCountry && venue.country && venue.city != venue.country && city != venue.country) {
    city = `${city}, ${venue.country}`;
  }

  if (!venue.address || !includeAddress) return city;
  return `${venue.address}, ${city}`;
}
