import React, { useMemo } from 'react';
import { RecordContextProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core'
import moment from 'moment-timezone';
import { flatMap, cloneDeep } from 'lodash';

import { createRange } from '@hisports/common';

import { CALENDAR_VIEWS } from '../../resources/events/EventViewSettings';

import { GameDetails, PracticeDetails, ActivityDetails, SlotDetails, AvailabilityDetails } from './EventDetails';
import { DragAndDropGrid, SeasonDndGrid } from './DragAndDropGrid';
import { useDragContext } from './DragContext';
import { useSchedulingContext } from './SchedulingContext';
import { useCalendarContext } from './CalendarContext';

const EVENT_TYPES = {
  teamEvents: ['Game', 'Practice', 'Training', 'Meeting', 'Meal', 'Accomodation'],
  slots: ['Slot'],
  availabilities: ['Availability'],
}

const isTeamEvent = ({ type, awayTeamId, homeTeamId, number }) => EVENT_TYPES.teamEvents.includes(type) || awayTeamId || homeTeamId || number;
const isSlot = ({ type }) => EVENT_TYPES.slots.includes(type);
const isAvailability = ({ type }) => EVENT_TYPES.availabilities.includes(type);

export const getResourceByType = event => {
  if (!event?.type) return;
  switch (event.type) {
    case 'Game':
      return 'games'
    case 'Draft Game':
      return 'draftGames'
    case 'Draft':
      return 'drafts'
    case 'Training':
    case 'Meeting':
    case 'Meal':
    case 'Accomodation':
      return 'activities'
  }
  if (isSlot(event)) return 'arenaslots'
  if (isAvailability(event)) return 'availabilities'
}

/**
 * Returns the z-index value based on the zIndexProps and state.
 *
 * @param {object} zIndexProps - The properties used to calculate the z-index value
 * @param {string|null} - The state of the element ('dragged', 'hovered', null)
 * @return {number|null} The calculated z-index value or null if zIndexProps is falsy
 */
const getZIndex = (zIndexProps, state) => {
  if (state === 'dragged') return 50000;
  if (!zIndexProps) return null;

  const { event, hours, minutes } = zIndexProps;
  const baseZIndex = hours * 60 + minutes; // from 0 to 1439 based on the time of the day

  if (isAvailability(event)) {
    return 10000 + baseZIndex;
  };
  if (isSlot(event)) {
    return 20000 + baseZIndex;
  }
  if (!state) {
    return 30000 + baseZIndex;
  }
  if (state === 'hovered') {
    return 40000 + baseZIndex;
  }
}

const calculateEvents = (events, getColumnIndex, maxWidth, timezone, isGrouped) => {
  if (Array.isArray(events?.[0])) {
    return events.map(eventGroup => groupEvents(eventGroup, getColumnIndex, maxWidth, timezone, isGrouped)).flat();
  }
  return groupEvents(events, getColumnIndex, maxWidth, timezone, isGrouped);
}

/**
 * events are grouped by an overlap of the latest start time within the group + 45m
 * events within groups are equally sized
 * groups that overlap other groups offset after the last event in the previous group
 */
const groupEvents = (events, getColumnIndex, maxWidth, timezone, isGrouped = true) => {
  // split multi-day events into two
  return cloneDeep(events).reduce((events, event) => {
    const { startTime, endTime, ...details } = event;
    const startOfDay = { hours: 6, minutes: 0, seconds: 0 }
    const endOfDay = moment.tz(startTime, timezone).endOf('day')

    if (moment.tz(endTime, timezone).isSameOrBefore(endOfDay)) { // normal event
      const eventStartOfDay = moment.tz(startTime, timezone).set(startOfDay);
      if (moment.tz(startTime, timezone).isBefore(eventStartOfDay)) {
        event.layout = { startTime: eventStartOfDay.toISOString() };
      }
      events.push(event);
      return events;
    }

    // event exceeds end of day, limit to end of day, create additional events for additional days
    events.push({
      startTime,
      endTime,
      layout: { endTime: endOfDay.toISOString() },
      multiday: true,
      ...details
    });

    let days = moment.tz(endTime, timezone).diff(endOfDay, 'days') // full days
    const diff = moment.tz(endTime, timezone).diff(endOfDay, 'hours') - (days * 24) // last partial day

    while (days-- > 0) { // add full day events (days 2 and 3 of a 4-day event)
      const prevEvent = events[events.length - 1] // always exists because you always add one a few lines ago
      const prevEventStart = prevEvent.layout?.startTime || prevEvent.startTime;
      const layoutStartTime = moment.tz(prevEventStart, timezone).add(1, 'day').set(startOfDay).toISOString();
      const layoutEndTime = moment.tz(layoutStartTime, timezone).endOf('day').toISOString();
      events.push({
        startTime,
        endTime,
        layout: { startTime: layoutStartTime, endTime: layoutEndTime },
        multiday: true,
        ...details
      });
    }

    if (diff < 24) {
      const layoutStartTime = moment.tz(endTime, timezone).set(startOfDay);
      const layoutEndTime = moment.tz(endTime, timezone);
      if (layoutEndTime.isAfter(layoutStartTime)) {
        events.push({
          startTime,
          endTime,
          layout: { startTime: layoutStartTime.toISOString(), endTime: layoutEndTime.toISOString() },
          multiday: true,
          ...details
        });
      }
    }
    return events;
  }, [])
  // sort events into groups
    .reduce((groups, event) => {
      const { startTime, endTime, layout } = event;
      const start = layout?.startTime || startTime;
      const end = layout?.endTime || endTime;
      const eventRange = createRange(start, end);
      const group = groups.findLast(group => {
      // group end time is 45m after the latest start time within the group
        const range = createRange(group.startTime, group.offsetTime);
        return eventRange.overlaps(range)
      })
      if (!group) {
        groups.push({
          startTime: start,
          endTime: end,
          offsetTime: moment.tz(start, timezone).add(45, 'minutes').toISOString(),
          events: [event],
        })
        return groups;
      }
      group.events.push(event);
      group.startTime = group.events.reduce((lastTime, event) => {
        const groupEventStart = event.layout?.startTime || event.startTime;
        if (!lastTime) return start;
        if (moment(groupEventStart).isBefore(lastTime)) return groupEventStart;
        return lastTime;
      }, null);
      group.endTime = group.events.reduce((lastTime, event) => {
        const groupEventEnd = event.layout?.endTime || event.endTime;
        if (!lastTime) return groupEventEnd;
        if (moment(groupEventEnd).isAfter(lastTime)) return groupEventEnd;
        return lastTime;
      }, null);

      if (group.events.length < 4) {
        const lastTime = group.events.reduce((lastTime, event) => {
          const groupEventStart = event.layout?.startTime || event.startTime;
          if (!lastTime) return groupEventStart;
          if (moment(groupEventStart).isAfter(lastTime)) return groupEventStart;
          return lastTime;
        }, null);
        group.offsetTime = moment.tz(lastTime, timezone).add(45, 'minutes').toISOString();
      } else {
      // group.offsetTime = moment(group.startTime).add(60, 'minutes').toISOString();
        const groupEventEnd = group.events[0].layout?.endTime || group.events[0].endTime;
        group.offsetTime = moment.tz(groupEventEnd, timezone).toISOString();
      }
      return groups;
    }, [])
  // determine overlapping groups and an offset from the last event in the parent group
    .reduce((groups, group) => {
      const groupRange = createRange(group.startTime, group.offsetTime)
      group.parent = groups.find(parent => {
        const range = createRange(parent.startTime, parent.offsetTime)
        return range.overlaps(groupRange)
      })
      if (!group.parent) {
        group.offset = 0;
        group.width = maxWidth;
      } else {
        const { width, events } = group.parent;
        const eventWidth = width / events.length;
        group.offset = (eventWidth * (events.length - 1)) + 10;
        group.width = maxWidth - group.offset;
      }
      groups.push(group);
      return groups;
    }, [])
  // flatten events with group relations
    .reduce((events, group, id) => {
      events.push(...group.events.map(event => {
        return {
          ...event,
          grouping: group,
        }
      }))
      return events;
    }, [])
  // calculate layout metadata
    .map(event => {
      const { id, startTime, endTime, grouping, layout } = event;
      const date = moment.tz(layout?.startTime || startTime, timezone);
      const index = grouping.events.findIndex(groupEvent => groupEvent.id === id);
      const eventWidth = isGrouped ? (grouping.width / grouping.events.length) : maxWidth;
      const colIndex = getColumnIndex(event, timezone);
      if (colIndex < 0) return;

      return {
        event,
        layout: {
          colIndex,
          hours: date.hours(),
          minutes: date.minutes(),
          duration: moment(layout?.endTime || endTime).diff(layout?.startTime || startTime, 'minutes'),
          width: eventWidth,
          groupWidth: grouping.width,
          offset: grouping.offset + (eventWidth * index),
        }
      }
    })
    .filter(Boolean);
}

const combineAvailabilities = (availabilities = []) => {
  if (!availabilities.length) return availabilities;

  availabilities = availabilities
    .map(availability => ({
      ...availability,
      range: createRange(availability.startTime, availability.endTime),
    }))
    .sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

  const decoupled = [];
  let decoupling = true;
  let time;

  do {
    if (!time) {
      // first time
      time = availabilities[0].startTime;
    }
    // find next time from availabilities
    const times = flatMap(availabilities, availability => [availability.startTime, availability.endTime]);
    const nextTime = times.sort((a, b) => new Date(a) - new Date(b)).find(t => t > time);

    if (!nextTime) {
      // no more times from availabilities
      decoupling = false;
      break;
    }
    // create new availability with new times and availability status
    const newAvailabilityRange = createRange(time, nextTime);
    const newAvailabilityOverlaps = availabilities.filter(e => newAvailabilityRange.overlaps(e.range, { adjacent: false }));
    const isAvailable = newAvailabilityOverlaps.some(availability => availability.isAvailable === true);
    const isUnavailable = newAvailabilityOverlaps.some(availability => availability.isAvailable === false);

    const newAvailability = {
      ...newAvailabilityOverlaps[0],
      startTime: time,
      endTime: nextTime,
      isMixed: isAvailable && isUnavailable,
      overlaps: newAvailabilityOverlaps,
    }

    if (newAvailability.id) {
      // filter out empty events
      decoupled.push(newAvailability);
    }

    time = nextTime;
  } while (decoupling);

  return decoupled;
}

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    height: props => props.height,
    width: props => props.width,

    position: 'absolute',
    // if isDnDItem, top and left are handled by gridLayout in a parent node (DragAndDropItem)
    top: props => !props.isDnDItem ? props.top : null,
    left: props => !props.isDnDItem ? props.left : null,
    zIndex: props => getZIndex(props.zIndexProps),

    '&:hover': {
      transform: props => `translate(${-props.offsetLeft}px, 0px)`,
      width: props => props.hoverWidth,
      zIndex: props => getZIndex(props.zIndexProps, 'hovered'),
    }
  },
  /* dndItem uses default gridLayout's style, with a couple of tweeks */
  dndItem: {
    boxSizing: 'border-box',
    zIndex: props => getZIndex(props.zIndexProps, props.isDragged ? 'dragged' : null),
    left: props => props.left, /* offsets positioning when many events are in the same place */

    '&:hover': {
      zIndex: props => getZIndex(props.zIndexProps, props.isDragged ? 'dragged' : 'hovered'),
    }
  }
}))

const TeamEventDetails = ({ event, inactive, className, ...props }) => {
  switch (event.type) {
    case 'Training':
    case 'Meeting':
    case 'Meal':
    case 'Accomodation':
      return <ActivityDetails activity={event} inactive={inactive} className={className} {...props} />

    case 'Practice':
      return <PracticeDetails practice={event} inactive={inactive} className={className} {...props} />

    case 'Game':
    default:
      return <GameDetails game={event} inactive={inactive} className={className} {...props} />
  }
}

export const EventDetails = ({ event, inactive, className, ...props }) => {
  if (isAvailability(event)) {
    return <AvailabilityDetails availability={event} className={className} {...props} />
  }
  if (isSlot(event)) {
    return <SlotDetails slot={event} className={className} {...props} />
  }
  return <TeamEventDetails event={event} inactive={inactive} className={className} {...props} />
}


const Event = ({ event, size, offset, offsetHours, layout, isDnDItem, offsetLeft, ...props }) => {
  const { resource, selectedGame } = useSchedulingContext();
  const { draggingEvent } = useDragContext();
  const isDragged = draggingEvent.id && draggingEvent.id == event.id;

  const { colIndex, hours, minutes, duration } = layout;
  const minute = size.height / 60

  const columnOffset = colIndex * size.width;
  const hoursOffset = (hours - offsetHours) * size.height;
  const minutesOffset = minutes * minute;

  const classes = useStyles({
    height: duration * minute,
    width: layout.width,
    isDnDItem,
    top: hoursOffset + minutesOffset,
    left: columnOffset + offset.width + layout.offset,
    offsetLeft, // left applied to the parent to offset from the other event in the same spot
    hoverWidth: layout.groupWidth,
    zIndexProps: {
      event, hours, minutes
    },
  })

  if (isDragged) {
    const { startTime, endTime } = draggingEvent;
    event.startTime = startTime;
    event.endTime = endTime;
  }

  return <EventDetails event={event} className={classes.root} {...props} />
}

export const TeamEvents = ({ events, size, offset, rowHeights, offsetHours, startDate, rowClick, ...props }) => {
  const { columns, filterEvents, getColumnIndex, timezone, pushSplitSurfaceEvents, type } = useCalendarContext();

  const layoutTeamEvents = useMemo(() => {
    let teamEvents = events.filter(event => isTeamEvent(event));
    if ([CALENDAR_VIEWS.MONTH, CALENDAR_VIEWS.SEASON].includes(type)) return filterEvents(teamEvents);

    teamEvents = pushSplitSurfaceEvents(teamEvents);
    teamEvents = filterEvents(teamEvents);
    return calculateEvents(teamEvents, getColumnIndex, size.width - 15, timezone, true);
  }, [ events, type, pushSplitSurfaceEvents, filterEvents, getColumnIndex, size.width, timezone ]);

  const DndGrid = type === CALENDAR_VIEWS.SEASON ? SeasonDndGrid : DragAndDropGrid;

  return <DndGrid columns={columns} cellSize={size} offset={offset} rowHeights={rowHeights} offsetHours={offsetHours}>
    {({ getDndItem, offsetHours }) => (
      layoutTeamEvents.map(({ event, layout }) => {
        const dndItem = getDndItem(layout, event, offsetHours);

        return (
          <DragAndDropItem key={dndItem?.key} data-grid={dndItem?.grid} styleOverrides={dndItem?.styleOverrides} eventId={event?.id}>
            <RecordContextProvider value={event}>
              <Event event={event} size={size} offset={offset} offsetHours={offsetHours} layout={layout} rowClick={rowClick} isDnDItem offsetLeft={dndItem?.styleOverrides.left} {...props} />
            </RecordContextProvider>
          </DragAndDropItem>
        )
      })
    )}
  </DndGrid>
}

// gridLayout from DragAndDropGrid sends props to the direct child. this needs to be the direct child
const DragAndDropItem = React.forwardRef(({ styleOverrides, eventId, children, className: gridLayoutClassName, style: gridLayoutStyle, ...gridLayoutProps }, gridLayoutRef) => {
  const { dragging, draggingEvent } = useDragContext();
  const classes = useStyles(
    {
      left: styleOverrides?.left,
      zIndexProps: styleOverrides?.zIndexProps,
      isDragged: draggingEvent && draggingEvent?.id === eventId
    }
  );

  const isDragged = draggingEvent && draggingEvent?.id === eventId
  const draggedZIndex = dragging && isDragged ? getZIndex(null, 'dragged') : null; // draggedZIndex needs to be in style to be updated on rerender.

  return (
    <div
      className={`${classes.dndItem} ${gridLayoutClassName}`}
      style={{ ...gridLayoutStyle, zIndex: draggedZIndex }}
      ref={gridLayoutRef}
      {...gridLayoutProps}
    >
      {children}
    </div>
  )
})

export const Slots = ({ events, size, offset, offsetHours, startDate, rowClick }) => {
  const { filterEvents, getColumnIndex, timezone, pushSplitSurfaceEvents, type } = useCalendarContext();
  const layoutSlots = useMemo(() => {
    let slots = events.filter(event => isSlot(event));
    if ([CALENDAR_VIEWS.MONTH, CALENDAR_VIEWS.SEASON].includes(type)) return;

    slots = pushSplitSurfaceEvents(slots);
    slots = filterEvents(slots);
    return calculateEvents(slots, getColumnIndex, size.width - 15, timezone, true);
  }, [ events, type, pushSplitSurfaceEvents, filterEvents, getColumnIndex, size.width, timezone ]);

  if (!layoutSlots?.length) return null;
  return layoutSlots.map(({ event, layout }) =>
    <RecordContextProvider value={event}>
      <Event event={event} size={size} offset={offset} offsetHours={offsetHours} layout={layout} rowClick={rowClick} />
    </RecordContextProvider>
  )
}

export const Availabilities = ({ events, size, offset, offsetHours, startDate, rowClick }) => {
  const { filterEvents, getColumnIndex, timezone, pushSplitSurfaceEvents, type } = useCalendarContext();
  const layoutAvailabilities = useMemo(() => {
    let availabilities = events.filter(event => isAvailability(event));
    if ([CALENDAR_VIEWS.MONTH, CALENDAR_VIEWS.SEASON].includes(type)) return;

    availabilities = pushSplitSurfaceEvents(availabilities);
    availabilities = filterEvents(availabilities);
    const combinedAvailabilities = availabilities.map(availability => combineAvailabilities(availability));
    return calculateEvents(combinedAvailabilities, getColumnIndex, size.width - 8, timezone, true);
  }, [ events, type, pushSplitSurfaceEvents, filterEvents, getColumnIndex, size.width, timezone ]);

  if (!layoutAvailabilities?.length) return null;
  return layoutAvailabilities.map(({ event, layout }) =>
    <RecordContextProvider value={event}>
      <Event event={event} size={size} offset={offset} offsetHours={offsetHours} layout={layout} rowClick={rowClick} />
    </RecordContextProvider>
  )
}
