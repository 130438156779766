import { HOME, AWAY } from '../constants.js';

export const getTeamById = (teams, teamId) => {
  if (!teams) return;
  return teams[teamId];
}

export const getTeamType = (game, teamId) => {
  if (game.homeTeamId == teamId) return HOME;
  if (game.awayTeamId == teamId) return AWAY;
}

export const isNDTeam = (teams, teamId) => {
  if (!teamId) return false;
  const team = getTeamById(teams, teamId);
  if (!team || !team.HCRId) return false;
  return team.HCRId.startsWith('NDT');
}
