import React, { Fragment } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { SimpleForm, useTranslate } from 'react-admin';

import { OfficeInput } from '../../resources/offices/OfficeInput';
import { useOffice } from '../inputs/OfficeSelector';

import { DialogFormToolbar } from './DialogForm';

const Toolbar = ({ handleSubmit, onClose }) => {
  return <DialogFormToolbar
    onCancel={onClose}
    handleSubmit={handleSubmit}
    submitLabel="ra.action.select"
  />
}

const OfficeSelectorForm = ({ onClose, handleSave, filter }) => {
  const translate = useTranslate();
  const officeId = useOffice();

  return <SimpleForm component={Fragment} toolbar={<Toolbar onClose={onClose} />} save={handleSave} initialValues={{ selectedOfficeId: officeId }} >
    <OfficeInput source="selectedOfficeId" filter={filter} fullWidth variant="outlined" margin="none" label={translate('resources.offices.name')} />
  </SimpleForm>
}

export default ({ open, onClose, handleSave, filter }) => {
  const translate = useTranslate();

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>{translate('components.office_selector.labels.title')}</DialogTitle>
    <DialogContent>
      <OfficeSelectorForm onClose={onClose} handleSave={handleSave} filter={filter} />
    </DialogContent>
  </Dialog>
}
