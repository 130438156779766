import React, { useMemo, useRef } from 'react';
import { GET_MANY_REFERENCE, NumberInput, SimpleForm, useQuery, useRecordContext, useTranslate } from "react-admin";
import { useFormState } from 'react-final-form';
import { isEmpty, sortBy } from 'lodash';
import { Grid, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import createCalculator from 'final-form-calculate';

import { getGameMembers, getLineup, getPenalties } from "@hisports/scoresheet/src/selectors";
import { displayTime, getMember, getMemberPositionGroup, getPenaltyDetails, isPlayerPosition, isStaffPosition } from '@hisports/scoresheet/src/util';

import { useSport } from "../../../http";
import { useLocale } from '../../../locale';
import { DurationTypeInput, EnumInput, SuspensionPositionGroupInput, SuspensionStatusInput } from '../../../common/inputs/EnumInputs';
import Toolbar from '../../../common/ra/Toolbar';
import { DateInput } from '../../../common/inputs/DateInput';
import { TeamInput } from '../../teams/TeamInput';

import { ScoresheetProvider, useMeta, useScoresheet, useScoresheetStore } from "../ScoresheetContext";
import { getMemberDescription } from '../lineup/MemberItem';
import { useSuspensionsWithStore } from '../../suspensions/useSuspensions';
import { useSeason } from '../../../common/inputs/SeasonSelector';

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(3),
    marginInline: 'auto'
  }
}))

const validate = (values, translate) => {
  const errors = {};

  if (!values.teamId) errors.teamId = 'ra.validation.required';
  if (!values.participantId) errors.participantId = 'ra.validation.required';
  if (!values.positionGroup) errors.positionGroup = 'ra.validation.required';

  if (values.durationType === 'Definite') {
    if (values.requiredGames == undefined) {
      errors.requiredGames = 'ra.validation.required';
    } else if (values.requiredGames < 1) {
      errors.requiredGames = translate('ra.validation.minValue', { min: 1 });
    }
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const PenaltyInput = ({ allowEmpty = true, allowReuse = true, requireIncidentReport = false, ...props }) => {
  const sport = useSport()
  const translate = useTranslate()
  const [ locale ] = useLocale();
  const { game, meta } = useMeta()
  const currentSuspension = useRecordContext()

  const { data: suspensions } = useSuspensionsWithStore(game?.id);

  const penalties = useScoresheet(scoresheet => getPenalties(scoresheet, meta.infractions, meta.types, sport, game.seasonId, { requireIncidentReport }))
  const members = useScoresheet(scoresheet => getGameMembers(scoresheet));

  const choices = penalties.map(penalty => {
    const { id, gameTime } = penalty
    const isCurrent = currentSuspension?.penaltyId === id
    const isExist = Object.values(suspensions).find(suspension => suspension.penaltyId === id)
    const disabled = !allowReuse && !isCurrent && isExist
    const name = `${displayTime(gameTime, sport)} - ${getPenaltyDetails(penalty, meta.infractions, meta.rules, meta.types, penalties, members, {
      served_abbr: translate('resources.scoresheets.messages.served_abbr'),
      coach: translate('resources.scoresheets.labels.coach'),
      bench: translate('resources.scoresheets.labels.bench_staff'),
      start: translate('resources.scoresheets.labels.start'),
      ended: translate('resources.scoresheets.labels.ended'),
    }, sport, game.seasonId, locale)}`
    return { id, name, disabled };
  })

  return <EnumInput select allowEmpty={allowEmpty} emptyText="ra.message.none" choices={choices} optionDescription="description" {...props} />
}

const GameTeamInput = props => {
  const { game } = useMeta();
  const { values } = useFormState();

  return <TeamInput source="teamId" filter={{ id: { inq: [game.homeTeamId, game.awayTeamId] } }} disabled={values?.penaltyId} forceSelect {...props} />
}

const useAdditionalTeamIds = (participantId, suspensionTeamId) => {
  const { data } = useQuery({
    type: GET_MANY_REFERENCE,
    resource: 'members',
    payload: {
      target: 'participants',
      id: participantId,
      filter: {},
    }
  }, {
    enabled: participantId != null,
  })

  return { teamIds: data?.map(team => team.teamId).filter(team => team !== suspensionTeamId) || [] };
}

const SuspensionAdditionalTeamInput = props => {
  const seasonId = useSeason();
  const { values } = useFormState();
  const { teamIds } = useAdditionalTeamIds(values?.participantId, values?.teamId)

  return <TeamInput source="additionalPurgingTeamId" filter={{ id: { inq: teamIds }, seasonId }} {...props} />
}

const MemberInput = props => {
  const translate = useTranslate();
  const { values } = useFormState();
  const scoresheetStore = useScoresheetStore();

  const choices = useMemo(() => {
    if (!values?.teamId) return []
    const { members = [] } = getLineup(scoresheetStore.getState(), { teamId: values.teamId }) || {};
    return sortBy(members, 'number').map(member => {
      const { id } = member.participant;
      const name = member.number ? `${member.participant.fullName} (${member.number})` : member.participant.fullName;
      const description = getMemberDescription(member, translate);
      return { id, name, description };
    })
  }, [values?.teamId, scoresheetStore, translate])

  return <EnumInput choices={choices} optionDescription="description" disabled={!values?.teamId || values?.penaltyId} {...props} />
}

const PositionGroupInput = props => {
  const { values } = useFormState();
  const members = useScoresheet(scoresheet => getGameMembers(scoresheet))

  const selectedMember = getMember(members, values.participantId)
  const disablePositionGroup = !(selectedMember?.positions?.some(isPlayerPosition) && selectedMember?.positions?.some(isStaffPosition))

  return <SuspensionPositionGroupInput disabled={disablePositionGroup} {...props} />
}

const RequiredGamesInput = ({ disabled, ...props }) => {
  const { values } = useFormState();
  const isIndefiniteSuspension = values?.durationType === 'Indefinite'

  return <NumberInput disabled={disabled || isIndefiniteSuspension} {...props} />
}

const ExpiryDateInput = ({ disabled, ...props }) => {
  const { values } = useFormState();
  const isIndefiniteSuspension = values?.durationType === 'Indefinite'

  return <DateInput disabled={disabled || isIndefiniteSuspension} {...props} />
}

const AutomaticSuspensionAlert = props => {
  const translate = useTranslate();
  const suspension = useRecordContext();
  const classes = useStyles()

  const isAutomaticSuspension = suspension?.code

  if (!isAutomaticSuspension) return null
  return <>
    <Alert severity="info" className={classes.alert} {...props}>
      <AlertTitle>{translate('resources.suspensions.alerts.automatic.title')}</AlertTitle>
      {translate('resources.suspensions.alerts.automatic.message')}
    </Alert>
    <br />
  </>
}

export const InnerSuspensionForm = props => {
  const translate = useTranslate();
  const { meta, game } = useMeta();
  const sport = useSport();
  const suspension = useRecordContext();

  const members = useScoresheet(scoresheet => getGameMembers(scoresheet))
  const penalties = useScoresheet(scoresheet => getPenalties(scoresheet, meta.infractions, meta.types, sport, game.seasonId))

  const decorators = useRef([createCalculator({
    field: 'penaltyId',
    updates: {
      teamId: (penaltyId, values, prevValues) => {
        const penalty = penalties.find(penalty => penalty.id === penaltyId)
        return penalty?.teamId || values.teamId;
      },
      participantId: (penaltyId, values, prevValues) => {
        const penalty = penalties.find(penalty => penalty.id === penaltyId)
        return penalty?.participantId || values.participantId;
      },
    }
  }, {
    field: 'teamId',
    updates: {
      participantId: (teamId, values, prevValues) => {
        const penalty = penalties.find(penalty => penalty.id === values.penaltyId)

        if (penalty?.participantId) return penalty.participantId

        if (!isEmpty(prevValues) && teamId !== prevValues.teamId) return null

        return values.participantId
      },
    }
  }, {
    field: 'participantId',
    updates: {
      positionGroup: (participantId, values, prevValues) => {
        return getMemberPositionGroup(members, participantId)
      },
    }
  }, {
    field: 'durationType',
    updates: {
      requiredGames: (durationType, values, prevValues) => {
        if (durationType === 'Indefinite') return
        return values?.requiredGames
      },
      expiry: (durationType, values, prevValues) => {
        if (durationType === 'Indefinite') return
        return values?.expiry
      },
    }
  })])

  const isAutomaticSuspension = suspension?.code || suspension?.sanctionId;

  return <SimpleForm decorators={decorators.current} validate={values => validate(values, translate)} toolbar={<Toolbar hideDelete />} {...props}>
    <Grid container spacing={2} fullWidth>
      <AutomaticSuspensionAlert />
      <Grid item xs={12}>
        <PenaltyInput source="penaltyId" helperText="ra.message.optional" disabled={isAutomaticSuspension} label="resources.suspensions.fields.penaltyId" {...inputProps} />
      </Grid>
      <Grid item md={6} xs={12}>
        <GameTeamInput source="teamId" disabled={isAutomaticSuspension} {...inputProps} />
      </Grid>
      <Grid item md={6} xs={12}>
        <SuspensionAdditionalTeamInput source="additionalPurgingTeamId" label="resources.suspensions.fields.additionalPurgingTeamId" {...inputProps} />
      </Grid>
      <Grid item md={6} xs={12}>
        <MemberInput source="participantId" disabled={isAutomaticSuspension} {...inputProps} />
      </Grid>
      <Grid item md={12} xs={12}>
        <DurationTypeInput source="durationType" disabled={isAutomaticSuspension} label="resources.suspensions.fields.durationType" helperText="" radio {...inputProps} />
      </Grid>
      <Grid item md={6} xs={12}>
        <RequiredGamesInput source="requiredGames" disabled={isAutomaticSuspension} label="resources.suspensions.fields.requiredGames" helperText="" {...inputProps} />
      </Grid>
      <Grid item md={6} xs={12}>
        <ExpiryDateInput source="expiry" disabled={isAutomaticSuspension} label="resources.suspensions.fields.expiry" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item md={6} xs={12}>
        <PositionGroupInput source="positionGroup" label="resources.suspensions.fields.positionGroup" helperText="" {...inputProps} />
      </Grid>
      <Grid item md={6} xs={12}>
        <SuspensionStatusInput source="status" label="resources.suspensions.fields.status" helperText="" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}

export const SuspensionForm = props => {
  const suspension = useRecordContext();

  if (!suspension?.gameId) return <InnerSuspensionForm {...props} />

  return <ScoresheetProvider gameId={suspension.gameId} hideAlerts>
    <InnerSuspensionForm {...props} />
  </ScoresheetProvider>
}
