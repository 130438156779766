import React from 'react'

import { Alert } from '@material-ui/lab';
import { useTranslate } from 'react-admin';

export default function IncidentAlert() {
  const translate = useTranslate();

  return <Alert severity="warning" >
    {translate('resources.scoresheets.alerts.missing_incidents')}
  </Alert>
}
