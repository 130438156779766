import { useQueryWithStore, GET_MANY_REFERENCE } from "react-admin";

export const useIncidentReports = (gameId, enabled = true) =>
  useQueryWithStore({
    type: GET_MANY_REFERENCE,
    resource: 'gameIncidents',
    payload: {
      target: 'gameId',
      id: gameId,
      pagination: { page: 1, perPage: 999 },
      filter: {},
    },
  }, { enabled, action: 'CUSTOM_QUERY' })
