import React from 'react';
import { Button, IconButton, useMediaQuery } from "@material-ui/core";

export const ResponsiveButton = ({ icon: Icon, children, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  if (fullScreen) {
    return <IconButton {...props}><Icon /></IconButton>
  }
  return <Button startIcon={<Icon />} {...props}>{children}</Button>
}
