import React from 'react';
import { useRecordContext } from 'react-admin';
import { ForumOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { ContactGrid } from '../../contacts/ContactGrid';
import { AddContactButton } from '../../contacts/ContactModal';

export default props => {
  const participant = useRecordContext(props);

  const showContacts = isAuthorized(participant, 'contacts', 'list')
  const canAddContact = isAuthorized(participant, 'contacts', 'create')

  if (!showContacts) return null;
  return <ListCard
    title="resources.contacts.labels.card.title"
    icon={<ForumOutlined />}
    reference="contacts"
    target="participants"
    sort={{
      field: ['isPrimary', 'type'],
      order: ['DESC', 'ASC'],
    }}
    addButton={canAddContact && <AddContactButton initialValues={{ participantId: participant?.id }} />}
  >
    <ContactGrid />
  </ListCard>
}
