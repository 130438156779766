import React from 'react';
import moment from 'moment';
import { Pagination, useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { OfficialGrid } from '../../officialOffices/OfficialGrid';
import { AddOfficialButton } from '../../officialOffices/OfficialModal';
import ImportOfficials from '../dialogs/officials';
import { ParticipantIcon } from '../../icons';

import { OfficialQualificationsGrid } from '../../qualifications/OfficialQualificationsGrid';
import { OfficialParticipantInput } from '../../officials/OfficialParticipantInput';

const OFFICIAL_TYPE_RESOURCE_MAP = {
  'Official': 'officials',
  'Scorekeeper': 'scorekeepers',
}

const filterProps = {
  variant: 'outlined',
  options: {
    helperText: undefined,
    style: { marginRight: 0 }
  },
  helperText: false,
  margin: 'normal',
}

export default ({ type = 'Official', ...props }) => {
  const office = useRecordContext(props);
  const officialOffice = useRecordContext();
  const today = moment().format('YYYY-MM-DD');

  const showOfficials = isAuthorized(office, 'officialOffices', 'show');
  const canAddOfficials = isAuthorized(office, 'officialOffices', 'create');
  const canImportOfficials = isAuthorized(office, 'officialOffices', 'importOfficials');

  if (!showOfficials) return null;

  const filter = { effectiveOffices: true, types: { inq: [type] }, or: [{ expiry: { gte: today } }, { expiry: { eq: null } }] }
  const filters = [
    <OfficialParticipantInput source="officialname" label="resources.officialOffices.filters.officialName" alwaysOn {...filterProps} />
  ]

  const addButton = canAddOfficials && <AddOfficialButton initialValues={{ officeId: office?.id }} />
  const actions = [
    canImportOfficials && <ImportOfficials officeId={office?.id} size="small" alwaysOn />,
  ].filter(Boolean);

  return <ListCard
    title={`resources.${OFFICIAL_TYPE_RESOURCE_MAP[type]}.labels.card.title`}
    subtitle={`resources.${OFFICIAL_TYPE_RESOURCE_MAP[type]}.labels.card.subtitle`}
    icon={<ParticipantIcon />}
    reference="officialOffices"
    target="officeId"
    filter={filter}
    filters={filters}
    sort={{
      field: ['office.name', 'participant.lastName', 'participant.firstName'],
      order: ['DESC', 'ASC', 'ASC'],
    }}
    pagination={<Pagination />}
    addButton={addButton}
    actions={actions}
    collapsable
  >
    <OfficialGrid
      rowClick="expand"
      expand={<OfficialQualificationsGrid />}
    />
  </ListCard>
}
