import { clone } from '@hisports/common/src/lodash.js';

import {
  SETTINGS_UPDATED,
  GOAL_SCORED,
  GOAL_EDITED,
  GOAL_DELETED,
  PENALTY_STARTED,
  PENALTY_ENDED,
  PENALTY_EDITED,
  PENALTY_DELETED,
  GOALIE_CHANGED,
  GAME_ENDED,
  GOALIE_CHANGE_DELETED,
  GOALIE_CHANGE_EDITED,
  SCORESHEET_RESET,
  GAME_STARTED
} from '../actions/index.js'
import { compareTime, isTimeAfter } from '../util/index.js';
import { INITIAL_TIME } from '../constants.js';
import { isGameEndedOrCompleted } from '../selectors/index.js';

export const initialState = clone(INITIAL_TIME);

export default function gameTime(state, action, scoresheet, sport) {
  state = typeof state !== 'undefined' ? state : initialState[sport];

  if (action.type === SCORESHEET_RESET) return initialState[sport];

  // ignore changes after the game end time
  if (action.type !== GAME_ENDED && isGameEndedOrCompleted(scoresheet)) return state;

  // ensure latest period and game time, in case new/edited goals/penalties are in past
  switch (action.type) {
    case GAME_STARTED: {
      const { periods } = action.payload.event;
      if (!periods || !periods.length || !periods[0]) return state;

      const period = periods[0];
      return clone(period);
    }
    case SETTINGS_UPDATED: {
      const { periods } = action.payload.event;
      if (!isFinite(state.period) || !periods) return state;

      const period = periods[parseInt(state.period) - 1];
      // if setting is after period, use setting, otherwise keep state
      return isTimeAfter(state, period, sport) ? clone(period) : state;
    }

    case GOAL_SCORED:
    case PENALTY_STARTED:
    case GOALIE_CHANGED:
    case GAME_ENDED: {
      const { gameTime } = action.payload.event;
      // if gameTime is after the state, use the gameTime, otherwise keep state
      return isTimeAfter(state, gameTime, sport) ? clone(gameTime) : state;
    }

    case PENALTY_ENDED: {
      const { endTime } = action.payload.event;
      // if endTime is after the state, use the endTime, otherwise keep state
      return isTimeAfter(state, endTime, sport) ? clone(endTime) : state;
    }

    case GOAL_EDITED:
    case GOAL_DELETED:
    case PENALTY_EDITED:
    case PENALTY_DELETED:
    case GOALIE_CHANGE_EDITED:
    case GOALIE_CHANGE_DELETED: {
      // use latest goal/penalty/goalie time, ignoring current as it may be the time being edited
      const times = [...scoresheet.goals, ...scoresheet.penalties, ...scoresheet.goalies]
        .map(event => event.gameTime)

      if (!times.length) return state;

      times.sort((a, b) => compareTime(a, b, sport));
      return clone(times[times.length - 1]);
    }

    default:
      return state;
  }
}
