import React from 'react';
import { ReferenceField } from 'react-admin';

import { TextLocaleField } from '../../common/fields/TextLocaleField';

export const RoleField = ({ variant = 'body2', ...props }) =>
  <ReferenceField reference="roles" link={false} {...props}>
    <TextLocaleField source="name" variant={variant} />
  </ReferenceField>

RoleField.defaultProps = {
  addLabel: true,
}
