import React from 'react';
import { NumberInput, SimpleForm, TextInput, useTranslate } from 'react-admin';
import { Grid, InputAdornment } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';

import SwitchInput from '../../common/inputs/SwitchInput';
import { ArrayI18nInput } from '../../common/inputs/I18nInput';

const validate = values => {
  const errors = {};

  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (values.order == null) errors.order = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'infractionTypes',
  basePath: '/infractionTypes',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const DurationInput = props => {
  const translate = useTranslate();
  return <NumberInput {...props} InputProps={{
    endAdornment: <InputAdornment position="end">
      {translate('ra.input.adornments.seconds')}
    </InputAdornment>
  }}
  />
}

export default props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} md={8}>
        <TextInput source="name" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput source="abbreviation" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ArrayI18nInput {...inputProps} />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextInput source="description" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <DurationInput source="duration" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberInput source="order" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberInput source="servingOrder" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <br />
      <Grid item xs={12}>
        <SwitchInput source="isShorthanded" helperText="resources.infractionTypes.helpers.isShorthanded" {...inputProps} />
        <SwitchInput source="isEjection" helperText="resources.infractionTypes.helpers.isEjection" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
