import React, { useRef } from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';

import { useSport } from '../../http';
import Toolbar from '../../common/ra/Toolbar';
import { SportsEnumInput, SurfaceSizeEnumInput, SurfaceTypeEnumInput } from '../../common/inputs/EnumInputs';
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';

import { VenueInput } from '../venues/VenueInput';

import { SurfaceInput } from './SurfaceInput';

const validate = surfaces => values => {
  const errors = {};

  if (!values.venueId) errors.venueId = 'ra.validation.required'
  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.fraction && values.parentId) errors.fraction = 'ra.validation.required'
  if (!values.type) errors.type = 'ra.validation.required'
  if (!values.size) errors.size = 'ra.validation.required'
  if (!values.sports?.length) errors.sports = 'ra.validation.required'

  if (values.parentId && values.name) {
    const parent = surfaces[values.parentId]
    if (parent && parent.name !== values.name) errors.name = 'ra.validation.invalid';
  }

  return errors;
}

const inputProps = {
  resource: 'surfaces',
  basePath: '/surfaces',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const ParentSurfaceInput = props => {
  const { values } = useFormState();
  return <SurfaceInput source="parentId" filter={{ venueId: values?.venueId }} disabled={!values?.venueId} {...props} />
}

const NameInput = ({ disabled, ...props }) => {
  const { values, initialValues } = useFormState();
  const isSplitSurface = values.parentId != null;
  return <TextInput disabled={disabled || (isSplitSurface && !initialValues[props.source])} {...props} />
}

const FractionInput = ({ disabled, ...props }) => {
  const { values, initialValues } = useFormState();
  const isSplitSurface = values.parentId != null;
  return <TextInput disabled={disabled || (!isSplitSurface && !initialValues[props.source])} {...props} />
}

export const SurfaceForm = ({ initialValues = {}, ...props }) => {
  const [ isSynced, toggle ] = useRecordSynced(props)
  const sport = useSport();

  const surfaces = useSelector(store => store.admin.resources.surfaces.data);
  const surfacesRef = useRef({});
  surfacesRef.current = surfaces;

  // decorators must be wrapped in useRef otherwise SimpleForm prop will register duplicates
  // this bug seems to be triggered by use of hooks (useSelector)
  const decorators = useRef([createCalculator({
    field: 'parentId',
    updates: {
      name: (parentId, values) => {
        if (!parentId) return values.name;
        const parent = surfacesRef.current[parentId];
        if (!parent?.name) return values.name;
        return parent.name;
      },
      type: (parentId, values) => {
        if (!parentId) return values.type;
        const parent = surfacesRef.current[parentId];
        if (!parent?.type) return values.type;
        return parent.type;
      },
    }
  })])

  const isSplitSurface = initialValues.parentId;
  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm initialValues={{ sports: [ sport ], ...initialValues }} decorators={decorators.current} toolbar={<Toolbar isSynced={isSynced} />} validate={validate(surfacesRef.current)} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} md={6}>
          <VenueInput source="venueId" disabled={isSynced || initialValues.venueId} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ParentSurfaceInput source="parentId" disabled={isSynced || isSplitSurface} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={4}>
          <NameInput source="name" label="resources.surfaces.labels.name" autoFocus={!isSplitSurface} disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FractionInput source="fraction" autoFocus={isSplitSurface} disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="alias" helperText="resources.surfaces.helpers.alias" {...inputProps} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SurfaceTypeEnumInput source="type" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SurfaceSizeEnumInput source="size" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SportsEnumInput source="sports" {...inputProps} />
        </Grid>
      </Grid>
    </SimpleForm>
  </>
}
