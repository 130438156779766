import React from 'react';
import { ArrayInput } from 'react-admin';

import { translateApiProperty } from '@hisports/common';

import { useLocale } from '../../locale';

import { EnumInput } from '../../common/inputs/EnumInputs';
import SortedFormIterator from '../../common/ra/SortedFormIterator';

const PropertyInput = ({ schema, ...props }) => {
  const [ locale ] = useLocale();

  if (!schema) return <EnumInput {...props} choices={[]} helperText="resources.scheduleSettings.helpers.schema" disabled />

  const properties = Object.keys(schema.schema.properties);
  const choices = properties.map(id => {
    const { displayOptions } = schema.schema.properties[id];
    const abbreviation = translateApiProperty(displayOptions, 'abbreviation', locale);
    const description = translateApiProperty(displayOptions, 'description', locale);
    return {
      id,
      name: description || abbreviation,
      description: abbreviation || description,
    }
  })
  return <EnumInput {...props} choices={choices} optionDescription="description" />
}

const TypeInput = props =>
  <EnumInput {...props} choices={[
    { id: 'All Games', name: 'resources.scheduleSettings.values.ranking_type.All Games' },
    { id: 'Head-to-head', name: 'resources.scheduleSettings.values.ranking_type.Head-to-head' }
  ]} />

const OrderInput = props =>
  <EnumInput {...props} choices={[
    { id: 'DESC', name: 'resources.scheduleSettings.values.ranking_order.DESC', description: 'resources.scheduleSettings.messages.high_low' },
    { id: 'ASC', name: 'resources.scheduleSettings.values.ranking_order.ASC', description: 'resources.scheduleSettings.messages.low_high' },
  ]} optionDescription="description" />

export const RankingInput = ({ schema, ...props }) =>
  <ArrayInput {...props}>
    <SortedFormIterator>
      <PropertyInput source="property" label="resources.scheduleSettings.labels.points_system_ranking.property" schema={schema} fullWidth />
      <OrderInput source="order" label="resources.scheduleSettings.labels.points_system_ranking.order" />
      <TypeInput source="type" label="resources.scheduleSettings.labels.points_system_ranking.type" />
    </SortedFormIterator>
  </ArrayInput>
