import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

export const ListField = props =>
  <ReferenceField basePath="/lists" resource="lists" reference="lists" link={false} allowEmpty {...props} >
    <TextField source="name" />
  </ReferenceField>

ListField.defaultProps = {
  addLabel: true,
}
