import React from 'react';
import { FunctionField, ReferenceField, useListContext, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';


import DateField from '../../common/fields/DateField';
import { PopoverTextField } from '../../common/fields/PopoverTextField';
import { CurrencyField } from '../../common/fields/CurrencyField';
import { EnumField } from '../../common/inputs/EnumInputs';

import { ParticipantField } from '../participants/ParticipantField';
import { OfficeField } from '../offices/OfficeField';
import { GameField } from '../games/GameField';
import { PayPeriodField } from '../payPeriods/PayPeriodField';

import { TransactionDatagrid } from './TransactionModal';

const isSelectable = (transactions, payPeriods = {}) => id => {
  const transaction = transactions?.[id]
  if (transaction?.type === 'Base') return false;
  const payPeriod = payPeriods[transaction?.payPeriodId];
  if (!payPeriod) return true;
  return payPeriod.isClosed !== true;
}

export const AmountAndDescriptionField = props =>
  <FunctionField
    render={() => {
      return <div style={{ display: 'inline-flex', gap: 4 }}>
        <CurrencyField source="amount" />
        <PopoverTextField source="description" />
      </div>
    }}
    {...props}
  />

const SubtypeField = props => {
  const translate = useTranslate()
  return <FunctionField
    render={({ subtype, type }) => {
      if (!subtype) return null;
      if (type === 'Base') {
        return translate(`resources.games.values.assignment.position.${subtype}`)
      }
      return translate(`resources.officialTransactions.values.subtype.${subtype}`)
    }}
    {...props}
  />
}

const ClaimNoteField = props => {
  return <ReferenceField basePath="/officialClaims" resource="officialClaims" reference="officialClaims" link={false} {...props}>
    <PopoverTextField source="note" />
  </ReferenceField>
}

export const TypeAndNoteField = props => {
  return <FunctionField render={({ subtype, claimId }) => {
    return <div style={{ display: 'inline-flex', gap: 4, flexWrap: 'wrap' }}>
      <EnumField source="type" />
      {subtype && <Typography variant="body2" color="textSecondary">–</Typography>}
      {subtype && <SubtypeField source="subtype" color="textSecondary" />}
      <ClaimNoteField source="claimId" />
    </div>
  }}
  {...props} />
}

export const TransactionsGrid = ({ hideGame = false, hideParticipant = false, ...props }) => {
  const { data: transactions } = useListContext();
  const payPeriods = useSelector(state => state?.admin?.resources?.payPeriods?.data)

  const isRowSelectable = isSelectable(transactions, payPeriods);

  return <TransactionDatagrid size="medium" rowOptions={{ isRowSelectable }} {...props}>
    {!hideParticipant && <ParticipantField source="participantId" link="show" includeId="inline" sortable={false} />}
    {!hideGame && <GameField source="gameId" includeDate="inline" includeTime includeSurface="full" sortable={false} />}
    <OfficeField source="officeId" sortable={false} />
    <DateField source="date" format="LL" sortable={false} />
    <TypeAndNoteField source="type" sortable={false} />
    <AmountAndDescriptionField source="amount" sortable={false} />
    <PayPeriodField source="payPeriodId" sortable={false} />
  </TransactionDatagrid>
}
