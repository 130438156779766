import React from 'react';
import { NumberInput, SimpleForm } from 'react-admin';
import { useFormState } from 'react-final-form'

import { isAuthorized } from '../../common/Authorize';
import Toolbar from '../../common/ra/Toolbar';
import { MemberPositionsEnumInput, PlayerPositionsEnumInput } from '../../common/inputs/EnumInputs';
import SwitchInput from '../../common/inputs/SwitchInput';
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';
import { useScopes } from '../../http';

import { ParticipantInput } from '../participants/ParticipantInput';

const players = ['F', 'C', 'D', 'G']
const isPlayer = positions => positions && positions.some(position => players.includes(position))
const validate = values => {
  const errors = {};

  if (!values.participantId) errors.participantId = 'ra.validation.required'
  if (!values.teamId) errors.teamId = 'ra.validation.required'
  if (!values.positions || !values.positions.length) errors.positions = 'ra.validation.required'

  const number = Number(values.number);
  if (isPlayer(values.positions)) {
    if (values.number == null) {
      errors.number = 'resources.members.validations.must_have_number';
    } else if (isNaN(number) || number < 0) {
      errors.number = 'ra.validation.invalid_number';
    }
    if (values.isOverage && values.isUnderage) {
      errors.isOverage = 'ra.validation.invalid';
      errors.isUnderage = 'ra.validation.invalid';
    }
  }

  return errors;
}

const PlayerNumberInput = ({ children, ...props }) => {
  const { values } = useFormState();

  if (!isPlayer(values.positions)) return null;
  return <NumberInput {...props} />
}

const PlayerBooleanInput = ({ children, ...props }) => {
  const { values } = useFormState();

  if (!isPlayer(values.positions)) return null;
  return <SwitchInput {...props} />
}

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const PositionsEnumInput = ({ canChangeStatus, disabled, ...props }) => {
  const { initialValues } = useFormState();
  const isPlayer = initialValues && initialValues.id && initialValues.positions.every(position => ['F', 'C', 'D', 'G'].includes(position));

  if (isPlayer && !canChangeStatus) return <PlayerPositionsEnumInput disabled={disabled} {...props} />
  return <MemberPositionsEnumInput {...props} disabled={disabled || !(canChangeStatus || isPlayer)} />
}

export const MemberForm = props => {
  const scopes = useScopes();
  const canChangeStatus = isAuthorized(props.initialValues?.id ? props.initialValues : scopes, 'members', 'status')
  const [ isSynced, toggle ] = useRecordSynced(props);

  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm resource="members" toolbar={<Toolbar isSynced={isSynced} />} validate={validate} {...props}>
      <ParticipantInput source="participantId" filter={{ _scope: 'Tenant' }} disabled={props.initialValues?.id || props.initialValues?.participantId || isSynced} {...inputProps} />
      <PositionsEnumInput source="positions" canChangeStatus={canChangeStatus} disabled={isSynced} {...inputProps} />
      <PlayerNumberInput source="number" {...inputProps} />
      <PlayerBooleanInput source="isCaptain" helperText="resources.members.helpers.isCaptain" {...inputProps} />
      <PlayerBooleanInput source="isAlternate" helperText="resources.members.helpers.isAlternate" {...inputProps} />
      {canChangeStatus && <PlayerBooleanInput source="isAffiliate" disabled={isSynced} {...inputProps} />}
      {canChangeStatus && <PlayerBooleanInput source="isOverage" disabled={isSynced} {...inputProps} />}
      {canChangeStatus && <PlayerBooleanInput source="isUnderage" disabled={isSynced} {...inputProps} />}
      {canChangeStatus && <SwitchInput source="isReleased" disabled={isSynced} {...inputProps} />}
    </SimpleForm>
  </>
}
