import React from 'react';
import { ReferenceField } from 'react-admin';

import { TextLocaleField } from '../../common/fields/TextLocaleField';

export const DivisionField = ({ variant = 'body2', ...props }) => {
  return <ReferenceField basePath="/divisions" resource="divisions" reference="divisions" {...props} link={false}>
    <TextLocaleField source="name" variant={variant} />
  </ReferenceField>
}

DivisionField.defaultProps = {
  addLabel: true,
}
