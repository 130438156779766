import React from 'react';
import { TextInput, SimpleForm, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { SeasonInput, ScheduleTypeEnumInput } from '../../common/inputs/EnumInputs';
import { CategoryInput } from '../categories/CategoryInput';

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.type) errors.type = 'ra.validation.required'
  return errors;
}

const inputProps = {
  resource: 'groups',
  basePath: '/groups',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const GroupForm = props => {
  const translate = useTranslate();
  return <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <SeasonInput source="seasonId" disabled {...inputProps} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextInput source="name" autoFocus {...inputProps} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ScheduleTypeEnumInput source="type" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <CategoryInput source="categoryIds" select multiple allowEmpty emptyLabel={translate('ra.message.all')} helperText="resources.groups.helpers.categoryIds" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
