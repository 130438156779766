import React from 'react';

import MaskedTextInput from './MaskedTextInput';

export const timeMask = value => {
  if (/^[2]/.test(value)) return [/\d/, /[0-3]/, ':', /[0-5]/, /\d/];
  return [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
}

export default props =>
  <MaskedTextInput maskOptions={{ mask: timeMask }} {...props} />
