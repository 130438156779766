import React from 'react';
import { useRecordContext } from 'react-admin'
import { Grid } from '@material-ui/core';


import { useScopes } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { TabbedShowLayout, Tab } from '../../common/ra/TabbedShowLayout';

import AvailabilityCard from '../availabilities/AvailabilityCard';
import { useBranchSettings } from '../branchOfficeSettings';

import TeamCard from './cards/TeamCard';
import RosterCard from './cards/RosterCard';
import SchedulesCard from './cards/SchedulesCard';
import EventsCard from './cards/EventsCard';
import TeamArenasCard from './cards/TeamArenasCard';
import RolesCard from './cards/RolesCard';
import NotificationsCard from './cards/NotificationsCard';
import SuspensionsCard from './cards/SuspensionsCard';
import GamePenaltiesCard from './cards/GamePenaltiesCard';
import AssignTargetsCard from './cards/AssignTargetsCard';
import ReportsCard from '../reports/ReportsCard';

const SummaryTab = props => {
  const team = useRecordContext(props);
  const scopes = useScopes();
  const { data: branchSettings } = useBranchSettings(team?.officeId)

  const canListAvailabilities = isAuthorized(team, 'availabilities', 'list');
  const canEditAvailabilities = isAuthorized(team, 'availabilities', 'edit');
  const showSuspensions = isAuthorized(team, 'suspensions', 'show') && branchSettings?.ff_suspensions;
  const showGamePenalties = isAuthorized(team, 'gamepenalties', 'show')
  const showReports = isAuthorized(scopes, 'reports', 'list');

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={9} lg={6}>
        <TeamCard />
      </Grid>
      {canListAvailabilities && <Grid item xs={12}>
        <AvailabilityCard collapsable editable={canEditAvailabilities} />
      </Grid>}
      {showGamePenalties && <Grid item xs={12}>
        <GamePenaltiesCard collapsable />
      </Grid>}
      {showSuspensions && <Grid item xs={12}>
        <SuspensionsCard collapsable />
      </Grid>}
      {showReports && <Grid item xs={12}>
        <ReportsCard targetType="Team" teamId={team.id} />
      </Grid>}
    </Grid>
  </Tab>
}

const RosterTab = props =>
  <Tab {...props}>
    <RosterCard />
  </Tab>

const ScheduleTab = props => {
  const team = useRecordContext(props);
  if (!isAuthorized(team, 'games', 'list')) return null;

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SchedulesCard />
      </Grid>
      <Grid item xs={12}>
        <EventsCard />
      </Grid>
    </Grid>
  </Tab>
}

const SettingsTab = props => {
  const team = useRecordContext(props);
  if (!isAuthorized(team, 'teams', 'settingsTab')) return null;

  const showOverrides = isAuthorized(team, 'assignTargets', 'list', true);
  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TeamArenasCard />
      </Grid>
      <Grid item xs={12}>
        <RolesCard />
      </Grid>
      <Grid item xs={12}>
        <NotificationsCard />
      </Grid>
      {showOverrides && <Grid item xs={12}>
        <AssignTargetsCard />
      </Grid>}
    </Grid>
  </Tab>
}

export const TeamShowLayout = props =>
  <TabbedShowLayout>
    <SummaryTab label="resources.teams.labels.tabs.summary" />
    <RosterTab label="resources.teams.labels.tabs.roster" path="roster" />
    <ScheduleTab label="resources.teams.labels.tabs.schedule" path="schedule" />
    <SettingsTab label="resources.teams.labels.tabs.settings" path="settings" />
  </TabbedShowLayout>
