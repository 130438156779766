import React, { useState } from 'react'
import { useRecordContext, useTranslate } from 'react-admin'
import { Button, ListSubheader, Menu } from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { FF_BRACKETS, FF_SEQUENCES } from '@hisports/common/featureFlags'

import { isAuthorized } from '../../Authorize'

import { GenerateDraftGamesAction } from '../../../resources/schedules/dialogs/generator'
import { AddGameAction } from '../../../resources/games/GameModal'
import { AddDraftGameAction } from '../../../resources/draftgames/DraftGameModal'
import { ImportDraftGamesAction } from '../../../resources/draftgames/dialogs'
import { AddScheduleSequenceAction } from '../../../resources/schedulesequences/ScheduleSequenceModal'
import { AddBracketButton } from '../../../resources/schedules/dialogs/BracketDialog'
import { useFlag } from '../../../http'

export const AddMenu = ({ hideAddGame = false, hideAddDraft = false, size = "small", ...props }) => {
  const schedule = useRecordContext();
  const translate = useTranslate();
  const isEnabled = useFlag()
  const [ anchor, setAnchor ] = useState(null);

  const canCreateDrafts = isAuthorized(schedule, 'draftGames', 'create');
  const canCreateGames = isAuthorized(schedule, 'games', 'create');
  const canCreateSequences = isAuthorized(schedule, 'schedulesequences', 'create') && isEnabled(FF_SEQUENCES);
  const showBrackets = isEnabled(FF_BRACKETS) && ['Playoffs', 'Tournament', 'Cup'].includes(schedule?.type)

  const handleOpen = e => {
    setAnchor(e.currentTarget);
  }
  const handleClose = () => {
    setAnchor(null);
  }

  if (hideAddGame && hideAddDraft) return null;
  if (!canCreateDrafts && !canCreateGames) return null;

  return <>
    <Button color="primary" size={size} startIcon={<Add />} onClick={handleOpen}>
      {translate('resources.draftGames.actions.create')}
    </Button>
    <Menu
      open={Boolean(anchor)}
      keepMounted
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
    >
      {!hideAddDraft && canCreateDrafts && <ListSubheader>{translate('resources.draftGames.name', 2)}</ListSubheader>}
      {!hideAddDraft && canCreateDrafts && <GenerateDraftGamesAction onClick={handleClose} {...props} />}
      {!hideAddDraft && canCreateDrafts && <ImportDraftGamesAction onClick={handleClose} {...props} />}
      {!hideAddDraft && canCreateDrafts && <AddDraftGameAction initialValues={{ scheduleId: schedule?.id }} onClick={handleClose} {...props} />}

      {!hideAddGame && canCreateGames && <ListSubheader>{translate('resources.games.name', 2)}</ListSubheader>}
      {!hideAddGame && canCreateGames && showBrackets && <AddBracketButton scheduleId={schedule?.id} onClick={handleClose} {...props} />}
      {!hideAddGame && canCreateGames && <AddGameAction initialValues={{ scheduleId: schedule?.id }} onClick={handleClose} {...props} />}

      {!hideAddGame && canCreateSequences && <ListSubheader>{translate('resources.schedulesequences.name', 2)}</ListSubheader>}
      {!hideAddGame && canCreateSequences && <AddScheduleSequenceAction initialValues={{ scheduleId: schedule?.id }} onClick={handleClose} />}
    </Menu>
  </>
}


