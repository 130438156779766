import React, { cloneElement, useState } from 'react';
import { Popover as MuiPopover } from '@material-ui/core';

export const Popover = ({ children, popoverContent, width = '400px', ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBlur = () => {
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <div>
    <div>
      {cloneElement(children, { onClick: handleClick })}
    </div>
    <MuiPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      onBlur={handleBlur}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      PaperProps={{
        style: { width }
      }}
      {...props}
    >
      {cloneElement(popoverContent, { handleClose })}
    </MuiPopover>
  </div>
}

