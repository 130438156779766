import React, {
  useCallback,
  useState,
  useMemo,
  isValidElement,
} from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab'
import {
  useInput,
  useTranslate,
  FieldTitle,
  useChoices,
  warning,
  InputHelperText,
  useResourceContext
} from 'react-admin';
import { orderBy, debounce } from 'lodash'
import { matchSorter } from 'match-sorter';

import AutocompleteSuggestion from './AutocompleteSuggestion';

const getOptionDescription = (option, optionDescription, translate) => {
  if (!option || !optionDescription) return;
  const description = option[optionDescription];
  return translate(description, { _: description, smart_count: 1 })
}

/**
* An Input component for an autocomplete field, using an array of objects for the options
*
* Pass possible options as an array of objects in the 'choices' attribute.
*
* By default, the options are built from:
*  - the 'id' property as the option value,
*  - the 'name' property an the option text
* @example
* const choices = [
*    { id: 'M', name: 'Male' },
*    { id: 'F', name: 'Female' },
* ];
* <AutocompleteInput source="gender" choices={choices} />
*
* You can also customize the properties to use for the option name and value,
* thanks to the 'optionText' and 'optionValue' attributes.
* @example
* const choices = [
*    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
*    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
* ];
* <AutocompleteInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
*
* `optionText` also accepts a function, so you can shape the option text at will:
* @example
* const choices = [
*    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
*    { id: 456, first_name: 'Jane', last_name: 'Austen' },
* ];
* const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
* <AutocompleteInput source="author_id" choices={choices} optionText={optionRenderer} />
*
* `optionText` also accepts a React Element, that will be cloned and receive
* the related choice as the `record` prop. You can use Field components there.
* Note that you must also specify the `matchSuggestion` prop
* @example
* const choices = [
*    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
*    { id: 456, first_name: 'Jane', last_name: 'Austen' },
* ];
* const matchSuggestion = (filterValue, choice) => choice.first_name.match(filterValue) || choice.last_name.match(filterValue);
* const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
* <SelectInput source="gender" choices={choices} optionText={<FullNameField />} matchSuggestion={matchSuggestion} />
*
* The choices are translated by default, so you can use translation identifiers as choices:
* @example
* const choices = [
*    { id: 'M', name: 'myroot.gender.male' },
*    { id: 'F', name: 'myroot.gender.female' },
* ];
*
* However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
* the choice to be translated. In that case, set the `translateChoice` prop to false.
* @example
* <AutocompleteInput source="gender" choices={choices} translateChoice={false}/>
*
* The object passed as `options` props is passed to the material-ui <TextField> component
*
* @example
* <AutocompleteInput source="author_id" options={{ color: 'secondary', InputLabelProps: { shrink: true } }} />
*/
const AutocompleteInput = ({
  allowDuplicates = false,
  className,
  classes: classesOverride,
  choices = [],
  debounce: debounceDelay = 250,
  emptyText,
  emptyValue,
  format,
  fullWidth,
  helperText,
  id: idOverride,
  input: inputOverride,
  isRequired: isRequiredOverride,
  label,
  limitChoicesToValue,
  margin = 'dense',
  matchSuggestion,
  multiple,
  meta: metaOverride,
  nullable = false,
  onBlur,
  onChange,
  onFocus,
  options: {
    suggestionComponentProps,
    labelProps,
    InputProps,
    ...inputOptions
  } = {
    suggestionComponentProps: undefined,
    labelProps: undefined,
    InputProps: undefined,
  },
  optionText = 'name',
  optionValue = 'id',
  optionDescription,
  disableSort,
  parse,
  placeholder = "ra.input.autocomplete.type_to_search",
  setFilter,
  shouldRenderSuggestions: shouldRenderSuggestionsOverride,
  source,
  suggestionLimit,
  translateChoice = true,
  validate,
  variant = 'filled',
  loading,
  groupBy,
  alwaysGroup = false,
  inputValueMatcher,
  filterKeys,
  suggestionComponent,
  disabled,
  disableClearable,
  hideDisabled,
  getOptionDisabled,
  minFilterLength = 0,
  emptyOptionsText,
  noResultsText,
  disableRemove,
  renderTags,
  ...rest
}) => {
  warning(
    isValidElement(optionText) && !matchSuggestion,
    `If the optionText prop is a React element, you must also specify the matchSuggestion prop:
<AutocompleteInput
  matchSuggestion={(filterValue, suggestion) => true}
/>
      `
  );

  const resource = useResourceContext(rest);
  const translate = useTranslate();
  const classes = useStyles({ classes: classesOverride });
  const { id, input, isRequired, meta: { touched, error, submitError } } = useInput({
    format,
    id: idOverride,
    input: inputOverride,
    meta: metaOverride,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const debouncedSetFilter = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
    debounce(setFilter || DefaultSetFilter, debounceDelay)
    , [setFilter, debounceDelay]);

  const [filterValue, setFilterValue] = useState('');
  const handleFilterChange = useCallback((event, value, reason) => {
    // getSuggestionFromValue will cause selectedValue to change, which will trigger a reset with no event or value.
    // must ignore otherwise filter value will reset.
    if (event == null && (value == null || value == '') && reason === 'reset') {
      if (multiple) return;
      return setFilterValue('');
    }
    if (multiple && event.type !== 'blur' && reason === 'reset') return;

    setFilterValue(value);
    debouncedSetFilter(value);
  }, [ multiple, debouncedSetFilter, setFilterValue]);

  const { getChoiceText, getChoiceValue } = useChoices({ optionText, optionValue });

  const getSuggestionFromValue = useCallback(
    value => choices.find(choice => getChoiceValue(choice) === value)
    , [ choices, getChoiceValue ]);

  const selectedValue = useMemo(() => {
    if (multiple) {
      if (!input.value || !Array.isArray(input.value)) return EmptyArray;
      return input.value.map(getSuggestionFromValue);
    }
    if (nullable) return getSuggestionFromValue(input.value);
    return getSuggestionFromValue(input.value) || '';
  }, [multiple, input.value, nullable, getSuggestionFromValue]);

  const handleChange = useCallback((event, item, reason, details) => {
    // prevent backspacing from removing an item while tags are hidden from the input
    if (disableRemove && reason === 'remove-option') return;
    const value = Array.isArray(item) ? item.map(getChoiceValue) : getChoiceValue(item)

    input.onChange(value);
  }, [getChoiceValue, input, disableRemove]);

  const handleOptionSelected = (option, value) => option === value && !(multiple && allowDuplicates);

  let options = choices.filter(Boolean);
  const grouped = groupBy && (alwaysGroup || options?.length > 5);
  if (grouped && !disableSort) {
    options = orderBy(options, groupBy)
  }

  const sorter = (grouped || disableSort) ? items => items : undefined;

  let _filterOptions;
  if (inputValueMatcher) {
    _filterOptions = (options, { inputValue }) => {
      if (!inputValue) return options;
      return options.filter(option => inputValueMatcher(inputValue, option));
    }
  } else if (filterKeys) {
    _filterOptions = (options, { inputValue }) => {
      if (!inputValue) return options;
      return matchSorter(options, inputValue, { keys: filterKeys, sorter });
    }
  } else {
    _filterOptions = (options, { inputValue }) => {
      if (!inputValue) return options;
      return matchSorter(options, inputValue, { keys: [getChoiceText], sorter });
    }
  }

  const isTooShort = (length = 0) => minFilterLength && length < minFilterLength

  const filterOptions = (options, input) => {
    if (isTooShort(input.inputValue?.length)) return [];
    let results = _filterOptions(options, input);
    if (hideDisabled) {
      results = results.filter(option => !getOptionDisabled(option))
    }
    return results;
  }

  const renderOption = useCallback((option, { selected }) => {
    if (!option) return null;
    const suggestion = !suggestionComponent
      ? <AutocompleteSuggestion primary={getChoiceText(option)} secondary={getOptionDescription(option, optionDescription, translate)} />
      : React.createElement(suggestionComponent, {
        ...suggestionComponentProps,
        suggestion: option,
      })

    return <>
      {suggestion}
      {selected && <Done fontSize="small" />}
    </>
  }, [ suggestionComponent, suggestionComponentProps, optionDescription, getChoiceText, translate ])

  let noOptionsText = translate('ra.message.no_options');
  if (emptyOptionsText && (!filterValue?.length || isTooShort(filterValue.length))) {
    noOptionsText = translate(emptyOptionsText, { _: emptyOptionsText, smart_count: 1 })
  } else if (noResultsText && filterValue?.length > 0) {
    noOptionsText = translate(noResultsText, { _: noResultsText, smart_count: 1 })
  }

  return <Autocomplete
    id={id}
    includeInputInList
    forcePopupIcon
    openOnFocus
    disableClearable={disableClearable}
    disableCloseOnSelect={multiple}
    selectOnFocus={false}
    translateChoice={translateChoice}

    multiple={multiple}
    loading={loading}
    disabled={disabled}

    value={selectedValue}
    onChange={handleChange}
    options={options}
    filterOptions={filterOptions}
    filterSelectedOptions={false}
    getOptionLabel={getChoiceText}
    getOptionDisabled={getOptionDisabled}
    getOptionSelected={handleOptionSelected}
    groupBy={grouped ? groupBy : null}
    renderOption={renderOption}
    noOptionsText={noOptionsText}

    inputValue={filterValue || ''}
    onInputChange={handleFilterChange}
    renderTags={renderTags}
    renderInput={({ inputProps, ...params }) => {
      return <TextField
        {...params}
        name={input.name}
        error={!!(touched && (error || submitError))}
        label={
          <FieldTitle
            label={label && translate(label, { _: label, smart_count: 1 })}
            {...labelProps}
            source={source}
            resource={resource}
            isRequired={typeof isRequiredOverride !== 'undefined' ? isRequiredOverride : isRequired}
          />
        }
        helperText={
          (touched && (error || submitError)) || helperText ? (
            <InputHelperText
              touched={touched}
              error={error || submitError}
              helperText={typeof helperText === 'string' ? translate(helperText, { _: helperText, smart_count: 1 }) : helperText}
            />
          ) : null
        }
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
        value={filterValue}
        className={classes.input}
        placeholder={placeholder && translate(placeholder, { _: placeholder, smart_count: 1 })}
        inputProps={{
          ...inputProps,
          autoComplete: 'off',
          "aria-autocomplete": 'off',
          onFocus(event) {
            inputProps.onFocus(event);
            input.onFocus(event);
          },
          onBlur(event) {
            inputProps.onBlur(event);
            input.onBlur(event);
          }
        }}
        {...inputOptions}
      />
    }}
  />
};

const DefaultSetFilter = () => {};
const EmptyArray = [];

const useStyles = makeStyles(theme => ({
  input: {
    minWidth: theme.spacing(24),
    marginRight: theme.spacing(1),
  },
  suggestion: {
    width: '100%',
  }
}), { name: 'HiAutocompleteInput' });

export default AutocompleteInput;
