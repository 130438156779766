import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { GET_ONE, useListContext, useLocale, useQuery, useRecordContext, useTranslate } from 'react-admin';
import { Typography, Link as MuiLink, LinearProgress, styled } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { dedupe, dedupeBy } from '@hisports/parsers';

import { SquareAlert as Alert } from '../SquareAlert';

import { usePermissions } from '../../http';
import { getSurfaceAttributes, getSurfaceName } from '../../resources/surfaces/util';
import { CALENDAR_VIEWS, useCalendarView, useShowCondensedView, useSurfaceSizes } from '../../resources/events/EventViewSettings';

import { SNAP_MINUTES, getEventId, getEventResource } from './DragAndDropGrid';
import { useSchedulingContext } from './SchedulingContext';
import { getDateRange } from '../fields/DateRangeField';

const CalendarContext = React.createContext(null);

const Loader = styled(LinearProgress)({
  position: 'absolute',
  inset: '0 0 auto 0'
})

const filterDates = (dates, weekdays = []) => {
  if (!dates?.length) return [];
  if (!weekdays?.length) return dates.sort((a, b) => a.localeCompare(b));

  return dates
    .filter(date => weekdays.includes(moment(date).locale('en').format('ddd')))
    .sort((a, b) => a.localeCompare(b));
}

const getDatesBeforeEnd = (dates, endDate) => {
  const endDateIndex = dates.findIndex(date => moment(date).isAfter(endDate, 'day'));
  return endDateIndex >= 0 ? dates.slice(0, endDateIndex) : dates;
}

const getMonthDates = (startDate, timezone) => {
  const dates = [];
  const date = moment.tz(startDate, timezone);
  const calendarEnd = moment.tz(startDate, timezone).endOf('week').endOf('month').endOf('week').endOf('day')

  do {
    dates.push(date.format('YYYY-MM-DD'))
    date.add(1, 'day');
  } while (date.isBefore(calendarEnd));

  return dates;
}

const getDays = (filterValues, timezone = moment.tz.guess()) => {
  if (!filterValues?.startTime) return {
    start: moment().tz(timezone).startOf('week').format('YYYY-MM-DD'),
    days: 7,
  }

  const start = moment.utc(filterValues.startTime);
  if (!filterValues.endTime) return {
    start: start.format('YYYY-MM-DD'),
    days: 1,
  }

  const end = moment.utc(filterValues.endTime);
  return {
    start: start.format('YYYY-MM-DD'),
    days: end.diff(start, 'days') + 1,
    type: CALENDAR_VIEWS.MONTH,
  }
}

const getTimezones = (office, surfaces = [], record) => {
  const timezones = [
    record?.timezone,
    office?.timezone,
    ...surfaces.filter(s => !['TBA', 'NDA'].includes(s?.alias)).map(s => s.timezone),
    moment.tz.guess(),
  ].filter(Boolean);

  return dedupe(timezones);
}

const getAllScheduleDates = (schedule, filterStartDate) => {
  if (!schedule?.startDate || !schedule?.endDate) return [];
  const start = moment(filterStartDate || schedule?.startDate);
  const end = moment(schedule?.endDate);
  const allDates = [];
  do {
    allDates.push(start.format('YYYY-MM-DD'))
    start.add(1, 'day');
  } while (start.isSameOrBefore(end));
  return allDates;
}

const hasOfficeAccess = (permissions, officeId) => permissions.some(p => p.roleType === 'System' || p.officeIds?.includes(officeId));

const useOffice = (officeId, hasAccess = true) => useQuery({
  type: GET_ONE,
  resource: 'offices',
  payload: {
    id: officeId,
  }
}, {
  enabled: officeId != null && hasAccess,
})

export const useCalendarContext = () => useContext(CalendarContext) || {};

export const CalendarProvider = memo(({
  includeVenue,
  popoverActions,
  hideArena,
  disableAuth = false,
  maxColumns: defaultMaxColumns = 5,
  initialStart,
  strictView,
  ...props
}) => {
  const { filterValues } = useListContext();
  const [ view, setView ] = useCalendarView();
  const [ surfaceSizes ] = useSurfaceSizes()
  const { selectedGame, availability, gameLength, loading, schedule, scheduleTeams, dateFilter, setDateFilter } = useSchedulingContext() || {};
  const locale = useLocale();
  const translate = useTranslate()
  const record = useRecordContext()
  const permissions = usePermissions();

  const officeId = schedule?.officeId || record?.officeId;
  const { data: scheduleOffice } = useOffice(officeId, hasOfficeAccess(permissions, officeId));
  const [ showCondensedView ] = useShowCondensedView();

  const [ colOffset, setColOffset ] = useState(0);
  const [ navigateToDate, setNavigateToDate ] = useState(null);

  const maxColumns = useMemo(() => {
    if (view === CALENDAR_VIEWS.SEASON) return 7;
    return defaultMaxColumns;
  }, [defaultMaxColumns, view])

  const filteredSurfaces = useMemo(() => (availability?.surfaces || []).filter(surface => {
    if (!surfaceSizes?.length) return true;
    return surfaceSizes.includes(surface.size)
  }), [availability, surfaceSizes])

  const timezones = useMemo(() => {
    if (view === CALENDAR_VIEWS.MONTH) return [];
    return getTimezones(scheduleOffice, filteredSurfaces, record);
  }, [ filteredSurfaces, record, scheduleOffice, view ])

  const timezone = useMemo(() => {
    if (view !== CALENDAR_VIEWS.MONTH && timezones?.length) {
      return timezones[0];
    } else {
      return moment.tz.guess()
    }
  }, [ timezones, view ])

  useEffect(() => {
    // on mount
    if (strictView) {
      return setView(strictView)
    }

    if (selectedGame) return;
    setView(CALENDAR_VIEWS.WEEK)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedGame || strictView || view === CALENDAR_VIEWS.SEASON) return;
    if (!selectedGame.date) {
      setView(CALENDAR_VIEWS.MONTH)
    } else {
      setView(CALENDAR_VIEWS.DAY)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strictView, selectedGame, setView])

  useEffect(() => {
    if (!selectedGame) return;
    if (filteredSurfaces.length && selectedGame?.arenaId) {
      const index = filteredSurfaces?.findIndex(surface => surface.id === selectedGame.arenaId)
      if (index < 0) return
      const offset = index + 1 - maxColumns < 0 ? 0 : index + 1 - maxColumns
      setColOffset(offset)
    } else {
      setColOffset(0);
    }
  }, [ filteredSurfaces, maxColumns, selectedGame ])

  const columns = useMemo(() => {
    const getAlert = (values) => {
      if (view === CALENDAR_VIEWS.SEASON) {
        if (loading) return <Loader color="primary" variant="indeterminate" />
        if (!values?.length) return <Alert severity="warning">
          {translate('components.calendar.messages.no_schedule_teams')}
        </Alert>

        return null
      }


      if (selectedGame == null) return <>
        <Alert severity="info">
          {translate('components.calendar.messages.select_game')}
        </Alert>
        {loading && <Loader color="primary" variant="indeterminate" />}
      </>

      if (selectedGame.date == null) return <>
        <Alert severity="warning">
          {translate('components.calendar.messages.schedule_game')}
        </Alert>
        {loading && <Loader color="primary" variant="indeterminate" />}
      </>

      if (loading) return <Loader color="primary" variant="indeterminate" />

      if (!availability?.surfaces?.length && view !== CALENDAR_VIEWS.MONTH) return <Alert severity="error">
        {translate('components.calendar.messages.no_home_surface')}
      </Alert>

      if (!values.length) return <Alert severity="warning">
        {translate('components.calendar.messages.no_result')}
      </Alert>

      return null;
    }

    if (view === CALENDAR_VIEWS.SEASON) {
      const header = getDateRange(dateFilter?.startDate, dateFilter?.endDate, locale, timezone);
      const slots = availability?.slots || [];
      const games = availability?.games || [];
      const practices = availability?.practices || [];
      const activities = availability?.activities || [];
      const availabilities = availability?.availabilities || [];

      const eventDates = dedupe([...slots, ...games, ...practices, ...activities, ...availabilities].map(event => event.date));
      const dates = filterDates(eventDates, dateFilter?.weekdays);

      const scheduleDates = getAllScheduleDates(schedule, dateFilter?.startDate);
      const allDates = filterDates(scheduleDates, dateFilter?.weekdays);

      const selectedDates = showCondensedView ? dates : allDates;

      const teams = dedupeBy('id', (scheduleTeams || []).map(scheduleTeam => scheduleTeam.team));

      return {
        type: CALENDAR_VIEWS.SEASON,
        header: header,
        disabled: false,
        alert: getAlert(teams),
        columns: teams.slice(colOffset, colOffset + maxColumns),
        totalColumns: teams.length,
        rows: getDatesBeforeEnd(dates, dateFilter?.endDate),
        allrows: getDatesBeforeEnd(allDates, dateFilter?.endDate),
        filterEvents: events => [],
        toEventDetails: (item, eventResource, eventId, gridDateIndex) => {
          const resource = eventResource || getEventResource(item)
          const id = eventId || getEventId(item);
          const date = selectedDates?.[gridDateIndex];

          return {
            id,
            resource,
            date,
            startTime: null,
            endTime: null,
          };
        },
        onNext: () => {
          setColOffset(colOffset + 1);
        },
        onBack: () => {
          setColOffset(colOffset - 1);
        }
      };
    }

    if (view === CALENDAR_VIEWS.MONTH) {
      const { start, type } = getDays(filterValues);
      const header = moment(start).endOf('week').format('MMMM YYYY');

      const dates = type === CALENDAR_VIEWS.MONTH ? getMonthDates(start, timezone) : [];

      const rows = [];
      const totalWeeks = dates.length / 7;
      for (let i = 0; i < totalWeeks; i++) {
        const daysOfWeek = dates.slice(i * 7, (i + 1) * 7);
        rows.push(daysOfWeek);
      }
      const values = dates.slice(0, 7).map(date => moment.tz(date, timezone).format('ddd'));

      return {
        type: CALENDAR_VIEWS.MONTH,
        header,
        disabled: false,
        alert: getAlert(values),
        columns: values,
        totalColumns: 7,
        rows,
        dates,
        start: selectedGame?.date,
        toEventDetails: (item, eventResource, eventId) => {
          const { i: itemId, x: itemColumn, y: itemRow, h: itemHeight } = item;
          const resource = eventResource || getEventResource(item)
          const id = eventId || getEventId(item);
          const date = rows?.[itemRow]?.[itemColumn];

          return {
            id,
            resource,
            date,
            startTime: null,
            endTime: null,
          };
        },
        filterEvents: events => {
          if (!selectedGame) return [];
          const teamIds = [selectedGame.homeTeamId, selectedGame.awayTeamId].filter(Boolean);
          if (!teamIds.length) return [];
          return events.filter(event => {
            if (event.homeTeamId && teamIds.includes(event.homeTeamId)) return true;
            if (event.awayTeamId && teamIds.includes(event.awayTeamId)) return true;
            if (event.teamIds && teamIds.some(teamId => teamIds.includes(teamId))) return true;
            return false
          })
        },
        getColumnKey: () => {},
        getColumnIndex: () => {},
        getColumnHeading: () => "Heading",
        onNext: () => {},
        onBack: () => {}
      };
    }

    if (view === CALENDAR_VIEWS.DAY) {
      const hasDate = selectedGame?.date != null;
      const values = hasDate ? filteredSurfaces.slice(colOffset, colOffset + maxColumns) : []
      const header = hasDate ? moment.utc(selectedGame?.date).format('LL') : ' - ';
      const effectiveIds = dedupe(filteredSurfaces?.map(surface => surface.effectiveSurfaceIds).flat()) || [];

      return {
        type: CALENDAR_VIEWS.DAY,
        header,
        disabled: !hasDate || !values.length,
        alert: getAlert(values),
        columns: values,
        totalColumns: filteredSurfaces.length,
        start: selectedGame?.date || initialStart,
        getColumnKey: surface => surface.id,
        getColumnIndex: event => values.findIndex(surface => (event.positionArenaId || event.arenaId) == surface.id || (event.targetType === 'Surface' && (event.positionArenaId || event.targetId) == surface.id)),
        getColumnHeading: surface => {
          const attributes = getSurfaceAttributes(surface, translate);
          return <>
            <Link component={MuiLink} to={`/surfaces/${surface.id}/show`} target="_blank">
              {getSurfaceName(surface)}
            </Link>
            <Typography variant="body2" color="textSecondary">{attributes}</Typography>
          </>
        },
        filterEvents: events => {
          const date = selectedGame?.date && moment(selectedGame.date);
          const dateEvents = events.filter(event => {
            if ((event.targetType === 'Surface' ? !event.targetId : !event.arenaId) || !event.startTime || !event.endTime) return false;
            if (date && !date.isSame(event.date, 'day')) return false;
            return !event.targetType ? effectiveIds.includes(event.arenaId) : event.targetType === 'Surface' && effectiveIds.includes(event.targetId)
          });
          return values.map(surface => {
            return dateEvents.filter(event => !event.targetType ? (event.positionArenaId || event.arenaId) === surface.id : event.targetType === 'Surface' && (event.positionArenaId || event.targetId) === surface.id);
          });
        },
        pushSplitSurfaceEvents: events => {
          const newEvents = []

          events.forEach(event => {
            const eventSurface = (availability?.surfaces || []).find(({ id }) => id === (event.arenaId || event.targetId));
            if (!eventSurface?.effectiveSurfaceIds) return;
            const newEventSurfaces = eventSurface.effectiveSurfaceIds.filter(surfaceId => surfaceId !== (event.arenaId || event.targetId) && filteredSurfaces.find(({ id }) => id === surfaceId));
            newEventSurfaces.forEach(surfaceId => {
              newEvents.push({ ...event, positionArenaId: surfaceId, isEditable: false, isSplitSurfaceEvent: true });
            })
          });

          return [...events, ...newEvents];
        },
        toEventDetails: (item, eventResource, eventId) => {
          const { i: itemId, x: itemColumn, y: itemRow, h: itemHeight } = item;
          const resource = eventResource || getEventResource(item)
          const id = eventId || getEventId(item);

          const date = selectedGame?.date;
          const startOfDay = { hours: 6, minutes: 0, seconds: 0 };
          const startTime = moment.tz(date, timezone).set(startOfDay).add(itemRow * SNAP_MINUTES, 'minutes').toISOString();
          // for draft games, we reset endTime based on the gameLength because the current endTime might be updated by a timeSlot's endTime
          const endTime = resource === 'games' ?
            moment.tz(date, timezone).set(startOfDay).add((itemRow + itemHeight) * SNAP_MINUTES, 'minutes').toISOString() :
            moment.tz(startTime, timezone).add(gameLength || 60, 'minutes').toISOString();
          const arena = values?.[itemColumn];

          return {
            id,
            resource,
            date,
            startTime,
            endTime,
            arenaId: arena?.id,
          };
        },
        onNext: () => {
          setColOffset(colOffset + 1);
        },
        onBack: () => {
          setColOffset(colOffset - 1);
        }
      };
    }

    const { start, days } = getDays(filterValues);
    const startDate = moment(start);
    const values = days > 0 ? Array(days).fill(null).map((_, day) => moment(startDate).add(day, 'days')) : []
    const nextMonth = values.find(date => date.format('M') !== startDate.format('M'));
    const header = nextMonth ? `${startDate.format('MMMM')} - ${nextMonth.format('MMMM YYYY')}` : startDate.format('MMMM YYYY');
    const dates = values.map(date => date.format('YYYY-MM-DD'))

    return {
      type: CALENDAR_VIEWS.WEEK,
      header,
      disabled: false,
      alert: null,
      columns: values,
      totalColumns: values.length,
      dates,
      start,
      getColumnKey: date => date.toISOString(),
      getColumnIndex: (event) => {
        const { startTime, layout } = event;
        const date = moment.tz(layout?.startTime || startTime, timezone);
        return date.diff(startDate, 'days');
      },
      getColumnHeading: date => <>
        <Typography variant="overline">{date.format('ddd')}</Typography>
        <Typography variant="h5">{date.format('D')}</Typography>
      </>,
      filterEvents: events => {
        return events.filter(event => event.date && event.startTime && event.endTime);
      },
      pushSplitSurfaceEvents: events => events,
      toEventDetails: (item, eventResource, eventId) => {
        const { i: itemId, x: itemColumn, y: itemRow, h: itemHeight } = item;
        const resource = eventResource || getEventResource(item);
        const id = eventId || getEventId(item);

        const date = moment.tz(filterValues.startTime, timezone).add('days', itemColumn).format('YYYY-MM-DD');
        const startOfDay = { hours: 6, minutes: 0, seconds: 0 };
        const startTime = moment.tz(date, timezone).set(startOfDay).add(itemRow * SNAP_MINUTES, 'minutes').toISOString();
        const endTime = moment.tz(date, timezone).set(startOfDay).add((itemRow + itemHeight) * SNAP_MINUTES, 'minutes').toISOString();

        return {
          id,
          resource,
          date,
          startTime,
          endTime,
        };
      }
    };
  }, [view, filterValues, selectedGame, translate, locale, loading, availability?.surfaces, availability?.slots, availability?.games, availability?.practices, availability?.activities, availability?.availabilities, scheduleTeams, colOffset, maxColumns, schedule, timezone, filteredSurfaces, initialStart, gameLength, dateFilter, showCondensedView])

  useEffect(() => {
    // handle dateFilter for month view
    if (columns?.type !== CALENDAR_VIEWS.MONTH || !columns?.dates?.length || !timezone) return;

    const startDate = moment.tz(columns.dates[0], timezone).startOf('day').toISOString();
    const endDate = moment.tz(columns.dates.pop(), timezone).endOf('day').toISOString();

    if (startDate != dateFilter?.startDate || endDate != dateFilter?.endDate) {
      setDateFilter({
        startDate,
        endDate,
        type: CALENDAR_VIEWS.MONTH,
      });
    }
  }, [columns.dates, columns?.type, dateFilter?.endDate, dateFilter?.startDate, setDateFilter, timezone])

  useEffect(() => {
    // init dateFilter for season view
    if (columns?.type !== CALENDAR_VIEWS.SEASON || dateFilter?.type === CALENDAR_VIEWS.SEASON || !schedule?.startDate) return;

    const scheduleStartDate = moment.utc(schedule.startDate);
    const scheduleEndDate = moment.utc(schedule.endDate);
    const oneMonthFromStartDate = scheduleStartDate.clone().add(1, 'month');

    return setDateFilter({
      startDate: scheduleStartDate.format('YYYY-MM-DD'),
      endDate: oneMonthFromStartDate.isAfter(scheduleEndDate, 'day') ? scheduleEndDate?.format('YYYY-MM-DD') : oneMonthFromStartDate.format('YYYY-MM-DD'),
      type: CALENDAR_VIEWS.SEASON,
    });
  }, [columns?.type, dateFilter?.type, schedule?.endDate, schedule?.startDate, setDateFilter])

  const value = useMemo(() => ({
    ...columns,
    colOffset,
    maxColumns,
    includeVenue,
    popoverActions,
    hideArena,
    disableAuth,
    initialStart,
    timezone,
    timezones,
    navigateToDate,
    setNavigateToDate,
  }), [ columns, colOffset, maxColumns, includeVenue, popoverActions, hideArena, disableAuth, initialStart, timezone, timezones, navigateToDate ]);

  return <CalendarContext.Provider value={value} {...props} />
})
