import { GET_ONE, useQuery } from 'react-admin';

export const useOfficeAssignSettings = (officeId, enabled) =>
  useQuery({
    type: GET_ONE,
    resource: 'officeAssignSettings',
    payload: { id: officeId },
  }, {
    enabled: officeId != null && enabled
  })
