import React from 'react';
import { useTranslate } from 'react-admin';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { ArticleView } from './ArticleView';
import { ArticleFilters } from './ArticleFilters';
import { ArticleShowLayout } from './ArticleShow';
import { ArticleForm } from './ArticleForm';

export const ArticleList = props =>
  <List {...props}
    component="div"
    filters={<ArticleFilters />}
    sort={{
      field: ['category', 'title'],
      order: ['ASC', 'ASC'],
    }}
    perPage={999}
    pagination={null}
  >
    <ArticleView />
  </List>

export const ArticleShow = props =>
  <Show {...props}
    title={<ResourceTitle title={record => record.title} />}
  >
    <ArticleShowLayout />
  </Show>

export const ArticleCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={record => translate('resources.help.labels.add')} />}
    {...props}
  >
    <ArticleForm redirect="show" />
  </Create>
}

export const ArticleEdit = props => {
  const translate = useTranslate()
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: record.title })} />}
    {...props}
  >
    <ArticleForm redirect="show" />
  </Edit>
}

