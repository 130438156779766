import React, { useState, useRef } from "react";
import { useTranslate } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  useMediaQuery,
} from '@material-ui/core';
import {
  AssignmentOutlined as ApproveIcon,
  Check as SignedIcon,
} from '@material-ui/icons'

import { dedupeBy } from "@hisports/parsers";
import { getLineupMembers, getLineupSignatory, getOfficials, isLineupApproved } from '@hisports/scoresheet/src/selectors';

import { useSignature, SignatureInput } from '../../../common/inputs/SignatureInput';
import { useMeta, useScoresheet } from '../ScoresheetContext';
import { useParticipant, useSport } from "../../../http";
import { isStaffMember } from "..";

const SignatoryInput = ({ teamId, value, error, onChange }) => {
  const sport = useSport()
  const { game, meta } = useMeta();

  const officials = useScoresheet(scoresheet => getOfficials(scoresheet, { assignedOnly: true }));
  const homeLineup = useScoresheet(scoresheet => getLineupMembers(scoresheet, { teamId: game?.homeTeamId, sport }));
  const awayLineup = useScoresheet(scoresheet => getLineupMembers(scoresheet, { teamId: game?.awayTeamId, sport }));

  const homeRoster = (meta?.teams[game?.homeTeamId]?.members || []);
  const awayRoster = (meta?.teams[game?.awayTeamId]?.members || []);

  const homeStaff = dedupeBy('participantId', [...homeLineup, ...homeRoster]).filter(member => isStaffMember(member));
  const awayStaff = dedupeBy('participantId', [...awayLineup, ...awayRoster]).filter(member => isStaffMember(member));

  const hasHomeStaffAccess = meta?.policies.awayTeamScorekeeping;
  const hasAwayStaffAccess = meta?.policies.homeTeamScorekeeping;

  const staff = [];
  if (game?.homeTeamId === teamId) {
    staff.push(...homeStaff)
    if (hasHomeStaffAccess) {
      staff.push(...awayStaff)
    }
  } else if (game?.awayTeamId === teamId) {
    staff.push(...awayStaff)
    if (hasAwayStaffAccess) {
      staff.push(...homeStaff)
    }
  }

  const signers = [...staff, ...officials];

  return <FormControl fullWidth variant="filled" error={error}>
    <InputLabel>Signatory</InputLabel>
    <Select value={value} fullWidth onChange={onChange}>
      {signers.map(member =>
        <MenuItem value={member.participantId}>{member.participant.fullName}</MenuItem>
      )}
    </Select>
  </FormControl>
}

const useDialogStyles = makeStyles(() => ({
  paperFullScreen: {
    height: 'unset'
  }
}))
const SignatureDialog = ({ open, teamId, onSave, onClose }) => {
  const translate = useTranslate()
  const sport = useSport();
  const identityId = useParticipant()
  const ref = useRef(null);
  const { signature, isEmpty, clear, handleChange } = useSignature(ref);
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const classes = useDialogStyles();

  const signatory = useScoresheet(scoresheet => getLineupSignatory(scoresheet, { participantId: identityId, sport }))
  const [ participantId, setParticipant ] = useState(signatory?.participantId);
  const [ signatoryMissing, setSignatoryMissing ] = useState(false);

  const handleSignatory = e => {
    const participantId = e.target.value;
    setParticipant(participantId);
    setSignatoryMissing(participantId == null)
  }

  const handleDiscard = () => {
    onClose()
  }

  const handleSave = () => {
    if (!participantId) {
      setSignatoryMissing(true);
      return;
    }

    if (isEmpty) return;

    onSave({ participantId, signature });
    onClose();
  }

  return <Dialog open={open} fullWidth fullScreen={fullScreen} maxWidth={fullScreen ? "xs" : "sm"} disableBackdropClick classes={classes}>
    <DialogTitle>{translate('resources.scoresheets.labels.sign_and_approve')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.scoresheets.messages.confirm_sign')}
      </DialogContentText>

      <SignatoryInput teamId={teamId} value={participantId} onChange={handleSignatory} error={signatoryMissing} />

      <SignatureInput ref={ref} onChange={handleChange} />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDiscard}>{translate('ra.action.discard')}</Button>
      <Button onClick={clear} disabled={isEmpty}>{translate('ra.action.clear')}</Button>
      <Button color="primary" onClick={handleSave} disabled={isEmpty}>{translate('ra.action.save')}</Button>
    </DialogActions>
  </Dialog>
}

export const SignLineup = ({ teamId, disabled, onSave }) => {
  const translate = useTranslate();
  const [ open, setOpen ] = useState();
  const isApproved = useScoresheet(scoresheet => isLineupApproved(scoresheet, { teamId }))

  if (isApproved) return <Button startIcon={<SignedIcon />} disabled>{translate('resources.scoresheets.labels.signed')}</Button>;
  return <>
    <Button color="primary" variant="contained" startIcon={<ApproveIcon />} disabled={disabled} onClick={() => setOpen(true)}>{translate('ra.action.sign')}</Button>
    {open && <SignatureDialog open={open} teamId={teamId} onSave={onSave} onClose={() => setOpen(false)} />}
  </>
}
