export const parseInput = input => {
  if (!input) return;
  const value = parseInt(input, 10);
  if (isNaN(value)) return;
  return value;
}

export const isLevel = value => {
  if (!value) return null;
  const level = parseInt(value, 10);
  if (isNaN(level)) return false;
  return level >= 0 && level <= 10;
}

export const isGrade = value => {
  if (!value) return null;
  const grade = parseInt(value, 10);
  if (isNaN(grade)) return false;
  return grade >= 0 && grade <= 100;
}

export const hasInvalidAttribute = (data, column, attribute) => {
  if (!data || !column || !attribute) return;
  return data
    .map(value => value[column])
    .some(value => {
      if (!value) return false
      const type = attribute.valueType;
      const options = attribute.options;
      if (type === "Number" && isNaN(value)) return true
      if (type === "Number" && (options && options.min != null && value < options.min)) return true
      if (type === "Number" && (options && options.max != null && value > options.max)) return true
      if (type === "Dropdown" && !options.values.includes(value)) return true
      return false
    })
}
