import React from 'react';
import { ReferenceInput, ReferenceArrayInput, useLocale } from 'react-admin';
import { startCase } from 'lodash';

import { useSeason } from '../../common/inputs/SeasonSelector';
import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { getDateRange } from '../../common/fields/DateRangeField';

const toName = payPeriod => startCase(payPeriod.name);

const toDates = (payPeriod, locale) => getDateRange(payPeriod.startDate, payPeriod.endDate, locale, 'UTC');

const PayPeriodSuggestions = ({ suggestion: payPeriod, query, isHighlighted, ...props }) => {
  const locale = useLocale();
  if (!payPeriod?.id) return null;
  return <AutocompleteSuggestion primary={toName(payPeriod)} secondary={toDates(payPeriod, locale)} />
}

export const PayPeriodInput = ({ allowEmpty, multiple, helperText, filter, ...props }) => {
  const seasonId = useSeason();
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;

  return <Input
    reference="payPeriods"
    sort={{
      field: ['startDate'],
      order: ['ASC'],
    }}
    filterToQuery={() => null}
    {...props}
    filter={{ seasonId, effectiveOffices: false, ...filter }}
  >
    <AutocompleteInput
      optionText={toName}
      helperText={helperText}
      suggestionComponent={PayPeriodSuggestions}
    />
  </Input>
}
