import React from 'react';
import { useTranslate } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core';

import { useAssignmentsContext } from '../../AssignmentsContext';
import { useAssignmentContext } from '../AssignmentContext';
import { filterFlags, isAssigned, isUnconfirmed, isIneligible, isRequested, isConflict, isDeclined, isExpired } from '../util';

import AssignButton from './AssignButton';
import RemoveButton from './RemoveButton';
import SwapButton from './SwapButton';
import RequestButton from './RequestButton';
import AcceptButton from './AcceptButton';
import DeclineButton from './DeclineButton';


export default ({ className: classProps, onAssign, quickAssign = false }) => {
  const { game, settings, branchSettings, assignments, refresh } = useAssignmentsContext();
  const { assignment, meta, official, filters, selectedId, selectOfficial, closePanel } = useAssignmentContext();
  const translate = useTranslate();

  if (!game || !assignment || !settings || !meta) return null;

  const { canAssign, canRequest, isAssignedSelf, isUnassigned, isOfficialSelf, isCompleted, isCertified } = meta;

  const changed = assignment.participantId !== selectedId
  const isNoShow = assignment.status === 'no-show';
  const isDraft = settings.status === 'Draft'

  const flags = official ? filterFlags(official.flags, assignment.position, filters) : [];

  const assigned = ['assigned', 'confirmed'].includes(assignment.status);
  const assignedOther = isAssigned(flags);
  const unconfirmed = isUnconfirmed(flags);
  const ineligible = isIneligible(flags);
  const expired = isExpired(flags);
  const requested = isRequested(flags);
  const declined = isDeclined(flags);
  const conflicted = isConflict(flags);

  const showRequest = canRequest && !assigned && !declined && !isNoShow && isOfficialSelf && !quickAssign;
  const showAccept = isAssignedSelf && (assigned || declined || isNoShow) && isOfficialSelf && !quickAssign;
  const showDecline = isAssignedSelf && (assigned || declined || isNoShow) && isOfficialSelf && !quickAssign;

  const showAssign = canAssign && !isNoShow;
  const showDiscard = canAssign && !isNoShow && !quickAssign;
  const showSwap = canAssign && !isUnassigned && !isNoShow && assignments.length > 1 && !quickAssign;
  const showRemove = (canAssign && !isUnassigned) || (canRequest && requested) && !quickAssign;

  const onDiscard = () => {
    selectOfficial(assignment.participantId);
  }

  const handleClose = (assignment, participantId) => {
    refresh(assignment, participantId)
  }

  return <div className={classProps}>
    {showDiscard && <Tooltip title={translate('resources.games.labels.assignment.discard_changes')}>
      <Button
        disabled={!changed}
        onClick={() => onDiscard()}
      >
        {translate('ra.action.discard')}
      </Button>
    </Tooltip>}
    {showRemove && <RemoveButton
      game={game}
      assignment={assignment}
      official={official}
      filters={filters}
      settings={settings}
      disabled={changed || (isCertified && !branchSettings?.certifiedOfficialChanges)}
      onClose={handleClose}
    />}
    {showSwap && <SwapButton
      game={game}
      assignment={assignment}
      official={official}
      filters={filters}
      settings={settings}
      disabled={changed || isCompleted}
      onClose={handleClose}
    />}

    {showDecline && <DeclineButton
      game={game}
      assignment={assignment}
      official={official}
      filters={filters}
      settings={settings}
      disabled={declined || isNoShow || isDraft || isCompleted}
      onClose={handleClose}
    />}
    {showAccept && <AcceptButton
      game={game}
      assignment={assignment}
      official={official}
      filters={filters}
      settings={settings}
      disabled={(!unconfirmed && !declined) || isNoShow || isDraft || isCompleted}
      onClose={handleClose}
    />}

    {showRequest && <RequestButton
      game={game}
      assignment={assignment}
      official={official}
      filters={filters}
      settings={settings}
      disabled={requested || ineligible || expired || conflicted || assignedOther || isDraft || isCompleted}
      onClose={handleClose}
    />}
    {showAssign && <AssignButton
      game={game}
      assignment={assignment}
      official={official}
      filters={filters}
      settings={settings}
      disabled={!changed || (isCertified && !branchSettings?.certifiedOfficialChanges)}
      onClose={handleClose}
      onAssign={onAssign}
    />}
  </div>
}
