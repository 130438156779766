import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import InfractionForm from './InfractionForm';

const AddInfractionDialog = ({ office, ...props }) =>
  <CreateResourceDialog label="resources.infractions.actions.add" resource="infractions" basePath="/infractions" {...props}>
    <InfractionForm office={office} />
  </CreateResourceDialog>

const EditInfractionDialog = ({ office, ...props }) =>
  <EditResourceDialog label="resources.infractions.labels.edit" resource="infractions" basePath="/infractions" {...props}>
    <InfractionForm office={office} />
  </EditResourceDialog>

export const AddInfractionButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.infractions.actions.add')}
    </Button>
    <AddInfractionDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const InfractionDatagrid = props => <DialogDatagrid dialog={EditInfractionDialog} {...props} />
