import React, { useContext, useEffect, useMemo, useState } from 'react';

import { getMembers } from '@hisports/scoresheet/src/selectors';

import { apiClient, useSport } from '../../../http';
import { unionBy, uniqBy } from 'lodash';
import { isEmpty } from '@hisports/parsers';

const GameMemberValidationContext = React.createContext(null);

export const useGameMemberValidationContext = () => useContext(GameMemberValidationContext) || {};

const validateMembers = async (gameId, teamId, members = []) => {
  return apiClient(`/games/${gameId}/validateMembers`, {
    method: 'POST',
    data: { teamId, members }
  })
    .then(res => res.data)
}

export const GameMemberValidationProvider = ({ disabled = false, game, meta, scoresheet, ...props }) => {
  const [ memberValidations, setMemberValidations ] = useState({});
  const [ inFlight, setInFlight ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const sport = useSport();

  const homeLineup = scoresheet?.lineups[game?.homeTeamId];
  const awayLineup = scoresheet?.lineups[game?.awayTeamId];

  useEffect(() => {
    // set initial validatedMembers
    const homeRoster = meta?.teams[game.homeTeamId].members || [];
    const homeLineupMembers = disabled ? (homeLineup?.members || []) : getMembers(homeLineup, meta?.teams[game.homeTeamId], sport);
    const homeMembers = unionBy([...homeLineupMembers, ...homeRoster], 'participantId')
    const hasHomeMembers = homeMembers.length > 0;

    const awayRoster = meta?.teams[game.awayTeamId].members || [];
    const awayLineupMembers = disabled ? (awayLineup?.members || []) : getMembers(awayLineup, meta?.teams[game.awayTeamId], sport);
    const awayMembers = unionBy([...awayLineupMembers, ...awayRoster], 'participantId')
    const hasAwayMembers = awayMembers.length > 0;

    if (!hasHomeMembers && !hasAwayMembers) return;

    setInFlight(true);
    setLoading(true);

    Promise.all([
      hasHomeMembers ? validateMembers(game.id, game.homeTeamId, homeMembers) : [],
      hasAwayMembers ? validateMembers(game.id, game.awayTeamId, awayMembers) : []
    ])
      .then(validations => setMemberValidations({ [game.homeTeamId]: validations[0], [game.awayTeamId]: validations[1] }))
      .finally(() => {
        setInFlight(false)
        setLoading(false);
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // check if any participants are missing from memberValidations
    if (inFlight || isEmpty(memberValidations)) return;
    const homeLineupMembers = disabled ? (homeLineup?.members || []) : getMembers(homeLineup, meta?.teams[game.homeTeamId], sport);
    const awayLineupMembers = disabled ? (awayLineup?.members || []) : getMembers(awayLineup, meta?.teams[game.awayTeamId], sport);

    const missingHomeMembers = homeLineupMembers.filter(lineupMember => !memberValidations[game.homeTeamId].some(member => member.participantId === lineupMember.participantId));
    const missingAwayMembers = awayLineupMembers.filter(lineupMember => !memberValidations[game.awayTeamId].some(member => member.participantId === lineupMember.participantId));

    const hasMissingHomeMembers = missingHomeMembers.length > 0;
    const hasMissingAwayMembers = missingAwayMembers.length > 0;

    if (!hasMissingHomeMembers && !hasMissingAwayMembers) return;

    setLoading(true);
    setInFlight(true);
    Promise.all([
      hasMissingHomeMembers ? validateMembers(game.id, game.homeTeamId, missingHomeMembers) : [],
      hasMissingAwayMembers ? validateMembers(game.id, game.awayTeamId, missingAwayMembers) : []
    ])
      .then(validations => setMemberValidations(prevState =>
        ({
          ...prevState,
          [game.homeTeamId]: uniqBy([...validations[0], ...prevState[game.homeTeamId]], 'participantId'),
          [game.awayTeamId]: uniqBy([...validations[1], ...prevState[game.awayTeamId]], 'participantId')
        })
      ))
      .finally(() => {
        setInFlight(false)
        setLoading(false);
      })
  }, [ disabled, game.id, inFlight, homeLineup, awayLineup, game, meta, memberValidations, sport ])

  const value = useMemo(() => ({
    memberValidations,
    loading
  }), [
    memberValidations,
    loading
  ]);

  return <GameMemberValidationContext.Provider value={value} {...props} />
}
