import React from 'react';
import { useRecordContext } from 'react-admin';
import { VerifiedUserOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { RoleGrid } from '../../roles/RoleGrid';
import { AddRoleButton } from '../../roles/RoleModal';

export default props => {
  const schedule = useRecordContext(props);

  const showRoles = isAuthorized(schedule, 'permissions', 'list', true)
  const canAddRoles = isAuthorized(schedule, 'permissions', 'create', true)

  if (!showRoles) return null;
  return <ListCard
    title="resources.permissions.labels.card.title"
    subtitle="resources.permissions.labels.card.subtitles.schedule"
    icon={<VerifiedUserOutlined />}
    collapsable
    reference="permissions"
    target="schedules"
    sort={{ field: 'roleId', order: 'ASC' }}
    addButton={canAddRoles && <AddRoleButton initialValues={{ principalType: 'Participant', targetType: 'Schedule', targetId: schedule?.id }} />}
  >
    <RoleGrid showOffice={false} />
  </ListCard>
}
