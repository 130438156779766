import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { SurfaceGrid } from '../../surfaces/SurfaceGrid';
import { AddSurfaceButton } from '../../surfaces/SurfaceModal';
import { SurfaceIcon } from '../../icons';

export default props => {
  const venue = useRecordContext();

  const canAddSurface = isAuthorized(venue, 'surfaces', 'create');
  const addButton = canAddSurface && <AddSurfaceButton initialValues={{ venueId: venue.id }} />

  return <ListCard
    {...props}
    title="resources.surfaces.labels.card.title"
    icon={<SurfaceIcon />}
    reference="surfaces"
    target="venueId"
    addButton={addButton}
    filter={{ _scope: 'Tenant', parentId: null }}
    sort={{
      field: 'name',
      order: 'ASC',
    }}
  >
    <SurfaceGrid includeVenue={false} rowClick="show" />
  </ListCard>
}
