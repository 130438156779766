import React, { useState } from "react";
import { useFormState } from 'react-final-form';
import { Grid, makeStyles } from '@material-ui/core';
import { get } from 'lodash';

import { getPeriods } from '@hisports/scoresheet/src/selectors';
import { parsePeriodInt, stringifyGameTime, stringifyTime } from '@hisports/scoresheet/src/util';
import { SHOOTOUT_PERIOD } from "@hisports/scoresheet/src/constants";
import { useScoresheet } from "../ScoresheetContext"

import { useSport } from "../../../http";
import { EnumInput, GamePeriodEnumInput } from "../../../common/inputs/EnumInputs";
import MaskedTextInput from "../../../common/inputs/MaskedTextInput";
import { NumberInput, useInput } from "react-admin";

const useStyles = makeStyles({
  disableMinWidth: {
    minWidth: '0px',
  },
  rightBorder: {
    borderRightColor: 'transparent',
    borderRadius: '4px 0px 0px 4px',
  },
  leftBorder: {
    borderRadius: '0px 4px 4px 0px',
  },
})

// min 00:00, max 99:59
const gameTimeMask = [/\d/, /\d/, ':', /[0-5]/, /\d/];

export const GameTimeInput = props => {
  const sport = useSport();
  if (sport === 'Hockey') return <HockeyGameTimeInput {...props} />
  if (sport === 'Soccer') return <SoccerGameTimeInput {...props} />
  if (sport === 'Baseball') return <BaseballGameTimeInput {...props} />
  return <GameClockTimeInput {...props} source={`${props.source}.clock`} options={{ placeholder: props?.placeholder || '00:00' }} />
}

export const HockeyGameTimeInput = ({ source, periodLabel = false, helperText, periodHelperText = false, placeholder, ...props }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const periodSettings = useScoresheet(scoresheet => getPeriods(scoresheet));

  const state = get(values, source) || {};
  const { period } = state;

  const periodSetting = periodSettings[parsePeriodInt(period) - 1];

  return <Grid container spacing={0}>
    <Grid item xs={12} md={4}>
      <GamePeriodEnumInput
        {...props}
        label={periodLabel}
        helperText={periodHelperText}
        source={`${source}.period`}
        InputProps={{
          classes: {
            notchedOutline: classes.rightBorder,
          }
        }}
        inputProps={{ className: classes.disableMinWidth }} // popup dropdown
        options={{ className: classes.disableMinWidth }} // input field
      />
    </Grid>
    <Grid item xs={12} md={8}>
      <GameClockTimeInput
        label="resources.scoresheets.labels.clock"
        helperText={helperText}
        source={`${source}.clock`}
        options={{
          placeholder: placeholder || stringifyGameTime(periodSetting) || '20:00',
        }}
        disabled={period === SHOOTOUT_PERIOD}
        InputProps={{
          classes: {
            notchedOutline: classes.leftBorder,
          }
        }}
        {...props}
      />
    </Grid>
  </Grid>
}

export const SoccerGameTimeInput = ({ source, label, placeholder, ...props }) => {
  const format = (formValue = {}) => {
    if (formValue.minutes == undefined && formValue.extra == undefined) return;
    if (!formValue.extra) return `${formValue.minutes}`;
    return `${formValue.minutes}+${formValue.extra}`;
  }

  const parse = (inputValue = '') => {
    let [ minutes, extra ] = inputValue.split('+');
    if (minutes) {
      minutes = minutes.trim()
    }
    if (extra) {
      extra = extra.trim()
    }
    return { minutes, extra }
  }

  return <MaskedTextInput
    label="resources.scoresheets.labels.clock"
    source={`${source}.clock`}
    maskOptions={{ mask: false, guide: false }}
    format={format}
    parse={parse}
    autoComplete="off"
    options={{
      placeholder: placeholder || '90+1',
    }}
    {...props} />
}

export const BaseballGameTimeInput = ({ source, label, placeholder, helperText, ...props }) => {
  const classes = useStyles();
  const { values } = useFormState();

  const state = get(values, source) || {};

  return <Grid container spacing={0}>
    <Grid item xs={12} md={6}>
      <NumberInput
        label="resources.scoresheets.labels.penalty.inning"
        source={`${source}.period`}
        InputProps={{
          classes: {
            notchedOutline: classes.rightBorder,
          }
        }}
        inputProps={{ className: classes.disableMinWidth }} // popup dropdown
        options={{ className: classes.disableMinWidth }} // input field
        helperText={helperText}
        {...props}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <EnumInput
        select
        label="resources.scoresheets.labels.penalty.half"
        source={`${source}.half`}
        InputProps={{
          classes: {
            notchedOutline: classes.leftBorder,
          }
        }}
        inputProps={{ className: classes.disableMinWidth }} // popup dropdown
        options={{ className: classes.disableMinWidth }} // input field
        choices={[
          { id: 1, name: 'resources.scoresheets.values.half.first' },
          { id: 2, name: 'resources.scoresheets.values.half.second' },
        ]} {...props} />
    </Grid>
  </Grid>
}

export const GameClockTimeInput = props => {
  // pad numbers when values are in number format
  // ex. { minutes: 8, seconds: 3 } => { minutes: '08', seconds: '03' }
  const {
    input: { value, onFocus, onBlur },
  } = useInput(props);

  const [ focused, setFocused ] = useState(false);

  const onInputFocus = e => {
    setFocused(true)
    return onFocus(e)
  }

  const onInputBlur = e => {
    setFocused(false);
    return onBlur(e)
  }

  const format = (value = {}) => {
    let { minutes, seconds } = value;

    if (minutes === '' && seconds === undefined) return;

    if (!focused) {
      minutes = stringifyTime(minutes || 0)
      seconds = stringifyTime(seconds || 0)
    }

    if (typeof minutes !== 'string') {
      minutes = stringifyTime(minutes)
    }
    if (typeof seconds !== 'string') {
      seconds = stringifyTime(seconds)
    }
    if (minutes == undefined && seconds == undefined) return;
    if (seconds == undefined) return `${minutes}`;
    return `${minutes}:${seconds}`;
  }

  const parse = (inputValue = '') => {
    const [ minutes, seconds ] = inputValue.split(':');
    return { ...value, minutes, seconds };
  }

  return <MaskedTextInput
    label="resources.scoresheets.labels.penalty.game_time"
    maskOptions={{ mask: gameTimeMask, guide: false }}
    format={format}
    parse={parse}
    onFocus={onInputFocus}
    onBlur={onInputBlur}
    autoComplete="off"
    options={{
      placeholder: '00:00',
    }}
    {...props} />
}
