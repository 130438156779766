import React, { forwardRef } from 'react';
import { useLogout, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';

import { authService } from '../http';
import Layout from './BaseLayout';

const Logout = forwardRef((props, ref) => {
  const translate = useTranslate();
  const logout = useLogout();

  return <Button color="primary" startIcon={<PowerSettingsNew />} onClick={() => logout()} ref={ref}>
    {translate('ra.auth.logout')}
  </Button>
});

/* eslint-disable react/jsx-no-useless-fragment */
const Meta = () =>
  <>
    <Logout />
  </>
/* eslint-enable react/jsx-no-useless-fragment */

export default () => {
  const translate = useTranslate();
  const user = authService.getUsername();

  return <Layout meta={<Meta />}>
    <h3>{translate('ra.auth.unauthorized')}</h3>
    <p>{translate('ra.auth.unauthorized_long', { user })}</p>
    <p>{translate('components.login.messages.unauthorized')}</p>
  </Layout>
}
