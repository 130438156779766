import React from 'react';
import { ArrayInput, GET_MANY_REFERENCE, NumberInput, SimpleForm, TextInput, useQuery, useRecordContext } from 'react-admin';
import { Grid } from '@material-ui/core'

import HorizontalFormIterator, { TransitionProps } from '../../common/ra/HorizontalFormIterator';
import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput';
import { ArrayI18nInput } from '../../common/inputs/I18nInput';

import { InfractionInput } from './InfractionInput';

const validate = values => {
  const errors = {};

  if (!values.infractionId) errors.infractionId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.name) errors.name = 'ra.validation.required'
  if (values.count == null) errors.count = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'infractionAccumulations',
  basePath: '/infractionAccumulations',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const arrayInputProps = {
  resource: 'infractionAccumulations',
  basePath: '/infractionAccumulations',
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

export default ({ initialValues, office, ...props }) => {
  const option = useRecordContext();

  const enabled = option != null;
  const { data: members = [], loaded } = useQuery({
    type: GET_MANY_REFERENCE,
    resource: 'infractionAccumulationMembers',
    payload: {
      target: 'infractionAccumulations',
      id: option?.id,
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'order', order: 'ASC' },
      filter: {}
    },
  }, { enabled })

  if (enabled && !loaded) return null;

  const values = { ...initialValues, members }
  return <SimpleForm initialValues={values} toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <TextInput source="name" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ArrayI18nInput {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source="members" label="resources.infractionAccumulations.fields.members" {...inputProps}>
          <HorizontalFormIterator disableReordering TransitionProps={TransitionProps}>
            <InfractionInput source="infractionId" label="" filter={{ officeId: office?.id }} {...arrayInputProps} />
          </HorizontalFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item xs={12}>
        <NumberInput source="count" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <InfractionInput source="infractionId" filter={{ officeId: office?.id }} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <SwitchInput source="isReplacement" helperText="resources.infractionAccumulations.helpers.isReplacement" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>;
}

