import React from 'react';
import moment from 'moment-timezone';
import { Typography, styled } from '@material-ui/core';
import { useLocale, useRecordContext } from 'react-admin';

const Alt = styled('div')(({ theme }) => ({
  color: theme.palette.text.disabled,
}))

export const getDateRange = (start, end, locale, timezone) => {
  const startDate = timezone ? moment.tz(start, timezone) : moment(start);
  const endDate = timezone ? moment.tz(end, timezone) : moment(end);
  const isSameYear = moment.utc().isSame(startDate, 'year')

  if (locale.startsWith('en')) {
    if (!startDate.isSame(endDate, 'month')) return `${startDate.format(isSameYear ? 'MMMM D' : 'LL')} - ${endDate.format(isSameYear ? 'MMMM D' : 'LL')}`
    if (!startDate.isSame(endDate, 'day')) return `${startDate.format(isSameYear ? 'MMMM D' : 'LL')} - ${endDate.format(isSameYear ? 'D' : 'LL')}`
    return startDate.format(isSameYear ? 'MMMM D' : 'LL');
  } else {
    if (!startDate.isSame(endDate, 'month')) return `${startDate.format(isSameYear ? 'D MMMM' : 'LL')} - ${endDate.format(isSameYear ? 'D MMMM' : 'LL')}`
    if (!startDate.isSame(endDate, 'day')) return `${startDate.format(isSameYear ? 'D' : 'LL')} - ${endDate.format(isSameYear ? 'D MMMM' : 'LL')}`
    return startDate.format(isSameYear ? 'D MMMM' : 'LL');
  }
}

const DateRangeField = ({ className, startSource, endSource, showLocal = false, ...props }) => {
  const locale = useLocale();
  const record = useRecordContext(props)
  if (!record) return null;

  const hasTimezone = record.timezone != null;
  const tzRange = getDateRange(record[startSource], record[endSource], locale, record.timezone || 'UTC')
  const localRange = getDateRange(record[startSource], record[endSource], locale)

  let range = showLocal && hasTimezone ? localRange : tzRange;
  if (showLocal && hasTimezone && localRange !== tzRange) {
    range = <>
      <div>{tzRange} {moment.tz(record[startSource], record.timezone || 'UTC').format('z')}</div>
      <Alt>{localRange}</Alt>
    </>
  }

  return <Typography
    component="span"
    variant="body2"
    noWrap
    className={className}
  >
    {range}
  </Typography>
}

DateRangeField.defaultProps = {
  label: 'Dates',
  addLabel: true
}

export default DateRangeField;
