import React, { Fragment, useEffect, useState } from 'react';
import { Button, Datagrid, ListContextProvider, NullableBooleanInput, Pagination, SimpleForm, useDataProvider, useList, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import { Queue } from '@material-ui/icons';

import { apiClient } from '../../http';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { InlineDateInput } from '../../common/inputs/DateInput';
import { OfficialTransactionTypeInput } from '../../common/inputs/EnumInputs';
import DateField from '../../common/fields/DateField';

import { ParticipantInput } from '../participants/ParticipantInput';
import { ParticipantField } from '../participants/ParticipantField';
import { GameField } from '../games/GameField';
import { GameInput } from '../games/GameInput';
import { AmountAndDescriptionField, TypeAndNoteField } from '../officialTransactions/TransactionsGrid';

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const useStyles = makeStyles(theme => ({
  certified: {
    width: '100%',
  }
}))

const TransactionGrid = props => {
  return <Datagrid size="medium" {...props}>
    <ParticipantField source="participantId" label="resources.officialTransactions.fields.participantId" link="show" includeId="full" sortable={false} />
    <GameField source="gameId" includeDate="inline" includeTime includeSurface="full" />
    <DateField source="date" format="LL" label="resources.officialTransactions.fields.date" />
    <TypeAndNoteField source="type" label="resources.officialTransactions.fields.type" />
    <AmountAndDescriptionField source="amount" label="resources.officialTransactions.fields.amount" />
  </Datagrid>
}

const TransactionList = () => {
  const dataProvider = useDataProvider();
  const payPeriod = useRecordContext();
  const { values } = useFormState();
  const { officeId, startTime, endTime, gameId, participantId, type, isGameCertified } = values;

  const { change } = useForm();
  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ transactions, setTransactions ] = useState([])

  const listContext = useList({
    data: transactions,
    loaded,
    loading,
    page: 1,
    perPage: 100
  })

  useEffect(() => {
    change('transactionIds', listContext?.selectedIds)
  }, [ listContext?.selectedIds, change ])

  useEffect(() => {
    setLoading(true);

    dataProvider.getList('officialTransactions', {
      filter: {
        officeId,
        effectiveOffices: false,
        startTime,
        endTime,
        gameId,
        participantId,
        type,
        isGameCertified,
        payPeriodId: null,
        'game.seasonId': payPeriod.seasonId
      },
      sort: { field: ['date', 'participant.lastName', 'participant.firstName'], order: ['ASC', 'ASC', 'ASC'] },
      pagination: { page: 1, perPage: 999 }
    })
      .then(res => {
        setTransactions(res.data);
        setLoaded(true);
        setLoading(false);
      })
      .catch(() => {
        setTransactions([]);
        setLoaded(true);
        setLoading(false);
      })
  }, [ officeId, dataProvider, startTime, endTime, gameId, participantId, type, isGameCertified, payPeriod.seasonId ])

  return <ListContextProvider value={listContext}>
    <TransactionGrid
      {...listContext}
      hasBulkActions
      rowClick="toggleSelection"
      isRowSelectable={() => true}
      isLoading={loading}
    />
    <Pagination rowsPerPageOptions={[50, 100, 250, 500]} />
  </ListContextProvider>
}

const AddTransactionsForm = props => {
  const payPeriod = useRecordContext()
  const classes = useStyles();

  return <SimpleForm initialValues={{ startTime: payPeriod.startDate, endTime: payPeriod.endDate }} {...props} {...inputProps} >
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <InlineDateInput
          source="startTime"
          label="resources.officialTransactions.filters.startTime"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InlineDateInput
          source="endTime"
          label="resources.officialTransactions.filters.endTime"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <GameInput
          source="gameId"
          label="resources.officialTransactions.fields.gameId"
          filter={{ 'transactions.officeId': payPeriod?.officeId }}
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ParticipantInput
          source="participantId"
          label="resources.officialTransactions.fields.participantId"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OfficialTransactionTypeInput
          source="type"
          label="resources.officialTransactions.fields.type"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <NullableBooleanInput
          source="isGameCertified"
          label="resources.games.filters.isCertified"
          className={classes.certified}
          {...inputProps}
        />
      </Grid>
    </Grid>
    <br />
    <TransactionList {...props} />
  </SimpleForm>
}

const AddTransactionModal = ({ open, handleClose, ...props }) => {
  const translate = useTranslate();
  const payPeriod = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const onSubmit = async values => {
    const transactionIds = values?.transactionIds || [];
    if (!transactionIds.length || !payPeriod?.id) {
      handleClose();
      return;
    }

    apiClient('/officialTransactions/assignPayPeriod', {
      method: 'POST',
      data: {
        transactionIds,
        payPeriodId: payPeriod.id,
      }
    })
      .then(({ data }) => {
        handleClose();
        refresh();
        notify('resources.officialTransactions.notifications.assigned_pay_period');
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify('resources.officialTransactions.notifications.assign_pay_period_error')
      })
  }

  return <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" fullScreen={fullScreen}>
    <DialogTitle>{translate('resources.payPeriods.labels.add_transactions')}</DialogTitle>
    <DialogContent>
      <AddTransactionsForm save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.add" cancelLabel="ra.action.close" onCancel={handleClose} />
      } />
    </DialogContent>
  </Dialog>
}

export default ({ ...props }) => {
  const translate = useTranslate();
  const [ open, setOpen ] = useState(false);

  const handleClick = () => setOpen(true);

  return <>
    <Button label={translate('resources.payPeriods.actions.add_transactions')} onClick={handleClick}>
      <Queue />
    </Button>
    {open && <AddTransactionModal open={open} handleClose={() => setOpen(false)} />}
  </>
}
