import React from 'react';
import { useRecordContext } from 'react-admin';
import { GroupOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { AssignSystemGrid } from '../../assignSystems/AssignSystemGrid';
import { AssignRuleList } from '../../assignRules/AssignRuleList';

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  return <ListCard
    title="resources.assignSystems.labels.card.title"
    subtitle="resources.assignSystems.labels.card.subtitle"
    icon={<GroupOutlined />}
    collapsable
    reference="assignSystems"
    basePath="/assignSystems"
    target="officeId"
    filter={{ seasonId, effectiveOffices: true, includeParents: true, _scope: 'Parents' }}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <AssignSystemGrid
      rowClick="expand"
      expand={<AssignRuleList type="systemId" officeId={office?.id} />}
    />
  </ListCard>
}
