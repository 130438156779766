import React from 'react';
import { Pagination, TextField, useRecordContext } from 'react-admin';

import { EXTERNAL_TEAMS_OFFICES } from '@hisports/common/src/constants';

import ListCard from '../../../common/cards/ListCard';
import TextArrayField from '../../../common/fields/TextArrayField';
import { isAuthorized } from '../../../common/Authorize';

import { ParticipantField } from '../../participants/ParticipantField';
import { MemberDatagrid, AddMemberButton } from '../../members/MemberModal';
import { MemberStatusField } from '../../members/MemberFields';

import ImportRoster from '../dialogs/upload';
import ImportProfile from '../../profiles/dialogs/import';
import { usePermissions } from '../../../http';

export default props => {
  const team = useRecordContext(props);
  const permissions = usePermissions()
  const isSystemUser = permissions.some(p => p.roleType === 'System');

  const canAddMember = (isAuthorized(team, 'members', 'create') && !team.externalId) || isSystemUser || EXTERNAL_TEAMS_OFFICES.includes(team?.officeId);
  const canImportMembers = (isAuthorized(team, 'members', 'import') && !team.externalId) || isSystemUser || EXTERNAL_TEAMS_OFFICES.includes(team?.officeId);
  const canImportProfiles = isAuthorized(team, 'profiles', 'import', true);

  const addButton = canAddMember && <AddMemberButton initialValues={{ teamId: team?.id }} />
  const actions = [
    canImportMembers && <ImportRoster teamId={team?.id} size="small" />,
    canImportProfiles && <ImportProfile teamId={team?.id} size="small" />,
  ]

  return <ListCard
    title="resources.teams.labels.team_roster"
    reference="members"
    target="teams"
    sort={{ field: "number", order: "ASC" }}
    perPage={50}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    addButton={addButton}
    actions={actions}
  >
    <MemberDatagrid size="small">
      <ParticipantField source="participantId" includeId="inline" />

      <TextField source="number" />
      <TextArrayField source="positions" />

      <MemberStatusField label="resources.members.fields.status" />
    </MemberDatagrid>
  </ListCard>
}
