import React from 'react';
import { ImageField, EditButton, useRecordContext } from 'react-admin'
import { Grid, Table, TableBody } from '@material-ui/core';

import Row from '../../common/cards/TableRow';
import { isAuthorized } from '../../common/Authorize';
import InfoCard from '../../common/cards/InfoCard';
import DateField from '../../common/fields/DateField';
import ContentField from '../../common/fields/ContentField';

import { OfficeField } from '../offices/OfficeField'

const PostCard = props => {
  const post = useRecordContext(props);
  const canEditPost = isAuthorized(post, 'posts', 'edit')
  return <InfoCard actions={
    canEditPost && <EditButton label="resources.posts.actions.edit" record={post} />
  }>
    <Table>
      <TableBody>
        <Row>
          <ImageField source="cover.url" />
          <ContentField source="content" />
        </Row>
        <Row label="resources.posts.fields.date">
          <DateField source="date" />
        </Row>
        <Row label="resources.posts.fields.officeId">
          <OfficeField source="officeId" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}

export const PostShowLayout = props =>
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <PostCard />
    </Grid>
  </Grid>
