import React, { Fragment, useEffect, useState } from 'react';
import { RecordContextProvider, useTranslate, useRecordContext, BooleanInput, SimpleForm } from 'react-admin';
import {
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  LinearProgress,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { BarChart, ExpandMore } from '@material-ui/icons'
import { useFormState } from 'react-final-form';

import { FF_DRAFT_GAMES } from '@hisports/common/featureFlags';
import { isEmpty } from '@hisports/parsers';

import { apiClient, useFlag } from '../../http';

import { TeamField } from '../teams/TeamField';
import { OfficeField } from '../offices/OfficeField';
import { ScheduleGroupInput } from '../groups/GroupInput';
import { GroupField } from '../groups/GroupField';
import { ActionMenuItem } from '../../common/ActionMenu';
import DateRangeField from '../../common/fields/DateRangeField';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
}

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
  overflowX: 'auto',
  overflowY: 'hidden',
})

const ByesList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyleType: 'none',
})

const useStyles = makeStyles(theme => ({
  draftInput: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  groupInput: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  status: {
    width: '100%',
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  table: {
    // add margin to minimize scrolling
    marginRight: theme.spacing(15),
  },
  officeHeader: {
    width: theme.spacing(3),
    height: theme.spacing(15),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  rotate: {
    transform: 'translate(25px, 58px) rotate(315deg)',
    width: theme.spacing(1.5),
  },
  rotateInner: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(.5),
  },
  officeCell: {
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  link: {
    display: 'inline-block',
    width: theme.spacing(21),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loaderDiv: {
    height: theme.spacing(0.5)
  }
}));

const Accordion = props => <MuiAccordion TransitionProps={{ unmountOnExit: true }} {...props} />
const Heading = props => <Typography variant="body2" gutterBottom {...props} />

const Summary = ({ matchups }) => {
  const translate = useTranslate();

  if (isEmpty(matchups)) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_matchups')}</MuiAccordionDetails>

  const hasGroup = matchups.some(matchup => matchup.groupId);
  return <Table padding="dense">
    <TableHead>
      <TableRow>
        <TableCell>{translate('resources.schedules.labels.team')}</TableCell>
        {hasGroup && <TableCell>{translate('resources.schedules.labels.group')}</TableCell>}
        <TableCell numeric>{translate('resources.schedules.labels.opponents')}</TableCell>
        <TableCell numeric>{translate('resources.schedules.labels.conflicts')}</TableCell>
        <TableCell numeric>{translate('resources.schedules.labels.byes')}</TableCell>
        <TableCell numeric>{translate('resources.schedules.labels.home')}</TableCell>
        <TableCell numeric>{translate('resources.schedules.labels.away')}</TableCell>
        <TableCell numeric>{translate('resources.schedules.labels.total')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {matchups.map(team => {
        const { teamId, home, away, byes, conflicts, totalOpponents, groupId } = team;
        return <RecordContextProvider value={{ teamId, groupId }}>
          <TableRow key={teamId}>
            <TableCell component="th" scope="row">
              <TeamField source="teamId" includeId="inline" />
            </TableCell>
            {hasGroup && <TableCell>
              <GroupField source="groupId" />
            </TableCell>}
            <TableCell numeric>{totalOpponents}</TableCell>
            <TableCell numeric>{conflicts}</TableCell>
            <TableCell numeric>{byes}</TableCell>
            <TableCell numeric>{home}</TableCell>
            <TableCell numeric>{away}</TableCell>
            <TableCell numeric>{home + away}</TableCell>
          </TableRow>
        </RecordContextProvider>
      })}
    </TableBody>
  </Table>
}

const Matchups = ({ matchups, groupId }) => {
  const translate = useTranslate();

  if (isEmpty(matchups)) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_matchups')}</MuiAccordionDetails>
  const showGroups = groupId != null;
  const teamIds = matchups.map(team => team.teamId);

  return <Table padding="dense">
    <TableHead>
      <TableRow>
        <TableCell numeric />
        <TableCell>{translate('resources.schedules.labels.team')}</TableCell>
        {showGroups && <TableCell>{translate('resources.schedules.labels.group')}</TableCell>}
        {teamIds.map((teamId, index) => <TableCell numeric>{index + 1}</TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {matchups.map((team, index) => {
        const { teamId, opponents } = team;

        return <RecordContextProvider value={{ teamId, groupId }} key={teamId}>
          <TableRow hover>
            <TableCell component="th" scope="row" numeric>{index + 1}</TableCell>
            <TableCell><TeamField source="teamId" /></TableCell>
            {showGroups && <TableCell><GroupField source="groupId" /></TableCell>}
            {opponents.map(opponent => {
              const { home, away } = opponent;

              return <TableCell numeric>
                {(home + away === 0) ? null : `${home}/${away}`}
              </TableCell>
            })}
          </TableRow>
        </RecordContextProvider>
      })}
    </TableBody>
  </Table>
}

const OfficeMatchups = ({ matchups, officeIds }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (isEmpty(matchups)) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_matchups')}</MuiAccordionDetails>

  return <Table padding="dense" className={classes.table}>
    <TableHead>
      <TableRow>
        <TableCell />
        {officeIds.map(officeId => <TableCell className={classes.officeHeader}>
          <div className={classes.rotate}>
            <span className={classes.rotateInner}>
              <RecordContextProvider value={{ officeId }}>
                <OfficeField source="officeId" classes={{ link: classes.link }} />
              </RecordContextProvider>
            </span>
          </div>
        </TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {matchups.map(team =>
        <TableRow hover>
          <TableCell component="th" scope="row">
            <RecordContextProvider value={team}>
              <TeamField source="teamId" />
            </RecordContextProvider>
          </TableCell>
          {officeIds.map(officeId => <TableCell numeric className={classes.officeCell}>
            {team?.officeMatchups?.[officeId] || 0}
          </TableCell>)}
        </TableRow>
      )}
    </TableBody>
  </Table>
}

const Byes = ({ byes }) => {
  const translate = useTranslate();

  if (!byes?.length || !byes.some(week => week.teamIds?.length > 0)) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_byes')}</MuiAccordionDetails>
  return <Table padding="dense">
    <TableHead>
      <TableRow>
        <TableCell numeric align="center">{translate('resources.schedules.labels.week')}</TableCell>
        <TableCell align="left">{translate('ra.date.name')}</TableCell>
        <TableCell>{translate('resources.schedules.labels.team')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {byes.map((week, index) =>
        <TableRow>
          <TableCell component="th" scope="row" numeric align="center">
            {index + 1}
          </TableCell>
          <TableCell component="th" scope="row" align="left">
            <RecordContextProvider value={week}>
              <DateRangeField source="startDate" startSource="startDate" endSource="endDate" />
            </RecordContextProvider>
          </TableCell>
          <TableCell>{!week.teamIds.length
            ? translate('ra.message.none')
            : <ByesList>
              {week.teamIds.map(teamId => {
                return <RecordContextProvider value={{ teamId }} key={teamId}>
                  <li>
                    <TeamField source="teamId" />
                  </li>
                </RecordContextProvider>
              })}
            </ByesList>}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
}

const AnalyzeBody = ({ officeId, scheduleId }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values } = useFormState();
  const record = useRecordContext();
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { matchups = [], officeMatchups, officeIds, byes } = analysis;
  const { groupId, includeDraft } = values;
  const { seasonId } = record;

  useEffect(() => {
    setLoading(true);
    apiClient(`/offices/${officeId}/analyze`, {
      method: "GET",
      params: {
        scheduleId,
        groupId,
        includeDraft,
        seasonId
      }
    })
      .then(res => res.data)
      .then(data => setAnalysis(data))
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [ scheduleId, officeId, groupId, includeDraft, seasonId])


  if (!matchups?.length && loading) return <LinearProgress colour="primary" variant="indeterminate" />
  if (error) return <MuiAccordionDetails>{translate('resources.schedules.messages.unable_to_load')}</MuiAccordionDetails>

  if (!matchups?.length) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_matchups')}</MuiAccordionDetails>
  if (matchups?.length > 200) return <MuiAccordionDetails>{translate('resources.schedules.messages.too_many_matchups')}</MuiAccordionDetails>

  return <>
    <div className={classes.loaderDiv}>
      {loading && <LinearProgress colour="primary" variant="indeterminate" />}
    </div>
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Heading>{translate('ra.action.summary')}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <Summary matchups={matchups} />
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Heading>{translate('resources.schedules.labels.matchups')}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <Matchups matchups={matchups} groupId={groupId} />
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Heading>{translate('resources.schedules.labels.office_matchups')}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <OfficeMatchups matchups={officeMatchups} officeIds={officeIds} />
      </AccordionDetails>
    </Accordion>

    {scheduleId ? <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Heading>{translate('resources.schedules.labels.byes')}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <Byes matchups={matchups} byes={byes} />
      </AccordionDetails>
    </Accordion> : null}
  </>
}

const AnalyzeForm = ({ scheduleId }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const isEnabled = useFlag();

  return <>
    {isEnabled(FF_DRAFT_GAMES) && <BooleanInput className={classes.draftInput} source="includeDraft" label="resources.schedules.labels.include_drafts" helperText="" {...inputProps} />}
    <ScheduleGroupInput className={classes.groupInput} source="groupId" scheduleId={scheduleId} defaultValue={null} showNone={translate('resources.groups.messages.all_groups')} {...inputProps} />
  </>
}

const AnalyzeDialog = ({ isOpen, handleClose, scheduleId, officeId }) => {
  const translate = useTranslate();

  return <Dialog
    scroll="body"
    maxWidth="md"
    fullWidth
    open={isOpen}
    onClose={handleClose}
  >
    <DialogTitle>{translate('resources.schedules.labels.analysis')}</DialogTitle>
    {isOpen && <SimpleForm component={Fragment} resource="schedules" toolbar={null}>
      <AnalyzeForm scheduleId={scheduleId} />
      <AnalyzeBody scheduleId={scheduleId} officeId={officeId} />
    </SimpleForm>}
    <DialogActions>
      <Button color="primary" onClick={handleClose}>{translate('ra.action.close')}</Button>
    </DialogActions>
  </Dialog>
}

export const AnalyzeButton = ({ onClick, size = "small", ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  return <>
    <Button color="primary" size={size} variant="text" startIcon={<BarChart />} onClick={() => setOpen(true)}>
      {translate('ra.action.analyze')}
    </Button>
    {isOpen && <AnalyzeDialog
      isOpen={isOpen}
      handleClose={() => setOpen(false)}
      {...props}
    />}
  </>
}

export const AnalyzeMenuItem = ({ onClick, handleClose: onClose, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const handleClick = () => {
    onClick();
    setOpen(true);
  }

  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><BarChart fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.offices.labels.analyzeSchedules')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <AnalyzeDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />}
  </>
}
