import React from 'react';
import { Datagrid, ReferenceField, useRecordContext, useTranslate } from 'react-admin';

import { displayTime, getPenalty, isLegacyRulebookSeason } from '@hisports/scoresheet/src/util';

import { useSport } from '../../http';
import FunctionField from '../../common/fields/FunctionField';
import { useLocale } from '../../locale';
import { TeamField } from '../teams/TeamField';
import { GameField } from '../games/GameField';
import { ParticipantField } from '../participants/ParticipantField';
import { ScheduleField } from '../schedules/ScheduleField';
import { InfractionField } from '../infractions/InfractionField'

const GamePenaltyField = ({ seasonId, ...props }) => {
  const translate = useTranslate();
  const gamePenalty = useRecordContext();
  const sport = useSport();
  const [ locale ] = useLocale();
  const infractions = [gamePenalty?.meta?.infraction].filter(Boolean)
  const durations = {
    [gamePenalty.duration]: gamePenalty?.meta?.duration
  }

  return <FunctionField render={() => {
    return getPenalty(gamePenalty, [], infractions, durations, [], {
      start: translate('resources.scoresheets.labels.start'),
      ended: translate('resources.scoresheets.labels.ended'),
    }, sport, seasonId, locale)
  }} {...props} />
}

const GamePenaltyGameTimeField = props => {
  const gamePenalty = useRecordContext();
  const sport = useSport();

  if (!gamePenalty?.gameTime) return null

  return <FunctionField render={() => {
    return displayTime(gamePenalty.gameTime, sport, false, false)
  }} {...props} />
}

export const GamePenaltyGrid = ({ seasonId, multipleGames = false, hideTeam = false, hideParticipant = false, ...props }) => {
  if (!seasonId) return null;

  const isLegacy = isLegacyRulebookSeason(seasonId);

  return <Datagrid size="medium" {...props}>
    {!hideParticipant && <ParticipantField source="participantId" includeId="full" />}
    {!hideTeam && <TeamField source="teamId" includeId="full" />}
    {multipleGames && <ReferenceField source="gameId" reference="games" resource="games" basePath="/games" label="resources.games.fields.scheduleId" link={false}>
      <ScheduleField source="scheduleId" includeCategory="full" includeType="full" />
    </ReferenceField>}
    {multipleGames && <GameField source="gameId" link="scoresheet" includeDate="inline" includeSurface="full" />}
    {isLegacy
      ? <GamePenaltyField label="resources.gamepenalties.fields.infraction" seasonId={seasonId} />
      : <InfractionField source="infractionId" />
    }
    <GamePenaltyGameTimeField label="resources.gamepenalties.fields.gameTime" />

  </Datagrid>
}
