import React, { Fragment, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { ProfileForm } from './ProfileForm';

const UpdateProfileDialog = ({ source = 'id', ...props }) => {
  const profile = useRecordContext(props);
  return <EditResourceDialog source={source} label="resources.profiles.labels.edit" disableDelete id={profile?.[source]} resource="profiles" basePath="/profiles" {...props}>
    <ProfileForm />
  </EditResourceDialog>
}

export const EditProfileButton = props => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" startIcon={<Edit />} onClick={() => setOpen(true)}>
      {translate('resources.profiles.actions.edit')}
    </Button>
    {isOpen && <UpdateProfileDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />}
  </>
}
