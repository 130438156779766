import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { ArenaSlotForm } from './ArenaSlotForm';

const AddArenaSlotDialog = props =>
  <CreateResourceDialog label="resources.arenaslots.actions.add" resource="arenaslots" basePath="/arenaslots" maxWidth="md" {...props}>
    <ArenaSlotForm />
  </CreateResourceDialog>

const EditArenaSlotDialog = props =>
  <EditResourceDialog label="resources.arenaslots.labels.edit" resource="arenaslots" basePath="/arenaslots" maxWidth="md" {...props}>
    <ArenaSlotForm />
  </EditResourceDialog>

export const AddArenaSlotButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.arenaslots.actions.add')}
    </Button>
    <AddArenaSlotDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const ArenaSlotDatagrid = props => <DialogDatagrid dialog={EditArenaSlotDialog} {...props} />
