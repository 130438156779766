import React from 'react';
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Launch } from '@material-ui/icons';

import { useTenant } from '../http';

export default props => {
  const translate = useTranslate();
  const tenant = useTenant();
  const { externalId } = useRecordContext(props);
  const resource = useResourceContext(props);

  if (!externalId || !tenant?.registryUrl) return null;

  let url;
  switch (resource) {
    case 'teams':
      url = `${tenant.registryUrl}/teams/profile/${externalId}/roster`;
      break;
    case 'participants':
      url = `${tenant.registryUrl}/members/profile/${externalId}/overview`;
      break;
    default:
      break;
  }

  if (!url) return null;
  return <Button href={url} target="_blank" rel="noreferrer" color="primary" startIcon={<Launch />}>
    {translate('ra.action.open_in_id')}
  </Button>
}
