import React, { useMemo, useState } from 'react';
import { useRefresh, useTranslate } from 'react-admin';
import { startCase } from 'lodash';
import { useMediaQuery,
  ListItem,
  ListItemText,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Box,
  alpha,
  Tooltip,
} from '@material-ui/core'
import {
  Sports as SportsIcon,
  MyLocation as MyLocationIcon,
  SwapHoriz as SwapHorizIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Announcement as ReportIcon,
  FontDownloadOutlined as FontDownloadOutlinedIcon,
} from '@material-ui/icons'
import {
  displayTime,
  getTeamType,
  getSummaryEventDetails,
  findAdditionalPenalties,
  getInfractionType,
  getPenaltyFlagName,
  getFlagColor,
  getLinkedPenalties,
  isLegacyRulebookSeason,
  requiresIncidentReport,
  getSeverity,
  getMemberLabel
} from '@hisports/scoresheet/src/util';
import {
  EVENT_TYPE_GOAL,
  EVENT_TYPE_PENALTY,
  EVENT_TYPE_GOALIE,
  HOME,
  FLAG_COLORS,
} from '@hisports/scoresheet/src/constants';
import { getGameMembers, getPenalties, isGameCertified, isGameCompleted } from '@hisports/scoresheet/src/selectors';
import { deleteGoal, deletePenalty } from '@hisports/scoresheet/src/actions';
import { translateApiProperty } from '@hisports/common';

import { useLocale } from '../../../locale';
import { isAuthorized } from '../../../common/Authorize';
import AlertDialog from '../../../common/dialogs/AlertDialog';
import { useSport, useScopes } from '../../../http';
import { useBranchSettings } from '../../branchOfficeSettings';
import { AddIncidentReportDialog } from '../gameIncidents/IncidentReportModal';
import { useIncidentReports } from '../gameIncidents/useIncidentReports';
import Flag from '../certification/Flag';

import { useMeta, useScoresheet, useScoresheetDispatch, useScoresheetStore } from "../ScoresheetContext"
import { SummaryEditModal } from './SummaryEditModal';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center',
  },
  dense: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  extraDense: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  summaryItems: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  nowrap: {
    flexWrap: 'nowrap',
  },
  bgWhite: {
    backgroundColor: 'white',
  },
  flaggedBgWarning: {
    backgroundColor: alpha(theme.palette.warning[50], .4),
  },
  flaggedBgError: {
    backgroundColor: alpha(theme.palette.error[50], .4),
  },
  flagIcon: {
    alignSelf: 'center',
    marginRight: theme.spacing(1),
  },
  accumulation: {
    display: 'flex',
    alignItems: 'center'
  },
  accumulationIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'middle'
  }
}))

const ActionIcon = ({ eventType, ...props }) => {
  if (eventType === EVENT_TYPE_GOAL) return <MyLocationIcon {...props} />
  if (eventType === EVENT_TYPE_PENALTY) return <SportsIcon {...props} />
  if (eventType === EVENT_TYPE_GOALIE) return <SwapHorizIcon {...props} />
  return <></>; // eslint-disable-line react/jsx-no-useless-fragment
}

const ActionChip = ({ teamType, eventType, color }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return <Chip
    label={translate(`resources.scoresheets.labels.${teamType}.${eventType}`, { _: '' })}
    icon={<ActionIcon eventType={eventType} fontSize="small" />}
    variant={teamType === HOME ? 'outlined' : undefined}
    className={teamType === HOME ? classes.bgWhite : ''}
  />
}

const AccumulationIcon = ({ event }) => {
  const classes = useStyles()
  const translate = useTranslate();
  const locale = useLocale();
  const { meta } = useMeta();

  if (!event?.accumulationId) return null;

  const accumulation = meta.accumulations?.find(accumulation => accumulation.id === event.accumulationId);

  let title = translate('resources.scoresheets.labels.penalty.accumulation')

  if (accumulation?.name) {
    const accumulationName = translateApiProperty(accumulation, 'name', locale)
    title += `: ${accumulationName}`
  }

  return <Tooltip title={title} placement="top" arrow>
    <FontDownloadOutlinedIcon className={classes.accumulationIcon} />
  </Tooltip>
}

export const ListItemSummaryDescription = ({ event, displayGameTime, participantId }) => {
  const [ locale ] = useLocale();
  const translate = useTranslate();
  const { meta, game } = useMeta();
  const sport = useSport();
  const classes = useStyles();

  const members = useScoresheet(scoresheet => getGameMembers(scoresheet, { showSuspended: true }));
  const penalties = useScoresheet(scoresheet => getPenalties(scoresheet, meta.infractions, meta.types, sport, game.seasonId));

  const description = !event ? getMemberLabel(members, participantId, locale) : getSummaryEventDetails(event, members, meta.infractions, meta.rules, meta.types, penalties, {
    coach: translate('resources.scoresheets.labels.coach'),
    bench: translate('resources.scoresheets.labels.bench_staff'),
    shg: translate('resources.scoresheets.messages.shg'),
    ppg: translate('resources.scoresheets.messages.ppg'),
    eng: translate('resources.scoresheets.messages.eng'),
    psg: translate('resources.scoresheets.messages.psg'),
    og: translate('resources.scoresheets.messages.og'),
    assist_abbr: translate('resources.scoresheets.messages.assist_abbr'),
    served_abbr: translate('resources.scoresheets.messages.served_abbr'),
    start: translate('resources.scoresheets.labels.start'),
    ended: translate('resources.scoresheets.labels.ended'),
    empty_net: translate('resources.scoresheets.messages.empty_net'),
    put_in: translate('resources.scoresheets.messages.put_in_goalie'),
  }, sport, game.seasonId, locale)
  const [primary, ...rest] = description.split(' - ');
  let secondary = rest.join(' - ');

  if (displayGameTime) {
    secondary = `${displayTime(event?.gameTime, sport, true)} - ${secondary}`
  }

  if (event?.accumulationId) {
    secondary = <div className={classes.accumulation}>
      {secondary}
      <AccumulationIcon event={event} />
    </div>
  }

  return <ListItemText primary={primary} secondary={secondary} />
}

const DeleteItemButton = ({ event, game, setAlert }) => {
  const dispatch = useScoresheetDispatch();
  const refresh = useRefresh();
  const store = useScoresheetStore();
  const { meta } = useMeta();
  const translate = useTranslate();
  const sport = useSport();

  const handleClick = async () => {
    const alert = {
      title: translate(`resources.scoresheets.labels.${event?.eventType}.delete`, { _: 'Delete' }),
      message: translate(`resources.scoresheets.messages.${event?.eventType}.delete_confirm`, { _: 'Are you sure you want to delete?' }),
      accept: translate('ra.action.delete'),
      reject: translate('ra.action.cancel'),
      isOpen: true,
    }

    if (event?.eventType === EVENT_TYPE_GOAL) {
      setAlert({
        ...alert,
        onClose: async (result) => {
          if (result) {
            await dispatch(deleteGoal(game.id, event.id))
          }
          setAlert(alert => ({ ...alert, isOpen: false }))
        }
      })
    }

    if (event?.eventType === EVENT_TYPE_PENALTY) {
      let additionalPenaltiesToDelete = []
      const isAccumulationPenalty = !!event.accumulationId

      if (!isAccumulationPenalty) {
        const scoresheetState = store.getState();
        const { penalties } = scoresheetState;
        additionalPenaltiesToDelete = isLegacyRulebookSeason(game.seasonId)
          ? findAdditionalPenalties(event, penalties, meta.rules, sport)
          : getLinkedPenalties(event, penalties, sport, true);
      }

      if (additionalPenaltiesToDelete.length) {
        alert.title = translate('resources.scoresheets.labels.penalty.delete_additional')
        alert.message += ` ${translate('resources.scoresheets.messages.penalty.delete_additional', additionalPenaltiesToDelete.length)}`
      }

      setAlert({
        ...alert,
        onClose: async (result) => {
          if (result) {
            await Promise.allSettled([
              dispatch(deletePenalty(game.id, event.id)),
              ...additionalPenaltiesToDelete.map(penalty => dispatch(deletePenalty(game.id, penalty.id)))
            ])
            refresh()
          }
          setAlert(alert => ({ ...alert, isOpen: false }))
        }
      })
    }
  }

  return <IconButton onClick={handleClick}>
    <DeleteIcon fontSize="small" />
  </IconButton>
}

const EditItemButton = ({ event }) => {
  const translate = useTranslate();
  const [modalOpen, setModalOpen] = useState(false);
  const { eventType } = event;

  return <>
    <IconButton onClick={() => setModalOpen(true)}>
      <EditIcon fontSize="small" />
    </IconButton>
    {modalOpen && <SummaryEditModal
      title={translate(`resources.scoresheets.labels.edit_${eventType}`, { _: startCase(eventType) })}
      event={event}
      open={modalOpen}
      setModalOpen={setModalOpen}
    />}
  </>
}

const IncidentReportButton = ({ event, game }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return <>
    <IconButton onClick={() => setModalOpen(true)}>
      <ReportIcon fontSize="small" />
    </IconButton>
    {modalOpen && <AddIncidentReportDialog
      initialValues={{ gameId: game?.id, participantId: event?.participantId, penaltyId: event?.id }}
      event={event}
      isOpen={modalOpen}
      handleClose={() => setModalOpen(false)}
    />}
  </>
}

export const SummaryItem = ({ event, status, isAssignedOfficial, isScorekeeper, ...props }) => {
  const { game, meta } = useMeta();
  const sport = useSport();
  const store = useScoresheetStore();
  const scoresheet = store.getState();
  const scopes = useScopes();
  const { data: branchSettings } = useBranchSettings(game?.officeId);
  const [ alert, setAlert ] = useState({ isOpen: false });

  const classes = useStyles();
  const translate = useTranslate();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { eventType, gameTime } = event;
  const teamType = getTeamType(game, event.teamId);
  const isCompleted = useScoresheet(scoresheet => isGameCompleted(scoresheet))
  const isCertified = useScoresheet(scoresheet => isGameCertified(scoresheet))

  const incidentsEnabled = isAuthorized(scopes, 'gameIncidents', 'show') || isAssignedOfficial
  const { data: incidents, loading: incidentsLoading } = useIncidentReports(game?.id, incidentsEnabled);

  const infractionType = getInfractionType(event, meta.infractions, meta.types, game.seasonId)
  const severity = getSeverity(event, meta.infractions, meta.types, game.seasonId);
  const requireIncidentReport = eventType === EVENT_TYPE_PENALTY && requiresIncidentReport(infractionType, severity);

  const hasIncidentReport = useMemo(() => {
    return incidents?.find(({ penaltyId }) => penaltyId === event.id)
  }, [incidents, event])

  const canEditSummaryList = status.scorekeeping && ((isScorekeeper && !isCompleted) || isAuthorized(game, 'scoresheets', 'full')) && !isCertified

  const showEditItemButton = canEditSummaryList && (eventType === EVENT_TYPE_PENALTY || eventType === EVENT_TYPE_GOAL)
  const showDeleteItemButton = showEditItemButton
  const showAddIncidentReportButton = requireIncidentReport && (!incidentsLoading && !hasIncidentReport) && (isAuthorized(scopes, 'gameIncidents', 'create') || isAssignedOfficial) && branchSettings?.ff_incidents;

  const flagName = event.eventType === EVENT_TYPE_PENALTY ? getPenaltyFlagName(scoresheet, event.id, meta): null
  const flagColor = getFlagColor(flagName);
  const tooltip = flagName ? translate(`resources.games.labels.flags.${flagName}`, 1) : null;

  const flaggedBgClassName = (() => {
    switch (flagColor) {
      case FLAG_COLORS.RED:
      case FLAG_COLORS.RED_ORANGE:
        return classes.flaggedBgError;
      case FLAG_COLORS.ORANGE:
        return classes.flaggedBgWarning
      default:
        return '';
    }
  })();

  return <ListItem className={`${classes.dense} ${flaggedBgClassName}`} {...props}>
    <Grid container spacing={isSmall ? 1 : 3} alignItems="center">
      {!isSmall &&
        <>
          <Grid item md={1} className={classes.center}>
            <ListItemText primary={displayTime(gameTime, sport, false, false)} />
          </Grid>
          <Grid item md={2}>
            <ActionChip teamType={teamType} eventType={eventType} color={flagColor} />
          </Grid>
          <Grid item md>
            <ListItemSummaryDescription event={event} {...props} />
          </Grid>
        </>
      }
      {isSmall &&
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            {displayTime(gameTime, sport, false, false)} - {translate(`resources.scoresheets.labels.${teamType}.${eventType}`, { _: '' })}
          </Typography>
          <ListItemSummaryDescription event={event} {...props} />
        </Grid>
      }
      <Grid item className={!isSmall ? classes.nowrap : ''} md="auto" xs={4}>
        <Box display="flex" alignItems="center">
          {tooltip && <Flag className={classes.flagIcon} color={flagColor} tooltip={tooltip} />}
          {showAddIncidentReportButton && <IncidentReportButton event={event} game={game} />}
          {showEditItemButton && <EditItemButton event={event} game={game} />}
          {showDeleteItemButton && <DeleteItemButton event={event} game={game} setAlert={setAlert} />}
        </Box>
      </Grid>
    </Grid>
    <AlertDialog {...alert} />
  </ListItem>
}
