import { byParticipantId, byTeamId } from '../util/index.js'
import { getLineupGoalies } from './lineups.js';

export const getGoalieChanges = (state, { teamId, includeStarters = false } = {}) => {
  if (!state) return [];
  let goalies = state.goalies || [];
  if (!includeStarters) {
    goalies = goalies.filter(goalie => !goalie.isStarter);
  }
  if (teamId) {
    goalies = goalies.filter(byTeamId(teamId));
  }
  return goalies;
}

export const getGoalie = (state, { teamId = null } = {}) => {
  if (!state || !teamId) return;
  const goalies = getGoalieChanges(state, { teamId, includeStarters: true })
  if (!goalies.length) return;
  return goalies[goalies.length - 1];
}

export const getShots = (state, { teamId, participantId } = {}) => {
  if (!state) return [];
  const shots = state.shots || [];
  if (teamId) {
    return shots.filter(byTeamId(teamId))
  }
  if (participantId) {
    return shots.find(byParticipantId(participantId))
  }
  return shots;
}

const setupTotals = (teamId, shots) => goalie => {
  const goalieShots = shots.find(g => g.participantId == goalie.participantId)
  return {
    ...goalie,
    teamId,
    totals: goalieShots ? goalieShots.totals : [null, null, null, null],
  }
}

export const getGoaliesShots = (state, game, sport) => {
  const shots = getShots(state);
  const homeGoalies = getLineupGoalies(state, { teamId: game.homeTeamId, affiliatesLast: false, sport })
  const awayGoalies = getLineupGoalies(state, { teamId: game.awayTeamId, affiliatesLast: false, sport })

  return [
    ...homeGoalies.map(setupTotals(game.homeTeamId, shots)),
    ...awayGoalies.map(setupTotals(game.awayTeamId, shots)),
  ]
}
