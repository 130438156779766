import { uniqBy } from '@hisports/common/src/lodash.js';
import {
  LINEUP_UPDATED, SCORESHEET_RESET,
} from '../actions/index.js';

export default (state = [], action) => {
  switch (action.type) {
    case LINEUP_UPDATED: {
      const lineup = action.payload.event;

      const members = uniqBy(lineup.members, 'participantId')

      // replacing suspensions for the current team members that are serving
      return members.reduce((purges, member) => {
        if (!member.suspension || !member.suspension.id || !member.suspension.purgeId) return purges
        const purge = {
          id: member.suspension.purgeId,
          teamId: lineup.teamId,
          participantId: member.participantId,
          suspensionId: member.suspension.id,
        }
        return [...purges, purge]
      }, [...state].filter(suspension => suspension.teamId !== lineup.teamId))
    }
    case SCORESHEET_RESET: {
      return [];
    }

    default:
      return state;
  }
}
