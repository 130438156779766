import React from 'react';
import { DatagridRow, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import FunctionField from '../../common/fields/FunctionField'
import GroupedView from '../../common/GroupedView';
import { getSurfaceAttributes, getSurfaceCity, getSurfaceName } from './util';

const useStyles = makeStyles(theme => ({
  row: {
    '& .column-name': {
      width: theme.spacing(50),
    },
    '& .column-fraction': {
      width: theme.spacing(50),
    },
  }
}))

const SurfacesList = ({ records, basePath, resource, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles(props);

  return records.map(record =>
    <DatagridRow key={record.id} basePath={basePath} resource={resource} record={record} id={record.id} rowClick="show" className={classes.row}>
      <FunctionField source="name" render={surface => getSurfaceName(surface)} />
      <FunctionField source="fraction" render={surface => getSurfaceAttributes(surface, translate)} />
    </DatagridRow>
  )
}

export const SurfaceView = () =>
  <GroupedView grouping={surface => getSurfaceCity(surface)}>
    <SurfacesList />
  </GroupedView>
