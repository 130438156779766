import React, { useEffect, useState } from 'react';
import { ArrayInput, CardContentInner, RecordContextProvider, SimpleForm, SimpleFormIterator, useDataProvider, useNotify, useTranslate } from "react-admin"
import { useForm, useFormState } from 'react-final-form';
import { Button, CardActions, LinearProgress, Typography, withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { isEmpty } from '@hisports/parsers';

import { TransitionProps } from '../../../common/ra/SimpleFormIterator';

import { useScheduleContext } from './WizardContext';
import { TeamImportButton } from '../../drafts/manual/TeamsStep';
import { TeamInput } from '../../teams/TeamInput';
import { ScheduleGroupInput } from '../../groups/GroupInput';

const styles = {
  indexContainer: {
    display: 'none',
  },
  line: {
    alignItems: 'top',
    border: 'none',
    padding: '0 8px 24px',
  },
  action: {
    paddingTop: '12px',
    paddingLeft: '20px'
  }
}

const FormIterator = withStyles(styles)(SimpleFormIterator);

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const getScheduleTeams = (dataProvider, scheduleId) => {
  if (!scheduleId) return Promise.resolve([]);
  return dataProvider.getList('scheduleteams', {
    filter: { scheduleId },
    sort: {
      field: ['group.name', 'team.name'],
      order: ['ASC', 'ASC']
    },
  }).then(res => res.data)
}

const validate = values => {
  const errors = {};
  errors.teams = values?.teams?.map(team => {
    const error = {};
    if (!team.teamId) error.teamId = 'ra.validation.required'
    return error;
  })
  return errors;
}

const TeamsInput = ({ schedule, ...props }) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const { change, mutators } = useForm();
  const { values } = useFormState();
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    getScheduleTeams(dataProvider, schedule.id)
      .then(teams => {
        change("teams", teams);
        setLoaded(true);
      })
  }, [dataProvider, schedule.id, change])

  let content = <LinearProgress color="primary" variant="indeterminate" />
  if (loaded) {
    const noTeams = isEmpty(values?.teams);
    content = <>
      {noTeams && <CardContentInner>
        <Typography variant="body2">{translate('resources.teams.messages.no_teams')}</Typography>
      </CardContentInner>}

      <ArrayInput source="teams" label="" {...inputProps}>
        <FormIterator disableReordering disableAdd TransitionProps={TransitionProps} {...inputProps}>
          <TeamInput source="teamId" filter={{ _scope: 'Tenant' }} label="resources.teams.name" ignoreSchedule {...inputProps} />
          <ScheduleGroupInput source="groupId" scheduleId={schedule.id} label=" " showNone={translate('ra.message.no_group')} select={false} {...inputProps} />
        </FormIterator>
      </ArrayInput>
    </>
  }

  return <>
    {content}
    <CardActions>
      <Button color="primary" startIcon={<Add />} onClick={() => mutators.push('teams', {})}>{translate('resources.drafts.labels.add_team')}</Button>
      <TeamImportButton label={translate('resources.drafts.labels.import_teams')} scheduleIdForGroupInput={schedule.id} />
    </CardActions>
  </>
}

export default ({ toolbar, handleNext }) => {
  const dataProvider = useDataProvider();
  const [ schedule ] = useScheduleContext();
  const notify = useNotify();

  const onSubmit = async values => {
    const teams = values.teams.filter(team => team.teamId != null)
    const scheduleTeams = await getScheduleTeams(dataProvider, schedule.id)

    const deletedTeams = scheduleTeams
      .filter(scheduleTeam => {
        return !teams.some(team => {
          return scheduleTeam.teamId === team.teamId && scheduleTeam.groupId === team.groupId
        })
      })

    const addedTeams = teams
      .filter(team => {
        return !scheduleTeams.some(scheduleTeam => {
          return scheduleTeam.teamId === team.teamId && scheduleTeam.groupId === team.groupId
        })
      })
      .map(team => ({
        teamId: team.teamId,
        scheduleId: schedule.id,
        officeId: schedule.officeId,
        groupId: team.groupId,
      }))

    try {
      await Promise.all([
        ...deletedTeams.map(team => dataProvider.delete("scheduleTeams", { id: team.id })),
        ...addedTeams.map(data => dataProvider.create("scheduleTeams", { data })),
      ])
      handleNext();
    } catch (error) {
      notify(error.message, 'error');
    }
  }

  return <RecordContextProvider value={schedule}>
    <SimpleForm toolbar={toolbar} validate={validate} save={onSubmit}>
      <TeamsInput schedule={schedule} />
    </SimpleForm>
  </RecordContextProvider>
}
