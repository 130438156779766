import React, { Fragment, useState } from 'react';
import { useRecordContext, useTranslate, SimpleForm, useNotify } from 'react-admin';
import { Grid, Dialog, DialogTitle, DialogActions as MuiDialogActions, Button, useMediaQuery, CircularProgress, styled } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useFormState } from 'react-final-form';
import arrayMutators from 'final-form-arrays'

import { apiClient, useFlag } from '../../../http';

import { VenueList } from './VenueList';
import { OfficeList } from './OfficeList';
import { useVenues } from './venues';
import { useOffices } from './offices';

import { FF_OFFICIAL_HIDE_ASSIGNERS } from "@hisports/common/featureFlags";

const DialogActions = styled(MuiDialogActions)({
  position: 'sticky',
  bottom: 0,
  background: 'white',
  zIndex: 10,
})

const Toolbar = ({ handleSubmit, onClose }) => {
  const translate = useTranslate();
  const { submitting } = useFormState();

  return <DialogActions>
    <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
    <Button type="submit" color="primary" onClick={handleSubmit} disabled={submitting}>
      {!submitting ? translate('ra.action.save') : translate('resources.participants.labels.availability.saving')}
    </Button>
  </DialogActions>
}

const AvailabilityForm = ({ participantId, onClose }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const isEnabled = useFlag();
  const { assigners, offices, loaded: officesLoaded, error: officesError } = useOffices()
  const { venues, loaded: venuesLoaded, error: venuesError } = useVenues()

  if (venuesError || officesError) {
    return <Alert severity="error">{translate('ra.page.error_try_again')}</Alert>
  }

  if (!officesLoaded || !venuesLoaded) {
    return <Alert severity="info" icon={<CircularProgress color="secondary" size={22} />}>{translate('ra.page.loading')}</Alert>
  }

  const handleSave = async values => {
    const { officeIds, venueIds } = values;

    await apiClient(`/participants/${participantId}/officialSettings`, {
      method: 'PATCH',
      data: {
        officeIds,
        venueIds,
      },
    })

    onClose();
    notify('Settings updated')
  }

  return <SimpleForm mutators={arrayMutators} component={Fragment} toolbar={<Toolbar onClose={onClose} />} fullWidth save={handleSave}>
    <div fullWidth> {/* eslint-disable-line react/no-unknown-property */}
      <Grid container>
        {!isEnabled(FF_OFFICIAL_HIDE_ASSIGNERS) && <Grid item xs={12} md={6}>
          <OfficeList participantId={participantId} assigners={assigners} offices={offices} />
        </Grid>}
        <Grid item xs={12} md={!isEnabled(FF_OFFICIAL_HIDE_ASSIGNERS) ? 6 : 12}>
          <VenueList participantId={participantId} venues={venues} />
        </Grid>
      </Grid>
    </div>
  </SimpleForm>
}

export default (props) => {
  const record = useRecordContext(props)
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isEnabled = useFlag();

  const [ open, setOpen ] = useState(false);
  const toggle = () => setOpen(open => !open);
  const onClose = () => setOpen(false);

  const participantId = record?.participantId || record?.id;
  if (!participantId) return null;
  return <>
    <Button startIcon={<Settings />} color="primary" size="small" onClick={toggle}>{translate('ra.message.settings')}</Button>
    <Dialog open={open} onClose={onClose} maxWidth={!isEnabled(FF_OFFICIAL_HIDE_ASSIGNERS) ? "md" : "sm"} fullWidth fullScreen={fullScreen} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>{translate('resources.participants.labels.availability.settings')}</DialogTitle>
      <AvailabilityForm participantId={participantId} onClose={onClose} />
    </Dialog>
  </>
}
