import React from 'react';
import { TextField, useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import TextArrayField from '../../../common/fields/TextArrayField';
import { isAuthorized } from '../../../common/Authorize';

import { TeamField } from '../../teams/TeamField';
import { MemberDatagrid } from '../../members/MemberModal';
import { MemberStatusField } from '../../members/MemberFields';

export default props => {
  const participant = useRecordContext(props);
  const seasonId = useSeason();

  const showMembers = isAuthorized(participant, 'members', 'list')
  if (!showMembers) return null;

  return <ListCard
    title="resources.teams.labels.card.title"
    reference="members"
    target="participants"
    filter={{ 'team.seasonId': seasonId }}
    sort={{
      field: ['team.category.order', 'team.name'],
      order: ['ASC', 'ASC'],
    }}
  >
    <MemberDatagrid size="medium">
      <TeamField source="teamId" includeCategory="inline" />
      <TextField source="number" />

      <TextArrayField source="positions" />
      <MemberStatusField label="resources.members.fields.status" />
    </MemberDatagrid>
  </ListCard>
}
