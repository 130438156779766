import React from 'react';

import FunctionField from "./FunctionField";

const getEnum = (category, source, resource, translate) => {
  const value = category[source];
  return translate(`resources.${resource}.values.${source}.${value}`, { _: value })
}

export const EnumField = props =>
  <FunctionField {...props} render={getEnum} />
