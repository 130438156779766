import React from 'react';
import { GET_LIST, useQueryWithStore } from "react-admin";

import { SurfaceInput } from "../surfaces/SurfaceInput";

const useArenaOffices = (officeId) => useQueryWithStore({
  type: GET_LIST,
  resource: 'arenaoffices',
  payload: {
    filter: { officeId: officeId }
  }
}, {
  enabled: !!officeId,
  action: 'CUSTOM_QUERY',
})

export const ArenaOfficeInput = ({ officeId, ...props }) => {
  const { data: surfaces = [] } = useArenaOffices(officeId);
  const parentSurfaceIds = surfaces.map(surface => surface.arenaId);

  return <SurfaceInput
    filter={parentSurfaceIds.length ? { effectiveSurfaces: parentSurfaceIds } : { "offices.id": officeId }}
    {...props}
  />
}
