import React from 'react';
import { styled } from '@material-ui/core';

import { useDragContext } from '../../../common/calendar/DragContext';

const Draggable = styled('div')({
  cursor: 'pointer',
  userSelect: 'none',
})

export default ({ children, event, draggable = true }) => {
  const { setDroppingEvent, dragging, setDragging } = useDragContext();
  return <Draggable
    draggable={draggable}
    unselectable="on"
    onDragStart={e => {
      if (!dragging) {
        setDragging(true);
      }

      setDroppingEvent(event);
      // this is a hack for Firefox
      // Firefox requires some kind of initialization
      // which we can do by adding this attribute
      // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
      return e.dataTransfer.setData("text/plain", "");
    }}
    onDragEnd={() => {
      if (dragging) {
        setDragging(false);
      }
    }}
  >
    {children}
  </Draggable>
}
