import React from 'react';
import { TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  text: {
    whiteSpace: 'pre-line',
  }
}));

const UserTextField = props => {
  const classes = useStyles();
  return <TextField className={classes.text} {...props} />
}

export default UserTextField;
