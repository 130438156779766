import { padStart } from '@hisports/common/src/lodash.js';
import {
  INITIAL_TIME,
  DEFAULT_SETTINGS,
  FIRST_PERIOD,
  SECOND_PERIOD,
  THIRD_PERIOD,
  OVERTIME_PERIOD,
  SHOOTOUT_PERIOD,
} from '../constants.js';

const sanitize = (n, defaultValue) => {
  const sanitized = parseInt(n, 10);
  if (isNaN(sanitized)) return defaultValue;
  return sanitized;
}

const sanitizeTime = time => ({
  period: time.period,
  minutes: sanitize(time.minutes, 20),
  seconds: sanitize(time.seconds, 0),
})

export const stringifyTime = input => {
  const value = parseInt(input, 10);
  if (isNaN(value)) return;
  if (value >= 10) return input.toString();
  return padStart(input, 2, '0');
};

export const stringifyGameTime = gameTime => {
  if (!gameTime || (!gameTime.minutes && !gameTime.seconds)) return;

  const minutes = !isNaN(gameTime.minutes) ? gameTime.minutes : 0;
  const seconds = !isNaN(gameTime.seconds) ? gameTime.seconds : 0;

  return `${stringifyTime(minutes || 0)}:${stringifyTime(seconds || 0)}`;
}

export const isRegulationPeriod = (period, includeOvertime = false) => {
  if (includeOvertime && period === OVERTIME_PERIOD) return true;
  return period == FIRST_PERIOD || period == SECOND_PERIOD || period == THIRD_PERIOD;
}

export const isInRegulationGameTime = (gameTime, includeOvertime = false) => {
  return isRegulationPeriod(gameTime.period, includeOvertime)
}

export const displayPeriod = gameTime => {
  if (!gameTime) return '';

  switch (gameTime.period) {
    case 1:
    case FIRST_PERIOD: return '1st period';
    case 2:
    case SECOND_PERIOD: return '2nd period';
    case 3:
    case THIRD_PERIOD: return '3rd period';
    case OVERTIME_PERIOD: return 'Overtime';
    case SHOOTOUT_PERIOD: return 'Shootouts';
    default: return '';
  }
}

export const displayInning = gameTime => {
  if (!gameTime) return '';

  switch (gameTime.period) {
    case 1:
    case '1': return '1st';
    case 2:
    case '2': return '2nd';
    case 3:
    case '3': return '3rd';
    default: return `${gameTime.period}th`;
  }
}

export const displayTime = (gameTime, sport, includePeriod = false, hide = false) => {
  if (sport === 'Hockey') {
    if (!gameTime || hide) return '--:--';

    const minutes = stringifyTime(gameTime.minutes) || '--';
    const seconds = stringifyTime(gameTime.seconds) || '--';

    const time = `${minutes}:${seconds}`;
    return includePeriod ? `${time} in ${displayPeriod(gameTime)}` : time;
  }

  if (sport === 'Soccer') {
    if (!gameTime || hide) return "--'";

    const minutes = parseInt(gameTime.minutes, 10);
    if (isNaN(minutes)) return "--'";

    let time = `${minutes}`
    const extra = parseInt(gameTime.extra, 10)

    if (extra) {
      time += `+${extra}`
    }

    return `${time}'`
  }

  if (sport === 'Baseball') {
    if (!gameTime || hide) return "--'";

    const period = parseInt(gameTime.period, 10);
    if (isNaN(period)) return "--'";

    let time = `${displayInning(gameTime)}`

    if (gameTime.half) {
      time = `${gameTime.half === 1 ? 'Top' : 'Bottom'} of ${time}`
    }

    return time
  }

  return '--'
};

export const compareTime = (a, b, sport) => {
  b = typeof b !== 'undefined' ? b : INITIAL_TIME[sport];
  // this conforms to Array.sort's compareFunction spec
  // < 0: a is earlier, 0: same, > 0: b is earlier
  // "a earlier than b" means "a in past of b"
  // -1: a in past, 0: same, +1: a in future

  if (a && !b) return -4;
  if (!a && b) return 4;
  if (!a && !b) return 0;

  // 1 - 2 - 3 - OT - SO
  if (sport === 'Hockey') {
    if (a.period === OVERTIME_PERIOD && b.period === SHOOTOUT_PERIOD) return -3; // OT && SO; a is past
    if (a.period === SHOOTOUT_PERIOD && b.period === OVERTIME_PERIOD) return 3; // SO && OT; b is past
    if (isFinite(a.period) && !isFinite(b.period)) return -3; // 1 && OT; a is past
    if (!isFinite(a.period) && isFinite(b.period)) return 3; // OT && 1; b is past
    if (isFinite(a.period) && isFinite(b.period)) { // 1 && 3
      if (a.period < b.period) return -3; // 1 < 3; a is past
      if (a.period > b.period) return 3; // 3 > 1; b is past
    }

    if (a.minutes < b.minutes) return 2; // 20:-- < 10:--; a is past
    if (a.minutes > b.minutes) return -2; // 10:-- > 20:--; b is past

    if (a.seconds < b.seconds) return 1; // --:30 < --:45; b is past
    if (a.seconds > b.seconds) return -1; // --:45 > --:30; a is past
  }

  if (sport === 'Soccer') {
    if (a.minutes < b.minutes) return -2; // 45' < 90'; a is past
    if (a.minutes > b.minutes) return 2; // 90' > 45'; b is past

    if (a.extra < b.extra) return -1; // 45' < 45+3'; a is past
    if (a.extra > b.extra) return 1; // 45+3' > 45'; b is past
  }

  if (sport === 'Baseball') {
    if (a.period < b.period) return -2; // Inning 1 < Inning 2; a is past
    if (a.period > b.period) return 2; // Inning 2 > Inning 1; b is past

    if (a.half < b.half) return -1; // Top of inning 1 < Bottom of inning 1; a is past
    if (a.half > b.half) return 1; // Bottom of inning 1 > Top of inning 1; b is past
  }

  return 0; // equal
}

// is b before a
export const isTimeBefore = (a, b, sport, includeEqual = false) => {
  const result = compareTime(a, b, sport);
  if (includeEqual) return result >= 0;
  return result > 0;
}

// is b after a
export const isTimeAfter = (a, b, sport, includeEqual = false) => {
  const result = compareTime(a, b, sport);
  if (includeEqual) return result <= 0;
  return result < 0;
}

export const isTimeEqual = (a, b, sport) => compareTime(a, b, sport) === 0;

export const isTimeInRange = (time, start, end, sport, { includeStart = true, includeEnd = true } = {}) => {
  if (isTimeBefore(start, time, sport, !includeStart)) return false;
  if (isTimeAfter(end, time, sport, !includeEnd)) return false;
  return true;
}

// for use with angular's orderBy filter
export const orderTime = (a, b, sport) =>
  compareTime(a.value || null, b.value || null, sport); // null to avoid default value

export const isTimeValid = (gameTime, periods = DEFAULT_SETTINGS.periods, sport) => {
  const { period, seconds } = gameTime;
  if (seconds >= 60) return false; // always 60 seconds in a minute...
  if (period === SHOOTOUT_PERIOD) return true; // SO doesn't have time, so times are arbitrary
  if (period === OVERTIME_PERIOD) return isTimeAfter(periods[3], gameTime, sport, true);
  const length = periods[parseInt(period) - 1];
  return isTimeAfter(length, gameTime, sport, true); // 1-3 <= 20:00 (or configured time, such as 15:00)
}

const getLength = duration => {
  return duration.duration || duration.length || duration || 0;
}

export const calculateGameTime = (gameTime, duration, periodSettings = DEFAULT_SETTINGS.periods) => {
  const startTime = sanitizeTime(gameTime);
  if (!duration) return startTime;

  let length = getLength(duration);
  if (!length) return startTime;

  const periods = periodSettings.map(sanitizeTime);
  let _periods = periods.map(period => period.period);

  const isRegulation = isInRegulationGameTime(startTime);
  const subtract = length < 0;

  length = Math.abs(length)
  if (subtract) {
    _periods = _periods.reverse();
  }

  const startPeriodIndex = _periods.findIndex(period => startTime.period == period);

  return _periods.reduce((newTime, period, index) => {
    if (!length) return newTime; // no remaining time to calculate
    if (startPeriodIndex > index) return newTime; // period is before start
    if (period === OVERTIME_PERIOD && isRegulation) return newTime;
    if (period !== OVERTIME_PERIOD && !isRegulation) return newTime;

    const periodTime = !subtract ? periods[index] : periods[periods.length - index - 1];

    let remainingPeriodLength;
    if (newTime.period == period) {
      if (!subtract) {
        remainingPeriodLength = (newTime.minutes * 60) + newTime.seconds;
      } else {
        remainingPeriodLength = ((periodTime.minutes - newTime.minutes - 1) * 60) + (60 - periodTime.seconds + newTime.seconds)
      }
    } else {
      remainingPeriodLength = (periodTime.minutes * 60) + newTime.seconds;
    }

    const remainder = remainingPeriodLength - length
    const remainderMin = Math.floor(remainder / 60);
    const remainderSec = remainder % 60;
    if (subtract) {
      if (remainder >= 0) { // last period
        length = 0;
        const offsetMin = remainderSec > 0 ? 1 : 0;
        const offsetSec = remainderSec > 0 ? 60 : 0;
        return {
          ...newTime,
          period,
          minutes: periodTime.minutes - remainderMin - offsetMin,
          seconds: offsetSec - periodTime.seconds - remainderSec
        }
      }

      length = length - remainingPeriodLength;
      return { ...newTime, period, minutes: periodTime.minutes, seconds: periodTime.seconds }
    }

    if (remainder >= 0) { // last period
      length = 0;
      return { ...newTime, period, minutes: remainderMin, seconds: remainderSec }
    }

    length = length - remainingPeriodLength;
    return { ...newTime, period, minutes: 0, seconds: 0 }
  }, { ...startTime })
}

export const calculateEndTime = (gameTime, duration, periods = DEFAULT_SETTINGS.periods) => {
  if (!duration) return null;

  const length = getLength(duration);
  if (!length) return null;

  return calculateGameTime(gameTime, duration, periods);
}

export const parsePeriodInt = period => {
  if (period === OVERTIME_PERIOD || period === SHOOTOUT_PERIOD) return 4;
  return parseInt(period);
}

export const calculateDuration = (startTime, endTime, periodAttributes, sport) => {
  if (!startTime || !endTime || !periodAttributes) return 0;

  const reversed = isTimeBefore(startTime, endTime, sport)
  const [ start, end ] = !reversed
    ? [ sanitizeTime(startTime), sanitizeTime(endTime) ]
    : [ sanitizeTime(endTime), sanitizeTime(startTime) ];

  if (start.period === SHOOTOUT_PERIOD) return 0;

  // parse period strings to ints
  const startPeriod = parsePeriodInt(start.period)
  const endPeriod = parsePeriodInt(end.period)

  if (startPeriod === endPeriod) { // same period
    // start time - end time
    const duration = (start.minutes - end.minutes) + ((60 - end.seconds) / 60) - ((60 - start.seconds) / 60);
    return !reversed ? duration : (duration * -1);
  }

  const totalPeriods = endPeriod - startPeriod + 1;
  const periods = Array.from(Array(totalPeriods).keys()).map(n => n + startPeriod);
  const duration = periods.reduce((sum, period) => {
    const length = periodAttributes[period - 1];
    if (period === startPeriod) { // start period
      // time to end of period
      sum += start.minutes + (start.seconds / 60);
    } else if (period < endPeriod) { // in-between periods
      // full period
      sum += length.minutes + ((60 - length.seconds) / 60) - 1;
    } else if (period === endPeriod) { // end period
      // time from start of period to end time
      sum += (length.minutes - end.minutes) + ((60 - end.seconds) / 60) - ((60 - length.seconds) / 60);
    }
    return sum;
  }, 0);

  return !reversed ? duration : (duration * -1);
}

