
import React from 'react'
import { Table, TableBody, makeStyles } from "@material-ui/core";
import { TextField, useRecordContext } from "react-admin";

import { UpdateGameAlert } from "../resources/draftgames/DraftGameForm";
import { TeamField } from '../resources/teams/TeamField';
import { GroupField } from '../resources/groups/GroupField';
import { ScheduleField } from '../resources/schedules/ScheduleField';
import { SurfaceField } from '../resources/surfaces/SurfaceField';
import Row from './cards/TableRow';
import DifferenceField from './fields/DifferenceField';
import DateField from './fields/DateField';
import TimeRangeField from './fields/TimeRangeField';

const useStyles = makeStyles(theme => ({
  time: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alert: {
    marginBottom: theme.spacing(2),
  }
}))

export const EventChanges = ({ previousEvent, hideAlert = false, indicateChangeRemoval = false, alertLabel }) => {
  const classes = useStyles();
  const event = useRecordContext();
  const showGameAlert = event?.updatedGameId && !hideAlert;

  const hideRow = property => event?.[property] == null && previousEvent?.[property] == null;

  const handleTimeHasChanged = (previousEvent, currentEvent) => {
    const startTimeChanged = previousEvent?.startTime !== currentEvent?.startTime;
    const endTimeChanged = previousEvent?.endTime !== currentEvent?.endTime;
    return startTimeChanged || endTimeChanged
  };

  return <>
    {showGameAlert && <div className={classes.alert}>
      <UpdateGameAlert updatedGameId={event?.updatedGameId} alertLabel={alertLabel} />
    </div>}
    <Table>
      <TableBody>
        {!hideRow('date') && <Row label="ra.date.name">
          <DifferenceField previousEvent={previousEvent} property="date" indicateChangeRemoval={indicateChangeRemoval}>
            <DateField source="date" format="dddd, LL" />
          </DifferenceField>
        </Row>}
        {!hideRow('startTime') && <Row label="ra.date.time">
          <div className={classes.time}>
            <span>
              <DifferenceField previousEvent={previousEvent} property="startTime" hasChanged={handleTimeHasChanged} indicateChangeRemoval={indicateChangeRemoval}>
                <TimeRangeField startSource="startTime" endSource="endTime" />
              </DifferenceField>
            </span>
          </div>
        </Row>}
        {!hideRow('homeTeamId') && <Row label="resources.games.fields.homeTeamId">
          <DifferenceField previousEvent={previousEvent} property="homeTeamId">
            <TeamField source="homeTeamId" />
          </DifferenceField>
        </Row>}
        {!hideRow('awayTeamId') && <Row label="resources.games.fields.awayTeamId">
          <DifferenceField previousEvent={previousEvent} property="awayTeamId">
            <TeamField source="awayTeamId" />
          </DifferenceField>
        </Row>}
        {!hideRow('groupId') && <Row label="resources.games.fields.groupId">
          <DifferenceField previousEvent={previousEvent} property="groupId">
            <GroupField source="groupId" />
          </DifferenceField>
        </Row>}
        {!hideRow('crossScheduleId') && <Row label="resources.games.fields.crossScheduleId">
          <DifferenceField previousEvent={previousEvent} property="groupId">
            <ScheduleField source="crossScheduleId" />
          </DifferenceField>
        </Row>}
        {!hideRow('crossGroupId') && <Row label="resources.games.fields.crossGroupId">
          <DifferenceField previousEvent={previousEvent} property="groupId">
            <GroupField source="crossGroupId" />
          </DifferenceField>
        </Row>}
        {
          previousEvent?.location ?
            !hideRow('location') && <Row label="resources.activities.fields.location">
              <TextField source="location" />
            </Row>
            :
            (!hideRow('arenaId') || event?.startTime) && <Row label="resources.surfaces.name">
              <DifferenceField previousEvent={previousEvent} property="arenaId" indicateChangeRemoval={indicateChangeRemoval}>
                <SurfaceField source="arenaId" />
              </DifferenceField>
            </Row>
        }
        {!hideRow('comments') && <Row label="resources.games.fields.comments">
          <DifferenceField previousEvent={previousEvent} property="comments">
            <TextField source="comments" />
          </DifferenceField>
        </Row>}
        {!hideRow('status') && <Row label="resources.activities.fields.status" hidden={!event?.status}>
          <TextField source="status" />
        </Row>}
      </TableBody>
    </Table>
  </>
}
