import React from 'react';
import { useTranslate } from 'react-admin';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { SurfaceView } from './SurfaceView';
import { SurfaceFilters } from './SurfaceFilters';
import { SurfaceShowLayout } from './SurfaceShow';
import { SurfaceForm } from './SurfaceForm';

import { getSurfaceName } from './util';

export const SurfaceList = props =>
  <List {...props}
    component="div"
    filters={<SurfaceFilters />}
    filterDefaultValues={{ _scope: 'Tenant' }}
    title="Surfaces"
    sort={{
      field: ['venue.country', 'venue.region', 'venue.city', 'venue.name', 'name'],
      order: ['ASC', 'ASC', 'ASC', 'ASC', 'ASC'],
    }}
  >
    <SurfaceView rowClick="show" />
  </List>

export const SurfaceShow = props =>
  <Show {...props}
    title={<ResourceTitle title={surface => getSurfaceName(surface)} />}
  >
    <SurfaceShowLayout />
  </Show>

export const SurfaceCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={record => translate('resources.surfaces.labels.add')} />}
    {...props}
  >
    <SurfaceForm redirect="show" />
  </Create>
}

export const SurfaceEdit = props => {
  const translate = useTranslate()
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: getSurfaceName(record) })} />}
    {...props}
  >
    <SurfaceForm redirect="show" />
  </Edit>
}
