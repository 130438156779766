import { GET_LIST, useQuery } from "react-admin";

export const useScheduleSeeds = scheduleId => {
  const { data, loading, error, refetch } = useQuery({
    type: GET_LIST,
    resource: 'gameBrackets',
    payload: {
      filter: { 'game.scheduleId': scheduleId },
    } }, {
    enabled: scheduleId != null,
  })

  if (data?.[0]) return true

  return false
}

export const useSeeds = gameId => {
  const { data, loading, error, refetch } = useQuery({
    type: GET_LIST,
    resource: 'gameBrackets',
    payload: {
      filter: { gameId },
    } }, {
    enabled: gameId != null,
  })

  return { data: data?.[0], loading, error, refetch }
}
