import React, { Children, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { RecordContextProvider, TextField, useDataProvider, useGetOne, useListContext, useNotify, useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { Form, useForm, useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Table, TableBody } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment-timezone';
import { isEqual } from 'lodash';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { createRange } from '@hisports/common';

import { CALENDAR_VIEWS, useShowCondensedView } from '../../resources/events/EventViewSettings';
import { SurfaceField } from '../../resources/surfaces/SurfaceField';
import { GameAvailabilityContextProvider } from '../../resources/games/GameAvailabilityContext';
import { GameAvailabilityInfo, useAvailabilities as useGameAvailabilities } from '../../resources/games/GameAvailabilityInfo';
import { hasConflicts, validateStatus } from '../../resources/games/GameForm';
import { PracticeAvailabilityInfo, useAvailabilities as usePracticeAvailabilities } from '../../resources/practices/PracticeAvailabilityInfo';
import { PracticeAvailabilityContextProvider } from '../../resources/practices/PracticeAvailabilityContext';
import { UpdateGameAlert } from '../../resources/draftgames/DraftGameForm';

import Row from '../cards/TableRow';
import { isAuthorized } from '../Authorize';
import TimeRangeField from '../fields/TimeRangeField';
import DateField from '../fields/DateField';
import DifferenceField from '../fields/DifferenceField';
import { GameStatusEnumInput } from '../inputs/EnumInputs';

import { useCalendarContext } from './CalendarContext';
import { useDragContext } from './DragContext';
import { useSchedulingContext } from './SchedulingContext';
import { isDraft, isGame, isPractice } from './EventDetails';
import { SurfaceInput } from "../../resources/surfaces/SurfaceInput";
import { useLimitDateChange } from '../../resources/events/EventGroupedGrid';

export const SNAP_MINUTES = 15;

const useStyles = makeStyles(theme => ({
  availabilityInfo: {
    padding: theme.spacing(2, 0),
  },
  time: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  increment: {
    marginLeft: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(1),
  }
}))

const getRowHeight = (view, cellSize) => {
  if ([CALENDAR_VIEWS.SEASON, CALENDAR_VIEWS.MONTH].includes(view)) return cellSize.height;
  return (cellSize.height / 60) * SNAP_MINUTES
}

const getMaxRows = (view, rows, offsetHours) => {
  if (view === CALENDAR_VIEWS.SEASON) return 1;
  if (view === CALENDAR_VIEWS.MONTH) return rows?.length;
  return (24 - offsetHours) * (60 / SNAP_MINUTES) // total rows in a calendar day
}

const getGridWidth = (view, cellSize, columns) => {
  if (view === CALENDAR_VIEWS.SEASON) return cellSize.width;
  return cellSize.width * columns.length;
}

const getCols = (view, columns) => {
  if (view === CALENDAR_VIEWS.SEASON) return 1;
  return columns.length
}

const getGridDimensions = (view, cellSize, rows, columns, offsetHours) => ({
  rowHeight: getRowHeight(view, cellSize),
  maxRows: getMaxRows(view, rows, offsetHours),
  gridWidth: getGridWidth(view, cellSize, columns),
  cols: getCols(view, columns)
})

const gridStyles = (top, left, width, height, dragging) => ({
  position: 'absolute',
  top,
  left,
  width,
  height,
  zIndex: !dragging ? null : 2147483647 // max
})

const getDndItem = (layout, event, offsetHours) => {
  if (!layout || !event) return;
  const { duration, colIndex, hours, minutes, offset } = layout;
  let itemId = `${event?.resource}-${event.id}`;
  if (event?.positionArenaId) {
    itemId += `-${event.positionArenaId}`;
  }

  const isEditable = event?.isEditable !== false;

  return {
    // these are styles that overrides the default GridLayout's child styling
    styleOverrides: {
      left: offset,
      zIndexProps: { event, hours, minutes }, // we send zindexProps because we dont know the state of the element (default, hover, dragged)
    },
    key: itemId,
    grid: {
      i: itemId,
      x: colIndex,
      y: ((hours - offsetHours) * (60 / SNAP_MINUTES)) + Math.floor(minutes / SNAP_MINUTES),
      w: 1,
      h: duration / SNAP_MINUTES,
      isDraggable: isEditable && !event.multiday,
    }
  }
}

const sanitizeItemId = (itemId) => {
  // small bug in react-grid-layout that appends "/.0" to the item id
  if (!itemId) return;
  if (!itemId.includes('/')) return itemId;
  return itemId.substring(0, itemId.indexOf('/'))
}

export const getEventId = item => {
  if (!item?.i) return;
  const sanitizedId = sanitizeItemId(item?.i);
  const id = sanitizedId.substring((item.i.indexOf('-') + 1));
  return isNaN(id) ? id : Number(id)
}

export const getEventResource = item => {
  if (!item?.i) return;
  const sanitizedId = sanitizeItemId(item?.i);
  return sanitizedId.substring(0, sanitizedId.indexOf('-'))
}

export const validateEvent = (event, schedule, translate) => {
  let error = false;

  const { startDate, endDate } = schedule || {};
  if ((isGame(event) || isDraft(event)) && event.date && startDate && moment.utc(startDate).isAfter(event.date)) {
    error = translate('resources.schedules.notifications.before_schedule_date', { startDate: moment.utc(startDate).format('MMM D') })
  } else if (event.date && endDate && moment.utc(endDate).add(1, 'day').isBefore(event.date)) {
    error = translate('resources.schedules.notifications.after_schedule_date', { endDate: moment.utc(endDate).format('MMM D') })
  }

  return error
}

const validate = values => {
  const errors = {};

  if (!values.status) errors.status = 'ra.validation.required';
  if (values.startTime && !values.arenaId) errors.arenaId = 'ra.validation.required';

  return errors;
}

const AvailabilityInfo = props => {
  const { draftId } = useSchedulingContext();
  const record = useRecordContext()
  const classes = useStyles();

  return <Grid container fullWidth className={classes.availabilityInfo}>
    {isPractice(record) ? <PracticeAvailabilityInfo /> : <GameAvailabilityInfo draftId={draftId} />}
  </Grid>
}

const TimeIncrementInput = props => {
  const classes = useStyles();
  const form = useForm();

  const increment = (amount, unitOfTime = 'minutes') => {
    const { values = {} } = form.getState()
    const { startTime, endTime, timezone } = values

    if (!timezone || !amount) return;

    form.batch(() => {
      if (startTime) {
        const newStartTime = moment.tz(startTime, timezone).add(amount, unitOfTime)
        if (newStartTime.isValid) {
          form.change('startTime', newStartTime.toISOString());
        }
      }
      if (endTime) {
        const newEndTime = moment.tz(endTime, timezone).add(amount, unitOfTime)
        if (newEndTime.isValid) {
          form.change('endTime', newEndTime.toISOString());
        }
      }
    })
  }

  return <ButtonGroup size="small" color="primary" className={classes.increment}>
    <Button onClick={() => increment(-5)}>-5</Button>
    <Button onClick={() => increment(5)}>+5</Button>
  </ButtonGroup>
}

const GameStatusInput = props => {
  const { blur, change } = useForm()
  const { values: record } = useFormState();
  const availabilities = useGameAvailabilities(record);
  const { deferDraftConflict } = useSchedulingContext();

  const conflicted = hasConflicts(availabilities, record.status, ['Conflict', 'Validation'])
  useEffect(() => {
    blur('status')
  }, [ conflicted, record.status, blur, change, availabilities ])

  const helperText = conflicted && !deferDraftConflict ? 'resources.games.helpers.conflicts' : '';
  return <GameStatusEnumInput validate={!deferDraftConflict && validateStatus(availabilities, ['Validation'])} helperText={helperText} {...props} />
}

const PracticeStatusInput = props => {
  const { blur, change } = useForm()
  const { values: practice } = useFormState();
  const availabilities = usePracticeAvailabilities(practice);

  const conflicted = hasConflicts(availabilities, practice.status, ['Conflict']);

  useEffect(() => {
    blur('status')
  }, [ conflicted, practice.status, blur, change, availabilities ])

  const helperText = conflicted ? 'resources.practices.helpers.conflicts' : '';
  return <GameStatusEnumInput validate={validateStatus(availabilities)} helperText={helperText} {...props} />
}

const StatusInput = props => {
  const { values: record } = useFormState();

  if (isPractice(record)) {
    return <PracticeStatusInput {...props} />
  }

  return <GameStatusInput {...props} />
}

const RecordForm = ({ children, ...props }) =>
  <Form {...props}>
    {props => <form onSubmit={props.handleSubmit}>
      <RecordContextProvider value={props.values}>
        {children}
      </RecordContextProvider>
    </form>}
  </Form>

export const EventChangesForm = ({ previousEvent, handleCancel, handleConfirm, canEditEvent, ...props }) => {
  const record = useRecordContext();
  const { availability } = useSchedulingContext();
  const { type } = useCalendarContext();


  // to expand to a timeslot when a game is dropped on top
  const overlappingTimeSlot = useMemo(() => {
    if (type !== CALENDAR_VIEWS.DAY) return;
    return (availability?.slots || []).find(slot => {
      if (slot.arenaId !== record.arenaId) return false;

      const recordRange = createRange(moment.tz(record.startTime, record.timezone), moment.tz(record.endTime, record.timezone));
      const slotRange = createRange(moment.tz(slot.startTime, slot.timezone), moment.tz(slot.endTime, slot.timezone));
      return slotRange.overlaps(recordRange)
    });
  }, [availability?.slots, record.arenaId, record.endTime, record.startTime, record.timezone, type])

  const updatedRecord = {
    ...record,
    startTime: overlappingTimeSlot?.startTime || record.startTime,
    endTime: overlappingTimeSlot?.endTime || record.endTime,
    arenaId: overlappingTimeSlot?.arenaId || record.arenaId,
  }

  const decorators = useRef([createCalculator({
    field: 'startTime',
    updates: {
      date: (startTime, values) => {
        if (!startTime || !values.timezone) return values?.date;
        return moment.tz(startTime, values.timezone).format('YYYY-MM-DD');
      }
    }
  }, {
    field: 'arenaId',
    updates: {
      timezone: (arenaId, values, prevValues) => {
        const surface = availability?.surfaces?.find(surface => surface.id === arenaId);
        if (['TBA', 'NDA'].includes(surface?.alias)) {
          return values?.timezone || prevValues?.timezone;
        }
        return surface?.timezone;
      }
    }
  })])

  return <RecordForm
    onSubmit={handleConfirm}
    validate={validate}
    validateOnBlur
    initialValues={updatedRecord}
    decorators={decorators.current}
  >
    <EventChangesFormBody previousEvent={previousEvent} handleCancel={handleCancel} overlappingTimeSlot={overlappingTimeSlot} canEditEvent={canEditEvent} />
  </RecordForm>
}

const EventChangesFormBody = ({ previousEvent, handleCancel, overlappingTimeSlot, canEditEvent, ...props }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const translate = useTranslate();
  const { type } = useCalendarContext();

  const handleTimeHasChanged = (previousEvent, currentEvent) => {
    const startTimeChanged = previousEvent?.startTime !== currentEvent?.startTime;
    const endTimeChanged = previousEvent?.endTime !== currentEvent?.endTime;
    return startTimeChanged || endTimeChanged
  };

  const hideRow = property => record?.[property] == null && previousEvent?.[property] == null;

  const canShowTimeIncrement = [CALENDAR_VIEWS.DAY, CALENDAR_VIEWS.WEEK].includes(type) && !overlappingTimeSlot && (!!record?.startTime || !!record?.endTime);

  return <>
    {record?.updatedGameId && <div className={classes.alert}>
      <UpdateGameAlert updatedGameId={record?.updatedGameId} />
    </div>}
    <Table>
      <TableBody>
        {!hideRow('date') && <Row label="ra.date.name">
          <DifferenceField previousEvent={previousEvent} property="date">
            <DateField source="date" format="dddd, LL" />
          </DifferenceField>
        </Row>}
        {!hideRow('startTime') && <Row label="ra.date.time">
          <div className={classes.time}>
            <span>
              <DifferenceField previousEvent={previousEvent} property="startTime" hasChanged={handleTimeHasChanged}>
                <TimeRangeField startSource="startTime" endSource="endTime" />
              </DifferenceField>
            </span>
            {canShowTimeIncrement && <TimeIncrementInput />}
          </div>
        </Row>}
        {
          previousEvent?.location ?
            !hideRow('location') && <Row label="resources.activities.fields.location">
              <TextField source="location" />
            </Row>
            :
            (!hideRow('arenaId') || record?.startTime) && <Row label="resources.surfaces.name">
              {record?.arenaId
                ? <DifferenceField previousEvent={previousEvent} property="arenaId">
                  <SurfaceField source="arenaId" />
                </DifferenceField>
                : record?.startTime ? <SurfaceInput source="arenaId" variant="outlined" fullWidth /> : null
              }
            </Row>
        }
        {!hideRow('status') && <Row label="resources.activities.fields.status" hidden={!record?.status}>
          <StatusInput source="status" defaultValue="Active" variant="outlined" fullWidth />
        </Row>}
      </TableBody>
    </Table>
    <AvailabilityInfo />
    {canEditEvent && <DialogActions>
      <Button onClick={handleCancel} >{translate('ra.action.cancel')}</Button>
      <Button type="submit" color="primary" >{translate('ra.action.confirm')}</Button>
    </DialogActions>}
  </>
}

const ConfirmDialog = ({ open = false, handleCancel, handleConfirm, previousEvent }) => {
  const { disableAuth } = useCalendarContext();
  const { resource: schedulingResource } = useSchedulingContext();
  const { draggingEvent } = useDragContext();
  const translate = useTranslate();
  const resource = useResourceContext();
  const disableDateChange = useLimitDateChange(draggingEvent);

  const eventResource = draggingEvent?.resource || schedulingResource || resource;
  const { data: event, loaded } = useGetOne(eventResource, draggingEvent?.id, {
    enabled: !disableAuth && open,
  });

  const hasDateChanged = previousEvent?.date !== draggingEvent?.date;
  const cantChangeDate = disableDateChange && hasDateChanged;

  if (!loaded && !disableAuth) return null;

  const canEditEvent = event?.id ? !cantChangeDate && isAuthorized(event, eventResource, 'edit') : !cantChangeDate;

  const AvailabilityContextProvider = isPractice(event) ? PracticeAvailabilityContextProvider : GameAvailabilityContextProvider;

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.games.labels.confirm_changes')}</DialogTitle>
    <DialogContent>
      {!canEditEvent && <Alert severity="warning">
        {translate(`resources.games.alerts.${cantChangeDate ? 'no_change_date_permission' : 'no_edit_permission'}`)}
      </Alert>}
      <RecordContextProvider value={draggingEvent}>
        <AvailabilityContextProvider>
          <EventChangesForm previousEvent={previousEvent} handleCancel={handleCancel} handleConfirm={handleConfirm} canEditEvent={canEditEvent} />
        </AvailabilityContextProvider>
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

export const SeasonDndGrid = ({ cellSize, offset, rowHeights, ...props }) => {
  const { rows, allrows } = useCalendarContext()
  const [ showCondensedView ] = useShowCondensedView();

  const selectedRows = showCondensedView ? rows : allrows;

  // dynamically set cellSize/offset to  create a dnd grid per row
  const sizes = useMemo(() => {
    if (!selectedRows?.length) return;

    let totalHeight = 0;

    return selectedRows.map((row, i) => {
      const rowHeight = rowHeights?.[i];

      if (i === 0) {
        // set initial offset from header
        totalHeight += offset.height
      }

      const size = {
        offsetHeight: totalHeight,
        cellHeight: rowHeight,
      }
      totalHeight += rowHeight;

      return size;
    })
  }, [offset.height, rowHeights, selectedRows])

  if (!selectedRows?.length || !sizes) return null;
  return <>
    {selectedRows.map((row, i) => {
      const size = sizes?.[i];
      return <DragAndDropGrid
        key={row}
        gridDateIndex={i}
        cellSize={{ ...cellSize, height: size?.cellHeight }}
        offset={{ ...offset, height: size?.offsetHeight }}
        {...props} />
    })}
  </>
}

export const DragAndDropGrid = ({ children, cellSize, offset, offsetHours, gridDateIndex }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const { data: events } = useListContext();
  const { gameLength, schedule, resource: schedulingResource, setSelectedGame, onSave, disableScheduling, setRefreshAvailability } = useSchedulingContext();
  const { dragging, setDragging, setDraggingEvent, droppingEvent } = useDragContext();
  const { timezone, columns, toEventDetails, type, rows } = useCalendarContext();
  const disableDateChange = useLimitDateChange(droppingEvent);
  const dataProvider = useDataProvider();
  const [ dragPosition, setDragPosition ] = useState({ x: 0, y: 0 });
  const [ initialPosition, setInitialPosition ] = useState({ x: 0, y: 0 });
  const [ showConfirm, setShowConfirm ] = useState(false);
  // Keep a current state of the layout
  const [ gridLayout, setGridLayout ] = useState();
  // Keep the previous layout for undo
  const [ previousLayout, setPreviousLayout ] = useState([]);
  const [ undo, setUndo ] = useState(false);
  const [ previousEvent, setPreviousEvent ] = useState({});
  // To trigger a manual rerender of the grid, 'data-grid' AND 'layout' must be updated simultaneously with matching keys
  // Use the 'setUpdateLayout' with your item changes to update 'layout'
  // https://github.com/react-grid-layout/react-grid-layout/issues/382
  const [ updateLayout, setUpdateLayout ] = useState([])

  const resource = useResourceContext();
  const isDroppable = type === CALENDAR_VIEWS.MONTH ? !disableDateChange : true; // disable drop in month view if no permissions

  const { rowHeight, maxRows, gridWidth, cols } = getGridDimensions(type, cellSize, rows, columns, offsetHours)

  const gridStyle = useMemo(() => {
    return gridStyles(offset?.height, offset?.width, gridWidth, rowHeight * maxRows, dragging);
  }, [ dragging, gridWidth, maxRows, offset, rowHeight ])

  useEffect(() => {
    // Update 'layout' when children change (data-grid items)
    if (!gridLayout || showConfirm || type === CALENDAR_VIEWS.MONTH) return;

    const oldGridLayout = JSON.parse(JSON.stringify(gridLayout));
    const newGridLayout = JSON.parse(JSON.stringify(gridLayout));
    Children.map(children, (child, index) => {
      const childItem = getDndItem(child, offsetHours, events);
      if (!childItem) return;
      const layoutIndex = newGridLayout.findIndex(item => item.i === childItem.key);
      if (layoutIndex === -1) return;

      newGridLayout[layoutIndex] = { ...newGridLayout[layoutIndex], ...childItem.grid };
    });

    if ((!isEqual(newGridLayout, oldGridLayout))) {
      setUpdateLayout(newGridLayout)
    }
  }, [ children, gridLayout, offsetHours, showConfirm, events, type ])

  useEffect(() => {
    // Once the manual rerender is done, clear the layout
    if (updateLayout.length) {
      setUpdateLayout([]);
    }
  }, [ updateLayout ])

  useEffect(() => {
    if (undo) {
      setUndo(false);
    }
  }, [ undo ])

  const onLayoutChange = (layout) => {
    layout = layout.map(item => ({ ...item, i: sanitizeItemId(item.i) }));
    setGridLayout(layout);
  }

  const onDragStart = (layout, oldItem, newItem) => {
    setInitialPosition({ x: oldItem.x, y: oldItem.y });

    const eventId = getEventId(oldItem);
    const oldEvent = events?.[eventId] || droppingEvent || {};
    setPreviousEvent(oldEvent);

    layout = layout.map(item => ({ ...item, i: sanitizeItemId(item.i) }));
    setPreviousLayout(layout);
  }

  const onDragStop = async (layout, oldItem, newItem) => {
    if (dragging) {
      // wait time to prevent popover from triggering
      setTimeout(() => setDragging(false), 100);
    }

    if (initialPosition.x === newItem.x && initialPosition.y === newItem.y) return;

    const newEvent = toEventDetails(newItem);
    const event = events?.[newEvent?.id];

    if (!event) return;

    setDraggingEvent({ ...event, ...newEvent });
    setShowConfirm(true);
  }

  const onDrag = (layout, oldItem, newItem) => {
    if (!dragging) {
      setDragging(true);
    }

    if (newItem.x !== dragPosition.x || newItem.y !== dragPosition.y) {
      setDragPosition({ x: newItem.x, y: newItem.y });
      const eventDetails = toEventDetails(newItem);
      setDraggingEvent(eventDetails);
    }
  }

  const saveEvent = async (event) => {
    const { resource, id, date, startTime, endTime, status } = event;

    if (onSave) {
      await onSave(event);
    } else {
      await dataProvider.update(resource, { id, data: { date, startTime, endTime, status } })
    }
  }

  const onDrop = (layout, layoutItem) => {
    if (!layoutItem.i) return
    if (dragging) {
      // wait time to prevent popover from triggering
      setTimeout(() => setDragging(false), 100);
    }

    const eventResource = droppingEvent?.resource || schedulingResource || resource;
    const { date, startTime, endTime, arenaId } = toEventDetails(layoutItem, eventResource, droppingEvent?.id, gridDateIndex);
    const event = { ...droppingEvent, date, startTime, endTime, resource: eventResource, timezone };

    if (arenaId) {
      event.arenaId = arenaId;
    }

    layout = layout.map(item => ({ ...item, i: sanitizeItemId(item.i) }));

    const error = validateEvent(event, schedule, translate);
    if (error) {
      return notify(error, 'error');
    }

    setUpdateLayout(layout);
    setDraggingEvent(event);
    setShowConfirm(true);
  }

  const handleConfirm = async (record) => {
    await saveEvent(record);
    if (setSelectedGame && !disableScheduling) {
      setSelectedGame(record);
    }
    if (setRefreshAvailability && type === CALENDAR_VIEWS.SEASON) {
      setRefreshAvailability(true)
    }

    setShowConfirm(false);
    setDraggingEvent({});
  }

  const handleCancel = async () => {
    setUndo(true);
    setShowConfirm(false);
    setDraggingEvent({});
  }

  const droppingItem = {
    i: Object.keys(droppingEvent).length !== 0 ? `${droppingEvent?.resource || schedulingResource || resource}-${droppingEvent.id}` : null,
    w: 1,
    h: [CALENDAR_VIEWS.MONTH, CALENDAR_VIEWS.SEASON].includes(type) ? 1 : (gameLength || 60) / SNAP_MINUTES
  }

  return <>
    <GridLayout
      className="layout"
      layout={(undo && previousLayout) || (updateLayout.length && updateLayout) || undefined}
      onLayoutChange={onLayoutChange}
      style={gridStyle}
      cols={cols}
      rowHeight={rowHeight}
      maxRows={maxRows}
      width={gridWidth}
      margin={[0, 0]}
      isResizable={false}
      allowOverlap
      compactType={null}
      onDragStart={onDragStart}
      onDragStop={onDragStop}
      onDrag={onDrag}
      onDrop={onDrop}
      isDroppable={isDroppable}
      droppingItem={droppingItem?.i ? droppingItem : undefined}
      useCSSTransforms
      isBounded
    >
      {/* the children needs to be a function so that we can set dnd props to the actual childrens */}
      {typeof children === 'function' && children({ getDndItem, offsetHours })}
    </GridLayout>
    <ConfirmDialog open={showConfirm} handleCancel={handleCancel} handleConfirm={handleConfirm} previousEvent={previousEvent} />
  </>
}


