import React from 'react';
import { ReferenceArrayField, useListContext, useLocale, useTranslate } from 'react-admin';
import { orderBy } from 'lodash';

import { dedupeBy } from '@hisports/parsers';
import { translateApiProperty } from '@hisports/common';

import { formatList } from '../../locale';

const format = (locale, divisions, source) => {
  const values = divisions.map(division => {
    const value = translateApiProperty(division, source, locale);
    return value
  })

  return formatList(values, locale);
}

const DivisionsList = ({ source, emptyText }) => {
  const translate = useTranslate()
  const locale = useLocale();
  const { ids = [], data } = useListContext();

  const divisions = orderBy(dedupeBy('id', ids.map(id => data?.[id])), 'order')
  return format(locale, divisions, source, translate) || translate(emptyText) || null;
}

export const DivisionsField = ({ authMethod = "show", variant = 'body2', emptyText, ...props }) => {
  return <ReferenceArrayField basePath="/divisions" resource="divisions" reference="divisions" allowEmpty {...props} >
    <DivisionsList source="name" emptyText={emptyText} />
  </ReferenceArrayField>
}

DivisionsField.defaultProps = {
  addLabel: true,
}
