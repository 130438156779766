import React from 'react';
import { ReferenceInput, ReferenceArrayInput, useLocale, RecordContextProvider } from 'react-admin';

import { translateApiProperty } from '@hisports/common';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'

import { OfficeField } from '../offices/OfficeField';

const filterSection = name => ({ name })

export const RuleSectionSuggestion = ({ suggestion: section, query, isHighlighted, includeOffice = false, ...props }) => {
  const locale = useLocale();
  const primary = `${section?.code ? `${section.code} - ` : ''}${translateApiProperty(section, 'name', locale)}`;
  return <RecordContextProvider value={section}>
    <AutocompleteSuggestion
      primary={primary}
      secondary={includeOffice && <OfficeField source="officeId" variant="inherit" />}
    />
  </RecordContextProvider>
}

export const RuleSectionInput = ({ helperText, multiple, includeOffice = false, ...props }) => {
  const locale = useLocale();

  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="ruleSections"
    filterToQuery={filterSection}
    sort={{ field: ['order', 'name'], order: ['ASC', 'ASC'] }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText={section => translateApiProperty(section, 'name', locale)}
      suggestionComponent={RuleSectionSuggestion}
      options={{ suggestionComponentProps: { includeOffice } }}
      filterKeys={['name', 'code']}
      disableSort
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
