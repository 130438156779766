import { makeStyles } from '@material-ui/core';
import React, { useState, forwardRef, useEffect } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';

export const useSignature = (ref) => {
  const [ signature, setSignature ] = useState(null);
  const [ isEmpty, setEmpty ] = useState(true);

  return {
    isEmpty,
    signature,
    handleChange: function() {
      setSignature(this.toDataURL());
      setEmpty(false);
    },
    clear: () => {
      ref.current.clear();
      setEmpty(true);
    },
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.grey[100],
    borderRadius: theme.spacing(.5),
    margin: theme.spacing(1, 0),
  }
}))

export const SignatureInput = forwardRef(({ onChange }, ref) => {
  const classes = useStyles();

  useEffect(() => {
    const resize = () => {
      const canvas = ref.current?.canvas;
      const signaturePad = ref.current?.instance;
      if (!canvas || !signaturePad) return;

      // maintain aspect ratio
      const width = canvas.parentNode.clientWidth;
      canvas.width = width;
      canvas.height = width / 2.5;

      signaturePad.clear();
    }
    resize()
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [ ref ])

  return <div className={classes.root}>
    <SignaturePad ref={ref} options={{
      dotSize: 2.5,
      minWidth: 0.5,
      penColor: 'rgb(0, 0, 0)',
      velocityFilterWeight: 0.7,
      onEnd: onChange,
    }} />
  </div>
})
