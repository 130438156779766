import React, { Fragment } from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../common/cards/ListCard';
import { useSeason } from '../../common/inputs/SeasonSelector';

import { AssignRuleAltGrid } from '../assignRules/AssignRuleGrid';
import { AddAssignRuleButton } from './AssignRuleModal';

export const AssignRuleList = ({ type, officeId, ...props }) => {
  const record = useRecordContext(props);
  const seasonId = useSeason();

  return <ListCard {...props}
    reference="assignRules"
    resource="assignRules"
    target="value"
    filter={{ seasonId, type, effectiveOffices: officeId, includeParents: true, _scope: 'Parents' }}
    sort={{
      field: ['type', 'value', 'order'],
      order: ['ASC', 'ASC', 'ASC'],
    }}
    perPage={25}
    rowsPerPageOptions={[]}
    addButton={<AddAssignRuleButton initialValues={{ type, value: record.id, seasonId, officeId }} />}
    addPosition="footer"
    component={Fragment}
  >
    <AssignRuleAltGrid rowClick={false} />
  </ListCard>
};
