import React from 'react';
import { useRecordContext } from 'react-admin';
import { styled } from '@material-ui/core'
import { Business, DateRange, PeopleOutline, PlaceOutlined, PinDropOutlined, Group } from '@material-ui/icons'

const styleIcon = Icon => styled(Icon)({
  fontSize: '1.5em',
  marginRight: '-1em',
  verticalAlign: 'middle',
})
const OfficeIcon = styleIcon(Business)
const ScheduleIcon = styleIcon(DateRange)
const TeamIcon = styleIcon(PeopleOutline)
const VenueIcon = styleIcon(PlaceOutlined)
const SurfaceIcon = styleIcon(PinDropOutlined)
const ListIcon = styleIcon(Group)
const GameIcon = styleIcon(Group)

export const TargetTypeField = ({ source = "targetType", ...props }) => {
  const record = useRecordContext(props);

  if (!record) return null;
  switch (record[source]) {
    case 'Office':
      return <OfficeIcon {...props} />
    case 'Schedule':
      return <ScheduleIcon {...props} />
    case 'Team':
      return <TeamIcon {...props} />
    case 'Venue':
      return <VenueIcon {...props} />
    case 'Surface':
    case 'Arena':
      return <SurfaceIcon {...props} />
    case 'List':
      return <ListIcon {...props} />
    case 'Game':
      return <GameIcon {...props} />
    default:
      return null;
  }
}
