import React, { useEffect, useRef, useState } from 'react'
import { RecordContextProvider, useInput, useRecordContext, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Paper, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, makeStyles, TextField, LinearProgress, Menu, MenuItem, FormControlLabel, Checkbox, Box, IconButton, Tooltip, Button } from '@material-ui/core';
import { MergeType as MergeTypeIcon, MoreVert as MoreVertIcon, Add as AddIcon } from '@material-ui/icons';

import { sum } from '@hisports/parsers';
import { getValidationDiff, unparseMatrix } from '@hisports/scheduler/src/util/matrix';

import { apiClient } from '../../../../http';
import { TeamField } from '../../../teams/TeamField';
import { useElementSize } from '../../../../common/useElementSize';
import { ScheduleField } from '../../ScheduleField';
import { GroupField } from '../../../groups/GroupField';

import { useGeneratorContext } from './GeneratorContext';
import { AddCrossTeamDialog } from './CrossTeamDialog';

const stickyStyle = (theme) => ({
  position: 'sticky',
  backgroundColor: theme.palette.background.paper,
  zIndex: 2,
});

const stickyBothSidesStyle = {
  zIndex: 3
}

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: props => props.maxHeight,
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    '& th': {
      fontWeight: '500',
    },
    // not using mui stickyHeader props because it conflicts with left sticky behaviour
    '& thead th': {
      borderBottom: `2px solid ${theme.palette.divider}`,
      ...stickyStyle(theme),
      top: 0,
      '&:first-child': stickyBothSidesStyle,
      '&:nth-child(2)': stickyBothSidesStyle
    },
    '& th:first-child': {
      ...stickyStyle(theme),
      left: 0,
    },
    '& th:nth-child(2)': {
      borderRight: `2px solid ${theme.palette.divider}`,
      ...stickyStyle(theme),
      left: props => props.firstCellWidth,
    },
    '& tfoot :is(th, td)': {
      fontWeight: '500',
      borderTop: `2px solid ${theme.palette.divider}`,
      ...stickyStyle(theme),
      bottom: 0,
      '&:first-child': stickyBothSidesStyle,
      '&:nth-child(2)': stickyBothSidesStyle
    },

  },
  disabledCell: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  input: {
    padding: theme.spacing(.5),
    textAlign: 'center',
  },
  firstColumns: {
    width: theme.spacing(20),
  },
  indexedTeam: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  totalColumns: {
    width: theme.spacing(8),
  },
  teamNameWidth: {
    minWidth: theme.spacing(16),
  },
  teamIndexWidth: {
    minWidth: theme.spacing(7.5),
  },
  crossTooltip: {
    verticalAlign: '-15%'
  }
}))

const CrossTeamTooltip = ({ schedule, ...props }) => {
  const translate = useTranslate();
  const team = useRecordContext(props);
  const classes = useStyles()
  const { scheduleId, groupId, isCrossTeam } = team

  if (!isCrossTeam) return null;

  const CrossSchedulingDetails = <span>
    {schedule?.id !== scheduleId && <>{translate('resources.draftGames.labels.cross_schedule')}: <ScheduleField source="scheduleId" link={false} variant="inherit" /></>}
    {schedule?.id !== scheduleId && groupId && <br />}
    {groupId && <>{translate('resources.draftGames.labels.cross_group')}: <GroupField source="groupId" link={false} variant="inherit" /></>}
  </span>

  return <Tooltip title={CrossSchedulingDetails} placement="top" className={classes.crossTooltip}>
    <MergeTypeIcon fontSize="small" />
  </Tooltip>
}

const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = () => setValue(currentValue => !currentValue);

  return [value, toggle];
}

const MatrixOptions = ({
  showOrganisation, toggleShowOrganisation,
  showShortNames, toggleShowShortNames,
  compactMode, toggleCompactMode,
  isCrossTeamDialogOpen, setCrossTeamDialogOpen
}) => {
  const [ anchor, setAnchor ] = useState(null)
  const toggleMenu = e => setAnchor(anchor => anchor ? !anchor : e.currentTarget)
  const handleClose = () => setAnchor(null)
  const translate = useTranslate();
  const classes = useStyles()

  return (
    <>
      <Box sx={{ mb: 2, textAlign: 'right' }}>
        <Button color="primary" startIcon={<AddIcon />} onClick={() => setCrossTeamDialogOpen(true)}>
          {translate('resources.draftGames.labels.add_cross_team')}
        </Button>
        <IconButton onClick={toggleMenu}><MoreVertIcon /></IconButton>
      </Box>
      <Menu
        classes={{ paper: classes.menu }}
        anchorEl={anchor}
        open={anchor != null}
        onClose={handleClose}
      >
        <MenuItem>
          <FormControlLabel
            className={classes.controlLabel}
            control={<Checkbox checked={showOrganisation} onChange={toggleShowOrganisation} disabled={false} name="" />}
            label={translate('resources.schedules.labels.matrix.show_organisation')}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            className={classes.controlLabel}
            control={<Checkbox checked={!showShortNames} onChange={toggleShowShortNames} disabled={false} name="" />}
            label={translate('resources.schedules.labels.matrix.show_full_names')}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            className={classes.controlLabel}
            control={<Checkbox checked={compactMode} onChange={toggleCompactMode} disabled={false} name="" />}
            label={translate('resources.schedules.labels.matrix.compact_mode')}
          />
        </MenuItem>
      </Menu>
    </>
  )
}

export const Matrix = ({ maxHeight, source = 'matrixTable' }) => {
  const translate = useTranslate();
  const { values } = useFormState();
  const { mutators } = useForm();
  const { schedule, groupId, scheduleTeams } = useGeneratorContext()
  const firstThRef = useRef();
  const { width: firstCellWidth } = useElementSize(firstThRef.current)
  const classes = useStyles({ maxHeight, firstCellWidth })
  const [ generateMatrixLoaded, setGenerateMatrixLoaded ] = useState(false);
  const [ generateMatrixLoading, setGenerateMatrixLoading ] = useState(true);
  const [ inverseMatrix, setInverseMatrix ] = useState(true) // hardcoded away rows, home columns
  const { input: { value: matrixTable, onChange } } = useInput({ source })
  const [ isCrossTeamDialogOpen, setCrossTeamDialogOpen ] = useState(false);
  const [ showOrganisation, toggleShowOrganisation ] = useToggle(false);
  const [ showShortNames, toggleShowShortNames ] = useToggle(true);
  const [ compactMode, toggleCompactMode ] = useToggle(false);

  const { gamesPerTeam, crossScheduleTeams = [] } = values;

  useEffect(() => {
    if (matrixTable) return
    apiClient(`/schedules/${schedule.id}/generateMatrix`, { params: {
      groupId,
      generationOptions: { gamesPerTeam }
    } })
      .then(res => res.data)
      .then(matrix => {
        const matrixTable = unparseMatrix(matrix)
        onChange(matrixTable)
      })
      .catch(err => {
        onChange()
      })
      .finally(() => {
        setGenerateMatrixLoaded(true)
        setGenerateMatrixLoading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ schedule.id, groupId, gamesPerTeam ])

  const handleCellChange = (value, rowIndex, columnIndex) => {
    if (isNaN(value)) return

    // maximum 3 digits
    if (value.length > 3) {
      value = parseInt(value.toString().slice(0, 3))
    }

    const table = [...matrixTable]

    if (inverseMatrix) {
      table[columnIndex][rowIndex] = Number(value)
    } else {
      table[rowIndex][columnIndex] = Number(value)
    }

    onChange(table)
  }

  const onCrossTeamSubmit = (crossTeam) => {
    onChange([
      ...matrixTable.map(row => [...row, 0]), // add zeroes at end of each row
      Array(matrixTable.length + 1).fill(0, 0, matrixTable.length).fill(null, matrixTable.length) // add row [0, 0, ... , 0, null]
    ])
    mutators.push('crossScheduleTeams', crossTeam)
    setCrossTeamDialogOpen(false)
  }

  if (!matrixTable && (generateMatrixLoading || !generateMatrixLoaded)) return <LinearProgress />;
  if (!matrixTable) return null;

  const totalGames = (scheduleTeams.length * gamesPerTeam) / 2
  const totalDiff = sum(matrixTable) - totalGames

  const rowTotals = matrixTable.map(sum)
  const columnTotals = matrixTable.map((row, index) => sum(matrixTable.map(row => row[index])))
  const teams = [...scheduleTeams, ...crossScheduleTeams.map(crossScheduleTeam => ({ isCrossTeam: true, ...crossScheduleTeam }))]

  return (
    <>
      <div>
        <MatrixOptions
          showOrganisation={showOrganisation} toggleShowOrganisation={toggleShowOrganisation}
          showShortNames={showShortNames} toggleShowShortNames={toggleShowShortNames}
          compactMode={compactMode} toggleCompactMode={toggleCompactMode}
          isCrossTeamDialogOpen={isCrossTeamDialogOpen} setCrossTeamDialogOpen={setCrossTeamDialogOpen}
        />
      </div>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table}>
          <colgroup>
            <col className={classes.firstColumns} />
            <col className={classes.totalColumns} />
            {teams.map((team) => (
              <col key={team.teamId} className={compactMode ? classes.teamIndexWidth : classes.teamNameWidth} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell ref={firstThRef}>
                {translate(`resources.draftGames.labels.${inverseMatrix ? 'away' : 'home'}`)} vs {translate(`resources.draftGames.labels.${inverseMatrix ? 'home' : 'away'}`)} →
              </TableCell>
              <TableCell align="center">{translate('resources.draftGames.labels.total')}</TableCell>
              {teams.map((team, index) => (
                <TableCell key={team.teamId} align="center">
                  {compactMode ?
                    <span>{index+1}</span>
                    :
                    <RecordContextProvider value={team}>
                      <div>
                        <TeamField source="teamId" link={false} includeOffice={showOrganisation ? 'full' : null} showShortName={showShortNames} />
                        <CrossTeamTooltip schedule={schedule} />
                      </div>
                    </RecordContextProvider>
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team, rowIndex) => {
              const rowSum = inverseMatrix ? columnTotals[rowIndex] : rowTotals[rowIndex]
              const columnSum = inverseMatrix ? rowTotals[rowIndex] : columnTotals[rowIndex]
              const cellDiff = getValidationDiff(gamesPerTeam, rowSum, columnSum)

              return (
                <TableRow key={team.teamId}>
                  <TableCell component="th" scope="row">
                    <div className={classes.indexedTeam}>
                      {compactMode &&
                        <span>{rowIndex+1}</span>
                      }
                      <RecordContextProvider value={team}>
                        <div>
                          <TeamField source="teamId" link={false} includeOffice={showOrganisation ? 'full' : null} showShortName={showShortNames} />
                          <CrossTeamTooltip schedule={schedule} />
                        </div>
                      </RecordContextProvider>
                    </div>
                  </TableCell>
                  <ValidatedTotalCell
                    component="th"
                    scope="row"
                    align="center"
                    cellDiff={cellDiff}
                    skipValidation={team.isCrossTeam}
                  >
                    {rowSum}
                  </ValidatedTotalCell>
                  {matrixTable[rowIndex].map((cell, columnIndex) => {
                    return (
                      <TableCell key={`${team.teamId}-${teams[columnIndex].teamId}`} align="center" className={rowIndex === columnIndex ? classes.disabledCell : ''}>
                        {rowIndex !== columnIndex && <TextField
                          inputProps={{
                            className: `${classes.input}`,
                          }}
                          autoFocus={!rowIndex && !columnIndex}
                          variant="outlined"
                          value={inverseMatrix ? matrixTable[columnIndex][rowIndex] : matrixTable[rowIndex][columnIndex]}
                          onChange={e => handleCellChange(e.target.value, rowIndex, columnIndex)}
                        />
                        }
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
          <tfoot>
            <TableRow>
              <TableCell component="th" scope="row">{translate('resources.draftGames.labels.total')}</TableCell>
              <ValidatedTotalCell cellDiff={totalDiff} component="th" scope="row" align="center" style={{ whiteSpace: 'nowrap' }}>{sum(matrixTable)}</ValidatedTotalCell>
              {teams.map((team, columnIndex) => {
                const rowSum = inverseMatrix ? columnTotals[columnIndex] : rowTotals[columnIndex]
                const columnSum = inverseMatrix ? rowTotals[columnIndex] : columnTotals[columnIndex]
                const cellDiff = getValidationDiff(gamesPerTeam, columnSum, rowSum)

                return (
                  <ValidatedTotalCell key={team.teamId} align="center" cellDiff={cellDiff} skipValidation={team.isCrossTeam}>
                    {columnSum}
                  </ValidatedTotalCell>
                )
              })}
            </TableRow>
          </tfoot>
        </Table>
      </TableContainer>
      <AddCrossTeamDialog isOpen={isCrossTeamDialogOpen} setOpen={setCrossTeamDialogOpen} onSubmit={onCrossTeamSubmit} schedule={schedule} groupId={groupId} teams={teams} />
    </>
  );
}

const getValidationStyles = (cellDiff, theme) => {
  if (!cellDiff) return;

  // over - red
  if (cellDiff == 1) return { backgroundColor: [theme.palette.error[300], '!important'], color: theme.palette.error.contrastText };
  if (cellDiff == 2) return { backgroundColor: [theme.palette.error[400], '!important'], color: theme.palette.error.contrastText };
  if (cellDiff == 3) return { backgroundColor: [theme.palette.error[500], '!important'], color: theme.palette.error.contrastText };
  if (cellDiff == 4) return { backgroundColor: [theme.palette.error[600], '!important'], color: theme.palette.error.contrastText };
  if (cellDiff >= 5) return { backgroundColor: [theme.palette.error[700], '!important'], color: theme.palette.error.contrastText };

  // under - blue
  if (cellDiff == -1) return { backgroundColor: [theme.palette.primary[300], '!important'], color: theme.palette.primary.contrastText };
  if (cellDiff == -2) return { backgroundColor: [theme.palette.primary[400], '!important'], color: theme.palette.primary.contrastText };
  if (cellDiff == -3) return { backgroundColor: [theme.palette.primary[500], '!important'], color: theme.palette.primary.contrastText };
  if (cellDiff == -4) return { backgroundColor: [theme.palette.primary[600], '!important'], color: theme.palette.primary.contrastText };
  if (cellDiff <= -5) return { backgroundColor: [theme.palette.primary[700], '!important'], color: theme.palette.primary.contrastText };
}

const useValidationStyles = makeStyles(theme => ({
  validation: ({ cellDiff }) => getValidationStyles(cellDiff, theme)
}));

const ValidatedTotalCell = ({ cellDiff, skipValidation, children, className: tableCellClasses, ...tableCellProps }) => {
  const classes = useValidationStyles({ cellDiff });

  return <TableCell {...tableCellProps} className={`${tableCellClasses} ${skipValidation ? '' : classes.validation}`} >
    {children}
    {cellDiff !== 0 && !skipValidation && <>{' '}({cellDiff})</>}
  </TableCell>
}
