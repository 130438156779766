import React from 'react';

import { useShowAvailabilities, useShowSlots, useShowTeamEvents } from '../../resources/events/EventViewSettings';

import { Availabilities, Slots, TeamEvents } from './Events';
import CalendarGrid from './grid'

export default ({ offsetHours = 6, events, ...props }) => {
  const [ showTeamEvents ] = useShowTeamEvents();
  const [ showSlots ] = useShowSlots();
  const [ showAvailabilities ] = useShowAvailabilities();

  return <CalendarGrid offsetHours={offsetHours}>
    {showTeamEvents && <TeamEvents events={events} {...props} />}
    {showSlots && <Slots events={events} {...props} />}
    {showAvailabilities && <Availabilities events={events} {...props} />}
  </CalendarGrid>
}
