import React from 'react';
import { RecordContextProvider, useRecordContext } from 'react-admin';
import { Card, Grid } from '@material-ui/core'

import { isAuthorized } from '../../common/Authorize';

import { useGameWithStore } from '../games/useGame';
import { useBranchSettings } from '../branchOfficeSettings';
import { ScoresheetProvider } from '../scoresheet/ScoresheetContext';
import { GamePurgeList } from '../scoresheet/suspension/GamePurgeList';
import { IncidentReportCard } from '../scoresheet/gameIncidents/IncidentReportCard';
import { ParticipantSuspensionsCard } from './ParticipantSuspensionsCard';
import { SuspensionDisciplineCard } from '../suspensionDiscipline/SuspensionDisciplineCard';
import DisciplineCommitteeCard from '../suspensionDiscipline/DisciplineCommitteeCard';
import SuspensionCard from './SuspensionCard';

export const SuspensionShowLayout = props => {
  const suspension = useRecordContext();
  const { gameId, participantId, penaltyId, disciplineStatus } = suspension;

  const { data: game } = useGameWithStore(gameId);
  const { data: branchSettings } = useBranchSettings(game?.officeId);

  const hasDiscipline = !!disciplineStatus;
  const disciplinesEnabled = !!branchSettings?.ff_suspension_disciplines;
  const canViewSuspensionDiscipline = hasDiscipline && disciplinesEnabled && isAuthorized(suspension, 'suspensionDisciplines', 'show');
  const canViewDisciplineCommittee = hasDiscipline && disciplinesEnabled && isAuthorized(suspension, 'suspensionDisciplines', 'committee');

  if (!gameId) return null;

  return <ScoresheetProvider gameId={gameId} hideAlerts>
    <Grid container spacing={3}>
      <Grid item xs={12} lg={canViewSuspensionDiscipline ? 6 : 12}>
        <SuspensionCard hasDiscipline={hasDiscipline} disciplinesEnabled={disciplinesEnabled} />
      </Grid>
      {canViewSuspensionDiscipline && <Grid item xs={12} lg={6}>
        <SuspensionDisciplineCard />
      </Grid>}
      <Grid item xs={12}>
        <Card>
          <GamePurgeList />
        </Card>
      </Grid>
      {canViewDisciplineCommittee && <Grid item xs={12}>
        <DisciplineCommitteeCard />
      </Grid>}
      <Grid item xs={12}>
        <RecordContextProvider value={{ id: gameId, officeId: game?.officeId }}>
          <IncidentReportCard hideAddButton filter={{ penaltyId }} rowOptions={{ isRowSelectable: () => false }} />
        </RecordContextProvider>
      </Grid>
      <Grid item xs={12}>
        <RecordContextProvider value={{ id: participantId }}>
          <ParticipantSuspensionsCard filter={{ participantId }} />
        </RecordContextProvider>
      </Grid>
    </Grid>
  </ScoresheetProvider>
}
