import moment from 'moment-timezone';
import Papa from 'papaparse'

import { dedupe } from "@hisports/parsers"

import { appName } from '../../../flags';


export default (transactions, translate) => {
  const participantIds = dedupe(transactions.map(transaction => transaction.participantId));

  const data = participantIds.map(participantId => {
    const participantTransactions = transactions.filter(tx => tx.participantId === participantId);
    const participant = participantTransactions?.[0]?.participant;

    const travelFees = participantTransactions
      .filter(tx => tx.type === 'Travel')
      .reduce((total, tx) => total += Number(tx.amount), 0)

    const officiatingFees = participantTransactions
      .filter(tx => tx.type === 'Base')
      .reduce((total, tx) => total += Number(tx.amount), 0)

    const otherFees = participantTransactions
      .filter(tx => ['Per Diem', 'Evaluation', 'Exhibition Game', 'Internship', 'Withhold', 'Other'].includes(tx.type))
      .reduce((total, tx) => total += Number(tx.amount), 0)

    const total = travelFees + officiatingFees + otherFees;

    return [
      participant?.firstName,
      participant?.lastName,
      participant?.HCRId,
      Number(officiatingFees).toFixed(2),
      Number(travelFees).toFixed(2),
      Number(otherFees).toFixed(2),
      Number(total).toFixed(2),
    ]
  })

  const csv = Papa.unparse({
    fields: [
      translate('components.exporter.transactions_exporter.reports.summary.columns.first_name'),
      translate('components.exporter.transactions_exporter.reports.summary.columns.last_name'),
      translate('components.exporter.transactions_exporter.reports.summary.columns.member_id'),
      translate('components.exporter.transactions_exporter.reports.summary.columns.officiating_fees'),
      translate('components.exporter.transactions_exporter.reports.summary.columns.travel_fees'),
      translate('components.exporter.transactions_exporter.reports.summary.columns.other_fees'),
      translate('components.exporter.transactions_exporter.reports.summary.columns.total_fees'),
    ],
    data,
  })
  const fileName = `${appName} - ${translate('components.exporter.transactions_exporter.reports.summary.title')} - ${moment().format('YYYY-MM-DD HH:mm')}`;

  return { csv, fileName };
}
