import React, { useState } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { getLineupApproval } from '@hisports/scoresheet/src/selectors';

import { ParticipantField } from '../../participants/ParticipantField';
import { useScoresheet } from '../ScoresheetContext';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    margin: theme.spacing(1),
  },
  expand: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  signature: {
    display: 'block',
    margin: '0 auto',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
    maxWidth: '100%',
    maxHeight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(12),
    }
  },
}))

export const LineupSignature = ({ teamId }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const approval = useScoresheet(scoresheet => getLineupApproval(scoresheet, { teamId }))
  const [ expanded, setExpanded ] = useState(true)
  if (!approval) return null;

  const toggle = <IconButton onClick={() => setExpanded(expand => !expand)} className={classes.expand}>
    {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
  </IconButton>

  return <RecordContextProvider value={approval}>
    <div className={classes.root}>
      <Typography variant="overline">{translate('resources.scoresheets.messages.lineup_approved_by')} <ParticipantField source="participantId" variant="overline" link={false} /></Typography>
      {toggle}
      {expanded && <img className={classes.signature} src={approval.signature} alt="Signature" />}
    </div>
  </RecordContextProvider>
}
