import React, { useMemo } from 'react';
import { ListContextProvider, useList } from 'react-admin';
import { useFormState } from 'react-final-form';

export const ArrayGrid = ({ source, children }) => {
  const { values } = useFormState();
  const data = useMemo(() => Object.values(values[source] || []), [ values, source ]);

  return <ListContextProvider value={useList({
    data,
    ids: data ? Object.keys(data) : [],
    loading: false,
    loaded: true,
    sort: {
      field: 'name',
      order: 'ASC'
    } })}>
    {children}
  </ListContextProvider>
}
