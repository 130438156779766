import React from 'react';
import { ImageInput, ImageField, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  input: {
    width: '256px'
  }
}));

const LogoInput = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  return <ImageInput
    accept="image/*"
    multiple={false}
    placeholder={translate('ra.input.file.drop.logo')}
    classes={{ root: classes.input }}
    {...props}
  >
    <ImageField source="url" />
  </ImageInput>
}

export default LogoInput;
