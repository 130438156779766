import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { SurfaceIcon } from '../../icons';

import { ArenaOfficesGrid } from '../../arenaoffices/ArenaOfficesGrid';
import { AddArenaOfficeButton } from '../../arenaoffices/ArenaOfficeModal';

export default ({ collapsable, ...props }) => {
  const office = useRecordContext(props)
  const canAddSurface = isAuthorized(office, 'arenaoffices', 'create') && !['League', 'Tournament', 'Cup'].includes(office?.type)

  return <ListCard
    title="resources.surfaces.labels.card.title"
    icon={<SurfaceIcon />}
    reference="arenaoffices"
    target="officeId"
    filter={{ effectiveOffices: true }}
    sort={{
      field: ['surface.venue.name', 'surface.name', 'isPrimary', 'office.name'],
      order: ['ASC', 'ASC', 'DESC', 'ASC'],
    }}
    collapsable={collapsable}
    addButton={canAddSurface && <AddArenaOfficeButton initialValues={{ officeId: office?.id }} target="officeId" />}
  >
    <ArenaOfficesGrid />
  </ListCard>
}
