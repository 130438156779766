import { createInstance } from 'localforage';
import { v4 as uuid } from 'uuid';
import { setTag } from '@sentry/browser';

const storage = createInstance({
  name: 'hisports',
  storeName: 'device'
})

export const storageReady = storage.ready();

const getDeviceId = () => {
  return storage.getItem('deviceId')
    .catch(() => null)
    .then(deviceId => {
      if (deviceId) return deviceId;

      const newId = uuid();
      storage.setItem('deviceId', newId).catch(e => {})
      return newId;
    })
}

export const deviceId = getDeviceId().catch(() => uuid())

deviceId.then(deviceId => {
  setTag('deviceId', deviceId)
})
