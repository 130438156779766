import React, { Fragment } from 'react';
import { SimpleForm, FormInput, TextInput, useNotify, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { FORM_ERROR } from 'final-form';

import { apiClient } from '../../http';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';

const validate = values => {
  const errors = {};
  if (!values.email) errors.email = 'ra.validation.required';
  return errors;
}

const InviteForm = props =>
  <SimpleForm component={Fragment} validate={validate} variant="outlined" margin="normal" {...props}>
    <FormInput fullWidth input={
      <TextInput source="email" type="email" autoComplete="off" />
    } />
    <FormInput fullWidth input={
      <TextInput source="note" type="text" helperText="resources.participants.helpers.note" multiline rows="3" />
    } />
  </SimpleForm>

export const InviteDialog = ({ participantId, open, onClose }) => {
  const translate = useTranslate();
  const notify = useNotify()

  const handleCancel = () => onClose()
  const handleSubmit = values => {
    return apiClient(`/participants/${participantId}/invite`, {
      method: 'POST',
      data: {
        email: values.email,
        note: values.note,
      },
    })
      .then(() => {
        onClose();
        notify('resources.participants.notifications.invitation_sent')
      })
      .catch(err => {
        const error = err?.response?.data?.error?.message
        return { [FORM_ERROR]: error || 'ra.page.error' }
      })
  }

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.participants.labels.account_invitation')}</DialogTitle>
    <DialogContent>
      <DialogContentText>{translate('resources.participants.messages.send_link')}</DialogContentText>
      <InviteForm initialValues={{ participantId }} save={handleSubmit} toolbar={
        <DialogFormToolbar submitLabel="ra.action.send" onCancel={handleCancel} />
      } />
    </DialogContent>
  </Dialog>
}
