import { useEffect, useMemo, useRef, useState } from "react";
import { apiClient } from "../../http"

export const useSlotsInUse = (slotIds = []) => {
  const [ data, setData ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  const prevSlotIdsRef = useRef();

  const slotIdsToFetch = useMemo(() => slotIds.filter(slotId => typeof data[slotId] === 'undefined'), [slotIds, data])

  useEffect(() => {
    const currentSlotIdsToFetch = JSON.stringify(slotIdsToFetch);

    if (prevSlotIdsRef.current === currentSlotIdsToFetch) {
      return;
    }

    prevSlotIdsRef.current = currentSlotIdsToFetch;

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await apiClient({
          method: 'GET',
          url: '/arenaslots/isInUse',
          params: { slotIds: slotIdsToFetch },
        })
        setData(data => ({ ...data, ...response.data }));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };


    if (!slotIdsToFetch?.length) return;

    fetchData();
  }, [ slotIdsToFetch ])

  return { data, loading, error };
}
