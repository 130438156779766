import React, { useMemo } from 'react';
import { GET_MANY_REFERENCE, useLocale, useQueryWithStore, useTranslate } from 'react-admin';
import { TableRow, TableCell, IconButton, Collapse, makeStyles, LinearProgress, useMediaQuery, Tooltip } from "@material-ui/core"
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { formatCurrency } from '../../../../../locale';

import { useAssignmentsContext } from '../AssignmentsContext';
import { AssignmentProvider, useAssignmentContext } from './AssignmentContext';
import { AssignmentStatus } from './AssignmentStatus';
import OfficialList from './OfficialList';
import OfficialDetails from './OfficialDetails';
import AssignToolbar from './AssignToolbar';

const useStyles = makeStyles(theme => ({
  toggleCell: {
    width: theme.spacing(22),
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      width: 30,
    }
  },
  feeCell: {
    width: '7ch',
  },
  statusCell: {
    width: theme.spacing(16),
  },
  status: {
    fontStyle: 'italic',
    fontColor: theme.palette.text.disabled,
  },
  panelCell: {
    padding: 0,
    borderBottom: 0,
  },
  panel: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(42),
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  details: {
    flexGrow: 1,
  },
  toolbar: {
    textAlign: 'right',
    padding: 8,
    backgroundColor: theme.palette.grey[100]
  }
}))

const useOfficialContacts = (officialId, types = [], isPrimary) => useQueryWithStore({
  type: GET_MANY_REFERENCE,
  resource: 'contacts',
  payload: {
    target: 'participants',
    id: officialId,
    filter: { _scope: 'Tenant', type: { inq: types }, isPrimary }
  },
}, {
  action: 'CUSTOM_QUERY',
  enabled: !!officialId
})

export const AdhocOfficial = ({ participant }) => {
  const translate = useTranslate();
  if (!participant) return;
  return `${participant.fullName.toUpperCase()} (${translate('resources.games.labels.assignment.adhoc')})`;
}

const Participant = ({ hideOfficial, hideStatus, hideContacts, assignment, settings }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const { status, participantId, participant } = assignment;

  const { data: contacts } = useOfficialContacts(participantId, ['Email', 'Mobile'], true)
  const [ email, mobile ] = useMemo(() => [
    contacts?.find(contact => contact.type === 'Email'),
    contacts?.find(contact => contact.type === 'Mobile')
  ], [contacts])

  const tooltipTitle = <span>{[email ? `Email: ${email.value}` : '', email ? <br /> : '', mobile ? `Mobile: ${mobile.value}` : '']}</span>
  const tooltipTitleMobile = <span>{[email ? email.value : '', email ? <br /> : '', mobile ? mobile.value : '']}</span>

  if (hideStatus) return <div className={classes.status}>
    {translate('resources.games.labels.assignment.hidden')}
  </div>

  if (hideOfficial && status && status !== "unassigned") return <div className={classes.status}>
    {translate('resources.games.labels.assignment.status.assigned')}
  </div>

  if (participantId && !hideOfficial && isMobile && !hideContacts) return <span>{participant.fullName.toUpperCase()} <br /> {tooltipTitleMobile} </span>
  if (participantId && !hideOfficial && !hideContacts) return (email || mobile) ? <Tooltip title={tooltipTitle}><span>{participant.fullName.toUpperCase()}</span></Tooltip> : <span>{participant.fullName.toUpperCase()}</span>
  if (participantId && !hideOfficial) return <span>{participant.fullName.toUpperCase()}</span>
  if (participant && !hideOfficial) return <div className={classes.status}>
    <AdhocOfficial participant={participant} />
  </div>

  if (settings?.mode === 'Integration') return <div className={classes.status}>{translate('ra.page.not_available')}</div>
  return <div className={classes.status}>
    {translate('resources.games.labels.assignment.unassigned')}
  </div>
}

const AssignmentSummaryRow = () => {
  const { assignment, meta, isPanelOpen, togglePanel } = useAssignmentContext();
  const { game, settings } = useAssignmentsContext();
  const translate = useTranslate();
  const classes = useStyles();
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const locale = useLocale();

  const showFeeAndContacts = meta.canAssign || meta.isOfficial;
  const hideOfficial = !game.isApproved && settings?.assignmentVisibility !== "Visible" && !meta.isAssignedGame && !meta.canAssign;
  const hideStatus = !game.isApproved && settings?.assignmentVisibility === "Hidden" && !meta.isOfficial && !meta.canAssign;

  const toggle = meta.enabled && <IconButton aria-label="expand row" size="small" disabled={!meta.showPanel}>
    {(isPanelOpen && meta.showPanel) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
  </IconButton>

  const assignmentStatus = !hideStatus
    ? <AssignmentStatus assignment={assignment} showDelegated />
    : <div className={classes.status}>
      {translate('resources.games.labels.assignment.hidden')}
    </div>

  if (small) {
    return <TableRow onClick={togglePanel}>
      <TableCell className={classes.toggleCell}>
        {toggle}
      </TableCell>
      <TableCell colSpan={2}>
        <div>{translate(`resources.games.values.assignment.position.${assignment.position}`)}</div>
        <Participant hideOfficial={hideOfficial} hideStatus={hideStatus} hideContacts={!showFeeAndContacts} assignment={assignment} settings={settings} />
      </TableCell>
      <TableCell align="right" className={classes.statusCell}>
        {showFeeAndContacts && <div>{formatCurrency(meta.fee, locale)}</div>}
        {assignmentStatus}
      </TableCell>
    </TableRow>
  }

  return <TableRow onClick={togglePanel}>
    <TableCell className={classes.toggleCell}>
      {toggle}
      {' '}
      {translate(`resources.games.values.assignment.position.${assignment.position}`, { _: assignment.position })}
    </TableCell>
    <TableCell>
      <Participant hideOfficial={hideOfficial} hideStatus={hideStatus} hideContacts={!showFeeAndContacts} assignment={assignment} settings={settings} />
    </TableCell>
    {showFeeAndContacts && <TableCell className={classes.feeCell} align="right" >{formatCurrency(meta.fee, locale)}</TableCell>}
    <TableCell className={classes.statusCell} align="right" colSpan={showFeeAndContacts ? 1 : 2}>{assignmentStatus}</TableCell>
  </TableRow>
}

const AssignmentPanelRow = () => {
  const { meta, isPanelOpen, isPanelLoading } = useAssignmentContext();
  const classes = useStyles();

  return <>
    {isPanelOpen && isPanelLoading && <TableRow>
      <TableCell colSpan={4} className={classes.panelCell}>
        <LinearProgress />
      </TableCell>
    </TableRow>}

    {meta.showPanel && <TableRow>
      <TableCell colSpan={4} className={classes.panelCell}>
        <Collapse in={isPanelOpen}>
          {isPanelOpen && <>
            <div className={classes.panel}>
              {meta.canAssign && (!meta.isCertified || meta.certifiedOfficialChanges) && <OfficialList />}
              <div className={classes.details}>
                <OfficialDetails />
              </div>
            </div>
            <AssignToolbar className={classes.toolbar} />
          </>}
        </Collapse>
      </TableCell>
    </TableRow>}
  </>
}

const AssignmentRow = () => {
  const { game } = useAssignmentsContext();
  const { assignment, meta } = useAssignmentContext();

  if (!game || !assignment || !meta) return null;
  if (meta.hideSupervisor) return null;

  return <>
    <AssignmentSummaryRow />
    <AssignmentPanelRow />
  </>
}

export const Assignment = ({ assignment }) => {
  return <AssignmentProvider assignment={assignment}>
    <AssignmentRow />
  </AssignmentProvider>
}
