import React from 'react';
import { useRecordContext } from 'react-admin';
import { LocalAtm } from '@material-ui/icons';

import { usePermissions } from '../../../http';
import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { AddTransactionButton } from '../../officialTransactions/TransactionModal';
import { TransactionsGrid } from '../../officialTransactions/TransactionsGrid';
import TransactionsExporter from '../../officialTransactions/TransactionsExporter';

export default ({ ...props }) => {
  const participant = useRecordContext();
  const permissions = usePermissions();
  const seasonId = useSeason();

  const canAddTransactions = isAuthorized(participant, 'officialTransactions', 'create');
  const canSelectAnyOffice = permissions.some(p => p.roleType === 'System')
  const allowedOfficeIds = permissions
    .filter(p =>
      p.inherited === false
      && p.roleType === 'Office'
      && p.scopes.some(scope => ['assigning:manage', 'assigning:assign'].includes(scope))
    )
    .flatMap(p => p.officeIds)

  let officeId;
  if (!canSelectAnyOffice && allowedOfficeIds.length === 1) {
    officeId = allowedOfficeIds[0];
  }

  const actions = [
    <TransactionsExporter target="participantId" showSummary />
  ]

  const initialValues = { officeId, participantId: participant?.id }
  return <ListCard
    title="resources.officialTransactions.name"
    icon={<LocalAtm />}
    reference="officialTransactions"
    target="participantId"
    sort={{ field: ['game.date', 'game.number'], order: ['DESC', 'ASC'] }}
    actions={actions}
    filter={{ 'game.seasonId': seasonId }}
    addButton={canAddTransactions && <AddTransactionButton
      initialValues={initialValues}
      officeIds={!canSelectAnyOffice ? allowedOfficeIds : undefined}
      target="participantId"
    />}
    collapsable
    {...props}
  >
    <TransactionsGrid hideParticipant />
  </ListCard>
}
