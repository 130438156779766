import React from 'react';
import { SimpleForm, TextInput, NumberInput, SaveButton, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';

import { PENALTY_SEVERITY } from '@hisports/scoresheet/src/constants';

import Toolbar from "../../../common/ra/Toolbar";
import SwitchInput from '../../../common/inputs/SwitchInput';
import { EnumInput } from '../../../common/inputs/EnumInputs';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}


const validate = (values, translate) => {
  const errors = {};

  if (!values.name || !values.name.length) {
    errors.name = 'ra.validation.required';
  } else if (values.name.length > 48) {
    errors.name = translate('ra.validation.longer_than', { smart_count: 48, current_length: values.name.length })
  }

  if (values.abbreviation && values.abbreviation.length > 4) {
    errors.abbreviation = translate('ra.validation.longer_than', { smart_count: 4, current_length: values.abbreviation.length })
  }

  if (values.description && values.description.length > 20) {
    errors.description = translate('ra.validation.longer_than', { smart_count: 20, current_length: values.description.length })
  }

  return errors;
}

const NameInput = props => {
  const { values } = useFormState();
  const disabled = !!values.id;

  return <TextInput disabled={disabled} {...props} />
}

const SeverityEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: PENALTY_SEVERITY.LOW, name: 'resources.rulebooks.values.severity.low' },
    { id: PENALTY_SEVERITY.MEDIUM, name: 'resources.rulebooks.values.severity.medium' },
    { id: PENALTY_SEVERITY.HIGH, name: 'resources.rulebooks.values.severity.high' },
    { id: PENALTY_SEVERITY.SEVERE, name: 'resources.rulebooks.values.severity.severe' },
  ]} />

export const DurationForm = props => {
  const translate = useTranslate();

  const toolbar = <Toolbar>
    <SaveButton />
  </Toolbar>

  return <SimpleForm toolbar={toolbar} validate={(values) => validate(values, translate)} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <NameInput source="name" autoFocus helperText="resources.rulebooks.helpers.name" {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <TextInput source="description" {...inputProps} />
      </Grid>

      <Grid item xs={12} md={6}>
        <NumberInput source="length" defaultValue={0} {...inputProps} />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextInput source="abbreviation" helperText="resources.rulebooks.helpers.abbreviation" {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <SeverityEnumInput source="severity" {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <SwitchInput source="requireIncidentReport" helperText="resources.rulebooks.helpers.requireIncidentReport" {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <SwitchInput source="timed" helperText="resources.rulebooks.helpers.timed" {...inputProps} />
      </Grid>

    </Grid>
  </SimpleForm>
}
