import React, { useState } from 'react';
import { Button, useListContext, useNotify, useRefresh, useTranslate } from 'react-admin';
import { apiClient } from '../../http';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as MuiButton } from '@material-ui/core';

const ProcessClaimDialog = ({ status, isOpen, onSubmit, onClose, selectedIds = [] }) => {
  const translate = useTranslate();
  let actionI18n = status === 'Approved' ? 'ra.action.approve' : 'ra.action.decline';
  actionI18n = status === 'Pending' ? 'ra.action.pend' : actionI18n;

  return <Dialog open={isOpen} onClose={onClose} maxWidth="medium">
    <DialogTitle>{translate(`resources.officialClaims.labels.${status}`, selectedIds.length || 1)}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate(`resources.officialClaims.helpers.${status}`)}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <MuiButton onClick={onClose}>{translate('ra.action.cancel')}</MuiButton>
      <MuiButton onClick={onSubmit} color="primary">{translate(actionI18n, selectedIds.length || 1)}</MuiButton>
    </DialogActions>
  </Dialog>
}

const ProcessClaim = ({ status, selectedIds = [], resource, ...props }) => {
  const translate = useTranslate();
  const { onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [ open, setOpen ] = useState(false);

  const onSubmit = async values => {
    apiClient('/officialClaims/process', {
      method: 'POST',
      data: {
        claimIds: selectedIds,
        status,
      }
    })
      .then(({ data }) => {
        refresh();
        onUnselectItems();
        notify(translate(`resources.officialClaims.notifications.${status}`, selectedIds.length || 1));
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate(`resources.officialClaims.notifications.${status.toLowerCase()}_error`, selectedIds.length || 1))
      })

    setOpen(false)
  }

  return <>
    <Button label={`resources.officialClaims.actions.${status}`} onClick={() => setOpen(true)} {...props} />
    <ProcessClaimDialog status={status} isOpen={open} onSubmit={onSubmit} onClose={() => setOpen(false)} selectedIds={selectedIds} />
  </>
}

export const ClaimBulkAction = props =>
  <>
    <ProcessClaim status="Pending" {...props} />
    <ProcessClaim status="Declined" {...props} />
    <ProcessClaim status="Approved" {...props} />
  </>
