import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { TimezoneEnumInput } from '../../common/inputs/EnumInputs';
import LogoInput from '../../common/inputs/LogoInput'
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';

import { OfficeInput } from '../offices/OfficeInput';

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = 'ra.validation.required';
  if (!values.type) errors.type = 'ra.validation.required';
  if (!values.timezone) errors.timezone = 'ra.validation.required';
  if (!values.parentId) errors.parentId = 'ra.validation.required';
  if (values.id && (values.id === values.parentId)) errors.parentId = 'resources.offices.validations.same_office';

  return errors;
}

const inputProps = {
  resource: 'offices',
  basePath: '/offices',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const NameInput = props => {
  const { values } = useFormState()
  const { name } = values;

  const hasDivision = name && name.match(/U\d+|Initiation|Novice|Atom|PeeWee|Bantam|Midget|Juvenile/i)
  const helperText = hasDivision ? "resources.offices.helpers.name" : null;

  return <TextInput helperText={helperText} {...props} />
}

export const CupForm = ({ hideType = false, initialValues, ...props }) => {
  const [ isSynced, toggle ] = useRecordSynced(props);

  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm toolbar={<Toolbar isSynced={isSynced} />} validate={validate} initialValues={{ ...initialValues, type: 'Cup' }} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12}>
          <NameInput source="name" autoFocus disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={7}>
          <OfficeInput source="parentId" filter={{ type: { nin: ['League', 'Cup', 'Zone'] }, _scope: 'Tenant' }} disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={5}>
          <TimezoneEnumInput source="timezone" {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <LogoInput source="logo" label="Logo" {...inputProps} />
        </Grid>
      </Grid>
    </SimpleForm>
  </>
}
