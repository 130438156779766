import { Typography, ListItemText, Box, Tooltip, Chip } from '@material-ui/core';
import React from 'react';
import { Link, ReferenceField, useTranslate } from 'react-admin';
import moment from 'moment-timezone';
import { isEmpty } from "lodash";

import { getInfractionType, getInfractionRule } from '@hisports/scoresheet/src/util';
import { FF_HIDE_INFRACTION_NAME } from '@hisports/common/featureFlags';
import { isGameCompleted } from "@hisports/scoresheet/src/selectors";

import { useFlag } from '../../../http';
import { ScheduleField } from '../../schedules/ScheduleField';
import { TeamField } from '../../teams/TeamField';
import { OfficeField } from '../../offices/OfficeField';

import { useMeta, useScoresheet } from '../ScoresheetContext';

const AdminSuspension = ({ suspension: validationSuspension }) => {
  const translate = useTranslate()
  const startDate = moment.utc(validationSuspension.startDate, 'YYYY-MM-DD').format('LL');
  const endDate = moment.utc(validationSuspension.endDate, 'YYYY-MM-DD').format('LL');
  const primary = validationSuspension.endDate ? `${startDate} - ${endDate}` : startDate;
  const secondary = translate('resources.offices.values.type.Administrative');

  return <ListItemText primary={primary} secondary={secondary} />
}

const GameOfficeField = ({ ...props }) => {
  return <ReferenceField basePath="/games" resource="games" reference="games" {...props}>
    <OfficeField source="officeId" includeType="inline" />
  </ReferenceField>
}

const GameSuspensions = ({ suspension: validationSuspension }) => {
  const translate = useTranslate();
  const { meta, game } = useMeta();
  const isEnabled = useFlag();

  if (!validationSuspension || !validationSuspension.game) return null

  const number = `${translate('resources.games.name', 1)} ${validationSuspension.game.number}`
  const date = moment.utc(validationSuspension.game.date, 'YYYY-MM-DD').format('LL');
  const type = getInfractionType(validationSuspension.penalty, meta.infractions, meta.types, game.seasonId);
  const rule = getInfractionRule(validationSuspension.penalty, meta.infractions, meta.rules, game.seasonId);
  const penalty = type && `${type.name}${!isEnabled(FF_HIDE_INFRACTION_NAME) && rule ? ` - ${rule.name}` : ''}`
  const positionGroup = translate(`resources.suspensions.values.positionGroup.${validationSuspension.positionGroup}`, { _: validationSuspension.positionGroup })
  const code = translate(`resources.suspensions.values.code.${validationSuspension.code || 'supplemental_long'}`, { _: validationSuspension.code })

  return <Box>
    <ListItemText
      primary={<Typography variant="body2">{code} <Typography variant="inherit" color="textSecondary">— { positionGroup }</Typography></Typography>}
      secondary={<Box display="flex" alignItems="center"><TeamField record={{ teamId: validationSuspension.teamId }} source="teamId" link={false} />&nbsp;—&nbsp;<ScheduleField source="id" record={{ id: validationSuspension.game.scheduleId }} link={false} /></Box>}
    />
    <ListItemText
      primary={<Typography variant="body2"><Link to={`/games/${validationSuspension.game.id}/show/scoresheet`}>{number} <Typography variant="inherit" color="textSecondary">— {date}</Typography></Link></Typography>}
      secondary={penalty}
    />
    <ListItemText
      primary={<GameOfficeField source="id" record={{ id: validationSuspension.game.id }} />}
    />
  </Box>
}

const SuspensionDetails = ({ validationGameSuspension }) => {
  const translate = useTranslate();
  const { meta, game } = useMeta();
  const isEnabled = useFlag();

  if (!validationGameSuspension || !validationGameSuspension.game || !validationGameSuspension.penalty) return null

  const number = `${translate('resources.games.name', 1)} ${validationGameSuspension.game.number}`
  const date = moment.utc(validationGameSuspension.game.date, 'YYYY-MM-DD').format('LL');
  const type = getInfractionType(validationGameSuspension.penalty, meta.infractions, meta.types, game.seasonId);
  const rule = getInfractionRule(validationGameSuspension.penalty, meta.infractions, meta.rules, game.seasonId);
  const penalty = type && `${type.name}${!isEnabled(FF_HIDE_INFRACTION_NAME) && rule ? ` - ${rule.name}` : ''}`
  const positionGroup = translate(`resources.suspensions.values.positionGroup.${validationGameSuspension.positionGroup}`, { _: validationGameSuspension.positionGroup })
  const code = translate(`resources.suspensions.values.code.${validationGameSuspension.code || 'supplemental_long'}`, { _: validationGameSuspension.code })

  return <span>
    {code} - {positionGroup}<br />
    {penalty && <><span style={{ fontStyle: 'italic' }}>{penalty}</span><br /></>}<br />
    {`${number} (${date})`}<br />
    <TeamField record={{ teamId: validationGameSuspension.teamId }} source="teamId" link={false} variant="inherit" /> - <ScheduleField source="id" record={{ id: validationGameSuspension.game.scheduleId }} link={false} variant="inherit" /><br />
  </span>
}

export const SuspensionInfo = ({ suspension: validationSuspension }) => {
  if (!validationSuspension) return null;
  if (validationSuspension.type === 'Admin') return <AdminSuspension suspension={validationSuspension} />;
  return <GameSuspensions suspension={validationSuspension} />;
}

export const SuspensionStatus = ({ selected, suspension: scoreSheetSuspension, memberValidation, ...props }) => {
  const validationGameSuspension = memberValidation?.suspensions?.find(({ id }) => scoreSheetSuspension?.id === id)

  return <Tooltip title={validationGameSuspension ? <SuspensionDetails validationGameSuspension={validationGameSuspension} /> : ''} arrow>
    <Chip size="small" label={<SuspensionTitle selected={selected} suspension={scoreSheetSuspension} memberValidation={memberValidation} />} variant="outlined" color="primary" {...props} />
  </Tooltip>
};

export const SuspensionTitle = ({ selected, suspension: scoresheetSuspension, memberValidation }) => {
  const translate = useTranslate();
  const isCompleted = useScoresheet(scoresheet => isGameCompleted(scoresheet));
  const adminSuspension = memberValidation?.suspensions?.find(({ type }) => type === 'Admin');

  let status = 'not_serving';
  let count;

  if (selected && isEmpty(scoresheetSuspension)) {
    status = 'playing';
    if (isCompleted) {
      status = 'played';
    }
  } else if (adminSuspension) {
    status = 'admin';
    count = adminSuspension?.endDate ? adminSuspension?.endDate : translate('resources.suspensions.labels.indefinite');
  } else if (selected && scoresheetSuspension.id && !scoresheetSuspension.purgeId) {
    status = undefined;
  } else if (selected && !scoresheetSuspension.id && !scoresheetSuspension.purgeId && scoresheetSuspension.game != null && scoresheetSuspension.total != null) {
    status = 'manual';
  } else if (selected) {
    status = 'purging';
    if (isCompleted) {
      status = 'purged';
    }
  }

  if (scoresheetSuspension?.game) {
    count = `${scoresheetSuspension.game}/${scoresheetSuspension?.total || '∞'}`;
  }

  let label = `${translate('resources.suspensions.labels.suspended')}`;
  if (status) {
    label += ` - ${translate(`resources.suspensions.labels.${status}`)}`;
  }
  if (count) {
    label += ` (${count})`;
  }
  return label;
};

