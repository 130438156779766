import React, { Fragment, useMemo, useState } from "react";
import { RecordContextProvider, ResourceContextProvider, SimpleForm, useNotify, useRefresh, useTranslate } from "react-admin";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from "@material-ui/core";
import { PersonAddOutlined } from "@material-ui/icons";

import { apiClient } from "../../http";
import { DialogFormToolbar } from "../../common/dialogs/DialogForm";
import { SelectableListView } from "../../common/dialogs/SelectableListView";
import { DateInput } from "../../common/inputs/DateInput";
import { useSeason } from "../../common/inputs/SeasonSelector";
import { EventViewSettingsContext, EventViewSettingsMenu, GAME_LIST_FILTERS, useEventSort, useReverseHomeAway } from "../events/EventViewSettings";
import { BaseEventView } from "../events/EventView";
import { TeamInput } from "../teams/TeamInput";

const inputProps = {
  variant: 'outlined',
}

export const gameFilter = (office, schedule) => {
  const isLeague = ['League', 'Tournament', 'Cup'].includes(office?.type)
  const filter = {}

  if (office && isLeague) {
    filter.scheduleOffices = office.id
  } else if (office && !isLeague) {
    filter.or = [{ scheduleOffices: office.id }, { homeTeamOffices: office.id }]
  } else if (schedule) {
    filter.scheduleId = schedule.id
  }

  return filter;
}


const validate = ({ selectedIds = [] }) => {
  const errors = {}

  if (!selectedIds.length) {
    errors.selectedIds = 'ra.validation.required'
  }

  return errors
}

const EventViewSettingsProvider = ({ ...props }) => {
  const groupDates = useState(true);
  const groupArenas = useState(false);
  const groupRounds = useState(false);
  const assignments = useState(false);
  const gameInfo = useState(false);
  const flags = useState(false);
  const reverseHomeAway = useReverseHomeAway()
  const calendar = useState(false);
  const teamEvents = useState(false);
  const slots = useState(true);
  const awaySlots = useState(true);
  const teamNames = useState(false);
  const availabilities = useState(false);
  const surfaceSizes = useState([]);
  const gameListFilter = useState(GAME_LIST_FILTERS.ALL_GAMES);
  const value = useMemo(() => ({
    calendar,
    teamEvents,
    slots,
    awaySlots,
    teamNames,
    availabilities,
    gameInfo,
    flags,
    reverseHomeAway,
    groupDates,
    groupArenas,
    groupRounds,
    assignments,
    surfaceSizes,
    gameListFilter
  }), [ calendar, teamEvents, slots, awaySlots, availabilities, gameInfo, flags, reverseHomeAway, groupDates, groupArenas, groupRounds, assignments, surfaceSizes, gameListFilter, teamNames ])
  return <EventViewSettingsContext.Provider value={value} {...props} />
}

const ShareDraftGameForm = ({ office, schedule, ...props }) => {
  const localSort = useEventSort();
  const seasonId = useSeason();

  const filters = [
    <TeamInput source="teamId" label="resources.teams.fields.teamId" filter={{ scheduleId: schedule?.id, seasonId: schedule?.seasonId }} multiple allowEmpty alwaysOn {...inputProps} />,
    <DateInput source="startTime" label="resources.draftGames.filters.startTime" alwaysOn {...inputProps} />,
    <DateInput source="endTime" label="resources.draftGames.filters.endTime" alwaysOn {...inputProps} />
  ]
  const actions = [
    <EventViewSettingsMenu
      disableCalendar
      disableAssignments
      disableGroupRounds={false}
      disableGroupArenas={false}
      alwaysOn
      showTeamEvents={false}
      showSlots={false}
      showAwaySlots={false}
      showTeamNames={false}
      showAvailabilties={false}
      showSurfaceSizes={false}
      showFlagsInput={false}
      showRenumber={false}
      showGameInfoInput={false}
    />
  ]

  // query only non shared draft games
  const filter = { ...gameFilter(office, schedule), seasonId, pastEvents: true, isShared: false, isPublished: false }

  // filter out draft games with unallocated teams from the list
  const localFilterCallback = draftGame => {
    return draftGame.homeTeamId && draftGame.awayTeamId
  }

  return <SimpleForm {...props}>
    <SelectableListView
      filter={filter}
      localSort={localSort}
      localFilterCallback={localFilterCallback}
      filters={filters}
      actions={actions}
      initialSelectAll
    >
      <BaseEventView
        rowClick="toggleSelection"
        isRowSelectable={() => true}
        hasBulkActions
        disableCalendar
        disableAssignments
        hideStatus
        component={Fragment}
      />
    </SelectableListView>
  </SimpleForm>
}

export const ShareDraftGameDialog = ({ isOpen, onClose, office, schedule, ...props }) => {
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const refresh = useRefresh();
  const notify = useNotify();

  const onSubmit = ({ selectedIds } = {}) => {
    const url = office ? `/offices/${office.id}/share` : `/schedules/${schedule.id}/share`;
    apiClient(url, {
      method: 'POST',
      data: {
        draftGameIds: selectedIds,
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        refresh();
        notify(translate('resources.draftGames.notifications.shared_games', count))
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate('resources.draftGames.notifications.shared_games_error', selectedIds.length))
      })
    onClose();
  }

  return <Dialog open={isOpen} fullWidth maxWidth="md" fullScreen={fullScreen} onClose={onClose}>
    <DialogTitle>{translate('resources.draftGames.labels.share')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.draftGames.messages.share_help')}
      </DialogContentText>
      <ResourceContextProvider value="draftGames">
        <RecordContextProvider value={null}>
          <EventViewSettingsProvider>
            <ShareDraftGameForm office={office} schedule={schedule} save={onSubmit} component={Fragment} validate={validate} validateOnBlur toolbar={
              <DialogFormToolbar submitLabel="ra.action.share" onCancel={onClose} disableInvalidSubmit />
            } />
          </EventViewSettingsProvider>
        </RecordContextProvider>
      </ResourceContextProvider>
    </DialogContent>
  </Dialog>
}

export const ShareDraftGameAction = ({ size = 'small', ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const handleClick = () => {
    setOpen(true);
  }

  return <>
    <Button color="primary" size={size} startIcon={<PersonAddOutlined />} onClick={handleClick}>{translate('ra.action.share')}</Button>
    {isOpen && <ShareDraftGameDialog isOpen={isOpen} onClose={() => setOpen(false)} {...props} />}
  </>
}
