import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import Toolbar from '../../common/ra/Toolbar';
import { DateInput } from '../../common/inputs/DateInput';
import CurrencyInput from '../../common/inputs/CurrencyInput';
import { OfficialTransactionTypeInput } from '../../common/inputs/EnumInputs';
import { GameInput } from '../games/GameInput';
import { ParticipantInput } from '../participants/ParticipantInput';
import { PayPeriodInput } from '../payPeriods/PayPeriodInput';
import { OfficeInput } from '../offices/OfficeInput';


const validate = values => {
  const errors = {}

  if (!values.gameId) errors.gameId = 'ra.validation.required';
  if (!values.participantId) errors.participantId = 'ra.validation.required';
  if (!values.officeId) errors.officeId = 'ra.validation.required';
  if (!values.date) errors.date = 'ra.validation.required';
  if (!values.type) errors.type = 'ra.validation.required';
  if ((!values.amount || isNaN(values.amount)) && values.amount !== 0) errors.amount = 'ra.validation.required';

  if (values.amount && !isNaN(values.amount) && values.type) {
    if (values.type === 'Withhold' && values.amount > 0) {
      errors.amount = 'ra.validation.negative'
    }
    if (values.type !== 'Withhold' && values.amount < 0) {
      errors.amount = 'ra.validation.positive'
    }
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const OfficialGameInput = props => {
  const { values } = useFormState()
  const { participantId, officeId } = values || {};

  const filter = {}
  if (participantId) {
    filter["officials.participantId"] = participantId
  }
  if (officeId) {
    filter["officials.officeId"] = officeId
  }

  return <GameInput filter={filter} {...props} />
}

const OfficialInput = props => {
  const { values } = useFormState()
  const { gameId } = values || {};

  const filter = {}
  if (gameId) {
    filter["officialGames.gameId"] = gameId,
    filter["officialGames.status"] = "confirmed"
  }

  return <ParticipantInput disabled={!gameId} filter={filter} {...props} />
}

const AmountInput = props => {
  const { values } = useFormState();
  const { type } = values || {};

  const helperText = type === 'Withhold' ? 'ra.validation.negative' : undefined;

  return <CurrencyInput nullable disabled={!type} helperText={helperText} {...props} />
}

const TransactionPayPeriodInput = props => {
  const { values, initialValues } = useFormState();
  const payPeriods = useSelector(state => state.admin.resources.payPeriods.data);

  const payPeriod = payPeriods?.[values?.payPeriodId];

  return <PayPeriodInput disabled={!!initialValues?.payPeriodId || !values.officeId || payPeriod?.isClosed} filter={{ officeId: values.officeId, isClosed: false }} {...props} />
}

export const TransactionForm = ({ initialValues, officeIds, ...props }) =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} initialValues={initialValues} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} sm={6}>
        <OfficeInput
          source="officeId"
          label="resources.officialTransactions.fields.officeId"
          disabled={!officeIds && !!initialValues?.officeId}
          filter={{
            type: { nin: ['League', 'Tournament', 'Cup'] },
            id: officeIds ? { inq: officeIds } : undefined,
          }}
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TransactionPayPeriodInput source="payPeriodId" label="resources.officialTransactions.fields.payPeriodId" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <OfficialGameInput source="gameId" label="resources.officialTransactions.fields.gameId" disabled={!!initialValues?.gameId} {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <OfficialInput source="participantId" label="resources.officialTransactions.fields.participantId" disabled={!!initialValues?.participantId} {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <OfficialTransactionTypeInput source="type" label="resources.officialTransactions.fields.type" hideBase {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateInput source="date" label="resources.officialTransactions.fields.date" initialValue={moment.utc().format('YYYY-MM-DD')} {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AmountInput source="amount" label="resources.officialTransactions.fields.amount" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="description" label="resources.officialTransactions.fields.description" helperText="ra.message.optional" multiline rows={3} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
