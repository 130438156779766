import React, { Fragment, useState } from 'react'
import { SimpleForm, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { Dialog, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText } from '@material-ui/core';
import { Restore } from '@material-ui/icons';

import { apiClient } from '../../http';

import { ActionMenuItem } from '../../common/ActionMenu';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import CheckboxInput from '../../common/inputs/CheckboxInput';
import { AssignRuleTypeEnumInput } from '../../common/inputs/EnumInputs';
import { FieldDependency } from '../../common/FieldDependency';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const validate = values => {
  const errors = {};
  return errors;
}

const ResetForm = (props) => {
  const translate = useTranslate();
  const types = ['systemId', 'feesId', "minReferee", "minLinesperson", "minOfficial", "minScorekeeper", "minTimekeeper", "minRefereeGrade", "minLinespersonGrade", "minAge", "status"]

  return <SimpleForm validate={validate} {...props} {...inputProps}>
    <CheckboxInput source="resetAssigner" label="resources.games.fields.resetAssigner" helperText="resources.games.helpers.reset_assigner" {...props} {...inputProps} />
    <FieldDependency fieldSource="resetAssigner" test={reset => !reset}>
      <AssignRuleTypeEnumInput source="rules" types={types} label={translate("resources.assignRules.labels.applied_settings", 2)} helperText="resources.assignRules.helpers.applied_settings" multiple {...props} {...inputProps} />
    </FieldDependency>
  </SimpleForm>
}

const ResetAssignRulesModal = ({ isOpen, handleClose, ...props }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const game = useRecordContext();

  const onSubmit = async ({ resetAssigner, rules }) => {
    await apiClient(`/games/${game.id}/applyAssignRules`, {
      method: 'POST',
      data: {
        resetAssigner,
        rules: !resetAssigner ? rules : undefined,
      }
    }).then(() => {
      handleClose();
      refresh();
      notify('ra.message.updated');
    }).catch(err => {
      handleClose();
      notify('ra.page.error_try_again', 'error')
    })
  }

  return <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.games.actions.applyAssignRules')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.games.messages.apply_assign_rules')}
      </DialogContentText>
      <ResetForm save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.apply" onCancel={handleClose} disableInvalidSubmit />
      } />
    </DialogContent>
  </Dialog>
}

export default ({ handleClose }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const handleClick = () => {
    handleClose();
    setOpen(true);
  }
  const handleSubmit = () => {
    setOpen(false);
  }

  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><Restore fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.games.actions.applyAssignRules')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <ResetAssignRulesModal isOpen={isOpen} handleClose={handleSubmit} />}
  </>
}
