import React from 'react';
import { Card, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export const ListItemLink = ({ to, onClick, primary, icon }) => {
  const renderLink = React.useMemo(() => {
    if (onClick) return;
    return React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />);
  }, [ to, onClick ]);

  return (
    <li>
      <ListItem button onClick={onClick} component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export default ({ links }) => {
  if (!links || !links.length) return null;
  return <Card>
    <List>{
      links.map(link => <ListItemLink key={link.primary} {...link} />)
    }</List>
  </Card>
}
