import React from 'react';
import moment from 'moment';

import FunctionField from './FunctionField';

const renderUpdatedAt = (record, source, resource, translate, includePrefix = false) => {
  const { updatedAt, updatedByAuthor } = record || {};
  const date = moment(updatedAt).calendar()
  if (!updatedAt) return null;

  const prefix = includePrefix ? `${translate('ra.message.updated')} ` : ''
  if (!updatedByAuthor) return `${prefix}${date}`;
  return `${prefix}${date} ${translate('ra.function.by')} ${updatedByAuthor}`;
}

export const UpdatedAtField = ({ includePrefix = false, ...props }) => {
  return <FunctionField source="updatedAt" render={(...params) => renderUpdatedAt(...params, includePrefix)} {...props} />
}
