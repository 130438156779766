import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Divider, Grid, Typography, makeStyles, styled } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';

import { getForfeit, getScore } from '@hisports/scoresheet/src/selectors';

import { TeamField } from '../../teams/TeamField';
import { useSport } from '../../../http';
import { useIsHomeFirst } from '../../events/EventViewSettings';

import { useMeta, useScoresheet } from '../ScoresheetContext';

const Home = styled(HomeIcon)(({ theme }) => ({
  fontSize: '90%',
  color: theme.palette.grey[500],
  verticalAlign: 'middle',
}))

const useStyles = makeStyles(theme => ({
  grid: {
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  teamName: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  }
}))

const Score = ({ teamId, score, ...props }) => {
  const translate = useTranslate();
  const game = useRecordContext();
  const forfeit = useScoresheet(scoresheet => getForfeit(scoresheet));
  const classes = useStyles();
  const isForfeited = forfeit === teamId || forfeit == -1;
  const isHome = game.homeTeamId === teamId;

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <div className={classes.teamName}>
        {isHome && <Home />} <TeamField source="teamId" record={{ teamId }} link={false} />
      </div>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h1">{score}</Typography>
    </Grid>
    {isForfeited && <Grid item xs={12}>
      <Typography variant="body1">{translate('resources.games.values.status.Forfeited')}</Typography>
    </Grid>}
  </Grid>
}

export const Scoreboard = ({ ...props }) => {
  const game = useRecordContext();
  const classes = useStyles();
  const isHomeFirst = useIsHomeFirst();
  const sport = useSport();
  const { meta } = useMeta();
  const { maxScoreDiff, scoringMode } = meta?.policies || {};
  const homeScore = useScoresheet(scoresheet => getScore(scoresheet, sport, { teamId: game.homeTeamId, maxScoreDiff, scoringMode }));
  const awayScore = useScoresheet(scoresheet => getScore(scoresheet, sport, { teamId: game.awayTeamId, maxScoreDiff, scoringMode }));

  const scores = isHomeFirst ? [homeScore, awayScore] : [awayScore, homeScore]
  const teamIds = isHomeFirst ? [game?.homeTeamId, game?.awayTeamId] : [game?.awayTeamId, game?.homeTeamId]

  return <Grid container spacing={2} className={classes.grid} fullWidth >
    <Grid item xs={5}>
      <Score teamId={teamIds[0]} score={scores[0]} />
    </Grid>
    <Grid item xs={2} container direction="row" justifyContent="center" alignItems="center">
      <Divider orientation="vertical" style={{ height: '70%', width: '1px' }} />
    </Grid>
    <Grid item xs={5}>
      <Score teamId={teamIds[1]} score={scores[1]} />
    </Grid>
  </Grid>
}
