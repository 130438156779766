import React, { Fragment, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Tooltip, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import { Clear } from '@material-ui/icons';
import moment from 'moment-timezone';

import { apiClient } from '../../../../../../http';
import { GameDetailsField } from '../../../../../games/GameDetailsField';

const DECLINE_AVAILABLE = -1;
const DECLINE_UNAVAILABLE = 0;
const REPEATING_REGEX = /([^a-zA-Z0-9])\1{1,}/;
const MIN_REASON_LENGTH = 15;

const respond = (gameId, accepted, notes, isAvailable) =>
  apiClient(`/games/${gameId}/respondAssignment`, {
    method: 'POST',
    data: { accepted, notes, isAvailable },
  })

const getIds = (official, flag) => {
  const meta = official.meta[flag];
  if (!meta) return [];
  return meta.ids || [];
}

const ConfirmDialog = ({ assignment, official, settings, disabled, open, onClose }) => {
  const translate = useTranslate();
  const isUnavailable = official.flags.includes('Unavailable') || official.flags.includes('Availability');
  const conflicts = getIds(official, 'Game Conflict'); // accepted games, require manual decline first
  const overlaps = getIds(official, 'Game Overlap'); // unconfirmed games
  const consecutives = getIds(official, 'Consecutive');
  const hasConflicts = conflicts.length > 0;
  const hasOverlaps = overlaps.length > 0;
  const hasConsecutives = consecutives.length > 0 && settings.enforceConsecutive;

  const [ option, setOption ] = useState(isUnavailable ? DECLINE_UNAVAILABLE : DECLINE_AVAILABLE);
  const [ reason, setReason ] = useState('');

  const handleCancel = () => onClose(false);
  const handleConfirm = () => onClose(true, reason, option);
  const handleOption = event => setOption(Number(event.target.value));
  const handleReason = event => setReason(event.target.value)

  const acceptingOther = option > 0;
  const reasonRequired = settings.declineReason;
  const reasonLength = reason ? reason.replace(REPEATING_REGEX, '$1').length : 0; // counts repeating characters as 1
  const reasonValid = !reasonRequired || reasonLength >= MIN_REASON_LENGTH;
  const isValid = option !== null && (acceptingOther || reasonValid)

  let label = translate('resources.games.messages.assignment.reason_for_declining');
  let helperText;

  if (acceptingOther) {
    label = translate('resources.games.labels.notes', 1)
    helperText = translate('resources.games.helpers.assignment.note_for_accepted_game')
  } else if (reasonRequired) {
    if (reasonLength > 0 && reasonLength < MIN_REASON_LENGTH) {
      const remaining = MIN_REASON_LENGTH - reasonLength
      helperText = `${translate('ra.validation.required')} - ${translate('ra.validation.more_characters', remaining)}`
    } else {
      helperText = translate('ra.validation.required')
    }
  }

  const assignments = overlaps.length === 1
    ? translate('resources.games.messages.assignment.both_assignments')
    : translate('resources.games.messages.assignment.assignments', overlaps.length + 1)

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.games.labels.assignment.decline')}</DialogTitle>
    <DialogContent>
      {disabled && <DialogContentText>
        {translate('resources.games.messages.assignment.contact_assigner')}
      </DialogContentText>}

      {!disabled && <>
        <DialogContentText>{translate('resources.games.messages.assignment.decline_assignment')}</DialogContentText>

        {!hasConflicts && hasOverlaps && <DialogContentText>{translate('resources.games.messages.assignment.other_assignments')}</DialogContentText>}
        {!hasConflicts && <FormControl>
          <RadioGroup value={option} onChange={handleOption}>
            <FormControlLabel value={DECLINE_UNAVAILABLE} control={<Radio />} label={translate('resources.games.messages.assignment.decline_as_unavailable', { assignments })} />
            <FormControlLabel value={DECLINE_AVAILABLE} control={<Radio />} label={translate('resources.games.messages.assignment.decline_as_available', { assignments })} />
            {hasOverlaps && <>
              <br />
              <DialogContentText>{translate('resources.games.messages.assignment.accept_another_assignment')}:</DialogContentText>
              {overlaps.map(id =>
                <FormControlLabel value={id} control={<Radio />} label={<GameDetailsField record={{ id }} source="id" />} />
              )}
            </>}
          </RadioGroup>
        </FormControl>}
        {!hasConflicts && hasConsecutives && !acceptingOther && <DialogContentText>
          <br />
          {translate('resources.games.messages.assignment.decline_consecutive_assignments')}
          <ul>
            {consecutives.map(id => <li><GameDetailsField record={{ id }} source="id" /></li>)}
          </ul>
        </DialogContentText>}

        <TextField
          margin="dense"
          fullWidth
          multiline
          rows={3}
          label={label}
          helperText={helperText}
          value={reason}
          onChange={handleReason}
        />
      </>}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary" disabled={disabled || !isValid}>{!acceptingOther ? translate('ra.action.decline') : translate('ra.action.accept_other')}</Button>
    </DialogActions>
  </Dialog>
}

export default ({ game, assignment, official, settings, disabled, onClose }) => {
  const [ declined, setDeclined ] = useState(false);
  const [ showConfirm, setConfirm ] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();

  if (!game || !assignment || !official || !settings) return null;

  const hours = moment.tz(game.startTime, game.timezone).diff(moment(), 'hours', true)
  const declineAllowed = settings.declineEnabled && (settings.declineHours == null || hours >= settings.declineHours) && !(settings.declineConfirmedDisabled && assignment.status === 'confirmed');

  const handleConfirm = (confirmed, notes, option) => {
    setConfirm(false);
    if (!confirmed) return;

    // options 0 and -1 decline this game, otherwise (>0) game id to accept instead
    const acceptingOther = option > 0;
    const gameId = acceptingOther ? option : game.id;
    const isAvailable = option !== DECLINE_UNAVAILABLE;
    setDeclined(true)
    respond(gameId, acceptingOther, notes, isAvailable).then(res => {
      onClose(assignment, official.participantId)
      notify(translate('resources.games.notifications.assignment.declined_game', { number: game.number }), 'success')
    }).catch(err => {
      setDeclined(false)
      const errorName = err?.response?.data?.error?.name;
      const errorMessage = errorName && translate(`resources.games.notifications.assignment.errors.${errorName}`);
      notify(errorMessage || 'ra.page.error_try_again', 'error')
    })
  }

  return <>
    <Tooltip title={translate('resources.games.messages.assignment.decline_game_assignment')}>
      <Button
        color="secondary"
        startIcon={<Clear fontSize="small" />}
        disabled={disabled || declined}
        onClick={() => setConfirm(true)}
      >
        {translate('ra.action.decline')}
      </Button>
    </Tooltip>
    <ConfirmDialog assignment={assignment} official={official} settings={settings} disabled={!declineAllowed} open={showConfirm} onClose={handleConfirm} />
  </>
}
