import React from 'react';
import { TextField, EditButton, useRecordContext } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import { getSeasonDisplayName } from '@hisports/common/src/seasons';

import InfoCard from '../../../common/cards/InfoCard';
import Row from '../../../common/cards/TableRow';
import DateRangeField from '../../../common/fields/DateRangeField';
import FunctionField from '../../../common/fields/FunctionField'
import UserTextField from '../../../common/fields/UserTextField';
import { hasAnyScope, isAuthorized } from '../../../common/Authorize';

import { CategoryField } from '../../categories/CategoryField';
import { OfficeField } from '../../offices/OfficeField';

import { usePermissions } from '../../../http';

const renderType = (schedule, source, resource, translate, sport) => {
  return `${getSeasonDisplayName(schedule.seasonId, sport)} ${translate(`resources.schedules.values.type.${schedule.type}`)}`
}

export default props => {
  const schedule = useRecordContext(props);
  const permissions = usePermissions()
  const isLeague = permissions.some(p =>
    p.roleType === 'System' ||
    (
      hasAnyScope(p.scopes, ['leagues:manage'])
      && p.roleType === 'Office'
      && !p.inherited
      && (p?.officeIds || []).includes(schedule.officeId)
    )
  );
  const canEditSchedule = isAuthorized(schedule, 'schedules', 'edit') && isLeague;

  return <InfoCard title="resources.schedules.name" actions={
    canEditSchedule && <EditButton label="resources.schedules.actions.edit" record={schedule} />
  }>
    <Table>
      <TableBody>
        <Row label="resources.schedules.fields.type">
          <FunctionField render={renderType} />
        </Row>
        <Row label="resources.schedules.labels.dates">
          <DateRangeField startSource="startDate" endSource="endDate" />
        </Row>
        <Row label="resources.schedules.fields.categoryId">
          <CategoryField source="categoryId" includeGender="full" />
        </Row>
        <Row label="resources.schedules.fields.gameFormat" hidden={!schedule?.gameFormat}>
          <TextField source="gameFormat" />
        </Row>
        <Row label="resources.schedules.fields.officeId">
          <OfficeField source="officeId" includeType="full" />
        </Row>
        <Row label="resources.schedules.fields.comments" hidden={!schedule?.comments}>
          <UserTextField source="comments" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
