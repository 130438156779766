import * as React from 'react';
import { useCallback } from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Button, sanitizeListRestProps,
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
  useResourceContext,
  useTranslate,
} from 'react-admin'
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';

// does not currently support nested relations
const parseInclude = _include => Array.isArray(_include) ? _include.map(include => ({ relation: include, scope: { scope: 'Tenant' } })) : (_include || undefined);

const ExportButton = (props) => {
  const {
    maxResults = 1000,
    onClick,
    label = 'ra.action.export',
    icon = <DownloadIcon />,
    exporter: customExporter,
    sort, // deprecated, to be removed in v4
    menuItem,
    ...rest
  } = props;
  const {
    filter,
    filterValues,
    currentSort,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const resource = useResourceContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate()

  const handleClick = useCallback(event => {
    if (!exporter) return;
    const _include = parseInclude(exporter._include);
    dataProvider.getList(resource, {
      sort: currentSort || sort,
      filter: filter
        ? { ...filterValues, ...filter, _include }
        : { ...filterValues, _include },
      pagination: { page: 1, perPage: maxResults },
    })
      .then(({ data }) => {
        return exporter(
          data,
          fetchRelatedRecords(dataProvider),
          dataProvider,
          resource
        )
      })
      .catch(error => {
        console.error(error); /* eslint-disable-line no-console */
        notify('ra.notification.http_error', { type: 'warning' });
      });

    if (typeof onClick === 'function') {
      onClick(event);
    }
  }, [
    currentSort,
    dataProvider,
    exporter,
    filter,
    filterValues,
    maxResults,
    notify,
    onClick,
    resource,
    sort,
  ]);

  if (menuItem) return <MenuItem onClick={handleClick} disabled={total === 0} disableRipple>
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText primary={translate(label)} />
  </MenuItem>

  return <Button
    onClick={handleClick}
    label={label}
    disabled={total === 0}
    {...sanitizeListRestProps(rest)}
  >
    {icon}
  </Button>
};

export default ExportButton
