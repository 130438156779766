import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { v4 as uuid } from 'uuid';

import { PitcherLimitsGrid } from './PitcherLimitsGrid';
import { AddPitcherLimitsButton } from './PitcherLimitsModal';

import { ArrayGrid } from '../ArrayGrid';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const PitcherLimits = ({ source }) => {
  const { values: { pitcherLimits } } = useFormState();
  const { mutators } = useForm();

  const onSave = pitcherLimit => {
    if (!pitcherLimit.id) {
      pitcherLimit.id = uuid();
    }

    const index = pitcherLimits?.findIndex(value => value.id === pitcherLimit.id);
    if (index >= 0) {
      const { id, ...changes } = pitcherLimit;
      const newPitcherLimits = Object.assign({}, pitcherLimits[index], changes);
      mutators.update('pitcherLimits', index, newPitcherLimits)
    } else {
      mutators.push('pitcherLimits', pitcherLimit);
    }
  }

  const onDelete = pitcherLimit => {
    const index = pitcherLimits?.findIndex(value => value.id === pitcherLimit.id);
    mutators.remove('pitcherLimits', index);
  }

  return <>
    <ArrayGrid source={source}>
      <PitcherLimitsGrid
        save={onSave}
        canDelete
        onDelete={onDelete}
        {...inputProps}
      />
    </ArrayGrid>
    <AddPitcherLimitsButton save={onSave} />
  </>
}
