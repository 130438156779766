import React from 'react';
import { Filter, BooleanInput } from 'react-admin';

import { LATEST_SEASON } from '@hisports/common/src/seasons';

import { InlineDateInput } from '../../common/inputs/DateInput';
import { GameStatusEnumInput } from '../../common/inputs/EnumInputs';
import { SeasonFilter, useSeason } from '../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../common/Authorize';
import { useDefaultSeason, useScopes } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';
import { TeamInput } from '../teams/TeamInput';

const multipleProps = {
  multiple: true,
  allowEmpty: false,
}

export const PracticeFilters = props => {
  const scopes = useScopes();
  const seasonId = useSeason()
  const defaultSeason = useDefaultSeason();

  const canListOffices = isAuthorized(scopes, 'offices', 'show');
  const canListTeams = isAuthorized(scopes, 'teams', 'list');
  const canListSurfaces = isAuthorized(scopes, 'surfaces', 'list');
  const showPastSeason = (seasonId === LATEST_SEASON) || (seasonId === defaultSeason);

  return <Filter variant="outlined" {...props}>
    <InlineDateInput source="startTime" label="resources.practices.filters.startTime" alwaysOn />
    <InlineDateInput source="endTime" label="resources.practices.filters.endTime" />
    {canListTeams && <TeamInput source="teamIds" filter={{ seasonId }} {...multipleProps} alwaysOn />}
    {canListOffices && <OfficeInput source="officeId" filter={{ type: { neq: 'Historical' } }} {...multipleProps} alwaysOn />}
    {canListSurfaces && <SurfaceInput source="arenaId" {...multipleProps} />}
    <GameStatusEnumInput source="status" {...multipleProps} />
    {showPastSeason && <BooleanInput source="pastEvents" alwaysOn />}
    <SeasonFilter source="seasonId" alwaysOn />
  </Filter>
}
