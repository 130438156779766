import React from 'react';
import { NumberInput, Pagination, useRecordContext } from 'react-admin';

import { FF_DISABLE_ADD_QUALIFICATIONS } from '@hisports/common/featureFlags';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';
import { useDefaultSeason, useFlag, useScopes } from '../../../http';
import { ParticipantIcon } from '../../icons';

import { QualificationGrid } from '../../qualifications/QualificationGrid';
import { AddQualificationButton } from '../../qualifications/QualificationModal';
import { QualificationCategoryInput } from '../../qualifications/QualificationForm';
import ImportGrades from '../dialogs/grades';
import ImportQualifications from '../dialogs/qualifications';

const expiry = new Date().toISOString();

export default ({ collapsable, ...props }) => {
  const isEnabled = useFlag();
  const scopes = useScopes();
  const office = useRecordContext(props);
  const seasonId = useSeason();
  const defaultSeason = useDefaultSeason();

  const showQualifications = isAuthorized(office, 'qualifications', 'show');
  const canAddQualifications = scopes.includes('internal') || (isAuthorized(office, 'qualifications', 'create') && !isEnabled(FF_DISABLE_ADD_QUALIFICATIONS))
  const canImportGrades = isAuthorized(office, 'qualifications', 'importGrades');
  const canImportQualifications = isAuthorized(office, 'qualifications', 'importQualifications');

  if (!showQualifications) return null;

  const isPast = seasonId !== defaultSeason;
  const filter = isPast
    ? { effectiveOffices: true,
      seasonId,
      qualificationCategory: {
        types: { inq: ['Official', 'Scorekeeper'] } } }
    : {
      effectiveOffices: true,
      qualificationCategory: {
        types: { inq: ['Official', 'Scorekeeper'] } },
      or: [
        { seasonId },
        { expiry: { gte: expiry } },
        { expiry: { eq: null } },
      ]
    }

  const filters = [
    <NumberInput source="level" variant="outlined" alwaysOn />,
    <QualificationCategoryInput source="qualificationCategoryId" filter={{ types: { inq: ['Official', 'Scorekeeper'] } }} variant="outlined" alwaysOn />,
    // <QualificationCategoryTypeInput source="qualificationCategory.types" variant="outlined" alwaysOn multiple />,
  ]

  const addButton = canAddQualifications && <AddQualificationButton initialValues={{ officeId: office?.id }} />
  const actions = [
    canImportQualifications && <ImportQualifications officeId={office?.id} size="small" alwaysOn />,
    canImportGrades && <ImportGrades officeId={office?.id} size="small" />,
  ].filter(Boolean);

  return <ListCard
    title="resources.qualifications.labels.card.title"
    icon={<ParticipantIcon />}
    reference="qualifications"
    target="officeId"
    filter={filter}
    sort={{
      field: ['seasonId', 'participant.lastName', 'participant.firstName', 'level'],
      order: ['DESC', 'ASC', 'ASC', 'ASC'],
    }}
    pagination={<Pagination />}
    filters={filters}
    addButton={addButton}
    actions={actions}
    collapsable={collapsable}
  >
    <QualificationGrid showParticipant rowClick="show" />
  </ListCard>
}
