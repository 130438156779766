import React, { Fragment, useEffect, useState } from 'react';
import { ArrayInput, SimpleForm, useRefresh, useNotify, useTranslate, RecordContextProvider, useDataProvider, useListContext, NumberInput } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Button, makeStyles, DialogContentText } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useFormState } from 'react-final-form';

import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import HorizontalFormIterator, { TransitionProps } from '../../common/ra/HorizontalFormIterator';

import { ScheduleGroupInput } from '../groups/GroupInput';
import { TeamInput } from '../teams/TeamInput';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const useStyles = makeStyles(theme => ({
  form: {
    gridTemplateColumns: `auto ${theme.spacing(12)}px`,
    columnGap: theme.spacing(2)
  }
}))

const hasDuplicateOrder = (order, teamOrders) => {
  const filteredOrders = teamOrders.filter(teamOrder => teamOrder && teamOrder === order);
  return filteredOrders.length > 1;
}

const validate = ({ scheduleTeams = [] }) => {
  const errors = {}

  const orders = scheduleTeams.map(team => team.order);

  errors.scheduleTeams = scheduleTeams.map(team => {
    const teamErrors = {};

    if (!team.order) teamErrors.order = 'ra.validation.required';
    if (!Number.isInteger(team.order) || team.order <= 0) teamErrors.order = 'ra.validation.invalid';
    if (hasDuplicateOrder(team.order, orders)) teamErrors.order = 'ra.validation.duplicate';

    return teamErrors;
  })

  return errors;
}

const EditOrderForm = ({ setGroupId }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values: { groupId } } = useFormState();

  useEffect(() => {
    setGroupId(groupId)
  }, [setGroupId, groupId])

  return <>
    <ScheduleGroupInput source="groupId" showNone={translate('ra.message.no_group')} {...inputProps} />
    <ArrayInput source="scheduleTeams" label="" {...inputProps}>
      <HorizontalFormIterator disableReordering disableRemove disableAdd TransitionProps={TransitionProps} classes={classes}>
        <TeamInput source="teamId" disabled options={{ label: null }} {...inputProps} />
        <NumberInput source="order" label="resources.scheduleteams.fields.order" min={1} {...inputProps} />
      </HorizontalFormIterator>
    </ArrayInput>
  </>
}

export const EditOrderButton = ({ initialValues, size, variant }) => {
  const [ isOpen, setOpen ] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const { filterValues } = useListContext();
  const [ scheduleTeams, setScheduleTeams ] = useState([]);
  const [ groupId, setGroupId ] = useState(null)

  const { scheduleId } = initialValues;

  useEffect(() => {
    dataProvider.getList('scheduleteams', {
      pagination: { page: 1, perPage: 999 },
      filter: { scheduleId },
      sort: { field: 'order', order: 'ASC' } })
      .then(res => res.data)
      .then(teams => setScheduleTeams(teams))
  }, [ dataProvider, scheduleId ])

  const onSubmit = async ({ scheduleTeams: updatedTeams }) => {
    if (!updatedTeams || !updatedTeams.length) {
      setOpen(false);
      return;
    }

    const teams = updatedTeams.filter(({ teamId, groupId, order }) => {
      return scheduleTeams.find(team => team.teamId === teamId && team?.groupId === groupId && team.order !== order)
    });

    return await Promise.all(teams.map(
      data => dataProvider.update("scheduleteams", { id: data.id, data })
    )).then(() => {
      setOpen(false)
      refresh()
    }).catch(error => {
      notify(error.message, 'error');
    })
  }

  if (!scheduleTeams.length) return null;
  const teams = scheduleTeams.filter(team => team.groupId == groupId)

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.scheduleteams.actions.edit')}
    </Button>
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>{translate('resources.scheduleteams.actions.edit')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('resources.scheduleteams.messages.team_order')}
        </DialogContentText>
        <RecordContextProvider value={{ scheduleTeams: teams }}>
          <SimpleForm initialValues={{ ...initialValues, groupId: filterValues?.groupId || null }} validate={validate} save={onSubmit} component={Fragment} toolbar={
            <DialogFormToolbar submitLabel="ra.action.save" cancelLabel="ra.action.cancel" onCancel={() => setOpen(false)} /> }
          {...inputProps}
          >
            <EditOrderForm setGroupId={setGroupId} />
          </SimpleForm>
        </RecordContextProvider>
      </DialogContent>
    </Dialog>
  </>
}
