import React, { Children, cloneElement, forwardRef, memo, useMemo, useRef } from 'react';
import { RecordContextProvider, useListContext, useTranslate } from 'react-admin';
import { Button, Table, TableBody, TableRow, TableCell, TableHead, makeStyles, styled, Tooltip } from '@material-ui/core';
import { CardTravel, Home as HomeIcon, KeyboardArrowDown } from "@material-ui/icons";
import moment from 'moment';

import { TeamField } from '../../../resources/teams/TeamField';
import DateField from '../../fields/DateField';
import { CALENDAR_VIEWS, useCalendarView, useIsHomeFirst, useShowAwaySlots, useShowCondensedView, useShowOfficeName } from '../../../resources/events/EventViewSettings';

import { useCalendarContext } from '../CalendarContext';
import { useSchedulingContext } from '../SchedulingContext';
import { EventDetails } from '../Events';
import { AvailabilityDetailsList } from './MonthGrid';
import { isDraft, isGame } from '../EventDetails';
import { useElementSize } from '../../useElementSize';

const TheadCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'bottom',
}))
const TopLeftCell = styled(TableCell)(({ theme }) => ({
  width: theme.spacing(14),
  padding: theme.spacing(1),
}))
const Home = styled(HomeIcon)(({ theme }) => ({
  fontSize: '90%',
  color: theme.palette.grey[500],
  marginInline: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  verticalAlign: 'middle',
}))

const SeasonView = styled('div')({
  maxHeight: '100vh',
  overflowY: 'auto',
  position: 'relative',
  '& .react-grid-item.react-grid-placeholder': {
    background: 'grey',
  }
})

const useSeasonStyles = makeStyles(theme => ({
  root: {
    tableLayout: 'fixed',
  },
  TeamNameColor: {
    color: 'black',
  },
  thead: {
    lineHeight: 1.5,
    paddingBlock: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBlock: theme.spacing(1),
  },
}))

const useCellStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.divider}`,
    zIndex: 1,
    verticalAlign: 'top',
    borderTop: props => props?.separator ? `4px solid ${theme.palette.divider}` : undefined
  },
  thead: {
    lineHeight: 1.5,
    paddingBlock: theme.spacing(1),
  },
  teamEventCell: {
    userSelect: 'none',
    minHeight: theme.spacing(8),
    padding: theme.spacing(1),
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
  },
  date: {
    cursor: 'pointer',
  },
  dateField: {
    whiteSpace: 'normal',
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  hide: {
    backgroundColor: theme.palette.grey[100],
  },
  tournament: {
    backgroundColor: theme.palette.warning[100],
    fontWeight: 'normal',
    paddingInline: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  tournamentIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    alignSelf: 'center',
    marginTop: 0,
    paddingRight: theme.spacing(0.7),
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  tournamentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  showMoreButton: {
    display: 'flex',
    alignItems: 'center',
    marginInline: 'auto',
    zIndex: 9999, // Fix to avoid grid offset issue
  },
}))

const useScheduledGamesStyles = makeStyles(theme => ({
  scheduledGames: {
    marginTop: 'auto',
    paddingTop: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: ({ isHomeFirst }) => isHomeFirst ? 'row' : 'row-reverse',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(.5),
    fontSize: 12,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(.5),
  },
  icon: {
    fontSize: '1.5em',
    opacity: .8,
  },
  colorWarning: {
    color: theme.palette.warning.dark
  }
}))

const LoadMoreButton = ({ columns }) => {
  const classes = useCellStyles();
  const translate = useTranslate();
  const { dateFilter, setDateFilter, schedule, loading } = useSchedulingContext();

  const scheduleEndDate = schedule?.endDate ? moment(schedule.endDate) : null;
  const dateFilterEndDate = dateFilter?.endDate ? moment(dateFilter.endDate) : null;

  if (!scheduleEndDate || !dateFilterEndDate || dateFilterEndDate.isSameOrAfter(scheduleEndDate, 'day')) return null;

  const handleDateFilter = () => {
    setDateFilter(({ endDate, ...prev }) => {
      const newEnd = moment(endDate).add(1, 'month');
      return {
        ...prev,
        endDate: newEnd.isAfter(scheduleEndDate, 'day') ? scheduleEndDate.toISOString() : newEnd.toISOString(),
        type: CALENDAR_VIEWS.SEASON,
      };
    });
  };

  return <TableCell colSpan={columns?.length + 1}>
    <Button className={classes.showMoreButton} color="primary" size="small" onClick={handleDateFilter} disabled={loading}>
      {translate('ra.action.load_more')}
      <KeyboardArrowDown fontSize="small" />
    </Button>
  </TableCell>
}

const EventCard = ({ event, ...props }) => {
  return <RecordContextProvider value={event}>
    <EventDetails event={event} {...props} />
  </RecordContextProvider>
}

const TeamEventCell = memo(({ date, team, separator }) => {
  const classes = useCellStyles({ separator });
  const { availability, surfaceOfficeId, selectedGame, scheduleTeams } = useSchedulingContext();
  const [ showAwaySlots ] = useShowAwaySlots();

  const teams = (scheduleTeams || []).map(scheduleTeam => scheduleTeam.team);
  const teamOfficeIds = teams.map(team => team.officeId);

  const teamTournaments = availability?.schedules?.filter(
    schedule => schedule.teams.find(scheduleteam => scheduleteam.id == team?.id)
  ) || []

  const isWithinTournamentPeriod = (teamTournament) => {
    const formattedDate = new Date(teamTournament.startDate);
    const tournamentStartDate = formattedDate.toISOString().split('T')[0];
    return (date >= tournamentStartDate && date <= teamTournament.endDate);
  };

  const teamTournamentsPerDay = teamTournaments.filter(tournament => isWithinTournamentPeriod(tournament))

  const games = useMemo(() => {
    return (availability?.games || [])
      .filter(game => game.date == date && [game.homeTeamId, game.awayTeamId].filter(Boolean).includes(team?.id))
  }, [availability?.games, date, team?.id])
  const practices = useMemo(() => {
    return (availability?.practices || [])
      .filter(practice => practice.date == date && practice.teamIds.includes(team?.id))
  }, [availability?.practices, date, team?.id])
  const activities = useMemo(() => {
    return (availability?.activities || [])
      .filter(activity => activity.date == date && activity.teamId === team?.id)
  }, [availability?.activities, date, team?.id])
  const slots = useMemo(() => {
    return (availability?.slots || [])
      .filter(slot => slot.date == date)
  }, [availability?.slots, date])

  const availabilities = useMemo(() => {
    const canShowAvailability = availability => {
      const { targetType, targetId } = availability;
      if (targetType === 'Team' && targetId !== team.id) return false;
      if (targetType === 'Office' && teamOfficeIds.includes(targetId) && targetId !== team.officeId) return false;
      return true;
    }

    return (availability?.availabilities || [])
      .filter(availability => availability.date == date && canShowAvailability(availability))
  }, [availability?.availabilities, date, team.id, team.officeId, teamOfficeIds])

  const events = useMemo(() => {
    const byTimeAscending = (a = {}, b = {}) => {
      if (!a.startTime && !b.startTime) return 0;
      if (!a.startTime) return 1;
      if (!b.startTime) return -1;
      return new Date(a.startTime) - new Date(b.startTime);
    };

    return [...games, ...practices, ...activities].sort(byTimeAscending);
  }, [activities, games, practices])


  const homeSlots = slots.filter(slot => (surfaceOfficeId || slot?.officeId === team?.officeId))
  const awaySlots = slots.filter(slot => slot?.officeId !== team?.officeId)

  const canShowAwaySlots = showAwaySlots && !surfaceOfficeId

  const hideCell = selectedGame && ![selectedGame?.homeTeamId, selectedGame?.awayTeamId].includes(team?.id);
  const className = hideCell ? `${classes.root} ${classes.hide}` : classes.root

  return <TableCell padding="none" className={className}>
    {(!!availabilities.length || !!homeSlots.length) &&
    <AvailabilityDetailsList
      availableSlots={homeSlots}
      awaySlots={awaySlots}
      showAwaySlots={canShowAwaySlots}
      availabilities={availabilities}
    />}

    <div className={classes.tournamentContainer}>
      {teamTournamentsPerDay.length > 0 && (
        teamTournamentsPerDay.map(tournament => (
          <Tooltip title={tournament.name}><span className={`${classes.ellipsis} ${classes.tournament}`}>{tournament.name}</span></Tooltip>
        ))
      )}
    </div>

    <div className={classes.teamEventCell}>
      <ul className={classes.list}>
        {events.map(event => {
          if (isGame(event) || isDraft(event)) {
            return <EventCard event={event} teamId={team?.id} showOpponent />
          }
          return <EventCard event={event} />
        })}
      </ul>
    </div>
  </TableCell>
})

const DayCell = memo(({ date, separator }) => {
  const [ view, setView ] = useCalendarView();
  const { setNavigateToDate } = useCalendarContext();
  const classes = useCellStyles({ separator })

  const handleDayClick = () => {
    setView(CALENDAR_VIEWS.WEEK);
    setNavigateToDate(date)
  }

  return <TableCell className={classes.root}>
    <div onClick={handleDayClick} className={classes.date}>
      <DateField source="date" format="dddd," yearFormat="dddd," record={{ date }} className={classes.dateField} />
      <br />
      <DateField source="date" format="ll" yearFormat="ll" record={{ date }} className={classes.dateField} />
    </div>
  </TableCell>
})

const DayRow = memo(({ date, separator }) => {
  const { columns } = useCalendarContext()

  return <TableRow>
    <DayCell date={date} separator={separator} />
    {(columns || []).map(team => <TeamEventCell team={team} date={date} separator={separator} />)}
  </TableRow>
})

const TotalScheduledGames = ({ team }) => {
  const { data } = useListContext();
  const isHomeFirst = useIsHomeFirst();
  const classes = useScheduledGamesStyles(isHomeFirst);
  const translate = useTranslate()

  const games = Object.values(data)

  if (!games?.length) return null;

  const homeGames = games.filter(game => game.homeTeamId === team?.id);
  const awayGames = games.filter(game => game.awayTeamId === team?.id);

  const scheduledHomeGames = homeGames.filter(game => game?.date);
  const scheduledAwayGames = awayGames.filter(game => game?.date);

  const homeTooltip = translate('components.calendar.tooltips.scheduled_home_games', { count: scheduledHomeGames.length, totalCount: homeGames.length });
  const awayTooltip = translate('components.calendar.tooltips.scheduled_away_games', { count: scheduledAwayGames.length, totalCount: awayGames.length });

  const isIncompleteHome = scheduledHomeGames.length !== homeGames.length
  const isIncompleteAway = scheduledAwayGames.length !== awayGames.length

  return (
    <div className={classes.scheduledGames}>
      <Tooltip title={homeTooltip} placement="top">
        <div className={`${classes.flexCenter} ${isIncompleteHome ? classes.colorWarning : ''}`}>
          <HomeIcon className={classes.icon} />
          <span style={{ whiteSpace: 'nowrap' }}>{scheduledHomeGames.length} / {homeGames.length}</span>
        </div>
      </Tooltip>
      <Tooltip title={awayTooltip} placement="top">
        <div className={`${classes.flexCenter} ${isIncompleteAway ? classes.colorWarning : ''}`}>
          <CardTravel className={classes.icon} />
          <span style={{ whiteSpace: 'nowrap' }}>{scheduledAwayGames.length} / {awayGames.length}</span>
        </div>
      </Tooltip>
    </div>
  )
}

const TeamHeaderCell = memo(({ team }) => {
  const [ showOfficeName ] = useShowOfficeName();
  const { selectedGame } = useSchedulingContext()
  const classes = useCellStyles()

  const hideCell = selectedGame && ![selectedGame?.homeTeamId, selectedGame?.awayTeamId].includes(team?.id);
  const isHome = selectedGame?.homeTeamId === team?.id

  return <TheadCell className={hideCell ? classes.hide : undefined} key={team?.id} align="center">
    <div className={classes.thead}>
      <span>
        <TeamField source="id" includeOffice={showOfficeName && 'full'} className={classes.TeamNameColor} record={team} />
        {isHome && <Home />}
      </span>
      <TotalScheduledGames team={team} />
    </div>
  </TheadCell>
})

const SeasonTableBody = memo((props) => {
  const translate = useTranslate();
  const [ showCondensedView ] = useShowCondensedView();
  const { rows, allrows, columns } = useCalendarContext();

  const selectedRows = showCondensedView ? rows : allrows;

  return <TableBody>
    {selectedRows?.length
      ? selectedRows.map((date, i) => {
        const prevWeek = selectedRows[i - 1] && moment(selectedRows[i - 1]).week()
        const week = moment(date).week();

        return <DayRow date={date} separator={prevWeek && prevWeek !== week} />
      })
      : <TableRow>
        <TableCell colSpan={columns?.length + 1} align="center" style={{ paddingBlock: '16px' }}>
          {translate('ra.message.no_rows')}
        </TableCell>
      </TableRow>}
    <LoadMoreButton columns={columns} />
  </TableBody>
})

const SeasonTable = forwardRef((props, ref) => {
  const classes = useSeasonStyles();
  const { columns } = useCalendarContext()

  if (!columns?.length) return null;
  return <Table ref={ref} size="small" className={classes.root} stickyHeader>
    <TableHead>
      <TopLeftCell align="center" />
      {(columns || []).map(team => <TeamHeaderCell key={team?.id} team={team} />)}
    </TableHead>
    <SeasonTableBody />
  </Table>
})

export default memo(({ children }) => {
  const tableRef = useRef(null);

  const rowRefs = tableRef?.current?.querySelectorAll('tr');
  const rowHeights = rowRefs && Array.from(rowRefs).map(row => {
    return row.getBoundingClientRect().height;
  });

  const cellRef = useRef(null);
  cellRef.current = tableRef?.current?.querySelector('tr:nth-child(1)');
  const cellSize = useElementSize(cellRef.current);

  const teamHeader = useRef(null);
  teamHeader.current = tableRef?.current?.querySelector('th');
  const teamHeaderSize = useElementSize(teamHeader.current);

  const isMeasured = cellSize.height > 0 && cellSize.width > 0

  const offset = useMemo(() => ({
    width: 0,
    height: teamHeaderSize.height,
  }), [ teamHeaderSize ])

  return <SeasonView>
    <SeasonTable ref={tableRef} />
    {isMeasured && Children.map(children, child => {
      if (!child) return null;
      return cloneElement(child, { ...child.props, size: cellSize, offset, rowHeights });
    })}
  </SeasonView>
})
