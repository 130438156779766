import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';
import { ParticipantIcon } from '../../icons';

import { DivisionInput } from '../../categories/DivisionInput';

import { RegistrationGrid } from '../../registrations/RegistrationGrid';
import { AddRegistrationButton } from '../../registrations/RegistrationModal';
import ImportRegistrations from '../dialogs/registrations';

export default ({ collapsable, ...props }) => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const showRegistrations = isAuthorized(office, 'registrations', 'show');
  const canAddRegistrations = isAuthorized(office, 'registrations', 'create');

  if (!showRegistrations) return null;

  const filters = [
    <DivisionInput source="divisionId" multiple variant="outlined" alwaysOn />
  ]

  const addButton = canAddRegistrations && <AddRegistrationButton initialValues={{ officeId: office?.id }} />
  const actions = [
    canAddRegistrations && <ImportRegistrations officeId={office?.id} size="small" />,
  ].filter(Boolean)

  return <ListCard
    title="resources.registrations.labels.card.title"
    icon={<ParticipantIcon />}
    reference="registrations"
    target="officeId"
    filter={{ seasonId, effectiveOffices: true }}
    sort={{
      field: ['participant.lastName', 'participant.firstName', 'division.name'],
      order: ['ASC', 'ASC', 'ASC'],
    }}
    filters={filters}
    addButton={addButton}
    actions={actions}
    collapsable={collapsable}
  >
    <RegistrationGrid showParticipant />
  </ListCard>
}
