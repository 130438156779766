import React, { Fragment, useState } from 'react';
import { Datagrid, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

// import { DialogDatagrid } from '../../../common/dialogs/DialogDatagrid';
// import { ResourceDialog } from '../../../common/dialogs/ResourceDialog';

// import { InfractionForm } from './InfractionForm';

// const AddInfractionDialog = props =>
//   <RecordContextProvider value={null}>
//     <ResourceDialog label="resources.rulebooks.actions.add_infraction" resource="rulebooks" basePath="/rulebooks" maxWidth="md" {...props}>
//       <InfractionForm {...props} />
//     </ResourceDialog>
//   </RecordContextProvider>

// const EditInfractionDialog = ({ save, ...props }) =>
//   <ResourceDialog label="resources.rulebooks.labels.edit_infraction" resource="rulebooks" basePath="/rulebooks" maxWidth="md" handleSubmit={save} {...props}>
//     <InfractionForm {...props} />
//   </ResourceDialog>

export const AddInfractionButton = ({ save, size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  const handleSubmit = infraction => {
    save(infraction);
    setOpen(false);
  }
  return <>
    <Button color="primary" startIcon={<Add />} disabled onClick={() => setOpen(true)}>
      {translate('resources.rulebooks.actions.add_infraction')}
    </Button>
    {/* <AddInfractionDialog isOpen={isOpen} handleClose={() => setOpen(false)} handleSubmit={handleSubmit} {...props} /> */}
  </>
}

// export const InfractionDatagrid = props => <DialogDatagrid dialog={EditInfractionDialog} {...props} />
export const InfractionDatagrid = props => <Datagrid {...props} />
