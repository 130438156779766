import React from 'react';
import { useRecordContext } from 'react-admin';
import { EmojiPeople } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { ClaimStatusEnumInput } from '../../../common/inputs/EnumInputs';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { ClaimsGrid } from '../../officialClaims/ClaimsGrid';
import { AddClaimButton } from '../../officialClaims/ClaimModal';
import { ClaimBulkAction } from '../../officialClaims/ClaimBulkActions';

export default ({ ...props }) => {
  const participant = useRecordContext(props);
  const seasonId = useSeason();

  const filters = [
    <ClaimStatusEnumInput source="status" variant="outlined" multiple alwaysOn />,
  ]

  const canAddClaims = isAuthorized(participant, 'officialClaims', 'create');
  const canBulkAction = isAuthorized(participant, 'officialClaims', 'changeStatus');

  return <ListCard
    title="resources.officialClaims.labels.card.title"
    icon={<EmojiPeople />}
    reference="officialClaims"
    target="participantId"
    filters={filters}
    filter={{ 'game.seasonId': seasonId }}
    sort={{ field: ['status', 'participant.lastName', 'participant.firstName', 'game.startTime'], order: ['ASC', 'ASC', 'ASC', 'ASC'] }}
    bulkActionButtons={canBulkAction && <ClaimBulkAction />}
    addButton={canAddClaims && <AddClaimButton
      target="participantId"
      initialValues={{
        participantId: participant?.id,
      }}
    />}
    collapsable
  >
    <ClaimsGrid hideParticipant />
  </ListCard>
}
