import React from 'react';
import { TextField } from 'react-admin';

import { EnumField } from '../../common/inputs/EnumInputs';
import { CategoriesField } from '../categories/CategoriesField';
import { SeasonField } from '../seasons/SeasonField';

import { GroupDatagrid } from './GroupModal';

export const GroupGrid = props =>
  <GroupDatagrid size="medium" {...props}>
    <SeasonField source="seasonId" />
    <TextField source="name" />
    <EnumField source="type" />
    <CategoriesField source="categoryIds" emptyText="ra.message.all" />
  </GroupDatagrid>
