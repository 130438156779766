import React, { useCallback } from 'react';
import { Edit as RAEdit, useUpdate, useSaveModifiers, useNotify, useRedirect, CRUD_UPDATE } from 'react-admin';
import { ResourceActions } from './ResourceActions';

const Form = ({
  resource,
  id,
  record,
  basePath,
  onSuccess,
  onFailure,
  transform,
  successMessage,
  undoable = true,
  mutationMode = undoable ? 'undoable' : undefined,
  children,
  ...props
}) => {
  // save based on useUpdateController
  const {
    onSuccessRef,
    setOnSuccess,
    onFailureRef,
    setOnFailure,
    transformRef,
    setTransform,
  } = useSaveModifiers({ onSuccess, onFailure, transform });
  const notify = useNotify()
  const redirect = useRedirect()

  const [ update, { loading: saving } ] = useUpdate(resource, id, {}, record)
  const save = useCallback(async (values, redirectTo, {
    onSuccess: onSuccessFromSave,
    onFailure: onFailureFromSave,
    transform: transformFromSave,
  } = {}) => {
    try {
      const data = await Promise.resolve(
        transformFromSave ? transformFromSave(values) :
          transformRef.current ? transformRef.current(values)
            : values
      )

      return await update(
        { payload: { data } },
        {
          action: CRUD_UPDATE,
          onSuccess: onSuccessFromSave || onSuccessRef.current || function() {
            notify(successMessage || 'ra.notification.updated', 'info', { smart_count: 1 }, mutationMode === 'undoable')
            redirect(redirectTo, basePath, data.id, data)
          },
          onFailure: onFailureFromSave || onFailureRef.current || function(error) {
            const errorMessage = typeof error === 'string' ? error : error.message;
            notify(errorMessage || 'ra.notification.http_error', 'warning', { _: errorMessage })
          },
          mutationMode,
          // added for final-form to recognize the error response
          returnPromise: true,
        }
      )
    } catch (e) {
      // passed to final-form
      return e;
    }
  }, [
    transformRef,
    update,
    onSuccessRef,
    onFailureRef,
    notify,
    successMessage,
    redirect,
    basePath,
    mutationMode,
  ])

  return React.cloneElement(children, {
    ...props,
    ...children.props,
    resource,
    basePath,
    id,
    record,
    saving,
    save,
    setOnSuccess,
    setOnFailure,
    setTransform,
  })
}

export function Edit({ children, id, ...props }) {
  return <RAEdit
    id={id}
    undoable={false}
    mutationMode="pessimistic"
    actions={<ResourceActions />}
    {...props}
  >
    <Form id={id}>
      {children}
    </Form>
  </RAEdit>
}
