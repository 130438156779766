import React from 'react';
import { TextField } from 'react-admin';
import { isInteger } from "lodash";
import { isEmpty } from "@hisports/parsers";

import FunctionField from '../../common/fields/FunctionField';
import { OfficeField } from '../offices/OfficeField';

import { AttributeTypeDatagrid } from './AttributeTypeModal';

const getGridAttributeOptions = (attributeType, source, resources, translate) => {
  if (!attributeType?.options) return;

  const gridOptions = [];
  const { valueType, options } = attributeType;

  Object.keys(options).map(function(key) {
    if (valueType == 'Dropdown') {
      gridOptions.push(options[key].join(', '));
    } else if (valueType == 'Number' && isInteger(options[key])) {
      gridOptions.push(`${translate(`resources.attributeTypes.fields.${key}`, key)}: ` + `${options[key]}`);
    }
  })

  if (isEmpty(gridOptions)) return;
  return gridOptions.join(', ');
}

const attributesRender = (attributeType, source, resources, translate) => {
  switch (source) {
    case 'valueType':
      return translate(`resources.attributeTypes.${source}.${attributeType[source]}`, attributeType[source])
    default:
      return null
  }
}

export const AttributeTypeGrid = props =>
  <AttributeTypeDatagrid size="medium" {...props}>
    <TextField source="name" />
    <FunctionField source="valueType" render={attributesRender} />
    <FunctionField source="options" render={getGridAttributeOptions} />
    <OfficeField source="officeId" />
  </AttributeTypeDatagrid>
