import React from 'react';
import { Filter, TextInput, BooleanInput } from 'react-admin';

import { LATEST_SEASON } from '@hisports/common/src/seasons';

import { ScheduleTypeEnumInput, GameFormatEnumInput } from '../../common/inputs/EnumInputs';
import { SeasonFilter, useSeason } from '../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../common/Authorize';
import { useDefaultSeason, useScopes } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';
import { CategoryInput } from '../categories/CategoryInput';

const multipleProps = {
  multiple: true,
  allowEmpty: false,
}

export const ScheduleFilters = ({ hideOffices, ...props }) => {
  const scopes = useScopes();
  const seasonId = useSeason()
  const defaultSeason = useDefaultSeason();

  const canListOffices = isAuthorized(scopes, 'offices', 'list');
  const showPastSeason = (seasonId === LATEST_SEASON) || (seasonId === defaultSeason);

  return <Filter variant="outlined" {...props}>
    <TextInput source="name" alwaysOn />
    {canListOffices && !hideOffices && <OfficeInput source="officeId" type={{ type: { neq: 'Historical' } }} alwaysOn {...multipleProps} />}
    <ScheduleTypeEnumInput source="type" alwaysOn {...multipleProps} />
    <CategoryInput source="categoryId" {...multipleProps} />
    <GameFormatEnumInput source="gameFormat" {...multipleProps} />
    {showPastSeason && <BooleanInput source="pastEvents" alwaysOn />}
    <SeasonFilter source="seasonId" alwaysOn />
  </Filter>
}
