import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core';
import { DoneAll } from '@material-ui/icons';

import { EventChanges } from '../../common/EventChanges';
import { apiClient } from '../../http';
import ScheduleSequenceError from '../schedulesequences/ScheduleSequenceError';

const PublishDraftGameDialog = ({ isOpen, setOpen, gameToUpdate, ...props }) => {
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext()

  const handleClick = (e) => e.stopPropagation(); // needed to prevent edit dialog from opening when clicking inside the dialog

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault()
    setOpen(false);
  }

  const handleConfirm = async (e) => {
    apiClient(`/schedules/${record?.scheduleId}/publish`, {
      method: 'POST',
      data: {
        draftGameIds: [record?.id]
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        const failed = data?.failed || 0;
        const invalid = data?.invalid || 0;

        refresh();
        notify(translate('resources.draftGames.notifications.published_games', count));

        if (failed) {
          notify(translate('resources.draftGames.notifications.publish_failed', failed), 'error');
        }
        if (invalid) {
          notify(translate('resources.draftGames.notifications.invalid_games', invalid), 'error')
        }
      })
      .catch(err => {
        const name = err?.response?.data?.error?.name
        if (['missing_sequence', 'sequence_exhausted'].includes(name)) {
          const errorMsg = translate(`resources.draftGames.notifications.${name}`)
          notify(<ScheduleSequenceError errorMsg={errorMsg} scheduleId={gameToUpdate?.scheduleId} />, 'error')
        } else {
          notify(translate('resources.draftGames.notifications.published_games_error', 1), 'error')
        }
      })

    handleClose(e)
  }

  return <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="sm" onClick={handleClick}>
    <DialogTitle>{translate('resources.games.labels.confirm_changes')}</DialogTitle>
    <DialogContent>
      <EventChanges previousEvent={gameToUpdate} />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary">{translate('ra.action.confirm')}</Button>
    </DialogActions>
  </Dialog>
}

export const PublishDraftGameAction = ({ size = "small", hideLabel = false, ...props }) => {
  const translate = useTranslate();
  const draftGame = useRecordContext()
  const [ isOpen, setOpen ] = useState(false);
  const games = useSelector(store => store.admin.resources.games.data);

  const gameToUpdate = games?.[draftGame?.updatedGameId];

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  }

  if (!gameToUpdate) return null;
  return <>
    <Button color="primary" size={size} startIcon={<DoneAll />} onClick={handleClick}>{!hideLabel && translate('ra.action.publish')}</Button>
    {isOpen && <PublishDraftGameDialog isOpen={isOpen} setOpen={setOpen} gameToUpdate={gameToUpdate} {...props} />}
  </>
}
