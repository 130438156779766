export const FORWARD = 'F';
export const CENTRE = 'C';
export const DEFENSE = 'D';
export const GOALIE = 'G';
export const PLAYER_POSITIONS = [
  FORWARD, CENTRE, DEFENSE, GOALIE
];
export const SKATER_POSITIONS = [
  FORWARD, CENTRE, DEFENSE
];
export const GOALIE_POSITIONS = [ GOALIE ];

export const HOME = 'home';
export const AWAY = 'away';

export const HEAD_COACH = 'Head Coach';
export const ASSISTANT_COACH = 'Assistant Coach';
export const GOALTENDING_COACH = 'Goaltending Coach';
export const COACH_POSITIONS = [
  HEAD_COACH,
  ASSISTANT_COACH,
  GOALTENDING_COACH
];

export const MANAGER = 'Manager';
export const TRAINER = 'Trainer';
export const SAFETY_PERSON = 'Safety Person';
export const BENCH_POSITIONS = [
  MANAGER,
  TRAINER,
  SAFETY_PERSON
];

export const STAFF_POSITIONS = [
  ...COACH_POSITIONS,
  ...BENCH_POSITIONS
];
export const MEMBER_POSITIONS = [
  ...PLAYER_POSITIONS,
  ...STAFF_POSITIONS
];

export const PLURALS = {
  'Referee': 'referees',
  'Linesperson': 'linespersons',
  'LinespersonTwo': 'linespersonsTwo',
  'LinespersonThree': 'linespersonsThree',
  'Official': 'officials',
  'Scorekeeper': 'scorekeepers',
  'Timekeeper': 'timekeepers',
  'Supervisor': 'supervisors',
}

export const REFEREE = 'Referee';
export const LINESPERSON = 'Linesperson';
export const LINESPERSON_TWO = 'LinespersonTwo';
export const LINESPERSON_THREE = 'LinespersonThree';
export const OFFICIAL = 'Official';
export const SUPERVISOR = 'Supervisor';

export const SCOREKEEPER = 'Scorekeeper';
export const TIMEKEEPER = 'Timekeeper';

export const LINESMAN = 'Linesman'
export const OLD_OFFICIAL_POSITIONS = [
  REFEREE, LINESMAN
]

export const OFFICIAL_POSITIONS = [
  REFEREE, LINESPERSON, LINESPERSON_TWO, LINESPERSON_THREE, OFFICIAL
]

export const KEEPER_POSITIONS = [
  SCOREKEEPER, TIMEKEEPER
]

export const ALL_OFFICIAL_POSITIONS = [
  ...OFFICIAL_POSITIONS, ...KEEPER_POSITIONS, SUPERVISOR
]

export const OFFICIAL_STATUS_UNASSIGNED = 'unassigned';
export const OFFICIAL_STATUS_REQUESTED = 'requested';
export const OFFICIAL_STATUS_ASSIGNED = 'assigned';
export const OFFICIAL_STATUS_DECLINED = 'declined';
export const OFFICIAL_STATUS_CONFIRMED = 'confirmed';
export const OFFICIAL_STATUS_NO_SHOW = 'no-show';

export const OFFICIAL_STATUSES = [
  OFFICIAL_STATUS_ASSIGNED,
  OFFICIAL_STATUS_REQUESTED,
  OFFICIAL_STATUS_DECLINED,
  OFFICIAL_STATUS_CONFIRMED,
  OFFICIAL_STATUS_NO_SHOW
]

export const MIN_SKATER_COUNT = 5;
export const MAX_SKATER_COUNT = 18;

export const MIN_GOALIE_COUNT = 0;
export const MAX_GOALIE_COUNT = 2;

export const MIN_PLAYER_COUNT = 6;
export const MAX_PLAYER_COUNT = 19;

export const MIN_STAFF_COUNT = 1;
export const MAX_STAFF_COUNT = 5;

export const FIRST_PERIOD = '1';
export const SECOND_PERIOD = '2';
export const THIRD_PERIOD = '3';
export const OVERTIME_PERIOD = 'OT';
export const SHOOTOUT_PERIOD = 'SO';
export const PERIODS = [
  FIRST_PERIOD,
  SECOND_PERIOD,
  THIRD_PERIOD,
  OVERTIME_PERIOD,
  SHOOTOUT_PERIOD,
]

export const SCHEDULED = 'scheduled';
export const STARTED = 'started';
export const ENDED = 'ended';
export const APPROVED = 'approved';
export const CERTIFIED = 'certified';

export const EVENT_TYPE_GOAL = 'goal';
export const EVENT_TYPE_PENALTY = 'penalty';
export const EVENT_TYPE_GOALIE = 'goalie';

export const PENALTY_SEVERITY = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  SEVERE: 'severe',
}

export const INFRACTION_SEVERITY = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  SEVERE: 'Severe',
}

export const TEAM_FLAGS = {
  LINEUP_MIN_LIMIT: 'Lineup Min Limit',
  LINEUP_MAX_LIMIT: 'Lineup Max Limit',
  NOT_ROSTERED: 'Not Rostered',
  LOW_SEVERITY_PENALTIES: 'Low Severity Penalties',
  HIGH_SEVERITY_PENALTIES: 'High Severity Penalties',
  YELLOW_PENALTIES: 'Yellow',
  DIRECT_RED_PENALTIES: 'Direct Red',
  INDIRECT_RED_PENALTIES: 'Indirect Red',
  EXTRA: 'Extra',
  SERVING: 'Serving',
  NOT_SERVING: 'Not Serving',
  SECONDARY_TEAM: 'Secondary Team',
  TRANSFERRED: 'Transferred',
  UNVALIDATED_MEMBERS: 'Unvalidated Members',
  FAULTY_PLAYERS: 'Faulty Players',
  MISSING_SUSPENDED_MEMBER: 'Missing Suspended Member',
}

export const FAULTY_PLAYER_REASONS = {
  HAS_INNINGS_REST: 'hasInningsRest',
  HAS_THROWS_REST: 'hasThrowsRest',
  CONSECUTIVE_DAYS: 'consecutiveDays',
  DAILY_INNINGS: 'dailyInnings',
  WEEKLY_INNINGS: 'weeklyInnings',
}

export const GENERAL_FLAGS = {
  COACH_ENTERED_RESULT: 'Coach Entered Result',
  UNFINISHED: 'Unfinished',
  NOTES: 'Notes',
}

export const FLAG_COLORS = {
  RED: 'red',
  ORANGE: 'orange',
  RED_ORANGE: 'red/orange',
  DEFAULT: 'default'
}

// hockey durations
export const MINOR = 'minor';
export const DOUBLE_MINOR = 'double-minor';
export const MAJOR = 'major';
export const MATCH = 'match';
export const MISCONDUCT = 'misconduct';

export const GAME_MISCONDUCT = 'game-misconduct';
export const GROSS_MISCONDUCT = 'gross-misconduct';
export const PENALTY_SHOT = 'penalty-shot';
export const HEAD_CONTACT = 'head-contact';
export const GAME_EJECTION = 'game-ejection';

// soccer durations
export const YELLOW = 'yellow';
export const YELLOW_RED = 'red';
export const RED = 'red_direct';

// baseball durations
export const EJECTION = 'ejection';

export const INITIAL_TIME = {
  Hockey: {
    period: '1',
    minutes: 20,
    seconds: 0
  },
  Soccer: {
    minutes: 0,
    extra: 0,
  },
  Baseball: {
    period: 1,
    half: 1,
  }
}

export const DEFAULT_SETTINGS = {
  periods: [
    { period: '1', minutes: 20, seconds: 0 },
    { period: '2', minutes: 20, seconds: 0 },
    { period: '3', minutes: 20, seconds: 0 },
    { period: 'OT', minutes: 10, seconds: 0 },
  ]
}

export const LEGACY_RULEBOOK_SEASONS = [
  '2023-24',
  '2022-23',
  '2021-22',
  '2020-21',
  '2019-20',
  '2018-19'
];

export const LEGACY_GAME_NUMBERING_SEASONS = [
  '2023-24',
  '2022-23',
  '2021-22',
  '2020-21',
  '2019-20',
  '2018-19'
];
