import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Box, Tooltip, alpha, makeStyles } from '@material-ui/core'
import { CardTravel, Home, StarBorder } from "@material-ui/icons";
import moment from 'moment-timezone';

import { SurfaceField } from '../../resources/surfaces/SurfaceField';
import { PracticeIcon, ActivityIcon, TimeSlotIcon, DraftIcon, GameIcon } from '../../resources/icons';
import { LocationField } from '../../resources/events/EventGrid';
import { OfficeField } from '../../resources/offices/OfficeField';
import { CategoryField } from '../../resources/categories/CategoryField';
import { TeamField } from '../../resources/teams/TeamField';
import { useScheduleWithStore } from '../../resources/schedules/useSchedule';

import { useSchedulingContext } from './SchedulingContext';
import { useCalendarContext } from './CalendarContext';
import { AvailabilityDetailsPopover, SlotDetailsPopover, ActivityDetailsPopover, PracticeDetailsPopover, GameDetailsPopover } from './EventDetailsPopover';
import { isAuthorized } from '../Authorize';

export const isDraftGameType = type => ['Draft', 'Draft Game'].includes(type)

export const isDraft = event => ['drafts', 'draftGames'].includes(event?.resource) || isDraftGameType(event?.type) || !!event?.draftId;

export const isGame = event => event?.resource === 'games' || event?.type === 'Game';

export const isPractice = event => event?.resource === 'practices' || event?.type === 'Practice';

const isInactive = status =>
  status && !['Active', 'Rescheduled', 'Rink Changed'].includes(status)

const getDuration = (start, end) => {
  const diff = moment(end).diff(start, 'minutes');

  const minutes = diff % 60
  const hours = Math.floor(diff / 60)
  if (minutes <= 0) return `${hours}h`
  if (hours <= 0) return `${minutes}m`
  return `${hours}h ${minutes}m`
}

const useEventStyles = makeStyles(theme => {
  const getBorderWidth = (eventType, isDraft, isSplitSurfaceEvent, isInactive) => {
    if (isDraft || isInactive) return 3;
    if (eventType === 'Slot' && !isSplitSurfaceEvent) return 2;
    return 1;
  }

  const getBorderStyle = (eventType, isDraft, isSplitSurfaceEvent) => {
    if (isDraft) return 'dashed';
    if (eventType === 'Slot' && !isSplitSurfaceEvent) return 'dotted';
    return 'solid';
  }

  const getBorderColor = (eventType, isSplitSurfaceEvent, isInactive, scheduleType) => {
    if (['Game', 'Practice', 'Activity'].includes(eventType)) {
      if (isInactive) return theme.palette.error[500];
      if (eventType === 'Game') {
        const gameColor = getGameColorBasedOnScheduleType(scheduleType);
        return isSplitSurfaceEvent ? gameColor[700] : gameColor[800];
      }
      if (eventType === 'Practice') return isSplitSurfaceEvent ? theme.palette.purple[300] : theme.palette.purple[600];
    }
    if (eventType === 'External') return isSplitSurfaceEvent ? theme.palette.blueGrey[400] : theme.palette.blueGrey[500];
    if (eventType === 'Slot') return theme.palette.grey[600];
  }

  const getBackgroundColor = (eventType, isSelected, isHovered, isSplitSurfaceEvent, scheduleType) => {
    switch (eventType) {
      case 'Game': {
        const gameColor = getGameColorBasedOnScheduleType(scheduleType);
        if (isSelected) return isSplitSurfaceEvent ? gameColor[100] : gameColor[300];
        if (isHovered) return gameColor[300];
        return isSplitSurfaceEvent ? gameColor[50] : gameColor[100];
      }

      case 'Practice':
        if (isSelected) return isSplitSurfaceEvent ? theme.palette.purple[100] : theme.palette.purple[300];
        if (isHovered) return theme.palette.purple[200];
        return isSplitSurfaceEvent ? theme.palette.purple[50] : theme.palette.purple[100];

      case 'External':
        if (isHovered) return theme.palette.blueGrey[200];
        return isSplitSurfaceEvent ? theme.palette.blueGrey[50] : theme.palette.blueGrey[100];

      case 'Slot':
        if (isHovered) return theme.palette.grey[200];
        return theme.palette.grey[50];

      default:
        return 'white';
    }
  }

  const getGameColorBasedOnScheduleType = (scheduleType) => {
    switch (scheduleType) {
      case 'Exhibition':
      case 'Placement':
        return theme.palette.yellow;

      case 'League':
        return theme.palette.lightBlue;

      case 'Playoffs':
      case 'Championship':
        return theme.palette.success;

      case 'Tournament':
      case 'Cup':
        return theme.palette.warning;

      default:
        return theme.palette.primary;
    }
  }

  const getBoxShadow = (isDraggable, isHovered) => {
    if (isDraggable && isHovered) return theme.shadows[7];
    if (isDraggable) return theme.shadows[2];
  }

  return ({
    root: {
      borderWidth: props => getBorderWidth(props.eventType, props.isDraft, props.isSplitSurfaceEvent, props.isInactive),
      borderStyle: props => getBorderStyle(props.eventType, props.isDraft, props.isSplitSurfaceEvent),
      borderColor: props => getBorderColor(props.eventType, props.isSplitSurfaceEvent, props.isInactive, props.scheduleType),
      backgroundColor: props => getBackgroundColor(props.eventType, props.isSelected, false, props.isSplitSurfaceEvent, props.scheduleType),
      boxShadow: props => getBoxShadow(props.isDraggable, false),
      transition: 'all 0.1s ease-in-out',

      borderRadius: theme.spacing(.75),
      overflow: 'hidden',
      padding: theme.spacing(.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      fontSize: theme.spacing(1.5),

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: props => getBackgroundColor(props.eventType, props.isSelected, true, props.isSplitSurfaceEvent, props.scheduleType),
        boxShadow: props => getBoxShadow(props.isDraggable, true),
      }
    },
  })
})

const useGameStyles = makeStyles(theme => ({
  number: {
    fontWeight: 'bold',
    display: 'flex',
    flexFlow: 'wrap',
    alignContent: 'center',
    gap: theme.spacing(0, .5),
    lineHeight: `${theme.spacing(2.5)}px`,
  },
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  status: {
    fontWeight: 'bold',
  },
  time: {
    whiteSpace: 'nowrap',
  },
  location: {
    whiteSpace: 'nowrap',
  },
  teamName: {
    fontStyle: 'italic',
    fontSize: theme.spacing(1.25),
  },
}))

const OpponentField = ({ teamId, game }) => {
  const classes = useGameStyles();
  const isHome = game?.homeTeamId === teamId;

  return <div className={classes.teamName}>
    <span>
      {isHome ? 'vs' : '@'}
      {' '}
      <TeamField source={isHome ? 'awayTeamId' : 'homeTeamId'} link={false} variant="inherit" />
    </span>
  </div>
}

export const GameDetails = ({ game, ...props }) => {
  const { data: schedule } = useScheduleWithStore(game.scheduleId);

  // schedule needs to be loaded to render the event classes properly on the game card
  if (!schedule) return null;
  return <GameCard schedule={schedule} game={game} {...props} />
}

const GameCard = ({ game, className, teamId, showOpponent = false, schedule, ...props }) => {
  const translate = useTranslate()
  const { selectedGame } = useSchedulingContext();
  const { hideArena, includeVenue, timezone } = useCalendarContext();
  const inactive = isInactive(game.status);
  const record = useRecordContext();
  const gameType = isDraft(game) ? 'draftGames' : 'games';
  const permittedToEdit = isAuthorized(record, gameType, 'edit');
  const classes = useGameStyles();
  const eventClasses = useEventStyles({
    eventType: selectedGame && (game.scheduleId !== selectedGame?.scheduleId) ? 'External' : 'Game',
    isDraft: isDraft(game),
    isSelected: game.id === selectedGame?.id,
    isDraggable: permittedToEdit && game?.isEditable !== false, // currently isEditable is never true, mostly undefined. so we specifically need to check if its not false
    isSplitSurfaceEvent: game.isSplitSurfaceEvent,
    isInactive: isInactive(game?.status),
    scheduleType: schedule?.type
  })

  const gameNumber = <div className={classes.number}>
    <CalendarGameIcon game={game} className={classes.icon} teamId={teamId} />
    {' '}
    {game.number != null ? game.number : game.id}
    {' - '}
    <CategoryField source="categoryId" variant="inherit" includeGender={false} />
  </div>

  return <GameDetailsPopover isEditable={game?.isEditable} disableLaunch={isDraft(game)} {...props}>
    {(onClick) => {
      return <div onClick={onClick} className={`${className} ${eventClasses.root}`}>
        {game.isSplitSurfaceEvent ?
          <div>
            <div style={{ whiteSpace: 'nowrap' }}>{translate(`resources.blockedSurfaces.name`)}</div>
            {gameNumber}
          </div>
          :<>
            {gameNumber}
            {inactive && <div className={classes.status}>{translate(`resources.games.values.status.${game.status}`)}</div>}
            {game?.startTime && game?.endTime && <div className={classes.time}>{moment.tz(game.startTime, game?.timezone || timezone).format('HH:mm')} - {moment.tz(game.endTime, game?.timezone || timezone).format('HH:mm z')} ({getDuration(game.startTime, game.endTime)})</div>}
            {!hideArena && <div className={classes.location}><SurfaceField record={game} source="arenaId" variant="caption" link={false} includeVenue={includeVenue} /></div>}
            {showOpponent && teamId && <OpponentField teamId={teamId} game={game} />}
          </>}
      </div>
    }}
  </GameDetailsPopover>
}

const usePracticeStyles = makeStyles(theme => ({
  number: {
    fontWeight: 'bold',
    display: 'flex',
    flexFlow: 'wrap',
    alignContent: 'center',
    gap: theme.spacing(0, .5),
    lineHeight: `${theme.spacing(2.5)}px`,
  },
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  status: {
    padding: 2,
    fontWeight: 'bold',
  },
  time: {
    whiteSpace: 'nowrap',
  },
  location: {
    whiteSpace: 'nowrap',
  },
}))

export const PracticeDetails = ({ practice, className, ...props }) => {
  const translate = useTranslate();
  const { resource } = useSchedulingContext();
  const { hideArena, includeVenue, timezone } = useCalendarContext();
  const inactive = isInactive(practice.status);
  const classes = usePracticeStyles();
  const eventClasses = useEventStyles({
    eventType: 'Practice',
    isSplitSurfaceEvent: practice.isSplitSurfaceEvent,
    isInactive: isInactive(practice?.status)
  });

  let time = `${moment.tz(practice.startTime, timezone).format('HH:mm')} - ${moment.tz(practice.endTime, timezone).format('HH:mm z')}`
  if (!practice.multiday) {
    time += ` (${getDuration(practice.startTime, practice.endTime)})`
  }

  const practiceTitle = <div className={classes.number}>
    <PracticeIcon className={classes.icon} />
    {' '}
    {translate(`resources.practices.name`, { smart_count: 1 })}
  </div>

  return <PracticeDetailsPopover {...props}>
    {(onClick) => {
      return <div onClick={onClick} className={`${className} ${eventClasses.root}`}>
        {practice.isSplitSurfaceEvent ?
          <div>
            <Box mb={0.25} style={{ whiteSpace: 'nowrap' }}>{translate(`resources.blockedSurfaces.name`)}</Box>
            {practiceTitle}
            {practice.name?.length > 0 && <div>{practice.name}</div>}
          </div>
          :<>
            {practiceTitle}
            {inactive && <div className={classes.status}>{translate(`resources.games.values.status.${practice.status}`)}</div>}
            <div className={classes.time}>{time}</div>
            {practice.name?.length > 0 && <div>{practice.name}</div>}
            {!hideArena && <div className={classes.location}><LocationField record={practice} variant="caption" link={false} includeAttributes={false} includeCity={false} includeVenue={includeVenue} /></div>}
          </>}
      </div>
    }}
  </PracticeDetailsPopover>
}

const useActivityStyles = makeStyles(theme => ({
  number: {
    fontWeight: 'bold',
    display: 'flex',
    alignContent: 'center',
    gap: theme.spacing(.5),
    lineHeight: `${theme.spacing(2.5)}px`,
  },
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  status: {
    padding: 2,
    fontWeight: 'bold',
  },
  time: {
    whiteSpace: 'nowrap',
  },
  location: {
    whiteSpace: 'nowrap',
  },
}))

export const ActivityDetails = ({ activity, className, ...props }) => {
  const translate = useTranslate();
  const { resource } = useSchedulingContext();
  const { timezone } = useCalendarContext();
  const inactive = isInactive(activity.status);
  const classes = useActivityStyles();
  const eventClasses = useEventStyles({
    eventType: 'Activity',
    isInactive: isInactive(activity?.status) });

  let time = `${moment.tz(activity.startTime, timezone).format('HH:mm')} - ${moment.tz(activity.endTime, timezone).format('HH:mm z')}`
  if (!activity.multiday) {
    time += ` (${getDuration(activity.startTime, activity.endTime)})`
  }

  return <ActivityDetailsPopover {...props}>
    {(onClick) => {
      return <div onClick={onClick} className={`${className} ${eventClasses.root}`}>
        <div className={classes.number}>
          <ActivityIcon className={classes.icon} />
          {' '}
          {translate(`resources.activities.values.type.${activity.type}`, { _: activity.type })}
        </div>
        {inactive && <div className={classes.status}>{translate(`resources.games.values.status.${activity.status}`)}</div>}
        <div className={classes.time}>{time}</div>
        {activity.name?.length > 0 && <div>{activity.name}</div>}
        <div className={classes.location}><LocationField record={activity} variant="caption" link={false} includeAttributes={false} includeCity={false} /></div>
      </div>
    }}
  </ActivityDetailsPopover>
}

const useSlotStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    display: 'inline-flex',
    alignContent: 'center',

    gap: theme.spacing(0, .5),
    lineHeight: `${theme.spacing(2.5)}px`,
    whiteSpace: 'nowrap',
  },
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  status: {
    padding: 2,
    fontWeight: 'bold',
  },
  time: {
    whiteSpace: 'nowrap',
  },
  details: {
    color: theme.palette.grey[800],
  },
  location: {
    whiteSpace: 'nowrap',
  },
}))

export const SlotDetails = ({ slot, className }) => {
  const { hideArena, includeVenue, timezone } = useCalendarContext();
  const classes = useSlotStyles();
  const eventClasses = useEventStyles({ eventType: 'Slot', isSplitSurfaceEvent: slot.isSplitSurfaceEvent });
  const translate = useTranslate();

  const { startTime, endTime, multiday } = slot;

  let time = `${moment.tz(startTime, timezone).format('HH:mm')} - ${moment.tz(endTime, timezone).format('HH:mm z')}`
  if (!multiday) {
    time += ` (${getDuration(startTime, endTime)})`
  }

  const timeSlotName = slot.name?.length > 0 ? slot.name : translate(`resources.arenaslots.name`, { smart_count: 1 });

  const timeslotTitle = <div className={classes.title}>
    <TimeSlotIcon className={classes.icon} />
    {' '}
    <span className={classes.ellipsis}>{timeSlotName}</span>
  </div>

  const arenaDetails = <div className={classes.details}>
    {hideArena ?
      <OfficeField source="officeId" link={false} variant="inherit" /> :
      <SurfaceField source="arenaId" link={false} variant="inherit" includeVenue={includeVenue} />}
  </div>

  return <SlotDetailsPopover>
    {(onClick) => {
      return <div onClick={onClick} className={`${className} ${eventClasses.root}`}>
        {slot.isSplitSurfaceEvent ?
          <div>
            <div style={{ whiteSpace: 'nowrap' }}>{translate(`resources.blockedSurfaces.name`)}</div>
            <Box display="flex" alignItems="center" flexWrap="wrap" mt={0.5} style={{ gapX: 4 }}>
              {timeslotTitle}
              {' - '}
              {arenaDetails}
            </Box>
          </div>
          :<>
            {timeslotTitle}
            <div className={classes.time}>{time}</div>
            {arenaDetails}
          </>
        }
      </div>
    }}
  </SlotDetailsPopover>
}

const useAvailabilityStyles = makeStyles(theme => ({
  available: {
    background: `repeating-linear-gradient(
      -45deg,
      ${alpha(theme.palette.success[200], 1/8)},
      ${alpha(theme.palette.success[200], 1/8)} 4px,
      ${alpha(theme.palette.success[300], 1/8)} 4px,
      ${alpha(theme.palette.success[300], 1/8)} 8px
    )`,
  },
  unavailable: {
    background: `repeating-linear-gradient(
      -45deg,
      ${alpha(theme.palette.grey[300], 1/3)},
      ${alpha(theme.palette.grey[300], 1/3)} 4px,
      ${alpha(theme.palette.grey[400], 1/3)} 4px,
      ${alpha(theme.palette.grey[400], 1/3)} 8px
    )`,
  },
  icon: {
    fontSize: '0.75rem',
  }
}))

export const AvailabilityDetails = ({ availability, className }) => {
  const classes = useAvailabilityStyles();
  const { isAvailable, isMixed } = availability;

  const availabilityClass = isAvailable && !isMixed ? classes.available : classes.unavailable;
  return <AvailabilityDetailsPopover>
    {(onClick) => {
      return <div onClick={onClick} className={`${className} ${availabilityClass}`}></div>
    }}
  </AvailabilityDetailsPopover>
};

export const CalendarGameIcon = ({ game, className, teamId }) => {
  const { selectedGame } = useSchedulingContext();
  const translate = useTranslate();

  const teamIds = [game.homeTeamId, game.awayTeamId].filter(Boolean);
  const isHome = (selectedGame && teamIds.includes(selectedGame.homeTeamId)) || (teamId && teamId === game.homeTeamId);
  const isAway = (selectedGame && teamIds.includes(selectedGame.awayTeamId)) || (teamId && teamId === game.awayTeamId);

  let teamsTooltip;
  if (isHome && isAway) {
    teamsTooltip = 'both_teams'
  } else if (isHome) {
    teamsTooltip = 'home_team'
  } else if (isAway) {
    teamsTooltip = 'away_team'
  }

  const typeTooltip = isDraft(game) ? 'draft_game' : 'game';

  const title = [typeTooltip, teamsTooltip]
    .filter(Boolean)
    .map(tooltip => translate(`components.calendar.tooltips.${tooltip}`))
    .join(' - ')

  let Icon;
  if (isHome && isAway) {
    Icon = StarBorder;
  } else if (isHome) {
    Icon = Home;
  } else if (isAway) {
    Icon = CardTravel;
  } else if (isDraft(game)) {
    Icon = DraftIcon;
  } else {
    Icon = GameIcon;
  }

  return (
    <Tooltip placement="top" title={title}>
      <Icon className={className} />
    </Tooltip>
  );
};

