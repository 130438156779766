import React from 'react';
import { Table, TableBody } from '@material-ui/core';

import BracketViewer from '../BracketViewer';

import { BracketReseedButton } from '../../schedules/dialogs/BracketReseedDialog';
import { usePermissions } from '../../../http';


import InfoCard from '../../../common/cards/InfoCard';
import Row from '../../../common/cards/TableRow';
import { getFilteredScopes } from '../../../http/restClient/response';
import { isAuthorized } from '../../../common/Authorize';
import { useRecordContext } from 'react-admin';

export default ({ url, scheduleId }) => {
  const record = useRecordContext();
  const permissions = usePermissions();

  const filteredScopes = getFilteredScopes(permissions, record?.officeId, record?.scheduleId, false);

  const canEditSeeds = isAuthorized(filteredScopes, 'gameBrackets', 'edit')

  const headerActions = [
    canEditSeeds && <BracketReseedButton onClick scheduleId={scheduleId} variant="inherit" />
  ].filter(Boolean);

  return <InfoCard title="resources.gameBrackets.name" headerAction={headerActions}>
    <Table>
      <TableBody>
        <Row >
          <BracketViewer url={url} scheduleId={scheduleId} showIframe />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
