import React from 'react';
import { ReferenceField, FunctionField, useTranslate, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';

import { isAuthorized } from '../../common/Authorize';

export const OfficeField = ({ variant = 'body2', includeType = false, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  const showLink = isAuthorized(record, 'offices', 'show') ? 'show' : false;
  return <ReferenceField basePath="/offices" resource="offices" reference="offices" link={showLink} {...props}>
    <FunctionField source="name" variant={variant} render={office => {
      const type = translate(`resources.offices.values.type.${office.type}`)
      const inline = [
        includeType === 'inline' && type,
      ].filter(Boolean).join(' • ')
      const full = [
        includeType === 'full' && type,
      ].filter(Boolean).join(' • ')
      return <>
        <Typography variant={variant} display="inline">{office.name}</Typography>
        {inline && <Typography variant={variant} display="inline" color="textSecondary"> {inline}</Typography>}
        {full && <div><Typography variant={variant} display="inline" color="textSecondary">{full}</Typography></div>}
      </>
    }} />
  </ReferenceField>
}

OfficeField.defaultProps = {
  addLabel: true,
}
