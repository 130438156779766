import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { GET_LIST, useQuery } from 'react-admin';

import { dedupe } from '@hisports/parsers';

import { apiClient } from '../../http';
import { CALENDAR_VIEWS, useCalendarView, useShowAwaySlots, useSurfaceSizes } from '../../resources/events/EventViewSettings';
import { useSchedule } from '../../resources/schedules/useSchedule';
import { useScheduleSettings } from '../../resources/scheduleSettings';

const SchedulingContext = React.createContext(null);

const getScheduleAvailability = async (scheduleId, teamId, date, additionalSurfaceIds, signal) => {
  const { data } = await apiClient({
    method: 'GET',
    url: `/schedules/${scheduleId}/availabilities`,
    params: { teamId, date, additionalSurfaceIds },
    signal,
  });

  return data;
}

const getOfficeAvailability = async (officeId, teamIds, awayTeamId, scheduleId, startDate, endDate, isSeasonView, includeAwaySlots, signal) => {
  const { data } = await apiClient({
    method: 'GET',
    url: `/offices/${officeId}/teamAvailabilities`,
    params: { teamIds, awayTeamId, scheduleId, startDate, endDate, includePractices: isSeasonView, includeActivities: isSeasonView, includeAwaySlots },
    signal,
  })

  return data
}

const useScheduleTeams = scheduleId => useQuery({
  type: GET_LIST,
  resource: 'scheduleteams',
  payload: {
    filter: { scheduleId, _include: 'team' },
    sort: { field: 'team.name', order: 'ASC' },
  }
}, {
  enabled: scheduleId != null,
})

export const useSchedulingContext = () => useContext(SchedulingContext) || {};

export const SchedulingProvider = memo(({ officeId, scheduleId: defaultScheduleId, draftId, resource, limitedView, showSchedule = false, onSave, disableScheduling = false, ...props }) => {
  const [ surfaceSizes, setSurfaceSizes ] = useSurfaceSizes()
  const [ view ] = useCalendarView();
  const [ selectedGame, setSelectedGame ] = useState(null);
  const [ showAwaySlots ] = useShowAwaySlots();

  const scheduleId = useMemo(() => {
    return defaultScheduleId || selectedGame?.scheduleId;
  }, [ defaultScheduleId, selectedGame ])

  const { data: schedule, loading: scheduleLoading } = useSchedule(scheduleId);
  const { data: scheduleTeams, loading: scheduleTeamsLoading } = useScheduleTeams(scheduleId);
  const { data: scheduleSettings, loading: scheduleSettingsLoading } = useScheduleSettings(scheduleId);

  const [ dateFilter, setDateFilter ] = useState(null);
  const [ availability, setAvailability ] = useState(null);
  const [ refreshAvailability, setRefreshAvailability ] = useState(false)
  const [ loading, setLoading ] = useState(0);
  const [ gameLength, setGameLength ] = useState(null);
  const [ surfaceOfficeId, setSurfaceOfficeId ] = useState(null);
  const [ deferDraftConflict, setDeferDraftConflict ] = useState(false);
  const [ limitDateChange, setLimitDateChange ] = useState(false);
  const { homeTeamId, awayTeamId, date, arenaId } = selectedGame || {}
  const { startDate, endDate } = schedule || {}

  useEffect(() => {
    // day, week availability
    if (![CALENDAR_VIEWS.DAY, CALENDAR_VIEWS.WEEK].includes(view)) return;

    setAvailability(null);

    if (!scheduleId || !startDate || !homeTeamId || disableScheduling) return;

    const abortController = new AbortController();
    if (date && view === CALENDAR_VIEWS.DAY) {
      // load day availability
      setLoading(loading => ++loading);
      getScheduleAvailability(scheduleId, homeTeamId, date, arenaId ? [arenaId] : undefined, abortController.signal)
        .then(setAvailability)
        .finally(() => {
          setLoading(loading => --loading);
        })
    } else if (startDate && view !== CALENDAR_VIEWS.DAY) {
      // load teams' availability
      setLoading(loading => ++loading);
      const teamIds = [homeTeamId, awayTeamId].filter(Boolean);

      getOfficeAvailability(officeId, teamIds, awayTeamId, scheduleId, startDate, endDate, false, showAwaySlots, abortController.signal)
        .then(setAvailability)
        .finally(() => {
          setLoading(loading => --loading);
        })
    }

    return () => {
      abortController.abort();
    }
  }, [ homeTeamId, awayTeamId, date, arenaId, scheduleId, startDate, endDate, view, officeId, disableScheduling, scheduleTeams, showAwaySlots ])

  useEffect(() => {
    // month view availability
    if (view !== CALENDAR_VIEWS.MONTH || dateFilter?.type !== CALENDAR_VIEWS.MONTH) return;

    const teamIds = [homeTeamId, awayTeamId].filter(Boolean);
    if (!teamIds.length) return;

    const abortController = new AbortController();

    setLoading(loading => ++loading);
    getOfficeAvailability(officeId, teamIds, awayTeamId, scheduleId, dateFilter.startDate, dateFilter.endDate, false, showAwaySlots, abortController.signal)
      .then(setAvailability)
      .finally(() => {
        setLoading(loading => --loading);
      })

    return () => {
      abortController.abort();
    }
  }, [homeTeamId, awayTeamId, officeId, scheduleId, startDate, endDate, view, showAwaySlots, dateFilter?.type, dateFilter?.startDate, dateFilter?.endDate])

  useEffect(() => {
    // season view availability
    if (view !== CALENDAR_VIEWS.SEASON || dateFilter?.type !== CALENDAR_VIEWS.SEASON) return;

    if (refreshAvailability) {
      return setRefreshAvailability(false);
    }

    const teamIds = dedupe((scheduleTeams || []).map(scheduleTeam => scheduleTeam.teamId))
    if (!teamIds.length) return;

    const abortController = new AbortController();
    setLoading(loading => ++loading);
    getOfficeAvailability(officeId, teamIds, null, scheduleId, dateFilter.startDate, dateFilter.endDate, true, true, abortController.signal)
      .then(setAvailability)
      .finally(() => {
        setLoading(loading => --loading);
      })

    return () => {
      abortController.abort();
    }
  }, [officeId, refreshAvailability, scheduleId, scheduleTeams, view, dateFilter?.startDate, dateFilter?.endDate, dateFilter?.type])

  useEffect(() => {
    if (!scheduleSettings || scheduleSettingsLoading) return;
    setSurfaceOfficeId(scheduleSettings?.surfaceOfficeId)
    setGameLength(scheduleSettings.gameLength);
    setDeferDraftConflict(scheduleSettings.deferDraftConflict);
    setLimitDateChange(scheduleSettings.limitDateChange)

    if (!setSurfaceSizes || !scheduleSettings?.surfaceSizes?.length) return;
    setSurfaceSizes(scheduleSettings.surfaceSizes);
  }, [scheduleSettings, scheduleSettingsLoading, setSurfaceSizes])

  const value = useMemo(() => ({
    resource,
    schedule,
    surfaceOfficeId,
    selectedGame,
    availability,
    setSelectedGame,
    dateFilter,
    setDateFilter,
    draftId,
    limitedView,
    loading: loading !== 0 || scheduleLoading || scheduleTeamsLoading || scheduleSettingsLoading,
    gameLength,
    deferDraftConflict,
    limitDateChange,
    onSave,
    showSchedule,
    disableScheduling,
    scheduleTeams,
    setRefreshAvailability,
  }), [resource, schedule, surfaceOfficeId, selectedGame, availability, draftId, limitedView, loading, scheduleLoading, scheduleTeamsLoading, scheduleSettingsLoading, gameLength, deferDraftConflict, limitDateChange, onSave, showSchedule, disableScheduling, scheduleTeams, dateFilter]);

  return <SchedulingContext.Provider value={value} {...props} />
})
