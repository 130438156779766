import moment from 'moment-timezone';
import Papa from 'papaparse'

import { appName } from '../../../flags';
import { getSurfaceName } from '../../surfaces/util';

export default (transactions, translate, hidePayPeriod = false) => {
  const data = transactions.map(transaction => {
    const payPeriod = transaction.payPeriod;
    const participant = transaction.participant;
    const game = transaction.game;
    const schedule = game?.schedule;
    const scheduleOffice = schedule?.office;
    const surface = game?.surface;
    const office = transaction.office;
    const amount = Number(transaction.amount).toFixed(2);
    const subtype = transaction.type === 'Base' ?
      translate(`resources.games.values.assignment.position.${transaction.subtype}`) :
      translate(`resources.officialTransactions.values.subtype.${transaction.subtype}`)

    return [
      !hidePayPeriod ? payPeriod?.name : false,
      game?.number,
      moment.tz(game.startTime, game.timezone).format('YYYY-MM-DD HH:mm'),
      getSurfaceName(surface),
      schedule?.name,
      scheduleOffice?.name,
      participant?.firstName,
      participant?.lastName,
      participant?.HCRId,
      office?.name,
      moment.utc(transaction.date).format('YYYY-MM-DD'),
      translate(`resources.officialTransactions.values.type.${transaction.type}`, { _: transaction.type }),
      transaction.subtype ? subtype : null,
      amount,
    ].filter(value => value !== false)
  })

  const csv = Papa.unparse({
    fields: [
      !hidePayPeriod && translate('components.exporter.transactions_exporter.reports.details.columns.pay_period'),
      translate('components.exporter.transactions_exporter.reports.details.columns.game_number'),
      translate('components.exporter.transactions_exporter.reports.details.columns.game_time'),
      translate('components.exporter.transactions_exporter.reports.details.columns.surface'),
      translate('components.exporter.transactions_exporter.reports.details.columns.schedule_name'),
      translate('components.exporter.transactions_exporter.reports.details.columns.schedule_office_name'),
      translate('components.exporter.transactions_exporter.reports.details.columns.first_name'),
      translate('components.exporter.transactions_exporter.reports.details.columns.last_name'),
      translate('components.exporter.transactions_exporter.reports.details.columns.member_id'),
      translate('components.exporter.transactions_exporter.reports.details.columns.assigner'),
      translate('components.exporter.transactions_exporter.reports.details.columns.transaction_date'),
      translate('components.exporter.transactions_exporter.reports.details.columns.type'),
      translate('components.exporter.transactions_exporter.reports.details.columns.subtype'),
      translate('components.exporter.transactions_exporter.reports.details.columns.amount'),
    ].filter(field => field !== false),
    data,
  });
  const fileName = `${appName} - ${translate('components.exporter.transactions_exporter.reports.details.title')} - ${moment().format('YYYY-MM-DD HH:mm')}`;


  return { csv, fileName };
}
