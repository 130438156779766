import React from 'react';
import { useLocale, useRecordContext } from 'react-admin';
import { Typography, styled } from '@material-ui/core';
import moment from 'moment-timezone';

import { isLocalTimezone } from '@hisports/parsers/src/dateTime';
import { getDateFormat } from './DateField';

const Alt = styled('div')(({ theme }) => ({
  color: theme.palette.text.disabled,
}))

const formatRange = (start, end, { showDate, timezone, locale }) => {
  const startTime = timezone ? moment.tz(start, timezone) : moment(start)
  const endTime = timezone ? moment.tz(end, timezone) : moment(end)

  let startFormat = 'HH:mm';
  if (showDate) {
    const dateFormat = getDateFormat(locale);
    startFormat = moment().isSame(startTime, 'year') ? `${dateFormat}, HH:mm` : 'll, HH:mm'
  }
  let endFormat = 'HH:mm';
  if (timezone) {
    endFormat = 'HH:mm z';
  }
  return `${startTime.format(startFormat)} - ${endTime.format(endFormat)}`
}

const TimeRangeField = ({ className, startSource, endSource, emptyText, showDate = false, showLocal = false, variant = 'body2', ...props }) => {
  const locale = useLocale();
  const record = useRecordContext(props);
  let range = emptyText;

  if (record && record[startSource] && record[endSource]) {
    range = formatRange(record[startSource], record[endSource], { showDate, locale, timezone: showLocal ? moment.tz.guess() : record.timezone })
    if (showLocal && !isLocalTimezone(record[startSource], record.timezone)) {
      const tzRange = formatRange(record[startSource], record[endSource], { locale, showDate, timezone: record.timezone })
      range = <>
        <div>{tzRange}</div>
        <Alt>{range}</Alt>
      </>
    }
  }

  if (!range) return null;

  return <Typography
    component="span"
    variant={variant}
    noWrap
    className={className}
  >
    {range}
  </Typography>
}

TimeRangeField.defaultProps = {
  label: 'Time',
  addLabel: true
}

export default TimeRangeField;
