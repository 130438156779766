import React, { useState } from 'react'
import { TextInput, useInput } from 'react-admin'
import MaskedInput from 'react-text-mask'
import { InputAdornment } from '@material-ui/core'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: true,
    allowLeadingZeroes: false,
  })

  return <MaskedInput mask={currencyMask} {...inputProps} />
}

export default ({ nullable = false, ...props }) => {
  const {
    input: { value, onFocus, onBlur },
  } = useInput(props);

  const [ focused, setFocused ] = useState(false);

  const onInputFocus = e => {
    setFocused(true)
    return onFocus(e)
  }

  const onInputBlur = e => {
    setFocused(false);
    return onBlur(e)
  }

  const parse = price => {
    if (nullable && !price && price !== 0) return null;

    price = price || '';

    if (price === '-_') return price;

    return Number(price.replace(/[^0-9.-]+/g, ""));
  }

  const format = price => {
    if (price == null || isNaN(Number(price)) || focused) return price
    return Number(price).toFixed(2)
  }

  const shrink = focused || !!value || value === 0;

  return <TextInput
    {...props}
    onFocus={onInputFocus}
    onBlur={onInputBlur}
    parse={parse}
    format={format}
    options={{
      ...props.options,
      InputProps: {
        inputComponent: CurrencyInput,
        startAdornment: shrink ? <InputAdornment>$</InputAdornment> : undefined
      },
      InputLabelProps: {
        shrink,
      }
    }}
  />
}
