import React, { useContext, useEffect, useState } from 'react';
import { useRecordContext, useTranslate, useVersion } from 'react-admin';
import { NotificationsOffOutlined } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';

import { FF_SSO } from '@hisports/common/featureFlags';

import { apiClient, useFlag } from '../../http';
import { InviteButton, SsoInviteButton } from './cards/ParticipantCard';

const ParticipantStatusContext = React.createContext(null);

export const useParticipantStatus = () => useContext(ParticipantStatusContext);

export const ParticipantStatusProvider = props => {
  const participant = useRecordContext();
  const [ status, setStatus ] = useState();
  const version = useVersion();

  useEffect(() => {
    apiClient(`/participants/${participant.id}/status`, { method: 'GET' })
      .then(res => res.data)
      .then(status => {
        setStatus(status)
      })
  }, [ participant.id, version ])

  if (!status) return null;
  return <ParticipantStatusContext.Provider value={status} {...props} />
}

export const ParticipantStatusAlert = props => {
  const participant = useRecordContext();
  const translate = useTranslate();
  const { types, accounts, contacts, notifications } = useParticipantStatus();
  const isEnabled = useFlag();

  if (!types.length) return <Alert severity="error">
    <AlertTitle>{translate('resources.participants.labels.status.roles_title')}</AlertTitle>
    {!accounts
      ? translate('resources.participants.labels.status.roles_body')
      : translate('resources.participants.labels.status.roles_unauthorized')
    }
  </Alert>

  if (!accounts) return <Alert severity="info" action={
    !isEnabled(FF_SSO)
      ? <InviteButton participant={participant} />
      : <SsoInviteButton participant={participant} />
  }>
    <AlertTitle>{translate('resources.participants.labels.status.account_title')}</AlertTitle>
    {translate('resources.participants.labels.status.account_body')}
  </Alert>

  if (accounts === 'Invited') return <Alert severity="success" action={
    !isEnabled(FF_SSO)
      ? <InviteButton participant={participant} />
      : <SsoInviteButton participant={participant} />
  }>
    <AlertTitle>{translate('resources.participants.labels.status.invited_title')}</AlertTitle>
    {translate('resources.participants.labels.status.invited_body')}
  </Alert>

  if (notifications) {
    if (!contacts) return <Alert severity="warning">
      <AlertTitle>{translate('resources.participants.labels.status.contacts_title')}</AlertTitle>
      {translate('resources.participants.labels.status.contacts_body')}
    </Alert>

    if (contacts === 'Unverified') return <Alert severity="warning" icon={<NotificationsOffOutlined />}>
      <AlertTitle>{translate('resources.participants.labels.status.unverified_title')}</AlertTitle>
      {translate('resources.participants.labels.status.unverified_body')}
    </Alert>
  }

  return null;
}
