import React, { useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { SupervisorAccountOutlined } from '@material-ui/icons';
import { Button, makeStyles } from '@material-ui/core';
import { useNotify, useRedirect, useTranslate } from 'react-admin';
import qs from 'qs';

import { apiClient, authService, useAccount } from '../../../http';

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.lightBlue[500],
    backgroundColor: theme.palette.lightBlue[50]
  },
  ssoIcon: {
    color: theme.palette.lightBlue[500]
  }
}))

export default ({ ...props }) => {
  const account = useAccount()
  const translate = useTranslate();
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    if (account?.externalId) return;
    const smaAccessToken = qs.parse(window?.location?.search, { ignoreQueryPrefix: true }).accessToken;
    if (!smaAccessToken) return;

    // strip any url params
    redirect('/profile')

    const ssoLink = async () => {
      return apiClient('/accounts/ssoLinkAccount', {
        method: 'POST',
        data: { smaAccessToken }
      })
        .then(({ data }) => {
          if (data?.externalId) {
            notify('components.login.messages.sso.link_success', 'success')
            authService.refreshAccount();
          }
        })
        .catch(e => notify('components.login.messages.sso.link_error', 'error'))
    };

    ssoLink();
  }, [notify, redirect, account?.externalId])

  const handleClick = async () => {
    return apiClient('/accounts/ssoLinkAccountRedirect', {
      method: 'GET',
      params: { username: account?.username },
    })
      .then(({ data: redirectUrl }) => {
        if (!redirectUrl) return;

        window.location.href = redirectUrl
      })
      .catch(e => notify('components.login.messages.sso.link_error', 'error'))
  }

  return <Alert
    className={classes.alert}
    severity="info"
    icon={<SupervisorAccountOutlined className={classes.ssoIcon} />}
    action={
      <Button color="inherit" size="small" onClick={handleClick}>
        {translate('components.login.actions.link_account')}
      </Button>
    }
  >
    <span style={{ fontWeight: "500" }}>{translate('components.login.alerts.sso_link')}</span>
  </Alert>
}
