import React from 'react';
import { Filter, TextInput, BooleanInput } from 'react-admin';

import { LATEST_SEASON } from '@hisports/common/src/seasons';

import { ScheduleTypeEnumInput, GameFormatEnumInput } from '../../common/inputs/EnumInputs';
import { useSeason, SeasonFilter } from '../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../common/Authorize';
import { FilterCard } from '../../common/cards/FilterCard';
import { SavedSearches } from '../../common/SavedSearches';
import { useScopes, useDefaultSeason } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';
import { CategoryInput } from '../categories/CategoryInput';

const multipleProps = {
  multiple: true,
  alwaysOn: true,
  fullWidth: true,
  allowEmpty: false,
}

const ScheduleFilters = props => {
  const scopes = useScopes();
  const seasonId = useSeason();
  const defaultSeason = useDefaultSeason();

  const canListOffices = isAuthorized(scopes, 'offices', 'show');
  const showPastSeason = (seasonId === LATEST_SEASON) || (seasonId === defaultSeason);

  return <Filter variant="outlined" margin="dense" fullWidth {...props}>
    <TextInput source="name" alwaysOn fullWidth />
    {canListOffices && <OfficeInput source="officeId" {...multipleProps} />}
    <ScheduleTypeEnumInput source="type" alwaysOn {...multipleProps} />
    <CategoryInput source="categoryId" {...multipleProps} />
    <GameFormatEnumInput source="gameFormat" {...multipleProps} />
    {showPastSeason && <BooleanInput source="pastEvents" label="resources.schedules.filters.pastEvents" alwaysOn fullWidth />}
    <SeasonFilter source="seasonId" alwaysOn fullWidth />
  </Filter>
}

export const ScheduleFilterList = () => {
  return <FilterCard>
    <ScheduleFilters />
    <SavedSearches ignoredFilters={['seasonId', 'pastEvents']} />
  </FilterCard>
}
