import React from 'react';
import { useTranslate } from 'react-admin';

import { useScopes } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import ListCard from '../../../common/cards/ListCard';

import { AnnouncementGrid } from '../../../resources/announcements/AnnouncementGrid';
import { AddAnnouncementButton } from '../../../resources/announcements/AnnouncementModal';
import { AnnouncementExpand } from '../../../resources/announcements/AnnouncementShow'

export default props => {
  const scopes = useScopes();
  const translate = useTranslate();
  const seasonId = useSeason();

  const canAddAnnouncement = isAuthorized(scopes, 'announcements', 'create');
  const canListOffices = isAuthorized(scopes, 'offices', 'list');

  const addButton = canAddAnnouncement && <AddAnnouncementButton key="add-announcement" initialValues={{ type: 'Officiating' }} variant="outlined" />

  return <ListCard
    title={translate('resources.announcements.labels.officiating')}
    subtitle={canAddAnnouncement && translate('resources.announcements.messages.send_email_officials')}
    resource="announcements"
    basePath="/announcements"
    filter={{ _scope: 'Parents', seasonId }}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    addButton={addButton}
    perPage={5}
    rowsPerPageOptions={[]}
  >
    <AnnouncementGrid rowClick="expand" expand={<AnnouncementExpand />} />
  </ListCard>
}
