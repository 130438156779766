import React from 'react';
import { BooleanField, NumberField, useTranslate } from 'react-admin';

import FunctionField from '../../common/fields/FunctionField';

import { InfractionTypeDatagrid } from './InfractionTypeModal';
import { TextLocaleField } from '../../common/fields/TextLocaleField';

const renderDuration = (duration, translate) => {
  if (!duration) return;
  const minutes = Math.floor(duration / 60);
  return `${minutes} ${translate('ra.input.adornments.minutes')}`
}

export const InfractionTypeGrid = props => {
  const translate = useTranslate();

  return <InfractionTypeDatagrid size="medium" {...props}>
    <TextLocaleField source="name" />
    <TextLocaleField source="abbreviation" />
    <TextLocaleField source="description" />
    <FunctionField source="duration" render={({ duration }) => renderDuration(duration, translate)} />
    <BooleanField source="isShorthanded" />
    <BooleanField source="isEjection" />
    <NumberField source="servingOrder" />
  </InfractionTypeDatagrid>
}
