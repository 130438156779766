import React from 'react';
import { Filter, TextInput } from 'react-admin';

import { isAuthorized } from '../../common/Authorize';
import { SeasonFilter } from '../../common/inputs/SeasonSelector';
import { useScopes } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';
import { CategoryInput } from '../categories/CategoryInput';

const multipleProps = {
  multiple: true,
  allowEmpty: false,
}

export const TeamFilters = props => {
  const scopes = useScopes();
  const canListOffices = isAuthorized(scopes, 'offices', 'show');

  return <Filter variant="outlined" {...props}>
    <TextInput source="name" alwaysOn />
    <TextInput source="HCRId" alwaysOn />
    {canListOffices && <OfficeInput source="officeId" {...multipleProps} />}
    <CategoryInput source="categoryId" {...multipleProps} />
    <SeasonFilter source="seasonId" alwaysOn />
  </Filter>
}
