import React, { useState } from 'react';
import { GET_MANY, useQueryWithStore, useTranslate } from 'react-admin';
import { Dialog, DialogActions, DialogTitle, Card, CardContent, Typography, makeStyles, useMediaQuery, CardMedia, Divider, IconButton, DialogContent } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close, Photo } from '@material-ui/icons';

import noProfileIcon from '../../../../assets/icons/no-profile.png';
import { ResponsiveButton } from '../../../common/ResponsiveButton';

import { useMeta } from '../ScoresheetContext';

import { useMemberValidationContext } from './MemberValidationContext';
import { useMemberItem, MemberItemContextProvider } from './MemberItemContext';
import { MemberFlagsList } from './MemberFlags';
import { getMemberDescription } from './MemberItem';

const useStyles = makeStyles(theme => ({
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  content: {
    padding: 0,
  },
  actions: {
    justifyContent: 'center',
  },
  media: {
    height: 450,
    objectFit: 'contain',
  },
  chip: {
    margin: '2px'
  },
  suspensionList: {
    gap: theme.spacing(2), // suspension is a group of 2 listItem. gap to seperate each suspension
  }
}));


const PhotoCard = ({ participant = {}, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { member, memberValidation, flagNames } = useMemberItem()
  if (!member) return null;

  return <Card fullWidth elevation={0}>
    <CardMedia
      className={classes.media}
      src={memberValidation?.pictureUrl || noProfileIcon}
      component="img"
    />
    <CardContent>
      <Typography variant="h5" component="h2">
        {member?.number != null && <Typography variant="inherit" component="span" color="textSecondary">
          #{member.number}&nbsp;
        </Typography>}
        {member?.participant?.fullName}
        {participant?.HCRId != null && <Typography variant="inherit" component="span" color="textSecondary">
          &nbsp;– {participant.HCRId}
        </Typography>}
      </Typography>
      <Typography variant="subtitle" color="textSecondary" component="p">
        {getMemberDescription(member, translate, true)}
      </Typography>
    </CardContent>
    {flagNames.length > 0 && <Divider />}
    <MemberFlagsList member={member} flagNames={flagNames} />
  </Card>
}

const PhotosDialog = ({ open, onClose, teamId, lineup, ...props }) => {
  const classes = useStyles();
  const { meta } = useMeta();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const [ index, setIndex ] = useState(0);
  const { memberValidations } = useMemberValidationContext();

  const handleNext = () => setIndex(index +1);
  const handleBack = () => setIndex(index - 1);
  const handleClose = () => onClose();

  const team = meta.teams[teamId];
  const members = (lineup?.members || []).map(member => {
    const memberValidation = memberValidations.find(({ participantId }) => participantId === member.participantId);
    return { ...member, ...memberValidation }
  });

  const { data: participants } = useQueryWithStore({
    type: GET_MANY,
    resource: 'participants',
    payload: { ids: members.map(member => member.participantId), _scope: 'Tenant' }
  }, { enabled: open, action: 'CUSTOM_QUERY' })
  const participant = participants?.find(participant => participant.id === members[index]?.participantId)

  return <Dialog
    open={open}
    fullWidth
    fullScreen={fullScreen}
    maxWidth={fullScreen ? "xs" : "sm"}
    onBackdropClick={() => handleClose()}
  >
    <DialogTitle>{team?.name}</DialogTitle>
    <div className={classes.close}>
      <IconButton onClick={() => handleClose()}><Close /></IconButton>
    </div>
    <DialogContent dividers className={classes.content}>
      <MemberItemContextProvider member={members[index]} teamId={teamId}>
        <PhotoCard participant={participant} />
      </MemberItemContextProvider>
    </DialogContent>
    <DialogActions className={classes.actions}>
      {!!members.length && <>
        <IconButton disabled={index === 0} onClick={() => handleBack()}><ChevronLeft /></IconButton>
        <IconButton disabled={(index + 1) === members.length} onClick={() => handleNext()}><ChevronRight /></IconButton>
      </>}
    </DialogActions>
  </Dialog>
}

export const PhotosButton = ({ teamId, lineup, disabled, ...props }) => {
  const translate = useTranslate();
  const { loading } = useMemberValidationContext();
  const [ open, setOpen ] = useState(false);

  return <>
    <ResponsiveButton icon={Photo} onClick={() => setOpen(true)} disabled={disabled || loading}>
      {translate('resources.scoresheets.labels.photos')}
    </ResponsiveButton>
    <PhotosDialog open={open} onClose={() => setOpen(false)} teamId={teamId} lineup={lineup} />
  </>
}
