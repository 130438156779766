import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { ArenaOfficeForm } from './ArenaOfficeForm';

const AddArenaOfficeDialog = ({ target, ...props }) => {
  const label = target === "arenaId" ? "resources.offices.actions.add" : "resources.surfaces.actions.add"
  return <CreateResourceDialog label={label} resource="arenaoffices" basePath="/arenaoffices" {...props}>
    <ArenaOfficeForm target={target} />
  </CreateResourceDialog>
}

const EditArenaOfficeDialog = ({ target, ...props }) => {
  const label = target === "arenaId" ? "resources.offices.labels.edit" : "resources.surfaces.labels.edit"
  return <EditResourceDialog label={label} resource="arenaoffices" basePath="/arenaoffices" {...props}>
    <ArenaOfficeForm target={target} />
  </EditResourceDialog>
}

export const AddArenaOfficeButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  const buttonLabel = props.target === "arenaId" ? "resources.offices.actions.add" : "resources.surfaces.actions.add"
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate(buttonLabel)}</Button>
    <AddArenaOfficeDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const ArenaOfficeDatagrid = props => {
  return <DialogDatagrid dialog={EditArenaOfficeDialog} {...props} />
}
