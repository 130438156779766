import React, { Fragment, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import NotificationForm from './NotificationForm';

const AddNotificationDialog = props =>
  <CreateResourceDialog label="resources.notifications.actions.add" resource="notifications" basePath="/notifications" {...props}>
    <NotificationForm />
  </CreateResourceDialog>

const EditNotificationDialog = props => {
  const notification = useRecordContext(props);

  const id = (notification?.id) || (props.initialValues?.id)
  const exists = id && !String(id).startsWith("_");
  const ResourceDialog = exists ? EditResourceDialog : CreateResourceDialog;
  return <ResourceDialog label="resources.notifications.labels.update_source" resource="notifications" basePath="/notifications" {...props}>
    <NotificationForm />
  </ResourceDialog>
}

export const AddNotificationButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.notifications.actions.add')}</Button>
    <AddNotificationDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const NotificationDatagrid = props => <DialogDatagrid dialog={EditNotificationDialog} {...props} />
