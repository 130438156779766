import React, { Fragment } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from "@material-ui/core"
import { SimpleForm, useNotify, useRecordContext, useRefresh, useTranslate } from "react-admin"
import { certifyScoresheet } from '@hisports/scoresheet/src/actions';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';

import { FlagList } from "./FlagList"
import { useScoresheetDispatch } from '../ScoresheetContext';

const certify = async (game, dispatch, notify) => {
  const dispatches = [
    certifyScoresheet(game.id)
  ]
  const results = await Promise.allSettled(dispatches.map(event => {
    try {
      return dispatch(event);
    } catch (e) {
      // catch validation errors caused by the dispatches
      // eslint-disable-next-line no-console
      console.error(e)
      return Promise.reject(e)
    }
  }))

  const isSuccessful = (results || []).every(result => result.status === 'fulfilled')
  const message = isSuccessful ? 'resources.scoresheets.messages.successful_game_certify' : 'ra.message.error';
  const notificationType = isSuccessful ? 'success' : 'error';

  notify(message, notificationType)
}

export const CertificationModal = ({ open, setModalOpen, onClose, customHeader: CustomHeader, hideCancel, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const translate = useTranslate();
  const dispatch = useScoresheetDispatch();
  const game = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = async () => {
    await certify(game, dispatch, notify);

    if (typeof setModalOpen === 'function') {
      setModalOpen(false);
    }
    if (typeof onClose === 'function') {
      onClose(false);
    }

    setTimeout(() => refresh(), 500);
  }

  const onCancel = () => {
    if (typeof setModalOpen === 'function') {
      setModalOpen(false);
    }
  }

  const Header = CustomHeader || <DialogTitle>{translate('resources.scoresheets.labels.certify', 1)}</DialogTitle>

  return <Dialog open={open} fullWidth fullScreen={fullScreen} maxWidth={fullScreen ? "xs" : "sm"} {...props}>
    {Header}
    <DialogContent>
      <DialogContentText>
        {translate('resources.scoresheets.messages.confirm_certify', 1)}
      </DialogContentText>
      <SimpleForm
        save={handleSubmit}
        component={Fragment}
        toolbar={
          <DialogFormToolbar
            submitLabel="ra.action.certify"
            cancelLabel="ra.action.cancel"
            onCancel={hideCancel ? undefined : onCancel}
          />
        }
      >
        <FlagList checkable={false} />
      </SimpleForm>
    </DialogContent>
  </Dialog>
}
