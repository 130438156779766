import { Typography } from '@material-ui/core';
import React from 'react';
import { ReferenceField, useRecordContext, useTranslate } from 'react-admin';

import { isAuthorized } from '../../common/Authorize';
import FunctionField from '../../common/fields/FunctionField'
import { useSeason } from '../../common/inputs/SeasonSelector';
import { CategoryField } from '../categories/CategoryField';
import { SeasonField } from '../seasons/SeasonField';

export const ScheduleField = ({ includeSeason = false, includeCategory = false, includeType = false, link = 'show', variant = 'body2', className, ...props }) => {
  const seasonId = useSeason();
  const record = useRecordContext(props);
  const translate = useTranslate();

  const showLink = isAuthorized(record, 'schedules', 'show') ? link : false;
  return <ReferenceField basePath="/schedules" resource="schedules" reference="schedules" link={showLink} {...props}>
    <FunctionField source="name" render={schedule => {
      const inline = [
        includeSeason === 'inline' && seasonId !== schedule.seasonId && <SeasonField source="seasonId" />,
        includeCategory === 'inline' && <CategoryField source="categoryId" variant="inherit" />,
        includeType === 'inline' && translate(`resources.schedules.values.type.${schedule.type}`),
      ].filter(Boolean).flatMap(e => [e, ' ']).slice(0, -1)
      const full = [
        includeSeason === 'full' && seasonId !== schedule.seasonId && <SeasonField source="seasonId" />,
        includeCategory === 'full' && <CategoryField source="categoryId" variant="inherit" />,
        includeType === 'full' && translate(`resources.schedules.values.type.${schedule.type}`),
      ].filter(Boolean).flatMap(e => [e, ' ']).slice(0, -1)
      return <>
        <Typography variant={variant} display="inline">{schedule.name}</Typography>
        {inline.length > 0 && <span><Typography variant={variant} display="inline" color="textSecondary"> {inline}</Typography></span>}
        {full.length > 0 && <div><Typography variant={variant} display="inline" color="textSecondary">{full}</Typography></div>}
      </>
    }} variant={variant} className={className} />
  </ReferenceField>
}

ScheduleField.defaultProps = {
  addLabel: true,
}
