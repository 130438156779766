import React from 'react';
import { BooleanInput } from 'react-admin';
import { makeStyles } from '@material-ui/core'

const useGroupStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(-2),
  }
}))

const useSwitchStyles = makeStyles(theme => ({
  root: {
    order: 2,
    marginLeft: 'auto',
  },
}))

export default ({ classes: classesOverride, ...props }) => {
  const groupClasses = useGroupStyles(classesOverride);
  const switchClasses = useSwitchStyles(classesOverride);
  return <BooleanInput {...props} classes={groupClasses} options={{ classes: switchClasses }} />
}
