import React, { useState } from 'react'
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core'
import { ExpandLess, ExpandMore, Flag } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useTranslate } from 'react-admin'

import { getCertificationFlags } from '@hisports/scoresheet/src/selectors'
import { TEAM_FLAGS } from '@hisports/scoresheet/src/constants'

import { useScoresheet } from '../ScoresheetContext'

const LINEUP_FLAGS = [
  TEAM_FLAGS.LINEUP_MIN_LIMIT,
  TEAM_FLAGS.LINEUP_MAX_LIMIT,
  TEAM_FLAGS.NOT_ROSTERED,
]

const useStyles = makeStyles(theme => ({
  action: {
    alignItems: 'flex-start',
  },
  ul: {
    paddingLeft: theme.spacing(2),
  },
  liText: {
    display: 'inline',
    verticalAlign: 'middle'
  }
}))

const LineupFlags = ({ teamId }) => {
  const [isOpen, setOpen] = useState(true);
  const classes = useStyles();
  const translate = useTranslate();
  const flags = useScoresheet(scoreSheet => getCertificationFlags(scoreSheet))

  const teamFlags = flags.filter(flag => flag.teamId === teamId);
  const lineupFlags = teamFlags.filter(flag => LINEUP_FLAGS.includes(flag.name) ? flag : null);
  if (!lineupFlags.length) return null;

  const toggle = () => setOpen(open => !open)
  const toggleAction = <IconButton onClick={toggle}>
    {isOpen ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
  </IconButton>

  return (
    <Box m={1} >
      <Alert
        icon={<Flag />}
        severity="error"
        action={toggleAction}
        classes={classes}>
        <AlertTitle>Lineup Flags</AlertTitle>
        {isOpen && <ul className={classes.ul}>
          {lineupFlags.map(flag => (
            <li key={flag.name}>
              <Typography variant="body1" className={classes.liText}>{translate(`resources.games.labels.flags.${flag.name}`, 2)}</Typography>
              {flag.name === TEAM_FLAGS.NOT_ROSTERED &&
                <Typography color="textSecondary" variant="body2">{translate(`resources.games.labels.flagsDetails.${TEAM_FLAGS.NOT_ROSTERED}`)}</Typography>
              }
            </li>
          ))}
        </ul>}
      </Alert>
    </Box>
  )
}

export default LineupFlags
