import React, { useContext, useMemo, useState } from 'react';
import { useGetMany, useTranslate } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';

import { useDefaultOffice } from '../../http';

import OfficeSelectorDialog from '../dialogs/OfficeSelectorDialog';

export const OfficeContext = React.createContext([null, () => {}]);
export const OfficeProvider = props => {
  const defaultOffice = useDefaultOffice();
  const [ officeId, setOfficeId ] = useState(defaultOffice);

  const value = useMemo(() => ({
    officeId,
    setOfficeId,
  }), [ officeId, setOfficeId ])

  return <OfficeContext.Provider value={value} {...props} />
}

export const useOffice = () => {
  const { officeId } = useContext(OfficeContext);
  return officeId;
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  }
}))

export default ({ filter, disabled, ...props }) => {
  const translate = useTranslate();
  const { officeId, setOfficeId } = useContext(OfficeContext);
  const { data, loaded, loading } = useGetMany('offices', [ officeId ], {
    enabled: officeId != null,
  });
  const office = data[0]

  const [ open, setOpen ] = useState(false);

  const onClose = () => setOpen(false);
  const handleSave = (values) => {
    const { selectedOfficeId } = values;
    setOfficeId(selectedOfficeId);
    onClose();
  }

  let label = translate('components.office_selector.actions.open')
  if (loaded && office?.name) {
    label = office?.name;
  } else if (loading && officeId) {
    label = translate('ra.page.loading');
  }

  const classes = useStyles();
  const icon = !disabled ? <Edit /> : <Visibility />;
  return <div className={classes.toolbar} >
    <Button color="primary" endIcon={icon} disabled={disabled} onClick={() => setOpen(true)}>
      {label}
    </Button>

    {open && <OfficeSelectorDialog open={open} onClose={onClose} handleSave={handleSave} filter={filter} />}
  </div>
}
