import React from 'react';
import { EditButton, useRecordContext, ReferenceField, TextField } from 'react-admin';
import { makeStyles, Table, TableBody, Typography } from '@material-ui/core';


import InfoCard from '../../common/cards/InfoCard';
import Row from '../../common/cards/TableRow';
import { EnumField } from '../../common/inputs/EnumInputs';
import { isAuthorized } from '../../common/Authorize';
import FunctionField from '../../common/fields/FunctionField';

import { ParticipantField } from '../participants/ParticipantField';
import { TeamField } from '../teams/TeamField';
import { ScheduleField } from '../schedules/ScheduleField';
import { GameField } from '../games/GameField';
import { PenaltyField } from '../scoresheet/gameIncidents/IncidentReportGrid';
import { SuspensionDetailsField } from '../scoresheet/suspension/SuspensionGrid';
import { AddSuspensionDisciplineButton, TriggerSuspensionDiscplineButton } from '../suspensionDiscipline/SuspensionDisciplineModal';

const useStyles = makeStyles(() => ({
  boldRow: {
    fontWeight: 'bold',
  }
}))

const isIndefinite = (durationType) => durationType === 'Indefinite';

const renderDisciplineAdditionalGames = (record, translate) => {
  const { disciplineDurationType, disciplineAdditionalGames, disciplineStatus } = record;
  const numOfGames = isIndefinite(disciplineDurationType) ? translate('resources.suspensionDisciplines.values.durationType.Indefinite') : disciplineAdditionalGames;
  const status = disciplineStatus ? ` (${translate(`resources.suspensionDisciplines.values.status.${disciplineStatus}`)})` : '';

  if (numOfGames == null) return translate('ra.page.not_available');

  return <Typography variant="body2">
    {numOfGames}
    <Typography variant="body2" component="span" color="textSecondary">{status}</Typography>
  </Typography>
};

const renderRequiredGames = (record, source, resource, translate) => {
  const { requiredGames, effectiveRequiredGames, durationType, effectiveDurationType } = record;

  switch (source) {
    case 'requiredGames':
      return isIndefinite(durationType) ? translate('resources.suspensions.values.durationType.Indefinite') : requiredGames;
    case 'disciplineAdditionalGames':
      return renderDisciplineAdditionalGames(record, translate);
    case 'effectiveRequiredGames':
      return isIndefinite(effectiveDurationType) ? translate('resources.suspensions.values.durationType.Indefinite') : effectiveRequiredGames;
    default:
      return null;
  }
};

export default ({ hasDiscipline, disciplinesEnabled, ...props }) => {
  const classes = useStyles();
  const suspension = useRecordContext(props);

  const canEditSuspension = isAuthorized(suspension, 'suspensions', 'edit');
  const canAddSuspensionDiscipline = disciplinesEnabled && isAuthorized(suspension, 'suspensionDisciplines', 'create') && !hasDiscipline;
  const canTriggerSuspensionDiscipline = disciplinesEnabled && isAuthorized(suspension, 'suspensionDisciplines', 'trigger') && !hasDiscipline;

  return <InfoCard
    title="resources.suspensions.name"
    actions={[
      canEditSuspension && <EditButton label="resources.suspensions.labels.edit" record={suspension} />,
      canAddSuspensionDiscipline && <AddSuspensionDisciplineButton initialValues={{ suspensionId: suspension.id, durationType: 'Definite', status: 'Pending' }} />,
      canTriggerSuspensionDiscipline && <TriggerSuspensionDiscplineButton />,
    ].filter(Boolean)}
  >
    <Table>
      <TableBody>
        <Row label="resources.suspensions.fields.participantId">
          <ParticipantField source="participantId" includeId="full" />
        </Row>
        <Row label="resources.suspensions.fields.teamId">
          <TeamField source="teamId" includeId="full" includeCategory="full" includeSeason="full" />
        </Row>
        <Row label="resources.suspensions.fields.additionalPurgingTeamId">
          <TeamField source="additionalPurgingTeamId" includeId="full" includeCategory="full" includeSeason="full" noneLabel />
        </Row>
        <Row label="resources.games.fields.scheduleId">
          <ReferenceField source="gameId" reference="games" resource="games" basePath="/games" label="resources.games.fields.scheduleId" link={false}>
            <ScheduleField source="scheduleId" includeCategory="full" includeType="full" />
          </ReferenceField>
        </Row>
        <Row label="resources.suspensions.fields.gameId">
          <GameField source="gameId" link="scoresheet" includeDate="inline" includeSurface="full" />
        </Row>
        <Row label="resources.suspensions.fields.penaltyId">
          <PenaltyField source="penaltyId" />
        </Row>
        {!disciplinesEnabled
          ? <Row label="resources.suspensions.labels.details">
            <SuspensionDetailsField source="suspensionId" />
          </Row>
          : <>
            <Row label="resources.suspensions.fields.positionGroup">
              <TextField source="positionGroup" />
            </Row>
            <Row label="resources.suspensions.labels.requiredGames">
              <FunctionField source="requiredGames" render={renderRequiredGames} />
            </Row>
            <Row label="resources.suspensions.labels.disciplineAdditionalGames">
              <FunctionField source="disciplineAdditionalGames" render={renderRequiredGames} />
            </Row>
            <Row label="resources.suspensions.labels.effectiveRequiredGames" cellClass={classes.boldRow}>
              <FunctionField source="effectiveRequiredGames" className={classes.boldRow} render={renderRequiredGames} />
            </Row>
            <Row label="resources.suspensions.fields.status">
              <EnumField source="status" />
            </Row>
          </>}
      </TableBody>
    </Table>
  </InfoCard>
}
