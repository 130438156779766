import React from 'react';
import moment from 'moment';

import FunctionField from './FunctionField';

const renderCreatedAt = (record, source, resource, translate, includePrefix = false) => {
  const { createdAt, createdByAuthor } = record || {};
  const date = moment(createdAt).calendar()
  const prefix = includePrefix ? `${translate('ra.message.created')} ` : ''

  if (!createdAt) return null;
  if (!createdByAuthor) return `${prefix}${date}`;
  return `${prefix}${date} ${translate('ra.function.by')} ${createdByAuthor}`;
}

export const CreatedAtField = ({ includePrefix = false, ...props }) => {
  return <FunctionField source="createdAt" render={(...params) => renderCreatedAt(...params, includePrefix)} {...props} />
}
