import { downloadCSV } from 'react-admin';
import moment from 'moment-timezone';
import Papa from 'papaparse'

import { appName } from '../../flags';
import { getSurfaceName } from '../surfaces/util';

const draftGamesExporter = data => {
  const draftGames = data.map(draftGame => {
    const timezone = draftGame.timezone;
    const homeTeam = draftGame.homeTeam;
    const awayTeam = draftGame.awayTeam;
    const schedule = draftGame.schedule;
    const surface = draftGame.surface;
    const category = draftGame.category;

    return [
      schedule?.name,
      category?.name,
      draftGame.id,
      homeTeam?.name,
      homeTeam?.HCRId,
      awayTeam?.name,
      awayTeam?.HCRId,
      draftGame.date ? moment.utc(draftGame.date, 'YYYY-MM-DD').format('dddd') : '',
      draftGame.date ? moment.utc(draftGame.date, 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
      draftGame.startTime ? moment.tz(draftGame.startTime, timezone).format("HH:mm") : '',
      draftGame.endTime ? moment.tz(draftGame.endTime, timezone).format("HH:mm") : '',
      timezone,
      getSurfaceName(surface, true),
      draftGame.status,
      draftGame.comments,
    ]
  })

  const csv = Papa.unparse({
    fields: ["Schedule Name", "Category", "Draft Number", "Home Team Name", "Home Team Registry ID", "Away Team Name", "Away Team Registry ID", "Day", "Date", "Start Time", "End Time", "Timezone", "Venue", "Status", "Comments"],
    data: draftGames
  });
  downloadCSV(csv, `${appName} - Draft Games - ${moment().format('YYYYMMDDHHmmss')}`);
};

draftGamesExporter._include = [
  'homeTeam',
  'awayTeam',
  'schedule',
  'surface',
  'category',
]

export default draftGamesExporter;
