import React, { useEffect } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { Redirect } from 'react-router-dom';
import qs from 'qs';

import { apiFetchPublic } from '../../http';

export default ({ location }) => {
  const { contact, token } = qs.parse(location.search.substring(1));
  const notify = useNotify();
  const refresh = useRefresh();
  useEffect(() => {
    apiFetchPublic(`/contacts/${contact}/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ verification: token })
    }).then((res) => {
      if (res.ok) {
        notify('ra.message.verified_email');
        refresh();
      } else {
        notify('resources.contacts.messages.error_verify', 'error');
      }
    }).catch(() => {
      notify('resources.contacts.messages.error_verify', 'error')
    })
  }, [contact, token, notify, refresh]);

  return <Redirect to="/profile" />;
}
