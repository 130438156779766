import loopbackRestClient from './restClient';
import { AuthService } from './authService';
import { AuthClient } from './authClient';

export const authService = new AuthService(
  process.env.AUTH_CLIENT_ID,
  process.env.AUTH_CLIENT_SECRET
);

export const authClient = new AuthClient(authService)
export const restClient = loopbackRestClient();

export { default as apiClient, apiFetchPublic } from './apiClient';
export { default as useHttpClient } from './useHttpClient';

export { deviceId } from './deviceService';

export * from './AuthContext';
