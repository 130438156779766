import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { Autocomplete } from '@material-ui/lab';

import { apiClient } from '../../http';

const AddressAutoCompleteInput = ({ defaultAddress, gameId, source, ...props }) => {
  const { change } = useForm();
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    if (!defaultAddress) return;
    setInputValue(defaultAddress);
    change(source, defaultAddress);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputChange = (event) => {
    const value = event.target.value || '';

    setInputValue(value);
    change(source, value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setDebounceTimeout(
      setTimeout(() => {
        fetchSuggestions(value);
      }, 1000)
    );
  };

  const fetchSuggestions = useCallback(
    async (value) => {
      if (!value) return;

      try {
        setLoading(true);
        const response = await apiClient(
          `/games/${gameId}/getAddresses`,
          {
            params: {
              address: value,
            },
          }
        );

        if (response.data.status === 'OK') {
          setSuggestions(response.data.predictions);
        } else {
          setSuggestions([]);
        }
      } catch (error) {
        setSuggestions([]);
      } finally {
        setLoading(false);
      }
    },
    [gameId]
  );

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  const handleSuggestionClick = (event, value) => {
    const description = value?.description || '';
    setInputValue(description);
    change(source, description);
  };

  const handleBlur = () => {
    const isValidAddress = suggestions.some(suggestion => suggestion.description == inputValue);
    const isDefaultAddress = inputValue == defaultAddress;
    if (!isValidAddress && !isDefaultAddress) {
      setInputValue('');
      change(source, '');
      setSuggestions([]);
    }
  };

  return (
    <Autocomplete
      freeSolo
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleSuggestionClick}
      options={suggestions || []}
      loading={loading}
      getOptionLabel={(option) => option?.description || ''}
      filterOptions={(options) => options}
      renderOption={(option) => (
        <div>
          {option.description}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          onBlur={handleBlur}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AddressAutoCompleteInput;
