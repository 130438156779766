import { useQueryWithStore, GET_ONE } from 'react-admin';
import { useSelector } from 'react-redux';

export const useOfficeSettings = officeId =>
  useQueryWithStore({
    type: GET_ONE,
    resource: 'officeSettings',
    payload: { id: officeId },
  })

// force check in cache (use with caution, data may have changed since last load)
export const useOfficeSettingsWithStore = (officeId, enabled = true) => {
  const officeSettingsInStore = useSelector(state => state?.admin?.resources?.officeSettings?.data?.[officeId])

  const { data, loading, error } = useQueryWithStore({
    type: GET_ONE,
    resource: 'officeSettings',
    payload: { id: officeId },
  }, {
    enabled: !officeSettingsInStore && !!officeId && !!enabled
  })
  return { data: officeSettingsInStore || data, loading, error };
}
