import React, { useEffect, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, makeStyles, useMediaQuery } from '@material-ui/core';
import { get } from 'lodash';
import { setSidebarVisibility } from 'ra-core';

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 55;

const getWidth = (theme, isOpen) => {
  const property = isOpen ? 'sidebar.width' : 'sidebar.closedWith';
  const defaultWidth = isOpen ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH;
  return get(theme, property, defaultWidth);
}

const useStyles = makeStyles(
  theme => ({
    drawerPaper: {
      position: 'relative',
      overflowX: 'hidden',
      width: props => getWidth(theme, props.open),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: '100%',
      [theme.breakpoints.only('xs')]: {
        backgroundColor: theme.palette.background.default,
      },
      zIndex: 1,
    },
  }),
  { name: 'RaSidebar' }
);

const Sidebar = ({
  children,
  closedSize,
  size,
  classes: classesOverride,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  // FIXME negating isXSmall and isSmall should be enough, but unfortunately
  // mui media queries use a two pass system and are always false at first
  // see https://github.com/mui-org/material-ui/issues/14336
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  useEffect(() => {
    if (isDesktop) {
      dispatch(setSidebarVisibility(true)); // FIXME renders with a closed sidebar at first
    }
  }, [isDesktop, dispatch]);

  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const classes = useStyles({ classes: classesOverride, open });
  useSelector(state => state.locale); // force redraw on locale change

  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
  const handleClose = () => dispatch(setSidebarVisibility(false));

  const variant = isXSmall ? 'temporary' : 'permanent';
  const onMenuClick = (isXSmall || isSmall) ? handleClose : undefined;

  return <Drawer
    variant={variant}
    open={open}
    PaperProps={{
      className: classes.drawerPaper,
    }}
    onClose={toggleSidebar}
    {...rest}
  >
    {cloneElement(Children.only(children), {
      dense: false,
      onMenuClick,
    })}
  </Drawer>
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sidebar;
