import { keyBy, uniqBy } from '@hisports/common/src/lodash.js';

import { hasPosition, isStaff, isPlayer, isGoalie, isSkater, isStarterGoalie, isNotSuspended, isAffiliate, isSuspended } from '../util/members.js';
import { HEAD_COACH, ASSISTANT_COACH, MANAGER, SAFETY_PERSON, MEMBER_POSITIONS } from '../constants.js';
import { byParticipantId } from '../util/index.js';
import { getOfficials } from './pregame.js';

export const getLineup = (state, { teamId }) => {
  if (!state) return null;
  return state.lineups[teamId];
}

export const isParticipantInLineup = (state, { participantId, teamId }) => {
  const lineup = getLineup(state, { teamId })
  if (!lineup) return false;

  return lineup.members.some(byParticipantId(participantId));
}

const isManager = hasPosition('Manager')
const isHeadCoach = hasPosition('Head Coach')
const isAssistantCoach = hasPosition('Assistant Coach')

export const compareLineupMembers = (a, b, affiliatesLast = true, sport) => {
  if (a && !b) return -1;
  if (!a && b) return 1;
  if (!a && !b) return 0;

  // sort suspensions to end
  if (isSuspended(a) && !isSuspended(b)) return 1;
  if (!isSuspended(a) && isSuspended(b)) return -1;

  if (affiliatesLast) {
    // then sort affiliates to end
    if (isAffiliate(a) && !isAffiliate(b)) return 1;
    if (!isAffiliate(a) && isAffiliate(b)) return -1;
  }

  // then sort staff to end
  if (isStaff(a) && !isStaff(b)) return 1;
  if (!isStaff(a) && isStaff(b)) return -1;
  if (isStaff(a) && isStaff(b)) {
    if (isManager(a) && !isManager(b)) return -1;
    if (!isManager(a) && isManager(b)) return 1;

    if (isHeadCoach(a) && !isHeadCoach(b)) return -1;
    if (!isHeadCoach(a) && isHeadCoach(b)) return 1;

    if (isAssistantCoach(a) && !isAssistantCoach(b)) return -1;
    if (!isAssistantCoach(a) && isAssistantCoach(b)) return 1;
  }

  // then sort goalies to end
  if (isGoalie(a) && !isGoalie(b)) return 1;
  if (!isGoalie(a) && isGoalie(b)) return -1;

  if (sport === "Soccer") return a.participant.lastName.localeCompare(b.participant.lastName);

  if (sport === "Hockey") {
    // then sort unnumbered members to end
    if (a.number && !b.number) return -1;
    if (!a.number && b.number) return 1;

    // then sort by number
    if (Number(a.number) < Number(b.number)) return -1;
    if (Number(a.number) > Number(b.number)) return 1;

    // then sort by name
    return a.participant.fullName.localeCompare(b.participant.fullName);
  }

  if (sport === "Baseball") {
    // then sort members with no batting order to end
    if (a.battingOrder && !b.battingOrder) return -1;
    if (!a.battingOrder && b.battingOrder) return 1;

    // then sort by batting order
    if (a.battingOrder && b.battingOrder) return Number(a.battingOrder) - Number(b.battingOrder);

    // then sort unnumbered members to end
    if (a.number && !b.number) return -1;
    if (!a.number && b.number) return 1;

    // then sort by number
    if (Number(a.number) < Number(b.number)) return -1;
    if (Number(a.number) > Number(b.number)) return 1;

    // then sort by name
    return a.participant.fullName.localeCompare(b.participant.fullName);
  }
}

export const getLineupMembers = (state, { teamId, showSuspended = false, affiliatesLast = true, sport, hideUnsaved = true }) => {
  const lineup = getLineup(state, { teamId });
  if (!lineup) return [];

  let members = lineup.members

  if (!showSuspended) {
    members = members.filter(isNotSuspended)
  }

  if (hideUnsaved) {
    members = members.filter(member => member.unsaved !== true)
  }

  members = members.sort((a, b) => compareLineupMembers(a, b, affiliatesLast, sport))

  return members;
}

export const getMembers = (lineup, team, sport, registrations = [], affiliatesLast = true) => {
  const lineupMembers = lineup && lineup.members || [] // selected members
  const rosterMembers = team && team.members || [] // carded members
  const affiliates = team && team.affiliates || [] // admin-added affiliates
  const lineupExtras = lineup && lineup.extras || [] // scoresheet-added extras
  const suspendedExtras = team && team.suspendedExtras || [] // previously suspended extras

  const members = uniqBy([
    ...lineupMembers,
    ...rosterMembers,
    ...affiliates,
    ...lineupExtras,
    ...suspendedExtras,
  ], 'participantId')
  members.sort((a, b) => compareLineupMembers(a, b, affiliatesLast, sport))

  if (!registrations || !registrations.length) return members;
  return uniqBy([...members, ...registrations], 'participantId')
}

export const getLineupPlayers = (state, { teamId, showSuspended, affiliatesLast, sport }) => {
  const members = getLineupMembers(state, { teamId, showSuspended, affiliatesLast, sport })
  return members.filter(isPlayer);
}

export const getLineupSkaters = (state, { teamId = null, showSuspended, affiliatesLast, sport }) => {
  const members = getLineupMembers(state, { teamId, showSuspended, affiliatesLast, sport })
  return members.filter(isSkater);
}

export const getLineupGoalies = (state, { teamId = null, showSuspended, affiliatesLast, sport }) => {
  const members = getLineupMembers(state, { teamId, showSuspended, affiliatesLast, sport })
  return members.filter(isGoalie);
}

export const getLineupStaff = (state, { teamId, showSuspended, affiliatesLast, sport }) => {
  if (!teamId) return getGameLineupStaff(state, sport);
  const members = getLineupMembers(state, { teamId, showSuspended, affiliatesLast, sport })
  return members.filter(isStaff);
}

export const getGameLineupStaff = (state, sport) => {
  const members = Object.keys(state.lineups).reduce((members, teamId) => {
    const lineupMembers = getLineupMembers(state, { teamId, sport })
    members.push(...lineupMembers)
    return members
  }, [])
  return members.filter(isStaff);
}

export const getLineupExtras = (state, { teamId, showSuspended = false }) => {
  const lineup = getLineup(state, { teamId });
  if (!lineup) return [];

  let extras = lineup.extras || [];

  if (!showSuspended) {
    extras = extras.filter(isNotSuspended)
  }

  return extras;
}

export const getGameMembers = (state, { showSuspended = false, includeExtras = false } = {}) => {
  if (!state) return {};
  const teamIds = Object.keys(state.lineups);
  return teamIds
    .map(teamId => {
      return [
        ...getLineupMembers(state, { teamId, showSuspended }),
        ...(includeExtras ? getLineupExtras(state, { teamId, showSuspended }) : [])
      ]
    })
    .reduce((members, lineup) => ({
      ...members,
      ...keyBy(lineup, 'participantId')
    }), {});
}

export const getLineupSignatory = (state, { teamId, participantId, includeOtherTeamStaff = false, sport }) => {
  const staff = includeOtherTeamStaff ? getGameLineupStaff(state, sport) : getLineupStaff(state, { teamId, sport });
  const officials = getOfficials(state, { assignedOnly: true })
  const signers = [...staff, ...officials]
  if (!signers.length) return null

  // if it's already signed, return the original signatory
  const approval = getLineupApproval(state, { teamId });
  if (approval) {
    const signatory = signers.find(byParticipantId(approval.participantId));
    if (signatory) return signatory;
  }

  if (participantId) {
    const self = signers.find(member => member.participantId === participantId);
    return self;
  }

  // otherwise find the most suitable staff position to sign
  const headCoach = staff.find(hasPosition(HEAD_COACH));
  if (headCoach) return headCoach;

  const assistant = staff.find(hasPosition(ASSISTANT_COACH));
  if (assistant) return assistant;

  const manager = staff.find(hasPosition(MANAGER));
  if (manager) return manager;

  const safety = staff.find(hasPosition(SAFETY_PERSON));
  if (safety) return safety;

  return signers[0];
}

export const getLineupCounts = (state, { teamId, showSuspended, sport }) => {
  const members = getLineupMembers(state, { teamId, showSuspended, sport });

  const totals = MEMBER_POSITIONS.reduce((totals, position) => {
    totals[position] = 0;
    return totals;
  }, {});
  totals.totalPlayer = 0;
  totals.totalSkater = 0;
  totals.totalStaff = 0;
  totals.totalStarterGoalie = 0;

  return members.reduce((total, member) => {
    member.positions.forEach(position => total[position]++);
    if (isPlayer(member)) total.totalPlayer++;
    if (isSkater(member)) total.totalSkater++;
    if (isStaff(member)) total.totalStaff++;
    if (isStarterGoalie(member)) total.totalStarterGoalie++;
    return total;
  }, totals);
}

// lineup/roster bench staff approvals
export const getLineupApproval = (state, { teamId }) => {
  const lineup = getLineup(state, { teamId });
  if (!lineup) return null;
  return lineup.approval;
}

export const isLineupApproved = (state, { teamId }) => {
  const approval = getLineupApproval(state, { teamId });
  return !!approval;
}
