import React from 'react';
import DefaultIcon from '@material-ui/icons/ViewList';
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux';
import { useResourceContext, useRecordContext, getResources, useTranslate } from 'react-admin';
import Helmet from 'react-helmet';
import { Avatar, Badge, Tooltip } from '@material-ui/core';
import { Sync } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    marginTop: -3,
  },
  svgIcon: {
    fontSize: 'large'
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    marginBottom: 5
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    marginTop: -3,
    marginLeft: 10
  },
  avatar: {
    width: 100,
    height: 100,
    boxShadow: theme.shadows[3],
  },
  smallAvatar: {
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.background.paper}`,
  },
  number: {
    fontSize: 'small',
  }
}))

export const ResourceIcon = ({ className, ...props }) => {
  const resourceName = useResourceContext(props);
  const resource = useSelector(state => getResources(state).find(resource => resource.name === resourceName))
  const Icon = resource && resource.icon || DefaultIcon;
  return <Icon className={className} />
}

export const ResourceTitle = ({ title, classes: classOverrides, ...props }) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props)
  const classes = useStyles(classOverrides);
  return <>
    <Helmet title={title(record)} />
    <ResourceIcon className={classes.icon} />
    {title(record)}
  </>
}

export const ResourceAvatarTitle = ({ title, classes: classOverrides, ...props }) => {
  const record = useRecordContext(props);
  const classes = useStyles(classOverrides);
  const translate = useTranslate();

  return <div className={classes.mainContainer}>
    <Helmet title={title(record)} />
    <Badge backgroundColor="grey" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={ record.externalId &&
      <Tooltip title={translate('resources.participants.labels.tooltips.picture_update_in_id')}>
        <Avatar className={classes.smallAvatar}>
          <Sync className={classes.svgIcon} htmlColor="black" />
        </Avatar>
      </Tooltip>
    }>
      <Avatar className={classes.avatar} src={record.pictureUrl} alt={record.fullName} />
    </Badge>
    <div>
      <div className={classes.container}>
        {title(record)}
      </div>
      {record.HCRId && <div className={classes.container}>
        <span className={classes.number}>{translate('resources.participants.fields.HCRId')} {record.HCRId}</span>
      </div>}
    </div>
  </div>
}

export default ResourceTitle;
