import React from 'react';

import ListCard from "../../common/cards/ListCard";
import { useSeason } from "../../common/inputs/SeasonSelector";
import { useDefaultSeason } from "../../http";

import { QualificationGrid } from "./QualificationGrid";

const expiry = new Date().toISOString()

export const OfficialQualificationsGrid = ({ className, variant = 'body2', ...props }) => {
  const seasonId = useSeason();
  const defaultSeason = useDefaultSeason();

  const isPast = seasonId !== defaultSeason;
  const filter = isPast
    ? { seasonId, qualificationCategory: {
      types: { inq: ['Official', 'Scorekeeper'] } } }
    : { qualificationCategory: {
      types: { inq: ['Official', 'Scorekeeper'] } },
    or: [
      { seasonId },
      { expiry: { gte: expiry } },
      { expiry: { eq: null } },
    ]
    }

  return <ListCard
    reference="qualifications"
    target="participants"
    source="participantId"
    filter={filter}
    sort={{ field: 'seasonId', order: 'DESC' }}
  >
    <QualificationGrid rowClick={false} />
  </ListCard>
}

OfficialQualificationsGrid.defaultProps = {
  addLabel: true,
}
