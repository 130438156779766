import React from 'react';
import { FunctionField } from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';
import { SmsOutlined } from '@material-ui/icons';

import { Popover } from '../Popover';

const useStyles = makeStyles(theme => ({
  popover: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-line',
  },
  icon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  }
}));

export const PopoverTextField = ({ source, ...props }) => {
  const classes = useStyles();
  return <FunctionField {...props} render={record => {
    const text = record?.[source];
    if (!text) return null;

    return <Popover
      popoverContent={
        <div className={classes.popover}>
          <Typography variant="body2">{text}</Typography>
        </div>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <SmsOutlined fontSize="small" className={classes.icon} />
    </Popover>
  }} />
}
