import { useListContext } from 'react-admin';
import { v4 as uuid } from 'uuid';

import useAccountPreferences from './useAccountPreferences';
import { useOfficialList } from '../resources/events/EventViewSettings';

export const getFilterValue = (filterValues, ignoredFilters) => {
  return Object.keys(filterValues)
    .filter(filter => !ignoredFilters.includes(filter))
    .reduce((filters, key) => {
      filters[key] = filterValues[key];
      return filters;
    }, {})
}

export const useSavedSearches = (ignoredFilters = []) => {
  const { resource, setFilters, filterValues } = useListContext();
  const [ searches, setSearches, loading, error ] = useAccountPreferences('SavedSearches', []);
  const [listId, setListId] = useOfficialList();

  const saveSearch = name => {
    const value = getFilterValue(filterValues, ignoredFilters)

    if (listId) {
      value.listId = listId;
    }

    setSearches(searches => ([...searches, { id: uuid(), name, resource, value }]))
  }

  const removeSearch = search => {
    if (!search?.id) return;
    setSearches(searches => searches.filter(p => p.id !== search.id))
  }

  const applySearch = search => {
    if (!search?.value) return;
    const ignored = Object.keys(filterValues)
      .filter(filter => ignoredFilters.includes(filter))
      .reduce((filters, key) => {
        filters[key] = filterValues[key]
        return filters;
      }, {})

    if (search.value.listId) {
      setListId(search.value.listId)
    }

    setFilters({ ...search.value, ...ignored });
  }

  return {
    searches: searches.filter(search => search.resource === resource),
    filterValues,
    saveSearch,
    removeSearch,
    applySearch,
    loading,
    error,
  }
}
