import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import RuleSectionForm from './RuleSectionForm';

const AddRuleSectionDialog = props =>
  <CreateResourceDialog label="resources.ruleSections.actions.add" resource="ruleSections" basePath="/ruleSections" {...props}>
    <RuleSectionForm />
  </CreateResourceDialog>

const EditRuleSectionDialog = props =>
  <EditResourceDialog label="resources.ruleSections.labels.edit" resource="ruleSections" basePath="/ruleSections" {...props}>
    <RuleSectionForm />
  </EditResourceDialog>

export const AddRuleSectionButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.ruleSections.actions.add')}
    </Button>
    <AddRuleSectionDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const RuleSectionDatagrid = props => <DialogDatagrid dialog={EditRuleSectionDialog} {...props} />
