import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import RuleForm from './RuleForm';

const AddRuleDialog = props =>
  <CreateResourceDialog label="resources.rules.actions.add" resource="rules" basePath="/rules" {...props}>
    <RuleForm />
  </CreateResourceDialog>

const EditRuleDialog = props =>
  <EditResourceDialog label="resources.rules.labels.edit" resource="rules" basePath="/rules" {...props}>
    <RuleForm />
  </EditResourceDialog>

export const AddRuleButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.rules.actions.add')}
    </Button>
    <AddRuleDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const RuleDatagrid = props => <DialogDatagrid dialog={EditRuleDialog} {...props} />
