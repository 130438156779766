import React, { useEffect, useMemo, useState } from "react";
import { ListContextProvider, useDataProvider, useResourceContext } from "react-admin";
import { useForm } from "react-final-form";

import { ListView } from "../cards/ListCard";
import { useList } from "../ra/useList";

export const SelectableListView = ({
  children,
  filter,
  sort,
  localSort,
  localFilter,
  localFilterCallback = () => true,
  initialSelectAll = false,
  ...props
}) => {
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const { change } = useForm();
  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ records, setRecords ] = useState([]);

  const initialSelection = useMemo(() => {
    if (!initialSelectAll) return
    return records?.map(record => record.id)
  }, [ records, initialSelectAll ])

  const listContext = useList({
    data: records,
    resource: resource.toLocaleLowerCase(), // resource has to be lowercase for team filtering to work correctly
    loaded,
    loading,
    page: 1,
    perPage: 25,
    sort: localSort,
    filter: localFilter,
    initialSelection,
  })

  useEffect(() => {
    change('selectedIds', listContext?.selectedIds || [])
  }, [ listContext?.selectedIds, change ])

  useEffect(() => {
    if (loading || loaded) return
    setLoading(true);

    dataProvider.getList(resource, {
      pagination: { page: 1, perPage: 999 },
      sort,
      filter,
    })
      .then(res => res.data)
      .then(records => records.filter(localFilterCallback))
      .then(setRecords)
      .catch(() => {
        setRecords([])
      })
      .finally(() => {
        setLoaded(true);
        setLoading(false);
      })
  }, [ dataProvider, loading, loaded, resource, sort, filter, localFilterCallback ])

  return <ListContextProvider value={listContext}>
    <ListView open {...props}>
      {children}
    </ListView>
  </ListContextProvider>
}
