import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import OfficeAssignSettingsForm from './OfficeAssignSettingsForm';

const EditOfficeAssignSettingsDialog = props =>
  <EditResourceDialog label="resources.officeAssignSettings.labels.edit_assigning" disableDelete resource="officeAssignSettings" basePath="/officeAssignSettings" {...props}>
    <OfficeAssignSettingsForm />
  </EditResourceDialog>

export const UpdateOfficeAssignSettingsButton = ({ officeId, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  return <>
    <Button color="primary" startIcon={<Edit />} onClick={() => setOpen(true)}>
      {translate('resources.officeAssignSettings.actions.edit')}
    </Button>
    <EditOfficeAssignSettingsDialog id={officeId} isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}
