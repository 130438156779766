import React from 'react';
import { LinearProgress, RecordContextProvider, useRecordContext, useTranslate } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import Row from '../../common/cards/TableRow';

const GeneralSettingsField = () => {
  const translate = useTranslate();
  const { visibleDaysEnabled, visibleDays } = useRecordContext();

  if (!visibleDaysEnabled) return translate('resources.officeAssignSettings.messages.visible_days_disabled')
  if (visibleDays === null) return translate('resources.officeAssignSettings.messages.visible_days_off')
  if (visibleDays === 0) return translate('resources.officeAssignSettings.messages.visible_same_day')
  return translate('resources.officeAssignSettings.messages.visible_days_on', { number: visibleDays })
}

const RequestSettingsField = () => {
  const translate = useTranslate();
  const { requestsEnabled } = useRecordContext();

  if (!requestsEnabled) return translate('resources.officeAssignSettings.messages.requests_enabled_off')
  return translate('resources.officeAssignSettings.messages.requests_enabled_on')
}

const DeclineSettingsField = () => {
  const translate = useTranslate();
  const { declineEnabled, declineConfirmedDisabled, declineHours, declineReason } = useRecordContext();

  if (!declineEnabled) return translate('resources.officeAssignSettings.messages.decline_enabled_off')
  return [
    declineHours > 0
      ? translate('resources.officeAssignSettings.messages.decline_enabled_on', { number: declineHours })
      : translate('resources.officeAssignSettings.messages.decline_enabled_on_anytime'),
    declineReason
      ? translate('resources.officeAssignSettings.messages.reason_required')
      : translate('resources.officeAssignSettings.messages.reason_not_required'),
    declineConfirmedDisabled && translate('resources.officeAssignSettings.messages.disabled_after_confirmation'),
  ].filter(Boolean).join(', ')
}

export const OfficeAssignSettingsField = ({ data, loading, error }) => {
  const translate = useTranslate();
  if (loading) return <LinearProgress />
  if (error) return translate('resources.officeAssignSettings.messages.load_error');

  return <RecordContextProvider value={data}>
    <Table>
      <TableBody>
        <Row label="resources.officeAssignSettings.labels.general">
          <GeneralSettingsField />
        </Row>
        <Row label="resources.officeAssignSettings.labels.requests">
          <RequestSettingsField />
        </Row>
        <Row label="resources.officeAssignSettings.labels.declines">
          <DeclineSettingsField />
        </Row>
      </TableBody>
    </Table>
  </RecordContextProvider>
}
