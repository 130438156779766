import React from 'react';
import { TextField, useRecordContext, useTranslate } from 'react-admin';
import { Archive } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import { FF_OFFICIALS, FF_SCOREKEEPERS, FF_TIMEKEEPERS, FF_ADDITIONAL_LINESPERSONS } from '@hisports/common/featureFlags';

import Toolbar from '../../common/ra/Toolbar';
import { CurrencyField } from '../../common/fields/CurrencyField';

import { OfficeField } from '../offices/OfficeField';
import { AssignFeesDatagrid } from './AssignFeesModal';
import { useFlag } from '../../http';


const ArchiveField = props => {
  const translate = useTranslate();
  const fees = useRecordContext()

  if (!fees?.isArchived) return null;

  return <Tooltip title={translate('resources.assignFees.fields.isArchived')}>
    <Archive />
  </Tooltip>
}

export const AssignFeesGrid = props => {
  const isEnabled = useFlag();

  return <AssignFeesDatagrid size="medium" toolbar={<Toolbar />} {...props}>
    <TextField source="name" />
    <CurrencyField source="Referee" />
    <CurrencyField source="Linesperson" />
    {isEnabled(FF_ADDITIONAL_LINESPERSONS) && <CurrencyField source="LinespersonTwo" />}
    {isEnabled(FF_ADDITIONAL_LINESPERSONS) && <CurrencyField source="LinespersonThree" />}
    {isEnabled(FF_OFFICIALS) && <CurrencyField source="Official" />}
    {isEnabled(FF_SCOREKEEPERS) && <CurrencyField source="Scorekeeper" />}
    {isEnabled(FF_TIMEKEEPERS) && <CurrencyField source="Timekeeper" />}
    <CurrencyField source="Supervisor" />
    <OfficeField source="officeId" />
    <ArchiveField source="isArchived" label={false} />
  </AssignFeesDatagrid>
}
