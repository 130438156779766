import React from 'react'
import { useTranslate } from 'react-admin'
import {
  CardHeader as muiCardHeader,
  styled
} from '@material-ui/core'

const CardHeader = styled(muiCardHeader)(({ theme }) => ({
  marginBottom: theme.spacing(-2),
}))

export default ({ title, ...props }) => {
  const translate = useTranslate()
  return <CardHeader
    title={title && translate(title, { _: title, smart_count: 1 })}
    {...props}
  />
}
