import React, { Children, cloneElement, forwardRef, memo, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableHead, TableBody, TableRow, TableCell, styled, IconButton, useTheme, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment-timezone';
import { RecordContextProvider, useListContext, useTranslate } from 'react-admin';
import { Check as AvailableIcon, Clear as UnavailableIcon, Warning, Home as HomeIcon, CardTravel, StarBorder } from '@material-ui/icons';

import { CALENDAR_VIEWS, useCalendarView, useIsHomeFirst, useShowSlots, useShowAwaySlots, useShowTeamName } from '../../../resources/events/EventViewSettings';
import { TeamField } from '../../../resources/teams/TeamField';
import { draftLabel } from '../../../resources/games/GameAvailabilityInfo';
import { useFlag } from '../../../http';
import { useElementSize } from '../../useElementSize';
import { useSchedulingContext } from '../SchedulingContext';
import { useCalendarContext } from '../CalendarContext';
import { GameDetailsPopover, AvailableSlotsPopover, AvailabilityDetailsListPopover } from '../EventDetailsPopover';
import { CalendarGameIcon, isDraft } from '../EventDetails';

const getAvailabilityBg = (theme, availability, isHovered) => {
  switch (availability) {
    case AVAILABILITY_STATUS.unavailable:
      return isHovered ? theme.palette.error[200] : theme.palette.error[100]

    case AVAILABILITY_STATUS.mixed :
      return isHovered ? theme.palette.warning[200] : theme.palette.warning[100]

    default:
      return isHovered ? theme.palette.action.hover : null
  }
}
const getAvailabilityColor = (theme, availability) => {
  switch (availability) {
    case AVAILABILITY_STATUS.unavailable:
      return `color-mix(in srgb, ${theme.palette.error.main} 30%, #000)`;

    case AVAILABILITY_STATUS.mixed :
      return `color-mix(in srgb, ${theme.palette.warning.main} 30%, #000)`;

    default:
      return null
  }
}

const useDayStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.divider}`,
    zIndex: 1,
    padding: 0,
  },
  day: {
    fontWeight: props => props.today ? 'bold' : undefined,
    userSelect: 'none',
    height: theme.spacing(20),
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    marginBottom: theme.spacing(1),
    marginInline: theme.spacing(1),
  },
  event: {
    fontSize: '85%',
    alignItems: 'center',
    paddingBlock: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      cursor: 'pointer',
    }
  },
  eventRow: {
    display: 'flex',
    gap: theme.spacing(.5),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    }
  },
  iconColumn: {
    flexShrink: 0,
    flexBasis: theme.spacing(1.5),
  },
  icon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    alignSelf: 'flex-start',
    marginTop: theme.spacing(0.5),
  },
  time: {},
  gameLabel: {
    fontWeight: 500,
    wordBreak: 'break-all'
  },
  teamName: {
    fontStyle: 'italic',
    fontSize: theme.spacing(1.25),
  },
  dayNumber: {
    marginTop: theme.spacing(1),
    marginInline: theme.spacing(1),
    position: 'relative',
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '2em',
    aspectRatio: 1,
    fontSize: '1rem',
  },
  additional: {
    fontSize: '85%',
  },
  tournamentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  tournament: {
    backgroundColor: theme.palette.warning[100],
    fontWeight: 'normal',
    paddingInline: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  tournamentIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    alignSelf: 'center',
    marginTop: 0,
    paddingRight: theme.spacing(0.7),
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
}))

const useAvailabilityStyles = makeStyles((theme) => ({
  availabilityDiv: {
    paddingBlock: theme.spacing(.5),
    paddingInline: theme.spacing(0.5),
    transition: 'background-color .1s ease-in-out',
    backgroundColor: ({ availabilityStatus }) => getAvailabilityBg(theme, availabilityStatus, false),

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ({ availabilityStatus }) => getAvailabilityBg(theme, availabilityStatus, true),
    }
  },
  awayAvailabilityDiv: {
    paddingBlock: theme.spacing(.5),
    paddingInline: theme.spacing(1),
    transition: 'background-color .1s ease-in-out',
    backgroundColor: () => getAvailabilityBg(theme, null, false),

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: () => getAvailabilityBg(theme, null, true),
    }
  },
  availabilities: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    fontSize: '85%',
    marginBottom: theme.spacing(0.5),
    color: ({ availabilityStatus }) => getAvailabilityColor(theme, availabilityStatus),
  },
  awayAvailabilities: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(1),
    fontSize: '85%',
    marginBottom: theme.spacing(0.5),
    color: () => getAvailabilityColor(),
  },
  availabilityIcon: {
    verticalAlign: 'middle'
  }
}))

const ScheduleView = styled('div')({
  maxHeight: '100vh',
  overflowY: 'auto',
  position: 'relative',
  '& .react-grid-item.react-grid-placeholder': {
    background: 'grey',
  }
})

const AVAILABILITY_STATUS = {
  available: 'available',
  unavailable: 'unavailable',
  mixed: 'mixed',
}

const getAvailabilityStatus = (available = [], unavailable = []) => {
  if (available.length && unavailable.length) return AVAILABILITY_STATUS.mixed;
  if (unavailable.length) return AVAILABILITY_STATUS.unavailable;
  return AVAILABILITY_STATUS.available;
}

const getAvailabilityIcon = (availabilityStatus) => {
  if (availabilityStatus === AVAILABILITY_STATUS.mixed) return Warning;
  if (availabilityStatus === AVAILABILITY_STATUS.unavailable) return UnavailableIcon;
  return AvailableIcon;
}


export const AvailabilityDetailsList = ({
  availableSlots = [],
  showSlots = true,
  awaySlots = [],
  showAwaySlots = true,
  availabilities = [],
}) => {
  const translate = useTranslate();

  const available = availabilities.filter(availability => availability.isAvailable);
  const unavailable = availabilities.filter(availability => !availability.isAvailable);
  const onlyAvailable = !!available.length && !unavailable.length;
  const availabilityLabel = onlyAvailable ?
    (!showSlots || !availableSlots.length ? translate('resources.availabilities.labels.available') : null)
    : translate('resources.availabilities.labels.unavailable')

  const availabilityStatus = getAvailabilityStatus(available, unavailable)
  const classes = useAvailabilityStyles({ availabilityStatus })
  const Icon = getAvailabilityIcon(availabilityStatus);

  return <>
    {(!!availabilities.length || (showSlots && !!availableSlots.length)) &&
      <AvailabilityDetailsListPopover slots={availableSlots} availabilities={availabilities}>
        {(onClick) => {
          return <div onClick={onClick} className={classes.availabilityDiv}>
            <Typography className={classes.availabilities}>
              <Icon className={classes.availabilityIcon} fontSize="small" />
              {!!availabilities.length &&
          <b>{availabilityLabel}</b>}
              {showSlots && !!availableSlots.length &&
          <span>{translate(`resources.arenaslots.messages.${showAwaySlots ? 'home_available_slots' : 'slots'}`, { smart_count: availableSlots.length })}</span>}
            </Typography>
          </div>
        }}
      </AvailabilityDetailsListPopover>}
    {showSlots && showAwaySlots && !!awaySlots.length && <AvailabilityDetailsListPopover slots={awaySlots}>
      {(onClick) => {
        return <div onClick={onClick} className={classes.awayAvailabilityDiv}>
          <Typography className={classes.awayAvailabilities}>
            <span>{translate('resources.arenaslots.messages.away_available_slots', { smart_count: awaySlots.length })}</span>
          </Typography>
        </div>
      }}
    </AvailabilityDetailsListPopover>}
  </>
}

export const AvailableSlots = ({ slots = [], date, readOnly, label }) => {
  const { timezone } = useCalendarContext();
  const translate = useTranslate()

  const today = moment.tz(date, timezone).isSame(undefined, 'day');
  const classes = useDayStyles({ date, today })

  return <AvailableSlotsPopover slots={slots} isEditable={false} readOnly={readOnly}>
    {(onClick) => {
      return <Typography onClick={onClick} className={classes.slots}>{translate(label || 'resources.arenaslots.messages.available_slots', { smart_count: slots.length })}</Typography>
    }}
  </AvailableSlotsPopover>
}

const TruncatedEventList = ({ events = [], max = 5 }) => {
  const [ showTeamName ] = useShowTeamName();
  const isHomeFirst = useIsHomeFirst();
  const isEnabled = useFlag()
  const translate = useTranslate()

  const classes = useDayStyles()

  const total = events.length;
  const visibleEvents = events.slice(0, max);

  return <>
    {visibleEvents
      .map(game => {
        const homeTeam = <TeamField source="homeTeamId" link={false} variant="inherit" />
        const awayTeam = <TeamField source="awayTeamId" link={false} variant="inherit" />
        return <RecordContextProvider value={game}>
          <GameDetailsPopover isEditable={false} disableLaunch={isDraft(game)}>
            {(onClick) => {
              return <li onClick={onClick} className={classes.event}>
                <div className={`${classes.title} ${classes.eventRow}`}>
                  <div className={classes.iconColumn}>
                    <CalendarGameIcon game={game} className={classes.icon} />
                  </div>
                  <div>
                    {game?.startTime && <span className={classes.time}>{moment.tz(game.startTime, game.timezone).format('HH:mm')}</span>}
                    {' '}
                    <span className={classes.gameLabel}>
                      {!showTeamName && isDraft(game) && `${draftLabel(isEnabled, translate, game?.type)} `}
                      {game?.number || game?.id}
                    </span>
                  </div>
                </div>
                {showTeamName && (
                  <div className={classes.teamName}>
                    <div className={classes.eventRow}>
                      <span className={classes.iconColumn}></span>
                      <span className={classes.teamName}>{isHomeFirst ? homeTeam : awayTeam}</span>
                    </div>
                    <div className={classes.eventRow}>
                      <span className={classes.iconColumn}>{isHomeFirst ? 'vs' : '@'}</span>
                      <span className={classes.teamName}>{isHomeFirst ? awayTeam : homeTeam}</span>
                    </div>
                  </div>
                )}
              </li>
            }}
          </GameDetailsPopover>
        </RecordContextProvider>
      })}
    {total > max && <span className={classes.additional}>{translate('resources.schedules.messages.additional_games', { smart_count: total - max })}</span>}
  </>
}

const Day = memo(({ date }) => {
  const { timezone, setNavigateToDate, header, filterEvents } = useCalendarContext();
  const { selectedGame, surfaceOfficeId, availability } = useSchedulingContext() || {};
  const [ _, setView ] = useCalendarView();
  const [ showSlots ] = useShowSlots();
  const [ showAwaySlots ] = useShowAwaySlots();
  const theme = useTheme()
  const { data = {} } = useListContext()

  const today = moment.tz(date, timezone).isSame(undefined, 'day');
  const classes = useDayStyles({ date, today })

  const homeTeam = useSelector(store => store.admin.resources.teams.data[selectedGame?.homeTeamId]);
  const awayTeam = useSelector(store => store.admin.resources.teams.data[selectedGame?.awayTeamId]);

  const homeTeamTournaments = availability?.schedules?.filter(
    schedule => schedule.teams.find(team => team.id == selectedGame?.homeTeamId)
  ) || []

  const awayTeamTournaments = availability?.schedules?.filter(
    schedule => schedule.teams.find(team => team.id == selectedGame?.awayTeamId)
  ) || []

  const bothTeamTournaments = awayTeamTournaments.filter(awayTournament => homeTeamTournaments.includes(awayTournament));

  const isWithinTournamentPeriod = (teamTournament) => {
    const formattedDate = new Date(teamTournament.startDate);
    const tournamentStartDate = formattedDate.toISOString().split('T')[0];
    return (date >= tournamentStartDate && date <= teamTournament.endDate);
  };

  const bothTeamTournamentsPerDay = bothTeamTournaments.filter(tournament => isWithinTournamentPeriod(tournament));

  const homeTeamTournamentsPerDay = homeTeamTournaments.filter(tournament => (
    !bothTeamTournamentsPerDay.includes(tournament) && isWithinTournamentPeriod(tournament)
  ))

  const awayTeamTournamentsPerDay = awayTeamTournaments.filter(tournament => (
    !bothTeamTournamentsPerDay.includes(tournament) && isWithinTournamentPeriod(tournament)
  ))

  const listGames = useMemo(() => {
    return Object.values(data).filter(event => event.date == date);
  }, [data, date])

  const availabilityGames = useMemo(() => {
    const dayGames = (availability?.games || []).filter(event => event.date == date);
    return filterEvents(dayGames);
  }, [availability?.games, date, filterEvents])

  const events = useMemo(() => {
    return [...(selectedGame ? availabilityGames : listGames)]
      .sort((a, b) => {
        if (!a.startTime || !b.startTime) return -1;
        return new Date(a.startTime) - new Date(b.startTime)
      })
  }, [availabilityGames, listGames, selectedGame])

  const slots = useMemo(() => {
    return (availability?.slots || [])
  }, [availability?.slots])

  const availabilities = useMemo(() => {
    return (availability?.availabilities || [])
  }, [availability?.availabilities])

  const thisMonth = moment.tz(date, timezone).isSame(moment(header, 'MMMM YYYY'), 'month')
  const day = moment.tz(date, timezone).date();

  const handleDayClick = (date) => {
    setView(CALENDAR_VIEWS.WEEK);
    setNavigateToDate(date)
  }

  const dayAvailabilities = availabilities.filter(availability => availability.date == date)

  const availableSlots = slots.filter(slot => slot.date == date && (surfaceOfficeId || homeTeam?.officeId == slot.officeId))
  const awayAvailableSlots = slots.filter(slot => slot.date == date && awayTeam?.officeId == slot.officeId)

  const canShowAwaySlots = showAwaySlots && !surfaceOfficeId && homeTeam?.officeId !== awayTeam?.officeId;

  return (
    <TableCell padding="none" class={classes.root} style={{ backgroundColor: !thisMonth ? theme.palette.grey[100] : undefined }}>
      <div className={classes.day}>
        <IconButton onClick={() => handleDayClick(date)} size="small" className={classes.dayNumber}>{day}</IconButton>
        <AvailabilityDetailsList
          availableSlots={availableSlots}
          showSlots={showSlots}
          awaySlots={awayAvailableSlots}
          showAwaySlots={canShowAwaySlots}
          availabilities={dayAvailabilities}
          date={date}
        />
        <div className={classes.tournamentContainer}>
          {bothTeamTournamentsPerDay.length > 0 && (
            bothTeamTournamentsPerDay.map(tournament => (
              <div className={classes.tournament}>
                <StarBorder className={classes.tournamentIcon} />
                <Tooltip title={tournament.name}><span className={classes.ellipsis}>{tournament.name}</span></Tooltip>
              </div>
            ))
          )}

          {homeTeamTournamentsPerDay.length > 0 && (
            homeTeamTournamentsPerDay.map(tournament => (
              <div className={classes.tournament}>
                <HomeIcon className={classes.tournamentIcon} />
                <Tooltip title={tournament.name}><span className={classes.ellipsis}>{tournament.name}</span></Tooltip>
              </div>
            ))
          )}

          {awayTeamTournamentsPerDay.length > 0 && (
            awayTeamTournamentsPerDay.map(tournament => (
              <div className={classes.tournament}>
                <CardTravel className={classes.tournamentIcon} />
                <Tooltip title={tournament.name}><span className={classes.ellipsis}>{tournament.name}</span></Tooltip>
              </div>
            ))
          )}
        </div>
        <ul className={classes.list}>
          <TruncatedEventList events={events} />
        </ul>
      </div>
    </TableCell>
  )
})

const Week = memo(({ dates }) => {
  return <TableRow>
    {dates.map(date => (
      <Day key={date} date={date} />
    ))}
  </TableRow>
})

const useMonthStyles = makeStyles(theme => ({
  root: {
    tableLayout: 'fixed',
  }
}))

const Month = forwardRef((props, ref) => {
  const classes = useMonthStyles();
  const { columns, rows } = useCalendarContext();

  return <Table size="small" className={classes.root} ref={ref}>
    <TableHead>
      <TableRow>
        {(columns || []).map(day => (
          <TableCell key={day} align="center">{day}</TableCell>)
        )}
      </TableRow>
    </TableHead>
    <TableBody>
      {(rows || []).map(datesPerWeek => (
        <Week key={datesPerWeek[0]} dates={datesPerWeek} />
      ))}
    </TableBody>
  </Table>
})

export default memo(({ children }) => {
  const bodyRef = useRef(null);

  const cellRef = useRef(null);
  cellRef.current = bodyRef?.current?.querySelector(`tr:nth-child(1) td:nth-child(1)`);
  const cellSize = useElementSize(cellRef.current);

  const monthHeader = useRef(null);
  monthHeader.current = bodyRef?.current?.querySelector('th');
  const monthHeaderSize = useElementSize(monthHeader.current);

  const isMeasured = cellSize.height > 0 && cellSize.width > 0

  const offset = useMemo(() => ({
    width: 0,
    height: monthHeaderSize.height,
  }), [ monthHeaderSize ])

  return <ScheduleView>
    <Month ref={bodyRef} />
    {isMeasured && Children.map(children, child => {
      if (!child) return null;
      return cloneElement(child, { ...child.props, size: cellSize, offset });
    })}
  </ScheduleView>
})
