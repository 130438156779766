import React from 'react';
import { FunctionField, GET_ONE, ReferenceField, useQuery, useRecordContext } from "react-admin";
import { makeStyles } from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { usePermissions } from '../../http';
import { hasAnyScope } from '../../common/Authorize';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  icon: {
    marginLeft: theme.spacing(1),
    justifySelf: 'flex-end',
    alignSelf: 'center',
  },
}))

const usePayPeriodWithStore = payPeriodId => {
  const payPeriodInStore = useSelector(state => state?.admin?.resources?.payPeriods?.data?.[payPeriodId])

  const { data, loading, error } = useQuery({
    type: GET_ONE,
    resource: 'payperiods',
    payload: { id: payPeriodId },
  }, {
    enabled: !payPeriodInStore && !!payPeriodId
  })
  return { data: payPeriodInStore || data, loading, error };
}

export const PayPeriodField = props => {
  const record = useRecordContext();
  const permissions = usePermissions();
  const classes = useStyles()
  const { data: payPeriod } = usePayPeriodWithStore(record?.payPeriodId);

  const showPayPeriodLink = !!payPeriod && permissions.some(p =>
    p.roleType === 'System' ||
    (
      hasAnyScope(p.scopes, ['assigning:manage', 'assigning:assign'])
      && p.roleType === 'Office'
      && !p.inherited
      && (p?.officeIds || []).includes(payPeriod.officeId)
    )
  );

  const showLink = showPayPeriodLink && 'show'
  return <ReferenceField basePath="/payPeriods" resource="payPeriods" reference="payPeriods" link={showLink} emptyText="—" {...props}>
    <FunctionField render={({ name, isClosed }) =>
      <div className={classes.root}>
        {name}
        {isClosed
          ? <Lock className={classes.icon} fontSize="small" color="secondary" />
          : <LockOpen className={classes.icon} fontSize="small" color="secondary" />
        }
      </div>}
    />
  </ReferenceField>
}
