import { sanitize } from './common.js'

export const getPosition = (value = '') => {
  if (!value) return 'F';
  const positions = ['F', 'C', 'D', 'G', 'Head Coach', 'Assistant Coach', 'Goaltending Coach', 'Manager', 'Trainer', 'Safety Person'];
  if (sanitize(value) === 'COACH') return 'Assistant Coach';
  if (sanitize(value).includes('VOLUNTEER')) return 'Volunteer';
  return positions.find(position => position.toUpperCase() === sanitize(value)) || 'F';
}

export const isPosition = value => {
  if (!value) return null;
  const position = value.toUpperCase();
  return ['F', 'C', 'D', 'G', 'HEAD COACH', 'ASSISTANT COACH', 'GOALTENDING COACH', 'MANAGER', 'TRAINER', 'SAFETY PERSON'].includes(position)
}

export const getZip = value => {
  if (!value) return;
  const zip = sanitize(value);
  const matches = /\D*(\d*)/.exec(zip)
  if (!matches) return;
  return matches[1];
}

export const getOffset = value => {
  const offset = sanitize(value || Math.floor(Math.random() * 1000))
  return offset.padStart(3, '0');
}

export const getZipId = (member) => {
  const zip = getZip(member.zip)
  const offset = getOffset(member.offset);
  if (!zip || !offset || !member.firstName || !member.lastName) return;
  // 90610 John Doe 001 = 90610DOJ001
  return `${zip}${member.lastName.substring(0, 2)}${member.firstName.substring(0, 1)}${offset}`
}

export const byHCRId = HCRId => participant => participant.HCRId == HCRId; // eslint-disable-line eqeqeq
