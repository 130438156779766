import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useListContext, useTranslate } from 'react-admin';
import { FormControlLabel, IconButton, Menu, MenuItem, Checkbox, Divider } from "@material-ui/core"
import { MoreVert } from "@material-ui/icons"

// import usePreferences from '../../common/usePreferences';

export const TransactionViewSettingsContext = React.createContext([false, value => {}]);

export const TransactionViewSettingsProvider = props => {
  // const groupGames = usePreferences('TransactionViewGroupGames', true);
  // const groupParticipants = usePreferences('TransactionViewGroupParticipant', false);
  const groupGames = useState(true);
  const groupParticipants = useState(false);

  const value = useMemo(() => ({
    groupGames,
    groupParticipants,
  }), [ groupGames, groupParticipants ])

  return <TransactionViewSettingsContext.Provider value={value} {...props} />
}

export const useTransactionViewSettings = () =>
  useContext(TransactionViewSettingsContext);

export const useGroupGames = () => {
  const { groupGames } = useTransactionViewSettings();
  return groupGames;
}

export const useGroupParticipants = () => {
  const { groupParticipants } = useTransactionViewSettings();
  return groupParticipants;
}

const SelectAllInput = ({ ...props }) => {
  const translate = useTranslate();
  const { selectedIds = [], onSelect, total, data = {} } = useListContext()

  const checked = selectedIds.length === total;

  const handleChange = e => {
    const checked = e.target.checked;

    if (!checked) {
      // unselect all
      onSelect([]);
    } else {
      // select all
      const idsToSelect = Object.keys(data);
      onSelect(idsToSelect);
    }
  }

  return <FormControlLabel
    control={<Checkbox
      checked={checked}
      onChange={handleChange}
      name="selectAll"
    />}
    label={translate(`resources.officialTransactions.messages.${checked ? 'unselect_all' : 'select_all'}`)}
  />
}


const GroupGamesInput = ({ ...props }) => {
  const translate = useTranslate();
  const [ checked, setGroupGames ] = useGroupGames();
  const [ groupParticipants ] = useGroupParticipants();

  useEffect(() => {
    if (groupParticipants) {
      setGroupGames(false)
    }
  }, [ groupParticipants, setGroupGames ])

  const handleChange = e => {
    const checked = e.target.checked;
    setGroupGames(checked)
  }

  return <FormControlLabel
    control={<Checkbox
      checked={checked}
      disabled={checked}
      onChange={handleChange}
      name="groupGames"
    />}
    label={translate('resources.officialTransactions.messages.group_by_game')}
  />
}

const GroupParticipantsInput = ({ ...props }) => {
  const translate = useTranslate();
  const [ checked, setGroupParticipants ] = useGroupParticipants();
  const [ groupGames ] = useGroupGames();

  useEffect(() => {
    if (groupGames) {
      setGroupParticipants(false)
    }
  }, [ groupGames, setGroupParticipants ])

  const handleChange = e => {
    const checked = e.target.checked;
    setGroupParticipants(checked)
  }

  return <FormControlLabel
    control={<Checkbox
      checked={checked}
      disabled={checked}
      onChange={handleChange}
      name="groupParticipants"
    />}
    label={translate('resources.officialTransactions.messages.group_by_participant')}
  />
}

export const TransactionViewSettings = ({ ...props }) => {
  const [ anchor, setAnchor ] = useState(null)

  const toggle = e => setAnchor(anchor => anchor ? !anchor : e.currentTarget)
  const onClose = () => setAnchor(null)

  return <>
    <IconButton onClick={toggle} size="small"><MoreVert /></IconButton>
    <Menu
      anchorEl={anchor}
      open={anchor != null}
      onClose={onClose}
    >
      <MenuItem dense onClick={onClose}>
        <SelectAllInput key="select-all" />
      </MenuItem>
      <Divider />
      <MenuItem dense onClick={onClose}>
        <GroupGamesInput key="group-games" />
      </MenuItem>
      <MenuItem dense onClick={onClose}>
        <GroupParticipantsInput key="group-participants" />
      </MenuItem>
    </Menu>
  </>
}
