import React, { cloneElement, useMemo, useContext } from 'react';
import {
  sanitizeListRestProps,
  useListContext,
  useResourceContext,
  useResourceDefinition,
  TopToolbar,
  CreateButton,
  FilterContext,
  FilterButton,
} from 'react-admin';

import ExportButton from './/ExportButton';
import { ResetFiltersButton } from '../ResetFiltersButton';


/**
 * Action Toolbar for the List view
 *
 * Internal component. If you want to add or remove actions for a List view,
 * write your own ListActions Component. Then, in the <List> component,
 * use it in the `actions` prop to pass a custom component.
 *
 * @example
 *     import { cloneElement } from 'react';
 *     import Button from '@material-ui/core/Button';
 *     import { TopToolbar, List, CreateButton, ExportButton } from 'react-admin';
 *
 *     const PostListActions = ({ basePath, filters }) => (
 *         <TopToolbar>
 *             { cloneElement(filters, { context: 'button' }) }
 *             <CreateButton/>
 *             <ExportButton/>
 *             // Add your custom actions here //
 *             <Button onClick={customAction}>Custom Action</Button>
 *         </TopToolbar>
 *     );
 *
 *     export const PostList = (props) => (
 *         <List actions={<PostListActions />} {...props}>
 *             ...
 *         </List>
 *     );
 */
const ListActions = props => {
  const { className, exporter = false, resetFilters = false, filters: filtersProp, actions = [], ...rest } = props;
  const {
    currentSort,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext(props);
  const resource = useResourceContext(rest);
  const { hasCreate } = useResourceDefinition(rest);
  const filters = useContext(FilterContext) || filtersProp;

  return useMemo(() => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filtersProp
        ? cloneElement(filtersProp, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })
        : filters && <FilterButton />}
      {resetFilters && <ResetFiltersButton {...props} />}
      {!!actions?.length && actions.map(action => action)}
      {exporter && (
        <ExportButton
          exporter={exporter}
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues} />
      )}
      {hasCreate && <CreateButton basePath={basePath} />}
    </TopToolbar>
  ),
  /* eslint-disable react-hooks/exhaustive-deps */
  [
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    filters,
    total,
    basePath,
    className,
    currentSort,
    exporter,
    hasCreate,
    resetFilters
  ]
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export default ListActions;
