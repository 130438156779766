import React from 'react';
import { useRecordContext } from 'react-admin';
import { LockOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { ConstraintsGrid } from '../../schedulingConstraints/ConstraintsGrid';
import { AddConstraintButton } from '../../schedulingConstraints/ConstraintsModal';

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const showConstraints = isAuthorized(office, 'schedulingConstraints', 'list')
  const canAddConstraints = isAuthorized(office, 'schedulingConstraints', 'create')

  if (!showConstraints) return null;
  return <ListCard
    title="resources.schedulingConstraints.labels.card.title"
    subtitle="resources.schedulingConstraints.labels.card.subtitle"
    icon={<LockOutlined />}
    collapsable
    reference="schedulingConstraints"
    target="officeId"
    filter={{ seasonId }}
    sort={{ field: 'name', order: 'ASC' }}
    addButton={canAddConstraints && <AddConstraintButton initialValues={{ officeId: office?.id, seasonId: seasonId }} office={office} />}
  >
    <ConstraintsGrid rowOptions={{ office }} />
  </ListCard>
}
