import React, { useState, cloneElement } from 'react';
import { Filter as RAFilter } from 'react-admin';

// load input dropdowns on focus
const LazyLoadFilter = (props) => {
  const [ activeFilters, setActiveFilters ] = useState({});

  const handleFocus = (filterName) => {
    setActiveFilters(prev => ({
      ...prev,
      [filterName]: true
    }));
  };

  // append onFocus and enableGetChoices props to children (inputs)
  const enhancedChildren = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return cloneElement(child, {
        onFocus: () => handleFocus(child.props.source),
        enableGetChoices: () => !!activeFilters[child.props.source],
        ...child.props
      });
    }
    return child;
  });

  return (
    <RAFilter {...props}>
      {enhancedChildren}
    </RAFilter>
  );
};

export default LazyLoadFilter;
