import React, { useCallback, useEffect, useState } from 'react';
import { useRecordContext, ListContextProvider, ResourceContextProvider, useGetManyReference, useResourceContext } from 'react-admin';
import { get, orderBy, pickBy } from 'lodash';

import { isEmpty } from '@hisports/parsers';

import { useList } from '../../common/ra/useList';
import { useGroupArenas } from './EventViewSettings';
import { isAuthorized } from '../../common/Authorize';
import { useScopes } from '../../http';

// forked from useReferenceManyFieldController

const useEventReferenceController = ({ includeGames = true, includeDraftGames = false, includePractices = true, includeActivities = false, includeSlots = false, includeAvailabilities = false, ...props }) => {
  const scopes = useScopes()
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const [ groupArena ] = useGroupArenas();
  const [ serverFilters, setServerFilters ] = useState({});

  const { reference = resource, target, filter: initialFilter = {}, source = 'id', basePath, page: initialPage, perPage: initialPerPage = 25, sort: initialSort = { field: 'startTime', order: 'ASC' } } = props;
  const referenceId = get(record, source);

  // Authorization check for data inclusion
  const enableGames = includeGames && isAuthorized(scopes, 'games', 'list');
  const enableDraftGames = includeDraftGames && isAuthorized(scopes, 'draftGames', 'list');
  const enablePractices = includePractices && isAuthorized(scopes, 'practices', 'list');
  const enableActivities = includeActivities && isAuthorized(scopes, 'activities', 'list');
  const enableSlots = includeSlots && isAuthorized(scopes, 'arenaslots', 'list');
  const enableAvailabilities = includeAvailabilities && isAuthorized(scopes, 'availabilities', 'list');
  const filter = { ...initialFilter, ...serverFilters, _include: groupArena ? 'surface' : undefined } // necessary so that loopback-filter sorts in useList correctly

  const { data: games, ids: gamesIds, ...gamesStatus } = useGetManyReference('games', target, referenceId, { page: 1, perPage: 999999 }, initialSort, filter, resource, { enabled: enableGames })
  const { data: draftGames, ids: draftGamesIds, ...draftGamesStatus } = useGetManyReference('draftGames', target, referenceId, { page: 1, perPage: 999999 }, initialSort, { ...filter, isPublished: false, updatedGameId: null }, resource, { enabled: enableDraftGames })
  const { data: practices, ids: practicesIds, ...practicesStatus } = useGetManyReference('practices', target, referenceId, { page: 1, perPage: 999999 }, initialSort, filter, resource, { enabled: enablePractices })
  const { data: activities, ids: activitiesIds, ...activitiesStatus } = useGetManyReference('activities', target, referenceId, { page: 1, perPage: 999999 }, initialSort, filter, resource, { enabled: enableActivities })
  const { data: slots, ids: slotsIds, ...slotsStatus } = useGetManyReference('arenaslots', target, referenceId, { page: 1, perPage: 999999 }, initialSort, filter, resource, { enabled: enableSlots })
  const { data: availabilities, ids: availabilitiesIds, ...availabilitiesStatus } = useGetManyReference('availabilities', target, referenceId, { page: 1, perPage: 999999 }, initialSort, filter, resource, { enabled: enableAvailabilities })

  const [ ids, setIds ] = useState([]);
  const [ data, setData ] = useState([]);
  useEffect(() => {
    let data = [
      ...(enableGames ? gamesIds.map(id => {
        if (!games[id]) return;
        games[id].resource = 'games';
        games[id].type = 'Game';
        return games[id];
      }).filter(Boolean) : []),

      ...(enableDraftGames ? draftGamesIds.map(id => {
        if (!draftGames[id]) return;
        draftGames[id].resource = 'draftGames';
        draftGames[id].type = 'Draft Game';
        return draftGames[id];
      }).filter(Boolean) : []),

      ...(enablePractices ? practicesIds.map(id => {
        if (!practices[id]) return;
        practices[id].resource = 'practices';
        return practices[id];
      }).filter(Boolean) : []),

      ...(enableActivities ? activitiesIds.map(id => {
        if (!activities[id]) return;
        activities[id].resource = 'activities';
        return activities[id];
      }).filter(Boolean) : []),

      ...(enableSlots ? slotsIds.map(id => {
        if (!slots[id]) return;
        slots[id].resource = 'arenaslots';
        slots[id].type = 'Slot';
        return slots[id];
      }).filter(Boolean) : []),

      ...(enableAvailabilities ? availabilitiesIds.map(id => {
        if (!availabilities[id]) return;
        availabilities[id].resource = 'availabilities';
        availabilities[id].type = 'Availability';
        return availabilities[id];
      }).filter(Boolean) : []),
    ]

    if (!isEmpty(initialSort)) {
      const order = Array.isArray(initialSort.order) ? initialSort.order.map(order => order.toLowerCase()) : initialSort.order.toLowerCase();
      data = orderBy(data, initialSort.field, order);
    }

    const ids = data.map(event => event.id).filter(Boolean);
    setData(data);
    setIds(ids);
  }, [ gamesIds, games, enableGames, draftGamesIds, draftGames, enableDraftGames, practicesIds, practices, enablePractices, activitiesIds, activities, enableActivities, slotsIds, slots, enableSlots, availabilitiesIds, availabilities, enableAvailabilities, initialSort ])

  const gamesLoaded = enableGames ? gamesStatus.loaded : true;
  const gamesLoading = enableGames ? gamesStatus.loading : false;
  const gamesError = enableGames ? gamesStatus.error : null;
  const draftGamesLoaded = enableDraftGames ? draftGamesStatus.loaded : true;
  const draftGamesLoading = enableDraftGames ? draftGamesStatus.loading : false;
  const draftGamesError = enableDraftGames ? draftGamesStatus.error : null;
  const practicesLoaded = enablePractices ? practicesStatus.loaded : true;
  const practicesLoading = enablePractices ? practicesStatus.loading : false;
  const practicesError = enablePractices ? practicesStatus.error : null;
  const activitiesLoaded = enableActivities ? activitiesStatus.loaded : true;
  const activitiesLoading = enableActivities ? activitiesStatus.loading : false;
  const activitiesError = enableActivities ? activitiesStatus.error : null;
  const slotsLoaded = enableSlots ? slotsStatus.loaded : true;
  const slotsLoading = enableSlots ? slotsStatus.loading : false;
  const slotsError = enableSlots ? slotsStatus.error : null;
  const availabilitiesLoaded = enableAvailabilities ? availabilitiesStatus.loaded : true;
  const availabilitiesLoading = enableAvailabilities ? availabilitiesStatus.loading : false;
  const availabilitiesError = enableAvailabilities ? availabilitiesStatus.error : null;

  const context = useList({
    data,
    ids,
    sort: initialSort,
    page: initialPage,
    perPage: initialPerPage,
    loaded: gamesLoaded && draftGamesLoaded && practicesLoaded && activitiesLoaded && slotsLoaded && availabilitiesLoaded,
    loading: gamesLoading || draftGamesLoading || practicesLoading || activitiesLoading || slotsLoading || availabilitiesLoading,
    error: gamesError || draftGamesError || practicesError || activitiesError || slotsError || availabilitiesError,
  })

  const setFilters = (filterValues, displayedFilters) => {
    const { effectiveSurfaces, scheduleOffices, teamOffices, homeTeamOffices, awayTeamOffices, surfaceOffices, ...rest } = filterValues
    const serverFilters = pickBy({ effectiveSurfaces, scheduleOffices, teamOffices, homeTeamOffices, awayTeamOffices, surfaceOffices }, value => typeof value !== 'undefined')
    setServerFilters(serverFilters)
    context.setFilters(filterValues, displayedFilters)
  }

  const refetch = useCallback(() => {
    gamesStatus.refetch();
    draftGamesStatus.refetch();
    practicesStatus.refetch()
    activitiesStatus.refetch();
    slotsStatus.refetch();
    availabilitiesStatus.refetch();
  }, [ gamesStatus, draftGamesStatus, practicesStatus, activitiesStatus, slotsStatus, availabilitiesStatus ])

  return {
    basePath: basePath ? basePath.replace(resource, reference) : `/${reference}`,
    resource,
    ...context,
    refetch,
    setFilters
  }
}

export const EventReferenceManyBase = ({ children, ...props }) =>
  <ListContextProvider value={useEventReferenceController(props)}>
    { children }
  </ListContextProvider>

export const EventReferenceManyField = ({ children, ...props }) => {
  const controllerProps = useEventReferenceController(props);
  return <ResourceContextProvider value="games">
    <ListContextProvider value={controllerProps}>
      { children }
    </ListContextProvider>
  </ResourceContextProvider>
}
