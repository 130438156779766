import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

import { FF_PITCHERS } from '@hisports/common/featureFlags';
import { isEmpty } from '@hisports/parsers';

import { useFlag } from '../../../http';
import FunctionField from '../../../common/fields/FunctionField'
import { VarianceDatagrid } from './VarianceModal';
import { CategoriesField } from '../../categories/CategoriesField';

const useStyles = makeStyles({
  tbody: {
    '& .column-categoryIds': {
      width: '25%',
    },
    '& .column-lineupLimits': {
      width: '25%',
    }
  }
})

const LineupLimitsField = props =>
  <FunctionField {...props} render={({ lineupLimits }, source, resource, translate) => {
    const positions = ['players', 'skaters', 'goalies', 'staff']
    const output = [];
    for (const position of positions) {
      const values = lineupLimits && lineupLimits[position];
      if (!values) continue;
      const positionName = translate(`resources.rulebooks.labels.${position}`).toLowerCase();
      if (values.min != null && values.max != null) {
        output.push(<div>{translate('resources.rulebooks.values.lineupLimits.between', { position: positionName, min: values.min, max: values.max })}</div>)
      } else if (values.min != null) {
        output.push(<div>{translate('resources.rulebooks.values.lineupLimits.min', { position: positionName, min: values.min })}</div>)
      } else if (values.max != null) {
        output.push(<div>{translate('resources.rulebooks.values.lineupLimits.max', { position: positionName, max: values.max })}</div>)
      }
    }
    if (isEmpty(output)) return '-'

    return output;
  }} />

const PitcherLimitsField = props =>
  <FunctionField {...props} render={({ pitcherLimits }, source, resource, translate) => {
    if (isEmpty(pitcherLimits)) return '-'
    const limits = [];

    for (const pitcherLimit of pitcherLimits) {
      const scheduleTypes = [];
      const throwsLimits = [];
      const inningsPitchedLimits = [];
      const inningsPitchedRests = [];

      pitcherLimit?.scheduleTypes?.map(type => {
        scheduleTypes.push(translate(`resources.rulebooks.values.pitcherLimits.scheduleTypes.${type}`))
      })

      pitcherLimit?.throwsLimits?.map(value => {
        throwsLimits.push(translate('resources.rulebooks.values.pitcherLimits.throwsLimits.between',
          { min: value.min, max: value.max, restingDays: value.restingDays })
        )
      })

      pitcherLimit?.inningsPitchedLimits?.map(value => {
        if (isEmpty(value.startDate) && isEmpty(value.endDate)) {
          inningsPitchedLimits.push(translate('resources.rulebooks.values.pitcherLimits.inningsPitchedLimits.always',
            { limitType: translate(`resources.rulebooks.values.pitcherLimits.limitType.${value.limitType}`), limit: value.limit })
          )
        } else if (!isEmpty(value.startDate) && isEmpty(value.endDate)) {
          inningsPitchedLimits.push(translate('resources.rulebooks.values.pitcherLimits.inningsPitchedLimits.from',
            { limitType: translate(`resources.rulebooks.values.pitcherLimits.limitType.${value.limitType}`), limit: value.limit, startDate: moment.utc(value.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') })
          )
        } else if (!isEmpty(value.startDate) && !isEmpty(value.endDate)) {
          inningsPitchedLimits.push(translate('resources.rulebooks.values.pitcherLimits.inningsPitchedLimits.between',
            { limitType: translate(`resources.rulebooks.values.pitcherLimits.limitType.${value.limitType}`), limit: value.limit, startDate: moment.utc(value.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), endDate: moment.utc(value.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') })
          )
        } else if (isEmpty(value.startDate) && !isEmpty(value.endDate)) {
          inningsPitchedLimits.push(translate('resources.rulebooks.values.pitcherLimits.inningsPitchedLimits.until',
            { limitType: translate(`resources.rulebooks.values.pitcherLimits.limitType.${value.limitType}`), limit: value.limit, endDate: moment.utc(value.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') })
          )
        }
      })

      pitcherLimit?.inningsPitchedRests?.map(value => {
        inningsPitchedRests.push(translate('resources.rulebooks.values.pitcherLimits.inningsPitchedRests.between',
          { min: value.min, max: value.max, restingDays: value.restingDays })
        )
      })

      if (scheduleTypes && scheduleTypes.length) {
        limits.push(<strong>{scheduleTypes.join(", ")}</strong>)
      }
      if (throwsLimits && throwsLimits.length) {
        limits.push(<figure>{translate('resources.rulebooks.labels.throws_limits')}<ul>{throwsLimits.map(limit => <li>{limit}</li>)}</ul></figure>)
      }
      if (inningsPitchedLimits && inningsPitchedLimits.length) {
        limits.push(<figure>{translate('resources.rulebooks.labels.innings_pitched_limits')}<ul>{inningsPitchedLimits.map(limit => <li>{limit}</li>)}</ul></figure>)
      }
      if (inningsPitchedRests && inningsPitchedRests.length) {
        limits.push(<figure>{translate('resources.rulebooks.labels.innings_pitched_rests')}<ul>{inningsPitchedRests.map(limit => <li>{limit}</li>)}</ul></figure>)
      }
    }

    return limits.map(limit => limit)
  }} />

export const VarianceGrid = props => {
  const classes = useStyles();
  const isEnabled = useFlag();

  return <VarianceDatagrid size="medium" classes={classes} {...props}>
    <CategoriesField source="categoryIds" />
    <LineupLimitsField source="lineupLimits" />
    {isEnabled(FF_PITCHERS) && <PitcherLimitsField source="pitcherLimits" />}
  </VarianceDatagrid>
}
