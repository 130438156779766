export const ADD_MEMBER_TO_LINEUP = 'ADD_MEMBER_TO_LINEUP';
export const REMOVE_MEMBER_FROM_LINEUP = 'REMOVE_MEMBER_FROM_LINEUP';

export const ADD_MEMBERS_TO_LINEUP = 'ADD_MEMBERS_TO_LINEUP';
export const REMOVE_MEMBERS_FROM_LINEUP = 'REMOVE_MEMBERS_FROM_LINEUP';
export const CLEAR_LINEUP = 'CLEAR_LINEUP';
export const RESTORE_LINEUP = 'RESTORE_LINEUP';

export const EDIT_LINEUP_MEMBER = 'EDIT_LINEUP_MEMBER';

export const addMemberToLineup = (gameId, teamId, member) => ({
  type: ADD_MEMBER_TO_LINEUP,
  payload: {
    gameId,
    teamId,
    member,
  }
});

export const removeMemberFromLineup = (gameId, teamId, member) => ({
  type: REMOVE_MEMBER_FROM_LINEUP,
  payload: {
    member,
    gameId,
    teamId,
  }
});

export const addMembersToLineup = (gameId, teamId, members) => ({
  type: ADD_MEMBERS_TO_LINEUP,
  payload: {
    gameId,
    teamId,
    members
  }
});

export const clearLineup = (gameId, teamId, type) => ({
  type: CLEAR_LINEUP,
  payload: {
    gameId,
    teamId,
    type
  }
});

export const restoreLineup = (gameId, teamId, lineup) => ({
  type: RESTORE_LINEUP,
  payload: {
    gameId,
    teamId,
    lineup
  }
})

export const editLineupMember = (gameId, teamId, member) => ({
  type: EDIT_LINEUP_MEMBER,
  payload: {
    gameId,
    teamId,
    member
  }
})
