import React from 'react';
import { useRecordContext } from 'react-admin';
import { Typography, Link, Table, TableBody, TableRow, TableCell, Box, Card as MuiCard, CardHeader, styled } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

const Card = styled(MuiCard)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const Image = styled('img')(({ theme }) => ({
  display: 'block',
  margin: `${theme.spacing(1)}px auto`,
  borderRadius: '4px',
  maxHeight: 450,
}))

const hasContent = block =>
  block.type ? block.children.some(hasContent) : !!block.text;

const Block = ({ block, parentType = null }) => {
  if (!block.type) {
    if (!block.text) return null;

    return <Box
      component="span"
      fontWeight={block.bold ? "bold" : undefined}
      fontStyle={block.italic ? 'italic' : undefined}
      style={block.underline ? { textDecoration: 'underline' } : undefined}
    >
      {block.text}
    </Box>
  }

  const children = block.children
    .filter(hasContent)
    .map(child => <Block block={child} parentType={block.type} />)

  if (!children.length && block.type !== 'attachment') return null;

  switch (block.type) {
    case 'attachment': {
      if (!block || !block.file || !block.file.url) return null;
      if (block.isImage) return <Image src={block.file.url} alt="" />
      return <Link href={block.file.url} underline="none">
        <Card variant="outlined">
          <CardHeader avatar={<GetApp />} title={block.title} />
        </Card>
      </Link>
    }

    case 'heading-one':
      return <Typography variant="h4" component="h1">{children}</Typography>

    case 'heading-two':
      return <Typography variant="h5" component="h2">{children}</Typography>

    case 'link':
      return <Typography display="inline">
        <Link href={block.url}>{children}</Link>
      </Typography>

    case 'bulleted-list':
      return <ul>{children}</ul>

    case 'numbered-list':
      return <ol>{children}</ol>

    case 'list-item':
      return <li>{children}</li>

    case 'block-quote':
      return <Typography paragraph component="blockquote" align="center">
        {children}
      </Typography>

    case 'table':
      return <Table>
        <TableBody>
          {children}
        </TableBody>
      </Table>

    case 'table-row':
      return <TableRow>{children}</TableRow>

    case 'table-cell':
      return <TableCell>{children}</TableCell>

    case 'paragraph':
    default:
      if (parentType === 'list-item') return <Typography display="inline">{children}</Typography>;
      return <Typography paragraph>
        {children}
      </Typography>
  }
}

export default ({ source, ...props }) => {
  const record = useRecordContext(props);
  if (!source || !record) return null;

  const content = record[source];
  if (!content) return null;

  const blocks = typeof content === 'string' ? JSON.parse(content) : content;
  return <p>
    {blocks.map(block => <Block block={block} />)}
  </p>
}
