import React from 'react';
import { makeStyles } from '@material-ui/core';
import { BooleanField } from 'react-admin';

import TimeRangeField from '../../common/fields/TimeRangeField';
import DateField from '../../common/fields/DateField';
import TextArrayField from '../../common/fields/TextArrayField';

import { OfficeField } from '../offices/OfficeField';
import { OfficesField } from '../offices/OfficesField';
import { CategoriesField } from '../categories/CategoriesField';
import { SurfaceField } from '../surfaces/SurfaceField';

import { ArenaSlotDatagrid } from './ArenaSlotModal';

const useStyles = makeStyles({
  headerRow: {
    display: 'none',
  }
})

export const ArenaSlotsGrid = ({ showOffice = true, showSurface = false, ...props }) => {
  const classes = useStyles();

  return <ArenaSlotDatagrid size="medium" className={classes} {...props}>
    <DateField source="startTime" label="ra.date.name" />
    <TimeRangeField startSource="startTime" endSource="endTime" label="ra.date.time" />
    { showOffice && <OfficeField source="officeId" /> }
    { showSurface && <SurfaceField source="arenaId" /> }
    <CategoriesField source="categoryIds" emptyText="ra.message.all" />
    <TextArrayField source="types" emptyText="ra.message.all" />
    <OfficesField source="leagueIds" emptyText="ra.message.all" />
    <BooleanField source="isShared" label="ra.message.shared_slot" />
  </ArenaSlotDatagrid>
}
