import React from 'react';
import { useRecordContext } from 'react-admin';
import { EmojiPeople } from '@material-ui/icons';
import moment from 'moment';

import { useParticipant, usePermissions } from '../../../http';
import ListCard from '../../../common/cards/ListCard';
import { ClaimsGrid, isAssigner } from '../../officialClaims/ClaimsGrid';
import { AddClaimButton } from '../../officialClaims/ClaimModal';
import { useGameAssignSettings, useGameOfficials } from '../../officialClaims/ClaimForm';
import { ClaimBulkAction } from '../../officialClaims/ClaimBulkActions';


export default ({ ...props }) => {
  const game = useRecordContext(props);
  const permissions = usePermissions();
  const participantId = useParticipant()
  const { data: officials } = useGameOfficials(game?.id);
  const { data: gameAssignSettings } = useGameAssignSettings(game?.id)

  const participantAssignment = officials?.find(official => official.participantId === participantId)
  const officeId = participantAssignment?.payOfficeId || gameAssignSettings?.officeId;
  const canAddClaims = moment().isSameOrAfter(game?.endTime) || game?.isApproved || gameAssignSettings?.allowEarlyClaims

  return <ListCard
    title="resources.officialClaims.labels.card.title"
    icon={<EmojiPeople />}
    reference="officialClaims"
    target="gameId"
    sort={{ field: ['participant.lastName', 'participant.firstName', 'type', 'subtype', 'status'], order: ['ASC', 'ASC', 'ASC', 'ASC', 'ASC'] }}
    perPage={9999}
    bulkActionButtons={isAssigner(permissions, officeId) && <ClaimBulkAction />}
    noResultsText={!canAddClaims ? "resources.officialClaims.messages.claim_unavailable" : undefined}
    addButton={<AddClaimButton
      disabled={!canAddClaims}
      target="gameId"
      initialValues={{
        gameId: game?.id,
        participantId: participantAssignment ? participantId : null,
        officeId: participantAssignment ? officeId : null,
      }}
    />}
    collapsable
  >
    <ClaimsGrid hideGame />
  </ListCard>
}
