import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

import { getPenalties, isGameActive, isGameCertified, isGameCompleted, isGameScheduled, isLineupApproved } from '@hisports/scoresheet/src/selectors';
import { FF_REQUIRE_GAME_START, FF_REQUIRE_LINEUPS } from '@hisports/common/featureFlags';

import { isAuthorized } from '../../../../common/Authorize';
import { useSport, useScopes, useFlag } from '../../../../http';
import { useBranchSettings } from '../../../branchOfficeSettings';
import { useMeta, useScoresheet } from '../../ScoresheetContext';
import { useIncidentReports } from '../../gameIncidents/useIncidentReports';
import { CertificationAlerts } from '../../certification/alerts';

import { ApproveAlert } from './ApproveAlert';
import { CompletedAlert } from './CompletedAlert';
import { StartAlert } from './StartAlert';

const LineupAlert = props => {
  const translate = useTranslate();
  return <Alert severity="info">
    {translate('resources.scoresheets.alerts.lineup')}
  </Alert>
}

export const GameActionAlerts = ({ isAssignedOfficial, isScorekeeper, ...props }) => {
  const { game, meta } = useMeta();
  const isEnabled = useFlag();
  const sport = useSport();
  const scopes = useScopes();
  const { data: branchSettings } = useBranchSettings(game?.officeId);

  const lineupApproved = useScoresheet(scoresheet =>
    isLineupApproved(scoresheet, { teamId: game.homeTeamId }) &&
    isLineupApproved(scoresheet, { teamId: game.awayTeamId })
  )
  const isScheduled = useScoresheet(scoresheet => isGameScheduled(scoresheet))
  const isActive = useScoresheet(scoresheet => isGameActive(scoresheet))
  const isCertified = useScoresheet(scoresheet => isGameCertified(scoresheet))
  const isCompleted = useScoresheet(scoresheet => isGameCompleted(scoresheet))
  const isIncidentsRequired = meta?.policies.requireIncidentReports && branchSettings?.ff_incidents;
  const isLineupApprovedRequired = isEnabled(FF_REQUIRE_LINEUPS)
  const isGameStartRequired = isEnabled(FF_REQUIRE_GAME_START)
  const isLineupReady = !isLineupApprovedRequired || lineupApproved

  const incidentRequiredPenalties = useScoresheet(scoresheet => getPenalties(scoresheet, meta.infractions, meta.types, sport, game.seasonId, { requireIncidentReport: true }))

  const enabled = (isAuthorized(scopes, 'gameIncidents', 'show') || isAssignedOfficial) && branchSettings?.ff_incidents;
  const { data: incidents, loading: incidentsLoading, error: incidentsError } = useIncidentReports(game?.id, enabled);

  const hasPenaltiesWithoutReport = useMemo(() => {
    return !incidentRequiredPenalties.every(penalty => incidents?.some(incident => incident.penaltyId === penalty.id))
  }, [incidents, incidentRequiredPenalties])

  const showLineupAlert = !isLineupReady && isScheduled
  const showStartGameAlert = isGameStartRequired && isLineupReady && isScheduled
  const showApproveAlert = (!isGameStartRequired && isLineupReady && isActive) || (isGameStartRequired && isLineupReady && isActive && !isScheduled)
  const showCompleteAlert = isCompleted && !isCertified;
  const showCertification = isCompleted && meta?.policies?.requireCertification === true;

  return <Grid container spacing={1}>
    {showLineupAlert && <Grid item xs={12}>
      <LineupAlert />
    </Grid>}
    {showStartGameAlert && <Grid item xs={12}>
      <StartAlert canStart={isScorekeeper} />
    </Grid>}
    {showApproveAlert && <Grid item xs={12}>
      <ApproveAlert canApprove={isScorekeeper} isMissingIncidents={isIncidentsRequired && hasPenaltiesWithoutReport} disabled={!!incidentRequiredPenalties?.length && incidentsLoading} />
    </Grid>}
    {showCompleteAlert && <Grid item xs={12}>
      <CompletedAlert showUndoApproval canUndoApproval={isScorekeeper} isCertified={isCertified} />
    </Grid>}
    {showCertification && <Grid item xs={12}>
      <CertificationAlerts isAssignedOfficial={isAssignedOfficial} isScorekeeper={isScorekeeper} />
    </Grid>}
  </Grid>
}
