import React from 'react';
import { TextInput, SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { useFormState } from 'react-final-form';

import { isLegacyRulebookSeason } from '@hisports/scoresheet/src/util';
import { FF_PITCHERS } from '@hisports/common/featureFlags';

import Toolbar from '../../common/ra/Toolbar';
import { EnumInput, SeasonInput } from '../../common/inputs/EnumInputs'
import { useFlag } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';

import { Durations } from './durations';
import { Infractions } from './infractions';

import { LimitsInput } from './LimitsInput';
import { Variances } from './variances';
import { PitcherLimits } from './pitcherLimits';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const ejections = [
  { id: 'None', name: 'resources.rulebooks.values.ejections.None' },
  { id: 'Contact', name: 'resources.rulebooks.values.ejections.Contact' },
  { id: 'All', name: 'resources.rulebooks.values.ejections.All' },
]

const EjectionsInput = props =>
  <EnumInput choices={ejections} {...props} />

const RulebookFormBody = () => {
  const translate = useTranslate();
  const isEnabled = useFlag();

  const { values: { seasonId } } = useFormState();
  const isLegacy = isLegacyRulebookSeason(seasonId);

  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12} md={8}>
      <TextInput source="name" autoFocus {...inputProps} />
    </Grid>

    <Grid item xs={12} md={4}>
      <SeasonInput source="seasonId" disabled {...inputProps} />
    </Grid>

    <Grid item xs={12}>
      <OfficeInput source="officeId" disabled {...inputProps} />
    </Grid>

    {isLegacy && <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.rulebooks.labels.durations')}</Typography>
      <Alert severity="info" variant="outlined">
        {translate('resources.rulebooks.alerts.durations')}
      </Alert>

      <Durations source="durations" />
    </Grid>}

    {isLegacy && <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.rulebooks.labels.infractions')}</Typography>
      <Alert severity="info" variant="outlined">
        {translate('resources.rulebooks.alerts.infractions')}
      </Alert>

      <Infractions source="infractions" />
    </Grid>}

    <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.rulebooks.labels.lineup_limits')}</Typography>
      <Alert severity="info" variant="outlined">
        {translate('resources.rulebooks.alerts.lineup_limits')}
      </Alert>

      <LimitsInput source="lineupLimits" />
    </Grid>

    {isEnabled(FF_PITCHERS) && <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.rulebooks.labels.pitcher_limits')}</Typography>
      <PitcherLimits source="pitcherLimits" />
    </Grid>}

    <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.rulebooks.labels.variances')}</Typography>
      <Variances source="variances" />
    </Grid>

    {isLegacy && <Grid item xs={12}>
      <Typography variant="subtitle2">{translate('resources.rulebooks.labels.additional_policies')}</Typography>
      <Alert severity="info" variant="outlined">
        {translate('resources.rulebooks.alerts.additional_policies')}
      </Alert>
    </Grid>}
    {isLegacy && <Grid item xs={12} md={6}>
      <EjectionsInput
        allowEmpty
        source="ejections"
        helperText="resources.rulebooks.helpers.ejections"
        {...inputProps}
      />
    </Grid>}
  </Grid>
}
export const RulebookForm = ({ save, ...props }) => {
  const onSave = (rulebook) => {
    return save(rulebook, `/offices/${rulebook.officeId}/show/settings`);
  }

  return <SimpleForm toolbar={<Toolbar />} save={onSave} {...props}>
    <RulebookFormBody />
  </SimpleForm>
}
