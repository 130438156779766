import React from 'react';
import { useTranslate } from 'react-admin';
import { Table, TableHead, TableBody, TableRow, TableCell, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Clear, Check } from '@material-ui/icons';
import moment from 'moment-timezone';

import { isLocalTimezone } from '@hisports/parsers/src/dateTime';

const duration = ({ startTime, endTime }) => {
  const diff = moment(endTime).diff(startTime);
  return moment.duration(diff).asHours();
}

const totalDuration = slots =>
  slots.reduce((total, slot) => total + duration(slot), 0)

const isAllDay = slots => {
  if (!slots.length) return false;
  const hours = totalDuration(slots);
  return hours > 22;
}

const getBackgroundColor = ({ selected, available, unavailable }, theme) => {
  if (selected) return theme.palette.primary.light;
  if (available) return theme.palette.common.white;
  if (unavailable) return theme.palette.grey[300];
  return theme.palette.grey[100];
}

const getIconColor = ({ selected, available, unavailable }, theme) => {
  if (selected) return theme.palette.common.black;
  if (available) return theme.palette.success.main;
  if (unavailable) return theme.palette.error.main;
  return theme.palette.grey[400];
}

const useDayStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: theme.spacing(10),
    backgroundColor: props => getBackgroundColor(props, theme),
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  day: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    fontWeight: props => props.today ? 'bold' : undefined,
    color: props => props.past ? theme.palette.text.disabled : undefined,
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
  },
  icon: {
    color: props => getIconColor(props, theme),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  time: {
    position: 'absolute',
    top: theme.spacing(3.5),
    lineHeight: '1em',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '.9em',
      fontSize: '.9em',
    },
  },
  info: {
    color: theme.palette.text.secondary,
    fontSize: '.65rem',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '.5rem',
      fontSize: '.5rem',
    },
  },
}))

const useSlotStyles = makeStyles(theme => ({
  root: {
    width: '.75em',
    height: '.75em',
    fontSize: '1.25em',
  },
  available: {
    color: theme.palette.success.main,
  },
  unavailable: {
    color: theme.palette.error.main,
  }
}))

const SlotTime = ({ slot, showRange }) => {
  const classes = useSlotStyles();
  const { startTime, endTime, timezone, isAvailable } = slot;
  const icon = isAvailable ? <Check className={`${classes.root} ${classes.available}`} /> : <Clear className={`${classes.root} ${classes.unavailable}`} />

  const start = moment.tz(startTime, timezone).format('HH:mm')
  const end = moment.tz(endTime, timezone).format('HH:mm')
  const range = `${start} - ${end}`
  return <div>{icon} {showRange ? range : start}</div>
}

const AllDay = ({ available, unavailable, classes }) => {
  const translate = useTranslate();
  return <>
    {available ? <Check className={classes.icon} />
      : unavailable ? <Clear className={classes.icon} />
        : null}
    {translate('ra.date.lexical.all_day')}
  </>
}

const Availability = ({ slots, available, unavailable, timezone, classes }) => {
  const translate = useTranslate();
  const showRange = useMediaQuery(theme => theme.breakpoints.up('sm'));
  if (!slots.length) return null;

  if (isAllDay(slots)) return <AllDay available={available} unavailable={unavailable} classes={classes} />;

  const timeslots = slots.slice(0, 2).map(slot => <SlotTime slot={slot} showRange={showRange} />);
  const offset = !isLocalTimezone(slots[0].startTime, slots[0].timezone, timezone);

  const info = []
  if (slots.length > 2) info.push(translate('resources.availabilities.labels.more', { smart_count: slots.length - 2 }))
  if (offset) info.push(moment.tz(slots[0].startTime, slots[0].timezone).format('z'))

  return <div className={classes.time}>
    {timeslots}
    {info.length ? <div className={classes.info}>{info.join(' / ')}</div> : null}
  </div>
}

const Day = ({ date, availabilities, selection, timezone, toggleSelection }) => {
  const selected = selection.includes(date);
  const onClick = event => toggleSelection(date, event.shiftKey, event.ctrlKey);

  const past = moment.tz(date, timezone).isBefore(undefined, 'day');
  const today = moment.tz(date, timezone).isSame(undefined, 'day');
  const day = moment.tz(date, timezone).date();

  const slots = availabilities.filter(availability => availability.date === date);
  const available = slots.some(slot => slot.isAvailable === true);
  const unavailable = slots.some(slot => slot.isAvailable === false)

  const classes = useDayStyles({ date, selected, available, unavailable, past, today })
  return <div className={classes.root} onClick={onClick}>
    <div className={classes.day}>
      {day}
    </div>
    <Availability date={date} slots={slots} available={available} unavailable={unavailable} timezone={timezone} classes={classes} />
  </div>
}

const Week = ({ dates, availabilities, selection, timezone, toggleSelection }) => {
  return <TableRow>
    {dates.map(date => <TableCell key={date} padding="none">
      <Day date={date} availabilities={availabilities} selection={selection} timezone={timezone} toggleSelection={toggleSelection} />
    </TableCell>)}
  </TableRow>
}

const useMonthStyles = makeStyles(theme => ({
  root: {
    tableLayout: 'fixed',
  }
}))

const PrevMonth = ({ date, timezone, setMonth }) => {
  const month = moment.tz(date, timezone).add(-1, 'months');
  const limit = moment.tz(timezone).add(-1, 'months')
  const enabled = month.isSameOrAfter(limit, 'month');
  const onClick = () => {
    if (!enabled) return;
    setMonth(-1);
  }
  const label = enabled ? `← ${month.format('MMMM YYYY')}` : ''
  return <TableCell colSpan={2} onClick={onClick}>
    <Button size="small">{label}</Button>
  </TableCell>
}

const NextMonth = ({ date, timezone, setMonth }) => {
  const month = moment.tz(date, timezone).add(1, 'months');
  const limit = moment.tz(timezone).add(4, 'months').endOf('year');
  const enabled = month.isSameOrBefore(limit, 'month');
  const onClick = () => {
    if (!enabled) return;
    setMonth(1)
  }
  const label = enabled ? `${month.format('MMMM YYYY')} →` : ''
  return <TableCell colSpan={2} onClick={onClick} align="right">
    <Button size="small">{label}</Button>
  </TableCell>
}

const Month = ({ date, dates, availabilities, timezone, selection, toggleSelection, setMonth }) => {
  const classes = useMonthStyles();
  const totalWeeks = dates.length / 7;

  const weeks = [];
  for (let week = 0; week < totalWeeks; week++) {
    const weekDates = dates.slice(week * 7, (week + 1) * 7);
    weeks.push(<Week key={week} dates={weekDates} availabilities={availabilities} timezone={timezone} selection={selection} toggleSelection={toggleSelection} />)
  }

  return <Table size="small" className={classes.root}>
    <TableHead>
      <TableRow>
        <PrevMonth date={date} setMonth={setMonth} />
        <TableCell colSpan={3} align="center">
          {moment.tz(date, timezone).format('MMMM YYYY')}
        </TableCell>
        <NextMonth date={date} setMonth={setMonth} />
      </TableRow>
      <TableRow>
        {dates.slice(0, 7).map(date => <TableCell align="center">{moment.tz(date, timezone).format('ddd')}</TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {weeks}
    </TableBody>
  </Table>
}

export default ({ date, dates, availabilities, timezone, selection, toggleSelection, setMonth }) => {
  return <Month date={date} dates={dates} availabilities={availabilities} timezone={timezone} selection={selection} toggleSelection={toggleSelection} setMonth={setMonth} />
}
