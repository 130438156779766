import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SingleFieldList as RaSingleFieldList } from 'react-admin';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    flexDirection: 'column'
  },
})

export const SingleFieldList = props => {
  const classes = useStyles();
  return <RaSingleFieldList classes={classes} {...props} />
}
