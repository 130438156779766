import React, { Suspense, useState, useContext } from 'react';
import { RecordContextProvider } from 'react-admin';
import { useSelector } from 'react-redux';

import AssignmentsDialog from './AssignmentsDialog';
import LineupDialog from './LineupDialog';
import CertifyDialog from './CertifyDialog';

// this is necessary to keep the dialog from disappearing on refresh
// as <List> unmounts the GameGrid component and <Layout> is high enough to avoid this

const GameContext = React.createContext(null);

export const useGameContext = () => useContext(GameContext);

export default ({ children }) => {
  const [ state, setGame ] = useState(null);
  const onClose = () => setGame(null);

  const open = !!state;

  const { gameId, type, teamId } = state || {}
  const game = useSelector(state => gameId && state.admin.resources.games.data[gameId]);

  return <GameContext.Provider value={setGame}>
    {children}
    <RecordContextProvider value={game}>
      <Suspense fallback={() => null}>
        {game && type === 'assign' && <AssignmentsDialog open={open} onClose={onClose} />}
        {game && type === 'lineup' && <LineupDialog open={open} teamId={teamId} onClose={onClose} />}
        {game && type === 'certify' && <CertifyDialog open={open} onClose={onClose} />}
      </Suspense>
    </RecordContextProvider>
  </GameContext.Provider>
}
