import React from 'react';
import { ReferenceField } from 'react-admin';

import { TextLocaleField } from '../../common/fields/TextLocaleField';

export const QualificationCategoryField = ({ variant = 'body2', ...props }) => {
  return <ReferenceField basePath="/qualificationCategories" resource="qualificationCategories" reference="qualificationCategories" link={false} {...props}>
    <TextLocaleField source="name" variant={variant} />
  </ReferenceField>
}

QualificationCategoryField.defaultProps = {
  addLabel: true,
}
