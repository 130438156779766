import React from 'react';
import { TextField, useTranslate } from "react-admin"
import { Tooltip } from '@material-ui/core';
import { Feedback, Timer } from '@material-ui/icons';
import { capitalize } from 'lodash';

import FunctionField from '../../../common/fields/FunctionField'

import { DurationDatagrid } from './DurationModal';

const Timed = () => {
  const translate = useTranslate();
  return <Tooltip title={translate('resources.rulebooks.fields.timed')}>
    <Timer />
  </Tooltip>
}

export const DurationGrid = props => {
  return <DurationDatagrid size="medium" {...props}>
    <TextField source="name" />
    <TextField source="abbreviation" />
    <TextField source="length" />
    <TextField source="description" />
    <FunctionField source="severity" render={({ severity }) => capitalize(severity)} />
    <FunctionField render={duration => [
      duration.timed && <Timed />,
      duration.requireIncidentReport && <Feedback />,
    ]} />
  </DurationDatagrid>
}
