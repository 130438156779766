import React from 'react';
import { SimpleForm, useTranslate, NumberInput, useRefresh } from 'react-admin';
import { DialogContentText, Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';

const validate = values => {
  const errors = {};
  if (!values.awaySeed) errors.awaySeed = 'ra.validation.required'
  if (!values.homeSeed) errors.homeSeed = 'ra.validation.required'
  return errors;
}

const inputProps = {
  resource: 'gameBrackets',
  basePath: '/gameBrackets',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const SeedsForm = props => {
  const translate = useTranslate();
  const refresh = useRefresh(true);

  return <SimpleForm toolbar={<Toolbar />} validate={validate} onSubmit={refresh} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} >
        <DialogContentText>
          {translate('resources.gameBrackets.messages.edit_seeds')}
        </DialogContentText>
      </Grid>
      <Grid item xs={12} lg={6}>
        <NumberInput source="awaySeed" label={translate('resources.gameBrackets.fields.seeds.away')} {...inputProps} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <NumberInput source="homeSeed" label={translate('resources.gameBrackets.fields.seeds.home')} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
