import { unionBy, uniqBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { getMembers } from '@hisports/scoresheet/src/selectors';

import { apiClient, useSport } from '../../../http';

const MemberValidationContext = React.createContext(null);

export const useMemberValidationContext = () => useContext(MemberValidationContext) || {};

const validateMembers = async (gameId, teamId, members = []) => {
  return apiClient(`/games/${gameId}/validateMembers`, {
    method: 'POST',
    data: { teamId, members }
  })
    .then(res => res.data)
}

export const MemberValidationProvider = ({ disabled = false, lineup, team, game, ...props }) => {
  const [ memberValidations, setMemberValidations ] = useState(team?.validations || []);
  const [ inFlight, setInFlight ] = useState(false);
  const sport = useSport();

  useEffect(() => {
    // set initial validatedMembers
    const rosterMembers = team?.members || [];
    const lineupMembers = disabled ? (lineup?.members || []) : getMembers(lineup, team, sport);
    const members = unionBy([...lineupMembers, ...(!game.isCertified ? rosterMembers : [])], 'participantId')
    if (!members.length) return;

    setInFlight(true);
    validateMembers(game.id, team.id, members)
      .then(setMemberValidations)
      .finally(() => setInFlight(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // check if any participants are missing from memberValidations
    if (inFlight || !memberValidations.length) return;
    const lineupMembers = disabled ? (lineup?.members || []) : getMembers(lineup, team, sport);

    const missingMembers = lineupMembers.filter(lineupMember => {
      return !memberValidations.some(member => member.participantId === lineupMember.participantId)
    });
    if (!missingMembers.length) return;

    setInFlight(true)
    validateMembers(game.id, team.id, missingMembers)
      .then(members => setMemberValidations(prevState => uniqBy([...members, ...prevState], 'participantId')))
      .finally(() => setInFlight(false))
  }, [ disabled, game.id, inFlight, lineup, team, memberValidations, sport ])

  const value = useMemo(() => ({
    memberValidations,
    loading: inFlight
  }), [
    memberValidations,
    inFlight
  ]);

  return <MemberValidationContext.Provider value={value} {...props} />
}
