import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import EditorInput from '../../common/inputs/EditorInput';
import { RoleEnumInput } from '../../common/inputs/EnumInputs';
import SwitchInput from '../../common/inputs/SwitchInput';

const validate = values => {
  const errors = {}

  if (!values.title) {
    errors.title = 'ra.validation.required';
  } else if (values.title.length > 80) {
    errors.title = 'ra.validation.title_too_long';
  }

  if (!values.content) errors.content = 'ra.validation.required';
  if (!values.category) errors.category = 'ra.validation.required';
  if (!values.roles || !values.roles.length) errors.roles = 'ra.validation.required';

  return errors;
}

const inputProps = {
  resource: 'help',
  basePath: '/help',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const ArticleForm = props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} md={10}>
        <TextInput source="title" autoFocus {...inputProps} />
      </Grid>
      <Grid item xs={12} md={2}>
        <SwitchInput source="isPublished" {...inputProps} defaultValue={true} />
      </Grid>
      <Grid item xs={12}>
        <EditorInput source="content" {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextInput source="category" {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <RoleEnumInput source="roles" multiple helperText="resources.help.helpers.roles" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
