import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { TextField, IconButton, Input, InputAdornment, FormControl, FormGroup, InputLabel, FormControlLabel, Select, MenuItem, Checkbox, makeStyles, ClickAwayListener, Radio } from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import { useAssignmentContext } from '../AssignmentContext';

export const useSearch = (initialState = '') => {
  const [ search, setSearch ] = useState(initialState);

  const onSearch = e => {
    setSearch(e.target.value);
  }
  const onClear = () => {
    setSearch('');
  }

  return { search, onSearch, onClear }
}

export const SearchInput = ({ label, className, value, placeholder, autoFocus, onChange, onClear }) => {
  return <TextField
    label={label}
    className={className}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    size="small"
    fullWidth
    autoFocus={autoFocus}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
      endAdornment: (
        value && <InputAdornment position="end">
          <IconButton onClick={onClear}>
            <Clear fontSize="small" />
          </IconButton>
        </InputAdornment>
      )
    }}
  />
}

const useSelectStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  attributes: {
    width: '100%',
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
    fontSize: '12px',
  },
  breakSpace: {
    whiteSpace: 'break-spaces'
  },
  search: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
  },
}))

const ListSelect = ({ name, label, lists, value, onChange }) => {
  const classes = useSelectStyles();
  if (!lists.length) return null;

  const handleChange = e => onChange(e.target.value)
  return <FormControl className={classes.root}>
    <InputLabel id={`${name}-label`}>{label}</InputLabel>
    <Select
      labelId={`${name}-label`}
      id={name}
      value={value}
      onChange={handleChange}
      input={<Input />}
      MenuProps={{
        disablePortal: true // required for ClickOverListener
      }}
    >
      <MenuItem value={null}><em>All officials</em></MenuItem>
      {lists.map(list => <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>)}
    </Select>
  </FormControl>
}

const AttributeValueSelect = ({ name, attributeTypes, toggleFilter, filters }) => {
  const translate = useTranslate();
  const classes = useSelectStyles();
  if (!attributeTypes.length) return null;

  const valueSelection = attributeTypes.map(attributeType => {
    switch (attributeType.valueType) {
      case 'Dropdown':
        return <FormControl className={classes.attributes} key={attributeType.id}>
          <InputLabel id={`${attributeType.name}-label`} shrink>{attributeType.name}</InputLabel>
          <Select
            labelId={`${name}-label`}
            id={`attributeValues[${attributeType.id}]`}
            name={`attributeValues[${attributeType.id}]`}
            multiple
            value={ !filters[`attributeValues[${attributeType.id}]`] ? [] : filters[`attributeValues[${attributeType.id}]`]}
            onChange={e => toggleFilter(`attributeValues[${attributeType.id}]`, e.target.value, true)}
            input={<Input />}
            inputProps={{ className: classes.breakSpace }}
            MenuProps={{ disablePortal: true /* required for ClickOverListener*/ }}
          >
            {attributeType.options.values.map(optionValue => <MenuItem key={optionValue} value={optionValue}>{optionValue}</MenuItem>)}
          </Select>
        </FormControl>
      case 'Text':
        return <SearchInput
          label={attributeType.name}
          className={classes.search}
          key={attributeType.id}
          autoFocus={false}
          name={`attributeValues[${attributeType.id}]`}
          value={!filters[`attributeValues[${attributeType.id}]`] ? '' : filters[`attributeValues[${attributeType.id}]`]}
          placeholder={translate('resources.games.labels.assignment.search_attributes')}
          onChange={e => toggleFilter(`attributeValues[${attributeType.id}]`, e.target.value, true)}
          onClear={() => toggleFilter(`attributeValues[${attributeType.id}]`, '', true)}
        />
      case 'Number':
        return <TextField
          className={classes.attributes}
          key={attributeType.id}
          id={`attributeValues[${attributeType.id}]`}
          label={attributeType.name}
          InputLabelProps={{ shrink: true }}
          name={`attributeValues[${attributeType.id}]`}
          type="number"
          value={!filters[`attributeValues[${attributeType.id}]`] ? '' : filters[`attributeValues[${attributeType.id}]`]}
          onChange={e => toggleFilter(`attributeValues[${attributeType.id}]`, e.target.value, true)}
        />
      default:
        return null;
    }
  })

  return valueSelection;
}

export const OfficialFilters = ({ className }) => {
  const { lists = [], attributeTypes = [], filters, isFilterMenuOpen, toggleFilter, toggleFilterMenu } = useAssignmentContext()
  const translate = useTranslate()
  const classes = useSelectStyles()

  if (!isFilterMenuOpen) return null;
  return <ClickAwayListener onClickAway={toggleFilterMenu}>
    <div className={className}>
      <FormGroup row>
        <ListSelect
          label={translate('ra.message.filter_by_list')}
          lists={lists} value={filters.listId} name="listId" onChange={value => toggleFilter('listId', value)}
        />
        <FormControlLabel
          label={translate('resources.officials.filters.unavailable')}
          control={<Checkbox checked={filters.unavailable} name="unavailable" size="small" color="primary" onClick={() => toggleFilter('unavailable')} />}
        />
        <FormControlLabel
          label={translate('resources.officials.filters.ineligible')}
          control={<Checkbox checked={filters.ineligible} name="ineligible" size="small" color="primary" onClick={() => toggleFilter('ineligible')} />}
        />
        <FormControlLabel
          label={translate('resources.officials.filters.expired')}
          control={<Checkbox checked={filters.expired} name="expired" size="small" color="primary" onClick={() => toggleFilter('expired')} />}
        />
        <FormControlLabel
          label={translate('resources.officials.filters.unregistered')}
          control={<Checkbox checked={filters.unregistered} name="unregistered" size="small" color="primary" onClick={() => toggleFilter('unregistered')} />}
        />
        <FormControlLabel
          label={translate('resources.officials.filters.new')}
          control={<Checkbox checked={filters.onlyNew} name="onlyNew" size="small" color="primary" onClick={() => toggleFilter('onlyNew')} />}
        />
        <FormControlLabel
          label={translate('resources.officials.filters.local')}
          control={<Checkbox checked={filters.onlyLocal} name="onlyLocal" size="small" color="primary" onClick={() => toggleFilter('onlyLocal')} />}
        />
        {(lists.length > 0 || filters.ignoreArena) && <FormControlLabel
          label={translate('resources.officials.filters.ignore_arena')}
          control={<Checkbox checked={filters.ignoreArena} name="ignoreArena" size="small" color="primary" onClick={() => toggleFilter('ignoreArena')} />}
        />}
        {attributeTypes.length > 0 && <>
          <InputLabel id="filter_by_attributes-label" className={classes.attributes}>{translate('ra.message.filter_by_attributes')}</InputLabel>
          <FormControlLabel label={translate('resources.officials.filters.match_all')}
            control={<Radio checked={filters.matchAllAttributes} name="matchAllAttributes" size="small" color="primary" onClick={() => toggleFilter('matchAllAttributes', true, true)} />}
          />
          <FormControlLabel label={translate('resources.officials.filters.match_any')}
            control={<Radio checked={!filters.matchAllAttributes} name="matchAnyAttributes" size="small" color="primary" onClick={() => toggleFilter('matchAllAttributes', false, true)} />}
          />
          <AttributeValueSelect attributeTypes={attributeTypes} toggleFilter={toggleFilter} filters={filters} />
        </>}
      </FormGroup>
    </div>
  </ClickAwayListener>
}
