import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { AddAttributeValuesButton } from '../../attributeValues/AttributeValuesModal';
import { AttributeValuesGrid } from '../../attributeValues/AttributeValuesGrid';
import { useScopes } from '../../../http';


export default ({ type, targetType, ...props }) => {
  const participant = useRecordContext(props);
  const scopes = useScopes();
  const participantId = participant?.id;

  const showAttributeValues = isAuthorized(scopes, 'attributeValues', 'list');
  const canAddAttributeValues = isAuthorized(scopes, 'attributeValues', 'create');
  const addButton = canAddAttributeValues && <AddAttributeValuesButton initialValues={{ targetType, targetId: participantId }} />;

  if (!showAttributeValues) return null;

  return <ListCard
    title="resources.attributeValues.labels.card.title"
    subtitle="resources.attributeValues.labels.card.subtitle"
    reference="attributeValues"
    target="targetId"
    filter={{ targetType, targetId: participantId, 'attributeType.type': type }}
    sort={{ field: 'attributeType.name', order: 'ASC' }}
    addButton={addButton}
  >
    <AttributeValuesGrid />
  </ListCard>
}
