import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import SanctionOffenseForm from './SanctionOffenseForm';

const AddSanctionOffenseDialog = props =>
  <CreateResourceDialog label="resources.sanctionOffenses.actions.add" resource="sanctionOffenses" basePath="/sanctionOffenses" {...props}>
    <SanctionOffenseForm />
  </CreateResourceDialog>

const EditSanctionOffenseDialog = props =>
  <EditResourceDialog label="resources.sanctionOffenses.labels.edit" resource="sanctionOffenses" basePath="/sanctionOffenses" {...props}>
    <SanctionOffenseForm />
  </EditResourceDialog>

export const AddSanctionOffenseButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.sanctionOffenses.actions.add')}
    </Button>
    <AddSanctionOffenseDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const SanctionOffenseDatagrid = props => <DialogDatagrid dialog={EditSanctionOffenseDialog} {...props} />
