import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Gavel } from '@material-ui/icons';

import { isGameCertified, isGameCompleted } from '@hisports/scoresheet/src/selectors';

import ListCard from '../../../common/cards/ListCard';

import { SuspensionGrid } from './SuspensionGrid';
import { AddSuspensionButton } from './SuspensionModal';
import { useMeta, useScoresheet } from '../ScoresheetContext';

export const SuspensionsCard = props => {
  const game = useRecordContext(props);
  const translate = useTranslate();
  const { meta } = useMeta();
  const gameId = game?.id;

  const isCertified = useScoresheet(scoresheet => isGameCertified(scoresheet))
  const isCompleted = useScoresheet(scoresheet => isGameCompleted(scoresheet))

  let initialStatus = 'Pending';

  if ((meta?.policies?.suspensionActivation === 'Approval' && isCompleted) || (meta?.policies?.suspensionActivation === 'Certification' && isCertified)) {
    initialStatus = 'Active';
  }

  const addButton = <AddSuspensionButton initialValues={{ gameId, status: initialStatus, durationType: 'Definite' }} />

  return <ListCard
    title={translate("resources.suspensions.name", 2)}
    icon={<Gavel />}
    basePath="/suspensions"
    reference="suspensions"
    target="gameId"
    sort={{ field: ['participant.lastName', 'participant.firstName'], order: ['ASC', 'ASC'] }}
    addButton={addButton}
  >
    <SuspensionGrid showDialog />
  </ListCard>
}
