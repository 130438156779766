import { useQueryWithStore, GET_ONE, useQuery } from 'react-admin';
import { useSelector } from 'react-redux';

export const useSchedule = scheduleId => useQuery({
  type: GET_ONE,
  resource: 'schedules',
  payload: {
    id: scheduleId,
  }
}, {
  enabled: scheduleId != null,
})

// force check in cache (use with caution, data may have changed since last load)
export const useScheduleWithStore = scheduleId => {
  const scheduleInStore = useSelector(state => state?.admin?.resources?.schedules?.data?.[scheduleId])

  const { data, loading, error } = useQueryWithStore({
    type: GET_ONE,
    resource: 'schedules',
    payload: { id: scheduleId },
  }, {
    enabled: !scheduleInStore && !!scheduleId
  })
  return { data: scheduleInStore || data, loading, error };
}

