import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ArrayInput, RecordContextProvider, SimpleForm, TextInput, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { Button, Checkbox, Chip, Dialog, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemIcon, ListItemText, makeStyles, useMediaQuery } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { Person as PersonIcon } from '@material-ui/icons';

import { FF_APPROVAL_SIGNATURE } from '@hisports/common/featureFlags';
import { OFFICIAL_STATUS_CONFIRMED, OFFICIAL_STATUS_DECLINED, OFFICIAL_STATUS_NO_SHOW } from '@hisports/scoresheet/src/constants';
import { getCertificationFlag, getCertificationFlags, getDefaultGameEndTime, getOfficials } from '@hisports/scoresheet/src/selectors';
import { addCertificationFlag, approveScoresheet, endGame, removeOfficial, saveCertificationFlags, updateOfficial } from '@hisports/scoresheet/src/actions';
import { generateGameFlags, isSupervisor, sortOfficials } from '@hisports/scoresheet/src/util';

import { useFlag, useHttpClient, useSport } from '../../../http';
import HorizontalFormIterator, { TransitionProps } from '../../../common/ra/HorizontalFormIterator';
import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { EnumInput } from '../../../common/inputs/EnumInputs';
import { SignatureInput, useSignature } from '../../../common/inputs/SignatureInput';

import { useMeta, useScoresheet, useScoresheetDispatch, useScoresheetStore } from '../ScoresheetContext';
import { Divider, FlagList } from '../certification/FlagList';
import { GameTimeInput } from '../components/GameTimeInput';

const useStyles = makeStyles(theme => ({
  divider: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  checkbox: {
    padding: 0
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  list: {
    paddingBottom: 0,
  },
  chip: {
    marginRight: theme.spacing(1)
  }
}))

const inputProps = {
  variant: 'outlined',
  size: 'small',
  margin: 'dense',
  fullWidth: true,
}

// simple email regex to require at least: a@a.a
const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/i;

const getDefaultEndTime = (scoresheet, sport) => {
  let endTime = {}
  const { minutes, seconds, ...rest } = getDefaultGameEndTime(scoresheet, sport)

  endTime = { ...rest }
  if (minutes != null || seconds != null) {
    // input requires minutes/seconds to be nested
    endTime.clock = {
      minutes,
      seconds,
    }
  }

  return { ...endTime }
}

const validate = (values, validateSignature, sport) => {
  const errors = {
    endTime: {}
  };
  const { clock, period, half } = values?.endTime || {}

  if (sport === 'Hockey') {
    const { minutes, seconds } = clock || {};
    if (!period) {
      errors.endTime.period = 'ra.validation.required'
    }
    if (period != 'SO') {
      if (minutes == undefined || seconds == undefined) {
        errors.endTime.clock = 'ra.validation.required'
      } else if (isNaN(minutes) || isNaN(seconds)) {
        errors.endTime.clock = 'ra.validation.invalid_number'
      }
    }

    if (validateSignature) {
      if (!values.officialId) errors.officialId = 'ra.validation.required';
      if (!values.signature) errors.signature = 'ra.validation.required';
    }
  }

  if (sport === 'Soccer') {
    const { minutes, extra } = clock || {};
    if (!minutes) {
      errors.endTime.clock = 'ra.validation.required'
    } else if (isNaN(minutes)) {
      errors.endTime.clock = 'ra.validation.invalid_number'
    } else if (minutes < 1) {
      errors.endTime.clock = 'ra.validation.greater_than_zero'
    } else if (extra && isNaN(extra)) {
      errors.endTime.clock = 'resources.scoresheets.validations.invalid_extra'
    }
  }

  if (sport === 'Baseball') {
    if (!period) {
      errors.endTime.period = 'ra.validation.required'
    } else if (isNaN(period)) {
      errors.endTime.period = 'ra.validation.invalid_number'
    } else if (period < 1) {
      errors.endTime.period = 'ra.validation.greater_than_zero'
    }
    if (!half) errors.endTime.half = 'ra.validation.required'
  }

  errors.emails = (values?.emails || []).map(email => {
    if (email && !emailRegex.test(email)) return 'ra.validation.invalid_email_format';
  })

  return errors;
}

const RASignatureInput = ({ source, ...props }) => {
  const translate = useTranslate()
  const ref = useRef(null);
  const { signature, isEmpty, clear, handleChange } = useSignature(ref);
  const { change } = useForm()

  useEffect(() => {
    if (isEmpty) {
      change(source)
    } else {
      change(source, signature)
    }
  }, [signature, isEmpty, change, source])

  return <>
    <SignatureInput ref={ref} onChange={handleChange} {...props} />
    <Button color="primary" size="small" onClick={clear}>{translate('ra.action.clear')}</Button>
  </>
}

const getPositionText = (official = {}, translate) => {
  let position = translate(`resources.games.values.assignment.position.${official.position}`, { _: official.position })
  if (!official.participantId) {
    position += ` (${translate('resources.games.labels.assignment.adhoc')})`
  }
  return position
}

const SignatoryInput = ({ officials = [], ...props }) => {
  const translate = useTranslate()
  const choices = useMemo(() => officials.map(official => ({
    id: official.id,
    name: official.participant.fullName,
    position: getPositionText(official, translate),
  })), [officials, translate])

  return <EnumInput choices={choices} filterKeys={['name']} optionDescription="position" {...props} />
}

const Official = ({ official = {}, ...props }) => {
  const translate = useTranslate()
  const classes = useStyles();
  const form = useForm();
  const [ checked, setChecked ] = useState(![OFFICIAL_STATUS_NO_SHOW, OFFICIAL_STATUS_DECLINED].includes(official.status));

  const primary = official.participant.fullName
  let secondary = getPositionText(official, translate);

  if (official.status === OFFICIAL_STATUS_DECLINED) {
    secondary += ` - ${translate(`resources.games.labels.assignment.status.${official.status}`, { _: official.status })}`
  }

  const handleSelect = () => {
    const { values = {} } = form.getState()
    const { noShowOfficialIds = [], assignedOfficialIds = [] } = values

    if (checked) {
      // unselecting official checkbox
      form.mutators.remove('assignedOfficialIds', assignedOfficialIds.indexOf(official.id))
      form.mutators.push('noShowOfficialIds', official.id)
    } else {
      // selecting official checkbox
      form.mutators.remove('noShowOfficialIds', noShowOfficialIds.indexOf(official.id))
      form.mutators.push('assignedOfficialIds', official.id)
    }

    setChecked(!checked)
  }

  return <ListItem className={classes.listItem}>
    <ListItemIcon><PersonIcon /></ListItemIcon>
    <ListItemText
      primaryTypographyProps={{ variant: "body2" }}
      primary={primary}
      secondary={secondary}
    />
    {!checked && <Chip label={translate('resources.games.labels.assignment.status.no_show')} variant="outlined" size="small" className={classes.chip} />}
    <Checkbox color="primary" className={classes.checkbox} checked={checked} onChange={handleSelect} />
  </ListItem>
}

const OfficialAttendanceList = ({ officials, label, helperText, ...props }) => {
  const classes = useStyles();

  return <List dense className={classes.list}>
    <Divider text="resources.scoresheets.labels.official_attendance" />
    {officials.map(official => <Official official={official} />)}
  </List>
}

const EmailsInput = ({ source, label, helperText }) => {
  return <ArrayInput source={source} label={label} helperText={helperText}>
    <HorizontalFormIterator disableReordering TransitionProps={TransitionProps}>
      <TextInput label="" placeholder="example@domain.com" helperText="" {...inputProps} />
    </HorizontalFormIterator>
  </ArrayInput>
}

const ApprovalForm = ({ game, assignedOfficials, noShowOfficials, ...props }) => {
  const { meta } = useMeta();
  const isEnabled = useFlag();

  const assignedOfficialsNoSupervisor = assignedOfficials.filter(official => !isSupervisor(official))
  const noShowAndAssignedOfficials = sortOfficials([...assignedOfficialsNoSupervisor, ...noShowOfficials]);

  const showOfficialAttendance = noShowAndAssignedOfficials.length > 0;
  const showFlags = meta?.policies?.requireCertification === true;
  const showSignature = assignedOfficials.length > 0 && isEnabled(FF_APPROVAL_SIGNATURE);

  return <SimpleForm {...props} {...inputProps}>
    <GameTimeInput source="endTime" helperText="resources.scoresheets.messages.end_game" periodLabel="resources.scoresheets.labels.period" {...inputProps} />
    {showOfficialAttendance && <OfficialAttendanceList officials={noShowAndAssignedOfficials} />}
    {showFlags && <FlagList showAll />}
    {showSignature && <SignatoryInput source="officialId" label="resources.scoresheets.labels.official" officials={assignedOfficials} {...inputProps} />}
    {showSignature && <RASignatureInput source="signature" />}
    <EmailsInput source="emails" label="resources.scoresheets.labels.additional_emails" helperText="resources.scoresheets.messages.additional_emails" />
  </SimpleForm>
}

export const ApprovalDialog = ({ open, setModalOpen, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const translate = useTranslate()
  const game = useRecordContext();
  const { data: meta } = useHttpClient(`/games/${game.id}/meta?includeMemberValidations=true`)
  const sport = useSport();
  const dispatch = useScoresheetDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const store = useScoresheetStore();
  const scoresheet = useScoresheet(scoresheet => scoresheet)
  const assignedOfficials = getOfficials(scoresheet, { assignedOnly: true })
  const noShowOfficials = getOfficials(scoresheet, { noShowOnly: true, declinedOnly: true })

  useEffect(() => {
    if (!meta) return;

    const scoresheet = store.getState();
    const automaticFlags = generateGameFlags(game, scoresheet, meta)

    // initialize automatic flags on the scoresheet
    if (!automaticFlags) return;

    automaticFlags.forEach(flag => {
      const scoresheetFlag = getCertificationFlag(scoresheet, flag.name, flag.teamId);

      if (!scoresheetFlag) {
        const event = addCertificationFlag(game.id, flag.name, flag.teamId);
        dispatch(event);
      }
    })
  }, [ dispatch, store, game, meta ])

  const onSubmit = async ({ endTime, officialId, signature, emails, assignedOfficialIds, noShowOfficialIds } = {}) => {
    const scoresheet = store.getState();
    const flags = getCertificationFlags(scoresheet)

    try {
      // end game
      await dispatch(endGame(game.id, { ...endTime, ...endTime.clock }))

      // update officials
      await Promise.allSettled([
        // update officials who did not show
        ...assignedOfficials.map(official => {
          if (noShowOfficialIds.includes(official.id)) {
            return removeOfficial(game.id, official, true)
          }
        }),
        // update officials who did show
        ...noShowOfficials.map(official => {
          if (assignedOfficialIds.includes(official.id)) {
            return updateOfficial(game.id, official, official.position, OFFICIAL_STATUS_CONFIRMED)
          }
        })
      ].filter(Boolean).map(event => {
        try {
          return dispatch(event)
        } catch (e) {
          // catch validation errors caused by the dispatches
          return Promise.reject(e)
        }
      }))

      // save certification flags
      if (flags?.length) {
        await dispatch(saveCertificationFlags(game.id, flags))
      }

      // approve scoresheet
      const official = assignedOfficials.find(official => official.id === officialId);
      const approval = { signature };

      if (official?.participantId) {
        approval.participantId = official.participantId;
      } else {
        approval.officialId = officialId
      }

      await dispatch(approveScoresheet(game.id, {
        unreviewed: false,
        officials: officialId ? [approval] : undefined,
        emails
      }))

      notify('resources.scoresheets.messages.successful_game_approve', 'success')
    } catch (e) {
      // catch validation errors caused by the dispatches
      // eslint-disable-next-line no-console
      console.error(e)
      notify('ra.message.error', 'error')
    }

    setModalOpen(false)
    setTimeout(() => refresh(), 500);
  }

  const onCancel = () => {
    setModalOpen(false);
  }

  const initialValues = {
    endTime: getDefaultEndTime(scoresheet, sport),
    assignedOfficialIds: assignedOfficials.map(official => official.id).filter(Boolean),
    noShowOfficialIds: noShowOfficials.map(official => official.id).filter(Boolean),
  }

  return <Dialog open={open} fullWidth fullScreen={fullScreen} maxWidth={fullScreen ? "xs" : "sm"}>
    <DialogTitle>{translate('resources.scoresheets.labels.approve_game')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.scoresheets.messages.confirm_sign_game')}
      </DialogContentText>
      <RecordContextProvider value={null}>
        <ApprovalForm
          validate={values => validate(values, assignedOfficials.length > 0, sport)}
          save={onSubmit}
          game={game}
          initialValues={initialValues}
          component={Fragment}
          assignedOfficials={assignedOfficials}
          noShowOfficials={noShowOfficials}
          toolbar={
            <DialogFormToolbar submitLabel="ra.action.approve" cancelLabel="ra.action.cancel" onCancel={onCancel} disableInvalidSubmit />
          }
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}
