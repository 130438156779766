import React, { useRef } from 'react';
import { Grid } from '@material-ui/core'
import { GET_ONE, NumberInput, ReferenceArrayInput, ReferenceInput, SimpleForm, TextInput, useQuery } from 'react-admin';

import Toolbar from '../../common/ra/Toolbar';
import { EnumInput } from '../../common/inputs/EnumInputs';
import { OfficeInput } from '../offices/OfficeInput';
import createCalculator from 'final-form-calculate';
import { useFormState } from 'react-final-form';
import { isEmpty } from '@hisports/parsers';
import { isInteger } from 'lodash';

const validate = values => {
  const errors = {};

  if (!values?.officeId) errors.officeId = 'ra.validation.required'
  if (!values?.attributeId) errors.attributeId = 'ra.validation.required'
  if (!values?.value && values?.value !== 0) errors.value = 'ra.validation.required'

  return errors;
}

const validateNumberValue = (options) => {
  if (isInteger(options?.min) && !isInteger(options?.max)) {
    return [minValueValidation(options?.min)];
  } else if (!isInteger(options?.min) && isInteger(options?.max)) {
    return [maxValueValidation(options?.max)];
  } else if (isInteger(options?.min) && isInteger(options?.max)) {
    return [minValueValidation(options?.min), maxValueValidation(options?.max)];
  }

  return [];
}

const minValueValidation = (min) => (value) => {
  return (value < min) ? { message: 'ra.validation.minValue', args: { min } } : undefined;
}

const maxValueValidation = (max) => (value) => {
  return value > max ? { message: 'ra.validation.maxValue', args: { max } } : undefined;
}

const inputProps = {
  resource: 'attributeValues',
  basePath: '/attributeValues',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const dropdownValueTypeChoice = values => {
  const options = [];

  Object.keys(values).map(function(key) {
    options.push({ id: values[key], name: values[key] });
  })

  return options;
}

export const ParticipantAttributeInput = ({ multiple, ...props }) => {
  const { values } = useFormState();
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  const officeId = values?.officeId ? values.officeId : 0;

  if (!officeId && officeId === 0) return null;

  return <Input
    source="attributeId"
    reference="attributeTypes"
    filter={{ targetType: 'Participant', type: 'Officiating', effectiveOffices: officeId, includeParents: true, _scope: 'Parents' }}
    sort={{
      field: 'name',
      order: 'ASC'
    }}
    {...props}
  >
    <EnumInput {...inputProps} />
  </Input>
}

export const AttributeValuesInput = ({ ...props }) => {
  const { values } = useFormState();
  const attributeId = values?.attributeId ? values.attributeId : 0;

  const { data: typeData, loading } = useQuery({
    type: GET_ONE,
    resource: 'attributeTypes',
    payload: {
      id: attributeId,
    }
  })

  if (loading) {
    return <TextInput disabled source="value" {...inputProps} />
  }

  switch (typeData?.valueType) {
    case 'Dropdown':
      return <EnumInput source="value" choices={dropdownValueTypeChoice(typeData?.options?.values)} {...inputProps} />
    case 'Number':
      return <NumberInput validate={validateNumberValue(typeData?.options)} min={typeData?.options?.min} max={typeData?.options?.max} source="value" {...inputProps} />
    case 'Text':
      return <TextInput source="value" {...inputProps} />
    default:
      return null;
  }
}

export const AttributeValuesForm = props => {
  const decorators = useRef([createCalculator({
    field: 'officeId',
    updates: {
      attributeId: (officeId, values, prevValues) => {
        if (isEmpty(prevValues) || prevValues.officeId == officeId) {
          return values.attributeId;
        }

        return null;
      }
    }
  }, {
    field: 'attributeId',
    updates: {
      value: (attributeId, values, prevValues) => {
        if (isEmpty(prevValues) || prevValues.attributeId == attributeId) {
          return values.value;
        }

        return null;
      }
    }
  })])

  return <SimpleForm toolbar={<Toolbar />} validate={validate} decorators={decorators.current} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <OfficeInput source="officeId" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ParticipantAttributeInput {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <AttributeValuesInput {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
