import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import CheckboxInput from './CheckboxInput';
import { FieldDependency } from '../FieldDependency';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(theme => ({
  checkbox: {
    paddingTop: theme.spacing(2.75),
  }
}))

const CheckboxRowInput = (props) => {
  const {
    showLabel = false,
    helperText = false,
    disabled = true,
    resource,
    source,
    hideHelper = false,
    children,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item xs={1}>
        <CheckboxInput source={`${source}IsChecked`} label={showLabel} hideHelper={hideHelper} className={classes.checkbox} />
      </Grid>
      <Grid item xs={11}>
        <FieldDependency fieldSource={`${source}IsChecked`} test={value => !!value} disabled={disabled}>
          {React.cloneElement(children, {
            label: translate(`resources.${resource}.fields.${source}`),
            helperText,
            ...props
          })}
        </FieldDependency>
      </Grid>
    </Grid>
  );
};

export default CheckboxRowInput;
