import React from 'react';
import { Show as RAShow } from 'react-admin';
import { Box } from '@material-ui/core';

import { ResourceActions } from './ResourceActions';

export function Show(props) {
  return <RAShow
    actions={<ResourceActions />}
    component={Box}
    {...props}
  />
}
