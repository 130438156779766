import React from 'react';
import { ReferenceInput, ReferenceArrayInput } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { VenueIcon } from '../icons';

import { getVenueName, getVenueCity } from './util';

export const filterVenue = name => ({ name })

const matches = (left, right) => `${left}`.trim().toLowerCase().includes(`${right}`.trim().toLowerCase());
export const matchVenue = (input, result) => {
  if (!input) return true;
  if (!result) return false;
  return matches(result.name, input) ||
    matches(result.address, input) ||
    matches(result.city, input) ||
    matches(result.region, input) ||
    matches(result.country, input) ||
    matches(result.alias, input)
}

export const VenueSuggestion = ({ suggestion: venue, query, isHighlighted, ...props }) =>
  <AutocompleteSuggestion icon={<VenueIcon />} primary={getVenueName(venue)} secondary={venue.address} />

export const VenueInput = ({ helperText, multiple, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="venues"
    filterToQuery={filterVenue}
    sort={{
      field: ['country', 'region', 'city', 'name'],
      order: ['ASC', 'ASC', 'ASC', 'ASC']
    }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText={getVenueName}
      groupBy={venue => getVenueCity(venue, true, true, false)}
      suggestionComponent={VenueSuggestion}
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
