import React from 'react';
import { SimpleForm, SaveButton, useTranslate } from 'react-admin';


import Toolbar from "../../../common/ra/Toolbar";
import { PitcherLimitsInput } from './PitcherLimitsInput';
import { isEmpty } from '@hisports/parsers';

const inputProps = {
  resource: 'rulebooks',
  basePath: '/rulebooks',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const validate = (values, translate) => {
  const errors = {};

  if (isEmpty(values?.scheduleTypes)) errors.scheduleTypes = 'ra.validation.required'

  if (!isEmpty(values?.throwsLimits)) {
    errors.throwsLimits = []
    values.throwsLimits.forEach((limit, index) => {
      if (limit && limit.min > limit.max) {
        errors.throwsLimits[index] = {}
        errors.throwsLimits[index].min = 'resources.rulebooks.validation.min'
        errors.throwsLimits[index].max = 'resources.rulebooks.validation.max'
      }
    });
  }

  if (!isEmpty(values?.inningsPitchedLimits)) {
    errors.inningsPitchedLimits = []
    values.inningsPitchedLimits.forEach((limit, index) => {
      if (limit && limit.startDate && limit.endDate && limit.startDate > limit.endDate) {
        errors.inningsPitchedLimits[index] = {}
        errors.inningsPitchedLimits[index].startDate = 'resources.rulebooks.validation.startDate'
        errors.inningsPitchedLimits[index].endDate = 'resources.rulebooks.validation.endDate'
      }
    })
  }

  if (!isEmpty(values?.inningsPitchedRests)) {
    errors.inningsPitchedRests = []
    values.inningsPitchedRests.forEach((limit, index) => {
      if (limit && limit.min > limit.max) {
        errors.inningsPitchedRests[index] = {}
        errors.inningsPitchedRests[index].min = 'resources.rulebooks.validation.min'
        errors.inningsPitchedRests[index].max = 'resources.rulebooks.validation.max'
      }
    });
  }

  return errors;
}

export const PitcherLimitsForm = props => {
  const translate = useTranslate();

  const toolbar = <Toolbar>
    <SaveButton />
  </Toolbar>

  return <SimpleForm toolbar={toolbar} validate={(values) => validate(values, translate)} {...props}>
    <PitcherLimitsInput />
  </SimpleForm>
}
