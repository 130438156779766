import React, { useRef } from 'react';
import { TextInput, SimpleForm, useTranslate } from 'react-admin';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import createCalculator from 'final-form-calculate';

import { changeTimezone, isEmpty } from '@hisports/parsers';
import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { InlineDateTimeInput, dateTimePlaceholder } from '../../common/inputs/DateInput';
import { GameStatusEnumInput, SeasonInput, TimezoneEnumInput } from '../../common/inputs/EnumInputs';
import { EndTimeInput } from '../../common/inputs/EndTimeInput';
import { FieldDependency } from '../../common/FieldDependency';
import { getRule } from '../../common/fields/RecurrenceSchedule';
import { RecurrenceField } from '../../common/fields/RecurrenceField';
import Toolbar from '../../common/ra/Toolbar';

import { TeamInput } from '../teams/TeamInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';
import { OfficeInput } from '../offices/OfficeInput';
import { hasConflicts, validateStatus } from '../games/GameForm';

import { PracticeAvailabilityContextProvider } from './PracticeAvailabilityContext';
import { PracticeAvailabilityInfo, useAvailabilities } from './PracticeAvailabilityInfo';


const validate = values => {
  const errors = {};

  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (!values.seasonId?.length) errors.seasonId = 'ra.validation.required'
  if (!values.arenaId) {
    errors.arenaId = 'ra.validation.required'
  }

  if (!values.startTime) errors.startTime = 'ra.validation.required'
  if (!values.endTime) errors.endTime = 'ra.validation.required'

  if (values.startTime && values.endTime) {
    if (!moment.tz(values.startTime, values.timezone).isValid()) {
      errors.startTime = 'resources.games.validations.invalid_time'
    } else if (!moment.tz(values.endTime, values.timezone).isValid()) {
      errors.endTime = 'resources.games.validations.invalid_time'
    } else if (moment.tz(values.endTime, values.timezone).isBefore(values.startTime, 'minute')) {
      errors.endTime = 'resources.games.validations.end_before_start'
    } else if (!moment.tz(values.endTime, values.timezone).isSame(values.startTime, 'day')) {
      errors.endTime = 'resources.games.validations.invalid_must_be_same_day'
    }
  }

  if (values.frequency != null && values.frequency !== 'Once') {
    if (!values.endDate) {
      errors.endDate = 'ra.validation.required'
    } else if (values.startTime && moment.utc(values.endDate).isBefore(values.startTime, 'day')) {
      errors.endDate = 'resources.games.validations.after_start_time';
    } else if (values.startTime && moment.utc(values.endDate).diff(values.startTime, 'day') > 365) {
      errors.endDate = 'resources.games.validations.invalid_date'
    }
  }

  if (!values.status) errors.status = 'ra.validation.required'

  return errors;
}

const PracticeStatusInput = props => {
  const { values } = useFormState();
  const availabilities = useAvailabilities(values);

  const helperText = hasConflicts(availabilities, values.status, ['Conflict']) ? 'resources.practices.helpers.conflicts' : '';

  return <GameStatusEnumInput validate={validateStatus(availabilities)} helperText={helperText} {...props} />
}

const inputProps = {
  resource: 'practices',
  basePath: '/practices',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const PracticeFormBody = () => {
  const { initialValues } = useFormState();
  const translate = useTranslate();

  const isEditing = initialValues.id != null;
  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12} md={9}>
      <TextInput source="name" helperText="ra.message.optional" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={3}>
      <SeasonInput source="seasonId" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={12}>
      <TeamInput source="teamIds" multiple helperText="ra.message.optional" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={12}>
      <OfficeInput source="officeId" filter={{ type: { nin: [...GAME_OFFICE_TYPES] } }} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <SurfaceInput source="arenaId" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <FieldDependency fieldSource="arenaId" disabled>
        <InlineDateTimeInput
          source="startTime"
          options={{
            label: translate('resources.practices.fields.startTime'),
            format: 'YYYY-MM-DD HH:mm',
            placeholder: dateTimePlaceholder,
            ampm: false,
          }}
          {...inputProps}
        />
      </FieldDependency>
    </Grid>
    <Grid item xs={12} md={4}>
      <FieldDependency fieldSource="arenaId" disabled>
        <EndTimeInput
          source="endTime"
          options={{
            label: translate('resources.practices.fields.endTime'),
            format: 'YYYY-MM-DD HH:mm',
            placeholder: dateTimePlaceholder,
            ampm: false,
          }}
          {...inputProps}
        />
      </FieldDependency>
    </Grid>
    <PracticeAvailabilityInfo />
    <Grid item xs={12} md={4}>
      <PracticeStatusInput source="status" defaultValue="Active" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <TimezoneEnumInput source="timezone" disabled {...inputProps} />
    </Grid>
    <Grid item xs={0} md={4} />

    {!isEditing && <RecurrenceField {...inputProps} />}

    <Grid item xs={12} lg={12}>
      <TextInput source="comments" multiline rows="3" helperText="ra.message.optional" {...inputProps} />
    </Grid>
  </Grid>
}

export const PracticeForm = ({ initialValues: propInitialValues, save, ...props }) => {
  const initialValues = { status: 'Active', type: 'Practice', frequency: 'Once', ...propInitialValues }

  const surfaces = useSelector(store => store.admin.resources.surfaces.data);
  const surfacesRef = useRef({});
  surfacesRef.current = surfaces;

  const teams = useSelector(store => store.admin.resources.teams.data);
  const teamsRef = useRef({});
  teamsRef.current = teams;

  const decorators = useRef([createCalculator({
    field: 'arenaId',
    updates: {
      timezone: (arenaId, values, prevValues) => {
        const surface = surfacesRef.current[arenaId];
        if (!surface || isEmpty(prevValues) || (values.timezone && !prevValues.timezone)) return values.timezone;
        return surface.timezone;
      }
    }
  }, {
    field: 'timezone',
    updates: {
      startTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.startTime || isEmpty(prevValues) || timezone === prevTimezone) return values.startTime;
        return changeTimezone(values.startTime, prevTimezone, timezone)
      },
      endTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.endTime || isEmpty(prevValues) || timezone === prevTimezone) return values.endTime;
        return changeTimezone(values.endTime, prevTimezone, timezone)
      },
    }
  }, {
    field: 'startTime',
    updates: {
      date: (startTime, values) => {
        if (!startTime || !values.timezone) return startTime;
        return moment.tz(startTime, values.timezone).format('YYYY-MM-DD');
      },
    }
  })])

  const onSave = async ({ frequency, interval, weekdays, endDate, recurrence, ...practice }) => {
    const rule = getRule({ frequency, interval, weekdays, endDate, timezone: practice.timezone });
    practice.recurrence = rule?.toString().replace('RRULE:', '') || null;
    return save(practice, props.redirect);
  }

  return <PracticeAvailabilityContextProvider>
    <SimpleForm
      initialValues={initialValues}
      validate={validate}
      save={onSave}
      decorators={decorators.current}
      toolbar={<Toolbar />}
      {...props}
    >
      <PracticeFormBody />
    </SimpleForm>
  </PracticeAvailabilityContextProvider>
}
