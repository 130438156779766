import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

import { apiClient } from '../../../http';

export const today = moment().format('YYYY-MM-DD');

const toToday = (time, timezone) => {
  const from = moment.tz(time, timezone);
  return moment.tz(today, timezone).set({ hours: from.hours(), minutes: from.minutes(), seconds: from.seconds() }).toISOString();
}

export const availabilitiesToSlot = (availabilities, date) =>
  availabilities.filter(slot => slot.date === date)
    .map(slot => ({
      id: uuid(),
      isAvailable: slot.isAvailable,
      startTime: toToday(slot.startTime, slot.timezone),
      endTime: toToday(slot.endTime, slot.timezone),
      timezone: slot.timezone,
      notes: slot.notes,
      updatedat: slot.updatedat,
    }))

const mergeNotes = (prev, next) => Array.from(new Set([prev, next])).filter(Boolean).join('\n') || undefined;

export const updateSlots = (existing = [], slot) => {
  const id = slot.id || uuid();

  if (slot.allDay) return [{
    id,
    isAvailable: slot.isAvailable,
    startTime: slot.startTime,
    endTime: slot.endTime,
    timezone: slot.timezone,
    notes: slot.notes || null,
  }]

  if (slot.remove) return existing.filter(s => s.id !== slot.id);

  let sorted = [...existing];
  slot.changed = true;

  if (!slot.id) {
    slot.id = id;
    sorted.push(slot);
  } else {
    sorted = sorted.reduce((slots, next) => {
      // update existing
      slots.push(next.id === id ? slot : next);
      return slots;
    }, [])
  }

  if (!sorted.length) return [];
  sorted = sorted.sort((a, b) => {
    const diff = moment.tz(a.startTime, a.timezone).valueOf() - moment.tz(b.startTime, b.timezone).valueOf()
    if (diff !== 0) return diff;
    if (a.changed && !b.changed) return -1;
    if (!a.changed && b.changed) return 1;
    return 0;
  })

  let result = [sorted[0]];

  for (let i = 1; i < sorted.length; i++) {
    const prev = result[result.length - 1];
    const next = sorted[i];
    if (!next) continue;
    if (!next.changed && !prev.changed) {
      // skip, nothing changed so no need to compare
      result.push(next)
      continue;
    }

    if (moment.tz(next.startTime, next.timezone).isAfter(prev.endTime)) {
      // no overlap, append
      result.push(next);
    } else if (prev.isAvailable === next.isAvailable) {
      if (moment.tz(next.endTime, next.timezone).isAfter(prev.endTime)) {
        // extend
        result[result.length - 1].endTime = next.endTime
        result[result.length - 1].notes = mergeNotes(prev.notes, next.notes);
        result[result.length - 1].changed = true
      }
    } else if (moment.tz(next.startTime, next.timezone).isSame(prev.endTime)) {
      // no overlap, add
      result.push(next)
    } else if (moment.tz(next.endTime, next.timezone).isAfter(prev.endTime)) {
      // reduce
      if (prev.changed) {
        next.startTime = prev.endTime;
        next.timezone = prev.timezone;
        next.changed = true;
      } else {
        result[result.length - 1].endTime = next.startTime;
        result[result.length - 1].changed = true;
      }
      result.push(next);
    } else if (prev.changed) {
      // noop
    } else {
      // split
      const split = { ...prev, id: uuid(), changed: true, startTime: next.endTime };
      result[result.length - 1].endTime = next.startTime;
      result[result.length - 1].timezone = next.timezone;
      result[result.length - 1].changed = true;
      result.push(next, split);
    }

    // filter cancelled out slots
    result = result.filter(slot => moment.tz(slot.endTime, slot.timezone).diff(slot.startTime, 'minutes') > 5)
  }

  // reset changes
  result = result.map(slot => {
    delete slot.changed;
    return slot;
  })

  return result;
}

export const formatTime = (time, timezone) =>
  timezone ?
    moment.tz(time, timezone).format('h:mm A') :
    moment(time).format('h:mm A');

export const saveAvailability = (resource, id, dates, slots, timezone, officeId) => {
  const times = slots.map(slot => ({
    isAvailable: slot.isAvailable,
    startTime: slot.startTime,
    endTime: slot.endTime,
    notes: slot.notes || undefined,
    timezone: slot.timezone,
  }))

  return apiClient(`/${resource}/${id}/availability`, {
    method: 'PUT',
    data: {
      dates,
      times,
      timezone,
      officeId,
    }
  })
}
