import React, { useMemo } from 'react';
import { ImageField, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';
import { ShowHeader } from '../../common/ShowHeader';
import { SyncedIndicator } from '../../common/SyncAlert';

import { OfficeGrid } from './OfficeGrid';
import { OfficeFilters } from './OfficeFilters';
import { OfficeShowLayout } from './OfficeShow';
import { OfficeForm } from './OfficeForm';
import { OfficeField } from './OfficeField';
import { OfficeActions } from './OfficeActions';

export const OfficeShowHeader = () => {
  const translate = useTranslate()
  return <ShowHeader
    name={office => office.name}
    description={office => <>{translate(`resources.offices.values.type.${office.type}`, { _: office.type })} • <OfficeField source="parentId" variant="inherit" /><SyncedIndicator record={office} /></>}
    avatar={office => <ImageField source="logo.url" />} />;
}

export const OfficeList = props =>
  <List {...props}
    filter={{ type: { nin: ['League', 'Tournament', 'Cup'] } }}
    filters={<OfficeFilters />}
    sort={{
      field: 'name',
      order: 'ASC'
    }}
  >
    <OfficeGrid rowClick="show" />
  </List>

export const OfficeShow = props => {
  const location = useLocation();
  const officeTab = useMemo(() => {
    return location?.pathname?.substring(location.pathname.lastIndexOf('/') + 1)
  }, [ location.pathname ])

  return <Show {...props}
    title={<OfficeShowHeader />}
    style={{ maxWidth: officeTab === 'scheduler' ? 'none' : undefined }}
    actions={<OfficeActions />}
  >
    <OfficeShowLayout />
  </Show>
}

export const OfficeCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={record => translate('resources.offices.labels.add')} />}
    {...props}
  >
    <OfficeForm redirect="show" />
  </Create>
}

export const OfficeEdit = props => {
  const translate = useTranslate()
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: record.name })} />}
    {...props}
  >
    <OfficeForm redirect="show" />
  </Edit>
}
