import React from 'react';
import { ReferenceField, TextField, ReferenceInput, useTranslate, useLocale } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion';
import { formatCurrency } from '../../locale';

export const AssignFeesField = props =>
  <ReferenceField basePath="/assignFees" resource="assignFees" reference="assignFees" link={false} {...props}>
    <TextField source="name" />
  </ReferenceField>

const AssignFeesSuggestion = ({ suggestion: fee }) => {
  const locale = useLocale();
  const fees = [ fee.Referee, fee.Linesperson, fee.Supervisor ]
    .map(value => formatCurrency(value, locale))
    .join(' / ')
  return <AutocompleteSuggestion primary={fee.name} secondary={fees} />
}

export const AssignFeesInput = props => {
  const translate = useTranslate()
  return <ReferenceInput
    reference="assignFees"
    perPage={999}
    filterToQuery={() => null}
    sort={{ field: 'name', order: 'ASC' }}
    {...props}
  >
    <AutocompleteInput
      optionText="name" {...props}
      suggestionComponent={AssignFeesSuggestion}
      alwaysGroup
      groupBy={({ isArchived }) => translate(`resources.assignFees.values.isArchived.${isArchived ? 'archived' : 'notArchived'}`)}
    />
  </ReferenceInput>
}
