import React from 'react';
import { SimpleList, useListContext } from 'react-admin';

export const AccountGrid = props => {
  const { data, ids } = useListContext(props);
  if (!ids || !data) return null;

  return <SimpleList
    primaryText={account => account.email}
    linkType="show"
    {...props} />
}
