import React, { Fragment, useState } from 'react';
import { TextInput, SimpleForm, useRefresh, useNotify, useTranslate, useRecordContext, RecordContextProvider } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { UpdateOutlined } from '@material-ui/icons';

import { apiClient } from '../../http';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { GameStatusEnumInput } from '../../common/inputs/EnumInputs';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const GameStatusForm = props =>
  <SimpleForm {...props} {...inputProps} >
    <GameStatusEnumInput source="status" defaultValue="Active" {...inputProps} />
    <TextInput source="comments" multiline minRows="3" helperText="resources.games.helpers.comments" {...inputProps} />
  </SimpleForm>

export default ({ size, variant, ...props }) => {
  const game = useRecordContext(props)
  const [ isOpen, setOpen ] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onSubmit = (values) => {
    const { status, comments } = values;

    return apiClient(`/games/${game.id}/changeStatus`, {
      method: 'POST',
      data: {
        status,
        comments
      }
    })
      .then(() => {
        setOpen(false)
        refresh();
        notify('resources.games.notifications.game_status_changed')
      }).catch(err =>
        notify('ra.page.error_try_again', 'error')
      )
  }

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<UpdateOutlined />} onClick={() => setOpen(true)}>
      {translate('resources.games.labels.change_game_status')}
    </Button>
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>{translate('resources.games.labels.change_game_status')}</DialogTitle>
      <DialogContent>
        <RecordContextProvider value={null}>
          <GameStatusForm save={onSubmit} initialValues={{ status: game.status, comments: game.comments }} component={Fragment} toolbar={
            <DialogFormToolbar submitLabel="resources.games.labels.change_game_status" onCancel={() => setOpen(false)} />
          } />
        </RecordContextProvider>
      </DialogContent>
    </Dialog>
  </>
}
