import Polyglot from 'node-polyglot';

import englishMessages from './messages/en';
import frenchMessages from './messages/fr';

import englishSportsMessages from './messages/sports/en'
import frenchSportsMessages from './messages/sports/fr'

import { useLocale } from './LocaleProvider';
import { useTenant } from '../http';

export { LocaleProvider, useLocale } from './LocaleProvider';

export const locales = {
  en: englishMessages,
  fr: frenchMessages,
}

const sports = {
  en: englishSportsMessages,
  fr: frenchSportsMessages,
}

const getPolyglot = (locale, sport) => {
  const polygot = new Polyglot({ locale, phrases: { '': '', ...locales[locale] } })

  // overwrite base dictionary with any sport specific translations
  const sportPhrases = sports?.[locale]?.[sport];
  if (sportPhrases) polygot.extend(sportPhrases);

  return polygot;
}

// provide admin-react messages based on the locale set, defaults to the users browser language setting
export const useI18nProvider = () => {
  const [ locale ] = useLocale()
  const { sport } = useTenant() || {};

  let polyglot = getPolyglot(locale, sport);
  let translate = polyglot.t.bind(polyglot);

  return {
    getLocale: () => locale,
    async changeLocale(locale, sport) {
      polyglot = getPolyglot(locale, sport);
      translate = polyglot.t.bind(polyglot)
    },
    translate: (key, options = {}) => translate(key, options),
  }
}

export const formatCurrency = (amount, locale, allowZero = false) => {
  amount = Number(amount || 0);
  if (!allowZero && (isNaN(amount) || !amount)) {
    if (locale === 'fr') return '– $';
    return '$ –';
  }
  return new Intl.NumberFormat(`${locale || 'en'}-CA`, { style: 'currency', currency: 'CAD' }).format(amount);
}

export const formatDistance = (amount, locale) => {
  amount = amount || 0;
  return `${amount} km`;
}

export const formatList = (values, locale) =>
  new Intl.ListFormat(locale, {
    localeMatcher: 'best fit',
    type: 'conjunction',
    style: 'long',
  }).format(values)
