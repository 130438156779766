import React, { Suspense, useEffect, useState } from 'react';
import { AdminContext, AdminUI, useTranslate, ReferenceInput, ReferenceArrayInput, List, ListView, Datagrid, DatagridHeader, ListToolbar } from 'react-admin';
import { DatagridHeaderCell } from 'ra-ui-materialui/esm/list/datagrid/DatagridHeaderCell'
import { createBrowserHistory as createHistory } from 'history';
import { Button, LinearProgress, styled } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';

import { restClient, authClient, AuthProvider, useReady } from './http'
import { useCreateTheme } from './theme';
import { LocaleProvider, useI18nProvider } from './locale';
import Layout from './layout/Layout';
import BaseLayout from './routes/BaseLayout';
import { SeasonProvider } from './common/inputs/SeasonSelector';
import { OfficeProvider } from './common/inputs/OfficeSelector';
import useOffline from './common/useOffline';
import { storageReady } from './http/deviceService';

import { authResources, getInitialState } from './resources';
import { EventViewSettingsProvider } from './resources/events/EventViewSettings';

import { customRoutes } from './routes'
import LoginPage from './routes/Login';

import { appName } from './flags';

// alter propTypes to avoid console warnings
// react-admin only handles a single sort by default, but rest client was made to support multiple
delete ReferenceInput.propTypes.sort;
delete ReferenceArrayInput.propTypes.sort;
delete List.propTypes.sort;
delete List.propTypes.filters;
delete ListToolbar.type.propTypes.filters;
delete ListView.propTypes.currentSort;
delete ListView.propTypes.filters;
delete Datagrid.propTypes.currentSort;
delete DatagridHeader.propTypes.currentSort;
delete DatagridHeaderCell.propTypes.currentSort;

const history = createHistory();

const initialState = getInitialState(history)
const Dashboard = () => <Redirect to="/profile" />

const Progress = styled(LinearProgress)(({ theme }) => ({
  margin: theme.spacing(2, 1),
}))

export const useStorageAvailable = () => {
  const [ isAvailable, setAvailable ] = useState(false);
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    storageReady
      .then(() => setAvailable(true))
      .catch(() => setAvailable(false))
      .finally(() => setLoading(false))
  }, [])

  return [ isAvailable, loading ];
}

const useTimeout = (length) => {
  const [ long, setLong ] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setLong(true), length)
    return () => clearTimeout(timeout)
  }, [ length ])
  return long;
}

const Loading = () => {
  const long = useTimeout(500);
  const longer = useTimeout(1000);

  if (!long) return null;
  return <BaseLayout hideNotifications>
    <Progress color="primary" variant={longer ? "indeterminate" : "buffer"} value={50} valueBuffer={75} />
  </BaseLayout>;
}

const App = () => {
  const translate = useTranslate();
  const theme = useCreateTheme()
  const [ storageAvailable, storageLoading ] = useStorageAvailable()
  const isOffline = useOffline()
  const ready = useReady();

  if (!storageAvailable) {
    if (storageLoading) return <Loading />
    return <BaseLayout hideNotifications>
      <h3>{translate('components.login.messages.storage.unavailable')}</h3>
      <p>{translate('components.login.messages.storage.cookies_disabled')}</p>
      <p>{translate('components.login.messages.storage.use_browser_storage')}</p>
      <p>{translate('components.login.messages.storage.enable_cookies')}</p>
      <Button variant="text" component="a" href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/auto" target="_blank" rel="noopener noreferrer">{translate('ra.action.learn')}</Button>
    </BaseLayout>
  }

  if (isOffline) {
    return <BaseLayout hideNotifications>
      <h3>{translate('components.login.messages.offline.you')}</h3>
      <p>{translate('components.login.messages.offline.reconnect')}</p>
    </BaseLayout>
  }

  if (!ready) return <Loading />

  return <AdminUI
    title={appName}
    theme={theme}
    layout={Layout}
    loginPage={LoginPage}
    customRoutes={customRoutes}
    dashboard={Dashboard}
    disableTelemetry
  >
    {authResources}
  </AdminUI>
}

const AppContext = ({ children }) => {
  const i18nProvider = useI18nProvider()
  return <AdminContext
    authProvider={authClient}
    dataProvider={restClient}
    i18nProvider={i18nProvider}
    history={history}
    initialState={initialState}
  >
    {children}
  </AdminContext>
}

export default () =>
  <LocaleProvider>
    <AuthProvider>
      <SeasonProvider>
        <OfficeProvider>
          <EventViewSettingsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Suspense fallback={() => null}>
                <AppContext>
                  <Helmet defaultTitle={appName} titleTemplate={`%s - ${appName}`} />
                  <App />
                </AppContext>
              </Suspense>
            </MuiPickersUtilsProvider>
          </EventViewSettingsProvider>
        </OfficeProvider>
      </SeasonProvider>
    </AuthProvider>
  </LocaleProvider>
