import React from 'react';
import { Datagrid, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';

import FunctionField from '../../common/fields/FunctionField'

const renderDate = announcement => {
  const date = moment(announcement.updatedAt);
  return date.calendar({
    sameElse: 'LL',
  })
}

const useStyles = makeStyles(theme => ({
  thead: {
    display: 'none'
  },
  row: {
    '& .column-title': {
      width: theme.spacing(100),
    },
    '& .column-updatedAt': {
      textAlign: 'right',
    },
    '& .column-updatedAt span': {
      color: '#333',
      fontStyle: 'italic',
    }
  }
}))

export const AnnouncementGrid = props => {
  const classes = useStyles();
  return <Datagrid size="medium" classes={classes} {...props}>
    <TextField source="title" />
    <FunctionField source="updatedAt" render={renderDate} />
  </Datagrid>
}
