import React from 'react';
import { ReferenceInput, useTranslate, useLocale } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { translateApiProperty } from '@hisports/common';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'

const useDescription = (source, defaultText) => {
  const roles = useSelector(state => state.admin.resources.roles.data);
  const { values } = useFormState();

  const roleId = values[source];
  if (!roleId || !roles) return defaultText;

  const role = roles[roleId]
  return role ? role.description : defaultText;
}

const RoleSuggestion = ({ suggestion: role, filter }) => {
  const locale = useLocale();
  return <AutocompleteSuggestion
    primary={translateApiProperty(role, 'name', locale)}
    secondary={translateApiProperty(role, 'description', locale)}
  />
}

export const RoleInput = ({ helperText: defaultText, ...props }) => {
  const locale = useLocale();
  const translate = useTranslate();
  const helperText = useDescription(props.source, defaultText);
  return <ReferenceInput
    reference="roles"
    sort={{ field: 'name', order: 'ASC' }}
    filterToQuery={() => null}
    {...props}
  >
    <AutocompleteInput
      optionText={role => translateApiProperty(role, 'name', locale)}
      suggestionComponent={RoleSuggestion}
      helperText={helperText}
      groupBy={({ context }) => translate(`resources.permissions.values.context.${context}`)} />
  </ReferenceInput>
}
