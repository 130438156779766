import React, { useState } from 'react';
import { useRedirect, useTranslate } from 'react-admin'

import { FF_SEQUENCES } from '@hisports/common/featureFlags';

import { useFlag } from '../../../http';
import { ImportStepper } from '../../../common/importers/ImportStepper';
import { isAuthorized } from '../../../common/Authorize';

import { StepperToolbar } from './StepperToolbar'
import { useScheduleContext } from './WizardContext';

import InfoStep from './InfoStep';
import TeamsStep from './TeamsStep';
import SequencesStep from './SequencesStep';
import SettingsStep from './SettingsStep';

const SCHEDULE_INFO = 0;
const TEAM_SELECT = 1;
const ADD_SEQUENCES = 2;
const SCHEDULE_SETTINGS = 3;

export const ScheduleWizard = props => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const isEnabled = useFlag();

  const [ activeStep, setActiveStep ] = useState(0);
  const [ schedule ] = useScheduleContext();

  const showSequences = isAuthorized(schedule, 'schedulesequences', 'show') && isEnabled(FF_SEQUENCES);

  const steps = [
    'schedule_info',
    'team_select',
    showSequences && 'add_sequences',
    'schedule_settings'
  ].filter(Boolean)
    .map(step => translate(`resources.schedules.labels.steps.${step}`))

  const handleSkip = () => {
    if (![TEAM_SELECT, ADD_SEQUENCES].includes(activeStep)) return
    redirect(`/schedules/${schedule.id}/show`)
  };

  const handleNext = () => {
    setActiveStep(activeStep => activeStep + (!showSequences && activeStep === TEAM_SELECT ? 2 : 1));
  };

  const handleBack = () => {
    if (activeStep === SCHEDULE_INFO) {
      return redirect('/schedules')
    }
    setActiveStep(activeStep => activeStep - (!showSequences && activeStep === SCHEDULE_SETTINGS ? 2 : 1));
  };

  const cancelLabel = activeStep === SCHEDULE_INFO ? "ra.action.cancel" : "ra.action.back";
  const submitLabel = activeStep === steps.length - 1 ? "ra.action.finish" : "ra.action.next";
  const showSkip = [TEAM_SELECT, ADD_SEQUENCES].includes(activeStep)

  const toolbar = <StepperToolbar
    onCancel={handleBack}
    cancelLabel={cancelLabel}
    submitLabel={submitLabel}
    handleSkip={handleSkip}
    showSkip={showSkip}
  />

  let step = null;
  switch (activeStep) {
    case SCHEDULE_INFO:
      step = <InfoStep toolbar={toolbar} handleNext={handleNext} />
      break;
    case TEAM_SELECT:
      step = <TeamsStep toolbar={toolbar} handleNext={handleNext} />
      break;
    case ADD_SEQUENCES:
      step = <SequencesStep toolbar={toolbar} handleNext={handleNext} />
      break;
    case SCHEDULE_SETTINGS:
      step = <SettingsStep toolbar={toolbar} handleNext={handleNext} />
      break;
  }

  return <>
    <ImportStepper steps={steps} activeStep={activeStep} />
    {step}
  </>
}
