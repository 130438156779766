import React, { useContext, useMemo, useState } from 'react';

const DragContext = React.createContext(null);

export const useDragContext = () => useContext(DragContext) || {};

export const DragProvider = ({ ...props }) => {
  const [ dragging, setDragging ] = useState(false);
  const [ draggingEvent, setDraggingEvent ] = useState({});
  const [ droppingEvent, setDroppingEvent ] = useState({});

  const value = useMemo(() => ({
    dragging,
    setDragging,
    draggingEvent,
    setDraggingEvent,
    droppingEvent,
    setDroppingEvent,
  }), [
    dragging,
    setDragging,
    draggingEvent,
    setDraggingEvent,
    droppingEvent,
  ]);

  return <DragContext.Provider value={value} {...props} />
}
