import { schemas } from '@hisports/scoresheet'
import { isLegacyRulebookSeason } from '@hisports/scoresheet/src/util';

export default () => store => next => action => {
  const { eventType, event } = action?.payload || {};
  const { sport, seasonId } = action?.meta || {};
  if (!eventType || !sport) return next(action);

  let schema = schemas[sport][eventType];
  if (!schema) return next(action);

  if (typeof schema === "function") {
    if (!seasonId) return next(action);
    const isLegacy = isLegacyRulebookSeason(seasonId);
    schema = schema(isLegacy);
  }

  action.payload.event = schema.validateSync(event, { stripUnknown: true });
  return next(action);
}
