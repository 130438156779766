import React, { useEffect } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { DialogTitle, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Close, Launch } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';

import { ScoresheetProvider } from '../scoresheet/ScoresheetContext';
import { CertificationModal } from '../scoresheet/certification/CertificationModal';

const useStyles = makeStyles(theme => ({
  buttons: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  }
}))

const DialogHeader = ({ game, onClose }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return <>
    <DialogTitle>
      {translate('resources.games.labels.game_number_certify', { number: game.number })}
    </DialogTitle>
    <div className={classes.buttons}>
      <Tooltip title={translate('ra.action.open_full_page')}>
        <IconButton component={Link} to={`/games/${game.id}/show/scoresheet`} onClick={onClose}>
          <Launch fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={translate('ra.action.close')}>
        <IconButton onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  </>
}

export default ({ open, onClose }) => {
  const game = useRecordContext()
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      if (!open) return;
      onClose();
    })
  }, [history, onClose, open])

  if (!game) return null;

  const onClick = e => e.stopPropagation();

  return <ScoresheetProvider gameId={game.id}>
    <CertificationModal
      open={open}
      onClick={onClick}
      onClose={onClose}
      customHeader={<DialogHeader game={game} onClose={onClose} />}
      hideCancel
    />
  </ScoresheetProvider>
}
