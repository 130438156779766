import React, { useState, useMemo, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import {
  List,
  ListSubheader,
  Checkbox,
  Button,
  Tooltip,
  TextField,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { Search } from '@material-ui/icons'
import { debounce } from 'lodash';

import { getLineup, getLineupCounts, getMembers } from '@hisports/scoresheet/src/selectors';
import { addMembersToLineup, clearLineup } from '@hisports/scoresheet/src/actions';
import { isSkater, isGoalie, isStaff, getInitialSuspension } from '@hisports/scoresheet/src/util';

import { useSport } from '../../../http';
import { useMeta, useScoresheet, useScoresheetDispatch } from '../ScoresheetContext';
import { SquareAlert as Alert } from '../../../common/SquareAlert';

import { MemberItem } from './MemberItem';
import { useMemberValidationContext } from './MemberValidationContext';
import { isPlayerUnvalidatedMember, isStaffUnvalidatedMember } from './MemberItemContext';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: theme.spacing(100),
    overflowY: 'auto',
  },
  filter: {
    margin: theme.spacing(1),
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  }
}))

export const LineupCheckbox = ({ type, teamId, selected, members, limits, disabled }) => {
  const translate = useTranslate();
  const { game } = useMeta();
  const dispatch = useScoresheetDispatch();
  const { memberValidations } = useMemberValidationContext();

  const minValid = limits.min == null || selected >= limits.min;
  const maxValid = limits.max == null || selected <= limits.max;
  const isValid = minValid && maxValid;

  const selectableMembers = members.filter(member => {
    const memberValidation = memberValidations.find(({ participantId }) => participantId === member.participantId);
    if (!memberValidation) return false
    if (['Skater', 'Goalie'].includes(type) && isPlayerUnvalidatedMember(member, memberValidation)) return false;
    if (['Staff'].includes(type) && isStaffUnvalidatedMember(member, memberValidation)) return false;
    return true;
  })

  const selectAll = () => {
    const membersToAdd = selectableMembers.map(member => {
      const memberValidation = memberValidations.find(({ participantId }) => participantId === member.participantId);

      return {
        ...member,
        suspension: getInitialSuspension(memberValidation, teamId),
      }
    });

    dispatch(addMembersToLineup(game.id, teamId, membersToAdd))
  }
  const unselectAll = () => {
    dispatch(clearLineup(game.id, teamId, type))
  }
  const handleClick = () => {
    if (disabled) return;
    if (selected < selectableMembers.length) {
      selectAll()
    } else {
      unselectAll()
    }
  }
  const tooltip = !selected ? translate('resources.scoresheets.messages.select_all') :
    !minValid ? translate('resources.scoresheets.messages.minimum_not_met') :
      !maxValid ? translate('resources.scoresheets.messages.over_limit') :
        selected < selectableMembers.length ? translate('resources.scoresheets.messages.select_all') : translate('resources.scoresheets.messages.unselect_all')
  return <Tooltip title={tooltip}>
    <Checkbox
      edge="start"
      size="small"
      color={isValid ? 'primary' : 'default'}
      checked={selected > 0}
      indeterminate={selected > 0 && selected < selectableMembers.length}
      onClick={handleClick}
      disabled={disabled}
    />
  </Tooltip>
}

const LineupGroup = ({ type, label, teamId, selected, members, limits, disabled, disableCheckbox, classes, isScorekeeper }) => {
  const translate = useTranslate();
  const [ limit, setLimit ] = useState(100);
  const { loading } = useMemberValidationContext();
  if (!members.length) return null;

  const limited = members.length > limit;
  const count = limits.max > 0 ? `${selected}/${limits.max}` : selected
  return <>
    <ListSubheader>
      {!disabled && <LineupCheckbox
        type={type}
        teamId={teamId}
        members={members}
        selected={selected}
        limits={limits}
        disabled={disableCheckbox || limited || loading}
      />}
      {label} ({count})
    </ListSubheader>

    {members.slice(0, limit).map(member => <MemberItem
      member={member}
      teamId={teamId}
      key={member.participantId}
      disabled={disabled}
      isScorekeeper={isScorekeeper}
      type={type}
    />)}

    {limited && <div className={classes.button}>
      <Button color="secondary" onClick={() => setLimit(limit => limit + 25)}>{translate('resources.scoresheets.messages.show_more')}</Button>
    </div>}
  </>
}

const useFilter = (initialValue, wait) => {
  const [ filter, setFilter ] = useState(initialValue);
  const setFilterDebounced = useCallback(debounce(setFilter, wait)) // eslint-disable-line react-hooks/exhaustive-deps
  return [ filter, setFilterDebounced ]
}

export const LineupList = ({ teamId, disabled, isScorekeeper }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const sport = useSport();
  const { meta } = useMeta();

  const { includeRegistrations, lineupLimits } = meta.policies;
  const team = meta.teams[teamId];

  const lineup = useScoresheet(scoresheet => getLineup(scoresheet, { teamId }))
  const counts = useScoresheet(scoresheet => getLineupCounts(scoresheet, { teamId, showSuspended: true, sport }))

  const [ filter, setFilter ] = useFilter('', 150)
  const [ showRegistrations, setShowRegistrations ] = useState(!team?.members?.length);

  const registrations = useMemo(() => {
    if (!showRegistrations) return [];
    return (meta.registrations || [])
      .filter(registration => team.officeId === registration.officeId)
      .map(registration => ({
        participantId: registration.participantId,
        participant: registration.participant,
        positions: ['F'],
        number: 0,
      }))
  }, [ showRegistrations, meta.registrations, team.officeId ])

  const members = useMemo(() =>
    disabled ? lineup?.members : getMembers(lineup, team, sport, registrations)
  , [ disabled, lineup, team, registrations, sport ])

  if (!members?.length) return <Alert severity="error">{translate('resources.scoresheets.alerts.team_has_no_members')}{includeRegistrations ? ` ${translate('resources.scoresheets.alerts.or_registrations')}` : ''}.</Alert>

  const showFilter = !disabled && members.length > 30;
  const filtered = showFilter && filter !== '';
  const results = !filtered ? members : members.filter(member => member.participant.fullName.toUpperCase().includes(filter.toUpperCase()))
  const showNoMatch = filtered && !results.length;

  const handleFilter = e => {
    setFilter(e.target.value)
  }

  return <>
    {showFilter && <div className={classes.filter}>
      <TextField
        onChange={handleFilter}
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={translate('ra.message.filter_by_name')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </div>}
    {showNoMatch && <Alert severity="info">{translate('resources.scoresheets.alerts.no_members_match_filters')}</Alert>}
    <List dense disablePadding className={classes.root}>
      <LineupGroup
        type="Skater"
        label={translate('resources.scoresheets.labels.players')}
        teamId={teamId}
        members={results.filter(isSkater)}
        selected={counts.totalSkater}
        limits={lineupLimits.skaters}
        disabled={disabled}
        disableCheckbox={filtered}
        classes={classes}
        isScorekeeper={isScorekeeper}
      />

      <LineupGroup
        type="Goalie"
        label={translate('resources.scoresheets.labels.goalies')}
        teamId={teamId}
        members={results.filter(isGoalie)}
        selected={counts.G}
        limits={lineupLimits.goalies}
        disabled={disabled}
        disableCheckbox={filtered}
        classes={classes}
        isScorekeeper={isScorekeeper}
      />

      <LineupGroup
        type="Staff"
        label={translate('resources.scoresheets.labels.bench_staff')}
        teamId={teamId}
        members={results.filter(isStaff)}
        selected={counts.totalStaff}
        limits={lineupLimits.staff}
        disabled={disabled}
        disableCheckbox={filtered}
        classes={classes}
        isScorekeeper={isScorekeeper}
      />

      {!showRegistrations && includeRegistrations && <div className={classes.button}>
        <Button color="secondary" onClick={() => setShowRegistrations(true)}>{translate('resources.scoresheets.messages.show_registrations')}</Button>
      </div>}
    </List>
  </>
}
