import React from 'react';
import { Pagination, useRecordContext } from 'react-admin';
import { SupervisorAccount } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { AssignTargetGrid } from '../../assignTargets/AssignTargetGrid';
import { AddAssignTargetButton } from '../../assignTargets/AssignTargetModal';

export default props => {
  const team = useRecordContext(props);

  const canAddOverride = isAuthorized(team, 'assignTargets', 'create', true)
  return <ListCard
    title="resources.assignTargets.labels.card.title"
    subtitle="resources.assignTargets.labels.card.subtitle"
    icon={<SupervisorAccount />}
    collapsable
    reference="assignTargets"
    target="targetId"
    filter={{ targetType: 'Team' }}
    sort={{ field: 'id', order: 'ASC' }}
    pagination={<Pagination />}
    addLabel={false}
    addButton={canAddOverride && <AddAssignTargetButton initialValues={{ targetType: 'Team', targetId: team?.id }} />}
  >
    <AssignTargetGrid rowClick={false} />
  </ListCard>
}
