import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Tooltip, Checkbox, FormControlLabel } from '@material-ui/core'
import { Assignment } from '@material-ui/icons';

import { apiClient } from '../../../../../../http';
import { useRichTranslate } from '../../../../../../common/useRichTranslate';
import { ParticipantField } from '../../../../../participants/ParticipantField';

import { isExpired, isIneligible, isUnavailable, filterFlags } from '../util';
import { useAssignmentContext } from '../AssignmentContext';
import { useAssignmentsContext } from '../../AssignmentsContext';
import { AdhocOfficial } from '..';

export const assign = (assignmentId, gameId, participantId, position, existingId, replacedIsNoShow = false) =>
  apiClient(`/games/${gameId}/assign`, {
    method: 'POST',
    data: {
      assignmentId,
      participantId,
      replacedParticipantId: existingId,
      replacedIsNoShow,
      position,
    },
  })

const ReassignDialog = ({ assignment = {}, official, open, onClose, game }) => {
  const [ checked, setChecked ] = useState(false);
  const translate = useRichTranslate();
  const { meta } = useAssignmentContext();
  const { isAdhoc } = meta || {};

  const oldOfficial = isAdhoc ? <AdhocOfficial participant={assignment.participant} /> : <ParticipantField record={assignment} source="participantId" variant="inherit" link={false} />
  const newOfficial = <ParticipantField record={official} source="participantId" variant="inherit" link={false} />

  const handleCancel = () => onClose(false);
  const handleConfirm = () => onClose(true, checked);
  const handleSelect = () => setChecked(!checked);

  return <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
    <DialogTitle>{translate('resources.games.labels.assignment.confirm_reassignment')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.games.messages.assignment.already_assigned', { name: oldOfficial })}
      </DialogContentText>
      <DialogContentText>
        {translate('resources.games.messages.assignment.continuing_replacement', { name1: oldOfficial, name2: newOfficial })}
      </DialogContentText>
      {game?.isApproved && !isAdhoc && <DialogContentText>
        <FormControlLabel
          label={translate('resources.games.messages.assignment.mark_as_no_show', { name: oldOfficial })}
          control={<Checkbox color="primary" checked={checked} onChange={handleSelect} />}
        />
      </DialogContentText>}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary">{translate('ra.action.remove')} & {translate('ra.action.assign')}</Button>
    </DialogActions>
  </Dialog>
}

const ConflictDialog = ({ assignment, official, flags, open, onClose }) => {
  const translate = useRichTranslate();
  const participant = <ParticipantField record={official} source="participantId" variant="inherit" />
  const handleCancel = () => onClose(false);
  const handleConfirm = () => onClose(true);

  const expired = isExpired(flags);
  if (expired) {
    return <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle>{translate('resources.games.labels.assignment.expired_qualification')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('resources.games.messages.assignment.invalid_qualification', { name: participant })}
        </DialogContentText>
        <DialogContentText>
          {translate('resources.games.messages.assignment.verified_eligibility')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} autoFocus>{translate('ra.action.cancel')}</Button>
        <Button onClick={handleConfirm}>{translate('ra.action.assign_anyway')}</Button>
      </DialogActions>
    </Dialog>
  }

  return <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
    <DialogTitle>{translate('resources.games.labels.assignment.flagged_official')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.games.messages.assignment.unable_accept_assignment', { name: participant })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={handleConfirm} color="primary">{translate('ra.action.assign_anyway')}</Button>
    </DialogActions>
  </Dialog>
}

export default ({ game, assignment, official, filters, disabled, onClose, onAssign = () => {} }) => {
  const [ dialog, setDialog ] = useState(null); // [reassign ->] [conflict ->] assign
  const [ assigned, setAssigned ] = useState(false);
  const [ inFlight, setInFlight ] = useState(false); // prevent double requests
  const notify = useNotify();
  const translate = useRichTranslate();
  const { meta } = useAssignmentContext();
  const { refetch, setOpenAssignmentIndexes } = useAssignmentsContext();
  const { isAdhoc } = meta || {};

  if (!game || !official || !assignment) return null;
  if (!official.participantId || !assignment.position) return null;

  const flags = filterFlags(official.flags, assignment.position, filters)
  const flagged = isIneligible(flags) || isExpired(flags) || isUnavailable(flags);
  const empty = assignment.participantId == null && !isAdhoc;

  const handleAssign = (isNoShow) => {
    if (inFlight) return;

    const replacedIsNoShow = isNoShow && !isAdhoc;

    setAssigned(true);
    setInFlight(true);
    assign(assignment.id, game.id, official.participantId, assignment.position, assignment.participantId, replacedIsNoShow)
      .then(res => {
        if (replacedIsNoShow) {
          setOpenAssignmentIndexes([]);
          setTimeout(() => refetch(), 750)
        } else {
          onClose(assignment);
        }
        setAssigned(false);
        onAssign(official);
        notify(translate('resources.games.notifications.assignment.official_assigned_to_game', { number: game.number }), 'success')
      })
      .catch(err => {
        setAssigned(false);
        const message = err?.response?.data?.error?.message
        notify(message || 'ra.page.error_try_again', 'error')
      })
      .finally(() => {
        setInFlight(false)
      })
  }

  const handleClick = () => {
    if (!empty) {
      setDialog('reassign')
      return;
    }

    if (flagged) {
      setDialog('conflict');
      return;
    }

    handleAssign();
  }

  const handleDialog = (confirmed, isNoShow) => {
    if (!confirmed) {
      setDialog(null);
      return;
    }

    if (dialog === 'reassign' && flagged && !game.isApproved) {
      setDialog('conflict');
      return;
    }

    setDialog(null);
    handleAssign(isNoShow);
  }

  return <>
    <Tooltip title={translate('resources.games.messages.assignment.assign_official_to_game')}>
      <Button
        color="primary"
        startIcon={<Assignment fontSize="small" />}
        disabled={disabled || assigned}
        onClick={handleClick}
      >
        {empty ? translate('ra.action.assign') : translate('ra.action.reassign')}
      </Button>
    </Tooltip>
    <ReassignDialog assignment={assignment} official={official} open={dialog === 'reassign'} onClose={handleDialog} game={game} />
    <ConflictDialog assignment={assignment} official={official} flags={flags} open={dialog === 'conflict'} onClose={handleDialog} />
  </>
}
