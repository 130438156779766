import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';

import { SquareAlert } from './SquareAlert';

export default ({ children = 'Loading', severity = 'info', square = false }) => {
  const Alert = square ? SquareAlert : MuiAlert;

  return <Alert severity={severity} icon={<CircularProgress color="secondary" size={22} />}>
    {children}
  </Alert>
}
