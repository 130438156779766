import React, { useEffect, useRef, useState } from "react";
import { RecordContextProvider, useDataProvider, useListContext, useTranslate } from "react-admin";
import { List, ListSubheader, TextField, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { InfoOutlined } from "@material-ui/icons";
import { isEqual } from "lodash";

import { useOfficialList, useShowAssignments } from "../events/EventViewSettings";
import { OfficeField } from "../offices/OfficeField";

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    gap: theme.spacing(1)
  },
  autocomplete: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  listSubheader: {
    paddingTop: theme.spacing(2),
    lineHeight: theme.spacing(0.25),
  }
}))

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const renderGroup = (params) =>
  <List key={params.key}>
    <ListSubheader>
      <RecordContextProvider value={params}>
        <OfficeField source="group" variant="inherit" link={false} />
      </RecordContextProvider>
    </ListSubheader>
    {params.children}
  </List>


export const QuickAssignListInput = ({ helperText, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { filterValues } = useListContext();
  const [showAssignments] = useShowAssignments();
  const [listId, setListId] = useOfficialList();
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);

  const assignOffices = useDeepCompareMemoize(filterValues.assignOffices); // prevent re-refetching when other filterValues change
  const canSelectList = showAssignments && assignOffices?.length === 1;

  const selectedList = lists?.length ? lists.find(list => list.id === listId) : null;

  useEffect(() => {
    if (!canSelectList) {
      setListId(null);
      setLists([]);
      return;
    }

    setLoading(true);

    dataProvider.getList('lists', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'officeId', order: 'ASC' },
      filter: { officeId: { inq: assignOffices }, effectiveOffices: false }
    }).then(({ data }) => {
      setLists(data);
      setLoading(false);
    });
  }, [canSelectList, assignOffices, dataProvider, setListId ]);

  return <div className={classes.flex}>
    <Autocomplete
      size="small"
      value={selectedList}
      onChange={(event, newValue) => setListId(newValue?.id)}
      loading={loading}
      options={lists}
      getOptionLabel={(option) => option.name}
      groupBy={(option) => option.officeId}
      renderGroup={renderGroup}
      disabled={!canSelectList}
      renderInput={params => <TextField {...params} className={classes.autocomplete} variant="outlined" label={translate("resources.lists.labels.official")} helperText={helperText} />}
      {...props}
    />
    <Tooltip title={<Typography variant="body2" display="inline">{translate("resources.lists.helpers.quick_assign_tooltip")}</Typography>}>
      <InfoOutlined color="primary" fontSize="small" />
    </Tooltip>
  </div>
}
