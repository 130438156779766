import React from "react";

import styles from "./FileViewer.module.scss";

const ImageFile = ({ fileData, onLoad, onPreviewFail, fileName, setPreviewInfo, containerRef, fileType, isMobile }) => {
  const reducedWidth = isMobile ? 50 : 200;
  const imageWidth = fileType === 'svg' ? window.innerWidth - reducedWidth : 'auto';

  return (
    <img
      style={{ width: imageWidth }}
      src={fileData}
      className={`${styles.Preview} ${styles.PreviewImg} ${fileType === 'svg' ? styles.SVG : ''}`}
      alt={fileName}
      onError={onPreviewFail}
      onLoad={(e) => {
        onLoad(Math.min(1, containerRef.current.clientWidth / e.target.naturalWidth, containerRef.current.clientHeight / e.target.naturalHeight));
        setPreviewInfo({ isVisible: true, naturalWidth: e.target.naturalWidth, naturalHeight: e.target.naturalHeight });
      }}
    />
  );
};

export default ImageFile;
