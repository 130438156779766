import React, { Fragment, useRef, useState } from 'react';
import { TextInput, NumberInput, SimpleForm, useRefresh, useNotify, useTranslate, useRecordContext, RecordContextProvider } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { FlagOutlined } from '@material-ui/icons';
import { FORM_ERROR } from 'final-form';
import { useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';

import { FF_DOUBLE_FORFEIT } from '@hisports/common/featureFlags';

import { apiClient, useFlag } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import CheckboxInput from '../../common/inputs/CheckboxInput';
import { useIsHomeFirst } from '../events/EventViewSettings';

import { TeamField } from '../teams/TeamField';

const useStyles = makeStyles(theme => ({
  checkbox: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  teams: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  label: {
    paddingLeft: theme.spacing(0.5),
  },
  input: {
    marginTop: theme.spacing(1),
  }
}))
const validate = values => {
  const errors = {};

  if (!values.homeForfeit && !values.awayForfeit) {
    errors.homeForfeit ='ra.validation.required';
    errors.awayForfeit ='ra.validation.required';
  }

  if (values.homeScore) {
    if (values.homeScore < 0) errors.homeScore = 'ra.validation.greater_than_or_equal_zero'
    if (values.awayScore == null) errors.awayScore = 'ra.validation.required'
  }

  if (values.awayScore) {
    if (values.awayScore < 0) errors.awayScore = 'ra.validation.greater_than_or_equal_zero'
    if (values.homeScore == null) errors.homeScore = 'ra.validation.required'
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const ForfeitCheckBox = ({ source, ...props }) => {
  const translate = useTranslate();
  const { values } = useFormState();
  const { homeForfeit, awayForfeit } = values;

  let label = translate('resources.games.values.status.Forfeited');
  if (homeForfeit && awayForfeit) {
    label = translate('resources.games.labels.forfeitTie');
  } else if (homeForfeit || awayForfeit) {
    if (values[source]) {
      label = translate('resources.games.labels.forfeitLoss');
    }
    if (!values[source]) {
      label = translate('resources.games.labels.forfeitWin');
    }
  }

  return <CheckboxInput
    source={source}
    label={label}
    helperText=""
    {...props} />
}

const GameForfeitForm = ({ game, ...props }) => {
  const isEnabled = useFlag();
  const translate = useTranslate();
  const classes = useStyles();
  const isHomeFirst = useIsHomeFirst();

  const canDoubleForfeit = isEnabled(FF_DOUBLE_FORFEIT)
  const canEditAdminNotes = isAuthorized(game, 'scoresheets', 'adminNotes')

  const decorators = useRef([createCalculator({
    field: 'homeForfeit',
    updates: {
      awayForfeit: (homeForfeit, values, prevValues) => {
        if (homeForfeit && !canDoubleForfeit) return false;
        return values.awayForfeit
      }
    }
  }, {
    field: 'awayForfeit',
    updates: {
      homeForfeit: (awayForfeit, values, prevValues) => {
        if (awayForfeit && !canDoubleForfeit) return false;
        return values.homeForfeit
      }
    }
  })])

  return <SimpleForm {...props} {...inputProps} decorators={decorators.current} validate={validate}>
    <RecordContextProvider value={game}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12}>
          <Grid container spacing={2} fullWidth>
            <Grid item xs={8} sm={9}>
              <div className={classes.teams}>
                <TeamField source={isHomeFirst ? "homeTeamId" : "awayTeamId"} link={false} />
                <Typography className={classes.label} variant="body2" color="textSecondary" display="inline">{`(${translate(`resources.games.labels.${isHomeFirst ? 'home' : 'away'}`)})`}</Typography>
              </div>
              <div>
                <ForfeitCheckBox source={isHomeFirst ? "homeForfeit" : "awayForfeit"} className={classes.checkbox} canDoubleForfeit={canDoubleForfeit} />
              </div>
            </Grid>
            <Grid item xs={4} sm={3}>
              <NumberInput source={isHomeFirst ? "homeScore" : "awayScore"} label="resources.games.labels.score" className={classes.input} min={0} max={999} helperText="ra.message.optional" {...inputProps} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} fullWidth>
            <Grid item xs={8} sm={9}>
              <div className={classes.teams}>
                <TeamField source={isHomeFirst ? "awayTeamId" : "homeTeamId"} link={false} />
                <Typography className={classes.label} variant="body2" color="textSecondary" display="inline">{`(${translate(`resources.games.labels.${isHomeFirst ? 'away' : 'home'}`)})`}</Typography>
              </div>
              <div>
                <ForfeitCheckBox source={isHomeFirst ? "awayForfeit" : "homeForfeit"} className={classes.checkbox} canDoubleForfeit={canDoubleForfeit} />
              </div>
            </Grid>
            <Grid item xs={4} sm={3}>
              <NumberInput source={isHomeFirst ? "awayScore" : "homeScore"} label="resources.games.labels.score" className={classes.input} min={0} max={999} helperText="ra.message.optional" {...inputProps} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TextInput
        source="notes"
        label="resources.games.labels.scoresheet_notes"
        multiline
        rows={2}
        helperText="resources.games.helpers.scoresheet_notes"
        {...inputProps}
      />
      {canEditAdminNotes && <TextInput
        source="adminNotes"
        label="resources.games.labels.admin_notes"
        multiline
        rows={2}
        helperText="resources.games.helpers.admin_notes"
        {...inputProps}
      />}
    </RecordContextProvider>
  </SimpleForm>
}

export default ({ size, variant, ...props }) => {
  const game = useRecordContext(props)
  const [ isOpen, setOpen ] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const onSubmit = (values) => {
    const { teamId, homeForfeit, awayForfeit, notes, adminNotes, homeScore, awayScore } = values;

    let forfeitId = teamId;
    if (!teamId) {
      if (homeForfeit && awayForfeit) {
        forfeitId = -1;
      } else if (homeForfeit) {
        forfeitId = game.homeTeamId;
      } else if (awayForfeit) {
        forfeitId = game.awayTeamId;
      }
    }

    return apiClient(`/games/${game.id}/forfeit`, {
      method: 'POST',
      data: {
        teamId: forfeitId,
        notes,
        adminNotes,
        score: {
          [game.homeTeamId]: homeScore,
          [game.awayTeamId]: awayScore
        }
      }
    })
      .then(res => res.data)
      .then(() => {
        setOpen(false)
        refresh();
        notify('resources.gameEvents.values.eventType.gameForfeited', 'info')
      })
      .catch(err => ({
        [FORM_ERROR]: translate('ra.page.error_try_again')
      }))
  }

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<FlagOutlined />} onClick={() => setOpen(true)}>
      {translate('resources.games.labels.forfeit')}
    </Button>
    <Dialog open={isOpen} fullWidth maxWidth="sm" fullScreen={fullScreen} onClose={() => setOpen(false)}>
      <DialogTitle>{translate('resources.games.labels.forfeit')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('resources.games.messages.forfeit_help')}
        </DialogContentText>
        <RecordContextProvider value={null}>
          <GameForfeitForm game={game} save={onSubmit} component={Fragment} toolbar={
            <DialogFormToolbar submitLabel="ra.action.save" onCancel={() => setOpen(false)} />
          } />
        </RecordContextProvider>
      </DialogContent>
    </Dialog>
  </>
}
