import React from 'react';
import { useTranslate } from 'react-admin';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { PostGrid } from './PostGrid';
import { PostFilters } from './PostFilters';
import { PostShowLayout } from './PostShow';
import { PostForm } from './PostForm';

export const PostList = props =>
  <List {...props}
    filters={<PostFilters />}
    sort={{
      field: ['officeId', 'date', 'title'],
      order: ['ASC', 'DESC', 'ASC'],
    }}
  >
    <PostGrid rowClick="show" />
  </List>

const getTitle = post => post.title

export const PostShow = props =>
  <Show {...props}
    title={<ResourceTitle title={getTitle} />}
  >
    <PostShowLayout />
  </Show>

export const PostCreate = props => {
  const translate = useTranslate()
  return <Create
    title={<ResourceTitle title={record => translate('resources.posts.labels.add')} />}
    {...props}
  >
    <PostForm redirect="show" />
  </Create>
}

export const PostEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: getTitle(record) })} />}
    {...props}
  >
    <PostForm redirect="show" />
  </Edit>
}
