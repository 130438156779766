import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useListContext, useRecordContext, useTranslate } from 'react-admin';
import { FormControlLabel, Checkbox, IconButton, Menu, MenuItem, Box, useMediaQuery, ListItemIcon, ListItemText, Divider, Typography, makeStyles, Button, ListItemSecondaryAction } from '@material-ui/core';
import { ArrowDropDown, CalendarToday, Dashboard, DateRange, FormatListNumbered, MoreVert, RadioButtonChecked, RadioButtonUnchecked, ViewDay, ViewList, ViewWeek } from '@material-ui/icons';

import { isHomeFirst } from '@hisports/scoresheet/src/util';

import usePreferences from '../../common/usePreferences';
import { useScopes, useSport, useTenant } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import ExportButton from '../../common/ra/ExportButton';

import RenumberDialog from '../drafts/dialogs/RenumberDialog';
import draftGamesExporter from '../draftgames/DraftGameExporter';


export const CALENDAR_VIEWS = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  SEASON: 'Season',
}

export const GAME_LIST_FILTERS = {
  ALL_DRAFT_GAMES: 'drafts_only',
  DRAFT_GAMES_NO_DATES: 'drafts_no_dates',
  DRAFT_GAMES_NO_TIMES: 'drafts_no_times',
  ALL_PUBLISHED_GAMES: 'all_published_games',
  ALL_GAMES: 'all_games'
}

const useStyles = makeStyles(theme => ({
  menu: {
    minWidth: theme.spacing(32),
  },
  menuButton: {
    marginLeft: theme.spacing(1.5),
  },
  label: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  controlLabel: {
    minWidth: '200px',
  }
}))

export const EventViewSettingsContext = React.createContext([false, value => {}]);

const groupRounds = [false, () => null];
export const EventViewSettingsProvider = props => {
  // CAUTION: there's an identical provider for this context in Scheduler.js and DraftGamesCard.js
  // when adding new settings, add it in that provider as well to avoid a crash
  const groupDates = usePreferences('GameViewGroupDates', false);
  const groupArenas = usePreferences('GameViewGroupArenas', false);
  const assignments = usePreferences('GameViewAssignments', false);
  const gameInfo = usePreferences('GameViewInformation', false);
  const flags = usePreferences('GameViewFlags', false);
  const reverseHomeAway = usePreferences('GameViewReverseHomeAway', false);
  const calendar = usePreferences('GameViewCalendar', false);
  const calendarView = usePreferences('GameViewCalendarView', CALENDAR_VIEWS.WEEK);
  const datelessView = usePreferences('GameViewDatelessView', CALENDAR_VIEWS.MONTH);
  const teamEvents = useState(true);
  const slots = usePreferences('GameViewSlots', true);
  const awaySlots = usePreferences('GameViewAwaySlots', false);
  const teamNames = usePreferences('GameViewTeamNames', false);
  const officeNames = usePreferences('GameViewOfficeNames', false);
  const condensedView = usePreferences('GameViewCondensedView', true);
  const availabilities = usePreferences('GameViewAvailabilities', true);
  const surfaceSizes = useState([]);
  const gameListFilter = usePreferences('GameViewGameListFilter', GAME_LIST_FILTERS.ALL_GAMES);
  const officialList = usePreferences('OfficialList');
  const value = useMemo(() => ({
    calendar,
    calendarView,
    datelessView,
    teamEvents,
    slots,
    awaySlots,
    teamNames,
    officeNames,
    condensedView,
    availabilities,
    gameInfo,
    flags,
    reverseHomeAway,
    groupDates,
    groupArenas,
    groupRounds,
    assignments,
    surfaceSizes,
    gameListFilter,
    officialList
  }), [calendar, calendarView, datelessView, teamEvents, slots, awaySlots, teamNames, officeNames, availabilities, gameInfo, flags, reverseHomeAway, groupDates, groupArenas, assignments, surfaceSizes, gameListFilter, condensedView, officialList])

  return <EventViewSettingsContext.Provider value={value} {...props} />
}

export const useEventViewSettings = () =>
  useContext(EventViewSettingsContext);

export const useGroupDates = (initialValue) => {
  const { groupDates } = useEventViewSettings();
  return groupDates;
}

export const useGroupArenas = () => {
  const { groupArenas } = useEventViewSettings();
  return groupArenas;
}

export const useGroupRounds = () => {
  const { groupRounds } = useEventViewSettings();
  return groupRounds;
}

export const useSurfaceSizes = () => {
  const { surfaceSizes } = useEventViewSettings();
  return surfaceSizes;
}

export const useEventSort = ({ groupByDate, groupByArena, groupByRound } = {}) => {
  const [ groupDates ] = useGroupDates();
  const [ groupArenas ] = useGroupArenas();
  const [ groupRounds ] = useGroupRounds();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const fields = [];
  const orders = [];

  if ((groupByDate || groupDates) && !isMobile && groupByDate !== false) {
    fields.push('date')
    orders.push('ASC')
  }
  if ((groupByArena || groupArenas) && !isMobile && groupByArena !== false) {
    fields.push('surface.venue.country', 'surface.venue.region', 'surface.venue.city', 'surface.venue.name', 'surface.name')
    orders.push('ASC', 'ASC', 'ASC', 'ASC', 'ASC')
  }
  if ((groupByRound || groupRounds) && !isMobile && groupByRound !== false) {
    fields.push('round')
    orders.push('ASC')
  }
  fields.push('startTime', 'number')
  orders.push('ASC', 'ASC')

  return {
    field: fields,
    order: orders,
  }
}

export const useShowAssignments = () => {
  const { assignments } = useEventViewSettings();
  return assignments;
}

export const useShowCalendar = () => {
  const { calendar } = useEventViewSettings();
  return calendar;
}

export const useCalendarView = () => {
  const { calendarView } = useEventViewSettings();
  return calendarView;
}

export const useDatelessView = () => {
  const { datelessView } = useEventViewSettings();
  return datelessView;
}

export const useShowTeamEvents = () => {
  const { teamEvents } = useEventViewSettings();
  return teamEvents;
}

export const useShowSlots = () => {
  const { slots } = useEventViewSettings();
  return slots;
}

export const useShowAwaySlots = () => {
  const { awaySlots } = useEventViewSettings();
  return awaySlots;
}

export const useShowTeamName = () => {
  const { teamNames } = useEventViewSettings();
  return teamNames;
}

export const useShowOfficeName = () => {
  const { officeNames } = useEventViewSettings();
  return officeNames;
}

export const useShowCondensedView = () => {
  const { condensedView } = useEventViewSettings();
  return condensedView;
}

export const useShowGameInfo = () => {
  const { gameInfo } = useEventViewSettings();
  return gameInfo;
}

export const useShowFlags = () => {
  const { flags } = useEventViewSettings();
  return flags;
}

export const useReverseHomeAway = () => {
  const { reverseHomeAway } = useEventViewSettings();
  return reverseHomeAway;
}

export const useIsHomeFirst = () => {
  const sport = useSport();
  const [ reverseHomeAway ] = useReverseHomeAway();

  return reverseHomeAway ? !isHomeFirst(sport) : isHomeFirst(sport);
}

export const useShowAvailabilities = () => {
  const { availabilities } = useEventViewSettings();
  return availabilities;
}

export const useGameListFilter = () => {
  const { gameListFilter } = useEventViewSettings();
  return gameListFilter;
}

export const useOfficialList = () => {
  const { officialList } = useEventViewSettings();
  return officialList;
}

const ShowGameInfoInput = ({ disableCalendar }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setGameInfo ] = useShowGameInfo();
  const [ showCalendar ] = useShowCalendar();

  const handleChange = e => {
    const checked = e.target.checked;
    setGameInfo(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    control={<Checkbox checked={checked} indeterminate={checked && showCalendar && !disableCalendar} disabled={showCalendar && !disableCalendar} onChange={handleChange} name="showGameInfo" />}
    label={translate('resources.games.messages.show_more_game_info')}
  />
}

const ShowFlagsInput = ({ disableCalendar }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setFlags ] = useShowFlags();
  const [ showCalendar ] = useShowCalendar();

  const handleChange = e => {
    const checked = e.target.checked;
    setFlags(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    control={<Checkbox checked={checked} indeterminate={checked && showCalendar && !disableCalendar} disabled={showCalendar && !disableCalendar} onChange={handleChange} name="showFlags" />}
    label={translate('resources.games.messages.show_flags')}
  />
}

const ReverseHomeAway = ({ disableCalendar }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setReverseHomeAway ] = useReverseHomeAway();
  const [ showCalendar ] = useShowCalendar();

  const handleChange = e => {
    const checked = e.target.checked;
    setReverseHomeAway(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    control={<Checkbox checked={checked} indeterminate={checked && showCalendar && !disableCalendar} disabled={showCalendar && !disableCalendar} onChange={handleChange} name="reverseHomeAway" />}
    label={translate('resources.games.messages.reverse_home_away')}
  />
}

const ShowAssignmentsInput = ({ disableCalendar }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setAssignments ] = useShowAssignments();
  const [ showCalendar ] = useShowCalendar();

  const handleChange = e => {
    const checked = e.target.checked;
    setAssignments(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    control={<Checkbox checked={checked} indeterminate={checked && showCalendar && !disableCalendar} disabled={showCalendar && !disableCalendar} onChange={handleChange} name="showAssignments" />}
    label={translate('resources.games.messages.show_assignments')}
  />
}

const GroupDatesInput = ({ disableCalendar }) => {
  const translate = useTranslate();
  const [ checked, setGroupDates ] = useGroupDates();
  const [ showCalendar ] = useShowCalendar();
  const { setSort } = useListContext();

  const handleChange = e => {
    const checked = e.target.checked;
    setGroupDates(checked)
    setSort("date", "ASC")
  }

  return <FormControlLabel
    label={translate('resources.games.messages.group_games_by_date')}
    control={<Checkbox
      checked={checked}
      indeterminate={checked && showCalendar && !disableCalendar}
      disabled={showCalendar && !disableCalendar}
      onChange={handleChange}
      name="groupDates"
    />}
  />
}

const GroupRoundsInput = ({ disableCalendar }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setGroupRounds ] = useGroupRounds();
  const [ showCalendar ] = useShowCalendar();
  const { setSort } = useListContext();

  const handleChange = e => {
    const checked = e.target.checked;
    setGroupRounds(checked)
    setSort(["rounds", "date"], ["ASC", "ASC"])
  }

  return <FormControlLabel
    className={classes.controlLabel}
    control={<Checkbox
      checked={checked}
      indeterminate={checked && showCalendar && !disableCalendar}
      disabled={showCalendar && !disableCalendar}
      onChange={handleChange}
      name="groupRounds"
    />}
    label={translate('resources.games.messages.group_games_by_round')}
  />
}

const GroupArenasInput = ({ disableCalendar }) => {
  const translate = useTranslate();
  const [ showCalendar ] = useShowCalendar();
  const [ checked, setGroupArenas ] = useGroupArenas();

  const { setSort } = useListContext();

  const handleChange = e => {
    const checked = e.target.checked;
    setGroupArenas(checked)

    if (checked && !showCalendar) {
      setSort(["date", "surface.venue.city", "surface.venue.name", "surface.name", "startTime"], ["ASC", "ASC", "ASC", "ASC", "ASC"])
    } else {
      setSort("startTime", "ASC")
    }
  }

  return <FormControlLabel
    label={translate('resources.games.messages.group_games_by_arena')}
    control={<Checkbox
      checked={checked}
      indeterminate={checked && showCalendar && !disableCalendar}
      disabled={showCalendar && !disableCalendar}
      onChange={handleChange}
      name="groupArenas"
    />}
  />
}

const ShowTeamEvents = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setShowTeamEvents ] = useShowTeamEvents();

  const handleChange = e => {
    const checked = e.target.checked;
    setShowTeamEvents(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    label={translate('resources.events.labels.team_events')}
    control={<Checkbox checked={checked} onChange={handleChange} />}
  />
}

const ShowSlots = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setShowSlots ] = useShowSlots();

  const handleChange = e => {
    const checked = e.target.checked;
    setShowSlots(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    label={translate('components.calendar.settings.arena_slots', 2)}
    control={<Checkbox checked={checked} onChange={handleChange} />}
  />
}

const ShowAwaySlots = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setShowAwaySlots ] = useShowAwaySlots();

  const handleChange = e => {
    const checked = e.target.checked;
    setShowAwaySlots(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    label={translate('components.calendar.settings.away_arena_slots', 2)}
    control={<Checkbox checked={checked} onChange={handleChange} />}
  />
}

const ShowOfficeNames = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setShowOfficeNames ] = useShowOfficeName();

  const handleChange = e => {
    const checked = e.target.checked;
    setShowOfficeNames(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    label={translate('resources.schedules.labels.matrix.show_organisation', 2)}
    control={<Checkbox checked={checked} onChange={handleChange} />}
  />
}

const ShowCondensedView = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setShowCondensedView ] = useShowCondensedView();

  const handleChange = e => {
    const checked = e.target.checked;
    setShowCondensedView(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    label={translate('components.calendar.settings.condensed_view', 2)}
    control={<Checkbox checked={checked} onChange={handleChange} />}
  />
}

const ShowTeamNames = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setShowTeamNames ] = useShowTeamName();

  const handleChange = e => {
    const checked = e.target.checked;
    setShowTeamNames(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    label={translate('components.calendar.settings.team_names', 2)}
    control={<Checkbox checked={checked} onChange={handleChange} />}
  />
}

const ShowAvailabilties = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ checked, setShowAvailabilities ] = useShowAvailabilities();

  const handleChange = e => {
    const checked = e.target.checked;
    setShowAvailabilities(checked);
  }

  return <FormControlLabel
    className={classes.controlLabel}
    label={translate('resources.availabilities.name', 2)}
    control={<Checkbox checked={checked} onChange={handleChange} />}
  />
}

export const CalendarToggle = () => {
  const [ showCalendar, setShowCalendar ] = useShowCalendar();
  const toggle = () => setShowCalendar(shown => !shown)
  return <IconButton onClick={toggle}>
    {showCalendar ? <ViewList /> : <DateRange />}
  </IconButton>
}

const ShowCalendarInput = () => {
  const translate = useTranslate();
  const [ checked, setShowCalendar ] = useShowCalendar();

  const handleChange = e => {
    const checked = e.target.checked;
    setShowCalendar(checked)
  }

  return <FormControlLabel
    label={translate('resources.events.labels.show_calendar')}
    control={<Checkbox checked={checked} onChange={handleChange} name="calendar" />}
  />
}

const viewIcons = {
  [CALENDAR_VIEWS.DAY]: <ViewDay fontSize="small" />,
  [CALENDAR_VIEWS.WEEK]: <ViewWeek fontSize="small" />,
  [CALENDAR_VIEWS.MONTH]: <CalendarToday fontSize="small" />,
  [CALENDAR_VIEWS.SEASON]: <Dashboard fontSize="small" />
}

const View = ({ view, onClose }) => {
  const translate = useTranslate();
  const [ calendarView, setCalendarView ] = useCalendarView();
  const checked = calendarView === view;

  const handleChange = e => {
    setCalendarView(view)
    onClose()
  }

  const icon = viewIcons[view]
  return <MenuItem onClick={handleChange}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={translate(`components.calendar.values.views.${view}`)} />
    <ListItemSecondaryAction>
      {checked ? <RadioButtonChecked fontSize="small" /> : <RadioButtonUnchecked fontSize="small" />}
    </ListItemSecondaryAction>
  </MenuItem>
}

const SurfaceSize = ({ size }) => {
  const translate = useTranslate();
  const [ sizes, setSizes ] = useSurfaceSizes();
  const checked = sizes.includes(size);

  const handleChange = e => {
    setSizes(prevSizes => {
      const newSizes = prevSizes.filter(s => s !== size);
      if (e.target.checked) {
        newSizes.push(size);
      }
      return newSizes;
    });
  }

  return <FormControlLabel
    label={translate(`resources.surfaces.values.size.${size}`)}
    control={<Checkbox checked={checked} onChange={handleChange} />}
  />
}

const SurfaceSizes = () => {
  const { sport } = useTenant() || {};
  const sportSizes = {
    Hockey: ['XL', 'M', 'XS'],
    Soccer: ['XL', 'L', 'M', 'S', 'XS'],
    Baseball: ['XL', 'L', 'M', 'S', 'XS'],
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
    {sportSizes[sport].map(value => <SurfaceSize key={value} size={value} />)}
  </Box>
}

const AllSizes = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ sizes, setSizes ] = useSurfaceSizes();
  const checked = !sizes?.length;

  const handleChange = e => {
    if (e.target.checked) {
      setSizes([]);
    }
  }

  return <FormControlLabel
    className={classes.controlLabel}
    label={translate('resources.surfaces.values.size.all_sizes')}
    control={<Checkbox checked={checked} onChange={handleChange} name="sizes" />}
  />
}

const RenumberGames = ({ onClose, ...props }) => {
  const translate = useTranslate()
  const [ open, setOpen ] = useState(false);
  const draft = useRecordContext();

  const { games } = draft || {};

  const onCloseDialog = () => {
    setOpen(false);
    onClose()
  }

  return <MenuItem onClick={() => setOpen(true)} disabled={!games?.length} disableRipple>
    <ListItemIcon>
      <FormatListNumbered />
    </ListItemIcon>
    <ListItemText primary={translate('resources.drafts.messages.renumber_games.actions.renumber_games')} />
    {open && <RenumberDialog isOpen={open} onClose={onCloseDialog} />}
  </MenuItem>
}

export const EventViewSettingsForm = ({ disableCalendar, disableAssignments, showLabel = false }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const scopes = useScopes()
  const canViewAssignments = isAuthorized(scopes, 'games', 'assign');
  const canViewFlags = isAuthorized(scopes, 'scoresheets', 'full');

  return <>
    {showLabel && <Typography className={classes.label} variant="overline">{translate('resources.games.labels.view_options')}</Typography>}
    {!disableCalendar && <ShowCalendarInput />}
    <GroupArenasInput disableCalendar={disableCalendar} />
    <ShowGameInfoInput disableCalendar={disableCalendar} />
    {!disableAssignments && canViewAssignments && <ShowAssignmentsInput disableCalendar={disableCalendar} />}
    {canViewFlags && <ShowFlagsInput disableCalendar={disableCalendar} />}
    <ReverseHomeAway disableCalendar={disableCalendar} />
  </>
}

const CalendarSettingsMenu = ({ disableSeasonView, showTeamEvents, showSlots, showAwaySlots, showTeamNames, showOfficeNames, showCondensedView, showAvailabilties, showViewToggle, showSurfaceSizes, showRenumber, showExporter, resource, filterValues, anchor, toggle, onClose }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [ calendarView ] = useCalendarView();

  const disabled = !showTeamEvents && !showSlots && !showTeamNames && !showViewToggle && !showAvailabilties && !showSurfaceSizes
  const showDivider = showViewToggle && (showSurfaceSizes || showTeamEvents || showSlots || showTeamNames || showAvailabilties || showRenumber || showExporter)
  return <>
    {showViewToggle && <Button onClick={toggle} color="secondary" variant="outlined" endIcon={<ArrowDropDown />} className={classes.menuButton}>
      {translate(`components.calendar.values.views.${calendarView}`)}
    </Button>}
    {!showViewToggle && <IconButton onClick={toggle} size="small" disabled={disabled}><MoreVert /></IconButton>}
    <Menu
      classes={{ paper: classes.menu }}
      anchorEl={anchor}
      open={anchor != null}
      onClose={onClose}
    >
      {showViewToggle && <>
        <View view={CALENDAR_VIEWS.DAY} onClose={onClose} />
        <View view={CALENDAR_VIEWS.WEEK} onClose={onClose} />
        <View view={CALENDAR_VIEWS.MONTH} onClose={onClose} />
        {!disableSeasonView && <View view={CALENDAR_VIEWS.SEASON} onClose={onClose} />}
      </>}
      {showDivider && <Divider />}
      {showSurfaceSizes && <>
        <MenuItem dense>
          <AllSizes key="all-sizes" />
        </MenuItem>
        <SurfaceSizes />
      </>}
      {showTeamEvents && <MenuItem dense>
        <ShowTeamEvents />
      </MenuItem>}
      {showSlots && <MenuItem dense>
        <ShowSlots />
      </MenuItem>}
      {showAwaySlots && <MenuItem dense>
        <ShowAwaySlots />
      </MenuItem>}
      {showTeamNames && <MenuItem dense>
        <ShowTeamNames />
      </MenuItem>}
      {showOfficeNames && <MenuItem dense>
        <ShowOfficeNames />
      </MenuItem>}
      {showCondensedView && <MenuItem dense>
        <ShowCondensedView />
      </MenuItem>}
      {showAvailabilties && <MenuItem dense>
        <ShowAvailabilties />
      </MenuItem>}
      {showRenumber && <>
        <Divider />
        <RenumberGames onClose={onClose} />
      </>}
      {showExporter && <>
        <Divider />
        <ExportButton menuItem exporter={draftGamesExporter} resource={resource} filterValues={filterValues} onClick={onClose} />
      </>}
    </Menu>
  </>
}

const ListSettingsMenu = ({ disableCalendar, disableGroupRounds, disableGroupArenas, showGameInfoInput, showFlagsInput, showAssignmentsInput, showRenumber, showExporter, resource, filterValues, anchor, toggle, onClose }) => {
  return <>
    <IconButton onClick={toggle} size="small"><MoreVert /></IconButton>
    <Menu
      anchorEl={anchor}
      open={anchor != null}
      onClose={onClose}
    >
      {!disableGroupRounds && <MenuItem dense onClick={onClose}>
        <GroupRoundsInput key="group-rounds" disableCalendar={disableCalendar} />
      </MenuItem>}
      <MenuItem dense onClick={onClose}>
        <GroupDatesInput key="group-dates" disableCalendar={disableCalendar} />
      </MenuItem>
      {!disableGroupArenas && <MenuItem dense onClick={onClose}>
        <GroupArenasInput key="group-arenas" disableCalendar={disableCalendar} />
      </MenuItem>}
      {showGameInfoInput && <MenuItem dense onClick={onClose}>
        <ShowGameInfoInput key="show-game-info" disableCalendar={disableCalendar} />
      </MenuItem>}
      {showAssignmentsInput && <MenuItem dense onClick={onClose}>
        <ShowAssignmentsInput key="show-assignment" disableCalendar={disableCalendar} />
      </MenuItem>}
      {showFlagsInput && <MenuItem dense onClick={onClose}>
        <ShowFlagsInput key="show-flags" disableCalendar={disableCalendar} />
      </MenuItem>}
      {showRenumber && <>
        <Divider />
        <RenumberGames onClose={onClose} />
      </>}
      {showExporter && <>
        <Divider />
        <ExportButton menuItem exporter={draftGamesExporter} resource={resource} filterValues={filterValues} onClick={onClose} />
      </>}
    </Menu>
  </>
}

export const EventViewSettingsMenu = ({
  disableCalendar = false,
  disableAssignments = false,
  disableGroupRounds = true,
  disableGroupArenas = false,
  disableSeasonView = false,
  showTeamEvents = false,
  showSlots = false,
  showAwaySlots = false,
  showTeamNames = false,
  showOfficeNames = false,
  showCondensedView = true,
  showAvailabilties = false,
  showViewToggle = false,
  showSurfaceSizes = false,
  showGameInfoInput = true,
  showFlagsInput = true,
  showRenumber = false,
  showExporter = false
}) => {
  const scopes = useScopes();
  const [ showCalendar ] = useShowCalendar();
  const [ groupArenas, setGroupArenas ] = useGroupArenas();
  const canViewAssignments = isAuthorized(scopes, 'games', 'assign');

  // Authorization check for calendar setting menu
  const canListTeamEvents = isAuthorized(scopes, 'games', 'list') || isAuthorized(scopes, 'practices', 'list') || isAuthorized(scopes, 'activities', 'list');
  const canListSlots = isAuthorized(scopes, 'arenaslots', 'list');
  const canListAvailabilties = isAuthorized(scopes, 'availabilities', 'list');

  const [ anchor, setAnchor ] = useState(null)
  const toggle = e => setAnchor(anchor => anchor ? !anchor : e.currentTarget)
  const onClose = () => setAnchor(null)

  const showCalendarInput = !disableCalendar;
  const showAssignmentsInput = !disableAssignments && canViewAssignments;

  useEffect(() => {
    if (disableGroupArenas === true) {
      setGroupArenas(false);
    }
  }, [ disableGroupArenas, setGroupArenas ])

  return <>
    {showCalendarInput && <CalendarToggle />}
    {showCalendar
      ? <CalendarSettingsMenu
        disableSeasonView={disableSeasonView}
        showTeamEvents={showTeamEvents && canListTeamEvents}
        showSlots={showSlots && canListSlots}
        showAwaySlots={showAwaySlots && canListSlots}
        showTeamNames={showTeamNames}
        showOfficeNames={showOfficeNames}
        showCondensedView={showCondensedView}
        showAvailabilties={showAvailabilties && canListAvailabilties}
        showViewToggle={showViewToggle}
        showSurfaceSizes={showSurfaceSizes}
        showRenumber={showRenumber}
        showExporter={showExporter}
        anchor={anchor}
        toggle={toggle}
        onClose={onClose}
      />
      : <ListSettingsMenu
        disableCalendar={disableCalendar}
        disableGroupRounds={disableGroupRounds}
        disableGroupArenas={disableGroupArenas}
        showGameInfoInput={showGameInfoInput}
        showFlagsInput={showFlagsInput}
        showAssignmentsInput={showAssignmentsInput}
        showRenumber={showRenumber}
        showExporter={showExporter}
        anchor={anchor}
        toggle={toggle}
        onClose={onClose}
      />}
  </>
}
