import React from 'react';
import { useTranslate } from 'react-admin';
import { CardActions, Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'
import moment from 'moment';

import { getLineup, isGameCertified, isGameScheduled } from '@hisports/scoresheet/src/selectors';
import { FF_PHOTOS, FF_RESTRICT_LINEUP_ENTRY } from '@hisports/common/featureFlags';

import InfoCard from '../../../common/cards/InfoCard';
import { isAuthorized } from '../../../common/Authorize';
import { SquareAlert as Alert } from '../../../common/SquareAlert';
import { useFlag, useParticipant, usePermissions } from '../../../http/AuthContext';
import { TeamField } from '../../teams/TeamField';
import LoadingAlert from '../../../common/LoadingAlert';

import { isStaffMember } from '..';
import { useMeta, useScoresheet } from '../ScoresheetContext';

import { LineupList } from './LineupList';
import { LineupRequirements } from './LineupRequirements';
import { LineupSignature } from './LineupSignature';
import { SaveLineup } from './SaveLineup';
import { AddExtraButton } from './AddExtra';
import { PhotosButton } from './Photos';
import { PrintLineupButton } from './PrintLineup';
import { MemberValidationProvider, useMemberValidationContext } from './MemberValidationContext';
import LineupFlags from './LineupFlags';

const useStyles = makeStyles(theme => ({
  subtitle: {
    margin: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
    }
  },
  changes: {
    color: theme.palette.grey[700],
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      lineHeight: 1.25,
    }
  }
}))

const ValidatingMembersAlert = () => {
  const { loading } = useMemberValidationContext();
  const translate = useTranslate();

  if (!loading) return null;
  return <LoadingAlert square>
    {translate('resources.scoresheets.alerts.loading.validatingMembers')}
  </LoadingAlert>
}

const LineupNotice = ({ disabled, completed, showCompleted = false }) => {
  const translate = useTranslate();
  if (completed && showCompleted) return <Alert severity="success">{translate('resources.games.alerts.status.completed')}</Alert>
  if (!completed && disabled) return <Alert severity="info">{translate('resources.scoresheets.alerts.lineup_change_post_game_started')}</Alert>
  return null;
}

export const LineupCardBody = ({ status, teamId, showCompleted }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const isEnabled = useFlag();
  const { game, meta } = useMeta();
  const permissions = usePermissions();
  const pregame = useScoresheet(scoresheet => isGameScheduled(scoresheet));
  const isCertified = useScoresheet(scoresheet => isGameCertified(scoresheet));
  const lineup = useScoresheet(scoresheet => getLineup(scoresheet, { teamId }))
  const participantId = useParticipant();
  const team = meta.teams[teamId]

  const isEarly = meta?.teams[teamId]?.earliestLineupAccessTime ? moment.tz(game.timezone).isBefore(meta?.teams[teamId]?.earliestLineupAccessTime) : false

  const isAssignedOfficial = ['confirmed', 'assigned'].includes(status?.officials?.self)
  const isHomeStaff = meta?.teams[game?.homeTeamId]?.members.some(member => member.participantId === participantId && isStaffMember(member));
  const isAwayStaff = meta?.teams[game?.awayTeamId]?.members.some(member => member.participantId === participantId && isStaffMember(member));
  const isTeamScorekeeper = (meta?.policies.awayTeamScorekeeping && status?.scorekeeping && isAwayStaff) || (meta?.policies.homeTeamScorekeeping && status?.scorekeeping && isHomeStaff)
  const isScorekeeper = isAssignedOfficial || isTeamScorekeeper

  const hasFullAccess = permissions.some(permission => {
    if (permission.roleType === 'System') return true;
    if (permission.roleType === 'Office' && permission.officeIds.includes(game.officeId) && isAuthorized(permission.scopes, 'scoresheets', 'full')) return true;
    if (permission.roleType === 'Office' && permission.officeIds.includes(team.officeId) && isAuthorized(permission.scopes, 'scoresheets', 'full')) return true;
    if (permission.roleType === 'Schedule' && permission.scheduleIds.includes(game.scheduleId) && isAuthorized(permission.scopes, 'scoresheets', 'full')) return true;
    return false;
  });

  const hasTeamAccess = permissions.some(permission => {
    if (permission.roleType === 'System') return true;
    if (permission.roleType === 'Team' && permission.teamIds.includes(teamId) && isAuthorized(permission.scopes, 'scoresheets', 'lineup')) return true;
    if (permission.roleType === 'Schedule' && permission.scheduleIds.includes(game.scheduleId) && isAuthorized(permission.scopes, 'scoresheets', 'lineup')) return true;
    if (permission.roleType === 'Office' && team && permission.officeIds.includes(team.officeId) && isAuthorized(permission.scopes, 'scoresheets', 'lineup')) return true;
    return false;
  })

  if (!team) return <Alert severity="warning">{translate('resources.scoresheets.alerts.team_has_no_members')}</Alert>

  if (isEnabled(FF_RESTRICT_LINEUP_ENTRY) && isEarly) {
    return <Alert severity="warning" action={<PrintLineupButton teamId={teamId} type="roster" />} >
      {translate('resources.scoresheets.alerts.early_lineup_entry',
        { date: moment.tz(meta.teams[teamId].earliestLineupAccessTime, game.timezone).format('LLLL') })}
    </Alert>
  }

  if (!hasFullAccess && !hasTeamAccess && !game.isApproved && !isScorekeeper) {
    return <Alert severity="error">{translate('resources.scoresheets.alerts.unauthorized_manage_lineup')}</Alert>
  }

  const disabled = (!pregame && !hasFullAccess && !isScorekeeper) || isCertified
  const showPhotos = isEnabled(FF_PHOTOS) && team.externalId != null
  const hasMembers = lineup?.members?.length > 0
  const rosterLink = <Link to={`/teams/${teamId}/show/roster`} target="blank" component={MuiLink}>{translate('resources.scoresheets.messages.your_team_roster')}</Link>

  return <MemberValidationProvider disabled={disabled} lineup={lineup} team={team} game={game}>
    <div className={classes.subtitle}>
      <TeamField record={{ teamId }} source="teamId" link={false} variant="subtitle2" />
      {!disabled && <Typography variant="subtitle1" className={classes.changes}>
        {translate('resources.scoresheets.messages.lineup_change_apply')} {rosterLink} {translate('resources.scoresheets.messages.lineup_change_apply_part_2')}
      </Typography>}
    </div>

    <ValidatingMembersAlert />
    <LineupNotice disabled={disabled} completed={game.isApproved} showCompleted={showCompleted} />
    <LineupList teamId={teamId} disabled={disabled} isScorekeeper={isScorekeeper} />
    <LineupRequirements teamId={teamId} disabled={disabled} />
    <LineupFlags teamId={teamId} />
    <LineupSignature teamId={teamId} />

    <CardActions>
      {!disabled && <>
        <SaveLineup teamId={teamId} disabled={disabled} />
        <AddExtraButton teamOfficeId={team.officeId} teamId={teamId} disabled={disabled} hasFullAccess={hasFullAccess} />
      </>}
      <PrintLineupButton teamId={teamId} disabled={!hasMembers} />
      {showPhotos && <PhotosButton teamId={teamId} lineup={lineup} disabled={!hasMembers} />}
    </CardActions>
  </MemberValidationProvider>
}

export const LineupCard = ({ status, teamId, title }) => {
  return <InfoCard title={title} disableMaxHeight>
    <LineupCardBody status={status} teamId={teamId} />
  </InfoCard>
}

export default LineupCardBody;
