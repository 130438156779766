import React from 'react';
import { ReferenceInput, ReferenceArrayInput, useTranslate } from 'react-admin';
import { Flip } from '@material-ui/icons';

import { isUUID } from '@hisports/parsers';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { SurfaceIcon } from '../icons';

import { getSurfaceName, getSurfaceCity, getSurfaceAttributes } from './util';

export const filterSurface = name => ({ name })

const matches = (left, right) => `${left}`.trim().toLowerCase().includes(`${right}`.trim().toLowerCase());
export const matchSurface = (input, result) => {
  if (!input) return true;
  if (!result) return false;
  const [ venue ] = input.trim().split(' - ');
  if (isUUID(venue)) return result.externalId === venue;
  return (
    matches(result.name, venue) ||
    matches(result.alias, venue) ||
    matches(result.venue.name, venue) ||
    matches(result.venue.address, venue) ||
    matches(result.venue.city, venue) ||
    matches(result.venue.region, venue) ||
    matches(result.venue.country, venue) ||
    matches(result.venue.alias, venue)
  )
}

export const SurfaceSuggestion = ({ suggestion: surface, query, isHighlighted, ...props }) => {
  const translate = useTranslate();

  const attributes = getSurfaceAttributes(surface, translate);
  const address = surface?.venue?.address;
  const fraction = surface?.fraction;

  let secondary = [];
  if (fraction) {
    secondary.push(translate('resources.surfaces.fields.split'));
  }
  if (attributes) {
    secondary.push(attributes);
  }
  if (secondary.length) {
    secondary = [<div>{secondary.join(' - ')}</div>]
  }
  if (address) {
    secondary.unshift(<div>{address}</div>);
  }

  return <AutocompleteSuggestion
    icon={fraction ? <Flip /> : <SurfaceIcon />}
    primary={getSurfaceName(surface)}
    secondary={secondary}
  />
}

export const SurfaceInput = ({ helperText, multiple, filter: inputFilter = {}, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;

  return <Input
    reference="surfaces"
    filterToQuery={filterSurface}
    filter={{ _scope: 'Tenant', ...inputFilter }}
    sort={{
      field: ['venue.country', 'venue.city', 'venue.region', 'venue.name', 'name'],
      order: ['ASC', 'ASC', 'ASC', 'ASC', 'ASC']
    }}
    perPage={100}
    {...props}
  >
    <AutocompleteInput
      optionText={surface => getSurfaceName(surface)}
      groupBy={surface => getSurfaceCity(surface, true, true, false)}
      suggestionComponent={SurfaceSuggestion}
      inputValueMatcher={matchSurface}
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
