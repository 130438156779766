import React, { Fragment } from 'react';
import { RecordContextProvider, SimpleForm, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@material-ui/core';
import { isEqual, sortBy } from 'lodash';

import { getPeriods } from '@hisports/scoresheet/src/selectors';
import { startGame } from '@hisports/scoresheet/src/actions';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';

import { useMeta, useScoresheet, useScoresheetDispatch } from '../ScoresheetContext';
import { HockeyPeriodSettings } from '../summary/PeriodsModal';

const StartForm = ({ game, loaded, assignedOfficials, noShowOfficials, ...props }) => {
  const scoresheetPeriods = useScoresheet(scoresheet => getPeriods(scoresheet)) || [];
  const { meta } = useMeta();
  const { policies } = meta || {};
  const { periods: periodSettings } = policies || {}

  return <SimpleForm {...props}>
    <HockeyPeriodSettings periods={periodSettings || scoresheetPeriods} />
  </SimpleForm>
}

export const StartDialog = ({ open, setModalOpen, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const translate = useTranslate()
  const game = useRecordContext();
  const dispatch = useScoresheetDispatch();
  const periodSettings = useScoresheet(scoresheet => getPeriods(scoresheet)) || [];
  const refresh = useRefresh();
  const notify = useNotify();

  const onSubmit = async ({ periods }) => {
    const hasSettingsChanged = !isEqual(sortBy(periods, 'period'), sortBy(periodSettings, 'period'));

    try {
      await dispatch(startGame(game.id, hasSettingsChanged ? periods : undefined))

      notify('resources.scoresheets.messages.successful_game_start', 'success')
    } catch (e) {
      // catch validation errors caused by the dispatches
      // eslint-disable-next-line no-console
      console.error(e)
      notify('ra.message.error', 'error')
    }

    setModalOpen(false)
    setTimeout(() => refresh(), 500);
  }

  const onCancel = () => {
    setModalOpen(false);
  }

  return <Dialog open={open} fullWidth fullScreen={fullScreen} maxWidth={fullScreen ? "xs" : "sm"}>
    <DialogTitle>{translate('resources.scoresheets.labels.start_game')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.scoresheets.messages.start_game')}
      </DialogContentText>
      <RecordContextProvider value={null}>
        <StartForm
          save={onSubmit}
          game={game}
          component={Fragment}
          toolbar={
            <DialogFormToolbar submitLabel="ra.action.start" cancelLabel="ra.action.cancel" onCancel={onCancel} disableInvalidSubmit />
          }
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}
