import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import {
  Checkbox,
  IconButton,
  Button,
  Popover,
  CardContent,
  CardActions,
  FormLabel,
  TextField,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  RadioGroup,
  Radio,
  Tooltip,
  Select,
  MenuItem,
  Grid,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons'
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

import { FF_BATTERS } from '@hisports/common/featureFlags';
import { editLineupMember, removeLineupExtra } from '@hisports/scoresheet/src/actions';
import { generateId, isPlayer, isSuspensionPurgeable } from '@hisports/scoresheet/src/util';

import { useFlag, useSport } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';

import { useMemberValidationContext } from './MemberValidationContext';
import { useMeta, useScoresheetDispatch } from '../ScoresheetContext';

const POSITIONS = [
  { id: 'F', name: 'resources.members.values.positions.F', type: 'resources.members.labels.player' },
  { id: 'C', name: 'resources.members.values.positions.C', type: 'resources.members.labels.player', sports: ['Hockey'] },
  { id: 'D', name: 'resources.members.values.positions.D', type: 'resources.members.labels.player', sports: ['Hockey'] },
  { id: 'G', name: 'resources.members.values.positions.G', type: 'resources.members.labels.player', sports: ['Hockey'] },
  { id: 'Manager', name: 'resources.members.values.positions.Manager', type: 'resources.members.labels.staff' },
  { id: 'Head Coach', name: 'resources.members.values.positions.Head Coach', type: 'resources.members.labels.staff' },
  { id: 'Assistant Coach', name: 'resources.members.values.positions.Assistant Coach', type: 'resources.members.labels.staff' },
  { id: 'Goaltending Coach', name: 'resources.members.values.positions.Goaltending Coach', type: 'resources.members.labels.staff', sports: ['Hockey', 'Soccer'] },
  { id: 'Safety Person', name: 'resources.members.values.positions.Safety Person', type: 'resources.members.labels.staff' },
  { id: 'Trainer', name: 'resources.members.values.positions.Trainer', type: 'resources.members.labels.staff' },
]

const DEFAULT_SUSPENSION_STATE = {
  game: null,
  total: null,
}

const SUSPENSION_TYPES = {
  NONE: 'None',
  GAME: 'Game',
  ADMINISTRATIVE: 'Admin',
  MANUAL: 'Manual'
}

const parseNumber = value => {
  const number = parseInt(value, 10);
  return !isNaN(number) ? number: undefined;
}

const isValidSuspension = (suspension, suspensionType) => {
  if (suspensionType === SUSPENSION_TYPES.MANUAL) {
    if (isEmpty(suspension)) return false
    if (!parseNumber(suspension.game)) return false;
    if (!parseNumber(suspension.total)) return false;
    if (Number(suspension.game) > Number(suspension.total)) return false;
  }
  return true
}

const initialSuspensionState = (suspension, adminSuspension) => {
  if (suspension) {
    return suspension.id ? SUSPENSION_TYPES.GAME : SUSPENSION_TYPES.MANUAL;
  } else if (adminSuspension) {
    return SUSPENSION_TYPES.ADMINISTRATIVE
  } else {
    return SUSPENSION_TYPES.NONE;
  }
}

const EditMemberPopover = ({ member, isExtra, anchorEl, onClose, teamId, isScorekeeper }) => {
  const translate = useTranslate();
  const sport = useSport();
  const isEnabled = useFlag();
  const game = useRecordContext();
  const { memberValidations } = useMemberValidationContext();

  const memberValidation = memberValidations.find(({ participantId }) => participantId === member.participantId);
  const gameSuspensions = (memberValidation?.suspensions || []).filter(suspension => suspension.type === 'Game');
  const adminSuspension = memberValidation?.suspensions?.find(suspension => suspension.type === 'Admin')

  const [ battingOrder, setBattingOrder ] = useState(member.battingOrder || null)
  const [ number, setNumber ] = useState(member.number || null)
  const [ positions, setPositions ] = useState(member.positions)
  const [ isStarter, setStarter ] = useState(member.isStarter)
  const [ memberStatus, setMemberStatus ] = useState(member.isCaptain ? 'Captain' : member.isAlternate ? 'Alternate' : '')
  const [ suspension, setSuspension ] = useState(member.suspension);
  const [ suspensionType, setSuspensionType ] = useState(initialSuspensionState(suspension, adminSuspension))

  const [ positionsOpen, setPositionsOpen ] = useState(false);

  const playerSelected = isPlayer({ positions })
  const goalieSelected = positions.includes('G')

  const validNumber = !playerSelected || !isEmpty(suspension) || !!number;
  const validBattingOrder = !playerSelected || battingOrder > 0 || battingOrder == null;
  const validPositions = positions.length > 0;
  const validSuspension = isValidSuspension(suspension, suspensionType)
  const isValid = validNumber && validBattingOrder && validPositions && validSuspension;
  const isAutomaticSuspension = !!gameSuspensions.length || adminSuspension;

  const canEditPurge = isAuthorized(game, 'scoresheets', 'full') || isScorekeeper || !isAutomaticSuspension;

  const handlePositions = positions => {
    setPositions(positions);
    setPositionsOpen(false);
  }

  const handleSave = () => {
    if (!isValid) return;
    onClose({
      ...member,
      battingOrder,
      number,
      positions,
      isCaptain: memberStatus === 'Captain',
      isAlternate: memberStatus === 'Alternate',
      isStarter: goalieSelected && isStarter,
      suspension: suspensionType !== SUSPENSION_TYPES.NONE ? suspension : undefined,
    })
  }

  const handleRemove = () => {
    onClose(member, true);
  }

  const handleCancel = () => {
    onClose()
  }

  return <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={() => onClose()}
    PaperProps={{
      style: {
        minWidth: 8 * 42,
        maxWidth: 8 * 42,
      }
    }}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    {playerSelected && isEnabled(FF_BATTERS) && <CardContent>
      <FormLabel>{translate('resources.members.labels.battingOrder')}</FormLabel>
      <TextField
        type="number"
        fullWidth
        autoFocus
        value={battingOrder}
        error={!validBattingOrder}
        onChange={e => setBattingOrder(parseNumber(e.target.value))}
        InputProps={{
          startAdornment: <InputAdornment position="start">#</InputAdornment>,
        }}
        inputProps={{ min: "1" }}
      />
    </CardContent>}

    {playerSelected && <CardContent>
      <FormLabel>{translate('resources.members.labels.jersey')}</FormLabel>
      <TextField
        type="number"
        fullWidth
        autoFocus
        value={number}
        error={!validNumber}
        onChange={e => setNumber(parseNumber(e.target.value))}
        InputProps={{
          startAdornment: <InputAdornment position="start">#</InputAdornment>,
        }}
      />
    </CardContent>}

    <CardContent>
      <FormLabel>{translate('resources.members.fields.positions')}</FormLabel>
      <FormGroup row>
        <Select
          multiple
          fullWidth
          value={positions}
          error={!validPositions}
          open={positionsOpen}
          onOpen={() => setPositionsOpen(true)}
          onClose={() => setPositionsOpen(false)}
          onChange={e => handlePositions(e.target.value)}
        >
          {POSITIONS
            .filter(position => !position.sports?.length || position.sports.includes(sport))
            .map(position =>
              <MenuItem key={position.id} value={position.id}>
                {translate(position.name)}
              </MenuItem>
            )}
        </Select>
      </FormGroup>
    </CardContent>

    {playerSelected && <CardContent>
      <FormLabel>{translate('resources.members.fields.status')}</FormLabel>
      <RadioGroup row value={memberStatus} onChange={e => setMemberStatus(e.target.value)}>
        <FormControlLabel
          control={<Radio color="primary" />}
          label={translate('resources.members.fields.isCaptain')}
          value="Captain"
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          label={translate('resources.members.fields.isAlternate')}
          value="Alternate"
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          label="None"
          value=""
        />
      </RadioGroup>

      {goalieSelected && <FormGroup>
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label={translate('resources.members.labels.starter_goalie')}
          checked={isStarter}
          onChange={() => {
            setStarter(isStarter => !isStarter)
          }}
        />
      </FormGroup>}
    </CardContent>}

    <CardContent>
      <FormGroup>
        <FormLabel>{translate('resources.members.labels.suspension')}</FormLabel>

        {/* Suspension input selector: not suspended, suspended playing, suspensions to purge, administrative, manual */}
        <Select
          fullWidth
          disabled={!canEditPurge}
          value={suspension?.id || suspensionType}
          onChange={e => {
            const { value } = e.target;

            if (value === SUSPENSION_TYPES.NONE) {
              setSuspensionType(SUSPENSION_TYPES.NONE)
            } else if (value === SUSPENSION_TYPES.MANUAL) {
              setSuspensionType(SUSPENSION_TYPES.MANUAL)
            } else if (value === SUSPENSION_TYPES.ADMINISTRATIVE) {
              setSuspensionType(SUSPENSION_TYPES.ADMINISTRATIVE)
            } else {
              setSuspensionType(SUSPENSION_TYPES.GAME)
            }

            const suspensionId = value;
            const selectedGameSuspension = gameSuspensions.find(({ id }) => id === suspensionId);

            if (selectedGameSuspension) {
              if (isSuspensionPurgeable(selectedGameSuspension, teamId)) {
                setSuspension({
                  id: e.target.value,
                  purgeId: generateId(),
                  game: selectedGameSuspension.servedGames + 1,
                  total: selectedGameSuspension.requiredGames
                })
              } else {
                setSuspension({
                  id: e.target.value
                })
              }
            } else {
              setSuspension(DEFAULT_SUSPENSION_STATE)
            }
          }}
        >
          <MenuItem value={SUSPENSION_TYPES.NONE}>
            {gameSuspensions.length > 0 || adminSuspension ? translate('resources.suspensions.messages.playing') : translate('resources.suspensions.labels.not_suspended')}
          </MenuItem>
          {gameSuspensions.map(suspension => {
            const number = `${translate('resources.games.name', 1)} ${suspension.game.number}`
            const date = moment.utc(suspension.game.date).format('LL');
            const name = `${number} (${date})`
            return <MenuItem key={suspension.id} value={suspension.id} disabled={adminSuspension}>
              <Tooltip title={name}>
                <div>{name}</div>
              </Tooltip>
            </MenuItem>
          })}
          {adminSuspension && <MenuItem value={SUSPENSION_TYPES.ADMINISTRATIVE}>
            {translate('resources.suspensions.labels.admin')}
          </MenuItem>}
          <MenuItem value={SUSPENSION_TYPES.MANUAL} disabled={adminSuspension}>
            {translate('resources.suspensions.labels.manual')}
          </MenuItem>
        </Select>

        {/* Suspension manual count input */}
        {!isEmpty(suspension) && suspensionType === SUSPENSION_TYPES.MANUAL && <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              type="number"
              label={translate('resources.members.labels.current_game')}
              disabled={!canEditPurge}
              value={suspension?.game}
              onChange={e => {
                setSuspension({
                  id: undefined,
                  purgeId: undefined,
                  game: parseNumber(e.target.value),
                  total: suspension.total,
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              label={translate('resources.members.labels.total_games')}
              disabled={!canEditPurge}
              value={suspension?.total}
              onChange={e => {
                setSuspension({
                  id: undefined,
                  purgeId: undefined,
                  total: parseNumber(e.target.value),
                  game: suspension.game,
                })
              }}
            />
          </Grid>
        </Grid>}

        {/* Game suspension selected count */}
        {!isEmpty(suspension) && suspensionType === SUSPENSION_TYPES.GAME && suspension.purgeId && <Grid container spacing={2}>
          <Grid item xs={suspension.total != null ? 6 : 5}>
            <Typography variant="body2" color="textSecondary">
              {`${translate('resources.members.labels.current_game')}: ${(suspension.game)}`}
            </Typography>
          </Grid>
          <Grid item xs={suspension.total != null ? 6 : 7}>
            <Typography variant="body2" color="textSecondary">
              {`${translate('resources.members.labels.total_games')}: ${suspension?.total || translate('resources.suspensions.values.durationType.Indefinite')}`}
            </Typography>
          </Grid>
        </Grid>
        }
        {/* Game suspension no purge */}
        {!isEmpty(suspension) && suspensionType === SUSPENSION_TYPES.GAME && !suspension.purgeId && <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              {translate('resources.suspensions.messages.unpurgeable')}
            </Typography>
          </Grid>
        </Grid>}
      </FormGroup>
    </CardContent>

    <CardActions>
      <Button color="primary" onClick={handleSave} disabled={!isValid}>{translate('ra.action.update')}</Button>
      {isExtra && <Button color="error" onClick={handleRemove}>{translate('ra.action.remove')}</Button>}
      <Button onClick={handleCancel}>{translate('ra.action.cancel')}</Button>
    </CardActions>
  </Popover>
}

export const EditMemberButton = ({ teamId, member, isExtra, isScorekeeper, disabled }) => {
  const [ target, setTarget ] = useState(null);
  const translate = useTranslate();

  const { game } = useMeta();
  const dispatch = useScoresheetDispatch();

  const handleClick = e => {
    setTarget(e.currentTarget)
  }

  const handleClose = (member, remove) => {
    if (member) {
      if (!remove) {
        dispatch(editLineupMember(game.id, teamId, member))
      } else if (isExtra) {
        dispatch(removeLineupExtra(game.id, teamId, member.participantId))
      }
    }

    setTarget(null)
  }

  return <>
    <Tooltip title={translate('ra.action.edit')} placement="top" arrow>
      <IconButton edge="end" disabled={disabled} onClick={handleClick}>
        <EditIcon fontSize="small" />
      </IconButton>
    </Tooltip>
    {target && <EditMemberPopover member={member} isExtra={isExtra} anchorEl={target} onClose={handleClose} isScorekeeper={isScorekeeper} teamId={teamId} />}
  </>
}
