import { useState, useEffect } from 'react';

import { apiClient } from '../../http';

export const useTeamEffectiveSettings = (teamId) => {
  const [ settings, setSettings ] = useState({});

  useEffect(() => {
    if (!teamId) {
      setSettings({})
      return;
    }
    apiClient(`/teams/${teamId}/effectiveSettings`)
      .then(res => res.data)
      .then(setSettings);
  }, [ teamId ])

  return settings;
}
