import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { ExternalProviderForm } from './ExternalProviderForm';

const AddExternalProviderDialog = props =>
  <CreateResourceDialog label="resources.externalProviders.actions.add" maxWidth="sm" resource="externalProviders" basePath="/externalProviders" {...props}>
    <ExternalProviderForm />
  </CreateResourceDialog>

export const EditExternalProviderDialog = props =>
  <EditResourceDialog label="resources.externalProviders.labels.edit" maxWidth="sm" resource="externalProviders" basePath="/externalProviders" {...props}>
    <ExternalProviderForm />
  </EditResourceDialog>

export const AddExternalProviderButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.externalProviders.actions.add')}</Button>
    <AddExternalProviderDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const EditExternalProviderButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.externalProviders.actions.edit')}</Button>
    <EditExternalProviderDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}
