import React from 'react';
import { Datagrid, TextField } from 'react-admin';

import DateField from '../../common/fields/DateField';
import { OfficeField } from '../offices/OfficeField';

export const PostGrid = props =>
  <Datagrid size="medium" {...props}>
    <TextField source="title" />
    <DateField source="date" />
    <OfficeField source="officeId" />
  </Datagrid>
