import { useState, useLayoutEffect } from 'react';

const measureSize = element => ({
  height: element?.getBoundingClientRect().height,
  width: element?.getBoundingClientRect().width,
})

export const useElementSize = element => {
  const [size, setSize] = useState(measureSize(element));

  useLayoutEffect(() => {
    if (!element) return;
    setSize(measureSize(element));

    const observer = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        setSize(measureSize(element));
      });
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [ element ]);

  return size;
}
