import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core';

import Toolbar from '../../common/ra/Toolbar';
import { TimezoneEnumInput } from '../../common/inputs/EnumInputs';
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';

const validate = values => {
  const errors = {};

  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.city) errors.city = 'ra.validation.required'
  if (!values.region) errors.region = 'ra.validation.required'
  if (!values.postalCode) errors.postalCode = 'ra.validation.required'
  if (!values.country) errors.country = 'ra.validation.required'
  if (!values.timezone) errors.timezone = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'venues',
  basePath: '/venues',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const VenueForm = props => {
  const [ isSynced, toggle ] = useRecordSynced(props)

  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm toolbar={<Toolbar isSynced={isSynced} />} validate={validate} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} md={4}>
          <TextInput source="name" label="resources.venues.labels.name" autoFocus disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="alias" helperText="resources.surfaces.helpers.alias" {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="address" label="resources.venues.labels.street_address" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="city" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="region" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="postalCode" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="country" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TimezoneEnumInput source="timezone" disabled={isSynced} {...inputProps} />
        </Grid>
      </Grid>
    </SimpleForm>
  </>
}
