import React from 'react';
import { Filter, useRecordContext } from 'react-admin';
import { Card, makeStyles, useMediaQuery } from '@material-ui/core';

import { ParticipantInput } from '../../participants/ParticipantInput';
import { OfficialTransactionTypeInput } from '../../../common/inputs/EnumInputs';
import { List, Pagination } from '../../../common/ra';

import TransactionsExporter from '../../officialTransactions/TransactionsExporter';
import { ScheduleInput } from '../../schedules/ScheduleInput';
import { TransactionBulkActions } from '../../officialTransactions/TransactionBulkActions';
import { GameInput } from '../../games/GameInput';
import { GroupedTransactionView } from '../../officialTransactions/TransactionView';
import AddTransactionsModal from '../AddTransactionsModal';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    marginBottom: '0px'
  },
  listActions: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  }
}))

const TransactionFilters = props => {
  const payPeriod = useRecordContext();

  return <Filter variant="outlined" {...props}>
    <ParticipantInput source="participantId" label="resources.officialTransactions.fields.participantId" multiple variant="outlined" alwaysOn />
    <OfficialTransactionTypeInput source="type" label="resources.officialTransactions.fields.type" multiple variant="outlined" alwaysOn />
    <ScheduleInput source="gameScheduleId" label="resources.officialTransactions.fields.gameScheduleId" variant="outlined" alwaysOn />
    <GameInput source="gameId" label="resources.officialTransactions.fields.gameId" variant="outlined" filter={{ 'transactions.officeId': payPeriod?.officeId }} alwaysOn />
  </Filter>
}

export default ({ ...props }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const payPeriod = useRecordContext();
  const classes = useStyles();

  const initialValues = { officeId: payPeriod?.officeId, payPeriodId: payPeriod?.id };
  const canAdd = payPeriod?.isClosed !== true;

  const actions = [
    canAdd && <AddTransactionsModal />,
    <TransactionsExporter showSummary />
  ].filter(Boolean)

  return <Card>
    <List
      title=" "
      customActions={actions}
      listActionsClass={classes.listActions}
      component={!isMobile ? "div" : undefined}
      filters={<TransactionFilters className={classes.listActions} />}
      filterDefaultValues={{ payPeriodId: payPeriod?.id }}
      perPage={50}
      pagination={<Pagination isMobile={isMobile} rowsPerPageOptions={[50, 100, 250, 500]} />}
      sort={{
        field: ['game.date', 'game.number', 'participant.lastName', 'participant.firstName'],
        order: ['ASC', 'ASC', 'ASC', 'ASC'],
      }}
      bulkActionButtons={canAdd && <TransactionBulkActions />}
      resource="officialTransactions"
      basePath="/officialTransactions"
      {...props}
    >
      <GroupedTransactionView initialValues={initialValues} canAdd={canAdd} hideOffice hidePayPeriod />
    </List>
  </Card>
}
