import React, { useState } from 'react';
import { GET_LIST, useDataProvider, useNotify, useQuery, useRecordContext, useRefresh, useResourceContext, useTranslate } from "react-admin";
import { LinearProgress, Link, Table, TableBody, Typography } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import { GAME_OFFICE_RESOURCES } from '@hisports/common/src/constants';

import { isAuthorized } from '../../common/Authorize';
import InfoCard from '../../common/cards/InfoCard';
import Row from '../../common/cards/TableRow';

import { AddExternalProviderButton, EditExternalProviderDialog } from './ExternalProviderModal';
import { usePermissions } from '../../http';

const TARGET_TYPES = {
  games: 'Game',
  surfaces: 'Surface',
  offices: 'Office',
  leagues: 'Office',
  cups: 'Office',
  tournaments: 'Office',
};

const useExternalProviders = (targetId) => {
  const resource = useResourceContext();
  const permissions = usePermissions();

  const isSystem = permissions.some(p => p.roleType === 'System');
  const targetType = TARGET_TYPES[resource];

  return useQuery({
    type: GET_LIST,
    resource: 'externalProviders',
    payload: {
      filter: { targetType, targetId, ...(!isSystem && { service: { neq: 'Stats' } }) },
    }
  }, {
    enabled: !!targetId,
  });
}

const ExternalProviderField = ({ provider, canEdit }) => {
  const [isOpen, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const link = provider?.link.startsWith('http') ? provider.link : `http://${provider.link}`;

  const handleOpen = () => {
    setOpen(true);
  }

  const handleDelete = () => dataProvider.delete('externalProviders', { id: provider.id })
    .then(() => {
      refresh();
      notify('resources.externalProviders.messages.deleted');
    })


  return <>
    <Row label={`resources.externalProviders.labels.services.${provider.service}`} onClick={canEdit && handleOpen}>
      {provider?.provider && <Typography variant="body2">{provider.provider}</Typography>}
      <Link href={link} target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}>
        {link.split('//')[1]}
      </Link>
    </Row>
    {canEdit && <EditExternalProviderDialog id={provider.id} isOpen={isOpen} handleClose={() => setOpen(false)} onDelete={handleDelete} canDelete={canEdit} />}
  </>
}

const ExternalProviderList = ({ providers, canEdit, isLoading }) => {
  const translate = useTranslate();

  if (isLoading) return <LinearProgress />;

  return <Table>
    <TableBody>
      {!providers?.length
        ? <Row>
          <Typography align="center" variant="body2">{translate("resources.externalProviders.messages.no_results")}</Typography>
        </Row>
        : providers.map(provider => (
          <ExternalProviderField key={provider.id} provider={provider} canEdit={canEdit} />
        ))}
    </TableBody>
  </Table>
}

const ExternalProvidersCard = ({ targetId }) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const { data: providers = [], loading, loaded } = useExternalProviders(targetId);

  const canEditProviders = isAuthorized(record, resource, GAME_OFFICE_RESOURCES.includes(resource) ? 'editProviders' : 'edit');
  const canCreateProviders = isAuthorized(record, resource, GAME_OFFICE_RESOURCES.includes(resource) ? 'createProviders' : 'create');

  const targetType = TARGET_TYPES[resource];
  const showIcon = targetType === 'Office';

  const actions = [
    canCreateProviders && <AddExternalProviderButton key="add" initialValues={{ targetType, targetId }} />
  ].filter(Boolean);

  if (!canCreateProviders && !providers?.length) return null;

  return <InfoCard
    title="resources.externalProviders.name"
    icon={showIcon && <LinkIcon />}
    actions={actions}
    collapsable
    collapseActions
  >
    <ExternalProviderList providers={providers} canEdit={canEditProviders} isLoading={loading && !loaded} />
  </InfoCard>
}

export default ExternalProvidersCard;
