import React from 'react';
import { BooleanInput, useRecordContext } from 'react-admin';
import { AccountBalance } from '@material-ui/icons';

import { useSeason } from '../../../common/inputs/SeasonSelector';
import ListCard from '../../../common/cards/ListCard';

import { AddPayPeriodButton } from '../../payPeriods/PayPeriodModal';
import { PayPeriodGrid } from '../../payPeriods/PayPeriodGrid';

export default ({ ...props }) => {
  const office = useRecordContext();
  const seasonId = useSeason();

  const filters = [
    <BooleanInput source="isClosed" label="resources.payPeriods.filters.isClosed" />
  ]

  return <ListCard
    title="resources.payPeriods.labels.card.title"
    icon={<AccountBalance />}
    reference="payPeriods"
    target="officeId"
    filters={filters}
    filter={{ seasonId }}
    sort={{
      field: ['startDate', 'name'],
      order: ['ASC', 'ASC'],
    }}
    addButton={<AddPayPeriodButton initialValues={{ officeId: office?.id, seasonId }} target="officeId" />}
    {...props}
  >
    <PayPeriodGrid hideOffice />
  </ListCard>
}
