import React from 'react';
import { useTranslate } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

import { List, Show, Create, Edit } from '../../common/ra';
import { useSeason } from '../../common/inputs/SeasonSelector';
import ResourceTitle from '../../common/ResourceTitle';

import { TeamGrid } from './TeamGrid';
import { TeamFilters } from './TeamFilters';
import { TeamFilterList } from './TeamFilterList';
import { TeamShowLayout } from './TeamShow';
import { TeamForm } from './TeamForm';
import { TeamActions } from './TeamActions';

export const TeamList = props => {
  const seasonId = useSeason();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return <List {...props}
    aside={!isSmall && <TeamFilterList />}
    filters={isSmall && <TeamFilters />}
    filterDefaultValues={{ seasonId }}
    sort={{
      field: 'name',
      order: 'ASC'
    }}
    resetFilters={isSmall}
  >
    <TeamGrid rowClick="show" />
  </List>
}

const getTitle = team => team.name

export const TeamShow = props =>
  <Show {...props}
    title={<ResourceTitle title={getTitle} />}
    actions={<TeamActions />}
  >
    <TeamShowLayout />
  </Show>

export const TeamCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.teams.labels.add')} />}
    {...props}
  >
    <TeamForm redirect="show" />
  </Create>
}

export const TeamEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: getTitle(record) })} />}
    {...props}
  >
    <TeamForm redirect="show" />
  </Edit>
}
