import React, { Fragment, useState, useRef } from 'react';
import { SimpleForm, BooleanInput, NumberInput, useNotify, useTranslate, RecordContextProvider } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { Add as AddIcon } from '@material-ui/icons'
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';

import { addLineupExtra } from '@hisports/scoresheet/src/actions';
import { isPlayer } from '@hisports/scoresheet/src/util';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { ResponsiveButton } from '../../../common/ResponsiveButton';
import { MemberPositionsEnumInput } from '../../../common/inputs/EnumInputs';
import { ParticipantInput } from '../../participants/ParticipantInput';
import { useSport } from '../../../http';

import { useMemberValidationContext } from './MemberValidationContext';
import { useMeta, useScoresheetDispatch } from "../ScoresheetContext"

const validate = values => {
  const errors = {};

  if (!values.participantId) errors.participantId = 'ra.validation.required'
  if (!values.positions || !values.positions.length) errors.positions = 'ra.validation.required'

  const number = Number(values.number);
  if (isPlayer(values)) {
    if (values.number == null) {
      errors.number = 'resources.members.validations.must_have_number';
    } else if (isNaN(number) || number < 0) {
      errors.number = 'ra.validation.invalid_number';
    }
  }

  return errors;
}

const PlayerNumberInput = props => {
  const { values } = useFormState();

  if (!isPlayer(values)) return null;
  return <NumberInput {...props} />
}

const ParticipantExtraInput = ({ teamOfficeId, ...props }) => {
  const { values } = useFormState();
  const filter = { _scope: 'Tenant' }

  if (teamOfficeId && values?.isSameOffice) {
    filter['teams.officeId'] = teamOfficeId;
  }

  return <ParticipantInput source="participantId" filter={filter} {...inputProps} />
}

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const ExtraForm = ({ teamOfficeId, ...props }) => {
  const sport = useSport();
  const disabledSameOffice = sport === 'Soccer'

  return <SimpleForm {...props} {...inputProps} validate={validate}>
    <Grid container spacing={1} fullWidth>
      <Grid item xs={12}>
        <ParticipantExtraInput teamOfficeId={teamOfficeId} {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MemberPositionsEnumInput source="positions" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <PlayerNumberInput source="number" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <BooleanInput source="isSameOffice" label="resources.scoresheets.labels.add_extra_same_office" defaultValue={true} disabled={disabledSameOffice} {...inputProps} helperText="" />
      </Grid>
      <Grid item xs={12} md={6}>
        <BooleanInput source="isAffiliate" label="resources.members.fields.isAffiliate" defaultValue={true} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}

const ExtraDialog = ({ teamOfficeId, open, onSave, onClose }) => {
  const translate = useTranslate()
  const handleDiscard = () => {
    onClose();
  }
  const handleSave = values => {
    const result = onSave(values)
    if (result) return result;
    onClose();
  }

  return <Dialog open={open} fullWidth maxWidth="sm" disableBackdropClick>
    <DialogTitle>{translate('resources.scoresheets.labels.add_extra')}</DialogTitle>
    <DialogContent>
      <RecordContextProvider value={null}>
        <ExtraForm
          save={handleSave}
          component={Fragment}
          toolbar={
            <DialogFormToolbar onCancel={handleDiscard} cancelLabel="ra.action.cancel" submitLabel="ra.action.save" />
          }
          teamOfficeId={teamOfficeId}
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

export const AddExtraButton = ({ teamOfficeId, teamId, disabled, hasFullAccess }) => {
  const translate = useTranslate();
  const { game, meta } = useMeta();
  const { extrasEnabled } = meta.policies;
  const { loading } = useMemberValidationContext();

  const [ open, setOpen ] = useState(false);
  const dispatch = useScoresheetDispatch();
  const notify = useNotify();

  const participants = useSelector(state => state.admin.resources.participants.data)
  const participantsRef = useRef(participants);
  participantsRef.current = participants;

  if (!extrasEnabled && !hasFullAccess) return null;

  const handleSave = ({ participantId, positions, number, isAffiliate }) => {
    // useRef is a workaround to get a current version of the state; useCallback doesn't seem to help
    const participant = participantsRef.current[participantId];
    if (!participant) return { [FORM_ERROR]: translate('ra.page.error_try_again') }

    dispatch(addLineupExtra(game.id, teamId, participant, positions, number, isAffiliate))
    notify(translate('resources.members.messages.has_been_added', { name: participant.fullName }))
  }

  return <>
    <ResponsiveButton icon={AddIcon} onClick={() => setOpen(true)} disabled={disabled || loading}>
      {translate('resources.scoresheets.labels.add_extra')}
    </ResponsiveButton>
    <ExtraDialog teamOfficeId={teamOfficeId} open={open} onSave={handleSave} onClose={() => setOpen(false)} />
  </>
}
