import React from 'react';
import { useFormState } from "react-final-form";
import { isArray } from 'lodash';

export const FieldDependency = ({ fieldSource, disabled, children, test = value => value != null }) => {
  const { values } = useFormState();

  if (isArray(fieldSource)) {
    if (fieldSource.every(source => test(values[source]))) {
      if (disabled) return React.cloneElement(children, { ...children.props, disabled });
      return null;
    }
  } else if (!test(values[fieldSource])) {
    if (disabled) return React.cloneElement(children, { ...children.props, disabled });
    return null;
  }

  return children;
}
