import React, { useMemo } from 'react';

import { getPenalties } from '@hisports/scoresheet/src/selectors';

import { useSport, useScopes } from '../../../../http';
import { useMeta, useScoresheet } from '../../ScoresheetContext';
import { useIncidentReports } from '../../gameIncidents/useIncidentReports';
import { useBranchSettings } from '../../../branchOfficeSettings';
import { isAuthorized } from '../../../../common/Authorize';

import IncidentAlert from './IncidentAlert';

export const SummaryAlerts = ({ isAssignedOfficial, isScorekeeper, ...props }) => {
  const { game, meta } = useMeta();
  const sport = useSport();
  const scopes = useScopes();
  const { data: branchSettings } = useBranchSettings(game?.officeId);

  const incidentRequiredPenalties = useScoresheet(scoresheet => getPenalties(scoresheet, meta.infractions, meta.types, sport, game.seasonId, { requireIncidentReport: true }))

  const enabled = branchSettings?.ff_incidents && (isAuthorized(scopes, 'gameIncidents', 'show') || isAssignedOfficial)
  const { data: incidents, loading: incidentsLoading, error: incidentsError } = useIncidentReports(game?.id, enabled);

  const hasPenaltiesWithoutReport = useMemo(() => {
    return !incidentRequiredPenalties.every(penalty => incidents?.some(incident => incident.penaltyId === penalty.id))
  }, [incidents, incidentRequiredPenalties])

  const showIncidentAlert = branchSettings?.ff_incidents && !!incidentRequiredPenalties?.length && (!incidentsLoading && hasPenaltiesWithoutReport) && isAssignedOfficial

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>
    {showIncidentAlert && <IncidentAlert />}
  </>
}
