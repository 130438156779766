import React from 'react';
import { ImageInput as RaImageInput, ImageField, useTranslate } from 'react-admin';

export default props => {
  const translate = useTranslate();
  return <RaImageInput
    accept="image/*"
    multiple={false}
    placeholder={translate('ra.input.file.drop.image')}
    {...props}
  >
    <ImageField source="url" />
  </RaImageInput>
}
