export const GAME_OFFICE_TYPES = [
  'League',
  'Tournament',
  'Cup'
];

export const GAME_OFFICE_RESOURCES = [
  'leagues',
  'tournaments',
  'cups',
]

export const LEAGUE_ROLES = [
  "system",
  "admin",
  "super-admin",
  "director",
  "manager",
  "organizer",
  "association",
  "registrar"
];

export const OFFICIAL_ROLES = [
  "system",
  "admin",
  "super-admin",
  "assigner",
  "assigning-manager",
  "official"
];

export const EXTERNAL_TEAMS_OFFICES = [
  5022, // Soccer
  3572, // Baseball
  5375, // Hockey
  5186, // Ball Hockey
];
