import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Tooltip, Button } from '@material-ui/core';
import { AlternateEmail, Check, Home, LocalHospital, NotificationImportantOutlined, NotificationsActiveOutlined, NotificationsOffOutlined, PhoneAndroidOutlined, Star } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { isAuthorized } from '../../common/Authorize';
import FunctionField from '../../common/fields/FunctionField'

import { ContactDatagrid } from './ContactModal';
import ContactVerifyDialog from './ContactVerifyDialog';

const useStyles = makeStyles({
  thead: {
    display: 'none',
  },
  icons: {
    display: 'flex',
    gap: 8,
    justifyContent: 'flex-end'
  }
})

const useInfoStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    lineHeight: '24px',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  value: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  verified: {
    fontSize: 'inherit',
    verticalAlign: 'text-top',
  },
  notes: {
    fontStyle: 'italic',
    paddingLeft: theme.spacing(4),
    color: theme.palette.text.secondary,
  }
}))

const TypeIcon = ({ type, className }) => {
  if (type === 'Email') return <AlternateEmail className={className} fontSize="small" />
  if (type === 'Mobile') return <PhoneAndroidOutlined className={className} fontSize="small" />
  if (type === 'Home') return <Home className={className} fontSize="small" />
  return null;
}

const numberRegex = /\d+/g;
const parseValue = (value, type) => {
  if (type === 'Email') return value;
  const numbers = value.match(numberRegex).join('');
  if (numbers.length !== 10) return value;
  return `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}-${numbers.substring(6)}`
}

const InfoField = props => {
  const classes = useInfoStyles();
  return <FunctionField {...props} render={contact => {
    const { type, value, notes, isVerified } = contact;
    return <div className={classes.root}>
      <TypeIcon type={type} className={classes.icon} />
      <div className={classes.value}>{parseValue(value, type)} {isVerified && type === 'Email' && <TooltipIcon title="resources.contacts.tooltips.isVerified" icon={<Check htmlColor="green" className={classes.verified} />} />}</div>
      <div className={classes.notes}>{notes}</div>
    </div>
  }} />
}

const TooltipIcon = ({ title, icon }) => {
  const translate = useTranslate();
  return <Tooltip title={translate(title)}>
    {icon}
  </Tooltip>
}


const StatusField = ({ className, ...props }) => {
  return <FunctionField {...props} render={contact => {
    const { type, isPrimary, isEmergency, isNotification, isVerified } = contact;
    const icons = [];
    if (type === 'Email') {
      if (isNotification && isVerified) icons.push(<TooltipIcon title="resources.contacts.tooltips.notificationsActive" icon={<NotificationsActiveOutlined />} />)
      if (!isNotification) icons.push(<TooltipIcon title="resources.contacts.tooltips.notificationsDisabled" icon={<NotificationsOffOutlined htmlColor="gray" />} />)
    }
    if (isEmergency) icons.push(<TooltipIcon title="resources.contacts.tooltips.isEmergency" icon={<LocalHospital htmlColor="red" />} />)
    if (isPrimary) icons.push(<TooltipIcon title="resources.contacts.tooltips.isPrimary" icon={<Star htmlColor="orange" />} />)
    return <div className={className}>{icons}</div>
  }} />
}

const VerifiedField = ({ setDialogContact, ...props }) => {
  return <FunctionField {...props} render={(contact, source, resource, translate) => {
    if (contact.type !== 'Email' || contact.isVerified || !contact.isNotification) return null;

    const canSendVerification = isAuthorized(contact, 'contacts', 'verify');

    const onClick = e => {
      e.preventDefault();
      e.stopPropagation();
      setDialogContact(contact);
    }

    return <Tooltip title={canSendVerification ? translate('ra.action.click_to_verify') : translate('ra.message.unverified')}>
      <Button size="small" onClick={onClick} startIcon={<NotificationImportantOutlined fontSize="small" />}>
        {translate('ra.message.unverified')}
      </Button>
    </Tooltip>
  }} />
}

export const ContactGrid = props => {
  const classes = useStyles();
  const [ dialogContact, setDialogContact ] = useState(null);

  return <>
    <ContactDatagrid size="medium" classes={classes} {...props}>
      <InfoField source="value" />
      <VerifiedField source="isVerified" setDialogContact={setDialogContact} textAlign="right" />
      <StatusField source="isPrimary" textAlign="right" className={classes.icons} />
    </ContactDatagrid>
    <ContactVerifyDialog isOpen={!!dialogContact} contact={dialogContact} onClose={() => setDialogContact(null)} />
  </>
}
