import React from 'react';
import { SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core';

import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput';

import { AccountInput } from '../accounts/AccountInput';
import { ParticipantInput } from '../participants/ParticipantInput';

const validate = values => {
  const errors = {};

  if (!values.accountId) errors.accountId = 'ra.validation.required'
  if (!values.participantId) errors.participantId = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'identities',
  basePath: '/identities',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const IdentityForm = props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <AccountInput source="accountId" disabled {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ParticipantInput source="participantId" {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <SwitchInput
          source="isPrimary"
          allowEmpty
          label="resources.identities.fields.isPrimary"
          helperText="resources.identities.helpers.isPrimary"
          options={{ color: 'secondary' }}
          {...inputProps}
        />
      </Grid>
    </Grid>
  </SimpleForm>
