import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { DraftsOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import { useParticipant } from '../../../http';

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(0.5),
  }
}))

export default () => {
  const translate = useTranslate();
  const participantId = useParticipant();
  const classes = useStyles()
  const isLoading = useSelector(store => store.admin.loading > 0)
  const allContacts = useSelector(store => store.admin.resources.contacts?.data)
  if (isLoading || !allContacts) return null;

  const contacts = Object.keys(allContacts)
    .map(id => allContacts[id])
    .filter(contact => contact.participantId === participantId)

  if (!contacts.length) return <Alert className={classes.alert} severity="warning" icon={<DraftsOutlined />}>
    {translate('resources.contacts.alerts.add_contact_email')}
  </Alert>

  const hasVerifiedContacts = contacts.some(contact => contact.type === 'Email' && contact.isVerified)
  if (hasVerifiedContacts) return null;

  return <Alert className={classes.alert} severity="warning" icon={<DraftsOutlined />}>
    {translate('resources.contacts.alerts.verify_contact_email')}
  </Alert>
}
