import React from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { Button, useMediaQuery } from "@material-ui/core";
import { PrintOutlined as PrintIcon } from '@material-ui/icons'

import { FF_PRINT_LINEUP } from '@hisports/common/featureFlags';

import { useFlag } from '../../../http';
import { useMeta } from "../ScoresheetContext"
import { useMemberValidationContext } from './MemberValidationContext';

export const PrintLineupButton = ({ teamId, disabled, hasFullAccess, type = "lineup", printMessage = "resources.scoresheets.labels.print" }) => {
  const translate = useTranslate();
  const isEnabled = useFlag();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const { game } = useMeta();
  const locale = useLocale();
  const { loading } = useMemberValidationContext();
  const isLineup = type === "lineup"

  const reportUrl = `${process.env.PDF_URL}/game/${game.id}/${type}/${teamId}${locale ? `?locale=${locale}` : ''}`

  if (!isEnabled(FF_PRINT_LINEUP) || game?.isApproved) return null;

  return <Button color={!isLineup ? "primary" : undefined} startIcon={!isLineup ? undefined : <PrintIcon />} href={reportUrl} disabled={disabled || loading} target="_blank" onClick={e => e.stopPropagation()}>
    {!isMobile && translate('resources.scoresheets.labels.print')}
  </Button>
}
