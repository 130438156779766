import React from 'react';

import ListCard from '../../../common/cards/ListCard';

import { ScheduleTeamContactGrid } from '../../contacts/ScheduleTeamContactGrid';

export default () =>
  <ListCard
    title="resources.teams.labels.contacts"
    reference="teamContacts"
    target="schedules"
    sort={{ field: 'id', order: 'ASC' }}
    perPage={999}
    pagination={null}
  >
    <ScheduleTeamContactGrid showGroup />
  </ListCard>
