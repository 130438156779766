import React from 'react';
import { useLocale, useRecordContext } from 'react-admin';
import { Typography, styled } from '@material-ui/core';
import moment from 'moment-timezone';

const Alt = styled('div')(({ theme }) => ({
  color: theme.palette.text.disabled,
}))

const getDate = (value, { timezone, format, yearFormat }) => {
  const date = timezone ? moment.tz(value, timezone) : moment(value)
  if (format === 'calendar') return date.calendar();
  if (moment().isSame(date, 'year')) return date.format(format)
  return date.format(yearFormat)
}

export const getDateFormat = locale => (!locale || locale.startsWith('en')) ? 'MMM D' : 'D MMM'

const DateField = ({ className, source, format, yearFormat = 'll', emptyText, showLocal = false, ...props }) => {
  const locale = useLocale();
  const record = useRecordContext(props)

  const dateFormat = format || getDateFormat(locale);
  let date = emptyText;

  if (record?.[source]) {
    const hasTimezone = record.timezone != null;
    const tzDate = getDate(record[source], { timezone: record.timezone || 'UTC', format: dateFormat, yearFormat });
    const localDate = getDate(record[source], { format: dateFormat, yearFormat })

    date = showLocal && hasTimezone ? localDate : tzDate;
    if (showLocal && hasTimezone && localDate !== tzDate) {
      date = <>
        <div>{tzDate} {moment.tz(record[source], record.timezone).format('z')}</div>
        <Alt>{localDate} {moment.tz(record[source], moment.tz.guess()).format('z')}</Alt>
      </>
    }
  }

  if (!date) return null;

  return <Typography
    component="span"
    variant="body2"
    noWrap
    className={className}
  >
    {date}
  </Typography>
}

DateField.defaultProps = {
  addLabel: true
}

export default DateField;
