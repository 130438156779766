import React from 'react';
import { SimpleForm, TextInput, useNotify, useMutation, useRefresh, CREATE, useTranslate } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import Toolbar from '../../common/ra/Toolbar';
import { useParticipant } from '../../http';

const useStyles = makeStyles(theme => ({
  toolbar: {
    marginTop: theme.spacing(1),
  },
  spacer: {
    height: 0,
  }
}))

const validate = values => {
  const errors = {};
  if (!values.body) errors.body = 'ra.validation.required';
  return errors;
}

const inputProps = {
  resource: 'games',
  basePath: '/games',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const SaveButton = ({ saving, invalid, redirect, handleSubmitWithRedirect }) => {
  const notify = useNotify()
  const translate = useTranslate()

  const onClick = event => {
    if (event) event.preventDefault();
    if (saving) return;
    if (invalid) {
      notify('ra.message.invalid_form', 'warning');
    }
    handleSubmitWithRedirect(redirect)
  }

  return <Button color="primary" startIcon={<Send />} onClick={onClick}>{translate('ra.action.send')}</Button>
}

export const MessageForm = ({ helperText, ...props }) => {
  const participantId = useParticipant();
  const classes = useStyles();
  const [ send ] = useMutation()
  const refresh = useRefresh()
  const notify = useNotify();
  const translate = useTranslate();

  if (!participantId) return null;

  const submit = data => {
    send({
      type: CREATE,
      resource: 'gameMessages',
      payload: { data }
    }, {
      onSuccess() {
        refresh()
      },
      onFailure() {
        notify('resources.gameMessages.notifications.send_messages_error', 'error');
      }
    })
  }

  const toolbar = <Toolbar classes={classes} width="lg">
    <SaveButton />
  </Toolbar>

  return <SimpleForm toolbar={toolbar} validate={validate} save={submit} {...props} keepDirtyOnReinitialize={false}>
    <TextInput source="body" multiline rows="2" {...inputProps} label={translate('resources.gameMessages.name', 1)} helperText={helperText} />
  </SimpleForm>
}
