import React from 'react';
import { EditButton, ShowButton, useEditContext, useNotify, useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { ListSubheader, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { Sync } from '@material-ui/icons';

import { apiClient } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { TopToolbar } from '../../common/cards/Card';
import { ActionMenu, ActionMenuItem } from '../../common/ActionMenu';

const SyncButton = ({ teamId }) => {
  const theme = useTheme()
  const notify = useNotify();
  const translate = useTranslate();
  const purple = theme.palette.tertiary.main;

  const handleClick = () => {
    notify('Syncing...', { autoHideDuration: 3 * 1000 }) // 3s
    apiClient(`/teams/${teamId}/sync`, { method: 'POST' })
  }
  return <ActionMenuItem size="small" onClick={handleClick}>
    <ListItemIcon><Sync htmlColor={purple} /></ListItemIcon>
    <ListItemText>{translate(`resources.teams.actions.sync`)}</ListItemText>
  </ActionMenuItem>
}

const TeamInternalActions = () => {
  const translate = useTranslate();
  const team = useRecordContext();
  if (!team || team.externalId == null) return null;

  return <>
    <ListSubheader>{translate('resources.teams.actions.internal')}</ListSubheader>
    <SyncButton teamId={team.id} />
  </>
}

export const TeamActions = ({ hasEdit, hasShow, ...props }) => {
  const resource = useResourceContext(props)
  const { basePath, record: team } = useEditContext(props);

  const canEditResource = isAuthorized(team, resource, 'edit');
  const canShowResource = isAuthorized(team, resource, 'show');
  const showInternalActions = isAuthorized(team, 'teams', 'internal', true);

  return <TopToolbar>
    {hasEdit && canEditResource && <EditButton basePath={basePath} resource={resource} record={team} />}
    {hasShow && canShowResource && <ShowButton basePath={basePath} resource={resource} record={team} />}
    {showInternalActions && <ActionMenu>
      <TeamInternalActions />
    </ActionMenu>}
  </TopToolbar>
}
