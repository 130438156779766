import { useState, useEffect, useCallback } from 'react';

import apiClient from './apiClient';
import { parseResponse } from './restClient/response';

export default (url, initialState = null, enabled = true) => {
  const [ data, setData ] = useState(initialState);
  const [ loaded, setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(null)
  // innerVersion is use to force a refetch
  const [innerVersion, setInnerVersion] = useState(0);

  const refetch = useCallback(() => {
    setInnerVersion(prevInnerVersion => prevInnerVersion + 1);
  }, []);

  useEffect(() => {
    if (!enabled) return;
    if (!url) {
      setLoading(false)
      setData(null);
      return;
    }
    setLoading(true)
    setError(null);
    apiClient(url)
      .then(({ data, headers }) => parseResponse(null, data, headers))
      .then(data => {
        setData(data)
        setLoaded(true);
        setLoading(false);
      }, e => {
        setError(e);
        setLoading(false);
      })
  }, [ url, enabled, innerVersion ])

  return { data, loaded, loading, error, refetch };
}
