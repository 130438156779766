import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { OfficeActions } from '../offices/OfficeActions';
import { OfficeShowHeader } from '../offices';

import { TournamentFilters } from './TournamentFilters';
import { TournamentView } from './TournamentView';
import { TournamentShowLayout } from './TournamentShow';
import { TournamentForm } from './TournamentForm';

export const TournamentList = props =>
  <List {...props}
    resource="offices"
    component="div"
    filters={<TournamentFilters />}
    filter={{ type: 'Tournament' }}
    sort={{
      field: ['parent.name', 'name'],
      order: ['ASC', 'ASC'],
    }}
  >
    <TournamentView rowClick="show" />
  </List>

export const TournamentShow = props => {
  const location = useLocation();
  const officeTab = useMemo(() => {
    return location?.pathname?.substring(location.pathname.lastIndexOf('/') + 1)
  }, [ location.pathname ])

  return <Show {...props}
    title={<OfficeShowHeader />}
    style={{ maxWidth: officeTab === 'scheduler' ? 'none' : undefined }}
    actions={<OfficeActions />}
  >
    <TournamentShowLayout />
  </Show>
}

export const TournamentCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.tournaments.labels.add')} />}
    {...props}
  >
    <TournamentForm redirect="show" initialValues={{ type: 'Tournament' }} hideType />
  </Create>
}

export const TournamentEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('resources.tournaments.labels.editing', { name: record.name })} />}
    {...props}
  >
    <TournamentForm redirect="show" />
  </Edit>
}
