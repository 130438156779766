import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { InsertDriveFileOutlined } from '@material-ui/icons';

import { isAuthorized } from '../../../common/Authorize';
import { useScopes } from '../../../http';

import ListCard from '../../../common/cards/ListCard';
import { AddGameDocumentButton } from '../../gameDocuments/GameDocumentModal';
import { GameDocumentGrid } from '../../gameDocuments/GameDocumentGrid';

export default props => {
  const game = useRecordContext(props);
  const translate = useTranslate();
  const scopes = useScopes();

  const canManageGameDocuments = !game?.isCertified || scopes.includes('scoresheets:manage');
  const canAddGameDocuments = isAuthorized(game, 'gameDocuments', 'create') && canManageGameDocuments;

  return <ListCard
    title={translate("resources.gameDocuments.name", 2)}
    icon={<InsertDriveFileOutlined fontSize="small" />}
    reference="gameDocuments"
    target="games"
    addButton={canAddGameDocuments && <AddGameDocumentButton initialValues={{ gameId: game.id }} />}
  >
    <GameDocumentGrid canManageGameDocuments={canManageGameDocuments} />
  </ListCard>
}

