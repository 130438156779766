import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import {
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Tooltip,
  Button,
  Typography,
  IconButton,
  makeStyles,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core'
import { Add, Close, ErrorOutline } from '@material-ui/icons'

import { useSavedSearches, getFilterValue } from './useSavedSearches';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}))

const SearchListItem = ({ search, onClick, onRemove }) =>
  <ListItem button onClick={onClick}>
    <ListItemText primary={search.name} />
    <ListItemSecondaryAction>
      <Tooltip title="Remove">
        <IconButton size="small" onClick={onRemove}>
          <Close fontSize="small" />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  </ListItem>

const AddSearchButton = ({ disabled, onClick }) => {
  const translate = useTranslate();

  return <Tooltip title={translate('components.search.save')}>
    <IconButton disabled={disabled} onClick={onClick} edge="end">
      <Add />
    </IconButton>
  </Tooltip>
}

const AddSearchDialog = ({ isOpen, onClose }) => {
  const [ name, setName ] = useState('');
  const translate = useTranslate();

  const handleClose = name => {
    onClose(name)
    setName('')
  }

  return <Dialog open={isOpen} maxWidth="xs" fullWidth>
    <DialogTitle>{translate('components.search.save')}</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label={translate('ra.input.field.name')}
        type="text"
        fullWidth
        value={name}
        onChange={e => setName(e.target.value)}
        helperText={translate('components.search.specify_name')}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleClose()}>{translate('ra.action.cancel')}</Button>
      <Button color="primary" onClick={() => handleClose(name)} disabled={!name}>{translate('ra.action.save')}</Button>
    </DialogActions>
  </Dialog>
}

const RemoveSearchDialog = ({ search, onClose }) => {
  const translate = useTranslate();

  if (!search) return null;
  return <Dialog open maxWidth="xs" fullWidth>
    <DialogTitle>{translate('components.search.remove')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('ra.message.delete_content', { name: search.name })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose()}>{translate('ra.action.cancel')}</Button>
      <Button onClick={() => onClose(search)}>{translate('ra.action.remove')}</Button>
    </DialogActions>
  </Dialog>
}

export const SavedSearches = ({ ignoredFilters = [] }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [ isAddDisabled, setAddDisabled ] = useState(true);
  const [ isAddOpen, setAddOpen ] = useState(false);
  const [ remove, setRemove ] = useState(null);

  const { searches, filterValues, saveSearch, removeSearch, applySearch, loading, error } = useSavedSearches(ignoredFilters);

  useEffect(() => {
    const value = getFilterValue(filterValues, ignoredFilters)
    setAddDisabled(!Object.keys(value).length)
  }, [ filterValues, ignoredFilters ])

  const handleAdd = () => {
    setAddOpen(true)
  }

  const handleAddClose = name => {
    if (name) saveSearch(name);
    setAddOpen(false);
  }

  const handleRemove = search => {
    setRemove(search)
  }

  const handleRemoveClose = search => {
    if (search) removeSearch(search);
    setRemove(null)
  }

  let action = null;
  if (error) {
    action = <Tooltip title={translate('components.search.loading_error')}><ErrorOutline /></Tooltip>
  } else if (loading) {
    action = <Tooltip title={translate('ra.page.loading')}><CircularProgress size={14} color="secondary" /></Tooltip>
  } else {
    action = <AddSearchButton disabled={isAddDisabled} onClick={handleAdd} />
  }

  return <>
    <div className={classes.header}>
      <Typography variant="overline">{translate('components.search.title')}</Typography>
      {action}
    </div>
    <List dense disablePadding>
      {searches.map(search =>
        <SearchListItem
          key={search.id}
          search={search}
          onClick={() => applySearch(search)}
          onRemove={() => handleRemove(search)}
        />)}
    </List>
    <AddSearchDialog isOpen={isAddOpen} onClose={handleAddClose} />
    <RemoveSearchDialog search={remove} onClose={handleRemoveClose} />
  </>
}
