import React, { Fragment } from 'react';
import { RecordContextProvider, SimpleForm, useNotify, useRecordContext, useTranslate } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, Grid, useMediaQuery } from "@material-ui/core";
import { isEqual, sortBy } from 'lodash';

import { getPeriods } from '@hisports/scoresheet/src/selectors';
import { updateSettings } from '@hisports/scoresheet/src/actions';

import { DialogFormToolbar } from "../../../common/dialogs/DialogForm";

import { GameClockTimeInput } from '../components/GameTimeInput';
import { useScoresheet, useScoresheetDispatch } from '../ScoresheetContext';

const inputProps = {
  variant: 'outlined',
  size: 'small',
  margin: 'dense',
  fullWidth: true,
}

const validate = values => {
  const { minutes, seconds } = values || {};

  if ((minutes == null || minutes === '') && (seconds == null || seconds === '')) {
    return { message: 'ra.validation.required' }
  }
}

export const HockeyPeriodSettings = ({ periods, className, ...props }) => {
  if (!periods?.length) return null;

  return <Grid container spacing={2} className={className}>
    {periods?.map((period, i) =>
      <Grid item md={3}>
        <GameClockTimeInput
          source={`periods[${i}]`}
          label={`resources.scoresheets.labels.periods.${period.period}`}
          initialValue={period}
          validate={validate}
          {...inputProps}
        />
      </Grid>
    )}
  </Grid>
}

const PeriodsForm = ({ ...props }) => {
  const periods = useScoresheet(scoresheet => getPeriods(scoresheet)) || [];

  return <SimpleForm {...props}>
    <HockeyPeriodSettings periods={periods} />
  </SimpleForm>
}

export const PeriodsModal = ({ open, setOpen, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const translate = useTranslate();
  const notify = useNotify();
  const game = useRecordContext();
  const dispatch = useScoresheetDispatch();
  const periodSettings = useScoresheet(scoresheet => getPeriods(scoresheet)) || [];

  const onSubmit = async ({ periods }) => {
    const hasSettingsChanged = !isEqual(sortBy(periods, 'period'), sortBy(periodSettings, 'period'));

    try {
      if (hasSettingsChanged) {
        await dispatch(updateSettings(game.id, { periods }))
      }

      notify('resources.scoresheets.messages.successful_update_settings', 'success')
    } catch (e) {
      // catch validation errors caused by the dispatches
      // eslint-disable-next-line no-console
      console.error(e)
      notify('ra.message.error', 'error')
    }

    setOpen(false);
  }
  const onCancel = () => setOpen(false);

  return <Dialog open={open} maxWidth="sm" fullScreen={fullScreen}>
    <DialogTitle>{translate('resources.scoresheets.labels.period_settings')}</DialogTitle>
    <DialogContent>
      <RecordContextProvider value={null}>
        <PeriodsForm
          save={onSubmit}
          component={Fragment}
          toolbar={
            <DialogFormToolbar
              submitLabel="ra.action.save"
              cancelLabel="ra.action.cancel"
              onCancel={onCancel}
            />
          }
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}
