import React, { memo, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, List, ListSubheader, makeStyles, Popover, Table, TableBody, Typography } from '@material-ui/core';
import { RecordContextProvider, ResourceContextProvider, useRecordContext, useTranslate, TextField, useNotify, useResourceContext } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Check, Clear, Launch, Close } from '@material-ui/icons';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import { isAuthorized } from '../Authorize';
import Row from '../../common/cards/TableRow';
import InfoCard from '../cards/InfoCard';
import { CALENDAR_VIEWS, useCalendarView, useIsHomeFirst } from '../../resources/events/EventViewSettings';
import TimeRangeField from '../../common/fields/TimeRangeField';
import TextArrayField from '../../common/fields/TextArrayField';
import FunctionField from '../fields/FunctionField';
import UserTextField from '../fields/UserTextField';
import { OfficeField } from '../../resources/offices/OfficeField';
import { OfficesField } from '../../resources/offices/OfficesField';
import { SurfaceField } from '../../resources/surfaces/SurfaceField';
import { TeamField } from '../../resources/teams/TeamField';
import { TeamsField } from '../../resources/teams/TeamsField';
import { GroupField } from '../../resources/groups/GroupField';
import { CategoryField } from '../../resources/categories/CategoryField';
import { ScheduleField } from '../../resources/schedules/ScheduleField';
import { GameStatusField } from '../../resources/games/cards/GameCard';
import { TargetField } from '../../resources/targets/TargetField';
import { GameTitle } from '../../resources/drafts/dialogs/GameDialog';
import { CategoriesField } from '../../resources/categories/CategoriesField';
import { SeasonField } from '../../resources/seasons/SeasonField';
import { AvailabilityList, SlotItem, isSlotInUse } from '../../resources/games/GameAvailabilityInfo';
import { PracticeAvailabilityContextProvider } from '../../resources/practices/PracticeAvailabilityContext';
import { GameAvailabilityContextProvider } from '../../resources/games/GameAvailabilityContext';
import { rowClick } from '../../resources/events/EventGrid';
import { UpdateGameAlert } from '../../resources/draftgames/DraftGameForm';
import { useSlotsInUse } from '../../resources/arenaslots/useSlotsInUse';

import { useDragContext } from './DragContext';
import { useCalendarContext } from './CalendarContext';
import { useSchedulingContext } from './SchedulingContext';
import { isDraftGameType, isPractice } from './EventDetails';
import { EventChangesForm, validateEvent } from './DragAndDropGrid';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: theme.spacing(24),
  },
  root: {
    margin: theme.spacing(1),
    minWidth: theme.spacing(50),
  },
  row: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    borderBottom: 'none',
  },
  icon: {
    verticalAlign: 'bottom',
  },
  note: {
    color: theme.palette.grey[700],
    fontStyle: 'italic',
  },
  availabilityList: {
    paddingInline: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  slotLoad: {
    minWidth: theme.spacing(24)
  }
}));

const LaunchButton = props => {
  const record = useRecordContext(props);

  const showLink = rowClick(record.id, null, record)

  if (!showLink) return null;

  return <div>
    <Link to={showLink}>
      <IconButton color="primary" size="small">
        <Launch fontSize="medium" />
      </IconButton>
    </Link>
  </div>
}

const ExitButton = ({ handleClose }) =>
  <IconButton size="small" onClick={handleClose}>
    <Close fontSize="medium" />
  </IconButton>

const EventDetailsPopover = ({ children, popoverContent, width, popRight, disableCloseOnBlur = false }) => {
  const { dragging } = useDragContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (dragging) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBlur = () => {
    if (disableCloseOnBlur) return; // prevents close when opening dialog within popover
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // hardcoded className only used as identifier when inspecting the dom
  return <div className="popover-wrapper">
    <div className="popover-item">
      {children(handleClick)}
    </div>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      onBlur={handleBlur}
      anchorOrigin={{
        vertical: 'center',
        horizontal: popRight ? 'right' : 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: popRight ? 'left' : 'right',
      }}
      PaperProps={{
        style: { width }
      }}
    >
      {popoverContent(handleClose)}
    </Popover>
  </div>
}

const ConfirmDialog = ({ event, open = false, handleCancel, handleConfirm, previousEvent }) => {
  const { disableAuth } = useCalendarContext();
  const translate = useTranslate();
  const { resource: schedulingResource } = useSchedulingContext();
  const resource = useResourceContext();

  if (!event || !disableAuth) return null;

  const eventResource = event?.resource || schedulingResource || resource;
  const canEditEvent = event?.id ? isAuthorized(event, eventResource, 'edit') : true;

  const AvailabilityContextProvider = isPractice(event) ? PracticeAvailabilityContextProvider : GameAvailabilityContextProvider;

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.games.labels.confirm_changes')}</DialogTitle>
    <DialogContent>
      {!canEditEvent && <Alert severity="warning">{translate('resources.games.alerts.no_edit_permission')}</Alert>}
      <RecordContextProvider value={event}>
        <AvailabilityContextProvider>
          <EventChangesForm previousEvent={previousEvent} handleCancel={handleCancel} handleConfirm={handleConfirm} canEditEvent={canEditEvent} />
        </AvailabilityContextProvider>
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

const SlotsList = ({ slots = [], handleClose, readOnly }) => {
  const translate = useTranslate();
  const notify = useNotify()
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const { selectedGame, setSelectedGame, onSave, schedule } = useSchedulingContext();
  const { data: slotsInUse, loading, error } = useSlotsInUse(slots.map(slot => slot.id))

  const { date, startTime, endTime, arenaId } = selectedSlot || {};
  const updatedEvent = { ...selectedGame, date, startTime, endTime, arenaId };

  const handleClick = async () => {
    if (!onSave || !selectedGame) return;

    const error = validateEvent(updatedEvent, schedule, translate);
    if (error) {
      return notify(error, 'error');
    }

    await onSave(updatedEvent);
    setSelectedGame(updatedEvent);
    handleClose();
  };

  const handleSelect = (slot) => {
    setSelectedSlot(slot);
    setOpen(true);
  }

  if (!slots?.length) return null;
  return <List dense disablePadding>
    <ListSubheader disableGutters>{translate('resources.availabilities.labels.slots')}</ListSubheader>
    {loading ?
      <LinearProgress className={classes.slotLoad} />
      :
      slots.map(slot => <RecordContextProvider key={slot.id} value={slot}>
        <SlotItem isSelected={false} handleChange={() => handleSelect(slot)} readOnly={readOnly} isInUse={isSlotInUse(slotsInUse, slot.id)} />
      </RecordContextProvider>)
    }
    <ConfirmDialog
      event={updatedEvent}
      open={open}
      handleCancel={() => setOpen(false)}
      handleConfirm={handleClick}
      previousEvent={selectedGame}
    />
  </List>
}

const SlotDetails = ({ handleClose }) => {
  const { hideArena, includeVenue } = useCalendarContext();
  const translate = useTranslate();
  const slot = useRecordContext();
  const classes = useStyles();

  const { date, categoryIds, types, leagueIds } = slot;
  const headerActions = [
    <ExitButton handleClose={handleClose} />
  ].filter(Boolean);

  return <ResourceContextProvider value="arenaslots">
    <InfoCard
      title={`${translate('resources.arenaslots.name', 1)} - ${moment(date).format('ll')}`}
      headerAction={headerActions}
    >
      <Table className={classes.root}>
        <TableBody>
          <Row cellClass={classes.row}>
            <TimeRangeField startSource="startTime" endSource="endTime" label="ra.date.time" />
          </Row>
          {!hideArena && <Row cellClass={classes.row}>
            <SurfaceField source="arenaId" includeAttributes="full" includeCity="full" includeVenue={includeVenue} />
          </Row>}
          <Row cellClass={classes.row}>
            <OfficeField source="officeId" />
          </Row>
          <Row hidden={!categoryIds} label="resources.arenaslots.fields.categoryIds" cellClass={classes.row}>
            <CategoriesField source="categoryIds" />
          </Row>
          <Row hidden={!types} label="resources.arenaslots.fields.types" cellClass={classes.row}>
            <TextArrayField source="types" />
          </Row>
          <Row hidden={!leagueIds} label="resources.arenaslots.fields.leagueIds" cellClass={classes.row}>
            <OfficesField source="leagueIds" />
          </Row>
        </TableBody>
      </Table>
    </InfoCard>
  </ResourceContextProvider>
}

const AvailabilityDetailsList = ({ slots, availabilities, handleClose }) => {
  const classes = useStyles();
  const [ view ] = useCalendarView();

  const headerActions = [
    <ExitButton handleClose={handleClose} />
  ]

  const available = availabilities.filter(availability => availability.isAvailable);
  const unavailable = availabilities.filter(availability => !availability.isAvailable);

  return <InfoCard
    title={moment(availabilities?.[0]?.date || slots?.[0]?.date).format('ll')}
    headerAction={headerActions}
  >
    <div className={classes.availabilityList}>
      <SlotsList slots={slots} readOnly={view === CALENDAR_VIEWS.SEASON} />
      <AvailabilityList unavailabilities={unavailable} availabilities={available} />
    </div>
  </InfoCard>
}

const AvailabilityDetails = ({ handleClose }) => {
  const { hideArena, includeVenue } = useCalendarContext();
  const translate = useTranslate();
  const availability = useRecordContext();
  const classes = useStyles();

  const { date, overlaps } = availability;
  const availabilities = overlaps || [availability];
  const headerActions = [
    <ExitButton handleClose={handleClose} />
  ].filter(Boolean);

  return <ResourceContextProvider value="availabilities">
    <InfoCard
      title={`${translate('resources.availabilities.name', availabilities.length)} - ${moment(date).format('ll')}`}
      headerAction={headerActions}
    >
      <Table className={classes.root}>
        <TableBody>
          {availabilities.map((overlappingAvailability, i) => {
            const { isAvailable, targetType } = overlappingAvailability;
            return <RecordContextProvider value={overlappingAvailability}>
              {!hideArena && <Row cellClass={classes.row}>
                <TargetField TargetField source="targetId" includeSeason={false} link includeVenue={includeVenue} />
              </Row>}
              <Row cellClass={classes.row}>
                <TimeRangeField startSource="startTime" endSource="endTime" label="ra.date.time" />
              </Row>
              {targetType === 'Surface' && <Row cellClass={classes.row}>
                <>
                  <OfficeField source="officeId" />
                  {overlappingAvailability?.isPrimary && <Typography variant="body2" display="inline" color="textSecondary"> ({translate('resources.arenaoffices.fields.isPrimary')})</Typography>}
                </>
              </Row>}
              {targetType === 'Surface' && <Row cellClass={classes.row}>
                <SurfaceField source="targetId" includeAttributes="full" includeCity="full" includeVenue={includeVenue} />
              </Row>}
              <Row cellClass={classes.row}>
                {translate(`resources.availabilities.values.isAvailable.${isAvailable ? 'available' : 'notAvailable'}`)}
                {' '}
                {isAvailable ? <Check fontSize="small" className={classes.icon} /> : <Clear fontSize="small" className={classes.icon} />}
              </Row>
              <Row cellClass={classes.row} hidden={!overlappingAvailability?.notes}>
                <FunctionField source="notes" render={(availability) => {
                  return <Typography variant="body2" className={classes.note}>{availability?.notes}</Typography>;
                }} />
              </Row>
              {i + 1 !== availabilities.length &&
              <Row cellClass={classes.row}>
                <Divider />
              </Row>}
            </RecordContextProvider>
          })}
        </TableBody>
      </Table>
    </InfoCard>
  </ResourceContextProvider>
}

const ActivityDetails = ({ handleClose, disableLaunch, ...props }) => {
  const translate = useTranslate();
  const activity = useRecordContext();
  const classes = useStyles();

  const { type, name, comments } = activity;
  const headerActions = [
    !disableLaunch && <LaunchButton />,
    <ExitButton handleClose={handleClose} />,
  ].filter(Boolean);

  return <ResourceContextProvider value="activities">
    <InfoCard
      title={`${translate(`resources.activities.values.type.${type}`, { _: type })}${name ? `: ${name}` : ''}`}
      headerAction={headerActions}
    >
      <Table className={classes.root}>
        <TableBody>
          <Row label="resources.activities.fields.teamId" cellClass={classes.row}>
            <TeamField source="teamId" />
          </Row>
          <Row label="ra.date.name" cellClass={classes.row}>
            <TimeRangeField startSource="startTime" endSource="endTime" showDate showLocal />
          </Row>
          <Row label="resources.activities.fields.location" cellClass={classes.row}>
            <TextField {...props} source="location" />
          </Row>
          <Row hidden={!comments} label="resources.activities.fields.comments" cellClass={classes.row}>
            <UserTextField source="comments" />
          </Row>
        </TableBody>
      </Table>
    </InfoCard>
  </ResourceContextProvider>
}

const PracticeDetails = ({ handleClose, disableLaunch }) => {
  const translate = useTranslate();
  const practice = useRecordContext();
  const classes = useStyles();
  const { includeVenue } = useCalendarContext();

  const headerActions = [
    !disableLaunch && <LaunchButton />,
    <ExitButton handleClose={handleClose} />,
  ].filter(Boolean);

  return <ResourceContextProvider value="practices">
    <InfoCard
      title="resources.practices.name"
      headerAction={headerActions}
    >
      <Table className={classes.root}>
        <TableBody>
          <Row label="resources.practices.fields.teamIds" cellClass={classes.row}>
            <TeamsField source="teamIds" />
          </Row>
          <Row label="ra.date.name" cellClass={classes.row}>
            <TimeRangeField startSource="startTime" endSource="endTime" showDate showLocal />
          </Row>
          <Row label="resources.practices.fields.arenaId" cellClass={classes.row}>
            <SurfaceField source="arenaId" includeAttributes="full" includeCity="full" includeVenue={includeVenue} />
          </Row>
          <Row label="resources.practices.fields.status" cellClass={classes.row}>
            <FunctionField source="status" render={({ status }) => translate(`resources.games.values.status.${status}`, { _: status })} />
          </Row>
          <Row label="resources.practices.fields.comments" hidden={!practice?.comments} cellClass={classes.row}>
            <UserTextField source="comments" />
          </Row>
        </TableBody>
      </Table>
    </InfoCard>
  </ResourceContextProvider>
}

const GameDetails = ({ handleClose, isEditable, disableLaunch }) => {
  const game = useRecordContext();
  const { popoverActions, includeVenue } = useCalendarContext();
  const isHomeFirst = useIsHomeFirst();
  const classes = useStyles();
  const translate = useTranslate();

  const isDraft = game?.draftId || isDraftGameType(game?.type);

  const title = isDraft ? <GameTitle game={game} isDraft /> : `${translate('resources.games.name', 1)} ${game?.number}`;
  const customActions = isEditable === false ? [!disableLaunch && <LaunchButton />] : popoverActions;
  const headerActions = [
    ...(customActions || [!disableLaunch && <LaunchButton />]),
    <ExitButton handleClose={handleClose} />,
  ].filter(Boolean);

  const homeTeam = <Row label="resources.games.fields.homeTeamId" cellClass={classes.row}>
    <TeamField source="homeTeamId" authMethod="gameTeams" includeId="full" includeCategory="full" />
  </Row>
  const awayTeam = <Row label="resources.games.fields.awayTeamId" cellClass={classes.row}>
    <TeamField source="awayTeamId" authMethod="gameTeams" includeId="full" includeCategory="full" />
  </Row>

  return <ResourceContextProvider value="games">
    <InfoCard
      title={title}
      headerAction={headerActions}
      smallActionBtns
    >
      {isDraft && game?.updatedGameId && <UpdateGameAlert updatedGameId={game?.updatedGameId} square />}
      <Table className={classes.root}>
        <TableBody>
          <Row label="resources.games.fields.scheduleId" cellClass={classes.row}>
            <ScheduleField source="scheduleId" includeCategory="full" includeType="full" />
          </Row>
          <Row label="resources.games.fields.groupId" hidden={!game?.groupId} cellClass={classes.row}>
            <GroupField source="groupId" />
          </Row>
          <Row label="resources.games.fields.crossScheduleId" hidden={!game?.crossScheduleId} cellClass={classes.row}>
            <ScheduleField source="crossScheduleId" includeCategory="full" includeType="full" />
          </Row>
          <Row label="resources.games.fields.crossGroupId" hidden={!game?.crossGroupId} cellClass={classes.row}>
            <GroupField source="crossGroupId" />
          </Row>
          <Row label="resources.games.labels.time" cellClass={classes.row}>
            <TimeRangeField startSource="startTime" endSource="endTime" showDate showLocal />
          </Row>
          {isHomeFirst ? <>{homeTeam}{awayTeam}</> : <>{awayTeam}{homeTeam}</>}
          <Row label="resources.games.fields.arenaId" cellClass={classes.row}>
            <SurfaceField source="arenaId" includeAttributes="full" includeCity="full" includeVenue={includeVenue} />
          </Row>
          <Row label="resources.games.fields.categoryId" hidden={!game?.categoryId} cellClass={classes.row}>
            <CategoryField source="categoryId" />
          </Row>
          <Row label="resources.games.fields.seasonId" hidden={!game?.seasonId} cellClass={classes.row}>
            <SeasonField source="seasonId" />
          </Row>
          <Row label="resources.games.fields.status" hidden={!game?.status} cellClass={classes.row}>
            <GameStatusField source="status" />
          </Row>
          <Row label="resources.games.fields.comments" hidden={!game?.comments} cellClass={classes.row}>
            <UserTextField source="comments" />
          </Row>
        </TableBody>
      </Table>
    </InfoCard>
  </ResourceContextProvider>
}

export const AvailabilityDetailsListPopover = memo(({ children, slots = [], availabilities = [], ...props }) => {
  return <EventDetailsPopover disableCloseOnBlur popoverContent={(handleClose) => <AvailabilityDetailsList handleClose={handleClose} slots={slots} availabilities={availabilities} />} {...props}>
    {children}
  </EventDetailsPopover>
})

export const AvailableSlotsPopover = memo(({ children, slots, ...props }) => {
  return <EventDetailsPopover disableCloseOnBlur popoverContent={(handleClose) => <SlotsList handleClose={handleClose} slots={slots} {...props} />} {...props}>
    {children}
  </EventDetailsPopover>
})

export const SlotDetailsPopover = memo(({ children, ...props }) => {
  return <EventDetailsPopover popoverContent={(handleClose) => <SlotDetails handleClose={handleClose} {...props} />} {...props}>
    {children}
  </EventDetailsPopover>
})

export const AvailabilityDetailsPopover = memo(({ children, ...props }) => {
  return <EventDetailsPopover popoverContent={(handleClose) => <AvailabilityDetails handleClose={handleClose} {...props} />} {...props}>
    {children}
  </EventDetailsPopover>
})

export const ActivityDetailsPopover = memo(({ children, ...props }) => {
  return <EventDetailsPopover popoverContent={(handleClose) => <ActivityDetails handleClose={handleClose} {...props} />} {...props}>
    {children}
  </EventDetailsPopover>
})

export const PracticeDetailsPopover = memo(({ children, ...props }) => {
  return <EventDetailsPopover popoverContent={(handleClose) => <PracticeDetails handleClose={handleClose} {...props} />} {...props}>
    {children}
  </EventDetailsPopover>
})

export const GameDetailsPopover = memo(({ children, ...props }) => {
  return <EventDetailsPopover popoverContent={(handleClose) => <GameDetails handleClose={handleClose} {...props} />} width="600px" {...props}>
    {children}
  </EventDetailsPopover>
})
