import React, { Fragment } from 'react';
import { RecordContextProvider, ResourceContextProvider, SimpleForm } from 'react-admin';
import ScheduleSequencesCard from '../cards/ScheduleSequencesCard';
import { useScheduleContext } from './WizardContext';

export default ({ toolbar, handleNext }) => {
  const [ schedule ] = useScheduleContext();

  return <ResourceContextProvider value="schedules">
    <RecordContextProvider value={schedule}>
      <SimpleForm toolbar={toolbar} save={handleNext} component={Fragment} >
        <ScheduleSequencesCard
          resource="schedulesequences"
          title={false}
          collapsable={false}
          componentProps={{ style: { boxShadow: 'none' } }}
        />
      </SimpleForm>
    </RecordContextProvider>
  </ResourceContextProvider>
}
