import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Card, CardHeader as MuiCardHeader, CardActions, Divider, IconButton, styled, makeStyles } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const CardHeader = styled(MuiCardHeader)(({ collapsable }) => ({
  cursor: collapsable ? 'pointer' : undefined,
}))

const Collapsable = styled('span')(({ open }) => ({
  display: !open ? 'none' : '',
}))

const useStyles = makeStyles(theme => ({
  smallIcons: {
    marginTop: '-3px', // same as small IconButton's padding
    marginright: '-3px', // same as small IconButton's padding
  },
  actions: {
    flexWrap: 'wrap',
  }
}));

export default ({
  title,
  subtitle,
  headerAction,
  actions = [],
  collapsable = false,
  collapseActions = false,
  icon,
  smallActionBtns = false,
  component: Component = Card,
  children,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [ open, setOpen ] = useState(false);
  const [ loaded, setLoaded ] = useState(!collapsable);

  const onToggle = () => {
    if (!collapsable) return;
    setLoaded(true);
    setOpen(open => !open);
  }

  let cardActions = actions;
  if (cardActions && Array.isArray(cardActions)) {
    cardActions = cardActions.filter(Boolean);
  }

  const canCollapseActions = collapsable && collapseActions && !open;
  const showActions = cardActions && (!Array.isArray(cardActions) || cardActions.length > 0) && !canCollapseActions;
  return <Component>
    {title && <CardHeader
      avatar={icon}
      classes={{ action: smallActionBtns ? classes.smallIcons : undefined }}
      title={translate(title, { _: title, smart_count: 1 })}
      subheader={subtitle && translate(subtitle, { _: subtitle, smart_count: 1 })}
      action={<>
        {(open || !collapsable) && headerAction}
        {collapsable && <IconButton>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
      </>}
      titleTypographyProps={{ variant: "subtitle2", gutterBottom: false }}
      subheaderTypographyProps={{ variant: "body2", gutterBottom: false }}
      collapsable={collapsable}
      onClick={onToggle}
    />}
    {title && <Divider />}
    {loaded && <Collapsable open={open || !collapsable}>
      {children}
    </Collapsable>}
    {showActions && <CardActions className={classes.actions}>{cardActions}</CardActions>}
  </Component>
}
