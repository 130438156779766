import { GET_LIST, useQueryWithStore } from "react-admin"

import { useSelector } from 'react-redux';

export const useBranchSettings = officeId => {
  const settingsInStore = useSelector(state => state?.admin?.resources?.effectiveBranchOfficeSettings?.data?.[officeId])

  const { data, loading, error, refetch } = useQueryWithStore({
    type: GET_LIST,
    resource: 'effectiveBranchOfficeSettings',
    payload: {
      filter: { officeId, effectiveOffices: false },
    } }, {
    enabled: !settingsInStore && !!officeId,
  })

  return { data: settingsInStore || data?.[0], loading, error, refetch }
}
