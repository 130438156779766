import React from 'react';
import { Filter, NullableBooleanInput } from 'react-admin';

import { DisciplineStatusInput, SuspensionStatusInput } from '../../common/inputs/EnumInputs';

import { ParticipantInput } from '../participants/ParticipantInput';
import { GameInput } from '../games/GameInput';
import { TeamInput } from '../teams/TeamInput';
import { ScheduleInput } from '../schedules/ScheduleInput';

const multipleProps = {
  multiple: true,
  allowEmpty: false,
}

export const SuspensionFilters = props => {
  return <Filter variant="outlined" {...props}>
    <ParticipantInput source="participantId" alwaysOn {...multipleProps} />
    <TeamInput source="teamId" alwaysOn {...multipleProps} />
    <ScheduleInput source="game.scheduleId" label="resources.games.fields.scheduleId" alwaysOn {...multipleProps} multiple={false} />
    <GameInput source="gameId" alwaysOn {...multipleProps} />
    <SuspensionStatusInput source="status" {...multipleProps} />
    <DisciplineStatusInput source="disciplineStatus" {...multipleProps} />
    <NullableBooleanInput source="isAutomatic" />
  </Filter>
}
