import React from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import ScheduleForm from './ScheduleForm';

const EditScheduleDialog = props =>
  <EditResourceDialog label="resources.schedules.labels.edit" resource="schedules" basePath="/schedules" maxWidth="md" {...props}>
    <ScheduleForm />
  </EditResourceDialog>

export const AddScheduleButton = ({ size, variant, initialValues, ...props }) => {
  const translate = useTranslate();
  return <Button color="primary" size={size} variant={variant} startIcon={<Add />} component={Link} to={{ pathname: "/schedules/create", state: { initialValues } }}>
    {translate('resources.schedules.actions.add')}
  </Button>
}

export const ScheduleDatagrid = props => <DialogDatagrid dialog={EditScheduleDialog} {...props} />
