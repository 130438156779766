import React from 'react';
import { useListContext } from 'react-admin';

import Toolbar from '../../common/ra/Toolbar';

import { OfficeField } from '../offices/OfficeField';
import { TargetField } from '../targets/TargetField';
import { TargetTypeField } from '../targets/TargetTypeField';
import { GroupsField } from '../groups/GroupsField';

import { AssignTargetDatagrid } from './AssignTargetModal';

export const AssignTargetGrid = props => {
  const { ids = [], data } = useListContext(props);
  const showGroups = ids?.some(id => {
    const groupIds = data?.[id]?.groupIds;
    return groupIds != null && groupIds.length > 0;
  })

  return <AssignTargetDatagrid size="medium" toolbar={<Toolbar />} {...props}>
    <TargetTypeField source="targetType" label="" />
    <TargetField source="targetId" />
    {showGroups && <GroupsField source="groupIds" showType />}
    <OfficeField source="officeId" filter={{ isAssigner: true }} />
  </AssignTargetDatagrid>
}
