import React from 'react';
import { TextField, EditButton, useRecordContext } from 'react-admin'
import { Grid, Table, TableBody } from '@material-ui/core';

import Row from '../../common/cards/TableRow';
import { isAuthorized } from '../../common/Authorize';
import InfoCard from '../../common/cards/InfoCard';
import ContentField from '../../common/fields/ContentField';

import { OfficeField } from '../offices/OfficeField'

import EventDateField from './EventDateField';

const WebEventCard = props => {
  const event = useRecordContext(props);

  const canEditWebEvent = isAuthorized(event, 'webevents', 'edit')
  return <InfoCard actions={
    canEditWebEvent && <EditButton label="resources.webevents.actions.edit" record={event} />
  }>
    <Table>
      <TableBody>
        <Row>
          <ContentField source="content" />
        </Row>
        <Row label="resources.webevents.labels.dates">
          <EventDateField source="startTime" />
        </Row>
        <Row label="resources.webevents.fields.location">
          <TextField source="location" />
        </Row>
        <Row label="resources.webevents.fields.officeId">
          <OfficeField source="officeId" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}

export const WebEventShowLayout = props =>
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <WebEventCard />
    </Grid>
  </Grid>
