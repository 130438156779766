import React from 'react';
import { BooleanInput } from 'react-admin';

import { LATEST_SEASON } from '@hisports/common/src/seasons';

import { ResetFiltersButton } from '../../common/ResetFiltersButton';
import { InlineDateInput } from '../../common/inputs/DateInput';
import { GameStatusEnumInput } from '../../common/inputs/EnumInputs';
import { SeasonFilter, useSeason } from '../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../common/Authorize';
import { FilterCard } from '../../common/cards/FilterCard';
import LazyLoadFilter from '../../common/ra/Filter';
import { useDefaultSeason, useScopes } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';
import { TeamInput } from '../teams/TeamInput';
import { SavedSearches } from '../../common/SavedSearches';

const filterProps = {
  alwaysOn: true,
  fullWidth: true,
}

const multipleProps = {
  multiple: true,
  allowEmpty: false,
  ...filterProps,
}

const PracticeFilters = props => {
  const scopes = useScopes();
  const seasonId = useSeason()
  const defaultSeason = useDefaultSeason();

  const canListOffices = isAuthorized(scopes, 'offices', 'show');
  const canListTeams = isAuthorized(scopes, 'teams', 'list');
  const canListSurfaces = isAuthorized(scopes, 'surfaces', 'list');
  const showPastSeason = (seasonId === LATEST_SEASON) || (seasonId === defaultSeason);

  return <LazyLoadFilter variant="outlined" margin="dense" fullWidth {...props}>
    <InlineDateInput source="startTime" label="resources.practices.filters.startTime" {...filterProps} />
    <InlineDateInput source="endTime" label="resources.practices.filters.endTime" {...filterProps} />
    {canListTeams && <TeamInput source="teamIds" filter={{ seasonId }} {...multipleProps} />}
    {canListOffices && <OfficeInput source="officeId" filter={{ type: { neq: 'Historical' } }} {...multipleProps} />}
    {canListSurfaces && <SurfaceInput source="arenaId" {...multipleProps} />}
    <GameStatusEnumInput source="status" {...multipleProps} />
    {showPastSeason && <BooleanInput source="pastEvents" label="resources.practices.filters.pastEvents" {...filterProps} />}
    <SeasonFilter source="seasonId" {...filterProps} />
  </LazyLoadFilter>
}

export const PracticeFilterList = () => {
  return <FilterCard>
    <PracticeFilters />
    <ResetFiltersButton />

    <SavedSearches ignoredFilters={['seasonId', 'pastEvents']} />
  </FilterCard>
}
