import React, { useEffect, useState } from 'react';
import { useResourceContext, useTranslate } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Button,
  makeStyles,
  styled,
} from '@material-ui/core';
import { UnfoldMore } from '@material-ui/icons';
import moment from 'moment-timezone';
import MaskedInput from 'react-text-mask';

import { isLocalTimezone } from '@hisports/parsers/src/dateTime';

import { useRichTranslate } from '../../../common/useRichTranslate';
import { timeMask } from '../../../common/inputs/BasicTimeInput';

import { today } from './util';

const Content = styled('div')({
  display: 'inline-grid',
  gridGap: 8,
  gridAutoFlow: 'column',
  alignItems: 'baseline',
})

const AvailableInput = ({ value, setValue, className, ...props }) => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const handleOpen = e => {
    setValue(value => !value);
  }
  return <FormControl>
    <Select
      value={value}
      open={false}
      onOpen={handleOpen}
      IconComponent={UnfoldMore}
      classes={{ select: className }}
      {...props}
    >
      <MenuItem value={true}>{translate(`resources.participants.messages.availability.${resource === 'participants' ? 'person' : 'thing'}_will`)}</MenuItem>
      <MenuItem value={false}>{translate(`resources.participants.messages.availability.${resource === 'participants' ? 'person' : 'thing'}_will_not`)}</MenuItem>
    </Select>
  </FormControl>
}

const DurationInput = ({ value, setValue, timezone, children, ...props }) => {
  const translate = useTranslate();
  const handleChange = e => setValue(e.target.value);

  const displayedTimezone = isLocalTimezone(undefined, timezone)
    ? 'resources.games.messages.assignment.time_display_local'
    : 'resources.games.messages.assignment.time_display_other'
  return <FormControl>
    <RadioGroup value={value} onChange={handleChange}>
      <FormControlLabel value="all day" control={<Radio />} label={translate('ra.date.lexical.all_day')} />
      <FormControlLabel value="before" control={<Radio />} label={<Content>{translate('ra.date.lexical.before')} {value === 'before' && children}</Content>} />
      <FormControlLabel value="between" control={<Radio />} label={<Content>{translate('ra.date.lexical.between')} {value === 'between' && children}</Content>} />
      <FormControlLabel value="after" control={<Radio />} label={<Content>{translate('ra.date.lexical.after')} {value === 'after' && children}</Content>} />
      <FormHelperText>
        {translate(displayedTimezone, { timezone: moment.tz(timezone).zoneAbbr() })}
      </FormHelperText>
    </RadioGroup>
  </FormControl>
}

const TimeInput = ({ date, value: userValue, timezone, onChange, className, ...props }) => {
  const defaultValue = moment.tz(userValue, timezone).format('HH:mm');

  return <MaskedInput
    className={className}
    defaultValue={defaultValue}
    mask={timeMask}
    onChange={(e) => {
      const value = e?.target?.value
      const hour = value?.split(':')[0];
      const minutes = value?.split(':')[1];
      if (isNaN(hour) || isNaN(minutes)) return onChange(null)

      const time = moment.tz(date, timezone).set({ hours: hour, minutes }).toISOString();
      onChange(time);
    }}
    render={(ref, props) => (
      <TextField inputRef={ref} style={{ width: 75 }} {...props} />
    )}
  />
}

const NotesInput = ({ value, onChange }) => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const handleChange = e => onChange(e.target.value);
  return <TextField
    value={value}
    onChange={handleChange}
    label={translate(`resources.${resource}.labels.availability.note`)}
    helperText={translate(`resources.${resource}.helpers.availability.note`)}
    variant="outlined"
    fullWidth
    multiline
    rows={2}
    rowsMax={4}
  />
}

const getDuration = (startTime, endTime, timezone) => {
  const dayStart = moment.tz(today, timezone).startOf('day')
  const dayEnd = moment.tz(today, timezone).endOf('day')

  const isStart = moment.tz(startTime, timezone).isSame(dayStart, 'minute');
  const isEnd = moment.tz(endTime, timezone).isSame(dayEnd, 'minute');

  if (isStart && isEnd) return 'all day';
  if (isStart && !isEnd) return 'before';
  if (!isStart && isEnd) return 'after';
  return 'between';
}

const useStyles = makeStyles(theme => ({
  available: {
    fontWeight: 500,
  },
  duration: {
    margin: theme.spacing(1, 2, 3)
  },
  removeButton: {
    position: 'absolute',
    left: theme.spacing(1),
    color: theme.palette.error.dark,
  }
}))


export default ({ slot, timezone, onClose }) => {
  const translate = useRichTranslate();
  const resource = useResourceContext();
  const classes = useStyles();

  const defaultStart = slot?.startTime ?? moment.tz(today, timezone).set({ hours: 9, minutes: 0 }).toISOString()
  const defaultEnd = slot?.endTime ?? moment.tz(today, timezone).set({ hours: 18, minutes: 0 }).toISOString()

  const defaultAvailable = resource === 'participants';
  const disabled = resource == 'teams' || resource == 'surfaces';
  const [ isAvailable, setAvailable ] = useState(slot?.isAvailable ?? defaultAvailable);
  const [ startTime, setStart ] = useState(defaultStart)
  const [ endTime, setEnd ] = useState(defaultEnd)
  const [ duration, setDuration ] = useState(getDuration(startTime, endTime, timezone));
  const [ notes, setNotes ] = useState(slot?.notes ?? null);

  const showStart = ['between', 'after'].includes(duration);
  const showTo = duration === 'between';
  const showEnd = ['between', 'before'].includes(duration);

  const effectiveStartTime = showStart ? startTime : moment.tz(today, timezone).startOf('day').toISOString()
  const effectiveEndTime = showEnd ? endTime : moment.tz(today, timezone).endOf('day').toISOString()

  const isUpdate = slot?.id != null
  const isValid = moment.tz(effectiveStartTime, timezone).isBefore(effectiveEndTime);

  useEffect(() => {
    const dayStart = moment.tz(today, timezone).startOf('day')
    const dayEnd = moment.tz(today, timezone).endOf('day')

    const isStart = moment.tz(startTime, timezone).isSame(dayStart, 'minute');
    const isEnd = moment.tz(endTime, timezone).isSame(dayEnd, 'minute');

    if (isStart) {
      setStart(defaultStart)
    } else if (isEnd) {
      setEnd(defaultEnd)
    }
  }, [ startTime, endTime, timezone, defaultStart, defaultEnd ])

  const onAdd = () => {
    onClose({
      id: slot?.id ?? null,
      allDay: duration === 'all day',
      isAvailable,
      startTime: effectiveStartTime,
      endTime: effectiveEndTime,
      timezone,
      notes,
    });
  }

  const onRemove = () => {
    onClose({
      id: slot.id,
      remove: true,
    })
  }

  return <Dialog open onClose={() => onClose()} maxWidth="sm" fullWidth>
    <DialogTitle>{!isUpdate ? translate('ra.action.add') : translate('ra.action.update')} {translate('ra.date.time')}</DialogTitle>
    <DialogContent>
      <div>
        <Content>
          {translate(`resources.${resource}.messages.availability.available`, { input: <AvailableInput value={isAvailable} setValue={setAvailable} className={classes.available} disabled={disabled} /> })}
        </Content>
      </div>

      <div className={classes.duration}>
        <DurationInput value={duration} setValue={setDuration} timezone={timezone}>
          {showStart && <TimeInput date={today} value={startTime} timezone={timezone} onChange={setStart} />}
          {showTo && ` ${translate('resources.participants.messages.availability.to')} `}
          {showEnd && <TimeInput date={today} value={endTime} timezone={timezone} onChange={setEnd} />}
        </DurationInput>
      </div>

      <NotesInput value={notes} onChange={setNotes} />
    </DialogContent>
    <DialogActions>
      {isUpdate && <Button onClick={onRemove} color="secondary" className={classes.removeButton}>{translate('ra.action.remove')}</Button>}
      <Button onClick={() => onClose()}>{translate('ra.action.cancel')}</Button>
      <Button onClick={onAdd} color="primary" disabled={!isValid}>{!isUpdate ? translate('ra.action.add') : translate('ra.action.update')}</Button>
    </DialogActions>
  </Dialog>
}
