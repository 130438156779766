import { useQueryWithStore, GET_MANY } from 'react-admin';
import { useSelector } from 'react-redux';

// force check in cache (use with caution, data may have changed since last load)
export const useSuspensionsWithStore = gameId => {
  const suspensionsInStore = useSelector(state => state?.admin?.resources?.suspensions?.data);

  const { data, loading, error } = useQueryWithStore({
    type: GET_MANY,
    resource: 'suspensions',
    payload: { filter: { gameId } }
  }, {
    enabled: !suspensionsInStore && !!gameId
  })
  return { data: suspensionsInStore || data, loading, error };
}
