import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import MyProfile from './MyProfile';
import Verify from './Verify';

export default [
  <Route path="/profile/verify" exact component={Verify} noLayout />,
  <Route path="/profile" component={MyProfile} />,
  <Redirect from="/accounts/my/verify" exact to="/profile/verify" />,
  <Redirect from="/accounts/my" exact to="/profile" />,
]
