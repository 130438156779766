import React, { useRef } from 'react';
import { Grid } from '@material-ui/core'
import { ArrayInput, NumberInput, SimpleForm, TextInput, useTranslate } from 'react-admin';
import createCalculator from 'final-form-calculate';
import { useFormState } from 'react-final-form';
import { isInteger } from 'lodash';

import { isEmpty } from '@hisports/parsers';

import HorizontalFormIterator, { TransitionProps } from '../../common/ra/HorizontalFormIterator';
import { AttributeTargetTypeEnumInput, AttributeTypeEnumInput, AttributeValueTypeEnumInput } from '../../common/inputs/EnumInputs';
import Toolbar from '../../common/ra/Toolbar';

import { OfficeInput } from '../offices/OfficeInput';

const validate = values => {
  const errors = {};

  if (!values?.name) errors.name = 'ra.validation.required';
  if (!values?.valueType) errors.valueType = 'ra.validation.required';

  if (values?.valueType == 'Number' && values?.options?.min > values?.options?.max && isInteger(values?.options?.min) && isInteger(values?.options?.max)) {
    errors.options = { "min": "resources.attributeTypes.validation.min", "max": "resources.attributeTypes.validation.max" };
  }

  if (values?.valueType == 'Dropdown' && !isEmpty(values?.options?.values)) {
    const dropDownErrors = [];

    Object.keys(values?.options?.values).map(function(key) {
      if (isEmpty(values.options.values[key])) {
        dropDownErrors[key] = 'ra.validation.required';
      }
    });

    errors.options = { "values": dropDownErrors };
  }

  return errors;
}

const validateHasDropdownOptions = (options) => {
  if (isEmpty(options)) {
    return { message: 'resources.attributeTypes.validation.no_dropdown_options' };
  }
}

const inputProps = {
  resource: 'attributeTypes',
  basePath: '/attributeTypes',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const AttributeValueTypeInput = ({ ...props }) => {
  const { values } = useFormState();

  switch (values.valueType) {
    case 'Number':
      return <NumberValueTypeInput />
    case 'Dropdown':
      return <DropdownValueTypeInput />
    default:
      return null;
  }
}

export const DropdownValueTypeInput = props => {
  const translate = useTranslate();

  return <ArrayInput validate={validateHasDropdownOptions} source="options.values" label="" {...inputProps}>
    <HorizontalFormIterator disableReordering TransitionProps={TransitionProps} {...inputProps}>
      <TextInput options={{ label: translate("resources.attributeTypes.fields.options") }} {...inputProps} />
    </HorizontalFormIterator>
  </ArrayInput>
}

export const NumberValueTypeInput = props => {
  return <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      <NumberInput source="options.min" label="resources.attributeTypes.fields.min" helperText="resources.attributeTypes.helpers.optional" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput source="options.max" label="resources.attributeTypes.fields.max" helperText="resources.attributeTypes.helpers.optional" {...inputProps} />
    </Grid>
  </Grid>
}

export const AttributeTypeForm = props => {
  const showOfficeInput = props?.officeId;
  const showAttributeTypeInput = props?.type;
  const showAttributeTargetTypeInput = props?.targetType;
  const attributeValuesData = props?.attributeValuesData;
  const loadedData = props?.loadedData;

  const decorators = useRef([createCalculator({
    field: 'valueType',
    updates: {
      options: (valueType, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.valueType != valueType) {
          // Weird behavior when saving if we dont reset the options initialValues
          props.initialValues.options = null;
          return null;
        }

        return values.options;
      }
    }
  })])

  if (!isEmpty(props.initialValues?.id) && !loadedData) return null;

  return <SimpleForm toolbar={<Toolbar />} validate={validate} decorators={decorators.current} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <TextInput source="name" label="resources.attributeTypes.fields.name" helperText="" {...inputProps} />
      </Grid>
      {showOfficeInput && <Grid item xs={12}>
        <OfficeInput source="officeId" label="resources.attributeTypes.fields.officeId" helperText="" {...inputProps} />
      </Grid>}
      {showAttributeTypeInput && <Grid item xs={12}>
        <AttributeTypeEnumInput source="type" label="resources.attributeTypes.fields.type" helperText="" {...inputProps} />
      </Grid>}
      {showAttributeTargetTypeInput && <Grid item xs={12}>
        <AttributeTargetTypeEnumInput source="targetType" label="resources.attributeTypes.fields.targetType" helperText="" {...inputProps} />
      </Grid>}
      <Grid item xs={12}>
        <AttributeValueTypeEnumInput source="valueType" label="resources.attributeTypes.fields.valueType" radio helperText={!isEmpty(attributeValuesData) ? "resources.attributeTypes.helpers.valueType.has_data" : ''} disabled={!isEmpty(attributeValuesData)} {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <AttributeValueTypeInput {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
