import React from 'react';
import { useRecordContext } from 'react-admin';
import { LocalAtm } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';

import { usePermissions } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';
import { AddTransactionButton } from '../../officialTransactions/TransactionModal';
import { TransactionsGrid } from '../../officialTransactions/TransactionsGrid';
import TransactionsExporter from '../../officialTransactions/TransactionsExporter';

export default ({ ...props }) => {
  const game = useRecordContext();
  const permissions = usePermissions();

  const canAddTransactions = isAuthorized(game, 'officialTransactions', 'create', false, false);
  const canSelectAnyOffice = permissions.some(p => p.roleType === 'System')
  const allowedOfficeIds = permissions
    .filter(p =>
      p.inherited === false
      && p.roleType === 'Office'
      && p.scopes.some(scope => ['assigning:manage', 'assigning:assign'].includes(scope))
    )
    .flatMap(p => p.officeIds)

  let officeId;
  if (!canSelectAnyOffice && allowedOfficeIds.length === 1) {
    officeId = allowedOfficeIds[0];
  }

  const actions = [
    <TransactionsExporter hidePayPeriod={!canAddTransactions} target="gameId" />
  ]

  const initialValues = { officeId, gameId: game?.id }
  return <ListCard
    title="resources.officialTransactions.name"
    icon={<LocalAtm />}
    reference="officialTransactions"
    target="gameId"
    sort={{ field: ['participant.lastName', 'participant.firstName', 'payPeriod.startDate', 'type', 'subtype'], order: ['ASC', 'ASC', 'ASC', 'ASC', 'ASC'] }}
    actions={actions}
    addButton={canAddTransactions && <AddTransactionButton
      initialValues={initialValues}
      officeIds={!canSelectAnyOffice ? allowedOfficeIds : undefined}
      target="gameId"
    />}
    collapsable
    {...props}
  >
    <TransactionsGrid hideGame />
  </ListCard>
}
