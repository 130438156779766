import React from 'react';
import { useTranslate } from 'react-admin';

import { List, Show, Create, Edit } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';

import { PageGrid } from './PageGrid';
import { PageFilters } from './PageFilters';
import { PageShowLayout } from './PageShow';
import { PageForm } from './PageForm';

export const PageList = props =>
  <List {...props}
    filters={<PageFilters />}
    sort={{
      field: ['officeId', 'section.name', 'order', 'title'],
      order: ['ASC', 'ASC', 'ASC', 'ASC'],
    }}
  >
    <PageGrid rowClick="show" />
  </List>

export const PageShow = props =>
  <Show {...props}
    title={<ResourceTitle title={record => record.title} />}
  >
    <PageShowLayout />
  </Show>

export const PageCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.pages.labels.add')} />}
    {...props}
  >
    <PageForm redirect="show" />
  </Create>
}

export const PageEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: record.title })} />}
    {...props}
  >
    <PageForm redirect="show" />
  </Edit>
}
