import React from 'react';
import { groupBy } from 'lodash';
import { useListContext } from 'react-admin';
import { Typography, Card, Table, TableBody, makeStyles } from '@material-ui/core'

import { dedupe } from '@hisports/parsers';

import { SelectAllCheckbox } from '../resources/events/EventGroupedGrid';

const useStyles = makeStyles(theme => ({
  header: {
    position: 'sticky',
    top: 0,
    paddingLeft: props => props.canSelectAll ? theme.spacing(0.5) : theme.spacing(1.5),
    paddingRight: theme.spacing(0.5),
    marginTop: 1,
    backgroundColor: theme.palette.background.default,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '56px'
  },
  row: {
    cursor: 'pointer',
  },
  action: {
    marginLeft: 'auto',
  }
}))

const Title = ({ group }) => group;
const Group = ({ group, records, basePath, resource, titleComponent: TitleComponent = Title, actionButton: ActionComponent, children, hasBulkActions = false, isRowSelectable, ...props }) => {
  const ids = records.map(record => record.id)
  const selectableRows = isRowSelectable ? ids.filter(id => isRowSelectable(id)) : ids
  const canSelectAll = selectableRows.length && hasBulkActions;
  const classes = useStyles({ canSelectAll });

  return <>
    <div className={classes.header}>
      <SelectAllCheckbox ids={ids} hasBulkActions={hasBulkActions} isRowSelectable={isRowSelectable} />
      <Typography variant="subtitle2"><TitleComponent group={group} /></Typography>
      {ActionComponent && <ActionComponent group={group} className={classes.action} {...props} />}
    </div>
    <Card square elevation={0.5}>
      <Table>
        <TableBody>
          {React.cloneElement(children, { records, classes, basePath, resource })}
        </TableBody>
      </Table>
    </Card>
  </>
}

export default ({ grouping, titleComponent, children, ...props }) => {
  const { ids, data, basePath, resource } = useListContext(props)
  if (!ids || !ids.length) return null;

  const records = ids?.map(id => data?.[id]).filter(Boolean) || [];
  const groups = groupBy(records, record => grouping(record));
  const groupKeys = dedupe(records.map(grouping)) // maintains order from API

  return groupKeys.map(group =>
    <Group key={group} group={group} titleComponent={titleComponent} records={groups[group]} basePath={basePath} resource={resource} {...props}>
      {children}
    </Group>
  )
}
