import React, { Fragment } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { SimpleForm, TextInput, useRecordContext, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { sortBy } from 'lodash';

import { validateGameNumber } from '@hisports/scheduler';
import { getGameNumberValidationError } from '@hisports/scheduler/src/sequences/sequences';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { renumberGames } from '../common/GamesView';

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const validateStartNumber = (startNumber, seasonId) => {
  if (!startNumber) {
    return { message: 'ra.validation.required' }
  } else if (!validateGameNumber(startNumber, seasonId)) {
    const gameNumberError = getGameNumberValidationError(startNumber, seasonId);
    return { message: `resources.schedulesequences.validations.${gameNumberError}` }
  }
}

const RenumberGamesForm = ({ onSubmit, ...props }) => {
  const translate = useTranslate();
  const draft = useRecordContext();

  const { options } = draft || {};
  const { startNumber } = options || {};

  const onKeyDown = e => {
    // prevent autofocus on menu items
    e.stopPropagation()
  }

  return <SimpleForm save={onSubmit} initialValues={{ startNumber }} {...props}>
    <DialogContentText fullWidth>
      {translate('resources.drafts.messages.renumber_games.messages.description')}
    </DialogContentText>
    <br />
    <TextInput onKeyDown={onKeyDown} validate={(startNumber) => validateStartNumber(startNumber, draft?.seasonId)} source="options.startNumber" placeholder="XY001" label="resources.drafts.fields.options.startNumber" {...inputProps} />
  </SimpleForm>
}

export default ({ isOpen, onClose }) => {
  const translate = useTranslate();
  const { change } = useForm();

  const onSubmit = async values => {
    const { games, options } = values || {}
    const { startNumber } = options || {};
    if (!games || !startNumber) return

    const sortedGames = sortBy(games, ['round', 'date', 'startTime'])
    const renumberedGames = renumberGames(sortedGames, startNumber)

    change('games', renumberedGames)
    change('options', { ...options, startNumber })

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>{translate('resources.drafts.messages.renumber_games.title')}</DialogTitle>
    <DialogContent>
      <RenumberGamesForm
        component={Fragment}
        onSubmit={onSubmit}
        toolbar={<DialogFormToolbar submitLabel="ra.action.confirm" cancelLabel="ra.action.cancel" onCancel={onClose} />}
      />
    </DialogContent>
  </Dialog>
}
