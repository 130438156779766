import React, { useState } from 'react';
import { GET_LIST, useQuery, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';
import { AttributeTypeForm } from './AttributeTypeForm';
import { isEmpty } from 'lodash';

const AddAttributeTypeDialog = props =>
  <CreateResourceDialog label="resources.attributeTypes.actions.add" resource="attributeTypes" basePath="/attributeTypes" {...props}>
    <AttributeTypeForm />
  </CreateResourceDialog>

const EditAttributeTypeDialog = props => {
  const { data: attributeValuesData = [], loaded } = useQuery({
    type: GET_LIST,
    resource: 'attributeValues',
    payload: {
      filter: { attributeId: !isEmpty(props.record?.id) ? props.record?.id : '' },
    },
  })

  return <EditResourceDialog label="resources.attributeTypes.labels.edit" resource="attributeTypes" basePath="/attributeTypes" disableDelete={!isEmpty(attributeValuesData)} {...props}>
    <AttributeTypeForm attributeValuesData={attributeValuesData} loadedData={loaded} />
  </EditResourceDialog>
}

export const AddAttributeTypeButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.attributeTypes.actions.add')}</Button>
    <AddAttributeTypeDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const AttributeTypeDatagrid = props => <DialogDatagrid dialog={EditAttributeTypeDialog} {...props} />
