import React from 'react';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { useDefaultSeason } from '../../../http';

import { QualificationGrid } from '../../../resources/qualifications/QualificationGrid';
import { ParticipantIcon } from '../../../resources/icons';

const expiry = new Date().toISOString()

export default props => {
  const seasonId = useSeason();
  const defaultSeason = useDefaultSeason();

  const isPast = seasonId !== defaultSeason;
  const filter = isPast
    ? { seasonId }
    : {
      or: [
        { seasonId },
        { expiry: { gte: expiry } },
        { expiry: { eq: null } },
      ]
    }

  return <ListCard
    title="resources.qualifications.labels.card.title"
    icon={<ParticipantIcon />}
    reference="qualifications"
    target="participants"
    filter={filter}
  >
    <QualificationGrid rowClick={false} />
  </ListCard>
}
