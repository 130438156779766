import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { SurfaceForm } from './SurfaceForm';

const AddSurfaceDialog = props =>
  <CreateResourceDialog label="resources.surfaces.actions.add" resource="surfaces" maxWidth="md" basePath="/surfaces" {...props}>
    <SurfaceForm />
  </CreateResourceDialog>

const EditSurfaceDialog = props =>
  <EditResourceDialog label="resources.surfaces.labels.edit" resource="surfaces" maxWidth="md" basePath="/surfaces" {...props}>
    <SurfaceForm />
  </EditResourceDialog>

export const AddSurfaceButton = ({ size, variant, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.surfaces.actions.add')}
    </Button>
    <AddSurfaceDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const SurfaceDatagrid = props => <DialogDatagrid dialog={EditSurfaceDialog} {...props} />
