import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as MuiButton } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { Button, useDataProvider, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';

const closePeriod = async (dataProvider, periodId) => {
  return dataProvider.update('payPeriods', { id: periodId, data: { isClosed: true } })
}

const ClosePeriodModal = ({ open, handleClose, ...props }) => {
  const translate = useTranslate();
  const payPeriod = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const onSubmit = async () => {
    if (!payPeriod?.id) {
      handleClose();
      return;
    }

    closePeriod(dataProvider, payPeriod.id)
      .then(({ data }) => {
        handleClose();
        refresh();
        notify('resources.payPeriods.notifications.pay_period_close');
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify('resources.payPeriods.notifications.close_pay_period_error')
      })
  }

  return <Dialog open={open} onClose={handleClose} maxWidth="medium">
    <DialogTitle>{translate('resources.payPeriods.labels.close_period')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.payPeriods.helpers.close_period')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <MuiButton onClick={handleClose}>{translate('ra.action.cancel')}</MuiButton>
      <MuiButton onClick={onSubmit} color="primary">{translate('ra.action.close')}</MuiButton>
    </DialogActions>
  </Dialog>
}

export default ({ ...props }) => {
  const translate = useTranslate();
  const [ open, setOpen ] = useState(false);

  const handleClick = () => setOpen(true);

  return <>
    <Button label={translate('ra.action.close')} onClick={handleClick}>
      <Lock />
    </Button>
    {open && <ClosePeriodModal open={open} handleClose={() => setOpen(false)} />}
  </>
}
