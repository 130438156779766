import React, { useState } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../../common/dialogs/DialogDatagrid';
import { ResourceDialog } from '../../../common/dialogs/ResourceDialog';

import { PitcherLimitsForm } from './PitcherLimitsForm';

const AddPitcherLimitsDialog = props =>
  <RecordContextProvider value={null}>
    <ResourceDialog label="resources.rulebooks.labels.add_limits" resource="rulebooks" basePath="/rulebooks" maxWidth="sm" {...props}>
      <PitcherLimitsForm {...props} />
    </ResourceDialog>
  </RecordContextProvider>

const EditPitcherLimitsDialog = ({ save, ...props }) =>
  <ResourceDialog label="resources.rulebooks.labels.edit_limits" resource="rulebooks" basePath="/rulebooks" maxWidth="sm" handleSubmit={save} {...props}>
    <PitcherLimitsForm {...props} />
  </ResourceDialog>

export const AddPitcherLimitsButton = ({ save, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  const handleSubmit = pitcherLimits => {
    save(pitcherLimits);
    setOpen(false);
  }
  return <>
    <Button color="primary" startIcon={<Add />} onClick={() => setOpen(true)}>
      {translate('resources.rulebooks.actions.add_limits')}
    </Button>
    <AddPitcherLimitsDialog isOpen={isOpen} handleClose={() => setOpen(false)} handleSubmit={handleSubmit} {...props} />
  </>
}

export const PitcherLimitsDatagrid = props => <DialogDatagrid dialog={EditPitcherLimitsDialog} {...props} />
