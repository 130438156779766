import React from 'react';
import { Datagrid, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import Toolbar from '../../common/ra/Toolbar';
import FunctionField from '../../common/fields/FunctionField'

import { getSurfaceAttributes, getSurfaceName } from './util';

const useStyles = makeStyles({
  headerRow: {
    display: 'none',
  }
})

export const SurfaceGrid = ({ includeVenue = true, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return <Datagrid size="medium" toolbar={<Toolbar />} classes={classes} {...props}>
    <FunctionField source="name" sortable render={surface => getSurfaceName(surface, includeVenue)} />
    <FunctionField source="fraction" render={surface => getSurfaceAttributes(surface, translate)} />
  </Datagrid>;
}
