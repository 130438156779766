import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';

import Toolbar from '../../common/ra/Toolbar';
import PasswordInput from '../../common/inputs/PasswordInput';

const validate = values => {
  const errors = {};
  if (!values.email) errors.email = 'ra.validation.required'
  return errors;
}

const inputProps = {
  resource: 'accounts',
  basePath: '/accounts',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const AccountForm = props =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} autoComplete="off" {...props}>
    <TextInput source="email" type="email" autoComplete="off" autoFocus {...inputProps} />
    <PasswordInput source="password" autoComplete="new-password" allowEmpty helperText="resources.accounts.helpers.password" {...inputProps} />
  </SimpleForm>
