import React from 'react';
import { get } from 'lodash';

import { getSeasonDisplayName } from '@hisports/common/src/seasons';

import FunctionField from '../../common/fields/FunctionField'

const renderSeason = (record, source, resource, translate, sport) => {
  const seasonId = get(record, source)
  return getSeasonDisplayName(seasonId, sport)
}

export const SeasonField = props =>
  <FunctionField source="seasonId" render={renderSeason} {...props} />


