import React from 'react';
import { LocalAtm } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { TransactionsGrid } from '../../../resources/officialTransactions/TransactionsGrid';
import TransactionsExporter from '../../../resources/officialTransactions/TransactionsExporter';

export default ({ ...props }) => {
  const seasonId = useSeason();

  const actions = [
    <TransactionsExporter target="participantId" showSummary />
  ]

  return <ListCard
    title="resources.officialTransactions.name"
    icon={<LocalAtm />}
    reference="officialTransactions"
    target="participantId"
    actions={actions}
    filter={{ 'game.seasonId': seasonId }}
    sort={{ field: ['game.date', 'game.number'], order: ['DESC', 'ASC'] }}
    collapsable
    {...props}
  >
    <TransactionsGrid hideParticipant />
  </ListCard>
}
