import React, { useMemo, useRef } from 'react'
import { GET_LIST, SimpleForm, useQuery } from 'react-admin';
import { Grid } from '@material-ui/core'
import { useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';
import { isEmpty } from 'lodash';

import { getInfractionOptionCodes } from '@hisports/scoresheet/src/util';

import Toolbar from '../../common/ra/Toolbar';
import { EnumInput } from '../../common/inputs/EnumInputs';
import { useSeason } from '../../common/inputs/SeasonSelector';

import { SanctionInput } from './SanctionInput';
import { InfractionInput } from '../infractions/InfractionInput';
import { RuleInput } from '../infractions/RuleInput';

const validate = values => {
  const errors = {};

  if (!values.sanctionId) errors.sanctionId = 'ra.validation.required';
  if (!values.optionId && !values.infractionId) {
    errors.optionId = 'ra.validation.required';
    errors.infractionId = 'ra.validation.required';
  }

  return errors;
}

const inputProps = {
  resource: 'sanctionMembers',
  basePath: '/sanctionMembers',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const useRuleOptions = ruleId => useQuery({
  type: GET_LIST,
  resource: 'ruleOptions',
  payload: {
    filter: { ruleId, _include: ['rule', 'infractions', 'members'] }
  },
}, {
  enabled: ruleId != null,
})

const RuleInfractionInput = props => {
  const { values: { ruleId, optionId } } = useFormState();
  return <InfractionInput {...props} filter={{ ruleId }} disabled={optionId || !ruleId} />
}

const OptionInputEnum = props => {
  const { values: { ruleId, infractionId } } = useFormState();
  const { data: ruleOptions, loading } = useRuleOptions(ruleId);

  const choices = useMemo(() => ruleOptions?.filter(option => option.ruleId === ruleId).map((option) => ({
    id: option.id,
    name: option.name,
    description: getInfractionOptionCodes(option, option.infractions).join(' + '),
  })), [ruleOptions, ruleId]);

  return <EnumInput
    choices={choices}
    optionDescription="description"
    filterKeys={['name']}
    disabled={loading || !ruleId || infractionId}
    {...props}
  />
}

export default ({ office, ...props }) => {
  const seasonId = useSeason();

  const decorators = useRef([createCalculator({
    field: 'ruleId',
    updates: {
      optionId: (ruleId, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.ruleId != ruleId) {
          return null;
        }
        return values.optionId;
      },
      infractionId: (ruleId, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.ruleId != ruleId) {
          return null;
        }
        return values.infractionId;
      }
    }
  })])

  return <SimpleForm toolbar={<Toolbar />} validate={validate} decorators={decorators.current} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <SanctionInput source="sanctionId" filter={{ seasonId }} disabled {...inputProps} />
      </Grid>
      <Grid item xs={12} md={12}>
        <RuleInput
          source="ruleId"
          label="resources.sanctionMembers.labels.rule"
          filter={{ _include: 'section', seasonId }}
          groupBy={({ section }) => section?.name}
          alwaysGroup
          includeOffice
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <OptionInputEnum source="optionId" office={office} {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <RuleInfractionInput source="infractionId" office={office} {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
