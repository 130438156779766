import React from 'react';
import { NumberField, TextField, useListContext } from "react-admin"

import { parseSequence } from '@hisports/scheduler';

import FunctionField from '../../common/fields/FunctionField';

import { GroupField } from '../groups/GroupField';
import { ScheduleSequenceDatagrid } from './ScheduleSequenceModal';

export default ({ ...props }) => {
  const { ids, data } = useListContext(props);
  const showGroup = ids?.some(id => data?.[id]?.groupId != null)

  return <ScheduleSequenceDatagrid size="medium" {...props}>
    {showGroup && <GroupField source="groupId" sortable={false} />}
    <FunctionField label="resources.schedulesequences.labels.starting_sequence" render={sequence => parseSequence(sequence)} />
    <TextField source="prefix" />
    <TextField source="start" />
    <TextField source="suffix" />
    <NumberField source="current" />
  </ScheduleSequenceDatagrid>
}
