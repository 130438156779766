import React, { useContext, useMemo } from 'react';

import { addMemberToLineup, removeMemberFromLineup } from '@hisports/scoresheet/src/actions';
import { getInitialSuspension, isSuspended, getFaultyPlayerReason, getFlagColor, isPlayer, isStaff } from '@hisports/scoresheet/src/util';
import { getLineup, getLineupExtras, getThrowsInnings } from '@hisports/scoresheet/src/selectors';
import { FLAG_COLORS, TEAM_FLAGS } from '@hisports/scoresheet/src/constants';
import { dedupe } from '@hisports/parsers';

import { useMeta, useScoresheet, useScoresheetDispatch, useScoresheetStore } from '../ScoresheetContext';
import { useMemberValidationContext } from './MemberValidationContext';

const MemberItemContext = React.createContext({});

export const useMemberItem = () => useContext(MemberItemContext);

const nonSelectableFlags = [TEAM_FLAGS.UNVALIDATED_MEMBERS];

const isExtraMember = (member, extras) => extras.some(extra => extra.participantId === member.participantId);
const isServing = (member, memberValidation, selected) => selected && memberValidation?.suspensions.some(suspension => suspension.id === member.suspension?.id) || !!member.suspension
const isNotServing = (member, memberValidation, selected) => selected && !member.suspension && memberValidation?.suspensions.length
const isFaulty = (throwsInningsStatus, memberThrowsInnings) => getFaultyPlayerReason(throwsInningsStatus, memberThrowsInnings);

export const isPlayerUnvalidatedMember = (member, memberValidation) => (memberValidation?.invalidMemberTypes || []).includes('Player') && isPlayer(member)
export const isStaffUnvalidatedMember = (member, memberValidation) => (memberValidation?.invalidMemberTypes || []).includes('Staff') && isStaff(member)
const isUnvalidatedMember = (member, memberValidation) => isPlayerUnvalidatedMember(member, memberValidation) || isStaffUnvalidatedMember(member, memberValidation)

const getFlagMeta = (member, memberValidation, memberThrowsInnings, extras, hasSuspension, selected, type) => {
  const flagNames = []

  if (['Skater', 'Goalie'].includes(type) && isPlayerUnvalidatedMember(member, memberValidation)) {
    flagNames.push(TEAM_FLAGS.UNVALIDATED_MEMBERS)
  } else if (type === 'Staff' && isStaffUnvalidatedMember(member, memberValidation)) {
    flagNames.push(TEAM_FLAGS.UNVALIDATED_MEMBERS)
  } else if (!type && isUnvalidatedMember(member, memberValidation)) {
    flagNames.push(TEAM_FLAGS.UNVALIDATED_MEMBERS)
  }

  if (hasSuspension && !selected) {
    flagNames.push(TEAM_FLAGS.MISSING_SUSPENDED_MEMBER)
  } else if (isNotServing(member, memberValidation, selected)) {
    flagNames.push(TEAM_FLAGS.NOT_SERVING)
  } else if (isServing(member, memberValidation, selected)) {
    flagNames.push(TEAM_FLAGS.SERVING)
  }

  if (isExtraMember(member, extras)) {
    flagNames.push(TEAM_FLAGS.EXTRA)
  }
  if (isFaulty(memberValidation?.throwsInningsStatus, memberThrowsInnings)) {
    flagNames.push(TEAM_FLAGS.FAULTY_PLAYERS)
  }

  if (memberValidation?.isTransferred) {
    flagNames.push(TEAM_FLAGS.TRANSFERRED)
  }
  if (memberValidation?.isSecondaryTeam) {
    flagNames.push(TEAM_FLAGS.SECONDARY_TEAM)
  }

  const flagColors = dedupe(flagNames.map(flagName => getFlagColor(flagName)));

  let flagColor;
  if (flagColors.includes(FLAG_COLORS.RED) && flagColors.includes(FLAG_COLORS.ORANGE)) {
    flagColor = FLAG_COLORS.RED_ORANGE
  } else if (flagColors.includes(FLAG_COLORS.RED)) {
    flagColor = FLAG_COLORS.RED
  } else if (flagColors.includes(FLAG_COLORS.ORANGE)) {
    flagColor = FLAG_COLORS.ORANGE
  } else if (flagColors.includes(FLAG_COLORS.DEFAULT)) {
    flagColor = FLAG_COLORS.DEFAULT
  }

  return {
    flagColor,
    flagNames,
  }
}

export const MemberItemContextProvider = ({ member: rosterMember, teamId, disabled, isScorekeeper, type, ...props }) => {
  const store = useScoresheetStore();
  const { game } = useMeta();
  const dispatch = useScoresheetDispatch()
  const { memberValidations, loading } = useMemberValidationContext();
  const memberThrowsInnings = useScoresheet(scoresheet => getThrowsInnings(scoresheet, { teamId: rosterMember.teamId, participantId: rosterMember.participantId }))
  const lineupMember = useScoresheet(scoresheet => {
    const lineup = getLineup(scoresheet, { teamId });
    if (!lineup) return null;
    return lineup.members.find(member => member.participantId === rosterMember.participantId);
  })
  const extras = useScoresheet(scoresheet => getLineupExtras(scoresheet, { teamId }));

  const member = lineupMember || rosterMember;
  const memberValidation = memberValidations?.find(({ participantId }) => participantId === member.participantId);

  const selected = !!lineupMember;
  const handleSelect = useMemo(() => () => {
    if (disabled || loading) return;
    if (!selected) {
      const initialLineup = store.getState().lineups[teamId]?.members || [];
      const memberToAdd = {
        ...member,
        suspension: getInitialSuspension(memberValidation, teamId),
        unsaved: !initialLineup.some(lineupMember => lineupMember.participantId === member.participantId), // indicate member is not saved to lineup yet if different from initial lineup
      }
      dispatch(addMemberToLineup(game.id, teamId, memberToAdd))
    } else {
      dispatch(removeMemberFromLineup(game.id, teamId, member))
    }
  }, [ disabled, loading, game.id, dispatch, member, memberValidation, selected, teamId, store])

  const value = useMemo(() => {
    const hasSuspension = isSuspended(member) || !!memberValidation?.suspensions?.length;
    const { flagNames, flagColor } = getFlagMeta(member, memberValidation, memberThrowsInnings, extras, hasSuspension, selected, type);
    const isExtra = isExtraMember(member, extras);
    const nonSelectable = nonSelectableFlags.some(flagName => flagNames.includes(flagName));

    return {
      game, member, teamId, memberValidation, flagNames, flagColor, isExtra, hasSuspension, handleSelect, selected, disabled, loading, isScorekeeper, nonSelectable
    };
  }, [member, memberValidation, memberThrowsInnings, extras, selected, type, game, teamId, handleSelect, disabled, loading, isScorekeeper])

  return <MemberItemContext.Provider value={value} {...props} />
}
