import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { InsertDriveFileOutlined } from '@material-ui/icons';

import { isAuthorized } from '../../../common/Authorize';
import { useScopes } from '../../../http';

import ListCard from '../../../common/cards/ListCard';
import { OfficialClaimDocumentGrid } from '../../officialClaimsDocuments/OfficialClaimDocumentGrid';
import { AddOfficialClaimDocumentButton } from '../../officialClaimsDocuments/OfficialClaimDocumentModal';
import { Alert, AlertTitle } from '@material-ui/lab';

export default props => {
  const game = useRecordContext(props);
  const translate = useTranslate();
  const scopes = useScopes();

  const canManageGameDocuments = !game?.isCertified || scopes.includes('assigning:official');
  const canAddGameDocuments = isAuthorized(game, 'officialClaimDocuments', 'create') && canManageGameDocuments;

  if (!game?.claimId) return <Alert severity="info">
    <AlertTitle>{translate('resources.officialClaimDocuments.name')}</AlertTitle>
    {translate('resources.officialClaimDocuments.messages.info_creating_claim')}
  </Alert>

  return <ListCard
    title={translate("resources.officialClaimDocuments.name", 2)}
    icon={<InsertDriveFileOutlined fontSize="small" />}
    reference="officialClaimDocuments"
    target="games"
    filter={{ claimId: game.claimId }}
    addButton={canAddGameDocuments && <AddOfficialClaimDocumentButton initialValues={{ gameId: game.id, claimId: game.claimId }} />}
  >
    <OfficialClaimDocumentGrid canManageGameDocuments={canManageGameDocuments} />
  </ListCard>
}

