import React from 'react';
import { ReferenceInput } from 'react-admin';

import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion';
import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import { MailOutline, Phone, Smartphone } from '@material-ui/icons';

const getSecondary = contact => {
  if (contact.type !== 'Email') return contact.type;
  const attributes = [];
  if (contact.isPrimary) attributes.push('Primary')
  if (contact.isEmergency) attributes.push('Emergency')
  if (!contact.isVerified) attributes.push('Unverified')
  return attributes.join(', ')
}

const getIcon = contact => {
  if (!contact) return null;
  if (contact.type === 'Email') return <MailOutline />
  if (contact.type === 'Mobile') return <Smartphone />
  if (contact.type === 'Home') return <Phone />
  return null
}

const ContactSuggestion = ({ suggestion: contact }) => {
  if (!contact) return null;
  const primary = contact.value;
  const secondary = getSecondary(contact);
  const icon = getIcon(contact);
  return <AutocompleteSuggestion primary={primary} secondary={secondary} icon={icon} />
}

export const ContactInput = ({ helperText, ...props }) =>
  <ReferenceInput reference="contacts" filterToQuery={() => null} {...props}>
    <AutocompleteInput optionText="value" helperText={helperText} suggestionComponent={ContactSuggestion} />
  </ReferenceInput>
