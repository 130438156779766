import React from 'react';
import { ReferenceInput, ReferenceArrayInput, SelectArrayInput, ChipField, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { intersection } from 'lodash'
import { dedupeBy } from '@hisports/parsers';

import ChipInput from '../../common/inputs/ChipInput';
import { useSeason, useFormSeason } from '../../common/inputs/SeasonSelector';
import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import { useDefaultSeason } from '../../http';
import { makeStyles } from '@material-ui/styles';

const validate = (value, translate) => {
  if (typeof value === 'undefined') return translate('ra.validation.required')
}

const toArray = groups => Object.keys(groups).map(id => groups[id]);
export const hasGroups = (data, officeId, scheduleType) =>
  toArray(data).some(group =>
    group && group.officeId === officeId && group.type === scheduleType
  )

const hasValues = arr => arr && arr.length;
const intersects = (left, right) => intersection(left, right).length > 0;
const filterAttributes = categoryIds => group => {
  if (!group) return false;
  if (hasValues(categoryIds) && hasValues(group.categoryIds) && !intersects(categoryIds, group.categoryIds)) return false
  return true;
}

const filterNone = () => true
const FilteredChoiceInput = ({ choiceFilter = filterNone, choices, showNone, allowEmpty = showNone, children, ...props }) => {
  const filteredChoices = dedupeBy('id', choices).filter(choiceFilter);
  if (!filteredChoices.length) return null;
  return React.cloneElement(children, { ...props, choices: filteredChoices, allowEmpty })
}

const ChipSelectInput = ({ multiple, optionText, choices, showNone, ...props }) => {
  const translate = useTranslate();
  let groups = choices ? [...choices] : [];
  groups = groups.sort((groupA, groupB) => {
    const a = optionText(groupA);
    const b = optionText(groupB);

    if (!a && !b) return 0;
    if (a && !b) return -1;
    if (b && !a) return 1;

    return a.localeCompare(b, undefined, {
      sensitivity: 'base',
      ignorePunctuation: true,
      numeric: true,
    })
  })

  const long = groups.length > 15;
  if (long && showNone) groups.push({ id: null, name: showNone });

  if (multiple || long) return <AutocompleteInput choices={groups} optionText={optionText} emptyText={translate('resources.groups.messages.all_groups')} multiple={multiple} nullable {...props} />
  return <ChipInput choices={groups} optionText={optionText} {...props} />
}

const useStyles = makeStyles(theme => ({
  selectArrayInput: {
    // add half of the label's line-height top, + bottom for v-alignment
    paddingBlock: 'calc(1rem * .75 / 2)',

    '& .MuiFormHelperText-root:empty': {
      margin: 0,
    }
  },
}))

export const GroupInput = ({ select = false, multiple, officeId, scheduleType, categoryIds, showNone, filter = {}, helperText, defaultValue, ...props }) => {
  const translate = useTranslate();
  const appSeason = useSeason();
  const defaultSeason = useDefaultSeason();
  const seasonId = useFormSeason()
  const groupsData = useSelector(state => state.admin.resources.groups.data);
  const classes = useStyles();

  const choiceFilter = filterAttributes(categoryIds);
  const groups = toArray(groupsData).filter(choiceFilter);
  const shouldValidate = showNone && hasGroups(groups, officeId, scheduleType);

  const optionText = group => {
    if (!group) return;
    const attributes = []
    if (group.seasonId && (group.seasonId !== defaultSeason || seasonId !== appSeason)) attributes.push(group.seasonId);
    if (!scheduleType) attributes.push(group.type)
    if (!attributes.length) return group.name
    return `${group.name} (${attributes.join(' ')})`
  }

  let input = null;
  if (select && multiple) {
    input = <SelectArrayInput optionText={optionText} allowEmpty={showNone} helperText={helperText} {...props}>
      <ChipField source={optionText} />
    </SelectArrayInput>
  } else {
    input = <ChipSelectInput multiple={multiple} optionText={optionText} showNone={showNone} helperText={helperText} {...props} />
  }

  const Reference = multiple ? ReferenceArrayInput : ReferenceInput;
  return <div className={select && multiple ? classes.selectArrayInput : null}>
    <Reference
      {...props}
      reference="groups"
      filter={{ seasonId, officeId, type: scheduleType, effectiveOffices: false, ...filter }}
      filterToQuery={() => null}
      sort={{
        field: 'name',
        order: 'ASC'
      }}
      perPage={9999}
      defaultValue={defaultValue}
      validate={shouldValidate ? (value) => validate(value, translate) : null}
      margin="none"
    >
      <FilteredChoiceInput choiceFilter={choiceFilter} showNone={showNone}>
        {input}
      </FilteredChoiceInput>
    </Reference>
  </div>
}

export const OfficeGroupInput = ({ filterByCategories = true, ...props }) => {
  const { values } = useFormState();

  if (!values) return null;
  let officeId = props.officeId || values.officeId;
  if (!officeId && values.targetType === 'Office') {
    officeId = values.targetId
  }
  if (!officeId) return null;

  return <GroupInput {...props}
    officeId={officeId}
    categoryIds={filterByCategories ? values.categoryIds : []}
  />
}

export const ScheduleGroupInput = props => {
  const { values } = useFormState()
  const schedules = useSelector(state => state.admin.resources.schedules);

  if (!values) return null;
  let scheduleId = props.scheduleId || values.scheduleId;
  if (!scheduleId && values.targetType === 'Schedule') {
    scheduleId = values.targetId
  }
  if (!scheduleId) return null;

  const schedule = schedules.data[scheduleId]
  if (!schedule) return null;
  const { officeId, type, categoryId } = schedule;

  return <GroupInput {...props}
    officeId={officeId}
    scheduleType={type}
    categoryIds={[categoryId]}
  />
}
