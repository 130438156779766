import React from 'react';
import { ReferenceInput, ReferenceArrayInput, RecordContextProvider } from 'react-admin';
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { RuleField } from './RuleField';
import { InfractionTypeField } from './InfractionTypeField';
import { OfficeField } from '../offices/OfficeField';

const useStyles = makeStyles(theme => ({
  secondary: {
    display: 'flex',
    flexDirection: 'column',
  }
}))

const filter = (input = '') => {
  if (!input) return;
  const [ code, type ] = input.split(' - ')
  if (!type) return { or: [
    { code: { ilike: `%${code}%` } },
    { 'type.name': { ilike: `%${code}%` } },
  ] }
  return { and: [
    { code: { ilike: `%${code}%` } },
    { 'type.name': { ilike: `%${type}%` } },
  ] }
}

export const InfractionSuggestion = ({ suggestion: infraction, query, isHighlighted, includeOffice = false, ...props }) => {
  const classes = useStyles();
  return <RecordContextProvider value={infraction}>
    <AutocompleteSuggestion
      primary={<>
        {infraction.code && `${infraction.code} - `}
        <InfractionTypeField source="typeId" variant="inherit" />
      </>}
      secondary={<div className={classes.secondary}>
        <RuleField source="ruleId" variant="inherit" />
        {includeOffice && <OfficeField source="officeId" variant="inherit" />}
      </div>}
    />
  </RecordContextProvider>
}

export const InfractionInput = ({ helperText, multiple, includeOffice = false, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  const types = useSelector(state => state.admin.resources.infractionTypes.data)

  const getOptionText = infraction => {
    const type = types?.[infraction?.typeId];
    return [ infraction.code, type?.name ].filter(Boolean).join(' - ')
  }

  return <Input
    reference="infractions"
    filterToQuery={filter}
    sort={{ field: ['rule.order', 'type.order', 'code'], order: ['ASC', 'ASC', 'ASC'] }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText={getOptionText}
      suggestionComponent={InfractionSuggestion}
      options={{ suggestionComponentProps: { includeOffice } }}
      disableSort
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
