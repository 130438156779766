import React, { useMemo, useRef, useState } from "react";
import { SimpleForm, TextInput, useRecordContext, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import createCalculator from 'final-form-calculate';
import { sortBy } from "lodash";

import { getLineupExtras, getSummary } from "@hisports/scoresheet/src/selectors";
import { dedupeBy } from "@hisports/parsers";

import { useSport } from "../../../http";
import SwitchInput from "../../../common/inputs/SwitchInput";
import { FieldDependency } from "../../../common/FieldDependency";
import { EnumInput } from "../../../common/inputs/EnumInputs";

import { useMeta, useScoresheet } from "../ScoresheetContext";
import { PenaltyInput } from "../suspension/SuspensionForm";
import { ListItemSummaryDescription } from "../summary/SummaryItem";
import { getMemberDescription } from "../lineup/MemberItem";

const validate = values => {
  const errors = {};
  if (!values.description) errors.description = 'ra.validation.required';

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const MemberInput = ({ participants, disabled, ...props }) => {
  const translate = useTranslate();

  const choices = useMemo(() => {
    if (!participants) return []

    return sortBy(participants, ['number', 'fullName']).map(member => {
      const { id } = member.participant;
      const name = member.number ? `${member.participant.fullName} (${member.number})` : member.participant.fullName;
      const description = getMemberDescription(member, translate);
      return { id, name, description };
    })
  }, [participants, translate])

  return <EnumInput choices={choices} optionDescription="description" disabled={disabled} {...props} />
}

export const IncidentReportForm = props => {
  const record = useRecordContext(props);
  const sport = useSport();
  const { meta, game } = useMeta();
  const [ disabled, setDisabled ] = useState(false);
  const events = useScoresheet(scoresheet => getSummary(scoresheet, meta?.infractions, meta?.types, sport, game.seasonId));
  const homeExtra = useScoresheet(scoresheet => getLineupExtras(scoresheet, { teamId: game.homeTeamId, showSuspended: true }));
  const awayExtra = useScoresheet(scoresheet => getLineupExtras(scoresheet, { teamId: game.awayTeamId, showSuspended: true }));

  const event = props?.event || events.find(event => event.id === record?.penaltyId)
  const isEditing = !!record?.id
  const participants = dedupeBy('participantId', [
    ...meta.teams[game.homeTeamId].members.map(member => member),
    ...meta.teams[game.awayTeamId].members.map(member => member),
    ...homeExtra,
    ...awayExtra,
  ]);

  const decorators = useRef([createCalculator({
    field: 'injured',
    updates: {
      returnedToGame: (injured, values, prevValues) => {
        if (!injured) return false
        return values.returnedToGame;
      }
    }
  }, {
    field: 'penaltyId',
    updates: {
      participantId: (penaltyId, values, prevValues) => {
        setDisabled(false)
        if (!values?.penaltyId && !prevValues?.penaltyId) return values?.participantId
        if (!penaltyId) return null
        const penalty = events.find(({ id }) => id === penaltyId)
        if (!penalty) return null
        setDisabled(true)
        return penalty.participantId
      }
    }
  }
  )])

  return <SimpleForm decorators={decorators.current} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      {isEditing && <Grid item xs={12}>
        <ListItemSummaryDescription event={event} participantId={record?.participantId} displayGameTime={record?.penaltyId} {...props} />
      </Grid>}
      {!isEditing && <Grid item xs={12}>
        <PenaltyInput source="penaltyId" allowEmpty allowReuse={false} helperText="ra.message.optional" label="resources.gameIncidents.fields.penaltyId" {...inputProps} />
      </Grid>}
      {!isEditing && <Grid item xs={12}>
        <MemberInput source="participantId" participants={participants} disabled={disabled} label="resources.gameIncidents.labels.participantId" {...inputProps} />
      </Grid>}
      <Grid item xs={12}>
        <TextInput source="description" multiline rows={5} helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <SwitchInput source="injured" defaultValue={false} helperText="" {...props} />
      </Grid>
      <FieldDependency fieldSource="injured" test={value => value != false}>
        <Grid item xs={12}>
          <SwitchInput source="returnedToGame" defaultValue={false} helperText="" {...props} />
        </Grid>
      </FieldDependency>
    </Grid>
  </SimpleForm>
}
