import React from 'react';
import { useRecordContext } from 'react-admin';
import { Timer } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { InfractionTypeGrid } from '../../infractions/InfractionTypeGrid';
import { AddInfractionTypeButton } from '../../infractions/InfractionTypeModal';

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const canAddType = isAuthorized(office, 'infractionTypes', 'create');

  return <ListCard
    title="resources.infractionTypes.labels.card.title"
    subtitle="resources.infractionTypes.labels.card.subtitle"
    icon={<Timer />}
    collapsable
    reference="infractionTypes"
    target="officeId"
    sort={{
      field: ['order', 'name'],
      order: ['ASC', 'ASC'],
    }}
    addButton={canAddType && <AddInfractionTypeButton initialValues={{ officeId: office?.id, seasonId }} />}
  >
    <InfractionTypeGrid />
  </ListCard>
}
