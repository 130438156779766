import React, { useState } from 'react';
import { useTranslate, useRecordContext, ListToolbar } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, Settings } from '@material-ui/icons'

import { EVENT_TYPE_PENALTY, EVENT_TYPE_GOAL } from '@hisports/scoresheet/src/constants';
import { getAdminNotes, getGameMembers, getNotes, isGameCertified, isGameCompleted } from '@hisports/scoresheet/src/selectors';
import { FF_PERIODS } from '@hisports/common/featureFlags';

import { isAuthorized } from '../../../common/Authorize';
import InfoCard from '../../../common/cards/InfoCard';
import { ActionsToolbar } from '../../../common/cards/ListCard';
import { useFlag } from '../../../http';

import { useMeta, useScoresheet } from '../ScoresheetContext';
import { Scoreboard } from '../components/Scoreboard';
import { ScoreEditModal } from '../components/ScoreEditModal';
import { SummaryBulkAddModal } from './SummaryBulkAddModal';
import { SummaryList } from './SummaryList';
import { SummaryAlerts } from './alerts';
import { NotesAlert } from './alerts/NotesAlert';
import { NotesModal } from './NotesModal';
import { PeriodsModal } from './PeriodsModal';
import SummaryFlags from './SummaryFlags';

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: theme.spacing(0),
    margin: theme.spacing(0, 1),
  }
}))

const PeriodsButton = ({ ...props }) => {
  const translate = useTranslate()
  const [ open, setOpen ] = useState(false);

  return <>
    <Button color="primary" size="small" startIcon={<Settings />} onClick={() => setOpen(true)} {...props}>
      {translate('resources.scoresheets.labels.period_settings')}
    </Button>
    {open && <PeriodsModal open={open} setOpen={setOpen} />}
  </>
}

const NotesButton = ({ ...props }) => {
  const translate = useTranslate()
  const [ open, setOpen ] = useState(false);
  const notes = useScoresheet(scoresheet => getNotes(scoresheet));
  const adminNotes = useScoresheet(scoresheet => getAdminNotes(scoresheet));

  const hasNotes = notes || adminNotes;

  return <>
    <Button color="primary" size="small" startIcon={hasNotes ? <EditIcon /> : <AddIcon />} onClick={() => setOpen(true)} {...props}>
      {translate(`resources.scoresheets.labels.${hasNotes ? 'edit_notes' : 'add_notes'}`)}
    </Button>
    {open && <NotesModal open={open} setOpen={setOpen} />}
  </>
}

const AddSummaryItemsButton = ({ eventType, label, ...props }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return <>
    <Button color="primary" size="small" startIcon={<AddIcon />} onClick={() => setModalOpen(true)} {...props}>{ label }</Button>
    {modalOpen && <SummaryBulkAddModal eventType={eventType} open={modalOpen} setModalOpen={setModalOpen} />}
  </>
}

const EditScoreButton = ({ ...props }) => {
  const translate = useTranslate()
  const [ open, setOpen ] = useState(false);

  return <>
    <Button color="primary" size="small" startIcon={<EditIcon />} onClick={() => setOpen(true)} {...props}>
      {translate('resources.scoresheets.labels.edit_score')}
    </Button>
    {open && <ScoreEditModal open={open} setOpen={setOpen} />}
  </>
}

export const SummaryCard = ({ status, isAssignedOfficial, isScorekeeper }) => {
  const translate = useTranslate();
  const isEnabled = useFlag();
  const { meta } = useMeta();
  const { scoringMode } = meta?.policies || {};
  const game = useRecordContext();
  const classes = useStyles();

  const isCompleted = useScoresheet(scoresheet => isGameCompleted(scoresheet))
  const isCertified = useScoresheet(scoresheet => isGameCertified(scoresheet))
  const gameMembers = useScoresheet(scoresheet => getGameMembers(scoresheet))

  const canEditSummaryList = status.scorekeeping && ((isScorekeeper && !isCompleted) || isAuthorized(game, 'scoresheets', 'full')) && !isCertified

  const showPeriodsButton = canEditSummaryList && isEnabled(FF_PERIODS);
  const showNotesButton = canEditSummaryList;

  const showAddGoalsButton = canEditSummaryList && scoringMode === 'Detailed';
  const showAddPenaltiesButton = canEditSummaryList && !!Object.keys(gameMembers).length;
  const showEditScoreButton = canEditSummaryList && scoringMode === 'Score';
  const showToolbar = showNotesButton || showAddGoalsButton || showAddPenaltiesButton || showEditScoreButton;

  const showAdminAlert = isAuthorized(game, 'scoresheets', 'adminNotes')

  return <InfoCard title="resources.scoresheets.labels.summary">
    <SummaryAlerts isAssignedOfficial={isAssignedOfficial} isScorekeeper={isScorekeeper} />
    {showToolbar && <ListToolbar classes={classes} actions={<ActionsToolbar actions={<>
      {showPeriodsButton && <PeriodsButton />}
      {showNotesButton && <NotesButton />}
      {showAddGoalsButton && <AddSummaryItemsButton eventType={EVENT_TYPE_GOAL} label={translate('resources.scoresheets.actions.add_goals')} />}
      {showAddPenaltiesButton && <AddSummaryItemsButton eventType={EVENT_TYPE_PENALTY} label={translate('resources.scoresheets.actions.add_penalties')} />}
      {showEditScoreButton && <EditScoreButton />}
    </>} />} />}
    <Scoreboard />
    <SummaryFlags />
    <SummaryList status={status} isAssignedOfficial={isAssignedOfficial} isScorekeeper={isScorekeeper} />
    <NotesAlert type="scoresheet" />
    {showAdminAlert && <NotesAlert type="admin" />}
  </InfoCard>
}
