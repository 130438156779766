import React from 'react';
import { useRecordContext } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';

import { AttributeTypeGrid } from '../../attributeTypes/AttributeTypeGrid';
import { AddAttributeTypeButton } from '../../attributeTypes/AttributeTypeModal';
import { AttributeIcon } from '../../icons';
import ImportAttributes from '../dialogs/attributes'

export default ({ type, targetType, ...props }) => {
  const office = useRecordContext(props);
  const officeId = office?.id;

  const showAttributes = isAuthorized(office, 'attributeTypes', 'list');
  const canAddAttributes = isAuthorized(office, 'attributeTypes', 'create');
  const canImportAttributes = isAuthorized(office, 'attributeValues', 'import')
  const addButton = canAddAttributes && <AddAttributeTypeButton initialValues={{ officeId, type, targetType }} />;

  if (!showAttributes) return null;

  return <ListCard
    title="resources.attributeTypes.labels.card.title"
    subtitle="resources.attributeTypes.labels.card.subtitle"
    icon={<AttributeIcon />}
    reference="attributeTypes"
    target="officeId"
    filter={{ officeId, type, targetType }}
    sort={{ field: 'name', order: 'ASC' }}
    addButton={addButton}
    actions={[canImportAttributes && <ImportAttributes officeId={officeId} size="small" />]}
    collapsable
  >
    <AttributeTypeGrid />
  </ListCard>
}
