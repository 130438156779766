import { useQueryWithStore, GET_ONE, useQuery } from 'react-admin';
import { useSelector } from 'react-redux';

export const useOffice = officeId => useQuery({
  type: GET_ONE,
  resource: 'offices',
  payload: {
    id: officeId,
  }
}, {
  enabled: officeId != null,
})

// force check in cache (use with caution, data may have changed since last load)
export const useOfficeWithStore = officeId => {
  const officeInStore = useSelector(state => state?.admin?.resources?.offices?.data?.[officeId])

  const { data, loading, error } = useQueryWithStore({
    type: GET_ONE,
    resource: 'schedules',
    payload: { id: officeId },
  }, {
    enabled: !officeInStore && !!officeId
  })
  return { data: officeInStore || data, loading, error };
}

