import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { Grid } from '@material-ui/core';

import VenueCard from './cards/VenueCard';
import SurfacesCard from './cards/SurfacesCard';
import EventsCard from './cards/EventsCard';

export const VenueShowLayout = props =>
  <SimpleShowLayout>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <VenueCard />
      </Grid>
      <Grid item xs={12} md={6} />
      <Grid item xs={12}>
        <SurfacesCard />
      </Grid>
      <Grid item xs={12}>
        <EventsCard />
      </Grid>
    </Grid>
  </SimpleShowLayout>
