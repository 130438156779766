import React from 'react';
import { Grid } from '@material-ui/core'

import PracticeCard from './cards/PracticeCard';

export const PracticeShowLayout = props =>
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <PracticeCard />
    </Grid>
  </Grid>
