import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles'
import { Notification } from 'react-admin';
import Helmet from 'react-helmet';

import background from '../../assets/backgrounds/background.jpg'

import { useCreateTheme } from '../theme';
import { logoDark, appName } from '../flags';
import { LanguageMenu } from '../layout/LanguageMenu';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundColor: '#fdfdfd',

    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(350 + theme.spacing(2 * 2))]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  logo: {
    marginTop: theme.spacing(8),
    width: 250,
    margin: '0 auto',
    textAlign: 'center',
    '& svg': {
      width: '100%',
      height: theme.spacing(10),
    },
    [theme.breakpoints.up(350 + theme.spacing(3 * 2))]: {
      marginTop: theme.spacing(16),
    }
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const Logo = ({ classes }) =>
  <div className={classes.logo} dangerouslySetInnerHTML={{ __html: logoDark }}>
  </div>

const BaseLayout = ({ children, meta, hideNotifications }) => {
  const classes = useStyles();
  return <div className={classes.root}>
    <Helmet defaultTitle={appName} titleTemplate={`%s - ${appName}`} />
    <main className={classes.layout}>
      <Logo classes={classes} />
      <Paper className={classes.paper} elevation={5}>
        {children}
      </Paper>
      <Paper className={classes.paper} elevation={2}>
        {meta}
        <LanguageMenu />
      </Paper>
    </main>
    {!hideNotifications && <Notification />}
  </div>
}

export default props => {
  const theme = useCreateTheme();
  return <ThemeProvider theme={theme}>
    <BaseLayout {...props} />
  </ThemeProvider>
}
