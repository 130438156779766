import React, { useEffect, useMemo } from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core'

import { usePermissions } from '../../http';

import Toolbar from '../../common/ra/Toolbar';
import { EnumInput } from '../../common/inputs/EnumInputs';

const validate = values => {
  const errors = {}

  if (!values.service) errors.service = 'ra.validation.required'
  if (!values.link) errors.link = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'externalProviders',
  basePath: '/externalProviders',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const ExternalIdInput = (props) => {
  const { values } = useFormState();
  const isSurface = values?.targetType === 'Surface';

  if (!isSurface) return null;

  return <TextInput {...props} />
}

const ServiceEnum = (props) => {
  const { values } = useFormState();
  const { change } = useForm();
  const permissions = usePermissions();

  const isSurface = values?.targetType === 'Surface';
  const isSystem = permissions.some(p => p.roleType === 'System');

  useEffect(() => {
    if (isSurface) {
      change('service', 'Streaming');
    }
  }, [isSurface, change]);

  const choices = useMemo(() => ([
    { id: 'Streaming', name: 'resources.externalProviders.labels.services.Streaming' },
    !isSurface && { id: 'Ticketing', name: 'resources.externalProviders.labels.services.Ticketing' },
    !isSurface && isSystem && { id: 'Stats', name: 'resources.externalProviders.labels.services.Stats' },
  ].filter(Boolean)), [isSurface, isSystem]);

  return <EnumInput {...props} select choices={choices} disabled={isSurface} />
}

export const ExternalProviderForm = (props) => {
  return <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} md={4}>
        <ServiceEnum source="service" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextInput source="provider" autoFocus {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="link" placeholder="https://www.spordle.com" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ExternalIdInput source="externalId" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
