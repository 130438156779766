import React from 'react'
import { GET_LIST, useQuery, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useSequences = scheduleId => useQuery({
  type: GET_LIST,
  resource: 'schedulesequences',
  payload: {
    filter: { scheduleId },
  }
}, { enabled: scheduleId != null })

const ScheduleSequenceAlert = ({ scheduleId, ...props }) => {
  const translate = useTranslate();
  const history = useHistory();
  const { data: sequences, loading } = useSequences(scheduleId);

  if (loading || sequences?.length) return null;

  const handleButtonClick = () => {
    history.push({
      pathname: `/schedules/${scheduleId}/show/games`,
      state: { focus: 'sequence' }
    });
  };

  return <Alert
    severity="warning"
    action={
      scheduleId && (
        <Button color="primary" onClick={handleButtonClick}>
          {translate('resources.schedulesequences.actions.add')}
        </Button>
      )
    }
    {...props}
  >
    {translate('resources.schedules.alerts.missing_sequence')}
  </Alert>
}

export default ScheduleSequenceAlert;
