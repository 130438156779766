import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core'
import { useFormState } from 'react-final-form';
import { useTranslate, useLocale } from 'react-admin';

import { translateApiProperty } from '@hisports/common';

import { ScheduleTypeEnumInput } from '../../common/inputs/EnumInputs';
import { SeasonFilter, useSeason } from '../../common/inputs/SeasonSelector';
import AutocompleteInput from '../../common/inputs/AutocompleteInput';

import { OfficeInput } from '../../resources/offices/OfficeInput';
import { ParticipantInput } from '../../resources/participants/ParticipantInput';
import { TeamInput } from '../../resources/teams/TeamInput';
import { ScheduleInput } from '../../resources/schedules/ScheduleInput';
import { CategoryInput } from '../../resources/categories/CategoryInput';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  allowEmpty: true,
  fullWidth: true
}

const TopFilters = ({ reports, hideOffice, hideParticipant, hideTeam, ...props }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const seasonId = useSeason();
  const { values } = useFormState();
  const { reportId } = values;

  const report = reports.find(report => report.id === reportId);
  const isPlayerReport = report && report.targetType === 'Participant'
  const isTeamReport = report && report.targetType === 'Team'
  const isLeagueReport = report && report.targetType === 'Office'

  return <>
    <Grid item xs={12} md={4}>
      <AutocompleteInput
        source="reportId"
        label="resources.reports.name"
        choices={reports}
        groupBy={report => translate(`resources.reports.values.types.${report.type}`)}
        optionText={report => translateApiProperty(report, 'name', locale)}
        {...inputProps}
      />
    </Grid>
    {isLeagueReport && !hideOffice && <Grid item xs={12} md={4}>
      <OfficeInput
        source="officeId"
        label="resources.offices.name"
        filter={{ _scope: 'Reports' }}
        perPage={100}
        {...inputProps}
      />
    </Grid>}
    {isPlayerReport && !hideParticipant && <Grid item xs={12} md={4}>
      <ParticipantInput
        source="participantId"
        label="resources.participants.name"
        filter={{ _scope: 'Reports' }}
        {...inputProps}
      />
    </Grid>}
    {isTeamReport && !hideTeam && <Grid item xs={12} md={4}>
      <TeamInput
        source="teamId"
        label="resources.teams.name"
        filter={{ seasonId, _scope: 'Reports' }}
        {...inputProps}
      />
    </Grid>}
  </>
}

const OptionalFilters = ({ reports, ...props }) => {
  const { values } = useFormState();
  const { reportId, officeId, scheduleType } = values;

  const report = reports.find(report => report.id === reportId);
  const isLeagueReport = report && report.targetType === 'Office'

  return <>
    <Grid item xs={12} md={4}>
      <ScheduleTypeEnumInput
        source="scheduleType"
        label="resources.schedules.fields.scheduleType"
        helperText="ra.message.optional"
        {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <ScheduleInput
        source="scheduleId"
        label="resources.schedules.name"
        filter={{ effectiveOffices: officeId, type: scheduleType }}
        helperText="ra.message.optional"
        {...inputProps}
      />
    </Grid>
    {isLeagueReport && <Grid item xs={12} md={4}>
      <CategoryInput
        source="categoryIds"
        label="resources.categories.name"
        multiple
        officeId={officeId}
        helperText="ra.message.optional"
        {...inputProps}
      />
    </Grid>}
  </>
}

const ReportFilters = ({
  reports,
  component: Component = Card,
  componentContent: ComponentContent = CardContent,
  groupFilters = true,
  hideOffice = false,
  hideParticipant = false,
  hideTeam = false,
}) => {
  return <Component>
    <ComponentContent>
      <SeasonFilter source="seasonId" />
      {groupFilters ?
        <>
          <Grid container spacing={2} fullWidth>
            <TopFilters reports={reports} hideOffice={hideOffice} hideParticipant={hideParticipant} hideTeam={hideTeam} />
          </Grid>
          <Grid container spacing={2} fullWidth>
            <OptionalFilters reports={reports} />
          </Grid>
        </>
        :
        <Grid container spacing={2} fullWidth>
          <TopFilters reports={reports} hideOffice={hideOffice} hideParticipant={hideParticipant} hideTeam={hideTeam} />
          <OptionalFilters reports={reports} />
        </Grid>
      }
    </ComponentContent>
  </Component>
}

export default ReportFilters;
