import React from 'react';

import { ParticipantField } from '../participants/ParticipantField';
import { OfficeField } from '../offices/OfficeField';
import { DivisionField } from '../categories/DivisionField';
import { SeasonField } from '../seasons/SeasonField';

import { RegistrationDatagrid } from './RegistrationModal';

export const RegistrationGrid = ({ showParticipant = false, showOffice = true, ...props }) =>
  <RegistrationDatagrid size="medium" {...props}>
    <SeasonField source="seasonId" />
    {showParticipant && <ParticipantField source="participantId" includeId="inline" />}
    {showOffice && <OfficeField source="officeId" />}
    <DivisionField source="divisionId" />
  </RegistrationDatagrid>
