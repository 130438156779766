import React from 'react';
import { useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(0, 1),
    fontSize: '0.9rem',
    fontWeight: 400,
  },
  skipButton: {
    marginLeft: 8,
    marginRight: 'auto',
  }
}))

export const StepperToolbar = ({
  handleSubmit,
  submitLabel,
  submittingLabel = submitLabel,
  hideSubmit = false,
  cancelLabel = "ra.action.cancel",
  onCancel,
  handleSkip,
  showSkip = false,
}) => {
  const classes = useStyles();
  const { submitError, submitting } = useFormState();
  const translate = useTranslate();

  return <>
    {submitError && <p className={classes.error}>{submitError}</p>}
    <DialogActions>
      {showSkip && <Button color="secondary" disabled={submitting} onClick={handleSkip} className={classes.skipButton}>
        {translate("ra.action.skip")}
      </Button>}
      {onCancel && <Button disabled={submitting} onClick={onCancel}>
        {translate(cancelLabel, { _: cancelLabel, smart_count: 1 })}
      </Button>}
      {!hideSubmit && <Button type="submit" color="primary" disabled={submitting} onClick={handleSubmit}>
        {!submitting
          ? translate(submitLabel, { _: submitLabel, smart_count: 1 })
          : translate(submittingLabel, { _: submittingLabel, smart_count: 1 })}
      </Button>}
    </DialogActions>
  </>
}
